import { Enrollment, Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { PickTypename } from "type-utils";

export type PatientWithActiveEnrollmentInfo<
  E extends PickTypename<
    NonNullable<NonNullable<Patient["activeCollaborativeCareCareEpisode"]>["collaborativeCareEnrollment"]>,
    "id"
  >
> = PickTypename<Patient, "__typename"> & {
  activeCollaborativeCareCareEpisode:
    | (PickTypename<NonNullable<Patient["activeCollaborativeCareCareEpisode"]>, "id"> & {
        collaborativeCareEnrollment: E | null;
      })
    | null;
};

export function activeEnrollment<
  E extends PickTypename<Enrollment, "id">,
  T extends PatientWithActiveEnrollmentInfo<E>
>(patient: T): E | null {
  return patient.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment || null;
}

export function patientUrl(patient: PickTypename<Patient, "id">) {
  return `/app/cocm/patient/${patient.id}`;
}
