import React, { ReactElement } from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

// Default value for row height and page size listed in https://mui.com/x/react-data-grid/components/#props
// All data grid users are encouraged to set their page size explicitly rather than relying on the default.
// TODO: Is it reasonable to extend this to work with variable-height rows?
const DEFAULT_PAGE_SIZE = 100;
const DEFAULT_ROW_HEIGHT = 52;

// DataGrid has a handy autoHeight prop that makes it take up enough room for however many rows you put it in.
// In practice though, this means that it 1) leaves no room for any content when you have an error state, making the
// error message disappear, and 2) when you're scrolled to the bottom of a page with a data grid, then move to the next
// page, the loading state takes up less height than the rows did, which means theres less content on the page, forcing
// the scrollbar back up and leaving the content cut off once it loads in. This attempts to address those by figuring
// out how large the grid should be automatically.
export function FixedHeightDataGrid(props: DataGridProps): ReactElement {
  // Use pageSize instead of rows.length so that the grid doesn't shrink when you get to the last page.
  // +2 here accounts for the column header row and the "row" of pagination controls.
  const rowsToMakeSpaceFor = (props.paginationModel?.pageSize || DEFAULT_PAGE_SIZE) + 2;
  const rowHeight = props.rowHeight || DEFAULT_ROW_HEIGHT;
  // Row height is defined by the box sizing of the row elements, which use content-box not border-box, so we have to
  // add a pixel per border.
  const borderHeight = props.paginationModel?.pageSize || DEFAULT_PAGE_SIZE;
  const totalHeight = rowsToMakeSpaceFor * rowHeight + borderHeight;

  return (
    <div style={{ width: "100%", height: `${totalHeight}px` }}>
      <DataGrid {...props} />
    </div>
  );
}
