import { Dialog, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { useCareEpisodeDischargeMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { CareEpisodeId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { refetchQueries } from "Lib/RefetchQueries";

function CareEpisodeDischargeDialog({
  id,
  closeDialog,
}: {
  id: CareEpisodeId;
  closeDialog: () => void;
}): ReactElement {
  const [dischargeCareEpisode, dischargeCareEpisodeResult] = apolloMutationHookWrapper(
    (x) => x.careDischargeCareEpisode,
    useCareEpisodeDischargeMutation({
      variables: {
        input: { careEpisodeId: id }, // value for 'input'
      },
      refetchQueries: refetchQueries("careEpisodes"),
    })
  );

  const error: ReactElement | null = dischargeCareEpisodeResult.remoteData.caseOf({
    NotAsked: () => null,
    Loading: () => null,
    Failure: (_e) => <Typography color={"error.main"}>The episode of care could not be saved</Typography>,
    Success: () => {
      setTimeout(() => closeDialog());
      return null;
    },
  });

  return (
    <Dialog disableEnforceFocus open={true} fullWidth={true} maxWidth={"xs"} onClose={() => closeDialog()}>
      <DialogContent dividers>
        Are you sure you want to discharge this episode of care?
        {error}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => closeDialog()}>
          Cancel
        </Button>

        <Button color="secondary" onClick={() => dischargeCareEpisode()}>
          Discharge
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CareEpisodeDischargeDialog;
