import report from "./report";
import common from "./common";
import general from "./general";
import settings from "./settings";
import careEpisodes from "./careEpisodes";
import dashboard from "./dashboard";
import login from "./login";
import failedLogins from "./failedLogins";
import qualityIssues from "./qualityIssues";
import appointments from "./appointments";
import treatmentServices from "./treatmentServices";
import decisionSupport from "./decisionSupport";
import enums from "./enums";
import collaborativeCare from "./collaborativeCare";
import patients from "./patients";
import organizations from "./organizations";
import outcomes from "./outcomes";
import external from "./external";
import integrations from "./integrations";
import care from "./care";
import implementation from "./implementation";
import billing from "./billing";
import instituteGroups from "./instituteGroups";

const en = {
  report,
  common,
  general,
  settings,
  careEpisodes,
  dashboard,
  login,
  failedLogins,
  qualityIssues,
  appointments,
  treatmentServices,
  decisionSupport,
  enums,
  collaborativeCare,
  patients,
  organizations,
  outcomes,
  external,
  integrations,
  care,
  implementation,
  billing,
  instituteGroups,
};

export default en;
