import React, { ReactElement } from "react";

// SVG patterns injected on the App level and globally available as patterns in
// SVGs or as backgrounds in CSS by importing the patterns from this file.
//
// Usage:
//
// import patterns from "./SvgPatterns";
//
// Add a fill on an SVG shape use
//   fill: `url(${Patterns.Crosshatch.svgUrl})`
//
// Add a background in CSS:
//   background: `url("${Patterns.crosshatch.dataUrl}")`

export type Pattern = {
  svgUrl: string;
  width: number;
  height: number;
  dataUrl: string;
};

const Patterns = {
  Crosshatch: {
    svgUrl: "#pattern-crosshatch",
    width: 8,
    height: 8,
    dataUrl:
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc4JyBoZWlnaHQ9JzgnPgogIDxyZWN0IHdpZHRoPSc4JyBoZWlnaHQ9JzgnIGZpbGw9JyNmZmYnLz4KICA8cGF0aCBkPSdNMCAwTDggOFpNOCAwTDAgOFonIHN0cm9rZS13aWR0aD0nMC41JyBzdHJva2U9JyNhYWEnLz4KPC9zdmc+Cg==",
  },
  DiagonalLines: {
    svgUrl: "#pattern-diagonal-lines",
    width: 5,
    height: 5,
    dataUrl:
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiNkZGQiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=",
  },
};

// -- Global SVG Definition ---------------------------------------------------

function PatternDef(props: { pattern: Pattern }): ReactElement {
  const { pattern } = props;
  const image = `<image xlink:href="${pattern.dataUrl}" x="0" y="0" width="${pattern.width}" height="${pattern.height}"></image>`;

  return (
    <pattern
      id={pattern.svgUrl.replace("#", "")}
      patternUnits="userSpaceOnUse"
      width={pattern.width}
      height={pattern.height}
      dangerouslySetInnerHTML={{ __html: image }}
    />
  );
}

function SvgPatterns(): ReactElement {
  return (
    <svg width={0} height={0}>
      <defs>
        {Object.values(Patterns).map((pattern, i) => {
          return <PatternDef key={i} pattern={pattern} />;
        })}
      </defs>
    </svg>
  );
}

export { SvgPatterns, Patterns };
export default Patterns;
