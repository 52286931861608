import React, { ReactElement } from "react";
import { PatientDetails } from "./PatientDetails";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { EditDemographicsButton } from "./EditDemographicsButton";
import { WithPermission } from "Shared/WithPermission";
import { notificationPreferenceT } from "GeneratedGraphQL/EnumTranslations";
import IntegrationStatusBadge from "Integrations/IntegrationStatusBadge";
import { ImportHistoryId } from "Lib/Ids";
import { DataSourceResourceType } from "GeneratedGraphQL/SchemaAndOperations";

type PatientInfoCardProps = {
  patient: PatientDetails;
};
export default function PatientInfoCard(props: PatientInfoCardProps): ReactElement {
  const { t } = useTranslation(["report", "enums", "common", "patients"]);

  const cardAction = (
    <WithPermission permission="editPatient">
      <Box marginRight="0.3em">
        <EditDemographicsButton patient={props.patient} />
      </Box>
    </WithPermission>
  );

  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.patientInfo")} action={cardAction} />
      <CardContent>
        <IntegrationStatusBadge
          importHistoryId={props.patient.id as unknown as ImportHistoryId}
          resourceType={DataSourceResourceType.PATIENT}
        />
        <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
          <LabelAndInfo label={t("patients:user.mrn")} data={props.patient.mrn} />
          <LabelAndInfo label={t("patients:user.email")} data={props.patient.email} />
          <LabelAndInfo label={t("patients:user.phoneNumber")} data={props.patient.phoneNumber} />
          <LabelAndInfo label={t("patients:user.genderIdentity")} data={props.patient.genderIdentity} />
          <LabelAndInfo
            label={t("patients:user.notificationPreference")}
            data={
              props.patient.notificationPreference
                ? notificationPreferenceT(props.patient.notificationPreference, t)
                : null
            }
          />
        </Grid>
      </CardContent>
    </Card>
  );
}

function LabelAndInfo({ label, data }: { label: string; data: string | null }): ReactElement {
  return (
    <>
      <Grid item sm={4} xs={12} key="">
        <Typography variant="h3">{label}</Typography>
      </Grid>
      <Grid item sm={8} xs={12}>
        <Typography>{data}</Typography>
      </Grid>
    </>
  );
}
