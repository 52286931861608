import { Add, Remove } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  DialogContent,
  FormControl,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper, remoteErrorMessage } from "Api/GraphQL";
import { useCurrentProviderId } from "AppSession/AppSession";
import { careEpisodeProviderRelationshipT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisodeProviderRelationship,
  useAddCareEpisodeProviderMutation,
  useCareEpisodeCareTeamDetailsQuery,
  useDeleteCareEpisodeProviderMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, ProviderId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import EnumSelect from "Shared/EnumSelect";
import ErrorMessage from "Shared/ErrorMessage";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import ProviderName from "Shared/Names/ProviderName";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type EditCareEpisodeProvidersButtonProps = {
  careEpisodeId: CareEpisodeId;
  buttonMinWidth?: string;
};

type ProviderSelectorMode = "all" | "organization";
type ProviderSelectorButtonProps = ButtonProps & { mode: ProviderSelectorMode };

function ProviderSelectorButton(props: ProviderSelectorButtonProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const text =
    props.mode === "all"
      ? t("careEpisodes:actions.showOrgProviders")
      : t("careEpisodes:actions.showAllProviders");
  return <Button {...props}>{text}</Button>;
}

export function EditCareEpisodeProvidersButton(props: EditCareEpisodeProvidersButtonProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <EditCareEpisodeProvidersDialog
      open={open}
      onClose={() => setOpen(false)}
      careEpisodeId={props.careEpisodeId}
    />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("careEpisodes:careTeam.edit")}
      </Button>
      {dialog}
    </Box>
  );
}

type EditCareEpisodeProvidersDialogProps = {
  careEpisodeId: CareEpisodeId;
  open: boolean;
  onClose: () => void;
};

function EditCareEpisodeProvidersDialog(props: EditCareEpisodeProvidersDialogProps): ReactElement {
  const { t } = useTranslation(["careEpisodes", "enums"]);
  const currentProviderId = useCurrentProviderId();

  const { remoteData } = apolloQueryHookWrapper(
    useCareEpisodeCareTeamDetailsQuery({ variables: { careEpisodeId: props.careEpisodeId } })
  );

  const [addProvider, { remoteData: addProviderRemoteData }] = apolloMutationHookWrapper(
    (response) => response.addCareEpisodeProvider,
    useAddCareEpisodeProviderMutation({})
  );

  const [deleteProvider, { remoteData: deleteProviderRemoteData }] = apolloMutationHookWrapper(
    (response) => response.deleteCareEpisodeProvider,
    useDeleteCareEpisodeProviderMutation({})
  );

  const [providerListMode, setProviderListMode] = React.useState<ProviderSelectorMode>("all");
  const [selectedProvider, setSelectedProvider] = React.useState<ProviderId | null>(currentProviderId);
  const [selectedRelationship, setSelectedRelationship] =
    React.useState<CareEpisodeProviderRelationship | null>(null);

  function swapProviderListMode() {
    setProviderListMode(providerListMode === "all" ? "organization" : "all");
    setSelectedProvider(null);
  }

  const addToCareTeam = () => {
    if (selectedProvider && selectedRelationship) {
      addProvider({
        variables: {
          input: {
            careEpisodeId: props.careEpisodeId,
            providerId: selectedProvider,
            relationship: selectedRelationship,
          },
        },
      });
    }
  };

  const removeError = deleteProviderRemoteData.caseOf({
    Failure: (error) => <Alert severity="error">{remoteErrorMessage(error)}</Alert>,
    _: () => null,
  });

  const addError = addProviderRemoteData.caseOf({
    Failure: (error) => <Alert severity="error">{remoteErrorMessage(error)}</Alert>,
    _: () => null,
  });

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.careEpisode) {
        const activeOrg = providerListMode === "organization" ? response.careEpisode.organization.id : null;
        return (
          <>
            <Typography>{t("careEpisodes:careTeam.description")}</Typography>
            {removeError}
            {addError}
            <Table>
              <TableBody>
                {response.careEpisode.careEpisodeProviders.map((member, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>
                        <ProviderName providerId={member.provider.id} />
                      </TableCell>
                      <TableCell>{careEpisodeProviderRelationshipT(member.relationship, t)}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() =>
                            deleteProvider({ variables: { input: { careEpisodeProviderId: member.id } } })
                          }
                        >
                          <Remove />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  {/* Using explicit column widths here so that the cells don't jump around as we selected differently-
                    sized names in the dropdowns. Keeping the inputs in the table so that they have a clear relationship
                    with the data is nice enough that I'm okay jumping through a little hoop for it. */}
                  <TableCell width="40%">
                    <Stack spacing={1} alignItems="flex-start">
                      <FormControl sx={{ width: "100%" }}>
                        <ProviderSelectSingle
                          setValue={setSelectedProvider}
                          value={selectedProvider}
                          organizationId={activeOrg}
                        />
                      </FormControl>
                      <ProviderSelectorButton
                        variant="outlined"
                        size="small"
                        onClick={swapProviderListMode}
                        mode={providerListMode}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell width="40%" sx={{ verticalAlign: "top" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <EnumSelect
                        enumTrans={careEpisodeProviderRelationshipT}
                        optionsEnum={CareEpisodeProviderRelationship}
                        onChange={(value) => setSelectedRelationship(value)}
                        title={t("careEpisodes:fields.relationship")}
                        value={selectedRelationship}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    <IconButton onClick={addToCareTeam}>
                      <Add />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        );
      } else {
        return <ErrorMessage message={t("careEpisodes:careTeam.error")} />;
      }
    },
  });

  return (
    <ResponsiveDialog
      dialogWidth="50%"
      open={props.open}
      onClose={props.onClose}
      title={t("careEpisodes:careTeam.dialogTitle")}
      stopBackdropClose={false}
    >
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}
