declare module "@mui/material/styles/zIndex" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface ZIndex {
    // These are the ones Mui defines
    mobileStepper: number;
    speedDial: number;
    appBar: number;
    drawer: number;
    modal: number;
    snackbar: number;
    tooltip: number;
    fab: number;

    // These are additional ones we've defined
    stickyCard: number;
    beacon: number;
    dragAndDrop: number;
  }
}

export const zIndex = {
  // Copied from Mui defaults: https://github.com/mui/material-ui/blob/v5.15.14/packages/mui-material/src/styles/zIndex.js
  mobileStepper: 1000,
  fab: 1050,
  speedDial: 1050,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500,

  // Defined by looking at random z indexes we've set across the codebase
  stickyCard: 999,
  beacon: 2000,
  dragAndDrop: 2050,
};
