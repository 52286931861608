import { TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { useCollaborativeCareTaskDetailsLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectDeepCompare } from "Lib/Hooks";
import React, { ReactElement, ReactNode, useContext } from "react";
import { Maybe } from "seidr";

type TaskContextType = {
  maybeTask: Maybe<TaskDetails>;
  changeTask: (task: TaskDetails) => void;
};

export const CurrentTaskContext: React.Context<TaskContextType> = React.createContext({
  maybeTask: Maybe.fromNullable<TaskDetails>(null),
  changeTask: function (_task: TaskDetails) {},
});

export function useCurrentTask(): TaskDetails | null {
  const { maybeTask } = useContext(CurrentTaskContext);
  const task = maybeTask.caseOf({
    Nothing: () => {
      return null;
    },
    Just: (task) => task,
  });
  return task;
}
export function useChangeCurrentTask(): (task: TaskDetails) => void {
  const { changeTask } = useContext(CurrentTaskContext);
  return changeTask;
}

/**
 * The behavior of this context is much more complex than it appears, and it's not hard to have it slip into a render
 * loop or incorrectly caching data. Caveat scriptor.
 *
 * The reason this context exists at all is so that we can show task or time entry dialogs triggered by actions in the
 * app bar. Normally we mount a dialog component at the same level as components that trigger it to open, but actions
 * like "Save Progress" in the app bar both open a dialog and close the app bar. If we mounted the dialog in the app
 * bar with the button, it would also get unmounted just after being opened. So instead we mount it higher up in the
 * app and use this context to pass information to it from the app bar.
 *
 * This has the side effect that the task data stored here is no longer linked to the query hook that created it, and
 * so normal Apollo cache update strategies do nothing. Hence, most of the logic inside here is effectively
 * reimplementing something like refetching logic to update task data when it changes. This also interacts with the
 * code that updates placeholder tasks to be a real task, in a way that frankly I don't fully understand.
 *
 * I believe that we could probably simplify our lives overall by making this context store just a task id, and making
 * each place that subscribes to this fetch the data for that task themselves. If we use the Apollo cache correctly
 * that should resolve to the same number of queries, since all but one of them will just read from the cache instead
 * of making a new fetch. It would simplify the codebase because they would all be synched through the cache again
 * instead of having this side-refetch logic. Unfortunately, that's a nontrivial refactor that I don't have time for
 * at the present.
 *
 * There are two main ways that the data in here gets updated:
 *     * Something calls the changeTask callback that the context provides. This both updates the stored task and
 *       launches a new request to get the latest version of it. When this request comes back the useEffect updates the
 *       stored task with any new data.
 *     * For reasons I can't fully fathom, when a user saves changes to a placeholder task, this executes the useEffect
 *       with the new data without ever calling the changeTask callback. I can only presume that this is some sort of
 *       Apollo effect because we were subscribed to the query, but I didn't think lazy queries worked that way, so
 *       I'm not certain.
 *
 * There is a third, secret way that data flows through here, which is that when a users hits Save Progress on two
 * tasks without refreshing the page in between, we get an extra spurious rerender of this context with the first
 * task after all the renders with the second task. Why does this happen? Who knows! It's bad though, so we have to
 * guard against it.
 */
export function CurrentTaskProvider({ children }: { children: ReactNode }): ReactElement {
  const [task, changeTask] = React.useState<TaskDetails>();
  const [getDetails, { data }] = useCollaborativeCareTaskDetailsLazyQuery();

  useEffectDeepCompare(() => {
    // Without this id check the spurious old data rerender mentioned above will reset the task to a previous one. In
    // effect this is saying that if we get a request back for a task that isn't the one we think we should be storing
    // we should ignore it, because what the heck even is it.
    if (data?.collaborativeCareTask && data.collaborativeCareTask.id === task?.id) {
      changeTask(data.collaborativeCareTask);
    }
  }, [data]);

  const changeTaskAndFetch = (newTask: TaskDetails) => {
    changeTask(newTask);
    getDetails({ variables: { id: newTask.id } });
  };

  return (
    <CurrentTaskContext.Provider
      value={{ maybeTask: Maybe.fromNullable<TaskDetails>(task), changeTask: changeTaskAndFetch }}
    >
      {children}
    </CurrentTaskContext.Provider>
  );
}

export default CurrentTaskContext;
