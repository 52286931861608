import { styled, Theme, Typography, TypographyTypeMap } from "@mui/material";
import { OverridableTypeMap, OverrideProps, DefaultComponentProps } from "@mui/material/OverridableComponent";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { color } from "Shared/Severity/SeverityThresholdClass";

import type { JSX } from "react";

// There is a bug with the typing on styled typography
// https://github.com/mui-org/material-ui/issues/13921#issuecomment-790820253
type OverridableComponentWithExtraProps<M extends OverridableTypeMap, ExtraProps> = {
  <C extends React.ElementType>(
    props: { component: C; theme?: Theme } & OverrideProps<M, C> & ExtraProps
  ): JSX.Element;
  (props: { theme?: Theme } & DefaultComponentProps<M> & ExtraProps): JSX.Element;
};

type OverridableComponent<M extends OverridableTypeMap> = {
  <C extends React.ElementType>(props: { component: C; theme?: Theme } & OverrideProps<M, C>): JSX.Element;
  (props: { theme?: Theme } & DefaultComponentProps<M>): JSX.Element;
};

type SeverityThresholdTypographyPropsType = {
  thresholdClass: ScaleThresholdClass;
};

function threshholdTextStyle(thresholdClass: ScaleThresholdClass, theme: Theme): { color: string } {
  return {
    color: color(thresholdClass, theme).text,
  };
}

const SeverityThresholdTypography = styled(Typography)<SeverityThresholdTypographyPropsType>(
  ({ theme, thresholdClass }) => threshholdTextStyle(thresholdClass, theme)
) as OverridableComponentWithExtraProps<TypographyTypeMap, SeverityThresholdTypographyPropsType>;

const GoodTextTypography = styled(Typography)(({ theme }) =>
  threshholdTextStyle(ScaleThresholdClass.NONE, theme)
) as OverridableComponent<TypographyTypeMap>;

const MediumTextTypography = styled(Typography)(({ theme }) =>
  threshholdTextStyle(ScaleThresholdClass.MODERATE, theme)
) as OverridableComponent<TypographyTypeMap>;

const BadTextTypography = styled(Typography)(({ theme }) =>
  threshholdTextStyle(ScaleThresholdClass.SEVERE, theme)
) as OverridableComponent<TypographyTypeMap>;

export { GoodTextTypography, BadTextTypography, MediumTextTypography, SeverityThresholdTypography };
