import { styled } from "@mui/material/styles";
import { ScoreCategoryColors } from "Style/Theme/palette";

export const HoverCircleSize = {
  small: {
    radius: 5,
    outline: 1,
  },
  large: {
    radius: 10,
    outline: 2,
  },
};

export const HoverCircle = styled("circle")<{ swatch: ScoreCategoryColors }>(({ swatch }) => {
  return {
    cursor: "pointer",
    r: HoverCircleSize.small.radius - HoverCircleSize.small.outline,
    strokeWidth: HoverCircleSize.small.outline,
    fill: swatch.primary,
    stroke: swatch.secondaryOffset,

    transition: "r 100ms, strokeWidth 100ms, fill 100ms, stroke 100ms",

    "&:hover": {
      r: HoverCircleSize.large.radius - HoverCircleSize.large.outline,
      strokeWidth: HoverCircleSize.large.outline,
      fill: swatch.secondaryOffset,
      stroke: swatch.primary,
    },
  };
});
