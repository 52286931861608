import React, { ReactNode, ReactElement } from "react";
import { Box, Grid, Typography } from "@mui/material";

type Props = {
  title: string;
  children?: ReactNode;
  titleAction?: ReactNode;
};

function Header(props: Props): ReactElement {
  const { title, children } = props;

  let subHeader = null;

  if (children) {
    subHeader = (
      <Grid item>
        <Box sx={{ mt: 1 }}>{children}</Box>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" justifyContent="space-between" spacing={3}>
      <Grid item>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
          </Grid>
          <Grid item>{props.titleAction}</Grid>
        </Grid>
        {subHeader}
      </Grid>
    </Grid>
  );
}

export default Header;
