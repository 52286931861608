import React, { ReactNode, ReactElement } from "react";
import { styled } from "@mui/material/styles";
import SumType from "sums-up";

class ChipSize extends SumType<{
  BodyChipSize: [];
  HeaderChipSize: [];
}> {
  public static BodyChipSize() {
    return new ChipSize("BodyChipSize");
  }
  public static HeaderChipSize() {
    return new ChipSize("HeaderChipSize");
  }
}

type StyleOptions = {
  styleOptions: {
    backgroundColor?: string;
    color?: string;
    width?: number;
    size?: ChipSize;
    border?: string;
  };
};

// Excludes styleOptions props from forwarding to silence warning about invalid HTML attributes.
const StyledChip = styled("span", { shouldForwardProp: (prop) => prop !== "styleOptions" })<StyleOptions>(
  ({ theme, onClick, styleOptions }) => {
    const clickableStyles = onClick
      ? {
          cursor: "pointer",
          transition: "all 0.2s",

          "&:hover": {
            boxShadow: "inset 0 0 0 200px rgba(0,0,0,0.04)",
          },
        }
      : {};

    const backgroundColorStyle = styleOptions.backgroundColor
      ? { backgroundColor: styleOptions.backgroundColor }
      : {};
    const colorStyle = styleOptions.color ? { color: styleOptions.color } : {};
    const widthStyle = styleOptions.width ? { width: styleOptions.width } : {};

    const size = styleOptions.size || ChipSize.BodyChipSize();
    const sizeStyle = size.caseOf({
      BodyChipSize: () => ({
        height: theme.spacing(1.5),
        padding: "0 0.5rem",
        fontSize: theme.typography.subtitle1.fontSize,
        lineHeight: "1em",
        borderRadius: theme.spacing(0.188),
      }),
      HeaderChipSize: () => ({
        height: theme.spacing(2.25),
        padding: "0.5rem 1rem",
        fontSize: theme.typography.h1.fontSize,
        lineHeight: "1em",
        borderRadius: theme.spacing(0.3),
      }),
    });

    return {
      position: "relative",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      whiteSpace: "nowrap",
      fontWeight: 600,
      border: styleOptions.border,
      ...clickableStyles,
      ...backgroundColorStyle,
      ...colorStyle,
      ...widthStyle,
      ...sizeStyle,
    };
  }
);

type ChipBaseProps = {
  children: ReactNode;
  backgroundColor?: string;
  color?: string;
  width?: number;
  size?: ChipSize;
  border?: string;
  onClick?: () => void;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ChipBase(props: ChipBaseProps): ReactElement {
  const { children, color, backgroundColor, width, onClick, size, border } = props;
  const style = { backgroundColor, color, width, size, border };

  const chip = (
    <StyledChip data-testid="chip" style={style} onClick={onClick} styleOptions={style}>
      {children}
    </StyledChip>
  );
  return props.chipTooltip ? props.chipTooltip(chip) : chip;
}

type ChipProps = {
  label: string;
  backgroundColor?: string;
  labelColor?: string;
  width?: number;
  size?: ChipSize;
  onClick?: () => void;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function Chip(props: ChipProps): ReactElement {
  const { label, backgroundColor, labelColor, width, size, onClick, chipTooltip } = props;

  return (
    <ChipBase
      backgroundColor={backgroundColor}
      color={labelColor}
      width={width}
      size={size}
      onClick={onClick}
      chipTooltip={chipTooltip}
    >
      {label}
    </ChipBase>
  );
}

export { ChipBase, Chip, ChipSize };
