import React, { ReactElement } from "react";

type DefaultErrorProps = { error?: string };

function DefaultError(props: DefaultErrorProps): ReactElement {
  return (
    <>
      <h2>We apologize but there was an error loading this data.</h2>
      {props.error && <h3>{props.error}</h3>}
    </>
  );
}

export default DefaultError;
