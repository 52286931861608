import { apolloMutationHookWrapper } from "Api/GraphQL";
import { InboxMessagesFindingDatagram } from "CollaborativeCare/CareManagerDashboard/InboxMessagesFindings";
import { useActOnInboxMessageMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type DismissActionProps = {
  finding: InboxMessagesFindingDatagram;
};
export function DismissAction(props: DismissActionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const [dismiss, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.actOnInboxMessage,
    useActOnInboxMessageMutation({
      variables: {
        input: {
          action: {
            dismiss: {
              inboxMessageId: props.finding.inboxMessage.id,
            },
          },
        },
      },
      refetchQueries: refetchQueries("findings"),
    })
  );

  const onDismiss = () => {
    dismiss();
  };

  const showSpinner = remoteData.kind === "Loading";
  const disabled = showSpinner;

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="error"
      onClick={onDismiss}
      showSpinner={showSpinner}
      disabled={disabled}
    >
      {t("collaborativeCare:findings.actions.dismiss")}
    </ButtonWithSpinner>
  );
}
