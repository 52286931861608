import { Stack, Typography, styled } from "@mui/material";
import { useEffectOnce } from "Lib/Hooks";
import React, { ReactElement, ReactNode } from "react";
import { XYCoord, useDrag, useDragLayer, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";

type DnDSortableListProps<TItem> = {
  items: ReadonlyArray<TItem>;
  // The itemType string controls what kinds of draggable objects can be dropped onto what kinds of drop targets. We
  // also rely on checking it to ensure that we can cast the item payload into a TItem so that we can do anything with
  // it, so it's important to pass distinct itemType strings here when you're using different kinds of data to avoid
  // having everything fall apart.
  itemType: string;
  getId: (item: TItem) => string;
  getPosition: (item: TItem) => number;
  renderItem: (item: TItem, dragging: boolean) => ReactNode;
  renderDragPreview: (item: TItem) => ReactNode;
  moveWhileDragging: (item: TItem, position: number) => void;
  // Note that we aren't getting access to the result of the mutation here - if something goes wrong the app will
  // be in an incorrect state until the user refreshes. There isn't anywhere to show an error, or anything the user can
  // do about it though, so I think that's okay?
  moveOnDrop: (item: TItem, position: number) => void;
  emptyMessage?: string;
};

/**
 * A list of items that can be sorted by dragging and dropping them into position.
 */
export function DnDSortableList<TItem>(props: DnDSortableListProps<TItem>): ReactElement {
  const sortedItems = [...props.items];
  sortedItems.sort((a, b) => props.getPosition(a) - props.getPosition(b));

  const emptyMessage = (
    <Stack direction="row" alignItems="center" justifyContent="center" marginTop="3rem">
      <Typography key="no-findings" variant="h3" textAlign="center">
        {props.emptyMessage}
      </Typography>
    </Stack>
  );

  return (
    <Stack direction="column" spacing={0}>
      <CustomDragLayer itemType={props.itemType} renderDragPreview={props.renderDragPreview} />
      {sortedItems.map((item) => (
        <DnDSortableListItem
          // This key prop is surprisingly critical - as we move stuff around in the list React needs to know which
          // component is which even though they're swapping places. A lot of lists in the app we can get away with
          // using the index of the item as the key, but here that specifically blows up in our face. We have to use
          // a unique identifier per item.
          key={props.getId(item)}
          itemData={item}
          itemType={props.itemType}
          getPosition={props.getPosition}
          renderItem={props.renderItem}
          moveWhileDragging={props.moveWhileDragging}
          moveOnDrop={props.moveOnDrop}
        />
      ))}
      {sortedItems.length === 0 && props.emptyMessage ? emptyMessage : null}
    </Stack>
  );
}

type CustomDragLayerProps<TItem> = {
  itemType: string;
  renderDragPreview: (item: TItem) => ReactNode;
};

function CustomDragLayer<TItem>(props: CustomDragLayerProps<TItem>): ReactElement | null {
  const dragState = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!dragState.isDragging || dragState.itemType !== props.itemType) {
    return null;
  }

  // This cast is safe because of the itemType guard above, this prevents us from getting drag data from other drag
  // sources that have other payload types.
  const itemData = dragState.item as TItem;

  return (
    <CustomDragLayerContainer>
      <CustomDragPreviewPosition currentOffset={dragState.currentOffset}>
        {props.renderDragPreview(itemData)}
      </CustomDragPreviewPosition>
    </CustomDragLayerContainer>
  );
}

/**
 * Box that covers the whole viewport so we can position items being dragged inside of it.
 */
const CustomDragLayerContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  pointerEvents: "none",
  zIndex: theme.zIndex.dragAndDrop,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
}));

type CustomDragPreviewPositionProps = {
  currentOffset: XYCoord | null;
};

/**
 * Positions the drag preview within the drag layer container.
 * @param props
 * @returns
 */
function CustomDragPreviewPosition(
  props: React.PropsWithChildren<CustomDragPreviewPositionProps>
): ReactElement | null {
  if (!props.currentOffset) {
    return null;
  }

  return (
    <div style={{ transform: `translate(${props.currentOffset.x}px, ${props.currentOffset.y}px)` }}>
      {props.children}
    </div>
  );
}

type DnDSortableListItemProps<TItem> = {
  itemData: TItem;
  itemType: string;
  getPosition: (item: TItem) => number;
  renderItem: (item: TItem, dragging: boolean) => ReactNode;
  moveWhileDragging: (item: TItem, position: number) => void;
  moveOnDrop: (item: TItem, position: number) => void;
};

// How many pixels from the top or bottom of the viewport will we start automatically scrolling during a drag operation.
const DRAG_SCROLL_ACTIVATION_WINDOW = 100;
// How many pixels will we scroll up or down each time the automatic scrolling is activated. These values chosed by the
// scientific method of me screwing around on my phone until I found ones that felt good.
const DRAG_SCROLL_SPEED = 25;

/**
 * A single element in a drag-sortable list. This is where most of the work of the drag and drop logic actually lives,
 * and as such does some complex things.
 *
 * First, note that the documentation for react-dnd will call the useDrag and useDrop hooks slightly differently -
 * rather than passing an object into the hook they pass a function that returns an object. For reasons I don't fully
 * understand, in my experience this leads to strange bugs where the drag data caches the original version of the state
 * even as the props change, whereas if I call it this way it all works how I expect.
 *
 * Second, these list items are both draggable objects and drop targets. Effectively what happens here is that as we
 * drag an item around we look for when it crosses the item above or below it, and move it up or down in the list, but
 * only in the local client's data, not on the server. Finally, when we drop it the item has been moved into the right
 * position already so we can fire a mutation to save that to the server. It's the responsibility of the caller of
 * DnDSortableList to do the state updates correctly, but as long as you do the result of the mutation should be a no-op
 * on the client.
 *
 * Third, we don't want to have gaps between elements in the stack, because that will leave us with space where we
 * aren't over any item in the list and so the drop will stop working. Instead, having spacing=0 on the stack and add
 * padding to the top of each list item so that the items are visually separated but logically adjacent.
 *
 * Some of the logic here, in particular the position calculations in hover, adapted from this example from the
 * react-dnd documentation: https://codesandbox.io/p/sandbox/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/04-sortable/simple?file=%2Fsrc%2FCard.tsx
 */
function DnDSortableListItem<TItem>(props: DnDSortableListItemProps<TItem>): ReactElement {
  const ref = React.useRef<HTMLDivElement>(null);

  const [dragState, dragRef, setDragPreview] = useDrag({
    type: props.itemType,
    item: props.itemData,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Clear out the default HTML5 drag preview so we can use the custom one we define above (CustomDragLayer).
  useEffectOnce(() => {
    setDragPreview(getEmptyImage(), { captureDraggingState: true });
  });

  const [_dropState, dropRef] = useDrop({
    accept: props.itemType,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: (item) => {
      const itemData = item as TItem;
      // Get the position off the item being dropped - the assumption here is that it's already been moved locally into
      // the right position, see comment above.
      const position = props.getPosition(itemData);
      // As we move items around locally (see the hover function) we assign them fractional positions to ensure that
      // they lie between the elements we're moving through. Round that back up to an integer for the server.
      const serverPosition = Math.ceil(position);
      props.moveOnDrop(itemData, serverPosition);
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      const itemData = item as TItem;

      const clientOffset = monitor.getClientOffset();
      const viewportHeight = window.innerHeight;

      if (!clientOffset) {
        return;
      }

      if (clientOffset.y >= viewportHeight - DRAG_SCROLL_ACTIVATION_WINDOW) {
        // There's another way to call this method: scrollBy({ top: 25, behavior: 'smooth' }) and you might think "oh
        // great, that will make the scrolling less choppy" but you would be wrong, instead it makes the scrolling not
        // work at all.
        window.scrollBy(0, DRAG_SCROLL_SPEED);
      } else if (clientOffset.y <= DRAG_SCROLL_ACTIVATION_WINDOW) {
        window.scrollBy(0, -DRAG_SCROLL_SPEED);
      }

      const dragIndex = props.getPosition(itemData);
      const hoverIndex = props.getPosition(props.itemData);

      if (dragIndex === hoverIndex) {
        return;
      }

      // This calculation moves the item up or down whenever it crosses the midline of the element above or below it.
      // I actually like trello's behavior slightly more (move the item when up when crosses the bottom of the item
      // above it and move down when it crosses the top of the item below it) but that logic is harder to implement
      // so I've stuck with this. In theory you can get that by just tweaking these calculations a little.
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // The item being dragged is above the item we're hovering over and the mouse is above the midline.
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // The item being dragged is below the item we're hovering and the mouse is below the midline.
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // The new position is between the item being hovered and the item below (if moving down) or above (if moving up).
      const newPosition = dragIndex < hoverIndex ? hoverIndex + 0.5 : hoverIndex - 0.5;

      props.moveWhileDragging(itemData, newPosition);
    },
  });

  // Stack all the refs together so we can apply them to a single element.
  dragRef(dropRef(ref));

  return (
    <div ref={ref} style={{ paddingTop: "1rem" }}>
      {props.renderItem(props.itemData, dragState.isDragging)}
    </div>
  );
}
