import { CompareGraphSummary } from "FeedbackReport/OverviewPane/Compare/CompareGraphs";
import React, { ReactElement } from "react";
import { CompletionCompareGraph } from "./CompletionCompareGraph";
import { SeverityCompareGraph } from "./SeverityCompareGraph";
import * as NEL from "Lib/NonEmptyList";
import { quantize } from "Lib/DateQuantizedData";
import { CategoricalCompareGraph } from "./CategoricalCompareGraph";

type CompareGraphProps = {
  width: number;
  height: number;
  target: CompareGraphSummary;
  dates: NEL.NonEmptyList<Date>;
};

export function CompareGraph(props: CompareGraphProps): ReactElement {
  return props.target.graphData.caseOf({
    Severity: ({ scale, values, valueRange, routeToScore }) => {
      return (
        <SeverityCompareGraph
          width={props.width}
          height={props.height}
          history={values}
          valueRange={valueRange}
          scale={scale}
          valueRoute={routeToScore}
          dates={props.dates}
        />
      );
    },
    Categorical: ({ values, routeToScore }) => {
      return (
        <CategoricalCompareGraph
          width={props.width}
          height={props.height}
          history={values}
          valueRoute={routeToScore}
          dates={props.dates}
        />
      );
    },
    Completion: ({ completions, routeToScore }) => {
      return (
        <CompletionCompareGraph
          width={props.width}
          height={props.height}
          completionHistory={quantize(props.dates, completions)}
          completionRoute={routeToScore}
        />
      );
    },
  });
}
