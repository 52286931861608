import { Link as RouterLink, useParams } from "react-router-dom";
import {
  InstituteAppointmentQuery,
  useInstituteAppointmentQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import DetailsLayout from "../DetailsLayout";
import { Dig } from "type-utils";
import { Maybe } from "seidr";
import { Link } from "@mui/material";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import AthenaPushbackDetails from "./AthenaPushbackDetails";

function InstituteAppointment(props: {
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const params = useParams<{ appointmentId?: string }>();
  return fromNullableString<"Appointment">(params.appointmentId).caseOf({
    Ok: (appointmentId) => (
      <DetailsLayout
        queryResult={useInstituteAppointmentQuery({
          variables: {
            appointmentId: appointmentId,
          },
        })}
        setSubCrumbs={props.setSubCrumbs}
        subCrumbs={(x) => [
          { text: "Patients", path: "/patients" },
          { text: x.patient.name, path: `/patients/${x.patient.id}` },
          {
            text: "Appointments",
            path: `/patients/${x.patient.id}/appointments`,
          },
          { text: x.id.toString(), path: `/appointments/${x.id}` },
        ]}
        headerContents={(_x) => <div></div>}
        browserTitle={() => "Appointment"}
        titleAction={(x) => <div>({x.id.toString()})</div>}
        resultFetcher={(x: InstituteAppointmentQuery) => x.schedulingAppointment}
        title={(_x) => "Appointment"}
        overviewFields={(x) => overviewFields(x)}
        integrationsDataSourceRecords={(p) => p.integrationsDataSourceRecords}
        tabs={(apt) => [
          {
            tabLabel: "Athena Pushback",
            path: "pushback",
            component: <AthenaPushbackDetails appointmentId={apt.id} />,
          },
        ]}
      />
    ),

    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

function overviewFields(dat: NonNullable<Dig<InstituteAppointmentQuery, ["schedulingAppointment"]>>) {
  return [
    { name: "id", value: dat.id.toString() },
    {
      name: "Patient",
      value: (
        <Link component={RouterLink} to={`../../patients/${dat.patient.id}`}>
          {dat.patient.name}
        </Link>
      ),
    },
    {
      name: "Provider",
      value: Maybe.fromNullable(dat.provider)
        .map((x) => (
          <Link component={RouterLink} to={`../../providers/${x.id}`}>
            {x.name}
          </Link>
        ))
        .getOrElse(null),
    },
    { name: "Session ID", value: dat.patientSession?.id.toString() },
    { name: "Session Number", value: dat.patientSession?.sessionNumber },
    {
      name: "Appointment Care Episode",
      value: Maybe.fromNullable(dat.careEpisode)
        .map((x) => (
          <Link component={RouterLink} to={`../../careEpisodes/${x.id}`}>
            {x.name}
          </Link>
        ))
        .getOrElse(null),
    },
    {
      name: "Session Care Episode",
      value: Maybe.fromNullable(dat.patientSession?.careEpisode)
        .map((x) => (
          <Link component={RouterLink} to={`../../careEpisodes/${x.id}`}>
            {x.name}
          </Link>
        ))
        .getOrElse(null),
    },
    { name: "Source", value: dat.source },
    { name: "Start Date", value: dat.startDate?.toLocaleString() },
    { name: "End Date", value: dat.endDate?.toLocaleString() },
    { name: "Minutes Durration", value: dat.minutesDuration },
    { name: "Comment", value: dat.comment },
    { name: "Status", value: dat.status },
  ];
}

export default InstituteAppointment;
