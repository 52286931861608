import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { WithFeature, useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { CareEpisode } from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId, TreatmentServiceId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Form, FormOverlay, useForm, useWrappedField } from "Shared/Form";
import OrganizationTree from "Shared/OrganizationTree";
import TreatmentServiceTree from "Shared/TreatmentServiceTree";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { WithOptionalFields } from "type-utils";

type CareEpisodeDetails = Pick<CareEpisode, "periodStart"> & {
  organizationId: OrganizationId;
  treatmentServiceId?: TreatmentServiceId | null;
};

type Defaults = WithOptionalFields<CareEpisodeDetails, "organizationId" | "periodStart">;

type CareEpisodeFormProps<ResponseType> = {
  mode: "create" | "edit";
  defaults: Defaults;
  onSuccess?: (response: ResponseType) => void;
  remoteData: MutationRemoteDataResult<ResponseType>;
  mutation: (fields: CareEpisodeDetails) => void;
  errorMessage: string;
};

export function CareEpisodeForm<ResponseType>(props: CareEpisodeFormProps<ResponseType>): ReactElement {
  const { t } = useTranslation(["careEpisodes", "common"]);
  const treatmentServiceIsEnabled = useWithFeatureEnabled("enableTreatmentServices");

  const fields = {
    periodStart: useWrappedField({ default: props.defaults.periodStart, required: true }),
    organizationId: useWrappedField({ default: props.defaults.organizationId, required: true }),
    treatmentServiceId: useWrappedField({
      default: props.defaults.treatmentServiceId,
      required: treatmentServiceIsEnabled,
    }),
  };

  const form = useForm({
    id: "edit-care-episode-form",
    fields: fields,
    onSuccess: props.onSuccess,
    remoteData: props.remoteData,
    submit: () => {
      props.mutation({
        organizationId: assertNonNull(fields.organizationId.value),
        periodStart: assertNonNull(fields.periodStart.value),
        treatmentServiceId: fields.treatmentServiceId.value,
      });
    },
  });

  return (
    <>
      <DialogContent>
        <Form onSubmit={form.onSubmit} id={form.id}>
          <FormOverlay response={props.remoteData} errorMessage={props.errorMessage} />
          <Stack direction="column" spacing={1} sx={{ paddingTop: "1rem" }}>
            <DatePicker
              label={t("careEpisodes:fields.periodStart")}
              value={fields.periodStart.value || new Date()}
              onChange={fields.periodStart.onChange}
            />
            <Typography variant="h2">{t("careEpisodes:fields.organization")}</Typography>
            <OrganizationTree
              selectedOrg={fields.organizationId.value || null}
              setSelectedOrg={fields.organizationId.onChange}
            />
            <WithFeature feature="enableTreatmentServices">
              <Typography variant="h2">{t("careEpisodes:fields.treatmentService")}</Typography>
              <TreatmentServiceTree
                selectedTreatmentService={fields.treatmentServiceId.value || null}
                setSelectedTreatmentService={fields.treatmentServiceId.onChange}
              />
            </WithFeature>
          </Stack>
          <ul>
            <li>{t("careEpisodes:editDialog.dateExplanation")}</li>
            <li>
              {treatmentServiceIsEnabled
                ? t("careEpisodes:editDialog.organizationAndTreatmentServiceExplanation")
                : t("careEpisodes:editDialog.organizationExplanation")}
            </li>
          </ul>
        </Form>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          type="submit"
          form={form.id}
          showSpinner={form.showSpinner}
          disabled={form.disableSubmit}
        >
          {t(props.mode === "create" ? "careEpisodes:editDialog.create" : "careEpisodes:editDialog.edit")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
