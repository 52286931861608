import { Routes, Route } from "react-router-dom";
import React, { ReactElement } from "react";
import { CareEpisodeDetailsPage } from "./CareEpisodeDetails";
import { CareEpisodeEditPage } from "./CareEpisodeEdit";

export function CareEpisodeDetailsPageRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<CareEpisodeDetailsPage />} path="/" />
      <Route element={<CareEpisodeEditPage />} path="/edit" />
    </Routes>
  );
}
