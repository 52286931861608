import React from "react";

import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  MetricParams,
  MetricSummaryData,
  Scale,
  ScaleScorer,
  ScaleScorersConfigurationQuery,
  useOutcomesTransientMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";
import { metricLabelGeneratorFromParams } from "./OutcomesMetricHelpers";
import { ensureMetricParams } from "./OutcomesFormHelpers";
import { EligibleCountColumn, MetricValueColumn } from "./OutcomesMetricBreakdown";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import { scaleMediumName } from "Shared/Scale/Scale";

type ScaleScorerConfig = Pick<ScaleScorer, "id"> & { scale: Pick<Scale, "name" | "shortname" | "nanoname"> };

type ScaleBreakdownTableProps = {
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  metricParams: MetricParams;
  scaleScorerConfigData: ReadonlyArray<ScaleScorerConfig>;
};

function ScaleBreakdownTable(props: ScaleBreakdownTableProps) {
  const { t } = useTranslation(["outcomes"]);

  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: { node: props.entityTreeNode },
    period: {
      dateRange: {
        min: props.startDate,
        max: props.endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };

  // Because of limitations on react hooks, you need to be very careful with how this is mounted, as it is going to issue an arbitrary numbers
  // of queries.
  // In theory, each instantiation should only issue the same number, because the component will be unmounted if the number of scales changes.
  const rowsWithData = props.scaleScorerConfigData.map((scorer) => {
    const { remoteData } = apolloQueryHookWrapper(
      useOutcomesTransientMetricSummaryDataQuery({
        variables: {
          drilldownMetric: drilldownMetric,
          transientMetricData: ensureMetricParams(props.metricParams),
          scaleScorerId: scorer.id,
        },
      })
    );
    return { scorer: scorer, data: remoteData.map((result) => result.outcomesTransientMetricSummaryData) };
  });

  const labelGenerator = metricLabelGeneratorFromParams(props.metricParams, t);

  const columns: Array<
    GridColDef<{
      scorer: ScaleScorerConfig;
      data: RemoteData<ApolloError, Pick<MetricSummaryData, "value" | "eligibleRows" | "triggerRows">>;
    }>
  > = [
    {
      field: "scale",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.scale"),
      sortable: true,
      flex: 2,
      valueGetter: (_value, row) => scaleMediumName(row.scorer.scale),
    },
    {
      field: "value",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.result"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => row.data.map((data) => data.value),
      renderCell: (params) => {
        return params.row.data.caseOf({
          Success: (data) => {
            return (
              <MetricValueColumn
                timeBasedAggregationType={props.metricParams.payload.timeBased?.timeBasedAggregationType}
                labelGenerator={labelGenerator}
                value={data.value}
              />
            );
          },
          _: () => {
            return <Skeleton width={"150px"} />;
          },
        });
      },
    },
    {
      field: "eligibleRows",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.eligibleRows"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => row.data.map((row) => row.eligibleRows),
      renderCell: (params) => {
        return params.row.data.caseOf({
          Success: (data) => {
            return <EligibleCountColumn value={data.eligibleRows} />;
          },
          _: () => {
            return <Skeleton width={"50px"} />;
          },
        });
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rowsWithData}
      getRowId={(row) => {
        return row.scorer.id.toString();
      }}
      hideFooterPagination={true}
      sortingMode={"client"}
    />
  );
}

type OutcomesTransientMetricMultiScaleBreakdownProps = {
  entityTreeNode: EntityTreeNodeParams | null;
  startDate: Date;
  endDate: Date;
  metricParams: MetricParams | null;
  scaleScorerConfigData: RemoteData<ApolloError, ScaleScorersConfigurationQuery>;
};

function OutcomesTransientMetricMultiScaleBreakdown(props: OutcomesTransientMetricMultiScaleBreakdownProps) {
  const { t } = useTranslation(["outcomes"]);
  const { metricParams, entityTreeNode } = props;

  if (metricParams === null || entityTreeNode === null) {
    return (
      <Card>
        <CardHeader title={t("outcomes:multiScaleBreakdown.header")} />
        <CardContent>{t("outcomes:multiScaleBreakdown.invalidParams")}</CardContent>
      </Card>
    );
  }

  const content = props.scaleScorerConfigData.caseOf({
    Success: (data) => {
      // We need the breakdown table to unload and reload whenever scales change as otherwise
      // the hooks system goes mad
      const key = data.assessmentScaleScorers?.nodes.map((x) => x.id).join("-") || "none";
      return (
        <ScaleBreakdownTable
          {...props}
          entityTreeNode={entityTreeNode}
          metricParams={metricParams}
          scaleScorerConfigData={data.assessmentScaleScorers?.nodes || []}
          key={key}
        />
      );
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <>{t("outcomes:multiScaleBreakdown.selectAScale")}</>,
  });
  return (
    <Card>
      <CardHeader title={t("outcomes:multiScaleBreakdown.header")} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          {content}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OutcomesTransientMetricMultiScaleBreakdown;
