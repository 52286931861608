import { Tab, Box, Tabs } from "@mui/material";
import { Link, matchPath, PathMatch, Route, Routes, useLocation } from "react-router-dom";
import { default as React, ReactElement } from "react";

function TabNav({
  baseRoute,
  routes,
}: {
  routes: Array<{
    tabLabel: string;
    path: string;
    component: ReactElement;
  }>;
  baseRoute: {
    tabLabel: string;
    component: ReactElement;
  };
}): ReactElement {
  const routeMatch = useRouteMatch(
    routes.map((x) => x.path),
    useLocation().pathname
  );
  const currentTab = routeMatch?.pattern.path || "/";

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab} aria-label="basic tabs example">
          {[{ ...baseRoute, path: "" }].concat(...routes).map((tab) => (
            <Tab
              key={`${tab.tabLabel}-${tab.path}`}
              label={tab.tabLabel}
              to={tab.path}
              value={tab.path}
              component={Link}
            />
          ))}
        </Tabs>
      </Box>
      <div role="tabpanel">
        <Box sx={{ p: 3 }}>
          <Routes>
            {routes.map((route) => (
              <Route path={route.path} element={route.component} />
            ))}
            <Route path="/" element={baseRoute.component} />
          </Routes>
        </Box>
      </div>
    </Box>
  );
}

function useRouteMatch(patterns: ReadonlyArray<string>, pathname: string): PathMatch | null {
  for (const pattern of patterns) {
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

export default TabNav;
