import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { TriggerBasedAggregationType } from "GeneratedGraphQL/SchemaAndOperations";
import { SectionHelpText } from "Outcomes/CreateTriggerBasedMetricForm";
import { enumFromStringValue } from "Shared/Enum";
import { FieldMethods } from "Shared/Form";
import React from "react";
import { useTranslation } from "react-i18next";

type TriggerBasedAggregationForm = {
  triggerBasedAggregationType: FieldMethods<TriggerBasedAggregationType, TriggerBasedAggregationType>;
};

export default function TriggerBasedAggregationForm(props: TriggerBasedAggregationForm) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const enumValue = enumFromStringValue<TriggerBasedAggregationType>(TriggerBasedAggregationType, value);
    props.triggerBasedAggregationType.onChange(
      enumValue || TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START
    );
  };

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.triggerBased.aggregation.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.triggerBased.aggregation.helperText")} />
      <RadioGroup value={props.triggerBasedAggregationType.value} onChange={onChange}>
        <FormControlLabel
          value={TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START}
          control={<Radio />}
          label={t("outcomes:create.triggerBased.aggregation.averageDays")}
        />
      </RadioGroup>
    </FormControl>
  );
}
