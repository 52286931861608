import React, { ReactElement, useState } from "react";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";
import { useEffectOnce } from "Lib/Hooks";
import { Typography } from "@mui/material";

/**
 * We are not sure if we are getting exceptions when the app crashes.
 * This page will intentioally call an exception and can be used to check
 * our sentry error reporting is working
 */
export function HardCrashPage(): ReactElement {
  useEffectOnce(() => {
    useState("break things");
  });
  return (
    <Page browserTitle="Hard Crash Test">
      <PageHeader title="Hard Crash Test" />
      <Typography>This page generates a hard error that should be sent to sentry</Typography>
    </Page>
  );
}
