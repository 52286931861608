import { Assessment } from "@mui/icons-material";
import { Button, Card, CardContent, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { PatientSession, Report } from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import KeycodeLoginDialog from "Providers/Dashboard/KeycodeLoginDialog";
import { ContextualReportButton } from "Shared/ContextualReportLink";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentDetails } from "./AppointmentDetails";
import { CancelAppointmentButton } from "./CancelAppointmentButton";
import { DeleteAppointmentButton } from "./DeleteAppointmentButton";

export function AppointmentActionsCard(props: {
  appointment: AppointmentDetails;
  patientId: PatientId;
  patientSession:
    | (Pick<PatientSession, "id" | "isReportAvailable"> & { assessmentReport: Pick<Report, "id"> | null })
    | null;
}): ReactElement {
  return (
    <Card>
      <CardContent>
        <Grid container columns={6} spacing={1}>
          <Grid item lg={6} xs={6}>
            <Stack direction="column" spacing={0.5}>
              <AppointmentList {...props} />
              <MeasurementList {...props} appointmentId={props.appointment.id} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

type ActionListSubheaderProps = {
  title: string;
};
function ActionListSubheader(props: ActionListSubheaderProps): ReactElement {
  return (
    <Typography variant="h2" marginBottom="0.65em">
      {props.title}
    </Typography>
  );
}

function AppointmentList(props: { appointment: AppointmentDetails; patientId: PatientId }): ReactElement {
  const { t } = useTranslation(["patients"]);

  return (
    <List subheader={<ActionListSubheader title={t("patients:appointments.actions.appointment")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          <CancelAppointmentButton appointment={props.appointment} />
          <DeleteAppointmentButton patientId={props.patientId} appointment={props.appointment} />
        </Stack>
      </ListItem>
    </List>
  );
}

function MeasurementList(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  patientSession:
    | (Pick<PatientSession, "id" | "isReportAvailable"> & { assessmentReport: Pick<Report, "id"> | null })
    | null;
}): ReactElement {
  const { t } = useTranslation(["patients"]);

  const [showKeycodeLogin, setShowKeycodeLogin] = React.useState<boolean>(false);

  const keycodeLoginButton = props.patientSession?.id ? (
    <Button variant="contained" color="secondary" onClick={() => setShowKeycodeLogin(true)}>
      {t("patients:appointments.actions.keycodeLogin")}
    </Button>
  ) : (
    <Button variant="contained" color="secondary" disabled>
      {t("patients:appointments.actions.keycodeLogin")}
    </Button>
  );

  const reportLink = props.patientSession?.isReportAvailable ? (
    <ContextualReportButton
      patient={props.patientId}
      report={props.patientSession.assessmentReport?.id}
      variant="contained"
      color="secondary"
    >
      <Assessment />
      {t("patients:patientDetails.actions.feedbackReport")}
    </ContextualReportButton>
  ) : (
    <Button variant="contained" disabled={true}>
      {t("patients:patientDetails.actions.noReport")}
    </Button>
  );

  let keycodeLoginDialog = null;

  if (props.patientSession?.id) {
    keycodeLoginDialog = (
      <KeycodeLoginDialog
        open={showKeycodeLogin}
        onClose={() => setShowKeycodeLogin(false)}
        patientSessionId={props.patientSession.id}
      />
    );
  }

  return (
    <List subheader={<ActionListSubheader title={t("patients:appointments.actions.measurement")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          {reportLink}
          {keycodeLoginDialog}
          {keycodeLoginButton}
        </Stack>
      </ListItem>
    </List>
  );
}
