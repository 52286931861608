import { Box, Skeleton } from "@mui/material";
import { toViewableDomainSections } from "FeedbackReport/DomainSection";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Maybe, RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import * as C from "Shared/Scale/Constructs";
import { Section } from "Shared/Section";
import { FeedbackReportContext } from "../FeedbackReportContext";
import ScaleSectionsTable from "./ScaleSectionsTable";

type ConstructSectionProps = {
  domain: Maybe<C.Domain>;
  children: ReactNode;
  hideTitle?: boolean;
};

function ConstructSection(props: ConstructSectionProps): ReactElement {
  const { domain, children } = props;
  const { t } = useTranslation(["report"]);

  return domain.caseOf({
    Just: (d) => (
      <Section title={d.name} hideTitle={props.hideTitle}>
        {children}
      </Section>
    ),
    Nothing: () => (
      <Section title={t("report:otherScales")} hideTitle={props.hideTitle}>
        {children}
      </Section>
    ),
  });
}

function ConstructsLoadingIndicator(): ReactElement {
  return <Skeleton width={400} height={80} />;
}

function ConstructsContent(props: {
  constructs: ReadonlyArray<C.ConstructSection>;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
}): ReactElement {
  const { constructs, feedbackReportContext } = props;

  const domainSections = toViewableDomainSections(constructs);
  const rows = domainSections.map((domainSection, i) => {
    const scaleSections = domainSection.constructs.flatMap(
      (constructSection) => constructSection.scaleSections
    );
    return (
      <ConstructSection key={i} domain={domainSection.domain} hideTitle={props.hideTitle}>
        <ScaleSectionsTable scaleSections={scaleSections} feedbackReportContext={feedbackReportContext} />
      </ConstructSection>
    );
  });

  return <>{rows}</>;
}

type Props = {
  constructs: RemoteData<Error, ReadonlyArray<C.ConstructSection>>;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
};

function Constructs(props: Props): ReactElement {
  const { constructs, feedbackReportContext } = props;

  const content = constructs.caseOf({
    Success: (constructs) => (
      <ConstructsContent
        constructs={constructs}
        feedbackReportContext={feedbackReportContext}
        hideTitle={props.hideTitle}
      />
    ),
    Loading: () => <ConstructsLoadingIndicator />,
    NotAsked: () => <ConstructsLoadingIndicator />,
    Failure: (err) => <ErrorMessage message={err.toString()} />,
  });

  return <Box data-testid="constructs">{content}</Box>;
}

export default Constructs;
