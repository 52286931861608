import { Chip, ChipProps, Typography } from "@mui/material";
import {
  CareEpisode,
  Organization,
  TreatmentService,
  useCareEpisodeDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, CareEpisodeId, PatientId } from "Lib/Ids";
import { AppointmentCareEpisodeAlgorithmTooltip } from "../Appointments/AppointmentCareEpisodeAlgorithm";
import React from "react";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";
import Spinner from "Shared/Spinner";
import Link from "MDS/Link";
import { patientCareEpisodeUrl } from "Patients/CareEpisodes2";

type TreatmentServiceWithName = Pick<TreatmentService, "name">;
type OrganizationWithName = Pick<Organization, "name">;

// Generate a hash code
function hashCode(s: string) {
  return s.split("").reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

// TODO: make nice colors
const colorIndex: ReadonlyArray<ChipProps["color"]> = [
  "default",
  "error",
  "info",
  "primary",
  "secondary",
  "success",
  "warning",
];

// Todo think about this more carefully. I think in general:
// - we should just actually add colors to treatment services and organizations
function careEpisodeColor(
  careEpisode:
    | Pick<CareEpisode, "id" | "status"> & {
        organization: OrganizationWithName;
        treatmentService: TreatmentServiceWithName | null;
      }
): ChipProps["color"] {
  const code = hashCode(careEpisode.treatmentService?.name || careEpisode.organization.name);
  return colorIndex[Math.abs(code) % colorIndex.length];
}

export function CareEpisodeChip(props: { careEpisodeId: CareEpisodeId; patientId: PatientId }) {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useCareEpisodeDetailsQuery({ variables: { careEpisodeId: props.careEpisodeId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.careEpisode) {
        return <CareEpisodeChipLoaded patientId={props.patientId} careEpisode={data.careEpisode} />;
      } else {
        return <ErrorMessage message={t("common:notFound")} />;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });
}

function CareEpisodeChipLoaded(props: {
  patientId: PatientId;
  careEpisode:
    | Pick<CareEpisode, "id" | "status"> & {
        organization: OrganizationWithName;
        treatmentService: TreatmentServiceWithName | null;
      };
}) {
  const { careEpisode } = props;
  return (
    <Link to={patientCareEpisodeUrl(careEpisode.id, props.patientId)}>
      <Chip
        label={careEpisode.treatmentService?.name || careEpisode.organization.name}
        color={careEpisodeColor(careEpisode)}
        variant={careEpisode.status === "ACTIVE" ? "filled" : "outlined"}
      />
    </Link>
  );
}

export default function CareEpisodeAppointmentChip(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  careEpisode:
    | (Pick<CareEpisode, "id" | "status"> & {
        organization: OrganizationWithName;
        treatmentService: TreatmentServiceWithName | null;
      })
    | null;
}) {
  const { careEpisode } = props;

  if (!careEpisode) {
    return (
      <Typography>
        Not Matched{" "}
        <AppointmentCareEpisodeAlgorithmTooltip
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown
        >
          <u>(why?)</u>
        </AppointmentCareEpisodeAlgorithmTooltip>
      </Typography>
    );
  }

  return (
    <AppointmentCareEpisodeAlgorithmTooltip
      appointmentId={props.appointmentId}
      patientId={props.patientId}
      hideUnknown
    >
      <CareEpisodeChipLoaded patientId={props.patientId} careEpisode={careEpisode} />
    </AppointmentCareEpisodeAlgorithmTooltip>
  );
}
