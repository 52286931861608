import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisode,
  EntityDecisionAlgorithm,
  Patient,
  Provider,
  TriggeredDecisionAlgorithm,
  TriggeredWorkflow,
  TriggeredWorkflowAction,
  TriggeredWorkflowStatus,
  useTriggeredWorkflowDetailsQuery,
  Workflow,
  WorkflowAction,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TriggeredWorkflowId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import { styled } from "@mui/material/styles";
import { Check } from "@mui/icons-material";
import Link, { LinkButton } from "MDS/Link";
import { TriggeredWorkflowActionButton } from "./TriggeredWorkflowAction";
import { PickTypename } from "type-utils";
import { RichText } from "MDS/RichText";

type TriggeredWorkflowDetailsProps = {
  triggeredWorkflowId: TriggeredWorkflowId;
};

export function TriggeredWorkflowDetail(props: TriggeredWorkflowDetailsProps): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);

  const { remoteData } = apolloQueryHookWrapper(
    useTriggeredWorkflowDetailsQuery({
      variables: {
        triggeredWorkflowId: props.triggeredWorkflowId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <SkeletonCard />,
    Loading: () => <SkeletonCard />,
    Failure: (err) => <ErrorCard message={err.message} />,
    Success: (response) => {
      if (response.decisionSupportTriggeredWorkflow) {
        return <TriggeredWorkflowCard triggeredWorkflow={response.decisionSupportTriggeredWorkflow} />;
      } else {
        return <ErrorCard message={t("decisionSupport:inbox.noSuchTriggeredWorkflow")} />;
      }
    },
  });
}

function SkeletonCard() {
  return (
    <Card>
      <CardHeader title={<Skeleton />} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}

function ErrorCard(props: { message: string }) {
  return (
    <Card>
      <CardHeader />
      <CardContent>
        <ErrorMessage message={props.message} />
      </CardContent>
    </Card>
  );
}

type WorkflowActionSummary = PickTypename<WorkflowAction, "id" | "text">;
type TriggeredWorkflowActionSummary = PickTypename<TriggeredWorkflowAction, "id" | "status"> & {
  workflowAction: WorkflowActionSummary;
};
type WorkflowSummary = PickTypename<Workflow, "id" | "title" | "callToAction" | "instructions">;
type PatientSummary = PickTypename<Patient, "id" | "name">;
type CareEpisodeSummary = PickTypename<CareEpisode, "id"> & { patient: PatientSummary };
type EntityDecisionAlgorithmSummary = PickTypename<EntityDecisionAlgorithm, "id" | "workflowNote">;
type TriggeredDecisionAlgorithmSummary = PickTypename<TriggeredDecisionAlgorithm, "id"> & {
  careEpisode: CareEpisodeSummary;
  entityDecisionAlgorithm: EntityDecisionAlgorithmSummary;
};
type ProviderSummary = PickTypename<Provider, "id" | "name">;
type TriggeredWorkflowDetails = PickTypename<
  TriggeredWorkflow,
  "id" | "status" | "snoozedUntil" | "triggeredAt" | "reasons" | "statusUpdatedAt"
> & {
  triggeredDecisionAlgorithm: TriggeredDecisionAlgorithmSummary;
  workflow: WorkflowSummary;
  triggeredWorkflowActions: ReadonlyArray<TriggeredWorkflowActionSummary>;
  statusUpdatedBy?: ProviderSummary | undefined | null;
};

const InfoBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.info.light,
}));

const InfoBox2 = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.report.shading.background,
}));

const CanceledCompletedCard = styled(Card)(() => ({
  opacity: 0.5,
}));

const SnoozedCard = styled(Card)(({ theme }) => ({
  borderWidth: "2px",
  borderStyle: "dashed",
  // At some point when we have the bandwidth to do designer-y stuff we should pull this out into a general concept in
  // the palette and maybe components too.
  borderColor: theme.palette.qualityIssues.snoozedBorder,
}));

const ActiveCard = Card;

type StatusFormattedCardProps = CardProps & { status: TriggeredWorkflowStatus };

function StatusFormattedCard(props: StatusFormattedCardProps) {
  const { status, ...cardProps } = props;
  switch (status) {
    case TriggeredWorkflowStatus.ACTIVE:
      return <ActiveCard {...cardProps}>{props.children}</ActiveCard>;
    case TriggeredWorkflowStatus.SNOOZED:
      return <SnoozedCard {...cardProps}>{props.children}</SnoozedCard>;
    case TriggeredWorkflowStatus.CANCELED:
    case TriggeredWorkflowStatus.COMPLETE:
      return <CanceledCompletedCard {...cardProps}>{props.children}</CanceledCompletedCard>;
  }
}

type TriggeredWorkflowCardProps = {
  triggeredWorkflow: TriggeredWorkflowDetails;
};

function TriggeredWorkflowCard(props: TriggeredWorkflowCardProps) {
  const { t } = useTranslation(["decisionSupport"]);

  const subtitleDate =
    props.triggeredWorkflow.status === TriggeredWorkflowStatus.SNOOZED
      ? props.triggeredWorkflow.snoozedUntil
      : props.triggeredWorkflow.statusUpdatedAt;

  const workflowNote = props.triggeredWorkflow.triggeredDecisionAlgorithm.entityDecisionAlgorithm
    .workflowNote ? (
    <InfoBox2>
      <RichText>
        {props.triggeredWorkflow.triggeredDecisionAlgorithm.entityDecisionAlgorithm.workflowNote}
      </RichText>
    </InfoBox2>
  ) : null;

  return (
    <StatusFormattedCard status={props.triggeredWorkflow.status}>
      <CardHeader
        title={props.triggeredWorkflow.workflow.callToAction}
        subheader={t(
          `decisionSupport:inbox.triggeredWorkflowDetails.subtitle.${props.triggeredWorkflow.status}`,
          { provider: props.triggeredWorkflow.statusUpdatedBy?.name, date: subtitleDate }
        )}
      />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Typography>
            {t("decisionSupport:inbox.triggeredWorkflowDetails.patientMetCriteria", {
              patientName: props.triggeredWorkflow.triggeredDecisionAlgorithm.careEpisode.patient.name,
              date: props.triggeredWorkflow.triggeredAt,
            })}
          </Typography>
          <List>
            {props.triggeredWorkflow.reasons.map((reason, i) => {
              return (
                <ListItem key={i}>
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                  <ListItemText>{reason}</ListItemText>
                </ListItem>
              );
            })}
          </List>
          <InfoBox>
            <RichText>{props.triggeredWorkflow.workflow.instructions}</RichText>
          </InfoBox>
          {workflowNote}
          <Stack direction="row" spacing={1} justifyContent="end">
            {props.triggeredWorkflow.triggeredWorkflowActions.map((triggeredAction, i) => {
              return <TriggeredWorkflowActionButton triggeredWorkflowAction={triggeredAction} key={i} />;
            })}
            <LinkButton
              variant="outlined"
              to={`/provider/patients/${props.triggeredWorkflow.triggeredDecisionAlgorithm.careEpisode.patient.id}/longitudinal`}
            >
              {t("decisionSupport:inbox.triggeredWorkflowDetails.viewReport")}
            </LinkButton>
          </Stack>
        </Stack>
        <Link
          to={`/app/providers/decision-support/algorithms/details/${props.triggeredWorkflow.triggeredDecisionAlgorithm.entityDecisionAlgorithm.id}`}
        >
          View Details of the Algorithm
        </Link>
      </CardContent>
    </StatusFormattedCard>
  );
}
