import { Patient, Task, TimeEntryLog } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, ReactNode, useContext } from "react";
import { PickTypename } from "type-utils";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { TimeEntryLogEditDialog } from "CollaborativeCare/TimeEntry/TimeEntryLogEditDialog";

export type ModifyTimeEntryFormProps = {
  task: PickTypename<Task, "id" | "title" | "isPlaceholder">;
  timeEntryLog: PickTypename<
    TimeEntryLog,
    | "id"
    | "clientStartTime"
    | "durationSeconds"
    | "durationReviewStatus"
    | "reviewedAt"
    | "unreviewedDurationSeconds"
  >;
  patient: PickTypename<Patient, "id" | "name"> | null;
};

type ModifyTimeEntryForm =
  | {
      showForm: true;
      task: PickTypename<Task, "id" | "title" | "isPlaceholder">;
      timeEntryLog: PickTypename<
        TimeEntryLog,
        | "id"
        | "clientStartTime"
        | "durationSeconds"
        | "durationReviewStatus"
        | "reviewedAt"
        | "unreviewedDurationSeconds"
      >;
      patient: PickTypename<Patient, "id" | "name"> | null;
    }
  | {
      showForm: false;
    };

type ModifyTimeEntryFormContextType = {
  showModifyForm: ModifyTimeEntryForm;
  useAdjustFinishedTimerForm: (props: ModifyTimeEntryFormProps) => void;
};
const initModifyForm: ModifyTimeEntryForm = {
  showForm: false,
};
const initModifyFormContextState = {
  showModifyForm: initModifyForm,
  useAdjustFinishedTimerForm: () => {
    return;
  },
};

const ModifyTimeEntryFormContext =
  React.createContext<ModifyTimeEntryFormContextType>(initModifyFormContextState);

export function ModifyTimeEntryFormProvider({ children }: { children: ReactNode }): ReactElement {
  const maybeTask = useCurrentTask();

  const hideForm: ModifyTimeEntryForm = {
    showForm: false,
  };
  const [showModifyForm, setShowModifyForm] = React.useState<ModifyTimeEntryForm>(hideForm);

  const useAdjustFinishedTimerForm = (props: ModifyTimeEntryFormProps) => {
    setShowModifyForm({
      showForm: true,
      task: props.task,
      timeEntryLog: props.timeEntryLog,
      patient: props.patient,
    });
    return;
  };

  let dialog = <></>;
  if (showModifyForm.showForm) {
    const task = maybeTask || showModifyForm.task;
    const dialogTask = { ...task, patient: showModifyForm.patient };
    dialog = (
      <TimeEntryLogEditDialog
        open={showModifyForm.showForm}
        onClose={() => {
          setShowModifyForm(hideForm);
        }}
        onSuccess={() => setTimeout(() => setShowModifyForm(hideForm), 300)}
        task={dialogTask}
        timeEntryLog={showModifyForm.timeEntryLog}
      />
    );
  }

  return (
    <>
      <ModifyTimeEntryFormContext.Provider value={{ showModifyForm, useAdjustFinishedTimerForm }}>
        {children}
      </ModifyTimeEntryFormContext.Provider>
      {dialog}
    </>
  );
}

export const useModifyTimeEntryForm = () => useContext(ModifyTimeEntryFormContext);
