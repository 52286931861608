import { Button, Card, CardContent, CardHeader, Stack, TextField, Typography } from "@mui/material";
import { apolloQueryToRemoteData } from "Api/GraphQL";
import { useMagicInspectorPathLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { Form, FormOverlay, useTextField } from "Shared/Form";
import React from "react";
import { useNavigate } from "react-router-dom";

export function InspectorEntry() {
  return (
    <Page browserTitle="Mirah - Inspector">
      <Stack direction="column" spacing={1}>
        <MagicInspectorPath />
        <ManualInspectorPath />
      </Stack>
    </Page>
  );
}

function MagicInspectorPath() {
  const navigate = useNavigate();
  const fields = {
    text: useTextField({ required: true, default: "" }),
  };

  const [getMagic, query] = useMagicInspectorPathLazyQuery();
  const remoteData = apolloQueryToRemoteData(query);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    getMagic({ variables: { text: fields.text.value || "" } });
  };

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: (response) => {
        if (response.magicInspectorPath) {
          navigate(response.magicInspectorPath);
        }
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  return (
    <Card sx={{ maxWidth: "50rem" }}>
      <CardHeader title="Find Inspector Page" />
      <CardContent>
        <Form onSubmit={onSubmit}>
          <FormOverlay response={remoteData} errorMessage="Error looking for model" />
          <Stack direction="column" spacing={1}>
            <TextField
              fullWidth
              value={fields.text.value}
              onChange={fields.text.onChange}
              error={fields.text.error}
              helperText={fields.text.helperText}
            />
            <Stack direction="row-reverse">
              <Button type="submit" color="secondary" variant="contained">
                I'm Feeling Lucky
              </Button>
            </Stack>
            <Typography>
              This can find the relevant inspector page for the most common entities in the application, when
              given a Mirah id, url or shortcode. The list of models it can find is:
            </Typography>
            <Typography>
              Patient session, patient, care episode, provider, institute, organization, enrollment, user,
              appointment, report, scale, invitation and computed value can be found by id or url. Institute,
              scale and survey modules can be found by shortcode or mnemonic.
            </Typography>
          </Stack>
        </Form>
      </CardContent>
    </Card>
  );
}

function ManualInspectorPath() {
  const navigate = useNavigate();
  const fields = {
    className: useTextField({ required: true, default: "" }),
    id: useTextField({ required: true, default: "" }),
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const path = `/app/ops/inspector/class/${fields.className.value}/id/${fields.id.value}`;
    navigate(path);
  };

  return (
    <Card sx={{ maxWidth: "50rem" }}>
      <CardHeader title="DIY Inspector Page" />
      <CardContent>
        <Form onSubmit={onSubmit}>
          <Stack direction="column" spacing={1}>
            <Typography>If you know the model and id you're looking for, enter them here:</Typography>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <Typography component="span">/app/ops/inspector/class/</Typography>
              <TextField value={fields.className.value} onChange={fields.className.onChange} size="small" />
              <Typography component="span">/id/</Typography>
              <TextField value={fields.id.value} onChange={fields.id.onChange} size="small" />
            </Stack>
            <Stack direction="row-reverse">
              <Button type="submit" variant="contained" color="secondary">
                Go
              </Button>
            </Stack>
          </Stack>
        </Form>
      </CardContent>
    </Card>
  );
}
