import React, { ReactElement } from "react";
import { Box, Card } from "@mui/material";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import { PatientDetails } from "./PatientDetails";
import PatientNoteCard from "./PatientNoteCard";
import { useIsMobile } from "Shared/Responsive";
import { useInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";

type PatientDetailsCardProps = {
  patient: PatientDetails;
  disableLink?: boolean;
  forPreviousEnrollment?: boolean;
  canReactivate?: boolean;
};

function PatientDetailsCard(props: PatientDetailsCardProps): ReactElement {
  // Note that the PRCH is meant to float as the page is scrolled down to always
  // keep it in view on this specific page. The zindex is chosen to be above
  // all other elements, but not above the tooltips that the PRCH might itself
  // generate.
  // We have different sizes here because the actual top gutter is different sizes.
  const stickyTop = useIsMobile() ? "4em" : "4.7em";
  const stickyProps = { position: "sticky", top: stickyTop, zIndex: "stickyCard" };
  const useStickyCard = !useInEmbeddedPatientOrEncounter() && !props.forPreviousEnrollment;

  const noteContent = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <Card>
      <Box margin="1em">
        <PatientNoteCard patient={props.patient} />
      </Box>
    </Card>
  );

  // there is already a banner at the top of the screen when viewing a previous enrollment that includes the patient name
  return (
    <>
      <Card variant="outlined" sx={useStickyCard ? stickyProps : {}}>
        <PatientReferenceCardHeader
          patientId={props.patient.id}
          disableLink={props.disableLink}
          forPreviousEnrollment={props.forPreviousEnrollment}
          canReactivate={props.canReactivate}
        />
      </Card>
      {noteContent}
    </>
  );
}

export default PatientDetailsCard;
