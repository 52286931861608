import * as Id from "Lib/Id";
import { UserSummary } from "Shared/User";
import { getCurrentUser } from "AppSession/AppSession";
import { InvitationPhase, InvitationStatus } from "GeneratedGraphQL/SchemaAndOperations";

export type InvitationId = Id.Id<"Invitation">;

// A Scale represents the clinical definition of a Scale like the PHQ-9 or
// GAD-7. It might be a subscale that is not directly administrable.
export type InvitationBase = {
  id: InvitationId;
};

export type InvitationWithUserSummary = InvitationBase & {
  status: InvitationStatus;
  phase: InvitationPhase;
  user: UserSummary;
};

// -- Helpers -----------------------------------------------------------------

/**
 * Return all invitations matching the currently logged in user
 * @param invitations invitations to filter
 */
function invitationsForCurrentUser(
  invitations: ReadonlyArray<InvitationWithUserSummary>
): ReadonlyArray<InvitationWithUserSummary> {
  return getCurrentUser()
    .map((user) => {
      return invitations.filter((invitation) => {
        return invitation.user.id.toString() === user.id;
      });
    })
    .getOrElse([]);
}

/**
 * Construct a link to edit this invitation in bulk/provider mode
 */
function editInvitationLink(invitationId: InvitationId) {
  return `/provider/assessment/invitation/${invitationId.toString()}/edit`;
}

function invitationActionName(invitation: InvitationWithUserSummary): string {
  switch (invitation.phase) {
    case "AFTER":
      return "Post-session form";
    case "DURING":
      return "In-session form";
    case "BEFORE":
    default:
      return "Pre-session form";
  }
}

// -- Transforms -----------------------------------------------------------------

export { invitationsForCurrentUser, editInvitationLink, invitationActionName };
