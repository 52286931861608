import React, { BaseSyntheticEvent, ReactElement, useState } from "react";
import { TaskDetails } from "./TaskCard";
import { Grid, Stack, TextField, useTheme } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useUpdateTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { RichText } from "MDS/RichText";
import { useIsMobile } from "Shared/Responsive";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { useEffectSimpleCompare } from "Lib/Hooks";

type EditableTaskBodyProps = {
  task: TaskDetails;
};

function EditableTaskBody(props: EditableTaskBodyProps): ReactElement {
  const [editingBody, setEditingBody] = useState<boolean>(false);
  const [editButtonVisible, setEditButtonVisible] = useState<boolean>(false);
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const originalBody = props.task.body;
  const [newBody, setNewBody] = useState<string>(originalBody);

  useEffectSimpleCompare(() => {
    // If the underlying task changes out from under us we should take that change rather than sticking with the
    // old data.
    if (originalBody !== newBody) {
      setNewBody(originalBody);
    }
  }, [originalBody]);

  const [updateTask, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateTask,
    useUpdateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  remoteData.caseOf({
    NotAsked: () => [],
    Loading: () => [],
    Failure: () => {
      setNewBody(originalBody);
      return [];
    },
    Success: (response) => {
      if (response?.task.body && !editingBody && newBody != response.task.body) {
        setNewBody(response.task.body);
      }
      return [];
    },
  });

  const bodyChangeHandler = function (e: BaseSyntheticEvent) {
    setNewBody(e.target.value);
  };

  const enterEditingBodyClickHandler = function () {
    setEditingBody(true);
  };

  const exitEditingBodyHandler = function () {
    if (newBody != originalBody) {
      updateTask({
        variables: {
          input: {
            taskId: props.task.id,
            body: newBody,
          },
        },
      });
    }
    setEditingBody(false);
  };
  const exitEditingBodyHandlerWithoutSaving = function () {
    setNewBody(originalBody);
    setEditingBody(false);
  };
  const columns = useIsMobile() ? 6 : 12;
  const isMobile = useIsMobile();

  let bodyDisplayValue = props.task.body;
  let bodyTextEmpty = false;
  if (bodyDisplayValue == "") {
    bodyDisplayValue = t("collaborativeCare:tasks.clickToAddDescription");
    bodyTextEmpty = true;
  }

  const theme = useTheme();

  const borderStyle = "solid";
  const borderWidth = "1px";
  const padding = "10px";
  const margin = "0px";
  let outlineStyle = {};
  if (editButtonVisible) {
    outlineStyle = {
      borderRadius: theme.shape.borderRadius,
      borderWidth: borderWidth,
      borderStyle: borderStyle,
      borderColor: theme.palette.textInput.borderColor,
      paddingTop: padding,
      paddingBottom: padding,
      marginTop: margin,
    };
  } else {
    outlineStyle = {
      borderRadius: theme.shape.borderRadius,
      borderWidth: borderWidth,
      borderStyle: borderStyle,
      borderColor: "transparent",
      paddingTop: padding,
      paddingBottom: padding,
      marginTop: margin,
    };
  }

  let body = (
    <>
      <Grid
        onMouseEnter={() => {
          setEditButtonVisible(true);
        }}
        onMouseLeave={() => {
          setEditButtonVisible(false);
        }}
        onClick={() => {
          //if the user is on mobile, or there is no existing body text,
          // they can simply click on the body to start editing
          if (isMobile || bodyTextEmpty) {
            enterEditingBodyClickHandler();
          }
        }}
        container
        columns={columns}
        spacing={1}
        style={{ minHeight: "3em" }}
      >
        <Grid item xs={9} style={{ paddingLeft: "0px" }}>
          <div style={outlineStyle}>
            <RichText>{bodyDisplayValue}</RichText>
          </div>
        </Grid>
        <Grid
          item
          xs={3}
          style={{ paddingTop: "20px", paddingBottom: "0px" }}
          hidden={useIsMobile() ? true : !editButtonVisible}
        >
          <ExpandingIconButton
            color="primary"
            variant="outlined"
            icon={<CreateIcon />}
            onClick={enterEditingBodyClickHandler}
          >
            {t("common:actions.edit")}
          </ExpandingIconButton>
        </Grid>
      </Grid>
    </>
  );
  if (editingBody) {
    body = (
      <>
        <Grid container columns={columns} spacing={1}>
          <Grid item xs={9} style={{ paddingLeft: "0px" }}>
            <TextField
              label={t("collaborativeCare:taskTemplates.fields.description")}
              multiline={true}
              defaultValue={props.task.body}
              onChange={bodyChangeHandler}
              autoFocus={true}
              fullWidth={true}
            ></TextField>
          </Grid>
          <Grid item xs={3} style={{ paddingTop: "20px", paddingBottom: "0px" }}>
            <Stack direction="row" spacing={1}>
              <ExpandingIconButton
                color="primary"
                variant="outlined"
                icon={<CheckIcon />}
                onClick={exitEditingBodyHandler}
              >
                {t("common:actions.save")}
              </ExpandingIconButton>
              <ExpandingIconButton
                color="primary"
                variant="outlined"
                icon={<CancelIcon />}
                onClick={exitEditingBodyHandlerWithoutSaving}
              >
                {t("common:actions.cancel")}
              </ExpandingIconButton>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
  return body;
}

export default EditableTaskBody;
