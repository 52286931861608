import Page from "Layout/Page";
import React, { useEffect } from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import ProviderSelect from "Shared/Filters/ProviderSelect";
import { ProviderFilter, CareEpisodeProviderRelationship } from "GeneratedGraphQL/SchemaAndOperations";
import PerformancePage from "./PerformancePage";
import { InstituteId, ProviderId } from "Lib/Ids";
import { useCurrentInstituteId } from "Contexts/CurrentInstituteIdContext";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyMonthParameter,
  useStickyProviderFilterParameter,
} from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";

const DEFAULT_PROVIDER_VALUE = null;

export default function InstitutePerformancePage() {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [now, _] = React.useState(new Date());
  const threeMonthsAgo = startOfMonth(sub(now, { months: 3 }));
  const endOfThisMonth = endOfMonth(now);

  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.COCM_INSTITUTE_PERFORMANCE,
    threeMonthsAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.COCM_INSTITUTE_PERFORMANCE,
    endOfThisMonth,
    "end",
    true
  );

  const [instituteId, setInstituteId] = React.useState<InstituteId | null>(null);
  const handleChangeInstituteId = (newValue: InstituteId | null) => {
    setInstituteId(newValue);
  };
  const [careManagers, setCareManagers] = useStickyProviderFilterParameter(
    STICKY_PARAMETER_NAMES.PROVIDER,
    STICKY_PARAMETER_FILTER_SETS.COCM_INSTITUTE_PERFORMANCE,
    DEFAULT_PROVIDER_VALUE,
    true
  );
  const handleSetCareManagers = (newValue: ProviderFilter | null) => {
    if (newValue?.allProviders) {
      handleChangeInstituteId(institute);
    } else {
      handleChangeInstituteId(null);
    }
    if (newValue?.me) {
      newValue = { providerIds: [myId] };
    }
    setCareManagers(newValue || careManagers);
  };
  const institute = useCurrentInstituteId();

  useEffect(() => {
    handleSetCareManagers(careManagers);
  }, []);

  const careManagerIds = careManagers?.providerIds || [];

  let myId: ProviderId;
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (currentProvider) {
    const currentProviderId = currentProvider.providerId.getOrElse(null);
    if (currentProviderId) {
      myId = currentProviderId;
    }
  }

  return (
    <Page browserTitle={t("collaborativeCare:performanceCharts.institutePerformance")}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1}>
            <Box height="6em" width="200px">
              <DatePicker
                label={"Start Month"}
                views={["month", "year"]}
                openTo={"month"}
                value={startDate}
                defaultValue={threeMonthsAgo}
                onChange={(newValue: Date) => setStartDate(newValue)}
              />
            </Box>
            <Box height="6em" width="200px">
              <DatePicker
                label={"End Month"}
                views={["month", "year"]}
                openTo={"month"}
                value={endDate}
                defaultValue={endOfThisMonth}
                onChange={(newValue: Date) => setEndDate(newValue)}
              />
            </Box>
            <Box height="6em" width="350px">
              <ProviderSelect
                value={careManagers}
                defaultValue={DEFAULT_PROVIDER_VALUE}
                setValue={function (newVal: ProviderFilter) {
                  handleSetCareManagers(newVal);
                }}
                defaultRelationships={[CareEpisodeProviderRelationship.CARE_MANAGER]}
                showMe={false}
              />
            </Box>
            <Box>
              <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
                <Divider orientation="vertical" flexItem />
                <ResetAndStickyFilterButtonGroup
                  onReset={() => {
                    setStartDate(threeMonthsAgo);
                    setEndDate(endOfThisMonth);
                    setCareManagers(DEFAULT_PROVIDER_VALUE);
                  }}
                />
              </Grid>
            </Box>
          </Stack>
        </Grid>
        <PerformancePage
          startDate={startDate}
          endDate={endDate}
          careManagerIds={careManagerIds}
          instituteId={instituteId}
        />
      </Grid>
    </Page>
  );
}
