import { Box, Paper, Skeleton, Stack, Typography, styled, useTheme } from "@mui/material";
import { LoadingStatus, PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { Patient, Task, useCollaborativeCareTaskDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Link from "MDS/Link";
import { OnDesktop, OnMobile, useIsMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { TaskDialogV2 } from "./TaskDialogV2";
import { MaybeStartStopTimeTrackingButton } from "./StartStopTimeTrackingButton";
import { CompleteTaskButton } from "./CompleteTaskButton";
import { TaskId } from "Lib/Ids";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";
import { PatientBillableMinutesBadge } from "CollaborativeCare/PatientBillableMinutesBadge";

export function LoadingCompactTaskCard(): ReactElement {
  return (
    <Paper sx={{ padding: "1rem" }}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Skeleton width="20%" />
          <Skeleton width="40%" />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <LoadingStatus />
          <Box flexGrow={1} />
          <Skeleton width="4rem" height="2.5rem" />
          <Skeleton width="4rem" height="2.5rem" />
        </Stack>
      </Stack>
    </Paper>
  );
}

type LazyCompactTaskCardProps = {
  taskId: TaskId;
} & Omit<CompactTaskCardProps, "task">;

export function LazyCompactTaskCard(props: LazyCompactTaskCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const { taskId, ...cardProps } = props;

  const { remoteData } = apolloQueryHookWrapper(
    // This query gets a lot more than we need but we already have it on hand from the original lazy task card
    useCollaborativeCareTaskDetailsQuery({
      variables: {
        id: taskId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <LoadingCompactTaskCard />,
    Loading: () => <LoadingCompactTaskCard />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.collaborativeCareTask) {
        return <CompactTaskCard task={response.collaborativeCareTask} {...cardProps} />;
      } else {
        return <ErrorMessage message={t("collaborativeCare:tasks.genericQueryError")} />;
      }
    },
  });
}

type TaskSummary = PickTypename<Task, "id" | "title" | "status" | "createdAt" | "dueAt"> & {
  patient: PickTypename<Patient, "id" | "name"> | null;
};

type CompactTaskCardProps = {
  task: TaskSummary;
  dragging?: boolean;
  onPaper?: boolean;
  inPatientContext?: boolean;
};

/**
 * A smaller card for tasks. Note that this implementation isn't using Mui's Card element itself because theres a lot
 * of extra padding we have in our theme on Card that we don't want. It's possible that the right answer here is to
 * change the styling on Card in the theme, but that has enough far-reaching effects that I haven't wanted to bite it
 * off yet.
 * @param props
 * @returns
 */
export function CompactTaskCard(props: CompactTaskCardProps): ReactElement {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const badge = props.task.patient ? (
    <PatientHeaderStatus patientId={props.task.patient.id} patientName={props.task.patient.name} />
  ) : null;

  const minutes = props.task.patient ? (
    <PatientBillableMinutesBadge patientId={props.task.patient.id} />
  ) : null;

  const patientLink = props.task.patient ? (
    <Link to={`/app/cocm/patient/${props.task.patient.id}`}>{props.task.patient.name}</Link>
  ) : null;

  // Some notes on the formatting here, because I ran into more CSS hell than I expected getting this to work:
  // * For reasons I don't fully understand, using <Divider> here instead of a border falls over. I think it's because
  //   the topmost Stack doesn't have an explicit height, so when the Divider sizes itself to `height: 100%` it doesn't
  //   know how to calculate that and just gets zero height. Hence, I'm using padding on the internal stacks and a
  //   border to simulate a divider.
  // * To keep the card compact we want to force titles and names to be a single line (whiteSpace: nowrap) and truncate
  //   them if they overflow (overflow: hidden, textOverflow: ellipsis). For some reason we need overflow hidden on both
  //   the container of the thing that holds the Typography and the Typography itself, I don't really know why.
  // * We want to force the patient side of the card to be an exact size so you don't get a bunch of whitespace there
  //   on large screens. Setting flexBasis, flexGrow=0 and flexShrink=0 basically tells flexbox that I know how big it
  //   should be and fuck off.
  // * Flex grow on the create/due/buttons line makes it take up the same space as the badge/minutes line even though
  //   the badge and minutes content is taller, so they all get centered together.

  // Pretty arbitrary, just chosen to be big enough for badge and minutes and most names.
  const patientInfoSize = theme.spacing(14);

  const patientInfo = props.inPatientContext ? null : (
    <Stack
      direction="column"
      spacing={0.5}
      padding={1}
      width={patientInfoSize}
      flexBasis={patientInfoSize}
      flexGrow={0}
      flexShrink={0}
      overflow="hidden"
      sx={{ borderRight: `1px solid ${theme.palette.divider}` }}
    >
      <Typography variant="h1" component="span" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {patientLink}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        {badge}
        {minutes}
      </Stack>
    </Stack>
  );

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          cursor: "pointer",
          border: props.onPaper ? `1px solid ${theme.palette.textInput.borderColor}` : "none",
        }}
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <Stack direction="row">
          {patientInfo}
          <TaskInfoCardSection task={props.task} />
        </Stack>
        {props.dragging ? <DragIndicator /> : null}
      </Paper>
      <TaskDialogV2
        task={props.task}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        openDetails={setDialogOpen}
      />
    </>
  );
}

function TaskInfoCardSection(props: { task: TaskSummary }): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const dueAt = props.task.dueAt ? (
    <Typography variant="caption">Due by {t("common:date.short", { date: props.task.dueAt })}</Typography>
  ) : null;

  return (
    <Stack direction="column" spacing={1} padding={1} flexGrow={1} overflow="hidden">
      <Typography variant="h1" component="span" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {props.task.title}
      </Typography>
      <OnDesktop>
        <Stack direction="row" spacing={0.5} alignItems="center" width="100%" flexGrow={1}>
          <Typography variant="caption">
            Created {t("common:date.short", { date: props.task.createdAt })}
          </Typography>
          {dueAt}
          <Box flexGrow={1} />
          <MaybeStartStopTimeTrackingButton taskId={props.task.id} patientId={props.task.patient?.id} />
          <CompleteTaskButton task={props.task} />
        </Stack>
      </OnDesktop>
      <OnMobile>
        <Stack direction="row" spacing={0.5} alignItems="center" width="100%" flexGrow={1}>
          <Typography variant="caption">
            Created {t("common:date.short", { date: props.task.createdAt })}
          </Typography>
          {dueAt}
        </Stack>
        <Stack direction="row" spacing={0.5} alignItems="center" width="100%" flexGrow={1}>
          <MaybeStartStopTimeTrackingButton taskId={props.task.id} patientId={props.task.patient?.id} />
          <CompleteTaskButton task={props.task} />
        </Stack>
      </OnMobile>
    </Stack>
  );
}

export function CompactTaskCardDragPreview(props: CompactTaskCardProps): ReactElement {
  // This imitates how large we expect the card to be in different situations. On desktop it should take up half the
  // screen (one column of the two column dashboard) minus the spacing between dashboard grid items. On mobile it takes
  // up the whole screen. I'd love to find a way to inject this at runtime that doesn't force us to reimplement it all
  // here, but I haven't found that yet.
  const desiredCardWidth = useIsMobile() ? "100%" : "calc(50% - 2rem)";
  // https://mui.com/system/shadows/
  const shadow = 2;
  // For the trello-esque dangling effect
  const transform = "rotate(-5deg)";

  return (
    <Box sx={{ maxWidth: desiredCardWidth, boxShadow: shadow, transform: transform }}>
      <CompactTaskCard {...props} />
    </Box>
  );
}

// Note that the card itself must be position: relative so that this can take up 100% of it with the position: absolute
// trick.
const DragIndicator = styled("div")(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.palette.secondary.light,
  borderRadius: theme.spacing(0.5),
}));
