import React, { ReactElement } from "react";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ReportIcon from "@mui/icons-material/Report";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";

import { Stack, styled } from "@mui/material";
import SimpleTooltip from "MDS/Tooltip/SimpleTooltip";
import { Flag, PatientFlag } from "GeneratedGraphQL/SchemaAndOperations";
import { Build, EscalatorWarning, HourglassFull, Sick } from "@mui/icons-material";

type FlagProps = {
  faIcon?: string;
};
type FlagIcon = {
  name: string;
  icon: ReactElement;
};
const SelfHarmIcon = styled(ReportIcon)(({ theme }) => ({
  color: theme.palette.report.scaleThreshold.Severe.primary,
}));

// these were based on registry icons to keep things consistent, but i broke this out into its own
// component so we won't have bleed later
// TODO: Move this to somewhere more shared
export const fontAwesomeToMuiIcon: Record<string, FlagIcon> = {
  "fa fa-medkit": { name: "Medical Consult", icon: <MedicalServicesIcon /> },
  "fa fa-asterisk": { name: "Medicine Concerns", icon: <MedicationLiquidIcon /> },
  "fa fa-home": { name: "Housing Concerns", icon: <HomeIcon /> },
  "fa fa-briefcase": { name: "Employment Concerns", icon: <WorkIcon /> },
  "fa fa-dollar": { name: "Cost Concerns", icon: <AttachMoneyIcon /> }, // this also maps to Medical Cost Concern Icon
  "fa fa-car": { name: "Transportation Concerns", icon: <EmojiTransportationIcon /> },
  "fa fa-exclamation-circle": { name: "Self Harm Risk", icon: <SelfHarmIcon /> },
  "fa fa-comments": { name: "Needs Consultation", icon: <MarkChatUnreadIcon /> },
  "fa fa-child": { name: "Under age 18", icon: <EscalatorWarning /> },
  "fa fa-thermometer-full": { name: "COVID-19 Health Alert", icon: <Sick /> },
  "fa fa-hourglass": { name: "In Treatment", icon: <HourglassFull /> },
  "fa fa-wrench": { name: "Quality Issues", icon: <Build /> },
};

export function PatientFlagIcon(props: FlagProps): FlagIcon | null {
  let icon: FlagIcon | undefined = undefined;
  if (props.faIcon) {
    icon = fontAwesomeToMuiIcon[props.faIcon];
  }
  if (icon == undefined) {
    icon = {
      name: "Unknown",
      icon: <QuestionMarkIcon />,
    };
  }
  return icon;
}

type FlagInfo = Pick<Flag, "__typename" | "name" | "icon">;
export type PatientFlagInfo = Pick<PatientFlag, "__typename" | "active"> & { flag: FlagInfo };

type PatientFlagsToolTipProps = {
  flags: ReadonlyArray<PatientFlagInfo>;
  excludeInTreatment?: boolean;
};
export function PatientFlags(props: PatientFlagsToolTipProps): ReactElement {
  const flags = props.flags
    .filter((f) => f.active && (!props.excludeInTreatment || f.flag.name !== "In Treatment"))
    .map((f, i) => {
      const icon = PatientFlagIcon({
        faIcon: f.flag.icon,
      })?.icon;

      if (icon) {
        return (
          <SimpleTooltip key={i} title={f.flag.name}>
            {icon}
          </SimpleTooltip>
        );
      }
      return null;
    });
  return <Stack direction="row">{flags}</Stack>;
}
