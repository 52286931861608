import { useCurrentInstituteId } from "Contexts/CurrentInstituteIdContext";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import RouterWithNotFound from "../../../../Shared/RouterWithNotFound";
import InstituteSettings from "./InstituteSettings";
import OrganizationRoutes from "./Organizations/OrganizationRoutes";
import TreatmentTrackRoutes from "./TreatmentTracks/TreatmentTrackRoutes";

function ConfigurationRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<OrganizationRoutes />} path="/organization/*" />
      <Route element={<TreatmentTrackRoutes />} path="/treatmenttrack/*" />
      <Route element={<InstituteSettingsShowRoute />} path="/" />
    </RouterWithNotFound>
  );
}

function InstituteSettingsShowRoute() {
  return <InstituteSettings instituteId={useCurrentInstituteId()} />;
}

export default ConfigurationRoutes;
