const careEpisodes = {
  title: "Mirah - Episode Of Care",
  cards: {
    details: "Episode of Care Details",
    careTeam: "Care Team",
    treatmentTracks: "Treatment Tracks",
  },
  createBreadcrumb: "Create a new Episode of Care",
  notEnded: "ongoing",
  sections: {
    summary: "Summary",
    organization: "Unit",
    organizationSubtitle: "The unit responsible for this episode of care",
    organizationSubtitleTreatmentService:
      "The unit responsible for this episode of care. This will be used for reporting but measurement protocols are defined by the treatment service.",
    treatmentService: "Treatment Service",
    treatmentServiceSubtitle: "The treatment service providing this episode of care.",
    careTeam: "Care Team",
    dates: "Dates",
    datesSubtitle: "The time period of this episode of care",
    settings: "Settings",
    treatmentTracks: "Treatment Tracks",
  },
  fields: {
    name: "Name",
    dates: "Dates",
    start: "Period Start",
    periodStart: "Period Start",
    end: "Period End",
    status: "Status",
    provider: "Provider",
    providers: "Providers",
    date: "Date",
    actions: "Actions",
    organizationSelector: "Unit selector",
    relationship: "Relationship",
    treatmentServiceSelector: "Treatment service selector",
    participatingInMBC: "Participating in MBC",
    addTreatmentTrack: "Add Treatment Track",
    treatmentService: "Treatment Service",
    treatmentServiceNotSpecified: "Not Specified", // TODO: this can be removed once treatment service is required
    organization: "Unit",
    measurementAllowed: "Automatic Measurement",
    measureStarting: "Measure Starting On",
    measureStartingImmediately: "Immediately",
    measureStartingShort: "Starts $t(common:date.medium)",
  },
  actions: {
    edit: "Edit",
    discharge: "Discharge",
    schedule: "Schedule Measurement",
    viewReport: "Report",
    sessionDetails: "Details",
    add: "Add",
    save: "Save",
    showOrgProviders: "Show providers from this unit",
    showAllProviders: "Show all providers",
    ok: "OK",
    cancel: "Cancel",
    details: "Actions",
  },
  treatmentTracks: {
    edit: "Edit",
    dialogTitle: "Treatment Tracks",
    explanation:
      "Treatment Tracks allow you to assign additional sets of measures for an episode of care. These are usually based on program, diagnosis, or some other medical necessity.",
    enrollmentData: "Enrolled as of $t(common:date.medium). First eligible session is #{{sessionNumber}}",
    activeTreatmentTracks: "Active Treatment Tracks",
    completeTreatmentTracks: "Complete Treatment Tracks",
    noTracks: "No Treatment Tracks",
    loadingTracks: "We're still loading the Treatment Tracks for this Episode of Care",
    errorTracks: "There was an error determining what Treatment Tracks were applied to this Episode of Care",
    addTracks: "Add a Treatment Track to this Episode of Care",
    noAvailableTracks: "There are no applicable Treatment Tracks for this Episode of Care",
    startDateAndSession: "Started $t(common:date.tiny), first eligible measurement #{{ startSessionNumber }}",
    availableTracks: "Available Treatment Tracks",
    pills: {
      completeTitle: "Complete Treatment Track?",
      enteredInErrorTitle: "Mark Treatment Track as Entered in Error?",
      completeText:
        "The Treatment Track {{ name }} will be marked complete for this Episode of Care. This should be done when the track has been fully completed. This action cannot be reversed.",
      enteredInErrorText:
        "The Treatment Track {{ name }} will be marked as entered in error for this Episode of Care. This should be done if adding this track was actually a mistake. This action cannot be reversed.",
    },
    completeDialog: {
      confirm: "Mark as complete",
      abort: "Leave as active",
      button: "Complete",
    },
    enteredInErrorDialog: {
      confirm: "Mark as entered in error",
      abort: "Leave as active",
      button: "Abort",
    },
  },
  dateBoth: "{{ periodStart; date(format:MM/dd/yyyy) }} - {{ periodEnd; date(format:MM/dd/yyyy) }}",
  dateStart: "$t(common:date.tiny) -",
  noDates: "No dates",
  autoDischargeWarning:
    "This episode of care will be automatically discharged in $t(common:date.durationToNow) unless it receives a new measurement or appointment",
  dischargeDialog: {
    title: "Discharge Episode of Care",
    confirm: "Discharge",
    abort: "Keep Active",
    details:
      "Discharging this episode of care will complete treatment for this patient. You will have to re-open this episode of care or start a new one to continue measurement.",
    resetMeasurement:
      "Starting a new episode of care will reset the measurement plan for the patient, including new baseline measurements.",
    outcomes:
      "This episode of care will be evaluated for outcomes on measurements that have already been completed.",
  },
  editDialog: {
    title: "Edit Episode Of Care",
    titleWithName: "Edit Episode of Care for {{name}}",
    createWithName: "Start Episode of Care for {{name}}",
    create: "Start Episode of Care",
    edit: "Save",
    editError: "There was an error saving your changes",
    notFound: "This episode of care cannot be edited",
    dateExplanation:
      "The start date of the episode of care is used to calculate the date from which to evaluate progress in treatment",
    organizationExplanation: "The measures administered may vary depending on the organization selected",
    organizationAndTreatmentServiceExplanation:
      "The measures administered may vary depending on the organization and treatment service selected",
  },
  careTeam: {
    description:
      "Members of the care team will see this patient on their schedule, when filtering for their cases, and this patient will be included in reporting and analytics for the provider.",
    edit: "Edit",
    dialogTitle: "Edit Care Team",
    noMembers: "The care team is empty",
    error: "There was an erroring loading the care team",
  },
};

export default careEpisodes;
