import React, { ReactElement, useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
} from "@mui/material";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  EntityType,
  ReportEntityTreeNode,
  SortDirection,
  TimeBasedAggregationType,
  useEntityTreeNodeSelectSingleQuery,
  useOutcomesMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { MetricId } from "Lib/Ids";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { EntitySummary, entityTreeNodeToParams } from "Entities/EntityPath";
import { entityTypeT } from "GeneratedGraphQL/EnumTranslations";
import * as NEL from "Lib/NonEmptyList";
import { ZoomIn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ClearableSelect } from "Shared/ClearableSelect";
import { HasAggregationType, metricLabelGenerator } from "./OutcomesMetricHelpers";
import { useFeatureSet, useInstituteHasGroups } from "Contexts/CurrentInstituteContext";
import { allowedEntityTypes } from "Contexts/EntityCollection";

type OutcomesMetricBreakdownInnerProps = OutcomesMetricBreakdownProps & {
  collection: EntityType;
  limit: number;
};

function EligibleCountColumn(props: { value: number | null | undefined }) {
  if (typeof props.value === "number") {
    return props.value.toFixed(0);
  } else {
    return "-";
  }
}

function EligibleCount(props: { row: ReportEntityTreeNode }) {
  const datum = props.row.summaryData.find((item) => item.name === "eligibleRows");

  return <EligibleCountColumn value={datum?.value} />;
}

/**
 * Makes a box with either a number, or a percentage bar filling up on the basis of the number.
 */
function MetricValueColumn(props: {
  value: number | null | undefined;
  labelGenerator: (data: { value: number | null }) => string;
  timeBasedAggregationType: TimeBasedAggregationType | null | undefined;
}) {
  const { value, labelGenerator, timeBasedAggregationType } = props;
  if (typeof value === "number") {
    if (timeBasedAggregationType === TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS) {
      const percent = value * 100;
      return (
        <div
          style={{
            display: "block",
            border: "2px solid #ddd",
            textAlign: "center",
            margin: "1px 0",
            width: "100%",
            background: `linear-gradient(to right, #babfd4 ${percent}%, #fff ${percent}%)`,
          }}
        >
          {labelGenerator({ value })}
        </div>
      );
    } else {
      return labelGenerator({ value });
    }
  } else {
    return "-";
  }
}

function MetricValue(props: {
  row: ReportEntityTreeNode;
  labelGenerator: (data: { value: number | null }) => string;
  metric: HasAggregationType;
}) {
  const { metric, labelGenerator } = props;
  const datum = props.row.summaryData.find((item) => item.name === "value");

  return (
    <MetricValueColumn
      value={datum?.value}
      labelGenerator={labelGenerator}
      timeBasedAggregationType={
        metric.__typename === "TimeBasedMetric" ? metric.timeBasedAggregationType : null
      }
    />
  );
}

function valueForSorting(row: Pick<ReportEntityTreeNode, "summaryData">, field: string): number {
  const item = row.summaryData.find((d) => d.name === field);

  return item?.value || 0;
}

type SortFields = "name" | "value" | "eligibleRows" | null;

function OutcomesMetricBreakdownInner(props: OutcomesMetricBreakdownInnerProps) {
  const { t } = useTranslation(["outcomes"]);
  const { metricId, startDate, endDate, entityTreeNodeParams } = props;

  const [sort, setSort] = useState<SortFields>("value");
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

  const labelGenerator = metricLabelGenerator(props.metricConfiguration, t);

  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: {
      collection: { node: entityTreeNodeParams, collection: props.collection },
    },
    sort,
    sortDirection,
    limit: props.limit,
    period: {
      dateRange: {
        min: startDate,
        max: endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };

  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesMetricSummaryDataQuery({
      variables: {
        drilldownMetric: drilldownMetric,
        metricId: metricId,
        scaleScorerId: null,
      },
    })
  );

  const columns: Array<GridColDef<ReportEntityTreeNode>> = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("outcomes:breakdown.tableColumns.entity"),
        sortable: true,
        flex: 2,
        valueGetter: (_value, row) => row.entityTreeNode.entity.name,
        renderCell: (params) => {
          return <EntitySummary entity={params.row.entityTreeNode.entity} />;
        },
      },
      {
        field: "value",
        headerName: t("outcomes:breakdown.tableColumns.result"),
        sortable: true,
        flex: 1,
        valueGetter: (_value, row) => valueForSorting(row, "value"),
        renderCell: (params) => {
          return (
            <MetricValue
              row={params.row}
              labelGenerator={labelGenerator}
              metric={props.metricConfiguration}
            />
          );
        },
      },
      {
        field: "eligibleRows",
        headerName: t("outcomes:breakdown.tableColumns.eligibleRows"),
        sortable: true,
        flex: 1,
        valueGetter: (_value, row) => valueForSorting(row, "eligibleRows"),
        renderCell: (params) => {
          return <EligibleCount row={params.row} />;
        },
      },
      {
        field: "actions",
        headerName: t("outcomes:breakdown.tableColumns.actions"),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <ZoomIn
              onClick={() => props.setEntityTreeNodeParams(entityTreeNodeToParams(params.row.entityTreeNode))}
            />
          );
        },
      },
    ];
  }, []);

  const content = remoteData.caseOf({
    Failure: (e) => <ErrorMessage message={e.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Success: (data) => {
      return (
        <>
          <DataGrid
            columns={columns}
            rows={data.outcomesMetricSummaryData.reportEntityTreeNodes}
            getRowId={(row) => {
              return row.entityTreeNode.path;
            }}
            hideFooterPagination={true}
            sortingMode={"server"}
            onSortModelChange={(model) => {
              return NEL.fromArray(model).caseOf({
                Nothing: () => {
                  setSort(null);
                },
                Just: (models) => {
                  const model = NEL.head(models);
                  setSort(model.field as unknown as SortFields);
                  setSortDirection(model.sort === "asc" ? SortDirection.ASC : SortDirection.DESC);
                },
              });
            }}
          />
        </>
      );
    },
  });

  return <Paper>{content}</Paper>;
}

function CollectionSelect(props: {
  items: ReadonlyArray<EntityType>;
  value: EntityType | null;
  onChange: (newValue: EntityType | null) => void;
  parentNode: EntityTreeNodeParams;
}): ReactElement {
  const { t } = useTranslation(["enums", "outcomes", "common"]);
  const featureSet = useFeatureSet();
  const groupsEnabled = useInstituteHasGroups();

  const { remoteData } = apolloQueryHookWrapper(
    useEntityTreeNodeSelectSingleQuery({ variables: { node: props.parentNode } })
  );

  return remoteData.caseOf({
    Success: (response) => {
      if (response.entityTreeNode?.entity) {
        const items = allowedEntityTypes(
          props.items,
          response.entityTreeNode.entity.entityType,
          featureSet,
          groupsEnabled
        ).map((option) => {
          return (
            <MenuItem value={option.toString()} key={option.toString()}>
              {entityTypeT(option, t)}
            </MenuItem>
          );
        });

        const valueOrBlank = props.value === null ? "" : props.value;

        // Passing null into a controlled component doesn't work well, use empty string instead.
        return (
          <ClearableSelect
            fullWidth={false}
            sx={{ minWidth: "350px" }}
            label={t("outcomes:breakdown.selectTitle")}
            value={valueOrBlank}
            onChange={props.onChange}
          >
            {items}
          </ClearableSelect>
        );
      } else {
        return <ErrorMessage message={t("common:notFound")} />;
      }
    },
    Loading: () => <Skeleton width="350px" />,
    NotAsked: () => <Skeleton width="350px" />,
    Failure: (e) => <ErrorMessage message={e.message} />,
  });
}

type OutcomesMetricBreakdownProps = {
  metricId: MetricId;
  entityTreeNodeParams: EntityTreeNodeParams;
  setEntityTreeNodeParams: (node: EntityTreeNodeParams) => void;
  startDate: Date;
  endDate: Date;
  metricConfiguration: HasAggregationType;
};

function OutcomesMetricBreakdown(props: OutcomesMetricBreakdownProps) {
  const [collection, setCollection] = useState<EntityType | null>(null);
  const [limit, setLimit] = useState<number>(15);
  const { t } = useTranslation(["outcomes"]);

  // When you select a new entity id, reset the collection as we don't know what's relevant.
  useEffect(() => {
    setCollection(null);
  }, [props.entityTreeNodeParams]);

  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    const number = parseInt(event.target.value.toString());

    if (!isNaN(number)) {
      setLimit(number);
    }
  };

  const content = collection ? (
    <OutcomesMetricBreakdownInner {...props} limit={limit} collection={collection} />
  ) : null;
  return (
    <Card>
      <CardHeader title={t("outcomes:breakdown.header")} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <CollectionSelect
              items={[
                EntityType.PROVIDER,
                EntityType.ORGANIZATION,
                EntityType.TREATMENT_SERVICE,
                EntityType.TREATMENT_TRACK,
                EntityType.PANEL,
                EntityType.PRIMARY_CARE_PHYSICIAN,
                EntityType.CARE_MANAGER,
              ]}
              onChange={setCollection}
              value={collection}
              parentNode={props.entityTreeNodeParams}
            />
            <FormControl>
              <Select value={limit} onChange={handleLimitChange}>
                <MenuItem value={15}>{t("outcomes:breakdown.firstItems", { number: 15 })}</MenuItem>
                <MenuItem value={25}>{t("outcomes:breakdown.firstItems", { number: 25 })}</MenuItem>
                <MenuItem value={50}>{t("outcomes:breakdown.firstItems", { number: 50 })}</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {content}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OutcomesMetricBreakdown;

export { CollectionSelect, MetricValueColumn, EligibleCountColumn, EligibleCount };
