import { Button, Card, CardContent, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CareEpisodeDetails } from "./CareEpisodeDetails";
import { CareEpisodeDischargeButton } from "./CareEpisodeDischargeButton";
import { ContextualReportButton } from "Shared/ContextualReportLink";
import { Assessment } from "@mui/icons-material";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

export function CareEpisodeActionsCard(props: {
  careEpisode: CareEpisodeDetails;
  patient: Pick<Patient, "id" | "hasCompletedAssessment">;
}): ReactElement {
  return (
    <Card>
      <CardContent>
        <Grid container columns={6} spacing={1}>
          <Grid item lg={6} xs={6}>
            <Stack direction="column" spacing={0.5}>
              <CareEpisodeList {...props} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

type ActionListSubheaderProps = {
  title: string;
};
function ActionListSubheader(props: ActionListSubheaderProps): ReactElement {
  return (
    <Typography variant="h2" marginBottom="0.65em">
      {props.title}
    </Typography>
  );
}

function CareEpisodeList(props: {
  careEpisode: CareEpisodeDetails;
  patient: Pick<Patient, "id" | "hasCompletedAssessment">;
}): ReactElement {
  const { t } = useTranslation(["careEpisodes", "patients"]);

  const reportLink = props.patient.hasCompletedAssessment ? (
    <ContextualReportButton
      careEpisode={props.careEpisode.id}
      patient={props.patient.id}
      variant="contained"
      color="secondary"
    >
      <Assessment />
      {t("patients:patientDetails.actions.feedbackReport")}
    </ContextualReportButton>
  ) : (
    <Button variant="contained" disabled={true}>
      {t("patients:patientDetails.actions.noReport")}
    </Button>
  );

  return (
    <List subheader={<ActionListSubheader title={t("careEpisodes:actions.details")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          {reportLink}
          <ReadOnlyOrImpersonatingDisabledButton>
            <CareEpisodeDischargeButton careEpisodeId={props.careEpisode.id} />
          </ReadOnlyOrImpersonatingDisabledButton>
        </Stack>
      </ListItem>
    </List>
  );
}
