import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

// I'm not adding this to MDS on purpose because I'm not sure this is really a
// strategy we want to use other places. This is the first thing I've run into
// where we just have one column of stuff on a single page all by itself, most
// of the time we shove more things in there so the max width kind of takes
// care of itself.
export const ReasonableColumnOfText = styled(Stack)(({ theme }) => ({
  // https://practicaltypography.com/line-length.html
  maxWidth: theme.spacing(50),
}));
