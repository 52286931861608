import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useQuickTrackMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { InternalUserDisabledButton } from "Shared/ContextDisabledButtons";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function QuickTrackButton(props: { patientId: PatientId }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [quickTrack, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareQuickTrackUnknownTask,
    useQuickTrackMutation({
      variables: {
        input: {
          patientId: props.patientId,
          addToDefaultList: true,
        },
      },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  // Unlike the normal Begin Tracking button we want this to be enabled if you're already tracking time on another
  // task. We probably don't want it to be enabled if that task is for the same patient as this button, but for the
  // sake of a speedy implementation I'm intentionally leaving that case unhandled. It will be possible to spam click
  // this button and create a bunch of tasks if you really want to. We can talk about this next week when we revisit
  // this feature in more depth.

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <InternalUserDisabledButton shortText>
      <ButtonWithSpinner
        variant="contained"
        color="secondary"
        showSpinner={showSpinner}
        disabled={disabled}
        onClick={() => quickTrack()}
      >
        {t("collaborativeCare:tasks.quickTrack")}
      </ButtonWithSpinner>
    </InternalUserDisabledButton>
  );
}
