import { TrendingUp, Troubleshoot } from "@mui/icons-material";
import { Alert, Box, styled, Typography } from "@mui/material";
import { Axis, Grid, AnimatedLineSeries, Tooltip, XYChart } from "@visx/xychart";
import { AxisScaleOutput } from "@visx/axis";
import { ScaleConfig } from "@visx/scale";
import {
  ChartData,
  ImplementationTargetGraphData,
  ImplementationTargetHeadlineStat,
  ImplementationTargetOnTrack,
  ImplementationTargetReportGraphDataSeries,
  ImplementationTargetReportGraphDataUnit,
  ImplementationTargetStatPeriod,
  ImplementationTargetType,
} from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import { useTranslation } from "react-i18next";
import { implementationTargetStatPeriodT } from "GeneratedGraphQL/EnumTranslations";
import { valueIsSecretlyNull } from "type-utils";
import { TFunction } from "i18next";
import { DEFAULT_CHART_THEME } from "Shared/GraphTheme";
import { formatMoney } from "Shared/Money";
import { formatPercent } from "Shared/formatters";

function graphXLegend(targetType: ImplementationTargetType, t: TFunction<["implementation"]>) {
  switch (targetType) {
    case ImplementationTargetType.COCM_NEW_ENROLLMENTS:
      return t("implementation:targets.COCM_NEW_ENROLLMENTS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_BILLED_MINUTES.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_RVUS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_VALUE_UNITS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLING.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY:
      return t("implementation:targets.COCM_MONTHLY_BILLING_EFFICIENCY.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.graphLegend");
  }
}

export function ImplementationTargetOnTrackBadge(props: { onTrack: ImplementationTargetOnTrack }) {
  const { t } = useTranslation(["enums"]);
  if (props.onTrack === ImplementationTargetOnTrack.ON_TRACK) {
    return (
      <Alert icon={<TrendingUp />} severity="success">
        {t("enums:ImplementationTargetOnTrack.ON_TRACK.title")}
      </Alert>
    );
  } else {
    return (
      <Alert icon={<Troubleshoot />} severity="error">
        {t("enums:ImplementationTargetOnTrack.OFF_TRACK.title")}
      </Alert>
    );
  }
}

const BorderedBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  border: `thin solid ${theme.palette.dividerLight}`,
  borderRadius: "3px",
}));

export function ImplementationTargetHeadlineStatBox(props: {
  stat:
    | Pick<ImplementationTargetHeadlineStat, "denominator" | "period" | "value" | "units" | "unitClass">
    | null
    | undefined;
  period: ImplementationTargetStatPeriod;
  endDate: Date;
}) {
  const { t } = useTranslation(["enums", "implementation", "common"]);
  let value = t("common:notApplicable");

  if (typeof props.stat?.value === "number") {
    if (props.stat.denominator) {
      value = `${formatValueFromUnitClass(
        props.stat.value,
        props.stat.unitClass
      )} / ${formatValueFromUnitClass(props.stat.denominator, props.stat.unitClass)}`;
    } else {
      value = formatValueFromUnitClass(props.stat.value, props.stat.unitClass);
    }
  }

  let text = implementationTargetStatPeriodT(props.period, t);

  if (props.period === ImplementationTargetStatPeriod.LAST_7_DAYS) {
    text = t("implementation:statPeriod.LAST_7_DAYS", { date: props.endDate });
  }

  const unitBox = props.stat?.units ? (
    <Typography variant="h3" textAlign={"center"} fontSize={"0.8em"}>
      {props.stat.units}
    </Typography>
  ) : null;

  return (
    <BorderedBox>
      <Typography variant="h1" fontSize={"2.5em"} textAlign={"center"}>
        {value}
      </Typography>
      {unitBox}
      <Typography variant="h3" fontSize={"1.0em"} textAlign={"center"}>
        {text}
      </Typography>
    </BorderedBox>
  );
}

export function formatValueFromUnitClass(value: number, unitClass: ImplementationTargetReportGraphDataUnit) {
  switch (unitClass) {
    case ImplementationTargetReportGraphDataUnit.INTEGER:
      return value.toFixed();
    case ImplementationTargetReportGraphDataUnit.FLOAT:
      return value.toFixed(2);
    case ImplementationTargetReportGraphDataUnit.PERCENT:
      return formatPercent(value);
    case ImplementationTargetReportGraphDataUnit.MONEY_CENTS:
      // Assume anything in implementation is big enough that cents aren't helpful.
      return formatMoney({ currency: "USD", cents: value }, 0);
  }
}

export function headlineStatOfType<T extends Pick<ImplementationTargetHeadlineStat, "period">>(
  stats: ReadonlyArray<T>,
  period: ImplementationTargetStatPeriod
) {
  return stats.find((stat) => stat.period === period);
}

export function ImplementationTargetHeadlineGraph(props: {
  series: ReadonlyArray<
    Pick<ImplementationTargetGraphData, "name" | "seriesType"> & {
      points: ReadonlyArray<Pick<ChartData, "value" | "date">>;
    }
  >;
  maxValue: number;
  unitClass: ImplementationTargetReportGraphDataUnit;
  targetType: ImplementationTargetType;
}) {
  const { t } = useTranslation(["implementation", "common"]);
  const yScale = { type: "linear", domain: [0, props.maxValue] } as ScaleConfig<AxisScaleOutput>;

  const xAccessor = (d: ChartData) => {
    if (valueIsSecretlyNull(d)) {
      return null;
    }
    return d.date;
  };

  const yAccessor = (d: ChartData) => {
    if (valueIsSecretlyNull(d)) {
      return null;
    }
    return d.value;
  };

  const yTickFormatter = (t: number) => {
    return formatValueFromUnitClass(t, props.unitClass);
  };

  const lineSeries = props.series.map((set, index) => {
    if (set.seriesType === ImplementationTargetReportGraphDataSeries.TARGET) {
      return (
        <AnimatedLineSeries
          key={index}
          dataKey={set.name}
          data={[...set.points]}
          yAccessor={yAccessor}
          xAccessor={xAccessor}
          strokeDasharray={"6, 4"}
          colorAccessor={() => "#DCD8D1"}
        />
      );
    }
    return (
      <AnimatedLineSeries
        key={index}
        dataKey={set.name}
        data={[...set.points]}
        yAccessor={yAccessor}
        xAccessor={xAccessor}
      />
    );
  });
  return (
    <>
      <XYChart
        height={300}
        theme={DEFAULT_CHART_THEME}
        xScale={{ type: "time", paddingInner: 0.5 }}
        yScale={yScale}
        margin={{ top: 20, right: 20, bottom: 40, left: 60 }}
      >
        <Axis orientation="left" numTicks={6} hideAxisLine tickFormat={yTickFormatter} />
        <Axis
          numTicks={5}
          orientation="bottom"
          hideAxisLine
          rangePadding={50}
          label={graphXLegend(props.targetType, t)}
          tickFormat={(value) => {
            return t("common:date.tiny", { date: value });
          }}
        />
        <Grid numTicks={6} strokeDasharray="3 3" />
        {lineSeries}
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!tooltipData || !tooltipData.nearestDatum || !colorScale) {
              return <></>;
            }

            const entries = Object.entries(tooltipData.datumByKey).map(([key, datum]) => {
              const item = datum.datum as ChartData;
              return (
                <div key={key} style={{ color: colorScale(key) }}>
                  {key}: {formatValueFromUnitClass(item.value, props.unitClass)}
                </div>
              );
            });

            return (
              <div>
                {t("common:date.tiny", { date: (tooltipData.nearestDatum.datum as ChartData).date })}
                {entries}
              </div>
            );
          }}
        />
      </XYChart>
    </>
  );
}

export function IncompleteReportWarning(props: { endDate: Date }) {
  const { t } = useTranslation(["implementation"]);

  if (props.endDate < new Date()) {
    return null;
  }

  return <Alert severity="warning">{t("implementation:incompleteReportWarning")}</Alert>;
}
