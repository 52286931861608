import { Checkbox, FormControl, FormControlLabel, FormHelperText, Input, TextField } from "@mui/material";
import {
  Institute,
  InstituteListQueryQueryVariables,
  InstituteSortParameter,
  SortDirection,
  SupportTask,
  SupportTaskArgument,
  SupportTaskArgumentInput,
  SupportTaskArgumentType,
  useInstituteListQueryLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { InstituteId } from "Lib/Ids";
import React, { ChangeEvent, ReactElement } from "react";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";

// There are three distinct types that represent a task argument at various points in its lifecycle. First is what I'm
// calling an "abstract argument", which is the argument definition that we get from the task definition query. Then
// we turn that into a "concrete argument", which has all the information from the abstract argument plus the data we
// need to carry around to actually let a user interact with the argument - a value, event handlers, etc.  Finally
// we turn a concrete argument into an "input argument", which is the form it takes to submit it to the run task
// mutation. The abstract argument and input argument types are defined by the graphql schema, concrete argument is
// defined here. By making it a discriminated union on field type we can make the event handlers all type correctly
// for the eventual input controls we attach them to and also use exhaustive switch checking to make sure we handle all
// the field types.

type AbstractArgument = Omit<SupportTaskArgument, "globalId">;
export type SupportTaskDetails = Omit<SupportTask, "globalId" | "arguments" | "category" | "subCategory"> & {
  arguments: ReadonlyArray<AbstractArgument>;
};

type BaseConcreteArgument = {
  name: string;
  code: string;
  description: string | null;
  required: boolean;
  errors: ReadonlyArray<string>;
  setErrors: (errors: ReadonlyArray<string>) => void;
};

type ConcreteStringArgument = BaseConcreteArgument & {
  fieldType: SupportTaskArgumentType.STRING | SupportTaskArgumentType.TEXT | SupportTaskArgumentType.ID;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type ConcreteInstituteIdArgument = BaseConcreteArgument & {
  fieldType: SupportTaskArgumentType.INSTITUTE_ID;
  value: InstituteId | "";
  onChange: (value: InstituteId | "") => void;
};

type ConcreteBooleanArgument = BaseConcreteArgument & {
  fieldType: SupportTaskArgumentType.BOOLEAN;
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type ConcreteIntArgument = BaseConcreteArgument & {
  fieldType: SupportTaskArgumentType.INT;
  value: number | undefined;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type ConcreteArgument =
  | ConcreteStringArgument
  | ConcreteInstituteIdArgument
  | ConcreteBooleanArgument
  | ConcreteIntArgument;

export function abstractToConcreteArgument(argument: AbstractArgument): ConcreteArgument {
  switch (argument.fieldType) {
    case SupportTaskArgumentType.STRING:
    case SupportTaskArgumentType.TEXT:
    case SupportTaskArgumentType.ID: {
      const [value, setValue] = React.useState<string>("");
      const [errors, setErrors] = React.useState<ReadonlyArray<string>>([]);
      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);

        // This is a pretty quick hack to get required checking in. If the error response from the mutation let us
        // associate validation errors with specific fields we'd have to make this smarter so we could support other
        // functions adding/removing errors from this collection.
        if (argument.required && event.target.value.trim().length === 0) {
          setErrors([`${argument.name} is required`]);
        } else {
          setErrors([]);
        }
      };
      return {
        name: argument.name,
        code: argument.code,
        description: argument.description,
        required: argument.required,
        errors: errors,
        setErrors: setErrors,
        fieldType: argument.fieldType,
        value: value,
        onChange: onChange,
      };
    }
    case SupportTaskArgumentType.INSTITUTE_ID: {
      const [value, setValue] = React.useState<InstituteId | "">("");
      const [errors, setErrors] = React.useState<ReadonlyArray<string>>([]);
      return {
        name: argument.name,
        code: argument.code,
        description: argument.description,
        required: argument.required,
        errors: errors,
        setErrors: setErrors,
        fieldType: argument.fieldType,
        value: value,
        onChange: setValue,
      };
    }
    case SupportTaskArgumentType.BOOLEAN: {
      // Initialize all checkboxes to false so that we avoid the tri-state problem of true, false, undefined (if you've
      // never interacted with the checkbox). This means that you can't actually have an optional boolean parameter, but
      // that seems like the lesser sin.
      const [value, setValue] = React.useState<boolean>(false);
      const [errors, setErrors] = React.useState<ReadonlyArray<string>>([]);
      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        // checked is always either true or false so we don't have to check required here.
        setValue(event.target.checked);
      };
      return {
        name: argument.name,
        code: argument.code,
        description: argument.description,
        required: argument.required,
        errors: errors,
        setErrors: setErrors,
        fieldType: argument.fieldType,
        value: value,
        onChange: onChange,
      };
    }
    case SupportTaskArgumentType.INT: {
      const [value, setValue] = React.useState<number | undefined>(undefined);
      const [errors, setErrors] = React.useState<ReadonlyArray<string>>([]);
      const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        // convert string to number
        const val = parseInt(event.target.value);
        Number.isNaN(val) ? setValue(undefined) : setValue(val);
      };

      return {
        name: argument.name,
        code: argument.code,
        description: argument.description,
        required: argument.required,
        errors: errors,
        setErrors: setErrors,
        fieldType: argument.fieldType,
        value: value,
        onChange: onChange, // wrap into string
      };
    }
  }
}

export function concreteToInputArgument(argument: ConcreteArgument): SupportTaskArgumentInput {
  switch (argument.fieldType) {
    case SupportTaskArgumentType.STRING:
    case SupportTaskArgumentType.TEXT:
    case SupportTaskArgumentType.ID:
    case SupportTaskArgumentType.INSTITUTE_ID: {
      // Rather than make the server check for empty string values, treat blank inputs as missing
      const value = argument.value.toString().trim().length == 0 ? undefined : argument.value.toString();
      return {
        code: argument.code,
        stringValue: value,
      };
    }
    case SupportTaskArgumentType.BOOLEAN: {
      return {
        code: argument.code,
        booleanValue: argument.value,
      };
    }
    case SupportTaskArgumentType.INT: {
      return {
        code: argument.code,
        integerValue: argument.value,
      };
    }
  }
}

type TaskArgumentProps = {
  argument: ConcreteArgument;
};

export function TaskArgumentInput(props: TaskArgumentProps): ReactElement {
  const error = props.argument.errors.length > 0;
  const helperText = error ? props.argument.errors.join(", ") : props.argument.description;
  switch (props.argument.fieldType) {
    // For now, ID inputs are just regular strings. Without knowing what scope they come from we can't do anything
    // particularly clever with a generic id on the client.
    case SupportTaskArgumentType.STRING:
    case SupportTaskArgumentType.ID: {
      return (
        <TextField
          label={props.argument.name}
          error={error}
          helperText={helperText}
          value={props.argument.value}
          onChange={props.argument.onChange}
        />
      );
    }
    case SupportTaskArgumentType.TEXT: {
      return (
        <TextField
          label={props.argument.name}
          error={error}
          helperText={helperText}
          multiline
          value={props.argument.value}
          onChange={props.argument.onChange}
        />
      );
    }
    case SupportTaskArgumentType.BOOLEAN: {
      return (
        <FormControl error={error}>
          <FormControlLabel
            control={<Checkbox />}
            label={props.argument.name}
            checked={props.argument.value}
            onChange={props.argument.onChange}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
    }
    case SupportTaskArgumentType.INSTITUTE_ID: {
      return <SearchableInstituteDropdown argument={props.argument} error={error} helperText={helperText} />;
    }
    case SupportTaskArgumentType.INT: {
      return (
        <FormControl error={error}>
          <FormControlLabel
            control={<Input type="number" />}
            label={props.argument.name}
            value={props.argument.value}
            onChange={props.argument.onChange}
            sx={{ margin: 0 }}
          />
          <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
      );
    }
  }
}

type InstituteDropdownProps = {
  argument: ConcreteInstituteIdArgument;
  error: boolean;
  helperText: string | null;
};

type InstituteSummary = Pick<
  Institute,
  | "__typename"
  | "id"
  | "name"
  | "shortcode"
  | "createdAt"
  | "updatedAt"
  | "isTest"
  | "enabled"
  | "measurementAllowed"
>;

function SearchableInstituteDropdown(props: InstituteDropdownProps): ReactElement {
  const queryVars: Omit<InstituteListQueryQueryVariables, "search"> = {
    sortBy: InstituteSortParameter.NAME,
    sortDirection: SortDirection.ASC,
    first: 10,
    last: null,
    before: null,
    after: null,
  };

  const [value, setValue] = React.useState<InstituteSummary | null>(null);

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => {
        props.argument.onChange(value ? value.id : "");
        setValue(value);
      }}
      error={props.error}
      required={props.argument.required}
      helperText={props.helperText}
      value={value}
      queryVariables={queryVars}
      query={useInstituteListQueryLazyQuery}
      unwrapResponse={(response) => response.institutes?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label="Institutes"
      autocompleteProps={{ noOptionsText: "No Matching Institutes", getOptionLabel: (option) => option.name }}
    />
  );
}
