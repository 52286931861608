import React from "react";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { DetailsWrapper } from "../DetailsWrapper";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { CareEpisodeId, ParticipantId } from "Lib/Ids";
import ScaleGroupScorePane from "./ScaleGroupScorePane";
import { useTranslation } from "react-i18next";

type ScaleGroupScoreProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

export const ScaleGroupScoreRoute = (props: ScaleGroupScoreProps) => {
  const { t } = useTranslation(["report"]);
  const parms = useParams<{ scaleGroupId?: string; scaleGroupScoreId?: string }>();

  const scaleGroupId = Id.fromNullableString<"ScaleGroup">(parms.scaleGroupId).getOrElse(null);
  const scaleGroupScoreId = Id.fromNullableString<"CareEpisodeComputedValue">(
    parms.scaleGroupScoreId
  ).getOrElse(null);

  if (scaleGroupId === null || scaleGroupScoreId === null) {
    return <ErrorMessage message={t("report:errors.invalidUrl")} />;
  }

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    // scaleGroupHistoryRoute takes participantId as a parameter but doesn't actually use it. Based on some commented-out
    // code it looks like participant id used to be in the URL for the scale group history and score routes, but isn't
    // anymore, so this parameter is totally vestigial. Rather than make a whole request to get the participant here
    // (or rewriting all the call sites for this route) I'm just forcing a null in.
    [
      t("report:breadcrumbs.measureHistory"),
      Routing.scaleGroupHistoryRoute(
        props.feedbackReportContext,
        scaleGroupId,
        null as unknown as ParticipantId
      ),
    ],
    [t("report:breadcrumbs.responses")],
  ];

  return (
    <DetailsWrapper breadcrumbs={breadcrumbs}>
      <ScaleGroupScorePane
        careEpisodeId={props.careEpisodeId}
        scaleGroupId={scaleGroupId}
        careEpisodeComputedValueId={scaleGroupScoreId}
        feedbackReportContext={props.feedbackReportContext}
      />
    </DetailsWrapper>
  );
};
