import React, { ReactElement } from "react";
import {
  PatientQualityIssue,
  PatientQualityIssueDetailsQuery,
  PatientQualityIssueStatus,
  PatientQualityIssueType,
  usePatientQualityIssueDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientId } from "Lib/Ids";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";
import { Skeleton, Typography } from "@mui/material";
import { activeIssueCount, pendingIssueCount } from "./PatientQualityIssue";

export type IssueCountColumnProps = {
  patientId: PatientId;
  activeStatuses: ReadonlyArray<PatientQualityIssueStatus> | null;
  activeIssue: PatientQualityIssueType | null;
};

function IssueCountColumnHookWrapper(props: IssueCountColumnProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    usePatientQualityIssueDetailsQuery({
      variables: {
        patientId: props.patientId,
      },
    })
  );

  return IssueCountColumn({ remoteData, ...props });
}

type IssueCountColumnDataProps = {
  remoteData: RemoteData<ApolloError, PatientQualityIssueDetailsQuery>;
} & IssueCountColumnProps;

function LoadingIndicator(): ReactElement {
  return <Skeleton height={21} width={50} data-testid="loading-indicator" />;
}

function IssueCountColumn(props: IssueCountColumnDataProps): ReactElement {
  const { t } = useTranslation(["qualityIssues"]);
  return props.remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      const issues = result.patientQualityIssues?.nodes || [];
      const issueCount = filteredIssuesCount(issues, props.activeIssue, props.activeStatuses);

      const active = activeIssueCount(issues);
      const pending = pendingIssueCount(issues);

      const showActive = active != issueCount;

      let text;

      if (pending > 0 && showActive) {
        text = t("qualityIssues:issueCountWithTotalAndPending", {
          count: issueCount,
          active: active,
          pending: pending,
        });
      } else if (pending > 0) {
        text = t("qualityIssues:issueCountWithPending", {
          count: issueCount,
          pending: pending,
        });
      } else if (showActive) {
        text = t("qualityIssues:issueCountWithTotal", { count: issueCount, active: active });
      } else {
        text = t("qualityIssues:issueCount", { count: issueCount });
      }

      return <Typography>{text}</Typography>;
    },
  });
}

function filteredIssuesCount(
  issues: ReadonlyArray<Pick<PatientQualityIssue, "status" | "issue">>,
  issueType: PatientQualityIssueType | null,
  statuses: ReadonlyArray<PatientQualityIssueStatus> | null
) {
  return issues.filter((issue) => {
    return (
      (issueType === null || issue.issue === issueType) &&
      (statuses === null || statuses.includes(issue.status))
    );
  }).length;
}

export default IssueCountColumnHookWrapper;
export { IssueCountColumnHookWrapper as HookWrapper, IssueCountColumn as Component };
