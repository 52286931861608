import { Route, Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import InstituteDetailsRoute from "./InstituteDetails/InstituteDetailsRoute";
import InstituteList from "./InstituteList/InstituteList";
import InstituteReportingRoute from "./Reporting/InstituteReportingRoute";

function Institutes(): ReactElement {
  return (
    <Routes>
      <Route element={<InstituteList />} path="/" />
      <Route element={<InstituteDetailsRoute />} path=":instituteId/*" />
      <Route element={<InstituteReportingRoute />} path="/reporting/integrations" />
    </Routes>
  );
}

export { Institutes };
