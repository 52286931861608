import React from "react";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { dataSourceRecordLogStatusT } from "GeneratedGraphQL/EnumTranslations";
import { DataSourceRecordLogStatus } from "GeneratedGraphQL/SchemaAndOperations";
import EnumSelect from "Shared/EnumSelect";
import { useQueryStringOptionalEnumParameter } from "Shared/QueryStringParameter";
import { useTranslation } from "react-i18next";

export type DataSourceRecordLogHistoryFilters = {
  status: DataSourceRecordLogStatus | null;
  setStatus: (status: DataSourceRecordLogStatus | null) => void;
};

export function useDataSourceRecordLogFilters(): DataSourceRecordLogHistoryFilters {
  const [status, setStatus] = useQueryStringOptionalEnumParameter<DataSourceRecordLogStatus>(
    "status",
    DataSourceRecordLogStatus,
    null
  );

  return {
    status,
    setStatus,
  };
}

const SelectContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(15),
}));

export function DataSourceRecordLogHistoryFilters(props: { filters: DataSourceRecordLogHistoryFilters }) {
  const { t } = useTranslation(["integrations"]);
  return (
    <Stack direction="row" spacing={1}>
      <SelectContainer>
        <EnumSelect
          optionsEnum={DataSourceRecordLogStatus}
          value={props.filters.status}
          onChange={props.filters.setStatus}
          title={t("integrations:dataSourceRecordLogHistory.filters.status")}
          enumTrans={dataSourceRecordLogStatusT}
        />
      </SelectContainer>
    </Stack>
  );
}
