import { Paper, Link, Table, TableRow, TableCell, TableHead } from "@mui/material";

import { Link as RouterLink } from "react-router-dom";

import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";

import React, { ReactElement } from "react";

import {
  InstituteOrganizationListQuery,
  useInstituteOrganizationListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { InstituteId, OrganizationId } from "Lib/Ids";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { ArrayElement, Dig } from "type-utils";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import { useEffectOnce } from "Lib/Hooks";
function InstitutePatientList(props: {
  instituteId: InstituteId;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useInstituteOrganizationListQuery({
      variables: {
        instituteId: props.instituteId,
      },
    })
  );

  useEffectOnce(() => {
    props.setSubCrumbs([{ text: "Organizations", path: "/organizations" }]);
  });

  return remoteData.caseOf({
    NotAsked: () => <h1>Loading</h1>,
    Loading: () => <h1>Loading</h1>,
    Failure: (error) => <h1>`Error! ${error.message}`</h1>,
    Success: (dat) => <LoadedOrgList orgs={dat.institute?.allOrganizations || []} />,
  });
}
function LoadedOrgList({
  orgs,
}: {
  orgs: Dig<InstituteOrganizationListQuery, ["institute", "allOrganizations"]>;
}): ReactElement {
  return (
    <Page browserTitle="Institute Organizations">
      <PageHeader title="Institute Organizations" />

      <Paper style={{ width: "100%" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> Name</TableCell>
              <TableCell align="right"> Shortname</TableCell>
              <TableCell align="right"> Measurement Allowed</TableCell>
              <TableCell align="right"> In Trial Period</TableCell>
            </TableRow>
          </TableHead>
          <RecursiveTree orgs={orgs} parentId={null} />
        </Table>
      </Paper>
    </Page>
  );
}

function RecursiveTree(props: {
  orgs: Dig<InstituteOrganizationListQuery, ["institute", "allOrganizations"]>;
  parentId: OrganizationId | null;
  level?: number;
}) {
  const level = props.level || 0;
  return (
    <>
      {props.orgs
        .filter((x) => x.parentId === props.parentId)
        .map((org) => (
          <>
            <TreeNode org={org} level={level} />
            <RecursiveTree orgs={props.orgs} parentId={org.id} level={level + 1} />
          </>
        ))}
    </>
  );
}

function TreeNode({
  org,
  level,
}: {
  org: ArrayElement<Dig<InstituteOrganizationListQuery, ["institute", "allOrganizations"]>>;
  level: number;
}) {
  return (
    <TableRow>
      <TableCell>
        <Link sx={{ ml: 1.5 * level }} component={RouterLink} to={org.id.toString()}>
          {org.name}
        </Link>
      </TableCell>
      <TableCell align="right"> {org.shortname}</TableCell>
      <TableCell align="right"> {boolCellVal(org.measurementAllowed)}</TableCell>
      <TableCell align="right"> {boolCellVal(org.inTrialPeriod)}</TableCell>
    </TableRow>
  );
}

function boolCellVal(value: boolean): string {
  return value ? "TRUE" : "FALSE";
}

export default InstitutePatientList;
