import React, { ReactElement } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Badge, IconButton, Paper } from "@mui/material";
import { TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { TimerBeginButton } from "CollaborativeCare/TimeEntry/TimerBeginButton";
import { patientUrl } from "CollaborativeCare/Utils/patient";
import { useChangeCurrentTask, useCurrentTask } from "Contexts/CurrentTaskContext";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  TaskSearchQuery,
  TaskSearchQueryVariables,
  TaskSortParameter,
  useTaskSearchQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId } from "Lib/Ids";
import Link from "MDS/Link";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  useShowExportToolbarFromFlag,
} from "Shared/SortablePagableCollectionDataGrid";
import { useTranslation } from "react-i18next";
import { SendTaskToTopButton } from "../SendToTopButton";
import { TaskCardBack } from "../TaskCard/TaskCardBack";
import { MobileTaskListCard, MobileTaskListCardForPatient } from "./MobileTaskList";
import { SearchFilters } from "./TaskSearchFilters";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import { COCM_TASK_LIST_TABLE_STORAGE_KEY } from "Shared/Storage";
import { useDebounce } from "Lib/Hooks";

function sortFieldToParameter(parameter: string | null): TaskSortParameter | null {
  switch (parameter) {
    case "dueBy":
      return TaskSortParameter.DUE_AT;
    case "title":
      return TaskSortParameter.TITLE;
    case "status":
      return TaskSortParameter.STATUS;
    case "patientName":
      return TaskSortParameter.PATIENT_NAME_LAST_FIRST;
    case "assignedTo":
      return TaskSortParameter.ASSIGNED_TO_NAME_LAST_FIRST;
    default:
      return null;
  }
}

type TaskListTableProps = {
  filters: SearchFilters;
  patientId?: PatientId;
};

export function TaskListTable(props: TaskListTableProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare", "enums", "patients"]);
  const { patientId, filters } = props;
  const [openDetails, setOpenDetails] = React.useState(false);
  const testPatientViewability = useTestPatientViewability();
  const task = useCurrentTask();
  const showExportToolbar = useShowExportToolbarFromFlag();

  let sharedCardBack = <></>;
  if (task) {
    sharedCardBack = (
      <TaskCardBack
        task={task}
        onClose={() => {
          setOpenDetails(false);
        }}
        openDetails={setOpenDetails}
        open={openDetails}
      />
    );
  }

  const panelFilter = usePanelFilter();
  const debouncedSearch = useDebounce(props.filters.search, 500);

  const queryVars: Pick<
    TaskSearchQueryVariables,
    "search" | "assignedTo" | "patientId" | "status" | "testPatient" | "forPanel"
  > = {
    status: filters.status,
    assignedTo: filters.assignedTo,
    patientId: patientId ? [patientId] : null,
    testPatient: testPatientViewability,
    search: debouncedSearch || null,
    forPanel: panelFilter,
  };

  const columns: DataGridCols<TaskSearchQuery, ["collaborativeCareTasks"]> = React.useMemo(() => {
    return [
      {
        field: "expand",
        headerName: "",
        width: 50,
        sortable: false,
        renderCell: (params) => {
          return <TaskCell task={params.row} openDetails={setOpenDetails} />;
        },
      },
      {
        field: "beginTask",
        headerName: "",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          return (
            <TimerBeginButton
              task={params.row}
              patientId={params.row.patient?.id}
              buttonLabel={t("collaborativeCare:tasks.actions.begin")}
              buttonCompleteLabel={t("collaborativeCare:tasks.actions.alreadyComplete")}
              variant="contained"
              color="secondary"
            />
          );
        },
      },
      {
        field: "sendToTop",
        headerName: "",
        width: 150,
        sortable: false,
        renderCell: (params) => {
          return <SendTaskToTopButton task={params.row} variant="contained" color="secondary" />;
        },
      },
      {
        field: "title",
        headerName: t("collaborativeCare:fields.title.label"),
        flex: 1,
        sortable: true,
      },
      {
        field: "status",
        headerName: t("collaborativeCare:fields.status.label"),
        flex: 1,
        sortable: true,
        valueGetter: (_value, row) => {
          return taskStatusT(row.status, t);
        },
      },
      {
        field: "patientName",
        headerName: t("collaborativeCare:fields.patient.label"),
        flex: 1,
        sortable: true,
        visible: !patientId,
        renderCell: (params) => {
          if (!params.row.patient) {
            return t("collaborativeCare:tasks.noPatient");
          }
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!params.row.patient.isTest}
              color="success"
            >
              <Link to={patientUrl(params.row.patient)}>{params.row.patient.nameLastFirst}</Link>
            </Badge>
          );
        },
      },
      {
        field: "assignedTo",
        headerName: t("collaborativeCare:fields.assignedTo.labelPastTense"),
        flex: 1,
        sortable: true,
        valueGetter: (_value, row) => {
          return row.assignedTo.name;
        },
      },
      {
        field: "dueBy",
        headerName: t("collaborativeCare:fields.dueAt.label"),
        flex: 1,
        sortable: true,
        renderCell: (params) => {
          const dueAt = params.row.dueAt;
          if (dueAt === null) {
            return "";
          } else {
            return t("collaborativeCare:tasks.dueAtSubhead", { date: dueAt });
          }
        },
      },
    ];
  }, [patientId]);

  return (
    <>
      <Paper>
        <SortablePagableCollectionDataGrid
          queryHook={useTaskSearchQuery}
          storageKey={COCM_TASK_LIST_TABLE_STORAGE_KEY}
          queryVariables={queryVars}
          columns={columns}
          unwrapData={(response) => response?.collaborativeCareTasks || null}
          colNameToSortParam={sortFieldToParameter}
          defaultPageSize={10}
          getRowId={(row) => row.id.toString()}
          showExportToolbar={showExportToolbar}
          mobileCard={patientId ? MobileTaskListCardForPatient : MobileTaskListCard}
          getFooterMessage={(data) => {
            const searchWithPanelsCount = data?.collaborativeCareTasks?.totalCount;
            const fullSearchResultCount = data?.nonPanelTasks?.totalCount;

            if (searchWithPanelsCount === undefined || fullSearchResultCount === undefined) {
              return undefined;
            }

            return t("collaborativeCare:tasks.patientListFooter", {
              // Note that even though count isn't interpolated into the translation we need it to select the right
              // variant.
              count: fullSearchResultCount - searchWithPanelsCount,
              fullSearchResultCount: fullSearchResultCount,
              searchWithPanelsCount: searchWithPanelsCount,
            });
          }}
        />
      </Paper>
      {sharedCardBack}
    </>
  );
}

type TaskCellProps = {
  task: TaskDetails;
  openDetails: (val: boolean) => void;
};
export function TaskCell(props: TaskCellProps): ReactElement {
  const changeTask = useChangeCurrentTask();

  const handleOnClick = () => {
    props.openDetails(true);
    changeTask(props.task);
  };

  return (
    <>
      <IconButton onClick={handleOnClick}>
        <ZoomInIcon />
      </IconButton>
    </>
  );
}
