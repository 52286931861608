import { InfoOutlined } from "@mui/icons-material";
import { Stack, Tooltip } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

type ItemWithInfoTooltipProps = {
  tooltip: string;
  item: ReactNode;
};

// Raw text nodes don't get spaced correctly by <Stack> so we have to put a span around them, but we don't want to add
// spans around something that's already a full element.
function wrappedItem(item: ReactNode): ReactNode {
  if (typeof item === "string" || typeof item === "number" || typeof item === "boolean") {
    return <span>{item}</span>;
  } else {
    return item;
  }
}

/**
 * Takes anything that can be rendered in React and sticks an info icom with a tooltip after it.
 */
export function ItemWithInfoTooltip(props: ItemWithInfoTooltipProps): ReactElement {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {wrappedItem(props.item)}
      <Tooltip title={props.tooltip}>
        <InfoOutlined fontSize="small" color="info" />
      </Tooltip>
    </Stack>
  );
}
