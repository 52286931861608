import { FeedbackReportContext } from "./FeedbackReportContext";
import { To } from "react-router-dom";
import { Opaque } from "Lib/Utils";
import { ParticipantId } from "Shared/Participant";
import { GoalId } from "Shared/Scale/Goal";
import {
  CareEpisodeClinicalAlertHistoryId,
  CareEpisodeClinicalAlertId,
} from "Shared/Scale/CareEpisodeClinicalAlert";
import { CareEpisodeComputedValueId, ScaleGroupId, ScaleId } from "Lib/Ids";

// -- Private API -------------------------------------------------------------

export type BaseContext = Pick<FeedbackReportContext, "patientId" | "careEpisodeId">;
type UriSegment = Opaque<string>;

function uriSegment(raw: string): UriSegment {
  // A segment can have no slashes and no dots
  return raw.replace(/\//g, "").replace(/\./g, "") as UriSegment;
}

// Private FeedbackReportRoute constructor. The public route constructors are
// the intended way to build a route and navigate
function feedbackReportRoute(context: BaseContext, uriSegments: Array<string>): FeedbackReportRoute {
  const { patientId, careEpisodeId } = context;

  return `/app/care-units/${patientId}/care-episodes/${careEpisodeId}/feedback-report/${uriSegments.join(
    "/"
  )}` as FeedbackReportRoute;
}

// -- Public API --------------------------------------------------------------

export type FeedbackReportRoute = Opaque<string>;

// Route constructors

function overviewRoute(context: BaseContext): FeedbackReportRoute {
  return feedbackReportRoute(context, []);
}

function scaleGroupHistoryRoute(
  context: BaseContext,
  scaleGroupId: ScaleGroupId,
  _participantId: ParticipantId
): FeedbackReportRoute {
  return feedbackReportRoute(context, [
    uriSegment("scale-group-history"),
    uriSegment(scaleGroupId.toString()),
    /*
    uriSegment("participant"),
    uriSegment(participantId.toString()),
    */
  ]);
}

function scaleGroupScoreRoute(
  context: BaseContext,
  scaleGroupId: ScaleGroupId,
  _participantId: ParticipantId,
  scoreId: CareEpisodeComputedValueId
): FeedbackReportRoute {
  return feedbackReportRoute(context, [
    uriSegment("scale-group-history"),
    uriSegment(scaleGroupId.toString()),
    /*
    uriSegment("participant"),
    uriSegment(participantId.toString()),
    */
    uriSegment("scale-group-score"),
    uriSegment(scoreId.toString()),
  ]);
}

function scaleAdministrationHistoryRoute(context: BaseContext, scaleId: ScaleId): FeedbackReportRoute {
  return feedbackReportRoute(context, [
    uriSegment("scale-administration-history"),
    uriSegment(scaleId.toString()),
  ]);
}

function scaleScoreRoute(
  context: BaseContext,
  scaleId: ScaleId,
  scoreId: CareEpisodeComputedValueId
): FeedbackReportRoute {
  return feedbackReportRoute(context, [
    uriSegment("scale-administration-history"),
    uriSegment(scaleId.toString()),
    uriSegment("scale-score"),
    uriSegment(scoreId.toString()),
  ]);
}

function goalRoute(context: BaseContext, goalId: GoalId): FeedbackReportRoute {
  return feedbackReportRoute(context, [uriSegment("goals"), uriSegment(goalId.toString())]);
}

function goalCreateRoute(context: BaseContext): FeedbackReportRoute {
  return feedbackReportRoute(context, [uriSegment("goals"), uriSegment("create")]);
}

function alertHistoryRoute(
  context: BaseContext,
  alertHistoryId: CareEpisodeClinicalAlertHistoryId
): FeedbackReportRoute {
  return feedbackReportRoute(context, [uriSegment("alert-history"), uriSegment(alertHistoryId.toString())]);
}

function alertRoute(
  context: BaseContext,
  alertHistoryId: CareEpisodeClinicalAlertHistoryId,
  alertId: CareEpisodeClinicalAlertId
): FeedbackReportRoute {
  return feedbackReportRoute(context, [
    uriSegment("alert-history"),
    uriSegment(alertHistoryId.toString()),
    uriSegment("alert"),
    uriSegment(alertId.toString()),
  ]);
}

// Navigation

// We only allow navigation with a FeedbackReportRoute
function unsafeNavigate(route: FeedbackReportRoute, nav: (to: To) => void): void {
  nav(route.toString());
}

export {
  overviewRoute,
  scaleGroupHistoryRoute,
  scaleGroupScoreRoute,
  scaleAdministrationHistoryRoute,
  scaleScoreRoute,
  goalRoute,
  goalCreateRoute,
  alertHistoryRoute,
  alertRoute,
  unsafeNavigate,
};
