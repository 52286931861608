import { Box, Button } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useCareEpisodeDetailsQuery, useEditCareEpisodeMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CareEpisodeId } from "Lib/Ids";
import Spinner from "Shared/Spinner";
import { CareEpisodeForm } from "./CareEpisodeForm";

type EditCareEpisodeButtonProps = {
  careEpisodeId: CareEpisodeId;
  buttonMinWidth?: string;
};

export function EditCareEpisodeButton(props: EditCareEpisodeButtonProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <EditCareEpisodeDialog open={open} onClose={() => setOpen(false)} careEpisodeId={props.careEpisodeId} />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("common:edit")}
      </Button>
      {dialog}
    </Box>
  );
}

type EditCareEpisodeDialogProps = {
  careEpisodeId: CareEpisodeId;
  open: boolean;
  onClose: () => void;
};

function EditCareEpisodeDialog(props: EditCareEpisodeDialogProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  const { remoteData } = apolloQueryHookWrapper(
    useCareEpisodeDetailsQuery({ variables: { careEpisodeId: props.careEpisodeId } })
  );

  const [editCareEpisode, { remoteData: mutationRemoteData }] = apolloMutationHookWrapper(
    (response) => response.editCareEpisode,
    useEditCareEpisodeMutation({ refetchQueries: ["CareEpisodeDetails"] })
  );

  let title = t("careEpisodes:editDialog.title");

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      const careEpisode = response.careEpisode;
      if (careEpisode) {
        title = t("careEpisodes:editDialog.titleWithName", { name: careEpisode.patient.name });
        return (
          <CareEpisodeForm
            mode="edit"
            defaults={{
              periodStart: careEpisode.periodStart,
              organizationId: careEpisode.organization.id,
              treatmentServiceId: careEpisode.treatmentService?.id,
            }}
            onSuccess={() => setTimeout(props.onClose, 300)}
            remoteData={mutationRemoteData}
            mutation={(fields) =>
              editCareEpisode({
                variables: {
                  careEpisode: {
                    careEpisodeId: careEpisode.id,
                    periodStart: fields.periodStart,
                    organizationId: fields.organizationId,
                    treatmentServiceId: fields.treatmentServiceId,
                  },
                },
              })
            }
            errorMessage={t("careEpisodes:editDialog.editError")}
          />
        );
      } else {
        return <ErrorMessage message={t("careEpisodes:editDialog.notFound")} />;
      }
    },
  });

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={title}
      stopBackdropClose={false}
      dialogWidth="50%"
    >
      {content}
    </ResponsiveDialog>
  );
}
