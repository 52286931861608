import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { careEpisodeProviderRelationshipT } from "GeneratedGraphQL/EnumTranslations";
import { CareEpisodeProvider, Provider } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import InfoSidebarCard from "Shared/InfoSidebarCard";
import { WithPermission } from "Shared/WithPermission";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { EditCareEpisodeProvidersButton } from "./EditCareEpisodeProvidersButton";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

type CareEpisodeCareTeamCardProps = {
  careEpisodeId: CareEpisodeId;
  careEpisodeProviders: ReadonlyArray<
    Pick<CareEpisodeProvider, "id" | "relationship"> & { provider: Pick<Provider, "id" | "name"> }
  >;
};

export default function CareEpisodeCareTeamCard(props: CareEpisodeCareTeamCardProps): ReactElement {
  const { t } = useTranslation(["careEpisodes", "enums"]);

  const cardAction = (
    <WithPermission permission="scheduleAppointment">
      <Box marginRight="0.3em">
        <ReadOnlyOrImpersonatingDisabledButton>
          <EditCareEpisodeProvidersButton careEpisodeId={props.careEpisodeId} />
        </ReadOnlyOrImpersonatingDisabledButton>
      </Box>
    </WithPermission>
  );

  const rows =
    props.careEpisodeProviders.length > 0 ? (
      props.careEpisodeProviders.map((cep) => {
        return (
          <TableRow key={cep.id.toString()}>
            <TableCell>{cep.provider.name}</TableCell>
            <TableCell>{careEpisodeProviderRelationshipT(cep.relationship, t)}</TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow key="empty">
        <TableCell colSpan={2}>{t("careEpisodes:careTeam.noMembers")}</TableCell>
      </TableRow>
    );

  return (
    <InfoSidebarCard action={cardAction} title={t("careEpisodes:cards.careTeam")}>
      <Table>
        <TableBody>{rows}</TableBody>
      </Table>
    </InfoSidebarCard>
  );
}
