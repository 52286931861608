import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PrepareConsultButton } from "CollaborativeCare/CaseConsult/CaseConsultReview/PrepareConsult";
import { CompleteConsultButton } from "CollaborativeCare/CaseConsult/CompleteCaseConsultButton";
import { RequestConsultButton } from "CollaborativeCare/CaseConsult/RequestConsult";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { consultRequestReasonT } from "GeneratedGraphQL/EnumTranslations";
import {
  CaseConsult,
  ConsultMeeting,
  ConsultRequest,
  Patient,
  Provider,
  useCaseConsultListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId } from "Lib/Ids";
import Link from "MDS/Link";
import { PropertyTable } from "MDS/PropertyTable";
import Paginator, { initPage } from "Shared/Paginator";
import { useIsMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type CaseConsultCardProps = {
  patient: PickTypename<Patient, "id" | "name">;
  forPreviousEnrollment?: boolean;
  enrollmentId?: EnrollmentId;
};

export function CaseConsultCard(props: CaseConsultCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const testPatientViewability = useTestPatientViewability();

  const [pagination, setPagination] = React.useState(initPage(10));

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        patient: { patientIds: [props.patient.id] },
        enrollmentId: props.enrollmentId,
        testPatient: testPatientViewability,
        ...pagination,
      },
      fetchPolicy: "network-only",
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.collaborativeCareCaseConsults?.pageInfo || null,
    _: () => null,
  });

  const consults = remoteData.caseOf({
    Success: (response) => response.collaborativeCareCaseConsults?.nodes || [],
    _: () => [],
  });

  const content = useIsMobile() ? (
    <PatientCaseConsultBlocks consults={consults} />
  ) : (
    <PatientCaseConsultTable consults={consults} />
  );

  const cardAction = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <RequestConsultButton patient={props.patient} buttonMinWidth="0" />
  );

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:caseConsult.consultList.title")} action={cardAction} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          {content}
          <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
        </Stack>
      </CardContent>
    </Card>
  );
}

type PatientWithName = PickTypename<Patient, "id" | "name">;
type ProviderWithName = PickTypename<Provider, "id" | "name">;
type ConsultRequestSummary = PickTypename<
  ConsultRequest,
  "id" | "requestReason" | "requestText" | "requestDate"
> & {
  provider: ProviderWithName;
};
type ConsultMeetingSummary = PickTypename<ConsultMeeting, "id" | "status" | "startTime">;
type CaseConsultSummary = PickTypename<CaseConsult, "id" | "status" | "caseSummary" | "presentationOrder"> & {
  patient: PatientWithName;
  consultRequests: {
    nodes: ReadonlyArray<ConsultRequestSummary>;
  };
  consultMeeting: ConsultMeetingSummary | null;
};

type PatientCaseConsultListProps = {
  consults: ReadonlyArray<CaseConsultSummary>;
};

function PatientCaseConsultTable(props: PatientCaseConsultListProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestTime")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.consults.map((consult, i) => (
          <PatientCaseConsultTableRow consult={consult} key={i} />
        ))}
      </TableBody>
    </Table>
  );
}

function PatientCaseConsultTableRow(props: { consult: CaseConsultSummary }): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);
  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  const meetingLink = props.consult.consultMeeting ? (
    <Link to={`/app/cocm/case-consult/meeting/${props.consult.consultMeeting.id}/case/${props.consult.id}`}>
      <Button variant="contained" color="secondary">
        {t("collaborativeCare:caseConsult.consultMeeting.discussedCases.viewCase")}
      </Button>
    </Link>
  ) : null;

  return (
    <TableRow>
      <TableCell>
        {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueShort", {
          date: sortedRequests[0]?.requestDate,
        })}
      </TableCell>
      <TableCell>
        {sortedRequests
          .map((request) => `${request.provider.name} (${consultRequestReasonT(request.requestReason, t)})`)
          .join(", ")}
      </TableCell>
      <TableCell>
        <PrepareConsultButton consult={props.consult} />
      </TableCell>
      <TableCell>
        <CompleteConsultButton consult={props.consult} />
      </TableCell>
      <TableCell>{meetingLink}</TableCell>
    </TableRow>
  );
}

function PatientCaseConsultBlocks(props: PatientCaseConsultListProps): ReactElement {
  return (
    <Stack direction="column" spacing={2} divider={<Divider />}>
      {props.consults.map((consult, i) => (
        <PatientCaseConsultBlock consult={consult} key={i} />
      ))}
    </Stack>
  );
}

function PatientCaseConsultBlock(props: { consult: CaseConsultSummary }): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  const meetingLink = props.consult.consultMeeting ? (
    <Link to={`/app/cocm/case-consult/meeting/${props.consult.consultMeeting.id}/case/${props.consult.id}`}>
      <Button variant="contained" color="secondary">
        {t("collaborativeCare:caseConsult.consultMeeting.discussedCases.viewCase")}
      </Button>
    </Link>
  ) : null;

  return (
    <Stack direction="column" spacing={0.5}>
      <PropertyTable>
        <TableBody>
          <TableRow>
            <TableCell>{t("collaborativeCare:caseConsult.consultList.fields.requestTime")}</TableCell>
            <TableCell>
              {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueShort", {
                date: sortedRequests[0]?.requestDate,
              })}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}</TableCell>
            <TableCell>
              {sortedRequests
                .map(
                  (request) => `${request.provider.name} (${consultRequestReasonT(request.requestReason, t)})`
                )
                .join(", ")}
            </TableCell>
          </TableRow>
        </TableBody>
      </PropertyTable>
      <Stack direction="row" spacing={1}>
        <PrepareConsultButton consult={props.consult} />
        <CompleteConsultButton consult={props.consult} />
        {meetingLink}
      </Stack>
    </Stack>
  );
}
