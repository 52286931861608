import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import RouterWithNotFound from "../../../../../Shared/RouterWithNotFound";
import OrganizationSettings from "./OrganizationSettings";

function OrganizationRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<OrganizationSettings />} path=":organizationId/*" />
    </RouterWithNotFound>
  );
}

export default OrganizationRoutes;
