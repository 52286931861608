import {
  UseFetchResultType,
  authenticate,
  getAppSession,
  useCurrentInstituteLoginDetails,
  useFetchOnce,
} from "AppSession/AppSession";
import { RawProviderAuthenticationData, parse } from "AppSession/AuthenticatedProviderUser";
import { InstituteId } from "Lib/Ids";
import { useQueryStringOptionalStringParameter } from "Shared/QueryStringParameter";
import SimpleLandingPage from "Shared/SimpleLandingPage";
import Spinner from "Shared/Spinner";
import { EMBER_AUTH_LOCAL_STORAGE_KEY } from "Shared/Storage";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type LocalStorageAuthenticationDataType = RawProviderAuthenticationData & {
  authenticator: "authenticator:sso";
};

//====================================================================================================
// DEVELOPMENT NOTE
//
// If you are developing on this, you may find that you get weird behavior where you get double
// logged in. This is caused by StrictMode rendering twice. There may be a better solution, but
// for now, just go into src/index.tsx and disable StrictMode.
//
// Note that explicitly double executing effects is part of StrictMode, and can't be stopped
// by any use of effects. We could probably do something weird around caching outside the framework
// but it seems dangerous to do change prod pathways just for development.
//====================================================================================================

// We need the two level affair so that we bootstrap adequately to get the institute id before we can send the sso request.
function SsoLandingPageInternal(props: { instituteId: InstituteId }) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  const [error, setError] = React.useState(false);

  const [ssoToken] = useQueryStringOptionalStringParameter("ssoToken");
  const [destination] = useQueryStringOptionalStringParameter("destination");

  // If we don't have an explicit landing page in mind, just go to the 'sort it out' page
  const targetUrl = destination ?? "/app/landing-page";

  // We need to make a request to our login endpoint.
  // Here's the actual data we'll send.
  const postData = {
    user: {
      ssoToken,
      institute_id: props.instituteId,
    },
    provider: "provider",
  };

  // Make the actual request.
  const remoteData = useFetchOnce(
    "/users/sign_in",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    },
    [ssoToken ?? "unknown"]
  );

  // Here's all of the handling for the possible results from our fetch.
  remoteData.caseOf({
    _: () => {},
    Failure: () => {
      if (!error) {
        setError(true);
      }
    },
    Success: (result: UseFetchResultType) => {
      // Now we can actually get out login result back.
      const responseData: LocalStorageAuthenticationDataType =
        result.json as LocalStorageAuthenticationDataType;

      // We still need to manually add in the field value, thanks typescript.
      responseData.authenticator = "authenticator:sso";

      // Now we can generate the correct localStorage shape and set our local storage entry.
      const localStorageSessionJson = {
        authenticated: responseData,
      };
      localStorage.setItem(EMBER_AUTH_LOCAL_STORAGE_KEY, JSON.stringify(localStorageSessionJson));

      // We also need to authenticate our app session or routes will get weird.
      authenticate(getAppSession(), parse(responseData));

      if (targetUrl.startsWith("/app")) {
        navigate(targetUrl);
      } else {
        window.location.href = targetUrl;
      }
    },
  });

  if (error) {
    return (
      <SimpleLandingPage
        title={t("common:ssoLanding.errorTitle")}
        subtitle={t("common:ssoLanding.errorSubtitle")}
      />
    );
  }

  return (
    <SimpleLandingPage title={t("common:ssoLanding.title")} subtitle={t("common:ssoLanding.subtitle")} />
  );
}

export default function SsoLandingPage() {
  const instituteLoginDetails = useCurrentInstituteLoginDetails();

  if (instituteLoginDetails.id) {
    return <SsoLandingPageInternal instituteId={instituteLoginDetails.id} />;
  } else {
    return <Spinner />;
  }
}
