import React, { MouseEvent, ReactElement, useContext } from "react";
import {
  CurrentInstituteDetailsFragment,
  DataSourceResourceType,
  PatientQualityIssue,
  PatientQualityIssueStatus,
  PatientQualityIssueType,
  UpdatePatientQualityIssueMutation,
  useUpdatePatientQualityIssueMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper, MutationRemoteDataResult } from "Api/GraphQL";
import { Button, ButtonProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dig } from "type-utils";
import { Loading, Maybe } from "seidr";
import { add } from "date-fns";
import CurrentInstituteContext, { hasEnabledDataSourceResource } from "Contexts/CurrentInstituteContext";
import { statusToSuperStatus } from "./PatientQualityIssue";
import { patientQualityIssueStatusT } from "GeneratedGraphQL/EnumTranslations";

export type EditQualityIssueProps = {
  providerName: string | null | undefined;
  patientQualityIssue: Pick<
    PatientQualityIssue,
    "id" | "issue" | "status" | "notes" | "statusActiveUntil" | "resolvedAt"
  >;
  note?: string;
  onEdit?: (newStatus: PatientQualityIssueStatus) => void;
};

// Type alias for the final result type with the object you care about returned from the server
export type UpdatePatientQualityIssueResult = Dig<
  UpdatePatientQualityIssueMutation,
  ["updatePatientQualityIssue", "result"]
> | null;

function EditQualityIssueHookWrapper(props: EditQualityIssueProps): ReactElement {
  const [mutateFunction, { remoteData }] = apolloMutationHookWrapper((data) => {
    return data.updatePatientQualityIssue;
  }, useUpdatePatientQualityIssueMutation());

  const submitAction = (status: PatientQualityIssueStatus) => {
    return () => {
      mutateFunction({
        variables: {
          input: {
            patientQualityIssueId: props.patientQualityIssue.id,
            status: status,
            notes: props.note,
            statusActiveUntil: add(new Date(), { days: 7 }),
          },
        },
      });

      if (props.onEdit) {
        props.onEdit(status);
      }
    };
  };

  return <EditQualityIssue makeSubmitHandler={submitAction} remoteData={remoteData} {...props} />;
}

type EditQualityIssueDataProps = {
  remoteData: MutationRemoteDataResult<UpdatePatientQualityIssueResult>;
  makeSubmitHandler: (status: PatientQualityIssueStatus) => () => void;
} & EditQualityIssueProps;

function EditQualityIssue(props: EditQualityIssueDataProps): ReactElement {
  const superStatus = statusToSuperStatus(props.patientQualityIssue.status);
  switch (superStatus) {
    case "active": {
      return <UnresolvedItemActions {...props} />;
    }
    case "pending": {
      return <PendingItemActions {...props} />;
    }
    case "resolved": {
      return <ResolvedItemActions {...props} />;
    }
  }
}

function resolutionByIssueType(
  issue: PatientQualityIssueType,
  currentInstitute: Maybe<CurrentInstituteDetailsFragment>
) {
  switch (issue) {
    case PatientQualityIssueType.AGE_INCORRECT:
    case PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO:
      if (hasEnabledDataSourceResource(currentInstitute, DataSourceResourceType.PATIENT)) {
        return PatientQualityIssueStatus.FIXED_IN_EHR;
      } else {
        return PatientQualityIssueStatus.RESOLVED;
      }
    case PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO:
    case PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER:
      if (hasEnabledDataSourceResource(currentInstitute, DataSourceResourceType.RELATED_PERSON)) {
        return PatientQualityIssueStatus.FIXED_IN_EHR;
      } else {
        return PatientQualityIssueStatus.RESOLVED;
      }
    case PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN:
    case PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO:
    case PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN:
    case PatientQualityIssueType.PATIENT_NEVER_OPENED:
    case PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD:
    case PatientQualityIssueType.CAREGIVER_NEVER_OPENED:
      return PatientQualityIssueStatus.RESOLVED;
  }
}

function UnresolvedItemActions(props: EditQualityIssueDataProps): ReactElement {
  const currentInstitute = useContext(CurrentInstituteContext);
  const { t } = useTranslation(["qualityIssues"]);
  const { note, patientQualityIssue } = props;

  if (props.patientQualityIssue.status === PatientQualityIssueStatus.RESOLVED) {
    return <ResolvedItemActions {...props} />;
  }

  const resolution = resolutionByIssueType(props.patientQualityIssue.issue, currentInstitute);

  let saveNote = null;

  if (note && note !== patientQualityIssue.notes) {
    const buttonText = t(patientQualityIssue.notes ? "qualityIssues:updateNote" : "qualityIssues:saveNote");
    saveNote = (
      <ActionButton
        onClick={props.makeSubmitHandler}
        remoteData={props.remoteData}
        status={props.patientQualityIssue.status}
        text={buttonText}
        variant="outlined"
      />
    );
  }

  return (
    <>
      {saveNote}
      <ActionButton
        onClick={props.makeSubmitHandler}
        remoteData={props.remoteData}
        status={PatientQualityIssueStatus.WONT_FIX}
        variant="outlined"
      />
      <ActionButton
        onClick={props.makeSubmitHandler}
        remoteData={props.remoteData}
        status={PatientQualityIssueStatus.SNOOZE}
        variant="outlined"
      />
      <ActionButton
        onClick={props.makeSubmitHandler}
        remoteData={props.remoteData}
        status={resolution}
        variant="contained"
      />
    </>
  );
}

function PendingItemActions(props: EditQualityIssueDataProps) {
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <ActionButton
      onClick={props.makeSubmitHandler}
      remoteData={props.remoteData}
      status={PatientQualityIssueStatus.UNRESOLVED}
      variant="contained"
      text={t("qualityIssues:actions.unsnooze")}
    />
  );
}

function ResolvedItemActions(props: EditQualityIssueDataProps) {
  return (
    <ActionButton
      onClick={props.makeSubmitHandler}
      remoteData={props.remoteData}
      status={PatientQualityIssueStatus.UNRESOLVED}
      variant="contained"
    />
  );
}

function ActionButton(props: {
  status: PatientQualityIssueStatus;
  remoteData: MutationRemoteDataResult<UpdatePatientQualityIssueResult>;
  onClick: (status: PatientQualityIssueStatus) => () => void;
  variant?: ButtonProps["variant"];
  text?: string;
}) {
  const { status, onClick, remoteData } = props;
  const { t } = useTranslation(["common", "enums"]);

  const buttonText = props.text ? props.text : patientQualityIssueStatusT(status, t);

  const handleClick = (event: MouseEvent) => {
    // Normally we don't need to bother with this sort of thing in React, but it's the quickest way out here. There's an
    // onClick handler on the card header that flips that collapsed/expanded state. When the "Reopen" button is shown,
    // it's technically in expanded, and the button is in the header, so clicking it fires that handler as well, putting
    // the card back into collapsed, which we don't want. stopPropagation here keeps the event from bubbling up to the
    // card header element, so we can skip that round of state changes and see the expanded card.
    event.stopPropagation();
    onClick(status)();
  };

  return (
    <Button variant={props.variant} disabled={remoteData.equals(Loading())} onClick={handleClick}>
      {remoteData.caseOf({
        Loading: () => t("common:saving"),
        _: () => buttonText,
      })}
    </Button>
  );
}

export default EditQualityIssueHookWrapper;
export { EditQualityIssueHookWrapper as HookWrapper, EditQualityIssue as Component };
