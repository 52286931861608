import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { ChipBase } from "Layout/Chip";
import { Link } from "MDS/Link";
import React, { ReactElement, ReactNode } from "react";

type ScaleChipBaseProps = {
  children: ReactNode;
  backgroundColor?: string;
  color?: string;
  width?: number;
  border?: string;
  onClickRoute?: FeedbackReportRoute;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleChipBase(props: ScaleChipBaseProps): ReactElement {
  const { onClickRoute, children, backgroundColor, color, width, chipTooltip, border } = props;

  const chip = (
    <ChipBase
      backgroundColor={backgroundColor}
      color={color}
      width={width}
      border={border}
      chipTooltip={chipTooltip}
    >
      {children}
    </ChipBase>
  );

  return onClickRoute ? <Link to={onClickRoute}>{chip}</Link> : chip;
}

export { ScaleChipBase };
