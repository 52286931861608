import { Box, IconButton, InputAdornment, Stack, TextField } from "@mui/material";
import DatePicker from "./DatePickers";
import { useTranslation } from "react-i18next";
import { ClearIcon, DateView } from "@mui/x-date-pickers";

const defaultViews: ReadonlyArray<DateView> = ["day", "month", "year"];

// Note that we don't use the DateRangeInput directly because for some obtuse
// reason it types everything as a string on the input object. You should use
export type DateRangeParams = {
  min?: Date;
  max?: Date;
};

export function OptionalDateRangeSelect(props: {
  dateRange: DateRangeParams | null;
  setDateRange: (value: DateRangeParams | null) => void;
  startLabel?: string;
  endLabel?: string;
  views?: ReadonlyArray<DateView>;
  format?: string;
}) {
  const { t } = useTranslation(["common"]);

  const startLabel = props.startLabel ?? t("common:dateRange.startDate");
  const endLabel = props.endLabel ?? t("common:dateRange.endDate");
  const views = props.views ?? defaultViews;

  const updateStartDate = (newValue: Date | null) => {
    if (!newValue && !props.dateRange?.max) {
      props.setDateRange(null);
    }
    props.setDateRange({ ...props.dateRange, min: newValue ?? undefined });
  };

  const updateEndDate = (newValue: Date | null) => {
    if (!newValue && !props.dateRange?.min) {
      props.setDateRange(null);
    }
    props.setDateRange({ ...props.dateRange, max: newValue ?? undefined });
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box minWidth="7em">
        <DatePicker
          label={startLabel}
          views={views}
          // Needs to be null not undefined to stay in controlled mode
          value={props.dateRange?.min ?? null}
          maxDate={props.dateRange?.max}
          onChange={updateStartDate}
          slots={{ textField: TextField }}
          slotProps={{
            textField: ({ inputProps, InputProps }) => ({
              ...inputProps,
              InputProps: {
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {props.dateRange?.min && (
                      <IconButton onClick={() => updateStartDate(null)} edge="end" size="small">
                        <ClearIcon />
                      </IconButton>
                    )}
                    {InputProps?.endAdornment} {/* Keeps the calendar icon */}
                  </InputAdornment>
                ),
              },
            }),
          }}
        />
      </Box>
      <Box minWidth="7em">
        <DatePicker
          label={endLabel}
          views={views}
          // Needs to be null not undefined to stay in controlled mode
          value={props.dateRange?.max || null}
          minDate={props.dateRange?.min}
          onChange={updateEndDate}
          slots={{ textField: TextField }}
          slotProps={{
            textField: ({ inputProps, InputProps }) => ({
              ...inputProps,
              InputProps: {
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {props.dateRange?.max && (
                      <IconButton onClick={() => updateEndDate(null)} edge="end" size="small">
                        <ClearIcon />
                      </IconButton>
                    )}
                    {InputProps?.endAdornment} {/* Keeps the calendar icon */}
                  </InputAdornment>
                ),
              },
            }),
          }}
        />
      </Box>
    </Stack>
  );
}
