import React, { ReactElement, ReactNode } from "react";
import { Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";

type InfoSidebarCardType = {
  title: string;
  action?: ReactNode;
  children: ReactNode;
  header?: ReactNode;
  backgroundColor?: string;
};

export default function InfoSidebarCard(props: InfoSidebarCardType): ReactElement {
  const style = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {};

  return (
    <Card sx={style}>
      <CardHeader title={props.title} action={props.action} />
      <CardContent>
        <Stack spacing={1}>
          {props.header}
          <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
            {props.children}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function LabelAndInfo({ label, data }: { label: string; data: string | null }): ReactElement {
  return <LabelAndRichInfo data={<Typography>{data}</Typography>} label={label} />;
}

export function LabelAndRichInfo({
  label,
  data,
}: {
  label: ReactElement | string;
  data: ReactElement | string | null;
}): ReactElement {
  return (
    <>
      <Grid item sm={4} xs={12} key="">
        <Typography variant="h3">{label}</Typography>
      </Grid>
      <Grid item sm={8} xs={12}>
        {data}
      </Grid>
    </>
  );
}
