import {
  Checkbox,
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { InstituteId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { OnDesktop, OnMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useInstituteHasGroups } from "Contexts/CurrentInstituteContext";
import {
  CurrentInstitute,
  InstituteGroup,
  useInstituteGroupsListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useSelectedInstituteGroups } from "Contexts/ActiveInstituteGroupContext";
import CategoryIcon from "@mui/icons-material/Category";

type GroupDetails = Pick<InstituteGroup, "id" | "name"> & {
  members: ReadonlyArray<Pick<CurrentInstitute, "id" | "name">>;
};

export function GlobalInstituteGroupIndicatorBadge(): ReactElement | null {
  const { t } = useTranslation(["instituteGroups"]);
  const theme = useTheme();
  const groupSelection = useSelectedInstituteGroups();
  const groupsEnabled = useInstituteHasGroups();

  const { remoteData } = apolloQueryHookWrapper(
    useInstituteGroupsListQuery({ skip: !groupsEnabled, context: { instituteLeader: true } })
  );
  const groups: ReadonlyArray<GroupDetails> = remoteData.caseOf({
    Success: (response) => {
      if (response.instituteGroups?.nodes) {
        return response.instituteGroups.nodes;
      }
      return [];
    },
    _: () => [],
  });

  if (!groupsEnabled) {
    return null;
  }

  const selectedIds = groupSelection.institutes;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addRemoveInstituteFromSelection = (instituteId: InstituteId) => {
    if (selectedIds.includes(instituteId)) {
      groupSelection.setInstituteIds(selectedIds.filter((id) => id !== instituteId));
    } else {
      groupSelection.setInstituteIds([...selectedIds, instituteId]);
    }
  };

  // If all selected, select none. If anything else, select all.
  const addRemoveWholeGroup = (group: GroupDetails) => {
    return () => {
      const thisGroupIds = group.members.map((institute) => institute.id);
      const allSelected = thisGroupIds.length > 0 && thisGroupIds.every((id) => selectedIds.includes(id));

      if (allSelected) {
        groupSelection.setInstituteIds(selectedIds.filter((id) => !thisGroupIds.includes(id)));
      } else {
        groupSelection.setInstituteIds([...selectedIds, ...thisGroupIds]);
      }
    };
  };

  // Being slightly tricky with the colors here so they work on both desktop (light background) and mobile (dark
  // background). Transparent background and inherit text let the disabled color scheme adapt to its surroundings.
  const backgroundColor =
    groupSelection.mode === "selected-institutes"
      ? theme.palette.collaborativeCare.panels.main
      : "transparent";
  const textColor =
    groupSelection.mode === "selected-institutes" ? theme.palette.collaborativeCare.panels.text : "inherit";

  const menuItems = groups.map((group) => {
    const thisGroupIds = group.members.map((institute) => institute.id);
    const allSelected = thisGroupIds.length > 0 && thisGroupIds.every((id) => selectedIds.includes(id));

    if (thisGroupIds.length === 0) {
      return (
        <React.Fragment key={group.id.toString()}>
          <MenuItem>
            <ListItemIcon>
              <Checkbox checked={false} disabled />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h2">{group.name}</Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ whiteSpace: "collapse" }}>
            <ListItemText>{t("instituteGroups:groupHasNoMembers")}</ListItemText>
          </MenuItem>
        </React.Fragment>
      );
    }

    return [
      <MenuItem key={group.id.toString()} onClick={addRemoveWholeGroup(group)}>
        <ListItemIcon>
          <Checkbox checked={allSelected} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h2">{group.name}</Typography>
        </ListItemText>
      </MenuItem>,
      ...group.members.map((institute) => (
        <MenuItem
          key={`${group.id}-${institute.id}`}
          onClick={() => addRemoveInstituteFromSelection(institute.id)}
          sx={{ whiteSpace: "collapse" }}
        >
          <ListItemIcon>
            <Checkbox checked={selectedIds.includes(institute.id)} />
          </ListItemIcon>
          <ListItemText>{institute.name}</ListItemText>
        </MenuItem>
      )),
      <Divider key={`${group.id}-divider`} />,
    ];
  });

  let explanationItem = null;

  if (groupSelection.mode === "selected-institutes") {
    explanationItem = <MenuItem>{t("instituteGroups:selectedInstitutesExplanation")}</MenuItem>;
  } else if (groupSelection.mode === "institute-scoped") {
    explanationItem = <MenuItem>{t("instituteGroups:instituteScopedExplanation")}</MenuItem>;
  } else {
    explanationItem = <MenuItem>{t("instituteGroups:leaderOnlyExplanation")}</MenuItem>;
  }

  const list = (
    <MenuList>
      {menuItems}
      {explanationItem}
    </MenuList>
  );

  return (
    <>
      <Chip
        icon={<CategoryIcon color="inherit" />}
        label={t("instituteGroups:titleWithCount", { count: groupSelection.institutes.length })}
        clickable={groupsEnabled}
        onClick={handleClick}
        size="medium"
        sx={{
          backgroundColor: backgroundColor,
          color: textColor,
          fontWeight: "bold",
          fontSize: "1rem",
          // currentColor matches the current _text_ color, so the border always has a contrast with the background
          // whether it's on light or dark.
          border: "solid currentColor 1px",
        }}
      />
      <OnDesktop>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {list}
        </Menu>
      </OnDesktop>
      <OnMobile>
        <ResponsiveDialog open={open} title={t("instituteGroups:selectInstituteGroup")} onClose={handleClose}>
          {list}
        </ResponsiveDialog>
      </OnMobile>
    </>
  );
}
