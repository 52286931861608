import { styled } from "@mui/material/styles";
import { Link } from "MDS/Link";
import * as NEL from "Lib/NonEmptyList";
import { dateIsStale } from "Lib/Staleness";
import { last } from "Lib/Utils";
import React, { ReactElement } from "react";
import { Nothing } from "seidr";
import { isCareEpisodeComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { ScaleCompletionChip } from "Shared/Scale/ScaleCompletionChip";
import { SeverityChip } from "Shared/Scale/SeverityChip";
import TrendIndicator from "Shared/Scale/TrendIndicator";
import { firstPreviousLast, getDate } from "Shared/Severity/Severity";
import { CompareGraph } from "Shared/Viz/CompareLineGraph";
import ParticipantAvatar from "../../ParticipantAvatar";
import { CompareGraphSummary } from "./CompareGraphs";

const StyledGraphTableRow = styled("tr")(({ theme }) => ({
  "> td": {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing(0.5)} 0`,
  },

  "&:last-of-type": {
    "> td": {
      borderBottomColor: "transparent",
    },
  },

  "&:hover": {
    "& .hoverMap": {
      display: "initial",
    },
    "& .basicMap": {
      display: "none",
    },
  },
}));

const ParticipantWrapper = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));

const GRAPH_DEFAULTS = {
  height: 100,
  maxWidth: 500,
};

function CompareGraphRow({
  selectedGraph,
  dates,
}: {
  selectedGraph: CompareGraphSummary;
  dates: NEL.NonEmptyList<Date>;
}): ReactElement {
  const chart = (
    <CompareGraph
      dates={dates}
      width={GRAPH_DEFAULTS.maxWidth}
      height={GRAPH_DEFAULTS.height}
      target={selectedGraph}
    />
  );

  const lastDate = NEL.last(dates);

  const chip = selectedGraph.graphData.caseOf({
    Severity: ({ values, routeToScore }) =>
      last(values).map((value) => (
        <SeverityChip
          value={value}
          isStale={dateIsStale(getDate(value), dates)}
          width={150}
          onClickRoute={routeToScore(value)}
        />
      )),
    Categorical: ({ values, routeToScore }) =>
      last(values).map((value) => (
        <SeverityChip
          value={value}
          isStale={dateIsStale(getDate(value), dates)}
          width={150}
          onClickRoute={routeToScore(value)}
        />
      )),
    Completion: ({ completions, routeToScore }) =>
      last(completions).map((completion) => (
        <ScaleCompletionChip
          completion={completion}
          isStale={dateIsStale(completion.date, dates)}
          width={150}
          onClickRoute={routeToScore(completion)}
        />
      )),
  });

  const trend = selectedGraph.graphData.caseOf({
    Severity: ({ values }) => {
      const { first, last } = firstPreviousLast(values);

      if (isCareEpisodeComputedValueDetails(first) && isCareEpisodeComputedValueDetails(last)) {
        return selectedGraph.trend.map((trend) => (
          <TrendIndicator trend={trend} currentValue={last} lastDate={lastDate} firstValue={first} />
        ));
      } else {
        return Nothing();
      }
    },
    Completion: Nothing,
    Categorical: ({ values }) => {
      const { first, last } = firstPreviousLast(values);

      if (isCareEpisodeComputedValueDetails(first) && isCareEpisodeComputedValueDetails(last)) {
        return selectedGraph.trend.map((trend) => (
          <TrendIndicator trend={trend} currentValue={last} lastDate={lastDate} firstValue={first} />
        ));
      } else {
        return Nothing();
      }
    },
  });

  return (
    <StyledGraphTableRow key={selectedGraph.id}>
      <td>{chart}</td>
      <td>
        <table>
          <tbody>
            <tr>
              <td colSpan={2}>
                <ParticipantWrapper>
                  <ParticipantAvatar participant={selectedGraph.participant} />
                </ParticipantWrapper>

                <Link to={selectedGraph.routeToScale}>{selectedGraph.name}</Link>
              </td>
            </tr>
            <tr>
              <td> {chip.getOrElse(null)}</td>
              <td> {trend.getOrElse(null)}</td>
            </tr>
          </tbody>
        </table>
      </td>
    </StyledGraphTableRow>
  );
}

export default CompareGraphRow;
