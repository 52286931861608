import { Theme } from "@mui/material";
import * as d3 from "d3";
import { Maybe, Nothing } from "seidr";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import {
  CareEpisodeScaleScorerSummaryHistoryItem,
  ComputedValueValidity,
  ScaleThresholdClass,
} from "GeneratedGraphQL/SchemaAndOperations";
import { DateQuantizedDatum, quantize } from "Lib/DateQuantizedData";
import * as NEL from "Lib/NonEmptyList";
import { Heatmap } from "Lib/Viz/Heatmap";
import React, { ReactElement } from "react";
import { CareEpisodeComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { GoalAnswer } from "Shared/Scale/Goal";
import { SeveritySupportedValues } from "./Severity";
import * as SeverityThresholdClass from "./SeverityThresholdClass";
import { CareEpisodeScaleScorerSummaryHistoryItemDetails } from "./SummaryHeatmapWithChip";

// Internal interface to make primitive access easy
type HeatmapItem<T> = {
  thresholdClass: ScaleThresholdClass;
  validity?: ComputedValueValidity | null;
  value?: number | null;
  date: Date;
  raw: T;
};

function datumToColor<T>(datum: DateQuantizedDatum<HeatmapItem<T>>, theme: Theme): string {
  // One option is here, we can do something a little different.
  return datum.caseOf({
    Value: (value) => {
      if (value.validity && value.validity == ComputedValueValidity.INVALID) {
        // If this result is invalid, we want no color here and will use a background image instead.
        return SeverityThresholdClass.color(ScaleThresholdClass.UNANSWERED, theme).primary;
      }
      return SeverityThresholdClass.color(value.thresholdClass, theme).primary;
    },
    Blank: () => theme.palette.report.blank.primary,
  });
}

function datumToBackgroundImage<T>(datum: DateQuantizedDatum<HeatmapItem<T>>, theme: Theme): Maybe<string> {
  return datum.caseOf({
    Value: (value) => {
      if (value.validity && value.validity == ComputedValueValidity.INVALID) {
        // If this result is invalid, we want no color here and will use a background image instead.
        return Maybe.fromNullable(
          SeverityThresholdClass.color(ScaleThresholdClass.UNANSWERED, theme).heatmapBackgroundImage
        );
      }
      return Maybe.fromNullable(
        SeverityThresholdClass.color(value.thresholdClass, theme).heatmapBackgroundImage
      );
    },
    Blank: () => Nothing(),
  });
}

function datumToHeightFunction<T>(
  bounds: [number, number]
): (datum: DateQuantizedDatum<HeatmapItem<T>>, maxBarHeight: number) => number {
  return function (datum: DateQuantizedDatum<HeatmapItem<T>>, maxBarHeight: number): number {
    return datum.caseOf({
      Value: (value) =>
        value.value ? d3.scaleLinear().range([0, maxBarHeight]).domain(bounds)(value.value) : 0,
      Blank: () => 0,
    });
  };
}

type SupportedHeatmapTypes = SeveritySupportedValues | CareEpisodeScaleScorerSummaryHistoryItemDetails;

type SeverityHeatmapProps<T extends SupportedHeatmapTypes> = {
  history: ReadonlyArray<T>;
  dates: NEL.NonEmptyList<Date>;
  maxWidth: number;
  idealColumnWidth: number;
  height: number;
  onColumnClickRoute?: (d: T) => FeedbackReportRoute | undefined;
  onColumnHover?: (s: T) => void;
  valueRange?: [number, number];
};

// Generic entry point that contains dispatch for different types
function SeverityHeatmap<T extends SupportedHeatmapTypes>(props: SeverityHeatmapProps<T>): ReactElement {
  const { history, ...passthroughProps } = props;

  // If there are no items it doesn't matter what generic you're doing
  return NEL.fromArray(history).caseOf({
    Nothing: () => (
      // Note that we don't pass through any of the onclick params because they're irrelevant if it's
      // empty
      <CareEpisodeComputedValueSeverityHeatmap
        history={[]}
        dates={passthroughProps.dates}
        height={passthroughProps.height}
        idealColumnWidth={passthroughProps.idealColumnWidth}
        maxWidth={passthroughProps.maxWidth}
      />
    ),
    Just: (nonEmptyHistory) => {
      const dispatchItem = NEL.head(nonEmptyHistory);

      if (dispatchItem.__typename === "CareEpisodeComputedValue") {
        // This is all a bit nasty, because typescript can't infer from the narrowing of the
        // dispatch item that all the other types are now constrained
        const typedProps = props as SeverityHeatmapProps<CareEpisodeComputedValueDetails>;
        return <CareEpisodeComputedValueSeverityHeatmap {...typedProps} />;
      } else if (dispatchItem.__typename === "CareEpisodeScaleScorerSummaryHistoryItem") {
        const typedProps = props as SeverityHeatmapProps<CareEpisodeScaleScorerSummaryHistoryItemDetails>;
        return <CareEpisodeScaleScorerSummarySeverityHeatmap {...typedProps} />;
      } else {
        const typedProps = props as SeverityHeatmapProps<GoalAnswer>;
        return <GoalAnswerSeverityHeatmap {...typedProps} />;
      }
    },
  });
}

function transformGoalAnswer(datum: GoalAnswer): HeatmapItem<GoalAnswer> {
  return {
    thresholdClass: datum.thresholdClass,
    validity: null,
    value: datum.value,
    date: datum.date,
    raw: datum,
  };
}

function transformCareEpisodeComputedValue(
  datum: CareEpisodeComputedValueDetails
): HeatmapItem<CareEpisodeComputedValueDetails> {
  return {
    thresholdClass: datum.thresholdClass == null ? ScaleThresholdClass.UNKNOWN : datum.thresholdClass,
    validity: datum.validity,
    value: datum.value,
    date: datum.date,
    raw: datum,
  };
}

function transformCareEpisodeScaleScorerHistorySummaryItem(
  datum: CareEpisodeScaleScorerSummaryHistoryItem
): HeatmapItem<CareEpisodeScaleScorerSummaryHistoryItem> {
  return {
    thresholdClass: datum.thresholdClass == null ? ScaleThresholdClass.UNKNOWN : datum.thresholdClass,
    validity: ComputedValueValidity.VALID, // We only get valid items out from this endpoint so just set to valid
    value: datum.value,
    date: datum.targetDate,
    raw: datum,
  };
}

function CareEpisodeComputedValueSeverityHeatmap(
  props: SeverityHeatmapProps<CareEpisodeComputedValueDetails>
) {
  const { history, ...passthroughProps } = props;
  const transformedHistory = history.map(transformCareEpisodeComputedValue);

  return <SeverityHeatmapInternal {...passthroughProps} history={transformedHistory} />;
}

function GoalAnswerSeverityHeatmap(props: SeverityHeatmapProps<GoalAnswer>) {
  const { history, ...passthroughProps } = props;
  const transformedHistory = history.map(transformGoalAnswer);

  return <SeverityHeatmapInternal {...passthroughProps} history={transformedHistory} />;
}

function CareEpisodeScaleScorerSummarySeverityHeatmap(
  props: SeverityHeatmapProps<CareEpisodeScaleScorerSummaryHistoryItemDetails>
) {
  const { history, ...passthroughProps } = props;
  const transformedHistory = history.map(transformCareEpisodeScaleScorerHistorySummaryItem);

  return <SeverityHeatmapInternal {...passthroughProps} history={transformedHistory} />;
}

type SeverityHeatmapInternalProps<T extends SupportedHeatmapTypes> = {
  history: ReadonlyArray<HeatmapItem<T>>;
  dates: NEL.NonEmptyList<Date>;
  maxWidth: number;
  idealColumnWidth: number;
  height: number;
  onColumnClickRoute?: (d: T) => FeedbackReportRoute | undefined;
  onColumnHover?: (s: T) => void;
  valueRange?: [number, number];
};

// The internal version requires the data to be translated to a common format
function SeverityHeatmapInternal<T extends SupportedHeatmapTypes>(
  props: SeverityHeatmapInternalProps<T>
): ReactElement {
  const {
    maxWidth,
    height,
    idealColumnWidth,
    history,
    dates,
    onColumnHover,
    onColumnClickRoute,
    valueRange,
  } = props;

  const datumToHeight = valueRange ? datumToHeightFunction(valueRange) : undefined;

  const quantized = quantize(dates, history);

  const onColumnHoverPassthrough = (column: DateQuantizedDatum<HeatmapItem<T>>) => {
    if (onColumnHover) {
      return column.caseOf({
        Value: (datum) => onColumnHover(datum.raw),
        Blank: () => {
          return undefined;
        },
      });
    }

    return undefined;
  };

  const onColumnClickRoutePassthrough = (column: DateQuantizedDatum<HeatmapItem<T>>) => {
    if (onColumnClickRoute) {
      return column.caseOf({
        Value: (datum) => onColumnClickRoute(datum.raw),
        Blank: () => {
          return undefined;
        },
      });
    }
    return undefined;
  };

  return (
    <Heatmap
      maxWidth={maxWidth}
      height={height}
      idealColumnWidth={idealColumnWidth}
      datumToColor={datumToColor}
      data={quantized}
      onColumnClickRoute={onColumnClickRoutePassthrough}
      onColumnHover={onColumnHoverPassthrough}
      datumToHeight={datumToHeight}
      datumToBackgroundImage={datumToBackgroundImage}
    />
  );
}

export { CareEpisodeComputedValueSeverityHeatmap, GoalAnswerSeverityHeatmap };

export default SeverityHeatmap;
