import { Route, Routes, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import NotFound from "Shared/NotFound";
import { fromNullableString } from "Lib/Id";
import { EntityType } from "GeneratedGraphQL/SchemaAndOperations";
import ImplementationTargetsDashboard from "./ImplementationTargetsDashboard";
import ImplementationTargetDetails from "./ImplementationTargetDetails";
import CreateImplementationTarget from "./CreateImplementationTarget";
import EditImplementationTarget from "./EditImplementationTarget";
import {
  IMPLEMENTATION_TARGETS_FLAG,
  WithFrontendFlag,
  WithoutFrontendFlag,
} from "Contexts/FrontendFlagContext";
import { WithPermission, WithoutPermission } from "Shared/WithPermission";

/**
 * Only allow certain entity types in the outcomes system. No patients/care episodes etc
 */
const allowedEntityTypes = [
  EntityType.INSTITUTE_GROUP,
  EntityType.INSTITUTE,
  EntityType.ORGANIZATION,
  EntityType.PROVIDER,
  EntityType.TREATMENT_SERVICE,
  EntityType.PANEL,
];

function ImplementationTargetRoute(): ReactElement | null {
  const params = useParams<{ implementationTargetId: string }>();

  return fromNullableString<"ImplementationTarget">(params.implementationTargetId).caseOf({
    Ok: (id) => <ImplementationTargetDetails id={id} />,
    Err: () => <NotFound />,
  });
}

function EditImplementationTargetRoute(): ReactElement | null {
  const params = useParams<{ implementationTargetId: string }>();

  return fromNullableString<"ImplementationTarget">(params.implementationTargetId).caseOf({
    Ok: (id) => <EditImplementationTarget implementationTargetId={id} />,
    Err: () => <NotFound />,
  });
}

/**
 * Provides full routing for implementation targets
 * @returns routes
 */
export function ImplementationRoutes(): ReactElement {
  return (
    <>
      <WithFrontendFlag flagName={IMPLEMENTATION_TARGETS_FLAG}>
        <WithPermission permission="viewImplementationTargets">
          <Routes>
            <Route element={<ImplementationTargetRoute />} path="/:implementationTargetId" />
            <Route element={<EditImplementationTargetRoute />} path="/:implementationTargetId/edit" />
            <Route element={<CreateImplementationTarget />} path="/create" />
            <Route element={<ImplementationTargetsDashboard />} path="/" />
          </Routes>
        </WithPermission>
        <WithoutPermission permission="viewImplementationTargets">
          <Routes>
            <Route element={<NotFound />} path="*" />
          </Routes>
        </WithoutPermission>
      </WithFrontendFlag>
      <WithoutFrontendFlag flagName={IMPLEMENTATION_TARGETS_FLAG}>
        <Routes>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </WithoutFrontendFlag>
    </>
  );
}

export { allowedEntityTypes };
