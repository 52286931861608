import { Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  Metric,
  MetricParams,
  MetricScaleScorerConfigurationPayload,
  MetricStatus,
  ScaleScorerConfigurationQuery,
  useOutcomesMetricDetailsQuery,
  useScaleScorersConfigurationQuery,
  useUpdateOutcomesMetricMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { allowedEntityTypes } from "Outcomes";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OutcomesTransientMetricComputedValueTable from "./OutcomesTransientMetricComputedValuesTable";
import PreviewMetricStats from "./PreviewMetricStats";
import CreateMetricForm from "./CreateMetricForm";
import { assertNonNull } from "Lib/Utils";
import { FormOverlay, TextFieldMethods, useTextField } from "Shared/Form";
import {
  MetricFormParams,
  defaultsToParams,
  ensureMetricParams,
  scaleScorerConfigToParams,
  scaleScorerDetailsToSupportedOptions,
  scaleScorerIdsFromParams,
} from "./OutcomesFormHelpers";
import { Success } from "seidr";
import { TimeBasedMetricConfiguration, TriggerBasedMetricConfiguration } from "./OutcomesMetricCard";
import { MetricId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import OutcomesTransientMetricBreakdown from "./OutcomesTransientMetricBreakdown";
import MetricRollupAndScalesForm from "./MetricRollupAndScalesForm";
import OutcomesTransientMetricMultiScaleBreakdown from "./OutcomesTransientMetricMultiScaleBreakdown";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyEntityTreeNodeParameter,
  useStickyMonthParameter,
} from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_NAMES, STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import { EntityTreeNodeDetails, entityTreeNodeToParams } from "Entities/EntityPath";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import Link from "MDS/Link";

export type ScaleScorerConfiguration = ScaleScorerConfigurationQuery["assessmentScaleScorer"];

function FormNoScaleScorer() {
  const { t } = useTranslation(["outcomes", "common"]);
  return (
    <Card>
      <CardHeader title={t("outcomes:create.configureMetricTitle")} />
      <CardContent>
        <Typography style={{ textAlign: "center" }}>{t("outcomes:create.chooseScorerHelp")}</Typography>
      </CardContent>
    </Card>
  );
}

function EditMetricName(props: { field: TextFieldMethods }) {
  const { t } = useTranslation(["outcomes"]);

  return (
    <Card>
      <CardHeader title={t("outcomes:edit.name.title")} />
      <CardContent>
        <TextField
          label={t("outcomes:edit.name.label")}
          onChange={props.field.onChange}
          value={props.field.value}
          placeholder={t("outcomes:edit.name.placeholder")}
          sx={{ width: "100%" }}
        />
      </CardContent>
    </Card>
  );
}

type EditMetricDetails = Pick<Metric, "id" | "name"> & {
  configuration: TimeBasedMetricConfiguration | TriggerBasedMetricConfiguration;
  entityTreeNode: EntityTreeNodeDetails;
};

function EditOutcomesMetric(props: { metric: EditMetricDetails }) {
  const { t } = useTranslation(["common", "outcomes"]);
  const [now, _] = React.useState(new Date());
  const oneYearAgo = startOfMonth(sub(now, { months: 12 }));
  const endOfThisMonth = endOfMonth(now);
  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    oneYearAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    endOfThisMonth,
    "end",
    true
  );
  const defaultEntityTreeNode = entityTreeNodeToParams(props.metric.entityTreeNode);
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    defaultEntityTreeNode,
    true
  );
  const [currentValidParams, setCurrentValidParams] = useState<MetricParams | null>();
  const [scaleScorerConfig, setScaleScorerConfig] = useState<MetricScaleScorerConfigurationPayload | null>(
    scaleScorerConfigToParams(props.metric.configuration.scorerConfig)
  );

  const navigate = useNavigate();

  const { remoteData: scaleScorerConfigData } = apolloQueryHookWrapper(
    useScaleScorersConfigurationQuery({
      // Apollo will only invoke this query if the scale scorer is present, so we can do the coercion even though it's not strictly held.
      skip: !scaleScorerConfig,
      variables: {
        ids: assertNonNull(scaleScorerConfig ? scaleScorerIdsFromParams(scaleScorerConfig) : null),
      },
    })
  );

  const fields = {
    name: useTextField({
      required: true,
      default: props.metric.name,
    }),
  };

  const [updateOutcomesMetric, { remoteData: saveRemoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesUpdateMetric,
    useUpdateOutcomesMetricMutation({
      refetchQueries: ["OutcomesMetrics"],
    })
  );

  const handleChangeStartDate = (newValue: Date) => {
    setStartDate(startOfMonth(newValue));
  };
  const handleChangeEndDate = (newValue: Date) => {
    setEndDate(endOfMonth(newValue));
  };

  const onSave = (formFields: MetricFormParams) => {
    updateOutcomesMetric({
      variables: {
        input: {
          id: props.metric.id,
          name: assertNonNull(fields.name.value),
          status: MetricStatus.ACTIVE,
          entityTreeNode: entityTreeNodeParams,
          payload: ensureMetricParams({
            scaleScorerConfig: assertNonNull(scaleScorerConfig), // we don't show the submit button unless it's valid
            ...formFields,
          }),
        },
      },
      onCompleted: (result) => {
        if (result.outcomesUpdateMetric?.success) {
          // Wait for a bit and the redirect on save success.
          setTimeout(() => {
            navigate("..");
          }, 500);
        }
      },
    });
  };

  const form = scaleScorerConfigData.caseOf({
    Success: (config) => {
      const data = config.assessmentScaleScorers?.nodes;
      const scorerDetails = data ? scaleScorerDetailsToSupportedOptions(data) : null;
      if (data && scorerDetails) {
        return (
          <CreateMetricForm
            scaleScorerSupportedOptions={scorerDetails}
            remoteData={saveRemoteData}
            onSave={onSave}
            defaults={Success(defaultsToParams(props.metric.configuration))}
            errorMessage={t("common:failedToSave")}
            onDataChange={(metricData) => {
              if (metricData && scaleScorerConfig) {
                setCurrentValidParams({ ...metricData, scaleScorerConfig });
              } else {
                setCurrentValidParams(null);
              }
            }}
            submitButtonText={t("outcomes:edit.submitButton")}
            forceFormInvalid={!fields.name.valid || !scaleScorerConfig}
          />
        );
      } else {
        return <FormNoScaleScorer />;
      }
    },
    _: () => {
      return <FormNoScaleScorer />;
    },
  });

  const scaleBreakdown = currentValidParams?.scaleScorerConfig.multiScale ? (
    <Grid item xs={2}>
      <OutcomesTransientMetricMultiScaleBreakdown
        scaleScorerConfigData={scaleScorerConfigData}
        metricParams={currentValidParams}
        entityTreeNode={entityTreeNodeParams}
        startDate={startDate}
        endDate={endDate}
      />
    </Grid>
  ) : null;

  return (
    <Page
      breadcrumbs={[
        <Link to="/app/outcomes" key="outcomes-breadcrumb">
          {t("outcomes:dashboard.title")}
        </Link>,
        <Link to={`/app/outcomes/${props.metric.id}`} key="outcome-details-breadcrumb">
          {props.metric.name}
        </Link>,
        <Typography key="edit-breadcrumb">{t("common:actions.edit")}</Typography>,
      ]}
      browserTitle={t("outcomes:create.title")}
    >
      <FormOverlay response={saveRemoteData} errorMessage={t("common:failedToSave")} />
      <Grid container columns={2} spacing={1}>
        <Grid item lg={1} xs={1}>
          <Stack direction={"column"} spacing={1}>
            <EditMetricName field={fields.name} />
            <MetricRollupAndScalesForm
              defaults={Success(props.metric.configuration.rollup)}
              scaleScorerConfigParams={scaleScorerConfig}
              scaleScorerConfigData={scaleScorerConfigData}
              startDate={startDate}
              endDate={endDate}
              entityTreeNode={entityTreeNodeParams}
              onChange={(value) => {
                if (!value) {
                  // Reset all the stats if you unset the scale scorer
                  setCurrentValidParams(null);
                }
                setScaleScorerConfig(value);
              }}
            />
            {form}
          </Stack>
        </Grid>
        <Grid item lg={1} xs={1} flexGrow={1} flexDirection="column">
          <Stack direction={"column"} spacing={1} flexGrow={1} flexDirection="column" height={"100%"}>
            <Card>
              <CardHeader title={t("outcomes:create.dates")} />
              <CardContent>
                <Stack direction="column" spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label={t("outcomes:create.startMonth")}
                      views={["month", "year"]}
                      openTo={"month"}
                      value={startDate}
                      defaultValue={oneYearAgo}
                      onChange={(newValue: Date) => handleChangeStartDate(newValue)}
                    />
                    <DatePicker
                      label={t("outcomes:create.endMonth")}
                      views={["month", "year"]}
                      openTo={"month"}
                      value={endDate}
                      defaultValue={endOfThisMonth}
                      onChange={(newValue: Date) => handleChangeEndDate(newValue)}
                    />
                  </Stack>

                  <EntityTreeNodeSelect
                    setValue={setEntityTreeNodeParams}
                    entityTypes={allowedEntityTypes}
                    value={entityTreeNodeParams}
                    defaultValue={defaultEntityTreeNode}
                  />
                  <ResetAndStickyFilterButtonGroup
                    onReset={() => {
                      setStartDate(oneYearAgo);
                      setEndDate(endOfThisMonth);
                      setEntityTreeNodeParams(defaultEntityTreeNode);
                    }}
                  />
                </Stack>
              </CardContent>
            </Card>
            <PreviewMetricStats
              endDate={endDate}
              startDate={startDate}
              metricParams={currentValidParams || null}
              entityTreeNodeParams={entityTreeNodeParams}
            />
          </Stack>
        </Grid>
        {scaleBreakdown}
        <Grid item xs={2}>
          <OutcomesTransientMetricBreakdown
            metricParams={currentValidParams || null}
            entityTreeNodeParams={entityTreeNodeParams}
            setEntityTreeNodeParams={setEntityTreeNodeParams}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
        <Grid item xs={2}>
          <OutcomesTransientMetricComputedValueTable
            endDate={endDate}
            entityTreeNodeParams={entityTreeNodeParams}
            metricParams={currentValidParams || null}
            startDate={startDate}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default function EditOutcomesMetricWrapper(props: { id: MetricId }) {
  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesMetricDetailsQuery({
      variables: { id: props.id },
    })
  );

  const { t } = useTranslation(["common"]);

  return remoteData.caseOf({
    Success: (response) => {
      if (response.outcomesMetric) {
        return <EditOutcomesMetric metric={response.outcomesMetric} />;
      } else {
        return <ErrorMessage message={t("common:notFound")} />;
      }
    },
    Loading: () => {
      return <Spinner />;
    },
    Failure: (e) => <ErrorMessage message={e.message} />,
    NotAsked: () => <Spinner />,
  });
}
