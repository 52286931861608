import { Button, Card, CardActions, CardContent, CardHeader, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { FindingDatagram } from "../Findings";
import { useTranslation } from "react-i18next";
import { useCurrentProviderId } from "AppSession/AppSession";
import { DismissAction } from "./Actions/DismissAction";
import { EnrollmentMonthBillingRuleResultWinner, EnrollmentMonthBillingRuleResultWinnerSortParameter, Patient, SortDirection, useEndOfMonthPushQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { dateToMonth } from "Shared/Month";
import { EntityId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import { PickTypename } from "type-utils";
import { RuleResultDetails } from "Billing/BillingWinnerDetailsModal";
import { BillableUnitExplanation, FailReasonsExplanation } from "Billing/BillingRuleHelpers";
import { usePatientLinkUrl } from "FeedbackReport/Demographics/DemographicInfo";
import Link from "MDS/Link";
import { useNavigate } from "react-router-dom";

const NUMBER_OF_WINNERS_TO_DISPLAY = 5;

type EndOfMonthPushFindingProps = {
  finding: FindingDatagram;
};
export function EndOfMonthPushFinding(props: EndOfMonthPushFindingProps): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const navigate = useNavigate();

  const currentProviderId = useCurrentProviderId();

  if (!currentProviderId) {
    return null;
  }

  const { remoteData } = apolloQueryHookWrapper(
    useEndOfMonthPushQuery({
      variables: {
        first: NUMBER_OF_WINNERS_TO_DISPLAY,
        month: dateToMonth(new Date()),
        availableForNode: {
          entityId: props.finding.providerId as unknown as EntityId
        },
        sortBy: EnrollmentMonthBillingRuleResultWinnerSortParameter.RATE_POWER_VS_CURRENT_WINNER,
        sortDirection: SortDirection.DESC
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      if (!response.billingEnrollmentMonthBillingRuleResultWinners?.nodes || 
        response.billingEnrollmentMonthBillingRuleResultWinners.nodes.length == 0) {
        return null;
      }

      const billingRules = response.billingEnrollmentMonthBillingRuleResultWinners.nodes.map((winner) => {
        return <BillingRule key={winner.id.toString()} resultWinner={winner} patient={winner.enrollmentMonth.enrollment.careEpisode.patient} />
      })

      const onInsightsClick = () => {
        navigate("/app/cocm/billing")
      }

      return (
        <Card>
          <CardHeader title={t("collaborativeCare:findings.findings.endOfMonthPush.title")} sx={{ paddingBottom: 0 }} />
          <CardContent sx={{ paddingTop: "0.5em", paddingBottom: "0.5em" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography variant="h3">{t("collaborativeCare:findings.findings.endOfMonthPush.table.patient")}</Typography></TableCell>
                  <TableCell><Typography variant="h3">{t("collaborativeCare:findings.findings.endOfMonthPush.table.nextStep")}</Typography></TableCell>
                  <TableCell><Typography variant="h3">{t("collaborativeCare:findings.findings.endOfMonthPush.table.codes")}</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {billingRules}
              </TableBody>
            </Table>
          </CardContent>
          <CardActions>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" color="secondary" onClick={onInsightsClick}>{t("collaborativeCare:findings.findings.endOfMonthPush.actions.moreBillingInsights")}</Button>
              <DismissAction findingId={props.finding.id} />
            </Stack>
            
          </CardActions>
        </Card >
      );
    },
  });
}

type BillingRuleProps = {
  resultWinner: PickTypename<EnrollmentMonthBillingRuleResultWinner, "id"> & {
    nextResult: RuleResultDetails | null
  }
  patient: PickTypename<Patient, "id" | "name">
}

function BillingRule(props: BillingRuleProps): ReactElement | null {
  const patientUrl = usePatientLinkUrl(props.patient.id);

  if (!props.resultWinner.nextResult) {
    return null;
  }

  return <TableRow>
    <TableCell>
      <Link to={patientUrl} onClick={(event) => event.stopPropagation()}>
        {props.patient.name}
      </Link>
    </TableCell>
    <TableCell>
      <FailReasonsExplanation minutesShort={props.resultWinner.nextResult.minutesShort} 
        failReasonCodes={props.resultWinner.nextResult.failReasonCodes}
        disqualifyReasonCodes={props.resultWinner.nextResult.disqualifyReasonCodes} />
    </TableCell>
    <TableCell><BillableUnitExplanation rule={props.resultWinner.nextResult.rule} /></TableCell>
  </TableRow>
}
