import { Avatar, Badge, CardHeader, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { patientUrl as cocmPatientUrl } from "CollaborativeCare/Utils/patient";
import { Patient, useMbcPatientDetailsHeaderQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId } from "Lib/Ids";
import { initials } from "Lib/Utils";
import Link from "MDS/Link";
import { differenceInYears, format } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { genderIdentityWithFallback } from "Shared/Patient";
import { useIsMobile } from "Shared/Responsive";
import { PatientFlagInfo, PatientFlags } from "Providers/Dashboard/PatientFlags";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { patientUrl as mbcPatientUrl } from "Patients";

type PatientReferenceCardHeaderProps = {
  patientId: PatientId;
  disableCardPadding?: boolean; // Cards automatically add a 16px left padding. We don't want that always.
  disableLink?: boolean;
};
function PatientReferenceCardHeader(props: PatientReferenceCardHeaderProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useMbcPatientDetailsHeaderQuery({
      variables: {
        patientId: props.patientId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => <Typography>Loading</Typography>,
    Failure: () => <Typography>Fail</Typography>,
    Success: (data) => (
      <LoadedHeader
        patient={data.patient}
        disableCardPadding={props.disableCardPadding}
        disableLink={props.disableLink}
      />
    ),
    NotAsked: () => <Typography>Not Asked</Typography>,
  });
}

type LoadedPatient = PickTypename<
  Patient,
  "id" | "dob" | "name" | "gender" | "genderIdentity" | "genderIdentityFhir" | "preferredPronouns" | "isTest"
> & { patientFlags: ReadonlyArray<PatientFlagInfo> };

type LoadedHeaderProps = {
  patient: LoadedPatient | null;
  disableCardPadding?: boolean;
  disableLink?: boolean;
};
function LoadedHeader(props: LoadedHeaderProps): ReactElement {
  const { t } = useTranslation(["enums", "patients", "common", "report"]);

  const patient = props.patient;

  if (patient === null) {
    return <CardHeader title="Failed to find Patient" />;
  }
  const dob =
    patient.dob === null
      ? t("report:demographics.ageUnknown")
      : `${differenceInYears(new Date(), patient.dob)} - ${format(patient.dob, "MM/dd/yyy")}`;

  const sexString = genderIdentityWithFallback(patient, t);
  const pronounsString =
    patient.preferredPronouns === null || patient.preferredPronouns.length === 0
      ? ""
      : `(${patient.preferredPronouns})`;

  const sxOverride = props.disableCardPadding ? { paddingLeft: 0 } : {};

  const subheader = <>{`${sexString} ${pronounsString} - ${dob}`}</>;

  return (
    <CardHeader
      avatar={<PatientAvatar patient={patient} />}
      action={<PatientFlags flags={patient.patientFlags} />}
      title={<PatientTitle patient={patient} disableLink={props.disableLink} />}
      subheader={subheader}
      sx={sxOverride}
    />
  );
}
type PatientTitleProps = {
  patient: LoadedPatient;
  disableLink: boolean | undefined;
};
function PatientTitle(props: PatientTitleProps): ReactElement {
  const patientUrl = useWithFeatureEnabled("enableCollaborativeCare") ? cocmPatientUrl : mbcPatientUrl;
  return props.disableLink ? (
    <Typography variant="h3" sx={{ wordBreak: "break-word" }} marginBottom={"2px"}>
      {props.patient.name}
    </Typography>
  ) : (
    <Link to={patientUrl(props.patient)} sx={{ wordBreak: "break-word" }}>
      {props.patient.name}
    </Link>
  );
}

function PatientAvatar({ patient }: { patient: PickTypename<Patient, "name" | "isTest"> }): ReactElement {
  const { t } = useTranslation(["patients"]);

  // On mobile we're so constrained that the test patient badge will actually cut
  // out of the viewport if placed on the left side.
  // It looks better on the left on desktop, but we'll compromise on mobile and put it on
  // the right so we can see it.
  const testPatientBadgeAnchorHorizontal = useIsMobile() ? "right" : "left";

  return (
    <Badge
      badgeContent={t("patients:referenceHeader.testPatient")}
      color="success"
      anchorOrigin={{ vertical: "top", horizontal: testPatientBadgeAnchorHorizontal }}
      invisible={!patient.isTest}
    >
      <Avatar
        //src="https://lh3.googleusercontent.com/a/AGNmyxbv6ZxrqzYozgyKbxga_xFx-G-YBq9qL4On5nwJ=s192-c-rg-br100"
        aria-label="patient"
      >
        {initials(`${patient.name}`)}
      </Avatar>
    </Badge>
  );
}

export default PatientReferenceCardHeader;
