import * as Id from "Lib/Id";

// The type parameter here is a little bit of a shenanigan, but it lets us treeify organizations without knowing what
// other fields the organization has, but still preserve those fields in the output type. If OrganizationTree didn't
// have a type parameter the output would get treated as though it didn't have anything except id, parentId and children
type Treeable<IdType extends string> = { id: Id.Id<IdType>; parentId: Id.Id<IdType> | undefined | null };
type Tree<T> = T & { children: Array<Tree<T>> };

// Transforms a flat list of organizations into a tree structure suitable for displaying in a Mui TreeView. Note that
// this returns an array of trees rather than a single tree, because it's common for institutes to have more than one
// root organization.
//
// The algorithm here is basically topological sort / breadth-first search, but instead of appending each node to the
// list as we find it, we look up the path to traverse down the tree to its parent, and put it in its parent's children
// collection.
export function treeify<T extends Treeable<Id>, Id extends string>(data: ReadonlyArray<T>): Array<Tree<T>> {
  const orgsById = data.reduce<Record<string, Treeable<Id>>>((map, org) => {
    map[org.id.toString()] = org;
    return map;
  }, {});

  // The queue of organizations to add to the tree. This starts with just the orgs that have no parent, and we add
  // each orgs children after we add the org itself, so each org will always have a home in the tree when it gets
  // pulled out of the queue.
  const toVisit = data.filter((o) => !o.parentId);
  const tree: Array<Tree<T>> = [];

  while (toVisit.length > 0) {
    const [next] = toVisit.splice(0, 1);
    if (!next) {
      throw new Error("somehow we got a bad rotation");
    }
    let parentId = next.parentId;

    // The path of ids to traverse down the tree to find the parent organization for this org.
    const path = [];
    while (parentId) {
      path.push(parentId);
      parentId = orgsById[parentId.toString()]?.parentId;
    }
    // Because we pushed path entries onto the back of the array, reverse it so highest parent is at the front.
    path.reverse();

    // Now walk down the tree to look for the parent at each level of the path.
    let collection = tree;
    for (const id of path) {
      const node = collection.filter((o) => o.id === id)[0];
      collection = node?.children || [];
    }
    collection.push({ ...next, children: [] });

    // Add organizations that are children of this org to the queue.
    data.filter((o) => o.parentId === next.id).forEach((o) => toVisit.push(o));
  }

  return tree;
}

export function ancestry<T extends Treeable<IdType>, IdType extends string>(
  items: ReadonlyArray<T>,
  target: Id.Id<IdType> | null
): Array<string> {
  if (!target) {
    return [];
  }

  const orgsById: Record<string, T> = items.reduce((map: Record<string, T>, item) => {
    map[item.id.toString()] = item;
    return map;
  }, {});

  const ancestry = [];
  let parentId = orgsById[target.toString()]?.parentId;
  while (parentId) {
    ancestry.push(parentId.toString());
    parentId = orgsById[parentId.toString()]?.parentId;
  }

  return ancestry;
}
