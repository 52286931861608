import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  TreatmentService,
  useCreateTreatmentServiceMutation,
  useTreatmentServicesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { TreatmentServiceId } from "Lib/Ids";
import { LinkButton } from "MDS/Link";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, FormOverlay, useRedirectOnSuccess } from "Shared/Form";
import { TreatmentServiceSelectBox } from "./TreatmentServiceSelectBox";
import { refetchQueries } from "Lib/RefetchQueries";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

function CreateTreatmentService(): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  const { remoteData } = apolloQueryHookWrapper(useTreatmentServicesQuery());

  return remoteData.caseOf({
    Loading: () => {
      return <CircularProgress />;
    },
    NotAsked: () => {
      return <CircularProgress />;
    },
    Failure: (_error) => {
      return <>{t("treatmentServices:index.error")}</>;
    },
    Success: (result) => {
      if (result.treatmentServices?.nodes) {
        return <CreateTreatmentServiceElement treatmentServices={result.treatmentServices.nodes} />;
      }
      return <>{t("treatmentServices:index.error")}</>;
    },
  });
}

type CreateTreatmentServicesElementProps = {
  treatmentServices: ReadonlyArray<Pick<TreatmentService, "__typename" | "id" | "name" | "serviceTypes">>;
};

function CreateTreatmentServiceElement(props: CreateTreatmentServicesElementProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // Mutation setup.
  const [createTreatmentService, { remoteData }] = apolloMutationHookWrapper((data) => {
    return data.createTreatmentService;
  }, useCreateTreatmentServiceMutation());

  // Name
  const [name, setName] = useState("");
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // Parent Treatment Service
  const [parentId, setParentId] = useState<TreatmentServiceId | null>(null);
  const onParentIdChangeHandler = (newParentId: TreatmentServiceId | null) => {
    setParentId(newParentId);
  };

  // Measurement Allowed
  const [measurementAllowed, setMeasurementAllowed] = useState(true);
  const onMeasurementAllowedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeasurementAllowed(event.target.checked);
  };

  // Service Types
  const [serviceTypes, setServiceTypes] = useState<Array<string>>([]);
  const onServiceTypesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceTypes(event.target.value.split(","));
  };

  // Create
  const onCreate = () => {
    createTreatmentService({
      variables: {
        input: {
          name: name,
          measurementAllowed: measurementAllowed,
          parentId: parentId,
          serviceTypes: serviceTypes,
        },
      },
      refetchQueries: refetchQueries("treatmentServices"),
    });
  };

  // Don't forget to bring the user back to the index page on success.
  const navigate = useNavigate();
  useRedirectOnSuccess(remoteData, "..", navigate);

  // The actual element and display.
  return (
    <Page browserTitle={t("treatmentServices:create.title")}>
      <Card>
        <CardHeader title={t("treatmentServices:create.title")}></CardHeader>
        <CardContent>
          <Form>
            <Stack spacing={2}>
              <Box width="30em">
                <TextField
                  fullWidth
                  label={t("treatmentServices:create.nameLabel")}
                  variant="outlined"
                  onChange={onNameChange}
                  value={name}
                ></TextField>
              </Box>
              <Box width="30em">
                <TreatmentServiceSelectBox
                  treatmentServices={props.treatmentServices}
                  onChangeHandler={onParentIdChangeHandler}
                />
              </Box>
              <Box width="30em">
                <TextField
                  value={serviceTypes}
                  fullWidth
                  label={t("treatmentServices:create.serviceTypesLabel")}
                  variant="outlined"
                  onChange={onServiceTypesChange}
                  helperText={t("treatmentServices:create.serviceTypesHelpText")}
                ></TextField>
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox checked={measurementAllowed} onChange={onMeasurementAllowedChange} />}
                  label={t("treatmentServices:create.measurementAllowedLabel")}
                />
              </Box>
              <Stack direction="row" spacing={1}>
                <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
                  <Button variant="contained" onClick={onCreate}>
                    {t("treatmentServices:create.createButton")}
                  </Button>
                </ReadOnlyOrImpersonatingDisabledButton>
                <LinkButton variant="contained" to="..">
                  {t("treatmentServices:create.cancelButton")}
                </LinkButton>
              </Stack>
              <FormOverlay response={remoteData} errorMessage={t("treatmentServices:create.error")} />
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Page>
  );
}

export default CreateTreatmentService;
