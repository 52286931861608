import React, { ReactElement } from "react";
import { compact } from "type-utils";
import { fromString } from "Lib/Id";
import { InstituteId } from "Lib/Ids";
import { INSTITUTE_GROUP_STORAGE_KEY } from "Shared/Storage";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { clearCache } from "Api/GraphQL";
import { useCurrentInstituteId } from "./CurrentInstituteIdContext";

/**
 * "leader-only" means that this page should only display data for the the leader group, and not any of the sub groups
 * "selected-institutes" means that this page will show data for the selected institutes
 * "all-institutes" means that this page will show data for all institutes there is access to
 * "not-supported" means that this institute doesn't support viewing institute groups at all
 */
export type InstituteGroupMode = "leader-only" | "selected-institutes" | "institute-scoped";

const DEFAULT_MODE = "selected-institutes";

/**
 *
 * We are building the ability to query multiple institutes worth of data at once on top of our existing infrastructure.
 * The way this works is that special types of institutes have the ability to own 'Institute Groups' which contain other institutes
 *
 * Each page can choose to deal with this in one of three ways: restrict to the leader only, show data from the requested institutes, or
 * show data from all available sources.
 *
 * This is injected via HTTP header so you don't have to generally interact with it when calling requests right now.
 *
 */
type SelectedInstituteGroups = {
  institutes: ReadonlyArray<InstituteId>;
  mode: InstituteGroupMode;
  // We need to have a way of setting the mode from a child provider so that we only
  // get a single render with the appropriate mode.
  setMode: (mode: InstituteGroupMode) => void;
  setInstituteIds: (groups: ReadonlyArray<InstituteId>) => void;
};

const SelectedInstituteGroupsContext = React.createContext<SelectedInstituteGroups>({
  institutes: [],
  mode: DEFAULT_MODE,
  setMode: (_mode) => {
    return;
  },
  setInstituteIds: () => {
    return;
  },
});

export const InstituteGroupContextRef = React.createRef();

export function SelectedInstituteGroupsProvider(props: React.PropsWithChildren): ReactElement {
  const currentInstituteId = useCurrentInstituteId();
  const [instituteIds, setInstituteIds] = React.useState<ReadonlyArray<InstituteId>>(
    getSelectedInstituteGroupsFromStorage()
  );
  const [mode, setMode] = React.useState<InstituteGroupMode>(DEFAULT_MODE);

  const setInstituteIdList = (newInstituteIds: ReadonlyArray<InstituteId>) => {
    // We always include the current institute which is the internal or group institute as otherwise
    // we can't see the groups or other items on the query when we have selected some institutes.
    setInstituteIds([currentInstituteId, ...newInstituteIds]);
    // We need to clear all queries when we change this to get new data.
    clearCache();
    localStorage.setItem(INSTITUTE_GROUP_STORAGE_KEY, newInstituteIds.map((id) => id.toString()).join(","));
  };

  const value = {
    institutes: instituteIds,
    mode,
    setMode,
    setInstituteIds: setInstituteIdList,
  };

  React.useImperativeHandle(InstituteGroupContextRef, () => value);

  return (
    <SelectedInstituteGroupsContext.Provider value={value}>
      {props.children}
    </SelectedInstituteGroupsContext.Provider>
  );
}

type SupportsInstituteGroupsOverrideProps = React.PropsWithChildren<{
  overrideMode?: InstituteGroupMode;
}>;

export function SupportsInstituteGroupsOverrideProvider(
  props: SupportsInstituteGroupsOverrideProps
): ReactElement {
  const [mode, setMode] = React.useState(props.overrideMode ?? DEFAULT_MODE);

  const selectedInstituteGroups = React.useContext(SelectedInstituteGroupsContext);

  useEffectSimpleCompare(() => {
    selectedInstituteGroups.setMode(mode);
  }, [mode]);

  return (
    <SelectedInstituteGroupsContext.Provider
      value={{
        institutes: selectedInstituteGroups.institutes,
        mode: mode,
        // Note that because of the useEffect above, calling this will propagate supportsInstituteGroups up through all the
        // parent contexts.
        setMode,
        setInstituteIds: selectedInstituteGroups.setInstituteIds,
      }}
    >
      {props.children}
    </SelectedInstituteGroupsContext.Provider>
  );
}

function getSelectedInstituteGroupsFromStorage() {
  const storageValue = localStorage.getItem(INSTITUTE_GROUP_STORAGE_KEY);

  if (storageValue === null) {
    return [];
  }

  return compact(storageValue.split(",").map((id) => fromString<"Institute">(id).getOrElse(null)));
}

/**
 * Gets the raw data for managing group state. Use this if you need to modify group state or render something
 * conditionally based on the group state.
 */
export const useSelectedInstituteGroups = () => React.useContext(SelectedInstituteGroupsContext);

export function getActiveInstituteGroupHeader(leaderOnly?: boolean) {
  // We have to do this weird trick because we can't access the context directly, but instead
  // have to access through a ref because we are outside of react.
  const value = InstituteGroupContextRef.current as SelectedInstituteGroups | null | undefined;

  if (!value || leaderOnly) {
    // We don't have the context available
    return {};
  }

  if (value.mode === "institute-scoped") {
    return {
      "Active-Institute-Ids": "all",
    };
  }
  if (value.mode === "selected-institutes" && value.institutes.length > 0) {
    return {
      "Active-Institute-Ids": value.institutes.join(","),
    };
  } else {
    return {};
  }
}
