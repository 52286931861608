import {
  EntitySelectQueryVariables,
  EntityTreeNodeParams,
  EntityType,
  useEntitySelectWithExtrasLazyQuery,
  useEntityTreeNodeSelectSingleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import { entityTypeT } from "GeneratedGraphQL/EnumTranslations";
import {
  ALL_ENTITY_TYPES,
  EntityPathIcon,
  EntitySummary,
  entityTreeNodeToParams,
  entityTypePriority,
  entityTypeToPathName,
  extractEntitiesFromDetails,
  usedEntityTypes,
} from "Entities/EntityPath";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import { assertNonNull } from "Lib/Utils";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { WithOptionalFields } from "type-utils";
import { useInstituteHasGroups } from "Contexts/CurrentInstituteContext";

type EntityTreeNodeSelectProps = {
  value: EntityTreeNodeParams | null;
  entityTypes?: ReadonlyArray<EntityType> | null;
  setValue: (newValue: EntityTreeNodeParams | null) => void;
  defaultValue?: EntityTreeNodeParams | null;
  required?: boolean;
  error?: boolean;
  helperText?: string | null; // Need the null here on top of ? to handle GQL Maybe (T | undefined | null)
  relatedOnly?: boolean;
};

type EntitySummaryWithUnknown = WithOptionalFields<EntitySummary, "entityType">;
type SelectOption = EntitySummaryWithUnknown;

function entitiesToEntityTreeNodeParams(
  values: ReadonlyArray<EntitySummaryWithUnknown>
): EntityTreeNodeParams {
  // If you select the root at any point beyond the first, take it as a reset
  if (values.findIndex((entity) => entity.entityType === EntityType.ROOT) > 0) {
    return { root: true };
  }
  const path = values
    .flatMap((entity) => {
      if (!entity.entityType || entity.entityType === EntityType.ROOT) {
        return [];
      } else {
        return [`${entityTypeToPathName(entity.entityType)}/${entity.id}`];
      }
    })
    .join("/");

  return {
    path,
  };
}

// This is used by MUI to check which option was actually selected.
const valueEqual = (left: EntitySummaryWithUnknown, right: EntitySummaryWithUnknown) => {
  return left.id === right.id;
};

export default function EntityTreeNodeSelect(props: EntityTreeNodeSelectProps): ReactElement {
  const { t } = useTranslation(["common", "enums"]);
  const { value, setValue, defaultValue, relatedOnly } = props;
  const [searchString, setSearchString] = React.useState<string>("");
  const [doSearch, response] = useEntitySelectWithExtrasLazyQuery();
  const highlightColor = useTheme().palette.filterHighlight;
  const [_currentRootNodeParams, currentRootNodeDetails] = useCurrentRootNode();
  const instituteGroupMode = useInstituteHasGroups();

  const usedTypes = React.useMemo(() => (value ? usedEntityTypes(value) : []), [value]);
  const allowedTypes = React.useMemo(
    () => (props.entityTypes ?? ALL_ENTITY_TYPES).filter((el) => !usedTypes.includes(el)),
    [value]
  );
  // We do separate searches for institute and institute group, so they are never part of this collection.
  const searchTypes = React.useMemo(
    () => allowedTypes.filter((el) => el !== EntityType.INSTITUTE && el !== EntityType.INSTITUTE_GROUP),
    [value]
  );

  const queryVars: Omit<EntitySelectQueryVariables, "search"> = {
    first: 30,
    entityType: searchTypes,
    relatedOnly: relatedOnly ?? null,
  };

  const { remoteData: defaultValueRemoteData } = apolloQueryHookWrapper(
    useEntityTreeNodeSelectSingleQuery({
      variables: {
        node: assertNonNull(defaultValue),
      },
      skip: !defaultValue,
    })
  );

  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useEntityTreeNodeSelectSingleQuery({
      variables: {
        node: assertNonNull(value),
      },
      skip: !value,
    })
  );

  const defaultValueEntities: ReadonlyArray<EntitySummaryWithUnknown> = extractEntitiesFromDetails(
    defaultValueRemoteData.caseOf({
      Success: (data) => {
        if (data.entityTreeNode) {
          return data.entityTreeNode;
        }
        // If we can't find it, revert to the institute
        return currentRootNodeDetails;
      },
      Loading: () => {
        return currentRootNodeDetails;
      },
      NotAsked: () => {
        return currentRootNodeDetails;
      },
      Failure: () => {
        return currentRootNodeDetails;
      },
    }),
    instituteGroupMode
  );

  const loadingString = t("common:remoteData.loading");

  // If you do not memoize this, you will get constant resets as the value changes
  const currentValueEntities: Array<EntitySummaryWithUnknown> = React.useMemo(() => {
    return [
      ...currentValueRemoteData.caseOf({
        Success: (data) => {
          if (data.entityTreeNode) {
            return extractEntitiesFromDetails(data.entityTreeNode, instituteGroupMode);
          }
          // If we can't find it, revert to the institute
          return defaultValueEntities;
        },
        Loading: () => {
          if (value?.entityId) {
            return [
              { id: value.entityId, name: loadingString, shortname: loadingString, entityType: undefined },
            ];
          } else {
            return defaultValueEntities;
          }
        },
        _: () => {
          if (value?.entityId) {
            return [
              {
                id: value.entityId,
                name: loadingString,
                shortname: loadingString,
                entityType: undefined,
              },
            ];
          } else {
            return defaultValueEntities;
          }
        },
      }),
    ];
  }, [currentValueRemoteData.kind, value?.path, value?.root]);

  // Autocomplete maintains two fields that we have to control - the "input" is the string that you've typed into the
  // search box, and the "value" is the item that you've selected. See the Mui docs for more.
  const handleInputChange = (
    _event: SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Without this check, the search causes the autocomplete to go into some infinite
    // loop hell where the input flickers and transitions from '' to the correct value.
    if (reason === "input" || reason === "clear") {
      doSearch({ variables: { ...queryVars, search: value } });
    }
    setSearchString(value);
  };

  const handleValueChange = (_event: SyntheticEvent, value: Array<EntitySummaryWithUnknown>) => {
    if (value.length === 0) {
      // If at any point you manage to clear out all the segments, revert to the current root node. This may be
      // different than the default node, which could e.g. be a particular provider.
      setValue(entityTreeNodeToParams(currentRootNodeDetails));
    } else {
      setValue(entitiesToEntityTreeNodeParams(value));
    }
  };

  let searchedOptions: Array<SelectOption> = [
    ...(response.data?.groups?.nodes ?? []),
    ...(response.data?.institutes?.nodes ?? []),
    ...(response.data?.all?.nodes ?? []),
  ];
  const fixedOptions: ReadonlyArray<SelectOption> =
    currentValueEntities.length > 0 ? currentValueEntities : [currentRootNodeDetails.entity];

  // We want the selected options at the top, then the searched options below.
  searchedOptions = searchedOptions.sort(
    (left: EntitySummaryWithUnknown, right: EntitySummaryWithUnknown) => {
      // To make the groups work, all entities in the same group need to appear next to each other
      // so we need to change the sort
      // We pad the numbers to make sure they're the same length during the sort
      return (
        entityTypePriority(left.entityType ?? EntityType.ROOT)
          .toString()
          .padStart(3, "0") + left.name
      ).localeCompare(
        entityTypePriority(right.entityType ?? EntityType.ROOT)
          .toString()
          .padStart(3, "0") + right.name
      );
    }
  );

  // If you have a fixed option that can also appear in the list, you need to deduplicate it. This is relatively quick
  // to do assuming the options have already been sorted.
  searchedOptions = searchedOptions.filter((el) => {
    // Filter out options which aren't allowed but allow it if they're a fixed option
    if (el.entityType && !allowedTypes.includes(el.entityType)) {
      return false;
    }

    // In theory we may need to check for duplicates, but that doesn't happen right now because we separate
    // the fixed options from the searched ones, and because we restrict the available types in the search you don't get a clash.
    return true;
  });

  const options = [...fixedOptions, ...searchedOptions];

  // See the long comment in the Autocomplete Single for why we're doing this.
  currentValueEntities.forEach((value) => {
    const valueInOptions = options.find((option) => valueEqual(value, option));
    if (!valueInOptions) {
      options.splice(0, 0, value);
    }
  });

  const highlight = typeof props.defaultValue !== "undefined" && props.defaultValue?.path !== value?.path;

  const sx = highlight
    ? {
        "& .MuiOutlinedInput-notchedOutline": {
          borderWidth: "4px",
          borderColor: highlightColor,
        },
      }
    : null;

  return (
    <Autocomplete
      multiple
      // Props controlled by this component that can't be passed from caller
      value={currentValueEntities}
      options={options}
      isOptionEqualToValue={valueEqual}
      loading={response.loading}
      inputValue={searchString}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      onOpen={() => doSearch({ variables: { ...queryVars, search: searchString } })}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("common:entityTreeNode.selectTitle")}
          required={!!props.required}
          helperText={props.helperText}
          error={props.error}
        />
      )}
      getOptionLabel={(option) => option.name}
      noOptionsText={t("common:entities.noMatching")}
      renderOption={(props, option) => {
        const icon = option.entityType ? (
          <>
            <EntityPathIcon entityType={option.entityType} />
          </>
        ) : null;
        return (
          <ListItem {...props} key={option.id.toString()}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText>{option.name}</ListItemText>
          </ListItem>
        );
      }}
      groupBy={(option) => {
        if (option.entityType) {
          const stringName = entityTypeT(option.entityType, t);
          if (option.entityType !== EntityType.INSTITUTE && usedTypes.includes(option.entityType)) {
            return t("common:entityTreeNode.selectedTypeHeader", { name: stringName });
          } else {
            return stringName;
          }
        } else {
          return "";
        }
      }}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              icon={option.entityType ? <EntityPathIcon entityType={option.entityType} /> : undefined}
              label={option.name}
              {...tagProps}
              disabled={option.id === currentRootNodeDetails.entity.id}
            />
          );
        })
      }
      // These are good defaults that we set here but callers can override
      clearOnBlur
      clearOnEscape
      disableCloseOnSelect
      // Always include all of the options returned by the query in the autocomplete options. Without this Mui does its
      // own filtering logic which we specifically do not want because we're doing the search on the server for a
      // reason.
      filterOptions={(options, _state) => options}
      sx={sx}
    />
  );
}
