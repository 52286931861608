import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { BillingConfigurationRoutes } from "./Configuration";
import BillingPrediction from "./BillingPrediction";
import BillingDashboard from "./BillingDashboard";

/**
 * Provides full routing for implementation targets
 * @returns routes
 */
export function BillingRoutes(): ReactElement {
  return (
    <>
      <Routes>
        <Route element={<BillingConfigurationRoutes />} path="configuration/*" />
        <Route element={<BillingPrediction />} path="prediction" />
        <Route element={<BillingDashboard />} path="/" />
      </Routes>
    </>
  );
}
