import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import React, { ReactElement } from "react";

export function CareEpisodeLoading(): ReactElement {
  return (
    <Stack spacing={1}>
      <Card>
        <CardContent>
          <Stack spacing={0.5}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack spacing={0.5}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}
