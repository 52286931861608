import { Button, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { CareEpisodeSurveyHistory } from "CollaborativeCare/PatientDetails/DetailTabs/SurveyHistoryTab/CareEpisodeSurveyHistory";
import { NotInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";
import { consultRequestReasonT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisode,
  CareEpisodeTreatmentTarget,
  CaseConsult,
  ConsultRequest,
  Enrollment,
  Patient,
  Provider,
  Task,
  TreatmentTarget,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PropertyTable } from "MDS/PropertyTable";
import { RichText } from "MDS/RichText";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

// This is for read-only informational aspects of interacting with a case consult review that we want to use multiple
// places.

type CareEpisodeTreatmentTargetSummary = PickTypename<CareEpisodeTreatmentTarget, "id" | "startedDate"> & {
  treatmentTarget: PickTypename<TreatmentTarget, "id" | "name">;
};

type ConsultRequestDetails = PickTypename<
  ConsultRequest,
  "id" | "requestDate" | "requestReason" | "requestText"
> & {
  provider: PickTypename<Provider, "id" | "name">;
};

type MaybeTaskWithId = PickTypename<Task, "id"> | null;

export type CaseConsultDetails = PickTypename<
  CaseConsult,
  "id" | "caseSummary" | "consultationDocumentation" | "consultationNotes" | "status" | "summaryUpdatedAt"
> & {
  enrollment: PickTypename<Enrollment, "id"> & {
    careEpisode: PickTypename<CareEpisode, "id"> & {
      collaborativeCareCareEpisodeTreatmentTargets: ReadonlyArray<CareEpisodeTreatmentTargetSummary>;
    };
  };
  patient: PickTypename<Patient, "id" | "note" | "mrn">;
  consultRequests: {
    nodes: ReadonlyArray<ConsultRequestDetails>;
  };
  caseSummaryTask: MaybeTaskWithId;
  consultMeetingTask: MaybeTaskWithId;
  documentationTask: MaybeTaskWithId;
};

type CaseConsultReviewInformationSectionProps = {
  consult: CaseConsultDetails;
};

export function ConsultRelevantDemographics(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <NotInEmbeddedPatientOrEncounter>
      <PropertyTable>
        <TableBody>
          <TableRow>
            <TableCell>{t("collaborativeCare:patientList.fields.mrn")}</TableCell>
            <TableCell>{props.consult.patient.mrn}</TableCell>
          </TableRow>
        </TableBody>
      </PropertyTable>
    </NotInEmbeddedPatientOrEncounter>
  );
}

export function MeasurementBundleList(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">{t("collaborativeCare:patientDetails.surveyHistory.bundles")}</Typography>
      {props.consult.enrollment.careEpisode.collaborativeCareCareEpisodeTreatmentTargets.map((target, i) => (
        <Typography key={i}>
          {target.treatmentTarget.name} -{" "}
          {t("collaborativeCare:patientDetails.surveyHistory.bundleStartDate", { date: target.startedDate })}
        </Typography>
      ))}
    </Stack>
  );
}

export function RequestsList(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["common", "enums", "collaborativeCare"]);

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.requests.title")}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography>{t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{t("collaborativeCare:caseConsult.consultList.fields.reason")}</Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {t("collaborativeCare:caseConsult.caseConsultReview.requests.requestDate")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.consult.consultRequests.nodes.map((request, i) => (
            <TableRow key={i}>
              <TableCell>{request.provider.name}</TableCell>
              <TableCell>
                {consultRequestReasonT(request.requestReason, t)} {request.requestText}
              </TableCell>
              <TableCell>{t("common:date.short", { date: request.requestDate })}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

export function CaseSummary(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const summaryEl = props.consult.caseSummary ? (
    <Typography>
      <RichText>{props.consult.caseSummary}</RichText>
    </Typography>
  ) : (
    <Typography variant="caption">
      {t("collaborativeCare:caseConsult.caseConsultReview.caseSummary.noSummary")}
    </Typography>
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.caseSummary.title")}
      </Typography>
      {summaryEl}
    </Stack>
  );
}

export function AdditionalPatientInformation(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const noteEl = props.consult.patient.note ? (
    <RichText>{props.consult.patient.note}</RichText>
  ) : (
    <Typography variant="caption">
      {t("collaborativeCare:caseConsult.caseConsultReview.patientInfo.noNote")}
    </Typography>
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.patientInfo.title")}
      </Typography>
      {noteEl}
    </Stack>
  );
}

export function Assessments(props: CaseConsultReviewInformationSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.assessments.title")}
      </Typography>
      <CareEpisodeSurveyHistory careEpisodeId={props.consult.enrollment.careEpisode.id} />
      <ContextualReportLink
        patient={props.consult.patient.id}
        careEpisode={props.consult.enrollment.careEpisode.id}
      >
        <Button variant="contained" color="secondary">
          {t("collaborativeCare:patientDetails.surveyHistory.reportLink")}
        </Button>
      </ContextualReportLink>
    </Stack>
  );
}
