import { Box, Tooltip as MuiTooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AcuteChangeCategory } from "GeneratedGraphQL/SchemaAndOperations";
import * as Staleness from "Lib/Staleness";
import { AcuteChangeDecliningIcon, AcuteChangeImprovingIcon } from "MDS/Icons";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Maybe } from "seidr";
import { formatDate } from "Shared/formatters";
import * as AcuteChange from "./AcuteChange";
import { CareEpisodeComputedValueDetails } from "./CareEpisodeComputedValueDetails";
import { SeverityChip } from "./SeverityChip";

const StyledTable = styled("table")(({ theme }) => ({
  margin: "auto",
  marginTop: theme.spacing(2),
  borderSpacing: 0,
  "& th": {
    textAlign: "center",
    fontWeight: "normal",
    color: theme.palette.text.secondary,
  },
  "& td": {
    textAlign: "center",
    padding: "6px",
  },
  "& tfoot td": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle1.fontSize,
    paddingTop: 0,
  },
}));

const StyledAcuteColumn = styled("th")(() => ({
  width: 240,
}));

const StyledTooltip = styled(TooltipBase)(() => ({
  [`& .${tooltipClasses.tooltip}`]: { maxWidth: 700 },
}));

type SupportedAcuteChangeValueTypes = CareEpisodeComputedValueDetails;

type AcuteChangeTooltipProps<T extends SupportedAcuteChangeValueTypes> = {
  acuteChange: AcuteChangeCategory;
  currentValue: T;
  previousValue: T;
  lastDate: Date;
  children: ReactElement;
};

function AcuteChangeTooltip<T extends SupportedAcuteChangeValueTypes>(
  props: AcuteChangeTooltipProps<T>
): ReactElement {
  const { t } = useTranslation(["report"]);
  const { acuteChange, currentValue, previousValue, lastDate, children } = props;

  // This is a bit silly in that we know that if we have an acute change,
  // we have at least 2 values.
  const content = (
    <Box>
      <StyledTable>
        <thead>
          <tr>
            <th>Previous Score</th>
            <StyledAcuteColumn>Acute change</StyledAcuteColumn>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SeverityChip value={previousValue} isStale={Staleness.isStale(lastDate, previousValue)} />
            </td>
            <td>
              <AcuteChangeIndicatorWithNameWithoutTooltip acuteChange={acuteChange} />
            </td>
            <td>
              <SeverityChip value={currentValue} isStale={Staleness.isStale(lastDate, currentValue)} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{formatDate(previousValue.targetDate)}</td>
            <td></td>
            <td>{formatDate(currentValue.targetDate)}</td>
          </tr>
        </tfoot>
      </StyledTable>
    </Box>
  );

  return (
    <StyledTooltip
      title={AcuteChange.name(t, acuteChange)}
      subtitle="Acute changes are significant changes between the 2 most recent sessions"
      content={content}
    >
      {children}
    </StyledTooltip>
  );
}

type BaseProps = {
  acuteChange: AcuteChangeCategory;
  size: number;
};

const StyledIconContainer = styled("span")(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  verticalAlign: "middle",
  paddingLeft: "6px",
}));

function AcuteChangeIndicatorBase(props: BaseProps): ReactElement | null {
  const icon = AcuteChangeIndicatorIcon(props);

  return icon ? <StyledIconContainer>{icon}</StyledIconContainer> : null;
}

function AcuteChangeIndicatorIcon(props: BaseProps): ReactElement | null {
  const { acuteChange, size } = props;

  switch (acuteChange) {
    case AcuteChangeCategory.DECLINING:
      return <AcuteChangeDecliningIcon sx={{ fontSize: size }} fontSize="inherit" />;
    case AcuteChangeCategory.IMPROVING:
      return <AcuteChangeImprovingIcon sx={{ fontSize: size }} fontSize="inherit" />;
    case AcuteChangeCategory.INVALID:
    case AcuteChangeCategory.NOT_SPECIFIED:
    case AcuteChangeCategory.NOT_SUPPORTED:
    case AcuteChangeCategory.STABLE:
      return null;
  }
}

type Props<T extends SupportedAcuteChangeValueTypes> = {
  acuteChange: AcuteChangeCategory;
  currentValue: T;
  previousValue: T;
  lastDate: Date;
};

function AcuteChangeIndicator<T extends SupportedAcuteChangeValueTypes>(
  props: Props<T>
): ReactElement | null {
  const { acuteChange, ...baseProps } = props;

  return (
    Maybe.fromNullable(<AcuteChangeIndicatorBase size={24} acuteChange={acuteChange} />)
      // Wrap to allow Tooltip specific refs
      .map((indicator) => <span>{indicator}</span>)
      .map((indicator) => (
        <AcuteChangeTooltip acuteChange={acuteChange} {...baseProps}>
          {indicator}
        </AcuteChangeTooltip>
      ))
      .getOrElse(null)
  );
}

function AcuteChangeIndicatorWithName<T extends SupportedAcuteChangeValueTypes>(
  props: Props<T>
): ReactElement | null {
  const { t } = useTranslation(["report"]);
  const { acuteChange, currentValue, lastDate, previousValue } = props;

  return Maybe.fromNullable(<AcuteChangeIndicatorBase size={24} acuteChange={acuteChange} />)
    .map((indicator) => (
      <span>
        {indicator} <strong>{AcuteChange.name(t, acuteChange)}</strong>
      </span>
    ))
    .map((indicator) => (
      <AcuteChangeTooltip
        acuteChange={acuteChange}
        currentValue={currentValue}
        previousValue={previousValue}
        lastDate={lastDate}
      >
        {indicator}
      </AcuteChangeTooltip>
    ))
    .getOrElse(null);
}

type JustAcuteChangeProps = {
  acuteChange: AcuteChangeCategory;
};

function AcuteChangeIndicatorWithNameWithoutTooltip(props: JustAcuteChangeProps): ReactElement | null {
  const { t } = useTranslation(["report"]);
  const { acuteChange } = props;

  return Maybe.fromNullable(<AcuteChangeIndicatorBase size={24} acuteChange={acuteChange} />)
    .map((indicator) => (
      <span>
        {indicator} <strong>{AcuteChange.name(t, acuteChange)}</strong>
      </span>
    ))
    .getOrElse(null);
}

function AcuteChangeIndicatorBadge(props: JustAcuteChangeProps): ReactElement | null {
  const { acuteChange } = props;
  const { t } = useTranslation(["report"]);

  return Maybe.fromNullable(<AcuteChangeIndicatorBase size={24} acuteChange={acuteChange} />)
    .map((indicator) => {
      // The throwaway <></> below are guarding against a forwardRef issue.
      return (
        <MuiTooltip title={AcuteChange.name(t, acuteChange)}>
          <>{indicator}</>
        </MuiTooltip>
      );
    })
    .getOrElse(null);
}

export default AcuteChangeIndicator;
export {
  AcuteChangeIndicatorBase,
  AcuteChangeIndicator,
  AcuteChangeIndicatorWithName,
  AcuteChangeIndicatorWithNameWithoutTooltip,
  AcuteChangeIndicatorBadge,
};
