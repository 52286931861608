import { Skeleton, Stack } from "@mui/material";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { parseScaleGroupHistoryQuery } from "FeedbackReport/DetailPane/DataStore/OverviewTreatmentResponseTransforms";
import DetailPaneLevelHeader from "FeedbackReport/DetailPaneLevelHeader";
import LevelDetail from "FeedbackReport/LevelDetail";
import { LevelHeaderLoadingIndicator } from "FeedbackReport/LevelHeader";
import {
  CareEpisodeComputedValueWithScaleAndAnswersFragment,
  useScaleGroupHistoryQueryQuery,
  useScaleScoreQueryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeComputedValueId, CareEpisodeId, ScaleGroupId } from "Lib/Ids";
import { arrOfN, nullableToRemoteData } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Failure, RemoteData, Success } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { toQuestionResponseSummary } from "Shared/Scale/QuestionResponse";
import { CareEpisodeScaleGroupHistory } from "Shared/Scale/ScaleGroup";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { ResponsesTable } from "../Shared/ResponsesTable";
import EditResponses from "./EditResponses";
import { ScaleScoreTrendInfo } from "./ScaleScoreTrendInfo";
import { scaleFriendlyName } from "Shared/Scale/Scale";

type DetailedScaleGroupHistoryData = RemoteData<Error, CareEpisodeScaleGroupHistory>;

type DetailedScaleScoreData = RemoteData<Error, CareEpisodeComputedValueWithScaleAndAnswersFragment>;

export type ScaleScorePaneProps = {
  careEpisodeId: CareEpisodeId;
  scaleGroupId: ScaleGroupId;
  careEpisodeComputedValueId: CareEpisodeComputedValueId;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleGroupScorePaneHookWrapper(props: ScaleScorePaneProps): ReactElement {
  const {
    careEpisodeId,
    scaleGroupId,
    careEpisodeComputedValueId: CareEpisodeComputedValueId,
    feedbackReportContext,
  } = props;

  return ScaleGroupScorePane({
    scaleGroupData: loadScaleGroupHistory(careEpisodeId, scaleGroupId, feedbackReportContext),
    scaleScoreData: loadScaleScore(CareEpisodeComputedValueId),
    feedbackReportContext,
  });
}

function loadScaleGroupHistory(
  careEpisodeId: CareEpisodeId,
  scaleGroupId: ScaleGroupId,
  feedbackReportContext: FeedbackReportContext
): DetailedScaleGroupHistoryData {
  const { remoteData } = apolloQueryHookWrapper(
    useScaleGroupHistoryQueryQuery({
      variables: {
        careEpisodeId,
        scaleGroupId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      if (result.assessmentCareEpisodeScaleGroupDetail) {
        return Success(
          parseScaleGroupHistoryQuery(
            result.assessmentCareEpisodeScaleGroupDetail.careEpisodeScaleGroupResult,
            feedbackReportContext.participantsByUserId
          )
        );
      } else {
        return Failure(new Error("Not Found"));
      }
    });
}

function loadScaleScore(careEpisodeComputedValueId: CareEpisodeComputedValueId): DetailedScaleScoreData {
  const { remoteData } = apolloQueryHookWrapper(
    useScaleScoreQueryQuery({
      variables: {
        careEpisodeComputedValueId: careEpisodeComputedValueId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => nullableToRemoteData(result.assessmentCareEpisodeComputedValue));
}

function ScaleScorerLoadingIndicator(): ReactElement {
  const rows = arrOfN(10).map((i) => {
    return (
      <tr key={i} data-testid="loading-row">
        <td>
          <Skeleton width={250} height={20} />
        </td>
        <td>
          <Skeleton width={50} height={20} />
        </td>
        <td>
          <Skeleton width={75} height={20} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <LevelDetail>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </LevelDetail>
    </div>
  );
}

type Props = {
  scaleGroupData: DetailedScaleGroupHistoryData;
  scaleScoreData: DetailedScaleScoreData;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleGroupScorePane(props: Props): ReactElement {
  const { scaleGroupData, scaleScoreData } = props;
  const { t } = useTranslation(["report"]);

  const content = scaleScoreData.caseOf({
    NotAsked: () => <ScaleScorerLoadingIndicator />,
    Loading: () => <ScaleScorerLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (score) => {
      return scaleGroupData.caseOf({
        NotAsked: () => <ScaleScorerLoadingIndicator />,
        Loading: () => <ScaleScorerLoadingIndicator />,
        Failure: (error) => <ErrorMessage message={error.message} />,
        Success: (scaleGroup) => {
          const administration = scaleGroup.histories.find((history) => {
            return history.scale.id === score.scale.id;
          });

          const trendInfo = administration ? (
            <ScaleScoreTrendInfo history={administration} scoreDate={score.date} />
          ) : null;

          return (
            <Stack spacing={0.5}>
              <DetailPaneLevelHeader label={score.user?.name || t("report:allRespondents")} />
              <DetailPaneLevelHeader
                label={t("report:sections.responses", {
                  date: score.date,
                  scale: scaleFriendlyName(score.scale),
                })}
              >
                <EditResponses scaleScore={score} />
              </DetailPaneLevelHeader>
              {trendInfo}
              <LevelDetail>
                <ResponsesTable responses={score.answers.map(toQuestionResponseSummary)} />
              </LevelDetail>
            </Stack>
          );
        },
      });
    },
  });

  return <div data-testid="scale-score">{content}</div>;
}

export default ScaleGroupScorePaneHookWrapper;
export { ScaleGroupScorePaneHookWrapper as HookWrapper, ScaleGroupScorePane as Component };
