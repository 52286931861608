import {
  Button,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableHead,
  Paper,
  TableContainer,
  TablePagination,
  MenuItem,
  Menu,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Box,
  Stack,
  IconButton,
} from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  useSurveyManagementIndexQuery,
  IntakeStatus,
  SortDirection,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { ArrowDropDown } from "@mui/icons-material";
import { Link } from "MDS/Link";
import { PatientId, CareEpisodeId } from "Lib/Ids";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SendImmediateSurveyButton } from "CollaborativeCare/PatientDetails/SendImmediateSurvey";
import { InviteSummary, transformInvitation, InvitationStatusElement } from "./InviteSummary";
import {
  CancelInvitationButton,
  DownloadPdfButton,
  ResendAllNotificationsButton,
  SendReminderButton,
  SurveyManagementTableFailed,
  SurveyManagementTableLoading,
} from "./SurveyManagementTableShared";
import { PatientDetails } from "CollaborativeCare/PatientDetails/PatientDetails";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import InvitationHistory from "CollaborativeCare/PatientDetails/InvitationHistory";
import InfoIcon from "@mui/icons-material/Info";

type SurveyManagementTableDesktopProps = {
  patient: PatientDetails;
  careEpisodeId: CareEpisodeId;
  intakeStatus: IntakeStatus;
  forPreviousEnrollment?: boolean;
};

export default function SurveyManagementTableDesktop(props: SurveyManagementTableDesktopProps) {
  const { t } = useTranslation(["common", "collaborativeCare", "common"]);

  const { remoteData } = apolloQueryHookWrapper(
    useSurveyManagementIndexQuery({
      variables: {
        careEpisodeId: props.careEpisodeId,
      },
    })
  );

  let cardAction = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <Box marginRight="0.3em" sx={{ display: "flex" }}>
      <ResendAllNotificationsButton
        patientId={props.patient.id}
        careEpisodeId={props.careEpisodeId}
        disabled={true}
      />
      &nbsp;
      <SendImmediateSurveyButton patient={props.patient} />
    </Box>
  );
  return remoteData.caseOf({
    NotAsked: () => <SurveyManagementTableLoading />,
    Loading: () => <SurveyManagementTableLoading />,
    Failure: () => <SurveyManagementTableFailed action={cardAction} />,
    Success: (response) => {
      const invitations =
        response.assessmentCareEpisodeScaleSummary?.invitations
          .map((rawInvitation) =>
            transformInvitation(rawInvitation, props.patient.id, props.careEpisodeId, t)
          )
          .filter((invitation) => {
            return invitation.status != "NOT_REQUIRED";
          }) || [];
      cardAction = props.forPreviousEnrollment ? (
        <></>
      ) : (
        <Box marginRight="0.3em" sx={{ display: "flex" }}>
          <ResendAllNotificationsButton
            patientId={props.patient.id}
            careEpisodeId={props.careEpisodeId}
            count={
              response.assessmentCareEpisodeScaleSummary?.invitations.filter((rawInvitation) => {
                return rawInvitation.canResendNotifications;
              }).length
            }
          />
          &nbsp;
          <SendImmediateSurveyButton patient={props.patient} />
        </Box>
      );

      return (
        <LoadedSurveyManagementTableDesktop
          invitations={invitations}
          intakeStatus={props.intakeStatus}
          patient={props.patient}
          careEpisodeId={props.careEpisodeId}
          forPreviousEnrollment={props.forPreviousEnrollment}
          action={cardAction}
        />
      );
    },
  });
}

type SurveyManagementDesktopTableHeaderProps = {
  sortDirection: SortDirection;
  sortColumn: keyof InviteSummary;
  onSortChange: (sortColumn: keyof InviteSummary) => void;
};

function SurveyManagementDesktopTableHeader(props: SurveyManagementDesktopTableHeaderProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return (
    <TableRow>
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.recipient")}
        sortDirection={props.sortDirection}
        showSortIndicator={"userName" == props.sortColumn}
        columnWidth="10%"
        onClick={() => props.onSortChange("userName")}
      />
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.measure")}
        sortDirection={props.sortDirection}
        showSortIndicator={"scalesSortKey" == props.sortColumn}
        columnWidth="15%"
        onClick={() => props.onSortChange("scalesSortKey")}
      />
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.status")}
        sortDirection={props.sortDirection}
        showSortIndicator={"status" == props.sortColumn}
        columnWidth="40%"
        onClick={() => props.onSortChange("status")}
      />
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.expiresOn")}
        sortDirection={props.sortDirection}
        showSortIndicator={"closesAt" == props.sortColumn}
        columnWidth="20%"
        onClick={() => props.onSortChange("closesAt")}
      />
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.sentBy")}
        sortDirection={props.sortDirection}
        showSortIndicator={false}
        columnWidth="10%"
        onClick={() => null}
      />
      <TableHeader
        header={t("collaborativeCare:surveyManagement.headers.actions")}
        sortDirection={props.sortDirection}
        showSortIndicator={false}
        columnWidth="10%"
        onClick={() => null}
      />
    </TableRow>
  );
}

type SurveyManagementDesktopTableRowProps = {
  invitation: InviteSummary;
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  forPreviousEnrollment?: boolean;
};

function SurveyManagementDesktopTableRow(props: SurveyManagementDesktopTableRowProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const closesAt = props.invitation.closesAt
    ? t("common:date.tinyWithTime", {
        date: props.invitation.closesAt,
      })
    : "";

  const methods = props.invitation.methods.map(function (m) {
    if (m == "email") {
      return (
        <React.Fragment key={m}>
          <EmailIcon />{" "}
        </React.Fragment>
      );
    } else if (m == "text") {
      return (
        <React.Fragment key={m}>
          {" "}
          <TextsmsIcon />
        </React.Fragment>
      );
    }
    return <>{m}</>;
  });

  const [historyOpen, setHistoryOpen] = React.useState<boolean>(false);
  const invitationDetailsModal = (
    <InvitationHistory
      open={historyOpen}
      onClose={() => {
        setHistoryOpen(false);
      }}
      rawInvitation={props.invitation.rawInvitation}
      careEpisodeId={props.careEpisodeId}
      forPreviousEnrollment={props.forPreviousEnrollment}
    ></InvitationHistory>
  );

  return (
    <TableRow>
      <TableCell>{props.invitation.userName}</TableCell>
      <TableCell>{props.invitation.scaleLinkElements}</TableCell>
      <TableCell>
        <InvitationStatusElement invitation={props.invitation}></InvitationStatusElement>
        <IconButton
          size="small"
          onClick={() => {
            setHistoryOpen(true);
          }}
        >
          <InfoIcon fontSize="small" />
        </IconButton>
      </TableCell>
      <TableCell>{closesAt}</TableCell>
      <TableCell>{methods}</TableCell>
      <TableCell>
        <ActionsDropdownDesktop
          invite={props.invitation}
          careEpisodeId={props.careEpisodeId}
          patientId={props.patientId}
          forPreviousEnrollment={props.forPreviousEnrollment}
        />
        {invitationDetailsModal}
      </TableCell>
    </TableRow>
  );
}

type LoadedSurveyManagementTableDesktopProps = {
  invitations: Array<InviteSummary>;
  intakeStatus: IntakeStatus;
  patient: PatientDetails;
  careEpisodeId: CareEpisodeId;
  action: ReactNode;
  forPreviousEnrollment?: boolean;
};

function LoadedSurveyManagementTableDesktop(props: LoadedSurveyManagementTableDesktopProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [page, setPage] = React.useState<number>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const [pageSize, setPageSize] = React.useState<number>(5);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sortColumn, setSortColumn] = React.useState<keyof InviteSummary>("sentAt");
  const handleChangeSortColumn = (newColumn: keyof InviteSummary) => {
    if (newColumn == sortColumn) {
      sortDirection == SortDirection.DESC
        ? setSortDirection(SortDirection.ASC)
        : setSortDirection(SortDirection.DESC);
    } else {
      setSortColumn(newColumn);

      //if we're moving to the `scalesSortKey` column, sort it the other way so A's appear at the top by default
      if (newColumn == "scalesSortKey") {
        setSortDirection(SortDirection.ASC);
      } else {
        setSortDirection(SortDirection.DESC);
      }
    }
    setPage(0);
  };

  // Newer dates are greater than older dates, so DESC sort puts the latest invites at the top by default.
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.DESC);

  props.invitations.sort(function (a, b) {
    const accessA = (str: keyof typeof a) => {
      return a[str] || 0;
    };
    const accessB = (str: keyof typeof b) => {
      return b[str] || 0;
    };
    if (sortDirection === SortDirection.ASC) {
      // swap a and b so they sort the other way
      const c = b;
      b = a;
      a = c;
    }

    const valueA = accessA(sortColumn);
    const valueB = accessB(sortColumn);

    if (valueA > valueB) {
      return -1;
    } else if (valueB > valueA) {
      return 1;
    } else return 0;
  });

  let noInvitesElement = <></>;
  if (props.invitations.length == 0) {
    if (props.intakeStatus != IntakeStatus.COMPLETE) {
      noInvitesElement = <Typography>{t("collaborativeCare:surveyManagement.intakeIncomplete")}</Typography>;
    } else {
      noInvitesElement = <Typography>{t("collaborativeCare:surveyManagement.noInvites")}</Typography>;
    }
    return (
      <Card>
        <CardHeader
          title={t("collaborativeCare:surveyManagement.title")}
          action={
            <Box marginRight="0.3em" sx={{ display: "flex" }}>
              <SendImmediateSurveyButton patient={props.patient} />
            </Box>
          }
        />
        <CardContent>{noInvitesElement}</CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:surveyManagement.title")} action={props.action} />
      <CardContent>
        <TableContainer component={Paper}>
          <Table size="small" aria-label="Surveys">
            <TableHead>
              <SurveyManagementDesktopTableHeader
                sortDirection={sortDirection}
                sortColumn={sortColumn}
                onSortChange={handleChangeSortColumn}
              />
            </TableHead>
            <TableBody>
              {props.invitations.slice(page * pageSize, page * pageSize + pageSize).map((invite, i) => (
                <React.Fragment key={i}>
                  <SurveyManagementDesktopTableRow
                    invitation={invite}
                    patientId={props.patient.id}
                    careEpisodeId={props.careEpisodeId}
                    forPreviousEnrollment={props.forPreviousEnrollment}
                  />
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.invitations.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

function TableHeader(props: {
  header: string;
  onClick: () => void;
  showSortIndicator: boolean;
  sortDirection: SortDirection;
  columnWidth: string;
}) {
  const sortIcon =
    props.sortDirection == SortDirection.DESC ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />;
  const sortElement = props.showSortIndicator ? sortIcon : <></>;
  return (
    <TableCell sx={{ width: props.columnWidth, whiteSpace: "nowrap" }} onClick={props.onClick}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="h3">{props.header}</Typography>
        {sortElement}
      </Stack>
    </TableCell>
  );
}

function ActionsDropdownDesktop(props: {
  invite: InviteSummary;
  careEpisodeId: CareEpisodeId;
  patientId: PatientId;
  forPreviousEnrollment?: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare"]);

  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const open = anchor != null;
  const handleOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  let reportLink = "";
  if (props.invite.scales[0]) {
    reportLink = `/app/care-units/${props.patientId}/care-episodes/${
      props.careEpisodeId
    }/feedback-report/scale-${props.invite.scales[0].scaleGroupId ? "group" : "administration"}-history/${
      props.invite.scales[0].scaleGroupId || props.invite.scales[0].id
    }`;
  }

  const element = (
    <div>
      <Button onClick={handleOpen}>
        <SettingsIcon />
        <ArrowDropDown fontSize="small" />
      </Button>
      <Menu open={open} onClose={handleClose} anchorEl={anchor}>
        <MenuItem
          disabled={
            props.forPreviousEnrollment || !props.invite.rawInvitation.manuallyAdministerable || false
          }
        >
          <Link to={`/provider/assessment/invitation/${props.invite.id}/edit`}>
            {t("collaborativeCare:surveyManagement.actions.administerNow")}
          </Link>
        </MenuItem>
        <SendReminderButton
          disabled={
            props.forPreviousEnrollment || !props.invite.rawInvitation.canResendNotifications || false
          }
          invitationId={props.invite.id}
          careEpisodeId={props.careEpisodeId}
          mobile={false}
        />
        <CancelInvitationButton
          disabled={!props.invite.manuallyCancelable}
          invitationId={props.invite.id}
          careEpisodeId={props.careEpisodeId}
          mobile={false}
        ></CancelInvitationButton>
        <Divider />
        <MenuItem disabled={props.invite.status != t("collaborativeCare:surveyManagement.status.complete")}>
          <Link to={reportLink}>{t("collaborativeCare:surveyManagement.actions.viewReport")}</Link>
        </MenuItem>
        <DownloadPdfButton
          disabled={!props.invite.downloadAvailable}
          invitationId={props.invite.id}
          mobile={false}
        />
      </Menu>
    </div>
  );
  return element;
}
