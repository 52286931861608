/**
 * Id generation
 *
 * We used typed ids to ensure that you cannot accidentally supply an id of the wrong type for function calls and the like.
 * However, given the way typescript works, in order to make this type safe. You have to wrap the string ids into another type.
 * This caused issues in other places:
 *  - `====` equality no longer works, so you can accidentally get `false` doing id === id.
 *  - Graphql caching does not seem to function as well as the id field is used for keying the cache.
 *  - It makes things harder to debug.
 *
 * So, in order to make everything work, we instead do the following:
 *  - Fully type everythinng from the graphql generation
 *  - Ensure that the ids are effectively tokens and have no methods except toString()
 *  - At run time, keep everything as a string rather than actually wrapping the string in the phantom type.
 *
 * This works because:
 *  - All the type information is thrown away at runtime anyway
 *  - You can't call any of the methods on ID anyway.
 */

import SumType from "sums-up";
import { Result, Ok, Err } from "seidr";
import { v4 as uuidv4 } from "uuid";
import { Phantom, identity, fromPairs } from "./Utils";
import { DeepPartial } from "fishery";

const UUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

const idSymbol = Symbol();

/**
 * NOTE THAT THIS IS A TYPE SYSTEM ONLY CONCEPT. At run time, all ids are represented as strings to get
 * better equality semantics.
 */
class _Id extends SumType<{ [idSymbol]: [string] }> {
  public toString() {
    return this.caseOf({
      [idSymbol]: identity,
    });
  }
}

// To work with specific Ids use Id<"Patient"> and with generic ids, use
// Id<string>
export type Id<A extends string> = Phantom<A, _Id>;

function fromString<A extends string>(rawId: string): Result<Error, Id<A>> {
  if (UUID_PATTERN.test(rawId)) {
    return Ok(rawId as unknown as Id<A>);
  } else {
    return Err(new Error(`'${rawId}' is not a valid UUID`));
  }
}

function fromNullableString<A extends string>(rawId: string | null | undefined): Result<Error, Id<A>> {
  if (rawId && UUID_PATTERN.test(rawId)) {
    return Ok(rawId as unknown as Id<A>);
  } else {
    return Err(new Error(`'${rawId}' is not a valid UUID`));
  }
}

export function fromOptionalDeepPartialOrGenerate<IdType extends string>(
  id: DeepPartial<Id<IdType>> | undefined
): Id<IdType> {
  if (id === undefined) {
    return generate<IdType>();
  }
  return unsafeFromUuid<IdType>(id as unknown as string);
}

// This method is designed for use in situtations where you are sure that the
// ID is well formed, e.g. it comes straight from a graphql query.  This should
// eventually be typed from the automatic graphql query, but right now that's
// coming out incorrectly as string.  For now, you are asserting that this
// string has come from a place where it is guaranteed to be a UUID.
function unsafeFromUuid<A extends string>(rawId: string): Id<A> {
  if (UUID_PATTERN.test(rawId)) {
    return rawId as unknown as Id<A>;
  } else {
    throw new Error(`'${rawId}' is not a valid UUID`);
  }
}

function toString<A extends string>(id: Id<A>): string {
  return id as unknown as string;
}

function generate<A extends string>(): Id<A> {
  return uuidv4() as unknown as Id<A>;
}

/**
 * Because ids are scalars, they have no inheritance in graphql. Therefore e.g. CareUnitId and PatientId are not related at all,
 * even though they are the same id. So allow forced conversion in limited cases via this method.
 * @param aId The input id to convert
 */
function forceConvertId<A extends string, B extends string>(aId: Id<A>): Id<B> {
  return unsafeFromUuid(aId.toString());
}

/**
 * Create a string map of id to object, using the given path function to get the id.
 * @param pathFn
 * @param collection
 */
function groupById<A, I extends string>(
  pathFn: (obj: A) => Id<I>,
  collection: ReadonlyArray<A>
): Record<string, A> {
  return fromPairs(collection.map((obj) => [pathFn(obj).toString(), obj]));
}

const Test = {
  _Id,
  UUID_PATTERN,
};

export default Id;
export {
  unsafeFromUuid,
  fromString,
  fromNullableString,
  toString,
  groupById,
  generate,
  forceConvertId,
  Test,
};
