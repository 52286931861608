import { Box, Button, Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import { Link } from "MDS/Link";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { MirahLogo } from "Shared/MirahIcon";
import { Helmet } from "react-helmet-async";
import { parse, RawProviderAuthenticationData } from "AppSession/AuthenticatedProviderUser";
import {
  authenticate,
  getAppSession,
  useCurrentInstituteLoginDetails,
  useFetch,
  UseFetchResultType,
} from "AppSession/AppSession";
import { useTranslation } from "react-i18next";
import { redirectInternalUser, shouldInternalRedirect } from "Shared/MirahPlugin";
import { EMBER_AUTH_LOCAL_STORAGE_KEY, PRIVACY_MODE_STORAGE_KEY } from "Shared/Storage";

// The result is going to be missing one bullshit parameter we'll just hardcode
// into the type.
type LocalStorageAuthenticationDataType = RawProviderAuthenticationData & {
  authenticator: "authenticator:devise";
};

function Login(): ReactElement {
  const { t } = useTranslation(["login"]);

  // The component at this route uses authenticated information to figure out where to go, use that instead of checking
  // feature flags here.
  let targetUrl = "/app/landing-page";

  // if you are logging into the internal institute, you'll want to go to the ops homepage
  // since schedule will not exist as a route
  getAppSession().caseOf({
    Unauthenticated: (appCtx, _appSessionId) =>
      appCtx.institute.caseOf({
        MirahInternalInstituteContext: () => {
          targetUrl = "/app/ops/";
        },
        ProviderInstituteContext: () => {
          return;
        },
        GroupLeaderInstituteContext: () => {
          return;
        },
      }),
    Uninitialized: () => {
      return;
    },
    Authenticated: () => {
      return;
    },
  });

  const actualUrl = window.location.pathname + window.location.search;
  if (actualUrl != "/app/login") {
    // Here we have a little bit of sleight of hand. If we're just chilling at /app/login,
    // then we forward the user to our current default (schedule, ember dashboard, or ops home page)
    // However, as this page is also a fallback route, if we end up at any other page, that
    // page itself is the targetUrl and we should attempt to go there again after logging in.
    targetUrl = actualUrl;
  }

  // First, if we're already logged in, we don't need to login again. Redirect the user
  // to the default page unless we came here with some sort of indication about where we want to go.
  if (getAppSession().type == "Authenticated") {
    window.location.href = targetUrl;
  }

  // We need some pre-baked data to decide how we want to proceed with login.
  const instituteLoginDetails = useCurrentInstituteLoginDetails();

  // OK, if we're not authenticated, should we be doing auth via plugin magic?
  if (shouldInternalRedirect() && instituteLoginDetails.id) {
    // For users running the mirah plugin who aren't logged into
    // this site we can actually just SSO them and get them on their way.
    redirectInternalUser(instituteLoginDetails.id.toString());
  } else if (instituteLoginDetails.forceSsoRedirect) {
    // Should we be logging in via SSO instead?
    // In this case, we've decided that we should handle login via SSO.
    // We'll want to redirect the user to our SAML endpoint which will handle
    // any further redirects.
    const destination = new URLSearchParams(window.location.search).get("targetUrl");
    const ssoUrl = `/auth/v1/sso/init?destination=${encodeURIComponent(
      destination || window.location.pathname + window.location.search
    )}`;
    window.location.href = ssoUrl;
  }

  // Alright, otherwise, folks are going to need to login

  const [username, setUsername] = useState("");
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const [password, setPassword] = useState("");
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [showError, setShowError] = useState(false);

  // We need to make a request to our login endpoint.
  // Here's the actual data we'll send.
  const postData = {
    user: {
      username: username,
      password: password,
      institute_id: instituteLoginDetails.id,
    },
    provider: "provider",
  };

  // Make the actual request.
  const { remoteData, fetchFn } = useFetch(
    "/users/sign_in",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    },
    [username, password, instituteLoginDetails.id?.toString()]
  );

  // Here's all of the handling for the possible results from our fetch.
  remoteData.caseOf({
    _: () => {
      /* noop */
    },
    Failure: () => {
      if (!showError) {
        setShowError(true);
      }
    },
    Success: (result: UseFetchResultType) => {
      // Now we can actually get out login result back.
      const responseData: LocalStorageAuthenticationDataType =
        result.json as LocalStorageAuthenticationDataType;

      // We still need to manually add in the field value, thanks typescript.
      responseData.authenticator = "authenticator:devise";

      // Now we can generate the correct localStorage shape and set our local storage entry.
      const localStorageSessionJson = {
        authenticated: responseData,
      };
      localStorage.setItem(EMBER_AUTH_LOCAL_STORAGE_KEY, JSON.stringify(localStorageSessionJson));

      // We also need to authenticate our app session or routes will get weird.
      authenticate(getAppSession(), parse(responseData));

      // Lastly, redirect to where we want to go.
      // Redirects outside of the app aren't possible in react router anymore, so we need to just
      // do a hard window location change.
      // TODO: now that we are authenticated, check to see if the target URL should change to schedule
      // based on the institute's feature gate
      window.location.href = targetUrl;
    },
  });

  // A login attempt.
  const login = async (event: React.SyntheticEvent, isDiscreet: boolean) => {
    // By default, a form submit will reload the page immediately, and as this function
    // is async it might get cut. By preventing the default behavior we will guarantee
    // execution.
    event.preventDefault();

    // We're just clearing the errors real quick if the user just pressed submit again.
    setShowError(false);

    // We want to kill any privacy mode stuff if we are doing a normal login.
    if (!isDiscreet) {
      sessionStorage.removeItem(PRIVACY_MODE_STORAGE_KEY);
    }

    fetchFn();
  };

  // This handles both a form submit (via hitting enter) or physically clicking
  // the login button.
  const handleSubmit = async (event: React.SyntheticEvent) => {
    login(event, false);
  };

  // This handles clicking the discreet login button.
  const handleLoginDiscreet = (event: React.SyntheticEvent) => {
    sessionStorage.setItem(PRIVACY_MODE_STORAGE_KEY, "true");
    login(event, true);
  };

  // We're going to block form submission via the button at least if
  // there's no username and password entered.
  const isFormValid = () => {
    return username.length > 0 && password.length > 0;
  };

  const theme = useTheme();

  return (
    <Box>
      <Helmet>
        <title>{t("login:titles.login")}</title>
      </Helmet>
      <Grid container columns={12} spacing={0}>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Typography sx={{ float: "right", mt: 0.5, mr: 0.5 }}>{instituteLoginDetails.name}</Typography>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} alignItems="center">
            <MirahLogo width="100px" height="100px" />

            <Box width="20em">
              <TextField
                id="username"
                label={t("login:fields.username")}
                onChange={handleUsernameChange}
                value={username}
                variant="outlined"
                fullWidth
              />
            </Box>

            <Box width="20em">
              <TextField
                id="password"
                label={t("login:fields.password")}
                onChange={handlePasswordChange}
                onSubmit={handleSubmit}
                value={password}
                variant="outlined"
                type="password"
                fullWidth
              />
            </Box>

            <Box sx={{ display: showError ? "block" : "none" }}>
              <Typography color={theme.palette.warning.main}>{t("login:errors.invalidPassword")}</Typography>
            </Box>

            <Box width="14em">
              <Button
                id="Log In"
                fullWidth
                color="primary"
                variant="contained"
                disabled={!isFormValid()}
                sx={{ marginTop: "1em" }}
                type="submit"
              >
                {t("login:buttons.login")}
              </Button>
            </Box>
            <Box width="14em">
              <Button
                id="Log In Discreet"
                fullWidth
                color="primary"
                variant="contained"
                disabled={!isFormValid()}
                onClick={handleLoginDiscreet}
              >
                {t("login:buttons.loginDiscreet")}
              </Button>
            </Box>
            <Box>
              <Stack direction="row" spacing={2} marginTop="1em">
                <Link to="/app/login/forgotpassword">{t("login:links.forgotPassword")}</Link>
                <Link to="/images/MirahProviderTermsOfUse.pdf">{t("login:links.termsOfUse")}</Link>
                <Link to="https://mirah-pub.s3.amazonaws.com/MirahPrivacyPolicy.pdf">
                  {t("login:links.privacyPolicy")}
                </Link>
                <Link to={"mailto:" + t("login:links.supportEmail")}>{t("login:links.supportEmail")}</Link>
              </Stack>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}

export default Login;
