import { Stack, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "Shared/Responsive";
import { FontSize } from "Style/Theme/typography";
import React, { ReactElement, ReactNode } from "react";

type SectionProps = {
  title: string;
  titleFontSize?: string;
  actions?: ReactNode;
};

/**
 * A large header and underline wrapping some content. May optionally have buttons alongside the header.
 *
 * This is originally based on the design for the schedule page (SchedulingAppointments.tsx) but that page uses some
 * different layout so I haven't tried harmonizing everything under one component. We should consider promoting this
 * into MDS and doing that though.
 */
export function Section(props: React.PropsWithChildren<SectionProps>): ReactElement {
  const theme = useTheme();

  const titleSize = props.titleFontSize || "2rem";
  const extraTitleMargin = useIsMobile() ? "0" : "4rem";
  const titleActionsJustification = useIsMobile() ? "space-betwee" : "flex-start";

  return (
    <Stack direction="column" spacing={0}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.dividerLight}`}
        justifyContent={titleActionsJustification}
        // Set a fixed height here so that section titles take up the same amount of space no matter the contents in
        // actions. This height is just big enough to fit a Mui input.
        height="3.625rem"
      >
        <Typography
          id={props.title}
          component="h2"
          fontSize={titleSize as FontSize}
          fontWeight="bold"
          marginRight={extraTitleMargin}
        >
          {props.title}
        </Typography>
        {props.actions}
      </Stack>
      {props.children}
    </Stack>
  );
}
