import { Alert, Box, Stack } from "@mui/material";
import { WithFeature } from "Contexts/CurrentInstituteContext";
import { careEpisodeStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisode,
  CareEpisodeMeasurementAllowedDetailsFragment,
  DataSourceResourceType,
  Organization,
  TreatmentService,
} from "GeneratedGraphQL/SchemaAndOperations";
import IntegrationStatusBadge from "Integrations/IntegrationStatusBadge";
import { ImportHistoryId } from "Lib/Ids";
import CareEpisodeMeasurementAllowedSwitch from "Patients/CareEpisodes/CareEpisodeMeasurementAllowedSwitch";
import InfoSidebarCard, { LabelAndInfo, LabelAndRichInfo } from "Shared/InfoSidebarCard";
import { WithPermission } from "Shared/WithPermission";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import CareEpisodeDecisionBadgeLoaded from "./CareEpisodeDecisionBadge";
import { careEpisodeDates } from "./CareEpisodeHelpers";
import { EditCareEpisodeButton } from "./EditCareEpisodeButton";
import { add } from "date-fns";

type CareEpisodeInfoCardProps = {
  careEpisode: Pick<
    CareEpisode,
    "id" | "status" | "periodEnd" | "periodStart" | "dischargeDueToInactivityDate"
  > & {
    treatmentService: Pick<TreatmentService, "name"> | null;
    organization: Pick<Organization, "name"> | null;
  } & CareEpisodeMeasurementAllowedDetailsFragment;
};

export default function CareEpisodeInfoCard(props: CareEpisodeInfoCardProps): ReactElement {
  const { t } = useTranslation(["careEpisodes", "common", "enums"]);
  const { careEpisode } = props;

  const cardAction = (
    <WithPermission permission="scheduleAppointment">
      <Box marginRight="0.3em">{<EditCareEpisodeButton careEpisodeId={props.careEpisode.id} />}</Box>
    </WithPermission>
  );

  const dischargeApproaching =
    props.careEpisode.dischargeDueToInactivityDate &&
    add(new Date(), { days: 14 }) > props.careEpisode.dischargeDueToInactivityDate;

  const dischargeWarning = dischargeApproaching ? (
    <Alert severity="warning">
      {t("careEpisodes:autoDischargeWarning", { date: props.careEpisode.dischargeDueToInactivityDate })}
    </Alert>
  ) : null;

  const header = (
    <Stack spacing={1}>
      <IntegrationStatusBadge
        importHistoryId={props.careEpisode.id as unknown as ImportHistoryId}
        resourceType={DataSourceResourceType.CARE_EPISODE}
      />
      {dischargeWarning}
      <CareEpisodeDecisionBadgeLoaded careEpisode={props.careEpisode} />
    </Stack>
  );

  return (
    <InfoSidebarCard action={cardAction} header={header} title={t("careEpisodes:cards.details")}>
      <LabelAndInfo
        label={t("careEpisodes:fields.status")}
        data={careEpisodeStatusT(careEpisode.status, t)}
      />
      <LabelAndRichInfo
        label={t("careEpisodes:fields.measurementAllowed")}
        data={
          <CareEpisodeMeasurementAllowedSwitch
            id={props.careEpisode.id}
            measurementAllowed={props.careEpisode.measurementAllowed}
          />
        }
      />
      <LabelAndInfo
        label={t("careEpisodes:fields.dates")}
        data={careEpisodeDates(careEpisode.periodStart, careEpisode.periodEnd, t)}
      />
      <WithFeature feature="enableTreatmentServices">
        <LabelAndInfo
          label={t("careEpisodes:fields.treatmentService")}
          data={careEpisode.treatmentService?.name || null}
        />
      </WithFeature>
      <LabelAndInfo
        label={t("careEpisodes:fields.organization")}
        data={careEpisode.organization.name || null}
      />
    </InfoSidebarCard>
  );
}
