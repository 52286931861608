import React, { ReactElement } from "react";
import { useTheme } from "@mui/material";
import { Chip } from "Layout/Chip";

type Props = {
  width?: number;
};

function NotTakenChip(props: Props): ReactElement {
  const { width } = props;
  const theme = useTheme();

  return (
    <Chip
      label="Not taken"
      backgroundColor={theme.palette.report.blank.primary}
      labelColor={theme.palette.text.primary}
      width={width}
    />
  );
}

export default NotTakenChip;
