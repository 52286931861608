import { SupervisedUserCircle } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useSelectedPanels } from "Contexts/SelectedPanelContext";
import { PanelStatus, usePanelsListQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { PanelId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { OnDesktop, OnMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { panelContainsProvider } from "./Panel";
import { useCurrentProviderId } from "AppSession/AppSession";
import { WithFeature } from "Contexts/CurrentInstituteContext";

export function GlobalPanelIndicatorBadge(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();
  const panelSelection = useSelectedPanels();

  const currentProviderId = useCurrentProviderId();

  const { remoteData } = apolloQueryHookWrapper(
    usePanelsListQuery({ variables: { status: PanelStatus.ACTIVE } })
  );
  const [myPanels, activePanels] = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCarePanels) {
        const allPanels = response.collaborativeCarePanels.nodes;
        const myPanels = allPanels.filter((panel) => panelContainsProvider(panel, currentProviderId));
        // Panels are already filtered by status in the query so we don't need to do it again here
        const activePanels = allPanels.filter((panel) => !panelContainsProvider(panel, currentProviderId));

        myPanels.sort((a, b) => a.name.localeCompare(b.name));
        activePanels.sort((a, b) => a.name.localeCompare(b.name));

        return [myPanels, activePanels];
      } else {
        return [[], []];
      }
    },
    _: () => [[], []],
  });

  const selectedIds = panelSelection.panels;
  const allMyPanelsSelected =
    myPanels.length > 0 && myPanels.every((panel) => selectedIds.includes(panel.id));
  const allActivePanelsSelected =
    activePanels.length > 8 && activePanels.every((panel) => selectedIds.includes(panel.id));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const addRemovePanelFromSelection = (panelId: PanelId) => {
    if (selectedIds.includes(panelId)) {
      panelSelection.setPanelList(panelSelection.panels.filter((id) => id !== panelId));
    } else {
      panelSelection.setPanelList([...panelSelection.panels, panelId]);
    }
  };

  // The logic in these functions to add/remove a list of panels can end up with the same panel id appearing twice in
  // the selected panels list, if you select a single panel and then select all of the list it contains. This works out
  // okay though because when we remove a panel we remove all copies of it from the list, so there's no user-visible
  // impact to the dupes.

  const addRemoveAllMyPanels = () => {
    const myPanelIds = myPanels.map((panel) => panel.id);
    if (allMyPanelsSelected) {
      panelSelection.setPanelList(panelSelection.panels.filter((id) => !myPanelIds.includes(id)));
    } else {
      panelSelection.setPanelList([...panelSelection.panels, ...myPanelIds]);
    }
  };

  const addRemoveAllActivePanels = () => {
    const activePanelIds = activePanels.map((panel) => panel.id);
    if (allActivePanelsSelected) {
      panelSelection.setPanelList(panelSelection.panels.filter((id) => !activePanelIds.includes(id)));
    } else {
      panelSelection.setPanelList([...panelSelection.panels, ...activePanelIds]);
    }
  };

  // Being slightly tricky with the colors here so they work on both desktop (light background) and mobile (dark
  // background). Transparent background and inherit text let the disabled color scheme adapt to its surroundings.
  const backgroundColor =
    panelSelection.mode === "panels-active" ? theme.palette.collaborativeCare.panels.main : "transparent";
  const textColor =
    panelSelection.mode === "panels-active" ? theme.palette.collaborativeCare.panels.text : "inherit";

  const enabled = panelSelection.mode !== "panels-ignored";

  // Forcing these text blocks to a small size so they don't warp the total menu size when all the panels have short
  // names
  const noMyPanelsMessage = (
    <MenuItem sx={{ justifyContent: "center" }}>
      <Typography variant="caption" maxWidth="12rem" whiteSpace="collapse" textAlign="center">
        {t("collaborativeCare:panels.myPanels.noPanels")}
      </Typography>
    </MenuItem>
  );
  const noActivePanelsMessage = (
    <MenuItem sx={{ justifyContent: "center" }}>
      <Typography variant="caption" maxWidth="12rem" whiteSpace="collapse" textAlign="center">
        {t("collaborativeCare:panels.activePanels.noPanels")}
      </Typography>
    </MenuItem>
  );

  const panelSelectList = (
    <MenuList>
      <MenuItem onClick={addRemoveAllMyPanels}>
        <ListItemIcon>
          <Checkbox checked={allMyPanelsSelected} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h2">{t("collaborativeCare:panels.myPanels.title")}</Typography>
        </ListItemText>
      </MenuItem>
      {myPanels.map((panel) => (
        <MenuItem
          key={panel.id.toString()}
          onClick={() => addRemovePanelFromSelection(panel.id)}
          sx={{ whiteSpace: "collapse" }}
        >
          <ListItemIcon>
            <Checkbox checked={selectedIds.includes(panel.id)} />
          </ListItemIcon>
          <ListItemText>{panel.name}</ListItemText>
        </MenuItem>
      ))}
      {myPanels.length === 0 ? noMyPanelsMessage : null}
      <Divider />
      <MenuItem onClick={addRemoveAllActivePanels}>
        <ListItemIcon>
          <Checkbox checked={allActivePanelsSelected} />
        </ListItemIcon>
        <ListItemText>
          <Typography variant="h2" component="span">
            {t("collaborativeCare:panels.activePanels.title")}
          </Typography>
        </ListItemText>
      </MenuItem>
      {activePanels.map((panel) => (
        <MenuItem
          key={panel.id.toString()}
          onClick={() => addRemovePanelFromSelection(panel.id)}
          sx={{ whiteSpace: "collapse" }}
        >
          <ListItemIcon>
            <Checkbox checked={selectedIds.includes(panel.id)} />
          </ListItemIcon>
          <ListItemText>{panel.name}</ListItemText>
        </MenuItem>
      ))}
      {activePanels.length === 0 ? noActivePanelsMessage : null}
    </MenuList>
  );

  return (
    <WithFeature feature="enableCollaborativeCare">
      <Chip
        icon={<SupervisedUserCircle color="inherit" />}
        label={t("collaborativeCare:panels.titleWithCount", { count: panelSelection.panels.length })}
        clickable={enabled}
        onClick={enabled ? handleClick : undefined}
        size="medium"
        sx={{
          backgroundColor: backgroundColor,
          color: textColor,
          fontWeight: "bold",
          fontSize: "1rem",
          // currentColor matches the current _text_ color, so the border always has a contrast with the background
          // whether it's on light or dark.
          border: "solid currentColor 1px",
        }}
      />
      <OnDesktop>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {panelSelectList}
        </Menu>
      </OnDesktop>
      <OnMobile>
        <ResponsiveDialog open={open} title={t("collaborativeCare:panels.selectPanel")} onClose={handleClose}>
          {panelSelectList}
        </ResponsiveDialog>
      </OnMobile>
    </WithFeature>
  );
}
