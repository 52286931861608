import React, { ReactElement } from "react";
import * as CareEpisode from "Shared/CareEpisode";
import { Button, Box, Typography, Link as MUILink } from "@mui/material";
import { styled } from "@mui/material/styles";
import { differenceInYears } from "date-fns";
import {
  clientParticipants,
  nonPatientParticipants,
  ParticipantSummary,
  isPatient,
} from "Shared/Participant";
import { find, humanize } from "Lib/Utils";
import { Link } from "MDS/Link";
import ParticipantAvatar from "FeedbackReport/ParticipantAvatar";

import {
  InvitationWithUserSummary,
  invitationsForCurrentUser,
  editInvitationLink,
  invitationActionName,
} from "Shared/Scale/Invitation";
import * as NEL from "Lib/NonEmptyList";
import ParticipantAvatarAndName from "FeedbackReport/ParticipantAvatarAndName";
import { ProviderSummary } from "Shared/Provider";
import { PatientSessionSummary } from "Shared/PatientSession";
import { formatDate } from "Shared/formatters";
import { useTranslation } from "react-i18next";
import { PatientConditionDetails, PatientConditionSummaryModal } from "./PatientConditionModal";
import { WithFeature, useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { PickTypename } from "type-utils";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { Maybe } from "seidr";
import { PatientId } from "Lib/Ids";

const StyledSubtitle = styled(Typography)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
}));

type CareTeamProps = {
  careTeam: ReadonlyArray<ProviderSummary>;
};

type PatientWithDemographics = PickTypename<
  Patient,
  "dob" | "gender" | "genderIdentity" | "genderIdentityFhir" | "preferredPronouns" | "name" | "id"
>;

function CareTeam(props: CareTeamProps): ReactElement {
  const { careTeam } = props;

  const team = NEL.fromArray(careTeam).map((list) =>
    list.mapWithIndex((member, i) => {
      const comma = i > 0 ? ", " : null;
      return (
        <span key={i}>
          {comma}
          <span>{member.user.name}</span>
        </span>
      );
    })
  );

  return (
    <StyledSubtitle variant="subtitle2">
      <Box sx={{ mr: 0.5 }}>
        <strong>Care Team:</strong>
      </Box>
      {team.getOrElse(<em>No care team assigned</em>)}
    </StyledSubtitle>
  );
}

function PatientConditions(props: {
  patientConditions: ReadonlyArray<PatientConditionDetails>;
}): ReactElement {
  const { patientConditions } = props;

  const codesOnly = patientConditions.length > 3;

  const [activePatientCondition, setActivePatientCondition] = React.useState<PatientConditionDetails | null>(
    null
  );

  const conditions = NEL.fromArray(patientConditions).map((list) =>
    list.mapWithIndex((condition, i) => {
      const comma = i > 0 ? ", " : null;

      // For space reasons, only show the code if there are more than 3 conditions
      const text = codesOnly
        ? condition.instituteCondition.code
        : `${condition.instituteCondition.name} (${condition.instituteCondition.code})`;
      return (
        <span key={i}>
          {comma}
          <MUILink onClick={() => setActivePatientCondition(condition)}>{text}</MUILink>
        </span>
      );
    })
  );

  return (
    <StyledSubtitle variant="subtitle2">
      <PatientConditionSummaryModal
        onClose={() => setActivePatientCondition(null)}
        patientCondition={activePatientCondition}
      />
      <Box sx={{ mr: 0.5 }}>
        <strong>Diagnoses:</strong>
      </Box>
      {conditions.getOrElse(<em>None</em>)}
    </StyledSubtitle>
  );
}

export function demographics(patient: PatientWithDemographics): string {
  const { t } = useTranslation(["report"]);

  const dob = Maybe.fromNullable(patient.dob).caseOf({
    Nothing: () => t("report:demographics.ageUnknown"),
    Just: (dob) => t("report:demographics.age", { age: differenceInYears(new Date(), dob) }),
  });

  // Reminder that 'gender' in the FHIR spec is biological sex.
  const sexString = Maybe.fromNullable(patient.gender).caseOf({
    Just: (gender) => t("report:demographics.sex", { sex: humanize(gender) }),
    Nothing: () => t("report:demographics.sexUnknown"),
  });

  const genderString = Maybe.fromNullable(patient.genderIdentity)
    .orElse(() => Maybe.fromNullable(patient.genderIdentityFhir).map(humanize))
    .map((genderString) => {
      return t("report:demographics.gender", { gender: genderString });
    })
    .getOrElse("");

  const pronounString = Maybe.fromNullable(patient.preferredPronouns)
    .map((pronouns) => t("report:demographics.pronouns", { pronouns }))
    .getOrElse("");

  const elements = [dob, sexString, pronounString].filter((el) => el.length > 0);

  if (genderString) {
    elements.push(genderString);
  }

  return elements.join("; ");
}

function ParticipantList(props: { participants: ReadonlyArray<ParticipantSummary> }): ReactElement | null {
  const { participants } = props;

  const participantsWithAvatars = clientParticipants(nonPatientParticipants(participants)).map(
    (participant, i) => {
      return <ParticipantAvatarAndName key={i} displayRelationship={true} participant={participant} />;
    }
  );

  if (participantsWithAvatars.length) {
    return (
      <>
        <Box sx={{ mr: 0.5 }}>&nbsp;</Box>
        <Box sx={{ mr: 0.5 }}>In treatment with:</Box>
        {participantsWithAvatars}
      </>
    );
  } else {
    return null;
  }
}

function ActiveInvitations(props: {
  invitations: ReadonlyArray<InvitationWithUserSummary>;
}): ReactElement | null {
  const result = NEL.fromArray(invitationsForCurrentUser(props.invitations));

  return result.caseOf({
    Just: (displayableInvitations) => {
      return (
        <Box>
          {displayableInvitations.map((invitation) => {
            return (
              <Button
                key={invitation.id.toString()}
                href={editInvitationLink(invitation.id)}
                variant="contained"
                color="primary"
                size="small"
              >
                {invitationActionName(invitation)}
              </Button>
            );
          })}
        </Box>
      );
    },
    Nothing: () => null,
  });
}

type PatientSessionInfoProps = {
  patientSession: PatientSessionSummary;
};

function PatientSessionInfo(props: PatientSessionInfoProps): ReactElement {
  const { patientSession } = props;

  const { t } = useTranslation(["report"]);
  const latestDate = formatDate(patientSession.targetDate);

  return (
    <Box>
      {patientSession.sessionNumber
        .map((n) =>
          t("report:latestSessionWithNumber", {
            date: latestDate,
            sessionNumber: n,
          })
        )
        .getOrElse(
          t("report:latestSessionNoNumber", {
            date: latestDate,
          })
        )}
    </Box>
  );
}

const StyledTitle = styled(Typography)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

const StyledPatientLink = styled(Link)(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

type Props = {
  patient: PatientWithDemographics;
  careEpisode: CareEpisode.CareEpisodeDetail;
  careTeam: ReadonlyArray<ProviderSummary>;
  patientConditions: ReadonlyArray<PatientConditionDetails>;
  activeInvitations: ReadonlyArray<InvitationWithUserSummary>;
  flatten: boolean;
};

function DemographicInfo(props: Props): ReactElement {
  const { patient, careEpisode, careTeam, patientConditions, flatten } = props;
  const { t } = useTranslation(["report"]);

  const patientAvatar = find(isPatient, careEpisode.participants).map((patient) => (
    <Box sx={{ ml: 1, mr: 0.5 }}>
      <ParticipantAvatar participant={patient} />
    </Box>
  ));

  const patientSessionInfo = careEpisode.latestPatientSessionWithFeedback.map((patientSession) => (
    <PatientSessionInfo patientSession={patientSession} />
  ));

  const flattenContent = (
    <StyledSubtitle variant="subtitle2">
      <Box sx={{ mr: 0.5 }}>
        <strong>{CareEpisode.humanizeStatus(careEpisode.status)}</strong>
      </Box>
      <Box sx={{ mr: 0.5 }}>&mdash;</Box>
      <Box sx={{ mr: 0.5 }}>{careEpisode.organizationSummary.name}</Box>
      <Box sx={{ mr: 0.5 }}>&mdash;</Box>
      <Box sx={{ mr: 0.5 }}>{t("report:startedOn", { date: careEpisode.periodStart })}</Box>
      {patientSessionInfo.getOrElse(null)}
    </StyledSubtitle>
  );

  const boxContent = (
    <StyledSubtitle variant="subtitle2">
      <table>
        <tbody>
          <tr>
            <td style={{ paddingRight: "1.5rem" }}>
              <strong>{CareEpisode.humanizeStatus(careEpisode.status)}</strong>
            </td>
            <td>{careEpisode.organizationSummary.name}</td>
          </tr>
          <tr>
            <td></td>
            <td>{t("report:startedOn", { date: careEpisode.periodStart })}</td>
          </tr>
          <tr>
            <td></td>
            <td>{patientSessionInfo.getOrElse(null)}</td>
          </tr>
        </tbody>
      </table>
    </StyledSubtitle>
  );

  const patientLinkUrl = usePatientLinkUrl(patient.id);

  return (
    <Box sx={{ mb: 0.5 }}>
      <StyledTitle variant="h1">
        Feedback Report:
        <StyledPatientLink to={patientLinkUrl}>
          {patientAvatar.getOrElse(null)}
          {patient.name}
        </StyledPatientLink>
      </StyledTitle>
      <Box sx={{ mt: 0.5, display: "flex", justifyContent: "space-between" }}>
        <Box>
          <StyledSubtitle variant="subtitle2">
            <Box sx={{ mr: 0.5 }}>
              {demographics(patient)}
              <ParticipantList participants={careEpisode.participants} />
            </Box>
          </StyledSubtitle>
          {flatten ? flattenContent : boxContent}
          <CareTeam careTeam={careTeam} />
          <WithFeature feature="enableConditions">
            <PatientConditions patientConditions={patientConditions} />
          </WithFeature>
        </Box>
        <ActiveInvitations invitations={props.activeInvitations} />
      </Box>
    </Box>
  );
}

export function usePatientLinkUrl(patientId: PatientId) {
  if (useWithFeatureEnabled("enableCollaborativeCare")) {
    return `/app/cocm/patient/${patientId}`;
  }
  return `/provider/patients/${patientId}`;
}

export { DemographicInfo };
