import { Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";

export function QualityIssueSummary(): ReactElement {
  return (
    <Stack direction="column" spacing={1} alignItems="center" margin="3rem">
      <Typography variant="h1" component="h1">
        Patient Data Quality Issues
      </Typography>
      <Typography variant="body1">
        On this page, you can see which patients have potential issues that may stop them from being
        successfully measured. In order to proceed, choose a patient from the left hand side list.
      </Typography>
    </Stack>
  );
}
