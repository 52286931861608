import React, { ReactElement } from "react";
import { Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";

/**
 * Extremely basic blockquote styling. With no text this will return nothing, so you can throw it in unconditionally if
 * you aren't sure if you'll have content. Optional citation prop for when the author isn't clear from context. Uses
 * semantic <blockquote> and <cite> elements.
 *
 * We may need to extend this in the future to take a full component as text to do things like formatting the quote, but
 * that's a problem for future us.
 */
export function Blockquote(props: { text?: string | null; citation?: string }): ReactElement | null {
  const theme = useTheme();

  if (!props.text) {
    return null;
  }

  const citation = (
    <Typography variant="caption" component="cite" sx={{ paddingTop: "1rem" }}>
      {props.citation}
    </Typography>
  );

  return (
    <Box
      sx={{
        padding: "1rem",
        borderLeft: `0.5rem solid ${theme.palette.dividerChip}`,
        backgroundColor: theme.palette.divider,
      }}
    >
      <Typography variant="body1" component="blockquote">
        {props.text}
      </Typography>
      {props.citation ? citation : null}
    </Box>
  );
}
