import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  CaseConsult,
  CaseConsultStatus,
  useDiscardTimerMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { useNavigate } from "react-router-dom";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { refetchQueries } from "Lib/RefetchQueries";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { PatientId } from "Lib/Ids";
import { PickTypename } from "type-utils";

type CancelConsultReviewButtonProps = {
  patientId: PatientId;
  consult: PickTypename<CaseConsult, "status">;
};

// This button's behavior is actually much like the discard timer button on the time tracking
// app bar. It needs to discard the current time tracking, if applicable, and bring the user
// back to the previous page.
export default function CancelConsultReviewButton(props: CancelConsultReviewButtonProps): ReactElement {
  const currentTimeTracking = useCurrentTimeTracking();
  const navigate = useNavigate();

  const [discardTimer, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTimeEntryLog,
    useDiscardTimerMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        navigate("../..", { relative: "path" });
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";
  const disabled = props.consult.status === CaseConsultStatus.COMPLETE || remoteData.kind === "Loading";

  // We need to exit early if we're not tracking so we can have a currentTimeTracking.timeEntryLogId
  // in the success case. This version just has a simple back behavior rather than canceling
  // anything in particular.
  if (currentTimeTracking.status !== "tracking") {
    return (
      <CancelConsultReviewButtonElement
        onClick={() => {
          navigate("../..", { relative: "path" });
        }}
        showSpinner={false}
        disabled={false}
      />
    );
  }

  return (
    <CancelConsultReviewButtonElement
      onClick={() => {
        discardTimer({
          variables: {
            input: {
              timeEntryLogId: currentTimeTracking.timeEntryLogId, // We need to do a late binding here.
            },
          },
        });
      }}
      showSpinner={showSpinner}
      disabled={disabled}
    />
  );
}

type CancelConsultReviewButtonElementProps = {
  showSpinner: boolean;
  disabled: boolean;
  onClick: () => void;
};
function CancelConsultReviewButtonElement(props: CancelConsultReviewButtonElementProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return (
    <ButtonWithSpinner
      variant="contained"
      color="error"
      showSpinner={props.showSpinner}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {t("collaborativeCare:caseConsult.caseConsultReview.actions.cancel")}
    </ButtonWithSpinner>
  );
}
