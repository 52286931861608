import React, { ReactElement } from "react";
import { Button, Stack } from "@mui/material";
import { PatientId, TaskId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import { EditTaskFormWithTaskId } from "CollaborativeCare/CreateOrEditTaskForm";

function EditPlaceholderTitleAndBodyDialog(props: {
  onBack: () => void;
  onSuccess?: () => void;
  patientId: PatientId;
  taskId: TaskId;
  setShowSurroundingForms?: (showForms: boolean) => void;
}): ReactElement {
  useTranslation(["collaborativeCare", "common"]);

  return (
    <>
      <Stack direction="column" spacing={1}>
        <EditTaskFormWithTaskId onSuccess={props.onSuccess} taskId={props.taskId} />
        <Stack direction="row-reverse" spacing={1}>
          <Button onClick={props.onBack} variant="outlined" color="secondary">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default EditPlaceholderTitleAndBodyDialog;
