import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EntityTreeNodeParams,
  ImplementationTarget,
  ImplementationTargetReportDate,
  useImplementationTargetReportDataSummaryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ImplementationTargetId } from "Lib/Ids";
import React from "react";
import { ArrowDropDown } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import Link, { LinkButton } from "MDS/Link";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import { WithPermission } from "Shared/WithPermission";
import EntityPath, { EntityTreeNodeDetails } from "Entities/EntityPath";
import {
  ImplementationTargetHeadlineGraph,
  ImplementationTargetHeadlineStatBox,
  ImplementationTargetOnTrackBadge,
} from "./ImplementationTargetElements";

export type TargetDetails = Pick<
  ImplementationTarget,
  "id" | "name" | "description" | "targetType" | "startDate" | "initialCredits" | "softLaunchDate" | "target"
> & {
  entityTreeNode: EntityTreeNodeDetails;
  closestReportDateTo: Pick<ImplementationTargetReportDate, "period" | "endDate"> | null;
};

type CurrentReportProps = {
  target: Pick<ImplementationTarget, "id" | "name" | "description" | "targetType">;
  entityTreeNode: EntityTreeNodeDetails;
  closestReportDateTo: Pick<ImplementationTargetReportDate, "period" | "endDate">;
};

function ImplementationTargetCurrentReport(props: CurrentReportProps) {
  const { t } = useTranslation(["common", "implementation"]);
  const { search } = useLocation();
  const { target, closestReportDateTo } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetReportDataSummaryQuery({
      variables: {
        implementationTargetId: target.id,
        // We do not send any entity tree node params. We want to use the parameters of the
        // target itself so it is not confusing.
        entityTreeNode: null,
        dateAndPeriod: {
          // If you don't splat it out then it'll put the __typename in and it'll error.
          period: closestReportDateTo.period,
          endDate: closestReportDateTo.endDate,
        },
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => null,
    NotAsked: () => null,
    Failure: () => {
      return <>{t("implementation:card.errorLoadingChart")}</>;
    },
    Success: (result) => {
      if (result.implementationTargetReport) {
        const statBoxes = result.implementationTargetReport.headlineStats.map((stat, index) => {
          return (
            <ImplementationTargetHeadlineStatBox
              endDate={props.closestReportDateTo.endDate}
              stat={stat}
              period={stat.period}
              key={index}
            />
          );
        });

        return (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              <ImplementationTargetHeadlineGraph
                series={result.implementationTargetReport.graphData}
                unitClass={result.implementationTargetReport.unitClass}
                maxValue={result.implementationTargetReport.graphMaxValue}
                targetType={target.targetType}
              />
            </Grid>
            <Grid item xs={3}>
              <Stack direction={"column"} spacing={1}>
                {statBoxes}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <ImplementationTargetOnTrackBadge onTrack={result.implementationTargetReport.onTrack} />
            </Grid>
            <Grid item xs={12}>
              <LinkButton
                variant="contained"
                color="secondary"
                fullWidth
                to={`${result.implementationTargetReport.implementationTarget.id.toString()}${search}`}
              >
                {t("implementation:card.moreInsights")}
              </LinkButton>
            </Grid>
          </Grid>
        );
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
  });
}

export function ImplementationTargetCard(props: {
  implementationTarget: TargetDetails;
  entityTreeNodeParams: EntityTreeNodeParams;
}) {
  const { search } = useLocation();
  const { t } = useTranslation(["implementation"]);

  const title = (
    <Link to={`${props.implementationTarget.id.toString()}${search}`}>{props.implementationTarget.name}</Link>
  );

  const target = props.implementationTarget;
  const content = target.closestReportDateTo ? (
    <ImplementationTargetCurrentReport
      target={target}
      closestReportDateTo={target.closestReportDateTo}
      entityTreeNode={target.entityTreeNode}
    />
  ) : (
    <Typography>{t("implementation:card.noData")}</Typography>
  );
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={
          <EntityPath
            includeInstitute={"when_solo"}
            entityTreeNode={props.implementationTarget.entityTreeNode}
          />
        }
        action={<EditButtonWithMenu id={props.implementationTarget.id} />}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}

type EditButtonWithMenuProps = {
  id: ImplementationTargetId;
};

function EditButtonWithMenu(props: EditButtonWithMenuProps) {
  const { t } = useTranslation(["common", "implementation"]);
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const open = anchor != null;
  const handleOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <WithPermission permission="editImplementationTargets">
        <Button onClick={handleOpen}>
          <SettingsIcon />
          <ArrowDropDown fontSize="small" />
        </Button>
        <Menu open={open} onClose={handleClose} anchorEl={anchor}>
          <Link to={`${props.id.toString()}/edit`}>
            <MenuItem>{t("common:edit")}</MenuItem>
          </Link>
        </Menu>
      </WithPermission>
    </>
  );
}
