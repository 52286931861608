import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Card, CardContent, CardHeader, CircularProgress } from "@mui/material";
import { apolloQueryHookWrapper, ApolloQueryRemoteData } from "Api/GraphQL";
import {
  TreatmentService,
  TreatmentServiceTreeQuery,
  useTreatmentServiceTreeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TreatmentServiceId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ancestry, treeify } from "Shared/Ancestry";
import ErrorMessage from "Shared/ErrorMessage";
import { OnlyExpandOnIconClickTreeItem } from "Shared/OnlyExpandOnIconClickTreeItem";

type TreatmentServiceSummary = Pick<TreatmentService, "__typename" | "id" | "name" | "parentId">;

type TreatmentServiceTree = TreatmentServiceSummary & {
  children: Array<TreatmentServiceTree>;
};

function TreatmentServiceTreeItem(props: TreatmentServiceTree): ReactElement {
  return (
    <OnlyExpandOnIconClickTreeItem nodeId={props.id.toString()} label={props.name}>
      {props.children.map((o, i) => (
        <TreatmentServiceTreeItem {...o} key={i} />
      ))}
    </OnlyExpandOnIconClickTreeItem>
  );
}

type TreatmentServiceSectionHookWrapperProps = {
  selectedTreatmentService: TreatmentServiceId | null;
  setSelectedTreatmentService: (newOrg: TreatmentServiceId) => void;
};

function TreatmentServiceSectionHookWrapper(props: TreatmentServiceSectionHookWrapperProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useTreatmentServiceTreeQuery());
  return <TreatmentServiceSection treatmentServiceData={remoteData} {...props} />;
}

type TreatmentServiceSectionProps = {
  selectedTreatmentService: TreatmentServiceId | null;
  treatmentServiceData: ApolloQueryRemoteData<TreatmentServiceTreeQuery>;
  setSelectedTreatmentService: (newOrg: TreatmentServiceId) => void;
};

function TreatmentServiceSection(props: TreatmentServiceSectionProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  function handleNodeSelect(_event: React.SyntheticEvent, id: Array<string> | string) {
    props.setSelectedTreatmentService(id as unknown as TreatmentServiceId);
  }

  // We don't want the default expanded items to change, so we need to keep the initial selection
  const [initialTreatmentService] = useState<TreatmentServiceId | null>(props.selectedTreatmentService);

  const tree = (query: TreatmentServiceTreeQuery): ReactElement => {
    const items = query.treatmentServices?.nodes || [];
    const tree = treeify(items);
    const ancestors = ancestry(items, initialTreatmentService);

    return (
      <TreeView
        aria-label={t("careEpisodes:fields.treatmentServiceSelector")}
        selected={props.selectedTreatmentService?.toString()}
        onNodeSelect={handleNodeSelect}
        multiSelect={false}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={ancestors}
        disabledItemsFocusable
      >
        {tree.map((o, i) => (
          <TreatmentServiceTreeItem {...o} key={i} />
        ))}
      </TreeView>
    );
  };

  return (
    <Card>
      <CardHeader
        title={t("careEpisodes:sections.treatmentService")}
        subheader={t("careEpisodes:sections.treatmentServiceSubtitle")}
      />
      <CardContent>
        {props.treatmentServiceData.caseOf({
          Success: tree,
          Loading: () => <CircularProgress />,
          NotAsked: () => <CircularProgress />,
          Failure: (e) => <ErrorMessage message={e.toString()} />,
        })}
      </CardContent>
    </Card>
  );
}

export default TreatmentServiceSectionHookWrapper;
