import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  InstituteCondition,
  Organization,
  Provider,
  TreatmentService,
  useAssociateTreatmentServiceQuery,
  useUpdateTreatmentServiceAssociationsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { fromNullableString } from "Lib/Id";
import { LinkButton } from "MDS/Link";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormOverlay, useRedirectOnSuccess } from "Shared/Form";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { InstituteConditionId, OrganizationId, ProviderId } from "Lib/Ids";

function AssociateTreatmentService(): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // First, let's wrangle the actual treatment service id we're attempting to update.
  const { id } = useParams();

  const treatmentServiceId = fromNullableString<"TreatmentService">(id).getOrElse(null);
  if (!treatmentServiceId) {
    return <>{t("treatmentServices:associate.errorLoading")}</>;
  }

  // Now we can actually query for our treatment service and see what we have.
  // This query also fetches all of our providers and organizations, both the global set
  // and the ones we presently have for this treatment service.
  const { remoteData } = apolloQueryHookWrapper(
    useAssociateTreatmentServiceQuery({
      variables: {
        treatmentServiceId: treatmentServiceId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => {
      return <CircularProgress />;
    },
    NotAsked: () => {
      return <CircularProgress />;
    },
    Failure: (_error) => {
      return <>{t("treatmentServices:associate.errorLoading")}</>;
    },
    Success: (result) => {
      if (result.treatmentService && result.organizations && result.providers && result.instituteConditions) {
        return (
          <AssociateTreatmentServiceElement
            treatmentService={result.treatmentService}
            organizations={result.organizations.nodes}
            providers={result.providers.nodes}
            instituteConditions={result.instituteConditions.nodes}
          />
        );
      }
      return <>{t("treatmentServices:associate.errorLoading")}</>;
    },
  });
}

type UpdateOrganizationAssociation = { organizationId: OrganizationId; associated: boolean };
type UpdateProviderAssociation = { providerId: ProviderId; associated: boolean };
type UpdateInstituteConditionAssociation = {
  instituteConditionId: InstituteConditionId;
  associated: boolean;
};

type AssociateTreatmentServiceElementProps = {
  treatmentService: Pick<TreatmentService, "__typename" | "id" | "name"> & {
    organizations: ReadonlyArray<Pick<Organization, "__typename" | "id">>;
    providers: ReadonlyArray<Pick<Provider, "__typename" | "id">>;
    instituteConditions: ReadonlyArray<Pick<InstituteCondition, "__typename" | "id">>;
  };
  organizations: ReadonlyArray<Pick<Organization, "__typename" | "id" | "name">>;
  providers: ReadonlyArray<Pick<Provider, "__typename" | "id" | "name">>;
  instituteConditions: ReadonlyArray<Pick<InstituteCondition, "__typename" | "id" | "name">>;
};
function AssociateTreatmentServiceElement(props: AssociateTreatmentServiceElementProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // Here we start with whatever the OG organizations were directly off the treatment service.
  // After that, we apply additions and removals via a function handler so we can cleanly
  // call update later.
  const [associatedOrganizations, setAssociatedOrganizations] = useState(
    props.treatmentService.organizations.map((organization) => {
      return organization.id;
    })
  );

  // This will be called whenever a given association updates.
  // We want to add an organization whenever the association is swapped to true,
  // and remove one when it is swapped to false.
  const updateOrganizationAssociationHandler = (association: UpdateOrganizationAssociation) => {
    if (association.associated) {
      setAssociatedOrganizations([...associatedOrganizations, association.organizationId]);
    } else {
      setAssociatedOrganizations(associatedOrganizations.filter((oid) => oid !== association.organizationId));
    }
  };

  const [associatedProviders, setAssociatedProviders] = useState(
    props.treatmentService.providers.map((provider) => {
      return provider.id;
    })
  );

  // Same deal with providers.
  const updateProviderAssociationHandler = (association: UpdateProviderAssociation) => {
    if (association.associated) {
      setAssociatedProviders([...associatedProviders, association.providerId]);
    } else {
      setAssociatedProviders(associatedProviders.filter((pid) => pid !== association.providerId));
    }
  };

  // Mutation to update orgs.
  const [associateTreatmentServiceOrganizations, { remoteData }] = apolloMutationHookWrapper((data) => {
    // We can't easily just return a blended data object to see if they both succeed or fail,
    // so we'll just return whether or not the organizations updated. We're only using this to
    // check success for navigation purposes anyways.
    return data.updateTreatmentServiceOrganizations;
  }, useUpdateTreatmentServiceAssociationsMutation());

  const [associatedInstituteConditions, setAssociatedInstituteConditions] = useState(
    props.treatmentService.instituteConditions.map((instituteCondition) => {
      return instituteCondition.id;
    })
  );

  const updateInstituteConditionAssociationHandler = (association: UpdateInstituteConditionAssociation) => {
    if (association.associated) {
      setAssociatedInstituteConditions([...associatedInstituteConditions, association.instituteConditionId]);
    } else {
      setAssociatedInstituteConditions(
        associatedInstituteConditions.filter((icid) => icid !== association.instituteConditionId)
      );
    }
  };

  // Associate
  const onAssociate = () => {
    associateTreatmentServiceOrganizations({
      variables: {
        inputOrganizations: {
          treatmentServiceId: props.treatmentService.id,
          organizationIds: associatedOrganizations,
        },
        inputProviders: {
          treatmentServiceId: props.treatmentService.id,
          providerIds: associatedProviders,
        },
        inputInstituteConditions: {
          treatmentServiceId: props.treatmentService.id,
          instituteConditionIds: associatedInstituteConditions,
        },
      },
    });
  };

  // Don't forget to bring the user back to the index page on success.
  const navigate = useNavigate();
  useRedirectOnSuccess(remoteData, "..", navigate);

  // The actual element and display.
  return (
    <Page browserTitle={t("treatmentServices:associate.title")}>
      <Card>
        <CardHeader title={t("treatmentServices:associate.title")}></CardHeader>
        <CardContent>
          <Form>
            <Stack spacing={2}>
              <Box width="30em">
                <Typography variant="h2" marginBottom="0.75em">
                  {t("treatmentServices:associate.treatmentServiceLabel")} {props.treatmentService.name}
                </Typography>
                {t("treatmentServices:associate.directions")}
              </Box>
              <Box width="30em">
                <Organizations
                  associatedOrganizations={associatedOrganizations}
                  organizations={props.organizations}
                  updateAssociationHandler={updateOrganizationAssociationHandler}
                />
              </Box>
              <Box width="30em">
                <Providers
                  associatedProviders={associatedProviders}
                  providers={props.providers}
                  updateAssociationHandler={updateProviderAssociationHandler}
                />
              </Box>
              <Box width="30em">
                <InstituteConditions
                  associatedInstituteConditions={associatedInstituteConditions}
                  instituteConditions={props.instituteConditions}
                  updateAssociationHandler={updateInstituteConditionAssociationHandler}
                />
              </Box>
              <Stack direction="row" spacing={1}>
                <Button variant="contained" onClick={onAssociate}>
                  {t("treatmentServices:associate.associateButton")}
                </Button>
                <LinkButton variant="contained" to="..">
                  {t("treatmentServices:associate.cancelButton")}
                </LinkButton>
              </Stack>
              <FormOverlay response={remoteData} errorMessage={t("treatmentServices:update.errorUpdating")} />
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Page>
  );
}

type OrganizationsProps = {
  associatedOrganizations: Array<OrganizationId>;
  organizations: ReadonlyArray<Pick<Organization, "__typename" | "id" | "name">>;
  updateAssociationHandler: (association: UpdateOrganizationAssociation) => void;
};
function Organizations(props: OrganizationsProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  // Unless we sort this, the order is going to be pretty confusing.
  // This whole thing is probably better drawn as a tree, but we'd need
  // to redo the actual tree view nodes to make that happen.
  const organizations = [...props.organizations]
    .sort((a, b) => {
      if (a.name == b.name) {
        return 0;
      } else if (a.name > b.name) {
        return 1;
      }
      return -1;
    })
    .map((organization, index) => {
      return (
        <OrganizationElement
          key={index}
          organization={organization}
          associatedOrganizations={props.associatedOrganizations}
          updateAssociationHandler={props.updateAssociationHandler}
        />
      );
    });
  return (
    <>
      <Typography variant="h2">{t("treatmentServices:associate.organizationsLabel")}</Typography>
      {organizations}
    </>
  );
}

type OrganizationProps = {
  associatedOrganizations: Array<OrganizationId>;
  organization: Pick<Organization, "__typename" | "id" | "name">;
  updateAssociationHandler: (association: UpdateOrganizationAssociation) => void;
};
function OrganizationElement(props: OrganizationProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  const associatedChosen = props.associatedOrganizations.includes(props.organization.id);

  // We're using two separate values here so we can track if this component is "dirty"
  // and needs to use a pending flag. UseState will only ever run once, so we can safely
  // assume that the originalChosen value is always the OG value as it will never be updated.
  const [originalChosen] = useState(associatedChosen);
  const [chosen, setChosen] = useState(associatedChosen);
  const onClick = () => {
    const futureChosen = !chosen;
    setChosen(futureChosen);

    // We want to let our parent know we've changed, so they can update their own
    // references when we post the form.
    props.updateAssociationHandler({
      organizationId: props.organization.id,
      associated: futureChosen,
    });
  };
  return (
    <Stack direction="row" spacing={0.5}>
      <IconButton onClick={onClick}>
        {chosen ? <CheckIcon fontSize="small" /> : <DoNotDisturbIcon fontSize="small" />}
      </IconButton>
      {originalChosen != chosen ? (
        <Typography variant="h3" paddingTop={"0.5em"} sx={{ fontStyle: "italic" }}>
          {t("treatmentServices:associate.pendingLabel")}
        </Typography>
      ) : null}
      <Typography variant="h3" paddingTop={"0.5em"}>
        {props.organization.name}
      </Typography>
    </Stack>
  );
}

type ProvidersProps = {
  associatedProviders: Array<ProviderId>;
  providers: ReadonlyArray<Pick<Provider, "__typename" | "id" | "name">>;
  updateAssociationHandler: (association: UpdateProviderAssociation) => void;
};
function Providers(props: ProvidersProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  const providers = [...props.providers]
    .sort((a, b) => {
      if (a.name == b.name) {
        return 0;
      } else if (a.name > b.name) {
        return 1;
      }
      return -1;
    })
    .map((provider, index) => {
      return (
        <ProviderElement
          key={index}
          provider={provider}
          associatedProviders={props.associatedProviders}
          updateAssociationHandler={props.updateAssociationHandler}
        />
      );
    });
  return (
    <>
      <Typography variant="h2">{t("treatmentServices:associate.providersLabel")}</Typography>
      {providers}
    </>
  );
}

type ProviderProps = {
  associatedProviders: Array<ProviderId>;
  provider: Pick<Provider, "__typename" | "id" | "name">;
  updateAssociationHandler: (association: UpdateProviderAssociation) => void;
};
function ProviderElement(props: ProviderProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  const associatedChosen = props.associatedProviders.includes(props.provider.id);

  // We're using two separate values here so we can track if this component is "dirty"
  // and needs to use a pending flag. UseState will only ever run once, so we can safely
  // assume that the originalChosen value is always the OG value as it will never be updated.
  const [originalChosen] = useState(associatedChosen);
  const [chosen, setChosen] = useState(associatedChosen);
  const onClick = () => {
    const futureChosen = !chosen;
    setChosen(futureChosen);

    // We want to let our parent know we've changed, so they can update their own
    // references when we post the form.
    props.updateAssociationHandler({
      providerId: props.provider.id,
      associated: futureChosen,
    });
  };
  return (
    <Stack direction="row" spacing={0.5}>
      <IconButton onClick={onClick}>
        {chosen ? <CheckIcon fontSize="small" /> : <DoNotDisturbIcon fontSize="small" />}
      </IconButton>
      {originalChosen != chosen ? (
        <Typography variant="h3" paddingTop={"0.5em"} sx={{ fontStyle: "italic" }}>
          {t("treatmentServices:associate.pendingLabel")}
        </Typography>
      ) : null}
      <Typography variant="h3" paddingTop={"0.5em"}>
        {props.provider.name}
      </Typography>
    </Stack>
  );
}

type InstituteConditionsProps = {
  associatedInstituteConditions: Array<InstituteConditionId>;
  instituteConditions: ReadonlyArray<Pick<InstituteCondition, "__typename" | "id" | "name">>;
  updateAssociationHandler: (association: UpdateInstituteConditionAssociation) => void;
};
function InstituteConditions(props: InstituteConditionsProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  const instituteConditions = [...props.instituteConditions]
    .sort((a, b) => {
      if (a.name == b.name) {
        return 0;
      } else if (a.name > b.name) {
        return 1;
      }
      return -1;
    })
    .map((instituteCondition, index) => {
      return (
        <InstituteConditionElement
          key={index}
          instituteCondition={instituteCondition}
          associatedInstituteConditions={props.associatedInstituteConditions}
          updateAssociationHandler={props.updateAssociationHandler}
        />
      );
    });
  return (
    <>
      <Typography variant="h2">{t("treatmentServices:associate.instituteConditionsLabel")}</Typography>
      {instituteConditions}
    </>
  );
}

type InstituteConditionProps = {
  associatedInstituteConditions: Array<InstituteConditionId>;
  instituteCondition: Pick<InstituteCondition, "__typename" | "id" | "name">;
  updateAssociationHandler: (association: UpdateInstituteConditionAssociation) => void;
};
function InstituteConditionElement(props: InstituteConditionProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  const associatedChosen = props.associatedInstituteConditions.includes(props.instituteCondition.id);

  // We're using two separate values here so we can track if this component is "dirty"
  // and needs to use a pending flag. UseState will only ever run once, so we can safely
  // assume that the originalChosen value is always the OG value as it will never be updated.
  const [originalChosen] = useState(associatedChosen);
  const [chosen, setChosen] = useState(associatedChosen);
  const onClick = () => {
    const futureChosen = !chosen;
    setChosen(futureChosen);

    // We want to let our parent know we've changed, so they can update their own
    // references when we post the form.
    props.updateAssociationHandler({
      instituteConditionId: props.instituteCondition.id,
      associated: futureChosen,
    });
  };
  return (
    <Stack direction="row" spacing={0.5}>
      <IconButton onClick={onClick}>
        {chosen ? <CheckIcon fontSize="small" /> : <DoNotDisturbIcon fontSize="small" />}
      </IconButton>
      {originalChosen != chosen ? (
        <Typography variant="h3" paddingTop={"0.5em"} sx={{ fontStyle: "italic" }}>
          {t("treatmentServices:associate.pendingLabel")}
        </Typography>
      ) : null}
      <Typography variant="h3" paddingTop={"0.5em"}>
        {props.instituteCondition.name}
      </Typography>
    </Stack>
  );
}

export default AssociateTreatmentService;
