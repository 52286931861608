import { useCurrentInstituteId } from "Contexts/CurrentInstituteIdContext";
import { InstituteId } from "Lib/Ids";
import CryptoJS from "crypto-js";

// Taken from https://attacomsian.com/blog/nodejs-encrypt-decrypt-data

export type ObfuscationSecrets = {
  instituteId: InstituteId;
};

/**
 * Encrypts a parameter of text given with the supplied secrets
 * @param text the text to encrypt
 * @param secrets the secrets to use for encryption
 * @returns the encrypted hash.
 */
const encrypt = (text: string, secrets: ObfuscationSecrets): string => {
  return CryptoJS.AES.encrypt(text, secrets.instituteId.toString()).toString();
};

/**
 * Decrypts the given parameter using the secrets provided
 * @param hash the encrypted parameter
 * @param secrets the secrets to use for decryption
 * @returns the decrypted result
 */
const decrypt = (hash: string, secrets: ObfuscationSecrets): string => {
  const bytes = CryptoJS.AES.decrypt(hash, secrets.instituteId.toString());
  return bytes.toString(CryptoJS.enc.Utf8);
};

/**
 * @returns a set of secrets that can be used to obfuscate query params
 */
function useObfuscationSecrets() {
  return {
    instituteId: useCurrentInstituteId(),
  };
}

export { encrypt, decrypt, useObfuscationSecrets };
