import React, { ReactElement } from "react";
import * as CareEpisodeComputedValueDetails from "Shared/Scale/CareEpisodeComputedValueDetails";
import * as NEL from "Lib/NonEmptyList";
import SeverityHeatmapWithChip from "Shared/Severity/SeverityHeatmapWithChip";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { CareEpisodeComputedValueId } from "Lib/Ids";

type Props = {
  height: number;
  maxWidth: number;
  scores: Array<CareEpisodeComputedValueDetails.CareEpisodeComputedValueDetails>;
  administrationDates: NEL.NonEmptyList<Date>;
  chipWidth?: number;
  onHeatmapClickRoute?: FeedbackReportRoute;
  onChipClickRoute?: (careEpisodeComputedValueId: CareEpisodeComputedValueId) => FeedbackReportRoute;
  heatmapTooltip?: (child: ReactElement) => ReactElement;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleScoresHeatmapWithChip(props: Props): ReactElement {
  const {
    height,
    maxWidth,
    scores,
    administrationDates,
    chipWidth,
    onHeatmapClickRoute,
    onChipClickRoute,
    heatmapTooltip,
    chipTooltip,
  } = props;

  return (
    <SeverityHeatmapWithChip
      height={height}
      maxWidth={maxWidth}
      dates={administrationDates}
      values={scores}
      chipWidth={chipWidth}
      onHeatmapClickRoute={onHeatmapClickRoute}
      onChipClickRoute={onChipClickRoute}
      heatmapTooltip={heatmapTooltip}
      chipTooltip={chipTooltip}
    />
  );
}

export default ScaleScoresHeatmapWithChip;
