import React, { ReactElement } from "react";
import { formatCompletion, ScaleCompletion } from "./ScaleCompletionHistory";
import { useTheme, Theme } from "@mui/material";
import { scaleFriendlyName } from "./Scale";

import { ScaleChipBase } from "./ScaleChipBase";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { Scale } from "GeneratedGraphQL/SchemaAndOperations";

// -- Style helpers -----------------------------------------------------------

type ChipStyles = {
  backgroundColor: string;
  color: string;
};

function staleStyles(theme: Theme): ChipStyles {
  return {
    backgroundColor: theme.palette.report.completion.secondary,
    color: theme.palette.report.completion.secondaryOffset,
  };
}

function freshStyles(theme: Theme): ChipStyles {
  return {
    backgroundColor: theme.palette.report.completion.primary,
    color: theme.palette.report.completion.offset,
  };
}

// -- Base --------------------------------------------------------------------

type ScaleCompletionChipBaseProps = {
  isStale: boolean;
  children: string;
  width?: number;
  onClickRoute?: FeedbackReportRoute;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleCompletionChipBase(props: ScaleCompletionChipBaseProps) {
  const { onClickRoute, isStale, children, width, chipTooltip } = props;

  const theme = useTheme();
  const styles = isStale ? staleStyles(theme) : freshStyles(theme);

  return (
    <ScaleChipBase
      onClickRoute={onClickRoute}
      backgroundColor={styles.backgroundColor}
      color={styles.color}
      width={width}
      chipTooltip={chipTooltip}
    >
      {children}
    </ScaleChipBase>
  );
}

// -- Implementations ---------------------------------------------------------

type ScaleCompletionChipProps = {
  completion: ScaleCompletion;
  isStale: boolean;
  onClickRoute?: FeedbackReportRoute;
  width?: number;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleCompletionChip(props: ScaleCompletionChipProps): ReactElement {
  const { completion, isStale, onClickRoute, width, chipTooltip } = props;

  return (
    <ScaleCompletionChipBase
      onClickRoute={onClickRoute}
      isStale={isStale}
      width={width}
      chipTooltip={chipTooltip}
    >
      {formatCompletion(completion)}
    </ScaleCompletionChipBase>
  );
}

type ScaleCompletionWithScaleNameChipProps = {
  scale: Pick<Scale, "name" | "shortname" | "nanoname" | "friendlyName">;
  isStale: boolean;
  onClickRoute?: FeedbackReportRoute;
  width?: number;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleCompletionWithScaleNameChip(props: ScaleCompletionWithScaleNameChipProps): ReactElement {
  const { scale, isStale, onClickRoute, width, chipTooltip } = props;

  return (
    <ScaleCompletionChipBase
      onClickRoute={onClickRoute}
      isStale={isStale}
      width={width}
      chipTooltip={chipTooltip}
    >
      {scaleFriendlyName(scale)}
    </ScaleCompletionChipBase>
  );
}

export { ScaleCompletionChip, ScaleCompletionWithScaleNameChip };
