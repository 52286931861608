const patients = {
  patientList: {
    pageTitle: "Patients",
    search: {
      searchClientsLabel: "Search",
    },
    columns: {
      mrn: "MRN",
      flags: "Flags",
      patientName: "Name",
      dob: "Date of Birth",
      gender: "Gender",
      actions: "Actions",
    },
  },
  patientDetails: {
    cards: {
      patientInfo: "Demographic Details",
      patientConditions: "Diagnoses & Problems",
      userConsents: "Consent Forms",
      coverages: "Insurance Coverage",
      careEpisodes: "Episodes of Care",
      appointments: "Appointments",
      upcomingAppointments: "Upcoming Appointments",
      recentAppointments: "Recent Appointments",
      activeInvitations: "Active Invitations",
    },
    actions: {
      general: "General",
      measurement: "Measurement",
      disableNotifications: "Disable Notifications",
      enableNotifications: "Enable Notifications",
      measurementProtocol: "Measurement Protocol",
      createEpisodeOfCare: "Start Episode of Care",
      feedbackReport: "Feedback Report",
      noReport: "No Report Available",
      createAppointment: "Create Appointment(s)",
      createAppointmentFor: "Create for {{name}}",
    },
    noActiveInvitations: "There are no currently open and active invitations for this patient",
  },
  patientConditions: {
    code: "Code",
    name: "Name",
    status: "Status",
    dates: "Dates",
    // I cannot get this to work with the interpolation style so cheating
    dateBoth: "{{ onsetDate; date(format:MM/dd/yyyy) }} - {{ abatementDate; date(format:MM/dd/yyyy) }}",
    dateOnset: "$t(common:date.tiny) -",
    dateAbatement: "- $t(common:date.tiny)",
    dateNone: "no dates",
    ehrOnly: "This data is received from the EMR and cannot be directly edited",
  },
  unknownValue: "unknown",
  user: {
    active: "Active",
    inactive: "Inactive",
    name: "Name",
    firstName: "First Name",
    lastName: "Last Name",
    email: "E-mail",
    phoneNumber: "Phone Number",
    mrn: "MRN",
    genderIdentity: "Gender Identity",
    notificationPreference: "Notification Preference",
  },
  details: {
    note: "Additional Patient Info",
    careManager: "Care Manager",
    primaryCareProvider: "Primary Care Provider",
    panel: "Panel",
  },
  relatedPerson: {
    relationship: "Relationship",
    createHeader: "Add a Related Person",
    updateHeader: "Update a Related Person",
    genericFormError: "Failed to save related person",
    add: "Add a Related Person",
    none: "There are no related people",
    edit: "Edit",
  },
  referenceHeader: {
    badgeCountAltText: "Count of active tasks for",
    testPatient: "Test",
  },
  actions: {
    editDetails: "Edit Demographics",
    editError: "Failed to edit patient demographics",
    create: "Add New Patient",
    createError: "Failed to create patient, see fields below for specific errors",
  },
  demographics: {
    legalFirstName: "First Name (Legal)",
    legalLastName: "Last Name (Legal)",
    preferredFirstName: "First Name (Preferred)",
    preferredLastName: "Last Name (Preferred)",
    preferredPronouns: "Preferred Pronouns",
    email: "Email",
    phoneNumber: "Phone Number",
    mrn: "ID Number",
    gender: "Sex",
    genderIdentity: "Gender Identity (free text)",
    genderIdentityFhir: "Gender Identity (select)",
    dob: "Date of Birth",
    dobFormat: "MM/dd/yyyy",
    notificationPreference: "Notification Preference",
    primaryLanguage: "Primary Language",
    race: "Race",
    ethnicity: "Ethnicity",
    isTest: "This is a test patient",
  },
  userConsent: {
    createdAt: "Date",
    user: "Respondent",
    consentType: "Consent Form",
    status: "Accepted?",
  },
  coverage: {
    payor: "Payer",
    ehrOnly: "This data is received from the EMR and cannot be directly edited",
    relationship: "Relationship",
    subscriberNumber: "Subscriber Number",
    dates: "Dates",
    // I cannot get this to work with the interpolation style so cheating
    dateBoth: "{{ startDate; date(format:MM/dd/yyyy) }} - {{ endDate; date(format:MM/dd/yyyy) }}",
    dateStart: "$t(common:date.tiny) -",
  },
  careEpisodes: {
    shortname: "Name",
    dates: "Dates",
    // I cannot get this to work with the interpolation style so cheating
    dateBoth: "{{ periodStart; date(format:MM/dd/yyyy) }} - {{ periodEnd; date(format:MM/dd/yyyy) }}",
    dateStart: "$t(common:date.tiny) -",
    status: "Status",
  },
  appointments: {
    title: "Mirah - Appointment",
    fields: {
      startDate: "Start Date",
      minutesDuration: "Duration (minutes)",
      serviceType: "Service Type",
      provider: "Provider",
      careEpisode: "Episode of Care",
      organization: "Organization",
      treatmentService: "Treatment Service",
      status: "Status",
    },

    cards: {
      invitations: "Invitations",
      appointmentDetails: "Appointment Details",
      patientSessionDetails: "Measurement Opportunity",
      careEpisodeMatching: "Episode of Care Matching",
      treatmentServiceMatching: "Treatment Service Matching",
      patientSessionCreation: "Measurement Opportunity Creation",
      measurementPlans: "Measuremnt Plan Selection",
      measurementFlow: "Measurement Flow",
    },
    actions: {
      appointment: "Appointment",
      measurement: "Measurement",
      keycodeLogin: "Generate login codes",
      cancel: "Cancel Appointment",
      alreadyCanceled: "Appointment Already Canceled",
      cantCancelEhrCreated: "Cannot cancel (imported from EHR)",
      delete: "Delete Appointment",
      cantDeleteEhrCreated: "Cannot delete (imported from EHR)",
    },
    noProvider: "No Provider",
    noDate: "No Date Specified",
    showingBefore: "Showing appointments before $t(common:date.medium)",
    showingAfter: "Showing appointments after $t(common:date.medium)",
    showMore: "Show more",
    notYetPlanned: "Not Planned Yet",
    notYetPlannedExplanation:
      "This appointment has not yet been planned using the automated planning system. More information will appear after the first time it is planned",
    notMeasured: "Not Measured",
    nothingToMeasure: "Nothing To Measure",
    why: "why?",
    notificationsBlocked: "Notifications Blocked",
    awaitingInterview: "Interview To Complete",
    nowLine: "NOW",
    noAppointments: "There are no appointments in this time range",
    columnHeaders: {
      episodeOfCare: "Episode of Care",
      appointment: "Appointment",
      measurement: "Measurement",
      actions: "Actions",
    },
    details: {
      appointmentDetails: {
        title: "Appointment Details",
        manuallyCreated: "Manually created on $t(common:date.medium)",
        ehrCreated: "Created from EHR Integration. Last updated $t(common:date.durationToNow) ago",
      },
    },
    recurrence: "Recurring {{recurrence}} until $t(common:date.tiny)",
    treatmentServiceSelection: {
      ehrSelected: "The EHR integration included the exact treatment service for this appointment",
      manuallySelected: "A system user manually assigned the treatment service for this appointment",
      algorithmSelected:
        "The Mirah system ran an algorithm to determine the best treatment service for this appointment",
      unknownSelected:
        "The system does not have a record for what mechanism was used to assign this treatment service to this appointment",
      algorithmNotRun:
        "We have no current record of this decision. Appointments that have not been updated for 90 days will not show this information",
      whyChosen: "Why was this particular treatment service chosen?",
    },
    careEpisodeSelection: {
      ehrSelected: "The EHR integration included the exact episode of care for this appointment",
      manuallySelected: "A system user manually assigned the episode of care for this appointment",
      algorithmSelected:
        "The Mirah system ran an algorithm to determine the episode of care for this appointment",
      unknownSelected:
        "The system does not have a record for what mechanism was used to assign this episodes of care to this appointment",

      algorithmNotRun:
        "We have no current record of this decision. Appointments that have not been updated for 90 days will not show this information",
      missingText:
        "An episode of care could not be matched or created for this appointment, because at least one of the following conditions could not be met:",
      missingProvider: "A provider was present on the appointment",
      noMatchingTreatmentService: "The appointment could be matched to a treatment service",
      automaticCreationDisabled: "The system is allowed to create new episodes of care automatically",
      missingOrganization: "An organization was present on the appointment",
      matchedExisting: "An existing episode of care was found to be a match",
      moreInfo: "More information is available on the ",
      appointmentDetailsPage: "appointment details page",
      whyChosen: "Why was this particular episode of care chosen?",
    },
    patientSessionCreation: {
      missingText:
        "A measurement could not be created for this appointment, because at least one of the following conditions could not be met:",
      algorithmNotRun:
        "We have no current record of this decision. Appointments that have not been updated for 90 days will not show this information",
      manualSelection:
        "A measurement opportunity was automatically created because this appointment was created manually by a user in the Mirah system",
      unknownSelection:
        "We do not have data on why this measurement opportunity was created. This may be because it was last updated more than 90 days ago.",
      algorithmicSelection:
        "A measurement opportunity was created because the appointment met all of the appropriate criteria",
      missing_care_episode: "Does the appointment have an active episode of care?",
      appointment_in_past: "Is the appointment in the future?",
      missing_provider: "Does the appointment have a provider?",
      disabled_provider: "Is the appointment's provider an enabled Mirah user?",
      appointment_canceled: "The appointment is not canceled?",
      appointment_too_close: "Is the appointment far enough away to all other measurements?",
      wrong_provider:
        "If only appointments with the primary provider are measured - is this the primary provider?",
      provider_measurement_disabled: "Does the appointment's provider have measurement enabled?",
      no_automatic_creation: "Is the episode of care configured to allow automatic measurement?",
      wrong_service_type: "Does the service type of the appointment support measurement?",
      no_autoplanning: "Is the system is configured to allow automatic planning?",
      moreInfo: "More information is available on the ",
      appointmentDetailsPage: "appointment details page",
    },
    planning: {
      noSession:
        "There is no measurement opportunity associated with this appointment so no plans were chosen.",
      manuallyPlanned:
        "The scales for this were assigned manually. The Mirah system did not do any planning.",
      plansSelected: "The following measurement plans were selected:",
      goToPlanningDetails: "See detailed measurement planning",
    },
    stepExplanation:
      "Click on a stage to see details of the decision the Mirah system made while processing this appointment",
    steps: {
      appointmentDetails: {
        title: "Appointment Details",
        error: "Error importing appointment",
        noDecision: "No automated decisions were made when processing this appointment",
      },
      careEpisodeSelection: {
        title: "Episode of Care Selection",
        error: "No episode of care found",
      },
      patientSessionExists: {
        title: "Measurement Opportunity Creation",
        error: "No measurement opportunity created",
      },
      planSelection: {
        title: "Measurement Plan Selection",
        error: "No plans selected",
      },
      scaleSelection: {
        title: "Scale Selection",
        error: "No scales to administer",
      },
    },
    reportScales: {
      noScalesYet:
        "The appointment is still in the future. The final scales will be selected when the first invitation is sent.",
      scalesSelected: "The following assessments were evaluated for administration:",
      manual: "Manual selection",
      noSession:
        "There is no measurement opportunity for this appointment, so no assessments will be administered.",
      columns: {
        scale: "Assessment",
        schedule: "Schedule",
        administer: "Administer?",
        details: "Details",
      },
    },
    notifications: {
      none: "No Scheduled Notifications",
      interview: "Is an interview",
      scheduled: "Scheduled: $t(common:date.tinyWithTime)",
      sent: "Scheduled: $t(common:date.tinyWithTime)",
      blocked: "Notifications blocked",
      resend: {
        loading: "Sending notifications...",
        success: "Notifications sent",
        noSent: "No notifications were sent as the invitation does not permit it",
        error: "Error sending notifications",
      },
      showAll: "show all",
      showSummary: "show summary",
      lastSent: "Last Sent $t(common:date.durationToNow) ago",
      nextSend: "Next Scheduled in $t(common:date.durationToNow)",
    },
    invitations: {
      notPlannedYet: "Not Yet Planned",
      notPlannedYetDetail:
        "The content for this invitation has not yet been planned because it is too far in the future",
      notMeasured:
        "There are no active invitations for this appointment because there is no measurement opportunity. See the 'Measurement Opportunity' section below on why Mirah chose not to measure this appointment",
      noneActive:
        "There are no active invitations for this appointment because there is nothing to administer.",
      columns: {
        respondent: "Respondent",
        content: "Content",
        status: "Status",
        notifications: "Notifications",
        actions: "Actions",
      },
      actions: {
        takeMeasurement: "Take Measurement",
        takeMeasurementBulk: "Bulk Edit Measurement Response",
        sendNotifications: "Send Notifications",
        resendNotifications: "Resend Notifications",
      },
      notFound: "There are no invitations",
      completedAt: "Completed $t(common:date.tinyWithTime)",
      startedAt: "Started $t(common:date.tinyWithTime)",
      partialStartedAt: "Partially Completed $t(common:date.tinyWithTime)",
      opensAt: "Opens at $t(common:date.tinyWithTime)",
      closesAt: "Open now. Closes at $t(common:date.tinyWithTime)",
      andMore: "+ {{count}} more",
      showFewer: "show fewer",
    },
    patientSessionDetails: {
      noSession:
        "No measurement opportunity was created for this appointment. See the 'Measurement Opportunity Creation' section for details",
      sessionNumber: "Measurement number",
      noSessionNumber: "Not yet assigned",
      sessionNumberExplanation:
        "The measurement number of an opportunity starts at 1 for each episode of care and increases by 1 for each set of measurements when at least one invitation was started",
    },
    cancelDialog: {
      title: "Cancel Appointment",
      confirm: "Cancel Appointment",
      details:
        "This will set the status of this appointment to canceled in the Mirah system. It will not cancel the appointment in the EMR or in any other system.",
      noSession:
        "There is no measurement opportunity associated with this appointment so canceling it will not change measurement",
      noObligation: "The provider will have no obligation to view feedback for this encounter",
      reportAvailable:
        "At least one respondent has entered data for this measurement opportunity. The provider will need to view it for their next encounter",
      noReport:
        "As no respondents have started, all invitations will be canceled and no feedback will be gathered",
      cancelOneOnly:
        "This appointment is part of a recurring series. This will only cancel this appointment. If you want to cancel the entire series, please use the 'Delete' option instead.",
      abort: "Keep Appointment",
    },
    deleteDialog: {
      title: "Delete Appointment",
      confirm: "Delete Appointment",
      confirmWithSeries: "Delete Appointment And Series",
      details:
        "This will delete the appointment in the Mirah system. It will not change the status of the appointment in the EMR or in any other system.",
      noSession:
        "There is no measurement opportunity associated with this appointment so deleting it will not change measurement",
      noObligation: "The provider will have no obligation to view feedback for this encounter",
      reportAvailable:
        "At least one respondent has entered data for this measurement opportunity. The provider will need to view it for their next encounter",
      noReport:
        "As no respondents have started, all invitations will be canceled and no feedback will be gathered",
      deleteSeriesPrompt:
        "This appointment is part of a recurring series. You can choose to delete just this appointment, or all the series in the future",
      abort: "Keep Appointment",
    },
    createDialog: {
      title: "Schedule a new appointment",
    },
    editDialog: {
      title: "Editing appointment",
      titleWithName: "Editing appointment for {{ name }}",
    },
    createForm: {
      actions: {
        create_one: "Create Appointment",
        create_other: "Create Appointments",
        edit_one: "Update Appointment",
        edit_other: "Update Appointments",
      },
      createForCareEpisode: "You are creating appointments for ",
      editForCareEpisode: "You are editing appointments for ",
      patientSessionExplanation:
        "Measurement opportunities are always created for manually created appointments.",
      planningExplanation:
        "The measurement plans and exact scales will be calculated using the planning system.",
      planManually: "Click here to manually select scales for planning",
      fields: {
        startDate: {
          label: "Date and Time",
          format: "MM/dd/yyyy hh:mm",
          missing: "Please enter a start time",
        },
        minutesDuration: {
          label: "Duration (minutes)",
          missing: "Please enter a number of minutes",
        },
        provider: {
          label: "Provider",
          missing: "Please select a provider",
        },
        recurrence: {
          label: "This is a recurring appointment",
        },
        recurrenceFrequency: {
          label: "Frequency",
        },
        recurrenceEndDate: {
          label: "End Date",
          format: "MM/dd/yyyy",
        },
      },
      sameRecurrence: "The recurrence of this appointment will not change",
      updateRecurrence: "All appointments for this recurring schedule will be altered to the schedule given.",
      removeRecurrence:
        "By removing the recurrence for this appointment, other appointments in the future will be deleted",
      addRecurrence:
        "You are adding a recurrence to this appointment. Other appointments in the future will be created",
      noCareEpisodeFound: "This appointment has no episode of care and cannot be edited here.",
      noCareEpisodeOldEdit: "Click here to edit the appointment in the old tool",
    },
  },
};

export default patients;
