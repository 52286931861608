const login = {
  titles: {
    login: "Mirah Login",
    forgotPassword: "Mirah Forgot Password",
  },
  fields: {
    username: "Username/Email",
    password: "Password",
  },
  errors: {
    invalidPassword: "Invalid username or password",
  },
  buttons: {
    login: "Log in",
    loginDiscreet: "Log in discreet mode",
    passwordReset: "Send password reset email",
  },
  links: {
    forgotPassword: "Forgot Password?",
    termsOfUse: "Terms of Use",
    privacyPolicy: "Privacy Policy",
    supportEmail: "support@mirah.com",
    login: "Back to login",
  },
  text: {
    forgotPasswordDirections:
      "If you have forgotten your password, you may request a password reset. An email will be sent to your account containing password reset instructions. Please enter your username below.",
    postForgotPassword: "If an account exists at the username given, an email has been sent.",
  },
};

export default login;
