import { ArrowDropDown, Email, Settings, Textsms } from "@mui/icons-material";
import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Link as MUILink,
  IconButton,
  Menu,
  Alert,
  Snackbar,
  Link,
} from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { invitationFormatT, invitationPhaseT, invitationStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  Invitation,
  InvitationFormat,
  InvitationNotification,
  InvitationNotificationStatus,
  InvitationStatus,
  ReportScale,
  User,
  UserType,
  useMbcResendInvitationNotificationsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { arrayMaxBy, arrayMinBy } from "Lib/Utils";
import { ScaleWithName, scaleMediumName } from "Shared/Scale/Scale";
import React, { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

type ReportScaleSummary = Pick<ReportScale, "id" | "disabled"> & { scale: ScaleWithName };
type InvitationDetails = Pick<
  Invitation,
  "id" | "status" | "phase" | "format" | "opensAt" | "closesAt" | "dateBegun" | "dateCompleted" | "referrer"
> & {
  user: Pick<User, "id" | "name" | "userType" | "allowsEmail" | "allowsText">;
  invitationNotifications: ReadonlyArray<
    Pick<
      InvitationNotification,
      "id" | "status" | "sendAt" | "dateEmailed" | "dateTexted" | "textAllowed" | "emailAllowed"
    >
  >;
  reportScales: ReadonlyArray<ReportScaleSummary>;
};

const STATES_ALLOWING_COMPLETION = [InvitationStatus.BEGUN, InvitationStatus.NEW, InvitationStatus.SENT];
const STATES_ALLOWING_NOTIFICATIONS = [InvitationStatus.FUTURE, InvitationStatus.NEW, InvitationStatus.SENT];

function IconsForNotifications(props: { email: boolean; text: boolean }) {
  return (
    <>
      {props.email ? <Email /> : null} {props.text ? <Textsms /> : null}
    </>
  );
}

function NotificationRow(props: { email: boolean; text: boolean; date: Date; sent: boolean }) {
  const { t } = useTranslation(["patients", "enums"]);
  const key = props.sent
    ? "patients:appointments.notifications.sent"
    : "patients:appointments.notifications.scheduled";
  return (
    <Typography fontSize={"0.8em"}>
      <IconsForNotifications text={props.text} email={props.email} /> {t(key, { date: props.date })}
    </Typography>
  );
}

/**
 * Construct a cell for an invitation that encapsulates its current status. Examples include:
 * * Completed 12/22/24 9:00am
 * * Opens 12/22/24 9:00am
 * * Open now. Closes 12/22/24 9:00am
 */
function InvitationStatusCell(props: {
  invitation: Pick<Invitation, "status" | "dateBegun" | "dateCompleted" | "opensAt" | "closesAt">;
}) {
  const { t } = useTranslation(["patients", "enums"]);
  switch (props.invitation.status) {
    case InvitationStatus.COMPLETE:
      if (props.invitation.dateCompleted) {
        return t("patients:appointments.invitations.completedAt", { date: props.invitation.dateCompleted });
      } else {
        return invitationStatusT(props.invitation.status, t);
      }
    case InvitationStatus.BEGUN:
      if (props.invitation.dateBegun) {
        return t("patients:appointments.invitations.startedAt", { date: props.invitation.dateBegun });
      } else {
        return invitationStatusT(props.invitation.status, t);
      }
    case InvitationStatus.PARTIAL:
      if (props.invitation.dateBegun) {
        return t("patients:appointments.invitations.partialStartedAt", { date: props.invitation.dateBegun });
      } else {
        return invitationStatusT(props.invitation.status, t);
      }
    case InvitationStatus.FUTURE:
      if (props.invitation.opensAt) {
        return t("patients:appointments.invitations.opensAt", { date: props.invitation.opensAt });
      } else {
        return invitationStatusT(props.invitation.status, t);
      }
    case InvitationStatus.NEW:
    case InvitationStatus.SENT:
      if (props.invitation.closesAt) {
        return t("patients:appointments.invitations.closesAt", { date: props.invitation.closesAt });
      } else {
        return invitationStatusT(props.invitation.status, t);
      }
    default:
      return invitationStatusT(props.invitation.status, t);
  }
}

function TakeAssessmentMenuLink(props: {
  invitation: Pick<Invitation, "id" | "status" | "referrer"> & { user: Pick<User, "id" | "userType"> };
}): ReactElement | null {
  const { t } = useTranslation(["patients"]);
  const referrer = props.invitation.referrer || "provider_session";
  if (
    props.invitation.user.userType === UserType.PATIENT &&
    STATES_ALLOWING_COMPLETION.includes(props.invitation.status)
  ) {
    const takeAssessmentLink = `/provider/exit-to-patient/${props.invitation.user.id}?referrer=${referrer}&destination=patient.assessment.invitation.begin&params=${props.invitation.id}`;
    return (
      <MenuItem>
        <MUILink underline="none" href={takeAssessmentLink}>
          {t("patients:appointments.invitations.actions.takeMeasurement")}
        </MUILink>
      </MenuItem>
    );
  }

  if (
    props.invitation.user.userType === UserType.RELATED_PERSON &&
    STATES_ALLOWING_COMPLETION.includes(props.invitation.status)
  ) {
    const takeAssessmentLink = `/provider/exit-to-patient/${props.invitation.user.id}?referrer=${referrer}&destination=related-person.assessment.invitation.begin&params=${props.invitation.id}`;
    return (
      <MenuItem>
        <MUILink underline="none" href={takeAssessmentLink}>
          {t("patients:appointments.invitations.actions.takeMeasurement")}
        </MUILink>
      </MenuItem>
    );
  }

  return null;
}

function TakeAssessmentBulkMenuLink(props: {
  invitation: Pick<Invitation, "id" | "referrer" | "status">;
}): ReactElement | null {
  const { t } = useTranslation(["patients"]);
  const referrer = props.invitation.referrer || "provider_session_bulk";
  if (STATES_ALLOWING_COMPLETION.includes(props.invitation.status)) {
    const takeAssessmentLink = `/provider/assessment/invitation/${props.invitation.id}/begin?referrer=${referrer}`;
    return (
      <MenuItem>
        <MUILink underline="none" href={takeAssessmentLink}>
          {t("patients:appointments.invitations.actions.takeMeasurementBulk")}
        </MUILink>
      </MenuItem>
    );
  }

  return null;
}

function ResendNotificationButton(props: {
  invitation: Pick<Invitation, "id" | "status">;
}): ReactElement | null {
  const { t } = useTranslation(["patients"]);
  const [open, setOpen] = React.useState(false);

  const [resendNotifications, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.assessmentResendInvitationNotifications,
    useMbcResendInvitationNotificationsMutation({
      variables: { input: { invitationId: props.invitation.id } },
      refetchQueries: ["AppointmentInvitationDetails"],
    })
  );

  const handleClick = () => {
    setOpen(true);
    resendNotifications();
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const snackbarContent = remoteData.caseOf({
    Success: (result) => {
      if (result?.newNotificationSent) {
        return (
          <Alert severity="success" sx={{ width: "100%" }}>
            {t("patients:appointments.notifications.resend.success")}
          </Alert>
        );
      } else {
        return (
          <Alert severity="warning" sx={{ width: "100%" }}>
            {t("patients:appointments.notifications.resend.noSent")}
          </Alert>
        );
      }
    },
    Loading: () => (
      <Alert severity="info" sx={{ width: "100%" }}>
        {t("patients:appointments.notifications.resend.loading")}
      </Alert>
    ),
    Failure: () => (
      <Alert severity="error" sx={{ width: "100%" }}>
        noSent
        {t("patients:appointments.notifications.resend.error")}
      </Alert>
    ),
    NotAsked: () => <Alert severity="info">{t("patients:appointments.notifications.resend.noSent")}</Alert>,
  });

  if (STATES_ALLOWING_NOTIFICATIONS.includes(props.invitation.status)) {
    const text =
      props.invitation.status === InvitationStatus.SENT
        ? "patients:appointments.invitations.actions.resendNotifications"
        : "patients:appointments.invitations.actions.sendNotifications";
    return (
      <MenuItem>
        <MUILink underline="none" onClick={handleClick}>
          {t(text)}
        </MUILink>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          {snackbarContent}
        </Snackbar>
      </MenuItem>
    );
  }
  return null;
}

/*
 * A dropdown with actions suitable for each invitation
 */
function ActionsButton(props: { invitation: InvitationDetails }): ReactElement {
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const open = anchor != null;
  const handleOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Settings />
        <ArrowDropDown fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchor}>
        <TakeAssessmentMenuLink invitation={props.invitation} />
        <TakeAssessmentBulkMenuLink invitation={props.invitation} />
        <ResendNotificationButton invitation={props.invitation} />
      </Menu>
    </>
  );
}

/**
 * This blubbering mess is designed to show information about notifications in a compact way.
 * There are two kinds of display:
 *  * If there are a lot of notifications, display a summary of last sent, and next to send
 *  * If there are not, or the expand option is pressed, show everything
 *
 * In addition, it should show a warning if the notifications are broken because they're blocked or we don't have contact details.
 */
function NotificationsCell(props: { invitation: InvitationDetails }): ReactElement | null {
  const { invitation } = props;
  const { t } = useTranslation(["patients"]);

  const unsentNotifications = useMemo(
    () =>
      invitation.invitationNotifications.filter(
        (notification) =>
          notification.status === InvitationNotificationStatus.SCHEDULED && notification.sendAt
      ),
    invitation.invitationNotifications
  );
  const sentNotifications = useMemo(
    () =>
      invitation.invitationNotifications.filter(
        (notification) =>
          notification.status === InvitationNotificationStatus.SENT &&
          !notification.dateEmailed &&
          !notification.dateTexted
      ),
    invitation.invitationNotifications
  );
  const blockedNotifications = useMemo(
    () =>
      invitation.invitationNotifications.filter(
        (notification) =>
          notification.status === InvitationNotificationStatus.FORBIDDEN ||
          notification.status === InvitationNotificationStatus.MISSING_DETAILS
      ),
    invitation.invitationNotifications
  );

  const visibleNotificationCount = sentNotifications.length + unsentNotifications.length;

  const [showAll, setShowAll] = useState<boolean>(visibleNotificationCount < 4);

  if (invitation.format === InvitationFormat.INTERVIEW) {
    return <Typography>{t("patients:appointments.notifications.interview")}</Typography>;
  }

  const blockedWarning =
    blockedNotifications.length > 0 ? (
      <Alert severity="warning">{t("patients:appointments.notifications.blocked")}</Alert>
    ) : null;

  if (showAll) {
    const sentNotificationRows = sentNotifications.map((notification) => {
      return (
        <NotificationRow
          key={notification.id.toString()}
          date={notification.dateEmailed || notification.dateTexted || new Date()}
          email={!!notification.dateEmailed}
          text={!!notification.dateTexted}
          sent={true}
        />
      );
    });
    const unsentNotificationRows = unsentNotifications.map((notification) => {
      return (
        <NotificationRow
          key={notification.id.toString()}
          date={notification.sendAt || new Date()}
          email={notification.emailAllowed}
          text={notification.textAllowed}
          sent={false}
        />
      );
    });

    const showSummaryLink =
      visibleNotificationCount > 3 ? (
        <Typography fontSize={"0.8em"}>
          <Link onClick={() => setShowAll(false)}>
            {t("patients:appointments.notifications.showSummary")}
          </Link>
        </Typography>
      ) : null;

    if (visibleNotificationCount) {
      return (
        <>
          {sentNotificationRows}
          {unsentNotificationRows}
          {showSummaryLink}
          {blockedWarning}
        </>
      );
    } else if (blockedNotifications.length > 0) {
      return blockedWarning;
    } else {
      return <Typography>{t("patients:appointments.notifications.none")}</Typography>;
    }
  } else {
    const mostRecentSent = arrayMaxBy(
      (notification) => notification.dateTexted?.getTime() || notification.dateEmailed?.getTime() || 0,
      sentNotifications
    );

    const nextToSend = arrayMinBy((notification) => notification.sendAt?.getDate() || 0, unsentNotifications);

    const mostRecentRow = mostRecentSent ? (
      <Typography fontSize={"0.8em"}>
        {mostRecentSent.dateEmailed ? <Email /> : null} {mostRecentSent.dateTexted ? <Textsms /> : null} {}{" "}
        {t("patients:appointments.notifications.lastSent", {
          date: mostRecentSent.dateTexted || mostRecentSent.dateEmailed,
        })}
      </Typography>
    ) : null;

    const nextToSendRow = nextToSend ? (
      <Typography fontSize={"0.8em"}>
        <IconsForNotifications email={nextToSend.emailAllowed} text={nextToSend.textAllowed} />
        {t("patients:appointments.notifications.nextSend", {
          date: nextToSend.sendAt,
        })}
      </Typography>
    ) : null;

    return (
      <>
        {mostRecentRow}
        {nextToSendRow}
        <Typography fontSize={"0.8em"}>
          <Link onClick={() => setShowAll(true)}>{t("patients:appointments.notifications.showAll")}</Link>
        </Typography>
        {blockedWarning}
      </>
    );
  }
}

function ContentCell(props: { scalesPlanned: boolean; reportScales: ReadonlyArray<ReportScaleSummary> }) {
  const { scalesPlanned, reportScales } = props;

  const { t } = useTranslation(["patients", "enums"]);
  const [showMore, setShowMore] = useState<boolean>(false);

  const scalesNames = (reportScales: ReadonlyArray<ReportScaleSummary>) =>
    reportScales
      .filter((rs) => !rs.disabled)
      .map((reportScale) => scaleMediumName(reportScale.scale))
      .join(", ");

  if (!scalesPlanned) {
    return (
      <Tooltip title={t("patients:appointments.invitations.notPlannedYetDetail")}>
        <Typography>{t("patients:appointments.invitations.notPlannedYet")}</Typography>
      </Tooltip>
    );
  }

  if (reportScales.length < 6) {
    return scalesNames(reportScales);
  }

  if (showMore) {
    return (
      <>
        {scalesNames(reportScales)}{" "}
        <Link onClick={() => setShowMore(false)}>{t("patients:appointments.invitations.showFewer")}</Link>
      </>
    );
  } else {
    return (
      <>
        {scalesNames(reportScales.slice(0, 4))}{" "}
        <Link onClick={() => setShowMore(true)}>
          {t("patients:appointments.invitations.andMore", { count: reportScales.length - 4 })}
        </Link>
      </>
    );
  }
}

function InvitationRow(props: { invitation: InvitationDetails; scalesPlanned: boolean }) {
  const { t } = useTranslation(["patients", "enums"]);
  const { invitation } = props;
  return (
    <TableRow>
      <TableCell>
        <Typography>{invitation.user.name}</Typography>
        <Typography fontStyle="italic">
          {invitationFormatT(invitation.format, t)} - {invitationPhaseT(invitation.phase, t)}
        </Typography>
      </TableCell>
      <TableCell>
        <ContentCell scalesPlanned={props.scalesPlanned} reportScales={props.invitation.reportScales} />
      </TableCell>
      <TableCell>
        <InvitationStatusCell invitation={props.invitation} />
      </TableCell>
      <TableCell>
        <NotificationsCell invitation={invitation} />
      </TableCell>
      <TableCell>
        <ActionsButton invitation={props.invitation} />
      </TableCell>
    </TableRow>
  );
}

export function InvitationsTable(props: {
  invitations: ReadonlyArray<InvitationDetails>;
  scalesPlanned: boolean;
}) {
  const { t } = useTranslation(["patients"]);

  let rows = props.invitations
    .filter((invitation) => invitation.status !== InvitationStatus.NOT_REQUIRED)
    .map((invitation) => (
      <InvitationRow
        key={invitation.id.toString()}
        invitation={invitation}
        scalesPlanned={props.scalesPlanned}
      />
    ));

  if (rows.length === 0) {
    rows = [
      <TableRow key="none">
        <TableCell colSpan={5}>{t("patients:appointments.invitations.noneActive")}</TableCell>
      </TableRow>,
    ];
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography fontWeight={"bold"}>
              {t("patients:appointments.invitations.columns.respondent")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={"bold"}>
              {t("patients:appointments.invitations.columns.content")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={"bold"}>
              {t("patients:appointments.invitations.columns.status")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={"bold"}>
              {t("patients:appointments.invitations.columns.notifications")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight={"bold"}>
              {t("patients:appointments.invitations.columns.actions")}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
}
