import { ComponentsProps, ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiMenu"] = {
  list: {
    padding: "0.5rem 0",
  },
  paper: {
    marginTop: "1rem",
  },
};

const defaultProps: ComponentsProps["MuiMenu"] = {
  elevation: 2,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

const override = {
  defaultProps,
  styleOverrides,
};

export default override;
