import React, { ReactElement } from "react";
import { Axis } from "@visx/xychart";
import { useTranslation } from "react-i18next";

// Anything more than this looks cramped and bad and doesn't really let you learn anything more anyway.
const MAX_TICKS = 5;

type DateAxisProps = {
  desiredTicks: number;
};

function DateAxis(props: DateAxisProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const actualTicks = Math.min(props.desiredTicks, MAX_TICKS);

  return (
    <Axis
      orientation="bottom"
      hideZero={false}
      numTicks={actualTicks}
      tickFormat={(value) => t("common:date.short", { date: value })}
    />
  );
}

export default DateAxis;
