import { useTheme, Tooltip } from "@mui/material";
import React, { ReactElement } from "react";
import { displayNameWithRelationship, ParticipantSummary, isPatient } from "Shared/Participant";
import { initials } from "Lib/Utils";
import { styled } from "@mui/material/styles";

const StyledAvatar = styled("span")(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  fontWeight: "bold",
  height: theme.spacing(1.5),
  width: theme.spacing(1.5),
  margin: 0,
  padding: 0,
  borderRadius: 12,
  border: theme.spacing(0.75),
  display: "inline-flex",
  color: theme.palette.common.white,
  alignItems: "center",
  justifyContent: "center",
}));

type Props = {
  participant: ParticipantSummary;
};

/**
 * An avatar representing a particular user in the clinical report
 */
function ParticipantAvatar(props: Props): ReactElement {
  const { participant } = props;
  const theme = useTheme();
  const secondaryColor = theme.palette.text.secondary;

  const style = isPatient(participant)
    ? { backgroundColor: secondaryColor }
    : {
        border: "1px solid",
        borderColor: secondaryColor,
        color: secondaryColor,
        backgroundColor: theme.palette.common.white,
      };

  return (
    <Tooltip title={displayNameWithRelationship(participant)}>
      <StyledAvatar style={style}>{initials(participant.name)}</StyledAvatar>
    </Tooltip>
  );
}

/** An avatar for when you know you have a patient, rather than an ambiguous participant */
export function PatientAvatar(props: { name: string }): ReactElement {
  const theme = useTheme();

  return (
    <Tooltip title={props.name}>
      <StyledAvatar style={{ backgroundColor: theme.palette.text.secondary }}>
        {initials(props.name)}
      </StyledAvatar>
    </Tooltip>
  );
}

/**
 * An avatar for when you have either a patient or nothing, and you want the component to take up the same amount of
 * space either way.
 */
export function MaybePatientAvatar(props: { name: string | undefined | null }): ReactElement {
  if (props.name === undefined || props.name === null) {
    return <StyledAvatar style={{ backgroundColor: "transparent", color: "transparent" }} />;
  } else {
    return <PatientAvatar name={props.name} />;
  }
}

export default ParticipantAvatar;
