import { Badge, Card, CardContent, CardHeader, Paper, Stack, Typography } from "@mui/material";
import {
  PatientListQuery,
  PatientListQueryVariables,
  PatientSortParameter,
  usePatientListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { shortGender } from "Lib/Gender";
import Link from "MDS/Link";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  useShowExportToolbarFromFlag,
} from "Shared/SortablePagableCollectionDataGrid";
import PatientListFilters, {
  PatientListFilters as PatientListFilterType,
  usePatientListFilters,
} from "./PatientListFilters";
import { PatientFlags } from "Providers/Dashboard/PatientFlags";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { DigUnpacked } from "type-utils";
import { MBC_PATIENT_LIST_TABLE_STORAGE_KEY } from "Shared/Storage";

function PatientListTable(props: { filters: PatientListFilterType }) {
  const { t } = useTranslation(["patients", "common"]);
  const showTestPatients = useTestPatientViewability();
  const showExportToolbar = useShowExportToolbarFromFlag();

  const queryVars: Pick<
    PatientListQueryVariables,
    "search" | "forProvider" | "flagIds" | "testPatient" | "activeOrganizations"
  > = {
    forProvider: props.filters.provider,
    activeOrganizations: props.filters.organization,
    search: props.filters.search || null,
    flagIds: props.filters.flagIds,
    testPatient: showTestPatients,
  };

  const columns: DataGridCols<PatientListQuery, ["patients"]> = React.useMemo(() => {
    return [
      {
        field: "mrn",
        headerName: t("patients:patientList.columns.mrn"),
        sortable: true,
        flex: 1,
        renderCell: (params) => params.row.mrn,
      },
      {
        field: "flags",
        headerName: t("patients:patientList.columns.flags"),
        sortable: false,
        flex: 1,
        disableExport: true,
        renderCell: (params) => {
          return <PatientFlags flags={params.row.patientFlags} excludeInTreatment={false} />;
        },
      },
      {
        field: "patientName",
        headerName: t("patients:patientList.columns.patientName"),
        sortable: true,
        flex: 2,
        renderCell: (params): ReactElement => {
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!params.row.isTest}
              color="success"
            >
              <Link to={`${params.row.id}`}>{params.row.nameLastFirst}</Link>
            </Badge>
          );
        },
      },
      {
        field: "dob",
        headerName: t("patients:patientList.columns.dob"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return params.row.dob ? t("common:date.tiny", { date: params.row.dob }) : "";
        },
      },
      {
        field: "gender",
        headerName: t("patients:patientList.columns.gender"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return params.row.gender ? shortGender(params.row.gender, t) : "";
        },
      },
      {
        field: "actions",
        headerName: t("patients:patientList.columns.actions"),
        sortable: false,
        flex: 1,
        disableExport: true,
        renderCell: (params) => {
          if (params.row.hasCompletedAssessment) {
            return (
              <ContextualReportLink patient={params.row.id}>
                <AssessmentIcon fontSize="large" />
              </ContextualReportLink>
            );
          }

          return "";
        },
      },
    ];
  }, []);

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        queryHook={usePatientListQuery}
        storageKey={MBC_PATIENT_LIST_TABLE_STORAGE_KEY}
        queryVariables={queryVars}
        unwrapData={(response) => response?.patients || null}
        showExportToolbar={showExportToolbar}
        colNameToSortParam={(field) => {
          switch (field) {
            case "mrn":
              return PatientSortParameter.MRN;
            case "dob":
              return PatientSortParameter.DOB;
            case "gender":
              return PatientSortParameter.GENDER;
            default:
              return PatientSortParameter.NAME_LAST_FIRST;
          }
        }}
        columns={columns}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
        mobileCard={MobileCard}
      />
    </Paper>
  );
}

function MobileCard(props: { row: DigUnpacked<PatientListQuery, ["patients", "nodes"]> }) {
  const { row } = props;
  const { t } = useTranslation(["patients", "common"]);

  return (
    <Card>
      <CardHeader title={row.nameLastFirst} />
      <CardContent>
        <Stack direction="row" spacing={1}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h3" color="text.secondary">
              {row.dob ? t("common:date.tiny", { date: row.dob }) : ""}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("patients:patientList.columns.mrn")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {row.mrn}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default function PatientList(): ReactElement {
  const filters = usePatientListFilters();
  const { t } = useTranslation(["patients"]);
  return (
    <Page browserTitle={t("patients:patientList.pageTitle")}>
      <PatientListFilters filters={filters} />
      <PatientListTable filters={filters} />
    </Page>
  );
}
