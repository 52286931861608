import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

import RouterWithNotFound from "../../../Shared/RouterWithNotFound";
import ConfigurationRoutes from "./Configuration/ConfigurationRoutes";
import UserRoutes from "./Users/UserRoutes";
import ExportRoutes from "./Exports/ExportRoutes";

function AdminRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<ConfigurationRoutes />} path="/configuration/*" />
      <Route element={<UserRoutes />} path="/users/*" />
      <Route element={<ExportRoutes />} path="/exports/*" />
    </RouterWithNotFound>
  );
}

export default AdminRoutes;
