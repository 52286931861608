import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { MutationRemoteDataResult, apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  QuickText,
  useCreateQuickTextMutation,
  useDeleteQuickTextMutation,
  useQuickTextListQuery,
  useUpdateQuickTextMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { RichText } from "MDS/RichText";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { Section } from "./Section";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Form, FormOverlay, useForm, useNumberField, useTextField } from "Shared/Form";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useTranslation } from "react-i18next";

export function QuickTextAdminPage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(useQuickTextListQuery());

  const content = remoteData.caseOf({
    NotAsked: () => <QuickTextListLoading />,
    Loading: () => <QuickTextListLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      const texts = response.collaborativeCareQuickTexts?.nodes || [];
      return <QuickTextList texts={texts} />;
    },
  });

  return (
    <Page browserTitle={t("collaborativeCare:caseConsult.quickText.title")}>
      <Section
        title={t("collaborativeCare:caseConsult.quickText.title")}
        actions={[<CreateQuickTextButton key={0} />]}
      >
        {content}
      </Section>
    </Page>
  );
}

function QuickTextListLoading(): ReactElement {
  const theme = useTheme();
  return (
    <Stack direction="column" spacing={0}>
      <Box padding={1} sx={{ backgroundColor: theme.palette.background.paper }}>
        <Stack direction="row" spacing={1}>
          <Skeleton width="25rem" />
          <Box flexGrow={1} />
          <Skeleton width="6rem" />
          <Skeleton width="6rem" />
        </Stack>
      </Box>
      <Box padding={1} sx={{ backgroundColor: theme.palette.background.default }}>
        <Stack direction="row" spacing={1}>
          <Skeleton width="25rem" />
          <Box flexGrow={1} />
          <Skeleton width="6rem" />
          <Skeleton width="6rem" />
        </Stack>
      </Box>
      <Box padding={1} sx={{ backgroundColor: theme.palette.background.paper }}>
        <Stack direction="row" spacing={1}>
          <Skeleton width="25rem" />
          <Box flexGrow={1} />
          <Skeleton width="6rem" />
          <Skeleton width="6rem" />
        </Stack>
      </Box>
    </Stack>
  );
}

type QuickTextDetails = PickTypename<QuickText, "id" | "title" | "text" | "order">;
type QuickTextListProps = {
  texts: ReadonlyArray<QuickTextDetails>;
};
type QuickTextItemProps = {
  text: QuickTextDetails;
};
type QuickTextFields = Pick<QuickText, "title" | "text" | "order">;

function QuickTextList(props: QuickTextListProps): ReactElement {
  const theme = useTheme();

  return (
    <Stack direction="column" spacing={0}>
      {props.texts.map((text, i) => {
        const background = i % 2 === 0 ? theme.palette.background.paper : theme.palette.background.default;
        return (
          <Box padding={1} sx={{ backgroundColor: background }} key={i}>
            <QuickTextRow text={text} />
          </Box>
        );
      })}
    </Stack>
  );
}

function QuickTextRow(props: QuickTextItemProps): ReactElement {
  return (
    <Stack direction="row" spacing={1}>
      <Stack direction="column" spacing={0.5} flexGrow={1}>
        <Typography variant="h3">{props.text.title}</Typography>
        <RichText>{props.text.text}</RichText>
      </Stack>
      <UpdateQuickTextButton text={props.text} />
      <DeleteQuickTextButton text={props.text} />
    </Stack>
  );
}

function CreateQuickTextButton(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [open, setOpen] = React.useState(false);

  const [createQuickText, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateQuickText,
    useCreateQuickTextMutation({
      refetchQueries: refetchQueries("quickTexts"),
    })
  );
  const onSubmit = (input: QuickTextFields) => {
    createQuickText({
      variables: { input: input },
    });
  };

  const dialog = open ? (
    <CreateOrUpdateQuickTextDialog
      onClose={() => setOpen(false)}
      remoteData={remoteData}
      onSubmit={onSubmit}
    />
  ) : null;

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("collaborativeCare:caseConsult.quickText.create")}
      </Button>
      {dialog}
    </>
  );
}

function DeleteQuickTextButton(props: QuickTextItemProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const [deleteQuickText, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteQuickText,
    useDeleteQuickTextMutation({
      refetchQueries: refetchQueries("quickTexts"),
      variables: {
        input: {
          quickTextId: props.text.id,
        },
      },
    })
  );

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="secondary"
      showSpinner={showSpinner}
      disabled={disabled}
      onClick={() => deleteQuickText()}
    >
      {t("common:actions.delete")}
    </ButtonWithSpinner>
  );
}

function UpdateQuickTextButton(props: QuickTextItemProps): ReactElement {
  const { t } = useTranslation(["common"]);

  const [open, setOpen] = React.useState(false);

  const [createQuickText, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateQuickText,
    useUpdateQuickTextMutation()
  );
  const onSubmit = (input: QuickTextFields) => {
    createQuickText({
      variables: { input: { ...input, quickTextId: props.text.id } },
    });
  };

  const dialog = open ? (
    <CreateOrUpdateQuickTextDialog
      onClose={() => setOpen(false)}
      remoteData={remoteData}
      onSubmit={onSubmit}
      defaults={props.text}
    />
  ) : null;

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("common:actions.edit")}
      </Button>
      {dialog}
    </>
  );
}

type CreateOrUpdateQuickTextDialogProps = {
  onClose: () => void;
  defaults?: QuickTextFields;
  remoteData: MutationRemoteDataResult<unknown>;
  onSubmit: (input: QuickTextFields) => void;
};

function CreateOrUpdateQuickTextDialog(props: CreateOrUpdateQuickTextDialogProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const fields = {
    title: useTextField({ required: true, default: props.defaults?.title || undefined }),
    text: useTextField({ required: false, default: props.defaults?.text || undefined }),
    // Until we have the ability to reorder the items here this will just set them all to the same order of 0, sticking
    // new items at the end.
    order: useNumberField({ required: true, default: props.defaults?.order || 0 }),
  };

  const form = useForm({
    id: "create-quick-text-form",
    fields: fields,
    remoteData: props.remoteData,
    submit: () => {
      props.onSubmit({
        // Cast is safe because we passed required field validation
        title: fields.title.value as string,
        text: fields.text.value || null,
        order: fields.order.value as number,
      });
    },
    onSuccess: () => {
      setTimeout(props.onClose, 500);
    },
  });

  return (
    <ResponsiveDialog open onClose={props.onClose}>
      <DialogContent>
        <Form id={form.id} onSubmit={form.onSubmit}>
          <FormOverlay
            response={props.remoteData}
            errorMessage={t("collaborativeCare:caseConsult.quickText.failedToSave")}
          />
          <Stack direction="column" spacing={1}>
            <TextField
              value={fields.title.value}
              onChange={fields.title.onChange}
              error={fields.title.error}
              helperText={fields.title.helperText}
              label={t("collaborativeCare:caseConsult.quickText.fields.title")}
            />
            <TextField
              value={fields.text.value}
              onChange={fields.text.onChange}
              error={fields.text.error}
              helperText={fields.text.helperText}
              title={t("collaborativeCare:caseConsult.quickText.fields.text")}
              multiline
              minRows={5}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button type="submit" form={form.id} variant="contained" color="secondary">
          {t("common:actions.save")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}
