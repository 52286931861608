import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EnrollmentStatus,
  IntakeStatus,
  usePatientActiveCollaborativeCareEpisodeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, EnrollmentId, PatientId } from "Lib/Ids";

type EnrollmentState =
  | {
      status: "unknown";
    }
  | {
      status: "enrolled";
      careEpisodeId: CareEpisodeId;
      enrollmentId: EnrollmentId;
      intakeStatus: IntakeStatus;
      enrolledAt: Date;
      consentDate: Date;
    }
  | { status: "not-enrolled" };

export function useEnrollmentState(patientId: PatientId) {
  const { remoteData } = apolloQueryHookWrapper(
    usePatientActiveCollaborativeCareEpisodeQuery({ variables: { patientId: patientId } })
  );
  return remoteData.caseOf<EnrollmentState>({
    NotAsked: () => ({ status: "unknown" }),
    Loading: () => ({ status: "unknown" }),
    Failure: () => ({ status: "unknown" }),
    Success: (response) => {
      if (response.patient?.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment) {
        if (
          response.patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.status ===
          EnrollmentStatus.ENROLLED
        ) {
          return {
            status: "enrolled",
            careEpisodeId: response.patient.activeCollaborativeCareCareEpisode.id,
            enrollmentId: response.patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.id,
            enrolledAt:
              response.patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.enrolledAt,
            intakeStatus:
              response.patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.intakeStatus,
            consentDate:
              response.patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.consentDate,
          };
        } else {
          return { status: "not-enrolled" };
        }
      } else {
        return { status: "not-enrolled" };
      }
    },
  });
}
