import React, { ReactElement } from "react";

import { Badge, IconButton, Paper } from "@mui/material";
import Paginator, { QueryPage } from "Shared/Paginator";
import { patientUrl } from "CollaborativeCare/Utils/patient";
import { PatientName, TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { ApolloError } from "@apollo/client";
import {
  PageInfo,
  SortDirection,
  TaskSearchQuery,
  TaskSortParameter,
} from "GeneratedGraphQL/SchemaAndOperations";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import { TimerBeginButton } from "CollaborativeCare/TimeEntry/TimerBeginButton";
import Link from "MDS/Link";
import { RemoteData } from "seidr";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { PatientId, TaskId } from "Lib/Ids";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { TaskCardBack } from "../TaskCard/TaskCardBack";
import { useChangeCurrentTask, useCurrentTask } from "Contexts/CurrentTaskContext";
import { SendTaskToTopButton } from "../SendToTopButton";

type DesktopTaskListProps = {
  remoteData: RemoteData<ApolloError, TaskSearchQuery>;
  pagination: QueryPage;
  setPagination: (p: QueryPage) => void;
  inPatientContext: boolean;
  sorting: SortOptions & {
    onChange: (newSorting: SortOptions) => void;
  };
};

function DesktopTaskList(props: DesktopTaskListProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return props.remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      if (!response.collaborativeCareTasks?.nodes) {
        return <ErrorMessage message={t("collaborativeCare:tasks.genericQueryError")} />;
      }

      return (
        <DesktopTaskListElement
          inPatientContext={props.inPatientContext}
          pagination={props.pagination}
          setPagination={props.setPagination}
          pageInfo={response.collaborativeCareTasks.pageInfo}
          taskDetails={response.collaborativeCareTasks.nodes}
          sorting={props.sorting}
        />
      );
    },
  });
}

function sortParameterToField(direction: TaskSortParameter | null): string {
  switch (direction) {
    case TaskSortParameter.DUE_AT:
      return "dueBy";
    case TaskSortParameter.TITLE:
      return "title";
    case TaskSortParameter.STATUS:
      return "status";
    case TaskSortParameter.PATIENT_NAME_LAST_FIRST:
      return "patientName";
    case TaskSortParameter.ASSIGNED_TO_NAME_LAST_FIRST:
      return "assignedTo";
    case TaskSortParameter.ID:
    default:
      return "patientName";
  }
}

function sortFieldToParameter(parameter: string | null): TaskSortParameter | null {
  switch (parameter) {
    case "dueBy":
      return TaskSortParameter.DUE_AT;
    case "title":
      return TaskSortParameter.TITLE;
    case "status":
      return TaskSortParameter.STATUS;
    case "patientName":
      return TaskSortParameter.PATIENT_NAME_LAST_FIRST;
    case "assignedTo":
      return TaskSortParameter.ASSIGNED_TO_NAME_LAST_FIRST;
    default:
      return null;
  }
}

type SortOptions = {
  sortBy: TaskSortParameter | null;
  sortDirection: SortDirection | null;
};

type DesktopTaskListElementProps = {
  inPatientContext: boolean;
  pagination: QueryPage;
  setPagination: (p: QueryPage) => void;
  pageInfo: PageInfo;
  taskDetails: ReadonlyArray<TaskDetails>;
  sorting: SortOptions & {
    onChange: (newSorting: SortOptions) => void;
  };
};
function DesktopTaskListElement(props: DesktopTaskListElementProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare", "enums", "patients"]);
  const [openDetails, setOpenDetails] = React.useState(false);
  const task = useCurrentTask();

  let sharedCardBack = <></>;
  if (task) {
    sharedCardBack = (
      <TaskCardBack
        task={task}
        onClose={() => {
          setOpenDetails(false);
        }}
        openDetails={setOpenDetails}
        open={openDetails}
      />
    );
  }

  type RowDataType = {
    task: TaskDetails;
    taskId: TaskId;
    patientId: PatientId | undefined;
    patient: PatientName | null;
    title: string;
    status: string;
    patientName: string | undefined;
    assignedTo: string; // Strictly speaking this is the care manager name.
    dueBy: Date | null;
    isTestPatient: boolean;
  };

  const columns: Array<GridColDef<RowDataType>> = [
    {
      field: "expand",
      headerName: "",
      width: 50,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <TaskCell task={params.row["task"]} openDetails={setOpenDetails} />;
      },
    },
    {
      field: "beginTask",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <TimerBeginButton
            task={params.row["task"]}
            patientId={params.row["patientId"]}
            buttonLabel={t("collaborativeCare:tasks.actions.begin")}
            buttonCompleteLabel={t("collaborativeCare:tasks.actions.alreadyComplete")}
            variant="contained"
            color="secondary"
          />
        );
      },
    },
    {
      field: "sendToTop",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return <SendTaskToTopButton task={params.row.task} variant="contained" color="secondary" />;
      },
    },
    {
      field: "title",
      headerName: t("collaborativeCare:fields.title.label"),
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: t("collaborativeCare:fields.status.label"),
      flex: 1,
      sortable: true,
    },
    {
      field: "patientName",
      headerName: t("collaborativeCare:fields.patient.label"),
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row["task"]) {
          return t("collaborativeCare:tasks.noPatient");
        }
        return (
          <Badge
            badgeContent={t("patients:referenceHeader.testPatient")}
            invisible={!params.row["isTestPatient"]}
            color="success"
          >
            <Link to={patientUrl(params.row["patient"])}>{params.row["patientName"]}</Link>
          </Badge>
        );
      },
    },
    {
      field: "assignedTo",
      headerName: t("collaborativeCare:fields.assignedTo.labelPastTense"),
      flex: 1,
      sortable: true,
    },
    {
      field: "dueBy",
      headerName: t("collaborativeCare:fields.dueAt.label"),
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const dueAt = params.row["dueBy"];
        if (dueAt === null) {
          return "";
        } else {
          return t("collaborativeCare:tasks.dueAtSubhead", { date: dueAt });
        }
      },
    },
  ];

  // We want to filter out the patient name column when we're displaying
  // the task list on the patient detail page. There's no hidden concept so we'll
  // just filter it out of the set.
  const filteredColumns = columns.filter((column) => {
    if (column.field === "patientName" && props.inPatientContext) {
      return false;
    }
    return true;
  });

  const rows: ReadonlyArray<RowDataType> = props.taskDetails.map((taskDetails) => {
    return {
      task: taskDetails,
      id: taskDetails.id, // Datagrid seems to require an id.
      taskId: taskDetails.id,
      patientId: taskDetails.patient?.id,
      patient: taskDetails.patient,
      title: taskDetails.title,
      status: taskStatusT(taskDetails.status, t),
      patientName: taskDetails.patient?.name,
      assignedTo: taskDetails.assignedTo.name,
      dueBy: taskDetails.dueAt,
      isTestPatient: taskDetails.patient?.isTest || false,
    };
  });

  // The focus stuff turns off this weird outlining that the datagrid does by default.
  // It just looks bad.
  return (
    <Paper>
      <DataGrid
        rows={rows}
        columns={filteredColumns}
        autoHeight
        disableRowSelectionOnClick={true}
        hideFooter={true}
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
        }}
        onSortModelChange={(models) => {
          const sortModel = models[0];
          if (!sortModel) {
            props.sorting.onChange({ sortBy: null, sortDirection: null });
            return;
          }

          // This only has to cover the column names where sortable: true
          const sortBy = sortFieldToParameter(sortModel.field);
          const sortDirection = sortModel.sort === "asc" ? SortDirection.ASC : SortDirection.DESC;
          props.sorting.onChange({ sortBy: sortBy, sortDirection: sortDirection });
        }}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: sortParameterToField(props.sorting.sortBy),
                sort: props.sorting.sortDirection === SortDirection.DESC ? "desc" : "asc",
              },
            ],
          },
        }}
      />
      <Paginator pagination={props.pagination} pageInfo={props.pageInfo} onChange={props.setPagination} />

      {sharedCardBack}
    </Paper>
  );
}
type TaskCellProps = {
  task: TaskDetails;
  openDetails: (val: boolean) => void;
};
export function TaskCell(props: TaskCellProps): ReactElement {
  const changeTask = useChangeCurrentTask();

  const handleOnClick = () => {
    props.openDetails(true);
    changeTask(props.task);
  };

  return (
    <>
      <IconButton onClick={handleOnClick}>
        <ZoomInIcon />
      </IconButton>
    </>
  );
}

export default DesktopTaskList;
