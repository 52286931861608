import { apolloQueryHookWrapper } from "Api/GraphQL";
import { TaskCardBack } from "CollaborativeCare/Tasks/TaskCard/TaskCardBack";
import { Task, useCollaborativeCareTaskDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type TaskSummary = PickTypename<Task, "id" | "title">;

type TaskDialogV2Props = {
  open: boolean;
  onClose: () => void;
  task: TaskSummary;
  openDetails: (val: boolean) => void;
};

// This can potentially be drawn directly into a list, so what we need to do
// is make sure we're drawing something that doesn't move the page around at all.
// null is thus perfect rather than a proper skeleton.
function TaskDialogV2Skeleton(): ReactElement | null {
  return null;
}

// We want to load a TaskCardBack here, pretty much, but we don't want to pass the actual full set of
// of data down here to do it, so we'll need to reload the whole thing.
export function TaskDialogV2(props: TaskDialogV2Props): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(
    useCollaborativeCareTaskDetailsQuery({ variables: { id: props.task.id } })
  );
  return remoteData.caseOf({
    NotAsked: () => <TaskDialogV2Skeleton />,
    Loading: () => <TaskDialogV2Skeleton />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.collaborativeCareTask) {
        return (
          <TaskCardBack
            task={response.collaborativeCareTask}
            onClose={props.onClose}
            open={props.open}
            openDetails={props.openDetails}
          />
        );
      } else {
        return <ErrorMessage message={t("collaborativeCare:tasks.genericQueryError")} />;
      }
    },
  });
}
