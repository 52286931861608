import { BarChart, Numbers, TrendingUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  EntityTreeNodeParams,
  MetricScaleScorerConfigurationPayload,
  ScaleScorerDomain,
  ScaleScorersConfigurationQuery,
  useScaleScorerSubtreeLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ScaleScorerId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import { SeverityCategoryChip } from "./SeverityHelpers";
import React, { useState } from "react";
import ScaleScorerWithUsageSelect from "Shared/Filters/ScaleScorerWithUsageSelect";
import { ApolloError } from "@apollo/client";
import { RemoteData } from "seidr";
import { scaleMediumName } from "Shared/Scale/Scale";
import { scaleScorerDetailsToSupportedOptions } from "Outcomes/OutcomesFormHelpers";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { remoteDataToMaybe } from "Lib/Utils";

const DOMAINS = [ScaleScorerDomain.RAW, ScaleScorerDomain.INTERPRETED];

function ScaleRow(props: {
  scorer: { id: ScaleScorerId; scale: { name: string; shortname: string | null } };
  onRemove: () => void;
}) {
  const { t } = useTranslation(["outcomes"]);
  return (
    <TableRow>
      <TableCell width={"100%"}>
        <Typography>{scaleMediumName(props.scorer.scale)}</Typography>
      </TableCell>
      <TableCell align="right">
        <Button variant="contained" onClick={props.onRemove}>
          {t("outcomes:create.multiScale.remove")}
        </Button>
      </TableCell>
    </TableRow>
  );
}

function SupportedDetails(props: {
  scaleData: NonNullable<ScaleScorersConfigurationQuery["assessmentScaleScorers"]>;
}) {
  const { t } = useTranslation(["outcomes"]);
  const options = scaleScorerDetailsToSupportedOptions(props.scaleData.nodes);

  if (!options) {
    return <Typography>{t("outcomes:multiScaleScorerDetails.selectAScale")}</Typography>;
  }

  const noNumerical = (
    <ListItem>
      <ListItemIcon>
        <Numbers />
      </ListItemIcon>
      <ListItemText>{t("outcomes:multiScaleScorerDetails.noNumerical")}</ListItemText>
    </ListItem>
  );

  const trendList =
    options.supportedTrends.length > 0 ? (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:multiScaleScorerDetails.commonTrends")}</ListItemText>
      </ListItem>
    ) : (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:multiScaleScorerDetails.noTrends")}</ListItemText>
      </ListItem>
    );

  const severityList =
    options.allSeverities.length > 0 ? (
      <>
        <ListItem>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText>
            {t("outcomes:multiScaleScorerDetails.allSeverities")}
            <Stack direction={"row"} spacing={1}>
              {options.allSeverities.map((s) => (
                <SeverityCategoryChip key={s} severityCategory={s} />
              ))}
            </Stack>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <BarChart />
          </ListItemIcon>
          <ListItemText>
            {t("outcomes:multiScaleScorerDetails.commonSeverities")}
            <Stack direction={"row"} spacing={1}>
              {options.commonSeverities.map((s) => (
                <SeverityCategoryChip key={s} severityCategory={s} />
              ))}
            </Stack>
          </ListItemText>
        </ListItem>
      </>
    ) : (
      <ListItem>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText>{t("outcomes:multiScaleScorerDetails.noSeverities")}</ListItemText>
      </ListItem>
    );

  return (
    <List>
      {noNumerical}
      {severityList}
      {trendList}
    </List>
  );
}

export default function MultiScaleScorerDetails(props: {
  onChange: (value: MetricScaleScorerConfigurationPayload | null) => void;
  scaleScorerConfigData: RemoteData<ApolloError, ScaleScorersConfigurationQuery>;
  scaleScorerConfigParams: MetricScaleScorerConfigurationPayload | null;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
}) {
  const { t } = useTranslation(["outcomes", "common"]);
  const [nextScaleScorerId, setNextScaleScorerId] = useState<ScaleScorerId | null>(null);

  const [subtreeQuery, subtreeQueryData] = useScaleScorerSubtreeLazyQuery({});
  const { remoteData: subtreeRemoteData } = apolloQueryHookWrapper(subtreeQueryData);

  const removeScorer = (scorerId: ScaleScorerId) => {
    if (props.scaleScorerConfigParams?.multiScale?.includes(scorerId)) {
      const newValues = [...props.scaleScorerConfigParams.multiScale];
      newValues.splice(props.scaleScorerConfigParams.multiScale.indexOf(scorerId), 1);
      props.onChange({ multiScale: newValues });
    }
  };

  const details = props.scaleScorerConfigData.caseOf({
    Success: (scorers) => {
      if (scorers.assessmentScaleScorers) {
        const scaleRows = scorers.assessmentScaleScorers.nodes.map((scorer) => {
          return (
            <ScaleRow scorer={scorer} key={scorer.id.toString()} onRemove={() => removeScorer(scorer.id)} />
          );
        });

        return (
          <>
            <TableContainer component={Paper}>
              <TableBody>{scaleRows}</TableBody>
            </TableContainer>
            <SupportedDetails scaleData={scorers.assessmentScaleScorers} />
          </>
        );
      }
      return <Typography>{t("outcomes:create.multiScale.noScalesSelected")}</Typography>;
    },
    Loading: () => <Spinner />,
    NotAsked: () => (
      <Typography textAlign={"center"}>{t("outcomes:create.multiScale.selectForDetails")}</Typography>
    ),
    Failure: (error) => <ErrorMessage message={error.message} />,
  });

  const addScale = () => {
    if (nextScaleScorerId) {
      const existingIds = props.scaleScorerConfigParams?.multiScale || [];
      props.onChange({ multiScale: [...existingIds, nextScaleScorerId] });
      setNextScaleScorerId(null);
    }
  };

  const setNextScaleAndCheckSubscales = (newValue: ScaleScorerId | null) => {
    if (newValue) {
      subtreeQuery({
        variables: { domain: DOMAINS, parentId: newValue },
      });
    }

    setNextScaleScorerId(newValue);
  };

  const subscaleIds = remoteDataToMaybe(subtreeRemoteData)
    .map((result) => {
      return result.assessmentScaleScorers?.nodes
        ? result.assessmentScaleScorers.nodes.map((node) => node.id)
        : [];
    })
    .getOrElse([]);

  const addScaleAndSubtree = () => {
    if (nextScaleScorerId) {
      const existingIds = props.scaleScorerConfigParams?.multiScale || [];
      props.onChange({ multiScale: [...existingIds, ...subscaleIds] });
    }
  };

  const hasSubscales = subscaleIds.length > 1;

  return (
    <Card>
      <CardHeader title={t("outcomes:create.multiScale.title")} />
      <CardContent>
        <Stack direction="column" spacing={2.5}>
          <Stack direction="row" alignItems={"center"} spacing={0.5}>
            <ScaleScorerWithUsageSelect
              domains={DOMAINS}
              setValue={setNextScaleAndCheckSubscales}
              value={nextScaleScorerId}
              entityTreeNode={props.entityTreeNode}
              startDate={props.startDate}
              endDate={props.endDate}
            />
            <Box flexGrow={1} />
            <Button variant="contained" disabled={!nextScaleScorerId} onClick={addScale}>
              {t("common:add")}
            </Button>
            <Button
              variant="contained"
              disabled={!nextScaleScorerId || !hasSubscales}
              onClick={addScaleAndSubtree}
            >
              {t("outcomes:addScaleAndSubtree")}
            </Button>
          </Stack>
          {details}
        </Stack>
      </CardContent>
    </Card>
  );
}
