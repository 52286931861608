import {
  Patient,
  PatientFilter,
  PatientSelectQueryVariables,
  usePatientSelectLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  OverridableAutocompleteProps,
  OverridableInputProps,
  QueryAutocompleteSingle,
} from "Shared/QueryAutocomplete";
import { ParameterSerializer } from "Shared/QueryStringParameter";
import * as Id from "Lib/Id";
import { oks } from "Lib/Utils";
import { PatientId } from "Lib/Ids";
import { PickTypename } from "type-utils";
import { TFunction } from "i18next";

type PatientSelectProps = {
  value: PatientFilter | null;
  setValue: (newValue: PatientFilter | null) => void;
};

const defaultValue: PatientFilter = {
  myPatients: true,
};

// Serializes and deserializes the patient filter from a string, to be used in query string.
export function patientFilterSerializer(
  defaultValue: PatientFilter | null
): ParameterSerializer<PatientFilter | null> {
  return {
    serialize: (x: PatientFilter | null) => {
      if (x?.allPatients) {
        return "all";
      }

      if (x?.myPatients) {
        return "mine";
      }

      if (x?.patientIds) {
        return x.patientIds.join(",");
      }

      if (x === defaultValue) {
        return null;
      }

      return "";
    },
    deserialize: (str: string | null) => {
      // explicitly serialize empty string as null
      if (str === "") {
        return null;
      }

      if (str === "all") {
        return {
          allPatients: true,
        };
      }

      if (str === "mine") {
        return {
          myPatients: true,
        };
      }

      if (!str) {
        return defaultValue;
      }

      const uuids = oks(
        str.split(",").map((idString) => {
          return Id.fromNullableString<"Patient">(idString);
        })
      );

      if (uuids.length > 0) {
        return {
          patientIds: uuids,
        };
      }

      return defaultValue;
    },
  };
}

// We need to translate the union type into the graphql input type
function inputToSelect(value: SelectOptionType | null): PatientFilter | null {
  if (!value) {
    return null;
  }

  if (value.id === "all") {
    return {
      allPatients: true,
    };
  }

  if (value.id === "mine") {
    return {
      myPatients: true,
    };
  }

  return {
    patientIds: [value.id],
  };
}

// Note that as long as we have the ids right here, the ignored text will be substituted in the component.
function selectToValue(value: PatientFilter | null, t: TFunction<["common"]>): SelectOptionType | null {
  if (!value) {
    return null;
  }

  if (value.allPatients !== undefined) {
    return { id: "all", name: t("common:patients.all") };
  }

  if (value.myPatients !== undefined) {
    return { id: "mine", name: t("common:patients.mine") };
  }

  if (value.patientIds[0]) {
    return { id: value.patientIds[0], name: "Loading.." };
  }

  return null;
}

type SelectOptionType = { id: PatientId | "mine" | "all"; name: string };

export default function PatientSelect(props: PatientSelectProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { value, setValue } = props;
  const queryVars: Omit<PatientSelectQueryVariables, "search"> = {
    first: 30,
  };

  const fixed: ReadonlyArray<SelectOptionType> = [
    { name: t("common:patients.mine"), id: "mine" },
    { name: t("common:patients.all"), id: "all" },
  ];

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => setValue(inputToSelect(value))}
      error={false}
      required={false}
      helperText=""
      value={selectToValue(value, t)}
      queryVariables={queryVars}
      query={usePatientSelectLazyQuery}
      fixedOptions={fixed}
      unwrapResponse={(response) => response.patients?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={t("common:patients.title")}
      autocompleteProps={{
        noOptionsText: t("common:patients.noMatching"),
        getOptionLabel: (option) => option.name,
      }}
    />
  );
}

type StrictPatientSelectProps = {
  value: PickTypename<Patient, "id" | "name"> | null;
  onChange: (newValue: PickTypename<Patient, "id" | "name">) => void;
  label?: string;
  autocompleteProps?: OverridableAutocompleteProps<PickTypename<Patient, "id" | "name">, false>;
  inputProps?: OverridableInputProps;
  hidden?: boolean;
};

export function StrictPatientSelect(props: StrictPatientSelectProps): ReactElement | null {
  const { t } = useTranslation(["common"]);

  if (props.hidden) {
    return null;
  }

  const queryVars: Omit<PatientSelectQueryVariables, "search"> = {
    first: 30,
  };

  const label = props.label ? props.label : t("common:patients.title");

  return (
    <QueryAutocompleteSingle
      value={props.value}
      valueUpdated={props.onChange}
      queryVariables={queryVars}
      query={usePatientSelectLazyQuery}
      unwrapResponse={(response) => response.patients?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={label}
      inputProps={props.inputProps}
      autocompleteProps={{
        noOptionsText: t("common:patients.noMatching"),
        getOptionLabel: (option) => option.name,
        ...props.autocompleteProps,
      }}
    />
  );
}

export { defaultValue };
