import { Box, Skeleton, Stack } from "@mui/material";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { useScaleScorerHistoryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import { CareEpisodeComputedValueId, CareEpisodeId, ScaleId } from "Lib/Ids";
import { resultToRemoteData } from "Lib/Utils";
import React, { ReactElement } from "react";
import { RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { formatDate } from "Shared/formatters";
import * as Scale from "Shared/Scale/Scale";
import ScaleShortDescription from "Shared/Scale/ScaleDescription";
import { FeedbackReportContext } from "../FeedbackReportContext";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import LevelDetail from "FeedbackReport/LevelDetail";
import { LevelHeaderLoadingIndicator } from "FeedbackReport/LevelHeader";
import { parseScaleAdministrationHistoryQuery } from "FeedbackReport/DetailPane/DataStore/OverviewTreatmentResponseTransforms";
import DetailPaneLevelHeader from "FeedbackReport/DetailPaneLevelHeader";
import ScaleScorerHistoryNarrative from "FeedbackReport/DetailPane/ScaleScorerHistoryNarrative";
import { useTranslation } from "react-i18next";
import ScaleAdministrationHistoryLineGraph from "Shared/Viz/ScaleAdministrationHistoryLineGraph";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";

// -- MODEL -------------------------------------------------------------------

type ScaleAdministrationHistoryData = RemoteData<Error, ReadonlyArray<ScaleScorerHistory>>;

export type ScaleAdministrationHistoryPaneProps = {
  careEpisodeId: CareEpisodeId;
  scaleId: ScaleId;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleAdministrationHistoryPaneHookWrapper(props: ScaleAdministrationHistoryPaneProps): ReactElement {
  const { careEpisodeId, scaleId, feedbackReportContext } = props;

  const { remoteData } = apolloQueryHookWrapper(
    useScaleScorerHistoryQuery({
      variables: {
        careEpisodeId,
        scaleId,
      },
    })
  );

  const transformed: ScaleAdministrationHistoryData = remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      return resultToRemoteData(
        parseScaleAdministrationHistoryQuery(result, feedbackReportContext.participantsByUserId)
      );
    });

  return ScaleAdministrationHistoryPane({
    remoteData: transformed,
    feedbackReportContext,
  });
}

function ScaleScorerHistoryLoadingIndicator(): ReactElement {
  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <Box sx={{ pl: 10, mt: 8, display: "flex", flexDirection: "row" }}>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
      </Box>
    </div>
  );
}

function UnscoredHistory(props: {
  scaleAdministrationHistory: ScaleScorerHistory;
  scale: Scale.ScaleWithScorer;
  feedbackReportContext: FeedbackReportContext;
}): ReactElement {
  const { t } = useTranslation(["report"]);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ mr: 4 }}>
        {props.scaleAdministrationHistory.scores.map((completion) => (
          <Box sx={{ mb: 1 }}>
            <Link to={Routing.scaleScoreRoute(props.feedbackReportContext, props.scale.id, completion.id)}>
              {formatDate(completion.date)}
            </Link>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: "20rem" }}>{t("report:noScoringInformation")}</Box>
    </Box>
  );
}

function CategoricalHistory(props: {
  scaleAdministrationHistory: ScaleScorerHistory;
  scale: Scale.ScaleWithScorer;
  feedbackReportContext: FeedbackReportContext;
}): ReactElement {
  const { t } = useTranslation(["report"]);
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ mr: 4 }}>
        {props.scaleAdministrationHistory.scores.map((completion) => (
          <Box sx={{ mb: 1 }}>
            <Link to={Routing.scaleScoreRoute(props.feedbackReportContext, props.scale.id, completion.id)}>
              {formatDate(completion.date)}
            </Link>
          </Box>
        ))}
      </Box>
      <Box sx={{ width: "20rem" }}>{t("report:noScoringInformation")}</Box>
    </Box>
  );
}

function NumericalHistory(props: {
  scaleAdministrationHistory: ScaleScorerHistory & { scale: Scale.ScaleWithNumericalScorer };
  feedbackReportContext: FeedbackReportContext;
  onScoreClickRoute: (scoreId: CareEpisodeComputedValueId) => Routing.FeedbackReportRoute;
}) {
  const { scaleAdministrationHistory, feedbackReportContext, onScoreClickRoute } = props;
  return (
    <Stack spacing={0}>
      <ScaleAdministrationHistoryLineGraph
        scores={scaleAdministrationHistory.scores}
        administrationDates={feedbackReportContext.administrationDates}
        scale={scaleAdministrationHistory.scale}
        onScoreClickRoute={onScoreClickRoute}
      />
      <ScaleScorerHistoryNarrative
        history={scaleAdministrationHistory}
        administrationDates={feedbackReportContext.administrationDates}
        onScoreClickRoute={onScoreClickRoute}
      />
    </Stack>
  );
}

function historyContent(history: ScaleScorerHistory, context: FeedbackReportContext): ReactElement | null {
  const onScoreClickRoute = (scoreId: CareEpisodeComputedValueId) =>
    Routing.scaleScoreRoute(context, history.scale.id, scoreId);

  if (Scale.isUnscored(history.scale.scorer)) {
    return (
      <UnscoredHistory
        feedbackReportContext={context}
        scale={history.scale}
        scaleAdministrationHistory={history}
      />
    );
  }

  if (Scale.isCategorical(history.scale.scorer)) {
    return (
      <CategoricalHistory
        feedbackReportContext={context}
        scale={history.scale}
        scaleAdministrationHistory={history}
      />
    );
  }

  if (Scale.scaleIsNumerical(history.scale)) {
    return (
      <NumericalHistory
        feedbackReportContext={context}
        onScoreClickRoute={onScoreClickRoute}
        scaleAdministrationHistory={history as ScaleScorerHistory & { scale: Scale.ScaleWithNumericalScorer }}
      />
    );
  }

  throw new Error("This code is unreachable");
}

type Props = {
  remoteData: ScaleAdministrationHistoryData;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleAdministrationHistoryPane(props: Props): ReactElement {
  const { remoteData, feedbackReportContext } = props;

  const content = remoteData.caseOf({
    NotAsked: () => <ScaleScorerHistoryLoadingIndicator />,
    Loading: () => <ScaleScorerHistoryLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (scaleAdministrationHistory) => {
      // If there are multiple respondents we may have multiple adminstrations, and so multiple charts, but they'll all
      // be of the same scale, so we can just show the header once.
      const scale = scaleAdministrationHistory[0]?.scale;
      const header = scale ? (
        <>
          <DetailPaneLevelHeader label={Scale.scaleMediumName(scale)} />
          <ScaleShortDescription scale={scale} />
        </>
      ) : null;

      return (
        <Stack direction="column" spacing={1}>
          {header}
          {scaleAdministrationHistory.map((history, i) => {
            const details = historyContent(history, feedbackReportContext);
            return (
              <React.Fragment key={i}>
                <LevelDetail>{details}</LevelDetail>
              </React.Fragment>
            );
          })}
        </Stack>
      );
    },
  });

  return <div data-testid="scale-administration-history">{content}</div>;
}

export default ScaleAdministrationHistoryPaneHookWrapper;
export {
  ScaleAdministrationHistoryPaneHookWrapper as HookWrapper,
  ScaleAdministrationHistoryPane as Component,
};
