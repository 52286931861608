import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { AppointmentId, PatientId } from "Lib/Ids";
import { PatientSessionCreationAlgorithmTable } from "Patients/Appointments/PatientSessionCreationAlgorithm";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentCareEpisodeAlgorithmTable } from "./AppointmentCareEpisodeAlgorithm";
import { WithFeature, WithOutFeature } from "Contexts/CurrentInstituteContext";
import { AppointmentTreatmentServiceInferenceTable } from "./AppointmentTreatmentServiceInference";
import { AppointmentMeasurementPlans } from "./AppointmentMeasurementPlans";
import { resources } from "i18n";
import { AlgorithmDecisionResult } from "Shared/DecisionTable";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useAppointmentAlgorithmDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { AppointmentScaleAdministration } from "./AppointmentScaleAdministration";

// Step status:
//   Appointment Details: always checked
//   Episode of Care Selection: checked if available, crossed out if failed
//   Measurement Opportunity: crossed red if no measurement opportunity
//   Measurement plan selection: crossed if no measurment opportynity
//   Scale Selection: crossed if no measurement opporutnity.

type MeasurementStep = {
  titleKey: `patients:appointments.steps.${keyof (typeof resources)["en"]["patients"]["appointments"]["steps"]}.title`;
  success: (
    episodeOfCareExists: boolean,
    patientSessionExists: boolean,
    scalesPlanned: boolean
  ) => AlgorithmDecisionResult;
  errorKey?: `patients:appointments.steps.${keyof (typeof resources)["en"]["patients"]["appointments"]["steps"]}.error`;
};

const steps: ReadonlyArray<MeasurementStep> = [
  {
    titleKey: "patients:appointments.steps.appointmentDetails.title",
    success: () => AlgorithmDecisionResult.SUCCESS,
  },
  {
    titleKey: "patients:appointments.steps.careEpisodeSelection.title",
    errorKey: "patients:appointments.steps.careEpisodeSelection.error",
    success: (episodeOfCareExists: boolean) => {
      return episodeOfCareExists ? AlgorithmDecisionResult.SUCCESS : AlgorithmDecisionResult.FAILURE;
    },
  },
  {
    titleKey: "patients:appointments.steps.patientSessionExists.title",
    errorKey: "patients:appointments.steps.patientSessionExists.error",
    success: (episodeOfCareExists: boolean, patientSessionExists: boolean) => {
      return episodeOfCareExists && patientSessionExists
        ? AlgorithmDecisionResult.SUCCESS
        : AlgorithmDecisionResult.UNKNOWN;
    },
  },
  {
    titleKey: "patients:appointments.steps.planSelection.title",
    success: (episodeOfCareExists: boolean, patientSessionExists: boolean) => {
      return episodeOfCareExists && patientSessionExists
        ? AlgorithmDecisionResult.SUCCESS
        : AlgorithmDecisionResult.UNKNOWN;
    },
  },
  {
    titleKey: "patients:appointments.steps.scaleSelection.title",
    success: (episodeOfCareExists: boolean, patientSessionExists: boolean, scalesPlanned: boolean) => {
      return episodeOfCareExists && patientSessionExists && scalesPlanned
        ? AlgorithmDecisionResult.SUCCESS
        : AlgorithmDecisionResult.UNKNOWN;
    },
  },
];

function StepDetails(props: { appointmentId: AppointmentId; patientId: PatientId; activeStep: number }) {
  const { t } = useTranslation(["patients", "enums"]);

  // For now, just hardcode to the index
  switch (props.activeStep) {
    // Appointment details
    case 0:
      return (
        <>
          <WithFeature feature={"enableTreatmentServices"}>
            <AppointmentTreatmentServiceInferenceTable
              appointmentId={props.appointmentId}
              patientId={props.patientId}
              hideUnknown={false}
            />
          </WithFeature>
          <WithOutFeature feature={"enableTreatmentServices"}>
            <Typography>{t("patients:appointments.steps.appointmentDetails.noDecision")}</Typography>
          </WithOutFeature>
        </>
      );

    // Care Episode Selection
    case 1:
      return (
        <AppointmentCareEpisodeAlgorithmTable
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={false}
        />
      );

    // Patient Session Selection
    case 2:
      return (
        <PatientSessionCreationAlgorithmTable
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={false}
        />
      );

    // Measurement Plan Selection
    case 3:
      return (
        <AppointmentMeasurementPlans
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={false}
        />
      );

    case 4:
      return (
        <AppointmentScaleAdministration appointmentId={props.appointmentId} patientId={props.patientId} />
      );

    // Nothing clicked yet
    default:
      return <Typography>{t("patients:appointments.stepExplanation")}</Typography>;
  }
}

function defaultStepFromUrlHash() {
  switch (window.location.hash) {
    case "#appointmentDetails":
      return 0;
    case "#careEpisodeMatching":
      return 1;
    case "#patientSessionCreation":
      return 2;
    case "#measurementPlanSelection":
      return 3;
    case "#scaleSelection":
      return 4;
    default:
      return -1;
  }
}

function AppointmentAlgorithmsStepperInternal(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  careEpisodeExists: boolean;
  patientSessionExists: boolean;
  scalesPlanned: boolean;
}): ReactElement {
  const { careEpisodeExists, patientSessionExists, scalesPlanned } = props;
  const { t } = useTranslation(["patients"]);

  const [activeStep, setActiveStep] = React.useState(defaultStepFromUrlHash());

  const stepElements = steps.map((step, index) => {
    const status = step.success(careEpisodeExists, patientSessionExists, scalesPlanned);

    return (
      <Step key={step.titleKey} completed={status === AlgorithmDecisionResult.SUCCESS}>
        <StepButton color="inherit" onClick={() => setActiveStep(index)}>
          <StepLabel error={status === AlgorithmDecisionResult.FAILURE}>
            {status === AlgorithmDecisionResult.FAILURE && step.errorKey
              ? t(step.errorKey)
              : t(step.titleKey)}
          </StepLabel>
        </StepButton>
      </Step>
    );
  });

  const stepper = (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel>
      {stepElements}
    </Stepper>
  );

  return (
    <Stack direction={"column"} spacing={1}>
      {stepper}
      <StepDetails activeStep={activeStep} appointmentId={props.appointmentId} patientId={props.patientId} />
    </Stack>
  );
}

export function AppointmentAlgorithmsStepper(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
}): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentAlgorithmDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );
  const { t } = useTranslation(["patients", "common"]);

  const result = remoteData.caseOf({
    Success: (data) => {
      if (data.schedulingAppointment) {
        const careEpisodeExists = !!data.schedulingAppointment.careEpisode?.id;
        const patientSessionExists = !!data.schedulingAppointment.patientSession?.id;
        return (
          <AppointmentAlgorithmsStepperInternal
            appointmentId={props.appointmentId}
            patientId={props.patientId}
            careEpisodeExists={careEpisodeExists}
            patientSessionExists={patientSessionExists}
            scalesPlanned={!!data.schedulingAppointment.patientSession?.scalesPlanned}
          />
        );
      } else {
        return <ErrorMessage message={t("common:notFound")} />;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });

  return (
    <Card id="careEpisodeMatching">
      <CardHeader title={t("patients:appointments.cards.measurementFlow")} />
      <CardContent>{result}</CardContent>
    </Card>
  );
}
