import { ApolloError } from "@apollo/client";
import {
  Alert,
  Box,
  Button,
  ButtonProps,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  EntityTreeNodeParams,
  MetricDataExportStatus,
  MetricParams,
  MetricStatus,
  OutcomesMetricDataExportQuery,
  useCreateMetricAndExportMutation,
  useOutcomesMetricDataExportDownloadUrlMutation,
  useOutcomesMetricDataExportQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { MetricDataExportId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Form, useForm, useTextField } from "Shared/Form";
import Spinner from "Shared/Spinner";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Failure, NotAsked, RemoteData, Success } from "seidr";
import { ensureMetricParams } from "./OutcomesFormHelpers";

type DownloadDialogProps = {
  metricDataExportId: MetricDataExportId;
};

function DownloadFileDialog(props: DownloadDialogProps) {
  const { t } = useTranslation(["outcomes"]);

  const [downloadError, setDownloadError] = useState<string | null>(null);

  // We don't want the loading screen to flash up while it polls, so we use a use state hook here to only update it when we get new data from the server.
  const [loadedData, setLoadedData] = useState<RemoteData<ApolloError, OutcomesMetricDataExportQuery>>(
    NotAsked()
  );

  useOutcomesMetricDataExportQuery({
    variables: {
      metricDataExportId: props.metricDataExportId,
    },
    pollInterval: 5000,
    onCompleted: (data) => {
      setLoadedData(Success(data));
    },
    onError: (e) => {
      setLoadedData(Failure(e));
    },
  });

  const processingContent = (
    <Box textAlign={"center"}>
      <Typography>{t("outcomes:download.processing")}</Typography>
      <Spinner />
    </Box>
  );

  const errorContent = (
    <Box textAlign={"center"}>
      <Typography>{t("outcomes:download.error")}</Typography>
    </Box>
  );

  const content = loadedData.caseOf({
    Success: (data) => {
      if (data.outcomesMetricDataExport) {
        switch (data.outcomesMetricDataExport.status) {
          case MetricDataExportStatus.ERROR:
            return errorContent;
          case MetricDataExportStatus.NEW:
          case MetricDataExportStatus.PROCESSING:
            return processingContent;
          case MetricDataExportStatus.READY:
            return (
              <Box textAlign={"center"}>
                <Typography>{t("outcomes:download.ready")}</Typography>
                <DownloadMetricExcelButton
                  metricDataExportId={props.metricDataExportId}
                  name={data.outcomesMetricDataExport.filename}
                  setError={setDownloadError}
                />
                {downloadError ? <Alert severity="error">{downloadError}</Alert> : null}
              </Box>
            );
        }
      } else {
        return errorContent;
      }
    },
    Failure: () => {
      return errorContent;
    },
    _: () => {
      return processingContent;
    },
  });

  return <DialogContent style={{ marginTop: "0.5em", overflow: "visible" }}>{content}</DialogContent>;
}

function DownloadMetricExcelButton(props: {
  metricDataExportId: MetricDataExportId;
  name: string;
  setError: (error: string) => void;
}) {
  const [downloadFile, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesCreateMetricDataExportDownloadUrl,
    useOutcomesMetricDataExportDownloadUrlMutation({
      variables: {
        input: {
          metricDataExportId: props.metricDataExportId,
        },
      },
    })
  );
  const showSpinner = remoteData.kind === "Loading";

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: (result) => {
        if (result?.downloadUrl) {
          window.location.href = result.downloadUrl;
        }
      },
      Failure: (error) => {
        props.setError(error.toError().message);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const onClick = () => {
    downloadFile({ variables: { input: { metricDataExportId: props.metricDataExportId } } });
  };

  return (
    <ButtonWithSpinner
      variant="contained"
      color="primary"
      showSpinner={showSpinner}
      disabled={showSpinner}
      onClick={onClick}
    >
      {props.name}
    </ButtonWithSpinner>
  );
}

type DownloadMetricExcelFormProps = {
  metricParams: MetricParams;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  setMetricDataExportId: (id: MetricDataExportId) => void;
};

function DownloadTransientMetricExcelForm(props: DownloadMetricExcelFormProps) {
  const { t } = useTranslation(["outcomes"]);
  const [createDataExport, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesCreateMetricAndExport,
    useCreateMetricAndExportMutation()
  );

  const fields = {
    name: useTextField({
      required: true,
    }),
  };

  const form = useForm({
    id: "save-metric-form",
    submit: () => {
      createDataExport({
        variables: {
          input: {
            payload: ensureMetricParams(props.metricParams),
            dateRange: {
              min: props.startDate,
              max: props.endDate,
            },
            name: assertNonNull(fields.name.value),
            status: MetricStatus.EXPORT_ONLY,
            entityTreeNode: props.entityTreeNode,
          },
        },
      });
    },
    remoteData: remoteData,
    fields: fields,
    onSuccess: (response) => {
      if (response) {
        props.setMetricDataExportId(response.metricDataExport.id);
      }
    },
  });

  return (
    <>
      <Form id={form.id} onSubmit={form.onSubmit}>
        <DialogContent style={{ marginTop: "0.5em", overflow: "visible" }}>
          <Stack direction="column" spacing={1}>
            <Typography>{t("outcomes:downloadTransient.explanation")}</Typography>
            <TextField
              onChange={fields.name.onChange}
              value={fields.name.value}
              placeholder={t("outcomes:downloadTransient.namePlaceholder")}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
            form={form.id}
          >
            {t("outcomes:downloadTransient.exportData")}
          </ButtonWithSpinner>
        </DialogActions>
      </Form>
    </>
  );
}

type DownloadMetricExcelProps = {
  metricParams: MetricParams;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  children: ReactNode;
  buttonProps?: Partial<ButtonProps>;
};

export default function DownloadTransientMetricExcelButton(props: DownloadMetricExcelProps) {
  const { t } = useTranslation(["outcomes"]);
  const [showSaveDialog, setShowSaveDialog] = useState<boolean>(false);

  const [metricDataExportId, setMetricDataExportId] = useState<MetricDataExportId | null>(null);

  let content = null;

  if (metricDataExportId) {
    content = <DownloadFileDialog metricDataExportId={metricDataExportId} />;
  } else {
    content = (
      <DownloadTransientMetricExcelForm
        endDate={props.endDate}
        startDate={props.startDate}
        entityTreeNode={props.entityTreeNode}
        metricParams={props.metricParams}
        setMetricDataExportId={setMetricDataExportId}
      />
    );
  }

  return (
    <>
      <ResponsiveDialog
        open={showSaveDialog}
        title={t("outcomes:downloadTransient.title")}
        keepMounted={false}
        dialogWidth="50%"
        onClose={() => setShowSaveDialog(false)}
      >
        {content}
      </ResponsiveDialog>
      <Button onClick={() => setShowSaveDialog(true)} {...props.buttonProps}>
        {props.children}
      </Button>
    </>
  );
}
