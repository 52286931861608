import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { genderIdentityT, genderT, notificationPreferenceT } from "GeneratedGraphQL/EnumTranslations";
import {
  Gender,
  GenderIdentity,
  NotificationPreference,
  Patient,
  User,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import EnumSelect from "Shared/EnumSelect";
import { Form, FormOverlay, useBooleanField, useForm, useTextField, useWrappedField } from "Shared/Form";
import { range } from "d3";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function DemographicsInputLoading(): ReactElement {
  return (
    <Stack spacing={1}>
      {/* 13 for the number of fields in the form. */}
      {range(13).map((i) => (
        <Skeleton key={i} />
      ))}
    </Stack>
  );
}

type PatientDemographicsDetails = Pick<
  Patient,
  | "legalFirstName"
  | "legalLastName"
  | "preferredFirstName"
  | "preferredLastName"
  | "preferredPronouns"
  | "email"
  | "phoneNumber"
  | "mrn"
  | "gender"
  | "genderIdentity"
  | "genderIdentityFhir"
  | "dob"
  | "primaryLanguage"
  | "race"
  | "ethnicity"
  | "isTest"
> &
  Pick<User, "notificationPreference">;

type DemographicsFormProps<ResponseType> = {
  defaults: PatientDemographicsDetails;
  onSuccess?: (response: ResponseType) => void;
  remoteData: MutationRemoteDataResult<ResponseType>;
  mutation: (fields: PatientDemographicsDetails) => void;
  errorMessage: string;
};

export function DemographicsForm<ResponseType>(props: DemographicsFormProps<ResponseType>): ReactElement {
  const { t } = useTranslation(["patients", "common"]);

  const fields = {
    legalFirstName: useTextField({
      default: props.defaults.legalFirstName || undefined,
      required: false,
      errorPaths: ["user.base"],
    }),
    legalLastName: useTextField({
      default: props.defaults.legalLastName || undefined,
      required: false,
      errorPaths: ["user.base"],
    }),
    preferredFirstName: useTextField({
      default: props.defaults.preferredFirstName || undefined,
      required: false,
      errorPaths: ["user.base"],
    }),
    preferredLastName: useTextField({
      default: props.defaults.preferredLastName || undefined,
      required: false,
      errorPaths: ["user.base"],
    }),
    preferredPronouns: useTextField({
      default: props.defaults.preferredPronouns || undefined,
      required: false,
    }),
    email: useTextField({
      default: props.defaults.email || undefined,
      required: false,
      errorPaths: ["user.email"],
    }),
    phoneNumber: useTextField({
      default: props.defaults.phoneNumber || undefined,
      required: false,
      errorPaths: ["user.phone_number"],
    }),
    mrn: useTextField({ default: props.defaults.mrn || undefined, required: false, errorPaths: ["mrn"] }),
    gender: useWrappedField({
      default: props.defaults.gender || undefined,
      required: false,
      errorPaths: ["gender"],
    }),
    genderIdentity: useTextField({
      default: props.defaults.genderIdentity || undefined,
      required: false,
      errorPaths: ["gender_identity"],
    }),
    genderIdentityFhir: useWrappedField({
      default: props.defaults.genderIdentityFhir || undefined,
      required: false,
      errorPaths: ["gender_identity_fhir"],
    }),
    dob: useWrappedField({ default: props.defaults.dob || undefined, required: false, errorPaths: ["dob"] }),
    notificationPreference: useWrappedField({
      default: props.defaults.notificationPreference || undefined,
      required: false,
      errorPaths: ["user.notification_preference"],
    }),
    primaryLanguage: useTextField({
      default: props.defaults.primaryLanguage || undefined,
      required: false,
      errorPaths: ["primary_language"],
    }),
    race: useTextField({
      default: props.defaults.race || undefined,
      required: false,
      errorPaths: ["race"],
    }),
    ethnicity: useTextField({
      default: props.defaults.ethnicity || undefined,
      required: false,
      errorPaths: ["ethnicity"],
    }),
    isTest: useBooleanField({
      default: props.defaults.isTest,
      required: true,
      errorPaths: ["is_test"],
    }),
  };

  const form = useForm({
    id: "edit-demographics-form",
    fields: fields,
    ignoreErrorPaths: ["user.username"],
    onSuccess: props.onSuccess,
    remoteData: props.remoteData,
    submit: () => {
      props.mutation({
        legalFirstName: fields.legalFirstName.value || null,
        legalLastName: fields.legalLastName.value || null,
        preferredFirstName: fields.preferredFirstName.value || null,
        preferredLastName: fields.preferredLastName.value || null,
        preferredPronouns: fields.preferredPronouns.value || null,
        email: fields.email.value || null,
        phoneNumber: fields.phoneNumber.value || null,
        mrn: fields.mrn.value || null,
        gender: fields.gender.value || null,
        genderIdentity: fields.genderIdentity.value || null,
        genderIdentityFhir: fields.genderIdentityFhir.value || null,
        dob: fields.dob.value || null,
        notificationPreference: fields.notificationPreference.value || null,
        primaryLanguage: fields.primaryLanguage.value || null,
        race: fields.race.value || null,
        ethnicity: fields.ethnicity.value || null,
        isTest: !!fields.isTest.value,
      });
    },
  });

  return (
    <>
      <DialogContent>
        <Form onSubmit={form.onSubmit} id={form.id}>
          <FormOverlay response={props.remoteData} errorMessage={form.globalError || props.errorMessage} />
          <Stack direction="column" spacing={1} sx={{ paddingTop: "1rem" }}>
            <TextField
              label={t("patients:demographics.legalFirstName")}
              value={fields.legalFirstName.value}
              error={fields.legalFirstName.error}
              helperText={fields.legalFirstName.helperText}
              onChange={fields.legalFirstName.onChange}
            />
            <TextField
              label={t("patients:demographics.legalLastName")}
              value={fields.legalLastName.value}
              error={fields.legalLastName.error}
              helperText={fields.legalLastName.helperText}
              onChange={fields.legalLastName.onChange}
            />
            <TextField
              label={t("patients:demographics.preferredFirstName")}
              value={fields.preferredFirstName.value}
              error={fields.preferredFirstName.error}
              helperText={fields.preferredFirstName.helperText}
              onChange={fields.preferredFirstName.onChange}
            />
            <TextField
              label={t("patients:demographics.preferredLastName")}
              value={fields.preferredLastName.value}
              error={fields.preferredLastName.error}
              helperText={fields.preferredLastName.helperText}
              onChange={fields.preferredLastName.onChange}
            />
            <TextField
              label={t("patients:demographics.preferredPronouns")}
              value={fields.preferredPronouns.value}
              error={fields.preferredPronouns.error}
              helperText={fields.preferredPronouns.helperText}
              onChange={fields.preferredPronouns.onChange}
            />
            <TextField
              label={t("patients:demographics.email")}
              value={fields.email.value}
              error={fields.email.error}
              helperText={fields.email.helperText}
              onChange={fields.email.onChange}
              type="email"
            />
            <TextField
              label={t("patients:demographics.phoneNumber")}
              value={fields.phoneNumber.value}
              error={fields.phoneNumber.error}
              helperText={fields.phoneNumber.helperText}
              onChange={fields.phoneNumber.onChange}
              type="tel"
            />
            <TextField
              label={t("patients:demographics.mrn")}
              value={fields.mrn.value}
              error={fields.mrn.error}
              helperText={fields.mrn.helperText}
              onChange={fields.mrn.onChange}
            />
            <EnumSelect
              title={t("patients:demographics.gender")}
              value={fields.gender.value || null}
              onChange={fields.gender.onChange}
              optionsEnum={Gender}
              enumTrans={genderT}
            />
            <TextField
              label={t("patients:demographics.genderIdentity")}
              value={fields.genderIdentity.value}
              error={fields.genderIdentity.error}
              helperText={fields.genderIdentity.helperText}
              onChange={fields.genderIdentity.onChange}
            />
            <EnumSelect
              title={t("patients:demographics.genderIdentityFhir")}
              value={fields.genderIdentityFhir.value || null}
              onChange={fields.genderIdentityFhir.onChange}
              error={fields.genderIdentityFhir.error}
              helperText={fields.genderIdentityFhir.helperText}
              optionsEnum={GenderIdentity}
              enumTrans={genderIdentityT}
            />
            <DatePicker
              label={t("patients:demographics.dob")}
              format={t("patients:demographics.dobFormat")}
              value={fields.dob.value}
              onChange={fields.dob.onChange}
              slotProps={{
                textField: {
                  error: fields.dob.error,
                  helperText: fields.dob.helperText,
                },
              }}
            />
            <EnumSelect
              title={t("patients:demographics.notificationPreference")}
              value={fields.notificationPreference.value || null}
              onChange={fields.notificationPreference.onChange}
              error={fields.notificationPreference.error}
              helperText={fields.notificationPreference.helperText}
              optionsEnum={NotificationPreference}
              enumTrans={notificationPreferenceT}
            />
            {/* The API/models don't define this as an enum and it didn't seem appropriate to create a client-side only
                enum for it, so we just have a regular select instead of a fancy one.  */}
            <FormControl error={fields.primaryLanguage.error}>
              <InputLabel>{t("patients:demographics.primaryLanguage")}</InputLabel>
              <Select
                label={t("patients:demographics.primaryLanguage")}
                value={fields.primaryLanguage.value}
                onChange={fields.primaryLanguage.onChange}
                error={fields.primaryLanguage.error}
              >
                <MenuItem value="en">{t("common:language.en")}</MenuItem>
                <MenuItem value="es">{t("common:language.es")}</MenuItem>
              </Select>
              <FormHelperText>{fields.primaryLanguage.helperText}</FormHelperText>
            </FormControl>
            <TextField
              label={t("patients:demographics.race")}
              value={fields.race.value}
              error={fields.race.error}
              helperText={fields.race.helperText}
              onChange={fields.race.onChange}
            />
            <TextField
              label={t("patients:demographics.ethnicity")}
              value={fields.ethnicity.value}
              error={fields.ethnicity.error}
              helperText={fields.ethnicity.helperText}
              onChange={fields.ethnicity.onChange}
            />
            <FormControl error={fields.isTest.error}>
              <FormControlLabel
                control={<Checkbox />}
                label={t("patients:demographics.isTest")}
                checked={fields.isTest.value}
                onChange={fields.isTest.onChange}
              />
              <FormHelperText>{fields.isTest.helperText}</FormHelperText>
            </FormControl>
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="full_access">
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            form={form.id}
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </ReadOnlyOrImpersonatingDisabledButton>
      </DialogActions>
    </>
  );
}
