import React, { ReactElement } from "react";
import { ProviderNameQuery, useProviderNameQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { ProviderId } from "Lib/Ids";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import ErrorMessage from "Shared/ErrorMessage";
import { Skeleton } from "@mui/material";

// This simple component takes a provider id and generates a name from it
// It is anticipated that the vast majority of the time this will be hit
// from cache.

export type ProviderNameProps = {
  providerId: ProviderId;
};

function ProviderNameHookWrapper(props: ProviderNameProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useProviderNameQuery({
      variables: {
        id: props.providerId,
      },
    })
  );

  return ProviderName({ remoteData });
}

type ProviderNameDataProps = {
  remoteData: RemoteData<ApolloError, ProviderNameQuery>;
};

function LoadingIndicator(): ReactElement {
  return <Skeleton height={21} width={75} data-testid="loading-indicator" />;
}

function ProviderName(props: ProviderNameDataProps): ReactElement {
  return props.remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      return <>{result.provider?.name}</>;
    },
  });
}

export default ProviderNameHookWrapper;
export { ProviderNameHookWrapper as HookWrapper, ProviderName as Component };
