import { Box, Button, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useAppointmentDetailsQuery, useEditAppointmentMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AppointmentId } from "Lib/Ids";
import { AppointmentForm, AppointmentFormInputLoading } from "./AppointmentForm";
import Link from "MDS/Link";

type EditAppointmentButtonProps = {
  appointmentId: AppointmentId;
  buttonMinWidth?: string;
};

export function EditAppointmentButton(props: EditAppointmentButtonProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <EditAppointmentDialog open={open} onClose={() => setOpen(false)} appointmentId={props.appointmentId} />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("common:edit")}
      </Button>
      {dialog}
    </Box>
  );
}

type EditAppointmentDialogProps = {
  appointmentId: AppointmentId;
  open: boolean;
  onClose: () => void;
};

function EditAppointmentDialog(props: EditAppointmentDialogProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  const [editAppointment, { remoteData: mutationRemoteData }] = apolloMutationHookWrapper(
    (response) => response.schedulingEditAppointment,
    useEditAppointmentMutation({ refetchQueries: ["AppointmentDetails", "AppointmentInvitationDetails"] })
  );

  let title = t("patients:appointments.editDialog.title");

  const content = remoteData.caseOf({
    NotAsked: () => <AppointmentFormInputLoading />,
    Loading: () => <AppointmentFormInputLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      const appointment = response.schedulingAppointment;
      if (appointment) {
        title = t("patients:appointments.editDialog.titleWithName", { name: appointment.patient.name });
        if (appointment.careEpisode) {
          return (
            <AppointmentForm
              careEpisodeId={appointment.careEpisode.id}
              patientId={appointment.patient.id}
              patientSessionId={appointment.patientSession?.id || undefined}
              mode="edit"
              defaults={{
                minutesDuration: appointment.minutesDuration,
                providerId: appointment.provider?.id || null,
                startDate: appointment.startDate,
                recurrenceEndDate: appointment.patientSession?.schedulingRecurrence?.endDate,
                recurrenceFrequency: appointment.patientSession?.schedulingRecurrence?.repeatFrequency,
              }}
              onSuccess={() => setTimeout(props.onClose, 300)}
              remoteData={mutationRemoteData}
              mutation={(fields) =>
                editAppointment({ variables: { input: { ...fields, appointmentId: props.appointmentId } } })
              }
              errorMessage={t("patients:actions.editError")}
            />
          );
        } else {
          // It is technically possible to not have a care episode associated with an appointment. This isn't possible
          // for newly created appointments, but might be for legacy. In that case, just show a message and bounce to the old flow.
          const editLink = appointment.patientSession ? (
            <Link
              to={`/provider/patients/${appointment.patient.id}/sessions/${appointment.patientSession.id}/edit`}
            >
              {t("patients:appointments.createForm.noCareEpisodeOldEdit")}
            </Link>
          ) : null;
          return (
            <>
              <Typography>{t("patients:appointments.createForm.noCareEpisodeFound")}</Typography>
              {editLink}
            </>
          );
        }
      } else {
        return <ErrorMessage message="Failed to load patient demographics" />;
      }
    },
  });

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={title}
      stopBackdropClose={false}
      dialogWidth="50%"
    >
      {content}
    </ResponsiveDialog>
  );
}
