import React, { ReactElement } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import RedirectToEmber from "./RedirectToEmber";
import * as AppSession from "./AppSession/AppSession";
import {
  AppContext,
  InstituteContext,
  getInactivityTimeout,
  getInstituteIdAndName,
} from "./AppSession/AppContext";
import {
  AuthenticatedProviderUser,
  AuthenticatedProviderUserContext,
} from "./AppSession/AuthenticatedProviderUser";
import * as GraphQL from "./Api/GraphQL";
import { RemoteData, Success, NotAsked, Nothing, Just, Maybe } from "seidr";
import Unauthenticated from "./Unauthenticated";
import AppLoader from "./AppLoader";
import ErrorMessage from "./Shared/ErrorMessage";
import { useTracking } from "Shared/Analytics/Metrics";
import { useIdleTimer } from "react-idle-timer";
import RouterWithNotFound from "./Shared/RouterWithNotFound";
import HelpScoutBeacon from "Layout/HelpScout";
import CurrentInstituteIdContext from "Contexts/CurrentInstituteIdContext";
import { WithCurrentInstituteLoaded } from "Contexts/CurrentInstituteContext";
import { DiscreetModeContextProvider } from "Contexts/DiscreetModeContext";
import { useEffectOnce, useEffectSimpleCompare } from "Lib/Hooks";
import { CollaborativeCareApp } from "CollaborativeCare/CollaborativeCareApp";
import { GuessRightLandingPage } from "Login/GuessRightLandingPage";
import { InternalApp } from "Ops/InternalApp";
import { MeasurementBasedCareApp } from "Providers/MeasurementBasedCareApp";
import { TestPatientViewabilityContextProvider } from "Contexts/TestPatientViewabilityContext";
import { FrontendFlagContextProvider } from "Contexts/FrontendFlagContext";
import { ResponsiveDndProvider } from "DragAndDrop/ResponsiveDndProvider";
import { ExternalRoutes } from "External";
import { PiiLevelContextContextProvider } from "Contexts/PiiLevelContext";
import SsoLandingPage from "SsoLandingPage";
import { isDevelopment } from "Debug/Debug";

import * as Sentry from "@sentry/react";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export type AppRouteProps = {
  user: AuthenticatedProviderUser;
  institute: InstituteContext;
};

function ProviderAppRoutes(props: AppRouteProps): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<GuessRightLandingPage />} path="landing-page" />
      <Route element={<ExternalRoutes />} path="external/*" />
      <Route element={<CollaborativeCareApp {...props} />} path="cocm/*" />
      <Route element={<SsoLandingPage />} path="sso-auth" />
      <Route element={<MeasurementBasedCareApp {...props} />} path="*" />
    </RouterWithNotFound>
  );
}

type AuthenticatedAppProps = {
  appContext: AppContext;
  user: AuthenticatedProviderUser;
  lastSentKeepAlive: Maybe<number>;
  keepAlive: (lastSent: number) => void;
};

function AuthenticatedApp(props: AuthenticatedAppProps): ReactElement {
  const { appContext, user, lastSentKeepAlive, keepAlive } = props;

  const keepAliveThrottle = 1000 * 60 * 3; // send a keep alive once ever 3 minutes

  const location = useLocation();

  const track = useTracking();

  useEffectSimpleCompare(() => {
    // Track the initial page view separately
    track({
      eventName: "pageview",
      eventData: {
        route: Just(location.pathname),
        data: Nothing(),
        patientId: Nothing(),
        providerId: Nothing(),
        relatedPersonId: Nothing(),
      },
    });
  }, [location.pathname]);

  const allowedRouter = appContext.institute.caseOf({
    ProviderInstituteContext: () => (
      <Route element={<ProviderAppRoutes user={user} institute={appContext.institute} />} path="app/*" />
    ),
    MirahInternalInstituteContext: () => (
      <Route element={<InternalApp user={user} institute={appContext.institute} />} path="app/*" />
    ),
    GroupLeaderInstituteContext: () => (
      <Route element={<ProviderAppRoutes user={user} institute={appContext.institute} />} path="app/*" />
    ),
  });

  const handleOnIdle = () => {
    // v1 if user becomes idle,
    // - sign out if no time remaining

    // v2 if user becomes idle:
    // - initiate modal with countdown to 1 minute signout or button to continue
    //   - set a 1 minute count down to auto log out on expiration (displaying the countdown to user)
    //   - if the user clicks "Stay Signed In" - call reset() (which will call on Active)
    //   - if the user interacts with the modal, it will actually send a keep alive, which is OK
    //   - the user will click to confirm which would send another keep alive from the OnAction
    //   - or the user will click log out, which would manually call log out
    if (process.env.NODE_ENV === "production") {
      AppSession.logout("endSession");
    }
  };

  const handleOnAction = (_event: Event) => {
    // we want to let the server know the user is active. this should get called once every 5 minutes
    // if user take an action, we should
    //  - send a keep alive
    //  - reset the idletimer
    const now = Date.now();
    handleReset();
    if (lastSentKeepAlive.map((timestamp) => now - timestamp > keepAliveThrottle).getOrElse(true)) {
      keepAlive(now);
    }
  };

  const handleReset = () => reset();

  const timeoutMilliseconds = getInactivityTimeout(appContext.institute);

  const { reset } = useIdleTimer({
    crossTab: true, // only emit onIdle and onActive on the lead tab
    timeout: timeoutMilliseconds,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    throttle: 1000 * 10, // in milliseconds - call on Action at most once per throttle ms
  });

  // This is probably where we want to wrap <LocationProvider>
  const currentInstituteIdAndName = getInstituteIdAndName(appContext.institute);

  // we only want the beacon in non-dev environments.
  const beacon = isDevelopment() ? null : <HelpScoutBeacon />;

  return (
    <ResponsiveDndProvider>
      <CurrentInstituteIdContext.Provider value={Maybe.fromNullable(currentInstituteIdAndName)}>
        <AuthenticatedProviderUserContext.Provider value={user}>
          <PiiLevelContextContextProvider>
            <DiscreetModeContextProvider>
              <TestPatientViewabilityContextProvider>
                <FrontendFlagContextProvider>
                  <span data-testid="authenticated-app">
                    {beacon}

                    <SentryRoutes>
                      {allowedRouter}
                      <Route element={<RedirectToEmber />} path={"*"} />
                    </SentryRoutes>
                  </span>
                </FrontendFlagContextProvider>
              </TestPatientViewabilityContextProvider>
            </DiscreetModeContextProvider>
          </PiiLevelContextContextProvider>
        </AuthenticatedProviderUserContext.Provider>
      </CurrentInstituteIdContext.Provider>
    </ResponsiveDndProvider>
  );
}

function App(): ReactElement {
  const [sessionData, setSessionData] = React.useState<RemoteData<Error, AppSession.AppSession>>(NotAsked());
  const [lastSentKeepAlive, setLastSentKeepAlive] = React.useState<Maybe<number>>(Nothing());

  useEffectOnce(() => AppSession.bootstrap((data) => setSessionData(data)));
  useEffectOnce(() =>
    AppSession.onLogout((session) => {
      GraphQL.clearCacheForLogout();
      setSessionData(Success(session));
    })
  );

  return sessionData.caseOf({
    NotAsked: () => <AppLoader />,
    Loading: () => <AppLoader />,
    Success: (session) => {
      return session.caseOf({
        Unauthenticated: () => <Unauthenticated />,
        Uninitialized: () => <AppLoader />,
        Authenticated: (appContext, user) => (
          <WithCurrentInstituteLoaded>
            <AuthenticatedApp
              appContext={appContext}
              user={user}
              lastSentKeepAlive={lastSentKeepAlive}
              keepAlive={(lastSent) => {
                AppSession.sendKeepAlive();
                setLastSentKeepAlive(Just(lastSent));
              }}
            />
          </WithCurrentInstituteLoaded>
        ),
      });
    },
    Failure: (e) => <ErrorMessage message={e.toString()} />,
  });
}

export { App };
