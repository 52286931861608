import React, { ReactElement } from "react";
import {
  InvitationSortParameter,
  ProviderAssessmentInvitationsQuery,
  ProviderAssessmentInvitationsQueryVariables,
  useProviderAssessmentInvitationsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import InvitationActions from "./InvitationActions";
import * as AppSession from "AppSession/AppSession";

function UnfilledAsessmentInvitations(): ReactElement {
  const userId = AppSession.getCurrentUserId().getOrElse(null);
  const filters: ProviderAssessmentInvitationsQueryVariables = {
    sortBy: InvitationSortParameter.ID,
    sortDirection: null,
    first: 10,
    last: null,
    after: null,
    before: null,
    status: null,
    userId: userId,
    isWaitingOnUserStates: true,
  }; // will need to add provider_id filter and date sort

  const { t } = useTranslation();

  //DataGridCols<InstitutePatientListQuery, ["patients"]>
  const columns: DataGridCols<ProviderAssessmentInvitationsQuery, ["assessmentInvitations"]> = [
    {
      // for now, assume the first participant is the patient until careunit is refactored as patient
      field: "patient.name",
      headerName: "Patient Name",
      flex: 2,
      sortable: false,
      valueGetter: (_value, row) => row.report.patientSession?.patient.name,
    },
    {
      field: "report.effectiveDate",
      headerName: "Appointment",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const effectiveDate = params.row.report.patientSession?.targetDate;
        return effectiveDate === undefined ? "" : t("date.long", { date: effectiveDate });
      }, // add report link if it is available
    },
    {
      field: "report",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const patientSession = params.row.report.patientSession;
        return patientSession === null ? null : (
          <InvitationActions
            invitationId={params.row.id}
            patientId={patientSession.patient.id}
            sessionId={patientSession.id}
            isReportAvailable={patientSession.isReportAvailable}
            isReportComplete={patientSession.isReportComplete}
            referrer={params.row.referrer}
            userId={params.row.user.id}
          />
        );
      },
    },
  ];

  const pageSize = 10;
  return (
    <SortablePagableCollectionDataGrid
      queryHook={useProviderAssessmentInvitationsQuery}
      queryVariables={filters}
      unwrapData={(data: ProviderAssessmentInvitationsQuery | null) => data?.assessmentInvitations || null}
      colNameToSortParam={(column) => (column === "effectiveDate" ? InvitationSortParameter.ID : null)}
      columns={columns}
      defaultPageSize={pageSize}
      localeText={{ noRowsLabel: "No Appointment Tasks" }}
    ></SortablePagableCollectionDataGrid>
  );
}

export default UnfilledAsessmentInvitations;
