import { Delete } from "@mui/icons-material";
import { Box, ButtonProps, DialogContent, DialogActions, Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { Patient, TimeEntryLog, useDiscardTimerMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { omit } from "ramda";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type TimeEntryLogDeleteIconButtonProps = ButtonProps & {
  timeEntryLog: Pick<TimeEntryLog, "id">;
  patient: PickTypename<Patient, "id"> | null;
  onSuccess?: () => void;
  children: ReactNode;
};

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.report.itemCoding.severe.primary,
  padding: theme.spacing(1),
  color: theme.palette.common.white,
}));

export function TimeEntryLogDeleteIconButton(props: TimeEntryLogDeleteIconButtonProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [showForm, setShowForm] = React.useState(false);

  const [deleteTimeEntry, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTimeEntryLog,
    useDiscardTimerMutation({
      refetchQueries: refetchQueries("timeEntries"),
      variables: {
        input: {
          timeEntryLogId: props.timeEntryLog.id,
        },
      },
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";
  const buttonProps = omit(["timeEntryLog"], props);

  return (
    <>
      <ExpandingIconButton
        variant="contained"
        color="secondary"
        icon={<Delete />}
        onClick={() => setShowForm(true)}
        {...buttonProps}
      >
        {t("common:actions.delete")}
      </ExpandingIconButton>
      <ResponsiveDialog
        title={t("collaborativeCare:tasks.timeEntryLog.deleteTimeEntryHeader")}
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <DialogContent>
          <StyledBox>
            <Stack direction="column">{props.children}</Stack>
          </StyledBox>
          <Typography>
            {" "}
            {t("common:actions.deletion.confirmationMessage")} {t("common:actions.permanentWarning")}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Stack direction="row-reverse" spacing={1}>
            <ButtonWithSpinner
              variant="contained"
              color="secondary"
              type="submit"
              showSpinner={showSpinner}
              disabled={showSpinner}
              onClick={() => deleteTimeEntry()}
            >
              {t("common:actions.delete")}
            </ButtonWithSpinner>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setTimeout(() => setShowForm(false), 200)}
            >
              {t("common:actions.cancel")}
            </Button>
          </Stack>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
}
