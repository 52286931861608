import React from "react";
import { DialogContent, TableBody, TableCell, TableRow } from "@mui/material";
import { humanize } from "Lib/Utils";
import { useTranslation } from "react-i18next";
import { formatDate } from "Shared/formatters";
import { PropertyTable } from "MDS/PropertyTable";
import { InstituteCondition, Patient, PatientCondition } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";

type PatientId = Pick<Patient, "__typename" | "id">;
type InstituteConditionDetails = Pick<InstituteCondition, "__typename" | "id" | "name" | "code">;
export type PatientConditionDetails = Pick<
  PatientCondition,
  "__typename" | "id" | "status" | "onsetDate" | "abatementDate"
> & { patient: PatientId; instituteCondition: InstituteConditionDetails };

type PatientConditionSummaryProps = {
  onClose: () => void;
  patientCondition: PatientConditionDetails | null;
};

export function PatientConditionSummaryModal(props: PatientConditionSummaryProps) {
  const { onClose, patientCondition } = props;
  const { t } = useTranslation(["report"]);

  let body = <></>;

  if (patientCondition) {
    const onsetDate = patientCondition.onsetDate
      ? formatDate(patientCondition.onsetDate)
      : t("report:patientCondition.unknownDate");

    const abatementDate = patientCondition.abatementDate
      ? formatDate(patientCondition.abatementDate)
      : t("report:patientCondition.currentDate");
    body = (
      <PropertyTable>
        <TableBody>
          <TableRow>
            <TableCell>{t("report:patientCondition.status")}</TableCell>
            <TableCell>{humanize(patientCondition.status)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("report:patientCondition.onsetDate")}</TableCell>
            <TableCell>{onsetDate}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t("report:patientCondition.abatementDate")}</TableCell>
            <TableCell>{abatementDate}</TableCell>
          </TableRow>
        </TableBody>
      </PropertyTable>
    );
  }

  return (
    <ResponsiveDialog
      open={!!patientCondition}
      title={`${patientCondition?.instituteCondition.name} (${patientCondition?.instituteCondition.code})`}
      onClose={onClose}
      aria-labelledby="modal-patient-condition-title"
    >
      <DialogContent>{body}</DialogContent>
    </ResponsiveDialog>
  );
}
