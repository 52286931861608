import { Add, BarChart, Edit, SportsScore, Toc } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisodeDetailsQuery,
  CareEpisodeSessionsQuery,
  CareEpisodeStatus,
  Patient,
  PatientSession,
  PatientSessionSortParameter,
  PatientSessionStatus,
  Report,
  SortDirection,
  useCareEpisodeDetailsQuery,
  useCareEpisodeSessionsQuery,
  useCareUnitSummaryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import * as Id from "Lib/Id";
import { remotePair } from "Lib/RemoteData";
import { humanize } from "Lib/Utils";
import { Link } from "MDS/Link";
import { PropertyTable } from "MDS/PropertyTable";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import { WithPermission } from "Shared/WithPermission";
import { careEpisodeDetailsBreadcrumbs } from "./CareEpisodeBreadcrumbs";
import CareEpisodeDetailsSettingsBox from "./CareEpisodeDetailsSettingsBox";
import CareEpisodeDetailsTreatmentTracksBox from "./CareEpisodeDetailsTreatmentTracksBox";
import CareEpisodeDischargeDialog from "./CareEpisodeDischargeDialog";
import { CareEpisodeLoading } from "./CareEpisodeLoading";
import { careEpisodeName } from "./CareEpisodeName";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { WithFeature } from "Contexts/CurrentInstituteContext";
import CareEpisodeDecisionBadge from "Patients/CareEpisodes2/CareEpisodeDecisionBadge";

function CareEpisodeDetailsHookWrapper(): ReactElement {
  const { t } = useTranslation(["common", "careEpisodes"]);
  const params = useParams<{
    patientId?: string;
    careEpisodeId?: string;
  }>();
  const careEpisodeId = Id.fromNullableString<"CareEpisode">(params.careEpisodeId).getOrElse(null);
  const patientId = Id.fromNullableString<"Patient">(params.patientId).getOrElse(null);

  if (!careEpisodeId || !patientId) {
    return (
      <Page browserTitle="Mirah">
        <ErrorMessage message="Invalid URL" />
      </Page>
    );
  }

  const { remoteData: careEpisodeResponse } = apolloQueryHookWrapper(
    useCareEpisodeDetailsQuery({
      variables: {
        careEpisodeId: careEpisodeId,
      },
    })
  );

  const { remoteData: careUnitResponse } = apolloQueryHookWrapper(
    useCareUnitSummaryQuery({
      variables: {
        patientId: patientId,
      },
    })
  );

  const breadcrumbs = remotePair(careEpisodeResponse, careUnitResponse).caseOf({
    Success: ([careEpisode, careUnit]) => {
      if (!careEpisode.careEpisode || !careUnit.patient) {
        return null;
      }

      return careEpisodeDetailsBreadcrumbs({
        patient: careUnit.patient,
        careEpisode: careEpisode.careEpisode,
        t: t,
      });
    },
    _: () => null,
  });

  const content = remotePair(careEpisodeResponse, careUnitResponse).caseOf({
    NotAsked: () => <CareEpisodeLoading />,
    Loading: () => <CareEpisodeLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: ([careEpisode, careUnit]) => {
      if (!careEpisode.careEpisode || !careUnit.patient) {
        return <ErrorMessage message="Unable to load data" />;
      }

      return <CareEpisodeDetails careEpisode={careEpisode.careEpisode} patient={careUnit.patient} />;
    },
  });

  return (
    <Page browserTitle="Mirah" breadcrumbs={breadcrumbs}>
      {content}
    </Page>
  );
}

type CareEpisodeDetails = NonNullable<CareEpisodeDetailsQuery["careEpisode"]>;
type PatientSummary = Pick<Patient, "__typename" | "id" | "name" | "autoCreateSessions" | "id">;

export type CareEpisodeDetailsProps = {
  careEpisode: CareEpisodeDetails;
  patient: Omit<PatientSummary, "autoCreateSessions">;
};

function CareEpisodeActions(
  props: CareEpisodeDetailsProps & { openDischargeDialog: () => void }
): ReactElement | null {
  const { t } = useTranslation(["careEpisodes"]);

  if (props.careEpisode.status !== CareEpisodeStatus.ACTIVE) {
    return null;
  }

  return (
    <WithPermission permission="scheduleAppointment">
      <Stack direction="row" spacing={1}>
        <Link to="edit">
          <Button variant="contained" color="primary">
            <Edit />
            {t("careEpisodes:actions.edit")}
          </Button>
        </Link>
        <Button variant="outlined" onClick={props.openDischargeDialog}>
          <SportsScore />
          {t("careEpisodes:actions.discharge")}
        </Button>
        <Link to={`/provider/patients/${props.patient.id}/sessions/create`}>
          <Button variant="outlined">
            <Add />
            {t("careEpisodes:actions.schedule")}
          </Button>
        </Link>
      </Stack>
    </WithPermission>
  );
}

function SummaryBox(props: CareEpisodeDetailsProps): ReactElement {
  const { t } = useTranslation(["common", "careEpisodes"]);
  const [dischargeDialogOpen, updateDischargeDialogOpen] = useState<boolean>(false);

  const end = props.careEpisode.periodEnd ? (
    t("date.medium", { date: props.careEpisode.periodEnd })
  ) : (
    <Typography fontStyle={"italic"}>{t("careEpisodes:notEnded")}</Typography>
  );

  const providerRows = props.careEpisode.careEpisodeProviders.map((provider, i) => (
    <TableRow key={i}>
      <TableCell>{provider.provider.name}</TableCell>
      <TableCell>{humanize(provider.relationship)}</TableCell>
    </TableRow>
  ));

  const summaryCardAction = <CareEpisodeDecisionBadge careEpisode={props.careEpisode} />;

  const dischargeDialog = dischargeDialogOpen ? (
    <CareEpisodeDischargeDialog
      id={props.careEpisode.id}
      closeDialog={() => updateDischargeDialogOpen(false)}
    />
  ) : null;

  return (
    <>
      <Card>
        <CardHeader title={t("careEpisodes:sections.summary")} action={summaryCardAction}></CardHeader>
        <CardContent>
          <Stack spacing={1}>
            <PropertyTable>
              <TableBody>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.name")}</TableCell>
                  <TableCell>{careEpisodeName(props.careEpisode, t)}</TableCell>
                </TableRow>
                <WithFeature feature={"enableTreatmentServices"}>
                  <TableRow>
                    <TableCell>{t("careEpisodes:fields.treatmentService")}</TableCell>
                    <TableCell>
                      {props.careEpisode.treatmentService?.name ||
                        t("careEpisodes:fields.treatmentServiceNotSpecified")}
                    </TableCell>
                  </TableRow>
                </WithFeature>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.start")}</TableCell>
                  <TableCell>{t("date.medium", { date: props.careEpisode.periodStart })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.end")}</TableCell>
                  <TableCell>{end}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.status")}</TableCell>
                  <TableCell>{humanize(props.careEpisode.status)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.measureStarting")}</TableCell>
                  <TableCell>
                    {props.careEpisode.measureStarting
                      ? t("common:date.medium", { date: props.careEpisode.measureStarting })
                      : t("careEpisodes:fields.measureStartingImmediately")}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>{t("careEpisodes:fields.providers")}</TableCell>
                  <TableCell>
                    <Table>
                      <TableBody>{providerRows}</TableBody>
                    </Table>
                  </TableCell>
                </TableRow>
              </TableBody>
            </PropertyTable>
            <CareEpisodeActions
              careEpisode={props.careEpisode}
              patient={props.patient}
              openDischargeDialog={() => updateDischargeDialogOpen(true)}
            />
          </Stack>
        </CardContent>
      </Card>

      {dischargeDialog}
    </>
  );
}

type AssessmentReportReference = Pick<Report, "__typename" | "id">;
type PatientSessionSummary = Pick<PatientSession, "__typename" | "id" | "targetDate" | "status"> & {
  assessmentReport: AssessmentReportReference | null;
};
type SessionRowProps = CareEpisodeDetailsProps & {
  session: PatientSessionSummary;
};

function SessionTableActions(props: SessionRowProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  const reportButton =
    props.session.assessmentReport && props.session.status === PatientSessionStatus.COMPLETE ? (
      <ContextualReportLink
        patient={props.patient.id}
        report={props.session.assessmentReport.id}
        careEpisode={props.careEpisode.id}
      >
        <Button variant="outlined">
          <BarChart />
          {t("careEpisodes:actions.viewReport")}
        </Button>
      </ContextualReportLink>
    ) : null;

  return (
    <Stack direction="row" spacing={1}>
      {reportButton}
      <Link to={`/provider/patients/${props.patient.id}/sessions/${props.session.id}`}>
        <Button variant="outlined">
          <Toc />
          {t("careEpisodes:actions.sessionDetails")}
        </Button>
      </Link>
    </Stack>
  );
}

function MeasurementsTable(props: CareEpisodeDetailsProps): ReactElement {
  const { t } = useTranslation(["common", "careEpisodes"]);

  const sessionColumns: DataGridCols<CareEpisodeSessionsQuery, ["careEpisode", "patientSessions"]> = [
    {
      field: "targetDate",
      headerName: t("careEpisodes:fields.date"),
      flex: 1,
      valueGetter: (_value, row) => t("date.long", { date: row.targetDate }),
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "status",
      headerName: t("careEpisodes:fields.status"),
      flex: 1,
      valueGetter: (_value, row) => humanize(row.status),
      sortable: false,
    },
    {
      field: "actions",
      headerName: t("careEpisodes:fields.actions"),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <SessionTableActions careEpisode={props.careEpisode} patient={props.patient} session={params.row} />
      ),
    },
  ];

  return (
    <Card>
      <CardHeader title="Measurements" />
      <CardContent>
        <Stack spacing={1}>
          <Box>
            <Link to={`/provider/patients/${props.patient.id}/sessions/create`}>
              <Button variant="outlined">
                <Add />
                {t("careEpisodes:actions.schedule")}
              </Button>
            </Link>
          </Box>
          <SortablePagableCollectionDataGrid
            queryHook={useCareEpisodeSessionsQuery}
            unwrapData={(response) => response?.careEpisode?.patientSessions || null}
            queryVariables={{ careEpisodeId: props.careEpisode.id }}
            colNameToSortParam={(column) =>
              column === "targetDate" ? PatientSessionSortParameter.TARGETDATE : null
            }
            defaultPageSize={10}
            defaultSortParams={{
              sortDirection: SortDirection.DESC,
              sortBy: PatientSessionSortParameter.TARGETDATE,
            }}
            columns={sessionColumns}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

function CareEpisodeDetails(props: CareEpisodeDetailsProps): ReactElement {
  return (
    <Stack spacing={1}>
      <SummaryBox {...props} />
      <CareEpisodeDetailsTreatmentTracksBox careEpisodeId={props.careEpisode.id} />
      <CareEpisodeDetailsSettingsBox {...props} />
      <MeasurementsTable {...props} />
    </Stack>
  );
}

export { CareEpisodeDetailsHookWrapper as CareEpisodeDetailsPage, CareEpisodeDetails as Component };
