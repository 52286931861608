import { Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { treatmentServiceInferenceReasonDescriptionT } from "GeneratedGraphQL/EnumTranslations";
import {
  FieldSourceEnum,
  TreatmentService,
  TreatmentServiceInferenceReason,
  useAppointmentAlgorithmDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import Spinner from "Shared/Spinner";
import { resources } from "i18n";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const sourceList: Record<
  FieldSourceEnum,
  `patients:appointments.treatmentServiceSelection.${keyof (typeof resources)["en"]["patients"]["appointments"]["treatmentServiceSelection"]}`
> = {
  [FieldSourceEnum.ALGORITHM]: "patients:appointments.treatmentServiceSelection.algorithmSelected",
  [FieldSourceEnum.EHR]: "patients:appointments.treatmentServiceSelection.ehrSelected",
  [FieldSourceEnum.MANUAL]: "patients:appointments.treatmentServiceSelection.manuallySelected",
  [FieldSourceEnum.UNKNOWN]: "patients:appointments.treatmentServiceSelection.ehrSelected",
};

export function AppointmentTreatmentServiceInferenceTable(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentAlgorithmDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.schedulingAppointment) {
        return (
          <TreatmentServiceInferenceInner
            {...props}
            hideUnknown={props.hideUnknown}
            inferredTreatmentServiceReason={data.schedulingAppointment.inferredTreatmentServiceReason}
            treatmentService={data.schedulingAppointment.treatmentService}
            treatmentServiceSource={data.schedulingAppointment.treatmentServiceSource}
            appointmentId={props.appointmentId}
            patientId={props.patientId}
          />
        );
      } else {
        return null;
      }
    },
    _: () => <Spinner />,
  });
}

function TreatmentServiceInferenceInner(props: {
  treatmentServiceSource: FieldSourceEnum;
  patientId: PatientId;
  appointmentId: AppointmentId;
  treatmentService: Pick<TreatmentService, "id" | "name"> | null;
  inferredTreatmentServiceReason: TreatmentServiceInferenceReason | null;
  hideUnknown?: boolean;
}) {
  const { t } = useTranslation(["patients", "enums"]);

  // For anything except algorithmic details, we just return saying what happened.
  if (props.treatmentServiceSource !== FieldSourceEnum.ALGORITHM) {
    return <Typography>{t(sourceList[props.treatmentServiceSource])}</Typography>;
  }

  const inference = props.inferredTreatmentServiceReason ? (
    <>
      <Typography>
        <b>{t("patients:appointments.treatmentServiceSelection.whyChosen")}</b>
      </Typography>
      <Typography>
        {treatmentServiceInferenceReasonDescriptionT(props.inferredTreatmentServiceReason, t)}
      </Typography>
    </>
  ) : null;

  return <>{inference}</>;
}

export function AppointmentTreatmentServiceInferenceTooltip(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
  children: ReactElement;
}) {
  return (
    <TooltipBase
      content={
        <AppointmentTreatmentServiceInferenceTable
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={props.hideUnknown}
        />
      }
    >
      {props.children}
    </TooltipBase>
  );
}
