const integrations = {
  statusBadge: {
    manuallyCreated: "Manually created by {{user}}. Updated $t(common:date.durationToNow) ago",
    manuallyCreatedNoUser: "Manually created. Updated $t(common:date.durationToNow) ago",
    ehrCreated: "Imported from EHR. Updated $t(common:date.durationToNow) ago",
    ehrCreatedNoDate: "Imported from EHR",
    integrationDetails: "Integration Details",
    algorithmCreated: "Created automatically by the Mirah system",
  },
  integrationLog: {
    title: "Import History for {{ name }}",
    ehrCreated:
      "Imported from EHR. Last updated $t(common:date.tinyWithTime) ($t(common:date.durationToNow) ago)",
    ehrCreatedNoDate: "Imported from EHR",
    algorithmCreated: "Created automatically by the Mirah system",
    neverImported: "Never Successfully Imported",
    records: {
      title: "Records",
      explanation: "Mirah has connected the following records:",
      remoteId: "Remote Id",
      sourceName: "Source",
      lastImportDate: "Last Import Date",
    },
    history: {
      title: "Import History",
      explanation: "Recent imports from the EHR:",
      warning: "Logs older than 90 days are not kept.",
    },
    lastLog: {
      title: "Last Import",
      noLog: "This record has not been updated in the last 90 days.",
      noErrors: "All fields were successfully processed",
      errorsPresent:
        "Some fields could not be understood by the Mirah system. This information may be incorrect:",
    },
    logs: {
      date: "Date",
      status: "Status",
    },
    algorithm: {
      noAlgorithms: "No business logic was run when importing this record",
      algorithmsRun: "The following business logic was run when importing this record:",
    },
    seeHistory: "See Full History",
  },
  dataSourceRecordLogHistory: {
    pageTitle: "Integration History",
    noneSelected: {
      title: "Integration History for {{ name }}",
      body: "Select a row on the left hand side to see more details about a particular sync point. Records are retained for 90 days. Items that have not been updated in the last 90 days will not show any records.",
    },
    filters: {
      status: "Status",
    },
    card: {
      rawData: "Raw Data",
      rawDataExplanation:
        "This is the raw data as received by the Mirah system. This data is transformed into something the Mirah system understands. Errors in synchronization may occur if this data is sent in a format that Mirah does not understand, or if there are elements missing from the data required by Mirah.",
    },
  },
  matches: {
    PATIENT: {
      MATCHED:
        "This remote record was matched to an existing record in the Mirah system on the basis of the patient's MRN or their full name and date of birth, and so the remote record was linked to the existing record and no new records were created",
      UNMATCHED:
        "This remote record did not match any other records in the Mirah system on the basis of MRN or name and Date of Birth, therefore a new record was created",
      INSUFFICIENT_DATA:
        "There was insufficient data to attempt a match on existing records in the Mirah system, therefore a new record was created",
    },
    generic: {
      MATCHED:
        "This remote record was matched to an existing record in the Mirah system and so the remote record was linked to the existing record and no new records were created",
      UNMATCHED:
        "This remote record did not match any other records in the Mirah system therefore a new record was created",
      INSUFFICIENT_DATA:
        "There was insufficient data to attempt a match on existing records in the Mirah system, therefore a new record was created",
    },
  },
};

export default integrations;
