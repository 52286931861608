import { ScaleGroupSummaryFragmentFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { ScaleGroupId } from "Lib/Ids";
import * as Scale from "./Scale";
import { ScaleScorerHistory } from "./ScaleScorerHistory";

export type ScaleGroupBase = {
  id: ScaleGroupId;
};

export type ScaleGroupSummary = ScaleGroupSummaryFragmentFragment;
export type CareEpisodeScaleGroupSummary = {
  scaleGroup: ScaleGroupSummary;
  histories: Array<ScaleScorerHistory>;
};

export type CareEpisodeScaleGroupHistory = {
  scaleGroup: ScaleGroupSummary;
  histories: Array<ScaleScorerHistory>;
};

function roleWithDefault(role: Scale.ScaleRole | null): Scale.ScaleRole {
  return role || Scale.ScaleRole.PER_RESPONDENT_TOTAL;
}

function displayableHistories(history: CareEpisodeScaleGroupSummary): Array<ScaleScorerHistory> {
  const allCombinedTotals = history.histories.filter(
    (sah) => roleWithDefault(sah.scale.role) === Scale.ScaleRole.COMBINED_TOTAL
  );

  const allCombinedSubscale = history.histories.filter(
    (sah) => roleWithDefault(sah.scale.role) === Scale.ScaleRole.COMBINED_SUBSCALE
  );

  const allPerRespondentTotals = history.histories.filter(
    (sah) => roleWithDefault(sah.scale.role) === Scale.ScaleRole.PER_RESPONDENT_TOTAL
  );

  const allPerRespondentSubscale = history.histories.filter(
    (sah) => roleWithDefault(sah.scale.role) === Scale.ScaleRole.PER_RESPONDENT_SUBSCALE
  );

  let histories: Array<ScaleScorerHistory> = [];

  if (allCombinedTotals.length) histories = allCombinedTotals;
  else if (allCombinedSubscale.length) histories = allCombinedSubscale;
  else if (allPerRespondentTotals.length) histories = allPerRespondentTotals;
  else if (allPerRespondentSubscale.length) histories = allPerRespondentSubscale;

  return histories;
}

type scaleWithSubScales = {
  parentScale: ScaleScorerHistory;
  subScales: Array<ScaleScorerHistory>;
};

function groupByDisplayableScale(history: CareEpisodeScaleGroupSummary): Array<scaleWithSubScales> {
  const parentHxs = displayableHistories(history);
  return parentHxs.map((parentScale) => ({
    parentScale,
    subScales: history.histories.filter((hx) => {
      return hx.scale.parentId === parentScale.scale.id;
    }),
  }));
}

export { displayableHistories, groupByDisplayableScale };
