import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Collapse, FormHelperText, Stack } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { Task, TaskStatus, useSetTaskStatusMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type CompleteTaskButtonProps = {
  task: PickTypename<Task, "id" | "status">;
};

export function CompleteTaskButton(props: CompleteTaskButtonProps): ReactElement {
  // When we click a button it gets unmounted and the other get mounted, so in order to avoid a flicker of the button
  // closing and then opening again, we need to flip it to default to starting open.
  const [defaultOpen, setDefaultOpen] = React.useState(false);

  if (props.task.status === TaskStatus.COMPLETE) {
    return <TaskIsAlreadyCompleteButton defaultOpen={defaultOpen} />;
  } else {
    return (
      <MarkTaskCompleteButton
        task={props.task}
        defaultOpen={defaultOpen}
        onClick={() => setDefaultOpen(true)}
      />
    );
  }
}

type MarkTaskCompleteButtonProps = {
  task: PickTypename<Task, "id">;
  defaultOpen: boolean;
  onClick: () => void;
};

function MarkTaskCompleteButton(props: MarkTaskCompleteButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [markComplete, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSetTaskStatus,
    useSetTaskStatusMutation({
      variables: {
        input: {
          taskId: props.task.id,
          status: TaskStatus.COMPLETE,
        },
      },
    })
  );

  const showError = remoteData.kind === "Failure";

  return (
    <Stack direction="row" spacing={0}>
      <Collapse in={showError} orientation="horizontal">
        {/* Using a margin here to space the text from the button rather than spacing on the stack because otherwise
            the TaskIsAlreadyCompleteButton has less spacing next to it since it doesn't have an error message. */}
        <FormHelperText error sx={{ whiteSpace: "nowrap", marginRight: "0.5rem" }}>
          {t("collaborativeCare:tasks.errors.failedComplete")}
        </FormHelperText>
      </Collapse>
      <ExpandingIconButton
        defaultOpen={props.defaultOpen}
        variant="outlined"
        color="secondary"
        icon={<CheckBoxOutlineBlank />}
        onClick={(event) => {
          event.stopPropagation();
          markComplete();
          props.onClick();
        }}
      >
        {t("collaborativeCare:tasks.actions.complete")}
      </ExpandingIconButton>
    </Stack>
  );
}

type TaskIsAlreadyCompleteButtonProps = {
  defaultOpen: boolean;
};

function TaskIsAlreadyCompleteButton(props: TaskIsAlreadyCompleteButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <ExpandingIconButton
      defaultOpen={props.defaultOpen}
      variant="outlined"
      color="secondary"
      icon={<CheckBox />}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {t("collaborativeCare:tasks.alreadyComplete")}
    </ExpandingIconButton>
  );
}
