import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { TimePeriodType } from "GeneratedGraphQL/SchemaAndOperations";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { enumFromStringValue } from "Shared/Enum";
import { FieldMethods, NumberFieldMembers } from "Shared/Form";
import React from "react";
import { useTranslation } from "react-i18next";

type TimePeriodFormProps = {
  timePeriodTypeField: FieldMethods<TimePeriodType, TimePeriodType>;
  timePeriodWeekField: NumberFieldMembers;
  timePeriodMonthField: NumberFieldMembers;
};

function LabelWithIntegerBox(props: { period: string; field: NumberFieldMembers; oneIndexed?: boolean }) {
  const { t } = useTranslation(["outcomes"]);

  // Offset exists because on the backend we have some fields which are 'months since treatment start' which is 0 indexed, but clinicians
  // think in 'months of treatment, which is one-indexed.
  // This leads to the following workaround: if you declare it oneIndexed, it'll catch the event, add or up
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const parsed = Number.parseInt(event.target.value);
    if (!isNaN(parsed) && props.oneIndexed) {
      const updatedTarget = { ...event.target, value: (parsed - 1).toString() };
      props.field.onChange({ ...event, target: updatedTarget });
    } else {
      props.field.onChange(event);
    }
  };

  const parsedValue = props.field.value ? Number.parseInt(props.field.value.toString()) : null;
  const adjustedValue = props.oneIndexed && parsedValue ? parsedValue + 1 : props.field.value;

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{props.period}</span>
        <Input
          sx={{ width: "2em" }}
          type="number"
          inputProps={{ min: 1 }}
          value={adjustedValue}
          onChange={onChange}
        />
        <span>{t("outcomes:create.timePeriod.unit")}</span>
      </Stack>
    </FormControl>
  );
}

export default function TimePeriodForm(props: TimePeriodFormProps) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const enumValue = enumFromStringValue<TimePeriodType>(TimePeriodType, value);
    props.timePeriodTypeField.onChange(enumValue || TimePeriodType.LAST_MEASUREMENT);
  };

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.timePeriod.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.timePeriod.helpText")} />
      <RadioGroup value={props.timePeriodTypeField.value} onChange={onChange}>
        <FormControlLabel
          value={TimePeriodType.LAST_MEASUREMENT}
          control={<Radio />}
          label={t("outcomes:create.timePeriod.lastMeasurement.title")}
        />
        <FormControlLabel
          value={TimePeriodType.MONTH_OF_TREATMENT}
          control={<Radio />}
          label={
            <LabelWithIntegerBox
              period={t("outcomes:create.timePeriod.monthOfTreatment.title")}
              field={props.timePeriodMonthField}
            />
          }
        />
        <FormControlLabel
          value={TimePeriodType.WEEK_OF_TREATMENT}
          control={<Radio />}
          label={
            <LabelWithIntegerBox
              period={t("outcomes:create.timePeriod.weekOfTreatment.title")}
              field={props.timePeriodWeekField}
            />
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
