import palette from "../palette";
import { ComponentsProps, ComponentsOverrides } from "@mui/material";
import { FontSize } from "../typography";

const styleOverrides: ComponentsOverrides["MuiTooltip"] = {
  arrow: {
    fontSize: FontSize.Regular,
    color: palette.common.white,
  },
  tooltip: {
    fontSize: FontSize.Small,
    backgroundColor: palette.common.white,
    color: palette.text.primary,
    // This number is pure magic and chosen to make our largest tooltips fit comfortably. If you find yourself needing
    // to change this, consider going through and giving each tooltip we've designed its own maxWidth as appropriate.
    maxWidth: 490,
    padding: "20px",
    boxShadow: "0px 0px 20px 2px #E8E8E3",
  },
};

const defaultProps: ComponentsProps["MuiTooltip"] = {
  placement: "top",
  arrow: true,
};
const override = {
  defaultProps,
  styleOverrides,
};

export default override;
