import { Box, Button, Grid, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type DiscreetModeBannerProps = {
  setDiscreetMode: (newDiscreetMode: boolean) => void;
};
export function DiscreetModeBanner(props: DiscreetModeBannerProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const handleDisableDiscreetModeClick = () => {
    props.setDiscreetMode(false);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <Stack>
          <Box textAlign={"center"}>{t("dashboard:discreet.text")}</Box>
          <Box textAlign={"center"}>
            <Button
              onClick={handleDisableDiscreetModeClick}
              variant="contained"
              sx={{ width: "15em", marginY: "1em" }}
            >
              {t("dashboard:discreet.button")}
            </Button>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
  );
}
