import { Alert, AlertColor, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { MonitoredJob, MonitoredJobStatus, useMonitoredJobQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { MonitoredJobId } from "Lib/Ids";
import Spinner from "Shared/Spinner";
import { formatDistance } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

// Milliseconds
const MONITOR_POLL_INTERVAL = 10 * 1000;

type MonitoredJobResponsePollProps = {
  monitoredJobId: MonitoredJobId;
  onMonitorSuccess?: () => void;
  onMonitorFailure?: () => void;
};

export function MonitoredJobResponsePoll(props: MonitoredJobResponsePollProps): ReactElement {
  const { remoteData, stopPolling } = apolloQueryHookWrapper(
    useMonitoredJobQuery({ variables: { id: props.monitoredJobId }, pollInterval: MONITOR_POLL_INTERVAL })
  );

  const jobStatus = remoteData.caseOf({
    Success: (response) => {
      if (response.monitoredJob) {
        return response.monitoredJob.status;
      } else {
        return undefined;
      }
    },
    _: () => undefined,
  });

  useEffectSimpleCompare(() => {
    if (jobStatus === MonitoredJobStatus.SUCCEEDED || jobStatus === MonitoredJobStatus.FAILED) {
      stopPolling();
    }

    if (jobStatus === MonitoredJobStatus.SUCCEEDED && props.onMonitorSuccess) {
      props.onMonitorSuccess();
    }

    if (jobStatus === MonitoredJobStatus.FAILED && props.onMonitorFailure) {
      props.onMonitorFailure();
    }
  }, [jobStatus]);

  return remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <Alert severity="error">{err.message}</Alert>,
    Success: (response) => {
      if (response.monitoredJob) {
        return <MonitoredJobResponseDisplay job={response.monitoredJob} />;
      } else {
        return <Alert severity="error">Failed to load task job details</Alert>;
      }
    },
  });
}

type MonitoredJobResponseDisplayProps = {
  job: PickTypename<MonitoredJob, "id" | "status" | "completion" | "logOutput" | "startedAt" | "endedAt">;
};

export function MonitoredJobResponseDisplay(props: MonitoredJobResponseDisplayProps) {
  let severity: AlertColor = "info";
  if (props.job.status === MonitoredJobStatus.SUCCEEDED) {
    severity = "success";
  } else if (props.job.status === MonitoredJobStatus.FAILED) {
    severity = "error";
  }

  // Avoid a permanently flashing progress bar by setting progress to 0 or 1 in terminal states if the job doesn't
  // report progress on it's own.
  let apparentCompletion = props.job.completion;
  if (props.job.status === MonitoredJobStatus.SUCCEEDED) {
    apparentCompletion = apparentCompletion ?? 1.0;
  } else if (props.job.status === MonitoredJobStatus.FAILED) {
    apparentCompletion = apparentCompletion ?? 0.0;
  }

  const progressLabel =
    apparentCompletion === null ? "" : <Typography>{Math.round(apparentCompletion * 100)}%</Typography>;

  return (
    <Alert severity={severity} sx={{ "& .MuiAlert-message": { flexGrow: 1 } }}>
      <Stack direction="column" spacing={1} width="100%" useFlexGap>
        <Stack direction="row" spacing={1} useFlexGap>
          <Typography>Status: {props.job.status}</Typography>
          <MonitoredJobTimeDisplay
            startedAt={props.job.startedAt}
            endedAt={props.job.endedAt}
            completion={props.job.completion}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
          <LinearProgress
            variant={apparentCompletion === null ? "indeterminate" : "determinate"}
            value={(apparentCompletion || 0) * 100}
            sx={{ width: "100%" }}
          />
          {progressLabel}
        </Stack>
        <Divider />
        <Typography>Output:</Typography>
        <Typography marginLeft="1rem" whiteSpace="pre-wrap">
          {props.job.logOutput}
        </Typography>
      </Stack>
    </Alert>
  );
}

type MonitoredJobTimeDisplayProps = {
  startedAt: Date | null;
  endedAt: Date | null;
  completion: number | null;
};

function MonitoredJobTimeDisplay(props: MonitoredJobTimeDisplayProps) {
  const { t } = useTranslation(["common"]);

  if (props.startedAt && props.endedAt) {
    return (
      <Stack direction="row" spacing={0.5} useFlexGap>
        Ran from
        <Typography>{t("common:date.time", { date: props.startedAt })}</Typography>
        to
        <Typography>{t("common:date.time", { date: props.endedAt })}</Typography>
      </Stack>
    );
  } else if (props.startedAt) {
    if (props.completion) {
      const now = new Date();
      const elapsedMillis = now.valueOf() - props.startedAt.valueOf();
      const estimatedTotalMillis = elapsedMillis * (1 / props.completion);
      const estimatedEnd = new Date(props.startedAt.valueOf() + estimatedTotalMillis);

      return (
        <Stack direction="row" spacing={0.5} useFlexGap>
          Started:
          <Typography>{t("common:date.time", { date: props.startedAt })}</Typography>
          Estimated time remaining:
          <Typography>{formatDistance(estimatedEnd, now)}</Typography>
        </Stack>
      );
    } else {
      return (
        <Stack direction="row" spacing={0.5} useFlexGap>
          Started:
          <Typography>{t("common:date.time", { date: props.startedAt })}</Typography>
        </Stack>
      );
    }
  } else {
    return <></>;
  }
}
