import { Alert, AlertColor, Card, CardContent, Skeleton, Stack, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useRunSupportTaskMutation, useTaskDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import ErrorMessage from "Shared/ErrorMessage";
import { Form } from "Shared/Form";
import { ReasonableColumnOfText } from "./SingleColumn";
import {
  abstractToConcreteArgument,
  concreteToInputArgument,
  SupportTaskDetails,
  TaskArgumentInput,
} from "./TaskArguments";
import { RichText } from "MDS/RichText";
import { MonitoredJobId } from "Lib/Ids";
import { MonitoredJobResponsePoll } from "Ops/MonitoredJob";

export function TaskForm(): ReactElement {
  const params = useParams();
  const { remoteData } = apolloQueryHookWrapper(
    useTaskDetailsQuery({ variables: { shortcode: params.taskShortcode || "" } })
  );

  const content = remoteData.caseOf({
    NotAsked: () => <TaskFormSkeleton />,
    Loading: () => <TaskFormSkeleton />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.supportTask === null) {
        return <ErrorMessage message={`No support task with shortcode ${params.taskShortcode}`} />;
      } else {
        return <TaskFormContent task={response.supportTask} />;
      }
    },
  });

  return (
    <Page
      browserTitle="Mirah Task Runner"
      breadcrumbs={[
        <Link to="../.." key={0}>
          Ops Tools
        </Link>,
        <Link to=".." key={1}>
          Support Task List
        </Link>,
      ]}
    >
      <Card>
        <CardContent>{content}</CardContent>
      </Card>
    </Page>
  );
}

function TaskFormSkeleton(): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <Skeleton width={40} />
      <Skeleton width={40} />
      <Skeleton width={40} />
      <Skeleton width={40} />
    </Stack>
  );
}

type TaskFormContentProps = {
  task: SupportTaskDetails;
};

type RunStrategy = "DRY_RUN" | "REAL_RUN";

function TaskFormContent(props: TaskFormContentProps): ReactElement {
  const args = props.task.arguments.map(abstractToConcreteArgument);

  const checkValid = () => {
    let valid = true;
    args.forEach((arg) => {
      if (
        (arg.required && arg.value?.toString().trim().length === 0) ||
        (arg.required && arg.value === undefined)
      ) {
        arg.setErrors([`${arg.name} is required`]);
        valid = false;
      }
    });

    return valid;
  };

  const [runStrategy, setRunStrategy] = React.useState<RunStrategy | undefined>(undefined);
  const [completedDryRun, setCompletedDryRun] = React.useState<boolean>(false);

  const [runTask, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.supportRunTask,
    useRunSupportTaskMutation()
  );

  const runTaskWithArguments = (dryRun: boolean) => {
    setRunStrategy(dryRun ? "DRY_RUN" : "REAL_RUN");

    if (checkValid()) {
      runTask({
        variables: {
          input: {
            shortcode: props.task.shortcode,
            dryRun: dryRun,
            arguments: args.map(concreteToInputArgument),
          },
        },
      });
    }
  };

  const response = remoteData.caseOf({
    NotAsked: () => null,
    Loading: () => null,
    Failure: (err) =>
      err.caseOf({
        userError: (errors, result) => ({
          success: false,
          message: [...errors.map((e) => e.message), result?.result],
          log: result?.log,
          url: null,
          urlContainsPhi: false,
          monitoredJobId: null,
        }),
        apolloError: (err) => ({
          success: false,
          message: [err.message],
          log: undefined,
          url: null,
          urlContainsPhi: false,
          monitoredJobId: null,
        }),
      }),
    Success: (response) => ({
      success: true,
      message: [response?.result],
      log: response?.log,
      url: response?.url,
      urlContainsPhi: response?.urlContainsPhi,
      monitoredJobId: response?.monitoredJobId,
    }),
  });

  const loading = remoteData.caseOf({
    Loading: () => true,
    _: () => false,
  });

  remoteData.caseOf({
    Success: (_) => {
      if (runStrategy === "DRY_RUN" && !completedDryRun) {
        setCompletedDryRun(true);
      }
    },
    _: () => {
      return;
    },
  });

  let downloadLink = null;

  if (response?.url) {
    const link = (
      <a href={response.url} target="_blank" rel="noreferrer">
        Download File
      </a>
    );

    const phiWarning = response.urlContainsPhi ? (
      <span>
        This data contains PHI and access will be audited. Please look for an email from IT explaining what
        you need to do.
      </span>
    ) : null;

    downloadLink = (
      <Stack spacing={0.5}>
        {phiWarning}
        {link}
      </Stack>
    );
  }

  return (
    <Form>
      <ReasonableColumnOfText direction="column" spacing={2}>
        <Stack spacing={0.5}>
          <Typography variant="h1">{props.task.name}</Typography>
          <Typography variant="body1">{props.task.description}</Typography>
        </Stack>
        {args.map((argument, i) => {
          return <TaskArgumentInput argument={argument} key={i} />;
        })}
        <TaskResponseDisplay response={response} />

        {downloadLink}

        <ButtonWithSpinner
          variant="contained"
          disabled={loading}
          fullWidth
          onClick={() => runTaskWithArguments(true)}
          showSpinner={loading && runStrategy === "DRY_RUN"}
        >
          Dry Run
        </ButtonWithSpinner>
        <ButtonWithSpinner
          variant="contained"
          disabled={!completedDryRun || loading}
          fullWidth
          onClick={() => runTaskWithArguments(false)}
          showSpinner={loading && runStrategy === "REAL_RUN"}
        >
          Run
        </ButtonWithSpinner>
        <Typography variant="body1" whiteSpace="pre">
          <span style={{ display: "block" }}>Debug Log:</span>
          {response?.log}
        </Typography>
      </ReasonableColumnOfText>
    </Form>
  );
}

type TaskResponseDisplayProps = {
  response: {
    success: boolean;
    message: ReadonlyArray<string | null | undefined>;
    monitoredJobId: MonitoredJobId | null | undefined;
  } | null;
};

function TaskResponseDisplay(props: TaskResponseDisplayProps): ReactElement {
  if (props.response?.monitoredJobId) {
    return <MonitoredJobResponsePoll monitoredJobId={props.response.monitoredJobId} />;
  } else {
    return <TaskResponseMessageDisplay response={props.response} />;
  }
}

function TaskResponseMessageDisplay(props: TaskResponseDisplayProps) {
  let messageSeverity: AlertColor = "info";
  if (props.response) {
    if (props.response.success) {
      messageSeverity = "success";
    } else {
      messageSeverity = "error";
    }
  }

  return (
    <Alert severity={messageSeverity}>
      <Stack direction="column" spacing={0.5}>
        {props.response?.message.map((line, i) => (
          <RichText key={i}>{line}</RichText>
        ))}
      </Stack>
    </Alert>
  );
}
