import { Skeleton, Stack } from "@mui/material";
import { FeedbackReportContext } from "FeedbackReport/FeedbackReportContext";
import { loadFeedbackReportCareEpisode } from "FeedbackReport/FeedbackReportRoute";
import Constructs from "FeedbackReport/OverviewPane/Constructs";
import { loadConstructs, loadTreatmentResponse } from "FeedbackReport/OverviewPane/OverviewPane";
import SpecialSections from "FeedbackReport/OverviewPane/SpecialSections";
import { TreatmentResponse } from "FeedbackReport/OverviewPane/TreatmentResponse";
import { CareEpisodeId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function CareEpisodeSurveyHistory(props: {
  careEpisodeId: CareEpisodeId;
  forPreviousEnrollment?: boolean;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const remoteData = loadFeedbackReportCareEpisode(props.careEpisodeId);
  return remoteData.caseOf({
    NotAsked: () => <ConstructsLoading />,
    Loading: () => <ConstructsLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (feedbackReportCareEpisode) => {
      return feedbackReportCareEpisode.caseOf({
        NoAdministrations: () =>
          props.forPreviousEnrollment ? (
            <ErrorMessage message={t("collaborativeCare:patientDetails.surveyHistory.noAdministrations")} />
          ) : (
            <ErrorMessage
              message={t(
                "collaborativeCare:patientDetails.surveyHistory.previousEnrollmentNoAdministrations"
              )}
            />
          ),
        WithAdministrations: (patient, careEpisode, careTeam, patientConditions, feedbackReportContext) => {
          return (
            <CareEpisodeConstructs
              careEpisodeId={props.careEpisodeId}
              feedbackReportContext={feedbackReportContext}
            />
          );
        },
      });
    },
  });
}

type CareEpisodeConstructsProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

function CareEpisodeConstructs(props: CareEpisodeConstructsProps): ReactElement {
  const [specialSections, constructs] = loadConstructs(props.careEpisodeId, props.feedbackReportContext);
  const treatmentResponse = loadTreatmentResponse(props.careEpisodeId, props.feedbackReportContext);

  return (
    <Stack direction="column" spacing={0}>
      <TreatmentResponse
        treatmentResponseData={treatmentResponse}
        feedbackReportContext={props.feedbackReportContext}
        hideTitle
      />
      <SpecialSections
        specialSections={specialSections}
        feedbackReportContext={props.feedbackReportContext}
        hideTitle
      />
      <Constructs constructs={constructs} feedbackReportContext={props.feedbackReportContext} hideTitle />
    </Stack>
  );
}

export function ConstructsLoading(): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <Skeleton width="10rem" />
        <Skeleton width="5rem" />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton width="10rem" />
        <Skeleton width="5rem" />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Skeleton width="10rem" />
        <Skeleton width="5rem" />
      </Stack>
    </Stack>
  );
}
