import { PatientQualityIssue, PatientQualityIssueStatus } from "GeneratedGraphQL/SchemaAndOperations";

export type PatientQualityIssueSuperStatus = "active" | "pending" | "resolved";

const statusMap: Record<PatientQualityIssueSuperStatus, ReadonlyArray<PatientQualityIssueStatus>> = {
  active: [PatientQualityIssueStatus.UNRESOLVED],
  pending: [PatientQualityIssueStatus.FIXED_IN_EHR, PatientQualityIssueStatus.SNOOZE],
  resolved: [
    PatientQualityIssueStatus.NOT_AN_ISSUE,
    PatientQualityIssueStatus.RESOLVED,
    PatientQualityIssueStatus.WONT_FIX,
    PatientQualityIssueStatus.OUT_OF_TREATMENT,
  ],
};

function superStatusToStatusMap(
  superStatus: PatientQualityIssueSuperStatus | null
): ReadonlyArray<PatientQualityIssueStatus> | null {
  if (superStatus) {
    return statusMap[superStatus];
  } else {
    return null;
  }
}

function statusToSuperStatus(status: PatientQualityIssueStatus): PatientQualityIssueSuperStatus {
  switch (status) {
    case PatientQualityIssueStatus.UNRESOLVED:
      return "active";
    case PatientQualityIssueStatus.FIXED_IN_EHR:
    case PatientQualityIssueStatus.SNOOZE:
      return "pending";
    case PatientQualityIssueStatus.NOT_AN_ISSUE:
    case PatientQualityIssueStatus.OUT_OF_TREATMENT:
    case PatientQualityIssueStatus.RESOLVED:
    case PatientQualityIssueStatus.WONT_FIX:
      return "resolved";
  }
}

// Put active issues at the top, followed by pending, then resolved
function superStatusSortOrder(superStatus: PatientQualityIssueSuperStatus) {
  switch (superStatus) {
    case "active":
      return "A";
    case "pending":
      return "B";
    case "resolved":
      return "C";
  }
}

function qualityIssueSortString(issue: Pick<PatientQualityIssue, "status" | "resolvedAt">) {
  const superStatus = statusToSuperStatus(issue.status);

  return `${superStatusSortOrder(superStatus)}--${issue.resolvedAt}`;
}

const issueCountbySuperStatus =
  (superStatus: PatientQualityIssueSuperStatus) =>
  (issues: ReadonlyArray<Pick<PatientQualityIssue, "status">>): number => {
    return issues.reduce((count, issue) => {
      if (statusToSuperStatus(issue.status) === superStatus) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  };

/**
 * Get the number of active issues from an array
 */
const activeIssueCount = issueCountbySuperStatus("active");

/**
 * Get the number of active issues from an array
 */
const pendingIssueCount = issueCountbySuperStatus("pending");

export {
  qualityIssueSortString,
  superStatusToStatusMap,
  statusToSuperStatus,
  activeIssueCount,
  pendingIssueCount,
  statusMap,
};
