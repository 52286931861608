import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useImplementationTargetWithReportDateDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { ImplementationTargetId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ChangeEvent, useState } from "react";
import { TargetDetails } from "./ImplementationTargetCard";
import Page from "Layout/Page";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import DatePicker from "Shared/DatePickers";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyBooleanParameter,
  useStickyDateParameter,
} from "Shared/StickyParameter";
import { useLocation, useNavigate } from "react-router-dom";
import { add, startOfDay, startOfWeek } from "date-fns";
import { WithPermission } from "Shared/WithPermission";
import { ImplementationTargetFullReport } from "./ImplementationTargetFullReport";
import { RetireImplementationTargetButton } from "./RetireImplementationTargetButton";
import { STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import { IncompleteReportWarning } from "./ImplementationTargetElements";
import Link from "MDS/Link";

function MissingReportCard(props: { firstReportDate: Date }) {
  const { t } = useTranslation(["implementation"]);
  return (
    <Card>
      <CardHeader title={t("implementation:details.missingReportHeader")} />
      <CardContent>
        <Typography>
          {t("implementation:details.missingReportBody", { date: props.firstReportDate })}
        </Typography>
      </CardContent>
    </Card>
  );
}

function ImplementationTargetDetails(props: {
  target: TargetDetails;
  activeDate: Date;
  setActiveDate: (newDate: Date) => void;
  defaultDate: Date;
}) {
  const { target } = props;
  const { t } = useTranslation(["implementation"]);
  const [nextWeekStart] = useState<Date>(add(startOfWeek(new Date()), { weeks: 1 }));

  const [hideProviderInformation, setHideProviderInformation] = useStickyBooleanParameter(
    "hideProvider",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    false,
    true
  );

  const navigate = useNavigate();
  const { search } = useLocation();

  const onEditTargetClickHandler = () => {
    navigate(`edit${search}`);
  };

  const handleChangeActiveDate = (newValue: Date) => {
    props.setActiveDate(startOfDay(newValue));
  };

  const reportContent = target.closestReportDateTo ? (
    <ImplementationTargetFullReport
      target={props.target}
      entityTreeNode={props.target.entityTreeNode}
      closestReportDateTo={target.closestReportDateTo}
      hideProviderInformation={hideProviderInformation}
    />
  ) : (
    <MissingReportCard firstReportDate={target.startDate} />
  );

  const breadcrumbs = [
    <Link key="implementation-breadcrumb" to="/app/cocm/implementation">
      {t("implementation:dashboard.title")}
    </Link>,
    <Typography key="target-breadcrumb">{target.name}</Typography>,
  ];

  return (
    <Page browserTitle={t("implementation:details.title")} breadcrumbs={breadcrumbs}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box minWidth="15em">
              <DatePicker
                label={t("implementation:dashboard.weekEnding")}
                views={["month", "year", "day"]}
                openTo={"day"}
                value={props.activeDate}
                defaultValue={props.defaultDate}
                onChange={(newValue: Date) => handleChangeActiveDate(newValue)}
                maxDate={nextWeekStart}
                shouldDisableDate={(date) => {
                  // Only select Sundays
                  return date.getDay() !== 0;
                }}
              />
            </Box>
            <FormControl>
              <FormControlLabel
                control={<Checkbox />}
                label={t("implementation:hideProviderInformation")}
                checked={hideProviderInformation}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setHideProviderInformation(event.target.checked)
                }
              />
            </FormControl>

            <Box>
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  props.setActiveDate(props.defaultDate);
                }}
              />
            </Box>
            <Box flexGrow={1} />
            <WithPermission permission="editImplementationTargets">
              <RetireImplementationTargetButton target={props.target} onSuccess={() => navigate("..")} />
              <Button variant="contained" color="secondary" onClick={onEditTargetClickHandler}>
                {t("implementation:details.editTarget")}
              </Button>
            </WithPermission>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <IncompleteReportWarning endDate={props.activeDate} />
        </Grid>
        <Grid item xs={12}>
          {reportContent}
        </Grid>
      </Grid>
    </Page>
  );
}

export default function ImplementationTargetDetailsWrapper(props: { id: ImplementationTargetId }) {
  const { t } = useTranslation(["common"]);

  // We need the date state hoisted up to the top because it's used in the query to work out what report to show.
  const [thisWeekStart] = useState<Date>(startOfWeek(new Date(), { weekStartsOn: 0 }));

  const [activeDate, setActiveDate] = useStickyDateParameter(
    "activeDate",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    thisWeekStart,
    true
  );

  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetWithReportDateDetailsQuery({
      variables: {
        implementationTargetId: props.id,
        date: activeDate,
      },
    })
  );

  return remoteData.caseOf({
    Success: (result) => {
      const target = result.implementationTarget;
      if (target) {
        return (
          <ImplementationTargetDetails
            target={target}
            activeDate={activeDate}
            setActiveDate={setActiveDate}
            defaultDate={thisWeekStart}
          />
        );
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
  });
}
