import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import Page from "Layout/Page";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Form, useRedirectOnSuccess } from "Shared/Form";
import { TreatmentTrackStatus, useCreateTreatmentTrackMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { enumToOptions, optionToEnum } from "Shared/Enum";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";

function CreateTreatmentTrack(): ReactElement {
  // We're setting up some patterns here that we might extrapolate out later.
  // Here, we have the actual property, but also whether or not it's ever been changed.
  // This lets us present a textfield that does not default to an error state.
  // We additionally have a validator for it, which is a simple function.
  const [name, setName] = useState("");
  const [nameDirty, setNameDirty] = useState(false);
  const isNameValid = () => {
    if (name.trim().length === 0) {
      return false;
    }
    return true;
  };

  // Here we have a handler for when changes to the name come in. Note that
  // any change will irreversibly set dirty to be on so we'll start showing errors.
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameDirty(true);
  };

  // Status is much simpler and has no validity, as it's purely a select box.
  // We could always write it in but it's fine for common use cases.
  const [status, setStatus] = useState(TreatmentTrackStatus.ACTIVE);
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.textContent) {
      setStatus(optionToEnum(TreatmentTrackStatus, event.target.textContent));
    }
  };

  // Lastly, we have some form-wide elements.

  // Here we check if the entire form is valid or not. If we had more validators
  // above, this would check all of them.
  // We actually disable the create button if the entire form is not valid.
  const isFormValid = () => {
    return isNameValid();
  };

  // We also need our actual submit call back to the server. It uses the properties
  // created above as the params.
  const [createTreatmentTrack, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.careCreateTreatmentTrack,
    useCreateTreatmentTrackMutation({
      variables: {
        input: {
          name: name,
          status: status,
        },
      },
    })
  );

  // We also need somethign to handle clicking the actual
  // create button. We check form validity again here, though with
  // the button disabled it's probably not necessary but a good standard pattern
  // to use.
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isFormValid()) {
      createTreatmentTrack();
    }
  };

  const { t } = useTranslation(["settings"]);

  const failed = remoteData.caseOf({
    Failure: () => true,
    _: () => false,
  });

  const loading = remoteData.caseOf({
    Loading: () => true,
    _: () => false,
  });

  // Lastly, we should redirect back to the track list upon success.
  // The . actually takes us back to the index page for this resource.
  useRedirectOnSuccess(
    remoteData,
    "/app/settings/provider/admin/configuration/treatmenttrack/",
    useNavigate()
  );

  return (
    <Page browserTitle={t("settings:treatmentTrack.createTreatmentTrack.title")}>
      <Card>
        <CardHeader title={t("settings:treatmentTrack.createTreatmentTrack.title")} />
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={1} alignItems="flex-start">
              <TextField
                variant="outlined"
                label={t("settings:treatmentTrack.createTreatmentTrack.name")}
                value={name}
                onChange={handleNameChange}
                error={!isNameValid() && nameDirty} // This is where we slip in dirty to prevent showing an error right away.
              />
              <Autocomplete
                options={enumToOptions(TreatmentTrackStatus)}
                value={status}
                sx={{ minWidth: 189 }} // Experimentally determined.
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("settings:treatmentTrack.createTreatmentTrack.status")}
                    variant="outlined"
                  />
                )}
                onChange={handleStatusChange}
              />
              {failed ? (
                <Typography color={useTheme().palette.error.main}>
                  {t("settings:treatmentTrack.createTreatmentTrack.error")}
                </Typography>
              ) : (
                <></>
              )}
              <Stack direction="row" spacing={1}>
                <ButtonWithSpinner
                  variant="contained"
                  type="submit"
                  disabled={!isFormValid() || loading}
                  showSpinner={loading}
                >
                  {t("settings:treatmentTrack.createTreatmentTrack.create")}
                </ButtonWithSpinner>
                <Button variant="outlined" type="input" href=".">
                  {t("settings:treatmentTrack.createTreatmentTrack.cancel")}
                </Button>
              </Stack>
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Page>
  );
}

export default CreateTreatmentTrack;
