import { Chip, Stack } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  FlagSelectQueryVariables,
  useFlagFetchQuery,
  useFlagSelectLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { FlagId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { BlankIcon } from "MDS/Icons";
import { fontAwesomeToMuiIcon } from "Providers/Dashboard/PatientFlags";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { QueryAutocompleteMultiple } from "Shared/QueryAutocomplete";

type FlagMultiSelectProps = {
  value: ReadonlyArray<FlagId> | null;
  setValue: (newValue: ReadonlyArray<FlagId> | null) => void;
};

export type FlagOptionType = { id: FlagId; name: string; icon: string };

export default function FlagMultiSelect(props: FlagMultiSelectProps): ReactElement {
  const { t } = useTranslation();
  const { setValue } = props;
  const queryVars: Omit<FlagSelectQueryVariables, "search"> = {
    first: 30,
  };

  // We only want to use the query to get the existing data once. After that, it'll be available
  // from the query we have in hand. If we don't do this, we'll get a flashing effect because every time
  // you change a flag it'll refetch the query for the values.
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // In cases where we are given an id as part of a query string etc we need to fetch
  // the name of the option no matter what it is rather than relying on the search.
  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useFlagFetchQuery({
      variables: {
        ids: assertNonNull(props.value), // Apollo will only call if value is present
      },
      skip: !firstLoad || !props.value,
    })
  );

  const value = props.value
    ? currentValueRemoteData.caseOf({
        Success: (result) => {
          if (result.flags) {
            return [...result.flags.nodes];
          } else if (props.value) {
            return props.value.map((id) => {
              return {
                id,
                name: "Loading",
                icon: "ignored",
              };
            });
          }
          return null;
        },
        _: () => {
          if (props.value) {
            return props.value.map((id) => {
              return {
                id,
                name: "Loading",
                icon: "ignored",
              };
            });
          }
          return null;
        },
      })
    : null;

  return (
    <QueryAutocompleteMultiple
      valueUpdated={(value) => {
        setFirstLoad(false);
        setValue(value.map((selected) => selected.id));
      }}
      error={false}
      required={false}
      helperText=""
      value={value || []}
      queryVariables={queryVars}
      query={useFlagSelectLazyQuery}
      unwrapResponse={(response) => response.flags?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={t("flags.title")}
      autocompleteProps={{
        noOptionsText: t("flags.noMatching"),
        getOptionLabel: (option) => option.name,
        renderOption: (props, option, _state) => {
          return (
            <li {...props}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {fontAwesomeToMuiIcon[option.icon]?.icon || <BlankIcon />}
                <span>{option.name}</span>
              </Stack>
            </li>
          );
        },
        renderTags: (value, getTagProps, _state) => {
          return value.map((flag, i) => {
            const props = getTagProps({ index: i });
            return <Chip icon={fontAwesomeToMuiIcon[flag.icon]?.icon || <BlankIcon />} {...props} />;
          });
        },
      }}
    />
  );
}
