import React, { ReactNode, ReactElement } from "react";
import LevelHeader from "../LevelHeader";
import { Box } from "@mui/material";

type Props = {
  label: string;
  children?: ReactNode;
};

function DetailPaneLevelHeader(props: Props): ReactElement {
  const { label, children } = props;

  return (
    <LevelHeader>
      {label}
      <Box sx={{ ml: 0.75 }}>{children}</Box>
    </LevelHeader>
  );
}

export default DetailPaneLevelHeader;
