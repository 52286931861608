import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement } from "react";
import { displayNameWithRelationship, ParticipantSummary } from "Shared/Participant";
import ParticipantAvatar from "./ParticipantAvatar";

const StyledParticipantWrapper = styled("span")(() => ({
  display: "inline-flex",
}));

type Props = {
  participant: ParticipantSummary;
  displayRelationship: boolean;
};

function ParticipantAvatarAndName(props: Props): ReactElement {
  const { participant, displayRelationship } = props;
  return (
    <StyledParticipantWrapper key={participant.participantId.toString()}>
      <Box sx={{ mr: 0.5 }}>
        <ParticipantAvatar participant={participant} />
      </Box>
      {displayRelationship ? displayNameWithRelationship(participant) : participant.name}
    </StyledParticipantWrapper>
  );
}

export default ParticipantAvatarAndName;
