export default {
  success: {
    title: "Redirecting you now",
    subtitle: "You will be redirected to the patient's page now.",
  },
  failure: {
    title: "An error has occured",
    subtitle: "Please try again. If this problem persists please contact support@mirah.com",
  },
  loading: {
    title: "Redirecting you",
    subtitle: "You will be redirected to the patient's page shortly.",
  },
  error: {
    title: "An error has occurred",
    subtitle: "Please refresh the page. If this problem persists please contact support@mirah.com",
  },
  noId: {
    title: "There is an error with your URL",
    subtitle:
      "If you see this message after clicking on a link from your EMR, it is likely your EMR is not configured correctly. Please contact support@mirah.com",
  },
  patientNotFound: {
    title: "No Patient Found",
    subtitle:
      "No patient with that identifier was found in our system. The patient may not have been imported into Mirah. Contact support@mirah.com",
  },
  remoteCreatePatient: {
    noId: {
      title: "A patient was requested but no identifier was supplied.",
      subtitle: "Please try launching Mirah from your EHR again",
    },
    error: {
      title: "An error occurred while launching this patient.",
      subtitle: "Please try launching Mirah from your EHR again",
    },
    loading: {
      title: "We are importing details of this patient from the EMR",
      subtitle:
        "This is the first time Mirah has seen this particular patient. Please wait while we load details of the patient from the EMR. This page will automatically refresh when the patient is ready",
    },
  },
};
