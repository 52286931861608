import { BarChart, Numbers, TrendingUp } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import {
  CategoricalScaleScorer,
  EntityTreeNodeParams,
  NumericalScaleScorer,
  ScaleScorerConfigurationQuery,
  ScaleScorerDomain,
  useScaleScorerConfigurationQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ScaleScorerId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import { SeverityCategoryChip } from "./SeverityHelpers";
import React from "react";
import ScaleScorerWithUsageSelect from "Shared/Filters/ScaleScorerWithUsageSelect";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { assertNonNull } from "Lib/Utils";

type NumericalScaleDetailsProps = Pick<
  NumericalScaleScorer,
  | "minValue"
  | "maxValue"
  | "minInterpretation"
  | "maxInterpretation"
  | "thresholdData"
  | "supportedSeverityCategories"
  | "supportedTrends"
>;
function NumericalScaleDetails(props: { config: NumericalScaleDetailsProps }) {
  const { minValue, maxValue, minInterpretation, maxInterpretation } = props.config;
  const { t } = useTranslation(["outcomes"]);

  const scoringList = (
    <ListItem>
      <ListItemIcon>
        <Numbers />
      </ListItemIcon>
      <ListItemText>
        <Typography variant="body1" display="inline">
          {t("outcomes:scaleScorerDetails.scoredBetween")}
        </Typography>
        <Typography variant="h3" display="inline">
          {minValue} {minInterpretation ? `(${minInterpretation})` : ""}
        </Typography>
        <Typography variant="body1" display="inline">
          {t("outcomes:scaleScorerDetails.and")}
        </Typography>
        <Typography variant="h3" display="inline">
          {maxValue} {maxInterpretation ? `(${maxInterpretation})` : ""}
        </Typography>
      </ListItemText>
    </ListItem>
  );

  const severityList =
    props.config.supportedSeverityCategories.length > 0 ? (
      <ListItem>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText>
          {t("outcomes:scaleScorerDetails.supportedSeverities")}
          <Stack direction={"row"} spacing={1}>
            {props.config.supportedSeverityCategories.map((s) => (
              <SeverityCategoryChip key={s} severityCategory={s} />
            ))}
          </Stack>
        </ListItemText>
      </ListItem>
    ) : (
      <ListItem>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.noSupportedSeverities")}</ListItemText>
      </ListItem>
    );

  const trendList =
    props.config.supportedTrends.length > 0 ? (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.supportedTrends")}</ListItemText>
      </ListItem>
    ) : (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.noTrends")}</ListItemText>
      </ListItem>
    );

  return (
    <List>
      {scoringList}
      {severityList}
      {trendList}
    </List>
  );
}

type CategoricalScaleDetailsProps = Pick<
  CategoricalScaleScorer,
  "supportedSeverityCategories" | "supportedTrends"
>;
function CategoricalScaleDetails(props: { config: CategoricalScaleDetailsProps }) {
  const { supportedSeverityCategories, supportedTrends } = props.config;
  const { t } = useTranslation(["outcomes"]);

  const scoringList = (
    <ListItem>
      <ListItemIcon>
        <Numbers />
      </ListItemIcon>
      <ListItemText>{t("outcomes:scaleScorerDetails.categoricalScale")}</ListItemText>
    </ListItem>
  );

  const severityList =
    supportedSeverityCategories.length > 0 ? (
      <ListItem>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText>
          {t("outcomes:scaleScorerDetails.supportedSeverities")}
          <Stack direction={"row"} spacing={1}>
            {props.config.supportedSeverityCategories.map((s) => (
              <SeverityCategoryChip key={s} severityCategory={s} />
            ))}
          </Stack>
        </ListItemText>
      </ListItem>
    ) : (
      <ListItem>
        <ListItemIcon>
          <BarChart />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.noSupportedSeverities")}</ListItemText>
      </ListItem>
    );

  const trendList =
    supportedTrends.length > 0 ? (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.supportedTrends")}</ListItemText>
      </ListItem>
    ) : (
      <ListItem>
        <ListItemIcon>
          <TrendingUp />
        </ListItemIcon>
        <ListItemText>{t("outcomes:scaleScorerDetails.noTrends")}</ListItemText>
      </ListItem>
    );

  return (
    <List>
      {scoringList}
      {severityList}
      {trendList}
    </List>
  );
}

type ScaleDetails = NonNullable<ScaleScorerConfigurationQuery["assessmentScaleScorer"]>;

function ScaleDetails(props: { config: ScaleDetails }) {
  const { t } = useTranslation(["outcomes"]);
  if (props.config.__typename === "NumericalScaleScorer") {
    return <NumericalScaleDetails config={props.config} />;
  } else if (props.config.__typename === "CategoricalScaleScorer") {
    return <CategoricalScaleDetails config={props.config} />;
  }

  return <Typography>{t("outcomes:scaleScorerDetails.unscoredScale")}</Typography>;
}

export default function ScaleScorerDetails(props: {
  scaleScorerId: ScaleScorerId | null;
  onChange: (value: ScaleScorerId | null) => void;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
}) {
  const { t } = useTranslation(["outcomes", "common"]);

  const { remoteData } = apolloQueryHookWrapper(
    useScaleScorerConfigurationQuery({
      skip: !props.scaleScorerId,
      variables: { id: assertNonNull(props.scaleScorerId) },
    })
  );
  const details = remoteData.caseOf({
    Success: (config) => {
      if (config.assessmentScaleScorer) {
        return <ScaleDetails config={config.assessmentScaleScorer} />;
      }
      return <Typography>{t("common:unexpectedError")}</Typography>;
    },
    Loading: () => <Spinner />,
    NotAsked: () => (
      <Typography textAlign={"center"}>{t("outcomes:create.scale.selectForDetails")}</Typography>
    ),
    Failure: (error) => <ErrorMessage message={error.message} />,
  });

  return (
    <Card>
      <CardHeader title={t("outcomes:create.scale.title")} />
      <CardContent>
        <Stack direction="column" spacing={2.5}>
          <ScaleScorerWithUsageSelect
            domains={[ScaleScorerDomain.RAW, ScaleScorerDomain.INTERPRETED]}
            setValue={props.onChange}
            value={props.scaleScorerId}
            entityTreeNode={props.entityTreeNode}
            startDate={props.startDate}
            endDate={props.endDate}
          />
        </Stack>
        {details}
      </CardContent>
    </Card>
  );
}
