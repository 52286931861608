import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { apolloMutationHookWrapper, MutationRemoteDataResult } from "Api/GraphQL";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { GoalStatus, GoalType, useCreateGoalMutationMutation } from "GeneratedGraphQL/SchemaAndOperations";
import * as Id from "Lib/Id";
import { UserId } from "Lib/Ids";
import React, { ChangeEvent, FormEvent, ReactElement } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import { clientParticipants, isPatient } from "Shared/Participant";
import { ACTIVE_GOAL_TYPES, GOAL_TYPE_CONFIGURATION } from "Shared/Scale/Goal";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { Form, FormOverlay, useRedirectOnSuccess } from "Shared/Form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { refetchQueries } from "Lib/RefetchQueries";

type CreateGoalFormProps = {
  feedbackReportContext: FeedbackReportContext;
  onSuccessRoute?: Routing.FeedbackReportRoute;
};

export function CreateGoalForm(props: CreateGoalFormProps): ReactElement {
  const { t } = useTranslation(["report"]);
  const nav = useNavigate();

  const [goalType, setGoalType] = React.useState<GoalType>(GoalType["TOP_PROBLEM"]);
  const handleGoalTypeInput = (event: SelectChangeEvent<HTMLSelectElement>) => {
    // Both here and in handleRespondentInput we're basically breaking the type checking to cast event.target.value
    // into the desired type. This is safe as long as we only use valid members of the type as attributes in the select,
    // and neccesary since <option> elements by definition can't know about our types so everything has to be a bare
    // string by the time it lands in the DOM. Just remember that if you do a bug in the rendering code it won't show
    // up as a type here, but as a spooky runtime error.
    setGoalType(GoalType[event.target.value as keyof typeof GoalType]);
  };

  const defaultRespondent = props.feedbackReportContext.participants.toArray().find(isPatient);
  if (defaultRespondent === undefined) {
    return <ErrorMessage message={t("report:errors.noPatient")} />;
  }

  const [respondent, setRespondent] = React.useState<UserId>(defaultRespondent.user.id);
  const handleRespondentInput = (event: SelectChangeEvent<HTMLSelectElement>) => {
    setRespondent(Id.unsafeFromUuid<"User">(event.target.value.toString()));
  };

  // I'm intentionally decoupling the error boolean here from just looking at the value so that we can not have the
  // form load into an error state. This will only put the input into an error state if the user enters some text and
  // then deletes it. Note that the other fields don't have any error tracking because the type checking should make it
  // impossible to put them in an invalid state.
  const [goalText, setGoalText] = React.useState<string>("");
  const [goalTextError, setGoalTextError] = React.useState<boolean>(false);
  const handleGoalTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    setGoalText(event.target.value);
    if (event.target.value.trim().length === 0) {
      setGoalTextError(true);
    }
  };

  // The other fields are all type checked, so we shouldn't be able to get them into invalid states.
  const formValid = goalText.trim().length > 0;

  const [createGoal, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.assessmentCreateGoal,
    useCreateGoalMutationMutation({
      refetchQueries: refetchQueries("goals"),
      variables: {
        goal: {
          careEpisodeId: props.feedbackReportContext.careEpisodeId.toString(),
          goalType: goalType,
          userId: respondent.toString(),
          patientText: goalText,
          status: GoalStatus.NEW,
        },
      },
    })
  );

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (formValid) {
      createGoal();
    }
  };

  useRedirectOnSuccess(remoteData, props.onSuccessRoute, nav);

  return CreateGoalFormContent({
    goalType: {
      value: goalType,
      onChange: handleGoalTypeInput,
    },
    respondent: {
      value: respondent,
      onChange: handleRespondentInput,
    },
    goalText: {
      value: goalText,
      onChange: handleGoalTextInput,
      isError: goalTextError,
    },
    response: remoteData,
    onSubmit: handleSubmit,
    valid: formValid,
    feedbackReportContext: props.feedbackReportContext,
  });
}

// The type parameter here is because the generated types aren't giving us a friendly type name for the success type,
// and we don't actually need to do anything with the success value anyway, so we can just parameterize it and ignore it
type CreateGoalFormContentProps<T> = {
  goalType: {
    value: GoalType;
    onChange: (e: SelectChangeEvent<HTMLSelectElement>) => void;
  };
  respondent: {
    value: UserId;
    onChange: (e: SelectChangeEvent<HTMLSelectElement>) => void;
  };
  goalText: {
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    isError: boolean;
  };
  response: MutationRemoteDataResult<T | undefined>;
  onSubmit: (e: FormEvent) => void;
  valid: boolean;
  feedbackReportContext: FeedbackReportContext;
};

export function CreateGoalFormContent<T>(props: CreateGoalFormContentProps<T>): ReactElement {
  const { goalType, respondent, goalText, response, onSubmit, valid, feedbackReportContext } = props;
  const { t } = useTranslation(["report"]);

  const overlay = <FormOverlay response={response} errorMessage={t("report:forms.addGoal.failure")} />;

  return (
    <Form data-testid="add-goal-form" onSubmit={onSubmit}>
      <Stack spacing={1} alignItems="flex-start">
        <FormControl>
          <InputLabel id="add-goal-goal-type-label">{t("report:forms.addGoal.goalType")}</InputLabel>
          <Select
            data-testid="goal-type-field"
            labelId="add-goal-goal-type-label"
            id="add-goal-goal-type"
            label={t("report:forms.addGoal.goalType")}
            // For reasons I don't fully understand, I have to cast to unknown here while I've seen other forms use the
            // same exact types in `value` without a cast. le sigh
            value={goalType.value as unknown}
            onChange={goalType.onChange}
          >
            {Object.entries(ACTIVE_GOAL_TYPES).map(([value, config]) => (
              <MenuItem key={value} value={value}>
                {config.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="add-goal-respondent-label">{t("report:forms.addGoal.respondent")}</InputLabel>
          <Select
            data-testid="respondent-field"
            labelId="add-goal-respondent-label"
            id="add-goal-respondent"
            label={t("report:forms.addGoal.respondent")}
            value={respondent.value as unknown}
            onChange={respondent.onChange}
          >
            {clientParticipants(feedbackReportContext.participants.toArray()).map((participant) => (
              <MenuItem key={participant.user.id.toString()} value={participant.user.id.toString()}>
                {participant.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          data-testid="goal-text-field"
          id="add-goal-goal-text"
          label={t("report:forms.addGoal.goalText")}
          fullWidth
          value={goalText.value}
          onChange={goalText.onChange}
          error={goalText.isError}
          helperText={goalText.isError ? t("report:forms.addGoal.goalTextError") : ""}
        />
        <Button
          data-testid="add-goal-submit"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!valid}
        >
          {t("report:forms.addGoal.submit", { goalTitle: GOAL_TYPE_CONFIGURATION[goalType.value].title })}
        </Button>
      </Stack>
      {overlay}
    </Form>
  );
}
