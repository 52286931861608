import { Box, Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  MetricParams,
  useOutcomesTransientMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  dateAccessor,
  metricLabelGeneratorFromParams,
  metricParamsDownloadDisabled,
  metricParamsYMax,
  metricValueFormatterFromParams,
  metricYAccessorFromParams,
} from "./OutcomesMetricHelpers";
import { useIsMobile } from "Shared/Responsive";
import DownloadTransientMetricExcelButton from "./DownloadTransientMetricExcel";
import { ensureMetricParams } from "./OutcomesFormHelpers";
import AnalyticsLineChart from "Shared/Graphs/AnalyticsLineChart";

type TimeBasedStatsBoxProps = {
  totalRows: number;
  successfulRows: number;
  rowsInTimePeriod: number;
  eligibleRows: number;
};

function TimeBasedStatsBox(props: TimeBasedStatsBoxProps) {
  const { t } = useTranslation(["outcomes"]);

  return (
    <Stack textAlign={"center"} direction={"row"} spacing={1}>
      <Box>
        <Typography fontSize={"2em"}>{props.totalRows}</Typography>
        {t("outcomes:create.stats.totalRows")}
      </Box>
      <Box>
        <Typography fontSize={"2em"}>{props.rowsInTimePeriod}</Typography>
        {t("outcomes:create.stats.rowsInTimePeriod")}
      </Box>
      <Box>
        <Typography fontSize={"2em"}>{props.eligibleRows}</Typography>
        {t("outcomes:create.stats.eligibleRows")}
      </Box>
      <Box>
        <Typography fontSize={"2em"}>{props.successfulRows}</Typography>
        {t("outcomes:create.stats.successfulRows")}
      </Box>
    </Stack>
  );
}

type TriggerBasedStatsBoxProps = {
  totalRows: number;
  eligibleRows: number;
  triggerRows: number;
};

function TriggerBasedStatsBox(props: TriggerBasedStatsBoxProps) {
  const { t } = useTranslation(["outcomes"]);

  return (
    <Stack textAlign={"center"} direction={"row"} spacing={1}>
      <Box>
        <Typography fontSize={"2em"}>{props.totalRows}</Typography>
        {t("outcomes:create.stats.totalRows")}
      </Box>
      <Box>
        <Typography fontSize={"2em"}>{props.eligibleRows}</Typography>
        {t("outcomes:create.stats.eligibleRows")}
      </Box>
      <Box>
        <Typography fontSize={"2em"}>{props.triggerRows}</Typography>
        {t("outcomes:create.stats.triggerRows")}
      </Box>
    </Stack>
  );
}

function CurrentStatsGraph(props: {
  metricParams: MetricParams;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
}) {
  const { t } = useTranslation(["outcomes", "common"]);

  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: { node: props.entityTreeNodeParams },
    period: {
      dateRange: {
        min: props.startDate,
        max: props.endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };

  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesTransientMetricSummaryDataQuery({
      variables: {
        drilldownMetric: drilldownMetric,
        transientMetricData: ensureMetricParams(props.metricParams),
        scaleScorerId: null,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => null,
    NotAsked: () => null,
    Failure: () => {
      return <>{t("common:unexpectedError")}</>;
    },
    Success: (result) => {
      const summary = result.outcomesTransientMetricSummaryData;
      if (summary.reportEntityTreeNodes[0]) {
        const node = summary.reportEntityTreeNodes[0];

        const valueFormatter = metricValueFormatterFromParams(props.metricParams);
        const yAccessor = metricYAccessorFromParams(props.metricParams);
        const labelAccessor = metricLabelGeneratorFromParams(props.metricParams, t);

        const formattedValue = summary.value !== null ? valueFormatter(summary.value) : "-";

        let statBox = null;

        if (props.metricParams.payload.timeBased) {
          statBox = (
            <TimeBasedStatsBox
              eligibleRows={summary.eligibleRows}
              rowsInTimePeriod={summary.rowsInTimePeriod}
              successfulRows={summary.successfulRows}
              totalRows={summary.totalRows}
            />
          );
        } else {
          statBox = (
            <TriggerBasedStatsBox
              eligibleRows={summary.eligibleRows}
              triggerRows={summary.triggerRows}
              totalRows={summary.totalRows}
            />
          );
        }

        const headlineUnits = props.metricParams.payload.timeBased
          ? t("outcomes:units.timeBased.successful")
          : t("outcomes:units.triggerBased.avgDays");

        const data = node.summaryGraphData?.find((x) => x.name === "value");

        const downloadButton = metricParamsDownloadDisabled(props.metricParams) ? (
          <Button variant="contained" disabled>
            {t("outcomes:downloadDisabled")}
          </Button>
        ) : (
          <DownloadTransientMetricExcelButton
            endDate={props.endDate}
            startDate={props.startDate}
            metricParams={props.metricParams}
            entityTreeNode={props.entityTreeNodeParams}
            buttonProps={{ variant: "contained", color: "primary" }}
          >
            {t("outcomes:downloadTransientAsExcel")}
          </DownloadTransientMetricExcelButton>
        );

        return (
          <Stack direction={"column"} spacing={0.5}>
            <AnalyticsLineChart
              data={data?.points.concat() || []}
              dataKey={t("outcomes:result")}
              xAccessor={dateAccessor}
              yAccessor={yAccessor}
              labelAccessor={labelAccessor}
              yDomainMax={metricParamsYMax(props.metricParams, data?.points)}
              numYTicks={5}
            />
            {statBox}
            <Box>
              <Typography variant="h1" fontSize={"3.5em"} textAlign={"center"}>
                {formattedValue}
              </Typography>
              <Typography textAlign={"center"}>{headlineUnits}</Typography>
            </Box>
            <Box>{downloadButton}</Box>
          </Stack>
        );
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
  });
}

// We may get null stats if they haven't selected enough things
type PreviewMetricStatsProps = {
  metricParams: MetricParams | null;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

export default function PreviewMetricStats(props: PreviewMetricStatsProps) {
  const { t } = useTranslation(["outcomes"]);
  let content: ReactElement | string = (
    <Typography style={{ textAlign: "center" }}>{t("outcomes:create.stats.noValidMetric")}</Typography>
  );
  if (props.metricParams) {
    content = (
      <CurrentStatsGraph
        endDate={props.endDate}
        startDate={props.startDate}
        metricParams={props.metricParams}
        entityTreeNodeParams={props.entityTreeNodeParams}
      />
    );
  }
  // We have different sizes here because the actual top gutter is different sizes.
  const stickyTop = useIsMobile() ? "4.4em" : "4.8em";
  return (
    <Card sx={{ position: "sticky", top: stickyTop, zIndex: "stickyCard" }}>
      <CardHeader
        title={t("outcomes:create.stats.header")}
        subheader={t("outcomes:create.stats.subheader")}
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
