import React, { ReactElement } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.body1.fontSize,
}));

type Props = {
  text: string;
};

function EmptyState(props: Props): ReactElement {
  const { text } = props;

  return (
    <Box data-testid="empty-state">
      <StyledBox>{text}</StyledBox>
    </Box>
  );
}

export default EmptyState;
