import {
  Alert,
  Avatar,
  Badge,
  Button,
  CardHeader,
  DialogActions,
  DialogContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientWithActiveEnrollmentInfo, patientUrl } from "CollaborativeCare/Utils/patient";
import {
  Enrollment,
  Patient,
  useCollaborativeCareReactivatePreviousEnrollmentMutation,
  usePatientReferenceBaseQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId, PatientId } from "Lib/Ids";
import { initials } from "Lib/Utils";
import Link from "MDS/Link";
import { differenceInYears, format } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { PatientHeaderStatus } from "./PatientHeaderStatus";
import { genderIdentityWithFallback } from "Shared/Patient";
import { PatientBillableMinutesBadge } from "CollaborativeCare/PatientBillableMinutesBadge";
import { useIsMobile } from "Shared/Responsive";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useNavigate, useParams } from "react-router-dom";
import { unsafeFromUuid } from "Lib/Id";
import { refetchQueries } from "Lib/RefetchQueries";
import { useEffectSimpleCompare } from "Lib/Hooks";

type PatientReferenceCardHeaderProps = {
  patientId: PatientId;
  disableCardPadding?: boolean; // Cards automatically add a 16px left padding. We don't want that always.
  disableLink?: boolean;
  forPreviousEnrollment?: boolean;
  canReactivate?: boolean;
};
function PatientReferenceCardHeader(props: PatientReferenceCardHeaderProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    usePatientReferenceBaseQuery({
      variables: {
        patientId: props.patientId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => <Typography>Loading</Typography>,
    Failure: () => <Typography>Fail</Typography>,
    Success: (data) => (
      <LoadedHeader
        patient={data.patient}
        disableCardPadding={props.disableCardPadding}
        disableLink={props.disableLink}
        forPreviousEnrollment={props.forPreviousEnrollment}
        canReactivate={props.canReactivate}
      />
    ),
    NotAsked: () => <Typography>Not Asked</Typography>,
  });
}

type LoadedPatient = PickTypename<
  Patient,
  "id" | "dob" | "name" | "gender" | "genderIdentity" | "genderIdentityFhir" | "preferredPronouns" | "isTest"
> &
  PatientWithActiveEnrollmentInfo<PickTypename<Enrollment, "id" | "enrolledAt">> & {
    collaborativeCareTasks: PickTypename<Patient["collaborativeCareTasks"], "totalCount">;
  };

type LoadedHeaderProps = {
  patient: LoadedPatient | null;
  disableCardPadding?: boolean;
  disableLink?: boolean;
  forPreviousEnrollment?: boolean;
  canReactivate?: boolean;
};
function LoadedHeader(props: LoadedHeaderProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums", "patients", "common", "report"]);

  const patient = props.patient;

  if (patient === null) {
    return <CardHeader title={t("report:demographics.noPatient")} />;
  }

  let dob = t("report:demographics.ageUnknown");
  if (patient.dob) {
    const age = differenceInYears(new Date(), patient.dob);
    dob = `${t("report:demographics.ageShort", { age: age })} - ${format(patient.dob, "MM/dd/yyy")}`;
  }

  const sexString = genderIdentityWithFallback(patient, t);
  const pronounsString =
    patient.preferredPronouns === null || patient.preferredPronouns.length === 0
      ? ""
      : `(${patient.preferredPronouns})`;

  const sxOverride = props.disableCardPadding ? { paddingLeft: 0 } : {};

  let enrolledAgo = null;
  if (patient.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment?.enrolledAt) {
    enrolledAgo = patient.activeCollaborativeCareCareEpisode.collaborativeCareEnrollment.enrolledAt;
  }

  let enrolledAgoElement = <></>;
  if (enrolledAgo != null) {
    enrolledAgoElement = (
      <>
        <div>
          {t("collaborativeCare:enrollment.enrolledTimeAgo", {
            enrolledAgo: t("common:date.durationToNow", { date: enrolledAgo }).toString(),
          })}
        </div>
      </>
    );
  }

  const subheader = (
    <>
      {`${sexString} ${pronounsString} - ${dob}`} {enrolledAgoElement}
    </>
  );

  return (
    <CardHeader
      avatar={
        <PatientAvatar
          patient={patient}
          taskCount={patient.collaborativeCareTasks.totalCount}
          forPreviousEnrollment={props.forPreviousEnrollment}
        />
      }
      action={
        <HeaderAction
          patient={patient}
          forPreviousEnrollment={props.forPreviousEnrollment}
          canReactivate={props.canReactivate}
        />
      }
      title={<PatientTitle patient={patient} disableLink={props.disableLink} />}
      subheader={subheader}
      sx={sxOverride}
    />
  );
}
type PatientTitleProps = {
  patient: LoadedPatient;
  disableLink: boolean | undefined;
};
function PatientTitle(props: PatientTitleProps): ReactElement {
  return props.disableLink ? (
    <Typography variant="h3" sx={{ wordBreak: "break-word" }} marginBottom={"2px"}>
      {props.patient.name}
    </Typography>
  ) : (
    <Link to={patientUrl(props.patient)} sx={{ wordBreak: "break-word" }}>
      {props.patient.name}
    </Link>
  );
}

function HeaderAction({
  patient,
  forPreviousEnrollment,
  canReactivate,
}: {
  patient: PickTypename<LoadedPatient, "id" | "activeCollaborativeCareCareEpisode" | "name" | "isTest">;
  forPreviousEnrollment?: boolean;
  canReactivate?: boolean;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [showModal, setShowModal] = React.useState<boolean>(false);
  let content = (
    <>
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <PatientHeaderStatus patientId={patient.id} patientName={patient.name} />
        <PatientBillableMinutesBadge patientId={patient.id} />
      </Stack>
    </>
  );
  const params = useParams<{ patientId: string; enrollmentId: string }>();
  if (forPreviousEnrollment) {
    if (!params.enrollmentId) {
      //theoretically shouldn't be possible
      return <></>;
    }
    const navigate = useNavigate();
    const enrollmentId = unsafeFromUuid<"Enrollment">(params.enrollmentId);
    const modal = (
      <ReactivateEnrollmentModal
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        enrollmentId={enrollmentId}
        onSuccess={() => {
          setShowModal(false);
          navigate("/app/cocm/patient/" + patient.id);
        }}
      ></ReactivateEnrollmentModal>
    );
    content = (
      <>
        <Tooltip
          title={
            !canReactivate ? t("collaborativeCare:patientDetails.enrollmentHistory.reactivateDisabled") : ""
          }
        >
          <span>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setShowModal(true);
              }}
              disabled={!canReactivate}
            >
              {t("collaborativeCare:patientDetails.enrollmentHistory.reactivateEnrollment")}
            </Button>
          </span>
        </Tooltip>
        {modal}
      </>
    );
  }
  return content;
}

function PatientAvatar({
  patient,
  taskCount,
  forPreviousEnrollment,
}: {
  patient: PickTypename<Patient, "name" | "isTest">;
  taskCount: number | undefined;
  forPreviousEnrollment?: boolean;
}): ReactElement {
  const { t } = useTranslation(["patients"]);
  const badgeText = `${t("patients:referenceHeader.badgeCountAltText")} ${patient.name}`;
  const badgeContent = (
    <Tooltip title={badgeText}>
      <Typography>{taskCount}</Typography>
    </Tooltip>
  );

  // On mobile we're so constrained that the test patient badge will actually cut
  // out of the viewport if placed on the left side.
  // It looks better on the left on desktop, but we'll compromise on mobile and put it on
  // the right so we can see it.
  const testPatientBadgeAnchorHorizontal = useIsMobile() ? "right" : "left";

  const fullBadge = forPreviousEnrollment ? (
    <Avatar aria-label="patient">{initials(`${patient.name}`)}</Avatar>
  ) : (
    <Badge
      color="error"
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={badgeContent}
    >
      <Badge
        badgeContent={t("patients:referenceHeader.testPatient")}
        color="success"
        anchorOrigin={{ vertical: "top", horizontal: testPatientBadgeAnchorHorizontal }}
        invisible={!patient.isTest}
      >
        <Avatar aria-label="patient">{initials(`${patient.name}`)}</Avatar>
      </Badge>
    </Badge>
  );
  return fullBadge;
}

function ReactivateEnrollmentModal(props: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  enrollmentId: EnrollmentId;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [reactivateEnrollmentMutation, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareReactivatePreviousEnrollment,
    useCollaborativeCareReactivatePreviousEnrollmentMutation({
      variables: { input: { enrollmentId: props.enrollmentId } },
      refetchQueries: refetchQueries("enroll"),
    })
  );
  const [alert, setAlert] = React.useState<ReactElement>(<></>);
  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Loading: () => null,
      NotAsked: () => null,
      Failure: (error) => {
        setAlert(<Alert severity="error">{error.toError().message}</Alert>);
      },
      Success: () => {
        props.onSuccess();
      },
    });
  }, [remoteData.kind]);

  return (
    <ResponsiveDialog
      title={t("collaborativeCare:patientDetails.enrollmentHistory.reactivateEnrollment")}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogContent>
        <Stack direction="column" spacing={1}>
          {alert}
          {t("collaborativeCare:patientDetails.enrollmentHistory.reactivateEnrollmentText")}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"outlined"}
          color={"error"}
          onClick={() => {
            reactivateEnrollmentMutation();
          }}
        >
          {t("collaborativeCare:patientDetails.enrollmentHistory.reactivate")}
        </Button>
      </DialogActions>
    </ResponsiveDialog>
  );
}

export default PatientReferenceCardHeader;
