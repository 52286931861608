import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  DataGridRowClickHandler,
} from "Shared/SortablePagableCollectionDataGrid";
import {
  EntityDecisionAlgorithmListQuery,
  EntityDecisionAlgorithmListQueryVariables,
  useEntityDecisionAlgorithmListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AlgorithmFilters } from "./AlgorithmFilters";
import EntityPath from "Entities/EntityPath";

type AlgorithmTableProps = {
  filters: AlgorithmFilters;
};

export function AlgorithmTable(props: AlgorithmTableProps): ReactElement | null {
  const { t } = useTranslation(["decisionSupport"]);
  const theme = useTheme();
  const navigate = useNavigate();

  const columns: DataGridCols<EntityDecisionAlgorithmListQuery, ["decisionSupportEntityDecisionAlgorithms"]> =
    [
      {
        field: "algorithm",
        headerName: t("decisionSupport:algorithms.headers.algorithm"),
        flex: 2,
        sortable: false,
        renderCell: (params) => {
          return params.row.name;
        },
      },
      {
        field: "target",
        headerName: t("decisionSupport:algorithms.headers.target"),
        flex: 2,
        sortable: false,
        renderCell: (params) => {
          return <EntityPath entityTreeNode={params.row.entityTreeNode} />;
        },
      },
    ];

  const queryVars: Pick<EntityDecisionAlgorithmListQueryVariables, "status"> = {
    status: props.filters.status,
  };

  const onClick: DataGridRowClickHandler<
    EntityDecisionAlgorithmListQuery,
    ["decisionSupportEntityDecisionAlgorithms"]
  > = (params, event) => {
    // Calling `navigate` in this handler will override the effect of clicking on any links in the table, so the patient
    // name column's link does nothing. Checking isDefaultPrevented tells us if we're clicking on something that's
    // generating another event in addition to this click (like a link, or possibly other inputs, I haven't tested).
    // As long as we don't navigate during clicks on the link the link works fine.
    if (!event.isDefaultPrevented()) {
      const id = params.row.id;
      navigate(`/app/providers/decision-support/algorithms/details/${id}`);
    }
  };

  return (
    <SortablePagableCollectionDataGrid
      sx={{ "& .MuiDataGrid-row.Mui-selected": { backgroundColor: theme.palette.background.default } }}
      disableRowSelectionOnClick={false}
      queryHook={useEntityDecisionAlgorithmListQuery}
      queryVariables={queryVars}
      unwrapData={(response) => response?.decisionSupportEntityDecisionAlgorithms || null}
      colNameToSortParam={(_field) => null}
      columns={columns}
      defaultPageSize={10}
      onRowClick={onClick}
    />
  );
}
