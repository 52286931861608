import {
  DetailedScaleCompletionFragmentFragment,
  ScaleCompletionFragmentFragment,
  ScaleSummaryFragment,
  ScaleWithQuestionsFragment as RawScaleWithQuestions,
} from "GeneratedGraphQL/SchemaAndOperations";
import * as DateQuantizedData from "Lib/DateQuantizedData";
import * as NEL from "Lib/NonEmptyList";
import { find, justs, last } from "Lib/Utils";
import { Err, Just, Maybe, Nothing, Ok, Result } from "seidr";
import { formatDate } from "Shared/formatters";
import { ParticipantSummary } from "Shared/Participant";
import { CareEpisodeUnscoredComputedValueDetails } from "./CareEpisodeComputedValueDetails";
import { QuestionResponseSummary, toQuestionResponseSummary } from "./QuestionResponse";

export type ScaleCompletion = Pick<CareEpisodeUnscoredComputedValueDetails, "id" | "date">;

export type DetailedScaleCompletion = ScaleCompletion & {
  questionResponses: ReadonlyArray<QuestionResponseSummary>;
};

export type ScaleCompletionHistory = {
  scale: ScaleSummaryFragment;
  latest: Maybe<DetailedScaleCompletion>;
  completions: Array<ScaleCompletion>;
  participant: ParticipantSummary;
};

export type QuantizedScaleCompletion =
  DateQuantizedData.DateQuantizedDatum<CareEpisodeUnscoredComputedValueDetails>;

// -- Parsers -----------------------------------------------------------------

// These can be simplified now we have better graphql typing.
function parseScaleCompletion(raw: ScaleCompletionFragmentFragment): Result<Error, ScaleCompletion> {
  if (raw.user) {
    return Ok({ id: raw.id, date: raw.targetDate, userId: raw.user.id });
  }
  return Err(new Error("Must provide a user"));
}

function parseDetailedScaleCompletion(
  rawScale: Omit<RawScaleWithQuestions, "__typename">,
  raw: DetailedScaleCompletionFragmentFragment
): Result<Error, DetailedScaleCompletion> {
  return parseScaleCompletion(raw).map((scaleCompletion) => {
    return {
      ...scaleCompletion,
      questionResponses: justs(
        rawScale.questions.map((question) =>
          find((a) => a.questionId === question.id, raw.answers).map((answer) =>
            toQuestionResponseSummary({ ...answer, question })
          )
        )
      ),
    };
  });
}

// -- Transformers ------------------------------------------------------------

function toQuantizedScaleCompletions(
  dates: NEL.NonEmptyList<Date>,
  completions: ReadonlyArray<CareEpisodeUnscoredComputedValueDetails>
): NEL.NonEmptyList<QuantizedScaleCompletion> {
  return DateQuantizedData.quantizeWithAccessor(dates, completions, (d) => d.targetDate);
}

// -- Helpers -----------------------------------------------------------------

function formatCompletion(completion: Pick<ScaleCompletion, "date">): string {
  return formatDate(completion.date);
}

function quantizedCompletionDate(completion: QuantizedScaleCompletion): Date {
  return completion.caseOf({
    Value: ({ date }) => date,
    Blank: (date) => date,
  });
}

function lastQuantizedCompletionDate(history: NEL.NonEmptyList<QuantizedScaleCompletion>): Date {
  return quantizedCompletionDate(NEL.last(history));
}

function lastCompletion(
  completions: NEL.NonEmptyList<QuantizedScaleCompletion>
): Maybe<CareEpisodeUnscoredComputedValueDetails> {
  return last(
    justs(
      NEL.toArray(
        completions.map((datum) =>
          datum.caseOf({
            Value: Just,
            Blank: Nothing,
          })
        )
      )
    )
  );
}

export {
  parseScaleCompletion,
  parseDetailedScaleCompletion,
  toQuantizedScaleCompletions,
  lastCompletion,
  formatCompletion,
  quantizedCompletionDate,
  lastQuantizedCompletionDate,
};
