import { Maybe, Result } from "seidr";
import * as Goal from "Shared/Scale/Goal";
import { ParticipantByUserIdMap } from "../../FeedbackReportContext";
import { sortItemsByPatientParticipant } from "Shared/Participant";
import { DigUnpacked } from "type-utils";
import { OverviewGoalsQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";

type RawGoal = DigUnpacked<OverviewGoalsQueryQuery, ["assessmentCareEpisodeScaleSummary", "goals"]>;

function parseGoals(
  rawGoals: ReadonlyArray<RawGoal>,
  participantsByUserId: ParticipantByUserIdMap
): Array<Goal.Goal> {
  return sortItemsByPatientParticipant(
    rawGoals.map(Goal.toGoal),
    (x) => Maybe.fromNullable(participantsByUserId[x.user.id.toString()]),
    (x) => x.startDate.toISOString()
  );
}

function parseOverviewGoals(
  raw: OverviewGoalsQueryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ReadonlyArray<Goal.Goal>> {
  return Result.fromNullable(
    new Error("no assessmentCareEpisodeScaleSummary found"),
    raw.assessmentCareEpisodeScaleSummary
  ).map((summary) => {
    return parseGoals(summary.goals, participantsByUserId).filter(Goal.goalIsDisplayable);
  });
}

export { parseOverviewGoals };
