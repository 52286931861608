import { XYChart } from "@visx/xychart";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import * as NEL from "Lib/NonEmptyList";
import React, { ReactElement } from "react";
import { Maybe } from "seidr";
import { ScaleWithNumericalScorer } from "Shared/Scale/Scale";
import { SeveritySupportedValues } from "Shared/Severity/Severity";
import ScaleThresholdChartBackground from "Shared/Viz/Shared/ScaleThresholdChartBackground";
import { HoverCircleSize } from "Shared/Viz/Shared/HoverCircle";
import { SeverityHistorySeries } from "Shared/Viz/Shared/SeverityHistorySeries";

type SeverityCompareGraphProps = {
  width: number;
  height: number;
  history: ReadonlyArray<SeveritySupportedValues>;
  valueRange: [number, number];
  scale: Maybe<ScaleWithNumericalScorer>;
  valueRoute: (value: SeveritySupportedValues) => FeedbackReportRoute;
  dates: NEL.NonEmptyList<Date>;
};

export function SeverityCompareGraph(props: SeverityCompareGraphProps): ReactElement {
  const { history, valueRange, valueRoute, width, height, dates } = props;

  return (
    <>
      <XYChart
        captureEvents={false}
        yScale={{ type: "linear", domain: valueRange, zero: false }}
        xScale={{
          type: "time",
          domain: [NEL.head(dates), NEL.last(dates)],
        }}
        width={width}
        height={height}
        // Just enough margin so that data point glyphs at the edges don't get cut off
        // Top needs more because we push invalids up.
        margin={{
          top: HoverCircleSize.large.radius + HoverCircleSize.small.radius + 2,
          bottom: HoverCircleSize.large.radius,
          right: HoverCircleSize.large.radius,
          left: HoverCircleSize.large.radius,
        }}
      >
        <ScaleThresholdChartBackground scale={props.scale} />
        <SeverityHistorySeries history={history} valueRoute={valueRoute} invalidYValue={valueRange[1]} />
      </XYChart>
    </>
  );
}
