import { compareAsc } from "date-fns";
import {
  OverviewTreatmentResponseQueryQuery,
  ScaleScoreQueryQuery,
  ScaleScorerHistoryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ExtendedReadonlyArray, resultMap2 } from "Lib/Utils";
import { Err, Ok, Result } from "seidr";
import { ParticipantSummary } from "Shared/Participant";
import {
  CareEpisodeComputedValueDetails,
  CareEpisodeComputedValueWithScaleId,
  parseDetailedScaleScore,
} from "Shared/Scale/CareEpisodeComputedValueDetails";
import * as Scale from "Shared/Scale/Scale";
import * as ScaleGroup from "Shared/Scale/ScaleGroup";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { ScaleSection, ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { Dig, DigUnpacked, DiscriminateUnion, Unpacked } from "type-utils";
import { ParticipantByUserIdMap } from "../../FeedbackReportContext";
import { parseScaleAdministrationHistories } from "./OverviewConstructsTransforms";

export type DetailedScaleScoreWithParticipant = CareEpisodeComputedValueWithScaleId & {
  participant: ParticipantSummary;
};

// Type imports from legacy old
type OverviewTreatmentResponseQuery = OverviewTreatmentResponseQueryQuery;
type RawTargetScaleSection = DigUnpacked<
  OverviewTreatmentResponseQuery,
  ["assessmentCareEpisodeScaleSummary", "targetSections"]
>;

type RawHistoryQuery = ScaleScorerHistoryQuery;
type RawDetailedScaleByUser = DigUnpacked<
  RawHistoryQuery,
  ["assessmentCareEpisodeScaleDetail", "careEpisodeScaleResult", "byUser"]
>;
type RawDetailedByScorer = DigUnpacked<
  RawHistoryQuery,
  ["assessmentCareEpisodeScaleDetail", "careEpisodeScaleResult", "byUser", "byScorer"]
>;
type RawScaleWithQuestions = Dig<
  RawHistoryQuery,
  ["assessmentCareEpisodeScaleDetail", "careEpisodeScaleResult", "scale"]
>;

// -- Helpers -----------------------------------------------------------------

function toScaleScorerHistoryByUser(
  rawScale: RawScaleWithQuestions,
  rawScaleByUser: RawDetailedScaleByUser,
  rawByScorer: RawDetailedByScorer,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ScaleScorerHistory> {
  const rawScorer = rawByScorer.scorer;

  const scores = rawByScorer.history
    .concat(rawByScorer.latest)
    .map((score) => ({
      ...score,
      invitationDate:
        score.mostRelevantInvitation?.dateCompleted || score.mostRelevantInvitation?.dateBegun || null,
    }))
    .sort((a, b) => compareAsc(a.date, b.date));

  const participant = rawScaleByUser.user && participantsByUserId[rawScaleByUser.user.id.toString()];

  if (!participant) {
    return Err(new Error("Missing Participant"));
  }

  // TODO: The 'latest calculation needs more work as we need to exclude things like
  // scales that were never administered etc.
  return Ok({
    latest: parseDetailedScaleScore(rawScale, rawByScorer.latest).toMaybe(),
    scale: { scorer: rawScorer, ...rawScale },
    scores,
    participant,
  });
}

/**
 * For each section, we will either get a single scale, or a scale group.
 * @param rawSections
 */
function parseScaleSections(
  rawSections: ReadonlyArray<RawTargetScaleSection>,
  participantsByUserId: ParticipantByUserIdMap
): Array<ScaleSectionSummary> {
  return rawSections.flatMap((raw) => {
    if (raw.__typename === "CareEpisodeScaleResult") {
      return parseScaleAdministrationHistories([raw], participantsByUserId).map(
        ScaleSection.IndividualScaleSection
      );
    } else {
      return parseScaleGroupSection(raw, participantsByUserId);
    }
  });
}

type CareEpisodeScaleGroupResult = DiscriminateUnion<
  Unpacked<Dig<OverviewTreatmentResponseQuery, ["assessmentCareEpisodeScaleSummary", "targetSections"]>>,
  "CareEpisodeScaleGroupResult"
>;

function parseScaleGroupSection(
  raw: CareEpisodeScaleGroupResult,
  participantsByUserId: ParticipantByUserIdMap
): ScaleSectionSummary {
  return ScaleSection.ScaleGroupSection(parseScaleGroupHistoryQuery(raw, participantsByUserId));
}

function onlyDisplayableScorers(history: Result<Error, ScaleScorerHistory>): boolean {
  return history.caseOf({
    Ok: (history) => {
      if ("scorer" in history.scale) {
        return Scale.isDisplayable(history.scale.scorer);
      } else {
        return true;
      }
    },
    // Let errors through so that we can surface them in the app properly.
    Err: (_) => true,
  });
}

// -- Main Query parsers ------------------------------------------------------

function parseScaleScoreQuery(raw: ScaleScoreQueryQuery): Result<Error, CareEpisodeComputedValueDetails> {
  const score = raw.assessmentCareEpisodeComputedValue
    ? {
        ...raw.assessmentCareEpisodeComputedValue,
        invitationDate:
          raw.assessmentCareEpisodeComputedValue.mostRelevantInvitation?.dateCompleted ||
          raw.assessmentCareEpisodeComputedValue.mostRelevantInvitation?.dateBegun ||
          null,
      }
    : undefined;

  return Result.fromNullable(new Error("No care episode computed value found"), score);
}

function parseDetailedScaleScoreQuery(
  raw: ScaleScoreQueryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, DetailedScaleScoreWithParticipant> {
  return Result.fromNullable(
    new Error("No care episode computed value found"),
    raw.assessmentCareEpisodeComputedValue
  )
    .flatMap((cv) => parseDetailedScaleScore(cv.scale, cv))
    .flatMap((dss) => {
      return Result.fromNullable(
        new Error("No participant found"),
        raw.assessmentCareEpisodeComputedValue?.user
          ? participantsByUserId[raw.assessmentCareEpisodeComputedValue.user.id.toString()]
          : null
      ).flatMap((participant) =>
        Ok({
          ...dss,
          participant: participant,
        })
      );
    });
}

function parseCareEpisodeScaleByScorer(
  participantsByUserId: ParticipantByUserIdMap,
  scale: RawScaleWithQuestions,
  byUser: RawDetailedScaleByUser,
  byScorer: RawDetailedByScorer
): ReadonlyArray<Result<Error, ScaleScorerHistory>> {
  // Not all scales have scale roles set. For now, assume any scale without a scale role
  // is Per Respondent Total.
  switch (scale.role || Scale.ScaleRole.PER_RESPONDENT_TOTAL) {
    case Scale.ScaleRole.INTERMEDIATE:
    case Scale.ScaleRole.CUSTOM:
    case Scale.ScaleRole.ALERT:
    case Scale.ScaleRole.AGGREGATE:
      return [];
    case Scale.ScaleRole.ADMINISTRATIVE:
    case Scale.ScaleRole.COMBINED_SUBSCALE:
    case Scale.ScaleRole.COMBINED_TOTAL:
    case Scale.ScaleRole.PER_RESPONDENT_SUBSCALE:
    case Scale.ScaleRole.PER_RESPONDENT_TOTAL:
      return [toScaleScorerHistoryByUser(scale, byUser, byScorer, participantsByUserId)];
  }
}

function parseCareEpisodeScaleByUser(
  participantsByUserId: ParticipantByUserIdMap,
  scale: RawScaleWithQuestions,
  byUser: RawDetailedScaleByUser
): ReadonlyArray<Result<Error, ScaleScorerHistory>> {
  return byUser.byScorer.flatMap((byScorer) =>
    parseCareEpisodeScaleByScorer(participantsByUserId, scale, byUser, byScorer)
  );
}

type HistoryTransformer = (
  byUser: RawDetailedScaleByUser
) => ReadonlyArray<Result<Error, ScaleScorerHistory>>;

function parseScaleAdministrationHistoryQuery(
  rawHistory: RawHistoryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ReadonlyArray<ScaleScorerHistory>> {
  return Result.fromNullable(
    new Error("No care episode scale found"),
    rawHistory.assessmentCareEpisodeScaleDetail?.careEpisodeScaleResult
  ).flatMap((ces) => {
    const toHistories: HistoryTransformer = (byUser) =>
      parseCareEpisodeScaleByUser(participantsByUserId, ces.scale, byUser);

    return (
      new ExtendedReadonlyArray(ces.byUser)
        .flatMap(toHistories)
        .filter(onlyDisplayableScorers)
        // Plain english: This rolls up an array of results into a result of an array by bailing on the first error:
        // Array<Result<Err, Value>> => Result<Err, Array<Value>>
        .reduce((result, history) => {
          return resultMap2((histories, newHistory) => [...histories, newHistory], result, history);
        }, Ok([] as ReadonlyArray<ScaleScorerHistory>))
    );
  });
}

function parseScaleGroupHistoryQuery(
  raw: DiscriminateUnion<
    DigUnpacked<OverviewTreatmentResponseQueryQuery, ["assessmentCareEpisodeScaleSummary", "targetSections"]>,
    "CareEpisodeScaleGroupResult"
  >,
  participantsByUserId: ParticipantByUserIdMap
): ScaleGroup.CareEpisodeScaleGroupHistory {
  return {
    scaleGroup: raw.scaleGroup,
    histories: parseScaleAdministrationHistories(raw.byScale, participantsByUserId),
  };
}

function parseOverviewTreatmentResponseQuery(
  raw: OverviewTreatmentResponseQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, Array<ScaleSectionSummary>> {
  return Result.fromNullable(
    new Error("no assessmentCareEpisodeScaleSummary found"),
    raw.assessmentCareEpisodeScaleSummary
  ).map((summary) => {
    return parseScaleSections(summary.targetSections, participantsByUserId);
  });
}

export {
  parseScaleScoreQuery,
  parseDetailedScaleScoreQuery,
  parseScaleAdministrationHistoryQuery,
  parseScaleGroupHistoryQuery,
  parseOverviewTreatmentResponseQuery,
};
