import { ArrowDropDown, NavigateBefore, NavigateNext } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  Link as MUILink,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { range } from "d3";
import {
  AppointmentSchedule,
  AppointmentScheduleSortParameter,
  Flag,
  PatientFlag,
  PatientSession,
  Report,
  SortDirection,
  useProviderSchedulingAppointmentsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import GridWithCenterableItems from "MDS/GridWithCenterableItems";
import SimpleTooltip from "MDS/Tooltip/SimpleTooltip";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import AppointmentFeedbackReportButton from "./AppointmentFeedbackReportButton";
import { GridItemWithLeftMargin } from "./GridItemWithLeftMargin";
import { PatientFlags } from "./PatientFlags";
import { ScheduleFilters } from "./ScheduleFilters";
import { DEFAULT_FILTERS } from "./Schedule";
import { useQueryStringNumberParameter } from "Shared/QueryStringParameter";
import SettingsIcon from "@mui/icons-material/Settings";
import KeycodeLoginDialog from "./KeycodeLoginDialog";
import { groupBy } from "ramda";
import { MBC_REDESIGN_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";

// A quick note on javascript date constructor day math:
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#individual_date_and_time_component_values
// Javascript helpfully defines this constructor so that if you pass in a day (or minute, month, etc) outside the valid
// range for that value it'll carry over into the other values correctly. So `new Date(2022, 11, 32)` will actually be
// Jan 1, 2023, and `new Date(2022, 9, -4)` will actually be Sep 26. This lets us just add offsets to days without
// having to worry about crossing month and year boundaries.

type ScheduledAppointment = Pick<
  AppointmentSchedule,
  | "__typename"
  | "id"
  | "startDate"
  | "measurementStatus"
  | "measurementCategory"
  | "patientId"
  | "patientName"
  | "currentStats"
  | "providerName"
> & {
  patientSession:
    | (Pick<PatientSession, "id" | "__typename" | "isReportAvailable"> & {
        assessmentReport: Pick<Report, "__typename" | "id"> | null;
      })
    | null;
} & {
  patientFlags: ReadonlyArray<
    Pick<PatientFlag, "__typename" | "id" | "active"> & {
      flag: Pick<Flag, "__typename" | "id" | "name" | "icon">;
    }
  >;
};

type AppointmentRowProps = {
  appointment: ScheduledAppointment;
  index: number;
};

const COLUMNS = {
  // If you're changing these, make sure all the other ones add up to total
  total: 12,
  patientName: 2,
  providerName: 2,
  time: 1,
  category: 2,
  /* In what I expect to be the average case, we don't really need two columns for the flags. However, this
     gives it enough room to fit someone with every flag on my laptop, and tweaking this number is a lot
     easier than making a "... and X more, hover/click to see" thing for the flag list to handle the
     exceptional case. */
  flags: 2,
  severity: 1,
  report: 1,
  actions: 1,
};

function AppointmentRow(props: AppointmentRowProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation(["appointments", "common"]);
  const mbcRedesignEnabled = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);

  const { appointment } = props;
  const background = props.index % 2 == 0 ? theme.palette.common.white : "transparent";

  const patientLink = mbcRedesignEnabled
    ? `/app/patients/${appointment.patientId}`
    : `/provider/patients/${appointment.patientId}`;

  return (
    <>
      <GridItemWithLeftMargin
        xs={COLUMNS.patientName}
        columns={COLUMNS.total}
        margin="1rem"
        bgcolor={background}
        data-testid={appointment.patientId}
      >
        <Link to={patientLink}>{appointment.patientName}</Link>
      </GridItemWithLeftMargin>
      <Grid item xs={COLUMNS.providerName} bgcolor={background}>
        {appointment.providerName}
      </Grid>
      <Grid item xs={COLUMNS.time} bgcolor={background}>
        {appointment.startDate ? t("common:date.time", { date: appointment.startDate }) : ""}
      </Grid>
      <Grid item xs={COLUMNS.category} bgcolor={background}>
        <SimpleTooltip
          title={t(`appointments:measurementCategory.${appointment.measurementCategory}.description`)}
        >
          <span>{t(`appointments:measurementCategory.${appointment.measurementCategory}.title`)}</span>
        </SimpleTooltip>
      </Grid>

      <Grid item xs={COLUMNS.flags} bgcolor={background}>
        <PatientFlags flags={appointment.patientFlags} excludeInTreatment={true} />
      </Grid>
      <Grid item xs={COLUMNS.report} bgcolor={background}>
        <AppointmentFeedbackReportButton
          patientId={appointment.patientId}
          reportId={appointment.patientSession?.assessmentReport?.id}
          isReportAvailable={appointment.patientSession?.isReportAvailable}
        />
      </Grid>
      <Grid item xs={COLUMNS.actions} bgcolor={background}>
        <ActionsButton {...props} />
      </Grid>
    </>
  );
}

function ActionsButton(props: AppointmentRowProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const mbcRedesignEnabled = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);
  const open = anchor != null;
  const handleOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const [showKeycodeLogin, setShowKeycodeLogin] = React.useState<boolean>(false);

  const openKeycodeLogin = () => {
    setShowKeycodeLogin(true);
    handleClose();
  };

  let keycodeLoginMenuItem = null;
  let keycodeLoginDialog = null;
  let sessionDetailsLink = null;

  if (props.appointment.patientSession?.id) {
    keycodeLoginMenuItem = (
      <MenuItem onClick={openKeycodeLogin}>{t("dashboard:actions.keycodeLogin")}</MenuItem>
    );
    if (showKeycodeLogin) {
      keycodeLoginDialog = (
        <KeycodeLoginDialog
          open={showKeycodeLogin}
          onClose={() => setShowKeycodeLogin(false)}
          patientSessionId={props.appointment.patientSession.id}
        />
      );
    }

    const appointmentLink = mbcRedesignEnabled
      ? `/app/patients/${props.appointment.patientId}/appointments/${props.appointment.id}`
      : `/provider/patients/${props.appointment.patientId}/sessions/${props.appointment.patientSession.id}`;

    sessionDetailsLink = (
      <MenuItem>
        <MUILink underline="none" href={appointmentLink}>
          {t("dashboard:actions.measurementDetails")}
        </MUILink>
      </MenuItem>
    );
  }

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
        <ArrowDropDown fontSize="small" />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchor}>
        {sessionDetailsLink}
        {keycodeLoginMenuItem}
      </Menu>
      {keycodeLoginDialog}
    </>
  );
}

function HeaderRow(): ReactElement {
  const { t } = useTranslation(["dashboard"]);

  return (
    <>
      <GridItemWithLeftMargin item xs={COLUMNS.patientName} columns={COLUMNS.total} margin="1rem">
        <Typography fontWeight="bold">{t("dashboard:patient.label")}</Typography>
      </GridItemWithLeftMargin>
      <Grid item xs={COLUMNS.providerName}>
        <Typography fontWeight="bold">{t("dashboard:headers.providerName")}</Typography>
      </Grid>
      <Grid item xs={COLUMNS.time}>
        <Typography fontWeight="bold">{t("dashboard:headers.appointmentTime")}</Typography>
      </Grid>
      <Grid item xs={COLUMNS.category}>
        <Typography fontWeight="bold">{t("dashboard:headers.appointmentStatus")}</Typography>
      </Grid>
      <Grid item xs={COLUMNS.flags}>
        <Typography fontWeight="bold">{t("dashboard:patient.flags")}</Typography>
      </Grid>
      <Grid item xs={COLUMNS.report}>
        <Typography fontWeight="bold">{t("dashboard:headers.report")}</Typography>
      </Grid>
      <Grid item xs={COLUMNS.actions}>
        <Typography fontWeight="bold">{t("dashboard:headers.actions")}</Typography>
      </Grid>
    </>
  );
}

type DayTitleRowProps = {
  day: Date;
};

function DayTitleRow(props: DayTitleRowProps): ReactElement {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Grid item xs={COLUMNS.total}>
      <Stack
        direction="column"
        width="100%"
        borderBottom={`1px solid ${theme.palette.dividerLight}`}
        mt={2}
        mb={1}
      >
        {/* I have a hunch that we should probably redo our default header styles so that this could be closer to just
            `variant="h1" component="h2"` (1.25rem for h1 is super tiny) but I do not have the energy to audit our
            header usage right now. */}
        <Typography component="h2" sx={{ fontSize: "2rem", fontWeight: "bold" }}>
          {t("date.dayMonth", { date: props.day })}
        </Typography>
      </Stack>
    </Grid>
  );
}

type SchedulingAppointmentsForDayProps = {
  day: Date;
  search: string | null;
  filters?: ScheduleFilters;
};
function SchedulingAppointmentsForDay(props: SchedulingAppointmentsForDayProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);

  // `new Date(...)` always returns a date in the browser timezone, so these bounds should always be right for the
  // user's view of the world. When we serialize the date Apollo converts it to UTC, so for example in EDT, the client
  // will have startOfDay = 2022-10-25T00:00:00 GMT-4. The server will get passed "2022-10-25T04:00:00Z", which should
  // then get compared correctly against all the timezone aware appointment dates in our database.
  const startOfDay = new Date(props.day.getFullYear(), props.day.getMonth(), props.day.getDate());
  // See note about adding offset to days above.
  const endOfDay = new Date(props.day.getFullYear(), props.day.getMonth(), props.day.getDate() + 1);

  const filters = props.filters || DEFAULT_FILTERS;

  const { remoteData } = apolloQueryHookWrapper(
    useProviderSchedulingAppointmentsQuery({
      variables: {
        sortBy: AppointmentScheduleSortParameter.STARTDATE,
        sortDirection: SortDirection.ASC,
        // Keeping these in case we ever want to go back to use this query in a data grid.
        first: null,
        last: null,
        before: null,
        after: null,
        provider: filters.provider,
        measurementCategory: filters.category ? [filters.category] : [],
        flags: filters.flags,
        testClient: filters.testClients,
        unit: filters.organization,
        startDateBefore: endOfDay,
        startDateAfter: startOfDay,
        search: props.search,
      },
    })
  );

  const content = remoteData.caseOf({
    NotAsked: () => (
      <GridItemWithLeftMargin xs={COLUMNS.total} columns={COLUMNS.total} margin="1rem">
        <Spinner />
      </GridItemWithLeftMargin>
    ),
    Loading: () => (
      <GridItemWithLeftMargin xs={COLUMNS.total} columns={COLUMNS.total} margin="1rem">
        <Spinner />
      </GridItemWithLeftMargin>
    ),
    Failure: (err) => (
      <GridItemWithLeftMargin xs={COLUMNS.total} columns={COLUMNS.total} margin="1rem">
        <ErrorMessage message={err.message} />
      </GridItemWithLeftMargin>
    ),
    Success: (response) => {
      const appointments = response.schedulingAppointmentSchedules?.nodes || [];

      const dedupedAppointments = deduplicateAppointments(appointments);

      const rows = dedupedAppointments.map((appointment, i) => {
        return <AppointmentRow index={i} appointment={appointment} key={i} />;
      });

      const headers = <HeaderRow />;

      const noApppointmentsMessage = (
        <GridItemWithLeftMargin item xs={COLUMNS.total} columns={COLUMNS.total} margin="1rem">
          <Typography fontStyle="italic">{t("dashboard:noAppointments")}</Typography>
        </GridItemWithLeftMargin>
      );

      return (
        <>
          {rows.length > 0 ? headers : noApppointmentsMessage}
          {rows}
        </>
      );
    },
  });

  return (
    <>
      <DayTitleRow day={props.day} />
      {content}
    </>
  );
}

/**
 * We often get duplicate appointments from the EMR where the previous appointment was deleted, or otherwise
 * changed leaving us with two copies. Rather than attempt to delete these old copies in the database, which we don't
 * have the logic to do adequately, we just don't want to display more than one thing that matches a provider, patient and time.
 * This function deuplicates by that, and then chooses preferentially:
 *  * The only one, if there are no duplicates.
 *  * Anything with an available report.
 *  * Anything with any report
 *  * The first available invitation.
 *
 * Note that this relies on the sort order that the appointments come in on - the group by will respect the same
 * order list.
 *
 * @param appointments the appointments to be deduplicated
 *
 * @returns a deduplicated list with only one appointment per patient/provider/time slot
 */
function deduplicateAppointments(
  appointments: ReadonlyArray<ScheduledAppointment>
): ReadonlyArray<ScheduledAppointment> {
  const appointmentGroups = groupBy((appointment) => {
    return `${appointment.providerName}${appointment.patientId}${appointment.startDate}}`;
  }, appointments);

  const filtered: ReadonlyArray<ScheduledAppointment> = Object.values(appointmentGroups).flatMap((group) => {
    if (typeof group === "undefined") {
      return [] as ReadonlyArray<ScheduledAppointment>;
    }
    if (group.length > 1) {
      let winners = group.filter((candidate) => candidate.patientSession?.isReportAvailable);

      if (winners.length > 0) {
        return winners;
      }

      winners = group.filter((candidate) => candidate.patientSession?.id);

      if (winners.length > 0) {
        return winners;
      }

      return [group[0]] as ReadonlyArray<ScheduledAppointment>;
    } else {
      return group as ReadonlyArray<ScheduledAppointment>;
    }
  });

  return filtered;
}

const DAYS_PER_PAGE = 7;

type PaginationProps = {
  firstDay: Date;
  lastDay: Date;
  atToday: boolean;
  showDateRange?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onToday: () => void;
};
function AppointmentPagination(props: PaginationProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Grid item xs={COLUMNS.total}>
      <Stack direction="column" alignItems="center" width="100%">
        {props.showDateRange ? (
          <Typography component="h1" variant="h1">
            {t("dashboard:pagination.title", { start: props.firstDay, end: props.lastDay })}
          </Typography>
        ) : null}
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onPrevious}>
            <NavigateBefore /> {t("dashboard:pagination.previous")}
          </Button>
          <Button disabled={props.atToday} onClick={props.onToday}>
            {t("dashboard:pagination.current")}
          </Button>
          <Button onClick={props.onNext}>
            {t("dashboard:pagination.next")} <NavigateNext />
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

type SchedulingAppointmentsProps = {
  filters?: ScheduleFilters;
  search: string | null;
};
function SchedulingAppointments(props: SchedulingAppointmentsProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const now = new Date();

  const [weekOffset, setWeekOffset] = useQueryStringNumberParameter("weekOffset", 0);

  const firstDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() + weekOffset * DAYS_PER_PAGE);

  const user = React.useContext(AuthenticatedProviderUserContext);

  const showingAllUnits =
    // No filter defaults to every org
    !props.filters?.organization ||
    // Explicitly every org
    props.filters.organization.allOrganizations ||
    // Internal users don't have orgs, so this turns into all orgs.
    (user && user.userType === "internal" && props.filters.organization.myOrganizations);
  const showingAllProviders =
    // No filter defaults to all providers
    !props.filters?.provider ||
    // Explicitly every provider
    props.filters.provider.allProviders ||
    // Internal users don't have a provider, so this turns into all providers.
    (user && user.userType === "internal" && props.filters.provider.me);

  // The default is "me" and "my units", so this should only trip either for internal users or if a real user explicitly
  // goes looking for everything all at once.
  const showingEverything = showingAllUnits && showingAllProviders && props.search === null;

  const atToday =
    now.getFullYear() === firstDay.getFullYear() &&
    now.getMonth() === firstDay.getMonth() &&
    now.getDate() === firstDay.getDate();
  const onNext = () => setWeekOffset(weekOffset + 1);
  const onPrevious = () => setWeekOffset(weekOffset - 1);
  const onToday = () => setWeekOffset(0);

  let content = null;
  if (showingEverything) {
    content = (
      <Grid item xs={COLUMNS.total} alignContent="center">
        <Typography variant="h1" component="p" margin="auto">
          {t("dashboard:badFilters.allAppointments")}
        </Typography>
      </Grid>
    );
  } else {
    content = range(DAYS_PER_PAGE).map((offset) => {
      // See note about adding offsets to days above.
      const targetDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + offset);

      return (
        <SchedulingAppointmentsForDay
          key={offset}
          day={targetDay}
          filters={props.filters}
          search={props.search}
        />
      );
    });
  }

  return (
    <GridWithCenterableItems container spacing={1} columns={COLUMNS.total}>
      <AppointmentPagination
        firstDay={firstDay}
        // Subtract one here so that the range is shown as, e.g., Monday - Sunday, where just adding the full week would
        // show Monday - Monday, which isn't what's on the page.
        lastDay={
          new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + DAYS_PER_PAGE - 1)
        }
        atToday={atToday}
        showDateRange
        onNext={onNext}
        onPrevious={onPrevious}
        onToday={onToday}
      />
      {content}
      <AppointmentPagination
        firstDay={firstDay}
        lastDay={
          new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + DAYS_PER_PAGE - 1)
        }
        atToday={atToday}
        onNext={onNext}
        onPrevious={onPrevious}
        onToday={onToday}
      />
    </GridWithCenterableItems>
  );
}

export default SchedulingAppointments;
