import React from "react";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import { FeedbackReportContext } from "../FeedbackReportContext";
import ErrorMessage from "Shared/ErrorMessage";
import GoalDetailPane from "FeedbackReport/DetailPane/GoalDetailPane";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { DetailsWrapper } from "../DetailsWrapper";
import { useTranslation } from "react-i18next";

type GoalProps = {
  feedbackReportContext: FeedbackReportContext;
};

export const GoalRoute = (props: GoalProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{ goalId?: string }>();

  const goalId = Id.fromNullableString<"Goal">(params.goalId);

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [t("report:breadcrumbs.responses")],
  ];

  return goalId.caseOf({
    Err: (error) => <ErrorMessage message={error.message} />,
    Ok: (goalId) => (
      <DetailsWrapper breadcrumbs={breadcrumbs}>
        <GoalDetailPane goalId={goalId} feedbackReportContext={props.feedbackReportContext} />
      </DetailsWrapper>
    ),
  });
};
