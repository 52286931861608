import { Delete } from "@mui/icons-material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  DeleteInstituteGroupMutation,
  useDeleteInstituteGroupMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { InstituteGroupId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

type DeleteInstituteGroupButtonProps = {
  instituteGroupId: InstituteGroupId;
};

export function DeleteInstituteGroupButton(props: DeleteInstituteGroupButtonProps): ReactElement {
  const navigate = useNavigate();

  const [confirmation, setConfirmation] = React.useState(false);

  const onSuccess = (result: DeleteInstituteGroupMutation) => {
    // Use the relative url to navigate directly to the patient.
    // We may find ourselves in either the patient or the care episode page. Either way we have a relative route available.
    if (result.deleteInstituteGroup?.success) {
      navigate(`..`);
    } else {
      alert("An error occurred when deleting the group");
    }
  };

  const [deleteInstituteGroup, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.deleteInstituteGroup,
    useDeleteInstituteGroupMutation({
      variables: { input: { instituteGroupId: props.instituteGroupId } },
      refetchQueries: refetchQueries("instituteGroups"),
      onCompleted: onSuccess,
    })
  );

  const onClick = () => {
    if (confirmation) {
      deleteInstituteGroup();
    } else {
      setConfirmation(true);
    }
  };

  const text = confirmation ? "CONFIRM: This will delete this institute group" : "Delete Institute Group";

  return (
    <ButtonWithSpinner
      variant="contained"
      color="error"
      type="submit"
      onClick={onClick}
      showSpinner={remoteData.kind === "Loading"}
      disabled={remoteData.kind === "Loading"}
      endIcon={<Delete />}
    >
      {text}
    </ButtonWithSpinner>
  );
}
