import { Patient, Task, useCurrentTimeTrackingQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { TimeEntryLogId } from "Lib/Ids";
import React, { ReactElement, ReactNode, useContext } from "react";
import { Maybe } from "seidr";
import { PickTypename } from "type-utils";

// The poll interval for changes in milliseconds
const POLL_INTERVAL = 5 * 1000;

type CurrentTimeTracking =
  | {
      status: "tracking";
      task: PickTypename<Task, "id" | "title">;
      timeEntryLogId: TimeEntryLogId;
      patient: PickTypename<Patient, "id" | "name"> | null;
    }
  | { status: "not tracking" | "unknown" };

const CurrentTimeTrackingContext = React.createContext<CurrentTimeTracking>({ status: "unknown" });

export function CurrentTimeTrackingProvider({ children }: { children: ReactNode }): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useCurrentTimeTrackingQuery({
      // We want to check in on it
      pollInterval: POLL_INTERVAL,
    })
  );
  const current: CurrentTimeTracking = remoteData.caseOf<CurrentTimeTracking>({
    Loading: () => ({ status: "unknown" }),
    NotAsked: () => ({ status: "unknown" }),
    Failure: () => ({ status: "unknown" }),
    Success: (result) =>
      Maybe.fromNullable(
        result.collaborativeCareMyActiveTimeEntryLog?.timeEntryLog
      ).caseOf<CurrentTimeTracking>({
        Nothing: () => ({ status: "not tracking" }),
        Just: (tel) => ({
          status: "tracking",
          task: {
            __typename: "Task",
            id: tel.workFor.id,
            title: tel.workFor.title,
          },
          timeEntryLogId: tel.id,
          patient: tel.workFor.patient,
        }),
      }),
  });
  return (
    <CurrentTimeTrackingContext.Provider value={current}>{children}</CurrentTimeTrackingContext.Provider>
  );
}

export const useCurrentTimeTracking = () => useContext(CurrentTimeTrackingContext);
