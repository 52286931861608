import { Box, Grid, Paper, styled } from "@mui/material";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import { cocmBillingAlgorithmStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  CocmBillingAlgorithmStatus,
  EntityTreeNodeParams,
  EntityType,
} from "GeneratedGraphQL/SchemaAndOperations";
import { LinkButton } from "MDS/Link";
import EnumSelect from "Shared/EnumSelect";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import { useIsMobile } from "Shared/Responsive";
import { useStickyEntityTreeNodeParameter, useStickyEnumParameter } from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const allowedEntityTypes = [EntityType.INSTITUTE, EntityType.TREATMENT_SERVICE];

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

export type AlgorithmListFilters = {
  entityTreeNodeParams: EntityTreeNodeParams;
  setEntityTreeNodeParams: (params: EntityTreeNodeParams) => void;
  status: CocmBillingAlgorithmStatus;
  setStatus: (status: CocmBillingAlgorithmStatus) => void;
};

export function useAlgorithmListFilters(): AlgorithmListFilters {
  const [currentRootNode] = useCurrentRootNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    currentRootNode,
    true
  );

  const [status, setStatus] = useStickyEnumParameter(
    "status",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    CocmBillingAlgorithmStatus,
    CocmBillingAlgorithmStatus.ACTIVE,
    true
  );

  return {
    entityTreeNodeParams,
    setEntityTreeNodeParams,
    status,
    setStatus,
  };
}

type AlgorithmListFiltersProps = {
  filters: AlgorithmListFilters;
};
export default function AlgorithmListFilters(props: AlgorithmListFiltersProps): ReactElement {
  const { t } = useTranslation(["billing", "common"]);

  const [currentRootNode] = useCurrentRootNode();
  const { search } = useLocation();

  const rowSpacing = useIsMobile() ? 1 : 0;

  return (
    <StyledPaper>
      <Grid container rowSpacing={rowSpacing} spacing={1}>
        <StyledBox>
          <Grid container rowSpacing={rowSpacing} spacing={1}>
            <Grid item xs="auto" minWidth={"25em"}>
              <EntityTreeNodeSelect
                setValue={props.filters.setEntityTreeNodeParams}
                entityTypes={allowedEntityTypes}
                value={props.filters.entityTreeNodeParams}
                defaultValue={currentRootNode}
              />
            </Grid>
            <Grid item xs="auto">
              <EnumSelect
                optionsEnum={CocmBillingAlgorithmStatus}
                enumTrans={cocmBillingAlgorithmStatusT}
                value={props.filters.status}
                onChange={props.filters.setStatus}
                title={t("billing:algorithmList.status")}
              />
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item display="flex" flexGrow={1}>
          <Box sx={{ flexGrow: 1 }} />
          <LinkButton variant="contained" color="secondary" to={`create${search}`}>
            {t("billing:algorithmList.createAlgorithm")}
          </LinkButton>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
