import { Routes, Route } from "react-router-dom";
import React, { ReactElement } from "react";
import { TreatmentServicesRoutes } from "./TreatmentServices/TreatmentServicesRoutes";
import { OrganizationsPage } from "./Organizations/OrganizationsPage";

export function InstituteRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<TreatmentServicesRoutes />} path="/treatment-services/*" />
      <Route element={<OrganizationsPage />} path="/organizations/*" />
    </Routes>
  );
}
