import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { PropertyTable } from "MDS/PropertyTable";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CareEpisodeDetailsProps } from "./CareEpisodeDetails";
import CareEpisodeMeasurementAllowedSwitch from "./CareEpisodeMeasurementAllowedSwitch";

function CareEpisodeDetailsSettingsBox(props: CareEpisodeDetailsProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  return (
    <Card>
      <CardHeader title={t("careEpisodes:sections.settings")}></CardHeader>
      <CardContent>
        <Stack spacing={1}>
          <PropertyTable>
            <TableBody>
              <TableRow>
                <TableCell>{t("careEpisodes:fields.participatingInMBC")}</TableCell>
                <TableCell>
                  <CareEpisodeMeasurementAllowedSwitch
                    id={props.careEpisode.id}
                    measurementAllowed={props.careEpisode.measurementAllowed}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </PropertyTable>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default CareEpisodeDetailsSettingsBox;
