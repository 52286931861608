import React, { ReactElement } from "react";
import { useMatch } from "react-router-dom";
import { styled } from "@mui/material/styles";

const InlineFocusStyle = styled("span", { shouldForwardProp: (prop) => prop !== "focused" })<{
  focused: boolean;
}>(({ theme, focused }) => ({
  display: "inline-block",
  border: "1px solid",
  borderColor: focused ? theme.palette.text.secondary : "transparent",
  borderRadius: theme.spacing(0.25),
}));

const TableRowFocusStyle = styled("tr", { shouldForwardProp: (prop) => prop !== "focused" })<{
  focused: boolean;
}>(({ theme, focused }) => ({
  // You can't apply borders to a tr directly, so give them to all the constituent tds, but only on the sides that
  // are the edge of the row. I'm not sure why I need to repeat the borderColor expression in each block, but
  // something about how mui renders the classes maybe means that if I don't the borders stick around when they
  // should be transparent.
  "& td": {
    borderTop: "1px solid",
    borderBottom: "1px solid",
    borderColor: focused ? theme.palette.text.secondary : "transparent",

    "&:first-of-type": {
      borderLeft: "1px solid",
      borderColor: focused ? theme.palette.text.secondary : "transparent",
    },

    "&:last-of-type": {
      borderRight: "1px solid",
      borderColor: focused ? theme.palette.text.secondary : "transparent",
    },
  },
}));

export function InlineFocusHighlight(props: React.PropsWithChildren<{ route: string }>): ReactElement {
  const route = props.route.endsWith("*") ? props.route : props.route + "/*";
  const match = useMatch(route);
  return <InlineFocusStyle focused={!!match}>{props.children}</InlineFocusStyle>;
}

export function TableRowFocusHighlight(props: React.PropsWithChildren<{ route: string }>): ReactElement {
  const route = props.route.endsWith("*") ? props.route : props.route + "/*";
  const match = useMatch(route);
  return <TableRowFocusStyle focused={!!match}>{props.children}</TableRowFocusStyle>;
}
