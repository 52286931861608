import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

import RouterWithNotFound from "../../Shared/RouterWithNotFound";
import AdminRoutes from "./Admin/AdminRoutes";

function ProviderRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<AdminRoutes />} path="/admin/*" />
    </RouterWithNotFound>
  );
}

export default ProviderRoutes;
