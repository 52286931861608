import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  CoverageListQuery,
  CoverageSortParameter,
  Patient,
  useCoverageListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { coverageRelationshipT } from "GeneratedGraphQL/EnumTranslations";

type CoveragesCardElementProps = {
  patient: PickTypename<Patient, "id">;
};

export function CoveragesCard(props: CoveragesCardElementProps): ReactElement {
  const { t } = useTranslation(["patients", "collaborativeCare", "enums"]);

  const columns: DataGridCols<CoverageListQuery, ["billingCoverages"]> = [
    {
      field: "payor",
      headerName: t("patients:coverage.payor"),
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return `${params.row.institutePayor.name} (${params.row.institutePayor.primaryId})`;
      },
    },
    {
      field: "subscriberNumber",
      headerName: t("patients:coverage.subscriberNumber"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return params.row.subscriberNumber;
      },
    },
    {
      field: "relationship",
      headerName: t("patients:coverage.relationship"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return params.row.relationship ? coverageRelationshipT(params.row.relationship, t) : "";
      },
    },
    {
      field: "dates",
      headerName: t("patients:coverage.dates"),
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        const { startDate, endDate } = params.row;

        if (endDate) {
          return t("patients:coverage.dateBoth", { startDate, endDate });
        } else {
          return t("patients:coverage.dateStart", { date: startDate });
        }
      },
    },
  ];
  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.coverages")} />
      <CardContent>
        <SortablePagableCollectionDataGrid
          queryHook={useCoverageListQuery}
          queryVariables={{ patientId: props.patient.id }}
          unwrapData={(response) => response?.billingCoverages || null}
          colNameToSortParam={() => {
            return CoverageSortParameter.ID;
          }}
          columns={columns}
          defaultPageSize={5}
          getRowId={(row) => row.id.toString()}
          autoHeight
        />
        <Typography>{t("patients:coverage.ehrOnly")}</Typography>
      </CardContent>
    </Card>
  );
}
