import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import EntityPath, { EntityTreeNodeDetails } from "Entities/EntityPath";
import {
  EntityMeasurementPlan,
  MeasurementPlan,
  MeasurementPlanScale,
  Scale,
} from "GeneratedGraphQL/SchemaAndOperations";
import { scaleMediumName } from "Shared/Scale/Scale";
import React from "react";
import { useTranslation } from "react-i18next";

type MeasurementPlanScaleDetails = Pick<
  MeasurementPlanScale,
  | "alternationGroup"
  | "discontinueSession"
  | "discontinueTime"
  | "format"
  | "maxRepeatInterval"
  | "onsetSession"
  | "onsetTime"
  | "phase"
  | "repeatSessions"
  | "measureOnsetFrom"
  | "oncePerPeriod"
  | "rolloutBefore"
  | "rolloutPeriod"
> & { scale: Pick<Scale, "id" | "name" | "shortname"> };
type MeasurementPlanDetails = Pick<MeasurementPlan, "name" | "description"> & {
  measurementPlanScales: ReadonlyArray<MeasurementPlanScaleDetails>;
};
type EntityMeasurementPlanSummary = Pick<EntityMeasurementPlan, "id" | "planType"> & {
  measurementPlan: MeasurementPlanDetails;
  entityTreeNode: EntityTreeNodeDetails;
};

function PlanRow(props: { entityMeasurementPlan: EntityMeasurementPlanSummary }) {
  return (
    <TableRow>
      <TableCell>
        <Stack direction={"column"} spacing={0.5}>
          <Typography>{props.entityMeasurementPlan.measurementPlan.name}</Typography>
          <Typography variant="subtitle1">
            {props.entityMeasurementPlan.measurementPlan.measurementPlanScales
              .map((planScale) => scaleMediumName(planScale.scale))
              .join(", ")}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <EntityPath entityTreeNode={props.entityMeasurementPlan.entityTreeNode} includeInstitute={"never"} />
      </TableCell>
    </TableRow>
  );
}

export default function EntityMeasurementPlanTable(props: {
  entityMeasurementPlans: ReadonlyArray<EntityMeasurementPlanSummary>;
}) {
  const { t } = useTranslation(["care"]);

  const rows =
    props.entityMeasurementPlans.length > 0 ? (
      props.entityMeasurementPlans.map((emp) => {
        return <PlanRow key={emp.id.toString()} entityMeasurementPlan={emp} />;
      })
    ) : (
      <TableRow>
        <TableCell colSpan={3}>{t("care:entityMeasurementPlans.noMatching")}</TableCell>
      </TableRow>
    );
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography fontWeight="bold">{t("care:entityMeasurementPlans.columns.name")}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">{t("care:entityMeasurementPlans.columns.appliesTo")}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{rows}</TableBody>
    </Table>
  );
}
