import { MutationRemoteDataResult } from "Api/GraphQL";
import { useEffectSimpleCompare } from "Lib/Hooks";
import SettingSwitch from "MDS/SettingSwitch";
import React, { ChangeEvent, ReactElement } from "react";

type ImmediateSettingsSwitchProps = {
  value: boolean;
  label: string;
  description: string;
  remoteData: MutationRemoteDataResult<unknown>;
  query: (setting: boolean) => void;
};

// A settings switch that immediately fires the mutation query to update its setting when the user clicks on it. The
// `query` and `remoteData` parameters should be constructed from the return values of an apolloMutationHookWrapper so
// that the only variable this component needs to supply to execute the query is the new boolean value of the switch.
// For example:
//
//    const [updateSetting, { remoteData }] = apolloMutationHookWrapper(
//     data => data.updateSettingMutationResult,
//     useUpdateSettingMutation({
//       refetchQueries: refetchQueries("appropriateSettingsCategory", {
//        appropriateSettingsDocumentName: {
//          id: props.id,
//        },
//      }),
//     })
//    )
//    const query = (setting: boolean) => {
//      updateSetting({
//        variables: { id: props.id, newValue: setting }
//      })
//    }
//
export default function ImmediateSettingsSwitch(props: ImmediateSettingsSwitchProps): ReactElement {
  // Track the actual state (allowed) and the state we want to get to (desiredAllowed) separately so that we can easily
  // revert the desired state if we get an error without getting into an infinite re-render loop.
  const [value, setValue] = React.useState(props.value);
  const [desiredValue, setDesiredValue] = React.useState<boolean | undefined>(undefined);

  // If the props passed into the component change without the component unmounting, the value we store in state here
  // can get unsynced from the value in the props, leading the switch to display the wrong value. Fix this by
  // overwriting the value in state with the one from props.
  useEffectSimpleCompare(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDesiredValue(event.target.checked);
    props.query(event.target.checked);
  };

  props.remoteData.caseOf({
    Failure: () => {
      if (desiredValue !== undefined) {
        setDesiredValue(undefined);
      }
    },
    Success: () => {
      if (desiredValue !== undefined && value !== desiredValue) {
        setValue(desiredValue);
        setDesiredValue(undefined);
      }
    },
    _: () => {
      return;
    },
  });

  return (
    <SettingSwitch
      label={props.label}
      checked={desiredValue === undefined ? value : desiredValue}
      description={props.description}
      onChange={onChange}
      queryStatus={props.remoteData}
    />
  );
}
