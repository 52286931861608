import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { severityCategoryT } from "GeneratedGraphQL/EnumTranslations";
import { ScaleThresholdClass, SeverityCategory } from "GeneratedGraphQL/SchemaAndOperations";
import { enumFromStringValue } from "Shared/Enum";
import { SeverityChipBase } from "Shared/Scale/SeverityChip";
import React from "react";
import { useTranslation } from "react-i18next";

function severityCategoryToThresholdClass(severityCategory: SeverityCategory): ScaleThresholdClass {
  switch (severityCategory) {
    case SeverityCategory.MILD:
      return ScaleThresholdClass.MILD;
    case SeverityCategory.MODERATE:
      return ScaleThresholdClass.MODERATE;
    case SeverityCategory.SEVERE:
      return ScaleThresholdClass.SEVERE;
    case SeverityCategory.NONE:
      return ScaleThresholdClass.NONE;
    default:
      return ScaleThresholdClass.UNKNOWN;
  }
}

export function SeverityCategoryChip(props: { severityCategory: SeverityCategory }) {
  const { t } = useTranslation(["enums"]);
  return (
    <SeverityChipBase
      thresholdClass={severityCategoryToThresholdClass(props.severityCategory)}
      isStale={false}
    >
      {severityCategoryT(props.severityCategory, t)}
    </SeverityChipBase>
  );
}

export function SeveritySelect(props: {
  severity: SeverityCategory;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
  onChange: (trend: SeverityCategory) => void;
  disabled: boolean;
}) {
  const { severity, onChange, disabled } = props;

  const handleOnChange = (event: SelectChangeEvent<SeverityCategory>) => {
    const parsed = event.target.value
      ? enumFromStringValue<SeverityCategory>(SeverityCategory, event.target.value)
      : undefined;

    // We don't do an update if we can't parse it, but given the constraints it should never occur
    if (parsed) {
      onChange(parsed);
    }
  };

  const items = props.supportedSeverities.map((severity) => {
    return (
      <MenuItem key={severity} value={severity}>
        <SeverityCategoryChip severityCategory={severity} />
      </MenuItem>
    );
  });

  // Note that we don't want to support all the trends, only the valid ones, so this isn't a full enum
  return (
    <FormControl>
      <Select sx={{ height: "2em" }} value={severity} onChange={handleOnChange} disabled={disabled}>
        {items}
      </Select>
    </FormControl>
  );
}

export function SeveritySelectMultiple(props: {
  severity: ReadonlyArray<SeverityCategory>;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
  onChange: (severities: ReadonlyArray<SeverityCategory>) => void;
  disabled: boolean;
}) {
  const { severity, onChange, disabled } = props;

  const handleOnChange = (event: SelectChangeEvent<ReadonlyArray<SeverityCategory>>) => {
    const finalArray: Array<SeverityCategory> = [];
    Array.isArray(event.target.value)
      ? event.target.value.forEach((value) => {
          const parsed = enumFromStringValue<SeverityCategory>(SeverityCategory, value);

          if (parsed) {
            finalArray.push(parsed);
          }
        })
      : null;

    onChange(finalArray);
  };

  const items = props.supportedSeverities.map((severity) => {
    return (
      <MenuItem key={severity} value={severity}>
        <SeverityCategoryChip severityCategory={severity} />
      </MenuItem>
    );
  });

  // Note that we don't want to support all the trends, only the valid ones, so this isn't a full enum
  return (
    <FormControl>
      <Select multiple sx={{ height: "2em" }} value={severity} onChange={handleOnChange} disabled={disabled}>
        {items}
      </Select>
    </FormControl>
  );
}

function severityToPriority(severity: SeverityCategory) {
  switch (severity) {
    case SeverityCategory.NONE:
      return 0;
    case SeverityCategory.MILD:
      return 1;
    case SeverityCategory.MODERATE:
      return 2;
    case SeverityCategory.SEVERE:
      return 3;
    case SeverityCategory.INVALID:
      return 4;
    case SeverityCategory.NOT_SPECIFIED:
      return 5;
    case SeverityCategory.NOT_SUPPORTED:
      return 6;
  }
}

export function sortSeverities(severities: ReadonlyArray<SeverityCategory>): ReadonlyArray<SeverityCategory> {
  return [...severities].sort((a, b) => severityToPriority(a) - severityToPriority(b));
}
