import { EntityDecisionAlgorithmStatus } from "GeneratedGraphQL/SchemaAndOperations";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import EnumSelect from "Shared/EnumSelect";
import { useTranslation } from "react-i18next";
import { entityDecisionAlgorithmStatusT } from "GeneratedGraphQL/EnumTranslations";
import { useQueryStringOptionalEnumParameter } from "Shared/QueryStringParameter";

export type AlgorithmFilters = {
  status: EntityDecisionAlgorithmStatus | null;
  setStatus: (status: EntityDecisionAlgorithmStatus | null) => void;
};

const DEFAULT_FILTERS = {
  status: EntityDecisionAlgorithmStatus.ACTIVE,
};

export function useAlgorithmFilters(): AlgorithmFilters {
  const [status, setStatus] = useQueryStringOptionalEnumParameter<EntityDecisionAlgorithmStatus>(
    "algorithmStatus",
    EntityDecisionAlgorithmStatus,
    DEFAULT_FILTERS.status,
    true
  );

  return { status, setStatus };
}

const SelectContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(20),
}));

type AlgorithmFiltersProps = {
  filters: AlgorithmFilters;
};

export function AlgorithmFilters(props: AlgorithmFiltersProps) {
  const { t } = useTranslation(["decisionSupport"]);
  return (
    <SelectContainer>
      <EnumSelect
        optionsEnum={EntityDecisionAlgorithmStatus}
        value={props.filters.status}
        onChange={props.filters.setStatus}
        title={t("decisionSupport:algorithms.filters.status")}
        enumTrans={entityDecisionAlgorithmStatusT}
      />
    </SelectContainer>
  );
}
