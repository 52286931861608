import React, { ReactElement } from "react";
import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Page from "./Layout/Page";

const StyledBox = styled(Box)(() => ({
  textAlign: "center",
}));

function AppLoader(): ReactElement {
  return (
    <Page browserTitle="Loading Mirah">
      <StyledBox sx={{ p: 30 }}>
        <CircularProgress />
      </StyledBox>
    </Page>
  );
}

export default AppLoader;
