import React, { ChangeEvent, ReactElement } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import MuiSwitch from "@mui/material/Switch";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { alpha } from "@mui/material/styles";
import { MutationRemoteDataResult } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import { FormHelperText } from "@mui/material";
import { useTranslation } from "react-i18next";

type SwitchProps = {
  label: string;
  checked: boolean;
  description: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // Because the switch is such a simple control it doesn't care about the type of the data coming back from the
  // mutation query. Sticking an unknown here lets us use the same component for everything as long as we don't try
  // to do anything with the response.
  queryStatus: MutationRemoteDataResult<unknown>;
};

const GreenSwitch = styled(MuiSwitch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.report.scaleThreshold.Low.primary, //pink[600],
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.report.scaleThreshold.Low.primary,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.report.scaleThreshold.Low.primary,
  },
}));

const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.report.scaleThreshold.Low.primary,
}));

const StyledDoNotDisturbIcon = styled(DoNotDisturbIcon)(({ theme }) => ({
  color: theme.palette.report.scaleThreshold.Severe.primary,
}));

const VerticalCenteredLabel = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
}));

const IconSpacing = styled("span")(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
}));

// This specific version is meant to be inserted as a child into a FormGroup component.
export function SettingSwitch(props: SwitchProps): ReactElement {
  const { t } = useTranslation(["settings"]);

  const icon = props.queryStatus.caseOf({
    Loading: () => <Spinner size="1.25rem" />,
    _: () => (props.checked ? <StyledCheckIcon /> : <StyledDoNotDisturbIcon />),
  });

  // With a single boolean form like this really the only thing that can go wrong is unknown 500 errors. Our graphql
  // error typing isn't fully baked right now, so I'm just swallowing the error value instead of really trying here.
  const errorText = props.queryStatus.caseOf({
    Failure: () => <FormHelperText error>{t("settings:errors.unknown")}</FormHelperText>,
    _: () => null,
  });

  const label = (
    <VerticalCenteredLabel>
      {props.label}:<IconSpacing />
      {icon}
    </VerticalCenteredLabel>
  );

  const switchControl = (
    <GreenSwitch
      onChange={props.onChange}
      checked={props.checked}
      inputProps={{ "aria-label": "controlled" }}
    />
  );

  return (
    <Tooltip title={props.description} placement="bottom">
      <span>
        <FormControlLabel control={switchControl} label={label} />
        {errorText}
      </span>
    </Tooltip>
  );
}

export default SettingSwitch;
