import React, { ReactElement } from "react";

type Props = {
  message: string;
};

function ErrorMessage(props: Props): ReactElement {
  return <div data-testid="error-message">{props.message}</div>;
}

export default ErrorMessage;
