import { Badge, BadgeProps, styled } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { getCurrentUser } from "AppSession/AppSession";
import {
  ReportProviderObligationsEnum,
  useAssessmentReportProviderObligationsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId, ReportId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import AssessmentIcon from "@mui/icons-material/Assessment";

type AppointmentFeedbackReportButtonProps = {
  patientId: PatientId;
  reportId: ReportId | undefined;
  // isReportComplete: boolean | undefined; // we don't have a different icon treatment for complete vs partial reports yet
  isReportAvailable: boolean | undefined;
};

function AppointmentFeedbackReportButton(props: AppointmentFeedbackReportButtonProps): ReactElement {
  // Note: the logic that ember does to redirect
  //       to the appropriate feedback report has been short circuited
  // longitudinal in ember: https://github.com/mirahtech/ember-frontend/blob/master/app/pods/provider/patients/show/longitudinal/route.js
  // const emberReportPath = `/provider/patients/${props.patientId}/longitudinal`;
  // Instead, we are just directly linking to the appropriate report using Contextual Report Link
  const { patientId, reportId, isReportAvailable } = props;

  const providerId = getCurrentUser().caseOf({
    Just: (user) => {
      return user.providerId.caseOf({
        Just: (id) => {
          return id;
        },
        _: () => {
          return undefined;
        },
      });
    },
    _: () => {
      return undefined;
    },
  });

  // Obligations are calculated independent of a provider sessions' report status
  // it shouldn't be the case that if a report_id and report is created that
  // the session reverts to a report being incomplete or unavailable, but i'm
  // adding this note here in case we run into this in the future and have a complaint of
  // "disappearing" obligations
  const content =
    reportId && providerId ? (
      <ObligationsBadge reportId={reportId} providerId={providerId} />
    ) : (
      <AssessmentIcon fontSize="large" />
    );
  return isReportAvailable ? (
    <ContextualReportLink patient={patientId}>{content}</ContextualReportLink>
  ) : (
    <></>
  );
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  "& .MuiBadge-badge": {
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    height: "15px",
    minWidth: "15px",
  },
}));

type ObligationsBadgeProps = {
  reportId: ReportId;
  providerId: ProviderId;
};
function ObligationsBadge(props: ObligationsBadgeProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useAssessmentReportProviderObligationsQuery({
      variables: {
        reportId: props.reportId.toString(),
        providerId: props.providerId.toString(),
      },
    })
  );

  const obligations = remoteData.caseOf({
    Success: (result) => {
      if (result.assessmentReportProviderObligations) {
        return result.assessmentReportProviderObligations.obligations;
      } else {
        return [];
      }
    },
    _: () => {
      return [];
    },
  });

  const hasObligation = obligations.includes(ReportProviderObligationsEnum.VIEW);

  return (
    <StyledBadge badgeContent=" " color="warning" overlap="circular" invisible={!hasObligation}>
      <AssessmentIcon fontSize="large" />
    </StyledBadge>
  );
}
export default AppointmentFeedbackReportButton;
