import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  PanelStatus,
  PanelTeamMember,
  PanelTeamMemberFunction,
  PanelTeamMemberStatus,
  usePanelsListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PanelId, PanelTeamMemberId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as Id from "Lib/Id";
import { PickTypename } from "type-utils";
import { TFunction } from "i18next";

type FieldData<T> = {
  value: T | undefined;
  onChange: (newValue: T) => void;
  error?: boolean;
  helperText?: string | null;
};

type PanelCareTeamSelectProps = {
  panel: FieldData<PanelId>;
  primaryCareProvider: FieldData<PanelTeamMemberId>;
  careManager: FieldData<PanelTeamMemberId>;
};

export function PanelCareTeamSelect(props: PanelCareTeamSelectProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  return (
    <Stack direction="column" spacing={1}>
      <PanelSelect
        panelId={props.panel.value}
        onChange={props.panel.onChange}
        error={props.panel.error}
        helperText={props.panel.helperText}
      />
      <TeamMemberSelect
        panelId={props.panel.value}
        tmFunction={PanelTeamMemberFunction.PRIMARY_CARE}
        label={t("patients:details.primaryCareProvider")}
        teamMemberId={props.primaryCareProvider.value}
        onChange={props.primaryCareProvider.onChange}
        error={props.primaryCareProvider.error}
        helperText={props.primaryCareProvider.helperText}
      />
      <TeamMemberSelect
        panelId={props.panel.value}
        tmFunction={PanelTeamMemberFunction.CARE_MANAGER}
        label={t("patients:details.careManager")}
        teamMemberId={props.careManager.value}
        onChange={props.careManager.onChange}
        error={props.careManager.error}
        helperText={props.careManager.helperText}
      />
    </Stack>
  );
}

type PanelSelectProps = {
  panelId: PanelId | undefined;
  onChange: (newId: PanelId) => void;
  error?: boolean;
  helperText?: string | null;
};

function PanelSelect(props: PanelSelectProps): ReactElement {
  const { t } = useTranslation(["patients"]);
  const { remoteData } = apolloQueryHookWrapper(usePanelsListQuery());

  const options = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCarePanels) {
        // You can only assign someone to an active panel, but if they're already in an inactive panel we need to keep
        // it in the dropdown to make everything coherent.
        const selectablePanels = response.collaborativeCarePanels.nodes.filter(
          (panel) => panel.status === PanelStatus.ACTIVE || panel.id === props.panelId
        );
        selectablePanels.sort((a, b) => a.name.localeCompare(b.name));
        return selectablePanels;
      } else {
        return [];
      }
    },
    _: () => [],
  });

  return (
    <FormControl>
      <InputLabel>Panel</InputLabel>
      <Select
        label={t("patients:details.panel")}
        value={props.panelId ? props.panelId.toString() : ""}
        onChange={(event) => props.onChange(Id.unsafeFromUuid<"Panel">(event.target.value))}
        error={props.error}
      >
        {options.map((option) => (
          <MenuItem value={option.id.toString()} key={option.id.toString()}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}

type TeamMemberSelectProps = {
  panelId: PanelId | undefined;
  tmFunction: PanelTeamMemberFunction;
  label: string;
  teamMemberId: PanelTeamMemberId | undefined;
  onChange: (newId: PanelTeamMemberId) => void;
  error?: boolean;
  helperText?: string | null;
};

function TeamMemberSelect(props: TeamMemberSelectProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(usePanelsListQuery());

  const options = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCarePanels) {
        const panel = response.collaborativeCarePanels.nodes.find((panel) => panel.id === props.panelId);

        if (!panel) {
          return [];
        }

        // You can only select active team members with the right role, but to keep the dropdown coherent also include
        // the team member they already have selected.
        const includeTeamMember = (
          teamMember: PickTypename<PanelTeamMember, "id" | "status" | "function">
        ) => {
          if (teamMember.id === props.teamMemberId) {
            return true;
          } else if (
            teamMember.status === PanelTeamMemberStatus.ACTIVE &&
            teamMember.function === props.tmFunction
          ) {
            return true;
          } else {
            return false;
          }
        };

        const members = panel.teamMembers.filter(includeTeamMember);
        members.sort((a, b) => a.provider.name.localeCompare(b.provider.name));
        return members;
      } else {
        return [];
      }
    },
    _: () => [],
  });

  const [disabled, errorMessage] = teamMemberSelectValidity(props.helperText, props.panelId, options, t);

  return (
    <FormControl>
      <InputLabel>{props.label}</InputLabel>
      <Select
        disabled={disabled}
        label={props.label}
        value={props.teamMemberId ? props.teamMemberId.toString() : ""}
        onChange={(event) => props.onChange(Id.unsafeFromUuid<"PanelTeamMember">(event.target.value))}
        error={props.error}
      >
        {options.map((option) => (
          <MenuItem value={option.id.toString()} key={option.id.toString()}>
            {option.provider.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}

function teamMemberSelectValidity(
  helperText: string | undefined | null,
  panelId: PanelId | undefined,
  options: ReadonlyArray<unknown>,
  t: TFunction<["collaborativeCare"]>
): [boolean, string | null | undefined] {
  if (helperText !== undefined && helperText !== null) {
    return [false, helperText];
  } else if (panelId === undefined) {
    return [true, t("collaborativeCare:panels.fields.noPanelSelected")];
  } else if (options.length === 0) {
    return [true, t("collaborativeCare:panels.fields.noRelevantTeamMembers")];
  } else {
    return [false, null];
  }
}
