import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { CircularProgress } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  TreatmentService,
  TreatmentServiceTreeQuery,
  useTreatmentServiceTreeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TreatmentServiceId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ancestry, treeify } from "Shared/Ancestry";
import ErrorMessage from "Shared/ErrorMessage";
import { OnlyExpandOnIconClickTreeItem } from "Shared/OnlyExpandOnIconClickTreeItem";

type TreatmentServiceSummary = Pick<TreatmentService, "__typename" | "id" | "name" | "parentId">;

type TreatmentServiceTree = TreatmentServiceSummary & {
  children: Array<TreatmentServiceTree>;
};

function TreatmentServiceTreeItem(props: TreatmentServiceTree): ReactElement {
  return (
    <OnlyExpandOnIconClickTreeItem nodeId={props.id.toString()} label={props.name}>
      {props.children.map((o, i) => (
        <TreatmentServiceTreeItem {...o} key={i} />
      ))}
    </OnlyExpandOnIconClickTreeItem>
  );
}

type TreatmentServiceTreeProps = {
  selectedTreatmentService: TreatmentServiceId | null;
  setSelectedTreatmentService: (newOrg: TreatmentServiceId) => void;
};

function TreatmentServiceTree(props: TreatmentServiceTreeProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(useTreatmentServiceTreeQuery());

  // We don't want the default expanded items to change, so we need to keep the initial selection
  const [initialOrg] = useState<TreatmentServiceId | null>(props.selectedTreatmentService);

  function handleNodeSelect(_event: React.SyntheticEvent, id: Array<string> | string) {
    props.setSelectedTreatmentService(id as unknown as TreatmentServiceId);
  }

  const tree = (orgQuery: TreatmentServiceTreeQuery): ReactElement => {
    const orgs = orgQuery.treatmentServices?.nodes || [];
    const tree = treeify(orgs);
    const ancestors = ancestry(orgs, initialOrg);

    return (
      <TreeView
        aria-label={t("common:treatmentServices.selector")}
        selected={props.selectedTreatmentService?.toString()}
        onNodeSelect={handleNodeSelect}
        multiSelect={false}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={ancestors}
        disabledItemsFocusable
      >
        {tree.map((o, i) => (
          <TreatmentServiceTreeItem {...o} key={i} />
        ))}
      </TreeView>
    );
  };

  return remoteData.caseOf({
    Success: tree,
    Loading: () => <CircularProgress />,
    NotAsked: () => <CircularProgress />,
    Failure: (e) => <ErrorMessage message={e.toString()} />,
  });
}

export default TreatmentServiceTree;
