import { Grid, Stack, Typography } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Patient, useMbcPatientDetailsHeaderQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PickTypename } from "type-utils";
import { PatientId } from "Lib/Ids";
import PatientDetailsCard from "./PatientDetailsCard";
import RelatedPeopleCard from "./RelatedPeopleCard";
import { PatientActionsCard } from "./PatientActionsCard";
import PatientInfoCard from "./PatientInfoCard";
import { PatientConditionsCard } from "./PatientConditionsCard";
import { UserConsentsCard } from "./UserConsentsCard";
import { CoveragesCard } from "./CoveragesCard";
import { CareEpisodesCard } from "./CareEpisodesCard";
import { RecentAppointmentsCard, UpcomingAppointmentsCard } from "./AppointmentsCard";
import { ActiveInvitationsCard } from "./ActiveInvitationsCard";

export type PatientDetails = PickTypename<
  Patient,
  | "id"
  | "phoneNumber"
  | "email"
  | "mrn"
  | "name"
  | "dob"
  | "genderIdentity"
  | "hasCompletedAssessment"
  | "race"
  | "ethnicity"
  | "primaryLanguage"
  | "preferredPronouns"
  | "notificationPreference"
  | "notificationPreferenceWithDefault"
  | "enableNotifications"
>;

// PatientDetails is separated into two views, one mobile and one desktop.
// We do this because we're also going to position: sticky the patient details card.
// This sticky element needs to have a single ancestor that it can scroll through.
export function PatientDetails(props: { patientId: PatientId }): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useMbcPatientDetailsHeaderQuery({ variables: { patientId: props.patientId } })
  );

  return remoteData.caseOf({
    Failure: () => <Typography>{t("common:remoteData.failure")}</Typography>,
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Success: (data) => {
      if (data.patient) {
        return <PatientDetailsElement patient={data.patient} />;
      } else {
        return <PatientNotFound />;
      }
    },
  });
}

function PatientNotFound() {
  const { t } = useTranslation(["common"]);
  return <Typography>{t("common:remoteData.failure")}</Typography>;
}

type PatientDetailsElementProps = {
  patient: PatientDetails;
};
function PatientDetailsElement(props: PatientDetailsElementProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    // If you're already on a page, then you don't care about institute grouping and just want to see the patient.
    <Page
      browserTitle={t("collaborativeCare:patientDetails.title")}
      instituteGroupOverride="institute-scoped"
    >
      <Grid container columns={12} spacing={1}>
        <Grid item lg={4} xs={12}>
          <Stack direction="column" minHeight={"100%"} spacing={1} flexGrow={1} flexDirection={"column"}>
            <LeftPanel patient={props.patient} />
          </Stack>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Stack direction="column" spacing={1}>
            <RightPanel patient={props.patient} />
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
}

type PanelProps = {
  patient: PatientDetails;
};

function LeftPanel(props: PanelProps): ReactElement {
  return (
    <>
      <PatientDetailsCard patientId={props.patient.id} disableLink />
      <PatientActionsCard patient={props.patient} />
      <PatientInfoCard patient={props.patient} />
    </>
  );
}

function RightPanel(props: PanelProps): ReactElement {
  return (
    <>
      <ActiveInvitationsCard patientId={props.patient.id} />
      <UpcomingAppointmentsCard patientId={props.patient.id} />
      <RecentAppointmentsCard patientId={props.patient.id} />
      <RelatedPeopleCard patient={props.patient} />
      <CareEpisodesCard patient={props.patient} />
      <PatientConditionsCard patient={props.patient} />
      <UserConsentsCard patient={props.patient} />
      <CoveragesCard patient={props.patient} />
    </>
  );
}
