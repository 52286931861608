import { Card, CardContent, CardHeader } from "@mui/material";
import {
  CareEpisodeListQuery,
  CareEpisodeSortParameter,
  DataSourceResourceType,
  Patient,
  SortDirection,
  useCareEpisodeListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { careEpisodeStatusT } from "GeneratedGraphQL/EnumTranslations";
import Link from "MDS/Link";
import { IntegrationStatusBadgeInline, toImportHistoryId } from "Integrations/IntegrationStatusBadge";
import { careEpisodeDates } from "Patients/CareEpisodes2/CareEpisodeHelpers";

type CareEpisodesCardElementProps = {
  patient: PickTypename<Patient, "id">;
};

export function CareEpisodesCard(props: CareEpisodesCardElementProps): ReactElement {
  const { t } = useTranslation(["careEpisodes", "patients", "collaborativeCare", "enums"]);

  const columns: DataGridCols<CareEpisodeListQuery, ["careEpisodes"]> = [
    {
      field: "shortname",
      headerName: t("patients:careEpisodes.shortname"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`care-episodes-new/${params.row.id}`}>{params.row.shortname}</Link>
            <IntegrationStatusBadgeInline
              importHistoryId={toImportHistoryId(params.row.id)}
              resourceType={DataSourceResourceType.CARE_EPISODE}
            />
          </>
        );
      },
    },
    {
      field: "status",
      headerName: t("patients:careEpisodes.status"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return careEpisodeStatusT(params.row.status, t);
      },
    },
    {
      field: "dates",
      headerName: t("patients:careEpisodes.dates"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        const { periodStart, periodEnd } = params.row;
        return careEpisodeDates(periodStart, periodEnd, t);
      },
    },
  ];
  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.careEpisodes")} />
      <CardContent>
        <SortablePagableCollectionDataGrid
          queryHook={useCareEpisodeListQuery}
          queryVariables={{ patientId: props.patient.id }}
          unwrapData={(response) => response?.careEpisodes || null}
          colNameToSortParam={(field) => {
            switch (field) {
              case "status":
                return CareEpisodeSortParameter.STATUS;
              default:
                return CareEpisodeSortParameter.PERIODSTART;
            }
          }}
          defaultSortParams={{
            sortBy: CareEpisodeSortParameter.PERIODSTART,
            sortDirection: SortDirection.DESC,
          }}
          columns={columns}
          defaultPageSize={5}
          getRowId={(row) => row.id.toString()}
          autoHeight
        />
      </CardContent>
    </Card>
  );
}
