import React, { ReactNode, ReactElement } from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledGrid = styled(Grid)(() => ({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: "rgba(255, 255, 255, 0.85)",
}));

function Overlay(props: { children: ReactNode }): ReactElement {
  return (
    <StyledGrid sx={{ zIndex: "tooltip" }} alignContent="center" justifyContent="center" container>
      {props.children}
    </StyledGrid>
  );
}

export default Overlay;
