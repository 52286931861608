import { Gender } from "GeneratedGraphQL/SchemaAndOperations";
import { resources } from "i18n";
import { exhaustiveGuard } from "type-utils";

// Converts the gender enum to a shorter translated form.
// Suitable for datagrids.
// This isn't a component so we can keep the result as string data, so we need to
// manually pass in our translation function.
export function shortGender(
  gender: Gender,
  t: (t: `common:gender.short.${keyof (typeof resources)["en"]["common"]["gender"]["short"]}`) => string
) {
  switch (gender) {
    case Gender.FEMALE:
      return t("common:gender.short.female");
    case Gender.MALE:
      return t("common:gender.short.male");
    case Gender.OTHER:
      return t("common:gender.short.nonbinary");
    case Gender.UNKNOWN:
      return t("common:gender.short.unknown");
    default:
      return exhaustiveGuard(gender);
  }
}

export function genderStringFromEnum(gender: Gender): string | null {
  switch (gender) {
    case Gender.FEMALE:
      return "F";
    case Gender.MALE:
      return "M";
    case Gender.OTHER:
      return "O";
    default:
      return null;
  }
}

export function genderEnumFromString(string: string): Gender {
  switch (string) {
    case "M":
      return Gender.MALE;
    case "F":
      return Gender.FEMALE;
    case "O":
      return Gender.OTHER;
    default:
      return Gender.UNKNOWN;
  }
}
