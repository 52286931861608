import {
  EhrLinkLogListQuery,
  EhrLinkLogStatus,
  useEhrLinkLogListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useState } from "react";

import { EhrLinkId } from "Lib/Ids";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { Just, Maybe, Nothing } from "seidr";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

type InstituteDetailsProps = {
  ehrLinkId: Array<EhrLinkId>;
};

function EhrLinkLogList(props: InstituteDetailsProps): ReactElement {
  const { ehrLinkId } = props;
  const extra: {
    ehrLinkId: ReadonlyArray<EhrLinkId> | EhrLinkId | null;
    status: ReadonlyArray<EhrLinkLogStatus> | EhrLinkLogStatus | null;
  } = {
    ehrLinkId: ehrLinkId,
    status: null,
  };

  const [dialogContent, setDialogContent] = useState<Maybe<string>>(Nothing());

  const dialog = dialogContent.caseOf({
    Nothing: () => null,
    Just: (contentString) => (
      <LogDialog logData={contentString} handleClose={() => setDialogContent(Nothing())} />
    ),
  });

  const columns: DataGridCols<EhrLinkLogListQuery, ["integrationsEhrLinkLogs"]> = React.useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => row.id.toString(),
      },
      {
        field: "startDate",
        headerName: "Start Date",
        filterable: false,
        sortable: false,
        valueGetter: (_value, row) => row.startDate.toLocaleString(),
        flex: 1,
      },
      {
        field: "status",
        headerName: "Status",
        filterable: false,
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => row.status.toString(),
      },
      {
        field: "log",
        headerName: "Log",
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return Maybe.fromNullable(params.row.log).caseOf({
            Just: (log) => (
              <Button
                onClick={() => {
                  setDialogContent(Just(log));
                }}
              >
                Check Logs
              </Button>
            ),
            Nothing: () => null,
          });
        },
      },
    ];
  }, []);

  return (
    <>
      <Paper>
        <SortablePagableCollectionDataGrid
          queryHook={useEhrLinkLogListQuery}
          unwrapData={(d?: EhrLinkLogListQuery | null) => d?.integrationsEhrLinkLogs || null}
          queryVariables={extra}
          defaultPageSize={10}
          colNameToSortParam={(_colName) => null}
          columns={columns}
        />
      </Paper>
      {dialog}
    </>
  );
}

const StyledCodeContent = styled("pre")(({ theme }) => ({
  color: theme.palette.common.black,
}));

function LogDialog({ logData, handleClose }: { logData: string; handleClose: () => void }): ReactElement {
  return (
    <Dialog fullScreen open onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography>Connection Log</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          <StyledCodeContent>
            <code>{logData}</code>
          </StyledCodeContent>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>CLOSE</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EhrLinkLogList;
