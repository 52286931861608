import React from "react";
import { useParams } from "react-router-dom";
import { CareEpisodeId } from "Lib/Ids";
import { FeedbackReportContext } from "../FeedbackReportContext";
import ScaleAdministrationHistoryPane from "./ScaleAdministrationHistoryPane";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { DetailsWrapper } from "../DetailsWrapper";
import { useTranslation } from "react-i18next";

type ScaleHistoryProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

export const ScaleHistoryRoute = (props: ScaleHistoryProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{ scaleId?: string }>();
  const scaleId = Id.fromNullableString<"Scale">(params.scaleId);

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [t("report:breadcrumbs.measureHistory")],
  ];

  return scaleId.caseOf({
    Err: (error) => <ErrorMessage message={error.message} />,
    Ok: (scaleId) => (
      <DetailsWrapper breadcrumbs={breadcrumbs}>
        <ScaleAdministrationHistoryPane
          scaleId={scaleId}
          careEpisodeId={props.careEpisodeId}
          feedbackReportContext={props.feedbackReportContext}
        />
      </DetailsWrapper>
    ),
  });
};
