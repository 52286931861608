import React from "react";
import { useParams } from "react-router-dom";
import { FeedbackReportContext } from "../FeedbackReportContext";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import ScaleScorePane from "./ScaleScorePane";
import { CareEpisodeId } from "Lib/Ids";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { DetailsWrapper } from "../DetailsWrapper";
import { useTranslation } from "react-i18next";

type ScaleScoreProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

export const ScaleScoreRoute = (props: ScaleScoreProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{ scaleId?: string; scaleScoreId?: string }>();

  const scaleId = Id.fromNullableString<"Scale">(params.scaleId).getOrElse(null);
  const CareEpisodeComputedValueId = Id.fromNullableString<"CareEpisodeComputedValue">(
    params.scaleScoreId
  ).getOrElse(null);

  if (scaleId === null || CareEpisodeComputedValueId === null) {
    return <ErrorMessage message={t("report:errors.invalidUrl")} />;
  }

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [
      t("report:breadcrumbs.measureHistory"),
      Routing.scaleAdministrationHistoryRoute(props.feedbackReportContext, scaleId),
    ],
    [t("report:breadcrumbs.responses")],
  ];

  return (
    <DetailsWrapper breadcrumbs={breadcrumbs}>
      <ScaleScorePane
        careEpisodeId={props.careEpisodeId}
        scaleId={scaleId}
        careEpisodeComputedValueId={CareEpisodeComputedValueId}
        feedbackReportContext={props.feedbackReportContext}
      />
    </DetailsWrapper>
  );
};
