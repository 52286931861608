import { LazyQueryResultTuple } from "@apollo/client";
import {
  Autocomplete,
  AutocompleteInputChangeReason,
  AutocompleteProps,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { useEffectOnce } from "Lib/Hooks";
import React, { ReactElement, SyntheticEvent } from "react";

// The two components in this file do basically the same thing (provide an searchable select box driven by server-side
// search via an apollo query), but differ in whether you can select one or several values. The base autocomplete Mui
// component exposes this as a simple boolean flag, but it ends up infecting the types of a bunch of the other
// parameters - the value type, the on change handler, etc. Rather than replicate what I'm sure is some fairly gnarly
// type wrangling that Mui did, I just duplicated the thing so we can always know whether we have one value or many to
// deal with. If you need to change something in here, keep in mind that you'll probably have to do it in two places.

// The type of autocomplete props that we allow users to override. Rather than listing the ones that are okay, I'm
// omitting the ones that this component needs total control over. The other two generic params in AutocompleteProps
// are for whether we should disable clearing the input (no) and whether the value is "Free Solo" i.e., not actually
// constrained to one of the options (no).
export type OverridableAutocompleteProps<TValue, TMultiple extends boolean | undefined> = Omit<
  AutocompleteProps<TValue, TMultiple, false, false>,
  | "multiple"
  | "renderInput"
  | "options"
  | "value"
  | "isOptionEqualToValue"
  | "loading"
  | "inputValue"
  | "onInputChange"
  | "onChange"
>;

export type OverridableInputProps = Omit<
  TextFieldProps,
  "variant" | "label" | "required" | "helperText" | "error"
>;

type QueryAutocompleteBaseProps<TValue, TQuery, TQueryVars> = {
  error?: boolean;
  helperText?: string | null; // Need the null here on top of ? to handle GQL Maybe (T | undefined | null)
  required?: boolean;
  label: string;
  skipPreload?: boolean;
  queryVariables: TQueryVars;
  query: () => LazyQueryResultTuple<TQuery, TQueryVars & { search: string | null }>; // TOOD: Move this to own block?
  fixedOptions?: ReadonlyArray<TValue>;
  unwrapResponse: (response: TQuery) => ReadonlyArray<TValue> | null | undefined;
  valueEqual: (a: TValue, b: TValue) => boolean;
  sortFn?: (a: TValue, b: TValue) => number;
  inputProps?: OverridableInputProps;
  // Although wrappers of this function are encouraged to use an interface which checks the default value against the current value
  // there are enough problems doing this generically that instead autocomplete has a property for if it is highlighted. This is necessary
  // because e.g. the query autocomplete tends to operate at the 'object' level not the 'id' level, e.g. ProviderSummary rather than ProviderId, and
  // you may not have the provider summary available.
  highlight?: boolean;
};

type QueryAutocompleteSingleProps<TValue, TQuery, TQueryVars> = QueryAutocompleteBaseProps<
  TValue,
  TQuery,
  TQueryVars
> & {
  valueUpdated: (value: TValue | null) => void;
  value: TValue | null | undefined;
  autocompleteProps?: OverridableAutocompleteProps<TValue, false>;
};

type QueryAutocompleteMultipleProps<TValue, TQuery, TQueryVars> = QueryAutocompleteBaseProps<
  TValue,
  TQuery,
  TQueryVars
> & {
  valueUpdated: (values: Array<TValue>) => void;
  value: Array<TValue>;
  autocompleteProps?: OverridableAutocompleteProps<TValue, true>;
};

export function QueryAutocompleteSingle<TValue, TQuery, TQueryVars>(
  props: QueryAutocompleteSingleProps<TValue, TQuery, TQueryVars>
): ReactElement {
  const [searchString, setSearchString] = React.useState<string>("");
  const [doSearch, response] = props.query();
  const autocompleteProps = props.autocompleteProps || {};
  const highlightColor = useTheme().palette.filterHighlight;

  if (!props.skipPreload) {
    // On first component load, search for nothing to initialize the list, or else it'll start with an error for
    // no matching values.
    useEffectOnce(() => {
      doSearch({ variables: { ...props.queryVariables, search: searchString } });
    });
  }

  // Autocomplete maintains two fields that we have to control - the "input" is the string that you've typed into the
  // search box, and the "value" is the item that you've selected. See the Mui docs for more.
  const handleInputChange = (
    _event: SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    // Without this check, the search causes the autocomplete to go into some infinite
    // loop hell where the input flickers and transitions from '' to the correct value.
    if (reason === "input" || reason === "clear") {
      doSearch({ variables: { ...props.queryVariables, search: value } });
    }
    setSearchString(value);
  };

  const handleValueChange = (_event: SyntheticEvent, value: TValue) => {
    props.valueUpdated(value);
  };

  const searchedOptions = (response.data ? props.unwrapResponse(response.data) : []) || [];
  // Apollo queries are defined to return readonly arrays, but we need to splice some items in (see below) so we gotta
  // launder the value back into a writeable array.
  const fixedOptions = props.fixedOptions || [];
  let options = [...fixedOptions, ...searchedOptions];

  if (props.sortFn) {
    options = options.sort(props.sortFn);
  }

  // If you have a fixed option that can also appear in the list, you need to deduplicate it. This is relatively quick
  // to do assuming the options have already been sorted.
  options = options.filter((el, index, array) => {
    const comp = array[index - 1];
    if (!comp) {
      return true;
    }

    return !props.valueEqual(el, comp);
  });

  let value = props.value;

  // If you do the following sequence of events...
  //   * Search for something, say "Alice"
  //   * Select Alice
  //   * Search for something else, say "Bob"
  // Now you're in a state where the selected value isn't in the options list (because Alice doesn't come back in a
  // search for Bob). Autocomplete gets rather peevish about this and doesn't actually _break_, but it starts spamming
  // warnings into the console. We avoid this by adding the current value to the front of the options list if it isn't
  // already in the options list.
  if (props.value) {
    // You could have a value which is value equal but not the same, e.g.
    // ```
    //   const myInput = { id: 123, name: "I don't have name because this came straight from the queryString"}
    //   const foundItem = { id: 123, name: "Outpatient Clinic" }
    // ```
    //
    // In order to avoid this, when you get a value passed in, look up the option that is delivered.
    // Do this below
    // Reassigning props.value so we can use it in a callback in find without typescript thinking it might be null
    const valuePresent = props.value;
    const valueInOptions = options.find((option) => props.valueEqual(valuePresent, option));
    if (valueInOptions) {
      value = valueInOptions;
    } else {
      options.splice(0, 0, props.value);
    }
  }

  const { sx, ...remainingProps } = autocompleteProps;

  const mergedSx = props.highlight
    ? [
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "4px",
            borderColor: highlightColor,
          },
        },
      ]
    : sx;

  return (
    <Autocomplete
      // Props controlled by this component that can't be passed from caller
      value={value}
      options={options}
      isOptionEqualToValue={props.valueEqual}
      loading={response.loading}
      inputValue={searchString}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props.inputProps}
          variant="outlined"
          label={props.label}
          required={!!props.required}
          helperText={props.helperText}
          error={props.error}
        />
      )}
      // These are good defaults that we set here but callers can override
      clearOnBlur
      clearOnEscape
      // Always include all of the options returned by the query in the autocomplete options. Without this Mui does its
      // own filtering logic which we specifically do not want because we're doing the search on the server for a
      // reason.
      filterOptions={(options, _state) => options}
      sx={mergedSx}
      // Pass through props from caller
      {...remainingProps}
    />
  );
}

export function QueryAutocompleteMultiple<TValue, TQuery, TQueryVars>(
  props: QueryAutocompleteMultipleProps<TValue, TQuery, TQueryVars>
): ReactElement {
  const [searchString, setSearchString] = React.useState<string>("");
  const [doSearch, response] = props.query();
  const autocompleteProps = props.autocompleteProps || {};

  if (!props.skipPreload) {
    // On first component load, search for nothing to initialize the list, or else it'll start with an error for
    // no matching values.
    React.useEffect(() => {
      doSearch({ variables: { ...props.queryVariables, search: "" } });
    }, []);
  }

  // Autocomplete maintains two fields that we have to control - the "input" is the string that you've typed into the
  // search box, and the "value" is the item that you've selected. See the Mui docs for more.
  const handleInputChange = (_event: SyntheticEvent, value: string) => {
    doSearch({ variables: { ...props.queryVariables, search: value } });
    setSearchString(value);
  };

  const handleValueChange = (_event: SyntheticEvent, value: Array<TValue>) => {
    props.valueUpdated(value);
  };

  const searchedOptions = (response.data ? props.unwrapResponse(response.data) : []) || [];
  // Apollo queries are defined to return readonly arrays, but we need to splice some items in (see below) so we gotta
  // launder the value back into a writeable array.
  let options = [...searchedOptions];

  if (props.sortFn) {
    options = options.sort(props.sortFn);
  }

  // See the long comment in the Single version above for why we're doing this.
  props.value.forEach((value, index) => {
    const valueInOptions = searchedOptions.find((option) => props.valueEqual(value, option));
    if (!valueInOptions) {
      options.splice(0, 0, value);
    } else {
      // Update the value (that might just e.g. be from the query string) with the fully returned
      // search value. Note that if you do this and replace the entire value hash you get a weird
      // infinite loop so it's important sadly to modify the existing array in place until
      // I can work out what's wrong with it properly.
      props.value[index] = valueInOptions;
    }
  });

  return (
    <Autocomplete
      multiple
      // Props controlled by this component that can't be passed from caller
      value={props.value}
      options={options}
      isOptionEqualToValue={props.valueEqual}
      loading={response.loading}
      inputValue={searchString}
      onInputChange={handleInputChange}
      onChange={handleValueChange}
      renderInput={(params) => (
        <TextField
          {...params}
          {...props.inputProps}
          variant="outlined"
          label={props.label}
          required={!!props.required}
          helperText={props.helperText}
          error={props.error}
        />
      )}
      // These are good defaults that we set here but callers can override
      clearOnBlur
      clearOnEscape
      // Always include all of the options returned by the query in the autocomplete options. Without this Mui does its
      // own filtering logic which we specifically do not want because we're doing the search on the server for a
      // reason.
      filterOptions={(options, _state) => options}
      // Pass through props from caller
      {...autocompleteProps}
    />
  );
}
