import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useCareEpisodeDischargeMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

function CareEpisodeDischargeDialog({
  id,
  closeDialog,
}: {
  id: CareEpisodeId;
  closeDialog: () => void;
}): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const [dischargeCareEpisode, dischargeCareEpisodeResult] = apolloMutationHookWrapper(
    (x) => x.careDischargeCareEpisode,
    useCareEpisodeDischargeMutation({
      variables: {
        input: { careEpisodeId: id },
      },
      refetchQueries: refetchQueries("careEpisodes"),
    })
  );

  const error: ReactElement | null = dischargeCareEpisodeResult.remoteData.caseOf({
    NotAsked: () => null,
    Loading: () => null,
    Failure: (_e) => <Typography color={"error.main"}>The episode of care could not be saved</Typography>,
    Success: () => {
      setTimeout(() => closeDialog());
      return null;
    },
  });

  const bullets = [
    t("careEpisodes:dischargeDialog.resetMeasurement"),
    t("careEpisodes:dischargeDialog.outcomes"),
  ];

  const showSpinner = dischargeCareEpisodeResult.remoteData.kind === "Loading";

  return (
    <ResponsiveDialog
      open={true}
      fullWidth={true}
      maxWidth={"xs"}
      onClose={() => closeDialog()}
      title={t("careEpisodes:dischargeDialog.title")}
    >
      <DialogContent dividers>
        <Stack direction="column" spacing={1}>
          <Typography>{t("careEpisodes:dischargeDialog.details")}</Typography>
          <ul>
            {bullets.map((bullet, index) => {
              return <li key={index}>{bullet}</li>;
            })}
          </ul>
          {error}
          <Stack direction="row" spacing={1}>
            <ButtonWithSpinner
              showSpinner={showSpinner}
              variant={"contained"}
              color="error"
              disabled={showSpinner}
              onClick={() => dischargeCareEpisode()}
            >
              {t("careEpisodes:dischargeDialog.confirm")}
            </ButtonWithSpinner>
            <Button onClick={() => closeDialog()} disabled={false} variant={"outlined"} color={"secondary"}>
              {t("careEpisodes:dischargeDialog.abort")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </ResponsiveDialog>
  );
}

export function CareEpisodeDischargeButton(props: { careEpisodeId: CareEpisodeId }) {
  const { t } = useTranslation(["careEpisodes"]);
  const [showForm, setShowForm] = useState<boolean>(false);

  const button = (
    <Button onClick={() => setShowForm(true)} variant={"outlined"} color={"secondary"}>
      {t("careEpisodes:actions.discharge")}
    </Button>
  );

  const dialog = showForm ? (
    <CareEpisodeDischargeDialog id={props.careEpisodeId} closeDialog={() => setShowForm(false)} />
  ) : null;

  return (
    <>
      {button}
      {dialog}
    </>
  );
}

export default CareEpisodeDischargeDialog;
