import Page from "Layout/Page";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { ListDetailsCutout } from "Shared/ListDetailsCutout";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes, useParams } from "react-router-dom";
import { DataSourceRecordLogDetail, IntegrationHistorySummary } from "./DataSourceRecordLogHistory";
import { DataSourceRecordLogHistoryList } from "./DataSourceRecordLogHistoryList";
import { enumFromStringValue } from "Shared/Enum";
import { DataSourceResourceType } from "GeneratedGraphQL/SchemaAndOperations";
import {
  DataSourceRecordLogHistoryFilters,
  useDataSourceRecordLogFilters,
} from "./DataSourceRecordLogHistoryFilters";

export function IntegrationHistoryRoute(): ReactElement {
  const params = useParams<{ importHistoryId?: string; resourceType?: string }>();
  const { t } = useTranslation(["integrations", "common"]);

  const filters = useDataSourceRecordLogFilters();

  const resourceType = params.resourceType
    ? enumFromStringValue<DataSourceResourceType>(DataSourceResourceType, params.resourceType)
    : null;

  if (!resourceType) {
    return <ErrorMessage message={t("common:notFound")} />;
  }

  return Id.fromNullableString<"ImportHistory">(params.importHistoryId).caseOf({
    Ok: (id) => (
      <Page browserTitle={t("integrations:dataSourceRecordLogHistory.pageTitle")}>
        <ListDetailsCutout
          list={<DataSourceRecordLogHistoryList importHistoryId={id} filters={filters} />}
          filters={<DataSourceRecordLogHistoryFilters filters={filters} />}
          details={
            <Routes>
              <Route element={<IntegrationHistoryLogRoute resourceType={resourceType} />} path="log/:logId" />
              <Route
                element={<IntegrationHistorySummary resourceType={resourceType} importHistoryId={id} />}
                path="/"
              />
            </Routes>
          }
        />
      </Page>
    ),
    Err: (_err) => <ErrorMessage message={t("common:notFound")} />,
  });
}

function IntegrationHistoryLogRoute(props: { resourceType: DataSourceResourceType }): ReactElement {
  const { t } = useTranslation(["integrations", "common"]);
  const params = useParams<{ logId?: string }>();

  return Id.fromNullableString<"DataSourceRecordLog">(params.logId).caseOf({
    Ok: (id) => <DataSourceRecordLogDetail logId={id} resourceType={props.resourceType} />,
    Err: (_err) => <ErrorMessage message={t("common:notFound")} />,
  });
}

export function IntegrationRoutes() {
  // TODO: add a route for not matching
  return (
    <Routes>
      <Route element={<IntegrationHistoryRoute />} path="import-history/:resourceType/:importHistoryId/*" />
      {/* <Route element={<IntegrationHistorySummary />} path="/" /> */}
    </Routes>
  );
}
