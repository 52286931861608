import { Card, CardContent, CardHeader } from "@mui/material";
import { PatientSession } from "GeneratedGraphQL/SchemaAndOperations";
import { ItemWithInfoTooltip } from "MDS/Tooltip/InfoTooltip";
import InfoSidebarCard, { LabelAndRichInfo } from "Shared/InfoSidebarCard";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type PatientSessionInfoCardProps = {
  patientSession: Pick<PatientSession, "sessionNumber" | "autoplan"> | null;
};
export default function PatientSessionInfoCard(props: PatientSessionInfoCardProps): ReactElement {
  const { t } = useTranslation(["report", "enums", "common", "patients"]);

  if (!props.patientSession) {
    return (
      <Card>
        <CardHeader title={t("patients:appointments.cards.patientSessionDetails")} />
        <CardContent>{t("patients:appointments.patientSessionDetails.noSession")}</CardContent>
      </Card>
    );
  }

  return (
    <InfoSidebarCard title={t("patients:appointments.cards.patientSessionDetails")}>
      <LabelAndRichInfo
        label={
          <ItemWithInfoTooltip
            item={t("patients:appointments.patientSessionDetails.sessionNumber")}
            tooltip={t("patients:appointments.patientSessionDetails.sessionNumberExplanation")}
          />
        }
        data={
          props.patientSession.sessionNumber?.toString() ||
          t("patients:appointments.patientSessionDetails.noSessionNumber")
        }
      />
    </InfoSidebarCard>
  );
}
