import { Grid, styled } from "@mui/material";

// This is super useful if you have a grid that is meant to be used to represent free flowing
// table rows or something like that. Just use it to replace the grid container and you're good to go.
const GridWithCenterableItems = styled(Grid)(({ theme }) => ({
  "& > .MuiGrid-item": {
    // Remove the top padding so that we can vertically center items within the whole box...
    paddingTop: 0,
    // ... and give the box a min height so that empty columns (e.g, a patient with no flags) don't take up no space
    // and not have a background color.
    minHeight: theme.spacing(4),
    // Make the boxes their own flex context and vertically center their children within them.
    display: "flex",
    alignItems: "center",
  },
}));

export default GridWithCenterableItems;
