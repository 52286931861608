import React, { ReactElement } from "react";
import Link from "@mui/material/Link";

type Props = {
  shadowSettingUrl: string;
  children?: ReactElement | string;
};

// This function just calculates a link back to the corresponding ember
// page for any given react shadow settings page.
function ShadowSettingsLink(props: Props) {
  const href = props.shadowSettingUrl.replace("/app/settings", "");
  return <Link href={href}>{props.children}</Link>;
}

export default ShadowSettingsLink;
