// SeverityHistory represents a slightly more generic domain than CareEpisodeComputedValues
// and GoalAnswers revolving data points with values/scores and a
// SeverityThresholdClass. This allows to work on a less specific domain
// supporting both Scores and Goals
//
// Specifically a SeveryHistory is a non empty list of the SeverityDatum
// sumtype, meaning it can include Blank entries or Value entries.
import * as DateQuantizedData from "Lib/DateQuantizedData";
import * as NEL from "Lib/NonEmptyList";
import { head, justs, last } from "Lib/Utils";
import { Just, Maybe, Nothing } from "seidr";
import { Id } from "Lib/Id";
import Trend from "Shared/Scale/Trend";
import { AcuteChangeCategory, ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { formatNumber } from "Shared/formatters";

export type SeverityDatum<T extends SeverityValue> = DateQuantizedData.DateQuantizedDatum<T>;

type HistoryParameter<T> = T extends SeverityDatum<infer A> ? A : never;

export type SeverityHistory<
  T extends SeverityDatum<U>,
  U extends SeverityValue = HistoryParameter<T>
> = NEL.NonEmptyList<T>;

export type SeverityValue = {
  date: Date;
  id: Id<string>;
  thresholdClass: ScaleThresholdClass;
  thresholdMnemonic: Maybe<string>;
  acuteChange?: AcuteChangeCategory;
  trend?: Trend;
};

export type SeverityValueWithNumber = SeverityValue & {
  value: number;
};

// This should be the ONLY way to create a SeverityHistory:
// By Quantizing with dates (usually administration dates).
function severityHistory<T extends SeverityValue>(
  dates: NEL.NonEmptyList<Date>,
  values: Array<T>
): SeverityHistory<SeverityDatum<T>> {
  return DateQuantizedData.quantize(dates, values);
}

// -- Helpers -----------------------------------------------------------------

function formatValue(severityValue: Pick<SeverityValueWithNumber, "value">): string {
  const value = severityValue.value;
  return formatNumber(value);
}

function severityDate<T extends SeverityValue>(datum: SeverityDatum<T>): Date {
  return datum.caseOf({
    Value: (value) => value.date,
    Blank: (date) => date,
  });
}

function severityValue<T extends SeverityValue>(datum: SeverityDatum<T>): Maybe<SeverityValue> {
  return datum.caseOf({
    Value: Just,
    Blank: Nothing,
  });
}

function firstSeverityValue<T extends SeverityValue>(
  history: SeverityHistory<SeverityDatum<T>>
): Maybe<SeverityValue> {
  return head(
    justs(
      NEL.toArray(
        history.map((datum) =>
          datum.caseOf({
            Value: Just,
            Blank: Nothing,
          })
        )
      )
    )
  );
}

function lastSeverityValue<T extends SeverityValue>(
  history: SeverityHistory<SeverityDatum<T>>
): Maybe<SeverityValue> {
  return last(
    justs(
      NEL.toArray(
        history.map((datum) =>
          datum.caseOf({
            Value: Just,
            Blank: Nothing,
          })
        )
      )
    )
  );
}

function firstSeverityDate<T extends SeverityValue>(history: SeverityHistory<SeverityDatum<T>>): Date {
  return severityDate(NEL.head(history));
}

function lastSeverityDate<T extends SeverityValue>(history: SeverityHistory<SeverityDatum<T>>): Date {
  return severityDate(NEL.last(history));
}

function values<T extends SeverityValue>(history: SeverityHistory<SeverityDatum<T>>): Array<T> {
  return history.toArray().reduce<Array<T>>((acc, d) => {
    return d.caseOf({
      Value: (v) => acc.concat(v),
      Blank: () => acc,
    });
  }, []);
}

function dates<T extends SeverityValue>(history: SeverityHistory<SeverityDatum<T>>): Array<Date> {
  return history.toArray().map(severityDate);
}

export {
  severityHistory,
  formatValue,
  severityDate,
  severityValue,
  firstSeverityDate,
  lastSeverityDate,
  lastSeverityValue,
  firstSeverityValue,
  values,
  dates,
};
