import { useParams } from "react-router-dom";
import { InstituteProviderQuery, useInstituteProviderQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement, ReactNode } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import DetailsLayout from "../DetailsLayout";
import { Dig } from "type-utils";
import { userOverviewFields } from "../UserOverviewFields";
import UnderMeasurementChip from "../UnderMeasurementChip";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import { Box } from "@mui/material";
import InspectorTab from "../../../Inspector/InspectorTab";

function InstitutePatient(props: {
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const params = useParams<{ providerId?: string }>();
  return fromNullableString<"Provider">(params.providerId).caseOf({
    Ok: (providerId) => (
      <DetailsLayout
        queryResult={useInstituteProviderQuery({
          variables: {
            providerId: providerId,
          },
        })}
        setSubCrumbs={props.setSubCrumbs}
        subCrumbs={(x) => [
          { text: "Providers", path: "/providers" },
          { text: x.name, path: `/providers/${x.id}` },
        ]}
        headerContents={(x) => (
          <Box sx={{ display: "none" }}>
            <UnderMeasurementChip
              measurementAllowed={x.measurementAllowed}
              measureStarting={x.measureStarting}
            />
          </Box>
        )}
        browserTitle={() => "Provider"}
        titleAction={(x) => <div>({x.id.toString()})</div>}
        resultFetcher={(x: InstituteProviderQuery) => x.provider}
        title={(p) => p.name}
        overviewFields={(x) => overviewFields(x)}
        integrationsDataSourceRecords={(p) => p.integrationsDataSourceRecords}
        tabs={() => [
          {
            tabLabel: "Inspector",
            path: "inspector",
            component: <InspectorTab className="Provider" id={providerId.toString()} />,
          },
        ]}
      />
    ),

    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

function overviewFields(dat: NonNullable<Dig<InstituteProviderQuery, ["provider"]>>) {
  const extraFields: Array<{
    value: string | ReactNode | null | boolean;
    name: string;
  }> = [
    { name: "id", value: dat.id.toString() },
    { name: "name", value: dat.name },
    { name: "status", value: dat.status },
    { name: "Measurement Allowed", value: dat.measurementAllowed },
    { name: "Title", value: dat.title },
    { name: "Suffix", value: dat.suffix },
    { name: "Default Relationship", value: dat.defaultRelationship },
    { name: "Practicing", value: dat.practicing },
  ];
  return extraFields.concat(userOverviewFields(dat.user));
}

export default InstitutePatient;
