import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { CocmBillingAlgorithmRuleId } from "Lib/Ids";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BillingRuleNameDetails, billingRuleName } from "./BillingRuleHelpers";
import { IntegerRange } from "GeneratedGraphQL/SchemaAndOperations";
import { rangeToText } from "Shared/IntegerRange";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { useQueryStringBooleanParameter } from "Shared/QueryStringParameter";

const allowedMonthsOfEnrollment: ReadonlyArray<IntegerRange> = [
  { min: 1, max: 1 },
  { min: 2, max: 2 },
  { min: 3, max: 3 },
  { min: 4, max: 4 },
  { min: 5, max: 5 },
  { min: 6, max: 6 },
  { min: 7, max: 7 },
  { min: 8, max: 8 },
  { min: 9, max: 9 },
  { min: 10, max: 10 },
  { min: 11, max: 11 },
  { min: 12, max: 17 },
  { min: 18 },
];

export type BillingDashboardFilters = {
  monthsOfEnrollment: ReadonlyArray<IntegerRange>;
  setMonthsOfEnrollment: (values: ReadonlyArray<IntegerRange>) => void;
  selectedRuleIds: ReadonlyArray<CocmBillingAlgorithmRuleId>;
  setSelectedRuleIds: (values: ReadonlyArray<CocmBillingAlgorithmRuleId>) => void;
  // This contains info on the currently active rules to reference by id
  selectedRuleDetails: ReadonlyArray<BillingRuleNameDetails>;
  changedFilterCount: number;
  excludeDischarged: boolean;
  setExcludeDischarged: (value: boolean) => void;
  reset: () => void;
};
export function useBillingDashboardFilters(
  ruleData: ReadonlyArray<BillingRuleNameDetails>
): BillingDashboardFilters {
  // TODO: consider make these parameters sticky
  const [monthsOfEnrollment, setMonthsOfEnrollment] =
    React.useState<ReadonlyArray<IntegerRange>>(allowedMonthsOfEnrollment);

  const allRuleIds = ruleData.map((r) => r.id);

  const [selectedRuleIds, setSelectedRuleIds] =
    React.useState<ReadonlyArray<CocmBillingAlgorithmRuleId>>(allRuleIds);

  const [excludeDischarged, setExcludeDischarged] = useQueryStringBooleanParameter(
    "excludeDischarged",
    true,
    true
  );

  useEffect(() => {
    // Automatically opt into all rules when the rule Ids change.
    setSelectedRuleIds(allRuleIds);
  }, [ruleData.join(",")]);

  const selectedRuleDetails = React.useMemo(() => {
    return ruleData.filter((r) => selectedRuleIds.includes(r.id));
  }, [selectedRuleIds]);

  const changedFilterCount =
    (selectedRuleIds.length === allRuleIds.length ? 0 : 1) +
    (monthsOfEnrollment.length === allowedMonthsOfEnrollment.length ? 0 : 1);

  return {
    monthsOfEnrollment,
    setMonthsOfEnrollment,
    selectedRuleIds,
    setSelectedRuleIds,
    selectedRuleDetails,
    reset: () => {
      setMonthsOfEnrollment(allowedMonthsOfEnrollment);
      setSelectedRuleIds(allRuleIds);
      setExcludeDischarged(true);
    },
    changedFilterCount,
    excludeDischarged,
    setExcludeDischarged,
  };
}

function CheckableRule(props: {
  name: string;
  checked: boolean;
  onChange: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) {
  return (
    <FormControlLabel
      control={<Checkbox checked={props.checked} onChange={props.onChange} />}
      label={props.name}
    />
  );
}

export function BillingDashboardFilters(props: {
  filters: BillingDashboardFilters;
  allRuleDetails: ReadonlyArray<BillingRuleNameDetails>;
}) {
  const { t } = useTranslation(["common", "billing"]);
  const { filters, allRuleDetails } = props;

  const handleCheckboxRuleChange = (ruleId: CocmBillingAlgorithmRuleId) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const without = filters.selectedRuleIds.filter((s) => s !== ruleId);

      if (checked) {
        filters.setSelectedRuleIds([...without, ruleId]);
      } else {
        filters.setSelectedRuleIds([...without]);
      }
    };
  };

  const handleMonthChange = (month: IntegerRange) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const without = filters.monthsOfEnrollment.filter((s) => s.min !== month.min && s.max !== month.max);

      if (checked) {
        filters.setMonthsOfEnrollment([...without, month]);
      } else {
        filters.setMonthsOfEnrollment([...without]);
      }
    };
  };

  const rules = allRuleDetails.map((rule) => {
    return (
      <CheckableRule
        key={rule.id.toString()}
        name={billingRuleName(rule)}
        checked={filters.selectedRuleIds.includes(rule.id)}
        onChange={handleCheckboxRuleChange(rule.id)}
      />
    );
  });

  const months = allowedMonthsOfEnrollment.map((month, index) => {
    const label = t("billing:dashboard.filters.month", { month: rangeToText(month, t) });

    return (
      <CheckableRule
        key={index}
        name={label}
        checked={filters.monthsOfEnrollment.includes(month)}
        onChange={handleMonthChange(month)}
      />
    );
  });

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        {rules}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SectionHelpText text={t("billing:dashboard.filters.monthHelpText")} />
        {months}
      </Grid>
    </Grid>
  );
}
