import { Maybe, Ok, Result } from "seidr";
import { PatientSessionSummaryFragmentFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { parseIsoStringOrDate } from "Lib/Utils";
import { PatientSessionId, ReportId } from "Lib/Ids";

export type PatientSessionBase = {
  id: PatientSessionId;
};

export type PatientSessionSummary = PatientSessionBase & {
  reportId: Maybe<ReportId>;
  sessionNumber: Maybe<number>;
  targetDate: Date;
};

function parsePatientSessionSummary(
  raw: PatientSessionSummaryFragmentFragment
): Result<Error, PatientSessionSummary> {
  const reportId = Maybe.fromNullable(raw.assessmentReport?.id);

  return Ok({
    id: raw.id,
    reportId,
    sessionNumber: Maybe.fromNullable(raw.sessionNumber),
    targetDate: parseIsoStringOrDate(raw.targetDate),
  });
}

export { parsePatientSessionSummary };
