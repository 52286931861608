import { Route, Routes, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import NotFound from "Shared/NotFound";
import { WithAnyRole, WithoutAnyRole } from "Shared/WithPermission";
import AlgorithmList from "./AlgorithmList";
import { fromNullableString } from "Lib/Id";
import BillingAlgorithmDetails from "./BillingAlgorithmDetails";

function AlgorithmDetailsRoute(): ReactElement | null {
  const params = useParams<{ algorithmId: string }>();

  return fromNullableString<"CocmBillingAlgorithm">(params.algorithmId).caseOf({
    Ok: (id) => <BillingAlgorithmDetails id={id} />,
    Err: () => <NotFound />,
  });
}

/**
 * Provides full routing for implementation targets
 * @returns routes
 */
export function BillingConfigurationRoutes(): ReactElement {
  return (
    <>
      <WithAnyRole roles={["Collaborative Care Superuser", "superuser"]}>
        <Routes>
          <Route element={<AlgorithmDetailsRoute />} path="/:algorithmId" />
          <Route element={<AlgorithmList />} path="/" />
        </Routes>
      </WithAnyRole>
      <WithoutAnyRole roles={["Collaborative Care Superuser", "superuser"]}>
        <Routes>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </WithoutAnyRole>
    </>
  );
}
