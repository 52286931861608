import { useCurrentProviderId } from "AppSession/AppSession";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { ForceStartStrategy, useBeginTimeEntryLogMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectOnce } from "Lib/Hooks";
import { PatientId } from "Lib/Ids";
import React from "react";

type AutomaticTimeTrackingArgs = {
  title: string;
  body: string;
  // Possibly undefined to handle some places where it's most convenient to call this at a level where we may not have
  // a patient. Gotta have the condition in the hook because of hook rules.
  patientId: PatientId | undefined;
  forceStart: ForceStartStrategy;
};

export function useAutomaticallyTrackTime(args: AutomaticTimeTrackingArgs) {
  const [startAutoTimeTracking] = useBeginTimeEntryLogMutation();
  const currentProviderId = useCurrentProviderId();
  const now = React.useMemo(() => new Date(), []);
  const autoTimeTrackingEnabled = useWithFeatureEnabled("enableCocmAutomaticTimeTracking");

  useEffectOnce(() => {
    if (args.patientId && currentProviderId && autoTimeTrackingEnabled) {
      startAutoTimeTracking({
        variables: {
          input: {
            taskStrategy: {
              titleAndBody: {
                title: args.title,
                body: args.body,
                patientId: args.patientId,
              },
            },
            clientStartTime: now,
            providerId: currentProviderId,
            forceStart: args.forceStart,
          },
        },
      });
    }
  });
}
