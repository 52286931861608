import { ProviderId } from "Lib/Ids";
import { Maybe } from "seidr";
import * as Id from "Lib/Id";
import React, { ReactElement, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { intersection } from "ramda";

export type AuthenticatedProviderUser = {
  id: string;
  authenticationToken: string;
  userType: "provider" | "internal";
  providerId: Maybe<ProviderId>;
  roles: Array<string>;
  username: string;
  firstName: string;
  lastName: string;
  name: string;
  lastSignIn: Date | null;
  // Maps permission types (e.g., 'editUser') to the scope that permission is
  // valid in (e.g., 'institute').
  permissions: Record<string, string>;
  helpscoutSignature?: string;
  email: string;
};

export function providerUserHasPermission(user: AuthenticatedProviderUser, permission: string) {
  if (user.userType === "internal" || user.permissions[permission] !== undefined) {
    return true;
  }

  return false;
}

export function providerUserHasAnyPermission(
  user: AuthenticatedProviderUser,
  permissions: ReadonlyArray<string>
) {
  if (user.userType === "internal" || intersection(permissions, Object.keys(user.permissions)).length > 0) {
    return true;
  }

  return false;
}

export function providerUserHasRole(user: AuthenticatedProviderUser, role: string) {
  if (user.userType === "internal" || user.roles.includes(role)) {
    return true;
  }

  return false;
}

export function providerUserHasAnyRole(user: AuthenticatedProviderUser, roles: ReadonlyArray<string>) {
  if (user.userType === "internal" || intersection(roles, user.roles).length > 0) {
    return true;
  }

  return false;
}

// RAW DATA TYPES
// These are the types which are used by the backend and are underscore cased.
// This is the provider data hash. There are additional elements for the patient hash in survey which are not present here.
// Note that the source of this data is a local storage blob, which is not actually typechecked, we just cast it to this
// type before we call `parse`.
export type RawProviderAuthenticationData = {
  active_role_type: "provider" | "patient" | "related_person";
  authentication_token: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  id: string;
  is_internal: boolean;
  language: string | null;
  last_sign_in_at: string | null;
  role: "provider" | "internal";
  roles: Array<string>;
  scoped_permissions: Array<string> | null;
  user_type: "provider";
  username: string;
  password_expired_at: Date | null;
  provider_id: string | null;
  helpscout_signature?: string;
};

export type RawProviderAuthenticationHeaders = {
  id: string;
  authentication_token: string;
  user_type: "provider";
};

function makePermissionMap(scopedPermissions: ReadonlyArray<string> | null): Record<string, string> {
  if (!scopedPermissions) {
    return {};
  }

  return scopedPermissions.reduce<Record<string, string>>((acc, scopedPermission) => {
    const [permission, scope] = scopedPermission.split(":");
    if (permission && scope) {
      acc[permission] = scope;
    }
    return acc;
  }, {});
}

function parse(raw: RawProviderAuthenticationData): AuthenticatedProviderUser {
  return {
    id: raw.id,
    authenticationToken: raw.authentication_token,
    userType: raw.user_type,
    providerId: Id.fromNullableString<"Provider">(raw.provider_id).toMaybe(),
    roles: raw.roles,
    username: raw.username,
    name: raw.name,
    firstName: raw.firstName,
    lastName: raw.lastName,
    lastSignIn: raw.last_sign_in_at ? new Date(raw.last_sign_in_at) : null,
    permissions: makePermissionMap(raw.scoped_permissions),
    helpscoutSignature: raw.helpscout_signature,
    email: raw.email,
  };
}

const AuthenticatedProviderUserContext = React.createContext<AuthenticatedProviderUser | undefined>(
  undefined
);

function WithInternalUser(props: { children: ReactNode }): ReactElement | null {
  const stripe = useTheme().palette.dividerLight;
  const user = React.useContext(AuthenticatedProviderUserContext);
  if (user && user.userType === "internal") {
    return (
      <Box
        sx={{
          // A hatched background. We don't use this for anything else in the app, so it should be fairly obvious that
          // it's not a normal component.
          background: `repeating-linear-gradient(135deg, ${stripe} 0px 5px, transparent 5px 10px)`,
        }}
      >
        {props.children}
      </Box>
    );
  } else {
    return null;
  }
}

function useIsInternalUser() {
  const user = React.useContext(AuthenticatedProviderUserContext);

  return user && user.userType === "internal";
}

export { parse, AuthenticatedProviderUserContext, WithInternalUser, useIsInternalUser };
