import { useIsMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useEnrollmentState } from "./EnrollmentState";
import { PickTypename } from "type-utils";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import {
  CareEpisodeSurveyHistory,
  ConstructsLoading,
} from "./DetailTabs/SurveyHistoryTab/CareEpisodeSurveyHistory";
import { Button, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import { ActiveTreatmentTargets } from "./DetailTabs/SurveyHistoryTab/ActiveTreatmentTargets";
import { CareEpisodeId } from "Lib/Ids";

type SurveyHistoryCardProps = {
  patient: PickTypename<Patient, "id">;
  forPreviousEnrollment?: boolean;
  careEpisodeId?: CareEpisodeId;
};
export default function SurveyHistoryCard(props: SurveyHistoryCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const columns = useIsMobile() ? 1 : 3;

  const enrollmentState = useEnrollmentState(props.patient.id);
  let content = null;
  if (props.forPreviousEnrollment && props.careEpisodeId) {
    content = (
      <Grid container columns={columns} spacing={3}>
        <Grid item xs="auto">
          <Stack direction="column" spacing={1}>
            <Typography variant="h3">
              {t("collaborativeCare:patientDetails.surveyHistory.measurementHistory")}
            </Typography>
            <CareEpisodeSurveyHistory careEpisodeId={props.careEpisodeId} />
            <ContextualReportLink patient={props.patient.id} careEpisode={props.careEpisodeId}>
              <Button variant="contained" color="secondary">
                {t("collaborativeCare:patientDetails.surveyHistory.reportLink")}
              </Button>
            </ContextualReportLink>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <ActiveTreatmentTargets
            careEpisodeId={props.careEpisodeId}
            forPreviousEnrollment={props.forPreviousEnrollment}
          />
        </Grid>
      </Grid>
    );
  } else {
    switch (enrollmentState.status) {
      case "unknown": {
        content = <ConstructsLoading />;
        break;
      }
      case "enrolled": {
        content = (
          <Grid container columns={columns} spacing={3}>
            <Grid item xs="auto">
              <Stack direction="column" spacing={1}>
                <Typography variant="h3">
                  {t("collaborativeCare:patientDetails.surveyHistory.measurementHistory")}
                </Typography>
                <CareEpisodeSurveyHistory
                  careEpisodeId={enrollmentState.careEpisodeId}
                  forPreviousEnrollment={props.forPreviousEnrollment}
                />
                <ContextualReportLink patient={props.patient.id} careEpisode={enrollmentState.careEpisodeId}>
                  <Button variant="contained" color="secondary">
                    {t("collaborativeCare:patientDetails.surveyHistory.reportLink")}
                  </Button>
                </ContextualReportLink>
              </Stack>
            </Grid>
            <Grid item xs={1}>
              <ActiveTreatmentTargets careEpisodeId={enrollmentState.careEpisodeId} />
            </Grid>
          </Grid>
        );
        break;
      }
      case "not-enrolled": {
        content = <Typography>{t("collaborativeCare:patientDetails.surveyHistory.notEnrolled")}</Typography>;
        break;
      }
    }
  }

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:patientDetails.cards.measurementHistory")} />{" "}
      <CardContent>{content}</CardContent>
    </Card>
  );
}
