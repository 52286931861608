import { Stack, Typography } from "@mui/material";
import {
  Entity,
  EntityTreeNode,
  EntityTreeNodeParams,
  EntityTreeSegment,
  EntityType,
  useEntityTreeNodeSelectSingleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import MedicationIcon from "@mui/icons-material/Medication";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import AssessmentIcon from "@mui/icons-material/Assessment";
import RouteIcon from "@mui/icons-material/Route";
import CodeIcon from "@mui/icons-material/Code";
import BusinessIcon from "@mui/icons-material/Business";
import DataObjectIcon from "@mui/icons-material/DataObject";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import ListIcon from "@mui/icons-material/List";
import PlaceIcon from "@mui/icons-material/Place";
import { useTranslation } from "react-i18next";
import { useInstituteHasGroups } from "Contexts/CurrentInstituteContext";
import CategoryIcon from "@mui/icons-material/Category";
import { apolloQueryHookWrapper } from "Api/GraphQL";

export const ALL_ENTITY_TYPES = Object.values(EntityType);

const ORDERED_ENTITY_TYPES = [
  EntityType.ROOT,
  EntityType.INSTITUTE_GROUP,
  EntityType.INSTITUTE,
  EntityType.INSTITUTE_PAYOR,
  EntityType.TREATMENT_SERVICE,
  EntityType.ORGANIZATION,
  EntityType.INSTITUTE_CONDITION,
  EntityType.PROVIDER,
  EntityType.PROVIDER_RELATIONSHIP,
  EntityType.CARE_MANAGER,
  EntityType.PRIMARY_CARE_PHYSICIAN,
  EntityType.TREATMENT_TRACK,
  EntityType.EXPERIMENT,
  EntityType.EXPERIMENT_VARIANT,
  EntityType.PATIENT,
  EntityType.USER,
  EntityType.CARE_EPISODE,
  EntityType.PATIENT_SESSION,
  EntityType.ENTITY_DECISION_ALGORITHM,
  EntityType.WORKFLOW,
  EntityType.WORKFLOW_ACTION,
  EntityType.PANEL,
  EntityType.LOCATION,
  EntityType.CARE_UNIT_TYPE,
  EntityType.ENCOUNTER,
];

export type EntitySummary = Pick<Entity, "id" | "name" | "shortname" | "entityType">;

export type EntityTreeNodeDetails = Pick<EntityTreeNode, "path" | "minAge" | "maxAge"> & {
  entity: EntitySummary;
  segments: ReadonlyArray<
    Pick<EntityTreeSegment, "id" | "path" | "entityType" | "name" | "shortname" | "rootPath">
  >;
};

type InstituteDisplay = "always" | "when_solo" | "never";

type EntityPathProps = {
  entityTreeNode: EntityTreeNodeDetails;
  includeInstitute?: InstituteDisplay;
};

// This function is necessary as we can't serialize extra fields on graphql, so drop all non-essential
// even if it technically already complies.
export function entityTreeNodeToParams(node: Pick<EntityTreeNode, "path">): EntityTreeNodeParams {
  return {
    path: node.path,
  };
}

export function extractEntitiesFromDetails(
  params: EntityTreeNodeDetails,
  alwaysIncludeInstitute: boolean
): ReadonlyArray<EntitySummary> {
  let printableSegments = params.segments;

  if (printableSegments.length > 1 && !alwaysIncludeInstitute) {
    printableSegments = printableSegments.filter((segment) => segment.entityType !== EntityType.INSTITUTE);
  }

  if (printableSegments.length > 0) {
    // When we get a segment, we get the full path, so that it builds up over time:
    // institute/123
    // institute/123/provider/456
    // institute/123/provider/456/patient/789
    //
    // When we make the entities, we only want the root path on each so we rewrite the path.
    return printableSegments.map((segment) => {
      return {
        ...segment,
        path: segment.rootPath,
      };
    });
  } else {
    return [params.entity];
  }
}

/**
 * Wraps EntityPath but takes a set of params and queries for all the data.
 */
function EntityPathFromParams(props: { node: EntityTreeNodeParams; includeInstitute?: InstituteDisplay }) {
  const { remoteData } = apolloQueryHookWrapper(
    useEntityTreeNodeSelectSingleQuery({ variables: { node: props.node } })
  );

  const { t } = useTranslation(["common"]);

  return remoteData.caseOf({
    Success: (data) => {
      if (data.entityTreeNode) {
        return <EntityPath entityTreeNode={data.entityTreeNode} includeInstitute={props.includeInstitute} />;
      } else {
        return <Typography>{t("common:remoteData.failure")}</Typography>;
      }
    },
    Failure: () => <Typography>{t("common:remoteData.failure")}</Typography>,
    _: () => <Typography>{t("common:remoteData.loading")}</Typography>,
  });
}

function EntityPath(props: EntityPathProps): ReactElement {
  const { entityTreeNode, includeInstitute } = props;
  const { t } = useTranslation(["care"]);
  const instituteGroupMode = useInstituteHasGroups();

  const displayedSegments = entityTreeNode.segments.filter((segment) => {
    return (
      segment.entityType !== EntityType.INSTITUTE ||
      includeInstitute === "always" ||
      instituteGroupMode ||
      (includeInstitute == "when_solo" && entityTreeNode.segments.length === 1)
    );
  });

  const segments = displayedSegments.map((segment, i) => {
    return (
      <React.Fragment key={i}>
        <EntityPathIcon entityType={segment.entityType} />
        <Typography component="span">{segment.name}</Typography>
      </React.Fragment>
    );
  });

  let filters = null;

  if (props.entityTreeNode.minAge !== null && props.entityTreeNode.maxAge !== null) {
    filters = (
      <Typography>
        {t("care:filters.age", { minAge: props.entityTreeNode.minAge, maxAge: props.entityTreeNode.maxAge })}
      </Typography>
    );
  } else if (props.entityTreeNode.minAge !== null) {
    filters = <Typography>{t("care:filters.minAge", { minAge: props.entityTreeNode.minAge })}</Typography>;
  } else if (props.entityTreeNode.maxAge !== null) {
    filters = <Typography>{t("care:filters.maxAge", { maxAge: props.entityTreeNode.maxAge })}</Typography>;
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.5}
        divider={<span>/</span>}
        component="span"
        display="inline-flex"
      >
        {segments}
      </Stack>
      {filters}
    </>
  );
}

function EntitySummary(props: { entity: EntitySummary }) {
  return (
    <Stack direction="row" spacing={1}>
      <EntityPathIcon entityType={props.entity.entityType} />
      <Typography component="span">{props.entity.name}</Typography>
    </Stack>
  );
}

function EntityPathIcon(props: { entityType: EntityType }) {
  switch (props.entityType) {
    case EntityType.PATIENT:
      return <PersonIcon fontSize="small" />;
    case EntityType.TREATMENT_SERVICE:
      return <AccountBalanceIcon fontSize="small" />;
    case EntityType.ORGANIZATION:
      return <ApartmentIcon alignmentBaseline="middle" fontSize="small" />;
    case EntityType.INSTITUTE:
      return <BusinessIcon alignmentBaseline="middle" fontSize="small" />;
    case EntityType.USER:
      return <PersonOutlineOutlinedIcon fontSize="small" />;
    case EntityType.INSTITUTE_CONDITION:
      return <MonitorHeartIcon fontSize="small" />;
    case EntityType.EXPERIMENT:
    case EntityType.EXPERIMENT_VARIANT:
      return <ScienceOutlinedIcon fontSize="small" />;
    case EntityType.CARE_EPISODE:
      return <MedicationIcon fontSize="small" />;
    case EntityType.PROVIDER_RELATIONSHIP:
    case EntityType.PROVIDER:
    case EntityType.PRIMARY_CARE_PHYSICIAN:
    case EntityType.CARE_MANAGER:
      return <MedicalServicesIcon fontSize="small" />;
    case EntityType.TREATMENT_TRACK:
      return <RouteIcon fontSize="small" />;
    case EntityType.PATIENT_SESSION:
      return <AssessmentIcon fontSize="small" />;
    case EntityType.ENTITY_DECISION_ALGORITHM:
      return <CodeIcon fontSize="small" />;
    case EntityType.WORKFLOW:
      return <DataObjectIcon fontSize="small" />;
    case EntityType.WORKFLOW_ACTION:
      return <ChecklistOutlinedIcon fontSize="small" />;
    case EntityType.INSTITUTE_PAYOR:
      return <MonetizationOnOutlinedIcon fontSize="small" />;
    case EntityType.PANEL:
      return <ListIcon fontSize="small" />;
    case EntityType.LOCATION:
      return <PlaceIcon fontSize="small" />;
    case EntityType.INSTITUTE_GROUP:
      return <CategoryIcon fontSize="small" />;
    // We don't care about these so don't give them icons
    case EntityType.CARE_UNIT_TYPE:
    case EntityType.ENCOUNTER:
    case EntityType.ROOT:
      return null;
  }
}

export function entityTypeToPathName(entityType: EntityType): string | null {
  switch (entityType) {
    case EntityType.PATIENT:
      return "patient";
    case EntityType.TREATMENT_SERVICE:
      return "treatment-service";
    case EntityType.ORGANIZATION:
      return "organization";
    case EntityType.INSTITUTE:
      return "institute";
    case EntityType.USER:
      return "user";
    case EntityType.INSTITUTE_CONDITION:
      return "institute-condition";
    case EntityType.EXPERIMENT:
      return "experiment";
    case EntityType.EXPERIMENT_VARIANT:
      return "experiment-variant";
    case EntityType.CARE_EPISODE:
      return "care-episode";
    case EntityType.PROVIDER_RELATIONSHIP:
      return "provider-relationship";
    case EntityType.PROVIDER:
      return "provider";
    case EntityType.PRIMARY_CARE_PHYSICIAN:
      return "primary-care-physician";
    case EntityType.CARE_MANAGER:
      return "care-manager";
    case EntityType.TREATMENT_TRACK:
      return "treatment-track";
    case EntityType.PATIENT_SESSION:
      return "patient-session";
    case EntityType.ENTITY_DECISION_ALGORITHM:
      return "entity-decision-algorithm";
    case EntityType.WORKFLOW:
      return "workflow";
    case EntityType.WORKFLOW_ACTION:
      return "workflow-action";
    case EntityType.INSTITUTE_PAYOR:
      return "institute-payor";
    case EntityType.PANEL:
      return "panel";
    case EntityType.LOCATION:
      return "location";
    case EntityType.INSTITUTE_GROUP:
      return "institute-group";
    case EntityType.CARE_UNIT_TYPE:
      return "care-unit-type";
    case EntityType.ENCOUNTER:
      return "encounter";
    case EntityType.ROOT:
      return null;
  }
}

export function entityPathNameToType(path: string): EntityType | null {
  switch (path) {
    case "patient":
      return EntityType.PATIENT;
    case "treatment-service":
      return EntityType.TREATMENT_SERVICE;
    case "organization":
      return EntityType.ORGANIZATION;
    case "institute":
      return EntityType.INSTITUTE;
    case "user":
      return EntityType.USER;
    case "institute-condition":
      return EntityType.INSTITUTE_CONDITION;
    case "experiment":
      return EntityType.EXPERIMENT;
    case "experiment-variant":
      return EntityType.EXPERIMENT_VARIANT;
    case "care-episode":
      return EntityType.CARE_EPISODE;
    case "provider-relationship":
      return EntityType.PROVIDER_RELATIONSHIP;
    case "provider":
      return EntityType.PROVIDER;
    case "primary-care-physician":
      return EntityType.PRIMARY_CARE_PHYSICIAN;
    case "care-manager":
      return EntityType.CARE_MANAGER;
    case "treatment-track":
      return EntityType.TREATMENT_TRACK;
    case "patient-session":
      return EntityType.PATIENT_SESSION;
    case "entity-decision-algorithm":
      return EntityType.ENTITY_DECISION_ALGORITHM;
    case "workflow":
      return EntityType.WORKFLOW;
    case "workflow-action":
      return EntityType.WORKFLOW_ACTION;
    case "institute-payor":
      return EntityType.INSTITUTE_PAYOR;
    case "panel":
      return EntityType.PANEL;
    case "location":
      return EntityType.LOCATION;
    case "institute-group":
      return EntityType.INSTITUTE_GROUP;
    case "care-unit-type":
      return EntityType.CARE_UNIT_TYPE;
    case "encounter":
      return EntityType.ENCOUNTER;
    default:
      return null;
  }
}

// Gives a relative priority of hierarchy to all entity types.
// For example we want Institute Groups at the top, then institutes, then orgs, etc.
// This is somewhat inexact because there's no real easy way to say that a location is 'higher' than
// an organization or vice versa.
export function entityTypePriority(entityType: EntityType): number {
  return ORDERED_ENTITY_TYPES.indexOf(entityType);
}

// If we only have an entity id here, we can't know what it is without
// looking it up. Otherwise we can guess from the path
export function usedEntityTypes(entityTreeNode: EntityTreeNodeParams) {
  if (!entityTreeNode.path || entityTreeNode.path === "/") {
    return [];
  }

  // We get type/id/type/id/type/id so we only look at even elements.
  const elements = entityTreeNode.path.split("/").filter((el, i) => {
    return i % 2 === 0;
  });

  return elements.flatMap((type) => {
    const used = entityPathNameToType(type);

    return used ? [used] : [];
  });
}

export default EntityPath;

export { EntityPathIcon, EntitySummary, EntityPathFromParams };
