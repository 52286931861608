import { Button } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { DemographicsForm } from "Patients/DemographicsForm";
import { CreatePatientResult, useCreateMbcPatientMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

export function CreatePatientButton(): ReactElement {
  const { t } = useTranslation(["patients"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? <CreatePatientDialog open={open} onClose={() => setOpen(false)} /> : null;

  return (
    <ReadOnlyOrImpersonatingDisabledButton>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("patients:actions.create")}
      </Button>
      {dialog}
    </ReadOnlyOrImpersonatingDisabledButton>
  );
}

type CreatePatientDialogProps = {
  open: boolean;
  onClose: () => void;
};

const EMPTY_DEFAULTS = {
  legalFirstName: null,
  legalLastName: null,
  preferredFirstName: null,
  preferredLastName: null,
  preferredPronouns: null,
  email: null,
  phoneNumber: null,
  mrn: null,
  gender: null,
  genderIdentity: null,
  genderIdentityFhir: null,
  dob: null,
  notificationPreference: null,
  primaryLanguage: null,
  race: null,
  ethnicity: null,
  isTest: false,
};

function CreatePatientDialog(props: CreatePatientDialogProps): ReactElement {
  const { t } = useTranslation(["patients"]);
  const navigate = useNavigate();

  const [createPatient, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.createPatient,
    useCreateMbcPatientMutation({})
  );

  const onSuccess = (response: CreatePatientResult) => {
    // Use the relative url to navigate directly to the patient.
    navigate(response.patient.id.toString());
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("patients:actions.create")}
      stopBackdropClose={true}
    >
      <DemographicsForm
        defaults={EMPTY_DEFAULTS}
        onSuccess={onSuccess}
        remoteData={remoteData}
        mutation={(fields) => createPatient({ variables: { input: fields } })}
        errorMessage={t("patients:actions.createError")}
      />
    </ResponsiveDialog>
  );
}
