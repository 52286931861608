import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { consultMeetingStatusT, consultRequestReasonT } from "GeneratedGraphQL/EnumTranslations";
import {
  CaseConsult,
  ConsultRequest,
  Patient,
  Provider,
  useCaseConsultListQuery,
  useConsultMeetingListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { ShadedTable } from "MDS/ShadedTable";
import { useIsMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { ConsultMeetingButton } from "./ConsultMeeting";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import Link from "MDS/Link";
import { Section } from "./Section";
import { REQUESTED_CONSULTS_STATUSES } from "./CaseConsult";
import { CompleteConsultButton } from "./CompleteCaseConsultButton";
import { PrepareConsultButton } from "./CaseConsultReview/PrepareConsult";
import { CancelConsultButton } from "./CancelCaseConsultButton";
import Paginator, { initPage } from "Shared/Paginator";
import { CaseConsultListFilters, useCaseConsultListFilters } from "./CaseConsultListFilters";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/PanelManagement/GlobalPanelIndicator";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";

export function CaseConsults(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  return (
    <Page browserTitle={t("collaborativeCare:caseConsult.consultList.title")} supportsPanels>
      <Stack direction="column" spacing={3}>
        <CaseConsultSection />
        <MeetingsSection />
      </Stack>
    </Page>
  );
}

function CaseConsultSection(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const testPatientViewability = useTestPatientViewability();

  const [pagination, setPagination] = React.useState(initPage(10));
  const filters = useCaseConsultListFilters();

  const panelFilter = usePanelFilter();

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        statuses: REQUESTED_CONSULTS_STATUSES,
        primaryCareProviderId: filters.pcp,
        careManagerId: filters.careManager,
        forPanel: panelFilter,
        testPatient: testPatientViewability,
        ...pagination,
      },
      fetchPolicy: "network-only",
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.collaborativeCareCaseConsults?.pageInfo || null,
    _: () => null,
  });

  const missingCasesCount = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareCaseConsults && response.nonPanelConsults) {
        return response.nonPanelConsults.totalCount - response.collaborativeCareCaseConsults.totalCount;
      } else {
        return 0;
      }
    },
    _: () => 0,
  });

  const consults = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareCaseConsults) {
        const consults = [...response.collaborativeCareCaseConsults.nodes];
        consults.sort((a, b) => a.presentationOrder - b.presentationOrder);
        return consults;
      } else {
        return [];
      }
    },
    _: () => [],
  });

  const isMobile = useIsMobile();
  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: () => {
      if (consults.length === 0) {
        return <NoConsultRequestsMessage missingCasesCount={missingCasesCount} />;
      } else if (isMobile) {
        return <CaseConsultCards consults={consults} />;
      } else {
        return <CaseConsultTable consults={consults} />;
      }
    },
  });

  const missingCasesMessage = (
    <Typography variant="caption">
      {t("collaborativeCare:caseConsult.consultList.hiddenConsultRequests", { count: missingCasesCount })}
    </Typography>
  );

  return (
    <Section
      title={t("collaborativeCare:caseConsult.consultList.requestsList")}
      actions={<CaseConsultListFilters filters={filters} />}
    >
      <Stack direction="column" spacing={1} alignItems="center">
        {content}
        {consults.length > 0 ? missingCasesMessage : null}
        <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
      </Stack>
    </Section>
  );
}

type NoConsultRequestsMessageProps = {
  missingCasesCount: number;
};

function NoConsultRequestsMessage(props: NoConsultRequestsMessageProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const message =
    props.missingCasesCount === 0
      ? t("collaborativeCare:caseConsult.consultList.noConsultRequests")
      : t("collaborativeCare:caseConsult.consultList.hiddenConsultRequests", {
          count: props.missingCasesCount,
        });

  return (
    <Stack direction="row" justifyContent="center" margin={2}>
      <Typography variant="h1" marginTop={"1em"}>
        {message}
      </Typography>
    </Stack>
  );
}

function CaseConsultTableHeader(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>
          <Typography fontWeight="bold">
            {t("collaborativeCare:caseConsult.consultList.fields.patient")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight="bold">{t("collaborativeCare:patientList.fields.pcp")}</Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight="bold">
            {t("collaborativeCare:caseConsult.consultList.fields.requestTime")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography fontWeight="bold">
            {t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

type PatientWithName = PickTypename<Patient, "id" | "name"> & {
  cocmPrimaryCareProvider: ProviderWithName | null;
};
type ProviderWithName = PickTypename<Provider, "id" | "name">;
type ConsultRequestSummary = PickTypename<
  ConsultRequest,
  "id" | "requestReason" | "requestText" | "requestDate"
> & {
  provider: ProviderWithName;
};
type CaseConsultSummary = PickTypename<CaseConsult, "id" | "status" | "caseSummary" | "presentationOrder"> & {
  patient: PatientWithName;
  consultRequests: {
    nodes: ReadonlyArray<ConsultRequestSummary>;
  };
};

type CaseConsultListProps = {
  consults: ReadonlyArray<CaseConsultSummary>;
};

type CaseConsultItemProps = {
  consult: CaseConsultSummary;
};

function CaseConsultTable(props: CaseConsultListProps): ReactElement {
  return (
    <ShadedTable>
      <CaseConsultTableHeader />
      <TableBody>
        {props.consults.map((consult, i) => (
          <CaseConsultTableRow consult={consult} key={i} />
        ))}
      </TableBody>
    </ShadedTable>
  );
}

function CaseConsultTableRow(props: CaseConsultItemProps): ReactElement {
  const { t } = useTranslation(["enums", "common", "collaborativeCare"]);

  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  return (
    <TableRow>
      <TableCell>
        <PatientHeaderStatus patientId={props.consult.patient.id} patientName={props.consult.patient.name} />
      </TableCell>
      <TableCell>
        <Link to={`/app/cocm/patient/${props.consult.patient.id}`}>{props.consult.patient.name}</Link>
      </TableCell>
      <TableCell>{props.consult.patient.cocmPrimaryCareProvider?.name}</TableCell>
      <TableCell>
        {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueShort", {
          date: sortedRequests[0]?.requestDate,
        })}
      </TableCell>
      <TableCell>
        {sortedRequests
          .map((request) => `${request.provider.name} (${consultRequestReasonT(request.requestReason, t)})`)
          .join(", ")}
      </TableCell>
      <TableCell>
        <PrepareConsultButton consult={props.consult} />
      </TableCell>
      <TableCell>
        <CompleteConsultButton consult={props.consult} />
      </TableCell>
      <TableCell>
        <CancelConsultButton consult={props.consult} />
      </TableCell>
    </TableRow>
  );
}

function CaseConsultCards(props: CaseConsultListProps): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      {props.consults.map((consult, i) => (
        <CaseConsultCard consult={consult} key={i} />
      ))}
    </Stack>
  );
}

function CaseConsultCard(props: CaseConsultItemProps): ReactElement {
  const { t } = useTranslation(["enums", "common", "collaborativeCare"]);

  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  return (
    <Card>
      <PatientReferenceCardHeader patientId={props.consult.patient.id} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={0.5}>
            <Typography fontWeight="bold">{t("collaborativeCare:patientList.fields.pcp")}:</Typography>
            <Typography>{props.consult.patient.cocmPrimaryCareProvider?.name}</Typography>
          </Stack>
          <Typography marginLeft={1}>
            {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueLong", {
              date: sortedRequests[0]?.requestDate,
            })}
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">
                    {t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">
                    {t("collaborativeCare:caseConsult.consultList.fields.reason")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRequests.map((request, i) => (
                <TableRow key={i}>
                  <TableCell>{request.provider.name}</TableCell>
                  <TableCell>{consultRequestReasonT(request.requestReason, t)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </CardContent>
      <CardActions>
        <PrepareConsultButton consult={props.consult} />
        <CompleteConsultButton consult={props.consult} />
      </CardActions>
    </Card>
  );
}

function MeetingsSection(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);
  const [pagination, setPagination] = React.useState(initPage(10));

  const panelFilter = usePanelFilter();

  const testPatientViewability = useTestPatientViewability();

  const { remoteData } = apolloQueryHookWrapper(
    useConsultMeetingListQuery({
      variables: { forPanel: panelFilter, testPatient: testPatientViewability, ...pagination },
      fetchPolicy: "network-only",
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.collaborativeCareConsultMeetings?.pageInfo || null,
    _: () => null,
  });

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareConsultMeetings || !response.nonPanelConsultMeetings) {
        return <ErrorMessage message={"collaborativeCare:caseConsult.consultList.failedToLoadMeetings"} />;
      }

      const missingMeetingsCount =
        response.nonPanelConsultMeetings.totalCount - response.collaborativeCareConsultMeetings.totalCount;

      return (
        <Stack direction="column" spacing={1} alignItems="center">
          <ShadedTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography fontWeight="bold">
                    {t("collaborativeCare:caseConsult.consultList.fields.meetingDate")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">
                    {t("collaborativeCare:caseConsult.consultList.fields.attendees")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">
                    {t("collaborativeCare:caseConsult.consultList.fields.patients")}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response.collaborativeCareConsultMeetings.nodes.map((meeting, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>{t("common:date.long", { date: meeting.startTime })}</TableCell>
                    <TableCell>
                      {meeting.attendees?.nodes.map((attendee) => attendee.provider.name).join(", ")}
                    </TableCell>
                    <TableCell>
                      {meeting.cases?.nodes.map((caseConsult) => caseConsult.patient.name).join(", ")}
                    </TableCell>
                    <TableCell>{consultMeetingStatusT(meeting.status, t)}</TableCell>
                    <TableCell>
                      <Link to={`meeting/${meeting.id}`}>
                        <Button variant="contained" color="secondary">
                          View Details
                        </Button>
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </ShadedTable>
          <Typography variant="caption">
            {t("collaborativeCare:caseConsult.consultList.hiddenMeetings", { count: missingMeetingsCount })}
          </Typography>
          <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
        </Stack>
      );
    },
  });

  return (
    <Section
      title="Consult Meetings"
      actions={[
        <ConsultMeetingButton key="consult-meeting-button" />,
        <GlobalPanelIndicatorBadge key="panels-badge" />,
      ]}
    >
      {content}
    </Section>
  );
}
