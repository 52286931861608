import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { WithInternalUser } from "AppSession/AuthenticatedProviderUser";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import {
  EntityTreeNodeParams,
  ImplementationTarget,
  ImplementationTargetPhase,
  ImplementationTargetReportGraphDataUnit,
  ImplementationTargetReportPeriod,
  ImplementationTargetType,
} from "GeneratedGraphQL/SchemaAndOperations";
import { allowedEntityTypes } from "Implementation";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import DatePicker from "Shared/DatePickers";
import { enumFromStringValue } from "Shared/Enum";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import {
  extractNumberFieldValue,
  FieldMethods,
  Form,
  NumberFieldMembers,
  TextFieldMethods,
  useBooleanField,
  useForm,
  useNumberField,
  useTextField,
  useWrappedField,
} from "Shared/Form";
import { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ExistingImplementationTargetWarning, {
  ExistingTargetDetails,
  useCheckExistingImplementationTarget,
} from "./ExistingImplementationTargetWarning";
import { ImplementationTargetId } from "Lib/Ids";
import { RemoteData } from "seidr";

// Note that target is required in the current configuration to create, but not required on the backend type, so this is stricter.
// We're unsure whether just 'target' is sufficient or if more is necessary to e.g. have more complicated targets.

type TargetTypeDisplayConfig = {
  period: ImplementationTargetReportPeriod;
  phase: ImplementationTargetPhase;
  targetFormat: ImplementationTargetReportGraphDataUnit;
};

const CONFIG_MAP: Record<ImplementationTargetType, TargetTypeDisplayConfig> = {
  COCM_MONTHLY_BILLED_MINUTES: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.INTEGER,
    phase: ImplementationTargetPhase.ONGOING,
  },
  COCM_MONTHLY_EXPECTED_BILLING: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.MONEY_CENTS,
    phase: ImplementationTargetPhase.ONGOING,
  },
  COCM_MONTHLY_EXPECTED_VALUE_UNITS: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.FLOAT,
    phase: ImplementationTargetPhase.ONGOING,
  },
  COCM_MONTHLY_EXPECTED_RVUS: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.FLOAT,
    phase: ImplementationTargetPhase.ONGOING,
  },
  COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.INTEGER,
    phase: ImplementationTargetPhase.ONGOING,
  },
  COCM_NEW_ENROLLMENTS: {
    period: ImplementationTargetReportPeriod.WEEK,
    targetFormat: ImplementationTargetReportGraphDataUnit.INTEGER,
    phase: ImplementationTargetPhase.LAUNCH,
  },
  COCM_MONTHLY_BILLING_EFFICIENCY: {
    period: ImplementationTargetReportPeriod.MONTH,
    targetFormat: ImplementationTargetReportGraphDataUnit.PERCENT,
    phase: ImplementationTargetPhase.ONGOING,
  },
};

// Defaults that can be passed into the form, if available. They are all optional
export type ImplementationTargetFormDefaults = Partial<
  Pick<
    ImplementationTarget,
    | "name"
    | "description"
    | "targetType"
    | "startDate"
    | "initialCredits"
    | "softLaunchDate"
    | "mirahInternal"
  >
> & { entityTreeNodeParams?: EntityTreeNodeParams; target?: number | null };

// This is the set of parameters that will be sent to you on valid form submission
export type ImplementationTargetFormParameters = Pick<
  ImplementationTarget,
  | "name"
  | "description"
  | "targetType"
  | "startDate"
  | "target"
  | "initialCredits"
  | "softLaunchDate"
  | "mirahInternal"
> & { entityTreeNodeParams: EntityTreeNodeParams; target: number };

type SectionHelpTextProps = {
  text: string;
};
// This is our breakout boxes after each section on this form, explaining what
// each section hopes to accomplish.
export function SectionHelpText(props: SectionHelpTextProps): ReactElement {
  return (
    <Box paddingBottom={"1em"}>
      <Typography variant="body1">{props.text}</Typography>
    </Box>
  );
}

function DateBox(props: {
  date: Date | undefined;
  setDate: (newDate: Date) => void;
  period: ImplementationTargetReportPeriod;
}) {
  const { t } = useTranslation(["implementation"]);
  switch (props.period) {
    case ImplementationTargetReportPeriod.WEEK:
      return (
        <>
          <DatePicker
            label={t("implementation:form.startDate.label")}
            format={t("implementation:form.startDate.format")}
            value={props.date}
            onChange={props.setDate}
            shouldDisableDate={(date) => {
              // TODO: this needs to be expanded to change to months if necessary
              // Only select mondays
              return date.getDay() !== 1;
            }}
          />
          <FormHelperText>{t("implementation:form.startDate.helperText")}</FormHelperText>
        </>
      );
    case ImplementationTargetReportPeriod.MONTH:
      return (
        <DatePicker
          views={["year", "month"]}
          label={t("implementation:form.startDate.label")}
          openTo={"month"}
          value={props.date}
          onChange={props.setDate}
        />
      );
  }
}

function TargetBox(props: {
  target: string | number | undefined;
  setTarget: (value: ChangeEvent<HTMLInputElement>) => void;
  targetType: ImplementationTargetType;
  unitType: ImplementationTargetReportGraphDataUnit;
}) {
  const { t } = useTranslation(["implementation"]);
  const { target, setTarget } = props;
  // TODO: switch to using the money text field when available
  return (
    <>
      <SectionHelpText text={t("implementation:form.configureTarget.helpText")} />
      {/* {unitType === ImplementationTargetReportGraphDataUnit.MONEY_CENTS ? (
        <MoneyTextField
          value={target}
          fullWidth
          label={t("implementation:form.target.label")}
          placeholder={t("implementation:form.target.placeholder")}
          variant="outlined"
          onChange={setTarget}
        />
      ) : ( */}
      <TextField
        type="number"
        value={target}
        fullWidth
        label={t("implementation:form.target.label")}
        placeholder={t("implementation:form.target.placeholder")}
        variant="outlined"
        onChange={setTarget}
      />
      {/* )} */}
    </>
  );
}

function FormInnerFields(props: {
  id: ImplementationTargetId | null;
  fields: TargetFields;
  targetType: ImplementationTargetType;
  existingTarget: RemoteData<Error, ExistingTargetDetails | null>;
}) {
  const { fields, targetType } = props;
  const { t } = useTranslation(["implementation"]);

  const config = CONFIG_MAP[targetType];

  return (
    <>
      <Card>
        <CardHeader title={t("implementation:form.coreDetails.title")} />
        <CardContent>
          <Stack direction="column" spacing={1}>
            <SectionHelpText text={t("implementation:form.coreDetails.helpText")} />
            <TextField
              value={fields.name.value}
              fullWidth
              label={t("implementation:form.name.label")}
              placeholder={t("implementation:form.name.placeholder")}
              variant="outlined"
              onChange={fields.name.onChange}
            />
            <TextField
              value={fields.description.value}
              multiline
              fullWidth
              rows={3}
              label={t("implementation:form.description.label")}
              placeholder={t("implementation:form.description.placeholder")}
              variant="outlined"
              onChange={fields.description.onChange}
            />
            <EntityTreeNodeSelect
              setValue={fields.entityTreeNodeParams.onChange}
              entityTypes={allowedEntityTypes}
              value={fields.entityTreeNodeParams.value ?? null}
            />
            <DateBox
              date={fields.startDate.value}
              setDate={fields.startDate.onChange}
              period={config.period}
            />
            {config.period === ImplementationTargetReportPeriod.WEEK ? (
              <>
                <DatePicker
                  label={t("implementation:form.softLaunchDate.label")}
                  format={t("implementation:form.softLaunchDate.format")}
                  value={fields.softLaunchDate.value || null} // Avoid uncontrolled errors
                  onChange={fields.softLaunchDate.onChange}
                />
                <FormHelperText>{t("implementation:form.softLaunchDate.helperText")}</FormHelperText>
              </>
            ) : null}
            <WithInternalUser>
              <FormControlLabel
                onChange={fields.mirahInternal.onChange}
                checked={fields.mirahInternal.value}
                control={<Checkbox />}
                label={
                  <>
                    <Typography fontWeight={"bold"}>
                      {t("implementation:form.mirahInternal.label")}
                    </Typography>
                    <Typography>{t("implementation:form.mirahInternal.helperText")}</Typography>
                  </>
                }
              />
            </WithInternalUser>
            <ExistingImplementationTargetWarning existing={props.existingTarget} />
          </Stack>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={t("implementation:form.configureTarget.title")} />
        <CardContent>
          <Stack direction="column" spacing={1}>
            <TargetBox
              setTarget={fields.target.onChange}
              target={fields.target.value}
              targetType={targetType}
              unitType={config.targetFormat}
            />
            {config.phase === ImplementationTargetPhase.LAUNCH ? (
              <>
                <TextField
                  type="number"
                  value={fields.initialCredits.value}
                  fullWidth
                  label={t("implementation:form.initialCredits.label")}
                  placeholder={t("implementation:form.initialCredits.placeholder")}
                  variant="outlined"
                  onChange={fields.initialCredits.onChange}
                />
                <FormHelperText>{t("implementation:form.initialCredits.helperText")}</FormHelperText>
              </>
            ) : null}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

type TargetFields = {
  name: TextFieldMethods;
  description: TextFieldMethods;
  targetType: FieldMethods<ImplementationTargetType, ImplementationTargetType>;
  startDate: FieldMethods<Date, Date>;
  softLaunchDate: FieldMethods<Date, Date>;
  entityTreeNodeParams: FieldMethods<EntityTreeNodeParams, EntityTreeNodeParams>;
  target: NumberFieldMembers;
  initialCredits: NumberFieldMembers;
  mirahInternal: FieldMethods<boolean, ChangeEvent<HTMLInputElement>>;
};

export default function ImplementationTargetForm<ResponseType>(props: {
  mode: "create" | "edit";
  id: ImplementationTargetId | null;
  defaults: ImplementationTargetFormDefaults;
  onSuccess?: (response: ResponseType) => void;
  remoteData: MutationRemoteDataResult<ResponseType>;
  mutation: (params: ImplementationTargetFormParameters) => void;
}) {
  const { mode, defaults, onSuccess, remoteData, mutation } = props;
  const { t } = useTranslation(["common", "enums", "implementation"]);
  const [currentRootNode] = useCurrentRootNode();

  const fields = {
    name: useTextField({
      required: true,
      default: defaults.name,
    }),
    description: useTextField({
      required: false,
      default: defaults.description || undefined,
    }),
    targetType: useWrappedField<ImplementationTargetType>({
      required: true,
      default: defaults.targetType,
    }),
    startDate: useWrappedField<Date>({
      default: defaults.startDate,
      required: true,
      errorPaths: ["start_date"],
    }),
    softLaunchDate: useWrappedField<Date>({
      default: defaults.softLaunchDate || undefined,
      required: false,
      errorPaths: ["soft_launch_date"],
    }),
    entityTreeNodeParams: useWrappedField<EntityTreeNodeParams>({
      required: true,
      default: defaults.entityTreeNodeParams ?? currentRootNode,
    }),
    target: useNumberField({
      required: true,
      default: defaults.target ?? undefined,
    }),
    initialCredits: useNumberField({
      required: false,
      default: defaults.initialCredits ?? undefined,
    }),
    mirahInternal: useBooleanField({
      required: false,
      default: defaults.mirahInternal ?? false,
    }),
  };

  const existingTarget = useCheckExistingImplementationTarget(
    props.id,
    fields.targetType.value ?? null,
    fields.startDate.value ?? null,
    fields.entityTreeNodeParams.value ?? null
  );

  const existingTargetPresent = existingTarget.caseOf({
    Success: (result) => !!result,
    _: () => false,
  });

  const form = useForm({
    id: "implementation-target-form",
    submit: () => {
      mutation({
        name: assertNonNull(fields.name.value),
        description: fields.description.value ?? null,
        startDate: assertNonNull(fields.startDate.value),
        softLaunchDate: fields.softLaunchDate.value ?? null,
        targetType: assertNonNull(fields.targetType.value),
        entityTreeNodeParams: assertNonNull(fields.entityTreeNodeParams.value),
        target: assertNonNull(fields.target.value) as number, // Cast is safe as it passed validation
        initialCredits: extractNumberFieldValue(fields.initialCredits.value),
        mirahInternal: assertNonNull(fields.mirahInternal.value),
      });
    },
    remoteData: props.remoteData,
    onSuccess,
    fields: fields,
  });

  const showSpinner = remoteData.kind === "Loading";

  const content = fields.targetType.value ? (
    <FormInnerFields
      id={props.id}
      fields={fields}
      targetType={fields.targetType.value}
      existingTarget={existingTarget}
    />
  ) : (
    <Card>
      <CardContent>{t("implementation:form.chooseTargetType")}</CardContent>
    </Card>
  );

  return (
    <Form onSubmit={form.onSubmit} id={form.id}>
      <Grid container spacing={1}>
        <Grid item lg={2} />
        <Grid item lg={8} xs={12}>
          <Stack direction={"column"} spacing={2}>
            <Card>
              <CardHeader title={t("implementation:form.targetType.title")} />
              <CardContent>
                <FormControl>
                  <SectionHelpText text={t("implementation:form.targetType.helpText")} />
                  <RadioGroup
                    value={fields.targetType.value}
                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                      const enumValue = enumFromStringValue<ImplementationTargetType>(
                        ImplementationTargetType,
                        value
                      );
                      if (enumValue) {
                        fields.targetType.onChange(enumValue);
                      }
                    }}
                  >
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_NEW_ENROLLMENTS}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_NEW_ENROLLMENTS.title")}
                          </Typography>
                          <Typography>
                            {t("enums:ImplementationTargetType.COCM_NEW_ENROLLMENTS.description")}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES.title")}
                          </Typography>
                          <Typography>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES.description")}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING.title")}
                          </Typography>
                          <Typography>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING.description")}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS.title")}
                          </Typography>
                          <Typography>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS.description")}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS.title")}
                          </Typography>
                          <Typography>
                            {t(
                              "enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS.description"
                            )}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.title")}
                          </Typography>
                          <Typography>
                            {t(
                              "enums:ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.description"
                            )}
                          </Typography>
                        </>
                      }
                    />
                    <FormControlLabel
                      value={ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY}
                      control={<Radio />}
                      label={
                        <>
                          <Typography fontWeight={"bold"}>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY.title")}
                          </Typography>
                          <Typography>
                            {t("enums:ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY.description")}
                          </Typography>
                        </>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
            {content}
            <Card>
              <CardActions>
                <ButtonWithSpinner
                  variant="contained"
                  color="secondary"
                  type="submit"
                  showSpinner={showSpinner}
                  disabled={showSpinner || !form.isValid || existingTargetPresent}
                >
                  {t(mode == "create" ? "implementation:form.create" : "implementation:form.edit")}
                </ButtonWithSpinner>
              </CardActions>
            </Card>
          </Stack>
        </Grid>
        <Grid item lg={2} />
      </Grid>
    </Form>
  );
}
