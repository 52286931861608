import { AppRouteProps } from "App";
import { SelectedInstituteGroupsProvider } from "Contexts/ActiveInstituteGroupContext";
import { ImplementationRoutes } from "Implementation";
import AppLayout from "Layout/AppLayout/AppLayout";
import Ops from "Ops";
import { OutcomesRoutes } from "Outcomes";
import RouterWithNotFound from "Shared/RouterWithNotFound";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

export function InternalApp(props: AppRouteProps): ReactElement {
  const navItems = {
    primaryNavigation: {
      label: "Institutes",
      location: "/app/ops/institutes",
    },
    additionalNavigation: [
      { label: "Outcomes", location: "/app/outcomes" },
      { label: "Analytics", location: "/provider/reports" },
      { label: "Implementation", location: "/app/implementation" },
      { label: "Legacy Internal", location: "/internal/institutes" },
    ],
  };
  return (
    <SelectedInstituteGroupsProvider>
      <AppLayout instituteNavItems={[]} userMenuItems={[]} navItems={navItems} {...props}>
        <RouterWithNotFound>
          <Route element={<Ops />} path="ops/*" />
          <Route element={<OutcomesRoutes />} path="outcomes/*" />
          <Route element={<ImplementationRoutes />} path="implementation/*" />
        </RouterWithNotFound>
      </AppLayout>
    </SelectedInstituteGroupsProvider>
  );
}
