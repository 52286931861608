import React from "react";
import { RemoteData } from "seidr";
import { CareEpisodeId } from "Lib/Ids";
import { FeedbackReportContext } from "./FeedbackReportContext";
import {
  CareEpisodeClinicalAlertHistory,
  deduplicateByGroup,
  alertTitle,
} from "Shared/Scale/CareEpisodeClinicalAlert";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { ClinicalAlertType, useOverviewAlertsQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { resultToRemoteData, sortBy } from "Lib/Utils";
import { parseOverviewAlerts } from "FeedbackReport/DetailPane/DataStore/OverviewAlertsTransforms";
import { Stack, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ErrorMessage from "Shared/ErrorMessage";
import { LatestAlertChip } from "Shared/Scale/AlertChip";
import { Link } from "MDS/Link";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { InlineFocusHighlight } from "Shared/Focusable";

type RisksProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

function loadAlerts(
  careEpisodeId: CareEpisodeId,
  feedbackReportContext: FeedbackReportContext
): RemoteData<Error, ReadonlyArray<CareEpisodeClinicalAlertHistory>> {
  const { remoteData } = apolloQueryHookWrapper(
    useOverviewAlertsQueryQuery({
      variables: {
        careEpisodeId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) =>
      resultToRemoteData(parseOverviewAlerts(result, feedbackReportContext.participantsByUserId))
    );
}

const RisksLoadingIndicator = () => {
  return (
    <Stack direction="row" spacing={1}>
      <Skeleton height={20} width={100} />
      <Skeleton height={20} width={60} />
      <Skeleton height={20} width={100} />
      <Skeleton height={20} width={60} />
      <Skeleton height={20} width={100} />
      <Skeleton height={20} width={60} />
    </Stack>
  );
};

export const RisksHookWrapper = (props: RisksProps) => {
  const alerts = loadAlerts(props.careEpisodeId, props.feedbackReportContext);

  return alerts.caseOf({
    NotAsked: () => <RisksLoadingIndicator />,
    Loading: () => <RisksLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (alertHistories) => (
      <Risks alertHistories={alertHistories} feedbackReportContext={props.feedbackReportContext} />
    ),
  });
};

function badgeSortOrder(alertType: ClinicalAlertType): number {
  switch (alertType) {
    case ClinicalAlertType.SUICIDALITY:
      return 0;
    case ClinicalAlertType.ALLIANCE:
      return 1;
    case ClinicalAlertType.MEDICATIONS:
      return 3;
    case ClinicalAlertType.SUBSTANCE_USE:
      return 2;
  }
}

type AlertBadgeProps = {
  history: CareEpisodeClinicalAlertHistory;
  feedbackReportContext: FeedbackReportContext;
};

const AlertBadge = (props: React.PropsWithChildren<AlertBadgeProps>) => {
  return props.history.latest.caseOf({
    Just: (alert) => {
      // Deep route to the responses for the most recent risk
      const route = Routing.alertRoute(props.feedbackReportContext, props.history.id, alert.id);

      // This route is the parent of all the specific response routes, so we'll
      // still count as focusing this risk even if we're looking at an older one
      const focusRoute = Routing.alertHistoryRoute(props.feedbackReportContext, props.history.id);

      return (
        <InlineFocusHighlight route={focusRoute}>
          <SpacedAlertBadge>
            <Link to={route}>{props.children}</Link>
          </SpacedAlertBadge>
        </InlineFocusHighlight>
      );
    },
    Nothing: () => <SpacedAlertBadge>{props.children}</SpacedAlertBadge>,
  });
};

const SpacedAlertBadge = styled("span")(({ theme }) => ({
  display: "inline-block",
  fontSize: theme.typography.h2.fontSize,
  fontWeight: "bold",

  padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
}));

type RiskComponentProps = {
  alertHistories: ReadonlyArray<CareEpisodeClinicalAlertHistory>;
  feedbackReportContext: FeedbackReportContext;
};

const Risks = (props: RiskComponentProps) => {
  const deduplicatedHistories = deduplicateByGroup(props.alertHistories);
  const sortedHistories = sortBy((history) => badgeSortOrder(history.alertType), deduplicatedHistories);

  return (
    <div>
      {sortedHistories.map((history, index) => {
        return (
          <AlertBadge history={history} feedbackReportContext={props.feedbackReportContext} key={index}>
            {alertTitle(history.alertType)}: <LatestAlertChip alertHistory={history} />
          </AlertBadge>
        );
      })}
    </div>
  );
};

export default RisksHookWrapper;
