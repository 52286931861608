import type {} from "@mui/x-data-grid/themeAugmentation";
import { ComponentsProps, ComponentsOverrides } from "@mui/material";
import palette from "../palette";

// Styling this component is notably trickier than most other Mui components. It has a _lot_ of subcomponent pieces
// that can be targeted, this page https://mui.com/x/api/data-grid/data-grid/#css is your friend.
const styleOverrides: ComponentsOverrides["MuiDataGrid"] = {
  columnHeader: {
    backgroundColor: palette.background.paper,
  },
  columnHeaderTitle: {
    fontWeight: "bold",
  },
  cell: {
    borderColor: palette.divider,
    display: "flex",
    alignItems: "center",
  },
  overlay: {
    backgroundColor: palette.common.white,
  },
};

const defaultProps: ComponentsProps["MuiDataGrid"] = {
  disableRowSelectionOnClick: true,
  disableColumnMenu: true,
  disableColumnSelector: true,
  disableColumnFilter: true,
  disableDensitySelector: true,
};

const override = {
  styleOverrides,
  defaultProps,
};

export default override;
