import {
  MetricInclusionCriterionParams,
  MetricMultiScaleScorerConfiguration,
  MetricParams,
  MetricRollupType,
  MetricScaleScorerConfigurationPayload,
  MetricSingleScaleScorerConfiguration,
  MetricSuccessCriterionParams,
  MetricTriggerParams,
  ScaleScorer,
  ScaleScorerType,
  SeverityCategory,
  TimeBasedMetricParams,
  Trend,
  TriggerBasedMetricParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TriggerBasedMetricDefaults } from "./CreateTriggerBasedMetricForm";
import { TimeBasedMetricDefaults } from "./CreateTimeBasedMetricForm";
import { ScaleScorerId } from "Lib/Ids";
import { intersection, uniq } from "ramda";
import { sortSeverities } from "./FormElements/SeverityHelpers";

type HasScorerConfig = {
  scorerConfig:
    | (Pick<MetricMultiScaleScorerConfiguration, "__typename"> & {
        scaleScorers: ReadonlyArray<Pick<ScaleScorer, "id">>;
      })
    | (Pick<MetricSingleScaleScorerConfiguration, "__typename"> & {
        scaleScorer: Pick<ScaleScorer, "id">;
      });
};

export type MetricDefaults =
  | (HasScorerConfig & TimeBasedMetricDefaults)
  | (HasScorerConfig & TriggerBasedMetricDefaults);

export type MetricFormParams = Omit<MetricParams, "scaleScorerConfig" | "populationId">;

function defaultsToParams(defaults: MetricDefaults): MetricParams {
  return {
    ...defaultsToFormParams(defaults),
    scaleScorerConfig: scaleScorerConfigToParams(defaults.scorerConfig),
  };
}

// Changes defaults, i.e. data from the server in the final format, into params suitable for sending back to the server
function defaultsToFormParams(defaults: MetricDefaults): MetricFormParams {
  const payload =
    defaults.__typename == "TimeBasedMetric"
      ? { timeBased: timeBasedDefaultsToParams(defaults) }
      : { triggerBased: triggerBasedDefaultsToParams(defaults) };
  return {
    inclusionCriteria: defaults.metricInclusionCriteria,
    payload,
  };
}

function timeBasedDefaultsToParams(defaults: TimeBasedMetricDefaults): TimeBasedMetricParams {
  return {
    successCriteria: defaults.metricSuccessCriteria,
    timePeriodType: defaults.timePeriodType,
    timePeriodValue: defaults.timePeriodValue,
    timeBasedAggregationType: defaults.timeBasedAggregationType,
  };
}

function triggerBasedDefaultsToParams(defaults: TriggerBasedMetricDefaults): TriggerBasedMetricParams {
  return {
    triggerBasedAggregationType: defaults.triggerBasedAggregationType,
    triggers: defaults.metricTriggers,
  };
}

function ensureMetricSuccessCriterion(criterion: MetricSuccessCriterionParams): MetricSuccessCriterionParams {
  const {
    criterionType,
    id,
    integerValue,
    numberValue,
    operator,
    severityValues,
    stringArrayValue,
    stringValue,
    trendValue,
  } = criterion;
  return {
    criterionType,
    id,
    integerValue,
    numberValue,
    operator,
    severityValues,
    stringArrayValue,
    stringValue,
    trendValue,
  };
}

function ensureMetricInclusionCriterion(
  criterion: MetricInclusionCriterionParams
): MetricInclusionCriterionParams {
  const {
    criterionType,
    id,
    numberValue,
    operator,
    severityValues,
    stringArrayValue,
    stringValue,
    trendValue,
    integerArrayValue,
    excludeResults,
  } = criterion;
  return {
    criterionType,
    id,
    numberValue,
    operator,
    severityValues,
    stringArrayValue,
    stringValue,
    trendValue,
    integerArrayValue,
    excludeResults,
  };
}

function ensureMetricTrigger(trigger: MetricTriggerParams): MetricTriggerParams {
  const { triggerType, stringArrayValue, stringValue, trendValue, id, integerValue, severityValue } = trigger;
  return {
    triggerType,
    stringArrayValue,
    stringValue,
    trendValue,
    id,
    integerValue,
    severityValue,
  };
}

// In theory you can receive a set of metric params which contains extra data. This
// isn't usually a problem EXCEPT that graphql will send the extra fields which aren't allowed.
// So need a function to laboriously drop fields which shouldn't be there.
function ensureMetricParams(dirty: MetricParams): MetricParams {
  let payload = null;

  if (dirty.payload.timeBased) {
    payload = {
      timeBased: {
        successCriteria: dirty.payload.timeBased.successCriteria.map(ensureMetricSuccessCriterion),
        timeBasedAggregationType: dirty.payload.timeBased.timeBasedAggregationType,
        timePeriodType: dirty.payload.timeBased.timePeriodType,
        timePeriodValue: dirty.payload.timeBased.timePeriodValue,
      },
    };
  } else {
    payload = {
      triggerBased: {
        triggers: dirty.payload.triggerBased.triggers.map(ensureMetricTrigger),
        triggerBasedAggregationType: dirty.payload.triggerBased.triggerBasedAggregationType,
      },
    };
  }

  return {
    scaleScorerConfig: dirty.scaleScorerConfig,
    populationId: dirty.populationId,
    payload,
    inclusionCriteria: dirty.inclusionCriteria.map(ensureMetricInclusionCriterion),
  };
}

export type ScaleScorerSupportedOptions = {
  isNumerical: boolean;
  /**
   * All severities are all severities that appear for all scales. These can be used as inclusion criteria, which
   * are OR'd, but not for success criteria, which are AND'd.
   */
  allSeverities: ReadonlyArray<SeverityCategory>;
  /**
   * Common severities are those severities in common with all the chosen scales which can be used for success criteria.
   */
  commonSeverities: ReadonlyArray<SeverityCategory>;
  supportedTrends: ReadonlyArray<Trend>;
};

type ScaleScorerWithName = Pick<ScaleScorer, "id"> & {
  scale: { name: string; shortname: string | null; nanoname: string | null };
};

export type MetricScaleScorerConfigurationIds =
  | (Pick<MetricMultiScaleScorerConfiguration, "__typename"> & {
      scaleScorers: ReadonlyArray<Pick<ScaleScorer, "id">>;
    })
  | (Pick<MetricSingleScaleScorerConfiguration, "__typename"> & {
      scaleScorer: Pick<ScaleScorer, "id">;
    });

export type MetricScaleScorerConfigurationWithName =
  | (Pick<MetricMultiScaleScorerConfiguration, "__typename"> & {
      scaleScorers: ReadonlyArray<ScaleScorerWithName>;
    })
  | (Pick<MetricSingleScaleScorerConfiguration, "__typename"> & {
      scaleScorer: ScaleScorerWithName;
    });

function scaleScorerIdsFromParams(
  params: MetricScaleScorerConfigurationPayload
): ReadonlyArray<ScaleScorerId> {
  if (params.multiScale) {
    return params.multiScale;
  } else {
    return [params.singleScale];
  }
}

function scaleScorerConfigToParams(
  config: MetricScaleScorerConfigurationIds
): MetricScaleScorerConfigurationPayload {
  if (config.__typename === "MetricMultiScaleScorerConfiguration") {
    return {
      multiScale: config.scaleScorers.map((s) => s.id),
    };
  } else {
    return {
      singleScale: config.scaleScorer.id,
    };
  }
}

function scaleScorerDetailsToSupportedOptions(
  scorers: ReadonlyArray<Pick<ScaleScorer, "scoreType" | "supportedSeverityCategories" | "supportedTrends">>
): ScaleScorerSupportedOptions | null {
  if (scorers.length === 0) {
    return null;
  }
  // If it's a single scale metric just return that scale's details.
  else if (scorers.length === 1 && scorers[0]) {
    const scorer = scorers[0];
    return {
      isNumerical: scorer.scoreType === ScaleScorerType.NUMERICAL,
      allSeverities: scorer.supportedSeverityCategories,
      commonSeverities: scorer.supportedSeverityCategories,
      supportedTrends: scorer.supportedTrends,
    };
  } else {
    const commonSeverities = sortSeverities(
      scorers.map((scorer) => scorer.supportedSeverityCategories).reduce(intersection)
    );
    const supportedTrends = scorers.map((scorer) => scorer.supportedTrends).reduce(intersection);

    return {
      // More than one scale is always considered non-numerical
      isNumerical: false,
      allSeverities: sortSeverities(uniq(scorers.flatMap((scorer) => scorer.supportedSeverityCategories))),
      commonSeverities,
      supportedTrends,
    };
  }
}

function scaleScorerParamsToRollupType(params: MetricScaleScorerConfigurationPayload): MetricRollupType {
  if (params.multiScale) {
    return MetricRollupType.MULTI_SCALE;
  } else {
    return MetricRollupType.SINGLE_SCALE;
  }
}

export {
  defaultsToParams,
  defaultsToFormParams,
  ensureMetricParams,
  scaleScorerConfigToParams,
  scaleScorerIdsFromParams,
  scaleScorerDetailsToSupportedOptions,
  scaleScorerParamsToRollupType,
};
