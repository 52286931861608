import React, { ReactElement } from "react";
import { Skeleton } from "@mui/material";
import { RemoteData } from "seidr";
import ScaleSectionsTable from "./ScaleSectionsTable";
import ErrorMessage from "Shared/ErrorMessage";
import { Section } from "Shared/Section";
import { ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { useTranslation } from "react-i18next";

function OneLineLoadingIndicator(): ReactElement {
  return (
    <div>
      <Skeleton width={80} height={20} />
    </div>
  );
}

type TreatmentResponseProps = {
  treatmentResponseData: RemoteData<Error, ReadonlyArray<ScaleSectionSummary>>;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
};

export function TreatmentResponse(props: TreatmentResponseProps): ReactElement {
  const { t } = useTranslation(["report"]);

  const content = props.treatmentResponseData.caseOf({
    NotAsked: () => <OneLineLoadingIndicator />,
    Loading: () => <OneLineLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (sections) => (
      <ScaleSectionsTable scaleSections={sections} feedbackReportContext={props.feedbackReportContext} />
    ),
  });

  return (
    <Section title={t("report:sections.treatmentResponse")} hideTitle={props.hideTitle}>
      {content}
    </Section>
  );
}
