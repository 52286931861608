import { Button } from "@mui/material";
import { CaseConsultId, ConsultMeetingId } from "Lib/Ids";
import Link from "MDS/Link";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type StartCaseConsultReviewButtonProps = {
  caseConsultId: CaseConsultId;
  consultMeetingId: ConsultMeetingId;
};

export default function StartConsultReviewButton(props: StartCaseConsultReviewButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Link to={`case/${props.caseConsultId}`}>
      <Button variant="contained" color="secondary">
        {t("collaborativeCare:caseConsult.consultMeeting.requestedCases.beginConsult")}
      </Button>
    </Link>
  );
}
