import { genderIdentityT, genderT } from "GeneratedGraphQL/EnumTranslations";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { PickTypename } from "type-utils";

type GenderablePatient = PickTypename<Patient, "gender" | "genderIdentity" | "genderIdentityFhir">;
// Parameter<typeof F>[1] gets the type of the second positional parameter of the function. In this case, that's the
// type of a translation function that can translate that enum. By anding these together we get the type of a
// translation function that can translate all of the enums we need, along with the constant fallback string.`
type GenderableTFunc = Parameters<typeof genderT>[1] &
  Parameters<typeof genderIdentityT>[1] &
  ((key: "report:demographics.sexUnknown") => string);

export function genderIdentityWithFallback(patient: GenderablePatient, t: GenderableTFunc) {
  if (patient.genderIdentity) {
    return patient.genderIdentity;
  } else if (patient.genderIdentityFhir) {
    return genderIdentityT(patient.genderIdentityFhir, t);
  } else if (patient.gender) {
    return genderT(patient.gender, t);
  }
  return t("report:demographics.sexUnknown");
}
