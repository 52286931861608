import { Box, Button } from "@mui/material";
import React, { ReactElement } from "react";
import { EnrollmentDialog } from "./EnrollmentDialog";
import { PickTypename } from "type-utils";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import { useTranslation } from "react-i18next";

type EnrollButtonProps = {
  patient: PickTypename<Patient, "id" | "name" | "dob">;
  buttonMinWidth: string;
};

export function EnrollButton(props: EnrollButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showDialog, setShowDialog] = React.useState(false);

  const enrollment = useEnrollmentState(props.patient.id);
  const disabled = enrollment.status === "enrolled" || enrollment.status === "unknown";

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setShowDialog(true)}
        disabled={disabled}
        fullWidth
      >
        {t("collaborativeCare:enrollment.actions.enroll")}
      </Button>

      <EnrollmentDialog open={showDialog} onClose={() => setShowDialog(false)} patient={props.patient} />
    </Box>
  );
}
