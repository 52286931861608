import { Route, Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import NotFound from "Shared/NotFound";
import Login from "Login/Login";
import ForgotPassword from "Login/ForgotPassword";
import SsoLandingPage from "SsoLandingPage";
import LoggedOut from "LoggedOut";

function GenericError(): ReactElement {
  return <NotFound title={decodeURIComponent(window.location.search.substring(1))} subtitle=" " />;
}

function Unauthenticated(): ReactElement {
  // Aight, so what's going on here is these are literally all of the available paths
  // for someone who is not logged in.
  // We specify routes going to login twice for clarity:
  // app/login is meant to be the actual login path.
  // * is just a catch all for any other path, rather than being 404. We'll just redirect users
  // back to login.
  return (
    <Routes>
      <Route element={<GenericError />} path="app/error/*" />
      <Route element={<ForgotPassword />} path="/app/login/forgotpassword" />
      <Route element={<SsoLandingPage />} path="/app/sso-auth" />
      <Route element={<LoggedOut />} path="/app/logged-out" />
      <Route element={<Login />} path="/app/login" />
      <Route element={<Login />} path="/*" />
    </Routes>
  );
}

export default Unauthenticated;
