import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Appointment,
  AppointmentMeasurementStatus,
  AppointmentSortParameter,
  AppointmentStatus,
  AppointmentsAndSessionsQuery,
  DataSourceResourceType,
  Invitation,
  InvitationStatus,
  MeasurementPlanningPhase,
  PatientSession,
  Provider,
  Recurrence,
  Report,
  SortDirection,
  useAppointmentsAndSessionsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DigUnpacked } from "type-utils";
import { appointmentStatusT, invitationFormatT, invitationStatusT } from "GeneratedGraphQL/EnumTranslations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import CareEpisodeAppointmentChip from "./CareEpisodeChip";
import { User } from "@sentry/react";
import { add, differenceInDays, startOfDay, sub } from "date-fns";
import { NotAsked, RemoteData, Success } from "seidr";
import { ApolloError } from "@apollo/client";
import Spinner from "Shared/Spinner";
import { IntegrationStatusBadgeInline } from "Integrations/IntegrationStatusBadge";
import { AppointmentId, CareEpisodeId, ImportHistoryId, PatientId } from "Lib/Ids";
import { Link } from "MDS/Link";
import { AppointmentPatientSessionCreationAlgorithmTooltip } from "../Appointments/PatientSessionCreationAlgorithm";
import { CreateAppointmentButton } from "Patients/Appointments/CreateAppointmentButton";
import { CancelAppointmentLink } from "Patients/Appointments/CancelAppointmentButton";

type ProviderWithName = Pick<Provider, "name">;

function AppointmentDetailsCell(props: {
  patientId: PatientId;
  appointment: Pick<Appointment, "id" | "startDate" | "status" | "source"> & {
    provider: ProviderWithName | null;
    patientSession:
      | (Pick<PatientSession, "isReportAvailable"> & { schedulingRecurrence: Pick<Recurrence, "id"> | null })
      | null;
  };
}) {
  const { t } = useTranslation(["common", "enums", "patients"]);
  const { appointment } = props;

  const dateDisplay = appointment.startDate
    ? differenceInDays(new Date(), appointment.startDate) > 365
      ? "common:date.tiny"
      : "common:date.tinyWithTime"
    : "patients:appointments.noDate";

  const cancelLink =
    appointment.status === AppointmentStatus.CANCELED ? null : (
      <CancelAppointmentLink appointment={appointment} text={"(Cancel)"} />
    );

  return (
    <>
      <Typography>
        <Link to={`/app/patients/${props.patientId}/appointments/${appointment.id}`}>
          {t(dateDisplay, { date: appointment.startDate })}
        </Link>
        <IntegrationStatusBadgeInline
          importHistoryId={appointment.id as unknown as ImportHistoryId}
          resourceType={DataSourceResourceType.APPOINTMENT}
        />
      </Typography>
      <Typography>
        {appointment.provider ? appointment.provider.name : t("patients:appointments.noProvider")}
      </Typography>
      <Typography fontSize={"0.8em"} fontStyle={"italic"}>
        {appointmentStatusT(appointment.status, t)} {cancelLink}
      </Typography>
    </>
  );
}

type InvitationDetails = Pick<Invitation, "id" | "status" | "format"> & { user: Pick<User, "name"> };
type ReportDetails = Pick<Report, "id"> & { invitations: ReadonlyArray<InvitationDetails> };

function MeasurementDetailsCell(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  appointmentMeasurementStatus: AppointmentMeasurementStatus;
  patientSession:
    | (Pick<PatientSession, "id" | "autoplan" | "maxPlanningPhase"> & {
        assessmentReport: ReportDetails | null;
      })
    | null;
}) {
  const { t } = useTranslation(["patients", "enums"]);

  if (
    props.patientSession?.autoplan &&
    (!props.patientSession.maxPlanningPhase ||
      props.patientSession.maxPlanningPhase === MeasurementPlanningPhase.PLANS_CALCULATED)
  ) {
    return (
      <Tooltip title={t("patients:appointments.notYetPlannedExplanation")}>
        <Typography>{t("patients:appointments.notYetPlanned")}</Typography>
      </Tooltip>
    );
  }

  if (!props.patientSession?.assessmentReport) {
    return (
      <Typography>
        {t("patients:appointments.notMeasured")} (
        <AppointmentPatientSessionCreationAlgorithmTooltip
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown
        >
          <Link to={`appointments/${props.appointmentId}#patientSessionCreation`}>
            {t("patients:appointments.why")})
          </Link>
        </AppointmentPatientSessionCreationAlgorithmTooltip>
      </Typography>
    );
  }

  const invitations = useMemo(() => {
    if (!props.patientSession?.assessmentReport?.invitations) {
      return [];
    }

    return props.patientSession.assessmentReport.invitations.filter(
      (inv) => inv.status !== InvitationStatus.NOT_REQUIRED
    );
  }, props.patientSession.assessmentReport.invitations);

  if (invitations.length === 0) {
    return (
      <Typography>
        {t("patients:appointments.nothingToMeasure")} (
        <Link to={`appointments/${props.appointmentId}#scaleSelection`}>
          {t("patients:appointments.why")})
        </Link>
      </Typography>
    );
  }

  let warnings = null;

  if (props.appointmentMeasurementStatus === AppointmentMeasurementStatus.NOTIFICATIONS_BLOCKED) {
    warnings = <Typography>{t("patients:appointments.notificationsBlocked")}</Typography>;
  } else if (props.appointmentMeasurementStatus === AppointmentMeasurementStatus.AWAITING_INTERVIEW) {
    warnings = <Typography> {t("patients:appointments.awaitingInterview")}</Typography>;
  }

  const rows = invitations.map((invitation) => {
    return (
      <li key={invitation.id.toString()}>
        {invitation.user.name} - {invitationFormatT(invitation.format, t)} -{" "}
        {invitationStatusT(invitation.status, t)}
      </li>
    );
  });

  return (
    <>
      <ul>{rows}</ul>
      {warnings}
    </>
  );
}

function AppointmentRow(props: {
  patientId: PatientId;
  appointment: DigUnpacked<AppointmentsAndSessionsQuery, ["schedulingAppointments", "nodes"]>;
}) {
  return (
    <TableRow>
      <TableCell>
        <AppointmentDetailsCell appointment={props.appointment} patientId={props.patientId} />
      </TableCell>
      <TableCell>
        <CareEpisodeAppointmentChip
          appointmentId={props.appointment.id}
          careEpisode={props.appointment.careEpisode}
          patientId={props.patientId}
        />
      </TableCell>
      <TableCell>
        <MeasurementDetailsCell
          appointmentId={props.appointment.id}
          patientId={props.patientId}
          patientSession={props.appointment.patientSession}
          appointmentMeasurementStatus={props.appointment.measurementStatus}
        />
      </TableCell>
    </TableRow>
  );
}

export function UpcomingAppointmentsCard(props: { patientId: PatientId; careEpisodeId?: CareEpisodeId }) {
  const [startOfToday, _] = React.useState(startOfDay(new Date()));
  const { t } = useTranslation(["patients"]);

  const [startDateBefore, setStartDateBefore] = useState(add(startOfToday, { weeks: 3 }));

  return (
    <AppointmentsCard
      onExtendDate={() => setStartDateBefore(add(startDateBefore, { weeks: 6 }))}
      patientId={props.patientId}
      careEpisodeId={props.careEpisodeId}
      sortDirection={SortDirection.ASC}
      startDateBefore={startDateBefore}
      startDateAfter={startOfToday}
      title={t("patients:patientDetails.cards.upcomingAppointments")}
      showCreate
    />
  );
}

export function RecentAppointmentsCard(props: { patientId: PatientId; careEpisodeId?: CareEpisodeId }) {
  const [startOfToday, _] = React.useState(startOfDay(new Date()));
  const { t } = useTranslation(["patients"]);

  const [startDateAfter, setStartDateAfter] = useState(sub(startOfToday, { months: 3 }));

  return (
    <AppointmentsCard
      onExtendDate={() => setStartDateAfter(sub(startDateAfter, { months: 6 }))}
      patientId={props.patientId}
      careEpisodeId={props.careEpisodeId}
      sortDirection={SortDirection.DESC}
      startDateAfter={startDateAfter}
      startDateBefore={startOfToday}
      title={t("patients:patientDetails.cards.recentAppointments")}
    />
  );
}

export function AppointmentsCard(props: {
  patientId: PatientId;
  careEpisodeId?: CareEpisodeId;
  title: string;
  startDateBefore: Date;
  startDateAfter: Date;
  sortDirection: SortDirection;
  onExtendDate: () => void;
  showCreate?: boolean;
}): ReactElement {
  const { t } = useTranslation(["patients", "enums", "common"]);

  const { startDateAfter, startDateBefore, sortDirection } = props;

  // We don't want to flash when loading more rows, so do the query then set the result when we get it back.
  const [cachedRemoteData, setCachedRemoteData] = React.useState<
    RemoteData<ApolloError, AppointmentsAndSessionsQuery>
  >(NotAsked());

  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentsAndSessionsQuery({
      variables: {
        patientId: props.patientId,
        careEpisodeId: props.careEpisodeId || null,
        sortBy: AppointmentSortParameter.STARTDATE,
        sortDirection,
        first: 100,
        startDateAfter,
        startDateBefore,
      },
      onCompleted: (data) => {
        setCachedRemoteData(Success(data));
      },
    })
  );

  const actions = props.showCreate ? <CreateAppointmentButton patientId={props.patientId} /> : null;

  const showingText =
    sortDirection === SortDirection.ASC
      ? "patients:appointments.showingAfter"
      : "patients:appointments.showingBefore";

  const showingDate = sortDirection === SortDirection.ASC ? startDateBefore : startDateAfter;

  const loadMoreRowText =
    remoteData.kind === "Loading" ? (
      "Loading..."
    ) : (
      <span>
        {t(showingText, { date: showingDate })} -{" "}
        <u onClick={props.onExtendDate}>{t("patients:appointments.showMore")}</u>
      </span>
    );

  const loadMoreRow = (
    <TableRow>
      <TableCell colSpan={5} sx={{ textAlign: "center", fontSize: "0.8em" }}>
        {loadMoreRowText}
      </TableCell>
    </TableRow>
  );

  const rows = cachedRemoteData.caseOf({
    Success: (result) => {
      if (result.schedulingAppointments && result.schedulingAppointments.nodes.length > 0) {
        const rows = result.schedulingAppointments.nodes.map((appointment) => {
          return (
            <AppointmentRow
              key={appointment.id.toString()}
              appointment={appointment}
              patientId={props.patientId}
            />
          );
        });

        return rows;
      } else {
        return [
          <TableRow key="noAppointments">
            <TableCell sx={{ textAlign: "center" }} colSpan={4}>
              {t("patients:appointments.noAppointments")}
            </TableCell>
          </TableRow>,
        ];
      }
    },
    Failure: () => {
      return [
        <TableRow key="error">
          <TableCell sx={{ textAlign: "center" }} colSpan={5}>
            {t("common:unexpectedError")}
          </TableCell>
        </TableRow>,
      ];
    },
    _: () => {
      return [
        <TableRow key="loading">
          <TableCell sx={{ textAlign: "center" }} colSpan={5}>
            <Spinner />
          </TableCell>
        </TableRow>,
      ];
    },
  });

  return (
    <Card>
      <CardHeader title={props.title} action={actions} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("patients:appointments.columnHeaders.appointment")}</TableCell>
              <TableCell>{t("patients:appointments.columnHeaders.episodeOfCare")}</TableCell>
              <TableCell>{t("patients:appointments.columnHeaders.measurement")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
            {loadMoreRow}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
