import { useIsMobile } from "Shared/Responsive";
import React, { ReactElement } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

/**
 * This wraps the DndProvider component from react-dnd to automatically swap between the HTML5 backend and the Touch
 * backend based on whether the viewport is desktop or mobile. Frustratingly, the HTML5 drag and drop API doesn't
 * support touch events, so it can't be used on mobile. And touch events only fire when you're using a touchscreen,
 * so the touch backend can't be used on desktop. Fortunately react-dnd provides a common API we can code against
 * and just swap the backend out.
 *
 * Based on what happens in the Firefox mobile emulator mode, you have to refresh the page to actually swap between
 * backends, but that should only be a problem for real users if they're dragging window sizes around a lot or
 * something. I don't expect that to happen much in practice, so I'm comfortable with this for now.
 *
 * The delayTouchStart option only has an effect with the TouchBackend on mobile, and is meant to make it so users can
 * scroll through a drag and drop list without initiating drag events, and then still drag by holding a tap on an item
 * briefly before starting to drag. 50 milliseconds is pretty arbitrary, but it was the shortest time I found that I
 * didn't generate spurious drag events while scrolling. This feels pretty good to me when I use it on a device but it's
 * not exactly discoverable and might not feel good to other people. I want to get it up on QA so the rest of the team
 * can try it out and then we'll decide to keep it or add an explicit drag handle or something.
 */
export function ResponsiveDndProvider(props: React.PropsWithChildren): ReactElement {
  const backend = useIsMobile() ? TouchBackend : HTML5Backend;
  return (
    <DndProvider backend={backend} options={{ delayTouchStart: 50 }}>
      {props.children}
    </DndProvider>
  );
}
