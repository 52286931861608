import React, { ReactElement, useState } from "react";
import Chip from "@mui/material/Chip";
import Id from "Lib/Id";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Check from "@mui/icons-material/Check";
import { Clear } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import CareEpisodeDetailsTreatmentTracksPillDialog from "./CareEpisodeDetailsTreatmentTracksPillDialog";
import { CareEpisodeTreatmentTrackStatus } from "GeneratedGraphQL/SchemaAndOperations";
import SimpleTooltip from "MDS/Tooltip/SimpleTooltip";

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: "32px", // Experimentally determined: it's the exact height of the pills.
  borderColor: theme.palette.dividerChip,
}));

type CareEpisodeDetailsTreatmentTracksPillProps = {
  careEpisodeTreatmentTrackId: Id<"CareEpisodeTreatmentTrack">;
  name: string;
  startSessionNumber: number;
  enrollmentDate: Date;
  showPillOptions: boolean;
};

function CareEpisodeDetailsTreatmentTracksPill(
  props: CareEpisodeDetailsTreatmentTracksPillProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  // Open/close the complete dialog.
  const [showComplete, setShowComplete] = useState(false);
  const openComplete = function () {
    setShowComplete(true);
  };
  const closeComplete = function () {
    setShowComplete(false);
  };

  // Open/close the entered in error dialog.
  const [showEnteredInError, setShowEnteredInError] = useState(false);
  const openEnteredInError = function () {
    setShowEnteredInError(true);
  };
  const closeEnteredInError = function () {
    setShowEnteredInError(false);
  };

  // We generate two differently aligned typographies depending on the
  // presence or absence of the buttons. It meaningfully changes the text
  // positioning.
  const buttons = props.showPillOptions ? (
    <>
      <Typography variant="h3" paddingTop={"0.6rem"}>
        {props.name}
      </Typography>
      <StyledDivider orientation="vertical" />
      <IconButton onClick={openComplete} sx={{ padding: "4px" }}>
        <Check fontSize="small" />
      </IconButton>
      <StyledDivider orientation="vertical" />
      <IconButton onClick={openEnteredInError} sx={{ padding: "4px" }}>
        <Clear fontSize="small" />
      </IconButton>
    </>
  ) : (
    <Typography variant="h3">{props.name}</Typography>
  );

  const label = (
    <SimpleTooltip
      title={t("careEpisodes:treatmentTracks.enrollmentData", {
        sessionNumber: props.startSessionNumber + 1,
        date: props.enrollmentDate,
      })}
    >
      <Stack direction="row" spacing={0.7}>
        {buttons}
      </Stack>
    </SimpleTooltip>
  );
  return (
    <span>
      <Chip label={label} variant="outlined" />
      <CareEpisodeDetailsTreatmentTracksPillDialog
        careEpisodeTreatmentTrackId={props.careEpisodeTreatmentTrackId}
        name={props.name}
        status={CareEpisodeTreatmentTrackStatus.COMPLETE}
        open={showComplete}
        onClose={closeComplete}
        title={t("careEpisodes:treatmentTracks.pills.completeTitle")}
        text={t("careEpisodes:treatmentTracks.pills.completeText", {
          name: props.name,
        })}
      />
      <CareEpisodeDetailsTreatmentTracksPillDialog
        careEpisodeTreatmentTrackId={props.careEpisodeTreatmentTrackId}
        name={props.name}
        status={CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR}
        open={showEnteredInError}
        onClose={closeEnteredInError}
        title={t("careEpisodes:treatmentTracks.pills.enteredInErrorTitle")}
        text={t("careEpisodes:treatmentTracks.pills.enteredInErrorText", {
          name: props.name,
        })}
      />
    </span>
  );
}

export default CareEpisodeDetailsTreatmentTracksPill;
