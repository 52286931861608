import { Grid } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "Shared/Responsive";
import { Findings } from "./Findings";
import { DashboardTaskList } from "./BetterTaskList/DashboardTaskList";
import { INBOX_MESSAGES_FINDINGS_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";
import { InboxMessagesFindings } from "./InboxMessagesFindings";

export function CareManagerDashboard(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const columns = useIsMobile() ? 6 : 12;

  const useInboxFindings = useIsFrontendFlagEnabled(INBOX_MESSAGES_FINDINGS_FLAG);
  const findingsContent = useInboxFindings ? <InboxMessagesFindings /> : <Findings />;
  return (
    <Page browserTitle={t("collaborativeCare:careManagerDashboard.title")} supportsPanels>
      <Grid container columns={columns} spacing={2}>
        <Grid item xs={6}>
          <DashboardTaskList />
        </Grid>
        <Grid item xs={6}>
          {findingsContent}
        </Grid>
      </Grid>
    </Page>
  );
}
