import React from "react";
import { Box, Typography, Card, CardContent, Stack, CardActions, styled } from "@mui/material";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import {
  CareEpisodeProviderRelationship,
  CollaborativeCarePatientSearchQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import { QuickTrackButton } from "CollaborativeCare/QuickTrackButton";
import { DigUnpacked } from "type-utils";

// We want these cards to take up (almost) all of the width of the viewport so that they're all the same size rather
// than getting wider and skinnier based on things like how long the patient's name is.
const MostOfThePageCard = styled(Card)(() => ({
  width: "90%",
}));

export function MobilePatientListCard(props: {
  row: DigUnpacked<CollaborativeCarePatientSearchQuery, ["patients", "nodes"]>;
}) {
  const { row } = props;
  const { t } = useTranslation(["collaborativeCare"]);

  let pcp = "";
  if (row.activeCollaborativeCareCareEpisode) {
    const provider = row.activeCollaborativeCareCareEpisode.careEpisodeProviders.find((provider) => {
      return provider.relationship == CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN;
    });
    pcp = provider?.provider.orderedName ?? "";
  }

  let cm = "";
  if (row.activeCollaborativeCareCareEpisode) {
    const provider = row.activeCollaborativeCareCareEpisode.careEpisodeProviders.find((provider) => {
      return provider.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
    });
    cm = provider?.provider.orderedName ?? "";
  }

  const enrollment =
    row.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment?.status ||
    t("collaborativeCare:enrollment.unenrolled");
  return (
    <MostOfThePageCard>
      <PatientReferenceCardHeader patientId={row.id} />
      <CardContent>
        <Stack direction="row" spacing={1}>
          {/* This is the left side grouping. */}
          <Stack direction="column" spacing={1}>
            <Typography variant="h3" color="text.secondary">
              {enrollment}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.mrn")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {row.mrn}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          {/* This is the right side grouping. */}
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.pcp")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {pcp}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.cm")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {cm}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <QuickTrackButton patientId={row.id} />
      </CardActions>
    </MostOfThePageCard>
  );
}
