// Contains methods to make it easier to work with enums.
import { fromArray, head, length, toArray } from "Lib/NonEmptyList";

export type SimpleEnum = Record<string, string>;

// Given an actual enum, turns that enum into an array of options
// suitable for passing into a UI select box.
export function enumToOptions<T extends SimpleEnum>(enumType: T): Array<keyof T> {
  return Object.entries(enumType).map((v) => {
    return v[0]; // The first element is the key for the enum; the second is the value.
  });
}

// Given an index generated by enumToOptions, what enum value is that with the
// provided enum. This is useful for setting the state from an event.target.value
// in a UI select box.
export function optionToEnum<T>(enumType: T, option: string) {
  return enumType[option as keyof typeof enumType];
}

export function enumFromStringValue<T>(enm: SimpleEnum, value: string): T | undefined {
  return (Object.values(enm) as unknown as ReadonlyArray<string>).includes(value)
    ? (value as unknown as T)
    : undefined;
}

export type EnumValueType = string;
export type EnumType = { [key in EnumValueType]: EnumValueType };
export type EnumValueOf<T> = T[keyof T];
export type EnumItems<T> = Array<EnumValueOf<T>>;

export function getEnumItems<T>(item: EnumType & T): EnumItems<T> {
  return (
    // get enum keys and values
    Object.values(item)
      // map items to enum
      .map((e) => item[e as keyof T]) as EnumItems<T>
  );
}

export function getRandomEnumValue<T>(item: EnumType & T): EnumValueOf<T> {
  return fromArray(getEnumItems(item)).caseOf({
    Nothing: () => {
      throw new Error("This enum is empty");
    },
    Just: (nel) => {
      const len = length(nel);
      return toArray(nel)[Math.floor(Math.random() * len)] || head(nel);
    },
  });
}
