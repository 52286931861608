import {
  Assessment,
  Block,
  CalendarMonth,
  Check,
  ChildCare,
  PinOutlined,
  RemoveCircle,
  Schedule,
  Textsms,
} from "@mui/icons-material";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  WithEnabledDataSourceResource,
  WithoutEnabledDataSourceResource,
} from "Contexts/CurrentInstituteContext";
import {
  DataSourceResourceType,
  PatientQualityIssue,
  PatientQualityIssueStatus,
  PatientQualityIssueType,
  QualityIssueDetailQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { DigUnpacked } from "type-utils";
import EditQualityIssue from "./EditQualityIssue";
import { statusToSuperStatus } from "./PatientQualityIssue";
import { format } from "date-fns";
import { Link } from "MDS/Link";
import { Box } from "@mui/system";
import { Blockquote } from "MDS/Blockquote";

type Issue = DigUnpacked<QualityIssueDetailQuery, ["patient", "patientQualityIssues"]>;
type PatientInfo = Pick<DigUnpacked<QualityIssueDetailQuery, ["patient"]>, "user" | "dob" | "name" | "id">;

export default function QualityIssueDetailCard(props: {
  issue: Issue;
  patientInfo: PatientInfo;
  onEdit: (issue: Pick<PatientQualityIssue, "id">) => void;
}): ReactElement {
  const { t } = useTranslation(["qualityIssues"]);
  const theme = useTheme();

  const { issue, patientInfo } = props;
  const superStatus = statusToSuperStatus(issue.status);

  const [expanded, setExpanded] = useState(superStatus === "active");
  const [note, setNote] = useState("");

  const onEdit = (_newStatus: PatientQualityIssueStatus) => {
    setNote("");
    props.onEdit(issue);
  };

  // TODO: Do this some other way so it doesn't hit the action button
  const opacity = superStatus === "resolved" ? 0.5 : 1;
  const backgroundColor =
    superStatus === "pending" ? theme.palette.background.default : theme.palette.background.paper;
  const border =
    superStatus === "pending" ? `2px dashed ${theme.palette.qualityIssues.snoozedBorder}` : "none";
  const actions =
    superStatus !== "resolved" && expanded ? (
      <EditQualityIssue
        patientQualityIssue={issue}
        providerName={issue.provider?.name}
        note={note}
        onEdit={onEdit}
      />
    ) : null;

  return (
    <Card variant="outlined" sx={{ opacity, backgroundColor, border }}>
      <CardHeader
        onClick={() => {
          setExpanded(!expanded);
        }}
        avatar={<QualityIssueBadge issueType={issue.issue} />}
        title={t(`qualityIssues:issues.${issue.issue}.longTitle`)}
        subheader={t(`qualityIssues:superStatuses.${superStatus}.subTitle`, {
          provider: issue.provider?.name,
          resolvedAt: issue.resolvedAt,
          statusUntil: issue.statusActiveUntil,
        })}
        action={<QualityIssueCardAction issue={issue} expanded={expanded} />}
      />
      <Collapse in={superStatus !== "resolved" && expanded}>
        {/* Extra padding here so the body text of the card aligns with the header text */}
        <CardContent sx={{ paddingLeft: "5.5rem" }}>
          <Stack direction="column" spacing={1}>
            <QualityIssueCardContent issue={issue} patientInfo={patientInfo} />
            <Blockquote text={props.issue.notes} citation={`${props.issue.provider?.name}'s notes`} />
            <TextField
              value={note}
              onChange={(event) => setNote(event.target.value)}
              multiline
              minRows={2}
              label="Your notes"
            />
          </Stack>
        </CardContent>
      </Collapse>
      <CardActions sx={{ justifyContent: "flex-end" }}>{actions}</CardActions>
    </Card>
  );
}

type QualityIssueCardActionProps = {
  issue: Issue;
  expanded: boolean;
};

function QualityIssueCardAction(props: QualityIssueCardActionProps): ReactElement | null {
  const superStatus = statusToSuperStatus(props.issue.status);

  switch (superStatus) {
    case "active": {
      return null;
    }
    case "pending": {
      return props.expanded ? null : <Schedule fontSize="large" />;
    }
    case "resolved": {
      return props.expanded ? (
        <EditQualityIssue patientQualityIssue={props.issue} providerName={props.issue.provider?.name} />
      ) : (
        <Check fontSize="large" />
      );
    }
  }
}

function IconBadge(props: { icon: ReactElement; color: string }): ReactElement {
  return (
    <Box
      borderRadius="50%"
      bgcolor={props.color}
      display="flex"
      alignItems="center"
      width="2.5rem"
      height="2.5rem"
      justifyContent="center"
    >
      {props.icon}
    </Box>
  );
}

function QualityIssueBadge(props: { issueType: PatientQualityIssueType }): ReactElement {
  const theme = useTheme();

  switch (props.issueType) {
    case PatientQualityIssueType.PATIENT_NEVER_OPENED:
    case PatientQualityIssueType.CAREGIVER_NEVER_OPENED: {
      return <IconBadge icon={<Assessment />} color={theme.palette.qualityIssues.neverOpenedMeasurement} />;
    }
    case PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN:
    case PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN: {
      return <IconBadge icon={<RemoveCircle />} color={theme.palette.qualityIssues.contactForbidden} />;
    }
    case PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO:
    case PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO:
    case PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO: {
      return <IconBadge icon={<Textsms />} color={theme.palette.qualityIssues.badContactInfo} />;
    }
    case PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER: {
      return <IconBadge icon={<ChildCare />} color={theme.palette.qualityIssues.noCaregiver} />;
    }
    case PatientQualityIssueType.AGE_INCORRECT: {
      return <IconBadge icon={<PinOutlined />} color={theme.palette.qualityIssues.wrongAge} />;
    }
    case PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD: {
      return <IconBadge icon={<CalendarMonth />} color={theme.palette.qualityIssues.noMeasurementsTaken} />;
    }
  }
}

function QualityIssueCardContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { issue, patientInfo } = props;

  switch (issue.issue) {
    case PatientQualityIssueType.AGE_INCORRECT:
      return <AgeIncorrectContent issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.CAREGIVER_CONTACT_FORBIDDEN:
      return <CaregiverContactForbiddenContent issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.PATIENT_OVER_11_CONTACT_FORBIDDEN:
      return <PatientContactForbiddenContent issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.PATIENT_OVER_11_NO_CONTACT_INFO:
      return <PatientNoContactInfoDetails issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.CAREGIVER_NO_CONTACT_INFO:
      return <CaregiverNoContactInfoDetails issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.PATIENT_UNDER_18_NO_CAREGIVER:
      return <PatientNoCaregiverDetails issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.CAREGIVER_SHARES_CONTACT_INFO:
      return <CaregiverSharedContactInfoDetails issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.CAREGIVER_NEVER_OPENED:
      return <CaregiverNotOpenedContent issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.PATIENT_NEVER_OPENED:
      return <PatientNotOpenedContent issue={issue} patientInfo={patientInfo} />;
    case PatientQualityIssueType.NOT_MEASURED_FOR_EXTENDED_PERIOD:
      return <NoAssessmentsCompletedContent issue={issue} patientInfo={patientInfo} />;
  }
}

function IssueDecisionGuide(props: { condition: ReactNode; yes?: ReactNode; no?: ReactNode }): ReactElement {
  const theme = useTheme();

  // All the random extra <span>s in here are because <ListItem> introduced a flex context, so if you have a ReactNode
  // parameter like yes={<>Some text{" "}<Link>...</Link>{" "}more text} without wrapping it in a span it'll get
  // treated as three separate nodes to lay out instead of one inline node. God help us if we ever actually want that
  // on this page.

  const yesItem = props.yes ? (
    <ListItem>
      <ListItemIcon>
        <Check />
      </ListItemIcon>
      <span>{props.yes}</span>
    </ListItem>
  ) : null;

  const noItem = props.no ? (
    <ListItem>
      <ListItemIcon>
        <Block />
      </ListItemIcon>
      <span>{props.no}</span>
    </ListItem>
  ) : null;

  return (
    <Box sx={{ borderRadius: "0.5rem", padding: "1rem", backgroundColor: theme.palette.info.light }}>
      <List>
        <ListItem>
          <span>{props.condition}</span>
        </ListItem>
        {yesItem}
        {noItem}
      </List>
    </Box>
  );
}

function AgeIncorrectContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  const dobString = patientInfo.dob ? `(${format(patientInfo.dob, "P")})` : null;

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}'s</b> Date of Birth {dobString} may be incorrect.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <WithEnabledDataSourceResource resourceType={DataSourceResourceType.PATIENT}>
          <IssueDecisionGuide
            condition="Is the information correct in your EMR?"
            yes='Select "Ignore" here'
            no='Update the information in the EMR and select "Resolved in EMR" here, it will be resolved automatically'
          />
        </WithEnabledDataSourceResource>
        <WithoutEnabledDataSourceResource resourceType={DataSourceResourceType.PATIENT}>
          <IssueDecisionGuide
            condition={
              <>
                Is the information correct on the patient's{" "}
                <Link target={"_blank"} to={`/provider/patients/${patientInfo.id}/edit`}>
                  Details Page
                </Link>
                ?
              </>
            }
            yes='Select "Ignore" here'
            no="Update their information, this will be automatically resolved"
          />
        </WithoutEnabledDataSourceResource>
      </Typography>
    </>
  );
}

function PatientNoContactInfoDetails(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> has no listed contact information.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <WithEnabledDataSourceResource resourceType={DataSourceResourceType.PATIENT}>
          <IssueDecisionGuide
            condition="Is the information filled out in the EMR?"
            yes='Once the EMR has the correct data, press "Resolved in EMR" and this will be resolved automatically'
            no='If you are unable to find any contact information, select "Ignore"'
          />
        </WithEnabledDataSourceResource>
        <WithoutEnabledDataSourceResource resourceType={DataSourceResourceType.PATIENT}>
          <IssueDecisionGuide
            condition="Can you find a valid phone number of email address for this patient?"
            yes={
              <>
                Add the information to their{" "}
                <Link target={"_blank"} to={`/provider/patients/${patientInfo.id}/edit`}>
                  details page
                </Link>
                , this will resolve automatically
              </>
            }
            no='Select "Ignore" here'
          />
        </WithoutEnabledDataSourceResource>
      </Typography>
    </>
  );
}

function PatientNoCaregiverDetails(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> has no listed caregiver.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <WithEnabledDataSourceResource resourceType={DataSourceResourceType.RELATED_PERSON}>
          <IssueDecisionGuide
            condition="Is it correct that the patient has no caregiver?"
            yes='Select "Ignore" here'
            no='Update their information in the EMR, select "Resolved in EMR" here, this will resolve automatically'
          />
        </WithEnabledDataSourceResource>
        <WithoutEnabledDataSourceResource resourceType={DataSourceResourceType.RELATED_PERSON}>
          <IssueDecisionGuide
            condition="Is it correct that the patient has no caregiver?"
            yes='Select "Ignore" here'
            no={
              <>
                Update the patient's{" "}
                <Link target={"_blank"} to={`/provider/patients/${patientInfo.id}/related-people`}>
                  Related People Page
                </Link>{" "}
                with the caregiver information
              </>
            }
          />
        </WithoutEnabledDataSourceResource>
      </Typography>
    </>
  );
}

function CaregiverNoContactInfoDetails(props: {
  issue: Issue;
  patientInfo: PatientInfo;
}): ReactElement | null {
  const { issue, patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  if (!issue.relatedPerson) {
    // This really shouldn't ever happen but it's difficult to enforce in the type system so provide a default
    // return <EditQualityIssue patientQualityIssue={issue} providerName={issue.provider?.name} />;
    return null;
  }

  return (
    <>
      <Typography component={"div"}>
        <b>{issue.relatedPerson.name}</b> has no listed contact information.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <WithEnabledDataSourceResource resourceType={DataSourceResourceType.RELATED_PERSON}>
          <IssueDecisionGuide
            condition="Are you able to find contact details for the caregiver?"
            yes='Update your records in the EMR and press "Resolved in EMR", this alert will resolve automatically'
            no='Select "Ignore" here'
          />
        </WithEnabledDataSourceResource>
        <WithoutEnabledDataSourceResource resourceType={DataSourceResourceType.RELATED_PERSON}>
          <IssueDecisionGuide
            condition="Are you able to find contact details for the caregiver?"
            yes={
              <>
                Update their information on their{" "}
                <Link
                  target={"_blank"}
                  to={`/provider/patients/${patientInfo.id}/related-people/${issue.relatedPerson.id}/edit`}
                >
                  Details Page
                </Link>
                , this will resolve automatically
              </>
            }
            no='Select "Ignore" here'
          />
        </WithoutEnabledDataSourceResource>
      </Typography>
    </>
  );
}

function CaregiverSharedContactInfoDetails(props: {
  issue: Issue;
  patientInfo: PatientInfo;
}): ReactElement | null {
  const { issue, patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  if (!issue.relatedPerson) {
    // This really shouldn't ever happen but it's difficult to enforce in the type system so provide a default
    // return <EditQualityIssue patientQualityIssue={issue} providerName={issue.provider?.name} />;
    return null;
  }

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> and <b>{issue.relatedPerson.name}</b> share the same contact information.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide condition='Once you have discussed with the patient and caregiver, you can mark this as "Resolved"' />
      </Typography>
    </>
  );
}

function PatientContactForbiddenContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> has turned off notifications. They will not receive any text or emails and
        therefore may be unable to complete assessments.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide
          condition="Is the patient willing to receive assessments?"
          yes={
            <>
              Go to their{" "}
              <Link target={"_blank"} to={`/provider/patients/${patientInfo.id}/edit`}>
                Details Page
              </Link>{" "}
              and edit the 'Notification Preference' setting
            </>
          }
          no='select "Ignore" here.'
        />
      </Typography>
    </>
  );
}

function CaregiverContactForbiddenContent(props: {
  issue: Issue;
  patientInfo: PatientInfo;
}): ReactElement | null {
  const { issue, patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  if (!issue.relatedPerson) {
    // This really shouldn't ever happen but it's difficult to enforce in the type system so provide a default
    // return <EditQualityIssue patientQualityIssue={issue} providerName={issue.provider?.name} />;
    return null;
  }

  return (
    <>
      <Typography component={"div"}>
        <b>{issue.relatedPerson.name}</b> has turned off notifications. They will not receive any text or
        emails and therefore may be unable to complete assessments.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide
          condition="Is the caregiver willing to receive assessments?"
          yes={
            <>
              Go to the caregiver's{" "}
              <Link
                target={"_blank"}
                to={`/provider/patients/${patientInfo.id}/related-people/${issue.relatedPerson.id}/edit`}
              >
                Details Page
              </Link>{" "}
              and edit the 'Notification Preference' setting
            </>
          }
          no='select "Ignore" here.'
        />
      </Typography>
    </>
  );
}

function CaregiverNotOpenedContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement | null {
  const { issue } = props;
  const { t } = useTranslation(["qualityIssues"]);

  if (!issue.relatedPerson) {
    // This really shouldn't ever happen but it's difficult to enforce in the type system so provide a default
    // return <EditQualityIssue patientQualityIssue={issue} providerName={issue.provider?.name} />;
    return null;
  }

  return (
    <>
      <Typography component={"div"}>
        <b>{issue.relatedPerson.name}</b> has not opened any assessments. This may be an indicator that they
        are not receiving assessments or that their email address or phone number is incorrect.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide
          condition="Talk to the caregiver and see if they are receiving assessments. Ask them to take the next link."
          yes="snooze this warning and it will automatically resolve once they take an assessment"
          no='If this is not a problem, select "Ignore" here.'
        />
      </Typography>
    </>
  );
}

function PatientNotOpenedContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> has not opened any assessments. This may be an indicator that they are not
        receiving assessments or that their email address or phone number is incorrect.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide
          condition="Talk to the patient and see if they are receiving assessments. Ask them to take the next link."
          yes="snooze this warning and it will automatically resolve once they take an assessment"
          no='If this is not a problem, select "Ignore" here.'
        />
      </Typography>
    </>
  );
}

function NoAssessmentsCompletedContent(props: { issue: Issue; patientInfo: PatientInfo }): ReactElement {
  const { patientInfo } = props;
  const { t } = useTranslation(["qualityIssues"]);

  return (
    <>
      <Typography component={"div"}>
        <b>{patientInfo.name}</b> has had no assessments completed recently.{" "}
        {t(`qualityIssues:issues.${props.issue.issue}.explanation`)}
        <IssueDecisionGuide
          condition="Has this patient been discharged, or are on a long hiatus?"
          yes='Discharge the episode of care, this will resolve automatically, or select "Ignore" here.'
          no="Please talk to the patient about completing assessments"
        />
      </Typography>
    </>
  );
}
