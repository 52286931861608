import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  Appointment,
  PatientSession,
  Recurrence,
  SourceEnum,
  useDeleteAppointmentMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { PatientId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Key things to know when deleting an appointment:
//  - you can only delete manual appointments, not if it's EHR scheduled
//  - if there is a series you can delete all future on the series.

export function DeleteAppointmentButton(props: {
  patientId: PatientId;
  appointment: Pick<Appointment, "id" | "status" | "source"> & {
    patientSession:
      | (Pick<PatientSession, "isReportAvailable"> & { schedulingRecurrence: Pick<Recurrence, "id"> | null })
      | null;
  };
}) {
  const { t } = useTranslation(["patients"]);
  const navigate = useNavigate();
  const { appointment } = props;
  const [showForm, setShowForm] = useState<boolean>(false);

  const [deleteAppointment, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.schedulingDeleteAppointment,
    useDeleteAppointmentMutation({
      variables: {
        input: {
          appointmentId: props.appointment.id,
        },
      },
      refetchQueries: ["AppointmentDetails", "AppointmentInvitationDetails"],
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        // Go back to the patient page once they have been deleted.
        // I would usually put a timeout on this, but if you do you get the 'not found' page underneath the second the deletion happens.
        navigate(`/app/patients/${props.patientId}`);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  if (appointment.source === SourceEnum.BULK_IMPORT) {
    return (
      <Button variant="contained" color="error" disabled>
        {t("patients:appointments.actions.cantDeleteEhrCreated")}
      </Button>
    );
  }

  const showSpinner = remoteData.kind === "Loading";

  const button = (
    <Button onClick={() => setShowForm(true)} disabled={showSpinner} variant={"outlined"} color={"error"}>
      {t("patients:appointments.actions.delete")}
    </Button>
  );

  const bullets = [t("patients:appointments.deleteDialog.noObligation")];

  if (appointment.patientSession) {
    if (appointment.patientSession.isReportAvailable) {
      bullets.push(t("patients:appointments.deleteDialog.reportAvailable"));
    } else {
      bullets.push(t("patients:appointments.deleteDialog.noReport"));
    }
  } else {
    bullets.push(t("patients:appointments.deleteDialog.noSession"));
  }

  if (appointment.patientSession?.schedulingRecurrence?.id) {
    bullets.push(t("patients:appointments.deleteDialog.deleteSeriesPrompt"));
  }

  const deleteWithSeriesButton = appointment.patientSession?.schedulingRecurrence ? (
    <ButtonWithSpinner
      showSpinner={showSpinner}
      variant={"contained"}
      color="error"
      disabled={showSpinner}
      onClick={() =>
        deleteAppointment({ variables: { input: { appointmentId: appointment.id, deleteSeries: true } } })
      }
    >
      {t("patients:appointments.deleteDialog.confirmWithSeries")}
    </ButtonWithSpinner>
  ) : null;

  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("patients:appointments.deleteDialog.title")}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>{t("patients:appointments.deleteDialog.details")}</Typography>
            <ul>
              {bullets.map((bullet, index) => {
                return <li key={index}>{bullet}</li>;
              })}
            </ul>
            <Stack direction="row" spacing={1}>
              <ButtonWithSpinner
                showSpinner={showSpinner}
                variant={"contained"}
                color="error"
                disabled={showSpinner}
                onClick={() => deleteAppointment()}
              >
                {t("patients:appointments.deleteDialog.confirm")}
              </ButtonWithSpinner>
              {deleteWithSeriesButton}
              <Button
                onClick={() => setShowForm(false)}
                disabled={false}
                variant={"outlined"}
                color={"secondary"}
              >
                {t("patients:appointments.deleteDialog.abort")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
