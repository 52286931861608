import * as Sentry from "@sentry/react";

// This function is just meant to be a clean wrapper around
// whatever error handling we are doing elsewhere. It lets us consistently
// make sure to check and scope errors and send them off to the righ
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function captureException(exception: any, codePath: string) {
  Sentry.withScope((scope) => {
    scope.setExtra("codePath", codePath);
    Sentry.captureException(exception);
  });
}

export default captureException;
