import { Box } from "@mui/material";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import { Id } from "Lib/Id";
import * as NEL from "Lib/NonEmptyList";
import * as Staleness from "Lib/Staleness";
import { last } from "Lib/Utils";
import React, { ReactElement } from "react";
import { CareEpisodeComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { GoalAnswer } from "Shared/Scale/Goal";
import NotTakenChip from "Shared/Scale/NotTakenChip";
import { SeverityChip } from "Shared/Scale/SeverityChip";
import SeverityHeatmap from "./SeverityHeatmap";

type SupportedHeatmapTypes = CareEpisodeComputedValueDetails | GoalAnswer;

type Props = {
  height: number;
  maxWidth: number;
  chipWidth?: number;
  onHeatmapClickRoute?: FeedbackReportRoute;
  onChipClickRoute?: (datumId: Id<string>) => FeedbackReportRoute;
  heatmapTooltip?: (child: ReactElement) => ReactElement;
  chipTooltip?: (child: ReactElement) => ReactElement;
  dates: NEL.NonEmptyList<Date>;
  values: ReadonlyArray<SupportedHeatmapTypes>;
};

function SeverityHeatmapWithChip(props: Props): ReactElement {
  const {
    height,
    maxWidth,
    dates,
    values,
    chipWidth,
    onHeatmapClickRoute,
    onChipClickRoute,
    heatmapTooltip,
  } = props;

  const answered = values.filter((value) => value.thresholdClass !== ScaleThresholdClass.UNANSWERED);
  const chip = last(answered).map((value) => {
    let onClickRoute;

    if (onChipClickRoute) {
      onClickRoute = onChipClickRoute(value.id);
    }

    let date;

    if ("targetDate" in value) {
      date = value.targetDate;
    } else {
      date = value.date;
    }

    const transformed = { ...value, date };

    const isStale = Staleness.dateIsStale(date, dates);

    return (
      <SeverityChip
        value={transformed}
        isStale={isStale}
        width={chipWidth}
        onClickRoute={onClickRoute}
        chipTooltip={props.chipTooltip}
      />
    );
  });

  const heatmapbase = (
    <SeverityHeatmap
      height={height}
      maxWidth={maxWidth}
      idealColumnWidth={10}
      dates={dates}
      history={values}
    />
  );

  const heatmap = heatmapTooltip ? heatmapTooltip(heatmapbase) : heatmapbase;

  return (
    <Box data-testid="severity-heatmap-with-chip" sx={{ display: "flex", flexDirection: "row" }}>
      {onHeatmapClickRoute ? <Link to={onHeatmapClickRoute}>{heatmap}</Link> : heatmap}
      <Box sx={{ ml: 0.5, display: "inline-flex" }}>{chip.getOrElse(<NotTakenChip width={chipWidth} />)}</Box>
    </Box>
  );
}

export default SeverityHeatmapWithChip;
