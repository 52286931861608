import { PanelFilter } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { compact } from "type-utils";
import { fromString } from "Lib/Id";
import { PanelId } from "Lib/Ids";
import { COCM_SELECTED_PANELS_STORAGE_KEY } from "Shared/Storage";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { useWithFeatureEnabled } from "./CurrentInstituteContext";

/**
 * "no-panels" mode means that the user has intentionally not selected any panels. We will not be doing any panel
 *             filtering or behavior changes.
 * "panels-active" mode means that the user selected one or more panels and we are using those to do filtering.
 * "panels-ignored" mode means that the user has selected one or more panels, but the current page doesn't support
 *                  doing anything with them, so we are not doing any panel filtering.
 */
type PanelModes = "no-panels" | "panels-active" | "panels-ignored";

/**
 * This is the full state of the panels system - do not use this unless you need to interact with the panels system
 * itself. In most cases you should use `usePanelFilter` below to get you the panel filter you can pass to a GQL API.
 *
 * If you do need to use the full power of this system, read the following. The way the data flows through the panel
 * context is convoluted. I am not happy with this, but I don't have a better solution at hand at present. If you come
 * up with one, I'd love to see it. The component tree that needs to use this data looks something like this:
 *
 * App
 *  |
 *  +- Global Nav
 *  |   |
 *  |   +- Panel Indicator (read + write panel state)
 *  |
 *  +- Some Application Page
 *      |
 *      +- the Page component (write supportsPanels state)
 *          |
 *          +- A query component (reads panel filter state)
 *
 * Note that the place where we know whether a particular page supports panel filtering (a particular application page)
 * is not a parent of the place where the panel indicator is mounted - we need to push that up to the topmost context
 * provider. At the same time, we need the correct supportsPanels boolean to be available immediately to the query
 * component so that it can make the right query on page load. If we only use the setSupportsPanels callback in the top-
 * most context, the query will initially get the wrong panel filter before the useEffect(() => setSupportsPanels) is
 * evaluated. On the other hand, if we only create a new context provider at the page level to provider the new value
 * to the query component, the global panel indicator will drift out of sync with that state. Hence the solution here,
 * with the SupportsPanelsOverrideProvider which both creates a new context provider so that its children have the right
 * data on their first render, as well as push the values up with a useEffect.
 *
 * Like I said, this is strange and confusing, and I struggle to believe it's the correct way to build this. It's the
 * best solution I have though, so if you have to debug it, my condolences.
 */
type SelectedPanels = {
  panels: ReadonlyArray<PanelId>;
  mode: PanelModes;
  setSupportsPanels: (newSupportsPanels: boolean) => void;
  setPanelList: (panels: ReadonlyArray<PanelId>) => void;
};

const SelectedPanelsContext = React.createContext<SelectedPanels>({
  panels: [],
  mode: "no-panels",
  setSupportsPanels: (_newSupportsPanels: boolean) => {
    return;
  },
  setPanelList: () => {
    return;
  },
});

export function SelectedPanelsProvider(props: React.PropsWithChildren): ReactElement {
  const [panels, setPanels] = React.useState<ReadonlyArray<PanelId>>(getSelectedPanelsFromStorage());
  const [supportsPanels, setSupportsPanels] = React.useState(false);

  const setPanelList = (newPanels: ReadonlyArray<PanelId>) => {
    setPanels(newPanels);
    localStorage.setItem(
      COCM_SELECTED_PANELS_STORAGE_KEY,
      newPanels.map((panelId) => panelId.toString()).join(",")
    );
  };

  return (
    <SelectedPanelsContext.Provider
      value={{
        panels: panels,
        mode: usePanelMode(panels, supportsPanels),
        setSupportsPanels: setSupportsPanels,
        setPanelList: setPanelList,
      }}
    >
      {props.children}
    </SelectedPanelsContext.Provider>
  );
}

type SupportsPanelsOverrideProps = React.PropsWithChildren<{
  supportsPanels: boolean;
}>;

export function SupportsPanelsOverrideProvider(props: SupportsPanelsOverrideProps): ReactElement {
  const [supportsPanels, setSupportsPanels] = React.useState(props.supportsPanels);

  const selectedPanels = React.useContext(SelectedPanelsContext);

  useEffectSimpleCompare(() => {
    selectedPanels.setSupportsPanels(supportsPanels);
  }, [supportsPanels]);

  return (
    <SelectedPanelsContext.Provider
      value={{
        panels: selectedPanels.panels,
        mode: usePanelMode(selectedPanels.panels, supportsPanels),
        // Note that because of the useEffect above, calling this will propagate supportsPanels up through all the
        // parent contexts.
        setSupportsPanels: setSupportsPanels,
        setPanelList: selectedPanels.setPanelList,
      }}
    >
      {props.children}
    </SelectedPanelsContext.Provider>
  );
}

function getSelectedPanelsFromStorage() {
  const storageValue = localStorage.getItem(COCM_SELECTED_PANELS_STORAGE_KEY);

  if (storageValue === null) {
    return [];
  }

  return compact(storageValue.split(",").map((id) => fromString<"Panel">(id).getOrElse(null)));
}

function usePanelMode(panels: ReadonlyArray<unknown>, supportsPanels: boolean): PanelModes {
  const collaborativeCare = useWithFeatureEnabled("enableCollaborativeCare");

  if (supportsPanels && collaborativeCare) {
    if (panels.length === 0) {
      return "no-panels";
    } else {
      return "panels-active";
    }
  } else {
    return "panels-ignored";
  }
}

/**
 * Gets the raw data for managing panel state. Use this if you need to modify panel state or render something
 * conditionally baesd on the panel state.
 */
export const useSelectedPanels = () => React.useContext(SelectedPanelsContext);

/**
 * Gets a PanelFilter instance represented by the current panel state. Use this if you're querying something and want
 * to respect panel state. Note that this takes page support into account, so you can use this hook unconditionally
 * in queries that may sometimes respect panels and sometimes ignore them.
 */
export function usePanelFilter(): PanelFilter | null {
  const selectedPanels = useSelectedPanels();

  if (selectedPanels.mode === "panels-active") {
    return {
      panelIds: selectedPanels.panels.map((panelId) => panelId),
    };
  } else {
    return null;
  }
}
