import { Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import {
  MetricInclusionCriterion,
  MetricInclusionCriterionParams,
  MetricParams,
  TriggerBasedAggregationType,
  TriggerBasedMetric,
  MetricTrigger,
  MetricTriggerParams,
  TriggerBasedMetricParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Form, useForm, useListField, useWrappedField } from "Shared/Form";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import InclusionCriteriaForm from "./FormElements/InclusionCriteriaForm";
import TriggersForm from "./FormElements/TriggersForm";
import TriggerBasedAggregationForm from "./FormElements/TriggerBasedAggregationForm";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { ScaleScorerSupportedOptions } from "./OutcomesFormHelpers";

type TriggerBasedMetricFormParams = Omit<MetricParams, "scaleScorerConfig" | "populationId">;

export function CreateTriggerBasedMetricForm<ResponseType>(
  props: CreateTriggerBasedMetricFormProps<ResponseType>
): ReactElement {
  const { t } = useTranslation(["outcomes", "common"]);

  const fields = {
    triggerBasedAggregationType: useWrappedField<TriggerBasedAggregationType>({
      default: props.defaults.triggerBasedAggregationType,
      required: true,
    }),
    triggers: useListField<MetricTriggerParams>({
      default: [...props.defaults.triggers],
      required: true,
      validate: (criteria) => {
        if (criteria.length === 0) {
          return t("outcomes:create.validation.atLeastOneTrigger");
        }
        return null;
      },
    }),
    inclusionCriteria: useListField<MetricInclusionCriterionParams>({
      default: [...props.inclusionCriteriaDefaults],
      required: true,
      validate: (criteria) => {
        // If the criterion has an array for severity but it's empty, that's an error.
        if (
          criteria.findIndex(
            (criterion) => criterion.severityValues && criterion.severityValues.length === 0
          ) > -1
        ) {
          return t("outcomes:create.validation.invalidInclusionSeverity");
        }
        return null;
      },
    }),
  };

  // this is in a thunk so that it's only evaluated when the form is known to be valid
  const transformFields = (): TriggerBasedMetricFormParams => {
    return {
      inclusionCriteria: fields.inclusionCriteria.value,
      payload: {
        triggerBased: {
          triggerBasedAggregationType: fields.triggerBasedAggregationType
            .value as unknown as TriggerBasedAggregationType,
          triggers: fields.triggers.value,
        },
      },
    };
  };

  const form = useForm({
    id: "trigger-based-metric-form",
    fields: fields,
    onSuccess: props.onSuccess,
    submit: () => {
      props.onSave(transformFields());
    },
    remoteData: props.remoteData,
  });

  let transformed: TriggerBasedMetricFormParams | null = null;

  if (form.isValid) {
    transformed = transformFields();
  }

  // Calling the individual elements out seems to be more effective than using deep compare, and simple
  // compare does not work.
  useEffect(() => {
    if (props.onDataChange) {
      props.onDataChange(transformed);
    }
  }, [
    transformed?.inclusionCriteria,
    transformed?.payload.triggerBased?.triggers,
    transformed?.payload.triggerBased?.triggerBasedAggregationType,
  ]);

  return (
    <Form onSubmit={form.onSubmit} id={form.id}>
      <Card>
        <CardHeader title={t("outcomes:create.configureMetric")} sx={{ paddingBottom: 0 }} />
        <CardContent>
          <Stack direction="column" spacing={2.5}>
            <TriggerBasedAggregationForm triggerBasedAggregationType={fields.triggerBasedAggregationType} />
            <InclusionCriteriaForm
              inclusionCriteriaField={fields.inclusionCriteria}
              supportedSeverities={props.scaleScorerSupportedOptions.allSeverities}
            />
            <TriggersForm
              triggersField={fields.triggers}
              supportedSeverities={props.scaleScorerSupportedOptions.commonSeverities}
              supportedTrends={props.scaleScorerSupportedOptions.supportedTrends}
            />
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            form={form.id}
            disabled={form.disableSubmit || props.forceFormInvalid}
          >
            {props.submitButtonText}
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
}

type SectionHelpTextProps = {
  text: string;
};
// This is our breakout boxes after each section on this form, explaining what
// each section hopes to accomplish.
export function SectionHelpText(props: SectionHelpTextProps): ReactElement {
  return (
    <Box paddingBottom={"1em"}>
      <Typography variant="body1">{props.text}</Typography>
    </Box>
  );
}

type CreateTriggerBasedMetricFormProps<ResponseType> = {
  defaults: TriggerBasedMetricParams;
  inclusionCriteriaDefaults: ReadonlyArray<MetricInclusionCriterionParams>;
  onSuccess?: (response: ResponseType) => void;
  onDataChange?: (properties: TriggerBasedMetricFormParams | null) => void;
  onSave: (fields: TriggerBasedMetricFormParams) => void;
  errorMessage: string;
  scaleScorerSupportedOptions: ScaleScorerSupportedOptions;
  forceFormInvalid?: boolean;
  submitButtonText: string;
  remoteData?: MutationRemoteDataResult<ResponseType>;
};

export type TriggerBasedMetricDefaults = Pick<
  TriggerBasedMetric,
  "__typename" | "triggerBasedAggregationType"
> & {
  metricInclusionCriteria: ReadonlyArray<
    Omit<MetricInclusionCriterion, "globalId" | "id" | "metric" | "__typename">
  >;
} & {
  metricTriggers: ReadonlyArray<Omit<MetricTrigger, "globalId" | "id" | "metric" | "__typename">>;
};
