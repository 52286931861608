import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CurrentInstituteDetailsFragment,
  DataSourceResourceType,
  useCurrentInstituteQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, ReactNode, useContext } from "react";
import { Just, Maybe } from "seidr";
import DefaultLoading from "Settings/DefaultLoading";
import ErrorMessage from "Shared/ErrorMessage";
import { BooleanKeys, Dig, NumberKeys } from "type-utils";

export const CurrentInstituteContext: React.Context<Maybe<CurrentInstituteDetailsFragment>> =
  React.createContext(Maybe.fromNullable<CurrentInstituteDetailsFragment>(null));

// No parameters are necessary as the user is explicitly added as part of the gql query
export function WithCurrentInstituteLoaded(props: { children: ReactNode }): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useCurrentInstituteQuery());

  return remoteData.caseOf({
    NotAsked: () => <DefaultLoading />,
    Loading: () => <DefaultLoading />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      return (
        <CurrentInstituteContext.Provider value={Just(result.currentInstitute)}>
          {props.children}
        </CurrentInstituteContext.Provider>
      );
    },
  });
}

type PulledFeatures = keyof Omit<Dig<CurrentInstituteDetailsFragment, ["featureSet"]>, "__typename">;
export type AllowedFeatures = PulledFeatures;

export function WithFeature(
  props: React.PropsWithChildren<{ feature: PulledFeatures }>
): ReactElement | null {
  if (featureIsEnabled(useContext(CurrentInstituteContext), props.feature)) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}

export function WithOutFeature(
  props: React.PropsWithChildren<{ feature: PulledFeatures }>
): ReactElement | null {
  if (featureIsEnabled(useContext(CurrentInstituteContext), props.feature)) {
    return null;
  } else {
    return <>{props.children}</>;
  }
}

type PulledBooleanConfigs = BooleanKeys<
  Omit<Dig<CurrentInstituteDetailsFragment, ["configuration"]>, "__typename">
>;

type PulledNumberConfigs = NumberKeys<
  Omit<Dig<CurrentInstituteDetailsFragment, ["configuration"]>, "__typename">
>;

export function WithConfiguration(
  props: React.PropsWithChildren<{ property: PulledBooleanConfigs }>
): ReactElement | null {
  const isEnabled = useConfigurationEnabled(props.property);
  if (isEnabled) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}

export function useConfigurationEnabled(configurationProperty: PulledBooleanConfigs): boolean {
  const currentInstitute = useContext(CurrentInstituteContext);
  return configurationIsEnabled(currentInstitute, configurationProperty);
}

export function useConfigurationNumber(configurationProperty: PulledNumberConfigs): number {
  const currentInstitute = useContext(CurrentInstituteContext);
  return currentInstitute.map((institute) => institute.configuration[configurationProperty]).getOrElse(0);
}

export function useConfiguration<
  Key extends keyof Omit<Dig<CurrentInstituteDetailsFragment, ["configuration"]>, "__typename">,
  Value = CurrentInstituteDetailsFragment["configuration"][Key]
>(configurationProperty: Key): Value {
  const currentInstitute = useContext(CurrentInstituteContext);

  return currentInstitute.caseOf({
    Just: (institute) => institute.configuration[configurationProperty] as Value,
    Nothing: () => {
      throw new Error("No current institute");
    },
  });
}

export function useInstituteHasGroups(): boolean {
  const currentInstitute = useContext(CurrentInstituteContext);
  return currentInstitute.map((institute) => institute.supportsInstituteGroups).getOrElse(false);
}

function configurationIsEnabled(
  currentInstitute: Maybe<CurrentInstituteDetailsFragment>,
  configurationProperty: PulledBooleanConfigs
): boolean {
  return currentInstitute.map((institute) => institute.configuration[configurationProperty]).getOrElse(false);
}

export function featureIsEnabled(
  currentInstitute: Maybe<CurrentInstituteDetailsFragment>,
  featureName: PulledFeatures
): boolean {
  return currentInstitute
    .map((institute) => {
      return institute.featureSet[featureName];
    })
    .getOrElse(false);
}

export function hasEnabledDataSourceResource(
  currentInstitute: Maybe<CurrentInstituteDetailsFragment>,
  resourceType: DataSourceResourceType
): boolean {
  return currentInstitute
    .map((institute) => {
      if (institute.configuration["integrationEnabled"]) {
        return false;
      }

      return institute.activeDataSourceResources.includes(resourceType);
    })
    .getOrElse(false);
}

/**
 * Returns whether this feature is enabled. Reminder that due to the context this is considered a hook call.
 * @param feature the feature name
 * @returns true if feature is enabled, false if not
 */
export function useWithFeatureEnabled(feature: PulledFeatures): boolean {
  return featureIsEnabled(useContext(CurrentInstituteContext), feature);
}

export function useFeatureSet() {
  return useContext(CurrentInstituteContext).caseOf({
    Just: (institute) => {
      return institute.featureSet;
    },
    Nothing: () => {
      throw new Error("Cannot get feature set before having a current institute");
    },
  });
}

/**
 * Render this block if the institute has the given EMR resource active
 * @param props the children and the resource type
 * @returns the children if the resource is enabled
 */
export function WithEnabledDataSourceResource(
  props: React.PropsWithChildren<{ resourceType: DataSourceResourceType }>
): ReactElement | null {
  if (hasEnabledDataSourceResource(useContext(CurrentInstituteContext), props.resourceType)) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}

/**
 * Render this block unless the institute has the given EMR resource active
 * @param props the children and the resource type
 * @returns the children if the resource is not enabled
 */
export function WithoutEnabledDataSourceResource(
  props: React.PropsWithChildren<{ resourceType: DataSourceResourceType }>
): ReactElement | null {
  if (hasEnabledDataSourceResource(useContext(CurrentInstituteContext), props.resourceType)) {
    return null;
  } else {
    return <>{props.children}</>;
  }
}

export default CurrentInstituteContext;
