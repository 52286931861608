import { GlobalStyles, useTheme } from "@mui/material";
import { getCurrentUser } from "AppSession/AppSession";
import CurrentInstituteContext from "Contexts/CurrentInstituteContext";
import { useEffectOnce } from "Lib/Hooks";
// Normally we wouldn't import the palette directly in a component and prefer to go through mui's theme system, but
// the beacon isn't really a component proper, it's a global function we call in an effect, so we can't do the normal
// `styled` thing here.
import palette from "Style/Theme/palette";
import React, { ReactElement } from "react";

type BeaconConfig =
  | {
      hideAvatars: boolean;
      color: string;
      display?: { position: "left" | "right" };
      docsEnabled: boolean;
    }
  | string
  | {
      name: string;
      email: string;
      signature: string | undefined;
      company: string;
      "institute-id": string;
      "user-id": string;
    };
type Beacon = (event: string, config?: BeaconConfig) => void;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Beacon: Beacon;
  }
}

function HelpScoutBeacon(): ReactElement {
  const theme = useTheme();

  const user = getCurrentUser().getOrElse(null);
  const institute = React.useContext(CurrentInstituteContext).getOrElse(null);

  useEffectOnce(() => {
    window.Beacon("config", {
      hideAvatars: true,
      color: palette.helpScout.background,
      display: { position: "right" },
      docsEnabled: true,
    });
    window.Beacon("init", "2953f495-9db5-4d2e-a0c1-a1f25ec6cc6f");
    if (user && institute) {
      // We actually need to to pre-fill the message platform stuff (ask)
      // but also to show the docs as we keep them secured via identify.
      window.Beacon("identify", {
        name: user.name,
        email: user.email,
        signature: user.helpscoutSignature,
        company: institute.name,
        "institute-id": institute.id.toString(),
        "user-id": user.id,
      });
    }
    return () => {
      window.Beacon("destroy");
    };
  });
  return (
    // We don't even draw the beacon ourselves, it's all handled by javascript.
    // We do need to influence it's zindex to get it over our drawer on small
    // screens, so this is attempting to do that.
    <GlobalStyles
      styles={{
        ".BeaconContainer": { zIndex: `${theme.zIndex.beacon} !important` },
        ".BeaconFabButtonFrame": { marginBottom: "2.5em" },
      }}
    />
  );
}

export default HelpScoutBeacon;
