import { Theme } from "@mui/material/styles";
import { ItemCoding } from "GeneratedGraphQL/SchemaAndOperations";
import { Maybe } from "seidr";

function toColor(normalizedScore: Maybe<ItemCoding>, theme: Theme): string {
  return normalizedScore.caseOf({
    Just: (ic) => {
      switch (ic) {
        case ItemCoding.SEVERE:
          return theme.palette.report.itemCoding.severe.primary;
        // For now, we only alert on severe. We will revisit this in future.
        case ItemCoding.MODERATE:
          return theme.palette.report.itemCoding.moderate.primary;
        case ItemCoding.MILD:
          return theme.palette.report.itemCoding.mild.primary;
        default:
          return theme.palette.report.itemCoding.unknown.primary;
      }
    },
    Nothing: () => theme.palette.report.itemCoding.unknown.primary,
  });
}

export { ItemCoding, toColor };
export default ItemCoding;
