import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { FindingDatagram } from "../Findings";
import { CreateTaskLaterAction } from "./Actions/CreateTaskLaterAction";
import { StartTaskNowAction } from "./Actions/StartTaskNowAction";
import { useTranslation } from "react-i18next";
import { useCurrentProviderId } from "AppSession/AppSession";
import { DismissAction } from "./Actions/DismissAction";
import Link from "MDS/Link";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { FindingsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { DigUnpacked } from "type-utils";
import { SendNotificationAndSnoozeAction } from "./Actions/SendNotificationAndSnoozeAction";

type TimeElapsedSinceInvitationFindingProps = {
  finding: FindingDatagram;
};
export function TimeElapsedSinceInvitationFinding(
  props: TimeElapsedSinceInvitationFindingProps
): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const currentProviderId = useCurrentProviderId();
  if (!props.finding.patient || !props.finding.invitation || !currentProviderId) {
    return null;
  }

  // This is just part of a common payload for our actions, so we might
  // as well define it here.
  const recipientName = props.finding.invitation.user.name;

  const scaleNames = props.finding.invitation.reportScales
    .map(
      (
        rs: DigUnpacked<FindingsQuery, ["collaborativeCareFindings", "nodes", "invitation", "reportScales"]>
      ) => rs.scale
    )
    .map(
      (
        scale: DigUnpacked<
          FindingsQuery,
          ["collaborativeCareFindings", "nodes", "invitation", "reportScales", "scale"]
        >
      ) => scale.shortname
    )
    .join(", ");

  let patientLinkContent = (
    <>
      <Typography variant="h2" component="span">
        <Link to={`/app/cocm/patient/${props.finding.patient.id}`}>{props.finding.patient.name}</Link>{" "}
      </Typography>
    </>
  );
  let caregiverTaskString = ``;
  let caregiverTaskTitleString = t("collaborativeCare:fields.patient.label");

  let viewMeasuresTaskTitle = t("collaborativeCare:findings.administerNowTask.title");
  if (recipientName != props.finding.patient.name) {
    viewMeasuresTaskTitle = t("collaborativeCare:findings.administerNowTask.titleCaregiver");
    caregiverTaskString = `'s ${t("collaborativeCare:findings.caregiverLower")} ***${recipientName}***`;
    caregiverTaskTitleString = t("collaborativeCare:findings.caregiverUpper");
    patientLinkContent = (
      <>
        {patientLinkContent}'s {t("collaborativeCare:findings.caregiverLower")}
        <Typography fontWeight="bold" component="span">
          {" "}
          {recipientName}{" "}
        </Typography>
      </>
    );
  }

  const reminderTask = {
    title: t("collaborativeCare:findings.findings.timeElapsedSinceInvitation.reminderTask.title", {
      caregiverName: caregiverTaskTitleString,
    }),
    body: `[${props.finding.patient.name}](/app/cocm/patient/${
      props.finding.patient.id
    })${caregiverTaskString} ${t(
      "collaborativeCare:findings.findings.timeElapsedSinceInvitation.reminderTask.body"
    )} ${scaleNames}.`,
    dueAt: new Date(),
    patientId: props.finding.patient.id,
    assignedToId: currentProviderId,
  };

  const administerTask = {
    title: viewMeasuresTaskTitle,
    body: `${t("collaborativeCare:findings.administerNowTask.body")} [${
      props.finding.patient.name
    }](/app/cocm/patient/${props.finding.patient.id})${caregiverTaskString}`,
    dueAt: new Date(),
    patientId: props.finding.patient.id,
    assignedToId: currentProviderId,
  };

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:findings.findings.timeElapsedSinceInvitation.title")} />
      <CardContent>
        <Stack direction="row" spacing={0.5} alignItems="center" useFlexGap>
          <PatientHeaderStatus
            patientId={props.finding.patient.id}
            patientName={props.finding.patient.name}
          />
          <span>
            {patientLinkContent}
            {t("collaborativeCare:findings.findings.timeElapsedSinceInvitation.body")} {scaleNames}
          </span>
        </Stack>
      </CardContent>
      <CardActions>
        <StartTaskNowAction
          findingId={props.finding.id}
          {...administerTask}
          buttonText={t("collaborativeCare:findings.findings.timeElapsedSinceInvitation.viewMeasures")}
          redirectOnSuccess={`/app/cocm/patient/${props.finding.patient.id}`}
        />
        <SendNotificationAndSnoozeAction
          findingId={props.finding.id}
          invitationId={props.finding.invitation.id}
        />
        <CreateTaskLaterAction findingId={props.finding.id} {...reminderTask} />
        <DismissAction findingId={props.finding.id} />
      </CardActions>
    </Card>
  );
}
