import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ListDetailsCutout } from "Shared/ListDetailsCutout";
import { useParams, Routes, Route } from "react-router-dom";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { AlgorithmTable } from "./AlgorithmTable";
import { AlgorithmDetail } from "./AlgorithmDetail";
import { AlgorithmSummary } from "./AlgorithmSummary";
import { AlgorithmFilters, useAlgorithmFilters } from "./AlgorithmFilters";

export function Algorithms(): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);
  const filters = useAlgorithmFilters();

  return (
    <Page browserTitle={t("decisionSupport:pageTitle")}>
      <ListDetailsCutout
        filters={<AlgorithmFilters filters={filters} />}
        list={<AlgorithmTable filters={filters} />}
        details={
          <Routes>
            <Route element={<AlgorithmDetailRoute />} path="details/:entityDecisionAlgorithmId" />
            <Route element={<AlgorithmSummary />} path="/" />
          </Routes>
        }
      />
    </Page>
  );
}

function AlgorithmDetailRoute(): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);
  const params = useParams<{ entityDecisionAlgorithmId?: string }>();

  return Id.fromNullableString<"EntityDecisionAlgorithm">(params.entityDecisionAlgorithmId).caseOf({
    Ok: (id) => <AlgorithmDetail entityDecisionAlgorithmId={id} />,
    Err: (_err) => <ErrorMessage message={t("decisionSupport:inbox.noSuchTriggeredWorkflow")} />,
  });
}

export default Algorithms;
