import { Button, ButtonProps } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useDiscardTimerMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { TimeEntryLogId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { omit } from "ramda";

import React, { ReactElement } from "react";

type DeleteTimeEntryButtonProps = ButtonProps & {
  timeEntryLogId: TimeEntryLogId;
  buttonLabel: string;
  onSuccess?: () => void;
};

export function DeleteTimeEntryButton(props: DeleteTimeEntryButtonProps): ReactElement | null {
  // distinct from Discard Time Entry because you pass in a time entry ID to this button

  const [discardTimer, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTimeEntryLog,
    useDiscardTimerMutation({
      refetchQueries: refetchQueries("tasks"),
      variables: {
        input: {
          timeEntryLogId: props.timeEntryLogId,
        },
      },
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const buttonProps = omit(["timeEntryLogId", "buttonLabel", "onSuccess"], props);
  return (
    <Button onClick={() => discardTimer()} {...buttonProps}>
      {props.buttonLabel}
    </Button>
  );
}
