import Page from "Layout/Page";
import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControl,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { useOutcomesMetricsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { OutcomesMetricCard } from "./OutcomesMetricCard";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import { useTranslation } from "react-i18next";
import { allowedEntityTypes } from "Outcomes";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyEntityTreeNodeParameter,
  useStickyMonthParameter,
} from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_NAMES, STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import Paginator, { initPage } from "Shared/Paginator";
import { entityTreeNodeToParams } from "Entities/EntityPath";
import {
  CurrentEntityQueryFilterExplanation,
  EntityQueryConfigCheckboxes,
  useStickyEntityQueryParams,
} from "Entities/EntityQuery";
import { FilterButton } from "Lib/FilterPanel";
import { SectionHelpText } from "./CreateTimeBasedMetricForm";
import { useDebounce } from "Lib/Hooks";

export default function OutcomesMetricsDashboard() {
  const { t } = useTranslation(["outcomes"]);
  const [now, _] = React.useState(new Date());
  const oneYearAgo = startOfMonth(sub(now, { months: 12 }));
  const endOfThisMonth = endOfMonth(now);
  const [pagination, setPagination] = React.useState(initPage(12));

  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    oneYearAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    endOfThisMonth,
    "end",
    true
  );
  const [entityRoot] = useCurrentRootNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.OUTCOMES,
    entityRoot,
    true
  );

  const [advancedOpen, setAdvancedOpen] = useState(false);

  const [searchString, setSearchString] = useState<string | null>(null);

  const entityQueryTypeParams = useStickyEntityQueryParams(STICKY_PARAMETER_FILTER_SETS.OUTCOMES);
  const { search } = useLocation();

  const navigate = useNavigate();
  const debouncedSearchString = useDebounce(searchString, 500);

  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesMetricsQuery({
      variables: {
        availableForNode: null,
        smartSearch: debouncedSearchString,
        entityQuery: {
          entityTreeNodeParams,
          exact: entityQueryTypeParams.exact,
          lessSpecific: entityQueryTypeParams.lessSpecific,
          moreSpecific: entityQueryTypeParams.moreSpecific,
        },
        ...pagination,
      },
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.outcomesMetrics?.pageInfo || null,
    _: () => null,
  });

  const handleChangeStartDate = (newValue: Date) => {
    setStartDate(startOfMonth(newValue));
  };
  const handleChangeEndDate = (newValue: Date) => {
    setEndDate(endOfMonth(newValue));
  };

  const metricItems = remoteData.caseOf({
    Success: (metrics) => {
      const noMetrics = (
        <Grid item lg={1} xs={2}>
          <Card>
            <CardHeader title={t("outcomes:noMetrics")} />
            <CardContent>{t("outcomes:explainMetrics")}</CardContent>
          </Card>
        </Grid>
      );
      if (metrics.outcomesMetrics) {
        if (metrics.outcomesMetrics.nodes.length === 0) {
          return noMetrics;
        }
        return metrics.outcomesMetrics.nodes.map((metric) => {
          return (
            <Grid key={metric.id.toString()} item lg={1} xs={2}>
              <OutcomesMetricCard
                metric={metric}
                configuration={metric.configuration}
                // For now, on the dashboard, we show the configuration of the metric,
                // not whatever is selected at the top. This reduces confusion because it shows
                // you the data for whatever the entity being measured is.
                // entityTreeNode={entityTreeNodeParams}
                entityTreeNode={entityTreeNodeToParams(metric.entityTreeNode)}
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
          );
        });
      } else {
        return noMetrics;
      }
    },
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
  });

  const onCreateMetricClickHandler = () => {
    navigate(`create${search}`);
  };

  return (
    <Page browserTitle={t("outcomes:dashboard.title")}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction={"row"}>
            <Stack direction="row" spacing={1} alignItems="center" rowGap={1} flexWrap={"wrap"} useFlexGap>
              <Box minWidth="7em">
                <DatePicker
                  label={t("outcomes:dashboard.startMonth")}
                  views={["month", "year"]}
                  openTo={"month"}
                  value={startDate}
                  format={"MMM yyyy"}
                  defaultValue={oneYearAgo}
                  onChange={(newValue: Date) => handleChangeStartDate(newValue)}
                />
              </Box>
              <Box minWidth="7em">
                <DatePicker
                  label={t("outcomes:dashboard.endMonth")}
                  views={["month", "year"]}
                  openTo={"month"}
                  value={endDate}
                  format={"MMM yyyy"}
                  defaultValue={endOfThisMonth}
                  onChange={(newValue: Date) => handleChangeEndDate(newValue)}
                />
              </Box>
              <Box minWidth="18em">
                <EntityTreeNodeSelect
                  setValue={setEntityTreeNodeParams}
                  entityTypes={allowedEntityTypes}
                  value={entityTreeNodeParams}
                  defaultValue={entityRoot}
                />
              </Box>
              <TextField
                value={searchString ?? ""}
                label="Search"
                onChange={(event) => setSearchString(event.target.value === "" ? null : event.target.value)}
              />
              <FilterButton
                activeFilterCount={entityQueryTypeParams.filterIsDefault ? 0 : 1}
                open={advancedOpen}
                toggleOpen={setAdvancedOpen}
              />
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  setStartDate(oneYearAgo);
                  setEndDate(endOfThisMonth);
                  setEntityTreeNodeParams(entityRoot);
                }}
              />
            </Stack>
            <Box flexGrow={1} />
            <Button variant="contained" color="secondary" onClick={onCreateMetricClickHandler}>
              {t("outcomes:dashboard.createMetric")}
            </Button>
          </Stack>
        </Grid>
        <Grid item lg={2} xs={2}>
          <Collapse in={advancedOpen}>
            <Stack direction="row" spacing={1}>
              <FormControl>
                <SectionHelpText text={t("outcomes:dashboard.queryType")} />
                <EntityQueryConfigCheckboxes
                  params={entityQueryTypeParams}
                  currentEntityTreeParams={entityTreeNodeParams}
                />
              </FormControl>
            </Stack>
          </Collapse>
        </Grid>
        {metricItems}
      </Grid>
      <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
      <CurrentEntityQueryFilterExplanation
        exact={entityQueryTypeParams.exact}
        moreSpecific={entityQueryTypeParams.moreSpecific}
        lessSpecific={entityQueryTypeParams.lessSpecific}
      />
    </Page>
  );
}
