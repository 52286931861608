import React, { ReactElement } from "react";
import { Button } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import { InstituteId } from "Lib/Ids";
import { useInstituteSsoSignonUrlLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";

type LaunchButtonProps = {
  disabled: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

function LaunchButton({ disabled, onClick }: LaunchButtonProps): ReactElement {
  return (
    <Button aria-label="delete" disabled={disabled} onClick={onClick} color="primary">
      <LaunchIcon />
    </Button>
  );
}

type LaunchInstituteButtonProps = {
  instituteId: InstituteId;
};

function LaunchInstituteButton(props: LaunchInstituteButtonProps): ReactElement {
  const [loadUrl, result] = useInstituteSsoSignonUrlLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      instituteId: props.instituteId,
    },
    onCompleted: (data) => {
      window.open(`${data.institute?.ssoSignonUrl}&internal_redirect=1`, "_blank");
    },
  });

  if (result.loading) {
    return <LaunchButton disabled={true} />;
  }

  return <LaunchButton onClick={() => loadUrl()} disabled={false} />;
}

export default LaunchInstituteButton;
