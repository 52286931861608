import { Ok, Result } from "seidr";
import { toCareEpisodeDetail, CareEpisodeDetail } from "Shared/CareEpisode";
import { parseFeedbackReportContext, FeedbackReportContext } from "./FeedbackReportContext";
import { SumType } from "sums-up";
import * as NEL from "Lib/NonEmptyList";
import { oks, parseIsoStringOrDate } from "Lib/Utils";
import { InvitationWithUserSummary } from "Shared/Scale/Invitation";
import { parseProviderSummary, ProviderSummary } from "Shared/Provider";
import { FeedbackReportCareEpisodeQueryQuery, Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientConditionDetails } from "./Demographics/PatientConditionModal";
import { Dig, PickTypename } from "type-utils";

type CareTeam = Array<ProviderSummary>;
type PatientConditions = ReadonlyArray<PatientConditionDetails>;
export type PatientInfo = PickTypename<
  Patient,
  | "name"
  | "id"
  | "phoneNumber"
  | "email"
  | "primaryLanguage"
  | "gender"
  | "genderIdentity"
  | "genderIdentityFhir"
  | "firstName"
  | "lastName"
  | "legalFirstName"
  | "legalLastName"
  | "preferredFirstName"
  | "preferredLastName"
  | "preferredPronouns"
  | "dob"
>;

// The top level data structure for the FeedbackReport. Includes a CareEpisode,
// CareUnit, CareTeam, and the full report context

class FeedbackReportCareEpisode extends SumType<{
  WithAdministrations: [PatientInfo, CareEpisodeDetail, CareTeam, PatientConditions, FeedbackReportContext];
  NoAdministrations: [PatientInfo, CareEpisodeDetail, CareTeam, PatientConditions];
}> {
  public static WithAdministrations = (
    patient: PatientInfo,
    careEpisode: CareEpisodeDetail,
    careTeam: CareTeam,
    patientConditions: PatientConditions,
    context: FeedbackReportContext
  ) =>
    new FeedbackReportCareEpisode(
      "WithAdministrations",
      patient,
      careEpisode,
      careTeam,
      patientConditions,
      context
    );
  public static NoAdministrations = (
    patient: PatientInfo,
    careEpisode: CareEpisodeDetail,
    careTeam: CareTeam,
    patientConditions: PatientConditions
  ) => new FeedbackReportCareEpisode("NoAdministrations", patient, careEpisode, careTeam, patientConditions);
}

// TODO: This function is now only a passthrough and can be safely deleted.
function parseInvitations(
  invitations: Dig<
    FeedbackReportCareEpisodeQueryQuery,
    ["assessmentCareEpisodeScaleSummary", "activeInvitations"]
  >
): ReadonlyArray<InvitationWithUserSummary> {
  return invitations.map((rawInvitation) => {
    return {
      id: rawInvitation.id,
      status: rawInvitation.status,
      phase: rawInvitation.phase,
      user: rawInvitation.user,
    };
  });
}

function parseFeedbackReportCareEpisode(
  query: FeedbackReportCareEpisodeQueryQuery
): Result<Error, FeedbackReportCareEpisode> {
  return Result.fromNullable(
    new Error("CareEpisodeScaleSummary Not Found"),
    query.assessmentCareEpisodeScaleSummary
  ).flatMap((summary) => {
    const patient = summary.careEpisode.patient;
    const careEpisode = toCareEpisodeDetail(summary.careEpisode);
    const activeInvitations = parseInvitations(summary.activeInvitations);

    const careTeam = oks(
      summary.careEpisode.careEpisodeProviders.map((cep) => parseProviderSummary(cep.provider))
    );

    return Ok(
      NEL.fromArray(summary.administrationDates).caseOf({
        Just: (administrationDates) => {
          return FeedbackReportCareEpisode.WithAdministrations(
            patient,
            careEpisode,
            careTeam,
            summary.careEpisode.patientConditions,
            parseFeedbackReportContext(
              patient.id,
              careEpisode,
              administrationDates.map(parseIsoStringOrDate),
              activeInvitations
            )
          );
        },
        Nothing: () =>
          FeedbackReportCareEpisode.NoAdministrations(
            patient,
            careEpisode,
            careTeam,
            summary.careEpisode.patientConditions
          ),
      })
    );
  });
}

export { FeedbackReportCareEpisode, parseFeedbackReportCareEpisode };
export default FeedbackReportCareEpisode;
