import React, { ReactElement } from "react";
import Page from "../Layout/Page";
import { Typography, Container, Box, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { MirahIconTwoTone } from "./MirahIcon";
import { LinkButton } from "MDS/Link";

const StyledPage = styled(Page)(({ theme }) => ({
  minHeight: "100%",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  paddingTop: 80,
  paddingBottom: 80,
}));

const StyledIcon = styled(MirahIconTwoTone)(({ theme }) => ({
  maxWidth: "50%",
  width: 150,
  maxHeight: 300,
  height: "auto",
  marginBottom: theme.spacing(7),
}));

type SimpleLandingPageProps = {
  title: string;
  subtitle?: string;
};

function SimpleLandingPage(props: SimpleLandingPageProps): ReactElement {
  const mobileDevice = useMediaQuery(useTheme().breakpoints.down("md"));

  const subtitle = props.subtitle ? (
    <Typography align="center" variant="subtitle2" color="textSecondary">
      {props.subtitle}
    </Typography>
  ) : null;

  return (
    <StyledPage browserTitle={props.title}>
      <Container maxWidth="lg">
        <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
          <StyledIcon />
        </Box>
        <Typography align="center" variant={mobileDevice ? "h4" : "h1"} color="textPrimary">
          {props.title}
        </Typography>
        {subtitle}
      </Container>
    </StyledPage>
  );
}

type SimpleLandingWithLinkPageProps = {
  title: string;
  subtitle?: string;
  href: string;
  cta: string;
};

export function SimpleLandingPageWithLink(props: SimpleLandingWithLinkPageProps): ReactElement {
  const mobileDevice = useMediaQuery(useTheme().breakpoints.down("md"));

  const subtitle = props.subtitle ? (
    <Typography align="center" variant="subtitle2" color="textSecondary">
      {props.subtitle}
    </Typography>
  ) : null;

  return (
    <StyledPage browserTitle={props.title}>
      <Container maxWidth="lg">
        <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
          <StyledIcon />
        </Box>
        <Typography align="center" variant={mobileDevice ? "h4" : "h1"} color="textPrimary">
          {props.title}
        </Typography>
        {subtitle}
        <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
          <LinkButton color="secondary" to={props.href} variant="outlined">
            {props.cta}
          </LinkButton>
        </Box>
      </Container>
    </StyledPage>
  );
}

export default SimpleLandingPage;
