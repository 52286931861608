import { useNavigate } from "react-router-dom";
import React from "react";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import {
  DataFetchRequestStatus,
  useCheckFirstTimePatientCreateQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import SimpleLandingPage from "Shared/SimpleLandingPage";
import { useTranslation } from "react-i18next";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import { EntityId } from "Lib/Ids";
import { MBC_REDESIGN_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";

const POLL_INTERVAL = 1000; // Check every second for updates

function launchUrl(patientId: EntityId, cocmEnabled: boolean, mbcRedesignEnabled: boolean) {
  if (cocmEnabled) {
    return `/app/cocm/patient/${patientId}`;
  } else if (mbcRedesignEnabled) {
    return `/app/patients/${patientId}`;
  } else {
    return `/provider/patients/${patientId}`;
  }
}

export default function RemoteCreatePatient() {
  const [pendingPatientFetchId] = useQueryStringIdParameter<"DataFetchRequest">("pendingPatientFetchId");
  const navigate = useNavigate();
  const { t } = useTranslation(["external"]);
  const cocmEnabled = useWithFeatureEnabled("enableCollaborativeCare");
  const mbcRedesignEnabled = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);

  if (!pendingPatientFetchId) {
    return <SimpleLandingPage title={t("external:noId.title")} subtitle={t("external:noId.subtitle")} />;
  }

  const { remoteData } = apolloQueryHookWrapper(
    useCheckFirstTimePatientCreateQuery({
      variables: { dataFetchId: pendingPatientFetchId },
      pollInterval: POLL_INTERVAL,
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      const result = data.integrationsDataFetchRequest;
      switch (result?.status) {
        case DataFetchRequestStatus.COMPLETE:
          if (result.dataSourceRecord?.target?.id) {
            navigate(launchUrl(result.dataSourceRecord.target.id, cocmEnabled, mbcRedesignEnabled));
            return (
              <SimpleLandingPage
                title={t("external:remoteCreatePatient.error.title")}
                subtitle={t("external:remoteCreatePatient.error.subtitle")}
              />
            );
          } else {
            return (
              <SimpleLandingPage
                title={t("external:remoteCreatePatient.error.title")}
                subtitle={t("external:remoteCreatePatient.error.subtitle")}
              />
            );
          }
        case DataFetchRequestStatus.BLOCKED:
        case DataFetchRequestStatus.PENDING:
        case DataFetchRequestStatus.IN_PROGRESS:
          return (
            <SimpleLandingPage
              title={t("external:remoteCreatePatient.loading.title")}
              subtitle={t("external:remoteCreatePatient.loading.subtitle")}
            />
          );
        case DataFetchRequestStatus.FETCH_ERROR:
        case DataFetchRequestStatus.SYSTEM_ERROR:
        case DataFetchRequestStatus.DEPENDENCY_ERROR:
        case null:
        default:
          return (
            <SimpleLandingPage
              title={t("external:remoteCreatePatient.error.title")}
              subtitle={t("external:remoteCreatePatient.error.subtitle")}
            />
          );
      }
    },
    Failure: (_) => {
      return <SimpleLandingPage title={t("external:error.title")} subtitle={t("external:error.subtitle")} />;
    },
    _: () => {
      return (
        <SimpleLandingPage title={t("external:loading.title")} subtitle={t("external:loading.subtitle")} />
      );
    },
  });
}
