import {
  ClinicalAlertHistoryFragmentFragment,
  OverviewAlertsQueryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Result } from "seidr";
import {
  CareEpisodeClinicalAlertHistory,
  toCareEpisodeClinicalAlertHistoryDetails,
} from "../../../Shared/Scale/CareEpisodeClinicalAlert";
import { ParticipantByUserIdMap } from "../../FeedbackReportContext";

function parseAlerts(
  raw: ReadonlyArray<ClinicalAlertHistoryFragmentFragment>,
  participantsByUserId: ParticipantByUserIdMap
): Array<CareEpisodeClinicalAlertHistory> {
  return raw.map((r) => toCareEpisodeClinicalAlertHistoryDetails(r, participantsByUserId));
}

function parseOverviewAlerts(
  raw: OverviewAlertsQueryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ReadonlyArray<CareEpisodeClinicalAlertHistory>> {
  return Result.fromNullable(
    new Error("no assessmentCareEpisodeScaleSummary found"),
    raw.assessmentCareEpisodeScaleSummary
  ).map((summary) => parseAlerts(summary.alerts, participantsByUserId));
}

export { parseOverviewAlerts };
