import React, { ReactElement } from "react";
import { Link as RouterLink, LinkProps } from "react-router-dom";
import { Button, ButtonTypeMap, Link as MUILink, LinkTypeMap, IconButton } from "@mui/material";

type LinkWrapperProps<T extends { props: unknown }> = Omit<T["props"], "component" | "href"> & LinkProps;

export function ButtonLink(props: LinkWrapperProps<ButtonTypeMap>): ReactElement {
  return <Button component={RouterLink} {...props} />;
}

export function Link(props: LinkWrapperProps<LinkTypeMap>): ReactElement {
  return <MUILink component={RouterLink} {...props} />;
}

// We pretty much inferred the type from poking around the Button implementation.
export type LinkButtonProps = Omit<ButtonTypeMap["props"], "component" | "href"> & { to: string };

// Provides a simple button that can be used to take the user to another location in the app.
export function LinkButton(props: LinkButtonProps): ReactElement {
  return (
    <Button component={RouterLink} {...props}>
      {props.children}
    </Button>
  );
}

// Provides a simple icon button that can be used to take the user to another location in the app.
export function LinkIconButton(props: LinkButtonProps): ReactElement {
  return (
    <IconButton component={RouterLink} {...props}>
      {props.children}
    </IconButton>
  );
}

export default Link;
