import React, { ReactElement } from "react";
import { PatientDetails } from "./PatientDetails";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { EditDemographicsButton } from "../../Patients/PatientDetails/EditDemographicsButton";
import { LabelAndInfo, LabelAndRichInfo } from "Shared/InfoSidebarCard";
import { UpdateConsentDateLink } from "./UpdateConsentDate";
import { MaybeEditEnrollmentCareTeamButton } from "./EditEnrollmentCareTeam";
import { NotInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";
import {
  IntegrationStatusBadgeIfIntegrationEnabled,
  toImportHistoryId,
} from "Integrations/IntegrationStatusBadge";
import { DataSourceResourceType } from "GeneratedGraphQL/SchemaAndOperations";

type PatientInfoCardProps = {
  patient: PatientDetails;
};
export default function PatientInfoCard(props: PatientInfoCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "report", "enums", "common", "patients"]);

  const enrollment = props.patient.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment;

  let enrolledDate = t("collaborativeCare:enrollment.unenrolled");
  if (enrollment) {
    enrolledDate = t("common:date.tiny", {
      date: enrollment.enrolledAt,
    });
  }

  let consentDate = <Typography>{t("collaborativeCare:enrollment.unenrolled")}</Typography>;
  if (enrollment) {
    consentDate = (
      <Stack direction="row" spacing={2}>
        <Typography>
          {t("common:date.tinyWithTime", {
            date: enrollment.consentDate,
          })}
        </Typography>
        <UpdateConsentDateLink enrollment={enrollment} text="edit" />
      </Stack>
    );
  }

  const cardAction = (
    <NotInEmbeddedPatientOrEncounter>
      <Box marginRight="0.3em">
        <EditDemographicsButton patient={props.patient} />
      </Box>
    </NotInEmbeddedPatientOrEncounter>
  );

  const careTeamInfo = (
    <>
      <LabelAndRichInfo
        label={t("patients:details.panel")}
        data={
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>{enrollment?.panel?.name || ""}</Typography>
            <MaybeEditEnrollmentCareTeamButton enrollment={enrollment} />
          </Stack>
        }
      />
      <LabelAndInfo
        label={t("patients:details.primaryCareProvider")}
        data={enrollment?.primaryCareProviderTeamMember?.provider.name || ""}
      />
      <LabelAndInfo
        label={t("patients:details.careManager")}
        data={enrollment?.careManagerTeamMember?.provider.name || ""}
      />
    </>
  );

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:patientDetails.cards.patientInfo")} action={cardAction} />
      <CardContent>
        <IntegrationStatusBadgeIfIntegrationEnabled
          importHistoryId={toImportHistoryId(props.patient.id)}
          resourceType={DataSourceResourceType.PATIENT}
        />
        <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
          <NotInEmbeddedPatientOrEncounter>
            <LabelAndInfo label={t("patients:user.mrn")} data={props.patient.mrn} />
            <LabelAndInfo label={t("patients:user.email")} data={props.patient.email} />
            <LabelAndInfo label={t("patients:user.phoneNumber")} data={props.patient.phoneNumber} />
          </NotInEmbeddedPatientOrEncounter>
          {careTeamInfo}
          <LabelAndRichInfo label={t("collaborativeCare:enrollment.consentDate")} data={consentDate} />
          <LabelAndInfo label={t("collaborativeCare:enrollment.enrollmentDate")} data={enrolledDate} />
        </Grid>
      </CardContent>
    </Card>
  );
}
