import {
  CareEpisodeProviderRelationship,
  ProviderFilter,
  ProviderSelectQueryVariables,
  useProviderSelectLazyQuery,
  useProviderSelectSingleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ProviderId } from "Lib/Ids";
import { assertNonNull, oks } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import * as Id from "Lib/Id";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";
import { ParameterSerializer } from "Shared/QueryStringParameter";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { TFunction } from "i18next";

type ProviderSelectProps = {
  label?: string;
  value: ProviderFilter | null;
  setValue: (newValue: ProviderFilter | null) => void;
  defaultRelationships?: ReadonlyArray<CareEpisodeProviderRelationship>;
  showMe?: boolean | undefined;
  defaultValue?: ProviderFilter | null;
};

/**
 * This is the format required by the autoselect to render the name string.
 */
type SelectOptionType = { id: ProviderId | "me" | "all"; name: string };

const defaultValue: ProviderFilter = {
  me: true,
};

// Serializes and deserializes the provider filter from a string, to be used in query string.
export function providerFilterSerializer(
  defaultValue: ProviderFilter | null
): ParameterSerializer<ProviderFilter | null> {
  return {
    serialize: (x: ProviderFilter | null) => {
      if (x?.allProviders) {
        return "all";
      }

      if (x?.me) {
        return "me";
      }

      if (x?.providerIds) {
        return x.providerIds.join(",");
      }

      if (x === defaultValue) {
        return null;
      }

      return "";
    },
    deserialize: (str: string | null) => {
      // explicitly serialize empty string as null
      if (str === "") {
        return null;
      }

      if (str === "all") {
        return {
          allProviders: true,
        };
      }

      if (str === "me") {
        return {
          me: true,
        };
      }

      if (!str) {
        return defaultValue;
      }

      const uuids = oks(
        str.split(",").map((idString) => {
          return Id.fromNullableString<"Provider">(idString);
        })
      );

      if (uuids.length > 0) {
        return {
          providerIds: uuids,
        };
      }

      return defaultValue;
    },
  };
}

// We need to translate the union type into the graphql input type
function inputToSelect(value: SelectOptionType | null): ProviderFilter | null {
  if (!value) {
    return null;
  } else if (value.id === "me") {
    return { me: true };
  } else if (value.id === "all") {
    return { allProviders: true };
  } else {
    return { providerIds: [value.id] };
  }
}

// Note that as long as we have the ids right here, the ignored text will be substituted in the component.
function selectToValue(
  value: ProviderFilter | null,
  showMe: boolean,
  t: TFunction<["common"]>
): SelectOptionType | null {
  if (!value || !showMe) {
    return null;
  } else if (value.me !== undefined) {
    return { id: "me", name: t("common:providers.me") };
  } else if (value.allProviders !== undefined) {
    return { id: "all", name: t("common:providers.all") };
  } else if (value.providerIds[0]) {
    return { id: value.providerIds[0], name: "Loading" };
  } else {
    return null;
  }
}

export default function ProviderSelect(props: ProviderSelectProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { setValue } = props;
  const queryVars: Omit<ProviderSelectQueryVariables, "search"> = {
    first: 30,
    defaultRelationship: props.defaultRelationships || null,
  };

  const showMe = props.showMe === undefined ? true : props.showMe;

  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useProviderSelectSingleQuery({
      variables: {
        id: assertNonNull(props.value?.providerIds?.[0]), // Apollo will only call if value is present
      },
      skip: !props.value?.providerIds,
    })
  );

  const value = props.value
    ? currentValueRemoteData.caseOf({
        Success: (result) => {
          if (result.provider) {
            return result.provider;
          } else if (props.value) {
            return selectToValue(props.value, showMe, t);
          }
          return null;
        },
        _: () => {
          return selectToValue(props.value, showMe, t);
        },
      })
    : null;

  const highlight = typeof props.defaultValue !== "undefined" && props.defaultValue !== value;

  const fixed: ReadonlyArray<SelectOptionType> = showMe
    ? [
        { name: t("common:providers.me"), id: "me" },
        { name: t("common:providers.all"), id: "all" },
      ]
    : [{ name: t("common:providers.all"), id: "all" }];

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => setValue(inputToSelect(value))}
      error={false}
      required={false}
      helperText=""
      value={value}
      queryVariables={queryVars}
      query={useProviderSelectLazyQuery}
      fixedOptions={fixed}
      unwrapResponse={(response) => response.providers?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={props.label || t("common:providers.title")}
      autocompleteProps={{
        noOptionsText: t("common:providers.noMatching"),
        getOptionLabel: (option) => option.name,
        sx: { width: "20em" },
      }}
      highlight={highlight}
    />
  );
}

export { defaultValue };
