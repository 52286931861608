import { EntitySummary, EntityTreeNodeDetails } from "Entities/EntityPath";
import { EntityTreeNodeParams, EntityType } from "GeneratedGraphQL/SchemaAndOperations";
import { EntityId, InstituteId } from "Lib/Ids";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Maybe } from "seidr";
import { usePiiLevel } from "./PiiLevelContext";
import CurrentInstituteContext from "./CurrentInstituteContext";
import { unsafeFromUuid } from "Lib/Id";
import { useProviderUserHasAnyRole } from "Shared/WithPermission";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";

const ROOT_UUID = "00000000-0000-0000-0000-000000000001";

// This is a special entity that doesn't really exist but just represents 'all data'
const ROOT_ENTITY: EntitySummary = {
  entityType: EntityType.ROOT,
  // For expedience don't translate this for now as we only need to support english
  name: "All Data",
  shortname: "All Data",
  id: unsafeFromUuid(ROOT_UUID),
};

export const CurrentInstituteIdContext: React.Context<Maybe<{ id: InstituteId; name: string }>> =
  React.createContext(Maybe.fromNullable<{ id: InstituteId; name: string }>(null));

export function useCurrentInstituteId(): InstituteId {
  const maybeInstituteId = useContext(CurrentInstituteIdContext);
  const instituteId = maybeInstituteId.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => institute.id,
  });
  return instituteId;
}

/**
 * Get the current institute id
 * @returns the current institute id as an entity id for use in e.g. path selection
 * @throws an error if you somehow have not even bootstrapped the application
 */
export function useCurrentInstituteEntity(): EntitySummary {
  const { t } = useTranslation(["settings"]);
  const maybeInstituteId = useContext(CurrentInstituteIdContext);
  const isAnonymous = usePiiLevel() === "full_anon";
  const instituteId = maybeInstituteId.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => {
      return {
        id: institute.id as unknown as EntityId,
        name: isAnonymous ? t("settings:piiLevel.anonInstitute") : institute.name,
        shortname: isAnonymous ? t("settings:piiLevel.anonInstitute") : institute.name,
        entityType: EntityType.INSTITUTE,
      };
    },
  });
  return instituteId;
}

export function useCurrentProviderEntity(): EntitySummary | null {
  const user = useContext(AuthenticatedProviderUserContext);

  if (!user) {
    return null;
  }

  return user.providerId.caseOf({
    Nothing: () => {
      return null;
    },
    Just: (providerId) => {
      return {
        id: providerId as unknown as EntityId,
        name: user.name,
        shortname: user.name,
        entityType: EntityType.PROVIDER,
      };
    },
  });
}

export function useCurrentRootNode(): [EntityTreeNodeParams, EntityTreeNodeDetails] {
  const maybeInstitute = useContext(CurrentInstituteContext);
  const instituteEntity = useCurrentInstituteEntity();

  return maybeInstitute.caseOf({
    Nothing: () => {
      throw new Error("No current institute!");
    },
    Just: (institute) => {
      if (institute.supportsInstituteGroups) {
        return [{ root: true }, { entity: ROOT_ENTITY, segments: [], path: "/", minAge: null, maxAge: null }];
      } else {
        return [
          { path: `institute/${institute.id.toString()}` },
          {
            entity: instituteEntity,
            segments: [
              {
                entityType: EntityType.INSTITUTE,
                id: instituteEntity.id,
                name: instituteEntity.name,
                shortname: instituteEntity.shortname,
                path: `institute/${instituteEntity.id}`,
                rootPath: `institute/${instituteEntity.id}`,
              },
            ],
            path: "institute/" + institute.id,
            minAge: null,
            maxAge: null,
          },
        ];
      }
    },
  });
}

/**
 * We sometimes want to start at a point which is not the institute level. For example, if you are a care manager
 * you want to start at looking at your own data. This will make your 'default' to be yourself rather than the
 * whole institute.
 * Superusers continue to start at the top level.
 */
export function useCurrentDefaultNode(): [EntityTreeNodeParams, EntityTreeNodeDetails] {
  const isSuperuser = useProviderUserHasAnyRole(["Collaborative Care Superuser", "superuser"]);
  const rootNode = useCurrentRootNode();
  const currentProviderEntity = useCurrentProviderEntity();

  if (!isSuperuser && currentProviderEntity) {
    const instituteId = rootNode[1].entity.id;
    const path = `institute/${instituteId}/provider/${currentProviderEntity.id}`;
    return [
      { path },
      {
        entity: currentProviderEntity,
        segments: [
          ...rootNode[1].segments,
          {
            entityType: EntityType.PROVIDER,
            id: currentProviderEntity.id,
            name: currentProviderEntity.name,
            shortname: currentProviderEntity.shortname,
            path: path,
            rootPath: `institute/${instituteId}`,
          },
        ],
        path,
        minAge: null,
        maxAge: null,
      },
    ];
  } else {
    return rootNode;
  }
}

export default CurrentInstituteIdContext;
