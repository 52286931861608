import { SvgIcon, SvgIconTypeMap, useTheme } from "@mui/material";
import React from "react";
import { Dig } from "type-utils";

type IconProps = Pick<Dig<SvgIconTypeMap, ["props"]>, "sx" | "fontSize" | "classes">;

function BlankIcon(props: IconProps) {
  return <SvgIcon {...props} data-testid="blank-icon" />;
}

function TrendStableIcon(props: IconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} data-testid="trend-stable">
      <rect width="20" height="6" rx="3" x="2" y="9" fill={theme.palette.report.trend.stable.primary} />
    </SvgIcon>
  );
}

function TrendImprovingIcon(props: IconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} data-testid="trend-improving">
      <circle cx="12" cy="12" r="10" fill={theme.palette.report.trend.improving.primary} />
      <path
        d="M12.1904 15.5715C12.7217 15.5715 13.1904 15.134 13.1904 14.6028V6.60278C13.1904 6.04028 12.7217 5.57153 12.1904 5.57153C11.6279 5.57153 11.1904 6.04028 11.1904 6.60278V14.5715C11.1904 15.134 11.6279 15.5715 12.1904 15.5715ZM12.1904 17.0715C11.4717 17.0715 10.9404 17.634 10.9404 18.3215C10.9404 19.0403 11.4717 19.5715 12.1904 19.5715C12.8779 19.5715 13.4404 19.009 13.4404 18.3215C13.4404 17.6653 12.8779 17.0715 12.1904 17.0715Z"
        fill={theme.palette.report.trend.improving.offset}
      />
    </SvgIcon>
  );
}

function TrendDecliningIcon(props: IconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} data-testid="trend-declining">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.581 4.22248C14.111 1.25917 9.88422 1.25917 8.41428 4.22249L2.42176 16.303C1.10285 18.9619 3.03712 22.0805 6.00512 22.0805H17.9902C20.9582 22.0805 22.8924 18.9619 21.5735 16.303L15.581 4.22248ZM12.8178 14.2246C12.8178 14.6688 12.429 15.0346 11.9884 15.0346C11.5218 15.0346 11.1589 14.6688 11.1589 14.1985V7.53615C11.1589 7.06587 11.5218 6.67396 11.9884 6.67396C12.429 6.67396 12.8178 7.06587 12.8178 7.53615V14.2246ZM10.9516 17.3338C10.9516 16.759 11.3922 16.2887 11.9884 16.2887C12.5586 16.2887 13.0252 16.7851 13.0252 17.3338C13.0252 17.9085 12.5586 18.3788 11.9884 18.3788C11.3922 18.3788 10.9516 17.9347 10.9516 17.3338Z"
        fill={theme.palette.report.trend.declining.primary}
      />
    </SvgIcon>
  );
}

function AcuteChangeImprovingIcon(props: IconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} data-testid="acute-change-improving">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7087 1.30684C11.8891 0.897721 12.3406 0.897721 12.521 1.30684L13.6957 3.96916C13.8761 4.37823 13.6504 4.88952 13.2895 4.88952H10.9401C10.5793 4.88952 10.3536 4.37823 10.534 3.96916L11.7087 1.30684ZM19.3035 5.29981C19.3973 4.94899 19.0782 4.62794 18.7293 4.72192L17.5948 5.02788L16.4603 5.33378C16.1113 5.42776 15.9948 5.86634 16.25 6.12313L17.0804 6.95895L17.9113 7.79471C18.1664 8.05155 18.602 7.93402 18.6958 7.5832L19.3035 5.29981ZM8.04615 7.88006C9.25735 6.66137 11.0217 6.05967 12.7212 6.24312C17.42 6.75032 19.5014 12.6926 16.1654 16.0497C14.9546 17.2679 13.2031 17.8921 11.4996 17.71C6.8766 17.2161 4.76955 11.1771 8.04615 7.88006ZM22.6953 11.5468C23.1014 11.7285 23.1014 12.1825 22.6953 12.3641L21.3731 12.9551L20.0515 13.5461C19.6454 13.7276 19.1371 13.5006 19.1371 13.1374V10.7735C19.1371 10.4103 19.6454 10.1833 20.0515 10.3649L21.3731 10.9559L22.6953 11.5468ZM18.7293 19.1889C19.0782 19.2829 19.3973 18.9618 19.3035 18.611L18.6958 16.3276C18.602 15.9768 18.1664 15.8593 17.9113 16.1161L17.0804 16.9519L16.25 17.7876C15.9948 18.0445 16.1113 18.4831 16.4603 18.577L17.5948 18.8829L18.7293 19.1889ZM12.521 22.6873C12.3406 23.1059 11.8891 23.1059 11.7087 22.6873L10.534 19.9632C10.3536 19.5447 10.5793 19.0214 10.9401 19.0214H13.2895C13.6504 19.0214 13.8761 19.5447 13.6957 19.9632L12.521 22.6873ZM4.92615 18.611C4.83275 18.9618 5.15176 19.2829 5.50046 19.1889L6.63507 18.8829L7.76972 18.577C8.11837 18.4831 8.23512 18.0445 7.97987 17.7876L6.31868 16.1161C6.06343 15.8593 5.62762 15.9768 5.53417 16.3276L4.92615 18.611ZM1.32284 12.3641C0.892387 12.1825 0.892387 11.7285 1.32284 11.5468L4.12435 10.3649C4.5548 10.1833 5.09288 10.4103 5.09288 10.7735V13.1374C5.09288 13.5006 4.5548 13.7276 4.12435 13.5461L1.32284 12.3641ZM5.50046 4.72192C5.15176 4.62794 4.83275 4.94899 4.92615 5.29981L5.53417 7.5832C5.62762 7.93402 6.06343 8.05155 6.31868 7.79471L7.97987 6.12313C8.23512 5.86634 8.11837 5.42776 7.76972 5.33378L6.63507 5.02788L5.50046 4.72192Z"
        fill={theme.palette.report.acuteChange.improving.primary}
      />
    </SvgIcon>
  );
}

function AcuteChangeDecliningIcon(props: IconProps) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} data-testid="acute-change-declining">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00216 10.2924C1.00072 10.3417 1 10.3912 1 10.441C1 11.7669 1.46696 12.9034 2.45393 13.8605C3.13007 14.5161 4.19566 14.5061 5.50258 14.4939H5.5026H5.50261H5.50263H5.50264C5.68463 14.4922 5.87129 14.4905 6.06223 14.4905C6.06241 14.4905 6.0626 14.4904 6.06278 14.4904H7.87008L6.54099 18.9101C6.50915 18.9998 6.49854 19.0696 6.49854 19.1095C6.49854 19.279 6.5516 19.4385 6.66834 19.608C6.78508 19.7675 6.95488 19.8771 7.18836 19.937C7.19897 19.937 7.23081 19.9469 7.29448 19.9669L7.31549 19.9719C7.34817 19.98 7.37605 19.9868 7.41122 19.9868C7.85695 19.9868 8.15411 19.7774 8.28146 19.3488L9.75267 14.4904H11.6559L9.4574 21.9134C9.38311 22.1726 9.40433 22.3819 9.52107 22.5614C9.68026 22.7708 9.86068 22.9004 10.0729 22.9502C10.1472 22.9801 10.2427 23 10.3382 23C10.5399 23 10.7097 22.9402 10.8795 22.8206C11.0493 22.701 11.1448 22.5614 11.1873 22.3919L13.5152 14.4904H15.6992L14.2407 19.3404C14.2089 19.46 14.1982 19.5298 14.1982 19.5697C14.1982 19.7292 14.2513 19.8887 14.3468 20.0382C14.4636 20.1978 14.6227 20.2974 14.835 20.3573L15.0897 20.4071C15.2913 20.4071 15.4718 20.3473 15.6309 20.2376C15.7901 20.128 15.8857 19.9685 15.9281 19.779L17.5296 14.4904H17.9478C17.948 14.4904 17.9482 14.4905 17.9484 14.4905C17.9484 14.4905 17.9484 14.4904 17.9484 14.4904H18.6638C18.8931 14.4904 19.1184 14.4726 19.3381 14.4383C19.6654 14.4064 19.9946 14.3493 20.3681 14.2427C21.2361 13.995 21.8857 13.6212 22.3314 12.8735C22.7771 12.1258 23 11.3183 23 10.441C23 10.392 22.9993 10.3432 22.9978 10.2946C22.9993 10.248 23 10.2012 23 10.1542C23 8.23385 21.7516 6.60503 20.0221 6.03503C19.3116 5.71778 18.5473 5.556 17.7361 5.556H17.6045C17.4855 5.34334 17.3522 5.13836 17.2059 4.94209C16.8127 3.88055 16.1334 2.98989 15.1679 2.27608C14.0217 1.42868 12.727 1 11.2624 1C9.76604 1 8.43946 1.42868 7.28268 2.30599C6.1259 3.18329 5.37241 4.29986 5.04342 5.65569C4.55823 5.76342 4.10788 5.92453 3.69236 6.14046C2.11265 6.78812 1 8.34124 1 10.1542C1 10.2005 1.00072 10.2465 1.00216 10.2924Z"
        fill={theme.palette.report.acuteChange.declining.primary}
      />
    </SvgIcon>
  );
}

export {
  TrendImprovingIcon,
  TrendStableIcon,
  AcuteChangeDecliningIcon,
  AcuteChangeImprovingIcon,
  TrendDecliningIcon,
  BlankIcon,
};
