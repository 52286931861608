import { Box, Tooltip as MuiTooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import captureException from "Lib/ErrorHandling";
import * as Staleness from "Lib/Staleness";
import { BlankIcon, TrendDecliningIcon, TrendImprovingIcon, TrendStableIcon } from "MDS/Icons";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import React, { ReactElement } from "react";
import { Just, Maybe, Nothing } from "seidr";
import { formatDate } from "Shared/formatters";
import { SeverityChip } from "Shared/Scale/SeverityChip";
import * as Trend from "Shared/Scale/Trend";
import { CareEpisodeComputedValueDetails } from "./CareEpisodeComputedValueDetails";

const StyledTable = styled("table")(({ theme }) => ({
  margin: "auto",
  marginTop: theme.spacing(2),
  borderSpacing: 0,
  "& th": {
    textAlign: "center",
    fontWeight: "normal",
    color: theme.palette.text.secondary,
  },
  "& td": {
    textAlign: "center",
    padding: "6px",
  },
  "& tfoot td": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: theme.typography.subtitle1.fontSize,
    paddingTop: 0,
  },
}));

const StyledTrendColumn = styled("th")(() => ({
  width: 175,
}));

type TrendTooltipProps<T extends SupportedType> = {
  trend: Trend.Trend;
  currentValue: T;
  firstValue: T | null;
  lastDate: Date;
  children: ReactElement;
};

function TrendTooltip<T extends SupportedType>(props: TrendTooltipProps<T>): ReactElement | null {
  const { trend, firstValue, lastDate, currentValue, children } = props;

  if (!firstValue) {
    captureException(
      new Error(`Computed Value ${currentValue.id} has a trend but no previous value was given`),
      "TrendIndicator"
    );
    return null;
  }

  // We won't display unsupported trends.
  const unsupportedTrend = trend == Trend.Trend.NOT_SUPPORTED;
  const trendHeader = unsupportedTrend ? <th></th> : <StyledTrendColumn>Trend</StyledTrendColumn>;
  const trendBody = unsupportedTrend ? null : <TrendIndicatorWithNameWithoutTooltip trend={trend} />;

  // This is a bit silly in that we know that if we have a trend, we definitely
  // have at least 3 values.
  const content = (
    <Box>
      <StyledTable>
        <thead>
          <tr>
            <th>First Score</th>
            {trendHeader}
            <th>Latest Score</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SeverityChip value={firstValue} isStale={Staleness.isStale(lastDate, firstValue)} />
            </td>
            <td>{trendBody}</td>
            <td>
              <SeverityChip value={currentValue} isStale={Staleness.isStale(lastDate, currentValue)} />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{formatDate(firstValue.date)}</td>
            <td></td>
            <td>{formatDate(firstValue.date)}</td>
          </tr>
        </tfoot>
      </StyledTable>
    </Box>
  );

  return (
    <TooltipBase
      title={`Trend: ${Trend.name(trend)}`}
      subtitle="Trend indicates the difference in score from the first administration to now, after at least 3 sessions have been measured"
      content={content}
    >
      {children}
    </TooltipBase>
  );
}

type BaseProps = {
  trend: Trend.Trend;
  size: number;
  includePlaceholder?: boolean;
};

const StyledIconContainer = styled("span")(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  verticalAlign: "middle",
}));

function TrendIndicatorBase(props: BaseProps): ReactElement | null {
  return Maybe.fromNullable(TrendIcon(props))
    .caseOf({
      Just: (x) => Just(x),
      Nothing: () => (props.includePlaceholder ? Just(<BlankIcon />) : Nothing()),
    })
    .map((trendIcon) => <StyledIconContainer>{trendIcon}</StyledIconContainer>)
    .getOrElse(null);
}

function TrendIcon(props: BaseProps) {
  const { trend, size } = props;
  switch (trend) {
    case Trend.Trend.STABLE:
      return <TrendStableIcon sx={{ fontSize: size }} fontSize="inherit" />;
    case Trend.Trend.IMPROVING:
      return <TrendImprovingIcon sx={{ fontSize: size }} fontSize="inherit" />;
    case Trend.Trend.DECLINING:
      return <TrendDecliningIcon sx={{ fontSize: size }} fontSize="inherit" />;
    case Trend.Trend.NOT_SPECIFIED:
    case Trend.Trend.NOT_SUPPORTED:
    case Trend.Trend.INVALID:
      return null;
  }
}

type SupportedType = CareEpisodeComputedValueDetails;

type Props = {
  trend: Trend.Trend;
  // Although in theory this should be always present, we can't assert this easily in the type system. So deal with it here rather than
  // forcing every single consumer to work out what to do with it.
  firstValue: SupportedType | null;
  currentValue: SupportedType;
  lastDate: Date;
  includePlaceholder?: boolean;
};

function TrendIndicator(props: Props): ReactElement | null {
  const { trend, firstValue, currentValue, lastDate, includePlaceholder } = props;

  return (
    Maybe.fromNullable(<TrendIndicatorBase size={24} trend={trend} {...{ includePlaceholder }} />)
      // Wrap to allow Tooltip specific refs
      .map((indicator) => <span>{indicator}</span>)
      .map((indicator) => (
        <TrendTooltip trend={trend} firstValue={firstValue} currentValue={currentValue} lastDate={lastDate}>
          {indicator}
        </TrendTooltip>
      ))
      .getOrElse(null)
  );
}

function TrendIndicatorWithName(props: Props): ReactElement | null {
  const { trend, includePlaceholder, ...passthroughProps } = props;

  return Maybe.fromNullable(<TrendIndicatorBase size={24} trend={trend} {...{ includePlaceholder }} />)
    .map((indicator) => (
      <span>
        {indicator} <strong>{Trend.name(trend)}</strong>
      </span>
    ))
    .map((indicator) => (
      <TrendTooltip trend={trend} {...passthroughProps}>
        {indicator}
      </TrendTooltip>
    ))
    .getOrElse(null);
}

type JustTrendProps = {
  trend: Trend.Trend;
  includePlaceholder?: boolean;
};

function TrendIndicatorWithNameWithoutTooltip(props: JustTrendProps): ReactElement | null {
  const { trend, includePlaceholder } = props;

  return Maybe.fromNullable(<TrendIndicatorBase size={24} trend={trend} {...{ includePlaceholder }} />)
    .map((indicator) => (
      <span>
        {indicator} <strong>{Trend.name(trend)}</strong>
      </span>
    ))
    .getOrElse(null);
}

function TrendIndicatorWithSimpleTooltip(props: JustTrendProps): ReactElement | null {
  const { includePlaceholder } = props;
  return (
    Maybe.fromNullable(<TrendIndicatorBase size={24} trend={props.trend} {...{ includePlaceholder }} />)
      // Wrap to allow Tooltip specific refs
      .map((indicator) => <span>{indicator}</span>)
      .map((indicator) => <MuiTooltip title={`${Trend.name(props.trend)} Trend`}>{indicator}</MuiTooltip>)
      .getOrElse(null)
  );
}

export default TrendIndicator;
export {
  TrendIndicatorBase,
  TrendIndicator,
  TrendIndicatorWithName,
  TrendIndicatorWithNameWithoutTooltip,
  TrendIndicatorWithSimpleTooltip,
};
