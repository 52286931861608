import { Alert } from "@mui/material";
import React, { ReactElement } from "react";
import { RemoteData } from "seidr";
import Overlay from "./Overlay";
import Spinner from "./Spinner";
import { styled } from "@mui/material/styles";
import { CheckCircle } from "@mui/icons-material";
import { To } from "react-router-dom";
import { useEffectDeepCompare, useEffectEveryRender, useEffectSimpleCompare } from "Lib/Hooks";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { useTranslation } from "react-i18next";
import { UserError } from "GeneratedGraphQL/SchemaAndOperations";
import { intersection } from "ramda";

export const StyledSuccessIcon = styled(CheckCircle)(({ theme }) => ({
  fontSize: 50,
  color: theme.palette.success.main,
}));

type FormOverlayProps<E, D> = {
  response: RemoteData<E, D>;
  errorMessage: string;
};

// Simple form overlay, usable when you have a constant error message that doesn't depend on the error response.
export function FormOverlay<E, D>(props: FormOverlayProps<E, D>): ReactElement | null {
  return props.response.caseOf({
    NotAsked: () => null,
    Loading: () => {
      return (
        <Overlay>
          <Spinner />
        </Overlay>
      );
    },
    Failure: (_e) => <Alert severity="error">{props.errorMessage}</Alert>,
    Success: () => (
      <Overlay>
        <StyledSuccessIcon data-testid="success-icon" />
      </Overlay>
    ),
  });
}

export const Form = styled("form")(({ theme }) => ({
  // In order to contain the FormOverlay, must have relative position so the overlay can be absolutely positioned inside
  position: "relative",
  // If the very first thing in your form is a field (common) the field label will peek out the top of the overlay
  // unless we give it a little push down.
  paddingTop: theme.spacing(0.5),
}));

const SUCCESS_BADGE_DURATION = 1500; // ms

// For forms that support it, bounce to another route on a successful response, after a timeout. Note that useEffect
// has to be the top-level call here or React will get angry about using more/fewer hooks than the previous render.
export function useRedirectOnSuccess<E, D>(
  response: RemoteData<E, D>,
  route: string | undefined,
  nav: (to: To) => void
) {
  useEffectEveryRender(() => {
    return response.caseOf({
      Success: () => {
        let timeoutId: NodeJS.Timeout | undefined = undefined;
        if (route) {
          timeoutId = setTimeout(() => nav(route), SUCCESS_BADGE_DURATION);
        }

        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      },
      _: () => undefined,
    });
  });
}

export function useConditionalRedirectOnSuccess<E, D>(
  response: RemoteData<E, D>,
  routeFn: (data: D) => string | undefined,
  nav: (to: To) => void
) {
  useEffectEveryRender(() => {
    return response.caseOf({
      Success: (data) => {
        let timeoutId: NodeJS.Timeout | undefined = undefined;
        const route = routeFn(data);
        if (route) {
          timeoutId = setTimeout(() => nav(route), SUCCESS_BADGE_DURATION);
        }

        return () => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
        };
      },
      _: () => undefined,
    });
  });
}

export type FieldSpec<TValue, TEvent> = {
  default?: TValue;
  required: boolean;
  // A list of path segments that can be returned by the API in a UserError type that should match this field.
  errorPaths?: ReadonlyArray<string>;
  validate?: (value: TValue) => string | null;
  checkRequired: (value: TValue | undefined) => boolean;
  extractValue: (event: TEvent) => TValue | undefined;
};

export type FieldMethods<TValue, TEvent> = {
  value: TValue | undefined;
  error: boolean;
  // Valid represents whether the field is valid, whether or not it has been touched by the user
  // This is different from error in that you don't want to display errors when the user has not touched
  // the form yet, but you don't want to let the user submit an invalid form.
  valid: boolean;
  helperText: string | null;
  matchServerError: (userError: UserError) => boolean;
  onChange: (event: TEvent) => void;
  reset: () => void;
  clearError: () => void;
};

export function useField<TValue, TEvent>(spec: FieldSpec<TValue, TEvent>): FieldMethods<TValue, TEvent> {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(spec.default);

  // This just makes sure that if our default value changes for our field, we'll blow up the state
  // by calling setValue on the field value again. This will let things like extant responsive dialogs
  // that are using a form update from new content, after say, a mutation.
  useEffectDeepCompare(() => {
    setValue(spec.default);
  }, [spec.default]);

  // Tracking error here as a separate value from the valid boolean in the return value may seem redundant, but they
  // server different purposes. "Valid" means "is this field ready to submit" while error also covers "has something
  // gone wrong submitting this field".
  const [error, setError] = React.useState<string | null>(null);
  const validate = (newValue: TValue | undefined) => {
    if (spec.required && !spec.checkRequired(newValue)) {
      // Possible future work, let callers customize this message
      return t("missingRequiredField");
    }

    // since 0 is falsy, lets be explicit
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (spec.validate && newValue !== undefined && newValue !== null) {
      return spec.validate(newValue);
    }

    return null;
  };

  const onChange = (event: TEvent) => {
    const newValue = spec.extractValue(event);
    setValue(newValue);
    setError(validate(newValue));
  };

  // A UserError is considered to match a field if any segment in the error's path array matches any segment in the
  // field's errorPaths array. This allows for fuzzy matching where a field can match multiple server attributes (e.g.
  // name fields matching errors on both name and username). We may need to extend this in the future if we do things
  // like send back which model is involved in the validation errors, etc.
  const matchServerError = (userError: UserError) => {
    const matchedPaths = intersection(spec.errorPaths || [], userError.path || []);

    if (matchedPaths.length > 0) {
      setError(userError.message);
      return true;
    } else {
      return false;
    }
  };

  return {
    value: value,
    error: error !== null,
    helperText: error,
    valid: validate(value) === null,
    onChange: onChange,
    matchServerError: matchServerError,
    reset: () => {
      setValue(spec.default);
      setError(null);
    },
    clearError: () => {
      setError(null);
    },
  };
}

type TextFieldSpec = {
  default?: string;
  required: boolean;
  errorPaths?: ReadonlyArray<string>;
  validate?: (newValue: string) => string | null;
};

export type TextFieldMethods = FieldMethods<string, React.ChangeEvent<HTMLInputElement>>;

/**
 * Get the properties required for a text field. The assumption is that this maps to a single Mui <TextField> component.
 *
 * @param spec The specification of the field structure
 * @returns
 */
export function useTextField(spec: TextFieldSpec): TextFieldMethods {
  return useField({
    // Using undefined as the default makes reset not work because React interprets it as an uncontrolled form value.
    default: spec.default === undefined ? "" : spec.default,
    required: spec.required,
    validate: spec.validate,
    errorPaths: spec.errorPaths,
    extractValue: (event: React.ChangeEvent<HTMLInputElement>) => event.target.value,
    checkRequired: (newValue: string | undefined) => newValue !== undefined && newValue !== "",
  });
}

type NumberFieldSpec = {
  default?: number;
  required: boolean;
  errorPaths?: ReadonlyArray<string>;
  validate?: (newValue: number) => string | null;
};

export type NumberFieldMembers = FieldMethods<string | number, React.ChangeEvent<HTMLInputElement>>;

/**
 * Get the properties required for a number in a text field. The assumption is that this maps to a single Mui <TextField> component.
 * This requires you to deal with the underlying ChangeEvent and parse the number, etc. use v2 if you want to
 * have this handled for you.
 *
 * @param spec The specification of the field structure
 * @returns
 */
export function useNumberField(spec: NumberFieldSpec): NumberFieldMembers {
  const { t } = useTranslation();
  if (spec.validate === undefined) {
    spec.validate = (newValue: number) => {
      if (Number.isNaN(newValue)) {
        return t("invalidNumberValue");
      }
      return null;
    };
  }

  return useField({
    // Using undefined as the default makes reset not work because React interprets it as an uncontrolled form value.
    default: spec.default === undefined ? "" : spec.default,
    required: spec.required,
    validate: spec.validate,
    errorPaths: spec.errorPaths,
    extractValue: (event: React.ChangeEvent<HTMLInputElement>) => Number.parseInt(event.target.value),
    checkRequired: (newValue: number | undefined) => newValue !== undefined && !Number.isNaN(newValue),
  });
}

// qqHRB
// // Number Field V2 differs from V1 in that it abstracts away the html event and leaves you just a number | string interface.
// // In order to use this you need to handle the HTML Event yourself, or - better - use the NumberField component
// // which does this for you.
// type NumberFieldV2Spec = {
//   default?: number;
//   required: boolean;
//   errorPaths?: ReadonlyArray<string>;
//   validate?: (newValue: number | null) => string | null;
// };

// export type NumberFieldV2Members = FieldMethods<number | null, string | number>;

// /**
//  * A field that is a number (or a string if bad input is entered). Use this rather than useNumberField
//  * if you don't want to deal with the HTML event. In this case you probably want to use NumberInput component
//  * which abstracts this away
//  */
// export function useNumberFieldV2(spec: NumberFieldSpec): NumberFieldMembers {
//   const { t } = useTranslation();
//   if (spec.validate === undefined) {
//     spec.validate = (newValue: number) => {
//       if (Number.isNaN(newValue)) {
//         return t("invalidNumberValue");
//       }
//       return null;
//     };
//   }

//   return useField({
//     // Using undefined as the default makes reset not work because React interprets it as an uncontrolled form value.
//     default: spec.default === undefined ? "" : spec.default,
//     required: spec.required,
//     validate: spec.validate,
//     errorPaths: spec.errorPaths,
//     extractValue: (value: number | string) => (typeof value === "number" ? value : null),
//     checkRequired: (newValue: number | undefined) => newValue !== undefined && !Number.isNaN(newValue),
//   });
// }

// If you have an optional number, it may have garbase in it or be an empty string. This only gives it back
// if it is a number
export function extractNumberFieldValue(value: number | string | undefined): number | null {
  if (typeof value === "number") {
    return value;
  }

  return null;
}

type BooleanFieldSpec = {
  default?: boolean;
  required: boolean;
  errorPaths?: ReadonlyArray<string>;
};

/**
 * Get the properties required for a boolean from a checkbox. The assumption is that this maps to a Mui <FormControlLabel>
 * component with a <CheckBox> / <Switch> / <RadioButton> that returns a checked.
 *
 * @param spec The specification of the field structure
 * @returns
 */
export function useBooleanField(spec: BooleanFieldSpec) {
  return useField({
    // Using undefined as the default makes reset not work because React interprets it as an uncontrolled form value.
    default: spec.default === undefined ? false : spec.default,
    required: spec.required,
    errorPaths: spec.errorPaths,
    extractValue: (event: React.ChangeEvent<HTMLInputElement>) => event.target.checked,
    checkRequired: (newValue: boolean | undefined) => newValue !== undefined,
  });
}

type WrappedFieldSpec<TValue> = {
  default?: TValue;
  required: boolean;
  errorPaths?: ReadonlyArray<string>;
  validate?: (newValue: TValue) => string | null;
};

/**
 * Get the properties for a field that's already wrapped in some higher level domain component. The idea here is that
 * this is something like <DatePicker> or <QueryAutocomplete> that's already doing the work of converting HTML events
 * into a properly typed javascript value for us, so we can just check for undefined if it's required and pass the
 * value along otherwise.
 *
 * @param spec The specification of the field structure
 * @returns
 */
export function useWrappedField<TValue>(spec: WrappedFieldSpec<TValue>) {
  return useField({
    default: spec.default,
    required: spec.required,
    validate: spec.validate,
    errorPaths: spec.errorPaths,
    extractValue: (event: TValue) => event,
    checkRequired: (newValue: TValue | undefined) => newValue !== undefined,
  });
}

type ListFieldSpec<TValue> = {
  default?: Array<TValue>;
  required: boolean;
  errorPaths?: ReadonlyArray<string>;
  validate?: (newValue: Array<TValue>) => string | null;
};

/**
 * Get the properties for a field that is a list of values. This is very similar to useWrappedField in that the
 * change handler just takes a value instead of an event. In addition it coerces the returned value to always be a list,
 * and includes some helper methods for working with list fields.
 *
 * @param spec The specification of the field structure
 * @returns
 */
export function useListField<TValue>(spec: ListFieldSpec<TValue>) {
  const field = useField({
    default: spec.default,
    required: spec.required,
    validate: spec.validate,
    errorPaths: spec.errorPaths,
    extractValue: (event: Array<TValue>) => event,
    checkRequired: (newValue: Array<TValue> | undefined) => newValue !== undefined,
  });

  return {
    ...field,
    value: field.value || [],
    removeNthValue: (n: number) => {
      if (!field.value) {
        return;
      }

      const newValues = [...field.value];
      newValues.splice(n, 1);
      field.onChange(newValues);
    },
  };
}

type FormSpec<ResponseType> = {
  submit: () => void;
  remoteData?: MutationRemoteDataResult<ResponseType>;
  onSuccess?: (response: ResponseType) => void;
  // Using unknown here instead of generics because we don't actually need to do anything with the data inside these
  // objects, just check their validity/structure.
  fields: Record<string, FieldMethods<unknown, unknown>>;
  // An optional id attribute that the form can take. This is useful when your submit button is not contained within
  // the form element, and you want to include the `form` attribute on the button to coordinate the submit event.
  id?: string;
  // Path matchers for errors that should not be included in the global errors list if they don't match any fields.
  // Use sparingly for when there's a rails validation that will piggyback on other validation errors and doesn't
  // add any helpful information.
  ignoreErrorPaths?: ReadonlyArray<string>;
};

/**
 * Get a submit handler and validity boolean for a whole form's worth of fields. Also sets up an effect handler to
 * trigger the form's success handler when appropriate. Note that the submit handler passed into this isn't called
 * unless the form as a whole is valid.
 *
 * @param spec
 * @returns
 */
export function useForm<ResponseType>(spec: FormSpec<ResponseType>) {
  const { submit, onSuccess, remoteData, fields, id } = spec;

  const [globalErrors, setGlobalErrors] = React.useState<Array<string>>([]);
  const isValid = Object.values(fields).every((field) => field.valid);
  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValid) {
      submit();
    }
  };

  const fakeSubmitHandler = () => {
    if (isValid) {
      submit();
    }
  };

  // The error collection logic is a little complicated here, but the goal is to match errors to specific form fields
  // where possible, and otherwise collect unmatched errors as a "global error" that we can show at the top of the
  // form. Specifically:
  // * ApolloError is always a global error
  // * UserError is a list of errors, each of which can match or not match
  // * Matched errors get attched to the fields (see matchServerError implementation in useField)
  // * Unmatched errors get collected in globalErrors.
  // * globalErrors is reset to empty in non-failure states so we don't stack up duplicates if you fail to submit the
  //   form multiple times in a row.

  useEffectSimpleCompare(() => {
    remoteData?.caseOf({
      Success: (response) => {
        setGlobalErrors([]);
        if (onSuccess) {
          onSuccess(response);
        }
      },
      Failure: (error) => {
        return error.caseOf({
          apolloError: (apolloError) => {
            setGlobalErrors([apolloError.message]);
          },
          userError: (userErrors) => {
            const errorMessages: Array<string> = [];
            userErrors.forEach((userError) => {
              let alreadyMatched = false;
              Object.values(fields).forEach((field) => {
                // Move the match call out of the assignment so we don't accidentally skip it with short circuiting.
                const matchedThisField = field.matchServerError(userError);
                alreadyMatched ||= matchedThisField;
              });

              // Eslint can't seem to understand the alreadyMatched assignment in the forEach block above, turn off
              // the fake error here.
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              if (!alreadyMatched) {
                const matchedIgnorePaths = intersection(spec.ignoreErrorPaths || [], userError.path || []);
                if (matchedIgnorePaths.length === 0) {
                  errorMessages.push(formatGlobalUserError(userError));
                }
              }
            });
            setGlobalErrors(errorMessages);
          },
        });
      },
      _: () => {
        setGlobalErrors([]);
        Object.values(fields).forEach((field) => {
          field.clearError();
        });
      },
    });
  }, [remoteData?.kind]);

  return {
    isValid: isValid,
    onSubmit: submitHandler,
    onFakeSubmit: fakeSubmitHandler,
    reset: () => {
      Object.values(fields).forEach((field) => field.reset());
    },
    id: id,
    showSpinner: remoteData?.kind === "Loading",
    disableSubmit: remoteData?.kind === "Loading" || !isValid,
    globalError: globalErrors.length === 0 ? undefined : globalErrors.join(", "),
  };
}

// This is my best interpretation of what ActiveRecord expects you do to do with the validation errors it generates.
// The last segment in the paths array is generally an attribute name, which may or may not correspond to something
// that a user knows what to do with, but it's better than just a sentence fragment.
function formatGlobalUserError(userError: UserError): string {
  if (userError.path && userError.path.length > 0) {
    const prefix = userError.path[userError.path.length - 1];
    return `${prefix} ${userError.message}`;
  } else {
    return userError.message;
  }
}
