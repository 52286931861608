import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisodeProviderRelationship,
  Provider,
  useCollaborativeCarePrimaryCareProvidersQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import { PickTypename } from "type-utils";
import { useTranslation } from "react-i18next";
import { ProviderId } from "Lib/Ids";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";

type PrimaryCareProviderPatientSearchFilterProps = {
  value: ProviderId | null;
  setValue: (value: ProviderId | null) => void;
  defaultValue?: ProviderId | null;
};
export function PrimaryCareProviderPatientSearchFilter(
  props: PrimaryCareProviderPatientSearchFilterProps
): ReactElement {
  const { t } = useTranslation();
  const { remoteData } = apolloQueryHookWrapper(useCollaborativeCarePrimaryCareProvidersQuery());

  return remoteData.caseOf({
    Loading: () => <Typography>{t("remoteData.loading")}</Typography>,
    Failure: () => <ErrorMessage message={t("remoteData.failure")} />,
    Success: (data) => {
      if (data.collaborativeCarePrimaryCareProviders) {
        return (
          <PrimaryCareProviderPatientSearchFilterElement
            PrimaryCareProviders={data.collaborativeCarePrimaryCareProviders}
            value={props.value}
            setValue={props.setValue}
            defaultValue={props.defaultValue}
          />
        );
      }
      return <ErrorMessage message={t("remoteData.failure")} />;
    },
    NotAsked: () => <Typography>{t("remoteData.notAsked")}</Typography>,
  });
}

type PrimaryCareProviderPatientSearchFilterElementProps = PrimaryCareProviderPatientSearchFilterProps & {
  PrimaryCareProviders: ReadonlyArray<PickTypename<Provider, "id" | "name">>;
};
function PrimaryCareProviderPatientSearchFilterElement(
  props: PrimaryCareProviderPatientSearchFilterElementProps
): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Box width="15em">
      <ProviderSelectSingle
        label={t("collaborativeCare:patientList.search.primaryCareProviderLabel")}
        value={props.value}
        setValue={props.setValue}
        defaultValue={props.defaultValue}
        defaultRelationship={[CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN]}
      />
    </Box>
  );
}
