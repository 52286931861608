import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from "@mui/material";
import {
  OrganizationFilter,
  PatientFilter,
  PatientQualityIssueType,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { optionToEnum } from "Shared/Enum";
import EnumSelect from "Shared/EnumSelect";
import OrganizationSelect, {
  defaultValue as defaultOrganizationValue,
} from "Shared/Filters/OrganizationSelect";
import PatientSelect from "Shared/Filters/PatientSelect";
import {
  useQueryStringOptionalEnumParameter,
  useQueryStringOptionalUnionParameter,
  useQueryStringOrganizationFilterParameter,
  useQueryStringPatientFilterParameter,
} from "Shared/QueryStringParameter";
import { PatientQualityIssueSuperStatus } from "./PatientQualityIssue";
import { patientQualityIssueTypeT } from "GeneratedGraphQL/EnumTranslations";

export type FilterProps = {
  setStatus: (status: PatientQualityIssueSuperStatus | null) => void;
  status: PatientQualityIssueSuperStatus | null;
  setIssue: (issue: PatientQualityIssueType | null) => void;
  issue: PatientQualityIssueType | null;
  setPatientFilter: (patientFilter: PatientFilter | null) => void;
  patientFilter: PatientFilter | null;
  setOrganizationFilter: (issue: OrganizationFilter | null) => void;
  organizationFilter: OrganizationFilter | null;
};

function useQualityFilters() {
  const [status, setStatus] = useQueryStringOptionalUnionParameter<PatientQualityIssueSuperStatus>(
    "qualityIssueStatus",
    ["active", "pending", "resolved"],
    "active"
  );

  const [issue, setIssue] = useQueryStringOptionalEnumParameter<PatientQualityIssueType>(
    "qualityIssueType",
    PatientQualityIssueType,
    null,
    true
  );
  const [patientFilter, setPatientFilter] = useQueryStringPatientFilterParameter("patientFilter", null, true);
  const [organizationFilter, setOrganizationFilter] = useQueryStringOrganizationFilterParameter(
    "organizationFilter",
    defaultOrganizationValue,
    true
  );

  return {
    status,
    setStatus,
    issue,
    setIssue,
    patientFilter,
    setPatientFilter,
    organizationFilter,
    setOrganizationFilter,
  };
}

export default function QualityIssuesFilters(props: FilterProps): ReactElement {
  const { t } = useTranslation(["qualityIssues"]);
  const {
    status,
    setStatus,
    issue,
    setIssue,
    organizationFilter,
    setOrganizationFilter,
    patientFilter,
    setPatientFilter,
  } = props;

  const onStatusChange = (event: SelectChangeEvent<string | null | undefined>) => {
    if (
      event.target.value === "active" ||
      event.target.value === "pending" ||
      event.target.value === "resolved"
    ) {
      setStatus(event.target.value);
    } else {
      setStatus(null);
    }
  };

  const onIssueChange = (newValue: PatientQualityIssueType | "all" | null) => {
    if (newValue && newValue !== "all") {
      setIssue(optionToEnum(PatientQualityIssueType, newValue));
    } else {
      setIssue(null);
    }
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box height="6em" width="200px">
        <OrganizationSelect setValue={setOrganizationFilter} value={organizationFilter} />
      </Box>
      <Box height="6em" width="200px">
        <PatientSelect setValue={setPatientFilter} value={patientFilter} />
      </Box>
      <Box height="6em" width="200px">
        <StatusSelector onChange={onStatusChange} value={status} />
      </Box>
      <Box height="6em" width="200px">
        <EnumSelect
          fullWidth
          onChange={onIssueChange}
          value={issue}
          optionsEnum={PatientQualityIssueType}
          title={t("qualityIssues:issueType")}
          enumTrans={patientQualityIssueTypeT}
        />
      </Box>
    </Stack>
  );
}

type StatusSelectorProps = {
  value: PatientQualityIssueSuperStatus | null;
  onChange: (event: SelectChangeEvent<string | null | undefined>) => void;
};

function StatusSelector(props: StatusSelectorProps): ReactElement {
  const { t } = useTranslation(["qualityIssues"]);

  const valueWithBlank = props.value || "";

  return (
    <FormControl fullWidth>
      <InputLabel>{t("qualityIssues:status")}</InputLabel>
      <Select
        fullWidth
        label={t("qualityIssues:status")}
        value={valueWithBlank as unknown}
        onChange={props.onChange}
      >
        <MenuItem value="" key="all">
          {t(`qualityIssues:allIssues`)}
        </MenuItem>
        <MenuItem value="active" key="active">
          {t(`qualityIssues:activeIssues`)}
        </MenuItem>
        <MenuItem value="pending" key="pending">
          {t(`qualityIssues:pendingIssues`)}
        </MenuItem>
        <MenuItem value="resolved" key="resolved">
          {t(`qualityIssues:resolvedIssues`)}
        </MenuItem>
      </Select>
    </FormControl>
  );
}

export { useQualityFilters };
