import { isBefore, isEqual } from "date-fns";
import * as NEL from "Lib/NonEmptyList";

function isStale(mostRecentDate: Date, withDate: { date: Date } | { targetDate: Date }): boolean {
  if ("date" in withDate) {
    return isBefore(withDate.date, mostRecentDate);
  }
  return isBefore(withDate.targetDate, mostRecentDate);
}

function isFresh(mostRecentDate: Date, withDate: { date: Date } | { targetDate: Date }): boolean {
  return !isStale(mostRecentDate, withDate);
}

function dateIsStale(targetDate: Date, dates: NEL.NonEmptyList<Date>) {
  return !isEqual(NEL.last(dates), targetDate);
}

export { isStale, isFresh, dateIsStale };
