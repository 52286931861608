import { CheckCircle, Close } from "@mui/icons-material";
import { Card, CardContent, styled } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { AllowedFeatures } from "Contexts/CurrentInstituteContext";
import { Institute, useInstituteListQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React from "react";
import { PickTypename } from "type-utils";

export function FeatureMatrixPage() {
  const { remoteData } = apolloQueryHookWrapper(useInstituteListQueryQuery());

  const content = remoteData.caseOf({
    Success: (response) => {
      if (response.institutes) {
        const institutes = [...response.institutes.nodes];
        institutes.sort((a, b) => a.shortcode.localeCompare(b.shortcode));
        return <FeatureMatrix institutes={institutes} />;
      } else {
        return <ErrorMessage message="Failed to load institutes" />;
      }
    },
    Failure: (err) => <ErrorMessage message={err.message} />,
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
  });

  return (
    <Page browserTitle="Institute Feature Matrix">
      <Card sx={{ overflow: "visible", overflowY: "visible", overflowX: "visible" }}>
        <CardContent>{content}</CardContent>
      </Card>
    </Page>
  );
}

type FeatureMatrixProps = {
  institutes: ReadonlyArray<
    PickTypename<Institute, "id" | "name" | "shortcode"> & {
      featureSet: { [key in AllowedFeatures]: boolean };
    }
  >;
};

const LargeMatrixTable = styled("table")(({ theme }) => ({
  paddingTop: "10rem",
  paddingRight: "5rem",
  width: "100%",
  "& > tbody > tr > td": {
    width: "1rem",
  },
  "& > tbody > tr:hover > td": {
    backgroundColor: theme.palette.report.shading.background,
  },
  "& > thead > tr > th": {
    position: "sticky",
    // Experimentally determined, may have to change if the length of our feature flag names changes.
    top: "15em",
    zIndex: theme.zIndex.stickyCard,
  },
  "& > thead > tr > th > span": {
    transform: "rotate(315deg)",
    transformOrigin: "left",
    position: "absolute",
    top: "-1rem",
    left: "0.5rem",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
  },
}));

function FeatureMatrix(props: FeatureMatrixProps) {
  const someInstitute = props.institutes[0];
  if (someInstitute === undefined) {
    return "No institutes";
  }

  const features = Object.keys(someInstitute.featureSet).filter(
    // Get rid of __typename so the cast is actually safe
    (feature) => !feature.startsWith("__")
  );
  features.sort((a, b) => a.localeCompare(b));

  const typedFeatures = features as unknown as ReadonlyArray<AllowedFeatures>;

  const [sortBy, setSortBy] = React.useState<AllowedFeatures | null>(null);

  const onHeaderClick = (feature: AllowedFeatures) => {
    if (feature === sortBy) {
      setSortBy(null);
    } else {
      setSortBy(feature);
    }
  };

  const sortedInstitutes = [...props.institutes];
  sortedInstitutes.sort((a, b) => {
    if (sortBy === null) {
      return 0;
    } else if (a.featureSet[sortBy] && !b.featureSet[sortBy]) {
      return -1;
    } else if (!a.featureSet[sortBy] && b.featureSet[sortBy]) {
      return 1;
    } else {
      return 0;
    }
  });

  return (
    <LargeMatrixTable>
      <thead>
        <tr>
          <th></th>
          {typedFeatures.map((feature) => (
            <th
              key={`${feature}-header`}
              onClick={() => onHeaderClick(feature)}
              style={{
                fontWeight: sortBy === feature ? "bold" : "normal",
              }}
            >
              <span>{feature}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sortedInstitutes.map((institute) => (
          <tr key={institute.id.toString()}>
            <td>{institute.shortcode}</td>
            {typedFeatures.map((feature) => (
              <td key={`${institute.id.toString()}-${feature}`}>
                {institute.featureSet[feature] ? <CheckCircle color="success" /> : <Close color="error" />}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </LargeMatrixTable>
  );
}
