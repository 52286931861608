import * as Id from "Lib/Id";
import { ScaleSectionSummary } from "./ScaleSection";
import { Maybe } from "seidr";
import {
  CareEpisodeSpecialSectionType,
  ConstructSummaryFragment as RawConstructSummary,
} from "GeneratedGraphQL/SchemaAndOperations";

export type ConstructId = Id.Id<"Construct">;

// A construct represents a part or facet of treatment, usually pertaining to the status of the patient, or about the treatment itself,
// e.g. 'Broad Band Symptoms', 'Therapeutic Alliance'
export type Construct = {
  id: ConstructId;
  name: string;
  shortname: string;
  domain: Domain;
  description: Maybe<string>;
  displayOrder: number;
};

export type DomainId = Id.Id<"Domain">;

export type Domain = {
  id: DomainId;
  name: string;
};

export type ConstructSection = {
  // Note that the construct may be null if there is no construct associated with the
  // section. These scales can all be considered a single construct of 'null'.
  construct: Maybe<Construct>;
  scaleSections: Array<ScaleSectionSummary>;
};

export type SpecialSection = {
  sectionType: CareEpisodeSpecialSectionType;
  scaleSections: ReadonlyArray<ScaleSectionSummary>;
};

function toConstructSummary(raw: RawConstructSummary): Construct {
  return {
    id: raw.id,
    name: raw.name,
    shortname: raw.name,
    description: Maybe.fromNullable(raw.description),
    domain: raw.domain,
    displayOrder: raw.displayOrder,
  };
}

export { toConstructSummary };
