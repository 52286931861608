import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useWithFeatureEnabled, WithFeature } from "Contexts/CurrentInstituteContext";
import {
  CareEpisodeProviderInput,
  useCareUnitSummaryQuery,
  useCreateCareEpisodeMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import * as Id from "Lib/Id";
import { OrganizationId, PatientId, TreatmentServiceId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "Shared/ErrorMessage";
import { useConditionalRedirectOnSuccess } from "Shared/Form";
import Spinner from "Shared/Spinner";
import { careEpisodeCreateBreadcrumbs } from "./CareEpisodeBreadcrumbs";
import { CareEpisodeLoading } from "./CareEpisodeLoading";
import CareEpisodeOrganizationSection from "./CareEpisodeOrganizationSection";
import CareEpisodeProviderSection from "./CareEpisodeProviderSection";
import CareEpisodeTreatmentServiceSection from "./CareEpisodeTreatmentServiceSection";

function CareEpisodeCreateHookWrapper(): ReactElement {
  const { t } = useTranslation(["common", "careEpisodes"]);
  const params = useParams<{
    patientId?: string;
  }>();
  const patientId = Id.fromNullableString<"Patient">(params.patientId).getOrElse(null);

  if (!patientId) {
    return (
      <Page browserTitle="Mirah">
        <ErrorMessage message="Invalid URL" />
      </Page>
    );
  }

  const { remoteData: careUnitResponse } = apolloQueryHookWrapper(
    useCareUnitSummaryQuery({
      variables: {
        patientId: patientId,
      },
    })
  );

  const breadcrumbs = careUnitResponse.caseOf({
    Success: (careUnit) => {
      if (!careUnit.patient) {
        return null;
      }

      return careEpisodeCreateBreadcrumbs({ patient: careUnit.patient, t });
    },
    _: () => null,
  });

  const content = careUnitResponse.caseOf({
    NotAsked: () => <CareEpisodeLoading />,
    Loading: () => <CareEpisodeLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (careUnit) => {
      if (!careUnit.patient) {
        return <ErrorMessage message="Unable to load data" />;
      }

      return <CareEpisodeCreate patientId={careUnit.patient.id} />;
    },
  });

  return (
    <Page browserTitle="Mirah" breadcrumbs={breadcrumbs}>
      {content}
    </Page>
  );
}

type CareEpisodeCreateProps = {
  patientId: PatientId;
};

export function CareEpisodeCreate(props: CareEpisodeCreateProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const treatmentServiceIsEnabled = useWithFeatureEnabled("enableTreatmentServices");

  // For now, default to no organization
  const [selectedOrg, setSelectedOrg] = React.useState<OrganizationId | null>(null);
  const [selectedTreatmentService, setSelectedTreatmentService] = React.useState<TreatmentServiceId | null>(
    null
  );

  // For now, default to completely blank new care episode provider. In future we could consider
  // e.g. using the current provider.
  const [careTeam, setCareTeam] = React.useState<Array<CareEpisodeProviderInput>>([]);

  const [periodStart, setPeriodStart] = React.useState<Date | null>(new Date());

  const [createCareEpisode, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.createCareEpisode,
    useCreateCareEpisodeMutation({
      variables: {
        careEpisode: {
          patientId: props.patientId,
          organizationId: selectedOrg,
          treatmentServiceId: selectedTreatmentService,
          periodStart: periodStart,
          careTeam: careTeam,
        },
      },
    })
  );

  function onSave() {
    createCareEpisode();
  }

  const isLoading = remoteData.caseOf({
    Loading: () => true,
    _: () => false,
  });
  const error = remoteData.caseOf({
    Failure: (err) =>
      err.caseOf({
        apolloError: (aerr) => aerr.message,
        // This is quite bad, but in order to actually ship this page ever I'm declaring
        // better error handling patterns out of scope. We have to make time for them soon though.
        userError: (uerr) => uerr.map((e) => e.message).join(", "),
      }),
    _: () => "",
  });

  useConditionalRedirectOnSuccess(
    remoteData,
    (data) => {
      if (!data?.careEpisode) {
        return undefined;
      }
      return `/app/patients/${props.patientId}/care-episodes/${data.careEpisode.id}`;
    },
    useNavigate()
  );

  const isFormValid = () => {
    // Form is valid if both org and treatment service are specified when treatment service is enabled, or just if org is selected if not enabled.
    return selectedOrg && (!treatmentServiceIsEnabled || selectedTreatmentService) && periodStart;
  };

  return (
    <Stack spacing={1}>
      <WithFeature feature={"enableTreatmentServices"}>
        <CareEpisodeTreatmentServiceSection
          selectedTreatmentService={selectedTreatmentService}
          setSelectedTreatmentService={setSelectedTreatmentService}
        />
      </WithFeature>
      <CareEpisodeOrganizationSection selectedOrg={selectedOrg} setSelectedOrg={setSelectedOrg} />
      <CareEpisodeProviderSection setCareTeam={setCareTeam} selectedOrg={selectedOrg} careTeam={careTeam} />
      <Card>
        <CardHeader
          title={t("careEpisodes:sections.dates")}
          subheader={t("careEpisodes:sections.datesSubtitle")}
        />
        <CardContent>
          {/* TODO: Make a ResponsiveDatePicker component that wraps this an MobileDatePicker and uses our theme
              breakpoints to pick which one to use. */}
          <DatePicker
            label={t("careEpisodes:fields.start")}
            format="MM/dd/yyyy"
            value={periodStart}
            onChange={setPeriodStart}
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={!isFormValid() || isLoading}
            >
              {isLoading ? <Spinner /> : t("careEpisodes:actions.save")}
            </Button>
            <ErrorMessage message={error} />
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export { CareEpisodeCreateHookWrapper as CareEpisodeCreatePage, CareEpisodeCreate as Component };
