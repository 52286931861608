// You have to do some weird things on the base 64 decode to stop it coming back blank.
// https://stackoverflow.com/questions/34436133/pdf-is-blank-when-downloading-using-javascript
function base64ToArrayBuffer(data: string) {
  const binaryString = window.atob(data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

function downloadPdf(base64EncodedData: string, name: string) {
  const arrBuffer = base64ToArrayBuffer(base64EncodedData);

  const file = new Blob([arrBuffer], {
    type: "application/pdf",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nav = window.navigator as unknown as any;
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (nav?.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(file);
    return;
  }

  const fileURL = URL.createObjectURL(file);

  const link = document.createElement("a");
  document.body.appendChild(link); //required in FF, optional for Chrome
  link.href = fileURL;
  link.download = `${name}.pdf`;
  link.click();
  window.URL.revokeObjectURL(fileURL);
  link.remove();
}

function openPdfInBrowser(base64EncodedData: string) {
  const arrBuffer = base64ToArrayBuffer(base64EncodedData);

  const file = new Blob([arrBuffer], {
    type: "application/pdf",
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nav = window.navigator as unknown as any;
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (nav?.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(file);
    return;
  }

  const fileURL = URL.createObjectURL(file);

  window.open(fileURL);
}

export { downloadPdf, openPdfInBrowser };
