import { Box, Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";
import { appointmentMeasurementCategoryT } from "GeneratedGraphQL/EnumTranslations";
import {
  AppointmentMeasurementCategory,
  OrganizationFilter,
  ProviderFilter,
} from "GeneratedGraphQL/SchemaAndOperations";
import { isEmpty } from "ramda";
import React, { ChangeEvent, ReactElement } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import EnumSelect from "Shared/EnumSelect";
import FlagMultiSelect from "Shared/Filters/FlagMultiSelect";
import OrganizationSelect from "Shared/Filters/OrganizationSelect";
import ProviderSelect from "Shared/Filters/ProviderSelect";
import {
  useQueryStringBooleanParameter,
  useQueryStringOptionalEnumParameter,
  useQueryStringOrganizationFilterParameter,
  useQueryStringProviderFilterParameter,
} from "Shared/QueryStringParameter";
import { FlagId } from "Lib/Ids";
import {
  MBC_REDESIGN_FLAG,
  WithoutFrontendFlag,
  useIsFrontendFlagEnabled,
} from "Contexts/FrontendFlagContext";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";

export type ScheduleFilters = {
  organization: OrganizationFilter | null;
  provider: ProviderFilter | null;
  flags: Array<FlagId> | null;
  category: AppointmentMeasurementCategory | null;
  testClients: boolean;
};

export type FilterProps = {
  setCategory: (category: AppointmentMeasurementCategory | null) => void;
  category: AppointmentMeasurementCategory | null;
  setProvider: (provider: ProviderFilter | null) => void;
  provider: ProviderFilter | null;
  setOrganization: (issue: OrganizationFilter | null) => void;
  organization: OrganizationFilter | null;
  filters: ScheduleFilters;
  testClients: boolean;
  setTestClients: (testClients: boolean) => void;
  flags: Array<FlagId> | null;
  setFlags: (flags: Array<FlagId> | null) => void;
};

type ScheduleFiltersProps = {
  filters: FilterProps;
};

// This currently does not return a query string flag filter because we haven't worked out how to make the
// serialization work.
function useScheduleFilters(defaultFilters: ScheduleFilters): FilterProps {
  const testClientsFromContext = useTestPatientViewability();
  const [testClients, setTestClients] = useQueryStringBooleanParameter(
    "testClients",
    defaultFilters.testClients
  );
  const shouldUseContextTestClients = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);

  const [category, setCategory] = useQueryStringOptionalEnumParameter<AppointmentMeasurementCategory>(
    "measurementCategory",
    AppointmentMeasurementCategory,
    defaultFilters.category,
    true
  );

  const [provider, setProvider] = useQueryStringProviderFilterParameter(
    "provider",
    defaultFilters.provider,
    true
  );
  const [organization, setOrganization] = useQueryStringOrganizationFilterParameter(
    "organization",
    defaultFilters.organization,
    true
  );

  const [flags, setFlags] = React.useState<Array<FlagId> | null>(defaultFilters.flags);

  return {
    testClients: shouldUseContextTestClients ? testClientsFromContext : testClients,
    setTestClients,
    category,
    setCategory,
    provider,
    setProvider,
    organization,
    setOrganization,
    flags,
    setFlags,
    filters: {
      testClients: shouldUseContextTestClients ? testClientsFromContext : testClients,
      category,
      provider,
      organization,
      flags,
    },
  };
}

const SelectContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(10),
}));

export function ScheduleFilters(props: ScheduleFiltersProps): ReactElement {
  const { t } = useTranslation(["common", "appointments", "enums"]);
  const { filters } = props;

  const onOrganizationChange = (newOrg: ScheduleFilters["organization"]) => filters.setOrganization(newOrg);
  const onProviderChange = (newProvider: ScheduleFilters["provider"]) => filters.setProvider(newProvider);
  const onFlagsChange = (newFlags: ScheduleFilters["flags"]) => {
    const flags = isEmpty(newFlags) ? null : newFlags;
    filters.setFlags(flags);
  };
  const onCategoryChange = (newValue: AppointmentMeasurementCategory) => filters.setCategory(newValue);
  const onTestClientsChange = (event: ChangeEvent<HTMLInputElement>) =>
    filters.setTestClients(event.target.checked);

  return (
    <Stack direction="row" spacing={1}>
      <SelectContainer>
        <OrganizationSelect value={filters.organization} setValue={onOrganizationChange} />
      </SelectContainer>
      <ProviderSelect value={filters.provider} setValue={onProviderChange} />
      <SelectContainer>
        <FlagMultiSelect value={filters.flags} setValue={onFlagsChange} />
      </SelectContainer>

      <SelectContainer>
        <EnumSelect
          optionsEnum={AppointmentMeasurementCategory}
          value={filters.category}
          onChange={onCategoryChange}
          title={t("appointments:measurementCategory.title")}
          sortFn={(a, b) => {
            return appointmentMeasurementCategoryT(a, t) > appointmentMeasurementCategoryT(b, t) ? 1 : -1;
          }}
          enumTrans={appointmentMeasurementCategoryT}
        />
      </SelectContainer>
      <WithoutFrontendFlag flagName={MBC_REDESIGN_FLAG}>
        <SelectContainer alignItems="center" display="flex">
          <FormControl>
            <FormControlLabel
              control={<Checkbox />}
              label={t("common:testClient")}
              checked={filters.testClients}
              onChange={onTestClientsChange}
            />
          </FormControl>
        </SelectContainer>
      </WithoutFrontendFlag>
    </Stack>
  );
}

export { useScheduleFilters };
