import {
  AcuteChangeCategory,
  ComputedValueValidity,
  DetailedScaleScoreFragment as RawDetailedScaleScore,
  ScaleThresholdClass,
  ScaleWithQuestionsFragment as RawScaleWithQuestions,
  SeverityCategory,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeComputedValueId, ScaleId } from "Lib/Ids";
import { find, justs } from "Lib/Utils";
import { Err, Maybe, Ok, Result } from "seidr";
import { SeveritySupportedValues } from "Shared/Severity/Severity";
import * as SeverityHistory from "Shared/Severity/SeverityHistory";
import { QuestionResponseSummary, toQuestionResponseSummary } from "./QuestionResponse";
import * as Trend from "./Trend";

// A CareEpisodeComputedValueDetails is a single data point (and sometimes a lack of a data point)
// of a ScaleScorerHistory. Essentially the score/result of a single survey
// filled out for a scale by a user for a specific ScaleScorer.
// TODO: remove this type as it's now purely the fragment
export type CareEpisodeComputedValueDetails = {
  __typename: "CareEpisodeComputedValue";
  id: CareEpisodeComputedValueId;
  targetDate: Date;
  date: Date;
  value: number | null;
  thresholdClass: ScaleThresholdClass | null;
  thresholdMnemonic: string | null;
  severityCategory: SeverityCategory;
  acuteChangeCategory: AcuteChangeCategory;
  trend: Trend.Trend;
  scorer: {
    __typename: "NumericalScaleScorer" | "CategoricalScaleScorer" | "UnscoredScaleScorer";
  };
  validity: ComputedValueValidity;
};

export type CareEpisodeNumericalComputedValueDetails = CareEpisodeComputedValueDetails & {
  value: number;
  scorer: { __typename: "NumericalScaleScorer" };
};

export type CareEpisodeCategoricalComputedValueDetails = CareEpisodeComputedValueDetails & {
  scorer: { __typename: "CategoricalScaleScorer" };
};

export type CareEpisodeUnscoredComputedValueDetails = CareEpisodeComputedValueDetails & {
  scorer: { __typename: "UnscoredScaleScorer" };
};

export type CareEpisodeInvalidComputedValueDetails = CareEpisodeComputedValueDetails & {
  validity: ComputedValueValidity.INVALID;
};

export type CareEpisodeNotAdministeredComputedValueDetails = CareEpisodeComputedValueDetails & {
  validity: ComputedValueValidity.NOT_ADMINISTERED;
};

// TODO: make this change after types align.
//export type CareEpisodeComputedValueDetails = CareEpisodeComputedValueDetailsFragment;

export type CareEpisodeComputedValueWithAnswers = CareEpisodeComputedValueDetails & {
  questionResponses: ReadonlyArray<QuestionResponseSummary>;
};

export type CareEpisodeComputedValueWithScaleId = CareEpisodeComputedValueWithAnswers & {
  scaleId: ScaleId;
};

function isCareEpisodeComputedValueDetails(
  value: SeveritySupportedValues | null
): value is CareEpisodeComputedValueDetails {
  return value !== null && value.__typename === "CareEpisodeComputedValue";
}

function isNumerical(
  value: CareEpisodeComputedValueDetails
): value is CareEpisodeNumericalComputedValueDetails {
  return value.scorer.__typename === "NumericalScaleScorer" && value.value !== null;
}

function isCategorical(
  value: CareEpisodeComputedValueDetails
): value is CareEpisodeCategoricalComputedValueDetails {
  return value.scorer.__typename === "CategoricalScaleScorer";
}

function isUnscored(
  value: CareEpisodeComputedValueDetails
): value is CareEpisodeUnscoredComputedValueDetails {
  return value.scorer.__typename === "UnscoredScaleScorer";
}

function isInvalid(value: CareEpisodeComputedValueDetails): value is CareEpisodeInvalidComputedValueDetails {
  return value.validity == ComputedValueValidity.INVALID;
}

function isNotAdministered(
  value: CareEpisodeComputedValueDetails
): value is CareEpisodeNotAdministeredComputedValueDetails {
  return value.validity == ComputedValueValidity.NOT_ADMINISTERED;
}

function switchCareEpisodeComputedValue<T>(
  value: CareEpisodeComputedValueDetails,
  numerical: (cv: CareEpisodeNumericalComputedValueDetails) => T,
  categorical: (cv: CareEpisodeCategoricalComputedValueDetails) => T,
  unscored: (cv: CareEpisodeUnscoredComputedValueDetails) => T,
  invalid: (cv: CareEpisodeInvalidComputedValueDetails) => T
): T {
  if (isNumerical(value)) {
    return numerical(value);
  } else if (isCategorical(value)) {
    return categorical(value);
  } else if (isUnscored(value)) {
    return unscored(value);
  } else if (isInvalid(value)) {
    return invalid(value);
  }

  // In theory this state is unreachable, but I can't get the compiler to acknowledge this because we can't switch on the
  // predicate narrowing functions
  throw new Error(`Runtime exception: Computed Value ${value.id} not of expected type`);
}

// -- Parsers -----------------------------------------------------------------

function parseDetailedScaleScore(
  rawScale: RawScaleWithQuestions,
  raw: RawDetailedScaleScore
): Result<Error, CareEpisodeComputedValueWithScaleId> {
  if (raw.value == null) return Err(new Error("Invalid score"));

  const questionResponses = justs(
    rawScale.questions.map((question) =>
      find((a) => a.questionId === question.id, raw.answers).map((answer) =>
        toQuestionResponseSummary({ ...answer, question })
      )
    )
  );
  const result: CareEpisodeComputedValueWithScaleId = {
    ...raw,
    scaleId: rawScale.id,
    questionResponses,
  };

  return Ok(result);
}

// -- Transformers ------------------------------------------------------------

function toSeverityValueCategorical(
  value: CareEpisodeCategoricalComputedValueDetails
): SeverityHistory.SeverityValue {
  return {
    ...value,
    date: value.targetDate,
    thresholdClass: value.thresholdClass || ScaleThresholdClass.UNKNOWN,
    thresholdMnemonic: Maybe.fromNullable(value.thresholdMnemonic),
  };
}

export {
  parseDetailedScaleScore,
  toSeverityValueCategorical,
  isCareEpisodeComputedValueDetails,
  isUnscored,
  isNumerical,
  isCategorical,
  isInvalid,
  isNotAdministered,
  switchCareEpisodeComputedValue,
};
