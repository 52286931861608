import { Badge, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import React, { ReactElement } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NavUserItem, UserMenuItem } from "./NavBar";
import { Link } from "MDS/Link";
import { Logout, Person } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { logout, sendLogout } from "AppSession/AppSession";
import { Variant } from "@mui/material/styles/createTypography";
import { usePiiLevel } from "Contexts/PiiLevelContext";

type NavUserMenuProps = {
  fontVariant: Variant;
  arrowSize: number;
  user: {
    name: string;
    lastSignIn: Date | null;
  };
  userBadge?: { count: number };
  userMenuItems: Array<UserMenuItem>;
  isMobile: boolean;
};

export function NavUserMenu(props: NavUserMenuProps): ReactElement | null {
  const { t } = useTranslation(["common", "general", "settings"]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sendLogout();
    logout("endSession");
  };

  const userName = usePiiLevel() === "full_anon" ? t("settings:piiLevel.anonUser") : props.user.name;

  // We'll want to build a set of all of the passed in menu items.
  const userMenuItems = props.userMenuItems.map((userMenuItem, index) => {
    // These are the child elements we'll have no matter what.
    const childElements = (
      <Stack direction="row">
        <ListItemIcon>{userMenuItem.icon}</ListItemIcon>
        {userMenuItem.label ? <ListItemText>{userMenuItem.label}</ListItemText> : null}
        {userMenuItem.additionalElement}
      </Stack>
    );

    const key = `${index}-${userMenuItem.label}-${userMenuItem.location}`;

    // We can have menu items that have actual links, or we can have menu items
    // that are not themselves links and we'll want to handle both cases here.
    if (userMenuItem.location) {
      return (
        <Link onClick={handleClose} key={key} to={userMenuItem.location} sx={{ textDecoration: "none" }}>
          <MenuItem>{childElements}</MenuItem>
        </Link>
      );
    }

    const onClick = userMenuItem.onClick
      ? () => {
          userMenuItem.onClick ? userMenuItem.onClick() : null;
          handleClose();
        }
      : undefined;

    // Now we can return our actual full MenuItem.
    return (
      <MenuItem onClick={onClick} key={key}>
        {childElements}
      </MenuItem>
    );
  });

  return (
    <>
      <NavUserItem variant={props.fontVariant} onClick={handleClick} style={{ cursor: "pointer" }}>
        <Stack direction="row" component={"span"}>
          <Badge
            invisible={props.userBadge === undefined}
            color={props.isMobile ? "secondary" : "primary"}
            badgeContent={props.userBadge?.count || 0}
          >
            {userName}
          </Badge>

          <ArrowDropDownIcon style={{ fontSize: props.arrowSize }} />
        </Stack>
      </NavUserItem>

      <Menu
        transitionDuration={0}
        elevation={2}
        variant="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <Link to="/provider/profile/edit" sx={{ textDecoration: "none" }}>
            <Stack direction="row">
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText>{t("general:navigation.user.profile")}</ListItemText>
            </Stack>
          </Link>
        </MenuItem>
        {/* We also want all of our previously defined menu items. */}
        {userMenuItems}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>{t("general:navigation.user.logout")}</ListItemText>
        </MenuItem>

        <MenuItem disabled>
          <Stack>
            <span>{t("general:navigation.user.lastSignIn")}</span>
            <span>{t("date.shortTime", { date: props.user.lastSignIn })}</span>
          </Stack>
        </MenuItem>
      </Menu>
    </>
  );
}
