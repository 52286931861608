import React from "react";

import { CareEpisodeDetail } from "Shared/CareEpisode";
import { ProviderSummary } from "Shared/Provider";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { DetailsWrapper } from "../DetailsWrapper";
import { DemographicInfo } from "./DemographicInfo";
import { Stack } from "@mui/material";
import { Legend } from "./Legend";
import { PatientConditionDetails } from "./PatientConditionModal";

type DemographicsProps = {
  patient: React.ComponentProps<typeof DemographicInfo>["patient"];
  careEpisode: CareEpisodeDetail;
  careTeam: ReadonlyArray<ProviderSummary>;
  patientConditions: ReadonlyArray<PatientConditionDetails>;
  feedbackReportContext: FeedbackReportContext;
};

export const DemographicsRoute = (props: DemographicsProps) => {
  return (
    <DetailsWrapper breadcrumbs={[]}>
      <Stack spacing={4}>
        <DemographicInfo
          patient={props.patient}
          careEpisode={props.careEpisode}
          careTeam={props.careTeam}
          patientConditions={props.patientConditions}
          activeInvitations={props.feedbackReportContext.activeInvitations}
          flatten={false}
        />
        <Legend />
      </Stack>
    </DetailsWrapper>
  );
};
