import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Skeleton,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { recurrenceFrequencyT } from "GeneratedGraphQL/EnumTranslations";
import { RecurrenceFrequency } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, PatientId, PatientSessionId, ProviderId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import Link from "MDS/Link";
import { CareEpisodeChip } from "Patients/PatientDetails/CareEpisodeChip";
import { ImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import EnumSelect from "Shared/EnumSelect";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import { Form, FormOverlay, useForm, useNumberField, useWrappedField } from "Shared/Form";
import { range } from "d3";
import { add } from "date-fns";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

export function AppointmentFormInputLoading(): ReactElement {
  return (
    <Stack spacing={1}>
      {/* 5 for the number of fields in the form. */}
      {range(5).map((i) => (
        <Skeleton key={i} />
      ))}
    </Stack>
  );
}

type AppointmentFormDetails = {
  startDate: Date;
  minutesDuration: number | null;
  providerId: ProviderId;
  recurrenceFrequency?: RecurrenceFrequency | null;
  recurrenceEndDate?: Date | null;
};

type AppointmentFormDefaults = {
  startDate: Date | null;
  minutesDuration: number | null;
  providerId: ProviderId | null;
  recurrenceFrequency?: RecurrenceFrequency | null;
  recurrenceEndDate?: Date | null;
};

type AppointmentFormProps<ResponseType> = {
  mode: "create" | "edit";
  defaults: AppointmentFormDefaults;
  onSuccess?: (response: ResponseType) => void;
  remoteData: MutationRemoteDataResult<ResponseType>;
  mutation: (fields: AppointmentFormDetails) => void;
  errorMessage: string;
  careEpisodeId: CareEpisodeId;
  patientId: PatientId;
  // In order to create the appropriate edit link for existing appointments, we need the patient session id.
  patientSessionId?: PatientSessionId;
};

export function AppointmentForm<ResponseType>(props: AppointmentFormProps<ResponseType>): ReactElement {
  const { t } = useTranslation(["patients", "common"]);

  const [recurrence, setRecurrence] = useState<boolean>(!!props.defaults.recurrenceFrequency);

  const fields = {
    startDate: useWrappedField({ default: props.defaults.startDate, required: true }),
    minutesDuration: useNumberField({ default: props.defaults.minutesDuration || 30, required: true }),
    providerId: useWrappedField<ProviderId>({
      default: props.defaults.providerId || undefined,
      required: true,
    }),
    recurrenceFrequency: useWrappedField<RecurrenceFrequency | null>({
      default: props.defaults.recurrenceFrequency,
      required: false,
    }),
    recurrenceEndDate: useWrappedField({ default: props.defaults.recurrenceEndDate, required: false }),
  };

  const form = useForm({
    id: "edit-appointment-form",
    fields: fields,
    onSuccess: props.onSuccess,
    remoteData: props.remoteData,
    submit: () => {
      props.mutation({
        startDate: assertNonNull(fields.startDate.value),
        // this will actually come out typed but we can't make the type system appreciate that.
        minutesDuration: (fields.minutesDuration.value || null) as unknown as number | null,
        providerId: assertNonNull(fields.providerId.value),
        recurrenceFrequency: recurrence ? fields.recurrenceFrequency.value : undefined,
        recurrenceEndDate: recurrence ? fields.recurrenceEndDate.value : undefined,
      });
    },
  });

  const recurrenceControls = recurrence ? (
    <>
      <EnumSelect
        title={t("patients:appointments.createForm.fields.recurrenceFrequency.label")}
        value={fields.recurrenceFrequency.value || null}
        onChange={fields.recurrenceFrequency.onChange}
        optionsEnum={RecurrenceFrequency}
        enumTrans={recurrenceFrequencyT}
      />
      <DatePicker
        label={t("patients:appointments.createForm.fields.recurrenceEndDate.label")}
        format={t("patients:appointments.createForm.fields.recurrenceEndDate.format")}
        value={fields.recurrenceEndDate.value}
        onChange={fields.recurrenceEndDate.onChange}
      />
    </>
  ) : null;

  const oldUrl =
    props.mode === "edit" && props.patientSessionId
      ? `/provider/patients/${props.patientId}/sessions/${props.patientSessionId}/edit`
      : `/provider/patients/${props.patientId}/sessions/create`;

  // We want to display one of the following messages:
  //  - you're adding a recurrence
  //  - you're removing a recurrence
  //  - this is the same recurrence and they will be updated.
  let recurrenceChange = null;

  if (recurrence) {
    if (!props.defaults.recurrenceFrequency) {
      recurrenceChange = t("patients:appointments.createForm.addRecurrence");
    } else if (
      props.defaults.recurrenceFrequency === fields.recurrenceFrequency.value &&
      props.defaults.recurrenceEndDate === fields.recurrenceEndDate.value
    ) {
      recurrenceChange = t("patients:appointments.createForm.sameRecurrence");
    } else {
      recurrenceChange = t("patients:appointments.createForm.updateRecurrence");
    }
  } else {
    if (props.defaults.recurrenceFrequency) {
      recurrenceChange = t("patients:appointments.createForm.removeRecurrence");
    }
  }

  return (
    <>
      <DialogContent>
        <Form onSubmit={form.onSubmit} id={form.id}>
          <FormOverlay response={props.remoteData} errorMessage={props.errorMessage} />
          <Stack direction="column" spacing={1} sx={{ paddingTop: "1rem" }}>
            <div>
              {t(
                props.mode === "create"
                  ? "patients:appointments.createForm.createForCareEpisode"
                  : "patients:appointments.createForm.editForCareEpisode"
              )}
              <CareEpisodeChip patientId={props.patientId} careEpisodeId={props.careEpisodeId} />
            </div>
            <DateTimePicker
              label={t("patients:appointments.createForm.fields.startDate.label")}
              format={t("patients:appointments.createForm.fields.startDate.format")}
              value={fields.startDate.value}
              onChange={fields.startDate.onChange}
            />
            <TextField
              label={t("patients:appointments.createForm.fields.minutesDuration.label")}
              autoFocus
              value={fields.minutesDuration.value}
              onChange={fields.minutesDuration.onChange}
              error={fields.minutesDuration.error}
              type="number"
              InputProps={{ inputProps: { min: 1 } }}
            />
            <ProviderSelectSingle
              label={t("patients:appointments.createForm.fields.provider.label")}
              value={fields.providerId.value || null}
              setValue={fields.providerId.onChange}
              error={fields.providerId.error}
              helperText={fields.providerId.helperText}
            />
            <FormControl>
              <FormControlLabel
                control={<Checkbox />}
                label={t("patients:appointments.createForm.fields.recurrence.label")}
                checked={recurrence}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setRecurrence(event.target.checked);
                  // Once we show the form, set some defaults
                  fields.recurrenceFrequency.onChange(RecurrenceFrequency.WEEKLY);
                  fields.recurrenceEndDate.onChange(add(new Date(), { months: 3 }));
                }}
              />
            </FormControl>
            {recurrenceControls}
            <ul>
              <li>{t("patients:appointments.createForm.patientSessionExplanation")}</li>
              <li>{t("patients:appointments.createForm.planningExplanation")}</li>
              {recurrenceChange ? <li>{recurrenceChange}</li> : null}
            </ul>
            <p>
              <Link to={oldUrl}>{t("patients:appointments.createForm.planManually")}</Link>
            </p>
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <ImpersonatingDisabledButton>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            form={form.id}
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t(
              props.mode === "create"
                ? "patients:appointments.createForm.actions.create"
                : "patients:appointments.createForm.actions.edit",
              { count: recurrence ? 2 : 1 }
            )}
          </ButtonWithSpinner>
        </ImpersonatingDisabledButton>
      </DialogActions>
    </>
  );
}
