import { apolloMutationHookWrapper } from "Api/GraphQL";
import { MBC_REDESIGN_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";
import { useCareEpisodeUpdateMeasurementAllowedMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ImmediateSettingsSwitch from "Shared/ImmediateSettingSwitch";

type CareEpisodeMeasurementAllowedSwitchProps = {
  id: CareEpisodeId;
  measurementAllowed: boolean;
};

export function CareEpisodeMeasurementAllowedSwitch(
  props: CareEpisodeMeasurementAllowedSwitchProps
): ReactElement {
  const { t } = useTranslation(["settings"]);
  const label = t(
    useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG)
      ? "settings:measurementAllowedSwitchRedesign.label"
      : "settings:measurementAllowedSwitch.label"
  );

  const [updateMeasurementAllowed, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsCareEpisodeUpdateMeasurementAllowed,
    useCareEpisodeUpdateMeasurementAllowedMutation({
      refetchQueries: refetchQueries("careEpisodes"),
    })
  );
  const partialUpdateMeasurementAllowed = (allowed: boolean) => {
    updateMeasurementAllowed({
      variables: {
        input: {
          careEpisodeId: props.id,
          measurementAllowed: allowed,
        },
      },
    });
  };
  return (
    <ImmediateSettingsSwitch
      value={props.measurementAllowed}
      label={label}
      description={t("settings:measurementAllowedSwitch.careEpisodeTooltip")}
      remoteData={remoteData}
      query={partialUpdateMeasurementAllowed}
    />
  );
}

export default CareEpisodeMeasurementAllowedSwitch;
