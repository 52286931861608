import { FormControl, FormHelperText, MenuItem, SelectProps, Theme, useTheme } from "@mui/material";
import { clinicalAlertValueT } from "GeneratedGraphQL/EnumTranslations";
import { ClinicalAlertValue } from "GeneratedGraphQL/SchemaAndOperations";
import { ClearableSelect } from "Shared/ClearableSelect";
import { ParseKeys, TFunction } from "i18next";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { alertColor } from "./CareEpisodeClinicalAlert";
import { ScaleChipBase } from "./ScaleChipBase";

type ClinicalAlertValueSelectProps = {
  title: ParseKeys;
  onChange: (newValue: ClinicalAlertValue | null) => void;
  value: ClinicalAlertValue | null;
  helperText?: string | null;
  fullWidth?: boolean;
} & Omit<SelectProps, "onChange" | "value">;

function riskMenuItem(alertValue: ClinicalAlertValue, theme: Theme, t: TFunction<["enums"]>) {
  return (
    <MenuItem value={alertValue.toString()} key={alertValue.toString()}>
      <ScaleChipBase
        color={alertColor(alertValue, theme).offset}
        backgroundColor={alertColor(alertValue, theme).primary}
      >
        {clinicalAlertValueT(alertValue, t)}
      </ScaleChipBase>
    </MenuItem>
  );
}

export default function ClinicalAlertValueSelect(props: ClinicalAlertValueSelectProps): ReactElement {
  const { title, value, helperText, defaultValue, sx, ...passthrough } = props;
  const fullWidth = props.fullWidth ?? true;
  const theme = useTheme();
  const { t } = useTranslation(["enums"]);
  const highlightColor = theme.palette.filterHighlight;

  const highlight = typeof defaultValue !== "undefined" && defaultValue !== value;

  const mergedSx = highlight
    ? [
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "4px",
            borderColor: highlightColor,
          },
        },
      ]
    : sx;

  const items = [
    riskMenuItem(ClinicalAlertValue.NOT_SUPPORTED, theme, t),
    riskMenuItem(ClinicalAlertValue.NO_ALERT, theme, t),
    riskMenuItem(ClinicalAlertValue.ALERT, theme, t),
  ];

  // Passing null into a controlled component doesn't work well, use empty string instead.
  const valueOrBlank = value === null ? "" : value;

  return (
    <FormControl error={props.error} fullWidth={fullWidth}>
      <ClearableSelect
        fullWidth={fullWidth}
        label={title}
        value={valueOrBlank}
        {...passthrough}
        sx={mergedSx}
        onChange={props.onChange}
        defaultValue={""}
        error={props.error}
      >
        {items}
      </ClearableSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}
