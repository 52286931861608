import { TreeItem, TreeItemClasses, TreeItemProps, useTreeItem } from "@mui/lab";
import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { ReactElement } from "react";

type TreeItemComponentProps = {
  classes: Partial<TreeItemClasses> | undefined;
  className?: string | undefined;
  label?: React.ReactNode;
  nodeId: string;
  icon?: React.ReactNode;
  expansionIcon?: React.ReactNode;
  displayIcon?: React.ReactNode;
};

// Largely adapted from https://mui.com/material-ui/react-tree-view/#contentcomponent-prop.
// This lets us reach into the guts of the Mui TreeItem and rewire the event handlers so that expand/collapse events
// only happen when you click the icon, and select events only happen when you click the label. This makes it easier to
// open/close parts of the tree without changing what you've selected.
const OnlyExpandOnIconClickTreeItemComponent = React.forwardRef(
  (props: TreeItemComponentProps, ref: React.ForwardedRef<unknown>) => {
    const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

    const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
      useTreeItem(nodeId);

    const moreClasses: Record<string, boolean> = {};
    if (classes?.expanded) {
      moreClasses[classes.expanded] = expanded;
    }
    if (classes?.selected) {
      moreClasses[classes.selected] = selected;
    }
    if (classes?.focused) {
      moreClasses[classes.focused] = focused;
    }
    if (classes?.disabled) {
      moreClasses[classes.disabled] = disabled;
    }

    const icon = iconProp || expansionIcon || displayIcon;

    return (
      <div
        className={clsx(className, classes?.root, moreClasses)}
        onMouseDown={preventSelection}
        ref={ref as React.ForwardedRef<HTMLDivElement>}
      >
        <div onClick={handleExpansion} className={classes?.iconContainer}>
          {icon}
        </div>
        <Typography onClick={handleSelection} component="div" className={classes?.label}>
          {label}
        </Typography>
      </div>
    );
  }
);

export function OnlyExpandOnIconClickTreeItem(props: TreeItemProps): ReactElement {
  return (
    <TreeItem {...props} ContentComponent={OnlyExpandOnIconClickTreeItemComponent}>
      {props.children}
    </TreeItem>
  );
}
