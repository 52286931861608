import React, { useEffect, useState } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  EntityType,
  MetricParams,
  ReportEntityTreeNode,
  SortDirection,
  useOutcomesTransientMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { EntitySummary, entityTreeNodeToParams } from "Entities/EntityPath";
import * as NEL from "Lib/NonEmptyList";
import { ZoomIn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { metricLabelGeneratorFromParams } from "./OutcomesMetricHelpers";
import { ensureMetricParams } from "./OutcomesFormHelpers";
import { WithNonNullFields } from "type-utils";
import { CollectionSelect, EligibleCount, MetricValueColumn } from "./OutcomesMetricBreakdown";

type OutcomesTransientMetricBreakdownInnerProps = WithNonNullFields<
  OutcomesTransientMetricBreakdownProps,
  "entityTreeNodeParams" | "metricParams"
> & {
  collection: EntityType;
  limit: number;
};

function MetricValue(props: {
  row: ReportEntityTreeNode;
  labelGenerator: (data: { value: number | null }) => string;
  metricParams: MetricParams;
}) {
  const { metricParams, labelGenerator } = props;
  const datum = props.row.summaryData.find((item) => item.name === "value");

  return (
    <MetricValueColumn
      value={datum?.value}
      labelGenerator={labelGenerator}
      timeBasedAggregationType={metricParams.payload.timeBased?.timeBasedAggregationType}
    />
  );
}

function valueForSorting(row: Pick<ReportEntityTreeNode, "summaryData">, field: string): number {
  const item = row.summaryData.find((d) => d.name === field);

  return item?.value || 0;
}

type SortFields = "name" | "value" | "eligibleRows" | null;

function OutcomesTransientMetricBreakdownInner(props: OutcomesTransientMetricBreakdownInnerProps) {
  const { t } = useTranslation(["outcomes"]);
  const { metricParams, startDate, endDate, entityTreeNodeParams } = props;

  const [sort, setSort] = useState<SortFields>("value");
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.ASC);

  const labelGenerator = metricLabelGeneratorFromParams(metricParams, t);

  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: {
      collection: { node: entityTreeNodeParams, collection: props.collection },
    },
    sort,
    sortDirection,
    limit: props.limit,
    period: {
      dateRange: {
        min: startDate,
        max: endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };

  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesTransientMetricSummaryDataQuery({
      variables: {
        drilldownMetric: drilldownMetric,
        transientMetricData: ensureMetricParams(metricParams),
        scaleScorerId: null,
      },
    })
  );

  const columns: Array<GridColDef<ReportEntityTreeNode>> = [
    {
      field: "name",
      headerName: t("outcomes:breakdown.tableColumns.entity"),
      sortable: true,
      flex: 2,
      valueGetter: (_value, row) => row.entityTreeNode.entity.name,
      renderCell: (params) => {
        return <EntitySummary entity={params.row.entityTreeNode.entity} />;
      },
    },
    {
      field: "value",
      headerName: t("outcomes:breakdown.tableColumns.result"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => valueForSorting(row, "value"),
      renderCell: (params) => {
        return (
          <MetricValue row={params.row} labelGenerator={labelGenerator} metricParams={props.metricParams} />
        );
      },
    },
    {
      field: "eligibleRows",
      headerName: t("outcomes:breakdown.tableColumns.eligibleRows"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => valueForSorting(row, "eligibleRows"),
      renderCell: (params) => {
        return <EligibleCount row={params.row} />;
      },
    },
    {
      field: "actions",
      headerName: t("outcomes:breakdown.tableColumns.actions"),
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <ZoomIn
            onClick={() => props.setEntityTreeNodeParams(entityTreeNodeToParams(params.row.entityTreeNode))}
          />
        );
      },
    },
  ];

  const content = remoteData.caseOf({
    Failure: (e) => <ErrorMessage message={e.message} />,
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Success: (data) => {
      return (
        <>
          <DataGrid
            columns={columns}
            rows={data.outcomesTransientMetricSummaryData.reportEntityTreeNodes}
            getRowId={(row) => {
              return row.entityTreeNode.path;
            }}
            hideFooterPagination={true}
            sortingMode={"server"}
            onSortModelChange={(model) => {
              return NEL.fromArray(model).caseOf({
                Nothing: () => {
                  setSort(null);
                },
                Just: (models) => {
                  const model = NEL.head(models);
                  setSort(model.field as unknown as SortFields);
                  setSortDirection(model.sort === "asc" ? SortDirection.ASC : SortDirection.DESC);
                },
              });
            }}
          />
        </>
      );
    },
  });

  return <Paper>{content}</Paper>;
}

type OutcomesTransientMetricBreakdownProps = {
  entityTreeNodeParams: EntityTreeNodeParams | null;
  setEntityTreeNodeParams: (id: EntityTreeNodeParams) => void;
  startDate: Date;
  endDate: Date;
  metricParams: MetricParams | null;
};

function OutcomesTransientMetricBreakdown(props: OutcomesTransientMetricBreakdownProps) {
  const [collection, setCollection] = useState<EntityType | null>(null);
  const [limit, setLimit] = useState<number>(15);
  const { t } = useTranslation(["outcomes"]);
  const { entityTreeNodeParams, metricParams } = props;

  // When you select a new entity id, reset the collection as we don't know what's relevant.
  useEffect(() => {
    setCollection(null);
  }, [entityTreeNodeParams]);

  const handleLimitChange = (event: SelectChangeEvent<number>) => {
    const number = parseInt(event.target.value.toString());

    if (!isNaN(number)) {
      setLimit(number);
    }
  };

  if (metricParams === null || entityTreeNodeParams === null) {
    return (
      <Card>
        <CardHeader title={t("outcomes:breakdown.header")} />
        <CardContent>{t("outcomes:breakdown.invalidParams")}</CardContent>
      </Card>
    );
  }

  const content = collection ? (
    <OutcomesTransientMetricBreakdownInner
      {...props}
      entityTreeNodeParams={entityTreeNodeParams} // You have to pass this back in to infer the non null
      metricParams={metricParams}
      limit={limit}
      collection={collection}
    />
  ) : null;
  return (
    <Card>
      <CardHeader title={t("outcomes:breakdown.header")} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={1}>
            <CollectionSelect
              parentNode={entityTreeNodeParams}
              items={[
                EntityType.PROVIDER,
                EntityType.ORGANIZATION,
                EntityType.TREATMENT_SERVICE,
                EntityType.TREATMENT_TRACK,
                EntityType.PANEL,
              ]}
              onChange={setCollection}
              value={collection}
            />
            <FormControl>
              <Select value={limit} onChange={handleLimitChange}>
                <MenuItem value={15}>{t("outcomes:breakdown.firstItems", { number: 15 })}</MenuItem>
                <MenuItem value={25}>{t("outcomes:breakdown.firstItems", { number: 25 })}</MenuItem>
                <MenuItem value={50}>{t("outcomes:breakdown.firstItems", { number: 50 })}</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {content}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OutcomesTransientMetricBreakdown;
