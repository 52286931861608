import { Card, CardContent, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollButton } from "CollaborativeCare/Enrollment/EnrollButton";
import { DischargeDialogButton } from "CollaborativeCare/Enrollment/DischargeDialog";
import { IntakeButton } from "CollaborativeCare/Enrollment/IntakeButton";
import { RequestConsultButton } from "CollaborativeCare/CaseConsult/RequestConsult";
import { LazyPrepareConsultButton } from "CollaborativeCare/CaseConsult/CaseConsultReview/PrepareConsult";
import { QuickTrackButton } from "CollaborativeCare/QuickTrackButton";
import { useTranslation } from "react-i18next";

// We want all the buttons to be a fixed size. This controls that fixed size.
const BUTTON_WIDTH = "7.5em";

type PatientActionProps = {
  patient: PickTypename<Patient, "id" | "name" | "dob">;
};
export function PatientActionsCard(props: PatientActionProps): ReactElement {
  return (
    <Card>
      <CardContent>
        <Grid container columns={6} spacing={1}>
          <Grid item lg={6} xs={6}>
            <Stack direction="column" spacing={0.5}>
              <TasksList patient={props.patient} />
              <EnrollmentList patient={props.patient} />
              <ConsultList patient={props.patient} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

type ActionListSubheaderProps = {
  title: string;
};
function ActionListSubheader(props: ActionListSubheaderProps): ReactElement {
  return (
    <Typography variant="h2" marginBottom="0.65em">
      {props.title}
    </Typography>
  );
}

function TasksList(props: PatientActionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <List subheader={<ActionListSubheader title={t("collaborativeCare:patientDetails.actions.tasks")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          <QuickTrackButton patientId={props.patient.id} />
        </Stack>
      </ListItem>
    </List>
  );
}

function EnrollmentList(props: PatientActionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <List
      subheader={<ActionListSubheader title={t("collaborativeCare:patientDetails.actions.enrollment")} />}
    >
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          <EnrollButton patient={props.patient} buttonMinWidth={BUTTON_WIDTH} />
          <IntakeButton patient={props.patient} buttonMinWidth={BUTTON_WIDTH} />
          <DischargeDialogButton patient={props.patient} buttonMinWidth={BUTTON_WIDTH} />
        </Stack>
      </ListItem>
    </List>
  );
}

function ConsultList(props: PatientActionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <List subheader={<ActionListSubheader title={t("collaborativeCare:patientDetails.actions.consult")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          <RequestConsultButton patient={props.patient} buttonMinWidth={BUTTON_WIDTH} />
          <LazyPrepareConsultButton patient={props.patient} variant="contained" />
        </Stack>
      </ListItem>
    </List>
  );
}
