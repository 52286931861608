import { buildChartTheme } from "@visx/xychart";
import { assertNonNull } from "Lib/Utils";

// These are the Mirah brand colors, ordered and filtered to make sense for graphs etc.
const GRAPH_COLORS = [
  "#637C83", // Amanda's unofficial light blue
  "#AC9474", // Pear
  "#CDB296", // Driftwood
  "#183B44", // Dark Harbor
  "#2C2C22", // Kelp
  "#BE8445", // Golden Hour
  "#632E16", // Rose Wood
  "#431F0F", // Garnet
];

// Taken from the original theme at
// https://github.com/airbnb/visx/blob/master/packages/visx-xychart/src/theme/colors.ts
const grayColors = [
  "#f8f9fa",
  "#f1f3f5",
  "#e9ecef",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#868e96",
  "#495057",
  "#343a40",
  "#212529",
];

export const DEFAULT_CHART_THEME = buildChartTheme({
  backgroundColor: "#fff",
  colors: GRAPH_COLORS,
  tickLength: 4,
  svgLabelSmall: {
    fill: grayColors[7],
  },
  svgLabelBig: {
    fill: grayColors[9],
  },
  gridColor: assertNonNull(grayColors[5]),
  gridColorDark: assertNonNull(grayColors[9]),
});
