import React, { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisodeProviderRelationship,
  Provider,
  useCollaborativeCareCareManagersQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import { PickTypename } from "type-utils";
import { useTranslation } from "react-i18next";
import { ProviderId } from "Lib/Ids";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";

type CareManagerPatientSearchFilterProps = {
  value: ProviderId | null;
  setValue: (value: ProviderId | null) => void;
  defaultValue?: ProviderId | null;
};
export function CareManagerPatientSearchFilter(props: CareManagerPatientSearchFilterProps): ReactElement {
  const { t } = useTranslation(["common", "enums"]);
  const { remoteData } = apolloQueryHookWrapper(useCollaborativeCareCareManagersQuery());

  return remoteData.caseOf({
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Failure: () => <ErrorMessage message={t("common:remoteData.failure")} />,
    Success: (data) => {
      if (data.collaborativeCareCareManagers) {
        return (
          <CareManagerPatientSearchFilterElement
            careManagers={data.collaborativeCareCareManagers}
            value={props.value}
            setValue={props.setValue}
            defaultValue={props.defaultValue}
          />
        );
      }
      return <ErrorMessage message={t("common:remoteData.failure")} />;
    },
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
  });
}

type CareManagerPatientSearchFilterElementProps = CareManagerPatientSearchFilterProps & {
  careManagers: ReadonlyArray<PickTypename<Provider, "id" | "name">>;
};
function CareManagerPatientSearchFilterElement(
  props: CareManagerPatientSearchFilterElementProps
): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Box width="15em">
      <ProviderSelectSingle
        label={t("collaborativeCare:patientList.search.careManagerLabel")}
        value={props.value}
        setValue={props.setValue}
        defaultValue={props.defaultValue}
        defaultRelationship={[CareEpisodeProviderRelationship.CARE_MANAGER]}
      />
    </Box>
  );
}
