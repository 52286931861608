import { Box, Button } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  CreateCareEpisodeResult,
  Patient,
  useCreateCareEpisodeMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { CareEpisodeForm } from "./CareEpisodeForm";
import { useNavigate } from "react-router-dom";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

type CreateCareEpisodeButtonProps = {
  buttonMinWidth?: string;
  patient: Pick<Patient, "id" | "name">;
};

export function CreateCareEpisodeButton(props: CreateCareEpisodeButtonProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <CreateCareEpisodeDialog open={open} onClose={() => setOpen(false)} patient={props.patient} />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <ReadOnlyOrImpersonatingDisabledButton>
        <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
          {t("careEpisodes:editDialog.create")}
        </Button>
        {dialog}
      </ReadOnlyOrImpersonatingDisabledButton>
    </Box>
  );
}

type CreateCareEpisodeDialogProps = {
  patient: Pick<Patient, "id" | "name">;
  open: boolean;
  onClose: () => void;
};

function CreateCareEpisodeDialog(props: CreateCareEpisodeDialogProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const navigate = useNavigate();
  const [today] = useState<Date>(new Date());

  const [createCareEpisode, { remoteData: mutationRemoteData }] = apolloMutationHookWrapper(
    (response) => response.createCareEpisode,
    useCreateCareEpisodeMutation({ refetchQueries: ["CareEpisodeDetails"] })
  );

  const onSuccess = (response: CreateCareEpisodeResult) => {
    // Use the relative url to navigate directly to the patient.
    // We may find ourselves in either the patient or the care episode page. Either way we have a relative route available.
    navigate(`care-episodes-new/${response.careEpisode.id}`);
  };

  const title = t("careEpisodes:editDialog.createWithName", { name: props.patient.name });
  const content = (
    <CareEpisodeForm
      mode="create"
      defaults={{
        periodStart: today,
      }}
      onSuccess={onSuccess}
      remoteData={mutationRemoteData}
      mutation={(fields) =>
        createCareEpisode({
          variables: {
            careEpisode: {
              patientId: props.patient.id,
              periodStart: fields.periodStart,
              organizationId: fields.organizationId,
              treatmentServiceId: fields.treatmentServiceId,
            },
          },
        })
      }
      errorMessage={t("careEpisodes:editDialog.editError")}
    />
  );

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={title}
      stopBackdropClose={false}
      dialogWidth="50%"
    >
      {content}
    </ResponsiveDialog>
  );
}
