import * as Id from "Lib/Id";
import { Result, Err, Ok, Maybe, Just, Nothing } from "seidr";
import * as AppContext from "AppSession/AppContext";
import * as AppSession from "AppSession/AppSession";
import {
  CreateAnalyticsEventInput,
  useCreateAnalyticsEventMutationMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { InstituteId, PatientId, ProviderId, RelatedPersonId } from "Lib/Ids";

// AnalyticsEvent vs MetricsEvent explained:
//
//  AnalyticsEvent represents the event we want to send to the server.  There
//  are certain fields that we always want to exist and we don't want the call
//  to configure. Like the eventDate or the eventUuid for example.
//  MetricsEvent serves to be the subset of event data that the consumers of
//  Metric.track can configure

type AnalyticsEvent = {
  eventUuid: Id.Id<"AnalyticsEvent">;
  eventName: string;
  sessionId: AppSession.AppSessionId;
  eventDate: Date;
  url: string;
  instituteId: InstituteId;
  data: Maybe<unknown>;
  route: Maybe<string>;
  patientId: Maybe<PatientId>;
  providerId: Maybe<ProviderId>;
  relatedPersonId: Maybe<RelatedPersonId>;
  userId: Maybe<string>;
};

type MetricsEvent = Pick<AnalyticsEvent, "data" | "patientId" | "providerId" | "relatedPersonId" | "route">;

// -- Internal Helpers --------------------------------------------------------

function getInstituteId(session: AppSession.AppSession): Maybe<InstituteId> {
  return session.caseOf({
    Uninitialized: () => Nothing(),
    Unauthenticated: (appContext, _s) => Just(AppContext.getInstituteId(appContext.institute)),
    Authenticated: (appContext, _u, _s) => Just(AppContext.getInstituteId(appContext.institute)),
  });
}

function metricsEventToAnalyticsEvent(
  session: AppSession.AppSession,
  eventName: string,
  event: MetricsEvent
): Result<string, CreateAnalyticsEventInput> {
  return getInstituteId(session).caseOf({
    Just: (instituteId) => {
      return Ok({
        ...event,
        eventName,
        userId: AppSession.getCurrentUserId().getOrElse(null),
        data: event.data.getOrElse(null),

        route: event.route.getOrElse(null),
        patientId: event.patientId.getOrElse(null),
        providerId: event.providerId.getOrElse(null),
        relatedPersonId: event.relatedPersonId.getOrElse(null),

        eventUuid: Id.toString(Id.generate<"AnalyticsEvent">()),
        sessionId: Id.toString(AppSession.getAppSessionId(session)),
        url: window.location.pathname,
        eventDate: new Date(),
        instituteId,
      });
    },
    Nothing: () => Err("AppSession.Uninitialized without an instituteId (required for metric events)"),
  });
}

// -- API ---------------------------------------------------------------------

export type TrackingEventData = { eventName: string; eventData?: MetricsEvent };

function useTracking(): (eventData: TrackingEventData) => void {
  const [createTrackingEvent] = useCreateAnalyticsEventMutationMutation();

  return (event: TrackingEventData) =>
    track((x) => createTrackingEvent({ variables: { event: x } }), event.eventName, event.eventData);
}

function track(
  tracker: (event: CreateAnalyticsEventInput) => void,
  eventName: string,
  event: MetricsEvent = {
    data: Nothing(),
    route: Nothing(),
    patientId: Nothing(),
    providerId: Nothing(),
    relatedPersonId: Nothing(),
  }
): void {
  const session = AppSession.getAppSession();

  metricsEventToAnalyticsEvent(session, eventName, event).caseOf({
    Ok: (analyticsEvent) => {
      tracker(analyticsEvent);
    },
    Err: (errorMessage) => console.warn(`Metrics tracking failed: ${errorMessage}`),
  });
}

const Test = {
  getInstituteId,
  metricsEventToAnalyticsEvent,
};

export { useTracking, Test };
