const report = {
  title: "Feedback Report",
  startedOn: "Started on $t(common:date.medium)",
  latestSessionWithNumber: "Latest Session: #{{ sessionNumber }} ($t(common:date.medium))",
  latestSessionNoNumber: "Latest Session: $t(common:date.medium)",
  acuteChange: {
    acuteChange: "Acute Change",
    worsening: "Acute Worsening",
    invalid: "Invalid",
    stable: "No Acute Change",
    notSpecified: "Not Specified",
    notSupported: "Not Supported",
    improvement: "Acute Improvement",
  },
  trend: {
    trend: "Trend",
    worsening: "Worsening",
    stable: "Stable",
    improving: "Improving",
    firstScore: "First Score",
    thisScore: "This Score",
  },
  validity: {
    invalid: "Invalid",
    notAdministered: "Not Administered",
  },
  bounds: "Scores range from {{ bottomValue }} to {{ topValue }}",
  boundsWithInterpretation:
    "Scores range from {{bottomValue}} to {{topValue}} where {{bottomValue}} means {{bottomInterpretation}} and {{topValue}} means {{topInterpretation}}",

  otherScales: "Other Scales",
  heatmapNoHistory: "Administered for a future session",
  noScoringInformation:
    "This scale has no scoring information. You can view the individual item responses by clicking on the dates of administration.",

  errors: {
    careEpisodeNoAdministrations: "There are not yet any administrations",
    invalidUrl: "Invalid URL",
    missingRisk: "Could not find Risk",
    noPatient: "No patient in care unit",
  },

  sections: {
    overview: "Overview",
    details: "Details",
    compare: "Compare",
    treatmentResponse: "Treatment Response",
    goalsProblems: "Goals / Problems",
    responses: "{{ scale }} Responses: $t(common:date.medium)",
    alertDetails: "{{ alertTitle }} - $t(common:date.medium)",
    scoredExternally: "This observation was scored by a system other than Mirah.",
    noResponses: "This observation has no item-level response information as it was generated externally.",
  },

  breadcrumbs: {
    patientInfo: "Patient Info",
    measureHistory: "Measure History",
    riskHistory: "Risk History",
    responses: "Responses",
    addGoal: "Add Goal / Problem",
  },

  legend: {
    severity: {
      title: "Severity Colors",
      notMeasured: "Not measured this week",
      low: "Doing well, check it out",
      moderate: "Pay attention",
      severe: "Stop, there might be a problem",
    },
  },

  goalsProblems: {
    noGoals: "There are no Goals/Problems",
    addGoal: "Add Goals/Problems",
    addGoalShort: "Add",
  },

  responses: {
    notAsked: "Not Asked",
    skipped: "Skipped",
    scheduled: "Scheduled for $t(common:date.medium)",
    answered: "Answered on $t(common:date.medium)",
  },

  forms: {
    addGoal: {
      failure: "The treatment goal could not be saved",
      goalType: "Goal Type",
      respondent: "Respondent",
      goalText: "Enter Text",
      goalTextError: "Please enter the description of your goal",
      submit: "Create {{ goalTitle }}",
    },
  },

  action: "Actions",

  actions: {
    generateClinicalNote: "Generate Clinical Note",
    addCareManagementTask: "Add Care Management Task",
    showOldReport: "Show Old Report",
    changeMeasurementPlan: "Change Measurement Plan",
  },

  noProgressNote: "There is no progress note available",

  progressNote: {
    title: "Progress Note",
    copy: "Copy",
    copied: "Copied",
  },

  demographics: {
    age: "{{age}} years old",
    ageShort: "{{age}} yrs",
    ageUnknown: "Unknown Age",
    sex: "Sex: {{ sex }}",
    sexUnknown: "Sex: Unknown",
    gender: "Gender: {{ gender }}",
    pronouns: "Pronouns: {{ pronouns }}",
    noPatient: "Failed to find patient",
  },

  patientCondition: {
    status: "Status",
    onsetDate: "Onset Date",
    abatementDate: "Abatement Date",
    unknownDate: "Unknown",
    currentDate: "Still Active",
  },

  allRespondents: "All Respondents",

  norse: {
    symptomsTitle: "CAMS Symptoms",
    strengthsTitle: "CAMS Strengths",
  },
};

export default report;
