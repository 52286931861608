const billing = {
  algorithmList: {
    pageTitle: "Billing Configuration",
    status: "Status",
    createAlgorithm: "Create Algorithm",
    columns: {
      name: "Name",
      actions: "Actions",
      entityId: "Applies to",
      startDate: "Start Date",
      endDate: "End Date",
      codes: "Codes",
    },
  },
  algorithmDetails: {
    pageTitle: "Billing Algorithm Details",
    entity: "Applies To",
    startDate: "Start Date",
    endDate: "End Date",
    status: "Status",
    explanation: {
      title: "How this works",
      algorithmTitle: "Which rule is selected",
      algorithmDescription:
        "The rules are evaluated in order from top to bottom. The first rule that matches all criteria will be selected as the winner. You should therefore put the rule that generates the most revenue at the top: for example, a 99492 with several units of 99494. If all rules fail, nothing will be generated for billing.",
      careUnits: "Care Units",
      careUnitsDetails:
        "Each rule can be assigned a value for 'Care Units', which corresponds to both the amount of care and financial reward for the care provider. Mirah staff can help you assign values to this field if you are unsure.",
      rvus: "RVUs",
      rvuDetails:
        "Each code can be assigned a number of RVUs which can then be tracked to approximate revenue",
    },
    rules: {
      title: "Rules",
    },
    codes: {
      title: "Billing Codes",
    },
  },
  algorithm: {
    endDateActive: "Active Now",
  },
  rules: {
    rule: "Rule {{number}}",
    billableMinutes: "Billable Minutes",
    anyAssessmentCompleted: {
      title: "Requires Assessment Completed",
      required: "Complete Assessment",
      pass: "An assessment has been completed since treatment start",
      fail: "An assessment has not been completed since treatment start",
      ignore: "There was no requirement to complete an assessment",
    },
    minimumMinutes: {
      title: "Minimum Minutes Required",
      required: "+ {{minutes}} minutes",
      pass: "At least {{minimumMinutes}} minutes have been recorded in the month (currently {{currentMinutes}})",
      fail: "You need at least {{minimumMinutes}} minutes recorded in the month (currently {{currentMinutes}})",
      ignore: "There was no minimum threshold of minutes",
    },
    notBilled99492Or99493Already: {
      title: "Requires no 99492 or 99493 billed previously",
      required: "Cannot be rebilled",
      pass: "Other codes such as 99492 have not been billed previously",
      fail: "Other codes such as 99492 have been billed previously",
      ignore: "This code can be billed without regard to previous billed codes",
    },
    mustBeInitialEncounter: {
      title: "Must be billed as initial encounter",
      required: "Cannot be rebilled",
      pass: "No initial encounter codes have been billed yet",
      fail: "Other initial encounter codes such as 99492 have been billed previously",
      ignore: "This code does not need to be the initial encounter",
    },
    mustBeSubsequentEncounter: {
      title: "Must be billed as subsequent encounter",
      required: "Can be rebilled",
      pass: "An initial encounter has already been billed",
      fail: "Can only be billed once an initial encounter has already been billed",
      ignore: "This code does not require an initial encounter to have been billed",
    },
    oncePerCalendarYear: {
      title: "Can only be billed once per calendar year",
      required: "Can only be billed once per year",
      pass: "Has not been billed this calendar year",
      fail: "Has already sbilled this calendar year",
      ignore: "This rule does not require a code be billed only once per year",
    },
    monthsWithoutAnyBilling: {
      title: "Can only be billed after a number of months without any billing",
      required: "Can only be billed after a number of months without any billing",
      pass: "Enough months have elapsed since anything was billed",
      fail: "Not enough months have elapsed since something was billed",
      ignore: "This rule does not require any months to elapse without billing",
    },
  },
  codes: {
    avgRatePerUnit: {
      title: "Avg $ per unit (blended)",
    },
    valueUnits: {
      title: "Care Units",
    },
    rvusPerUnit: {
      title: "RVUs per Unit",
    },
    initialEncounter: {
      title: "Qualifies as initial encounter",
    },
  },
  prediction: {
    pageTitle: "Estimated Billing",
    showValueBy: "Show value as",
    fields: {
      yearAndMonth: "Year and Month",
      searchClientsLabel: "Search...",
    },
    excludeDischarged: "Hide Discharged",
    excludeDischargedExplanation:
      "When checked, patients that were discharged before the end of the month will be not be shown",
    columns: {
      patientStatus: "Status",
      patient: "Patient Name",
      mrn: "MRN",
      dob: "DoB",
      estimatedRate: "Estimated Rate",
      currentMinutes: "Minutes",
      monthOfEnrollment: "Month",
      careManager: "Care Manager",
      pcp: "PCP",
      winningResultRvus: {
        full: "Current RVUs",
        grouped: "RVUs",
      },
      nextResultRvus: {
        full: "Next RVUs",
        grouped: "RVUs",
      },
      winningResultBillableMinutes: {
        full: "Current Billable Minutes",
        grouped: "Billable Minutes",
      },
      nextResultBillableMinutes: {
        full: "Next Billable Minutes",
        grouped: "Billable Minutes",
      },
      winningResultValueUnits: {
        full: "Current Care Units",
        grouped: "Care Units",
      },
      nextResultValueUnits: {
        full: "Next Care Units",
        grouped: "Care Units",
      },
      winningResultEstimatedRate: {
        full: "Current Est $",
        grouped: "Est $",
      },
      nextResultEstimatedRate: {
        full: "Next Est $",
        grouped: "Est $",
      },
      winningResultBilledUnits: {
        full: "Current Billing Codes",
        grouped: "Codes",
      },
      nextResultBilledUnits: {
        full: "Next Billing Codes",
        grouped: "Codes",
      },
      nextResultRateDiffVsCurrentWinner: {
        full: "Next Billing Additional $",
        grouped: "+$",
      },
      nextResultRvuDiffVsCurrentWinner: {
        full: "Next Billing Additional RVUs",
        grouped: "+RVU",
      },
      nextResultBillableMinutesDiffVsCurrentWinner: {
        full: "Next Billing Additional Minutes",
        grouped: "+Minutes",
      },
      nextResultValueUnitDiffVsCurrentWinner: {
        full: "Next Billing Additional Care Units",
        grouped: "+Care Units",
      },
      nextResultSteps: {
        full: "Required steps to next",
        grouped: "Required Steps",
      },
      rvuPowerVsCurrentWinner: {
        full: "RVU impact to next ",
        grouped: "RVU Impact",
      },
      billableMinutesPowerVsCurrentWinner: {
        full: "Billing Minutes impact to next ",
        grouped: "Billing Minutes Impact",
      },
      valueUnitPowerVsCurrentWinner: {
        full: "Care Unit impact to next",
        grouped: "Care Unit impact",
      },
      ratePowerVsCurrentWinner: {
        full: "$ impact to next",
        grouped: "$ Impact",
      },
      organization: "Organization",
    },
    columnGroups: {
      winningResult: "Billable Now",
      nextResult: "Next Billing Target",
    },
    powerBar: {
      low: {
        tooltip: "This is a lower impact activity. It requires effort to reach the next threshold",
        chip: "Low",
      },
      medium: {
        tooltip: "This is a medium impact activity. It requires moderate effort to reach the next threshold",
        chip: "Medium",
      },
      high: {
        tooltip: "This is a high impact activity. It requires little effort to reach the next threshold",
        chip: "High",
      },
    },
  },
  predictionWinner: {
    title: "Billing Details",
    titleWithDetails: "{{patientName}} -  $t(common:date.monthYear)",
    algorithmDetails: "Algorithm Details",
    algorithmExplanation:
      "Rules are evaluated from top to bottom. The first rule to meet requirements will be suggested for billing",
    patientSummary: {
      title: "Patient Details",
      patientName: "Name",
      dob: "Date of Birth",
      mrn: "MRN",
    },
    summary: {
      title: "Enrollment Summary",
      enrolledAt: "Enrolled on",
      monthOfTreatment: "Month Of Treatment",
      minutesMonth: "Minutes ($t(common:date.monthYear))",
      firstValidatedAssessmentCompleted: "First Assessment Completed",
      lastValidatedAssessmentCompletedThisMonth: "Last Assessment Completed $t(common:date.monthYear)",
    },
    currentWinner: {
      title: "Billable Now",
      missing: "No billing targets have been hit this month",
    },
    nextUp: {
      title: "Next Billing Target",
      missing:
        "There are no more billing targets to hit. This may be because you have maxed out billing, or some codes have become ineligible",
      codes: "Codes",
      rvus: "RVUs",
      billableMinutes: "Billable Minutes",
      expectedRate: "Estimated $",
      valueUnits: "Value Units",
      algorithm: "Algorithm",
      showAlgorithm: "Show Details",
      steps: "Required Steps",
    },
    status: {
      active: {
        title: "Currently Active",
        description: "This rule has currently been selected as the best fitting",
      },
      requirementsNotMet: {
        title: "Requirements Not Met",
        description: "The requirements for this rule have not yet been met",
      },
      disqualified: {
        title: "Ineligible",
        description: "This rule is not eligible for billing as it has failed a prerequisite",
      },
      bettered: {
        title: "No longer optimal",
        description:
          "This rule met the criteria for billing, but a higher priority rule has succeeded and will be chosen instead",
      },
      skipped: {
        title: "Skipped",
        description:
          "This rule has not met required criteria, but a higher priority rule has succeeded so this will never be chosen.",
      },
      next: {
        title: "Next Up",
        description: "This is the next rule to be billed once its requirements have been met.",
      },
    },
  },
  dashboard: {
    title: "Billing Insights",
    viewBillingAlgorithm: "View Billing Algorithm Details",
    noAlgo: "No billing algorithms have been configured for this period",
    filters: {
      title: "Filters",
      explanationClosed: "Expend to select which rules and months are visible",
      explanation:
        "Select which rules and months to view. Deselecting an item will remove it from all graphs except the top left total.",
      month: "Month {{month}}",
      monthPlus: "Month {{month}}+",
      monthHelpText: "Show only patients who have been enrolled for the following period:",
    },
  },
  effort: {
    title: "Highest Impact Minutes",
    subheader: "How doing high impact tasks affects your target",
    minutes: "Minutes",
    clickForDetails: "Click for details on what actions to take",
    additionalMinutes: "Additional minutes required",
    legends: {
      rate: "Estimated Additional Billing",
      rvus: "Estimated Additional RVUs",
      billableMinutes: "Estimated Additional Minutes",
      valueUnits: "Estimated Additional Value Units",
    },
    ofTarget: "of target",
    noDataFound: "No data is available",
    withinXMinutes: "in {{minutes}} minutes",
  },
  stats: {
    title: "Stats by rule",
    subheader: "See a breakdown of value provided by each billing rule",
    notAvailable: "There are no stats available",
    series: {
      historical: "Prior 6 Months Average",
      previous: "Previous Month",
      current: "This Month",
    },
  },
};
export default billing;
