import { ComputedValueValidity } from "GeneratedGraphQL/SchemaAndOperations";
import * as NEL from "Lib/NonEmptyList";
import {
  CareEpisodeCategoricalComputedValueDetails,
  CareEpisodeComputedValueDetails,
  CareEpisodeNumericalComputedValueDetails,
  CareEpisodeUnscoredComputedValueDetails,
} from "Shared/Scale/CareEpisodeComputedValueDetails";
import { GoalAnswer } from "Shared/Scale/Goal";

// This type represents supported types that look something like 'severity values with dates'
// Note that the tactic here is not to transform data into a common format,
// but instead keep the original document intact, and allow a set of functions
// which exports the data fields directly across the common interface.
export type SeveritySupportedValues =
  | CareEpisodeNumericalComputedValueDetails
  | CareEpisodeCategoricalComputedValueDetails
  | CareEpisodeUnscoredComputedValueDetails
  | CareEpisodeComputedValueDetails
  | GoalAnswer;

// This function used to do some logic to return different date fields for different kinds of values but we don't
// actually want that anymore. I'm leaving it in place to avoid having to update the many callers.
function getDate(value: SeveritySupportedValues) {
  return value.date;
}

type FirstPrevious<T extends SeveritySupportedValues> = {
  first: T | null;
  previous: T | null;
};

function firstPrevious<T extends SeveritySupportedValues>(
  scores: ReadonlyArray<T>,
  target: T
): FirstPrevious<T> {
  const includedScores = scores.slice(0, scores.indexOf(target));

  return NEL.fromArray(includedScores).caseOf<FirstPrevious<T>>({
    Nothing: () => ({
      first: null,
      previous: null,
    }),
    Just: (includedScores) => ({
      first: NEL.head(includedScores),
      previous: NEL.last(includedScores),
    }),
  });
}

type FirstPreviousLast<T extends SeveritySupportedValues> = {
  first: T | null;
  previous: T | null;
  last: T | null;
};

function firstPreviousLast<T extends SeveritySupportedValues>(
  scores: ReadonlyArray<T>
): FirstPreviousLast<T> {
  return NEL.fromArray(scores).caseOf<FirstPreviousLast<T>>({
    Just: (scoresNotEmpty) => ({
      last: NEL.last(scoresNotEmpty),
      ...firstPrevious(scores, NEL.last(scoresNotEmpty)),
    }),
    Nothing: () => ({ last: null, first: null, previous: null }),
  });
}

function isInvalid(value: SeveritySupportedValues) {
  // Goals don't have a validity so they never count as invalid.
  return "validity" in value && value.validity == ComputedValueValidity.INVALID;
}

export { getDate, firstPrevious, firstPreviousLast, isInvalid };
