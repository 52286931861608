import React, { ReactElement } from "react";
import { Stack, IconButton, Link as MUILink } from "@mui/material";
import { Expand } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { usePrevious } from "Lib/Hooks";
import { useIsDesktop } from "Shared/Responsive";

type Panes = "left" | "right";
type PaneMode = Panes | "both";

function otherPane(pane: Panes): Panes {
  return pane == "left" ? "right" : "left";
}

const COLLAPSED_PANE_WIDTH = "5rem";
const COLLAPSE_TRANSITION_DURATION = "500ms";

const CollapseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(0.5),
  right: theme.spacing(0.5),
  borderRadius: `0 ${theme.spacing(0.5)} 0 0`,

  "& *": {
    transformOrigin: "center",
    // My intuition tells me this should be 90deg, but for some reason this is the number that works.
    transform: "rotate(45deg)",
  },
}));

const CollapseContainer = styled("div")<{ width: string; top: number }>(({ width, top }) => ({
  position: "relative",
  width: width,
  transition: `width ${COLLAPSE_TRANSITION_DURATION}, top ${COLLAPSE_TRANSITION_DURATION}`,
  overflowX: "hidden",
  top: `${top}px`,
}));

// If we don't hide the contents of a collapsed pane, the browser will still try to lay them out behind the placeholder
// element, and because we're giving them very little space the placeholder will end up much taller than it should be.
// This hides the pane contents so that the placeholder is merely as tall as the other pane. This has the side effect
// that the contents disappear immediately upon clicking the collapse button, instead of appearing to slide out of
// from. The ideal solution would be to use a transition like `transition: display 0s 500ms` so that the change doesn't
// happen until the transition is over and the elements are already hidden. Unfortunately display isn't a transitionable
// property, so that does nothing. We could track a separate boolean for this state in the component and use useState
// and useEffect to swap it on a 500ms delay, but that's more work than I think this behavior deserves right now.
const HiddenWhileCollapsed = styled("div")<{ open: boolean }>(({ open }) => ({
  display: open ? "auto" : "none",
}));

const SlidingBox = styled("div", { shouldForwardProp: (prop) => prop !== "visible" })<{
  visible: boolean;
  pane: Panes;
}>(({ theme, visible, pane }) => ({
  position: "absolute",
  borderRadius: theme.spacing(0.5),
  top: theme.spacing(0.5),
  bottom: 0,

  // The positioning logic here is a bit gnarly, but the constraints of styled components mean I'm stuck with ternarys
  // here. The high-level goal is to have the placeholder blocks for the collapsed text slide in from the side as the
  // collapse transition happens. In order to do that we have each placeholder box start positioned just outside the
  // container - for the left pane the placeholder is just off the right edge and the right pane's is just off the left
  // edge. This is the `-${COLLAPSED_PANE_WIDTH}` term. Then to slide it in, we set the position to be just inside the
  // edge, which is the theme.spacing(0.5) term. For the attribute that isn't being transitioned, we set it to always
  // "auto".
  left: pane == "left" ? "auto" : visible ? theme.spacing(0.5) : `-${COLLAPSED_PANE_WIDTH}`,
  right: pane == "left" ? (visible ? theme.spacing(0.5) : `-${COLLAPSED_PANE_WIDTH}`) : "auto",
  transition: `left ${COLLAPSE_TRANSITION_DURATION}, right ${COLLAPSE_TRANSITION_DURATION}`,

  backgroundColor: theme.palette.report.shading.background,
  // Subtract some spacing here to account for the gutter between cards
  width: `calc(${COLLAPSED_PANE_WIDTH} - ${theme.spacing(1)})`,
  cursor: "pointer",

  "&:hover": {
    backgroundColor: theme.palette.report.shading.focus,
  },
}));

const RotatedText = styled("div")<{ pane: Panes }>(({ theme, pane }) => ({
  transform: `rotate(${pane == "left" ? -90 : 90}deg)`,

  position: "absolute",
  top: "50%",
  // Because we're rotating in different directions for each pane, this positioning has to be asymmetrical to account
  // for how the font uses the top vs bottom extent of its bounding box.
  left: pane == "left" ? "-50%" : "-25%",

  fontSize: theme.typography.h1.fontSize,
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: theme.spacing(0.25),
}));

type CollapsedPlaceholderProps = {
  visible: boolean;
  pane: Panes;
  onClick: () => void;
};

const CollapsedPlaceholder = (props: React.PropsWithChildren<CollapsedPlaceholderProps>) => {
  return (
    <SlidingBox visible={props.visible} pane={props.pane} onClick={props.onClick}>
      <RotatedText pane={props.pane}>{props.children}</RotatedText>
    </SlidingBox>
  );
};

type CollapsiblePaneProps = {
  pane: Panes;
  mode: PaneMode;
  setMode: React.Dispatch<React.SetStateAction<PaneMode>>;
  title: string;
  maxHeight: number;
  top: number;
};

const CollapsiblePane = (props: React.PropsWithChildren<CollapsiblePaneProps>) => {
  const { pane, mode, setMode, title, top } = props;

  const open = mode == "both" || pane == mode;
  const targetMode = mode == "both" ? pane : "both";
  const width =
    mode == "both" ? "50%" : mode == pane ? `calc(100% - ${COLLAPSED_PANE_WIDTH})` : COLLAPSED_PANE_WIDTH;

  const button =
    mode == pane ? null : (
      <CollapseIconButton onClick={() => setMode(targetMode)}>
        <Expand />
      </CollapseIconButton>
    );

  return (
    <CollapseContainer width={width} top={top}>
      <HiddenWhileCollapsed open={open}>{props.children}</HiddenWhileCollapsed>
      <CollapsedPlaceholder visible={!open} pane={pane} onClick={() => setMode("both")}>
        {title}
      </CollapsedPlaceholder>
      {button}
    </CollapseContainer>
  );
};

const TopRightContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: theme.spacing(0.5),
}));

// We want to put some absolutely positioned elements inside a stack, so we gotta set its position to relative so that
// it counts as the most recently positioned parent.
const PositionedStack = styled(Stack)(() => ({
  position: "relative",
}));

type TwoPaneProps = {
  leftPane: ReactElement;
  rightPane: ReactElement;
  leftTitle: string;
  rightTitle: string;
  maxHeight: number;
  topOffset: number;
  ensureOpenOnRouteChange?: Panes;
};

export const TwoPaneLayout = (props: TwoPaneProps) => {
  const horizontalLayout = useIsDesktop();

  const [mode, setMode] = React.useState<PaneMode>("both");

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const [rightPaneOffset, setRightPaneOffset] = React.useState(0);

  const checkedSetMode = (mode: PaneMode) => {
    // Having the placeholder for the pane float down in the middle of the page looks very weird, so does having the
    // pane be down the page and the placeholder up a the top. If we're collapsing something just force it back up.
    if (mode !== "both") {
      setRightPaneOffset(0);
    }

    setMode(mode);
  };

  if (
    props.ensureOpenOnRouteChange &&
    location !== prevLocation &&
    mode === otherPane(props.ensureOpenOnRouteChange)
  ) {
    checkedSetMode("both");
  }

  const desiredRightPaneOffset = Math.max(window.pageYOffset - props.topOffset, 0);
  if (location !== prevLocation && rightPaneOffset !== desiredRightPaneOffset) {
    setRightPaneOffset(desiredRightPaneOffset);
  }

  const bringToTop =
    rightPaneOffset < 48 ? null : (
      <TopRightContainer>
        <MUILink onClick={() => setRightPaneOffset(0)}>Bring to top</MUILink>
      </TopRightContainer>
    );

  if (horizontalLayout) {
    return (
      <PositionedStack direction="row">
        {bringToTop}
        <CollapsiblePane
          pane="left"
          mode={mode}
          setMode={checkedSetMode}
          title={props.leftTitle}
          maxHeight={props.maxHeight}
          top={0}
        >
          {props.leftPane}
        </CollapsiblePane>
        <CollapsiblePane
          pane="right"
          mode={mode}
          setMode={checkedSetMode}
          title={props.rightTitle}
          maxHeight={props.maxHeight}
          top={rightPaneOffset}
        >
          {props.rightPane}
        </CollapsiblePane>
      </PositionedStack>
    );
  } else {
    return (
      <Stack direction="column" spacing={2}>
        {props.leftPane}
        {props.rightPane}
      </Stack>
    );
  }
};
