import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { Money } from "GeneratedGraphQL/SchemaAndOperations";
import { ChangeEvent } from "react";

export type MoneyDetails = Pick<Money, "cents" | "currency">;

export function DisplayMoney(props: { money: MoneyDetails }) {
  return formatMoney(props.money);
}

export function formatMoney(money: MoneyDetails, maximumFractionDigits = 2) {
  return (money.cents / 100.0).toLocaleString("en-US", {
    style: "currency",
    currency: money.currency,
    maximumFractionDigits,
  });
}

type MoneyTextFieldProps = Omit<TextFieldProps, "value" | "setValue" | "prefix" | "type"> & {
  value: string | number | undefined;
  onChange: (newValue: number | string | undefined) => void;
};

export function MoneyTextField(props: MoneyTextFieldProps) {
  const { value, onChange, ...passthrough } = props;

  // We have to convert from cents to $
  const transformedValue = typeof value === "number" ? value / 100.0 : value;
  const updateValue = (event: ChangeEvent<HTMLInputElement>) => {
    const parsed = Number.parseFloat(event.target.value);
    if (Number.isNaN(parsed)) {
      onChange(event.target.value);
    } else {
      onChange(parsed);
    }
  };

  return (
    <TextField
      {...passthrough}
      type="number"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      value={transformedValue}
      onChange={updateValue}
    />
  );
}
