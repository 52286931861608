import { CurrentInstituteDetailsFragment, EntityType } from "GeneratedGraphQL/SchemaAndOperations";
import { AllowedFeatures } from "./CurrentInstituteContext";

const featureMap: Partial<Record<EntityType, AllowedFeatures>> = {
  [EntityType.TREATMENT_SERVICE]: "enableTreatmentServices",
  [EntityType.PRIMARY_CARE_PHYSICIAN]: "enableCollaborativeCare",
  [EntityType.CARE_MANAGER]: "enableCollaborativeCare",
  [EntityType.LOCATION]: "enableLocations",
};

// Only some entities are hierarchies and allow selection of themselves beneath.
const hierarchyMap: Partial<Record<EntityType, boolean>> = {
  [EntityType.ORGANIZATION]: true,
  [EntityType.TREATMENT_SERVICE]: true,
};

// Some entities, once selected, it makes no sense to allow you to select a different entity beneath it. For example,
// if you have a care episode that already fixes the organization and treatment service.
const forbiddenMap: Partial<Record<EntityType, ReadonlyArray<EntityType>>> = {
  [EntityType.CARE_EPISODE]: [EntityType.PATIENT, EntityType.ORGANIZATION, EntityType.TREATMENT_SERVICE],
  [EntityType.PATIENT_SESSION]: [
    EntityType.CARE_EPISODE,
    EntityType.PATIENT,
    EntityType.ORGANIZATION,
    EntityType.TREATMENT_SERVICE,
  ],
};

function allowedEntityTypes(
  entityTypes: ReadonlyArray<EntityType>,
  parentEntityType: EntityType,
  featureSet: CurrentInstituteDetailsFragment["featureSet"],
  groupsEnabled: boolean
) {
  return entityTypes.filter((entity) => {
    if (entity === EntityType.INSTITUTE_GROUP) {
      return groupsEnabled;
    }

    const requiredFeature = featureMap[entity];

    // Don't allow features that are turned off.
    if (requiredFeature && !featureSet[requiredFeature]) {
      return false;
    }

    // Don't show the parent unless it's a hiearchy.
    if (entity === parentEntityType && !hierarchyMap[parentEntityType]) {
      return false;
    }

    // Restrict particular items that don't make sense.
    if (forbiddenMap[entity]?.includes(entity)) {
      return false;
    }

    return true;
  });
}

export { allowedEntityTypes };
