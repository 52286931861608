import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EhrLink,
  Institute,
  IntegrationStatusDate,
  useInstituteIntegrationReportQuery,
  useUpdateEhrLinkNotesMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import Link from "MDS/Link";
import React, { ReactElement, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import NoteIcon from "@mui/icons-material/Note";
import { v4 as uuidv4 } from "uuid";
import { EhrLinkId, InstituteId } from "Lib/Ids";
import { humanize } from "Lib/Utils";
import { format, startOfDay, sub } from "date-fns";

type InstituteIntegrationReportHookWrapperProps = Record<string, never>;
export default function InstituteIntegrationReportHookWrapper(
  _: InstituteIntegrationReportHookWrapperProps
): ReactElement {
  const [startDate, setStartDate] = useState(sub(startOfDay(new Date()), { days: 6 }));
  const onHandleChangeStartDate = (newValue: Date | null) => {
    if (newValue) {
      setStartDate(newValue);
    }
  };

  const [endDate, setEndDate] = useState(startOfDay(new Date()));
  const onHandleChangeEndDate = (newValue: Date | null) => {
    if (newValue) {
      setEndDate(newValue);
    }
  };

  const { remoteData } = apolloQueryHookWrapper(
    useInstituteIntegrationReportQuery({
      variables: {
        startDate: startDate,
        endDate: endDate,
      },
    })
  );

  const statuses = remoteData.caseOf({
    NotAsked: () => <Loading />,
    Loading: () => <Loading />,
    Failure: () => <Error />,
    Success: (result) => {
      if (result.integrationStatuses) {
        return <InstituteIntegrationReportComponent integrationStatuses={result.integrationStatuses} />;
      }
      return <Error />;
    },
  });

  return (
    <Page browserTitle="Integration Status Report">
      <Card>
        <CardHeader title="Integration Status Report" />
        <CardContent>
          <DesktopDatePicker
            label="Start Date"
            format="MM/dd/yyyy"
            value={startDate}
            onChange={onHandleChangeStartDate}
          />
          <DesktopDatePicker
            label="End Date"
            format="MM/dd/yyyy"
            value={endDate}
            onChange={onHandleChangeEndDate}
          />
          {statuses}
        </CardContent>
      </Card>
    </Page>
  );
}

// This type is really an IntegrationStatus, but we're only ever looking
// at Pick's on child keys, so it doesn't even show up in the types.
type InstituteIntegrationReportComponentProps = {
  integrationStatuses: ReadonlyArray<{
    institute: Pick<Institute, "id" | "name" | "customerSupportManager">;
    ehrConnection: Pick<EhrLink, "id" | "connectionType" | "connectionTypeClass" | "notes">;
    dates: ReadonlyArray<Pick<IntegrationStatusDate, "date" | "success" | "total">>;
  }>;
};
function InstituteIntegrationReportComponent(props: InstituteIntegrationReportComponentProps): ReactElement {
  // We're going to be using a datagrid, so we need to do a lot of setup first.
  // This first part is all of the cell renderers, which we need to make our column definitions.

  const dataGridInstituteLinkRenderer = (params: GridRenderCellParams<RowDataType>) => {
    return <InspectorLink text={params.row.institute} url={params.row.instituteInspectorUrl} />;
  };

  const dataGridConnectionClassLinkRenderer = (params: GridRenderCellParams<RowDataType>) => {
    return <InspectorLink text={params.row.connectionClass} url={params.row.ehrConnectionInspectorUrl} />;
  };

  const dataGridConnectionTypeLinkRenderer = (params: GridRenderCellParams<RowDataType>) => {
    return <InspectorLink text={params.row.connectionType} url={params.row.ehrConnectionInspectorUrl} />;
  };

  const dataGridNotesRenderer = (params: GridRenderCellParams<RowDataType>) => {
    return <Notes notes={params.row.notes} ehrLinkId={params.row.ehrLinkId} />;
  };

  const dataGridDatesRenderer = (params: GridRenderCellParams<RowDataType>) => {
    const datesCells = params.row.dates.map((date: IntegrationStatusDate) => {
      // We're just going to map the success/total numbers to a background color.
      let backgroundColor = "grey";
      if (date.success > 0) {
        backgroundColor = "green";
      } else if (date.total > 0) {
        backgroundColor = "red";
      }
      // Because timezones suck so hard and javascript doesn't support actual dates well,
      // what we're going to do is take our date object and instead of doing anything smart with it,
      // we're going to create a new date in the local timezone with the exact same date as our UTC date.
      const localDate = new Date(date.date.getUTCFullYear(), date.date.getUTCMonth(), date.date.getUTCDate());

      const tooltipText = `Success: ${date.success} / Total: ${date.total}`;

      return (
        <Box
          key={uuidv4()}
          sx={{ backgroundColor: backgroundColor, color: "white" }}
          minWidth="5em"
          minHeight="2em"
          display="flex"
        >
          <Tooltip title={tooltipText}>
            <Typography margin="auto">{format(localDate, "MM-dd")}</Typography>
          </Tooltip>
        </Box>
      );
    });
    return (
      <Box overflow="scroll">
        <Stack direction="row" spacing={0.1}>
          {datesCells}
        </Stack>
      </Box>
    );
  };

  // OK! Now that we have set up all of the cell renderers, we can set up the actual column definitions.
  const columns: Array<GridColDef<RowDataType>> = [
    { field: "institute", headerName: "Institute", width: 250, renderCell: dataGridInstituteLinkRenderer },
    {
      field: "connectionClass",
      headerName: "Class",
      width: 80,
      renderCell: dataGridConnectionClassLinkRenderer,
    },
    {
      field: "connectionType",
      headerName: "Type",
      width: 120,
      renderCell: dataGridConnectionTypeLinkRenderer,
    },
    {
      field: "customerSupportManager",
      headerName: "CSM",
      width: 200,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 90,
      renderCell: dataGridNotesRenderer,
    },
    {
      field: "dates",
      headerName: "Dates",
      minWidth: 400,
      flex: 1,
      sortable: false,
      renderCell: dataGridDatesRenderer,
    },
  ];

  type RowDataType = {
    id: InstituteId;
    institute: string;
    instituteInspectorUrl: string;
    connectionClass: string;
    connectionType: string;
    ehrConnectionInspectorUrl: string;
    customerSupportManager: string | null;
    notes: string;
    ehrLinkId: EhrLinkId;
    dates: Array<Pick<IntegrationStatusDate, "date" | "success" | "total">>;
  };

  // Now we need to actually supply the row data. There's more row fields than columns,
  // because we're always accessing the row data to draw columns rather than attempting to use a single cell value.
  const rows: ReadonlyArray<RowDataType> = props.integrationStatuses.map((integrationStatus) => {
    const instituteInspectorUrl = `/app/ops/inspector/class/Institute/id/${integrationStatus.institute.id}`;
    const ehrConnectionInspectorUrl = `/app/ops/inspector/class/Integrations::EhrConnection/id/${integrationStatus.ehrConnection.id}`;

    return {
      id: integrationStatus.institute.id, // Datagrid seems to require an id.
      institute: integrationStatus.institute.name,
      instituteInspectorUrl: instituteInspectorUrl,
      connectionClass: humanize(integrationStatus.ehrConnection.connectionTypeClass),
      connectionType: humanize(integrationStatus.ehrConnection.connectionType),
      ehrConnectionInspectorUrl: ehrConnectionInspectorUrl,
      customerSupportManager: integrationStatus.institute.customerSupportManager,
      notes: integrationStatus.ehrConnection.notes || "No notes",
      ehrLinkId: integrationStatus.ehrConnection.id,
      dates: [...integrationStatus.dates].reverse(), // We've gotten feedback to have the latest dates first.
    };
  });

  // The focus stuff turns off this weird outlining that the datagrid does by default.
  // It just looks bad.
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      autoHeight
      disableRowSelectionOnClick={true}
      hideFooter={true}
      sx={{
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
          outline: "none",
        },
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "institute", sort: "asc" }],
        },
      }}
    />
  );
}

type InspectorLinkParams = {
  text: string;
  url: string;
};
function InspectorLink(params: InspectorLinkParams): ReactElement {
  if (params.text && params.url) {
    return (
      <Link to={params.url} style={{ textDecoration: "none" }} target="_blank">
        {params.text}
      </Link>
    );
  }
  return <CellError />;
}

function Loading(): ReactElement {
  return <h1>Loading</h1>;
}

function Error(): ReactElement {
  return <h1>Error</h1>;
}
function CellError(): ReactElement {
  return <Typography>Error</Typography>;
}

type NotesParams = {
  notes: string;
  ehrLinkId: EhrLinkId;
};
function Notes(params: NotesParams): ReactElement {
  // Mutation to actually update notes back on the server.
  // This API is only callable by internal users.
  const [updateEhrLinkNotes] = apolloMutationHookWrapper(
    (data) => data.updateEhrLinkNotes,
    useUpdateEhrLinkNotesMutation()
  );

  // Lastly, we can set up the default notes value. Since we're fully updating the notes
  // via state, there's no need for a refresh query here. It's possible we get into
  // a weird state from an error, but who cares, it's internal.
  const [notes, setNotes] = useState<string>(params.notes);

  // We're handling editing text as a modal, so this is for that.
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    setOpen(false);
    updateEhrLinkNotes({
      variables: {
        input: {
          ehrLinkId: params.ehrLinkId,
          notes: notes,
        },
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNotes(event.target.value);
  };

  return (
    <Box>
      <Tooltip title={notes}>
        <IconButton onClick={handleOpen}>
          <NoteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            id="notes"
            variant="standard"
            value={notes}
            sx={{ width: 400, height: 400 }}
            onChange={handleChange}
            minRows={17}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit}>Submit</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export {
  InstituteIntegrationReportHookWrapper as InstituteIntegrationReport,
  InstituteIntegrationReportComponent as Component,
};
