import { Button } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  CreateInstituteGroupResult,
  useCreateInstituteGroupMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { InstituteGroupForm } from "./InstituteGroupForm";
import { refetchQueries } from "Lib/RefetchQueries";

export function CreateInstituteGroupButton(): ReactElement {
  const { t } = useTranslation(["instituteGroups"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? <CreateInstituteGroupDialog open={open} onClose={() => setOpen(false)} /> : null;

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("instituteGroups:actions.create")}
      </Button>
      {dialog}
    </>
  );
}

type CreateInstituteGroupDialogProps = {
  open: boolean;
  onClose: () => void;
};

const EMPTY_DEFAULTS = {
  name: undefined,
  leaderId: undefined,
};

function CreateInstituteGroupDialog(props: CreateInstituteGroupDialogProps): ReactElement {
  const { t } = useTranslation(["instituteGroups"]);
  const navigate = useNavigate();

  const [createInstituteGroup, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.createInstituteGroup,
    useCreateInstituteGroupMutation({ refetchQueries: refetchQueries("instituteGroups") })
  );

  const onSuccess = (response: CreateInstituteGroupResult) => {
    navigate(response.instituteGroup.id.toString());
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("instituteGroups:actions.create")}
      stopBackdropClose={false}
    >
      <InstituteGroupForm
        defaults={EMPTY_DEFAULTS}
        onSuccess={onSuccess}
        remoteData={remoteData}
        mutation={(fields) => createInstituteGroup({ variables: { input: fields } })}
        errorMessage={t("instituteGroups:actions.createError")}
      />
    </ResponsiveDialog>
  );
}
