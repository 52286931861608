import Page from "Layout/Page";
import React, { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
} from "@mui/material";
import {
  ImplementationTargetStatus,
  ImplementationTargetType,
  useImplementationTargetDashboardQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { add, startOfDay, startOfWeek } from "date-fns";
import { useCurrentRootNode } from "Contexts/CurrentInstituteIdContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyBooleanParameter,
  useStickyDateParameter,
  useStickyEntityTreeNodeParameter,
  useStickyOptionalEnumParameter,
} from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { ImplementationTargetCard } from "./ImplementationTargetCard";
import { allowedEntityTypes } from "Implementation";
import { STICKY_PARAMETER_FILTER_SETS } from "Shared/Storage";
import { IncompleteReportWarning } from "./ImplementationTargetElements";
import { WithInternalUser } from "AppSession/AuthenticatedProviderUser";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import Paginator, { initPage } from "Shared/Paginator";
import { WithPermission } from "Shared/WithPermission";
import EnumSelect from "Shared/EnumSelect";
import { implementationTargetTypeT } from "GeneratedGraphQL/EnumTranslations";
import { FilterButton } from "Lib/FilterPanel";
import { SectionHelpText } from "./ImplementationTargetForm";
import {
  CurrentEntityQueryFilterExplanation,
  EntityQueryConfigCheckboxes,
  useStickyEntityQueryParams,
} from "Entities/EntityQuery";

export default function ImplementationTargetsDashboard() {
  const { t } = useTranslation(["implementation"]);
  const [thisWeekStart] = useState<Date>(startOfWeek(new Date()));
  const [nextWeekStart] = useState<Date>(add(startOfWeek(new Date()), { weeks: 1 }));
  const [pagination, setPagination] = React.useState(initPage(12));

  const [activeDate, setActiveDate] = useStickyDateParameter(
    "activeDate",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    thisWeekStart,
    true
  );
  const [currentRootNode] = useCurrentRootNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    "entityPath",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    currentRootNode,
    true
  );

  const [hideProviderInformation, setHideProviderInformation] = useStickyBooleanParameter(
    "hideProvider",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    false,
    true
  );

  const [targetType, setTargetType] = useStickyOptionalEnumParameter<ImplementationTargetType>(
    "targetType",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    ImplementationTargetType,
    null,
    true
  );

  const entityQueryTypeParams = useStickyEntityQueryParams(STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION);

  const [showInternalTargets, setShowInternalTargets] = useStickyBooleanParameter(
    "showInternal",
    STICKY_PARAMETER_FILTER_SETS.IMPLEMENTATION,
    false,
    true
  );
  const [advancedOpen, setAdvancedOpen] = useState(false);

  const { search } = useLocation();

  const navigate = useNavigate();

  const activeExtraFilters =
    (hideProviderInformation ? 1 : 0) +
    (targetType ? 1 : 0) +
    (showInternalTargets ? 1 : 0) +
    (entityQueryTypeParams.filterIsDefault ? 0 : 1);

  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetDashboardQuery({
      variables: {
        availableForNode: null,
        entityQuery: {
          entityTreeNodeParams,
          exact: entityQueryTypeParams.exact,
          lessSpecific: entityQueryTypeParams.lessSpecific,
          moreSpecific: entityQueryTypeParams.moreSpecific,
        },
        targetType: targetType ? [targetType] : null,
        date: activeDate,
        status: [ImplementationTargetStatus.ACTIVE],
        hideProviderInformation,
        includeMirahInternal: showInternalTargets,
        ...pagination,
      },
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.implementationTargets?.pageInfo || null,
    _: () => null,
  });

  const handleChangeActiveDate = (newValue: Date) => {
    setActiveDate(startOfDay(newValue));
  };

  const targetItems = remoteData.caseOf({
    Success: (targets) => {
      const notargets = (
        <Grid item lg={1} xs={2}>
          <Card>
            <CardHeader title={t("implementation:noTargets")} />
            <CardContent>{t("implementation:explainTargets")}</CardContent>
          </Card>
        </Grid>
      );
      if (targets.implementationTargets) {
        if (targets.implementationTargets.nodes.length === 0) {
          return notargets;
        }
        return targets.implementationTargets.nodes.map((target) => {
          return (
            <Grid key={target.id.toString()} item lg={1} xs={2}>
              <ImplementationTargetCard
                implementationTarget={target}
                entityTreeNodeParams={entityTreeNodeParams}
              />
            </Grid>
          );
        });
      } else {
        return notargets;
      }
    },
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
  });

  const onCreateTargetClickHandler = () => {
    navigate(`create${search}`);
  };

  return (
    <Page browserTitle={t("implementation:dashboard.title")} instituteGroupOverride={"institute-scoped"}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction={"row"}>
            <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"} useFlexGap>
              <Box minWidth="15em">
                <DatePicker
                  label={t("implementation:dashboard.weekEnding")}
                  views={["month", "year", "day"]}
                  openTo={"day"}
                  value={activeDate}
                  defaultValue={thisWeekStart}
                  onChange={(newValue: Date) => handleChangeActiveDate(newValue)}
                  shouldDisableDate={(date) => {
                    // Only select Sundays
                    return date.getDay() !== 0;
                  }}
                  maxDate={nextWeekStart}
                />
              </Box>

              <Box minWidth="25em">
                <EntityTreeNodeSelect
                  setValue={setEntityTreeNodeParams}
                  entityTypes={allowedEntityTypes}
                  value={entityTreeNodeParams}
                  defaultValue={currentRootNode}
                />
              </Box>
              <FilterButton
                activeFilterCount={activeExtraFilters}
                open={advancedOpen}
                toggleOpen={setAdvancedOpen}
              />
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  setActiveDate(thisWeekStart);
                  setEntityTreeNodeParams(currentRootNode);
                  setShowInternalTargets(false);
                  setTargetType(null);
                  setHideProviderInformation(false);
                  entityQueryTypeParams.reset();
                }}
              />
            </Stack>
            <Box flexGrow={1} />
            <WithPermission permission="editImplementationTargets">
              <Button variant="contained" color="secondary" onClick={onCreateTargetClickHandler}>
                {t("implementation:dashboard.createTarget")}
              </Button>
            </WithPermission>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <IncompleteReportWarning endDate={activeDate} />
        </Grid>
        <Grid item lg={2} xs={2}>
          <Collapse in={advancedOpen}>
            <Grid container columns={2} spacing={1}>
              <Grid item xs={2} lg={1}>
                <FormControl>
                  <SectionHelpText text={t("implementation:dashboard.queryType")} />
                  <EntityQueryConfigCheckboxes
                    params={entityQueryTypeParams}
                    currentEntityTreeParams={entityTreeNodeParams}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} lg={1}>
                {/*  If you don't add padding the text is clipped at the top*/}
                <Box minWidth="25em" paddingTop={1}>
                  <EnumSelect
                    onChange={setTargetType}
                    value={targetType}
                    enumTrans={implementationTargetTypeT}
                    optionsEnum={ImplementationTargetType}
                    title={t("implementation:targetType")}
                  />
                </Box>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t("implementation:hideProviderInformation")}
                    checked={hideProviderInformation}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                      setHideProviderInformation(event.target.checked)
                    }
                  />
                </FormControl>
                <WithInternalUser>
                  <FormControl>
                    <FormControlLabel
                      control={<Checkbox />}
                      label={t("implementation:showInternalTargets")}
                      checked={showInternalTargets}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setShowInternalTargets(event.target.checked)
                      }
                    />
                  </FormControl>
                </WithInternalUser>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
        {targetItems}
      </Grid>
      <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
      <CurrentEntityQueryFilterExplanation
        exact={entityQueryTypeParams.exact}
        moreSpecific={entityQueryTypeParams.moreSpecific}
        lessSpecific={entityQueryTypeParams.lessSpecific}
      />
    </Page>
  );
}
