import { Button, Checkbox, FormControlLabel, FormGroup, SelectProps } from "@mui/material";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getEnumItems, EnumType, EnumValueOf } from "./Enum";

type EnumCheckboxesParams<T> = {
  optionsEnum: EnumType & T;
  enumTrans: (value: EnumValueOf<T>, t: TFunction<["enums"]>) => string;
  sortFn?: (a: EnumValueOf<T>, b: EnumValueOf<T>) => number;
  onChange: (newValues: Array<EnumValueOf<T>> | null) => void;
  value: Array<EnumValueOf<T>> | null;
  omitOptions?: ReadonlyArray<EnumValueOf<T>>;
} & Omit<SelectProps, "onChange" | "value">;

export default function EnumCheckboxes<T extends EnumType>(props: EnumCheckboxesParams<T>): ReactElement {
  const { sortFn, optionsEnum, enumTrans, value, omitOptions } = props;
  const { t } = useTranslation(["enums", "common"]);

  const compare =
    sortFn ||
    function (_a: string, _b: string) {
      return 0;
    };

  const optionsToSkip = omitOptions || [];

  const items = getEnumItems(optionsEnum)
    .sort(compare)
    .filter((option) => !optionsToSkip.includes(option))
    .map((option) => {
      const updateFn = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (checked) {
          if (value && !value.includes(option)) {
            const newValue = value.concat([option]);
            props.onChange(newValue);
          } else {
            // Do nothing
          }
        } else {
          if (!value) {
            return;
          }

          const index = value.indexOf(option);

          if (index > -1) {
            const newArray = [...value];
            newArray.splice(index, 1);
            props.onChange(newArray);
          }
        }
      };
      return (
        <FormGroup key={option}>
          <FormControlLabel
            key={option}
            control={
              <Checkbox checked={value?.includes(option)} onChange={updateFn} name={option.toString()} />
            }
            label={enumTrans(option, t)}
          />
        </FormGroup>
      );
    });

  const selectAllFn = function () {
    props.onChange(getEnumItems(optionsEnum));
  };

  const deselectAllFn = function () {
    props.onChange([]);
  };

  const selectAllButtons = (
    <>
      <FormGroup row={true}>
        <FormControlLabel
          key="selectAll"
          control={
            <Button variant="contained" onClick={selectAllFn} name={"selectAll"}>
              {t("common:selectAll")}
            </Button>
          }
          label=""
        ></FormControlLabel>
        <FormControlLabel
          key="deselectAll"
          control={
            <Button variant="outlined" onClick={deselectAllFn} name={"deselectAll"}>
              {t("common:deselectAll")}
            </Button>
          }
          label=""
        ></FormControlLabel>
      </FormGroup>
    </>
  );

  return (
    <>
      {selectAllButtons} {items}
    </>
  );
}
