import {
  EntityTreeNodeParams,
  MonthParams,
  OrganizationFilter,
  PatientFilter,
  ProviderFilter,
  ScaleTargeting,
  ScaleThresholdClass,
  SeverityQueryParams,
  Trend,
} from "GeneratedGraphQL/SchemaAndOperations";
import * as Id from "Lib/Id";
import { useSearchParams } from "react-router-dom";
import { enumFromStringValue, SimpleEnum } from "./Enum";
import { organizationFilterSerializer } from "./Filters/OrganizationSelect";
import { patientFilterSerializer } from "./Filters/PatientSelect";
import { providerFilterSerializer } from "./Filters/ProviderSelect";
import { parseISO as parseISODate, isValid as isValidDate, startOfMonth, endOfMonth } from "date-fns";
import { formatDateIso } from "./formatters";
import { ObfuscationSecrets, decrypt, encrypt, useObfuscationSecrets } from "./parameterObfuscation";
import { QueryPage } from "./Paginator";
import { dateToMonth, monthToDate } from "./Month";
import { DateRangeParams } from "./DateRangeSelect";

/**
 * Safely serialize and deserialize a single parameter into the given type.
 */
export type ParameterSerializer<T> = {
  /**
   * Function to take a well formatted parameter and put it to a string suitable of being persisted in a query param.
   * There are some circumstances where you might not want to persist anything, e.g. there is a default value that does
   * not need to be in the query. In that case you can return nothing.
   */
  serialize: (x: T) => string | null;
  /**
   * Attempt to deserialize a string key from url params into memory. The key may or may not be present.
   * Note that this must resolve - the implementation must either be a Maybe<T> or
   * you should specify a default value as part of implementation.
   */
  deserialize: (str: string | null) => T;
};

const parseOptionalString: () => ParameterSerializer<string | null> = () => {
  return {
    serialize: (x: string | null) => x,
    deserialize: (str: string | null) => {
      return str;
    },
  };
};

// Parse an enum from a string. You need to give the actual enum object so that it can be inspected at runtime.
const parseEnum: <T extends string>(enumObject: SimpleEnum, defaultValue: T) => ParameterSerializer<T> = <T,>(
  enumObject: SimpleEnum,
  defaultValue: T
) => {
  return {
    serialize: (x: T | null) => (x ? x.toString() : defaultValue),
    deserialize: (str: string | null) =>
      str ? enumFromStringValue(enumObject, str) || defaultValue : defaultValue,
  };
};

// Parse JSON from an object, with a default provided if either it is not present, or the parsing results in an error.
const parseJson: <T>(defaultValue: T) => ParameterSerializer<T> = <T,>(defaultValue: T) => {
  return {
    serialize: (x: T) => JSON.stringify(x),
    deserialize: (str: string | null) => {
      if (str) {
        try {
          return JSON.parse(str);
        } catch {
          return defaultValue;
        }
      } else {
        return defaultValue;
      }
    },
  };
};

const parseOptionalJson: <T>() => ParameterSerializer<T | null> = <T,>() => {
  return {
    serialize: (x: T) => JSON.stringify(x),
    deserialize: (str: string | null) => {
      if (str) {
        try {
          return JSON.parse(str);
        } catch {
          return null;
        }
      } else {
        return null;
      }
    },
  };
};

// Parse an enum from a string. You need to give the actual enum object so that it can be inspected at runtime.
// The enum is optional, and you can receive null.
const parseOptionalEnum: <T extends string>(
  enumObject: SimpleEnum,
  defaultValue: T | null
) => ParameterSerializer<T | null> = <T extends string>(enumObject: SimpleEnum, defaultValue: T | null) => {
  return {
    serialize: (x: T | null) => {
      // We serialize as "null" if it's null and that's not the default
      if (x) {
        return x === defaultValue ? null : x.toString();
      } else {
        return defaultValue ? "null" : null;
      }
    },
    deserialize: (str: string | null) => {
      // Special case: handle the string null to be null
      if (str === "null") {
        return null;
      }

      return str ? enumFromStringValue(enumObject, str) || defaultValue : defaultValue;
    },
  };
};

// Parse an enum from a string. You need to give the actual enum object so that it can be inspected at runtime.
// The enum is optional, and you can receive null.
const parseOptionalEnumArray: <T extends string>(
  enumObject: SimpleEnum,
  defaultValue: ReadonlyArray<T> | null
) => ParameterSerializer<ReadonlyArray<T> | null> = <T extends string>(
  enumObject: SimpleEnum,
  defaultValue: ReadonlyArray<T> | null
) => {
  return {
    serialize: (x: ReadonlyArray<T> | null) => {
      // We serialize as "null" if it's null and that's not the default
      if (x) {
        return x === defaultValue ? null : x.join(",");
      } else {
        return defaultValue ? "null" : null;
      }
    },
    deserialize: (str: string | null) => {
      // Special case: handle the string null to be null
      if (str === "null") {
        return null;
      }

      if (str) {
        const parsed = str.split(",").flatMap((item) => {
          const enumItem = enumFromStringValue<T>(enumObject, item);

          if (enumItem) {
            return [enumItem];
          } else {
            return [];
          }
        });

        return parsed;
      } else {
        return defaultValue;
      }
    },
  };
};

// Parse an enum from a string. You need to give the actual enum object so that it can be inspected at runtime.
// The enum is optional, and you can receive null.
const parseOptionalEncrypted: (
  defaultValue: string | null,
  obfuscationSecrets: ObfuscationSecrets
) => ParameterSerializer<string | null> = (
  defaultValue: string | null,
  obfuscationSecrets: ObfuscationSecrets
) => {
  return {
    serialize: (x: string | null) => (x ? encrypt(x, obfuscationSecrets) : defaultValue),
    deserialize: (str: string | null) =>
      str ? decrypt(str, obfuscationSecrets) || defaultValue : defaultValue,
  };
};

// Parse a date from a string
const parseDate: (defaultValue: Date) => ParameterSerializer<Date> = (defaultValue) => {
  return {
    serialize: (x: Date) => formatDateIso(x),
    deserialize: (str: string | null) => {
      if (str) {
        const result = parseISODate(str);
        if (isValidDate(result)) {
          return result;
        }
      }
      return defaultValue;
    },
  };
};

const parseOptionalDateRange: (
  defaultValue: DateRangeParams | null
) => ParameterSerializer<DateRangeParams | null> = (defaultValue) => {
  return {
    serialize: (range: DateRangeParams | null) => {
      if (!range) return null; // If the input is null, return null
      const min = range.min ? formatDateIso(range.min) : "";
      const max = range.max ? formatDateIso(range.max) : "";
      return min || max ? `${min},${max}` : null; // Use comma-separated format
    },
    deserialize: (str: string | null) => {
      if (!str) return defaultValue; // If no input, return defaultValue

      const [minStr, maxStr] = str.split(",");
      const min = minStr ? parseISODate(minStr) : undefined;
      const max = maxStr ? parseISODate(maxStr) : undefined;

      if ((min && isValidDate(min)) || (max && isValidDate(max))) {
        return { min: isValidDate(min) ? min : undefined, max: isValidDate(max) ? max : undefined };
      }
      return defaultValue; // If invalid input, return defaultValue
    },
  };
};

// Parse a date from a string
const parseMonthStart: (defaultValue: Date) => ParameterSerializer<Date> = (defaultValue) => {
  return {
    serialize: (x: Date) => formatDateIso(x),
    deserialize: (str: string | null) => {
      if (str) {
        const result = parseISODate(str);
        if (isValidDate(result)) {
          return startOfMonth(result);
        }
      }
      return defaultValue;
    },
  };
};

// Parse a date from a string
const parseMonthEnd: (defaultValue: Date) => ParameterSerializer<Date> = (defaultValue) => {
  return {
    serialize: (x: Date) => formatDateIso(x),
    deserialize: (str: string | null) => {
      if (str) {
        const result = parseISODate(str);
        if (isValidDate(result)) {
          return endOfMonth(result);
        }
      }
      return defaultValue;
    },
  };
};

// Parse a date from a string
const parseMonthStructured: (defaultValue: MonthParams) => ParameterSerializer<MonthParams> = (
  defaultValue
) => {
  return {
    serialize: (x: MonthParams) => formatDateIso(monthToDate(x)),
    deserialize: (str: string | null) => {
      if (str) {
        const result = parseISODate(str);
        if (isValidDate(result)) {
          return dateToMonth(result);
        }
      }
      return defaultValue;
    },
  };
};

const parseOptionalDate: () => ParameterSerializer<Date | null> = () => {
  return {
    serialize: (x: Date | null) => (x ? formatDateIso(x) : null),
    deserialize: (str: string | null) => {
      if (str) {
        const result = parseISODate(str);
        if (isValidDate(result)) {
          return result;
        }
      }
      return null;
    },
  };
};

// Parse an enum from a string. You need to give the actual enum object so that it can be inspected at runtime.
const parseBoolean: (defaultValue: boolean) => ParameterSerializer<boolean> = (defaultValue: boolean) => {
  return {
    serialize: (x: boolean | null) => {
      if (x === defaultValue) {
        return null;
      }

      if (x) {
        return "true";
      } else {
        return "false";
      }
    },
    deserialize: (str: string | null) => {
      if (str === "true") {
        return true;
      } else if (str == "false") {
        return false;
      }

      return defaultValue;
    },
  };
};

const parseNumber: (defaultValue: number) => ParameterSerializer<number> = (defaultValue: number) => {
  return {
    serialize: (x: number | null) => {
      if (x === defaultValue || !x) {
        return null;
      }

      return x.toString();
    },
    deserialize: (str: string | null) => {
      if (str) {
        const parsed = parseInt(str);

        if (Number.isNaN(parsed)) {
          return defaultValue;
        } else {
          return parsed;
        }
      }

      return defaultValue;
    },
  };
};

const parseOptionalNumber: () => ParameterSerializer<number | null> = () => {
  return {
    serialize: (x: number | null) => {
      if (!x) {
        return null;
      }

      return x.toString();
    },
    deserialize: (str: string | null) => {
      if (str) {
        const parsed = parseInt(str);

        if (Number.isNaN(parsed)) {
          return null;
        } else {
          return parsed;
        }
      }

      return null;
    },
  };
};

// Parse a string union of type `"a" | "b"> with a default value. null is not allowed and will be replaced by
// default value
const parseStringUnion: <T extends string>(
  values: ReadonlyArray<T>,
  defaultValue: T
) => ParameterSerializer<T> = <T,>(values: ReadonlyArray<T>, defaultValue: T) => {
  return {
    serialize: (x: T | null) => (x ? x.toString() : defaultValue),
    deserialize: (str: string | null) =>
      str && (values as ReadonlyArray<string>).includes(str) ? (str as T) || defaultValue : defaultValue,
  };
};

// Parse a string union of type `"a" | "b"> with a default value. null will be serialized as the empty string
// as long as it is not the default value.
const parseOptionalStringUnion: <T extends string>(
  values: ReadonlyArray<T>,
  defaultValue: T | null
) => ParameterSerializer<T | null> = <T,>(values: ReadonlyArray<T>, defaultValue: T) => {
  return {
    serialize: (x: T | null) => {
      if (x === defaultValue) {
        return null;
      }

      if (x) {
        return x.toString();
      } else {
        return "";
      }
    },
    deserialize: (str: string | null) => {
      if (str === "") {
        return null;
      }
      return str && (values as ReadonlyArray<string>).includes(str)
        ? (str as T) || defaultValue
        : defaultValue;
    },
  };
};

// Parse a UUID that is optional, returning either null or the id.
const parseOptionalId: <T extends string>() => ParameterSerializer<Id.Id<T> | null> = <
  T extends string
>() => {
  return {
    serialize: (x: Id.Id<T> | null) => (x ? x.toString() : null),
    deserialize: (str: string | null) => Id.fromNullableString<T>(str).getOrElse(null),
  };
};

// Parse a UUID that is required, returning default value upon problem
const parseId: <T extends string>(defaultValue: Id.Id<T>) => ParameterSerializer<Id.Id<T>> = <
  T extends string
>(
  defaultValue: Id.Id<T>
) => {
  return {
    serialize: (x: Id.Id<T>) => (x === defaultValue ? null : x.toString()),
    deserialize: (str: string | null) => Id.fromNullableString<T>(str).getOrElse(defaultValue),
  };
};

const parseEntityTreeNodeParams: (
  defaultValue: EntityTreeNodeParams
) => ParameterSerializer<EntityTreeNodeParams> = (defaultValue: EntityTreeNodeParams) => {
  return {
    serialize: (x: EntityTreeNodeParams | null) => {
      if (!x) {
        return null;
      }

      if (x.path) {
        if (x.path === defaultValue.path) {
          return null;
        } else {
          return `path:${x.path}`;
        }
      } else if (x.entityId) {
        if (x.entityId === defaultValue.entityId) {
          return null;
        } else {
          return `entity:${x.entityId}`;
        }
      } else {
        if (x.root === defaultValue.root) {
          return null;
        }
        return "root:true";
      }
    },
    deserialize: (str: string | null) => {
      if (str) {
        if (str === "root:true") {
          return {
            root: true,
          };
        }
        const [part, id] = str.split(":");

        if (part === "entity") {
          return Id.fromNullableString<"Entity">(id).caseOf({
            Ok: (entityId) => {
              return {
                entityId,
              };
            },
            Err: () => {
              return defaultValue;
            },
          });
        } else if (part === "path" && id) {
          return {
            path: id,
          };
        } else if (part === "root") {
          return {
            root: true,
          };
        }
      }

      return defaultValue;
    },
  };
};

const parseSeverityQuery: (
  defaultValue: ReadonlyArray<SeverityQueryParams> | null
) => ParameterSerializer<ReadonlyArray<SeverityQueryParams> | null> = (
  defaultValue: ReadonlyArray<SeverityQueryParams> | null
) => {
  const targetingParser = parseOptionalEnum<ScaleTargeting>(ScaleTargeting, null);
  const trendParser = parseOptionalEnum<Trend>(Trend, null);
  const thresholdClassesParser = parseOptionalEnumArray<ScaleThresholdClass>(ScaleThresholdClass, null);

  return {
    serialize: (x: ReadonlyArray<SeverityQueryParams> | null) => {
      if (!x) {
        return null;
      }

      const components = x.map((query) => {
        return `${query.isFirstMeasurement}:${query.isLastMeasurement}:${
          query.targeting
        }:${thresholdClassesParser.serialize(query.thresholdClasses || null)}:${query.trend}`;
      });

      return components.join(";");
    },
    deserialize: (str: string | null) => {
      if (!str) {
        return defaultValue;
      }

      // Each item in array is split by ;
      return str.split(";").map((item) => {
        // Each field is split by :
        const [isFirstMeasurement, isLastMeasurement, targetingString, thresholdClassesString, trendString] =
          item.split(":");

        return {
          isFirstMeasurement: isFirstMeasurement === "true" ? true : undefined,
          isLastMeasurement: isLastMeasurement === "true" ? true : undefined,
          targeting: targetingString ? targetingParser.deserialize(targetingString) || null : undefined,
          thresholdClasses: thresholdClassesString
            ? thresholdClassesParser.deserialize(thresholdClassesString) || null
            : undefined,
          trend: trendString ? trendParser.deserialize(trendString) || null : undefined,
        };
      });
    },
  };
};

const parsers = {
  parseBoolean,
  parseDate,
  parseEnum,
  parseMonthEnd,
  parseMonthStart,
  parseStringUnion,
  parseNumber,
  parseOptionalNumber,
  parseOptionalDate,
  parseOptionalEncrypted,
  parseOptionalEnum,
  parseOptionalId,
  parseId,
  parseEntityTreeNodeParams,
  parseOptionalStringUnion,
  parseJson,
  parseOptionalJson,
  parseMonthStructured,
  parseOptionalEnumArray,
  parseSeverityQuery,
  parseOptionalDateRange,
};

/**
 * Read a value from the query string as string, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalStringParameter(parameterName: string, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseOptionalString(), replace);
}

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum object, needed for realtime lookup
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringEnumParameter<T extends string>(
  parameterName: string,
  enumObject: SimpleEnum,
  defaultValue: T,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parseEnum(enumObject, defaultValue), replace);
}

/**
 * Read a value from the query string as a string union, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param values an array containing the runtime values you can check the string against.
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringUnionParameter<T extends string>(
  parameterName: string,
  values: ReadonlyArray<T>,
  defaultValue: T,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parseStringUnion(values, defaultValue), replace);
}

/**
 * Read a value from the query string as a string union, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param values an array containing the runtime values you can check the string against.
 * @param defaultValue if nothing read from query string, this will be used. Can be null
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalUnionParameter<T extends string>(
  parameterName: string,
  values: ReadonlyArray<T>,
  defaultValue: T | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parseOptionalStringUnion(values, defaultValue), replace);
}

/**
 * Read a value from the query string as an enum that can be optional, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum, required for runtime serialization.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalEnumParameter<T extends string>(
  parameterName: string,
  enumObject: SimpleEnum,
  defaultValue: T | null,
  replace?: boolean
) {
  return useQueryStringParameter<T | null>(
    parameterName,
    parseOptionalEnum<T>(enumObject, defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum object, needed for realtime lookup
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalEncryptedParameter(
  parameterName: string,
  defaultValue: string | null,
  replace?: boolean
) {
  const obfuscationSecrets = useObfuscationSecrets();
  return useQueryStringParameter(
    parameterName,
    parseOptionalEncrypted(defaultValue, obfuscationSecrets),
    replace
  );
}

/**
 * Read a value from the query string as a typed ID, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringIdParameter<IdType extends string>(parameterName: string, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseOptionalId<IdType>(), replace);
}

/**
 * Read a value from the query string as a typed ID, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringIdArrayParameter<IdType extends string>(parameterName: string, replace?: boolean) {
  return useQueryStringArrayParameter(parameterName, parseOptionalId<IdType>(), replace);
}

/**
 * Read a patient filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringPatientFilterParameter(
  parameterName: string,
  defaultValue: PatientFilter | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, patientFilterSerializer(defaultValue), replace);
}

/**
 * Read an organization filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOrganizationFilterParameter(
  parameterName: string,
  defaultValue: OrganizationFilter | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, organizationFilterSerializer(defaultValue), replace);
}

/**
 * Read an organization filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringProviderFilterParameter(
  parameterName: string,
  defaultValue: ProviderFilter | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, providerFilterSerializer(defaultValue), replace);
}

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringBooleanParameter(parameterName: string, defaultValue: boolean, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseBoolean(defaultValue), replace);
}

/**
 * Read a value from the query string as an number, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalNumberParameter(parameterName: string, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseOptionalNumber(), replace);
}

/**
 * Read a value from the query string as an number, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringNumberParameter(parameterName: string, defaultValue: number, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseNumber(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringDateParameter(parameterName: string, defaultValue: Date, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseDate(defaultValue), replace);
}

/**
 * Read a value from the query string as an optional date range
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalDateRangeParameter(
  parameterName: string,
  defaultValue: DateRangeParams | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parseOptionalDateRange(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringMonthParameter(
  parameterName: string,
  defaultValue: Date,
  mode: "start" | "end",
  replace?: boolean
) {
  const parser = mode === "start" ? parseMonthStart : parseMonthEnd;
  return useQueryStringParameter(parameterName, parser(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringMonthV2Parameter(parameterName: string, defaultValue: MonthParams, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseMonthStructured(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringOptionalDateParameter(parameterName: string, replace?: boolean) {
  return useQueryStringParameter(parameterName, parseOptionalDate(), replace);
}

/**
 * Read an organization filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useQueryStringPagingParameter(
  parameterNameBase: string,
  defaultValue: QueryPage,
  replace?: boolean
): [QueryPage, (value: QueryPage) => void] {
  const [first, setFirst] = useQueryStringOptionalNumberParameter(parameterNameBase + "First", replace);
  const [last, setLast] = useQueryStringOptionalNumberParameter(parameterNameBase + "Last", replace);
  const [before, setBefore] = useQueryStringOptionalStringParameter(parameterNameBase + "Before", replace);
  const [after, setAfter] = useQueryStringOptionalStringParameter(parameterNameBase + "After", replace);

  let ret: QueryPage = defaultValue;

  if (first) {
    ret = {
      first,
      last: null,
      before: null,
      after,
    };
  } else if (last) {
    ret = {
      first: null,
      last,
      before,
      after: null,
    };
  }

  const setFunction = (newValue: QueryPage | null) => {
    if (newValue) {
      setFirst(newValue.first);
      setLast(newValue.last);
      setBefore(newValue.before);
      setAfter(newValue.after);
    } else {
      setFirst(null);
      setLast(null);
      setBefore(null);
      setAfter(null);
    }
  };

  return [ret, setFunction];
}

function useQueryStringEntityTreeNodeParameter(
  parameterName: string,
  defaultValue: EntityTreeNodeParams,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parsers.parseEntityTreeNodeParams(defaultValue), replace);
}

function useQueryStringSeverityQueryParameter(
  parameterName: string,
  defaultValue: ReadonlyArray<SeverityQueryParams> | null,
  replace?: boolean
) {
  return useQueryStringParameter(parameterName, parsers.parseSeverityQuery(defaultValue), replace);
}

/**
 * Base functionality for reading a parameter with a given name off the query string.
 */
function useQueryStringParameter<T>(
  parameterName: string,
  serializer: ParameterSerializer<T>,
  replace?: boolean
): [T, (newValue: T) => void] {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const currentValue = getSearchParamByName(currentSearchParams, parameterName, serializer);

  const onUpdate = (newValue: T) => {
    // We need to take the active current url params in case we get multiple updates in the same cycle.
    const newSearchParams = new URLSearchParams(window.location.search);

    const newSerialized = serializer.serialize(newValue);
    if (newSerialized === null) {
      newSearchParams.delete(parameterName);
    } else {
      newSearchParams.set(parameterName, newSerialized);
    }
    setSearchParams(newSearchParams, { replace });
    return newSearchParams;
  };

  return [currentValue, onUpdate];
}

/**
 * Base functionality for reading a parameter with a given name off the query string.
 */
function useQueryStringArrayParameter<T>(
  parameterName: string,
  serializer: ParameterSerializer<T | null>,
  replace?: boolean
): [ReadonlyArray<T> | null, (newValue: ReadonlyArray<T> | null) => void] {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const currentValue: ReadonlyArray<T> | null = getSearchParamArrayByName(
    currentSearchParams,
    parameterName,
    serializer
  );

  const onUpdate = (newValue: ReadonlyArray<T>) => {
    // We need to take the active current url params in case we get multiple updates in the same cycle.
    const newSearchParams = new URLSearchParams(window.location.search);

    // Ensure we delete all existing parts of the array.
    newSearchParams.delete(parameterName);

    newValue.forEach((value) => {
      const newSerialized = serializer.serialize(value);

      if (newSerialized) {
        newSearchParams.append(parameterName, newSerialized);
      }
    });

    setSearchParams(newSearchParams, { replace });
    return newSearchParams;
  };

  return [currentValue, onUpdate];
}

function searchParamIsPresent(params: URLSearchParams, name: string) {
  return params.has(name);
}

function getSearchParamByName<T>(
  params: URLSearchParams,
  name: string,
  serializer: ParameterSerializer<T>
): T {
  const foundValue = params.get(name);

  return serializer.deserialize(foundValue);
}

function getSearchParamArrayByName<T>(
  params: URLSearchParams,
  name: string,
  serializer: ParameterSerializer<T | null>
): ReadonlyArray<T> | null {
  const foundValue = params.getAll(name);

  if (foundValue.length === 0) {
    return null;
  }

  return foundValue.map(serializer.deserialize).filter(notNullFilter);
}

function notNullFilter<T>(item: T | null): item is T {
  return !!item;
}

export {
  useQueryStringParameter,
  useQueryStringIdParameter,
  useQueryStringEnumParameter,
  useQueryStringOptionalEnumParameter,
  useQueryStringPatientFilterParameter,
  useQueryStringOrganizationFilterParameter,
  useQueryStringUnionParameter,
  useQueryStringOptionalUnionParameter,
  useQueryStringBooleanParameter,
  useQueryStringProviderFilterParameter,
  useQueryStringOptionalNumberParameter,
  useQueryStringNumberParameter,
  useQueryStringDateParameter,
  useQueryStringOptionalDateParameter,
  useQueryStringIdArrayParameter,
  useQueryStringMonthParameter,
  useQueryStringOptionalEncryptedParameter,
  useQueryStringOptionalStringParameter,
  useQueryStringPagingParameter,
  useQueryStringEntityTreeNodeParameter,
  useQueryStringSeverityQueryParameter,
  useQueryStringOptionalDateRangeParameter,
  useQueryStringMonthV2Parameter,
  getSearchParamArrayByName,
  getSearchParamByName,
  searchParamIsPresent,
  parsers,
};

export default useQueryStringParameter;
