import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import components from "./components";
import { enUS, Localization } from "@mui/material/locale";
import { negotiatedLanguage, SupportedLanguageMap } from "i18n";
import { zIndex } from "./ZIndex";

const muiLocales: SupportedLanguageMap<Localization> = {
  en: enUS,
};

const theme = createTheme(
  {
    palette,
    typography,
    components,
    zIndex,
    spacing: 16, // 1rem
  },
  muiLocales[negotiatedLanguage]
);

export default theme;
