import { Button, Paper } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import React, { ReactElement, ReactNode, useState } from "react";

import {
  DataSourceRecordLogsQuery,
  DataSourceRecordLogsQueryVariables,
  InternalIntegrationsDataSourceRecordsFragment,
  useDataSourceRecordLogsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import RecordLogDialog from "./RecordLogDialog";
import { NonEmptyList, fromArray } from "Lib/NonEmptyList";

type ExtraVars = Pick<DataSourceRecordLogsQueryVariables, "dataSourceRecordId">;

function ImportHistory(props: {
  dataSourceRecordIds: ReadonlyArray<InternalIntegrationsDataSourceRecordsFragment>;
}): ReactElement {
  return fromArray(props.dataSourceRecordIds).caseOf({
    Just: (a) => <NelImportHistory dataSourceRecordIds={a} />,
    Nothing: () => <div>No Import Data</div>,
  });
}

function NelImportHistory(props: {
  dataSourceRecordIds: NonEmptyList<InternalIntegrationsDataSourceRecordsFragment>;
}): ReactElement {
  const [dialog, setDialog] = useState<ReactNode>(null);

  const extra: ExtraVars = {
    dataSourceRecordId: props.dataSourceRecordIds.toArray().map((x) => x.id),
  };

  const cols: DataGridCols<DataSourceRecordLogsQuery, ["integrationsDataSourceRecordLogs"]> =
    React.useMemo(() => {
      return [
        {
          field: "status",
          headerName: "Status",
          filterable: false,
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) => row.status,
        },
        {
          field: "createdAt",
          headerName: "Created At",
          filterable: false,
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) =>
            new Date(row.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
        },
        {
          field: "id",
          headerName: "ID",
          filterable: false,
          sortable: false,
          flex: 1,
          renderCell: (params) => {
            return (
              <Button
                startIcon={<InfoIcon />}
                onClick={() =>
                  setDialog(<RecordLogDialog log={params.row} closeDialog={() => setDialog(null)} />)
                }
              >
                Details
              </Button>
            );
          },
        },
      ];
    }, []);
  return (
    <>
      <Paper>
        <SortablePagableCollectionDataGrid
          queryHook={useDataSourceRecordLogsQuery}
          unwrapData={(d) => d?.integrationsDataSourceRecordLogs || null}
          queryVariables={extra}
          defaultPageSize={10}
          colNameToSortParam={(_colName) => null}
          columns={cols}
        />
      </Paper>
      {dialog}
    </>
  );
}

export default ImportHistory;
