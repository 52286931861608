// Until I take the time to write the docs, read these: https://mui.com/api/data-grid/data-grid/

import React, { ReactElement, useState } from "react";
import * as NEL from "Lib/NonEmptyList";
import {
  DataGrid,
  DataGridProps,
  GridApi,
  GridColDef,
  GridInitialState,
  GridPagination,
  GridPaginationModel,
  GridSortItem,
  GridSortModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridValidRowModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid";
import { PageInfo, SortDirection } from "GeneratedGraphQL/SchemaAndOperations";
import { QueryHookOptions, QueryResult } from "@apollo/client";
import { FixedHeightDataGrid } from "MDS/FixedHeightDataGrid";
import { DigUnpacked } from "type-utils";
import { useEffectDeepCompare } from "Lib/Hooks";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DATA_TABLE_RESET_EXPORT, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";
import { useIsMobile } from "./Responsive";
import Paginator, { EmptyQueryPage, PageSelection, QueryPage } from "./Paginator";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

type ResultData<TDataNode> = {
  totalCount: number;
  nodes: ReadonlyArray<TDataNode>;
  pageInfo: PageInfo;
};

type PagedQueryState<TVariables, TSortableCol> = {
  variables: TVariables;
  pageVars: QueryPage;
  paginationModel: GridPaginationModel;
  sortVars: SortInfo<TSortableCol>;
};

type SortInfo<TSortableCol> = {
  sortDirection: SortDirection | null;
  sortBy: TSortableCol | null;
};

function resetPage(defaultSize: number): QueryPage {
  return {
    first: defaultSize,
    after: null,
    last: null,
    before: null,
  };
}

const resetSort = {
  sortDirection: null,
  sortBy: null,
};

const GLOBAL_PAGE_SIZE_DEFAULT = 25;

/**
 * This type allows you to query into a query object and get results which you can turn into columns
 * e.g.:
 *   cols: DataGridCols<OutcomesMetricComputedValueQuery, ["outcomesMetricComputedValues"]>
 */
export type DataGridCols<
  TQuery,
  Keys extends Array<string | number | symbol>,
  Data extends GridValidRowModel = NonNullable<DigUnpacked<TQuery, [...Keys, "nodes"]>>
> = Array<GridColDef<Data>>;

export type DataGridRowClickHandler<
  /**
   * The result of the query that this table is based on
   */
  TData,
  /**
   * An array of keys to access the collection from the TData query. Keys should be at the level of the data we are displaying.
   * Each Column Definition in the array is restricted to either using a ValueGetter, or a RenderCell because we cannot guarantee
   * the types will be compatible between these two functions.
   *
   * A similiar restriction is in place preventing you from using ValueFormatter
   * because transforming the data in a separate function makes it hard to guarantee typesafety.
   */
  Keys extends Array<string | number | symbol>
> = RowClickHandler<DigUnpacked<TData, [...Keys, "nodes"]>>;

type RowClickHandler<RowType> = (params: { row: RowType }, event: MuiEvent<React.MouseEvent>) => void;

type Props<TData, TVariables, TDataNode extends GridValidRowModel, TSortableCol> = {
  queryHook: (
    baseOptions?: QueryHookOptions<TData, TVariables & EmptyQueryPage & SortInfo<TSortableCol>>
  ) => QueryResult<TData, TVariables & EmptyQueryPage>;
  queryVariables: TVariables;
  unwrapData: (data: TData | undefined | null) => ResultData<TDataNode> | null;
  colNameToSortParam: (name: string) => TSortableCol | null;
  defaultSortParams?: SortInfo<TSortableCol>;
  columns: Array<GridColDef<TDataNode>>;
  defaultPageSize?: number;
  onRowClick?: RowClickHandler<TDataNode>;
  getRowId?: (row: TDataNode) => string;
  pollInterval?: number | undefined;
  storageKey?: string; // If specified, table state will be persisted.
  showExportToolbar?: boolean;
  mobileCard?: (props: { row: TDataNode }) => ReactElement;
  getFooterMessage?: (data: TData | undefined | null) => string | undefined;
} & Pick<
  DataGridProps,
  | "disableRowSelectionOnClick"
  | "sx"
  | "localeText"
  | "initialState"
  | "autoHeight"
  | "columnGroupingModel"
  | "columnVisibilityModel"
  | "apiRef"
>;

function SortablePagableCollectionDataGrid<
  TData,
  TVariables,
  TDataNode extends GridValidRowModel,
  TSortableCol
>({
  queryVariables,
  queryHook,
  unwrapData,
  colNameToSortParam,
  defaultSortParams,
  columns,
  defaultPageSize,
  disableRowSelectionOnClick,
  pollInterval,
  storageKey,
  showExportToolbar,
  mobileCard,
  getFooterMessage: getMissingRowMessage,
  ...passthroughProps
}: Props<TData, TVariables, TDataNode, TSortableCol>): ReactElement {
  const [initialState, apiRef, forceStateSave, onStateReset] = useDataGridState(
    storageKey,
    passthroughProps.apiRef,
    passthroughProps.initialState
  );

  const [state, setState] = useState<PagedQueryState<TVariables, TSortableCol>>({
    variables: queryVariables,
    pageVars: resetPage(defaultPageSize || GLOBAL_PAGE_SIZE_DEFAULT),
    paginationModel: {
      // We don't currently support stickiness on the current page number for a couple of reasons:
      //  - We'd have to store a transient cursor id rather than just an offset, and it's unclear how well that would actually work
      //  - I suspect it'd cause a somewhat confusing user experience without a lot more thought.
      page: 0,
      pageSize:
        initialState?.pagination?.paginationModel?.pageSize ?? defaultPageSize ?? GLOBAL_PAGE_SIZE_DEFAULT,
    },
    sortVars: gridSortItemToSortInfo(
      (initialState?.sorting?.sortModel || [])[0],
      defaultSortParams,
      colNameToSortParam
    ),
  });

  const { data, fetchMore, loading } = queryHook({
    variables: {
      ...state.variables,
      ...state.pageVars,
      ...state.sortVars,
    },
    pollInterval,
  });

  const isMobile = useIsMobile() && mobileCard;

  // The simplest way of resetting everything as per https://github.com/mui/mui-x/issues/4301#issuecomment-2168072343 is to
  // simply change the key of the table (and also remove the initial state)
  const [key, setKey] = useState(0);

  const keyWithStorageKey = `${storageKey}${key}`;

  const doReset = () => {
    onStateReset();
    setKey(key + 1);
  };

  useEffectDeepCompare(() => {
    setState(
      resetPageState({
        ...state,
        variables: { ...state.variables, ...queryVariables },
      })
    );
  }, [queryVariables]);

  useEffectDeepCompare(() => {
    fetchMoreReal({
      ...state.variables,
      ...state.pageVars,
      ...state.sortVars,
    });
  }, [state]);

  const fetchMoreReal = (vars: TVariables & EmptyQueryPage) => {
    fetchMore({
      variables: vars,
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;
        return fetchMoreResult;
      },
    });
  };

  const currentData = unwrapData(data);
  const footerMessage = getMissingRowMessage ? getMissingRowMessage(data) : undefined;

  const slots = {
    toolbar: showExportToolbar ? CustomToolbar : null,
    footer: () => <FooterWithMessage message={footerMessage} />,
  };

  // Disable selection on click by default rather than enable it
  const disableRowSelectionOnClickWithDefault =
    typeof disableRowSelectionOnClick === "undefined" ? true : disableRowSelectionOnClick;

  if (isMobile) {
    const pagingChange = (q: QueryPage, selection: PageSelection) => {
      setState({
        ...state,
        paginationModel: paginationModelFromPageInfo(
          q,
          currentData?.totalCount || 0,
          defaultPageSize,
          state.paginationModel.page,
          selection
        ),
        pageVars: q,
      });
    };
    return (
      <MobileView
        mobileCard={mobileCard}
        resultData={currentData}
        setPagination={pagingChange}
        queryPage={state.pageVars}
        missingRowMessage={footerMessage}
      />
    );
  }

  const component = passthroughProps.autoHeight ? DataGrid : FixedHeightDataGrid;

  return React.createElement(component, {
    ...passthroughProps,
    key: keyWithStorageKey,
    apiRef,
    paginationModel: state.paginationModel,
    pagination: true,
    rows: currentData?.nodes || [],
    columns,
    loading,
    disableRowSelectionOnClick: disableRowSelectionOnClickWithDefault,
    filterMode: "server",
    sortingMode: "server",
    disableColumnSelector: false,
    disableColumnMenu: false,
    disableColumnFilter: true,
    paginationMode: "server",
    rowCount: currentData?.totalCount || 0,
    pageSizeOptions: [10, 25, 50, 100],
    initialState: {
      ...initialState,
    },
    slots,
    slotProps: {
      toolbar: { onReset: doReset },
    },
    onSortModelChange: (model) => {
      forceStateSave();
      setState(newSortState(model, state, colNameToSortParam));
    },
    onPaginationModelChange: (newPageModel) => {
      forceStateSave();
      const pageInfo = currentData?.pageInfo;
      if (pageInfo) {
        setState(newPageState(newPageModel, pageInfo, state));
      }
    },
  });
}

function resetPageState<TVariables, TSortableCol>(
  currentQueryState: PagedQueryState<TVariables, TSortableCol>
): PagedQueryState<TVariables, TSortableCol> {
  return {
    ...currentQueryState,
    paginationModel: {
      ...currentQueryState.paginationModel,
      page: 0,
    },
    pageVars: resetPage(currentQueryState.paginationModel.pageSize),
  };
}

function resetSortState<TVariables, TSortableCol>(
  currentQueryState: PagedQueryState<TVariables, TSortableCol>
): PagedQueryState<TVariables, TSortableCol> {
  return { ...resetPageState(currentQueryState), sortVars: resetSort };
}

function newSortState<TVariables, TSortableCol>(
  model: GridSortModel,
  currentQueryState: PagedQueryState<TVariables, TSortableCol>,
  colNameToSortParam?: (name: string) => TSortableCol | null
): PagedQueryState<TVariables, TSortableCol> {
  if (colNameToSortParam == null) {
    return resetSortState(currentQueryState);
  }
  return NEL.fromArray(model).caseOf<PagedQueryState<TVariables, TSortableCol>>({
    Nothing: () => resetSortState(currentQueryState),
    Just: (models) => {
      const model = NEL.head(models);
      if (model.sort) {
        return {
          ...currentQueryState,
          sortVars: {
            sortDirection: model.sort == "asc" ? SortDirection.ASC : SortDirection.DESC_NULLS_LAST,
            //TODO: is there a better way to do this????
            sortBy: colNameToSortParam(model.field),
          },
        };
      }
      return { ...currentQueryState, sortVars: resetSort };
    },
  });
}

function paginationModelFromPageInfo(
  q: QueryPage,
  totalRows: number,
  defaultPageSize: number | undefined,
  currentPage: number,
  selection: PageSelection
): GridPaginationModel {
  const pageSize = defaultPageSize ?? q.first ?? q.last ?? GLOBAL_PAGE_SIZE_DEFAULT;
  switch (selection) {
    case "first":
      return {
        page: 0,
        pageSize,
      };
    case "last":
      return {
        page: pageSize === 0 ? 1 : Math.floor(totalRows / pageSize),
        pageSize,
      };
    case "next":
      return {
        page: currentPage + 1,
        pageSize,
      };
    case "prev":
      return {
        page: currentPage - 1,
        pageSize,
      };
  }
}

function newPageState<TVariables, TSortableCol>(
  newPage: GridPaginationModel,
  pageInfo: PageInfo,
  currentQueryState: PagedQueryState<TVariables, TSortableCol>
): PagedQueryState<TVariables, TSortableCol> {
  let newNextPageInfo = resetPage(newPage.pageSize);

  if (newPage.pageSize !== currentQueryState.paginationModel.pageSize) {
    if (currentQueryState.pageVars.first) {
      newNextPageInfo = {
        ...currentQueryState.pageVars,
        first: newPage.pageSize,
      };
    } else if (currentQueryState.pageVars.last) {
      newNextPageInfo = {
        ...currentQueryState.pageVars,
        last: newPage.pageSize,
      };
    }
  } else if (newPage.page > currentQueryState.paginationModel.page) {
    newNextPageInfo = {
      first: newPage.pageSize,
      after: pageInfo.endCursor,
      before: null,
      last: null,
    };
  } else {
    newNextPageInfo = {
      last: newPage.pageSize,
      before: pageInfo.startCursor,
      first: null,
      after: null,
    };
  }

  return {
    ...currentQueryState,
    paginationModel: newPage,
    pageVars: {
      ...newNextPageInfo,
    },
  };
}

/**
 * @returns simple shim to return whether the feature flag for export is on
 */
function useShowExportToolbarFromFlag() {
  return useIsFrontendFlagEnabled(DATA_TABLE_RESET_EXPORT);
}

/**
 * Allows you to persistently keep table state
 * Based on https://next.mui.com/x/react-data-grid/state/#save-and-restore-the-state-from-external-storage
 * @returns [
 *   the initial state,
 *   the apiRef, which needs to be plugged into the DataGrid,
 *   a function to force the save of the current state.
 *   a function to clear the storage to allow resetting.
 * ]
 */
function useDataGridState(
  storageKey?: string,
  apiRef?: React.MutableRefObject<GridApi> | undefined,
  defaultInitialState?: GridInitialStateCommunity | undefined
): [GridInitialState | undefined, React.MutableRefObject<GridApi>, () => void, () => void] {
  const apiRefWithNew = apiRef || useGridApiRef();

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const stateFromLocalStorage = storageKey ? localStorage?.getItem(storageKey) : undefined;

  const resetInitialState = stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : defaultInitialState;

  const [initialState, setInitialState] = React.useState<GridInitialState | undefined>(resetInitialState);

  const forceSave = () => {
    // The type system seems to believe this current hook cannot be null when in fact it definitely
    // can.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (apiRefWithNew.current?.exportState) {
      const currentState = apiRefWithNew.current.exportState();
      setInitialState(currentState);
      if (storageKey) {
        localStorage.setItem(storageKey, JSON.stringify(currentState));
      }
    }
  };

  const saveSnapshot = React.useCallback(() => {
    forceSave();
  }, [apiRefWithNew]);

  React.useLayoutEffect(() => {
    if (storageKey) {
      // handle refresh and navigating away/refreshing
      window.addEventListener("beforeunload", saveSnapshot);

      return () => {
        // in case of an SPA remove the event-listener
        window.removeEventListener("beforeunload", saveSnapshot);
        saveSnapshot();
      };
    } else {
      return () => {};
    }
  }, [saveSnapshot]);

  const onReset = () => {
    if (storageKey) {
      localStorage.removeItem(storageKey);
    }
    setInitialState(defaultInitialState);
  };

  return [initialState, apiRefWithNew, forceSave, onReset];
}

function CustomToolbar(props: { onReset: () => void }) {
  const { t } = useTranslation(["common"]);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      <Button size="small" startIcon={<Clear />} onClick={props.onReset}>
        {t("common:filters.resetColumns")}
      </Button>
    </GridToolbarContainer>
  );
}

type FooterWithMessageProps = {
  message: string | undefined;
};

// This replaces the data grid footer wholesale. The implementation here is based on replicating what it appeears to
// be doing visually. As far as I can tell neither <GridFooter> nor <GridPagination> take any meaningful props, so I'm
// simply omitting them.
function FooterWithMessage(props: FooterWithMessageProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
      <Typography variant="caption" marginLeft="1rem">
        {props.message}
      </Typography>
      <Box flexGrow={1} />
      <GridPagination />
    </Stack>
  );
}

type MobileViewProps<T extends GridValidRowModel> = {
  mobileCard: (props: { row: T }) => ReactElement;
  resultData: ResultData<T> | null;
  queryPage: QueryPage;
  setPagination: (q: QueryPage, selection: PageSelection) => void;
  missingRowMessage: string | undefined;
};

function MobileView<T extends GridValidRowModel>(props: MobileViewProps<T>) {
  if (!props.resultData?.nodes) {
    return <div>No Data</div>;
  }
  const rows = props.resultData.nodes.map((row, index) => {
    return React.createElement(props.mobileCard, { key: index, row: row });
  });

  return (
    <Stack spacing={0.5} alignItems="center">
      {rows}
      <Typography variant="caption">{props.missingRowMessage}</Typography>
      <Paginator
        pagination={props.queryPage}
        pageInfo={props.resultData.pageInfo}
        onChange={props.setPagination}
        key="paginator"
      />
    </Stack>
  );
}

function gridSortItemToSortInfo<TSortableCol>(
  item: GridSortItem | undefined,
  defaultSortParams: SortInfo<TSortableCol> | undefined,
  colNameToSortParam: (name: string) => TSortableCol | null
) {
  if (item === undefined) {
    return defaultSortParams || resetSort;
  }

  const sortCol = colNameToSortParam(item.field);
  const sortDir = item.sort === "asc" ? SortDirection.ASC : SortDirection.DESC;
  return {
    sortBy: sortCol,
    sortDirection: sortDir,
  };
}

export { useDataGridState, useShowExportToolbarFromFlag };

export default SortablePagableCollectionDataGrid;
