import React, { ReactElement } from "react";
import {
  alertValue,
  ClinicalAlertType,
  ClinicalAlertValue,
  CareEpisodeClinicalAlertHistory,
  CareEpisodeClinicalAlertId,
  alertColor,
} from "./CareEpisodeClinicalAlert";
import { Chip, ChipSize } from "Layout/Chip";
import { useTheme } from "@mui/material";

type AlertStatusChipProps = {
  alertStatus: ClinicalAlertValue;
  alertType: ClinicalAlertType;
  onClick?: () => void;
};

function AlertStatusChip(props: AlertStatusChipProps): ReactElement {
  const { alertStatus, alertType, onClick } = props;

  const theme = useTheme();
  const colors = alertColor(alertStatus, theme);

  return (
    <Chip
      label={alertValue(alertType, alertStatus)}
      labelColor={colors.offset}
      backgroundColor={colors.primary}
      size={ChipSize.HeaderChipSize()}
      onClick={onClick}
    />
  );
}

function NotMeasuredAlertChip(): ReactElement {
  const theme = useTheme();
  return (
    <Chip
      label="Not Measured"
      labelColor={theme.palette.text.primary}
      backgroundColor={theme.palette.background.default}
      size={ChipSize.HeaderChipSize()}
    />
  );
}

type LatestAlertChipProps = {
  alertHistory: CareEpisodeClinicalAlertHistory;
  onClick?: (id: CareEpisodeClinicalAlertId) => void;
};

function LatestAlertChip(props: LatestAlertChipProps): ReactElement {
  const { alertHistory, onClick } = props;

  return alertHistory.latest.caseOf({
    Just: (alertValue) => {
      const onChipClick = onClick ? () => onClick(alertValue.id) : undefined;

      return (
        <AlertStatusChip
          alertStatus={alertValue.status}
          alertType={alertHistory.alertType}
          onClick={onChipClick}
        />
      );
    },
    Nothing: () => <NotMeasuredAlertChip />,
  });
}

export { LatestAlertChip, AlertStatusChip, NotMeasuredAlertChip };
