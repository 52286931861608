// This file contains all of the scripts we need to get the mirah plugin redirects
// working correctly. It's just a straight copy from the ember version, but tweaked
// for typescript and react.

// We need to replace the subdomain with internal to make the redirect
// work correctly. This is sadly not elegant to do.
export const getDomain = (hostname: string) => {
  const domains = hostname.split(".");
  const relevantDomains = {
    1: () => {
      // localhost
      return domains;
    },
    2: () => {
      if (domains.includes("localhost")) {
        return [domains[1]];
      } else {
        // mirah.com
        return domains;
      }
    },
    3: () => {
      // sub.mirah.com
      return [domains[1], domains[2]];
    },
    4: () => {
      // sub.qa.mirah.dev
      return [domains[1], domains[2], domains[3]];
    },
  };
  return relevantDomains[domains.length as keyof typeof relevantDomains]().join(".");
};

// Handles redirecting internal users.
export const redirectInternalUser = (instituteId: string) => {
  // This preserves the original intended destination even though
  // the current url should be pointing to the login page.
  const intendedDestination = `${window.location.pathname}${window.location.search}`;

  const protocol = window.location.protocol;
  const host = `${getDomain(window.location.hostname)}:${window.location.port}`;
  const path = `${protocol}//internal.${host}/app/ops/redirect`;

  const redirectUrl = `${path}?destination=${encodeURIComponent(
    intendedDestination
  )}&institute=${encodeURIComponent(instituteId)}`;

  window.location.assign(redirectUrl);
  return;
};

// Checks to see if this is already an internal redirect.
// We want to let the normal auth flow if so.
const isInternalRedirect = () => {
  const redirect = new URLSearchParams(window.location.search).get("internal_redirect");
  return redirect;
};

// Checks for the div inserted by our chrome plugin that reports
// the version number of the plugin.
export const hasPlugin = () => {
  const pluginDiv = document.getElementById("mirah-plugin-version");
  if (pluginDiv) {
    return true;
  }
  return false;
};

// Whether or not we should do a redirect for internal users.
export const shouldInternalRedirect = () => {
  const isInternalDomain = window.location.hostname.split(".")[0] == "internal";
  return hasPlugin() && !isInternalDomain && !isInternalRedirect();
};
