import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { Inbox } from "./Inbox";
import { Algorithms } from "./Algorithms";

export function DecisionSupportRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<Inbox />} path="inbox/*" />
      <Route element={<Algorithms />} path="algorithms/*" />
    </Routes>
  );
}
