import {
  DialogContent,
  DialogContentText,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useGenerateUserLoginCodeMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientSessionId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";

type KeycodeLoginDialogProps = {
  patientSessionId: PatientSessionId;
  open: boolean;
  onClose: () => void;
};

const KeycodeLoginDialog = (props: KeycodeLoginDialogProps): ReactElement => {
  const [generateLoginCodes, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.generateUserLoginCode,
    useGenerateUserLoginCodeMutation()
  );

  const { t } = useTranslation(["dashboard"]);

  const { open } = props;

  useEffect(() => {
    // We want to immediately trigger the request when the dialog opens
    if (open) {
      generateLoginCodes({ variables: { input: { patientSessionId: props.patientSessionId } } });
    }
  }, [open]);

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (error) => {
      const message = error.caseOf({
        apolloError: (aerr) => aerr.message,
        // This is quite bad, but in order to actually ship this page ever I'm declaring
        // better error handling patterns out of scope. We have to make time for them soon though.
        userError: (uerr) => uerr.map((e) => e.message).join(", "),
      });
      return <ErrorMessage message={message} />;
    },
    Success: (response) => {
      let content: ReactElement | null = null;
      if (response?.loginCodes && response.loginCodes.length > 0) {
        const header = (
          <TableHead>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                {t("dashboard:keycodeLogin.name")}
              </TableCell>
              <TableCell component="th" scope="row" align="center">
                {t("dashboard:keycodeLogin.code")}
              </TableCell>
            </TableRow>
          </TableHead>
        );
        const rows = response.loginCodes.map((row) => {
          return (
            <TableRow key={row.code}>
              <TableCell>{row.user.name}</TableCell>
              <TableCell>
                <pre>{row.code}</pre>
              </TableCell>
            </TableRow>
          );
        });
        content = (
          <>
            <Table>
              {header}
              <TableBody>{rows}</TableBody>
            </Table>
            <Typography> {t("dashboard:keycodeLogin.timing")}</Typography>
          </>
        );
      } else {
        content = <DialogContentText>"There are no login codes"</DialogContentText>;
      }
      return content;
    },
  });

  return (
    <ResponsiveDialog title="Key Code Login" open={props.open} onClose={props.onClose}>
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
};

export default KeycodeLoginDialog;
