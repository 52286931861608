import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EnrollmentIntakeSessionInvitationsDocument,
  IntakeStatus,
  Invitation,
  InvitationFormat,
  InvitationStatus,
  ReportScale,
  Scale,
  User,
  useEnrollmentCheckIntakeScalesCompleteMutation,
  useEnrollmentIntakeSessionInvitationsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import Link from "MDS/Link";
import { ShadedTable } from "MDS/ShadedTable";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { CheckCircleOutline } from "@mui/icons-material";

type IntakeManualAdministrationLinksProps = {
  enrollmentId: EnrollmentId;
  onClose: () => void;
};

export function IntakeManualAdministrationLinks(props: IntakeManualAdministrationLinksProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(
    useEnrollmentIntakeSessionInvitationsQuery({ variables: { enrollmentId: props.enrollmentId } })
  );

  const invitations = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareEnrollment) {
        return response.collaborativeCareEnrollment.intakeSession?.assessmentReport?.invitations || [];
      } else {
        return [];
      }
    },
    _: () => [],
  });

  const selfReportInvitations = invitations.filter(
    (invite) => invite.format === InvitationFormat.SELF_REPORT
  );
  const interviewInvitations = invitations.filter((invite) => invite.format === InvitationFormat.INTERVIEW);

  const enableClose = remoteData.caseOf({
    Success: (response) => response.collaborativeCareEnrollment?.intakeStatus === IntakeStatus.COMPLETE,
    _: () => false,
  });

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={1} divider={<Divider />}>
          <AdministerInvitationsSection
            invitations={interviewInvitations}
            enrollmentId={props.enrollmentId}
          />
          <SentInvitationsSection
            invitations={selfReportInvitations}
            scalesAdministered={interviewInvitations}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box flexGrow={3} />
        <Button variant="contained" color="secondary" disabled={!enableClose} onClick={() => props.onClose()}>
          {t("collaborativeCare:enrollment.actions.intake")}
        </Button>
      </DialogActions>
    </>
  );
}

type ReportScaleSummary = PickTypename<ReportScale, "id"> & {
  scale: Pick<Scale, "id" | "shortname">;
};

type InvitationSummary = PickTypename<Invitation, "id" | "format" | "status"> & {
  user: PickTypename<User, "id" | "name">;
  reportScales: ReadonlyArray<ReportScaleSummary>;
};

type AdministerInvitationsSectionProps = {
  enrollmentId: EnrollmentId;
  invitations: ReadonlyArray<InvitationSummary>;
};

function AdministerInvitationsSection(props: AdministerInvitationsSectionProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  if (props.invitations.length === 0) {
    return null;
  }

  return (
    <>
      <Typography>{t("collaborativeCare:enrollment.manualMeasuresPrompt")}</Typography>
      <ShadedTable>
        <TableBody>
          {props.invitations.map((invite) => {
            return (
              <AdministerInvitationRow
                invitation={invite}
                key={invite.id.toString()}
                enrollmentId={props.enrollmentId}
              />
            );
          })}
        </TableBody>
      </ShadedTable>
    </>
  );
}

type AdministerInvitationRowProps = {
  enrollmentId: EnrollmentId;
  invitation: InvitationSummary;
};

function AdministerInvitationRow(props: AdministerInvitationRowProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [checkStatus, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEnrollmentCheckIntakeScalesComplete,
    useEnrollmentCheckIntakeScalesCompleteMutation({
      variables: { input: { enrollmentId: props.enrollmentId } },
      // TODO - For whatever reason, if you create a task on enrollment, apollo will update the
      // invitation.status in the cache, but not re-render this prop
      // if you don't add a task this works fine.
      refetchQueries: [EnrollmentIntakeSessionInvitationsDocument],
    })
  );

  const link =
    props.invitation.status === InvitationStatus.COMPLETE ? (
      <Stack direction="row" spacing={0.5}>
        <CheckCircleOutline color="success" />
        <Typography>{t("collaborativeCare:enrollment.surveyComplete")}</Typography>
      </Stack>
    ) : (
      <Stack direction="row" spacing={3}>
        <Link to={`/provider/assessment/invitation/${props.invitation.id}/edit`} target="_blank">
          <Button variant="contained" color="secondary">
            {t("collaborativeCare:enrollment.actions.administerSurvey", {
              count: props.invitation.reportScales.length,
            })}
          </Button>
        </Link>
        <ButtonWithSpinner
          variant="outlined"
          color="secondary"
          onClick={() => checkStatus()}
          showSpinner={remoteData.kind === "Loading"}
          disabled={remoteData.kind === "Loading"}
        >
          {t("collaborativeCare:enrollment.actions.checkSurveyComplete")}
        </ButtonWithSpinner>
      </Stack>
    );
  useEffect(() => {
    checkStatus();
  }, []);
  return (
    <TableRow key={props.invitation.id.toString()}>
      <TableCell>{props.invitation.user.name}</TableCell>
      <TableCell>{link}</TableCell>
    </TableRow>
  );
}

type SentInvitationSectionProps = {
  invitations: ReadonlyArray<InvitationSummary>;
  scalesAdministered: ReadonlyArray<InvitationSummary>;
};

function SentInvitationsSection(props: SentInvitationSectionProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  if (props.invitations.length === 0) {
    if (props.scalesAdministered.length == 0)
      return (
        <>
          <Typography>{t("collaborativeCare:enrollment.notifyMissingRecipientsPrompt")}</Typography>
        </>
      );
    else return null;
  }

  return (
    <>
      <Typography>{t("collaborativeCare:enrollment.notifyMeasuresPrompt")}</Typography>
      <ShadedTable>
        <TableBody>
          {props.invitations.map((invite) => {
            return invite.reportScales.map((reportScale) => {
              return (
                <TableRow key={reportScale.id.toString()}>
                  <TableCell>{invite.user.name}</TableCell>
                  <TableCell>{reportScale.scale.shortname}</TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </ShadedTable>
    </>
  );
}
