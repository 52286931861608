import { Stack, useTheme } from "@mui/material";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/PanelManagement/GlobalPanelIndicator";
import { ProviderId } from "Lib/Ids";
import {
  CareManagerSelectSingle,
  PrimaryCareProviderSelectSingle,
} from "Shared/Filters/ProviderSelectSingle";
import { ResetAndStickyFilterButtonGroup, useStickyOptionalIdParameter } from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React, { ReactElement } from "react";

export type CaseConsultListFilters = {
  pcp: ProviderId | null;
  setPcp: (value: ProviderId | null) => void;
  careManager: ProviderId | null;
  setCareManager: (value: ProviderId | null) => void;
};

const DEFAULTS = {
  pcpId: null,
  cmId: null,
};

export function useCaseConsultListFilters(): CaseConsultListFilters {
  const [pcp, setPcp] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.PCP,
    STICKY_PARAMETER_FILTER_SETS.COCM_CASE_CONSULT,
    true
  );
  const [careManager, setCareManager] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.CARE_MANAGER,
    STICKY_PARAMETER_FILTER_SETS.COCM_CASE_CONSULT,
    true
  );

  return {
    pcp,
    setPcp,
    careManager,
    setCareManager,
  };
}

type CaseConsultListFilterProps = {
  filters: CaseConsultListFilters;
};

export function CaseConsultListFilters(props: CaseConsultListFilterProps): ReactElement {
  const theme = useTheme();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <PrimaryCareProviderSelectSingle
        value={props.filters.pcp}
        setValue={props.filters.setPcp}
        autocompleteProps={{
          fullWidth: false,
          sx: { width: "20rem", backgroundColor: theme.palette.background.paper },
        }}
        defaultValue={DEFAULTS.pcpId}
      />
      <CareManagerSelectSingle
        value={props.filters.careManager}
        setValue={props.filters.setCareManager}
        autocompleteProps={{
          fullWidth: false,
          sx: { width: "20rem", backgroundColor: theme.palette.background.paper },
        }}
        defaultValue={DEFAULTS.cmId}
      />
      <GlobalPanelIndicatorBadge />
      <ResetAndStickyFilterButtonGroup
        onReset={() => {
          props.filters.setCareManager(DEFAULTS.cmId);
          props.filters.setPcp(DEFAULTS.pcpId);
        }}
      />
    </Stack>
  );
}
