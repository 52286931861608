import { Axis } from "@visx/xychart";
import React, { ReactElement } from "react";
import { parseBounds, ScaleWithNumericalScorer } from "Shared/Scale/Scale";
import * as Threshold from "Shared/Scale/Threshold";

type AxisRecord = Record<number, { val: number; name: string }>;

function ScaleThresholdAxis({ scale }: { scale: ScaleWithNumericalScorer }): ReactElement {
  const hasThresholds = (scale.scorer.thresholdData?.length || 0) > 0;
  const ticks =
    scale.scorer.thresholdData?.reduce<AxisRecord>((map, t) => {
      const val = t.minValue + (t.maxValue - t.minValue) / 2;
      map[val] = {
        val,
        name: Threshold.mnemonicShortTitle(t.mnemonic, { maxWordLength: 8, desiredTitleLength: 20 }),
      };
      return map;
    }, {}) || {};

  const endpoints = parseBounds(scale.scorer).reduce<AxisRecord>((map, b) => {
    b.interpretation.caseOf({
      Just: (name) => {
        map[b.value] = {
          val: b.value,
          name,
        };
      },
      Nothing: () => null,
    });

    return map;
  }, {});

  const leftAxis = hasThresholds ? (
    <Axis
      tickValues={Object.entries(ticks).map((value) => value[1].val)}
      tickFormat={(tickVal: number) => ticks[tickVal]?.name}
      tickLabelProps={() => ({ angle: -50 })}
      orientation={"left"}
      hideTicks={true}
    />
  ) : (
    <Axis
      orientation={"left"}
      tickValues={Object.entries(endpoints).map((value) => value[1].val)}
      tickFormat={(tickVal: number) => endpoints[tickVal]?.name}
      tickLabelProps={() => ({ angle: -50 })}
    />
  );

  return (
    <>
      {leftAxis}
      <Axis orientation={"right"} />
    </>
  );
}

export default ScaleThresholdAxis;
