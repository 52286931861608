import { Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function InboxSummary(): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);

  return (
    <Stack direction="column" spacing={1} alignItems="center" margin="3rem">
      <Typography variant="h1" component="h1">
        {t("decisionSupport:inbox.noSelectedWorkflow.title")}
      </Typography>
      <Typography variant="body1">{t("decisionSupport:inbox.noSelectedWorkflow.body")}</Typography>
    </Stack>
  );
}
