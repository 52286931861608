import { Group } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { WithInternalUser } from "AppSession/AuthenticatedProviderUser";
import { getImpersonatingUserId, setImpersonatingUserId } from "AppSession/ImpersonatingUser";
import { useProviderUserQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { AppLayoutUserMenuItem } from "Layout/AppLayout/AppLayout";
import Page from "Layout/Page";
import { UserId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import UserSelect from "Shared/Filters/UserSelect";
import RouterWithNotFound from "Shared/RouterWithNotFound";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";

function ImpersonatingUserRoute() {
  return (
    <WithInternalUser>
      <RouterWithNotFound>
        <Route element={<ImpersonatingUserPage />} path={"/"} />
      </RouterWithNotFound>
    </WithInternalUser>
  );
}

function ImpersonatingUserPage() {
  const { t } = useTranslation(["settings", "common"]);
  const impersonatingUserId = getImpersonatingUserId().getOrElse(null);
  // This state exists simply to force a render when it is updated as otherwise it does not
  const [_lastImpersonatingUser, setLastImpersonatingUser] = useState<UserId | null>(impersonatingUserId);
  // This manages the select box
  const [selectedUserId, setSelectedUserId] = useState<UserId | null>(impersonatingUserId);

  const { remoteData } = apolloQueryHookWrapper(
    useProviderUserQuery({
      variables: {
        id: assertNonNull(impersonatingUserId),
      },
      skip: !impersonatingUserId,
    })
  );

  const username = remoteData.caseOf({
    Success: (userData) => {
      return userData.user?.name;
    },
    Failure: (error) => error.message,
    _: () => t("common:remoteData.loading"),
  });

  const content = impersonatingUserId ? (
    <>
      <Typography>{t("settings:impersonatingUser.currentlyAssuming", { name: username })}</Typography>
      <Button
        variant="contained"
        onClick={() => {
          setImpersonatingUserId(null);
          setLastImpersonatingUser(null);
        }}
      >
        {t("settings:impersonatingUser.stopAssuming")}
      </Button>
    </>
  ) : (
    <>
      <UserSelect
        value={impersonatingUserId}
        setValue={(value) => {
          setSelectedUserId(value);
        }}
        provider={true}
      />
      <Button
        variant="contained"
        onClick={() => {
          setImpersonatingUserId(selectedUserId);
          setLastImpersonatingUser(selectedUserId);
        }}
        disabled={!selectedUserId}
      >
        {t("settings:impersonatingUser.title")}
      </Button>
    </>
  );

  return (
    <Page browserTitle={t("settings:impersonatingUser.title")}>
      <Stack direction="column" spacing={1}>
        <Box sx={{ background: "white" }}>
          <Typography>{t("settings:impersonatingUser.explanation")}</Typography>
        </Box>
        <Box sx={{ background: "white" }}>{content}</Box>
      </Stack>
    </Page>
  );
}

function ImpersonatingUserMenuItem(): AppLayoutUserMenuItem {
  const { t } = useTranslation(["settings"]);

  const impersonatingUserId = getImpersonatingUserId().getOrElse(null);

  const { remoteData } = apolloQueryHookWrapper(
    useProviderUserQuery({
      skip: !impersonatingUserId,
      variables: {
        id: assertNonNull(impersonatingUserId),
      },
    })
  );

  let label = t("settings:impersonatingUser.menuItemBlank");

  remoteData.caseOf({
    Success: (data) => {
      if (data.user) {
        label = t("settings:impersonatingUser.menuItemActive", { name: data.user.name });
      }
    },
    _: () => null,
  });

  return {
    icon: <Group />,
    label: label,
    location: "impersonation",
  };
}

export { ImpersonatingUserRoute, ImpersonatingUserMenuItem };
