import { Card, CardHeader, CardContent } from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PatientDetails } from "CollaborativeCare/PatientDetails/PatientDetails";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import SurveyManagementTableDesktop from "./SurveyManagementTableDesktop";
import SurveyManagementTableMobile from "./SurveyManagementTableMobile";
import { CareEpisodeId } from "Lib/Ids";
import { IntakeStatus } from "GeneratedGraphQL/SchemaAndOperations";

export default function SurveyManagement(props: {
  patient: PatientDetails;
  mobile: boolean;
  forPreviousEnrollment?: boolean;
  careEpisodeId?: CareEpisodeId;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  //if we're going in a previous enrollment, the user shouldn't be able to interact with anything
  if (props.forPreviousEnrollment && props.careEpisodeId) {
    const careEpisodeId = props.careEpisodeId;
    if (props.mobile) {
      return (
        <SurveyManagementTableMobile
          patient={props.patient}
          careEpisodeId={careEpisodeId}
          forPreviousEnrollment={props.forPreviousEnrollment}
          intakeStatus={"enrolled" as IntakeStatus}
        />
      );
    } else {
      return (
        <SurveyManagementTableDesktop
          patient={props.patient}
          careEpisodeId={careEpisodeId}
          forPreviousEnrollment={props.forPreviousEnrollment}
          intakeStatus={"enrolled" as IntakeStatus}
        />
      );
    }
  } else {
    const enrollmentState = useEnrollmentState(props.patient.id);
    switch (enrollmentState.status) {
      case "not-enrolled": {
        return (
          <Card>
            <CardHeader title={t("collaborativeCare:surveyManagement.title")} />
            <CardContent>{t("collaborativeCare:patientDetails.surveyHistory.notEnrolled")}</CardContent>
          </Card>
        );
        break;
      }
      case "unknown": {
        return (
          <Card>
            <CardHeader title={t("collaborativeCare:surveyManagement.title")} />
            <CardContent>{t("collaborativeCare:surveyManagement.loading")}</CardContent>
          </Card>
        );
        break;
      }
      case "enrolled": {
        const careEpisodeId = enrollmentState.careEpisodeId;
        if (props.mobile) {
          return (
            <SurveyManagementTableMobile
              patient={props.patient}
              careEpisodeId={careEpisodeId}
              intakeStatus={enrollmentState.intakeStatus}
            />
          );
        } else {
          return (
            <SurveyManagementTableDesktop
              patient={props.patient}
              careEpisodeId={careEpisodeId}
              intakeStatus={enrollmentState.intakeStatus}
            />
          );
        }
        break;
      }
    }
  }
}
