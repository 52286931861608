import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import RouterWithNotFound from "Shared/RouterWithNotFound";
import { TaskForm } from "./TaskForm";
import { TaskHistory } from "./TaskHistory";
import { TaskList } from "./TaskList";

export function TaskRunnerRoute(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<TaskList />} path="/" />
      <Route element={<TaskForm />} path="/:taskShortcode/run" />
      <Route element={<TaskHistory />} path="/history" />
    </RouterWithNotFound>
  );
}
