import { OrderedOrLast, sort, sortOrderedOrLast } from "Lib/Utils";
import { Just, Maybe, Nothing } from "seidr";
import { ParticipantRelationship } from "Shared/Participant";
import * as C from "Shared/Scale/Constructs";
import { scaleFriendlyName } from "Shared/Scale/Scale";
import { ScaleSectionSummary, graphableScaleSections } from "Shared/Scale/ScaleSection";

type ConstructData = {
  scaleSections: Array<ScaleSectionSummary>;
  displayOrder: OrderedOrLast;
  construct: Maybe<C.Construct>;
};

type DomainData = {
  domain: Maybe<C.Domain>;
  displayOrder: OrderedOrLast;
  constructs: Array<ConstructData>;
};

function toDomainSections(data: Array<C.ConstructSection>) {
  return data.reduce<
    Record<
      string,
      {
        domain: Maybe<C.Domain>;
        displayOrder: OrderedOrLast;
        constructs: Record<string, ConstructData>;
      }
    >
  >((obj, curr) => {
    curr.construct.caseOf({
      Just(construct) {
        const key = construct.domain.id.toString();
        const constructKey = construct.id.toString();
        let currentObject = obj[key];
        if (currentObject) {
          currentObject.displayOrder = currentObject.displayOrder.min(
            OrderedOrLast.Ordered(construct.displayOrder)
          );
        } else {
          currentObject = {
            domain: Just(construct.domain),
            displayOrder: OrderedOrLast.Ordered(construct.displayOrder),
            constructs: {},
          };
        }

        let constructObject = currentObject.constructs[constructKey];

        if (constructObject) {
          constructObject.scaleSections = constructObject.scaleSections.concat(curr.scaleSections);
        } else {
          constructObject = {
            construct: Just(construct),
            displayOrder: OrderedOrLast.Ordered(construct.displayOrder),
            scaleSections: curr.scaleSections,
          };
        }
        currentObject.constructs[constructKey] = constructObject;

        obj[key] = currentObject;
      },
      Nothing() {
        const foundOther = obj["Other"];
        const baseOtherConstruct = {
          displayOrder: OrderedOrLast.Last(),
          construct: Nothing(),
          scaleSections: curr.scaleSections,
        };
        if (!foundOther) {
          obj["Other"] = {
            domain: Nothing(),
            displayOrder: OrderedOrLast.Last(),
            constructs: {
              Other: baseOtherConstruct,
            },
          };
        } else {
          const foundOtherOther = foundOther.constructs["Other"] || baseOtherConstruct;

          obj["Other"] = {
            ...foundOther,
            constructs: {
              ...foundOther.constructs,
              Other: {
                ...foundOtherOther,
                scaleSections: foundOtherOther.scaleSections.concat(curr.scaleSections),
              },
            },
          };
        }
      },
    });

    return obj;
  }, {});
}

/**
 * Until we decide to send domains at the top level, we need to parse construct sections into domain sections,
 * filter the ones that can be displayed and order them
 * @param sections Construct sections
 */
function toViewableDomainSections(sections: ReadonlyArray<C.ConstructSection>): ReadonlyArray<DomainData> {
  const constructsWithData = sections.filter((construct) => {
    return graphableScaleSections(construct.scaleSections).length > 0;
  });

  const byDomain = toDomainSections(constructsWithData);

  const sortedGroups = sort(
    (a, b) => sortOrderedOrLast(a.displayOrder, b.displayOrder),
    Object.values(byDomain)
  );

  const sortedScalesInGroups = sortedGroups.map<DomainData>((value) => ({
    ...value,
    constructs: sort(
      (a, b) => sortOrderedOrLast(a.displayOrder, b.displayOrder),
      Object.values(value.constructs).map<ConstructData>((constructValue) => ({
        ...constructValue,
        scaleSections: sort(sortScaleSectionSummaries, constructValue.scaleSections),
      }))
    ),
  }));

  return sortedScalesInGroups;
}

function sortScaleSectionSummaries(a: ScaleSectionSummary, b: ScaleSectionSummary): number {
  return a.caseOf({
    IndividualScaleSection: (a) => {
      return b.caseOf({
        IndividualScaleSection: (b) => {
          const pa = a.participant;
          const pb = b.participant;
          if (pa.participantId.toString() === pb.participantId.toString()) {
            return scaleFriendlyName(a.scale).localeCompare(scaleFriendlyName(b.scale));
          }
          if (pa.relationship === ParticipantRelationship.PATIENT) {
            return -1;
          }
          if (pb.relationship === ParticipantRelationship.PATIENT) {
            return 1;
          }
          return pa.participantId.toString().localeCompare(pb.participantId.toString());
        },
        ScaleGroupSection: (_b) => -1,
      });
    },
    ScaleGroupSection: (a) => {
      return b.caseOf({
        IndividualScaleSection: (_b) => 1,
        ScaleGroupSection: (b) => a.scaleGroup.name.localeCompare(b.scaleGroup.name),
      });
    },
  });
}

const Test = {
  sortScaleSectionSummaries,
};

export { toViewableDomainSections, Test };
