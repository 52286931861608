import { Edit } from "@mui/icons-material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { CareEpisodeComputedValueWithScaleAndAnswersFragment } from "GeneratedGraphQL/SchemaAndOperations";
import Id from "Lib/Id";
import React, { ReactElement } from "react";

type Props = {
  invitationId: Id<"Invitation">;
};

export function EditResponse(props: Props): ReactElement {
  return (
    <Box sx={{ ml: 0.5 }}>
      <Link to={`/provider/assessment/invitation/${props.invitationId.toString()}/edit`}>
        <IconButton sx={{ p: 0.5, border: "1px solid black", borderRadius: "4px", padding: "4px" }}>
          <Edit sx={{ fontSize: 18 }} />
        </IconButton>
      </Link>
    </Box>
  );
}

// Several of our cases for wanting edit responses actually can come from multiple invitations!
// This gives us one link for each of those invitations. It's just a blast of edit buttons and isn't
// great but lets folks get at the data.
type EditResponsesProps = {
  scaleScore: CareEpisodeComputedValueWithScaleAndAnswersFragment;
};

export function EditResponses(props: EditResponsesProps): ReactElement {
  const { scaleScore } = props;
  let editResponseLinks: Array<ReactElement> = [];
  if (scaleScore.report) {
    // We're going to look through the report for invitations matching this user and scale.
    // For each of those, we'll be able to generate a report edit link back to ember.
    const matchedInvitations = scaleScore.report.invitations.filter((invitation) => {
      let matchedUser = false; // Whether or not this invitation has the correct user.
      if (scaleScore.user) {
        matchedUser = invitation.user.id === scaleScore.user.id;
      }

      // Whether or not this invitation has the correct scale.
      const matchedScale = invitation.reportScales.find((reportScale) => {
        return (
          reportScale.scale.id === scaleScore.scale.id || reportScale.scale.id === scaleScore.scale.parentId
        );
      });

      return !!(matchedUser && matchedScale);
    });

    editResponseLinks = matchedInvitations.map((invitation) => {
      return <EditResponse key={invitation.id.toString()} invitationId={invitation.id} />;
    });
  }

  return <>{editResponseLinks}</>;
}

export default EditResponses;
