import React, { ReactElement } from "react";
import { CircularProgress, CircularProgressProps, SxProps, Theme } from "@mui/material";

type SpinnerProps = {
  size?: number | string;
  sx?: SxProps<Theme> | undefined;
} & Pick<CircularProgressProps, "color">;

function Spinner(props: SpinnerProps): ReactElement {
  // 40 (pixels) is the default size defined here https://mui.com/api/circular-progress/
  const size = props.size || 40;

  return <CircularProgress color={props.color} size={size} data-testid="loading-indicator" sx={props.sx} />;
}

export default Spinner;
