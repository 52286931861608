import React, { ReactElement } from "react";

/**
 * This context describes the circumstances under which the application was launched. For most users most of the time
 * this will be in a normal browser by opening a bookmark or something, but for some users it will be something more
 * exotic. In particular this tracks:
 *   * Whether the app was launched from an EHR or not
 *   * If launched from an EHR, what context was it launched from (no context, patient chart, etc)
 *   * Is the app embedded in an iframe or similar container
 *
 * In theory each of those can be set independently, in practice we care about combinations like [true, patient, true],
 * which corresponds to running in an Epic sidecar rather than a regular app.
 *
 * Note that LaunchContextProvider must be mounted above the app's router in order to not erroneously recompute the
 * launch context on route changes. That means that this has to function in an unauthenticated context.
 */

type LaunchContexts = "none" | "patient" | "encounter";

type LaunchState = {
  fromEhr: boolean;
  context: LaunchContexts | null;
  embedded: boolean;
};

export const LaunchContext = React.createContext<LaunchState>({
  fromEhr: false,
  context: null,
  embedded: false,
});

export function LaunchContextProvider(props: React.PropsWithChildren): ReactElement {
  // Note that these aren't state variables that can be updated at runtime - we observe the launch context once at
  // startup and then it's frozen until you reload the page. Normal app behavior like clicking links will change the
  // query string, so we should assume that after the first time we load the state it's no longer valid to try to
  // recompute it.
  const embedded = isEmbedded();
  const queryState = queryStringLaunchState();

  return (
    <LaunchContext.Provider
      value={{
        fromEhr: queryState.fromEhr,
        context: queryState.context,
        embedded: embedded,
      }}
    >
      {props.children}
    </LaunchContext.Provider>
  );
}

function queryStringLaunchState(): Pick<LaunchState, "fromEhr" | "context"> {
  const query = new URLSearchParams(window.location.search);

  const fromEhr = query.get("fromEhr") === "true";
  const rawContext = query.get("launchContext");

  if (!fromEhr) {
    return {
      fromEhr: false,
      context: null,
    };
  } else if (rawContext === "none" || rawContext === "patient" || rawContext === "encounter") {
    return {
      fromEhr: true,
      context: rawContext,
    };
  } else {
    return {
      fromEhr: true,
      context: null,
    };
  }
}

function isEmbedded() {
  try {
    // Window.top is the top-most container in a stack of pages and iframes. In non-embedded circumstances, it will be
    // the same as the current window.
    return window != window.top;
  } catch {
    // In some cases, particularly Epic, accessing window.top (or window.parent) raises an exception. Consider that
    // evidence of being embedded.
    return true;
  }
}

export function useInEmbeddedPatientOrEncounter(): boolean {
  const launch = React.useContext(LaunchContext);
  return (
    launch.embedded && launch.fromEhr && (launch.context === "patient" || launch.context === "encounter")
  );
}

export function InEmbeddedPatientOrEncounter(props: React.PropsWithChildren): ReactElement | null {
  if (useInEmbeddedPatientOrEncounter()) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}

export function NotInEmbeddedPatientOrEncounter(props: React.PropsWithChildren): ReactElement | null {
  if (useInEmbeddedPatientOrEncounter()) {
    return null;
  } else {
    return <>{props.children}</>;
  }
}
