import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiTab"] = {
  root: {
    textTransform: "none",
    fontWeight: "bolder",
    paddingLeft: "0em",
    paddingBottom: "0em",
    marginRight: "1em",
  },
};

const override = {
  styleOverrides,
};

export default override;
