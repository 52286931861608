import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import * as NEL from "Lib/NonEmptyList";
import React, { ReactElement } from "react";
import { XYChart } from "@visx/xychart";
import ScaleThresholdChartBackground from "Shared/Viz/Shared/ScaleThresholdChartBackground";
import { Nothing } from "seidr";
import { CareEpisodeCategoricalComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { CategoricalHistorySeries } from "Shared/Viz/Shared/CategoricalHistorySeries";
import { HoverCircleSize } from "Shared/Viz/Shared/HoverCircle";

type CategoricalCompareGraphProps = {
  width: number;
  height: number;
  history: ReadonlyArray<CareEpisodeCategoricalComputedValueDetails>;
  dates: NEL.NonEmptyList<Date>;
  valueRoute: (value: CareEpisodeCategoricalComputedValueDetails) => FeedbackReportRoute;
};

export function CategoricalCompareGraph(props: CategoricalCompareGraphProps): ReactElement {
  const { history, dates, valueRoute, width, height } = props;

  return (
    <XYChart
      captureEvents={false}
      yScale={{ type: "linear", domain: [0, 0], zero: false }}
      xScale={{
        type: "time",
        domain: [NEL.head(dates), NEL.last(dates)],
      }}
      width={width}
      height={height}
      // Just enough margin so that data point glyphs at the edges don't get cut off
      margin={{
        top: HoverCircleSize.large.radius,
        bottom: HoverCircleSize.large.radius,
        right: HoverCircleSize.large.radius,
        left: HoverCircleSize.large.radius,
      }}
    >
      <ScaleThresholdChartBackground scale={Nothing()} />
      <CategoricalHistorySeries history={history} valueRoute={valueRoute} />
    </XYChart>
  );
}
