import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiCardContent"] = {
  root: {
    padding: "2rem",
    paddingTop: "1rem",

    "&:last-child": {
      paddingBottom: "2rem",
    },
  },
};

const override = {
  styleOverrides,
};

export default override;
