import { CareEpisode, Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { TFunction } from "i18next";
import { Link } from "MDS/Link";
import React, { ReactNode } from "react";
import { careEpisodeName, CareEpisodeNameable } from "./CareEpisodeName";

type PatientSummary = Pick<Patient, "__typename" | "id" | "name">;
type CareEpisodeBreadcrumbable = CareEpisodeNameable & Pick<CareEpisode, "id">;
type CareEpisodeBreadcrumbArgs = {
  careEpisode: CareEpisodeBreadcrumbable;
  patient: PatientSummary;
  t: TFunction<["common", "careEpisodes"]>;
};

// These are regular functions and not components because they need to return list, not a single ReactElement. In order
// for the Mui Breadcrumb component to separate its children correctly we have to pass them in as a list. However the
// jsx compiler will only correctly transform jsx style code for functions that return single ReactElement objects
// (and this is enforced by typescript), so we have to fall back to regular functions here. Don't call hooks inside
// these functions - they get called from inside a conditional in the components that use them, which is why we pass
// the translation function in as a parameter instead of calling useTranslation here - calling a hook inside a
// conditional will blow up your page with a rendered different number hooks each render error.

export function careEpisodeDetailsBreadcrumbs({
  careEpisode,
  patient,
  t,
}: CareEpisodeBreadcrumbArgs): Array<ReactNode> {
  return [
    <Link to={`/provider/patients/${patient.id}`} key={0}>
      {patient.name}
    </Link>,
    <span key={1}>{t("common:entities.careEpisode", { count: 100 })}</span>,
    <Link to={`/app/patients/${patient.id}/care-episodes/${careEpisode.id}`} key={2}>
      {careEpisodeName(careEpisode, t)}
    </Link>,
  ];
}

export function careEpisodeEditBreadcrumbs({
  careEpisode,
  patient,
  t,
}: CareEpisodeBreadcrumbArgs): Array<ReactNode> {
  return [
    ...careEpisodeDetailsBreadcrumbs({ patient, careEpisode, t }),
    <span key={3}>{t("common:actions.edit")}</span>,
  ];
}

export function careEpisodeCreateBreadcrumbs({
  patient,
  t,
}: Omit<CareEpisodeBreadcrumbArgs, "careEpisode">): Array<ReactNode> {
  return [
    <Link to={`/provider/patients/${patient.id}`} key={0}>
      {patient.name}
    </Link>,
    <span key={1}>{t("careEpisodes:createBreadcrumb", { count: 100 })}</span>,
  ];
}
