//========================================================================
// 'Raw' send request, useful for things like bootstrapping institute data,
// and hitting the authentication end points.
//========================================================================

import { RemoteData, Loading, Success, Failure, Maybe, Nothing, Just } from "seidr";
import { captureException } from "Lib/ErrorHandling";

export type HttpRemoteError = {
  jsonBody?: unknown | null;
  error: Error;
  response: Maybe<Response>;
};

export type ApiData<T> = RemoteData<HttpRemoteError, T>;

/**
 * Make an arbitrary HTTP call, and call the callback function given every time
 * there is a status change. The callback function will be called with RemoteData
 * objects.
 * As with `httpRequestWithEffect`, this does not do any type checking on the result -
 * you are asserting that it will come back in the required format. Any
 * mismatch between expectation and reality will produce runtime errors.
 * @param callback The callback function. Will be passed a remote data object.
 * @param url The url to call
 * @param init Extra http properties as defined by the fetch api.
 */
function request<T>(callback: (data: ApiData<T>) => void, url: string, init?: RequestInit): void {
  fetch(url, init)
    .then((response) => {
      const error = new Error(`Received unexpected status ${response.status}`);

      if (response.ok) {
        // 204 has no content
        if (response.status === 204) {
          return callback(Success(undefined as unknown as T));
        }

        return response.json().then((result) => {
          return callback(Success(result as T));
        });
      } else {
        if (response.bodyUsed) {
          return response
            .clone()
            .json()
            .then((json) => {
              callback(
                Failure({
                  response: Just(response),
                  jsonBody: json,
                  error,
                })
              );
            })
            .catch((error) => {
              callback(Failure({ response: Just(response), error }));
            });
        } else {
          callback(Failure({ response: Just(response), error }));
        }
      }
    })
    .catch((error) => {
      captureException(error, "Http#request");
      callback(Failure({ response: Nothing(), error }));
    });

  callback(Loading());
}

export { request };
