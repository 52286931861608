import React from "react";

import { Button, Card, CardContent, CardHeader } from "@mui/material";
import {
  DataDumpSortParameter,
  DataDumpStatus,
  ExportListQuery,
  SortDirection,
  useExportListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";

import { humanize, titleize } from "Lib/Utils";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { useTranslation } from "react-i18next";
import CreateExportForm from "./CreateExportForm";
import DownloadExportButton from "./DownloadExportButton";

const PAGE_SIZE = 10;

function humanizeStatus(status: DataDumpStatus) {
  return titleize(humanize(status.toString()));
}

function ExportListRoute() {
  const { t } = useTranslation(["settings"]);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const cols: DataGridCols<ExportListQuery, ["exportDataDumps"]> = [
    {
      field: "name",
      headerName: t("settings:exports.name"),
      sortable: false,
      flex: 2,
      valueGetter: (_value, row) => row.name,
    },
    {
      field: "password",
      headerName: t("settings:exports.password"),
      sortable: false,
      flex: 2,
      valueGetter: (_value, row) => {
        if (row.status === DataDumpStatus.READY) {
          return row.password;
        }
        return null;
      },
    },
    {
      field: "status",
      headerName: t("settings:exports.status"),
      sortable: false,
      filterable: false,
      flex: 1,
      valueGetter: (_value, row) => humanizeStatus(row.status),
    },
    {
      field: "createdAt",
      headerName: t("settings:exports.createdAt"),
      sortable: true,
      filterable: false,
      flex: 1,
      valueGetter: (_value, row) =>
        new Date(row.createdAt).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
    },
    {
      field: "Actions",
      headerName: t("settings:exports.actions"),
      sortable: false,
      filterable: false,
      flex: 1,
      renderCell: (x) => {
        return <DownloadExportButton id={x.row.id} status={x.row.status} />;
      },
    },
  ];

  const cardAction = (
    <Button
      variant="contained"
      color="secondary"
      sx={{ marginRight: "1.5em" }}
      onClick={() => setShowCreateDialog(true)}
    >
      {t("settings:exports.createNew")}
    </Button>
  );

  return (
    <Page browserTitle={t("settings:exports.browserTitle")}>
      <Card>
        <CardHeader title={t("settings:exports.title")} action={cardAction} />
        <CardContent>
          <SortablePagableCollectionDataGrid
            queryHook={useExportListQuery}
            unwrapData={(d) => d?.exportDataDumps || null}
            defaultPageSize={PAGE_SIZE}
            queryVariables={{}}
            colNameToSortParam={(colName) => {
              switch (colName) {
                case "createdAt": {
                  return DataDumpSortParameter.CREATED_AT;
                  break;
                }
                default: {
                  return null;
                  break;
                }
              }
            }}
            defaultSortParams={{
              sortBy: DataDumpSortParameter.CREATED_AT,
              sortDirection: SortDirection.DESC,
            }}
            columns={cols}
            pollInterval={10000}
          />
          <CreateExportForm onClose={() => setShowCreateDialog(false)} open={showCreateDialog} />
        </CardContent>
      </Card>
    </Page>
  );
}

export default ExportListRoute;
