import React, { ReactElement, useState } from "react";

import {
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import {
  CreateRelatedPersonInput,
  NotificationPreference,
  Patient,
  RelatedPerson,
  RelatedPersonRelationship,
  UpdateRelatedPersonInput,
  useCreateRelatedPersonMutation,
  useUpdateRelatedPersonMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { MutationRemoteDataResult, apolloMutationHookWrapper } from "Api/GraphQL";
import { PickTypename } from "type-utils";
import { Form, FormOverlay, useBooleanField, useForm, useTextField, useWrappedField } from "Shared/Form";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import EnumSelect from "Shared/EnumSelect";
import { notificationPreferenceT, relatedPersonRelationshipT } from "GeneratedGraphQL/EnumTranslations";
import { useTranslation } from "react-i18next";
import { refetchQueries } from "Lib/RefetchQueries";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

type PersonDetail = PickTypename<
  RelatedPerson,
  | "id"
  | "email"
  | "active"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "relatedPersonRelationship"
  | "notificationPreference"
>;

export function RelatedPersonForm(props: {
  closeForm: () => void;
  patient: PickTypename<Patient, "id">;
  personToEdit?: PersonDetail;
  additionalTitle?: string;
  preSelectedRelationship?: RelatedPersonRelationship;
  subtitle?: string;
}): ReactElement {
  const { updateOrCreateRelatedPerson, remoteData } = useUpdateOrCreateRelatedPerson(
    props.patient,
    props.personToEdit
  );

  const fields = {
    firstName: useTextField({ required: true, default: props.personToEdit?.firstName || undefined }),
    lastName: useTextField({ required: true, default: props.personToEdit?.lastName || undefined }),
    email: useTextField({ required: false, default: props.personToEdit?.email || undefined }),
    phoneNumber: useTextField({ required: false, default: props.personToEdit?.phoneNumber || undefined }),
    notificationPreference: useWrappedField({
      default: props.personToEdit?.notificationPreference || undefined,
      required: false,
    }),
    // Default to active=true on the assumption that if you're making a new person it's because you want them to
    // receive measures.
    active: useBooleanField({
      required: false,
      default: props.personToEdit?.active === undefined ? true : props.personToEdit.active,
    }),
  };

  const [relationship, setRelationship] = useState<RelatedPersonRelationship>(
    props.personToEdit?.relatedPersonRelationship ||
      props.preSelectedRelationship ||
      RelatedPersonRelationship.GUARDIAN
  );

  const { t } = useTranslation(["collaborativeCare", "patients", "common"]);
  const form = useForm({
    fields: fields,
    submit: () => {
      if (fields.firstName.value !== undefined && fields.lastName.value !== undefined) {
        updateOrCreateRelatedPerson({
          firstName: fields.firstName.value,
          lastName: fields.lastName.value,
          email: fields.email.value,
          phoneNumber: fields.phoneNumber.value,
          relatedPersonRelationship: relationship,
          active: fields.active.value,
          notificationPreference: fields.notificationPreference.value || null,
        });
      }
    },
    remoteData: remoteData,
    onSuccess: props.closeForm,
  });

  return (
    <ResponsiveDialog
      open
      onClose={props.closeForm}
      title={
        (props.personToEdit === undefined
          ? t("patients:relatedPerson.createHeader")
          : t("patients:relatedPerson.updateHeader")) +
        " " +
        (props.additionalTitle || "")
      }
      subtitle={props.subtitle}
    >
      <DialogContent>
        <Form onSubmit={form.onSubmit}>
          <FormOverlay response={remoteData} errorMessage={t("patients:relatedPerson.genericFormError")} />
          <Stack direction="column" spacing={1}>
            <TextField
              title={t("patients:user.firstName")}
              label={t("patients:user.firstName")}
              autoFocus
              value={fields.firstName.value}
              onChange={fields.firstName.onChange}
              error={fields.firstName.error}
            />
            <TextField
              title={t("patients:user.lastName")}
              label={t("patients:user.lastName")}
              value={fields.lastName.value}
              onChange={fields.lastName.onChange}
              error={fields.lastName.error}
            />
            <TextField
              title={t("patients:user.email")}
              label={t("patients:user.email")}
              value={fields.email.value}
              onChange={fields.email.onChange}
              error={fields.email.error}
            />
            <TextField
              title={t("patients:user.phoneNumber")}
              label={t("patients:user.phoneNumber")}
              value={fields.phoneNumber.value}
              onChange={fields.phoneNumber.onChange}
              error={fields.phoneNumber.error}
            />
            <EnumSelect
              title={t("patients:demographics.notificationPreference")}
              value={fields.notificationPreference.value || null}
              onChange={fields.notificationPreference.onChange}
              optionsEnum={NotificationPreference}
              enumTrans={notificationPreferenceT}
            />
            <EnumSelect
              optionsEnum={RelatedPersonRelationship}
              title={t("patients:relatedPerson.relationship")}
              enumTrans={relatedPersonRelationshipT}
              onChange={(newValue) => {
                if (newValue !== null) {
                  setRelationship(newValue);
                }
              }}
              value={relationship}
            />
            <FormControl error={fields.active.error}>
              <FormControlLabel
                control={<Checkbox />}
                label={t("patients:user.active")}
                checked={fields.active.value}
                onChange={fields.active.onChange}
              />
              <FormHelperText>{fields.active.helperText}</FormHelperText>
            </FormControl>
            <Stack direction="row-reverse">
              <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="full_access">
                <ButtonWithSpinner
                  variant="contained"
                  color="secondary"
                  type="submit"
                  showSpinner={form.showSpinner}
                  disabled={form.disableSubmit}
                >
                  {t("common:actions.save")}
                </ButtonWithSpinner>
              </ReadOnlyOrImpersonatingDisabledButton>
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </ResponsiveDialog>
  );
}

type UpdateOrCreateRelatedPerson = {
  updateOrCreateRelatedPerson: (
    input: Omit<CreateRelatedPersonInput, "patientId"> & Omit<UpdateRelatedPersonInput, "relatedPersonId">
  ) => void;
  remoteData: MutationRemoteDataResult<{
    __typename: "CreateRelatedPersonResult" | "UpdateRelatedPersonResult";
    relatedPerson: PickTypename<RelatedPerson, "id">;
  } | null>;
};

function useUpdateOrCreateRelatedPerson(
  patient: PickTypename<Patient, "id">,
  relatedPerson: PersonDetail | undefined
): UpdateOrCreateRelatedPerson {
  const [createRelatedPerson, createRelatedPersonResult] = apolloMutationHookWrapper(
    (response) => response.createRelatedPerson,
    useCreateRelatedPersonMutation({
      refetchQueries: refetchQueries("relatedPeople"),
    })
  );

  const [updateRelatedPerson, updateRelatedPersonResult] = apolloMutationHookWrapper(
    (response) => response.updateRelatedPerson,
    useUpdateRelatedPersonMutation({
      refetchQueries: refetchQueries("relatedPeople"),
    })
  );

  if (relatedPerson === undefined) {
    return {
      updateOrCreateRelatedPerson: (props: {
        email?: string | null;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        notificationPreference?: NotificationPreference | null;
        relatedPersonRelationship: RelatedPersonRelationship;
      }) => createRelatedPerson({ variables: { input: { ...props, patientId: patient.id } } }),
      remoteData: createRelatedPersonResult.remoteData,
    };
  } else {
    return {
      updateOrCreateRelatedPerson: (props: {
        email?: string | null;
        firstName: string;
        lastName: string;
        phoneNumber?: string | null;
        notificationPreference?: NotificationPreference | null;
        relatedPersonRelationship: RelatedPersonRelationship;
      }) => updateRelatedPerson({ variables: { input: { ...props, relatedPersonId: relatedPerson.id } } }),
      remoteData: updateRelatedPersonResult.remoteData,
    };
  }
}
