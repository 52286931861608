import { ListItem } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  TreatmentTrackSelectQueryVariables,
  TreatmentTrackStatus,
  useTreatmentTrackSelectLazyQuery,
  useTreatmentTrackSelectSingleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TreatmentTrackId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";

type TreatmentTrackSelectProps = {
  value: TreatmentTrackId | null;
  setValue: (newValue: TreatmentTrackId | null) => void;
  status?: TreatmentTrackStatus;
};

type SelectOption = { id: TreatmentTrackId; title: string };

function inputToSelect(value: SelectOption | null): TreatmentTrackId | null {
  if (value) {
    return value.id;
  } else {
    return null;
  }
}

export default function TreatmentTrackSelect(props: TreatmentTrackSelectProps): ReactElement {
  const { t } = useTranslation(["common"]);
  // In cases where we are given an id as part of a query string etc we need to fetch
  // the name of the option no matter what it is rather than relying on the search.
  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useTreatmentTrackSelectSingleQuery({
      variables: {
        id: assertNonNull(props.value), // Apollo will only call if value is present
      },
      skip: !props.value,
    })
  );
  const queryVars: Omit<TreatmentTrackSelectQueryVariables, "search"> = {
    first: 10,
    status: props.status || null,
  };

  const value = props.value
    ? currentValueRemoteData.caseOf({
        Success: (result) => {
          if (result.careTreatmentTrack) {
            return {
              id: result.careTreatmentTrack.id,
              title: result.careTreatmentTrack.name,
            };
          } else if (props.value) {
            return {
              id: props.value,
              title: t("common:remoteData.loading"),
            };
          }
          return null;
        },
        _: () => {
          if (props.value) {
            return {
              id: props.value,
              title: t("common:remoteData.loading"),
            };
          }
          return null;
        },
      })
    : null;

  return (
    <QueryAutocompleteSingle
      query={useTreatmentTrackSelectLazyQuery}
      queryVariables={queryVars}
      unwrapResponse={(response) =>
        response.careTreatmentTracks?.nodes.map((track) => {
          return {
            id: track.id,
            // For now, we just use the scale name as the title, without the domain.
            title: track.name,
          };
        })
      }
      valueUpdated={(value) => props.setValue(inputToSelect(value))}
      valueEqual={(left, right) => left.id === right.id}
      value={value}
      label={t("common:treatmentTracks.title")}
      autocompleteProps={{
        noOptionsText: t("common:treatmentTracks.noneMatching"),
        getOptionLabel: (option) => option.title,
        renderOption: (props, option) => {
          return (
            <ListItem {...props} key={option.id.toString()}>
              {option.title}
            </ListItem>
          );
        },
      }}
    />
  );
}
