import {
  CaseConsult,
  CaseConsultStatus,
  Patient,
  useCaseConsultDetailsQuery,
  useCaseConsultListQuery,
  useSaveConsultReviewNotesMutation,
  useStartCaseSummaryTimeEntryMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { REQUESTED_CONSULTS_STATUSES, isPostMeeting } from "../CaseConsult";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Button, DialogContent, Stack, TextField, Typography } from "@mui/material";
import {
  AdditionalPatientInformation,
  Assessments,
  CaseConsultDetails,
  MeasurementBundleList,
  RequestsList,
} from "../CaseConsultReviewInformation";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { refetchQueries } from "Lib/RefetchQueries";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";

type LazyPrepareConsultButtonProps = {
  patient: PickTypename<Patient, "id">;
  variant?: "text" | "contained" | "outlined";
};

export function LazyPrepareConsultButton(props: LazyPrepareConsultButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const testPatientViewability = useTestPatientViewability();
  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        statuses: REQUESTED_CONSULTS_STATUSES,
        patient: {
          patientIds: [props.patient.id],
        },
        testPatient: testPatientViewability,
      },
    })
  );

  const consult = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareCaseConsults) {
        // As of this writing, each patient should only have zero or one case consults in the pre-meeting states at a
        // time. When a new request comes in, if there's a consult that hasn't been reviewed yet we add the request to
        // that consult. Thus, this shouldn't be dropping data on the floor. If that changes, this will also have to
        // change.
        return response.collaborativeCareCaseConsults.nodes[0];
      }

      return undefined;
    },
    _: () => undefined,
  });

  if (consult) {
    return <PrepareConsultButton consult={consult} />;
  } else {
    return (
      <Button variant={props.variant || "contained"} color="secondary" disabled={true}>
        {t("collaborativeCare:caseConsult.consultList.actions.beginSummary")}
      </Button>
    );
  }
  return <></>;
}

type CaseConsultSummary = PickTypename<CaseConsult, "id" | "caseSummary" | "status"> & {
  patient: PickTypename<Patient, "id" | "name">;
};

export function PrepareConsultButton(props: { consult: CaseConsultSummary }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const disabled = isPostMeeting(props.consult);
  const message =
    props.consult.caseSummary === null
      ? t("collaborativeCare:caseConsult.consultList.actions.beginSummary")
      : t("collaborativeCare:caseConsult.consultList.actions.editSummary");
  const variant = props.consult.status === CaseConsultStatus.NEEDS_SUMMARY ? "contained" : "outlined";
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Button variant={variant} color="secondary" disabled={disabled} onClick={() => setOpen(true)}>
        {message}
      </Button>
      <PrepareConsultDialog consult={props.consult} open={open} onClose={() => setOpen(false)} />
    </>
  );
}

type PrepareConsultDialogProps = {
  consult: CaseConsultSummary;
  open: boolean;
  onClose: () => void;
};

function PrepareConsultDialog(props: PrepareConsultDialogProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [startTimeTracking] = useStartCaseSummaryTimeEntryMutation({
    variables: { input: { caseConsultId: props.consult.id } },
    refetchQueries: refetchQueries("tasks"),
  });
  // This starts time tracking whenever the dialog is opened. We are intentionally not automatically stopping time
  // tracking when the dialog is closed, on the assumption that you may need to navigate to other pages while prepping
  // the summary. The mutation is idempotent so if we call it multiple times in a row (from closing and re-opening
  // the dialog) we'll only end up with one task and one time entry.
  useEffectSimpleCompare(() => {
    if (props.open) {
      startTimeTracking();
    }
  }, [props.open]);

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultDetailsQuery({ variables: { id: props.consult.id } })
  );

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareCaseConsult) {
        return (
          <ErrorMessage message={t("collaborativeCare:caseConsult.consultList.prepareSummary.loadError")} />
        );
      }

      return <PrepareConsultDialogContent consult={response.collaborativeCareCaseConsult} />;
    },
  });

  return (
    <ResponsiveDialog
      open={props.open}
      title={t("collaborativeCare:caseConsult.consultList.prepareSummary.title", {
        patient: props.consult.patient.name,
      })}
      onClose={props.onClose}
      stopBackdropClose={true}
    >
      <DialogContent>{content}</DialogContent>
    </ResponsiveDialog>
  );
}

function PrepareConsultDialogContent(props: { consult: CaseConsultDetails }): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <MeasurementBundleList consult={props.consult} />
      <RequestsList consult={props.consult} />
      <AdditionalPatientInformation consult={props.consult} />
      <Assessments consult={props.consult} />
      <EditCaseSummary consult={props.consult} />
    </Stack>
  );
}

function EditCaseSummary(props: { consult: CaseConsultDetails }): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [text, setText] = React.useState(props.consult.caseSummary || "");
  const [saveSummary, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSaveConsultReviewNotes,
    useSaveConsultReviewNotesMutation({
      variables: {
        input: {
          caseConsultId: props.consult.id,
          text: text,
          type: {
            summary: true,
          },
        },
      },
    })
  );

  const status = remoteData.caseOf({
    NotAsked: () => "",
    Loading: () => t("collaborativeCare:caseConsult.consultList.prepareSummary.saving"),
    Failure: () => t("collaborativeCare:caseConsult.consultList.prepareSummary.saveError"),
    Success: () => t("collaborativeCare:caseConsult.consultList.prepareSummary.saved"),
  });

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.caseSummary.title")}
      </Typography>
      <TextField value={text} onChange={(event) => setText(event.target.value)} multiline minRows={5} />
      <Stack direction="row-reverse" spacing={1}>
        <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
          <ButtonWithSpinner
            showSpinner={showSpinner}
            disabled={disabled}
            variant="contained"
            color="secondary"
            onClick={() => saveSummary()}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </ReadOnlyOrImpersonatingDisabledButton>
        <Typography variant="caption">{status}</Typography>
      </Stack>
    </Stack>
  );
}
