import { Card, CardContent, CardHeader, Skeleton, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { Timeline } from "CollaborativeCare/Timeline";
import { startOfToday, subDays } from "date-fns";
import { Patient, useTimelineCardQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { CSSProperties, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type TimelineCardProps = {
  patient: PickTypename<Patient, "id">;
};
export default function TimelineCard(props: TimelineCardProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const DAYS_TO_LOAD = 45; // How many days do we want to load at a time?
  const [lookbackDays, setLookbackDays] = useState(DAYS_TO_LOAD);

  const startTime = subDays(startOfToday(), lookbackDays);

  // This just lets us continually add more lookback period
  // to the query.
  const onLoadMoreEvents = () => {
    setLookbackDays(lookbackDays + DAYS_TO_LOAD);
  };

  const { remoteData } = apolloQueryHookWrapper(
    useTimelineCardQuery({
      variables: {
        patientId: props.patient.id,
        startTime: startTime,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => {
      return <TimelineCardSkeleton />;
    },
    Loading: () => {
      return <TimelineCardSkeleton />;
    },
    Failure: () => {
      return <TimelineCardListError />;
    },
    Success: (result) => {
      if (!result.patient?.collaborativeCareTimeEntryLogs) {
        return <TimelineCardListError />;
      }

      const cardContentStyle: CSSProperties = {
        minHeight: "10em",
        maxHeight: "55vh",
        overflow: "auto",
      };

      const startTimeFormatted = t("common:date.tiny", { date: startTime });
      const endTimeFormatted = t("common:date.tiny", { date: startOfToday() });
      const title = `${t(
        "collaborativeCare:patientDetails.cards.timeline"
      )} (${startTimeFormatted} - ${endTimeFormatted})`;

      return (
        <>
          <Card>
            <CardHeader title={title} />
            <CardContent sx={cardContentStyle}>
              <Timeline
                events={result.patient.collaborativeCareTimeEntryLogs.nodes}
                onLoadMoreEventsClicked={onLoadMoreEvents}
              />
            </CardContent>
          </Card>
        </>
      );
    },
  });
}

function TimelineCardSkeleton(): ReactElement {
  return <Skeleton variant="rectangular" height="10em" />;
}

function TimelineCardListError(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return <Typography>{t("collaborativeCare:patientDetails.timeline.failure")}</Typography>;
}
