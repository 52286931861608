import { TFunction } from "i18next";

export function careEpisodeDates(
  periodStart: Date | null,
  periodEnd: Date | null,
  t: TFunction<["careEpisodes"]>
) {
  if (periodEnd && periodStart) {
    return t("careEpisodes:dateBoth", { periodStart, periodEnd });
  } else if (periodStart) {
    return t("careEpisodes:dateStart", { date: periodStart });
  } else {
    return t("careEpisodes:noDates");
  }
}
