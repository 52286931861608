import {
  GoalFragmentFragment as GoalFragment,
  GoalStatus,
  GoalType,
  NormalizedScore,
  ScaleThresholdClass,
} from "GeneratedGraphQL/SchemaAndOperations";
import * as Id from "Lib/Id";
import { find, oks, parseIsoStringOrDate } from "Lib/Utils";
import { Just, Maybe, Nothing, Result } from "seidr";
import * as Threshold from "Shared/Scale/Threshold";
import { DigUnpacked } from "type-utils";
import { UserSummary } from "../User";

export type GoalId = Id.Id<"Goal">;

// A goal represents an idiopathic (i.e. per patient) question that might be
// positive (a goal), or negative (a problem).
export type Goal = {
  __typename: "Goal";
  id: GoalId;
  patientText: string;
  answers: Array<GoalAnswer>;
  goalType: GoalType;
  user: UserSummary;
  startDate: Date;
  endDate: Maybe<Date>;
  isBeneficial: boolean;
  status: GoalStatus;
};

export type GoalAnswerId = Id.Id<"GoalAnswer">;

// An answer to an individual goal
export type GoalAnswer = {
  __typename: "GoalAnswer";
  id: GoalAnswerId;
  date: Date;
  // TODO: Value's are nullable in the raw data. For now we discard any null
  // answers. It appears that this is null when the question was asked but not
  // answered. It's unclear why there even is an answer then?
  value: number;
  thresholdMnemonic: Maybe<string>;
  thresholdClass: ScaleThresholdClass;
};

export type GoalTypeDisplayData = {
  title: string;
  description: string;
  bounds: [number, number];
  answerWeights: Record<string, NormalizedScore>;
  leftText: string;
  rightText: string;
  orderedAnswers: ReadonlyArray<number>;
};

const GOAL_TYPE_CONFIGURATION: Record<keyof typeof GoalType, GoalTypeDisplayData> = {
  TOP_PROBLEM: {
    title: "Top Problem",
    description:
      "Rated on a scale of 0 to 4, where 0 is 'not a problem at all' and 4 is 'a very big problem'. Goals should be phrased as a problem the client is experiencing.",
    bounds: [0, 4],
    answerWeights: {
      0: NormalizedScore.VALUE_1,
      1: NormalizedScore.VALUE_2,
      2: NormalizedScore.VALUE_4,
      3: NormalizedScore.VALUE_6,
      4: NormalizedScore.VALUE_7,
    },
    leftText: "not a problem at all",
    rightText: "a very big problem",
    orderedAnswers: [0, 1, 2, 3, 4],
  },
  GOAL_ATTAINMENT_SCALING: {
    title: "Goal Attainment Scaling",
    description:
      "Rated on a scale of -2 to 2, where -2 is 'Below Expected' and 2 is 'Above Expected'. Goals should be phrased as a positive thing to attain.",
    bounds: [-2, 2],
    answerWeights: {
      "-2": NormalizedScore.VALUE_1,
      "-1": NormalizedScore.VALUE_2,
      "0": NormalizedScore.VALUE_4,
      "1": NormalizedScore.VALUE_6,
      "2": NormalizedScore.VALUE_7,
    },
    leftText: "Below Expected",
    rightText: "Above Expected",
    orderedAnswers: [-2, -1, 0, 1, 2],
  },
  GOAL_LIKERT: {
    title: "7-point Likert Goal",
    // TODO: How do we map the full list?
    // I've done Level of Agreement for now, but that does not match leftText and rightText
    // See: https://www.marquette.edu/student-affairs/assessment-likert-scales.php
    description: "A simple 7 point likert, with 1 = not at all, 4 = somewhat and 7 = completely.",
    bounds: [1, 7],
    answerWeights: {
      1: NormalizedScore.VALUE_1,
      2: NormalizedScore.VALUE_2,
      3: NormalizedScore.VALUE_3,
      4: NormalizedScore.VALUE_4,
      5: NormalizedScore.VALUE_5,
      6: NormalizedScore.VALUE_6,
      7: NormalizedScore.VALUE_7,
    },
    leftText: "not at all",
    rightText: "completely",
    orderedAnswers: [1, 2, 3, 4, 5, 6, 7],
  },
  // We don't actually use the custom goal right now
  CUSTOM: {
    title: "Custom Goal",
    description: "A custom goal",
    bounds: [-99, 99],
    answerWeights: {},
    leftText: "Not Used",
    rightText: "Not Used",
    orderedAnswers: [],
  },
};

const ACTIVE_GOAL_TYPES: Record<string, GoalTypeDisplayData> = {
  TOP_PROBLEM: GOAL_TYPE_CONFIGURATION.TOP_PROBLEM,
  GOAL_LIKERT: GOAL_TYPE_CONFIGURATION.GOAL_LIKERT,
  GOAL_ATTAINMENT_SCALING: GOAL_TYPE_CONFIGURATION.GOAL_ATTAINMENT_SCALING,
};

/**
 * Gets the answer weight for the value given, if present.
 */
function toGoalAnswerWeight(answer: number, goalType: GoalType): Maybe<NormalizedScore> {
  return Maybe.fromNullable(GOAL_TYPE_CONFIGURATION[goalType].answerWeights[answer]);
}

function goalBounds(goal: Goal): [number, number] {
  return GOAL_TYPE_CONFIGURATION[goal.goalType].bounds;
}
/**
 * We want to filter out goals which are entered in error
 * @param goal the goal
 */
function goalIsDisplayable(goal: Goal) {
  return goal.status !== GoalStatus.ENTERED_IN_ERROR;
}

function toGoal(rawGoal: GoalFragment): Goal {
  return {
    id: rawGoal.id,
    __typename: rawGoal.__typename,
    patientText: rawGoal.patientText,
    answers: oks(rawGoal.goalAnswers.map((answer) => toGoalAnswer(rawGoal.thresholdData, answer))),
    goalType: rawGoal.goalType,
    user: rawGoal.user,
    startDate: parseIsoStringOrDate(rawGoal.startDate),
    endDate: Maybe.fromNullable(rawGoal.endDate).map(parseIsoStringOrDate),
    isBeneficial: rawGoal.isBeneficial,
    status: rawGoal.status,
  };
}

function determineThreshold(
  thresholds: ReadonlyArray<Threshold.Threshold>,
  value: number
): [Maybe<string>, ScaleThresholdClass] {
  return find((t) => value >= t.minValue && value <= t.maxValue, thresholds).caseOf({
    Just: (t) => [Just(t.mnemonic), t.thresholdClass],
    Nothing: () => [Nothing(), ScaleThresholdClass.UNKNOWN],
  });
}

function toGoalAnswer(
  thresholdData: ReadonlyArray<Threshold.Threshold>,
  rawGoalAnswer: DigUnpacked<GoalFragment, ["goalAnswers"]>
): Result<Error, GoalAnswer> {
  return Result.fromNullable(new Error("Goal answer must have a value"), rawGoalAnswer.value).map((value) => {
    const [thresholdMnemonic, thresholdClass] = determineThreshold(thresholdData, value);

    return {
      id: rawGoalAnswer.id,
      __typename: rawGoalAnswer.__typename,
      value,
      thresholdMnemonic,
      thresholdClass,
      date: parseIsoStringOrDate(rawGoalAnswer.targetDate),
    };
  });
}

export {
  goalBounds,
  GOAL_TYPE_CONFIGURATION,
  ACTIVE_GOAL_TYPES,
  toGoal,
  toGoalAnswer,
  toGoalAnswerWeight,
  goalIsDisplayable,
};
