import { Theme } from "@mui/material";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { ScoreCategoryColors } from "../../Style/Theme/palette";

function isUnknown(thresholdClass: ScaleThresholdClass): boolean {
  return thresholdClass === ScaleThresholdClass.UNKNOWN;
}

function color(thresholdClass: ScaleThresholdClass | null, theme: Theme): ScoreCategoryColors {
  const threshholdTheme = theme.palette.report.scaleThreshold;
  switch (thresholdClass) {
    case ScaleThresholdClass.UNKNOWN:
    case null:
      return threshholdTheme.Unknown;
    case ScaleThresholdClass.UNANSWERED:
      return threshholdTheme.Unanswered;
    case ScaleThresholdClass.CONTINUOUS:
      return threshholdTheme.Continuous;
    case ScaleThresholdClass.NONE:
      return threshholdTheme.None;
    case ScaleThresholdClass.LOW:
      return threshholdTheme.Low;
    case ScaleThresholdClass.MILD:
      return threshholdTheme.Mild;
    case ScaleThresholdClass.MODERATE:
      return threshholdTheme.Moderate;
    case ScaleThresholdClass.MOD_SEVERE:
      return threshholdTheme.ModerateToSevere;
    case ScaleThresholdClass.SEVERE:
      return threshholdTheme.Severe;
  }
}

export { color, isUnknown };
