import { Grid, Stack, Typography } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Report,
  Appointment,
  CareEpisode,
  Organization,
  PatientSession,
  Provider,
  TreatmentService,
  useAppointmentDetailsQuery,
  Recurrence,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { PickTypename } from "type-utils";
import { AppointmentId, PatientId } from "Lib/Ids";
import PatientDetailsCard from "Patients/PatientDetails/PatientDetailsCard";
import { AppointmentActionsCard } from "./AppointmentActionsCard";
import { AppointmentInvitationsCard } from "Patients/Appointments/AppointmentInvitationsCard";
import AppointmentInfoCard from "./AppointmentInfoCard";
import { AppointmentAlgorithmsStepper } from "./AppointmentAlgorithmsStepper";
import PatientSessionInfoCard from "./PatientSessionInfoCard";
import NotFound from "Shared/NotFound";

export type AppointmentDetails = PickTypename<
  Appointment,
  | "id"
  | "startDate"
  | "serviceType"
  | "careEpisodeSource"
  | "organizationSource"
  | "status"
  | "source"
  | "minutesDuration"
> & {
  provider: Pick<Provider, "id" | "name"> | null;
  careEpisode: Pick<CareEpisode, "id" | "name"> | null;
  organization: Pick<Organization, "id" | "name"> | null;
  treatmentService: Pick<TreatmentService, "id" | "name"> | null;
  patientSession:
    | (Pick<PatientSession, "id" | "sessionNumber" | "autoplan" | "isReportAvailable"> & {
        assessmentReport: Pick<Report, "id"> | null;
        schedulingRecurrence: Pick<Recurrence, "id" | "endDate" | "repeatFrequency"> | null;
      })
    | null;
};

export function AppointmentDetails(props: {
  patientId: PatientId;
  appointmentId: AppointmentId;
}): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  return remoteData.caseOf({
    Failure: () => <Typography>{t("common:remoteData.failure")}</Typography>,
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Success: (data) => {
      if (data.schedulingAppointment) {
        return (
          <AppointmentDetailsElement appointment={data.schedulingAppointment} patientId={props.patientId} />
        );
      } else {
        return <NotFound />;
      }
    },
  });
}

type PatientDetailsElementProps = {
  appointment: AppointmentDetails;
  patientId: PatientId;
};
function AppointmentDetailsElement(props: PatientDetailsElementProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  return (
    <Page browserTitle={t("patients:appointments.title")}>
      <Grid container columns={12} spacing={1}>
        <Grid item lg={4} xs={12}>
          <Stack direction="column" minHeight={"100%"} spacing={1} flexGrow={1} flexDirection={"column"}>
            <LeftPanel patientId={props.patientId} appointment={props.appointment} />
          </Stack>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Stack direction="column" spacing={1}>
            <RightPanel appointment={props.appointment} patientId={props.patientId} />
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
}

type PanelProps = {
  appointment: AppointmentDetails;
  patientId: PatientId;
};

function LeftPanel(props: PanelProps): ReactElement {
  return (
    <>
      <PatientDetailsCard patientId={props.patientId} />
      <AppointmentActionsCard
        patientId={props.patientId}
        patientSession={props.appointment.patientSession || null}
        appointment={props.appointment}
      />
      <AppointmentInfoCard patientId={props.patientId} appointment={props.appointment} />
      <PatientSessionInfoCard patientSession={props.appointment.patientSession} />
    </>
  );
}

function RightPanel(props: PanelProps): ReactElement {
  return (
    <>
      <AppointmentInvitationsCard appointmentId={props.appointment.id} patientId={props.patientId} />
      <AppointmentAlgorithmsStepper appointmentId={props.appointment.id} patientId={props.patientId} />
    </>
  );
}
