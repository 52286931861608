import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import InspectorTab from "./InspectorTab";
import { Typography } from "@mui/material";

function InspectorRoute(): ReactElement {
  const { className, id } = useParams();

  if (className && id) {
    return <Inspector id={id} className={className} />;
  }
  return <Typography>ERROR</Typography>;
}

type InspectorProps = {
  id: string;
  className: string;
};

// This handles drawing a standalone inspector. We can get here from a raw url or
// from arbitrary links.
function Inspector(props: InspectorProps): ReactElement {
  return (
    <Page browserTitle="Mirah Inspector">
      <Box>
        <InspectorTab id={props.id} className={props.className} />
      </Box>
    </Page>
  );
}

export default InspectorRoute;
