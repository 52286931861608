import React, { ReactElement } from "react";
import { Card, CardContent, Grid } from "@mui/material";
import Risks from "./Risks";
import { TwoPaneLayout } from "./TwoPaneLayout";
import OverviewPane from "./OverviewPane/OverviewPane";
import { FeedbackReportDetailRoutes } from "./DetailRoutes";
import FeedbackReportCareEpisode, { PatientInfo } from "./FeedbackReportCareEpisode";
import ErrorMessage from "Shared/ErrorMessage";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import FeedbackReportActionMenu from "./FeedbackReportActionMenu";
import FeedbackReportProgressNoteModal from "./FeedbackReportProgressNote/FeedbackReportProgressNoteModal";
import { DemographicInfo } from "./Demographics/DemographicInfo";
import { CareEpisodeDetail } from "Shared/CareEpisode";
import * as AppSession from "AppSession/AppSession";
import { Just, Nothing } from "seidr";
import * as Metrics from "Shared/Analytics/Metrics";
import { TrackingEventData } from "Shared/Analytics/Metrics";
import { useEffectEveryRender } from "Lib/Hooks";
import { PatientId } from "Lib/Ids";
import { ProviderSummary } from "Shared/Provider";
import { PatientConditionDetails } from "./Demographics/PatientConditionModal";
import { FeedbackReportContext } from "./FeedbackReportContext";
import { useAutomaticallyTrackTime } from "CollaborativeCare/TimeEntry/AutomaticTimeTracking";
import { ForceStartStrategy } from "GeneratedGraphQL/SchemaAndOperations";

const ReportCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const ReportCardContent = styled(CardContent)(({ theme }) => ({
  // Our cards by default have two units of padding, but when we add space between them as well that plus the padding
  // the actual content elements add makes everything feel too spread out.
  padding: theme.spacing(1),
}));

const RightAlignedGrid = styled(Grid)(() => ({
  textAlign: "right",
}));

type FeedbackReportProps = {
  careEpisode: FeedbackReportCareEpisode;
};

function trackReportMetrics(
  patientId: PatientId,
  careEpisode: CareEpisodeDetail,
  tracker: (e: TrackingEventData) => void
) {
  careEpisode.latestPatientSessionWithFeedback
    // Collect patient session info
    .flatMap((patientSession) =>
      patientSession.reportId.map((reportId) => ({
        data: {
          latestPatientSessionId: patientSession.id.toString(),
          patientSessionId: patientSession.id.toString(),
          reportId: reportId.toString(),
        },
      }))
    )
    // Collect providerId from AppSession
    .flatMap((event) =>
      AppSession.getCurrentUser()
        .flatMap((u) => u.providerId)
        .map((providerId) => ({
          ...event,
          providerId: Just(providerId),
        }))
    )
    // Collect patientId
    .map((event) => ({
      ...event,
      patientId: Just(patientId),
    }))
    .caseOf({
      // Perform metric event effect
      Just: (event) => {
        tracker({
          eventName: "provider.patients.show.longitudinal",
          eventData: {
            ...event,
            route: Nothing(),
            relatedPersonId: Nothing(),
            data: Just(event.data),
          },
        });

        tracker({
          eventName: "provider.assessment.reportView",
          eventData: {
            ...event,
            route: Nothing(),
            relatedPersonId: Nothing(),
            data: Just({
              ...event.data,
              activeReportId: event.data.reportId,
              latestReportId: event.data.reportId,
            }),
          },
        });
      },
      Nothing() {
        return;
      },
    });
}

export const FeedbackReport = (props: FeedbackReportProps) => {
  const { t } = useTranslation(["report"]);

  return props.careEpisode.caseOf({
    NoAdministrations: () => <ErrorMessage message={t("report:errors.careEpisodeNoAdministrations")} />,
    WithAdministrations: (patient, careEpisode, careTeam, patientConditions, feedbackReportContext) => {
      return (
        <FeedbackReportWithAdministrations
          patient={patient}
          careEpisode={careEpisode}
          careTeam={careTeam}
          patientConditions={patientConditions}
          feedbackReportContext={feedbackReportContext}
        />
      );
    },
  });
};

type FeedbackReportWithAdministrationsProps = {
  patient: PatientInfo;
  careEpisode: CareEpisodeDetail;
  careTeam: Array<ProviderSummary>;
  patientConditions: ReadonlyArray<PatientConditionDetails>;
  feedbackReportContext: FeedbackReportContext;
};

function FeedbackReportWithAdministrations(props: FeedbackReportWithAdministrationsProps): ReactElement {
  const { t } = useTranslation(["report", "collaborativeCare"]);
  const [viewportHeight, setViewportHeight] = React.useState<number>(window.innerHeight);
  const tracker = Metrics.useTracking();
  const [showDialog, setShowDialog] = React.useState<null | "progressNote" | "addTask">(null);

  useEffectEveryRender(() => {
    const onResize = () => setViewportHeight(window.innerHeight);
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  useAutomaticallyTrackTime({
    title: t("collaborativeCare:tasks.automaticTasks.viewFeedbackReport.title"),
    body: t("collaborativeCare:tasks.automaticTasks.viewFeedbackReport.body"),
    patientId: props.patient.id,
    forceStart: ForceStartStrategy.NEVER,
  });

  // 320 here is pretty much a purely magic number, based on how much space the header, risks, name and padding take up.
  const topOffset = 320;
  const contentHeight = viewportHeight - topOffset;

  React.useEffect(() => {
    trackReportMetrics(props.patient.id, props.careEpisode, tracker);
  }, [props.careEpisode.id]);

  const overview = (
    <ReportCard>
      <ReportCardContent>
        <OverviewPane
          careEpisodeId={props.careEpisode.id}
          feedbackReportContext={props.feedbackReportContext}
        />
      </ReportCardContent>
    </ReportCard>
  );

  const details = (
    <ReportCard>
      <ReportCardContent>
        <FeedbackReportDetailRoutes
          patient={props.patient}
          careEpisode={props.careEpisode}
          careTeam={props.careTeam}
          patientConditions={props.patientConditions}
          feedbackReportContext={props.feedbackReportContext}
        />
      </ReportCardContent>
    </ReportCard>
  );

  return (
    <>
      <FeedbackReportProgressNoteModal
        show={showDialog == "progressNote"}
        careEpisodeId={props.careEpisode.id}
        onClose={() => setShowDialog(null)}
      />
      <Grid container>
        <Grid item xs={10} sx={{ pl: 2.5 }}>
          <DemographicInfo
            patient={props.patient}
            careEpisode={props.careEpisode}
            careTeam={props.careTeam}
            patientConditions={props.patientConditions}
            activeInvitations={props.feedbackReportContext.activeInvitations}
            flatten={true}
          />
        </Grid>
        <RightAlignedGrid item xs={2}>
          <FeedbackReportActionMenu
            patientId={props.patient.id}
            showDialog={(dialog) => setShowDialog(dialog)}
          />
        </RightAlignedGrid>
        <Grid item xs={12}>
          <ReportCard>
            <ReportCardContent>
              <Risks
                careEpisodeId={props.careEpisode.id}
                feedbackReportContext={props.feedbackReportContext}
              />
            </ReportCardContent>
          </ReportCard>
        </Grid>
        <Grid item xs={12}>
          <TwoPaneLayout
            leftPane={overview}
            leftTitle={t("report:sections.overview")}
            rightPane={details}
            rightTitle={t("report:sections.details")}
            maxHeight={contentHeight}
            topOffset={topOffset}
            ensureOpenOnRouteChange="right"
          />
        </Grid>
      </Grid>
    </>
  );
}
