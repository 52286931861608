import Page from "Layout/Page";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import PanelFilters, { usePanelFilters } from "./PanelFilters";
import PanelList from "./PanelList";

export function CurrentPanel(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const searchFilters = usePanelFilters();

  return (
    <Page browserTitle={t("collaborativeCare:panel.title")} supportsPanels>
      <PanelFilters filters={searchFilters} />
      <PanelList filters={searchFilters} />
    </Page>
  );
}
