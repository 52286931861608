import { Box, Skeleton } from "@mui/material";
import { CareEpisodeSpecialSectionType } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { SpecialSection } from "Shared/Scale/Constructs";
import { FeedbackReportContext } from "../FeedbackReportContext";
import NorseSection from "./NorseSection";

function SpecialSectionsLoadingIndicator(): ReactElement {
  return <Skeleton width={400} height={80} />;
}

function SpecialSectionsContent(props: {
  specialSections: ReadonlyArray<SpecialSection>;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
}): ReactElement {
  const { specialSections, feedbackReportContext } = props;

  const sections = specialSections.map((section, i) => {
    switch (section.sectionType) {
      case CareEpisodeSpecialSectionType.NORSE:
        return (
          <NorseSection
            key={i}
            section={section}
            feedbackReportContext={feedbackReportContext}
            hideTitle={props.hideTitle}
          />
        );
    }
  });

  return <>{sections}</>;
}

type Props = {
  specialSections: RemoteData<Error, ReadonlyArray<SpecialSection>>;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
};

function SpecialSections(props: Props): ReactElement {
  const { specialSections, feedbackReportContext } = props;

  const content = specialSections.caseOf({
    Success: (sections) => (
      <SpecialSectionsContent
        specialSections={sections}
        feedbackReportContext={feedbackReportContext}
        hideTitle={props.hideTitle}
      />
    ),
    Loading: () => <SpecialSectionsLoadingIndicator />,
    NotAsked: () => <SpecialSectionsLoadingIndicator />,
    Failure: (err) => <ErrorMessage message={err.toString()} />,
  });

  return <Box data-testid="specialSections">{content}</Box>;
}

export default SpecialSections;
