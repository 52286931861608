import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  MetricParams,
  MetricSuccessCriterionType,
  MetricTriggerType,
  TimeBasedAggregationType,
  TimeBasedMetricParams,
  TimePeriodType,
  Trend,
  TriggerBasedAggregationType,
  TriggerBasedMetricParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { useState } from "react";
import { CreateTimeBasedMetricForm, SectionHelpText } from "./CreateTimeBasedMetricForm";
import { useTranslation } from "react-i18next";
import { CreateTriggerBasedMetricForm } from "./CreateTriggerBasedMetricForm";
import { MetricFormParams, ScaleScorerSupportedOptions } from "./OutcomesFormHelpers";
import { RemoteData } from "seidr";
import Spinner from "Shared/Spinner";
import { MutationRemoteDataResult } from "Api/GraphQL";

type CreateMetricFormInnerProps<ResponseType> = {
  scaleScorerSupportedOptions: ScaleScorerSupportedOptions;
  defaults: MetricParams | null;
  onSuccess?: (response: ResponseType) => void;
  onDataChange?: (properties: MetricFormParams | null) => void;
  onSave: (fields: MetricFormParams) => void;
  errorMessage: string;
  forceFormInvalid?: boolean;
  submitButtonText: string;
  remoteData?: MutationRemoteDataResult<ResponseType>;
};

const timeBasedDefaults: TimeBasedMetricParams = {
  successCriteria: [
    {
      criterionType: MetricSuccessCriterionType.TREATMENT_RESPONSE,
      operator: null,
      numberValue: null,
      integerValue: null,
      severityValues: null,
      stringValue: null,
      trendValue: null,
    },
  ],
  timePeriodType: TimePeriodType.MONTH_OF_TREATMENT,
  timePeriodValue: 3,
  timeBasedAggregationType: TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS,
};

const triggerBasedDefaults: TriggerBasedMetricParams = {
  triggers: [
    {
      triggerType: MetricTriggerType.FIRST_SESSION_OF_TREND,
      integerValue: null,
      severityValue: null,
      stringValue: null,
      trendValue: Trend.IMPROVING,
      stringArrayValue: [],
    },
  ],
  triggerBasedAggregationType: TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START,
};

function CreateMetricInnerForm<ResponseType>(props: CreateMetricFormInnerProps<ResponseType>) {
  const { t } = useTranslation(["outcomes"]);
  const [metricType, setMetricType] = useState<"time" | "trigger" | null>(
    props.defaults ? (props.defaults.payload.timeBased ? "time" : "trigger") : null
  );

  let mainSection = null;

  if (metricType === "time") {
    const defaults: TimeBasedMetricParams = props.defaults?.payload.timeBased
      ? props.defaults.payload.timeBased
      : timeBasedDefaults;

    mainSection = (
      <CreateTimeBasedMetricForm
        {...props}
        defaults={defaults}
        inclusionCriteriaDefaults={props.defaults?.inclusionCriteria || []}
      />
    );
  } else if (metricType === "trigger") {
    const defaults: TriggerBasedMetricParams = props.defaults?.payload.triggerBased
      ? props.defaults.payload.triggerBased
      : triggerBasedDefaults;

    mainSection = (
      <CreateTriggerBasedMetricForm
        {...props}
        defaults={defaults}
        inclusionCriteriaDefaults={props.defaults?.inclusionCriteria || []}
      />
    );
  }

  const onChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === "time" || value === "trigger") {
      setMetricType(value);
    } else {
      setMetricType(null);
    }
    if (props.onDataChange) {
      // Reset the form from
      props.onDataChange(null);
    }
  };

  return (
    <>
      <Card>
        <CardHeader title={t("outcomes:create.metricType.title")} />
        <CardContent>
          <FormControl>
            <SectionHelpText text={t("outcomes:create.metricType.helpText")} />
            <RadioGroup value={metricType} onChange={onChange}>
              <FormControlLabel
                value={"time"}
                control={<Radio />}
                label={t("outcomes:create.metricType.time")}
              />
              <FormControlLabel
                value={"trigger"}
                control={<Radio />}
                label={t("outcomes:create.metricType.trigger")}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
      {mainSection}
    </>
  );
}
type CreateMetricFormProps<ResponseType> = {
  scaleScorerSupportedOptions: ScaleScorerSupportedOptions;
  defaults: RemoteData<Error, MetricParams>;
  onSuccess?: (response: ResponseType) => void;
  onDataChange?: (properties: MetricFormParams | null) => void;
  onSave: (fields: MetricFormParams) => void;
  errorMessage: string;
  forceFormInvalid?: boolean;
  submitButtonText: string;
  remoteData?: MutationRemoteDataResult<ResponseType>;
};

export default function CreateMetricForm<ResponseType>(props: CreateMetricFormProps<ResponseType>) {
  return props.defaults.caseOf({
    Success: (defaults) => {
      return <CreateMetricInnerForm {...props} defaults={defaults} />;
    },
    NotAsked: () => {
      return <CreateMetricInnerForm {...props} defaults={null} />;
    },
    Failure: () => {
      return <CreateMetricInnerForm {...props} defaults={null} />;
    },
    Loading: () => {
      return <Spinner />;
    },
  });
}
