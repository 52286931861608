import { Box, Button, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import {
  MetricInclusionCriterion,
  MetricInclusionCriterionParams,
  MetricParams,
  MetricSuccessCriterion,
  MetricSuccessCriterionParams,
  TimeBasedAggregationType,
  TimeBasedMetric,
  TimeBasedMetricParams,
  TimePeriodType,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Form, useForm, useListField, useNumberField, useWrappedField } from "Shared/Form";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TimePeriodForm from "./FormElements/TimePeriodForm";
import TimeBasedAggregationForm from "./FormElements/TimeBasedAggregationForm";
import SuccessCriteriaForm from "./FormElements/SuccessCriteriaForm";
import InclusionCriteriaForm from "./FormElements/InclusionCriteriaForm";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { ScaleScorerSupportedOptions } from "./OutcomesFormHelpers";

type TimeBasedMetricFormParams = Omit<MetricParams, "scaleScorerConfig" | "populationId">;

export function CreateTimeBasedMetricForm<ResponseType>(
  props: CreateTimeBasedMetricFormProps<ResponseType>
): ReactElement {
  const { t } = useTranslation(["outcomes", "common"]);

  const fields = {
    timeBasedAggregationType: useWrappedField<TimeBasedAggregationType>({
      default: props.defaults.timeBasedAggregationType,
      required: true,
    }),
    timePeriodType: useWrappedField<TimePeriodType>({
      default: props.defaults.timePeriodType || TimePeriodType.MONTH_OF_TREATMENT,
      required: true,
    }),
    // Make this required for now. Ideally it's only required if time period is set to the right thing.
    timePeriodWeek: useNumberField({
      required: true,
      default: props.defaults.timePeriodValue || 1,
    }),
    timePeriodMonth: useNumberField({
      required: true,
      default: props.defaults.timePeriodValue || 1,
    }),
    successCriteria: useListField<MetricSuccessCriterionParams>({
      default: [...props.defaults.successCriteria],
      required: true,
      validate: (criteria) => {
        if (criteria.length === 0) {
          return t("outcomes:create.validation.atLeastOneSuccess");
        }
        // If the criterion has an array for severity but it's empty, that's an error.
        if (
          criteria.findIndex(
            (criterion) => criterion.severityValues && criterion.severityValues.length === 0
          ) > -1
        ) {
          return t("outcomes:create.validation.invalidSuccess");
        }
        return null;
      },
    }),
    inclusionCriteria: useListField<MetricInclusionCriterionParams>({
      default: [...props.inclusionCriteriaDefaults],
      required: true,
      validate: (criteria) => {
        // If the criterion has an array for severity but it's empty, that's an error.
        if (
          criteria.findIndex(
            (criterion) => criterion.severityValues && criterion.severityValues.length === 0
          ) > -1
        ) {
          return t("outcomes:create.validation.invalidInclusionSeverity");
        }
        return null;
      },
    }),
  };

  // this is in a thunk so that it's only evaluated when the form is known to be valid
  const transformFields = (): TimeBasedMetricFormParams => {
    // Work out which time period value we actually want.
    let timePeriodValue = undefined;
    if (fields.timePeriodType.value === TimePeriodType.MONTH_OF_TREATMENT) {
      timePeriodValue = fields.timePeriodMonth.value as unknown as number;
    } else if (fields.timePeriodType.value === TimePeriodType.WEEK_OF_TREATMENT) {
      timePeriodValue = fields.timePeriodWeek.value as unknown as number;
    }

    return {
      inclusionCriteria: fields.inclusionCriteria.value,
      payload: {
        timeBased: {
          successCriteria: fields.successCriteria.value,
          timeBasedAggregationType: fields.timeBasedAggregationType
            .value as unknown as TimeBasedAggregationType,
          timePeriodType: fields.timePeriodType.value as unknown as TimePeriodType,
          timePeriodValue,
        },
      },
    };
  };

  const form = useForm({
    id: "edit-metric-form",
    fields: fields,
    onSuccess: props.onSuccess,
    submit: () => {
      props.onSave(transformFields());
    },
    remoteData: props.remoteData,
  });

  let transformed: TimeBasedMetricFormParams | null = null;

  if (form.isValid) {
    transformed = transformFields();
  }

  // Calling the individual elements out seems to be more effective than using deep compare, and simple
  // compare does not work.
  useEffect(() => {
    if (props.onDataChange) {
      props.onDataChange(transformed);
    }
  }, [
    transformed?.inclusionCriteria,
    transformed?.payload.timeBased?.timePeriodValue,
    transformed?.payload.timeBased?.timeBasedAggregationType,
    transformed?.payload.timeBased?.timePeriodType,
    transformed?.payload.timeBased?.successCriteria,
  ]);

  return (
    <Form onSubmit={form.onSubmit} id={form.id}>
      <Card>
        <CardHeader title={t("outcomes:create.configureMetric")} sx={{ paddingBottom: 0 }} />
        <CardContent>
          <Stack direction="column" spacing={2.5}>
            <TimeBasedAggregationForm timeBasedAggregationType={fields.timeBasedAggregationType} />
            <TimePeriodForm
              timePeriodTypeField={fields.timePeriodType}
              timePeriodMonthField={fields.timePeriodMonth}
              timePeriodWeekField={fields.timePeriodWeek}
            />
            <InclusionCriteriaForm
              inclusionCriteriaField={fields.inclusionCriteria}
              supportedSeverities={props.scaleScorerSupportedOptions.allSeverities}
            />
            <SuccessCriteriaForm
              successCriteriaField={fields.successCriteria}
              supportedSeverities={props.scaleScorerSupportedOptions.commonSeverities}
              isNumerical={props.scaleScorerSupportedOptions.isNumerical}
              supportedTrends={props.scaleScorerSupportedOptions.supportedTrends}
            />
          </Stack>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            form={form.id}
            disabled={form.disableSubmit || props.forceFormInvalid}
          >
            {props.submitButtonText}
          </Button>
        </CardActions>
      </Card>
    </Form>
  );
}

type SectionHelpTextProps = {
  text: string;
};
// This is our breakout boxes after each section on this form, explaining what
// each section hopes to accomplish.
export function SectionHelpText(props: SectionHelpTextProps): ReactElement {
  return (
    <Box paddingBottom={"1em"}>
      <Typography variant="body1">{props.text}</Typography>
    </Box>
  );
}

type CreateTimeBasedMetricFormProps<ResponseType> = {
  defaults: TimeBasedMetricParams;
  inclusionCriteriaDefaults: ReadonlyArray<MetricInclusionCriterionParams>;
  onSuccess?: (response: ResponseType) => void;
  onDataChange?: (properties: TimeBasedMetricFormParams | null) => void;
  onSave: (fields: TimeBasedMetricFormParams) => void;
  errorMessage: string;
  scaleScorerSupportedOptions: ScaleScorerSupportedOptions;
  forceFormInvalid?: boolean;
  submitButtonText: string;
  remoteData?: MutationRemoteDataResult<ResponseType>;
};

export type TimeBasedMetricDefaults = Pick<
  TimeBasedMetric,
  "__typename" | "timeBasedAggregationType" | "timePeriodType" | "timePeriodValue"
> & {
  metricSuccessCriteria: ReadonlyArray<
    Omit<MetricSuccessCriterion, "globalId" | "id" | "metric" | "__typename">
  >;
} & {
  metricInclusionCriteria: ReadonlyArray<
    Omit<MetricInclusionCriterion, "globalId" | "id" | "metric" | "__typename">
  >;
};
