import React, { ReactElement, ReactNode } from "react";

import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { BadTextTypography, GoodTextTypography } from "MDS/MDSTypography";
import { QuestionMark } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export enum AlgorithmDecisionResult {
  SUCCESS = "SUCCESS",
  FAILURE = "FAILURE",
  UNKNOWN = "UNKNOWN",
}

function DecisionRow({
  children,
  result,
}: {
  children: ReactNode;
  result: AlgorithmDecisionResult;
}): ReactElement {
  return (
    <TableRow>
      <TableCell component="th" scope="row" align={"left"}>
        {children}
      </TableCell>
      <DecisionIconCell result={result} />
    </TableRow>
  );
}

function DecisionIconCell({ result }: { result: AlgorithmDecisionResult }): ReactElement {
  const middle = (() => {
    switch (result) {
      case AlgorithmDecisionResult.SUCCESS:
        return (
          <GoodTextTypography component="span">
            <CheckIcon />
          </GoodTextTypography>
        );
      case AlgorithmDecisionResult.FAILURE:
        return (
          <BadTextTypography component="span">
            <DoNotDisturbIcon />
          </BadTextTypography>
        );
      case AlgorithmDecisionResult.UNKNOWN:
        return <QuestionMark />;
      default:
        return null;
    }
  })();

  return <TableCell align="right">{middle}</TableCell>;
}

export type DecisionTableSection = {
  topElement?: string | ReactNode;
  bottomElement?: string | ReactNode;
  rows: ReadonlyArray<{ description: string; result: AlgorithmDecisionResult }>;
};

function DecisionTableSection(props: { section: DecisionTableSection; hideUnknown?: boolean }) {
  const { t } = useTranslation(["common"]);
  const { section } = props;
  const rows = section.rows;
  const topText = props.section.topElement ? (
    <TableRow key="topText">
      <TableCell colSpan={2}>{props.section.topElement}</TableCell>
    </TableRow>
  ) : null;

  const bottomText = props.section.bottomElement ? (
    <TableRow key="bottomText">
      <TableCell colSpan={2}>{props.section.bottomElement}</TableCell>
    </TableRow>
  ) : null;

  const unknownRowCount = rows.filter((row) => row.result === AlgorithmDecisionResult.UNKNOWN).length;

  const allSuccessful =
    rows.every((row) => row.result === AlgorithmDecisionResult.SUCCESS) && rows.length > 0;

  // If everything is a success, show nothing in hideUnknown mode. Otherwise show successes and failures but ignore unknowns.
  // For show everything, always show everything.
  const rowsToUse = props.hideUnknown
    ? allSuccessful
      ? []
      : rows.filter((row) => {
          return row.result !== AlgorithmDecisionResult.UNKNOWN;
        })
    : rows;

  const rowContent = rowsToUse.map((row, i) => {
    return (
      <DecisionRow result={row.result} key={i}>
        {row.description}
      </DecisionRow>
    );
  });

  // If all successful, display the success check. If some hidden for unknown, say that instead.
  const hiddenRowText = props.hideUnknown ? (
    allSuccessful ? (
      <TableRow key="hiddenRows">
        <TableCell colSpan={2}>{t("common:decisionTable.successfulItems", { count: rows.length })}</TableCell>
      </TableRow>
    ) : unknownRowCount > 0 ? (
      <TableRow key="hiddenRows">
        <TableCell colSpan={2}>
          {t("common:decisionTable.additionalItems", { count: unknownRowCount })}
        </TableCell>
      </TableRow>
    ) : null
  ) : null;

  return (
    <>
      {topText}
      {rowContent}
      {hiddenRowText}
      {bottomText}
    </>
  );
}

type DecisionTableProps = {
  sections: ReadonlyArray<DecisionTableSection>;
  hideUnknown?: boolean;
};

export default function DecisionTable(props: DecisionTableProps) {
  const sections = props.sections.map((section, i) => {
    return <DecisionTableSection hideUnknown={props.hideUnknown} section={section} key={i} />;
  });
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" size="small" sx={{ maxWidth: 350 }}>
        <TableBody>{sections}</TableBody>
      </Table>
    </TableContainer>
  );
}
