import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  MetricSuccessCriterionType,
  Operator,
  SeverityCategory,
  Trend,
  MetricSuccessCriterionParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import { FieldMethods } from "Shared/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import { SeveritySelectMultiple } from "./SeverityHelpers";
import { OperatorSelect, TrendSelect } from "./CommonOutcomeFormElements";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";

type SuccessCriteriaFormProps = {
  successCriteriaField: FieldMethods<
    ReadonlyArray<MetricSuccessCriterionParams>,
    ReadonlyArray<MetricSuccessCriterionParams>
  >;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
  supportedTrends: ReadonlyArray<Trend>;
  isNumerical: boolean;
};

type ItemFields = {
  disabled: boolean;
  row: MetricSuccessCriterionParams;
  onUpdate: (data: MetricSuccessCriterionParams) => void;
};

/**
 * Creates a simple field which looks like "My field value is <select operator> <value>"
 */
function OperatorField(
  props: ItemFields & { inputWidth?: string; label?: string | null; postLabel?: string | null }
) {
  const { row, disabled, onUpdate } = props;
  const inputWidth = props.inputWidth || "30px";

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const operator = row.operator || Operator.LESS_THAN;
  const value = row.numberValue || 0;

  const onOperatorChange = (event: SelectChangeEvent<Operator>) => {
    onUpdate({ ...row, operator: event.target.value as Operator });
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);

    // If for whatever reasons we can't work out a valid number, reset to zero.
    onUpdate({ ...row, numberValue: isNaN(newValue) ? 0 : newValue });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        {props.label ? <span>{props.label}</span> : null}
        <OperatorSelect operator={operator} disabled={disabled} onChange={onOperatorChange} />
        <FormControl>
          <Input
            sx={{ width: inputWidth }}
            onChange={onValueChange}
            type="number"
            value={value}
            disabled={disabled}
          />
        </FormControl>
        {props.postLabel ? <span>{props.postLabel}</span> : null}
      </Stack>
    </FormControl>
  );
}

function deltaIsIncreasing(value: number): "increasing" | "decreasing" {
  return value < 0 ? "decreasing" : "increasing";
}

function deltaOperatorToOperator(deltaOperator: Operator, value: number): Operator {
  switch (deltaOperator) {
    case Operator.EQUAL:
      return Operator.EQUAL;
    case Operator.LESS_THAN:
      return value < 0 ? Operator.GREATER_THAN : Operator.LESS_THAN;
    case Operator.GREATER_THAN:
      return value < 0 ? Operator.LESS_THAN : Operator.GREATER_THAN;
  }
}

/**
 * The delta controller is slightly complicated because although under the hood it deals with
 * things like '< -5' people find it difficult to understand so we have to translate into a simpler to understand
 */
function Delta(props: ItemFields) {
  const { t } = useTranslation(["outcomes"]);
  const { row, disabled, onUpdate } = props;

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const operator = row.operator || Operator.LESS_THAN;
  const value = row.numberValue || 0;

  const onOperatorChange = (event: SelectChangeEvent<Operator>) => {
    // We need some special handling of this as the user selects 'decrease greater than' meaning 'less than' as an operator
    onUpdate({ ...row, operator: deltaOperatorToOperator(event.target.value as Operator, value) });
  };

  const onModeChange = (_event: SelectChangeEvent<"increasing" | "decreasing">) => {
    // we want to keep the viewed operator the same when we switch the sign, so we have to switch the
    // operator
    const newOperator = (() => {
      switch (operator) {
        case Operator.LESS_THAN:
          return Operator.GREATER_THAN;
        case Operator.GREATER_THAN:
          return Operator.LESS_THAN;
        case Operator.EQUAL:
          return Operator.EQUAL;
      }
    })();

    onUpdate({ ...row, operator: newOperator, numberValue: value * -1 });
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    const multiplier = value < 0 ? -1 : 1;
    onUpdate({ ...row, numberValue: newValue * multiplier });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.successCriteria.delta.title")}</span>
        <FormControl>
          <Select
            sx={{ height: "2em" }}
            value={deltaIsIncreasing(value)}
            onChange={onModeChange}
            disabled={disabled}
          >
            <MenuItem value={"increasing"}>{t("outcomes:create.successCriteria.delta.increase")}</MenuItem>
            <MenuItem value={"decreasing"}>{t("outcomes:create.successCriteria.delta.decrease")}</MenuItem>
          </Select>
        </FormControl>
        <OperatorSelect
          operator={deltaOperatorToOperator(operator, value)}
          disabled={disabled}
          onChange={onOperatorChange}
        />
        <FormControl>
          <Input
            sx={{ width: "30px" }}
            onChange={onValueChange}
            type="number"
            value={Math.abs(value)}
            disabled={disabled}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

/**
 * Simple statement for 'the final value is < 5'
 */
function FinalValue(props: ItemFields) {
  const { t } = useTranslation(["outcomes"]);
  return <OperatorField label={t("outcomes:create.successCriteria.finalValue.title")} {...props} />;
}

/**
 * Simple statement for 'the final value is < 50% of the initial
 */
function FinalValuePercent(props: ItemFields) {
  const { t } = useTranslation(["outcomes"]);
  return (
    <OperatorField
      label={t("outcomes:create.successCriteria.finalValuePercent.title")}
      {...props}
      inputWidth={"40px"}
      postLabel={t("outcomes:create.successCriteria.finalValuePercent.units")}
    />
  );
}

function TrendRow(props: ItemFields) {
  const { t } = useTranslation(["outcomes"]);
  const { row, disabled, onUpdate } = props;

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const value = row.trendValue || Trend.IMPROVING;

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.successCriteria.trend.title")}</span>
        <FormControl>
          <TrendSelect
            disabled={disabled}
            trend={value}
            onChange={(trend) => onUpdate({ ...row, trendValue: trend })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

function SeverityRow(props: ItemFields & { supportedSeverities: ReadonlyArray<SeverityCategory> }) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const value = row.severityValues || [SeverityCategory.MILD];

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.successCriteria.severity.title")}</span>
        <FormControl>
          <SeveritySelectMultiple
            disabled={disabled}
            supportedSeverities={props.supportedSeverities}
            severity={value}
            onChange={(severity) => onUpdate({ ...row, severityValues: severity })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

const defaultValues: Record<MetricSuccessCriterionType, MetricSuccessCriterionParams> = {
  [MetricSuccessCriterionType.DELTA]: {
    criterionType: MetricSuccessCriterionType.DELTA,
    operator: Operator.LESS_THAN,
    numberValue: -3,
  },
  [MetricSuccessCriterionType.FINAL_VALUE]: {
    criterionType: MetricSuccessCriterionType.FINAL_VALUE,
    operator: Operator.LESS_THAN,
    numberValue: 3,
  },
  [MetricSuccessCriterionType.FINAL_VALUE_PERCENT]: {
    criterionType: MetricSuccessCriterionType.FINAL_VALUE_PERCENT,
    operator: Operator.LESS_THAN,
    numberValue: 50,
  },
  [MetricSuccessCriterionType.TREND]: {
    criterionType: MetricSuccessCriterionType.TREND,
    trendValue: Trend.IMPROVING,
  },
  [MetricSuccessCriterionType.SEVERITY]: {
    criterionType: MetricSuccessCriterionType.SEVERITY,
    severityValues: [SeverityCategory.NONE],
  },
  [MetricSuccessCriterionType.TREATMENT_REMISSION]: {
    criterionType: MetricSuccessCriterionType.TREATMENT_REMISSION,
  },
  [MetricSuccessCriterionType.TREATMENT_RESPONSE]: {
    criterionType: MetricSuccessCriterionType.TREATMENT_RESPONSE,
  },
  [MetricSuccessCriterionType.IMPROVED_SEVERITY]: {
    criterionType: MetricSuccessCriterionType.IMPROVED_SEVERITY,
  },
};

function Item(props: {
  active: boolean;
  row: MetricSuccessCriterionParams;
  onCheckboxChange: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onUpdate: (data: MetricSuccessCriterionParams) => void;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
}) {
  const { active, row, onCheckboxChange, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  const subprops = { disabled: !active, onUpdate, row };

  // Assign a default for now for compiler
  const component = (() => {
    switch (row.criterionType) {
      case MetricSuccessCriterionType.DELTA:
        return <Delta {...subprops} />;
      case MetricSuccessCriterionType.FINAL_VALUE:
        return <FinalValue {...subprops} />;
      case MetricSuccessCriterionType.TREND:
        return <TrendRow {...subprops} />;
      case MetricSuccessCriterionType.FINAL_VALUE_PERCENT:
        return <FinalValuePercent {...subprops} />;
      case MetricSuccessCriterionType.SEVERITY:
        return <SeverityRow {...subprops} supportedSeverities={props.supportedSeverities} />;
      case MetricSuccessCriterionType.TREATMENT_REMISSION:
        return <Typography>{t("outcomes:create.successCriteria.treatmentRemission.title")}</Typography>;
      case MetricSuccessCriterionType.TREATMENT_RESPONSE:
        return <Typography>{t("outcomes:create.successCriteria.treatmentResponse.title")}</Typography>;
      case MetricSuccessCriterionType.IMPROVED_SEVERITY:
        return <Typography>{t("outcomes:create.successCriteria.improvedSeverity.title")}</Typography>;
      default:
        return <Delta {...subprops} />;
    }
  })();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={active} onChange={onCheckboxChange} />}
        label={component}
      />
    </FormGroup>
  );
}

export default function SuccessCriteriaForm(props: SuccessCriteriaFormProps) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);
  // Technically in the back end this is just a collection, but to make the UI simpler we're going to offer a set of checkboxes
  // one per type of success criteria.
  // This component will react and spit up a full set of objects, but the checkbox component itself is purely internal
  const [currentData, setCurrentData] =
    React.useState<Record<MetricSuccessCriterionType, MetricSuccessCriterionParams>>(defaultValues);

  const activeNodes: Partial<Record<MetricSuccessCriterionType, boolean>> = {};

  props.successCriteriaField.value?.forEach((value) => {
    currentData[value.criterionType] = value;
    activeNodes[value.criterionType] = true;
  });

  const handleCheckboxChange = (criterionType: MetricSuccessCriterionType) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const set = props.successCriteriaField.value || [];
      const without = set.filter((s) => s.criterionType !== criterionType);
      const item = currentData[criterionType];

      if (checked) {
        props.successCriteriaField.onChange([...without, item]);
      } else {
        props.successCriteriaField.onChange(without);
      }
    };
  };

  const handleDetailsChange = (criterionType: MetricSuccessCriterionType) => {
    return (newValue: MetricSuccessCriterionParams) => {
      const set = props.successCriteriaField.value || [];
      const without = set.filter((s) => s.criterionType !== criterionType);
      setCurrentData({ ...currentData, [criterionType]: newValue });
      props.successCriteriaField.onChange([...without, newValue]);
    };
  };

  const responseControl = (
    <Item
      active={activeNodes.TREATMENT_RESPONSE || false}
      onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.TREATMENT_RESPONSE)}
      onUpdate={handleDetailsChange(MetricSuccessCriterionType.TREATMENT_RESPONSE)}
      row={currentData.TREATMENT_RESPONSE}
      supportedSeverities={props.supportedSeverities}
    />
  );

  const remissionControl = props.supportedSeverities.includes(SeverityCategory.NONE) ? (
    <Item
      active={activeNodes.TREATMENT_REMISSION || false}
      onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.TREATMENT_REMISSION)}
      onUpdate={handleDetailsChange(MetricSuccessCriterionType.TREATMENT_REMISSION)}
      row={currentData.TREATMENT_REMISSION}
      supportedSeverities={props.supportedSeverities}
    />
  ) : null;

  let numericalControls = null;

  if (props.isNumerical) {
    numericalControls = (
      <>
        <Item
          active={activeNodes.DELTA || false}
          onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.DELTA)}
          onUpdate={handleDetailsChange(MetricSuccessCriterionType.DELTA)}
          row={currentData.DELTA}
          supportedSeverities={props.supportedSeverities}
        />
        <Item
          active={activeNodes.FINAL_VALUE || false}
          onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.FINAL_VALUE)}
          onUpdate={handleDetailsChange(MetricSuccessCriterionType.FINAL_VALUE)}
          row={currentData.FINAL_VALUE}
          supportedSeverities={props.supportedSeverities}
        />
        <Item
          active={activeNodes.FINAL_VALUE_PERCENT || false}
          onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.FINAL_VALUE_PERCENT)}
          onUpdate={handleDetailsChange(MetricSuccessCriterionType.FINAL_VALUE_PERCENT)}
          row={currentData.FINAL_VALUE_PERCENT}
          supportedSeverities={props.supportedSeverities}
        />
      </>
    );
  }

  let trendControl = null;

  if (props.supportedTrends.length > 0) {
    trendControl = (
      <Item
        active={activeNodes.TREND || false}
        onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.TREND)}
        onUpdate={handleDetailsChange(MetricSuccessCriterionType.TREND)}
        row={currentData.TREND}
        supportedSeverities={props.supportedSeverities}
      />
    );
  }

  let severityControls = null;

  if (props.supportedSeverities.length > 0) {
    severityControls = (
      <>
        <Item
          active={activeNodes.SEVERITY || false}
          onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.SEVERITY)}
          onUpdate={handleDetailsChange(MetricSuccessCriterionType.SEVERITY)}
          row={currentData.SEVERITY}
          supportedSeverities={props.supportedSeverities}
        />
        <Item
          active={activeNodes.IMPROVED_SEVERITY || false}
          onCheckboxChange={handleCheckboxChange(MetricSuccessCriterionType.IMPROVED_SEVERITY)}
          onUpdate={handleDetailsChange(MetricSuccessCriterionType.IMPROVED_SEVERITY)}
          row={currentData.IMPROVED_SEVERITY}
          supportedSeverities={props.supportedSeverities}
        />
      </>
    );
  }

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.successCriteria.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.successCriteria.helpText")} />
      {responseControl}
      {remissionControl}
      {numericalControls}
      {trendControl}
      {severityControls}
    </FormControl>
  );
}
