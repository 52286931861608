import {
  MetricInclusionCriterion,
  MetricInclusionCriterionType,
  MetricParams,
  MetricSuccessCriterion,
  MetricSuccessCriterionType,
  MetricTrigger,
  MetricTriggerType,
  Operator,
  SeverityCategory,
  TimeBasedAggregationType,
  TimeBasedMetric,
  TimePeriodType,
  Trend,
  TriggerBasedAggregationType,
  TriggerBasedMetric,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { ChartDataItem } from "Shared/Graphs/AnalyticsLineChart";
import { Stack, Typography } from "@mui/material";
import SimpleTooltip from "MDS/Tooltip/SimpleTooltip";
import { scaleMediumName } from "Shared/Scale/Scale";
import { TimeBasedMetricConfiguration, TriggerBasedMetricConfiguration } from "./OutcomesMetricCard";
import { PickTypename } from "type-utils";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { severityCategoryT, trendT } from "GeneratedGraphQL/EnumTranslations";
import { MetricScaleScorerConfigurationWithName } from "./OutcomesFormHelpers";

/*
 * This file contains a variety of text helpers to allow you to get text about a metric, for example what it triggers on,
 * what period it measures, etc.
 */

type MetricSuccessCriterionDetails = Pick<
  MetricSuccessCriterion,
  | "id"
  | "criterionType"
  | "integerValue"
  | "operator"
  | "severityValues"
  | "stringValue"
  | "trendValue"
  | "numberValue"
>;

type MetricInclusionCriteriaDetails = Pick<
  MetricInclusionCriterion,
  | "id"
  | "criterionType"
  | "integerValue"
  | "operator"
  | "severityValues"
  | "stringArrayValue"
  | "stringValue"
  | "trendValue"
  | "numberValue"
  | "excludeResults"
  | "integerArrayValue"
>;

type MetricTriggerDetails = Pick<
  MetricTrigger,
  "id" | "triggerType" | "integerValue" | "severityValue" | "stringArrayValue" | "stringValue" | "trendValue"
>;

type HasTriggerBasedAggregation = PickTypename<TriggerBasedMetric, "triggerBasedAggregationType">;
type HasTimeBasedAggregation = PickTypename<TimeBasedMetric, "timeBasedAggregationType">;
export type HasAggregationType = HasTriggerBasedAggregation | HasTimeBasedAggregation;

function metricLabelGenerator<T extends TFunction<["outcomes"]>>(metric: HasAggregationType, t: T) {
  if (metric.__typename === "TimeBasedMetric") {
    return timeBasedMetricLabelGenerator(metric.timeBasedAggregationType);
  } else {
    return triggerBasedMetricLabelGenerator(metric.triggerBasedAggregationType, t);
  }
}

function metricLabelGeneratorFromParams(metric: MetricParams, t: TFunction<["outcomes"]>) {
  if (metric.payload.timeBased) {
    return timeBasedMetricLabelGenerator(metric.payload.timeBased.timeBasedAggregationType);
  } else {
    return triggerBasedMetricLabelGenerator(metric.payload.triggerBased.triggerBasedAggregationType, t);
  }
}

function metricValueFormatter(metric: HasAggregationType) {
  if (metric.__typename === "TimeBasedMetric") {
    return timeBasedMetricValueFormatter(metric.timeBasedAggregationType);
  } else {
    return triggerBasedMetricValueFormatter(metric.triggerBasedAggregationType);
  }
}

function metricValueFormatterFromParams(metric: MetricParams) {
  if (metric.payload.timeBased) {
    return timeBasedMetricValueFormatter(metric.payload.timeBased.timeBasedAggregationType);
  } else {
    return triggerBasedMetricValueFormatter(metric.payload.triggerBased.triggerBasedAggregationType);
  }
}

/**
 *  Returns units, e.g. "avg days". For metrics
 */
function metricUnits(metric: HasAggregationType, t: TFunction<["outcomes"]>): string | null {
  if (metric.__typename === "TimeBasedMetric") {
    return timeBasedMetricUnits(metric.timeBasedAggregationType, t);
  } else {
    return triggerBasedMetricUnits(metric.triggerBasedAggregationType, t);
  }
}

function metricYAccessor(metric: HasAggregationType) {
  if (metric.__typename === "TimeBasedMetric") {
    return timeBasedMetricYAccessor(metric.timeBasedAggregationType);
  } else {
    return triggerBasedMetricYAccessor(metric.triggerBasedAggregationType);
  }
}

function metricYAccessorFromParams(metric: MetricParams) {
  if (metric.payload.timeBased) {
    return timeBasedMetricYAccessor(metric.payload.timeBased.timeBasedAggregationType);
  } else {
    return triggerBasedMetricYAccessor(metric.payload.triggerBased.triggerBasedAggregationType);
  }
}

function metricYMax(
  metric: HasAggregationType,
  data: ReadonlyArray<ChartDataItem> | undefined
): number | undefined {
  if (
    metric.__typename === "TimeBasedMetric" &&
    metric.timeBasedAggregationType === TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS
  ) {
    return 100;
  } else {
    if (data) {
      return Math.max(...data.map((a) => a.value)) || undefined;
    } else {
      return undefined;
    }
  }
}

function metricParamsYMax(
  metricParams: MetricParams,
  data: ReadonlyArray<ChartDataItem> | undefined
): number | undefined {
  if (
    metricParams.payload.timeBased &&
    metricParams.payload.timeBased.timeBasedAggregationType ===
      TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS
  ) {
    return 100;
  } else {
    if (data) {
      return Math.max(...data.map((a) => a.value)) || undefined;
    } else {
      return undefined;
    }
  }
}

function timeBasedMetricLabelGenerator(aggregationType: TimeBasedAggregationType) {
  switch (aggregationType) {
    case TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS:
      // Percentage of patients comes as a unity figure, so needs to be multiplied.
      return (d: ChartDataItem) => `${(d.value * 100).toFixed(1)}%`;
    case TimeBasedAggregationType.NUMBER_OF_PATIENTS:
      // Number of patients is an integer
      return (d: ChartDataItem) => d.value.toFixed(0);
  }
}

function timeBasedMetricValueFormatter(aggregationType: TimeBasedAggregationType) {
  switch (aggregationType) {
    case TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS:
      // Percentage of patients comes as a unity figure, so needs to be multiplied.
      return (d: number) => `${(d * 100).toFixed(1)}%`;
    case TimeBasedAggregationType.NUMBER_OF_PATIENTS:
      // Number of patients is an integer
      return (d: number) => d.toFixed(0);
  }
}

function timeBasedMetricYAccessor(aggregationType: TimeBasedAggregationType) {
  switch (aggregationType) {
    case TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS:
      // Percentage of patients comes as a unity figure, so needs to be multiplied.
      return (d: ChartDataItem) => {
        return (d.value * 100).toFixed(1);
      };
    case TimeBasedAggregationType.NUMBER_OF_PATIENTS:
      // Number of patients is an integer
      return (d: ChartDataItem) => d.value.toFixed(0);
  }
}

function timeBasedAggregationSummary(aggregationType: TimeBasedAggregationType) {
  switch (aggregationType) {
    case TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS:
      return "% of patients";
    case TimeBasedAggregationType.NUMBER_OF_PATIENTS:
      return "# of patients";
  }
}

function triggerBasedMetricValueFormatter(aggregationType: TriggerBasedAggregationType) {
  switch (aggregationType) {
    case TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START:
      // do a single decimal point
      return (d: number) => d.toFixed(1);
  }
}

function timeBasedMetricUnits(
  aggregationType: TimeBasedAggregationType,
  t: TFunction<["outcomes"]>
): string | null {
  switch (aggregationType) {
    case TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS:
      return null;
    case TimeBasedAggregationType.NUMBER_OF_PATIENTS:
      return t("outcomes:units.timeBased.patients");
  }
}

function triggerBasedMetricUnits(aggregationType: TriggerBasedAggregationType, t: TFunction<["outcomes"]>) {
  switch (aggregationType) {
    case TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START:
      // do a single decimal point
      return t("outcomes:units.triggerBased.avgDays");
  }
}

function triggerBasedAggregationSummary(
  aggregationType: TriggerBasedAggregationType,
  t: TFunction<["outcomes"]>
) {
  switch (aggregationType) {
    case TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START:
      return t("outcomes:units.triggerBased.averageNumberOfDays");
  }
}

function triggerBasedMetricYAccessor(aggregationType: TriggerBasedAggregationType) {
  switch (aggregationType) {
    case TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START:
      // Give it a single decimal point for now
      return (d: ChartDataItem) => d.value.toFixed(1);
  }
}

function triggerBasedMetricLabelGenerator(
  aggregationType: TriggerBasedAggregationType,
  t: TFunction<["outcomes"]>
) {
  switch (aggregationType) {
    case TriggerBasedAggregationType.AVERAGE_DAYS_SINCE_TREATMENT_START:
      // Number of patients is an integer
      return (d: ChartDataItem) => d.value.toFixed(1) + " " + t("outcomes:units.triggerBased.days");
  }
}

function scaleSummary(scaleConfig: MetricScaleScorerConfigurationWithName) {
  if (scaleConfig.__typename === "MetricSingleScaleScorerConfiguration") {
    return `as measured on ${scaleMediumName(scaleConfig.scaleScorer.scale)}`;
  } else {
    if (scaleConfig.scaleScorers.length < 6) {
      return `as measured on ${scaleConfig.scaleScorers
        .map((scorer) => scaleMediumName(scorer.scale))
        .join(", ")}`;
    } else {
      const toDisplay = scaleConfig.scaleScorers.slice(0, 4);

      return `as measured on ${toDisplay.map((scorer) => scaleMediumName(scorer.scale)).join(", ")} and ${
        scaleConfig.scaleScorers.length - toDisplay.length
      } more`;
    }
  }
}

function timeBasedTimeSummary(
  timePeriodType: TimePeriodType,
  timePeriodValue: number | null,
  t: TFunction<["outcomes"]>
) {
  switch (timePeriodType) {
    case TimePeriodType.LAST_MEASUREMENT:
      return t("outcomes:summaries.lastMeasurement");
    case TimePeriodType.WEEK_OF_TREATMENT:
      // In theory you shouldn't have a null time period for this entry but it's technically possible, so set to 0 to make it not crash
      return t("outcomes:summaries.weekOfTreatment", { value: (timePeriodValue || 0).toFixed(0) });
    case TimePeriodType.MONTH_OF_TREATMENT:
      // In theory you shouldn't have a null time period for this entry but it's technically possible, so set to 0 to make it not crash
      return t("outcomes:summaries.monthOfTreatment", { value: (timePeriodValue || 0).toFixed(0) });
  }
}

function SuccessCriteriaSummary(props: {
  successCriteria: ReadonlyArray<MetricSuccessCriterionDetails>;
}): ReactElement {
  const criterion = props.successCriteria[0];
  const { t } = useTranslation(["outcomes"]);
  switch (props.successCriteria.length) {
    case 0:
      return <>{t("outcomes:summaries.stillInTreatment")}</>;
    case 1:
      if (criterion) {
        return (
          <>
            {successCriterionText(
              criterion.criterionType,
              criterion.numberValue,
              criterion.operator,
              criterion.severityValues,
              criterion.trendValue,
              t
            )}
          </>
        );
      } else {
        // This technically is not reachable I'm just not going to argue with typescript right now;
        return <>{t("outcomes:summaries.stillInTreatment")}</>;
      }
    default:
      return <>{t("outcomes:summaries.meetingSuccessCriteria", { value: props.successCriteria.length })}</>;
  }
}

function successCriterionText(
  criterionType: MetricSuccessCriterionType,
  numberValue: number | null,
  operator: Operator | null,
  severities: ReadonlyArray<SeverityCategory> | null,
  trend: Trend | null,
  t: TFunction<["outcomes", "enums"]>
) {
  switch (criterionType) {
    case MetricSuccessCriterionType.DELTA:
      if (numberValue && operator == Operator.LESS_THAN) {
        const number = Math.abs(numberValue);
        if (numberValue < 0) {
          return t("outcomes:successCriteria.deltaDecreaseMoreThan", { value: number });
        } else {
          return t("outcomes:successCriteria.deltaDecreaseLessThan", { value: number });
        }
      } else if (numberValue && operator == Operator.GREATER_THAN) {
        const number = Math.abs(numberValue);
        if (numberValue < 0) {
          return t("outcomes:successCriteria.deltaIncreaseMoreThan", { value: number });
        } else {
          return t("outcomes:successCriteria.deltaIncreaseLessThan", { value: number });
        }
      }
      return t("outcomes:successCriteria.deltaOperator", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricSuccessCriterionType.FINAL_VALUE:
      return t("outcomes:successCriteria.finalValue", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricSuccessCriterionType.SEVERITY:
      return t("outcomes:successCriteria.severity", {
        severity: severities?.map((s) => severityCategoryT(s, t)).join(", "),
      });
    case MetricSuccessCriterionType.FINAL_VALUE_PERCENT:
      return t("outcomes:successCriteria.finalValuePercent", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricSuccessCriterionType.TREND:
      return t("outcomes:successCriteria.trend", {
        trend: trend ? trendT(trend, t) : "",
      });
    case MetricSuccessCriterionType.TREATMENT_REMISSION:
      return t("outcomes:successCriteria.remission");
    case MetricSuccessCriterionType.TREATMENT_RESPONSE:
      return t("outcomes:successCriteria.response");
    case MetricSuccessCriterionType.IMPROVED_SEVERITY:
      return t("outcomes:successCriteria.improvedSeverity");
  }
}

function InclusionCriteriaSummary(props: {
  inclusionCriteria: ReadonlyArray<MetricInclusionCriteriaDetails>;
}): ReactElement | null {
  const { t } = useTranslation(["outcomes"]);
  if (props.inclusionCriteria.length < 1) {
    return null;
  }

  const inclusionReasons = props.inclusionCriteria.map((c) => {
    return (
      <li key={c.id.toString()}>
        {
          <InclusionCriterionText
            criterionType={c.criterionType}
            numberValue={c.numberValue}
            operator={c.operator}
            severityValues={c.severityValues}
            excludeResults={c.excludeResults}
            integerArrayValue={c.integerArrayValue}
            stringArrayValue={c.stringArrayValue}
          />
        }
      </li>
    );
  });

  const tooltipTitle = (
    <Stack direction="column" spacing={1}>
      {t("outcomes:inclusionCriteria.meetingAll")}
      <ul>{inclusionReasons}</ul>
    </Stack>
  );

  return (
    <SimpleTooltip title={tooltipTitle}>
      <Typography component="span">
        {t("outcomes:inclusionCriteria.meetingXCriteria", { count: props.inclusionCriteria.length })}
      </Typography>
    </SimpleTooltip>
  );
}

function InclusionCriterionText(props: {
  criterionType: MetricInclusionCriterionType;
  numberValue: number | null;
  operator: Operator | null;
  severityValues: ReadonlyArray<SeverityCategory> | null;
  stringArrayValue: ReadonlyArray<string | null> | null;
  integerArrayValue: ReadonlyArray<number | null> | null;
  excludeResults: boolean | null;
}) {
  const { t } = useTranslation(["outcomes", "enums"]);
  const {
    criterionType,
    numberValue,
    operator,
    severityValues: severityType,
    integerArrayValue,
    stringArrayValue,
    excludeResults,
  } = props;

  switch (criterionType) {
    case MetricInclusionCriterionType.AGE_AT_TREATMENT_START:
      return t("outcomes:inclusionCriteria.ageAtTreatmentStart", {
        min: (integerArrayValue || [])[0] || "?",
        max: (integerArrayValue || [])[1] || "?",
      });
    case MetricInclusionCriterionType.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE:
      return t("outcomes:inclusionCriteria.daysBetweenStartAndBaseline", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricInclusionCriterionType.DAYS_SINCE_TREATMENT_START:
      return t("outcomes:inclusionCriteria.daysSinceTreatmentStart", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricInclusionCriterionType.BASELINE_SEVERITY:
      return t("outcomes:inclusionCriteria.baselineSeverity", {
        severities: severityType?.map((s) => severityCategoryT(s, t)).join(", "),
      });
    case MetricInclusionCriterionType.BASELINE_SCORE:
      return t("outcomes:inclusionCriteria.baselineScore", {
        operator: operatorValueText(operator, numberValue, t),
      });
    case MetricInclusionCriterionType.COCM_TARGET_SCALE:
      return t("outcomes:inclusionCriteria.cocmTargetScale");
    case MetricInclusionCriterionType.GENDER:
      return t("outcomes:inclusionCriteria.gender", {
        matching: excludeResults
          ? t("outcomes:inclusionCriteria.notMatching")
          : t("outcomes:inclusionCriteria.matching"),
        genders: stringArrayValue,
      });
    case MetricInclusionCriterionType.RACE:
      return t("outcomes:inclusionCriteria.race", {
        matching: excludeResults
          ? t("outcomes:inclusionCriteria.notMatching")
          : t("outcomes:inclusionCriteria.matching"),
        races: stringArrayValue,
      });
  }
}

function TriggerSummary(props: { triggers: ReadonlyArray<MetricTriggerDetails> }): ReactElement {
  const { t: outcomesT } = useTranslation(["outcomes"]);
  const { t: enumT } = useTranslation(["enums"]);
  const trigger = props.triggers[0];

  const reasons = props.triggers.map((t) => {
    return (
      <li key={t.id.toString()}>
        {triggerText(t.triggerType, t.trendValue, t.severityValue, outcomesT, enumT)}
      </li>
    );
  });

  const tooltipTitle = (
    <Stack direction="column" spacing={1}>
      {outcomesT("outcomes:triggers.meetingAll")}
      <ul>{reasons}</ul>
    </Stack>
  );

  switch (props.triggers.length) {
    case 0:
      return <>{outcomesT("outcomes:triggers.stillInTreatment")}</>;
    case 1:
      if (trigger) {
        return (
          <>{triggerText(trigger.triggerType, trigger.trendValue, trigger.severityValue, outcomesT, enumT)}</>
        );
      } else {
        // This technically is not reachable I'm just not going to argue with typescript right now;
        return <>{outcomesT("outcomes:triggers.unknown")}</>;
      }
    default:
      return (
        <SimpleTooltip title={tooltipTitle}>
          <Typography component="span">
            {outcomesT("outcomes:triggers.meetingX", { count: props.triggers.length })}
          </Typography>
        </SimpleTooltip>
      );
  }
}

function triggerText(
  triggerType: MetricTriggerType,
  trendValue: Trend | null,
  severityValue: SeverityCategory | null,
  t: TFunction<["outcomes"]>,
  enumT: TFunction<["enums"]>
) {
  switch (triggerType) {
    case MetricTriggerType.FIRST_SESSION_OF_TREND:
      return t("outcomes:triggers.firstSessionOfTrend", {
        trend: trendValue ? trendT(trendValue, enumT) : "",
      });
    case MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY:
      return t("outcomes:triggers.firstSessionOfImprovedSeverity");
    case MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY:
      return t("outcomes:triggers.firstSessionOfSpecifiedSeverity", {
        severity: severityValue ? severityCategoryT(severityValue, enumT) : "",
      });
    case MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE:
      return t("outcomes:triggers.firstSessionOfTreatmentResponse");
    case MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION:
      return t("outcomes:triggers.firstSessionOfTreatmentRemission");
  }
}

// There is backend validation about the operator but it doesn't express in graphql so for now the number is assumed to be present.
function operatorValueText(
  operator: Operator | null,
  numberValue: number | null,
  t: TFunction<["outcomes"]>
) {
  if (!operator || !numberValue) {
    return t("outcomes:operatorText.unknown");
  }
  switch (operator) {
    case Operator.GREATER_THAN:
      return t("outcomes:operatorText.greaterThan", { value: numberValue });
    case Operator.LESS_THAN:
      return t("outcomes:operatorText.lessThan", { value: numberValue });
    case Operator.EQUAL:
      return t("outcomes:operatorText.equal", { value: numberValue });
  }
}

function dateAccessor(d: ChartDataItem) {
  return d.date.getUTCMonth() + 1 + "/" + d.date.getUTCDate() + "/" + d.date.getUTCFullYear();
}

function TimeBasedMetricShortTitle(props: { config: TimeBasedMetricConfiguration }): ReactElement {
  const { config } = props;
  const { t } = useTranslation(["outcomes"]);

  return (
    <Typography>
      {timeBasedAggregationSummary(config.timeBasedAggregationType)} {scaleSummary(config.scorerConfig)}{" "}
      <SuccessCriteriaSummary successCriteria={config.metricSuccessCriteria} />{" "}
      <InclusionCriteriaSummary inclusionCriteria={config.metricInclusionCriteria} />{" "}
      {timeBasedTimeSummary(config.timePeriodType, config.timePeriodValue, t)}
    </Typography>
  );
}

function TriggerBasedMetricShortTitle(props: {
  configuration: TriggerBasedMetricConfiguration;
}): ReactElement {
  const { configuration } = props;
  const { t } = useTranslation(["outcomes"]);
  return (
    <Typography>
      {triggerBasedAggregationSummary(configuration.triggerBasedAggregationType, t)}{" "}
      <TriggerSummary triggers={configuration.metricTriggers} /> {scaleSummary(configuration.scorerConfig)}{" "}
      <InclusionCriteriaSummary inclusionCriteria={configuration.metricInclusionCriteria} />{" "}
    </Typography>
  );
}

function metricDownloadDisabled(
  metric: HasAggregationType & { scorerConfig: MetricScaleScorerConfigurationWithName }
): boolean {
  return (
    metric.__typename === "TriggerBasedMetric" ||
    metric.scorerConfig.__typename === "MetricMultiScaleScorerConfiguration"
  );
}

function metricParamsDownloadDisabled(metricParams: MetricParams): boolean {
  if (metricParams.payload.triggerBased) {
    return true;
  }

  // We don't currently support multi scale downloads
  if (metricParams.scaleScorerConfig.multiScale) {
    return true;
  }

  return false;
}

export {
  timeBasedMetricLabelGenerator,
  timeBasedMetricYAccessor,
  timeBasedMetricValueFormatter,
  dateAccessor,
  TimeBasedMetricShortTitle,
  TriggerBasedMetricShortTitle,
  triggerBasedMetricYAccessor,
  triggerBasedMetricLabelGenerator,
  triggerBasedMetricValueFormatter,
  metricLabelGenerator,
  metricValueFormatter,
  metricYAccessor,
  timeBasedTimeSummary,
  timeBasedAggregationSummary,
  triggerBasedAggregationSummary,
  triggerText,
  InclusionCriterionText,
  successCriterionText,
  metricLabelGeneratorFromParams,
  metricValueFormatterFromParams,
  metricUnits,
  metricYAccessorFromParams,
  metricDownloadDisabled,
  metricParamsDownloadDisabled,
  metricYMax,
  metricParamsYMax,
};
