import { Box, Button } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  Patient,
  useEditPatientDemographicsMutation,
  usePatientDemographicsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { DemographicsForm, DemographicsInputLoading } from "../DemographicsForm";

type EditDemographicsButtonProps = {
  patient: PickTypename<Patient, "id" | "name">;
  buttonMinWidth?: string;
};

export function EditDemographicsButton(props: EditDemographicsButtonProps): ReactElement {
  const { t } = useTranslation(["patients"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <EditDemographicsDialog open={open} onClose={() => setOpen(false)} patient={props.patient} />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("patients:actions.editDetails")}
      </Button>
      {dialog}
    </Box>
  );
}

type EditDemographicsDialogProps = {
  patient: PickTypename<Patient, "id" | "name">;
  open: boolean;
  onClose: () => void;
};

function EditDemographicsDialog(props: EditDemographicsDialogProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  const { remoteData } = apolloQueryHookWrapper(
    usePatientDemographicsQuery({ variables: { id: props.patient.id } })
  );

  const [editDemographics, { remoteData: mutationRemoteData }] = apolloMutationHookWrapper(
    (response) => response.editPatientDemographics,
    useEditPatientDemographicsMutation({})
  );

  const content = remoteData.caseOf({
    NotAsked: () => <DemographicsInputLoading />,
    Loading: () => <DemographicsInputLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.patient) {
        return (
          <DemographicsForm
            defaults={{
              ...response.patient,
              notificationPreference: response.patient.user.notificationPreference,
            }}
            onSuccess={() => setTimeout(props.onClose, 300)}
            remoteData={mutationRemoteData}
            mutation={(fields) =>
              editDemographics({ variables: { input: { ...fields, patientId: props.patient.id } } })
            }
            errorMessage={t("patients:actions.editError")}
          />
        );
      } else {
        return <ErrorMessage message="Failed to load patient demographics" />;
      }
    },
  });

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("patients:actions.editDetails") + " (" + props.patient.name + ")"}
      stopBackdropClose={true}
    >
      {content}
    </ResponsiveDialog>
  );
}
