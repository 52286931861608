import { Box, Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import {
  CaseConsultStatus,
  ConsultMeeting,
  ConsultMeetingStatus,
  useCaseConsultDetailsQuery,
  useSaveConsultReviewNotesMutation,
  useStartCaseConsultReviewMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { unsafeFromUuid } from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import NotFound from "Shared/NotFound";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CompleteAndDocumentedButton from "./CaseConsultReview/CompleteAndDocumentedButton";
import CompleteAndDocumentLaterButton from "./CaseConsultReview/CompleteAndDocumentLaterButton";
import CancelConsultReviewButton from "./CaseConsultReview/CancelConsultReviewButton";
import { CaseConsultId, ConsultMeetingId } from "Lib/Ids";
import { QuickTextInputList } from "./QuickTextInput";
import { useDebounce, useEffectOnce, useEffectSimpleCompare } from "Lib/Hooks";
import {
  AdditionalPatientInformation,
  Assessments,
  CaseConsultDetails,
  CaseSummary,
  ConsultRelevantDemographics,
  MeasurementBundleList,
  RequestsList,
} from "./CaseConsultReviewInformation";
import { PickTypename } from "type-utils";
import { isTerminal } from "./CaseConsult";
import { refetchQueries } from "Lib/RefetchQueries";
import { LazyCompactTaskCard } from "CollaborativeCare/CareManagerDashboard/BetterTaskList/CompactTaskCard";
import { usePiiLevelIsAtLeast, usePiiLevelIsInsufficient } from "Contexts/PiiLevelContext";
import Link from "MDS/Link";
import { useInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";

export function CaseConsultReview(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const params = useParams<{ meetingId: string; caseConsultId: string }>();
  if (!params.meetingId || !params.caseConsultId) {
    return <NotFound />;
  }
  const meetingId = unsafeFromUuid<"ConsultMeeting">(params.meetingId);
  const caseConsultId = unsafeFromUuid<"CaseConsult">(params.caseConsultId);

  const inEmbeddedPatientOrEncounter = useInEmbeddedPatientOrEncounter();

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultDetailsQuery({ variables: { id: caseConsultId } })
  );

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareCaseConsult) {
        return <NotFound />;
      }

      return <CaseConsultReviewCard consult={response.collaborativeCareCaseConsult} meetingId={meetingId} />;
    },
  });

  const breadcrumbs = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareCaseConsult && !inEmbeddedPatientOrEncounter) {
        return [
          <Link to={`/app/cocm/case-consult`} key="consult-breadcrumb">
            {t("collaborativeCare:caseConsult.consultList.title")}
          </Link>,
          <Link to={`/app/cocm/case-consult/meeting/${meetingId}`} key="meeting-breadcrumb">
            {t("collaborativeCare:caseConsult.consultMeeting.pageTitle")}
          </Link>,
          <Typography key="patient-breadcrumb">
            {response.collaborativeCareCaseConsult.patient.name}
          </Typography>,
        ];
      } else {
        return [];
      }
    },
    _: () => [],
  });

  return (
    <Page
      breadcrumbs={breadcrumbs}
      browserTitle={t("collaborativeCare:caseConsult.caseConsultReview.pageTitle")}
    >
      {content}
    </Page>
  );
}

type CaseConsultReviewCardProps = {
  consult: CaseConsultDetails & { consultMeeting?: PickTypename<ConsultMeeting, "id" | "status"> | null };
  meetingId: ConsultMeetingId;
};

function CaseConsultReviewCard(props: CaseConsultReviewCardProps): ReactElement {
  const [startConsultReview, _] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartCaseConsultReview,
    useStartCaseConsultReviewMutation({
      variables: {
        input: {
          caseConsultId: props.consult.id,
          consultMeetingId: props.meetingId,
        },
      },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const shouldStartTimeTracking =
    // No consult meeting attached, user has probably just clicked Begin Consultation
    (props.consult.consultMeeting === null ||
      // Consult meeting attached and meeting is ongoing, users has probably just clicked View Details to go back to a
      // patient during a meeting. Note that we do _not_ want to automatically track time once a meeting has ended - they
      // might come back to this page, but it will probably be while doing some other task.
      props.consult.consultMeeting?.status === ConsultMeetingStatus.ONGOING) &&
    // The consult is not complete or canceled (the mutation will throw an error anyway if we tried time tracking in
    // this state).
    !isTerminal(props.consult);

  useEffectOnce(() => {
    if (shouldStartTimeTracking) {
      startConsultReview();
    }
  });

  return (
    <Card>
      <PatientReferenceCardHeader patientId={props.consult.patient.id} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <ConsultRelevantDemographics consult={props.consult} />
          <MeasurementBundleList consult={props.consult} />
          <RequestsList consult={props.consult} />
          <CaseSummary consult={props.consult} />
          <AdditionalPatientInformation consult={props.consult} />
          <Assessments consult={props.consult} />
          <Discussion consult={props.consult} meetingId={props.meetingId} />
          <Recommendations consult={props.consult} meetingId={props.meetingId} />
          <Tasks consult={props.consult} meetingId={props.meetingId} />
          <Actions consult={props.consult} meetingId={props.meetingId} />
        </Stack>
      </CardContent>
    </Card>
  );
}

function Discussion(props: CaseConsultReviewCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const piiReadOnly = usePiiLevelIsInsufficient("limited_pii");
  const disabled = piiReadOnly || props.consult.status === CaseConsultStatus.COMPLETE;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { text, setText, statusMessage } = useAutosaveText(
    "discussion",
    props.consult.id,
    props.consult.consultationDocumentation || ""
  );

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.caseConsultReview.discussion.title")}
      </Typography>
      {disabled ? (
        <Box component="section" sx={{ p: 1, border: "1px solid grey", borderRadius: 1 }}>
          <Typography variant="body1">{text} </Typography>
        </Box>
      ) : (
        <>
          <TextField
            multiline
            minRows={5}
            disabled={disabled}
            value={text}
            onChange={(event) => setText(event.target.value)}
            inputRef={inputRef}
          />
          <Typography variant="caption">{statusMessage}</Typography>
          <QuickTextInputList inputRef={inputRef} fullText={text} setText={setText} />
        </>
      )}
    </Stack>
  );
}

// After some feedback from the clincial team for more guidance around the 2 fields
// in a review, we decided to rename "Notes" to "Recommendations"
function Recommendations(props: CaseConsultReviewCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const piiReadOnly = usePiiLevelIsInsufficient("limited_pii");
  const disabled = piiReadOnly || props.consult.status === CaseConsultStatus.COMPLETE;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { text, setText, statusMessage } = useAutosaveText(
    "notes",
    props.consult.id,
    props.consult.consultationNotes || ""
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={3}>
        <Typography variant="h1">
          {t("collaborativeCare:caseConsult.caseConsultReview.recommendations.title")}
        </Typography>
      </Stack>
      {disabled ? (
        <Box component="section" sx={{ p: 1, border: "1px solid grey", borderRadius: 1 }}>
          <Typography variant="body1">{text} </Typography>
        </Box>
      ) : (
        <>
          <TextField
            multiline
            minRows={5}
            disabled={disabled}
            value={text}
            onChange={(event) => setText(event.target.value)}
            inputRef={inputRef}
          />
          <Typography variant="caption">{statusMessage}</Typography>
          <QuickTextInputList inputRef={inputRef} fullText={text} setText={setText} />
        </>
      )}
    </Stack>
  );
}

/**
 * Wraps up the mutation to save case consult text with a debouncing process so it saves automatically after 500
 * milliseconds with no changes.
 *
 * @param type Which field the text should be saved into
 * @param caseConsultId Id of the case consult the text is associated with
 * @param defaultText The default value of the text
 * @returns A struct with three fields:
 *   text: The current value of the text
 *   setText: A handler to update the text to a new value
 *   statusMessage: A human-readable string describing what state the autosave is in
 */
function useAutosaveText(type: "notes" | "discussion", caseConsultId: CaseConsultId, defaultText: string) {
  const { t } = useTranslation(["collaborativeCare"]);
  const [text, setText] = React.useState(defaultText);
  const [isDebouncing, setIsDebouncing] = React.useState(false);
  const debouncedText = useDebounce(text, 500);
  const allowSaveForPii = usePiiLevelIsAtLeast("limited_pii");

  const [saveText, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSaveConsultReviewNotes,
    useSaveConsultReviewNotesMutation({
      variables: {
        input: {
          caseConsultId: caseConsultId,
          text: debouncedText,
          type: type === "notes" ? { notes: true } : { discussion: true },
        },
      },
    })
  );

  const wrappedSetText = (text: string) => {
    setText(text);
    setIsDebouncing(true);
  };

  // Wait to save until after the debounced value has settled so that we aren't spamming requests on every keystroke.
  useEffectSimpleCompare(() => {
    // Never attempt to save text unless the piiLevel is a user editing mode.
    if (allowSaveForPii) {
      saveText();
    }
    setIsDebouncing(false);
  }, [debouncedText]);

  const statusMessage = remoteData.caseOf({
    // For reasons I don't fully understand, the request never seems to actually be in this state. I'm not sure if this
    // is an Apollo quirk I don't understand, but if I refresh the page and don't touch anything, it ends up in Success,
    // rather than NotAsked. Including the text here for completeness anyway.
    NotAsked: () => t("collaborativeCare:caseConsult.caseConsultReview.autosave.noChanges"),
    Loading: () => t("collaborativeCare:caseConsult.caseConsultReview.autosave.saving"),
    Success: () =>
      isDebouncing
        ? t("collaborativeCare:caseConsult.caseConsultReview.autosave.waitingToSave")
        : t("collaborativeCare:caseConsult.caseConsultReview.autosave.saved"),
    Failure: () => t("collaborativeCare:caseConsult.caseConsultReview.autosave.error"),
  });

  return {
    text: text,
    setText: wrappedSetText,
    statusMessage: statusMessage,
  };
}

function Tasks(props: CaseConsultReviewCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const summaryCard = props.consult.caseSummaryTask ? (
    <LazyCompactTaskCard taskId={props.consult.caseSummaryTask.id} inPatientContext={true} onPaper />
  ) : null;
  const meetingCard = props.consult.consultMeetingTask ? (
    <LazyCompactTaskCard taskId={props.consult.consultMeetingTask.id} inPatientContext={true} onPaper />
  ) : null;
  const documentationCard = props.consult.documentationTask ? (
    <LazyCompactTaskCard taskId={props.consult.documentationTask.id} inPatientContext={true} onPaper />
  ) : null;

  // In principle this should never be visible - as soon as you open the consult view it creates the consult meeting
  // task, so anyone looking at this will have at least that task in the list. We can't force that in the API though,
  // so we might as well have a fallback in case we eventually screw up a refetch or something.
  const noTasksMessage =
    summaryCard === null && meetingCard === null && documentationCard === null ? (
      <Typography variant="caption">
        {t("collaborativeCare:caseConsult.caseConsultReview.tasks.noTasks")}
      </Typography>
    ) : null;

  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="h1">{t("collaborativeCare:caseConsult.caseConsultReview.tasks.title")}</Typography>
      {summaryCard}
      {meetingCard}
      {documentationCard}
      {noTasksMessage}
    </Stack>
  );
}

function Actions(props: CaseConsultReviewCardProps): ReactElement {
  return (
    // The extra padding here is to push the actions away from the content so they're visually separate without a header
    <Stack direction="row-reverse" spacing={1} paddingTop="4rem">
      <CompleteAndDocumentedButton consult={props.consult} meetingId={props.meetingId} />
      <CompleteAndDocumentLaterButton consult={props.consult} meetingId={props.meetingId} />
      <CancelConsultReviewButton consult={props.consult} patientId={props.consult.patient.id} />
    </Stack>
  );
}
