import { Button, Menu, MenuItem, Link as MUILink } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CareEpisodeId, PatientId } from "Lib/Ids";
import {
  CareEpisodeSortParameter,
  CareEpisodeStatus,
  CreateAppointmentResult,
  SortDirection,
  useCareEpisodeListQuery,
  useCreateAppointmentMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentForm } from "./AppointmentForm";
import { roundToNearestMinutes } from "date-fns";
import { ArrowDropDown } from "@mui/icons-material";
import { useCurrentProviderId } from "AppSession/AppSession";

export function CreateAppointmentButton(props: {
  careEpisodeId?: CareEpisodeId;
  patientId: PatientId;
}): ReactElement {
  const { t } = useTranslation(["patients", "common"]);

  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const [careEpisodeId, setCareEpisodeId] = React.useState<CareEpisodeId | null>(null);
  const dropdownOpen = anchor != null;
  const handleDropdownOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleDropdownClose = () => {
    setAnchor(null);
  };
  const { remoteData } = apolloQueryHookWrapper(
    useCareEpisodeListQuery({
      variables: {
        patientId: props.patientId,
        status: [CareEpisodeStatus.ACTIVE],
        sortBy: CareEpisodeSortParameter.PERIODSTART,
        sortDirection: SortDirection.DESC,
        after: null,
        before: null,
        first: null,
        last: null,
      },
    })
  );

  const dialog = careEpisodeId ? (
    <CreateAppointmentDialog
      {...props}
      careEpisodeId={careEpisodeId}
      open={true}
      onClose={() => setCareEpisodeId(null)}
    />
  ) : null;

  const menuOptions = remoteData.caseOf({
    Success: (data) => {
      if (data.careEpisodes) {
        return data.careEpisodes.nodes.map((careEpisode) => {
          return (
            <MenuItem
              key={careEpisode.id.toString()}
              onClick={() => {
                setCareEpisodeId(careEpisode.id);
                handleDropdownClose();
              }}
            >
              <MUILink underline="none">
                {t("patients:patientDetails.actions.createAppointmentFor", { name: careEpisode.shortname })}
              </MUILink>
            </MenuItem>
          );
        });
      }
      return [];
    },
    _: () => [<MenuItem key="loading'">{t("common:remoteData.loading")}</MenuItem>],
  });

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleDropdownOpen}>
        {t("patients:patientDetails.actions.createAppointment")}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Menu open={dropdownOpen} onClose={handleDropdownClose} anchorEl={anchor}>
        {menuOptions}
        <MenuItem key="create">
          <MUILink underline="none" href={`${props.patientId}/care-episodes/create`}>
            {t("patients:patientDetails.actions.createEpisodeOfCare")}
          </MUILink>
        </MenuItem>
      </Menu>
      {dialog}
    </>
  );
}

type CreateAppointmentDialogProps = {
  open: boolean;
  onClose: () => void;
  careEpisodeId: CareEpisodeId;
  patientId: PatientId;
};

function CreateAppointmentDialog(props: CreateAppointmentDialogProps): ReactElement {
  const { t } = useTranslation(["patients"]);
  const navigate = useNavigate();
  const providerId = useCurrentProviderId();

  const startDate = roundToNearestMinutes(new Date(), { nearestTo: 15, roundingMethod: "ceil" });

  const defaults = {
    startDate,
    providerId,
    minutesDuration: 30,
  };

  const [createAppointment, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.schedulingCreateAppointment,
    useCreateAppointmentMutation({})
  );

  const onSuccess = (response: CreateAppointmentResult) => {
    // Use the relative url to navigate directly to the patient.
    // We may find ourselves in either the patient or the care episode page. Either way we have a relative route available.
    navigate(`appointments/${response.appointment.id}`);
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("patients:appointments.createDialog.title")}
      stopBackdropClose={false}
      dialogWidth="50%"
    >
      <AppointmentForm
        careEpisodeId={props.careEpisodeId}
        patientId={props.patientId}
        mode="create"
        defaults={defaults}
        onSuccess={onSuccess}
        remoteData={remoteData}
        mutation={(fields) =>
          createAppointment({ variables: { input: { careEpisodeId: props.careEpisodeId, ...fields } } })
        }
        errorMessage={t("patients:actions.createError")}
      />
    </ResponsiveDialog>
  );
}
