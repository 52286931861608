import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Tab,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import { useInspectorNodeQuery, InspectorNodeQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { Dig, DigUnpacked } from "type-utils";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { PropertyTable } from "MDS/PropertyTable";
import { Link } from "MDS/Link";

type InspectorProps = {
  className: string;
  // We do terrible things to ids coming into this and we're not undoing all of that.
  // It can also be like, any id, even for things we don't have a type of yet.
  id: string;
};

function InspectorTabHookWrapper(props: InspectorProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useInspectorNodeQuery({
      variables: {
        id: props.id,
        className: props.className,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => <>Loading</>,
    NotAsked: () => <>Loading</>,
    Failure: () => <Error />,
    Success: (result) => {
      if (result.inspectorNode) {
        return <InspectorTabElement node={result.inspectorNode} />;
      }
      return <>Error</>;
    },
  });
}

function Error(): ReactElement {
  return (
    <Box>
      <h3>Error</h3>
      Please report this specific url to engineering and we'll take a look at it.
    </Box>
  );
}

// The default tab label text is sometimes smaller than the headings we put inside it, which feels visually bad.
const BiggerTab = styled(Tab)(({ theme }) => ({
  fontSize: "1.75rem",
  padding: [theme.spacing(0.25), theme.spacing(1)],
}));

// We're sticking cards inside the tab panels which have their own padding, we don't need to also add padding around
// those cards and just waste the space.
const FlushTabPanel = styled(TabPanel)(() => ({
  padding: 0,
}));

type InspectorTabElementProps = {
  node: Dig<InspectorNodeQuery, ["inspectorNode"]>;
};

// This handles the overarching display for this entire node.
function InspectorTabElement(props: InspectorTabElementProps): ReactElement {
  const [tabValue, setTabValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  return (
    <>
      <TabContext value={tabValue}>
        <Box sx={{ borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <BiggerTab label="Model" value="1" />
            <BiggerTab label="Versions" value="2" />
            <BiggerTab label="Associations" value="3" />
          </TabList>
        </Box>
        <FlushTabPanel value="1">
          <InspectorTabModelElement {...props} />
        </FlushTabPanel>
        <FlushTabPanel value="2">
          <InspectorTabVersionsElement {...props} />
        </FlushTabPanel>
        <FlushTabPanel value="3" sx={{ padding: 0 }}>
          <InspectorTabAssociationsElement {...props} />
        </FlushTabPanel>
      </TabContext>
    </>
  );
}

// This handles the display of the actual model we're looking at.
function InspectorTabModelElement(props: InspectorTabElementProps): ReactElement {
  const modelFieldsRows = props.node.model.fields.map((f) => {
    return (
      <TableRow key={f.name}>
        <TableCell>
          <RightAlignedH4>{f.name}</RightAlignedH4>
        </TableCell>
        <TableCell>{f.value}</TableCell>
      </TableRow>
    );
  });
  return (
    <Card>
      <CardHeader title="Model" />
      <CardContent>
        <PropertyTable>
          <TableHead>
            <TableRow>
              <TableCell>
                <RightAlignedH4>Field</RightAlignedH4>
              </TableCell>
              <TableCell>
                <StyledH4>Value</StyledH4>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{modelFieldsRows}</TableBody>
        </PropertyTable>
      </CardContent>
    </Card>
  );
}

// This handles the display of all of the varied versions for a model.
function InspectorTabVersionsElement(props: InspectorTabElementProps): ReactElement {
  // Let's dump out quick if we have no versions.
  const hasVersions = props.node.versions.length > 0;
  if (!hasVersions) {
    return (
      <Box>
        <h3>This model has no previous versions</h3>
        This might be because the model actually has no previous versions, but it could be because the class
        itself does not have versioning enabled.
      </Box>
    );
  }

  // We want to set up a select box so folks can pick a single previous version
  // to look at. Since we know we have at least one version, let's just pick that
  // to start. We'll hide versions if their index is not equal to to the selected version.
  const [selectedVersion, setSelectedVersion] = React.useState("0");
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedVersion(event.target.value as string);
  };

  // The ordering from the server is chronological, but we want reverse chronological.
  const orderedVersions = [...props.node.versions].reverse();

  const versionMenuItems = orderedVersions.map((v, i) => {
    const versionLabel = `createdAt: ${v.createdAt}`;
    const eventLabel = `event: ${v.event}`;
    const whodunnitLabel = `whodunnit: ${v.whodunnit}`;
    return (
      <MenuItem key={i} value={i}>
        <Chip label={versionLabel} />
        <Chip label={eventLabel} />
        <Chip label={whodunnitLabel} />
      </MenuItem>
    );
  });

  const versionElements = orderedVersions.map((v, i) => {
    return <InspectorTabVersionElement version={v} index={i.toString()} selectedIndex={selectedVersion} />;
  });

  return (
    <>
      <FormControl fullWidth>
        <Select
          labelId="version-simple-select-label"
          id="version-simple-select"
          value={selectedVersion}
          label="Version"
          onChange={handleChange}
          variant="standard"
        >
          {versionMenuItems}
        </Select>
      </FormControl>
      {versionElements}
    </>
  );
}

type InspectorTabVersionElementProps = {
  version: DigUnpacked<InspectorNodeQuery, ["inspectorNode", "versions"]>;
  index: string;
  selectedIndex: string;
};

const RightAlignedH4 = styled("h4")(() => {
  return { textAlign: "right", padding: 0, margin: 0 };
});

const StyledH4 = styled("h4")(() => {
  return { padding: 0, margin: 0 };
});

// This handles the display of a single version for a model.
function InspectorTabVersionElement(props: InspectorTabVersionElementProps): ReactElement | null {
  // This just wires up us hiding everything that is not selected.
  if (props.index != props.selectedIndex) {
    return null;
  }
  const versionFieldRows = props.version.fields.map((f) => {
    return (
      <TableRow key={f.name}>
        <TableCell>
          <RightAlignedH4>{f.name}</RightAlignedH4>
        </TableCell>
        <TableCell>{f.oldValue}</TableCell>
        <TableCell>{f.newValue}</TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <Card sx={{ marginTop: "1em", marginBottom: "1em" }}>
        <CardHeader title="Version Metadata"></CardHeader>
        <CardContent>
          <PropertyTable>
            <TableBody>
              <TableRow>
                <TableCell>
                  <RightAlignedH4>createdAt</RightAlignedH4>
                </TableCell>
                <TableCell>{props.version.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <RightAlignedH4>event</RightAlignedH4>
                </TableCell>
                <TableCell>{props.version.event}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <RightAlignedH4>whodunnit</RightAlignedH4>
                </TableCell>
                <TableCell>{props.version.whodunnit ? props.version.whodunnit : "null"}</TableCell>
              </TableRow>
            </TableBody>
          </PropertyTable>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Version" />
        <CardContent>
          <PropertyTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <RightAlignedH4>Field</RightAlignedH4>
                </TableCell>
                <TableCell>
                  <h4>Old Value</h4>
                </TableCell>
                <TableCell>
                  <h4>New Value</h4>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{versionFieldRows}</TableBody>
          </PropertyTable>
        </CardContent>
      </Card>
    </>
  );
}

// This handles the display of all associations for this model.
function InspectorTabAssociationsElement(props: InspectorTabElementProps): ReactElement {
  // Let's dump out quick if we have no associaitons.
  const hasVersions = props.node.associations.length > 0;
  if (!hasVersions) {
    return (
      <Box>
        <h3>This model has no associations</h3>
        This might be because it actually has no associations, but if you were linked here from another model,
        it's probably because associations have been turned off for this class.
      </Box>
    );
  }

  const associationElements = props.node.associations.map((a) => {
    return <InspectorTabAssociationElement key={a.className} association={a} />;
  });
  return <>{associationElements}</>;
}

type InspectorTabAssociationElementProps = {
  association: DigUnpacked<InspectorNodeQuery, ["inspectorNode", "associations"]>;
};

// This handles the display for a single association for this model.
function InspectorTabAssociationElement(props: InspectorTabAssociationElementProps): ReactElement {
  const associationRows = props.association.edges.map((e) => {
    const link = `/app/ops/inspector/class/${props.association.className}/id/${e.id}`;
    return (
      <TableRow key={e.id}>
        <TableCell>
          <Link to={link} underline="always">
            <EdgeSummary edge={e} />
          </Link>
        </TableCell>
      </TableRow>
    );
  });
  if (associationRows.length == 0) {
    associationRows.push(
      <Box>
        <h4>This model has no associations of this type</h4>
        It's possible for there to be linkage here, but this model does not have any.
      </Box>
    );
  }
  return (
    <Card sx={{ marginBottom: "1em" }}>
      <CardHeader title={`Associations for ${props.association.className}`} />
      <CardContent>
        <PropertyTable>
          <TableBody>{associationRows}</TableBody>
        </PropertyTable>
      </CardContent>
    </Card>
  );
}

type AssociationEdge = {
  id: string;
  summary: ReadonlyArray<{ name: string; value: string | null }>;
};

const SummaryContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.report.shading.focus,
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(0.5),
  width: "fit-content",
}));

function EdgeSummary(props: { edge: AssociationEdge }): ReactElement {
  const text = props.edge.summary.map((field) => (
    <Stack direction="row" spacing={0.25}>
      <Typography fontWeight="bold">{field.name}:</Typography>
      <Typography>{field.value}</Typography>
    </Stack>
  ));
  return (
    <SummaryContainer direction="row" flexWrap="wrap" useFlexGap={true} spacing={1}>
      {text}
    </SummaryContainer>
  );
}

export default InspectorTabHookWrapper;
