import { ParticipantByUserIdMap } from "FeedbackReport/FeedbackReportContext";
import * as NEL from "Lib/NonEmptyList";
import { groupBy } from "Lib/Utils";
import { Maybe } from "seidr";
import { ParticipantSummary } from "Shared/Participant";
import SumType from "sums-up";
import { AnswerBase, AnswerDetail, AnswerSummary, SkippedAnswerDetail, SkippedAnswerSummary } from "./Answer";
import {
  AnswerDetailWithUserFragmentFragment,
  AnswerSummaryWithoutUserFragmentFragment,
  QuestionType,
} from "GeneratedGraphQL/SchemaAndOperations";
import { DigOptional } from "type-utils";
import { QuestionId } from "Lib/Ids";

export type Question = {
  id: QuestionId;
  title: string;
  reportText: Maybe<string>;
  type: QuestionType;
};

export type QuestionOption = {
  title: string;
  value: string;
  reportText: Maybe<string>;
};

// A Response is a wrapper around answers to questions:
//  * Questions without answers are `NotAsked`
//  * Questions with a non-value answer are `Skipped`
//  * Questions with a full answer are `Answered` of that answer
export class Response<A extends AnswerSummary, S extends AnswerBase> extends SumType<{
  NotAsked: [S];
  // Technically there is answer data associated with Skipped. For instance how
  // long they looked at the question without answering and skipping. This
  // level of data is not yet needed.
  Skipped: [S];
  Answered: [A];
}> {
  public static NotAsked = <S extends AnswerBase>(notAnswered: S) => new Response("NotAsked", notAnswered);
  public static Skipped = <S extends AnswerBase>(skipped: S) => new Response("Skipped", skipped);
  public static Answered = <A extends AnswerSummary>(answer: A) => new Response("Answered", answer);
}

export type ResponseSummary = Response<AnswerSummary, SkippedAnswerSummary>;

export type ResponseDetail = Response<AnswerDetail, SkippedAnswerDetail>;

export type QuestionResponse<A extends AnswerSummary, S extends AnswerBase> = {
  question: Question;
  response: Response<A, S>;
};

export type QuestionResponseSummary = QuestionResponse<AnswerSummary, SkippedAnswerSummary>;

export type QuestionResponseDetail = QuestionResponse<AnswerDetail, SkippedAnswerDetail>;

// -- Helpers -----------------------------------------------------------------

function responseParticipant(response: ResponseDetail) {
  return response.caseOf({
    NotAsked: (d) => d.participant,
    Skipped: (d) => d.participant,
    Answered: (d) => d.participant,
  });
}

function questionText(question: Pick<Question, "reportText" | "title">): string {
  return question.reportText.getOrElse(question.title);
}

// -- Transformers ------------------------------------------------------------

function toQuestion(raw: {
  id: QuestionId;
  title: string;
  reportText: string;
  questionType: QuestionType;
}): Question {
  return {
    id: raw.id,
    title: raw.title,
    reportText: Maybe.fromNullable(raw.reportText),
    type: raw.questionType,
  };
}

function toQuestionOption(
  raw: DigOptional<AnswerSummaryWithoutUserFragmentFragment, ["option"]>
): Maybe<QuestionOption> {
  return Maybe.fromNullable(raw).map((rawOption) => {
    return {
      title: rawOption.title,
      value: rawOption.value,
      reportText: Maybe.fromNullable(rawOption.reportText),
    };
  });
}

function toResponseSummary(raw: AnswerSummaryWithoutUserFragmentFragment): ResponseSummary {
  return Maybe.fromNullable(raw.value).caseOf({
    Just: (value) =>
      Response.Answered({
        id: raw.id,
        normalizedScore: Maybe.fromNullable(raw.normalizedScore),
        value: value,
        option: toQuestionOption(raw.option),
        questionId: raw.questionId,
        itemCoding: Maybe.fromNullable(raw.itemCoding),
        endorsed: Maybe.fromNullable(raw.endorsed),
      }),
    Nothing: () => Response.Skipped({ id: raw.id }),
  });
}

function toResponseDetail(
  raw: AnswerDetailWithUserFragmentFragment,
  participantsByUserId: ParticipantByUserIdMap
): ResponseDetail {
  const participant = participantsByUserId[raw.user.id.toString()];
  if (!participant) {
    throw new Error("Could not find participant");
  }
  return Maybe.fromNullable(raw.value).caseOf({
    Just: (value) =>
      Response.Answered({
        id: raw.id,
        normalizedScore: Maybe.fromNullable(raw.normalizedScore),
        value: value,
        option: toQuestionOption(raw.option),
        questionId: raw.questionId,
        itemCoding: Maybe.fromNullable(raw.itemCoding),
        endorsed: Maybe.fromNullable(raw.endorsed),
        participant: participant,
      }),
    Nothing: () =>
      Response.Skipped({
        id: raw.id,
        participant: participant,
      }),
  });
}

function toQuestionResponseSummary(
  raw: Omit<AnswerDetailWithUserFragmentFragment, "user">
): QuestionResponseSummary {
  return Maybe.fromNullable(raw.value).caseOf({
    Just(value) {
      return {
        question: toQuestion(raw.question),
        response: Response.Answered({
          id: raw.id,
          normalizedScore: Maybe.fromNullable(raw.normalizedScore),
          value: value,
          option: toQuestionOption(raw.option),
          questionId: raw.questionId,
          itemCoding: Maybe.fromNullable(raw.itemCoding),
          endorsed: Maybe.fromNullable(raw.endorsed),
        }),
      };
    },
    Nothing() {
      return {
        question: toQuestion(raw.question),
        response: Response.Skipped({
          id: raw.id,
        }),
      };
    },
  });
}

function toQuestionResponseDetail(
  raw: AnswerDetailWithUserFragmentFragment,
  participantsByUserId: ParticipantByUserIdMap
): QuestionResponseDetail {
  const participant = participantsByUserId[raw.user.id.toString()];
  if (!participant) {
    throw new Error("Could not find participant");
  }

  return Maybe.fromNullable(raw.value).caseOf({
    Just(value) {
      return {
        question: toQuestion(raw.question),
        response: Response.Answered({
          id: raw.id,
          normalizedScore: Maybe.fromNullable(raw.normalizedScore),
          value: value,
          option: toQuestionOption(raw.option),
          questionId: raw.questionId,
          itemCoding: Maybe.fromNullable(raw.itemCoding),
          endorsed: Maybe.fromNullable(raw.endorsed),
          participant: participant,
        }),
      };
    },
    Nothing() {
      return {
        question: toQuestion(raw.question),
        response: Response.Skipped({
          id: raw.id,
          participant: participant,
        }),
      };
    },
  });
}

export type QuestionResponseWithParticipant = {
  participant: ParticipantSummary;
  responses: NEL.NonEmptyList<QuestionResponseDetail>;
};

function responsesByParticipant(
  responses: ReadonlyArray<QuestionResponseDetail>
): ReadonlyArray<QuestionResponseWithParticipant> {
  const responsesByParticipantId = groupBy(
    (r) => responseParticipant(r.response).user.id.toString(),
    responses
  );

  return Object.values(responsesByParticipantId).flatMap((resp) => {
    return NEL.fromArray(resp || []).caseOf({
      Just: (groupedResponses) => {
        const participant = responseParticipant(NEL.head(groupedResponses).response);

        return [
          {
            participant: participant,
            responses: groupedResponses,
          },
        ];
      },
      Nothing: () => [],
    });
  });
}

export {
  questionText,
  QuestionType,
  toResponseSummary,
  toQuestionResponseSummary,
  toResponseDetail,
  toQuestionResponseDetail,
  responsesByParticipant,
};
export default QuestionResponse;
