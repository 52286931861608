import { Scale } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { scaleMediumName } from "./Scale";

function ScaleShortDescription({
  scale,
}: {
  scale: Pick<Scale, "name" | "shortname" | "nanoname" | "reportShortDescription">;
}): ReactElement | null {
  return scale.reportShortDescription ? (
    <p>
      <strong>{scaleMediumName(scale)}</strong>
      {` ${scale.reportShortDescription}.`}
    </p>
  ) : null;
}

export default ScaleShortDescription;
