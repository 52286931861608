import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { BillableUnitExplanation, billableUnitExplanationText } from "Billing/BillingRuleHelpers";
import { DownloadEnrollmentMonthSummaryPdfButton } from "CollaborativeCare/DownloadEnrollmentMonthSummaryButton";
import { PatientBillableMinutesWithTargetBadgeElement } from "CollaborativeCare/PatientBillableMinutesBadge";
import {
  Enrollment,
  EnrollmentMonthSortParameter,
  EnrollmentMonthsTableQuery,
  EnrollmentMonthsTableQueryVariables,
  SortDirection,
  useEnrollmentMonthsTableQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { PatientDetailsDataGridContainer } from "./PatientDetailsDataGridContainer";

type EnrollmentMonthsCardProps = {
  enrollment: Pick<Enrollment, "id"> | null;
};

const defaultSortParams = {
  sortBy: EnrollmentMonthSortParameter.MONTH,
  sortDirection: SortDirection.ASC,
};

export default function EnrollmentMonthsCard(props: EnrollmentMonthsCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const content = props.enrollment ? (
    <EnrollmentMonthsCardInner enrollment={props.enrollment} />
  ) : (
    <Typography>{t("collaborativeCare:patientDetails.enrollmentMonths.noEnrollment")}</Typography>
  );

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:patientDetails.enrollmentMonths.title")} />
      <CardContent>{content}</CardContent>
    </Card>
  );
}

function EnrollmentMonthsCardInner(props: { enrollment: Pick<Enrollment, "id"> }) {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const queryVars: Pick<EnrollmentMonthsTableQueryVariables, "enrollmentId"> = {
    enrollmentId: props.enrollment.id,
  };

  const columns: DataGridCols<EnrollmentMonthsTableQuery, ["collaborativeCareEnrollmentMonths"]> =
    React.useMemo(() => {
      const allColumns: DataGridCols<EnrollmentMonthsTableQuery, ["collaborativeCareEnrollmentMonths"]> = [
        {
          field: "beginningOfMonth",
          flex: 1,
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.beginningOfMonth"),
          sortable: true,
          valueGetter: (_value, row) => t("common:date.monthYear", { date: row.beginningOfMonth }),
        },
        {
          field: "month",
          flex: 1,
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.month"),
          sortable: true,
          valueGetter: (_value, row) => row.month,
        },
        {
          field: "billableMinutes",
          minWidth: 100,
          flex: 1,
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.minutes"),
          sortable: true,
          valueGetter: (_value, row) => row.billableMinutes,
          renderCell: (params) => {
            return (
              <PatientBillableMinutesWithTargetBadgeElement
                billableMinutes={params.row.billableMinutes}
                minutesTargetCeiling={params.row.minutesTargetCeiling}
                minutesTargetFloor={params.row.minutesTargetFloor}
                minutesTargetStatus={params.row.minutesTargetStatus}
              />
            );
          },
        },
        {
          field: "winningResultBilledUnits",
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.billing"),
          sortable: false,
          flex: 1,
          minWidth: 180,
          valueGetter: (_value, row) =>
            billableUnitExplanationText(
              row.billingEnrollmentMonthBillingRuleResultWinner?.winningResult?.rule.ruleCodes
            ),
          renderCell: (params) => {
            const rule = params.row.billingEnrollmentMonthBillingRuleResultWinner?.winningResult?.rule;
            if (rule) {
              return <BillableUnitExplanation rule={rule} />;
            } else {
              return "-";
            }
          },
        },
        {
          field: "validatedMeasureCompleted",
          flex: 1,
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.validatedMeasureCompleted"),
          sortable: false,
          valueGetter: (_value, row) => row.validatedMeasureCompleted,
          renderCell: (params) => {
            return params.row.validatedMeasureCompleted ? <CheckIcon /> : <CancelIcon />;
          },
        },
        {
          field: "download",
          disableExport: true,
          flex: 1,
          headerName: t("collaborativeCare:patientDetails.enrollmentMonths.pdf"),
          sortable: false,
          renderCell: (params) => {
            return (
              <DownloadEnrollmentMonthSummaryPdfButton enrollmentMonthId={params.row.id} disabled={false} />
            );
          },
        },
      ];

      return allColumns;
    }, []);

  function sortFieldToParameter(parameter: string | null): EnrollmentMonthSortParameter | null {
    switch (parameter) {
      case "month":
        return EnrollmentMonthSortParameter.MONTH;
      default:
        return EnrollmentMonthSortParameter.MONTH;
    }
  }

  return (
    <PatientDetailsDataGridContainer>
      <SortablePagableCollectionDataGrid
        columns={columns}
        queryHook={useEnrollmentMonthsTableQuery}
        queryVariables={queryVars}
        unwrapData={(response) => response?.collaborativeCareEnrollmentMonths || null}
        colNameToSortParam={sortFieldToParameter}
        defaultSortParams={defaultSortParams}
        autoHeight
      />
    </PatientDetailsDataGridContainer>
  );
}
