import { Button, Skeleton, Typography } from "@mui/material";
import { PatientId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { TasksCardListFilters } from "./TasksCard";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  SortDirection,
  Task,
  TaskSortParameter,
  useTasksCardQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { TaskDetails } from "CollaborativeCare/Tasks/TaskCard/TaskCard";

function CopyMonthlySummaryButtonSkeleton(): ReactElement {
  return <Skeleton variant="rectangular" width="10em" height="3em" />;
}

function CopyMonthlySummaryButtonError(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return <Typography>{t("collaborativeCare:patientDetails.tasks.failure")}</Typography>;
}

type CopyMonthlySummaryButtonProps = {
  patientId: PatientId;
  filters: TasksCardListFilters;
};

export function CopyMonthlySummaryButton(props: CopyMonthlySummaryButtonProps): ReactElement {
  // We want to get both active and inactive tasks for the copy behavior, so we actually need
  // to go and do a separate query for that.
  const { remoteData } = apolloQueryHookWrapper(
    useTasksCardQuery({
      variables: {
        patientId: props.patientId,
        sortBy: TaskSortParameter.DUE_AT,
        sortDirection: SortDirection.DESC,
        status: null, // We specifically are NOT going to pick a status for this, because we want both active and complete tasks.
        year: props.filters.date.getFullYear(),
        month: props.filters.date.getMonth() + 1, // JS vs Ruby
        enrollmentId: null,
      },
    })
  );
  return remoteData.caseOf({
    NotAsked: () => {
      return <CopyMonthlySummaryButtonSkeleton />;
    },
    Loading: () => {
      return <CopyMonthlySummaryButtonSkeleton />;
    },
    Failure: () => {
      return <CopyMonthlySummaryButtonError />;
    },
    Success: (result) => {
      if (!result.collaborativeCareTasks) {
        return <CopyMonthlySummaryButtonError />;
      }
      return (
        <CopyMonthlySummaryButtonElement
          tasks={result.collaborativeCareTasks.nodes}
          filters={props.filters}
        />
      );
    },
  });
}

type CopyMonthlySummaryButtonElementProps = {
  tasks: ReadonlyArray<TaskDetails & Pick<Task, "totalMinutesForMonth" | "createdAt">>;
  filters: TasksCardListFilters;
};
function CopyMonthlySummaryButtonElement(props: CopyMonthlySummaryButtonElementProps): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const [showCopied, setShowCopied] = useState<boolean>(false);

  if (props.tasks.length == 0) {
    return null;
  }

  const date = t("common:date.monthYear", {
    date: props.filters.date,
  });

  const patient = props.tasks[0]?.patient;
  let summaryText = `${patient?.name} - ${date}\n`;

  // We want to display the tasks in creation order.
  const sortedTasks = [...props.tasks].sort((a, b) => {
    return a.createdAt > b.createdAt ? 1 : -1;
  });

  sortedTasks.forEach((task) => {
    // Skip any task with less than 1 minute.
    if (task.totalMinutesForMonth < 1) {
      return;
    }

    const taskDate = t("common:date.tinyWithTime", {
      date: task.createdAt,
    });

    summaryText = summaryText.concat(
      `${task.title} - Created: ${taskDate} - ${task.assignedTo.name} - ${task.totalMinutesForMonth} Minutes \n`
    );
  });

  const onCopyClick = async () => {
    navigator.clipboard.writeText(summaryText);

    // We're going to briefly display the Copied! text and then swap back.
    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 2250);
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={onCopyClick}
      sx={{ width: "10em", minHeight: "3em", flexShrink: 0 }}
    >
      {showCopied
        ? t("collaborativeCare:patientDetails.tasks.actions.copyMonthlyTasksSummaryCopied")
        : t("collaborativeCare:patientDetails.tasks.actions.copyMonthlyTasksSummary")}
    </Button>
  );
}
