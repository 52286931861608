const common = {
  patient_one: "patient",
  patient_other: "patients",

  date: {
    long: "{{ date; date(format:EEEE, MMMM d, yyyy h:mm aa) }}",
    medium: "{{ date; date(format:MMM dd, yyyy) }}",
    short: "{{ date; date(format:MMM dd) }}",
    shortTime: "{{ date; date(format:h:mm aa, MMM dd) }}",
    tiny: "{{ date; date(format:MM/dd/yyyy) }}",
    tinyWithTime: "{{ date; date(format:MM/dd/yyyy h:mm aa) }}",
    time: "{{ date; date(format:h:mm aa) }}",
    dayMonth: "{{ date; date(format:EEEE, MMMM do) }}",
    monthYear: "{{ date; date(format:MMM ''yy) }}", // 2 single quotes is one quote.
    durationToNow: "{{ date; distanceToNow }}",
    ago: "ago",
  },

  all: "All",

  dateRange: {
    startDate: "Start Date",
    endDate: "End Date",
  },

  unit: {
    minute: {
      short: "min",
    },
  },

  language: {
    en: "English",
    es: "Spanish",
  },

  gender: {
    short: {
      female: "F",
      male: "M",
      nonbinary: "NB",
      unknown: "UK",
    },
  },

  race: {
    title: "Race",
    unknown: "Unknown",
  },

  saving: "Saving",
  pause: "Pause",
  resume: "Resume",
  delete: "Delete",

  missingRequiredField: "Please enter a value",

  invalidNumberValue: "Please enter a number",
  noUnsavedCanges: "No Unsaved Changes",
  failedToSave: "The system encountered an error when saving your changes.",

  entities: {
    institute: "Instance",
    organization: "Organization",
    careEpisode_one: "Care Episode",
    careEpisode_other: "Care Episodes",
    title: "Entities",
    selectTitle: "Entity",
    top: "Entire Organization",
    noMatching: "No Matching Entities",
  },

  entityTreeNode: {
    selectTitle: "Entities",
    selectPlaceholder: "Search for orgs, providers, etc",
    selectedTypeHeader: "{{name}} - unselect to change",
  },

  actions: {
    edit: "Edit",
    save: "Save",
    search: "Search",
    cancel: "Cancel",
    delete: "Delete",
    deletion: {
      confirmationMessage: "Are you sure you want to delete?",
    },
    permanentWarning: "This action cannot be undone",
    add: "Add",
    remove: "Remove",
    back: "Back",
    close: "Close",
    confirm: "Confirm",

    discardChanges: "Discard Changes",
    sort: "Sort By",
  },

  features: {
    patientEnablementButton: "Patient Enablement Button",
  },

  organizations: {
    title: "Units",
    all: "All Units",
    mine: "My Units",
    noMatching: "No Matching Units",
    selector: "Select a unit",
  },

  treatmentServices: {
    selector: "Select a treatment service",
  },

  patients: {
    title: "Patients",
    all: "All Patients",
    mine: "My Patients",
    noMatching: "No Matching Patients",
  },

  providers: {
    title: "Providers",
    all: "All Providers",
    me: "Me",
    noMatching: "No Matching Providers",
    create: "New Provider",
  },

  users: {
    title: "User",
    noneMatching: "No matching users",
  },

  flags: {
    title: "Flags",
    noMatching: "No Matching Flags",
  },

  testClient: "Test Clients",

  scales: {
    title: "Measures",
    noMatching: "no matching measures",
    targeting: "Targeting",
    ageRange: {
      none: "",
      lessThan: "age <= {{ maxAge }}",
      greaterThan: "age {{ minAge }}+",
      bounded: "age {{ minAge }} - {{ maxAge }}",
    },
    estimatedTime: {
      none: "",
      subMinute: "< 1 min",
      minutes: "{{ minutes }} min",
    },
  },

  scaleScorers: {
    title: "Scale",
    noneMatching: "No matching scales",
    withUsage: "{{ name }} - {{ sessionCount }} administrations",
  },

  treatmentTracks: {
    title: "Treatment Track",
    noneMatching: "No matching treatment tracks",
  },

  remoteData: {
    loading: "Loading",
    failure: "Error",
    notAsked: "Loading",
    unknown: "Unknown",
  },

  edit: "Edit",
  unexpectedError: "An unexpected error has occurred",
  notFound: "Not Found",
  add: "Add",
  unknown: "Unknown",
  selectAll: "Select All",
  deselectAll: "Deselect All",

  decisionTable: {
    successfulItems_one: "The check was successful",
    successfulItems_other: "All {{count}} checks where successful",
    additionalItems_one: "An additional {{count}} items were not evaluated",
    additionalItems_other: "One additional item was not evaluated",
  },

  filters: {
    clearFilter: "Clear Filters",
    pin: "Pin",
    unpin: "Unpin",
    persistMessage: "Your filters have been restored from your last use",
    resetColumns: "Reset Columns",
  },

  notApplicable: "N/A",

  ssoLanding: {
    title: "You are being logged in",
    subtitle: "We are logging you in. You will be redirected to your destination shortly.",
    errorTitle: "This request has expired or was invalid.",
    errorSubtitle:
      "This request may have expired. Please try logging in from your single-sign-on provider again.",
  },

  loggedOut: {
    title: "You have been logged out.",
    subtitle: "You may have timed out, or selected to end your session.",
    cta: "Log Back In",
  },

  filterPanel: {
    showActiveFilters_one: "Show {{count}} Filter",
    showActiveFilters_other: "Show {{count}} Filters",
    hideActiveFilters_one: "Hide {{count}} Filter",
    hideActiveFilters_other: "Hide {{count}} Filters",
    activeFilters_one: "{{count}} Filter",
    activeFilters_other: "{{count}} Filters",
    filters: "Filters",
    dialogTitle: "Settings & Filters",
  },

  entityQuery: {
    exact: "Items specific to",
    moreSpecific: "Sub-items that contribute to",
    lessSpecificPrefix: "Rollup items that",
    lessSpecificSuffix: "contributes to",
    allExplanation: "You are currently not filtering these items by entity and are seeing all entities.",
    someExplanation: "You are currently filtering these items to include:",
    exactExplanation: "items matching exactly the entity selected",
    moreSpecificExplanation: "sub-items that contribute to entity selected",
    lessSpecificExplanation: "rollup items the entity selected contributes to",
    filterAtTop: "Select 'Filters' at the top of the page to change the filtering of these items",
  },

  integerRange: {
    exact: "{{min}}",
    closed: "{{min}}-{{max}}",
    openHigh: "{{min}}+",
    openLow: "-{{max}}",
  },
};

export default common;
