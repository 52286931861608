import { Button, styled } from "@mui/material";
import { apolloMutationHookWrapper, MutationRemoteDataResult } from "Api/GraphQL";
import {
  GoalEvent,
  GoalStatus,
  UpdateGoalStatusMutation,
  UpdateGoalStatusMutationFn,
  useUpdateGoalStatusMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "seidr";
import { Goal } from "Shared/Scale/Goal";
import { Dig } from "type-utils";

export type ToggleGoalStatusButtonProps = {
  goal: Pick<Goal, "id" | "status">;
  careEpisodeId: CareEpisodeId;
};

export type UpdateGoalStatusResult = Dig<UpdateGoalStatusMutation, ["assessmentUpdateGoal", "result"]> | null;

function ToggleGoalStatusButtonHookWrapper(props: ToggleGoalStatusButtonProps): ReactElement {
  const [mutateFunction, { remoteData }] = apolloMutationHookWrapper(
    (data) => {
      return data.assessmentUpdateGoal;
    },
    useUpdateGoalStatusMutation({
      // Make sure we refetch the overview goals when we update the goal status
      refetchQueries: refetchQueries("goals"),
    })
  );

  return ToggleGoalStatusButton({ mutateFunction, remoteData, ...props });
}

type ToggleGoalStatusButtonDataProps = {
  remoteData: MutationRemoteDataResult<UpdateGoalStatusResult>;
  mutateFunction: UpdateGoalStatusMutationFn;
} & ToggleGoalStatusButtonProps;

const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

function calculateGoalEvent(status: GoalStatus): GoalEvent {
  switch (status) {
    case GoalStatus.NEW:
      return GoalEvent.CANCEL;
    case GoalStatus.ACTIVE:
      return GoalEvent.PAUSE;
    default:
      return GoalEvent.RESUME;
  }
}

function ToggleGoalStatusButton(props: ToggleGoalStatusButtonDataProps): ReactElement {
  const { t } = useTranslation();

  const { goal, remoteData } = props;

  const onToggle = () => {
    props.mutateFunction({
      variables: {
        input: {
          goalId: goal.id.toString(),
          event: calculateGoalEvent(goal.status),
        },
      },
    });
  };

  const buttonText = (goalOrUpdate: ToggleGoalStatusButtonProps["goal"]) => {
    if (goalOrUpdate.status === GoalStatus.ACTIVE) {
      return t("pause");
    } else if (goalOrUpdate.status === GoalStatus.NEW) {
      return t("delete");
    } else {
      return t("resume");
    }
  };

  return (
    <StyledSecondaryButton
      color="secondary"
      variant="contained"
      data-testid="update-goal"
      size="small"
      disabled={props.remoteData.equals(Loading())}
      onClick={onToggle}
    >
      {remoteData.caseOf({
        Loading: () => t("saving"),
        Success: (update) => buttonText(update?.goal || goal),
        _: () => buttonText(goal),
      })}
    </StyledSecondaryButton>
  );
}

export default ToggleGoalStatusButtonHookWrapper;
export { ToggleGoalStatusButtonHookWrapper as HookWrapper, ToggleGoalStatusButton as Component };
