import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  TreatmentService,
  useUpdateTreatmentServiceMutation,
  useUpdateTreatmentServiceRequiredDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { fromNullableString } from "Lib/Id";
import { TreatmentServiceId } from "Lib/Ids";
import { LinkButton } from "MDS/Link";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormOverlay, useRedirectOnSuccess } from "Shared/Form";
import { TreatmentServiceSelectBox } from "./TreatmentServiceSelectBox";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

function UpdateTreatmentService(): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // First, let's wrangle the actual treatment service id we're attempting to update.
  const { id } = useParams();

  const treatmentServiceId = fromNullableString<"TreatmentService">(id).getOrElse(null);
  if (!treatmentServiceId) {
    return <>{t("treatmentServices:update.errorLoading")}</>;
  }

  // Now we can actually query for our treatment service and see what we have.
  const { remoteData } = apolloQueryHookWrapper(
    useUpdateTreatmentServiceRequiredDataQuery({
      variables: {
        treatmentServiceId: treatmentServiceId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => {
      return <CircularProgress />;
    },
    NotAsked: () => {
      return <CircularProgress />;
    },
    Failure: (_error) => {
      return <>{t("treatmentServices:update.errorLoading")}</>;
    },
    Success: (result) => {
      if (result.treatmentService && result.treatmentServices) {
        return (
          <UpdateTreatmentServiceElement
            treatmentService={result.treatmentService}
            treatmentServices={result.treatmentServices.nodes}
          />
        );
      }
      return <>{t("treatmentServices:update.errorLoading")}</>;
    },
  });
}

type UpdateTreatmentServiceElementProps = {
  treatmentService: Pick<
    TreatmentService,
    "__typename" | "id" | "name" | "measurementAllowed" | "parentId" | "serviceTypes"
  >;
  treatmentServices: ReadonlyArray<Pick<TreatmentService, "__typename" | "id" | "name">>;
};
function UpdateTreatmentServiceElement(props: UpdateTreatmentServiceElementProps): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);

  // Mutation setup.
  const [updateTreatmentService, { remoteData }] = apolloMutationHookWrapper((data) => {
    return data.updateTreatmentService;
  }, useUpdateTreatmentServiceMutation());

  // Name
  const [name, setName] = useState(props.treatmentService.name);
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  // Parent Treatment Service
  const [parentId, setParentId] = useState<TreatmentServiceId | null>(null);
  const onParentIdChangeHandler = (newParentId: TreatmentServiceId | null) => {
    setParentId(newParentId);
  };

  // Measurement Allowed
  const [measurementAllowed, setMeasurementAllowed] = useState(true);
  const onMeasurementAllowedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMeasurementAllowed(event.target.checked);
  };

  // Service Types
  const [serviceTypes, setServiceTypes] = useState(props.treatmentService.serviceTypes);
  const onServiceTypesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceTypes(event.target.value.split(","));
  };

  // Update
  const onUpdate = () => {
    updateTreatmentService({
      variables: {
        input: {
          treatmentServiceId: props.treatmentService.id,
          name: name,
          measurementAllowed: measurementAllowed,
          parentId: parentId,
          serviceTypes: serviceTypes.map((serviceType) => {
            // This just makes sure we aren't saving stuff with extra starting
            // whitespace. We still want to let folks insert that stuff while editing.
            return serviceType.trim();
          }),
        },
      },
    });
  };

  // Don't forget to bring the user back to the index page on success.
  const navigate = useNavigate();
  useRedirectOnSuccess(remoteData, "..", navigate);

  // The actual element and display.
  return (
    <Page browserTitle={t("treatmentServices:update.title")}>
      <Card>
        <CardHeader title={t("treatmentServices:update.title")}></CardHeader>
        <CardContent>
          <Form>
            <Stack spacing={2}>
              <Box width="30em">
                <TextField
                  value={name}
                  fullWidth
                  label={t("treatmentServices:update.nameLabel")}
                  variant="outlined"
                  onChange={onNameChange}
                ></TextField>
              </Box>
              <Box width="30em">
                <TreatmentServiceSelectBox
                  treatmentServices={props.treatmentServices}
                  onChangeHandler={onParentIdChangeHandler}
                  startingParentId={
                    props.treatmentService.parentId ? props.treatmentService.parentId : undefined
                  }
                />
              </Box>
              <Box width="30em">
                <TextField
                  value={serviceTypes}
                  fullWidth
                  label={t("treatmentServices:update.serviceTypesLabel")}
                  variant="outlined"
                  onChange={onServiceTypesChange}
                  helperText={t("treatmentServices:update.serviceTypesHelpText")}
                ></TextField>
              </Box>
              <Box>
                <FormControlLabel
                  control={<Checkbox checked={measurementAllowed} onChange={onMeasurementAllowedChange} />}
                  label={t("treatmentServices:update.measurementAllowedLabel")}
                />
              </Box>
              <Stack direction="row" spacing={1}>
                <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
                  <Button variant="contained" onClick={onUpdate}>
                    {t("treatmentServices:update.updateButton")}
                  </Button>
                </ReadOnlyOrImpersonatingDisabledButton>
                <LinkButton variant="contained" to="..">
                  {t("treatmentServices:update.cancelButton")}
                </LinkButton>
              </Stack>
              <FormOverlay response={remoteData} errorMessage={t("treatmentServices:update.errorUpdating")} />
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Page>
  );
}

export default UpdateTreatmentService;
