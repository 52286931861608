import iAWriterQuattroSWoff2 from "../../../Assets/Fonts/iAWriterQuattroS/iAWriterQuattroS-Regular.woff2";
import GibsonBook from "../../../Assets/Fonts/Gibson_Complete_web/Gibson-Book.woff2";
import iAWriterQuattroSBoldWoff2 from "../../../Assets/Fonts/iAWriterQuattroS/iAWriterQuattroS-Bold.woff2";
import iAWriterQuattroSItalicWoff2 from "../../../Assets/Fonts/iAWriterQuattroS/iAWriterQuattroS-Italic.woff2";
import iAWriterQuattroSBoldItalicWoff2 from "../../../Assets/Fonts/iAWriterQuattroS/iAWriterQuattroS-BoldItalic.woff2";
import iAWriterMonoSWoff2 from "../../../Assets/Fonts/iAWriterMonoS/iAWriterMonoS-Regular.woff2";
import iAWriterMonoSBoldWoff2 from "../../../Assets/Fonts/iAWriterMonoS/iAWriterMonoS-Bold.woff2";
import iAWriterMonoSItalicWoff2 from "../../../Assets/Fonts/iAWriterMonoS/iAWriterMonoS-Italic.woff2";
import iAWriterMonoSBoldItalicWoff2 from "../../../Assets/Fonts/iAWriterMonoS/iAWriterMonoS-BoldItalic.woff2";

const gibsonBook: FontFace = {
  fontFamily: "gibson",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "normal",
  src: `url(${GibsonBook})`,
  format: "woff2",
};

const iAWriterQuattroS: FontFace = {
  fontFamily: "iAWriterQuattroS",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "normal",
  src: `url(${iAWriterQuattroSWoff2})`,
  format: "woff2",
};

const iAWriterQuattroSBold: FontFace = {
  fontFamily: "iAWriterQuattroS",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "bold",
  src: `url(${iAWriterQuattroSBoldWoff2})`,
  format: "woff2",
};

const iAWriterQuattroSItalic: FontFace = {
  fontFamily: "iAWriterQuattroS",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: "normal",
  src: `url(${iAWriterQuattroSItalicWoff2})`,
  format: "woff2",
};

const iAWriterQuattroSBoldItalic: FontFace = {
  fontFamily: "iAWriterQuattroS",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: "bold",
  src: `url(${iAWriterQuattroSBoldItalicWoff2})`,
  format: "woff2",
};

const iAWriterMonoS: FontFace = {
  fontFamily: "iAWriterMonoS",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "normal",
  src: `url(${iAWriterMonoSWoff2})`,
  format: "woff2",
};
const iAWriterMonoSBold: FontFace = {
  fontFamily: "iAWriterMonoS",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: "bold",
  src: `url(${iAWriterMonoSBoldWoff2})`,
  format: "woff2",
};
const iAWriterMonoSItalic: FontFace = {
  fontFamily: "iAWriterMonoS",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: "normal",
  src: `url(${iAWriterMonoSItalicWoff2})`,
  format: "woff2",
};
const iAWriterMonoSBoldItalic: FontFace = {
  fontFamily: "iAWriterMonoS",
  fontStyle: "italic",
  fontDisplay: "swap",
  fontWeight: "bold",
  src: `url(${iAWriterMonoSBoldItalicWoff2})`,
  format: "woff2",
};

type FontFace = {
  fontFamily: string;
  fontStyle: "normal" | "italic" | "oblique";
  fontDisplay: "auto" | "block" | "swap" | "fallback" | "optional";
  fontWeight: "normal" | "bold";
  src: string;
  format: "woff" | "woff2" | "truetype" | "opentype" | "embedded-opentype" | "svg";
};

function convertFontFace(font: FontFace): string {
  return `
        @font-face {
          font-family: '${font.fontFamily}';
          font-style: ${font.fontStyle};
          font-display: ${font.fontDisplay};
          font-weight: ${font.fontWeight};
          src: ${font.src} format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `;
}

const fonts = [
  gibsonBook,
  iAWriterQuattroS,
  iAWriterQuattroSBold,
  iAWriterQuattroSItalic,
  iAWriterQuattroSBoldItalic,
  iAWriterMonoS,
  iAWriterMonoSBold,
  iAWriterMonoSItalic,
  iAWriterMonoSBoldItalic,
];

const override = {
  styleOverrides: `
      ${fonts.map((font) => convertFontFace(font)).join()}
    `,
};

export default override;
