import { styled } from "@mui/material/styles";
import { DataContext } from "@visx/xychart";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useContext } from "react";
import { Maybe } from "seidr";
import { ScaleWithNumericalScorer } from "Shared/Scale/Scale";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";

const StyledChartBackgroundBox = styled("rect", {
  shouldForwardProp: (prop) => {
    return prop !== "thresholdClass";
  },
})<{
  thresholdClass: ScaleThresholdClass;
}>(({ thresholdClass, theme }) => ({
  fill: SeverityThresholdClass.color(thresholdClass, theme).primary,
  opacity: 0.3,
}));

function ScaleThresholdChartBackground({ scale }: { scale: Maybe<ScaleWithNumericalScorer> }): ReactElement {
  const { yScale, margin, innerWidth, innerHeight } = useContext(DataContext);

  // The goal here is to use thresholds for our background if we can, which is when we have a well defined y scale and
  // at least one threshold. But if we don't have those things, then we fall back on just one big gray box.

  const defaultBoxes = [
    <StyledChartBackgroundBox
      x={margin?.left}
      y={margin?.top}
      width={innerWidth}
      height={innerHeight}
      thresholdClass={ScaleThresholdClass.UNKNOWN}
      key={1} // This will otherwise be considered keyless and will annoy us in the console.
    />,
  ];

  const boxes = scale.caseOf({
    Nothing: () => defaultBoxes,
    Just: (scale) => {
      if (yScale && scale.scorer.thresholdData && scale.scorer.thresholdData.length > 0) {
        return scale.scorer.thresholdData.map((thresh, index) => {
          const bY = yScale(thresh.maxValue)?.valueOf() || 0;
          const bheight = (yScale(thresh.minValue)?.valueOf() || 0) - bY;

          return (
            <StyledChartBackgroundBox
              x={margin?.left}
              y={bY}
              width={innerWidth}
              height={bheight}
              thresholdClass={thresh.thresholdClass}
              key={index}
            />
          );
        });
      } else {
        return defaultBoxes;
      }
    },
  });

  return <>{boxes}</>;
}

export default ScaleThresholdChartBackground;
