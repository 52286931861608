import { Button, ButtonProps } from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactElement } from "react";
import Spinner from "Shared/Spinner";

type ButtonWithSpinnerProps = ButtonProps & {
  showSpinner: boolean;
};

// A button that can optionally show a spinner animation inside of it. This is meant to be used for buttons that are
// disabled while some operation (like a form submission) is pending. Note that the color of the spinner animation is
// not currently changeable and matches the background of a variant="contained" color="primary" button, so you should
// make sure to disable the button whenever the spinner is showing.
export function ButtonWithSpinner(props: ButtonWithSpinnerProps): ReactElement {
  const spinner = (
    <Spinner
      // 24 Makes it just fit inside a one-line button
      size={24}
      // Positioning shenanigans here make it centered within the button vertically and horizontally
      sx={{ position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px" }}
    />
  );

  const { showSpinner, ...buttonProps } = props;

  // Box has to be position: relative so that the spinner can be absolutely positioned inside it.
  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <Button {...buttonProps}>{props.children}</Button>
      {showSpinner && spinner}
    </Box>
  );
}
