import React, { ReactElement } from "react";
import TaskSearchFilters, { useTaskSearchFilters } from "./TaskSearchFilters";
import { PatientId } from "Lib/Ids";
import { TaskListTable } from "./TaskListTable";

type TaskListProps = {
  fixedPatientId?: PatientId;
};

function TaskList(props: TaskListProps): ReactElement {
  const { fixedPatientId } = props;
  const filters = useTaskSearchFilters();

  return (
    <>
      <TaskSearchFilters filters={filters} />
      <TaskListTable filters={filters} patientId={fixedPatientId} />
    </>
  );
}

export default TaskList;
