import SumType from "sums-up";
import * as NEL from "Lib/NonEmptyList";
import { find } from "Lib/Utils";
import { isEqual } from "date-fns";

type ValueWithDate = {
  date: Date;
};

class DateQuantizedDatum<V extends ValueWithDate> extends SumType<{
  Value: [V];
  Blank: [Date];
}> {}

const Value = <V extends ValueWithDate>(v: V) => new DateQuantizedDatum("Value", v);
const Blank = (d: Date) => new DateQuantizedDatum("Blank", d);

export type DateQuantizedData<V extends ValueWithDate> = NEL.NonEmptyList<DateQuantizedDatum<V>>;

function quantize<V extends ValueWithDate>(
  all: NEL.NonEmptyList<Date>,
  values: ReadonlyArray<V>
): DateQuantizedData<V> {
  return all.map((date) => {
    return find((v) => isEqual(v.date, date), values).caseOf({
      Just: Value,
      Nothing: () => Blank(date),
    });
  });
}

function quantizeWithAccessor<V>(
  all: NEL.NonEmptyList<Date>,
  values: ReadonlyArray<V>,
  transform: (datum: V) => Date
): DateQuantizedData<V & ValueWithDate> {
  return all.map((date) => {
    return find((v) => isEqual(transform(v), date), values).caseOf({
      Just: (v) => Value({ ...v, date }),
      Nothing: () => Blank(date),
    });
  });
}

const Test = {
  Value,
  Blank,
};

export { quantize, quantizeWithAccessor, DateQuantizedDatum, Test };
