import { Box, Card, CardContent, CardHeader, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { AlgorithmDetailsQuery, useAlgorithmDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { EntityDecisionAlgorithmId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import { styled } from "@mui/material/styles";
import { RichText } from "MDS/RichText";
import EntityPath from "Entities/EntityPath";
import {
  entityDecisionAlgorithmStatusDescriptionT,
  entityDecisionAlgorithmStatusT,
} from "GeneratedGraphQL/EnumTranslations";
import { Check, Close } from "@mui/icons-material";

type AlgorithmDetailsProps = {
  entityDecisionAlgorithmId: EntityDecisionAlgorithmId;
};

type AlgorithmDetails = NonNullable<AlgorithmDetailsQuery["decisionSupportEntityDecisionAlgorithm"]>;

export function AlgorithmDetail(props: AlgorithmDetailsProps): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);

  const { remoteData } = apolloQueryHookWrapper(
    useAlgorithmDetailsQuery({
      variables: {
        entityDecisionAlgorithmId: props.entityDecisionAlgorithmId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <SkeletonCard />,
    Loading: () => <SkeletonCard />,
    Failure: (err) => <ErrorCard message={err.message} />,
    Success: (response) => {
      if (response.decisionSupportEntityDecisionAlgorithm) {
        return <AlgorithmCard algorithm={response.decisionSupportEntityDecisionAlgorithm} />;
      } else {
        return <ErrorCard message={t("decisionSupport:algorithms.noSuchAlgorithm")} />;
      }
    },
  });
}

function SkeletonCard() {
  return (
    <Card>
      <CardHeader title={<Skeleton />} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}

function ErrorCard(props: { message: string }) {
  return (
    <Card>
      <CardHeader />
      <CardContent>
        <ErrorMessage message={props.message} />
      </CardContent>
    </Card>
  );
}

const InfoBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.info.light,
}));

type AlgorithmCardProps = {
  algorithm: AlgorithmDetails;
};

function AlgorithmCard(props: AlgorithmCardProps) {
  const { t } = useTranslation(["decisionSupport", "enums", "common"]);

  const statusTitle = entityDecisionAlgorithmStatusT(props.algorithm.status, t);

  const statusDescription = entityDecisionAlgorithmStatusDescriptionT(props.algorithm.status, t);

  const subheader = `${statusTitle} - ${statusDescription}`;

  return (
    <Card>
      <CardHeader title={props.algorithm.name} subheader={subheader} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Typography>{t("decisionSupport:algorithms.details.appliesTo")} </Typography>
          <EntityPath entityTreeNode={props.algorithm.entityTreeNode} />
          <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
            <Grid item sm={4} xs={12} key="">
              <Typography variant="h3">{t("decisionSupport:algorithms.details.startDate")}</Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography>
                {props.algorithm.startDate
                  ? t("common:date.tiny", { date: props.algorithm.startDate })
                  : null}
              </Typography>
            </Grid>
            <Grid item sm={4} xs={12} key="">
              <Typography variant="h3">
                {t("decisionSupport:algorithms.details.sendNotifications")}
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography>{props.algorithm.sendNotifications ? <Check /> : <Close />}</Typography>
            </Grid>
          </Grid>
          <InfoBox>
            <RichText>{props.algorithm.notes || t("decisionSupport:algorithms.details.noNotes")}</RichText>
          </InfoBox>
        </Stack>
        <RichText>{props.algorithm.decisionAlgorithm.fullDescription}</RichText>
      </CardContent>
    </Card>
  );
}
