import { IntegerRange } from "GeneratedGraphQL/SchemaAndOperations";
import { TFunction } from "i18next";

export function rangeToText(range: IntegerRange, t: TFunction<["common"]>): string {
  if (range.max === range.min) {
    return t("common:integerRange.exact", range);
  } else if (typeof range.min === "number" && typeof range.max === "number") {
    return t("common:integerRange.closed", range);
  } else if (typeof range.max === "number") {
    return t("common:integerRange.openLow", range);
  } else if (typeof range.min === "number") {
    return t("common:integerRange.openHigh", range);
  } else {
    return "";
  }
}
