import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiCardActions"] = {
  root: {
    // This value should match the padding in MuiCardContent so the buttons are aligned with the text.
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
};

const override = {
  styleOverrides,
};

export default override;
