import { useTheme } from "@mui/material/styles";
import { GlyphSeries, LineSeries } from "@visx/xychart";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { getDate, isInvalid, SeveritySupportedValues } from "Shared/Severity/Severity";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";
import { HoverCircle, HoverCircleSize } from "./HoverCircle";

type SeverityHistorySeriesProps = {
  history: ReadonlyArray<SeveritySupportedValues>;
  valueRoute: (value: SeveritySupportedValues) => FeedbackReportRoute;
  invalidYValue: number;
};

export function SeverityHistorySeries(props: SeverityHistorySeriesProps): ReactElement {
  const { history, valueRoute } = props;
  const theme = useTheme();

  const getX = (value: SeveritySupportedValues) => getDate(value);
  const getY = (value: SeveritySupportedValues) => value.value;

  const validScores = history.filter((score) => !isInvalid(score));
  const invalidScores = history.filter(isInvalid);

  validScores.sort((a, b) => getDate(a).valueOf() - getDate(b).valueOf());
  invalidScores.sort((a, b) => getDate(a).valueOf() - getDate(b).valueOf());

  return (
    <>
      <LineSeries dataKey="line" data={validScores} xAccessor={getX} yAccessor={getY} />
      <GlyphSeries
        dataKey="glyph-valid"
        data={validScores}
        xAccessor={getX}
        yAccessor={getY}
        renderGlyph={(props) => (
          <Link to={valueRoute(props.datum)}>
            <HoverCircle
              cx={props.x}
              cy={props.y}
              swatch={SeverityThresholdClass.color(props.datum.thresholdClass, theme)}
            />
          </Link>
        )}
      />
      <GlyphSeries
        dataKey="glyph-invalid"
        data={invalidScores}
        xAccessor={getX}
        // Force invalid values up to the top of the chart...
        yAccessor={(_) => props.invalidYValue}
        renderGlyph={(props) => (
          <Link to={valueRoute(props.datum)}>
            <HoverCircle
              cx={props.x}
              // ... and then push them up even a little more so the dot is fully above the top axis instead of
              // centered on it.
              cy={props.y - (HoverCircleSize.small.radius + 2)}
              swatch={SeverityThresholdClass.color(ScaleThresholdClass.UNANSWERED, theme)}
            />
          </Link>
        )}
      />
    </>
  );
}
