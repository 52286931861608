import { apolloMutationHookWrapper } from "Api/GraphQL";
import { InboxMessagesFindingDatagram } from "CollaborativeCare/CareManagerDashboard/InboxMessagesFindings";
import {
  useActOnInboxMessageMutation,
  useResendInvitationNotificationsMutation,
  useSendInvitationNotificationsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { InvitationId, PatientId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type SendNotificationAndSnoozeActionProps = {
  finding: InboxMessagesFindingDatagram;
  invitationId?: InvitationId;
  patientId?: PatientId;
};
export function SendNotificationAndSnoozeAction(props: SendNotificationAndSnoozeActionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [snooze, { remoteData: snoozeRemoteData }] = apolloMutationHookWrapper(
    (response) => response.actOnInboxMessage,
    useActOnInboxMessageMutation({
      variables: {
        input: {
          action: {
            snooze: {
              inboxMessageId: props.finding.inboxMessage.id,
              date: tomorrow,
            },
          },
        },
      },
      refetchQueries: refetchQueries("findings"),
    })
  );

  const [sendInvitationNotifications, { remoteData: singleNotificationRemoteData }] =
    apolloMutationHookWrapper(
      (response) => response.collaborativeCareSendInvitationNotifications,
      useSendInvitationNotificationsMutation({})
    );

  useEffectSimpleCompare(() => {
    singleNotificationRemoteData.caseOf({
      NotAsked: () => null,
      Loading: () => null,
      Failure: () => null,
      Success: () => {
        snooze();
      },
    });
  }, [singleNotificationRemoteData.kind]);

  const [resendAllNotifications, { remoteData: allNotificationsRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareResendAllInvitationNotifications,
    useResendInvitationNotificationsMutation({})
  );

  useEffectSimpleCompare(() => {
    allNotificationsRemoteData.caseOf({
      NotAsked: () => null,
      Loading: () => null,
      Failure: () => null,
      Success: () => {
        snooze();
      },
    });
  }, [allNotificationsRemoteData.kind]);

  const onSnooze = () => {
    if (props.invitationId) {
      sendInvitationNotifications({
        variables: {
          input: {
            invitationId: props.invitationId,
          },
        },
      });
    } else if (props.patientId) {
      resendAllNotifications({
        variables: {
          input: {
            patientId: props.patientId,
          },
        },
      });
    }
  };

  const showSpinner = snoozeRemoteData.kind === "Loading";
  const disabled = showSpinner;

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="success"
      onClick={onSnooze}
      showSpinner={showSpinner}
      disabled={disabled}
    >
      {t("collaborativeCare:findings.actions.notifyAndSnooze")}
    </ButtonWithSpinner>
  );
}
