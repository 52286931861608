import { apolloMutationHookWrapper } from "Api/GraphQL";
import { DataDumpStatus, useDownloadExportMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { DataDumpId } from "Lib/Ids";
import React, { useState } from "react";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { useTranslation } from "react-i18next";
import { Alert, Snackbar } from "@mui/material";

type ButtonProps = {
  id: DataDumpId;
  status: DataDumpStatus;
};

type AlertProps = {
  severity: "success" | "error";
  message: string;
} | null;

export default function (props: ButtonProps) {
  const { t } = useTranslation(["settings"]);
  const [showAlert, setShowAlert] = useState<AlertProps>(null);
  const [downloadFile, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.exportCreateDataDumpDownloadUrl,
    useDownloadExportMutation({
      variables: {
        input: {
          dataDumpId: props.id,
        },
      },
    })
  );
  const showSpinner = remoteData.kind === "Loading";

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: (result) => {
        if (result?.downloadUrl) {
          window.location.href = result.downloadUrl;
          setShowAlert({ severity: "success", message: t("settings:exports.downloadBegins") });
        }
      },
      Failure: (_) => {
        setShowAlert({ severity: "error", message: t("settings:exports.error") });
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  if (props.status !== DataDumpStatus.READY) {
    return null;
  }

  let alert = undefined;
  const onClose = () => setShowAlert(null);

  if (showAlert !== null) {
    alert = (
      <Snackbar
        open={true}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ position: "fixed" }}
      >
        <Alert onClose={onClose} variant="filled" severity={showAlert.severity}>
          {showAlert.message}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <>
      <ButtonWithSpinner
        showSpinner={showSpinner}
        variant={"contained"}
        color="secondary"
        disabled={showSpinner}
        onClick={() => downloadFile()}
      >
        {t("settings:exports.download")}
      </ButtonWithSpinner>

      {alert}
    </>
  );
}
