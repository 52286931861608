import { Box, styled } from "@mui/material";

/**
 * For reasons I don't understand, something in the guts of the Mui datagrid is ever so slightly imperfectly responsive,
 * and so when we use them in a card on the patient details page on a mobile-sized viewport on a desktop browser, such
 * as in the Epic sidecar, they push the width of the page into the space taken up by the vertical scrollbar, which
 * makes the whole page grow an unisghtly horizontal scrollbar. Note that this does not happen on actual mobile
 * browsers.
 *
 * This box calculates its size to be several rems smaller than the viewport, which accounts for the spacing and padding
 * on our cards to be just the right size to force its content to not overflow in this specific situation. On desktop,
 * the size of the table will be constrained by the layout of the rest of the page to be much smaller than this.
 *
 * You should put this around any use of a data grid on patient details page. As far as I can tell, it doesn't need to
 * be used on other data grid pages, such as patient search.
 */
export const PatientDetailsDataGridContainer = styled(Box)(({ theme }) => ({
  maxWidth: `calc(100vw - ${theme.spacing(5.25)})`,
}));
