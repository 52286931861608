import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import {
  useOutcomesMetricTemplatesLazyQuery,
  useOutcomesMetricTemplateNameQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { MetricId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";
import { assertNonNull } from "Lib/Utils";

type ChooseMetricTemplateProps = {
  onTemplateIdChange: (value: MetricId | null) => void;
  templateId: MetricId | null;
};

type MetricTemplateSelectProps = {
  value: MetricId | null;
  setValue: (newValue: MetricId | null) => void;
};

type SelectOption = { id: MetricId; title: string };

function inputToSelect(value: SelectOption | null): MetricId | null {
  if (value) {
    return value.id;
  } else {
    return null;
  }
}

function MetricTemplateSelect(props: MetricTemplateSelectProps) {
  const { t } = useTranslation(["outcomes"]);

  // In cases where we are given an id as part of a query string etc we need to fetch
  // the name of the option no matter what it is rather than relying on the search.
  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useOutcomesMetricTemplateNameQuery({
      variables: {
        id: assertNonNull(props.value), // Apollo will only call if value is present
      },
      skip: !props.value,
    })
  );

  const value = props.value
    ? currentValueRemoteData.caseOf({
        Success: (result) => {
          if (result.outcomesMetricTemplate) {
            return {
              id: result.outcomesMetricTemplate.id,
              title: result.outcomesMetricTemplate.name,
            };
          } else if (props.value) {
            return {
              id: props.value,
              title: "Loading",
            };
          }
          return null;
        },
        _: () => {
          if (props.value) {
            return {
              id: props.value,
              title: "Loading",
            };
          }
          return null;
        },
      })
    : null;

  return (
    <QueryAutocompleteSingle
      query={useOutcomesMetricTemplatesLazyQuery}
      queryVariables={{}}
      unwrapResponse={(response) =>
        response.outcomesMetricTemplates?.nodes.map((template) => {
          return {
            id: template.id,
            title: template.name,
          };
        })
      }
      valueUpdated={(value) => props.setValue(inputToSelect(value))}
      valueEqual={(left, right) => left.id === right.id}
      value={value}
      label={t("outcomes:templateSelect.title")}
      autocompleteProps={{
        noOptionsText: t("outcomes:templateSelect.noneMatching"),
        getOptionLabel: (option) => option.title,
        sx: { width: "20em" },
      }}
    />
  );
}

function SelectMetric(props: ChooseMetricTemplateProps) {
  const { t } = useTranslation(["outcomes"]);

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.template.useATemplate")}</span>
        <FormControl>
          <MetricTemplateSelect value={props.templateId} setValue={props.onTemplateIdChange} />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

export function ChooseMetricTemplate(props: ChooseMetricTemplateProps) {
  const { t } = useTranslation(["outcomes"]);
  const [activeTemplate, setActiveTemplate] = useState<"template" | "scratch">(
    props.templateId ? "template" : "scratch"
  );

  const onChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === "template") {
      setActiveTemplate(value);
    } else {
      setActiveTemplate("scratch");
      props.onTemplateIdChange(null);
    }
  };

  const onTemplateIdChange = (metricId: MetricId | null) => {
    if (metricId) {
      setActiveTemplate("template");
    } else {
      setActiveTemplate("scratch");
    }

    props.onTemplateIdChange(metricId);
  };

  return (
    <>
      <Card>
        <CardHeader title={t("outcomes:create.template.title")} />
        <CardContent>
          <FormControl>
            <SectionHelpText text={t("outcomes:create.template.helpText")} />
            <RadioGroup value={activeTemplate} onChange={onChange}>
              <FormControlLabel
                value={"scratch"}
                control={<Radio />}
                label={t("outcomes:create.template.scratch")}
              />
              <FormControlLabel
                value={"template"}
                control={<Radio />}
                label={<SelectMetric onTemplateIdChange={onTemplateIdChange} templateId={props.templateId} />}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
    </>
  );
}
