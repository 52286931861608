import React from "react";
import { AppBar, Box, Toolbar, IconButton } from "@mui/material";
import Account from "./Account";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { OnMobile } from "Shared/Responsive";
import { UserMenuItem } from "../NavBar/NavBar";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/PanelManagement/GlobalPanelIndicator";
import { useSelectedPanels } from "Contexts/SelectedPanelContext";
import { DebugModal } from "Debug/DebugModal";
import { NotInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";

const StyledToolBar = styled(Toolbar)(() => ({
  minHeight: 64,
}));

const StyledAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== "panelsActive" })<{
  panelsActive: boolean;
}>(({ theme, panelsActive }) => ({
  backgroundColor: panelsActive ? theme.palette.collaborativeCare.panels.main : theme.palette.primary.main,
  position: "fixed",
}));

type Props = {
  user: {
    name: string;
    lastSignIn: Date | null;
  };
  userBadge?: { count: number };
  toggleNavDrawer: () => void;

  userMenuItems: Array<UserMenuItem>;
};

function TopBar({ user, toggleNavDrawer, userMenuItems, userBadge }: Props) {
  const panelsActive = useSelectedPanels().mode === "panels-active";

  return (
    <OnMobile>
      <NotInEmbeddedPatientOrEncounter>
        <StyledAppBar panelsActive={panelsActive}>
          <StyledToolBar>
            <IconButton
              edge="start"
              aria-label="open navigation"
              onClick={toggleNavDrawer}
              size="large"
              // For reasons I don't understand setting `color="inherit"` on IconButton directly has no effect, but
              // setting it like this through the sx prop does.
              sx={{ color: "inherit" }}
            >
              <MenuIcon />
            </IconButton>

            <Box sx={{ ml: 2, flexGrow: 1 }} />
            <DebugModal />
            <GlobalPanelIndicatorBadge />
            <Box sx={{ ml: 2 }}>
              <Account isMobile={true} user={user} userMenuItems={userMenuItems} userBadge={userBadge} />
            </Box>
          </StyledToolBar>
        </StyledAppBar>
        <Toolbar />
      </NotInEmbeddedPatientOrEncounter>
    </OnMobile>
  );
}

export default TopBar;
