import { DoNotDisturb } from "@mui/icons-material";
import { Button, ButtonProps, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { useDiscardTimerMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { refetchQueries } from "Lib/RefetchQueries";
import React, { ReactElement } from "react";

type TimerDiscardButtonProps = ButtonProps & {
  buttonLabel: string;
  onSuccess?: () => void;
};

export function TimerDiscardButton(props: TimerDiscardButtonProps): ReactElement | null {
  const currentTimeTracking = useCurrentTimeTracking();

  const { buttonLabel, onSuccess, ...passthroughProps } = props;

  const discardTimer = useDiscardTimer(onSuccess);

  // We'll want to exit early, and also descope currentTimeTracking so
  // we can pull off the currentTimeTracking.timeEntryLogId later.
  if (currentTimeTracking.status !== "tracking") {
    return (
      <Button disabled={true} startIcon={<DoNotDisturb />}>
        {buttonLabel}
      </Button>
    );
  }

  return (
    <Button
      onClick={() =>
        discardTimer({
          variables: {
            input: {
              timeEntryLogId: currentTimeTracking.timeEntryLogId,
            },
          },
        })
      }
      startIcon={<DoNotDisturb />}
      {...passthroughProps}
    >
      {buttonLabel}
    </Button>
  );
}

type TimerDiscardMenuItemProps = {
  label: string;
  onSuccess?: () => void;
};

export function TimerDiscardMenuItem(props: TimerDiscardMenuItemProps): ReactElement {
  const currentTimeTracking = useCurrentTimeTracking();
  const discardTimer = useDiscardTimer(props.onSuccess);

  if (currentTimeTracking.status !== "tracking") {
    return (
      <MenuItem disabled>
        <ListItemIcon>
          <DoNotDisturb />
        </ListItemIcon>
        <ListItemText>{props.label}</ListItemText>
      </MenuItem>
    );
  } else {
    return (
      <MenuItem
        onClick={() =>
          discardTimer({ variables: { input: { timeEntryLogId: currentTimeTracking.timeEntryLogId } } })
        }
      >
        <ListItemIcon>
          <DoNotDisturb />
        </ListItemIcon>
        <ListItemText>{props.label}</ListItemText>
      </MenuItem>
    );
  }
}

function useDiscardTimer(onSuccess: (() => void) | undefined) {
  const [discardTimer, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTimeEntryLog,
    useDiscardTimerMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        if (onSuccess) {
          onSuccess();
        }
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  return discardTimer;
}
