import { Box, Grid, Paper, TextField, styled } from "@mui/material";
import { FlagId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrganizationFilter, ProviderFilter } from "GeneratedGraphQL/SchemaAndOperations";
import { CreatePatientButton } from "./CreatePatientButton";
import { useIsMobile } from "Shared/Responsive";
import {
  useQueryStringIdArrayParameter,
  useQueryStringOrganizationFilterParameter,
} from "Shared/QueryStringParameter";
import ProviderSelect from "Shared/Filters/ProviderSelect";
import OrganizationSelect from "Shared/Filters/OrganizationSelect";
import FlagMultiSelect from "Shared/Filters/FlagMultiSelect";
import { useStickyProviderFilterParameter } from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

const DEFAULT_FILTERS = {
  organization: { allOrganizations: true },
  provider: { allProviders: true },
  flagIds: null,
};

export function usePatientListFilters(): PatientListFilters {
  const [organization, setOrganization] = useQueryStringOrganizationFilterParameter(
    "organization",
    DEFAULT_FILTERS.organization
  );
  const [provider, setProvider] = useStickyProviderFilterParameter(
    STICKY_PARAMETER_NAMES.PROVIDER,
    STICKY_PARAMETER_FILTER_SETS.MBC_PATIENT_SEARCH,
    DEFAULT_FILTERS.provider
  );
  const [flagIds, setFlagIds] = useQueryStringIdArrayParameter<"Flag">("flagIds");
  // Note that we don't want search on the query string as it may contain PHI.
  const [search, setSearch] = useState<string | null>(null);

  return {
    organization: organization || DEFAULT_FILTERS.organization,
    setOrganization,
    provider: provider || DEFAULT_FILTERS.provider,
    setProvider,
    search,
    setSearch,
    flagIds,
    setFlagIds,
  };
}

export type PatientListFilters = {
  organization: OrganizationFilter;
  setOrganization: (organization: OrganizationFilter) => void;
  provider: ProviderFilter;
  setProvider: (provider: ProviderFilter) => void;
  search?: string | null;
  setSearch: (search: string | null) => void;
  flagIds: ReadonlyArray<FlagId> | null;
  setFlagIds: (flagIds: ReadonlyArray<FlagId> | null) => void;
};

type PatientListFiltersProps = {
  filters: PatientListFilters;
};
export default function PatientListFilters(props: PatientListFiltersProps): ReactElement {
  const { t } = useTranslation(["patients", "common"]);

  const rowSpacing = useIsMobile() ? 1 : 0;

  return (
    <StyledPaper>
      <Grid container rowSpacing={rowSpacing} spacing={1}>
        <StyledBox>
          <Grid container rowSpacing={rowSpacing} spacing={1}>
            <Grid item xs="auto">
              <TextField
                id="outlined-required"
                label={t("patients:patientList.search.searchClientsLabel")}
                value={props.filters.search || ""}
                onChange={(event) =>
                  props.filters.setSearch(event.target.value === "" ? null : event.target.value)
                }
                variant="outlined"
              />
            </Grid>
            <Grid item xs="auto">
              <ProviderSelect value={props.filters.provider} setValue={props.filters.setProvider} />
            </Grid>
            <Grid item xs="auto">
              <OrganizationSelect
                value={props.filters.organization}
                setValue={props.filters.setOrganization}
              />
            </Grid>
            <Grid item xs="auto">
              <FlagMultiSelect value={props.filters.flagIds} setValue={props.filters.setFlagIds} />
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item display="flex" flexGrow={1}>
          <Box sx={{ flexGrow: 1 }} />
          <CreatePatientButton />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
