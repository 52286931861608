import React, { ReactElement } from "react";
import { AcuteChangeIndicatorWithName } from "Shared/Scale/AcuteChangeIndicator";
import { TrendIndicatorWithName } from "Shared/Scale/TrendIndicator";
import { justs, last } from "Lib/Utils";
import ScaleDisplayName from "Shared/Scale/ScaleDisplayName";
import {
  CareEpisodeComputedValueDetails,
  CareEpisodeNumericalComputedValueDetails,
  switchCareEpisodeComputedValue,
  CareEpisodeCategoricalComputedValueDetails,
  CareEpisodeUnscoredComputedValueDetails,
} from "Shared/Scale/CareEpisodeComputedValueDetails";
import { Just, Maybe, Nothing } from "seidr";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { SeverityChip } from "Shared/Scale/SeverityChip";
import { styled } from "@mui/material/styles";
import { FeedbackReportRoute } from "../FeedbackReportRouting";
import * as NEL from "Lib/NonEmptyList";
import { ScaleScoreTooltip } from "Shared/Tooltip";
import * as Staleness from "Lib/Staleness";
import ParticipantAvatarAndName from "../ParticipantAvatarAndName";
import { CareEpisodeComputedValueId } from "Lib/Ids";
import { AcuteChangeCategory, Trend } from "GeneratedGraphQL/SchemaAndOperations";
import { ParticipantSummary } from "Shared/Participant";
import captureException from "Lib/ErrorHandling";
import { firstPreviousLast } from "Shared/Severity/Severity";

type SupportedTrendTypes =
  | CareEpisodeComputedValueDetails
  | CareEpisodeNumericalComputedValueDetails
  | CareEpisodeCategoricalComputedValueDetails
  | CareEpisodeUnscoredComputedValueDetails;

type TrendNarrativeProps = {
  trend: Trend;
  firstValue: SupportedTrendTypes | null;
  currentValue: SupportedTrendTypes;
  lastDate: Date;
};
function TrendNarrative(props: TrendNarrativeProps): ReactElement {
  const { trend, ...passthroughProps } = props;

  return (
    <span data-testid="trend-narrative">
      {" "}
      since treatment start, the trend is <TrendIndicatorWithName trend={trend} {...passthroughProps} />.
    </span>
  );
}

type NumericalAcuteChangeNarrativeProps = {
  score: CareEpisodeNumericalComputedValueDetails;
  previousValue: CareEpisodeNumericalComputedValueDetails;
  scaleScorerHistory: ScaleScorerHistory;
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute;
  lastDate: Date;
};

function NumericalAcuteChangeNarrative(props: NumericalAcuteChangeNarrativeProps): ReactElement {
  const { score, scaleScorerHistory, onScoreClickRoute, previousValue, lastDate } = props;

  return (
    <span data-testid="acute-change-narrative">
      {" "}
      A recent{" "}
      <AcuteChangeIndicatorWithName
        acuteChange={score.acuteChangeCategory}
        currentValue={score}
        previousValue={previousValue}
        lastDate={lastDate}
      />{" "}
      with a score of{" "}
      <ScaleScoreTooltip
        participant={scaleScorerHistory.participant}
        scale={scaleScorerHistory.scale}
        administration={score}
        isFresh={Staleness.isFresh(lastDate, score)}
      >
        <SeverityChip
          value={score}
          onClickRoute={onScoreClickRoute(score.id)}
          isStale={Staleness.isStale(lastDate, score)}
        />
      </ScaleScoreTooltip>
      .
    </span>
  );
}

type LatestScoreNarrativeProps = {
  score: CareEpisodeNumericalComputedValueDetails;
  scaleScorerHistory: ScaleScorerHistory;
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute;
  lastDate: Date;
};

function LatestScoreNarrative(props: LatestScoreNarrativeProps): ReactElement {
  const { score, scaleScorerHistory, onScoreClickRoute, lastDate } = props;

  return (
    <span data-testid="latest-score-narrative">
      The latest score was{" "}
      <ScaleScoreTooltip
        participant={scaleScorerHistory.participant}
        scale={scaleScorerHistory.scale}
        administration={score}
        isFresh={Staleness.isFresh(lastDate, score)}
      >
        <SeverityChip
          value={score}
          onClickRoute={onScoreClickRoute(score.id)}
          isStale={Staleness.isStale(lastDate, score)}
        />
      </ScaleScoreTooltip>
      .
    </span>
  );
}
const StyledParticipantWrapper = styled("div")(({ theme }) => ({
  lineHeight: "2em",
  fontSize: theme.typography.body1.fontSize,
}));

type Props = {
  history: ScaleScorerHistory;
  administrationDates: NEL.NonEmptyList<Date>;
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute;
};

// Example narrative:
//
// For Tyler, on the SFSS-Y, since treatment start, the trend
// is Improving. A recent Acute Improvement with a score of 25.

function ScaleScorerHistoryNarrative(props: Props): ReactElement {
  const { history, onScoreClickRoute, administrationDates } = props;
  const participant = history.participant;

  const lastAdministration = (history.latest as Maybe<CareEpisodeComputedValueDetails>).orElse(() =>
    last(history.scores)
  );
  const lastDate = NEL.last(administrationDates);

  const content = lastAdministration.map((score) => {
    return generateNarrativeText(lastDate, history, score, onScoreClickRoute, participant);
  });

  return content.getOrElse(
    <div data-testid="scale-scorer-history-narrative-empty-state">
      There have not yet been any <ScaleDisplayName scale={history.scale} /> administrations taken by
      <ParticipantAvatarAndName participant={participant} displayRelationship />
    </div>
  );
}

function generateNarrativeText(
  lastDate: Date,
  history: ScaleScorerHistory,
  value: CareEpisodeComputedValueDetails,
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute,
  participant: ParticipantSummary
) {
  const { first, previous } = firstPreviousLast(history.scores);
  let trend;
  switch (value.trend) {
    case Trend.IMPROVING:
    case Trend.DECLINING:
    case Trend.STABLE:
      // Technically the presence of a trend means there is definitely a first value here, but
      // the type system can't express this so just double check.
      trend = Maybe.fromNullable(first).map((intake) => {
        return (
          <TrendNarrative trend={value.trend} currentValue={value} firstValue={intake} lastDate={lastDate} />
        );
      });
      break;
    case Trend.NOT_SPECIFIED:
    case Trend.NOT_SUPPORTED:
    case Trend.INVALID:
      trend = Nothing();
  }

  const acuteChange = switchCareEpisodeComputedValue(
    value,
    (numerical) =>
      numericalNarrative(
        numerical,
        previous as CareEpisodeNumericalComputedValueDetails | null, // Type system can't infer that all of the items are the same thing.
        onScoreClickRoute,
        history,
        lastDate
      ),
    (categorical) =>
      categoricalNarrative(
        categorical,
        previous as CareEpisodeCategoricalComputedValueDetails | null, // Type system can't infer that all of the items are the same thing.
        onScoreClickRoute,
        history,
        lastDate
      ),
    (_unscored) => null,
    (_invalid) => null
  );

  const narrative = justs([trend, Just(acuteChange)]).map((n, i) => (
    <span key={i}>
      <span>{n}</span>
      <br />
    </span>
  ));

  return (
    <StyledParticipantWrapper data-testid="scale-scorer-history-narrative">
      For <ParticipantAvatarAndName participant={participant} displayRelationship />, {narrative}
    </StyledParticipantWrapper>
  );
}

function numericalNarrative(
  score: CareEpisodeNumericalComputedValueDetails,
  previousValue: CareEpisodeNumericalComputedValueDetails | null,
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute,
  history: ScaleScorerHistory,
  lastDate: Date
) {
  switch (score.acuteChangeCategory) {
    case AcuteChangeCategory.IMPROVING:
    case AcuteChangeCategory.DECLINING:
      if (!previousValue) {
        captureException(
          new Error(`Computed Value ${score.id} has trend but no previous computed value can be found`),
          "ScaleScorerHistoryNarrative"
        );
        return null;
      }
      return (
        <NumericalAcuteChangeNarrative
          score={score}
          onScoreClickRoute={onScoreClickRoute}
          scaleScorerHistory={history}
          previousValue={previousValue}
          lastDate={lastDate}
        />
      );
    default:
      return (
        <div>
          <LatestScoreNarrative
            lastDate={lastDate}
            score={score}
            onScoreClickRoute={onScoreClickRoute}
            scaleScorerHistory={history}
          />
        </div>
      );
  }
}

type CategoricalAcuteChangeNarrativeProps = {
  score: CareEpisodeCategoricalComputedValueDetails;
  previousValue: CareEpisodeCategoricalComputedValueDetails | null;
  scaleScorerHistory: ScaleScorerHistory;
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute;
  lastDate: Date;
};

function CategoricalAcuteChangeNarrative(props: CategoricalAcuteChangeNarrativeProps): ReactElement | null {
  const { score, scaleScorerHistory, onScoreClickRoute, lastDate, previousValue } = props;

  if (!previousValue) {
    captureException(
      new Error(`Computed Value ${score.id} has trend but no previous computed value can be found`),
      "ScaleScorerHistoryNarrative"
    );
    return null;
  }

  return (
    <span data-testid="acute-change-narrative">
      {" "}
      A recent{" "}
      <AcuteChangeIndicatorWithName
        acuteChange={score.acuteChangeCategory}
        currentValue={score}
        lastDate={lastDate}
        previousValue={previousValue}
      />{" "}
      with a score of{" "}
      <ScaleScoreTooltip
        participant={scaleScorerHistory.participant}
        scale={scaleScorerHistory.scale}
        administration={score}
        isFresh={Staleness.isFresh(lastDate, score)}
      >
        <SeverityChip
          value={score}
          onClickRoute={onScoreClickRoute(score.id)}
          isStale={Staleness.isStale(lastDate, score)}
        />
      </ScaleScoreTooltip>
      .
    </span>
  );
}

type CategoricalNarrativeProps = {
  score: CareEpisodeCategoricalComputedValueDetails;
  scaleScorerHistory: ScaleScorerHistory;
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute;
  lastDate: Date;
};

function CategoricalNarrative(props: CategoricalNarrativeProps): ReactElement {
  const { score, scaleScorerHistory, onScoreClickRoute, lastDate } = props;

  return (
    <span data-testid="latest-score-narrative">
      The latest result was{" "}
      <ScaleScoreTooltip
        participant={scaleScorerHistory.participant}
        scale={scaleScorerHistory.scale}
        administration={score}
        isFresh={Staleness.isFresh(lastDate, score)}
      >
        <SeverityChip
          value={score}
          onClickRoute={onScoreClickRoute(score.id)}
          isStale={Staleness.isStale(lastDate, score)}
        />
      </ScaleScoreTooltip>
      .
    </span>
  );
}

function categoricalNarrative(
  score: CareEpisodeCategoricalComputedValueDetails,
  previousValue: CareEpisodeCategoricalComputedValueDetails | null,
  onScoreClickRoute: (sId: CareEpisodeComputedValueId) => FeedbackReportRoute,
  history: ScaleScorerHistory,
  lastDate: Date
) {
  let acuteChange;
  switch (score.acuteChangeCategory) {
    case AcuteChangeCategory.IMPROVING:
    case AcuteChangeCategory.DECLINING:
      acuteChange = (
        <CategoricalAcuteChangeNarrative
          score={score}
          onScoreClickRoute={onScoreClickRoute}
          scaleScorerHistory={history}
          lastDate={lastDate}
          previousValue={previousValue}
        />
      );
      break;
    default:
      acuteChange = (
        <div>
          <CategoricalNarrative
            score={score}
            onScoreClickRoute={onScoreClickRoute}
            scaleScorerHistory={history}
            lastDate={lastDate}
          />
        </div>
      );
  }
  return acuteChange;
}

export default ScaleScorerHistoryNarrative;
