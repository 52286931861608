import React from "react";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import { DetailsWrapper } from "../DetailsWrapper";
import AlertHistoryDetailPane from "./AlertHistoryDetailPane";
import * as Routing from "../FeedbackReportRouting";
import { FeedbackReportContext } from "../FeedbackReportContext";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";

type AlertHistoryProps = {
  feedbackReportContext: FeedbackReportContext;
};

export const AlertHistoryRoute = (props: AlertHistoryProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{ alertHistoryId?: string }>();

  const alertHistoryId = Id.fromNullableString<"CareEpisodeScale">(params.alertHistoryId);

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [t("report:breadcrumbs.riskHistory")],
  ];

  return alertHistoryId.caseOf({
    Err: (error) => <ErrorMessage message={error.message} />,
    Ok: (alertHistoryId) => (
      <DetailsWrapper breadcrumbs={breadcrumbs}>
        <AlertHistoryDetailPane
          alertHistoryId={alertHistoryId}
          feedbackReportContext={props.feedbackReportContext}
        />
      </DetailsWrapper>
    ),
  });
};
