import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { CreateTaskLaterAction } from "./Actions/CreateTaskLaterAction";
import { StartTaskNowAction } from "./Actions/StartTaskNowAction";
import { useTranslation } from "react-i18next";
import { useCurrentProviderId } from "AppSession/AppSession";
import { DismissAction } from "./Actions/DismissAction";
import Link from "MDS/Link";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { InboxMessagesFindingDatagram } from "../InboxMessagesFindings";

type AssessmentNeedsReviewFindingProps = {
  finding: InboxMessagesFindingDatagram;
};
export function AssessmentNeedsReviewFinding(props: AssessmentNeedsReviewFindingProps): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const currentProviderId = useCurrentProviderId();

  if (!props.finding.patient || !currentProviderId) {
    return null;
  }

  // This is just part of a common payload for our actions, so we might
  // as well define it here.
  const task = {
    title: t("collaborativeCare:findings.findings.assessmentNeedsReview.title"),
    body: `[${props.finding.patient.name}](/app/cocm/patient/${props.finding.patient.id}) ${t(
      "collaborativeCare:findings.findings.assessmentNeedsReview.body"
    )}`,
    dueAt: new Date(),
    patientId: props.finding.patient.id,
    assignedToId: currentProviderId,
  };

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:findings.findings.assessmentNeedsReview.title")} />
      <CardContent>
        <Stack direction="row" spacing={0.5} alignItems="center" useFlexGap>
          <PatientHeaderStatus
            patientId={props.finding.patient.id}
            patientName={props.finding.patient.name}
          />
          <span>
            <Typography variant="h2" component="span">
              <Link to={`/app/cocm/patient/${props.finding.patient.id}`}>{props.finding.patient.name}</Link>{" "}
            </Typography>
            {t("collaborativeCare:findings.findings.assessmentNeedsReview.body")}
          </span>
        </Stack>
      </CardContent>
      <CardActions>
        <StartTaskNowAction
          finding={props.finding}
          {...task}
          buttonText={t("collaborativeCare:findings.findings.assessmentNeedsReview.startTaskNow")}
          redirectOnSuccess={`/app/care-units/${props.finding.patient.id}/report`}
        />
        <CreateTaskLaterAction finding={props.finding} {...task} />
        <DismissAction finding={props.finding} />
      </CardActions>
    </Card>
  );
}
