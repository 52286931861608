import { WithFeature, WithOutFeature } from "Contexts/CurrentInstituteContext";
import { CareEpisodeId, PatientId, ReportId } from "Lib/Ids";
import { Link, LinkButton, LinkButtonProps } from "MDS/Link";
import React, { ReactElement } from "react";

// We want to be able to pass through props to the underlying link component, but not anything that would override the
// url settings that we're applying in this component.
type NonNavigationLinkProps = { children?: React.ReactNode };

type Props = NonNavigationLinkProps & {
  // Patients and Care Units are secretly the same thing, just handle whatever we have.
  patient: PatientId;
  careEpisode?: CareEpisodeId;
  report?: ReportId;
};

function efrRoute(patient: PatientId, careEpisode?: CareEpisodeId) {
  if (careEpisode) {
    return `/app/care-units/${patient}/care-episodes/${careEpisode}/feedback-report`;
  } else {
    return `/app/care-units/${patient}/report`;
  }
}

function emberReportRoute(patient: PatientId, report?: ReportId) {
  const base = `/provider/patients/${patient}/report-v2`;

  if (report) {
    return `${base}?activeReportId=${report}`;
  } else {
    return base;
  }
}

// Given a patient id and optionally a care episode id, renders a link to the appropriate report based on how the
// institute is configured. Without the care episode id institutes using the EFR will get linked to the latest care
// episode - supply the care episode id if you want to avoid a query+redirect or you want to link to the report for
// a different care episode. Institutes using the ember report can take a report id to link to a particular date. This
// parameter will be ignored for institutes using the EFR.
export function ContextualReportLink(props: React.PropsWithChildren<Props>): ReactElement {
  const { patient, careEpisode, report, ...linkProps } = props;

  return (
    <>
      <WithFeature feature="enableMeasurementDimensions">
        <Link to={efrRoute(patient, careEpisode)} data-testid="contextual-report-link" {...linkProps}>
          {props.children}
        </Link>
      </WithFeature>
      <WithOutFeature feature="enableMeasurementDimensions">
        <Link to={emberReportRoute(patient, report)} data-testid="contextual-report-link" {...linkProps}>
          {props.children}
        </Link>
      </WithOutFeature>
    </>
  );
}

export function ContextualReportButton(
  props: React.PropsWithChildren<Props> & Omit<LinkButtonProps, "to">
): ReactElement {
  const { patient, careEpisode, report, ...linkProps } = props;

  return (
    <>
      <WithFeature feature="enableMeasurementDimensions">
        <LinkButton to={efrRoute(patient, careEpisode)} data-testid="contextual-report-link" {...linkProps}>
          {props.children}
        </LinkButton>
      </WithFeature>
      <WithOutFeature feature="enableMeasurementDimensions">
        <LinkButton
          to={emberReportRoute(patient, report)}
          data-testid="contextual-report-link"
          {...linkProps}
        >
          {props.children}
        </LinkButton>
      </WithOutFeature>
    </>
  );
}
