import { useEffectSimpleCompare } from "Lib/Hooks";
import { useLocation } from "react-router-dom";

// Purely effectful —  should be refactored to not be a component?
function RedirectToEmber(): null {
  const location = useLocation();
  useEffectSimpleCompare(() => {
    window.location.href = location.pathname + location.search;
  }, [location.pathname, location.search]);

  return null;
}

export default RedirectToEmber;
