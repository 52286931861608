import { Table } from "@mui/material";
import { styled } from "@mui/material/styles";

/* A table for displaying a single object's properties, rather than a list of records. Instead of bolding the <th> row
 * bold the first column, since that's where the property names are.
 */
export const PropertyTable = styled(Table)(() => ({
  // The very long selector here is so that we don't also pick up tds in nested tables.
  "& > tbody > tr > td:first-of-type": {
    fontWeight: "bold",
  },
}));
