import { Flag } from "@mui/icons-material";
import { IconButton, Menu, Switch, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { WithPermission } from "Shared/WithPermission";
import React, { ReactElement, ReactNode } from "react";
import CurrentInstituteContext, { AllowedFeatures, useFeatureSet } from "./CurrentInstituteContext";
import { frontendFlagStorageKey } from "Shared/Storage";

/**
 * This is a lightweight frontend-first feature flag system. To add a new feature flag, do the following:
 *
 *     // exploding-declaration 2030-01-01
 *     export const EXAMPLE_FLAG = "example"
 *
 *     const FLAGS = [
 *        // Any flags that already exist...
 *        EXAMPLE_FLAG
 *     ]
 *
 * You can then wrap any component in
 *
 *     import { EXAMPLE_FLAG, WithFrontendFlag } from "Contexts/FrontendFlagContext"
 *
 *     <WithFrontendFlag flagName={EXAMPLE_FLAG}>
 *       {...}
 *     </WithFrontendFlag>
 *
 * to have have it only show when the flag is enabled. To enable a flag, log into the app as an internal user and use
 * the internal-only flag menu in the nav bar to turn flags on and off. Flag states will persist on tab refresh, but
 * will reset on closing a tab and making a new one.
 *
 * If a frontend flag has the same name as a feature flag, the frontend flag will be considered to be permanently
 * enabled if the feature flag is enabled. If the feature flag is disabled, the frontend flag can be toggled on or off
 * as normal. Note that toggling a frontend flag with the same name as a feature flag on does not change the feature
 * flag's state on the backend. The goal of this behavior is to allow us to smooth out the process of rolling out a
 * feature that was developed behind a frontend flag - we can start having it be internal only with a frontend flag,
 * then create a backend flag for specific environments like qa and demo, then enable it on select customers, then
 * remove the flag entirely and enable it for everyone.
 *
 * The exploding declaration will make the build fail with lint errors if the flag is still present on that date - we
 * don't have a lint rule that forces you to include an exploding comment, but we should use code review to ensure that
 * we use them. These flags should be relatively short lived so that we don't end up with dozens of them.
 */

// exploding-declaration 2026-01-01
export const MBC_REDESIGN_FLAG = "enableMbcRedesign";

// exploding-declaration 2025-07-01
export const DATA_TABLE_RESET_EXPORT = "Data Table Reset & Export";

// exploding-declaration 2025-07-01
export const IMPLEMENTATION_TARGETS_FLAG = "enableImplementationTargets";

// exploding-declaration 2025-04-01
export const TIMELINE_FLAG = "timeline";

// exploding-declaration 2025-05-01
export const INBOX_MESSAGES_FINDINGS_FLAG = "Display and use Inbox Messages Findings";

const FLAGS: ReadonlyArray<string> = [
  MBC_REDESIGN_FLAG,
  DATA_TABLE_RESET_EXPORT,
  IMPLEMENTATION_TARGETS_FLAG,
  TIMELINE_FLAG,
  INBOX_MESSAGES_FINDINGS_FLAG,
];

type FrontendFlagsEnabled = {
  flags: ReadonlyArray<{ name: string; enabled: boolean }>;
  setFlag: (flag: string, value: boolean) => void;
};

export const FrontendFlagContext: React.Context<FrontendFlagsEnabled> = React.createContext({
  flags: getFlagEnablement(),
  setFlag: (_flag: string, _value: boolean) => {
    return;
  },
});

function getFlagEnablement(): FrontendFlagsEnabled["flags"] {
  return FLAGS.map((flag) => {
    return {
      name: flag,
      enabled: sessionStorage.getItem(frontendFlagStorageKey(flag)) === "true" || false,
    };
  });
}

export function FrontendFlagContextProvider(props: { children: ReactNode }): ReactElement {
  const [flagEnablement, setFlagEnablement] = React.useState<FrontendFlagsEnabled["flags"]>(
    getFlagEnablement()
  );
  const setFlag = (name: string, value: boolean) => {
    sessionStorage.setItem(frontendFlagStorageKey(name), value.toString());

    // We have to make a new list and update it in place like this so that
    // 1) It's a new reference and setState recognizes the update
    // 2) The items in the list are in the same order so they don't jump around in the menu
    const newFlags = [...flagEnablement];
    for (const flag of newFlags) {
      if (flag.name === name) {
        flag.enabled = value;
      }
    }

    setFlagEnablement(newFlags);
  };

  return (
    <FrontendFlagContext.Provider value={{ flags: flagEnablement, setFlag: setFlag }}>
      {props.children}
    </FrontendFlagContext.Provider>
  );
}

export function WithFrontendFlag(props: React.PropsWithChildren<{ flagName: string }>): ReactElement | null {
  const enabled = useIsFrontendFlagEnabled(props.flagName);

  return enabled ? <>{props.children}</> : null;
}

export function WithoutFrontendFlag(
  props: React.PropsWithChildren<{ flagName: string }>
): ReactElement | null {
  const enabled = useIsFrontendFlagEnabled(props.flagName);

  return enabled ? null : <>{props.children}</>;
}

export function useIsFrontendFlagEnabled(flagName: string) {
  const flag = React.useContext(FrontendFlagContext).flags.filter((flag) => flag.name === flagName)[0];
  // This gets a FeatureSet | false (depending on if we've logged in or not) and then if it's false we simply
  // consider all features to be off. This lets us act like we're checking features even if this gets called while
  // the user isn't logged into the app.
  const features = React.useContext(CurrentInstituteContext)
    .map((institute) => institute.featureSet)
    .getOrElse(false);

  if (!flag) {
    return false;
  }

  if (flag.enabled) {
    return true;
  }

  if (features && flag.name in features) {
    return features[flag.name as AllowedFeatures];
  }

  return flag.enabled;
}

export function FrontendFlagsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Local developer providers can simply acquire this permission via
  // the Developer user role.
  return (
    <WithPermission permission="viewFrontendFlags">
      <IconButton onClick={handleClick}>
        <Flag />
      </IconButton>
      <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
        <FrontendFlagToggles />
      </Menu>
    </WithPermission>
  );
}

function FrontendFlagToggles() {
  const flags = React.useContext(FrontendFlagContext);

  const featureList = Object.entries(useFeatureSet());
  const enabledFeatures = featureList.filter((feature) => feature[1] === true);
  const availableFlags = flags.flags.filter(
    (flag) => enabledFeatures.findIndex((feature) => feature[0] === flag.name) === -1
  );

  const noFlagsMessage =
    availableFlags.length == 0 ? (
      <Typography variant="caption" padding={2} display="inline-block">
        No active frontend flags
      </Typography>
    ) : null;

  return (
    <Table>
      <TableBody>
        {availableFlags.map((flag) => {
          return (
            <TableRow key={flag.name}>
              <TableCell>{flag.name}</TableCell>
              <TableCell>
                <Switch
                  checked={flag.enabled}
                  onChange={(_event, checked) => flags.setFlag(flag.name, checked)}
                />
              </TableCell>
            </TableRow>
          );
        })}
        {enabledFeatures.map((feature) => {
          return (
            <TableRow key={feature[0]}>
              <TableCell>{feature[0]}</TableCell>
              <TableCell>
                <Switch checked={true} disabled />
              </TableCell>
            </TableRow>
          );
        })}
        {noFlagsMessage}
      </TableBody>
    </Table>
  );
}
