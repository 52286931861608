import { Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { careEpisodeInferenceReasonDescriptionT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisodeInferenceReason,
  DataSourceRecordAlgorithmLog,
  FieldSourceEnum,
  useAppointmentAlgorithmDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import Link from "MDS/Link";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import DecisionTable, { AlgorithmDecisionResult, DecisionTableSection } from "Shared/DecisionTable";
import Spinner from "Shared/Spinner";
import { resources } from "i18n";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

const sourceList: Record<
  FieldSourceEnum,
  `patients:appointments.careEpisodeSelection.${keyof (typeof resources)["en"]["patients"]["appointments"]["careEpisodeSelection"]}`
> = {
  [FieldSourceEnum.ALGORITHM]: "patients:appointments.careEpisodeSelection.algorithmSelected",
  [FieldSourceEnum.EHR]: "patients:appointments.careEpisodeSelection.ehrSelected",
  [FieldSourceEnum.MANUAL]: "patients:appointments.careEpisodeSelection.manuallySelected",
  [FieldSourceEnum.UNKNOWN]: "patients:appointments.careEpisodeSelection.ehrSelected",
};

function MoreInfoWithLink(props: { patientId: PatientId; appointmentId: AppointmentId }) {
  const { t } = useTranslation(["patients"]);

  return (
    <Typography>
      {t("patients:appointments.careEpisodeSelection.moreInfo")}{" "}
      <Link to={`/app/patients/${props.patientId}/appointments/${props.appointmentId}#careEpisodeMatching`}>
        {t("patients:appointments.careEpisodeSelection.appointmentDetailsPage")}
      </Link>
    </Typography>
  );
}

export function AppointmentCareEpisodeAlgorithmTable(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentAlgorithmDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.schedulingAppointment) {
        return (
          <CareEpisodeMissingReasonInner
            {...props}
            careEpisodeInferenceReason={data.schedulingAppointment.inferredCareEpisodeReason}
            hideUnknown={props.hideUnknown}
            careEpisodeSource={data.schedulingAppointment.careEpisodeSource}
            lastCareEpisodeCreationAlgorithm={
              data.schedulingAppointment.lastActiveCareEpisodeCreationAlgorithm ||
              data.schedulingAppointment.lastCareEpisodeCreationAlgorithm
            }
            careEpisodeExists={!!data.schedulingAppointment.careEpisode?.id}
          />
        );
      } else {
        return null;
      }
    },
    _: () => <Spinner />,
  });
}

// CREATING THE DECISION LOG
//
// The failure reasons are ORDERED coming out of the back end - you will always fail missing provider first.
// Therefore we give check marks to everything before the algorithm status
// We only want to show the relevant reasons

const failureReasonList: ReadonlyArray<
  [
    string,
    `patients:appointments.careEpisodeSelection.${keyof (typeof resources)["en"]["patients"]["appointments"]["careEpisodeSelection"]}`
  ]
> = [
  ["missing_provider", "patients:appointments.careEpisodeSelection.missingProvider"],
  ["no_matching_treatment_service", "patients:appointments.careEpisodeSelection.noMatchingTreatmentService"],
  ["matched_existing", "patients:appointments.careEpisodeSelection.matchedExisting"],
  ["automatic_creation_disabled", "patients:appointments.careEpisodeSelection.automaticCreationDisabled"],
  ["missing_organization", "patients:appointments.careEpisodeSelection.missingOrganization"],
];

const failureReasonListNoTreatmentService: ReadonlyArray<
  [
    string,
    `patients:appointments.careEpisodeSelection.${keyof (typeof resources)["en"]["patients"]["appointments"]["careEpisodeSelection"]}`
  ]
> = [
  ["missing_provider", "patients:appointments.careEpisodeSelection.missingProvider"],
  ["matched_existing", "patients:appointments.careEpisodeSelection.matchedExisting"],
  ["automatic_creation_disabled", "patients:appointments.careEpisodeSelection.automaticCreationDisabled"],
  ["missing_organization", "patients:appointments.careEpisodeSelection.missingOrganization"],
];

/**
 * Construct a decision tree explaining why the appointment chose this episode of care.
 * If the episode of care was set manually or by EHR, just report that. If the algorithm was used
 * then display the decision tree on why that happened.
 */
function CareEpisodeMissingReasonInner(props: {
  careEpisodeSource: FieldSourceEnum;
  lastCareEpisodeCreationAlgorithm: Pick<DataSourceRecordAlgorithmLog, "algorithm" | "statusName"> | null;
  patientId: PatientId;
  appointmentId: AppointmentId;
  careEpisodeExists: boolean;
  careEpisodeInferenceReason: CareEpisodeInferenceReason | null;
  hideUnknown?: boolean;
}) {
  const { t } = useTranslation(["patients", "enums"]);
  const enableTreatmentServices = useWithFeatureEnabled("enableTreatmentServices");

  const bottomElement = props.hideUnknown ? (
    <MoreInfoWithLink appointmentId={props.appointmentId} patientId={props.patientId} />
  ) : null;

  // For anything except algorithmic details, we just return saying what happened.
  if (props.careEpisodeSource !== FieldSourceEnum.ALGORITHM) {
    return (
      <DecisionTable
        hideUnknown={props.hideUnknown}
        sections={[
          {
            topElement: t(sourceList[props.careEpisodeSource]),
            rows: [],
            bottomElement,
          },
        ]}
      />
    );
  }

  const algo = props.lastCareEpisodeCreationAlgorithm;

  if (!algo) {
    return t("patients:appointments.careEpisodeSelection.algorithmNotRun");
  }

  const reasonsToUse = enableTreatmentServices ? failureReasonList : failureReasonListNoTreatmentService;

  const successIndex = props.careEpisodeExists
    ? reasonsToUse.length + 1
    : reasonsToUse.findIndex((row) => {
        return row[0] === algo.statusName;
      });

  if (successIndex === -1) {
    return t("patients:appointments.careEpisodeSelection.algorithmNotRun");
  }

  const rows = reasonsToUse.map((reason, index) => {
    return {
      description: t(reason[1]),
      result:
        index < successIndex
          ? AlgorithmDecisionResult.SUCCESS
          : index === successIndex
          ? AlgorithmDecisionResult.FAILURE
          : AlgorithmDecisionResult.UNKNOWN,
    };
  });

  const sections: ReadonlyArray<DecisionTableSection> = [
    {
      topElement: props.careEpisodeExists
        ? t("patients:appointments.careEpisodeSelection.algorithmSelected")
        : t("patients:appointments.careEpisodeSelection.missingText"),
      rows,
      bottomElement,
    },
  ];

  const inference = props.careEpisodeInferenceReason ? (
    <>
      <Typography>
        <b>{t("patients:appointments.careEpisodeSelection.whyChosen")}</b>
      </Typography>
      <Typography>{careEpisodeInferenceReasonDescriptionT(props.careEpisodeInferenceReason, t)}</Typography>
    </>
  ) : null;

  return (
    <>
      <DecisionTable hideUnknown={props.hideUnknown} sections={sections} />
      {inference}
    </>
  );
}

export function AppointmentCareEpisodeAlgorithmTooltip(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
  children: ReactElement;
}) {
  return (
    <TooltipBase
      content={
        <AppointmentCareEpisodeAlgorithmTable
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={props.hideUnknown}
        />
      }
    >
      {props.children}
    </TooltipBase>
  );
}
