import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiTable"] = {
  root: {
    // Remove the bottom border on the last row. Especially important when we nest tables.
    "tr:last-child > td, tr:last-child > th": {
      border: "none",
    },
  },
};

const override = {
  styleOverrides,
};

export default override;
