import { Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { LazyCompactTaskCard } from "CollaborativeCare/CareManagerDashboard/BetterTaskList/CompactTaskCard";
import { OpenQuickAddTaskForm } from "CollaborativeCare/CareManagerDashboard/BetterTaskList/QuickAddTaskForm";
import { Patient, Task, TaskStatus, useTaskSearchQuery } from "GeneratedGraphQL/SchemaAndOperations";
import * as Paginator from "Shared/Paginator";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type PostIntakeTaskListProps = {
  patient: PickTypename<Patient, "id" | "name">;
  onFinish: () => void;
};

export const MAX_TASKS = 20;

export function PostIntakeTaskList(props: PostIntakeTaskListProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [pagination] = React.useState<Paginator.QueryPage>(Paginator.initPage(MAX_TASKS));

  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return <></>;
  }
  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return <></>;
  }

  let tasks: ReadonlyArray<PickTypename<Task, "id">> = [];
  const { remoteData } = apolloQueryHookWrapper(
    useTaskSearchQuery({
      variables: {
        search: null,
        status: TaskStatus.ACTIVE,
        sortBy: null,
        sortDirection: null,
        assignedTo: null,
        patientId: [props.patient.id],
        ...pagination,
      },
    })
  );

  remoteData.caseOf({
    NotAsked: () => null,
    Loading: () => null,
    Failure: () => null,
    Success: (response) => {
      if (response.collaborativeCareTasks) {
        tasks = response.collaborativeCareTasks.nodes;
      }
    },
  });

  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={1}>
          <SuccessText>
            {t("collaborativeCare:enrollment.addTasksPrompt", { patient: props.patient.name })}
          </SuccessText>
          {tasks.map((task, i) => {
            return <LazyCompactTaskCard key={i} taskId={task.id} inPatientContext={true} onPaper />;
          })}
          <OpenQuickAddTaskForm defaultPatient={props.patient} hidePatientField={true} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={props.onFinish}>
          {t("collaborativeCare:enrollment.actions.finish")}
        </Button>
      </DialogActions>
    </>
  );
}

const SuccessText = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));
