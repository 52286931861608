import {
  OrganizationFilter,
  OrganizationSelectQueryVariables,
  useOrganizationSelectLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId } from "Lib/Ids";
import { oks } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";
import { ParameterSerializer } from "Shared/QueryStringParameter";
import * as Id from "Lib/Id";
import { TFunction } from "i18next";

type OrganizationSelectProps = {
  value: OrganizationFilter | null;
  setValue: (newValue: OrganizationFilter | null) => void;
  defaultValue?: OrganizationFilter | null;
};

const defaultValue: OrganizationFilter = {
  myOrganizations: true,
};

// Serializes and deserializes the filter from a string, to be used in query string.
export function organizationFilterSerializer(
  defaultValue: OrganizationFilter | null
): ParameterSerializer<OrganizationFilter | null> {
  return {
    serialize: (x: OrganizationFilter | null) => {
      if (x?.allOrganizations) {
        return "all";
      }

      if (x?.myOrganizations) {
        return "mine";
      }

      if (x?.organizationIds) {
        return x.organizationIds.join(",");
      }

      if (x === defaultValue) {
        return null;
      }

      return "";
    },
    deserialize: (str: string | null) => {
      // explicitly serialize empty string as null
      if (str === "") {
        return null;
      }

      if (str === "all") {
        return {
          allOrganizations: true,
        };
      }

      if (str === "mine") {
        return {
          myOrganizations: true,
        };
      }

      if (!str) {
        return defaultValue;
      }

      const uuids = oks(
        str.split(",").map((idString) => {
          return Id.fromNullableString<"Organization">(idString);
        })
      );

      if (uuids.length > 0) {
        return {
          organizationIds: uuids,
        };
      }

      return defaultValue;
    },
  };
}

// We need to translate the union type into the graphql input type
function inputToSelect(value: SelectOptionType | null): OrganizationFilter | null {
  if (!value) {
    return null;
  }

  if (value.id === "all") {
    return {
      allOrganizations: true,
    };
  }

  if (value.id === "mine") {
    return {
      myOrganizations: true,
    };
  }

  return {
    organizationIds: [value.id],
  };
}

// Note that as long as we have the ids right here, the ignored text will be substituted in the component.
function selectToValue(value: OrganizationFilter | null, t: TFunction<["common"]>): SelectOptionType | null {
  if (!value) {
    return null;
  }

  if (value.allOrganizations !== undefined) {
    return { id: "all", name: t("common:organizations.all") };
  }

  if (value.myOrganizations !== undefined) {
    return { id: "mine", name: t("common:organizations.mine") };
  }

  if (value.organizationIds[0]) {
    return { id: value.organizationIds[0], name: "Loading.." };
  }

  return null;
}

type SelectOptionType = { id: OrganizationId | "mine" | "all"; name: string };

export default function OrganizationSelect(props: OrganizationSelectProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { value, setValue } = props;
  const queryVars: Omit<OrganizationSelectQueryVariables, "search"> = {
    first: 30,
  };

  const fixed: ReadonlyArray<SelectOptionType> = [
    { name: t("common:organizations.mine"), id: "mine" },
    { name: t("common:organizations.all"), id: "all" },
  ];
  const highlight = typeof props.defaultValue !== "undefined" && props.defaultValue !== value;

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => setValue(inputToSelect(value))}
      error={false}
      required={false}
      helperText=""
      value={selectToValue(value, t)}
      queryVariables={queryVars}
      query={useOrganizationSelectLazyQuery}
      fixedOptions={fixed}
      unwrapResponse={(response) => response.organizations?.nodes}
      valueEqual={(left, right) => {
        return left.id === right.id;
      }}
      label={t("common:organizations.title")}
      autocompleteProps={{
        noOptionsText: t("common:organizations.noMatching"),
        getOptionLabel: (option) => option.name,
      }}
      highlight={highlight}
    />
  );
}

export { defaultValue };
