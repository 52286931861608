import React, { ReactElement } from "react";
import { truncate } from "Lib/Utils";
import { ScaleScoreWithHistoryTooltip } from "Shared/Tooltip";
import { scaleFriendlyName } from "./Scale";
import { ScaleScorerHistory } from "./ScaleScorerHistory";
import * as NEL from "Lib/NonEmptyList";
import { Scale } from "GeneratedGraphQL/SchemaAndOperations";

type Props = {
  // TODO: All these props should not be optional!
  fullWidth?: boolean;
  history?: ScaleScorerHistory;
  administrationDates?: NEL.NonEmptyList<Date>;
  scale?: Pick<Scale, "name" | "shortname" | "nanoname" | "friendlyName">;
};

function ScaleDisplayName(props: Props): ReactElement {
  const { history, administrationDates, scale, fullWidth } = props;

  if (history && administrationDates) {
    const displayName =
      fullWidth === true ? scaleFriendlyName(history.scale) : truncate(15, scaleFriendlyName(history.scale));
    return (
      <ScaleScoreWithHistoryTooltip history={history} administrationDates={administrationDates}>
        <strong>{displayName}</strong>
      </ScaleScoreWithHistoryTooltip>
    );
  }
  if (scale) {
    const displayName =
      fullWidth === true ? scaleFriendlyName(scale) : truncate(15, scaleFriendlyName(scale));
    return <strong>{displayName}</strong>;
  }
  // TODO: All these props should not be optional!
  return <strong>LIES!</strong>;
}

export default ScaleDisplayName;
