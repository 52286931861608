import {
  CareEpisodeProviderRelationship,
  Provider,
  ProviderSelectQueryVariables,
  ProvidersForOrganizationQueryVariables,
  useProviderSelectLazyQuery,
  useProvidersForOrganizationLazyQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId, ProviderId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { getEnumItems } from "Shared/Enum";
import { OverridableAutocompleteProps, QueryAutocompleteSingle } from "Shared/QueryAutocomplete";

import { PickTypename } from "type-utils";

type ProviderSummary = PickTypename<Provider, "id" | "name">;

type ProviderSelectProps = {
  value: ProviderId | null;
  setValue: (newValue: ProviderId | null) => void;
  organizationId?: OrganizationId | null;
  defaultRelationship?: ReadonlyArray<CareEpisodeProviderRelationship>;
  autocompleteProps?: OverridableAutocompleteProps<ProviderSummary, false>;
  label?: string;
  error?: boolean;
  helperText?: string | null;
  defaultValue?: ProviderId | null;
};

type ProviderSelectWithOrgProps = {
  value: ProviderId | null;
  setValue: (newValue: ProviderId | null) => void;
  organizationId: OrganizationId;
  defaultRelationship?: ReadonlyArray<CareEpisodeProviderRelationship>;
  autocompleteProps?: OverridableAutocompleteProps<ProviderSummary, false>;
  label?: string;
  error?: boolean;
  helperText?: string | null;
  defaultValue?: ProviderId | null;
};

type ProviderSelectNoOrgProps = Omit<ProviderSelectProps, "organizationId">;

/// Note that as long as we have the ids right here, the ignored text will be substituted in the component.
function selectToValue(value: ProviderId | null): ProviderSummary | null {
  if (!value) {
    return null;
  } else {
    return { id: value, name: "Loading", __typename: "Provider" };
  }
}

// This is slightly funky because the different queries need different unwrapping etc.
function ProviderSelectWithOrg(props: ProviderSelectWithOrgProps) {
  const { t } = useTranslation(["common"]);
  const { value, setValue } = props;
  const queryVars: Omit<ProvidersForOrganizationQueryVariables, "search"> = {
    first: 30,
    organizationId: props.organizationId,
    defaultRelationship: props.defaultRelationship || null,
  };

  const highlight = typeof props.defaultValue !== "undefined" && props.defaultValue !== value;

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => setValue(value ? value.id : null)}
      key={props.organizationId.toString()} // If the org id changes we need to completely change the render
      error={props.error}
      required={false}
      helperText={props.helperText}
      value={selectToValue(value)}
      queryVariables={queryVars}
      query={useProvidersForOrganizationLazyQuery}
      unwrapResponse={(response) => response.organization?.practicingAttachedProviders.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={props.label ? props.label : t("common:providers.title")}
      highlight={highlight}
      autocompleteProps={{
        ...props.autocompleteProps,
        noOptionsText: t("common:providers.noMatching"),
        getOptionLabel: (option) => option.name,
      }}
    />
  );
}

// This is slightly funky because the different queries need different unwrapping etc.
function ProviderSelectNoOrg(props: ProviderSelectNoOrgProps) {
  const { t } = useTranslation(["common"]);
  const { value, setValue } = props;
  const queryVars: Omit<ProviderSelectQueryVariables, "search"> = {
    first: 30,
    defaultRelationship: props.defaultRelationship || null,
  };

  const highlight = typeof props.defaultValue !== "undefined" && props.defaultValue !== value;

  return (
    <QueryAutocompleteSingle
      valueUpdated={(value) => setValue(value ? value.id : null)}
      error={props.error}
      required={false}
      helperText={props.helperText}
      value={selectToValue(value)}
      queryVariables={queryVars}
      query={useProviderSelectLazyQuery}
      unwrapResponse={(response) => response.providers?.nodes}
      valueEqual={(left, right) => left.id === right.id}
      label={props.label ? props.label : t("common:providers.title")}
      highlight={highlight}
      autocompleteProps={{
        ...props.autocompleteProps,
        noOptionsText: t("common:providers.noMatching"),
        getOptionLabel: (option) => option.name,
      }}
    />
  );
}

// Select everyone who isn't a care manager rather than everyone who is a pcp, on the theory that the default
// value for this field is "clinician" and it seems likely that people who are broadly considered pcps will end up
// with that as their default relationship.
const everyoneExceptCareManagers = getEnumItems(CareEpisodeProviderRelationship).filter(
  (relationship) => relationship != CareEpisodeProviderRelationship.CARE_MANAGER
);

/**
 * Selects a primary care doctor. Use this in preference to PrimaryCareProviderSelect if the graphql endpoint is for a ProviderId, rather than a ProviderFilter
 */
export function PrimaryCareProviderSelectSingle(
  props: Omit<ProviderSelectProps, "defaultRelationships">
): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const label = props.label || t("collaborativeCare:fields.pcp.label");

  return <ProviderSelectSingle {...props} label={label} defaultRelationship={everyoneExceptCareManagers} />;
}

/**
 * Selects a care manager. Use this in preference to PrimaryCareProviderSelect if the graphql endpoint is for a ProviderId, rather than a ProviderFilter
 */
export function CareManagerSelectSingle(
  props: Omit<ProviderSelectProps, "defaultRelationships">
): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const label = props.label || t("collaborativeCare:patientList.search.careManagerLabel");
  return (
    <ProviderSelectSingle
      {...props}
      label={label}
      defaultRelationship={[CareEpisodeProviderRelationship.CARE_MANAGER]}
    />
  );
}

export default function ProviderSelectSingle(props: ProviderSelectProps): ReactElement {
  const { organizationId } = props;
  if (organizationId !== undefined && organizationId !== null) {
    return <ProviderSelectWithOrg {...props} organizationId={organizationId} />;
  } else {
    return <ProviderSelectNoOrg {...props} />;
  }
}
