import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  AppointmentScaleAdministrationQuery,
  ReportScalePlanningDecision,
  useAppointmentScaleAdministrationQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import { Dig } from "type-utils";
import { scaleMediumName } from "Shared/Scale/Scale";
import { replaceSessionWithMeasurement, scheduleSummaryOrPreset } from "Care/MeasurementPlanSchedule";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";

function NoSession() {
  const { t } = useTranslation(["patients"]);
  return <Typography>{t("patients:appointments.reportScales.noSession")}</Typography>;
}
function ReportScalesTable(props: {
  patientId: PatientId;
  reportScales: Dig<
    AppointmentScaleAdministrationQuery,
    ["schedulingAppointment", "patientSession", "assessmentReport", "reportScales"]
  >;
}) {
  const { t } = useTranslation(["care", "patients"]);

  const rows = props.reportScales.map((reportScale) => {
    const includePlanName = reportScale.careMeasurementPlanScales.length > 1;
    const scheduleContent = reportScale.manual
      ? t("patients:appointments.reportScales.manual")
      : reportScale.careMeasurementPlanScales
          .map((mps) => {
            return includePlanName
              ? `${scheduleSummaryOrPreset(t, mps)} (${mps.measurementPlan.name})`
              : scheduleSummaryOrPreset(t, mps);
          })
          .join(", ");

    return (
      <TableRow key={reportScale.id.toString()}>
        <TableCell>{scaleMediumName(reportScale.scale)}</TableCell>
        <TableCell>{scheduleContent}</TableCell>
        <TableCell>
          {reportScale.planningDecision === ReportScalePlanningDecision.ADMINISTER ? (
            <CheckIcon />
          ) : (
            <CancelIcon />
          )}
        </TableCell>
        <TableCell>{replaceSessionWithMeasurement(reportScale.planningReason)}</TableCell>
      </TableRow>
    );
  });
  return (
    <>
      <Typography>{t("patients:appointments.reportScales.scalesSelected")}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography fontWeight={"bold"}>
                {t("patients:appointments.reportScales.columns.scale")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={"bold"}>
                {t("patients:appointments.reportScales.columns.schedule")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={"bold"}>
                {t("patients:appointments.reportScales.columns.administer")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography fontWeight={"bold"}>
                {t("patients:appointments.reportScales.columns.details")}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </>
  );
}

export function AppointmentScaleAdministration(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentScaleAdministrationQuery({ variables: { appointmentId: props.appointmentId } })
  );
  const { t } = useTranslation(["patients"]);

  return remoteData.caseOf({
    Success: (data) => {
      const session = data.schedulingAppointment?.patientSession;

      if (session?.assessmentReport) {
        if (session.scalesPlanned) {
          return (
            <ReportScalesTable
              reportScales={session.assessmentReport.reportScales}
              patientId={props.patientId}
            />
          );
        } else {
          return t("patients:appointments.reportScales.noScalesYet");
        }
      } else {
        return <NoSession />;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });
}
