import * as Http from "Api/Http";
import * as Id from "Lib/Id";
import { Result, RemoteData, Err } from "seidr";
import { resultToRemoteData } from "Lib/Utils";
import SumType from "sums-up";
import { InstituteId } from "Lib/Ids";

// NOTE: None of the classes in here are actual React contexts, and instead are
// basically just a collection of objects doing session/context-like behaviors.
// Caveat emptor.

export type RawBootstrap = {
  id: string;
  name: string;
  shortcode: string;
  enabled: boolean;
  slogan?: string;
  logo_url?: string;
  institute_type: string;
  inactivity_timeout_seconds: number | null;
  public_configuration: {
    hasPii: boolean;
  };
  public_feature_flags: {
    enableDashboardSchedule: boolean;
  };
};

export type InstituteContextBase = {
  id: InstituteId;
  name: string;
  enabled: boolean;
  shortcode: string;
  featureFlags: PublicFeatureFlags;
  inactivityTimeoutSeconds: number | null;
};

export type PublicFeatureFlags = {
  enableDashboardSchedule: boolean;
};

export type AppContext = {
  institute: InstituteContext;
  hasPii: boolean;
};

class InstituteContext extends SumType<{
  MirahInternalInstituteContext: [InstituteContextBase];
  ProviderInstituteContext: [InstituteContextBase];
  GroupLeaderInstituteContext: [InstituteContextBase];
}> {
  public static MirahInternalInstituteContext = (inst: InstituteContextBase) =>
    new InstituteContext("MirahInternalInstituteContext", inst);
  public static ProviderInstituteContext = (inst: InstituteContextBase) =>
    new InstituteContext("ProviderInstituteContext", inst);
  public static GroupLeaderInstituteContext = (inst: InstituteContextBase) =>
    new InstituteContext("GroupLeaderInstituteContext", inst);
}

function getInstituteId(institute: InstituteContext): InstituteId {
  return institute.caseOf({
    ProviderInstituteContext: (inst) => inst.id,
    MirahInternalInstituteContext: (inst) => inst.id,
    GroupLeaderInstituteContext: (inst) => inst.id,
  });
}

function getInstituteIdAndName(institute: InstituteContext): { id: InstituteId; name: string } {
  return institute.caseOf({
    ProviderInstituteContext: (inst) => {
      return { id: inst.id, name: inst.name };
    },
    MirahInternalInstituteContext: (inst) => {
      return { id: inst.id, name: inst.name };
    },
    GroupLeaderInstituteContext: (inst) => {
      return { id: inst.id, name: inst.name };
    },
  });
}

/**
 * Gets the inactivity timeout for the current institute, i.e. the timeout at which without any motion
 * or clicks the user should be logged out. It is in seconds
 *
 * The default is 15 minutes.
 * @param institute the institute
 * @returns the default timeout in seconds.
 */
function getInactivityTimeoutMilliseconds(institute: InstituteContext): number {
  const defaultTimout = 15 * 60;
  const override = institute.caseOf({
    ProviderInstituteContext: (inst) => inst.inactivityTimeoutSeconds,
    MirahInternalInstituteContext: (inst) => inst.inactivityTimeoutSeconds,
    GroupLeaderInstituteContext: (inst) => inst.inactivityTimeoutSeconds,
  });

  return (override || defaultTimout) * 1000;
}

function bootstrapToAppContext(data: RawBootstrap): Result<Error, AppContext> {
  if (data.institute_type === "provider") {
    return Id.fromString<"Institute">(data.id).map((id) => ({
      institute: InstituteContext.ProviderInstituteContext({
        id,
        name: data.name,
        enabled: data.enabled,
        shortcode: data.shortcode,
        featureFlags: data.public_feature_flags,
        inactivityTimeoutSeconds: data.inactivity_timeout_seconds,
      }),
      hasPii: data.public_configuration.hasPii,
    }));
  } else if (data.institute_type === "mirah_internal") {
    return Id.fromString<"Institute">(data.id).map((id) => ({
      institute: InstituteContext.MirahInternalInstituteContext({
        id,
        name: data.name,
        enabled: data.enabled,
        shortcode: data.shortcode,
        featureFlags: data.public_feature_flags,
        inactivityTimeoutSeconds: data.inactivity_timeout_seconds,
      }),
      hasPii: data.public_configuration.hasPii,
    }));
  } else if (data.institute_type === "group_leader") {
    return Id.fromString<"Institute">(data.id).map((id) => ({
      institute: InstituteContext.GroupLeaderInstituteContext({
        id,
        name: data.name,
        enabled: data.enabled,
        shortcode: data.shortcode,
        featureFlags: data.public_feature_flags,
        inactivityTimeoutSeconds: data.inactivity_timeout_seconds,
      }),
      hasPii: data.public_configuration.hasPii,
    }));
  } else {
    return Err(new Error("Unsupported Type"));
  }
}

function bootstrap(onDone: (result: RemoteData<Error, AppContext>) => void): void {
  Http.request<RawBootstrap>((result) => {
    onDone(
      result
        .mapFailure((e) => e.error)
        .map(bootstrapToAppContext)
        .flatMap(resultToRemoteData)
    );
  }, "/bootstrap");
}

export {
  InstituteContext,
  bootstrap,
  getInstituteId,
  getInstituteIdAndName,
  getInactivityTimeoutMilliseconds as getInactivityTimeout,
};
