import { BugReportOutlined } from "@mui/icons-material";
import {
  Button,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useIsMobile } from "Shared/Responsive";
import React, { FormEvent, ReactElement } from "react";
import { isDevelopment } from "./Debug";
import { LaunchContext } from "Contexts/LaunchContext";

export function DebugModal(): ReactElement | null {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const color = useIsMobile() ? theme.palette.background.paper : theme.palette.primary.main;

  if (!isDevelopment()) {
    return null;
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <BugReportOutlined sx={{ fill: color }} />
      </IconButton>
      <ResponsiveDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Stack direction="column" spacing={1} divider={<Divider />}>
            <Shell />
            <LaunchContextInfo />
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

function Shell(): ReactElement {
  const theme = useTheme();

  const [input, setInput] = React.useState("");
  const [output, setOutput] = React.useState("");

  const run = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const result = tryEval(input);
    setOutput(`${output}\n> ${input}\n${result}\n`);
    setInput("");
  };

  return (
    <Stack direction="column" spacing={1}>
      <form onSubmit={run}>
        <Typography
          fontFamily="monospace"
          whiteSpace="pre-wrap"
          padding={1}
          marginBottom={1}
          sx={{ backgroundColor: theme.palette.report.shading.background, wordBreak: "break-word" }}
        >
          {output}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            value={input}
            onChange={(event) => setInput(event.target.value)}
            sx={{ flexGrow: 1 }}
            inputProps={{ sx: { fontFamily: "monospace" } }}
          />
          <Button variant="outlined" type="submit">
            Run
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

function tryEval(command: string) {
  // We want to only return strings, but the results of eval and catch are not necessarily things that have toString
  // methods, so we interpolate into an empty string. This may also force evaluation of some lazy data structure that
  // I didn't think could exist in Javascript, but in some cases calling eval won't raise an error, but then trying to
  // put the result of the eval into a string will. Strange and bad!
  try {
    const result = eval(command);
    return `${result}`;
  } catch (error) {
    return `${error}`;
  }
}

function LaunchContextInfo() {
  const theme = useTheme();
  const state = React.useContext(LaunchContext);

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography>Launch Context:</Typography>
      <Typography
        fontFamily="monospace"
        whiteSpace="pre-wrap"
        padding={1}
        marginBottom={1}
        sx={{ backgroundColor: theme.palette.report.shading.background, wordBreak: "break-word" }}
      >
        {JSON.stringify(state, null, 2)}
      </Typography>
    </Stack>
  );
}
