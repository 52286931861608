import { ListItem } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  ScaleScorerDomain,
  useScaleScorerSelectSingleQuery,
  EntityTreeNodeParams,
  useScaleScorerWithUsageSelectLazyQuery,
  ScaleScorerWithUsageSelectQueryVariables,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ScaleScorerId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";

type ScaleScorerWithUsageSelectProps = {
  value: ScaleScorerId | null;
  domains?: ReadonlyArray<ScaleScorerDomain>;
  setValue: (newValue: ScaleScorerId | null) => void;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

type SelectOption = { id: ScaleScorerId; name: string; sessionCount: number | null };

function inputToSelect(value: SelectOption | null): ScaleScorerId | null {
  if (value) {
    return value.id;
  } else {
    return null;
  }
}

export default function ScaleScorerWithUsageSelect(props: ScaleScorerWithUsageSelectProps): ReactElement {
  const { t } = useTranslation(["common"]);
  // In cases where we are given an id as part of a query string etc we need to fetch
  // the name of the option no matter what it is rather than relying on the search.
  const { remoteData: currentValueRemoteData } = apolloQueryHookWrapper(
    useScaleScorerSelectSingleQuery({
      variables: {
        id: assertNonNull(props.value), // Apollo will only call if value is present
      },
      skip: !props.value,
    })
  );
  const queryVars: Omit<ScaleScorerWithUsageSelectQueryVariables, "search"> = {
    first: 25,
    domain: props.domains || null,
    entityTreeNode: props.entityTreeNode,
    dateRange: {
      min: props.startDate,
      max: props.endDate,
    },
  };

  const value: SelectOption | null = props.value
    ? currentValueRemoteData.caseOf({
        Success: (result) => {
          if (result.assessmentScaleScorer) {
            return {
              id: result.assessmentScaleScorer.id,
              name: result.assessmentScaleScorer.scale.name,
              sessionCount: null,
            };
          } else if (props.value) {
            return {
              id: props.value,
              name: "Loading",
              sessionCount: null,
            };
          }
          return null;
        },
        _: () => {
          if (props.value) {
            return {
              id: props.value,
              name: "Loading",
              sessionCount: null,
            };
          }
          return null;
        },
      })
    : null;

  return (
    <QueryAutocompleteSingle
      query={useScaleScorerWithUsageSelectLazyQuery}
      queryVariables={queryVars}
      unwrapResponse={(response) =>
        response.assessmentScaleScorersWithUsage?.nodes.map((result) => {
          return {
            id: result.scaleScorer.id,
            // For now, we just use the scale name as the title, without the domain.
            name: result.scale.name,
            sessionCount: result.sessionCount,
          };
        })
      }
      valueUpdated={(value) => props.setValue(inputToSelect(value))}
      valueEqual={(left, right) => left.id === right.id}
      value={value}
      label={t("common:scaleScorers.title")}
      autocompleteProps={{
        sx: { width: "100%" },
        noOptionsText: t("common:scaleScorers.noneMatching"),
        getOptionLabel: (option) => option.name,
        renderOption: (props, option) => {
          return (
            <ListItem {...props} key={option.id.toString()}>
              {t("common:scaleScorers.withUsage", { name: option.name, sessionCount: option.sessionCount })}
            </ListItem>
          );
        },
      }}
    />
  );
}
