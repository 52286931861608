import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useActiveInvitationDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, PatientId } from "Lib/Ids";
import { InvitationsTable } from "Patients/Invitations/InvitationsTable";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type ActiveInvitationsCardProps = {
  patientId: PatientId;
  careEpisodeId?: CareEpisodeId;
};

export function ActiveInvitationsCard(props: ActiveInvitationsCardProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useActiveInvitationDetailsQuery({
      variables: { patientId: props.patientId, careEpisodeId: props.careEpisodeId || null },
    })
  );

  const { t } = useTranslation(["patients"]);

  const inner = remoteData.caseOf({
    Success: (data) => {
      if (data.assessmentInvitations?.nodes && data.assessmentInvitations.nodes.length > 0) {
        return <InvitationsTable invitations={data.assessmentInvitations.nodes} scalesPlanned={true} />;
      } else {
        return <Typography>{t("patients:patientDetails.noActiveInvitations")}</Typography>;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });

  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.activeInvitations")} />
      <CardContent>{inner}</CardContent>
    </Card>
  );
}
