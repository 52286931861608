import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, ButtonBase, Collapse, DialogContent, Skeleton, Stack, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { TreatmentTargetPicker } from "CollaborativeCare/TreatmentTargetPicker";
import {
  ActiveTreatmentTargetsQuery,
  useActiveTreatmentTargetsQuery,
  useAddTreatmentTargetMutation,
  useRemoveTreatmentTargetMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { CareEpisodeId, CareEpisodeTreatmentTargetId, TreatmentTargetId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import { Form, useForm, useWrappedField } from "Shared/Form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DigUnpacked } from "type-utils";

export function ActiveTreatmentTargets(props: {
  careEpisodeId: CareEpisodeId;
  forPreviousEnrollment?: boolean;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(
    useActiveTreatmentTargetsQuery({
      variables: { careEpisodeId: props.careEpisodeId },
    })
  );

  const targets = remoteData.caseOf({
    NotAsked: () => <ActiveTreatmentTargetsLoading />,
    Loading: () => <ActiveTreatmentTargetsLoading />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.careEpisode) {
        const targets = response.careEpisode.collaborativeCareCareEpisodeTreatmentTargets;
        if (targets.length === 0) {
          return <Typography>{t("collaborativeCare:patientDetails.surveyHistory.noBundles")}</Typography>;
        }

        return targets.map((target, i) => (
          <CareEpisodeTreatmentTargetInfo
            target={target}
            careEpisodeId={props.careEpisodeId}
            key={i}
            forPreviousEnrollment={props.forPreviousEnrollment}
          />
        ));
      } else {
        return (
          <ErrorMessage message={t("collaborativeCare:patientDetails.surveyHistory.cantLoadCareEpisode")} />
        );
      }
    },
  });

  const addTarget = remoteData.caseOf({
    Success: (response) => {
      if (
        response.careEpisode &&
        (props.forPreviousEnrollment == false || props.forPreviousEnrollment == null)
      ) {
        return (
          <AddTreatmentTargetButton
            careEpisodeId={props.careEpisodeId}
            existingTargets={response.careEpisode.collaborativeCareCareEpisodeTreatmentTargets.map(
              (careTarget) => careTarget.treatmentTarget
            )}
          />
        );
      } else {
        return <></>;
      }
    },
    _: () => <></>,
  });

  return (
    <Stack direction="column" spacing={1} maxWidth="25rem">
      <Typography variant="h3">
        {props.forPreviousEnrollment
          ? t("collaborativeCare:patientDetails.surveyHistory.bundles")
          : t("collaborativeCare:patientDetails.surveyHistory.activeBundles")}
      </Typography>
      {targets}
      {addTarget}
    </Stack>
  );
}

type CareEpisodeTreatmentTargetInfoProps = {
  // TODO: Double check if can remove
  careEpisodeId: CareEpisodeId;
  target: DigUnpacked<
    ActiveTreatmentTargetsQuery,
    ["careEpisode", "collaborativeCareCareEpisodeTreatmentTargets"]
  >;
  forPreviousEnrollment?: boolean;
};

function CareEpisodeTreatmentTargetInfo(props: CareEpisodeTreatmentTargetInfoProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);
  const [open, setOpen] = React.useState(false);

  const chevron = open ? <KeyboardArrowUp /> : <KeyboardArrowDown />;

  const removeButtonContent = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <RemoveTreatmentTargetButton
      careEpisodeTreatmentTargetId={props.target.id}
      treatmentTarget={props.target.treatmentTarget}
      scaleNames={props.target.treatmentTarget.measurementPlan.measurementPlanScales.map(
        (mps) => mps.scale.shortname || mps.scale.name
      )}
    />
  );
  return (
    <ButtonBase component="div" sx={{ display: "block" }} onClick={() => setOpen(!open)}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Typography>{props.target.treatmentTarget.name}</Typography>
          <Box flexGrow={1} />
          <Typography>
            {t("collaborativeCare:patientDetails.surveyHistory.bundleStartDate", {
              date: props.target.startedDate,
            })}
          </Typography>
          {chevron}
        </Stack>
        <Collapse in={open}>
          <Stack direction="column" spacing={1}>
            <Typography>
              {t("collaborativeCare:patientDetails.surveyHistory.bundleMeasuresList", {
                measuresList: props.target.treatmentTarget.measurementPlan.measurementPlanScales
                  .map((planScale) => planScale.scale.shortname)
                  .join(", "),
              })}
            </Typography>
            <Stack direction="row-reverse">{removeButtonContent}</Stack>
          </Stack>
        </Collapse>
      </Stack>
    </ButtonBase>
  );
}

type RemoveTreatmentTargetProps = {
  careEpisodeTreatmentTargetId: CareEpisodeTreatmentTargetId;
  scaleNames: ReadonlyArray<string>;
  treatmentTarget: DigUnpacked<
    ActiveTreatmentTargetsQuery,
    ["careEpisode", "collaborativeCareCareEpisodeTreatmentTargets", "treatmentTarget"]
  >;
};

function RemoveTreatmentTargetButton(props: RemoveTreatmentTargetProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);
  const [open, setOpen] = React.useState(false);

  // Because this is all technically inside a <ButtonBase> we have to call stopPropagation on things that get triggered
  // with a click to keep them from closing the target info section.

  const clickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  const clickClose = (event: React.MouseEvent<unknown>) => {
    event.stopPropagation();
    setOpen(false);
  };

  const [removeTarget, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareRemoveTreatmentTarget,
    useRemoveTreatmentTargetMutation({
      variables: {
        input: {
          careEpisodeTreatmentTargetId: props.careEpisodeTreatmentTargetId,
        },
      },
    })
  );

  const showSpinner = remoteData.kind === "Loading";
  const disable = remoteData.kind === "Loading";

  useEffectSimpleCompare(() => {
    if (remoteData.kind === "Success") {
      setOpen(false);
    }
  }, [remoteData.kind]);

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={clickOpen}>
        {t("common:actions.remove")}
      </Button>
      <ResponsiveDialog
        open={open}
        onClose={clickClose}
        title={t("collaborativeCare:patientDetails.surveyHistory.removeBundle", {
          title: props.treatmentTarget.name,
        })}
      >
        <DialogContent>
          <Stack direction="column" spacing={3}>
            <Typography>
              {t("collaborativeCare:patientDetails.surveyHistory.removeBundleWarning", {
                measureList: props.scaleNames.join(", "),
              })}
            </Typography>
            <Stack direction="row-reverse">
              <ButtonWithSpinner
                variant="contained"
                color="error"
                onClick={() => removeTarget()}
                showSpinner={showSpinner}
                disabled={disable}
              >
                {t("collaborativeCare:patientDetails.surveyHistory.removeBundle", {
                  title: props.treatmentTarget.name,
                })}
              </ButtonWithSpinner>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

type AddTreatmentTargetProps = {
  careEpisodeId: CareEpisodeId;
  existingTargets: ReadonlyArray<
    DigUnpacked<
      ActiveTreatmentTargetsQuery,
      ["careEpisode", "collaborativeCareCareEpisodeTreatmentTargets", "treatmentTarget"]
    >
  >;
};

function AddTreatmentTargetButton(props: AddTreatmentTargetProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [showForm, setShowForm] = React.useState(false);

  const [addTarget, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAddTreatmentTarget,
    useAddTreatmentTargetMutation()
  );

  const fields = {
    target: useWrappedField<
      DigUnpacked<
        ActiveTreatmentTargetsQuery,
        ["careEpisode", "collaborativeCareCareEpisodeTreatmentTargets", "treatmentTarget"]
      >
    >({
      required: true,
      validate: (newValue) => {
        const alreadyAdded = props.existingTargets.find((target) => target.id === newValue.id);
        if (alreadyAdded) {
          return t("collaborativeCare:patientDetails.surveyHistory.duplicateBundles");
        }
        return null;
      },
    }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      addTarget({
        variables: {
          input: {
            careEpisodeId: props.careEpisodeId,
            treatmentTargetId: fields.target.value?.id as TreatmentTargetId,
          },
        },
      });
    },
    onSuccess: () => {
      setShowForm(false);
    },
  });

  const addButton = (
    <Box>
      <Button variant="contained" color="secondary" onClick={() => setShowForm(true)}>
        {t("common:actions.add")}
      </Button>
    </Box>
  );
  const formComponent = (
    <Form onSubmit={form.onSubmit}>
      <Stack direction="column" spacing={1}>
        <TreatmentTargetPicker
          fullWidth
          value={fields.target.value}
          onChange={fields.target.onChange}
          error={fields.target.error}
          helperText={fields.target.helperText}
          label={t("collaborativeCare:patientDetails.surveyHistory.bundle")}
        />

        <ButtonWithSpinner
          type="submit"
          variant="contained"
          color="secondary"
          disabled={form.disableSubmit}
          showSpinner={form.showSpinner}
        >
          {t("common:actions.add")}
        </ButtonWithSpinner>
      </Stack>
    </Form>
  );

  const content = showForm ? formComponent : addButton;

  return content;
}

function ActiveTreatmentTargetsLoading(): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <Skeleton width="100%" />
      <Skeleton width="100%" />
    </Stack>
  );
}
