import React, { ReactElement } from "react";
import { RemoteData } from "seidr";
import * as Goal from "Shared/Scale/Goal";
import { ConstructSection, SpecialSection } from "Shared/Scale/Constructs";
import { ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import GoalsSlashProblems from "./GoalsSlashProblems";
import Constructs from "./Constructs";
import { TreatmentResponse } from "./TreatmentResponse";
import SpecialSections from "./SpecialSections";

const ShadedBox = styled("div")(({ theme }) => ({
  background: theme.palette.report.shading.background,
  // 1px box shadow to create a border without changing positioning
  boxShadow: `0 0 0 1px ${theme.palette.report.shading.focus}`,
  borderRadius: theme.spacing(0.25),
  padding: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

const ConstructsWrapper = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(1),
}));

export type OverviewContentProps = {
  goalsData: RemoteData<Error, ReadonlyArray<Goal.Goal>>;
  constructData: RemoteData<Error, ReadonlyArray<ConstructSection>>;
  specialSections: RemoteData<Error, ReadonlyArray<SpecialSection>>;
  treatmentResponseData: RemoteData<Error, ReadonlyArray<ScaleSectionSummary>>;
  feedbackReportContext: FeedbackReportContext;
};

export function OverviewContent(props: OverviewContentProps): ReactElement {
  return (
    <Stack spacing={3}>
      <ShadedBox>
        <Stack spacing={1}>
          <TreatmentResponse
            treatmentResponseData={props.treatmentResponseData}
            feedbackReportContext={props.feedbackReportContext}
          />
          <GoalsSlashProblems goals={props.goalsData} feedbackReportContext={props.feedbackReportContext} />
        </Stack>
      </ShadedBox>
      <ConstructsWrapper>
        <Stack spacing={3}>
          <SpecialSections
            specialSections={props.specialSections}
            feedbackReportContext={props.feedbackReportContext}
          />
          <Constructs constructs={props.constructData} feedbackReportContext={props.feedbackReportContext} />
        </Stack>
      </ConstructsWrapper>
    </Stack>
  );
}
