import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  TriggeredWorkflowAction,
  TriggeredWorkflowActionStatus,
  useRunWorkflowActionMutation,
  WorkflowAction,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { FormOverlay } from "Shared/Form";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";

type WorkflowActionDetails = PickTypename<WorkflowAction, "id" | "text">;
type TriggeredWorkflowActionDetails = PickTypename<TriggeredWorkflowAction, "id" | "status"> & {
  workflowAction: WorkflowActionDetails;
};

type TriggeredWorkflowActionButtonProps = {
  triggeredWorkflowAction: TriggeredWorkflowActionDetails;
};

export function TriggeredWorkflowActionButton(props: TriggeredWorkflowActionButtonProps): ReactElement {
  const [runWorkflowAction, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.decisionSupportRunWorkflowAction,
    useRunWorkflowActionMutation({
      // Note that we specifically aren't using any refetch queries here because the mutation response has everything
      // we need to update the cache and rerender the triggered workflow component.
      variables: {
        input: {
          triggeredWorkflowActionId: props.triggeredWorkflowAction.id,
        },
      },
    })
  );

  // For reasons I don't particularly care about typescript gets angry if I pass runWorkflowAction into the onClick
  // prop directly, but is perfectly happy if I launder it through another anonymous function.
  const onClick = () => runWorkflowAction();

  const isLoading = remoteData.kind === "Loading";
  const disabled = props.triggeredWorkflowAction.status === TriggeredWorkflowActionStatus.USED || isLoading;

  return (
    <>
      <FormOverlay response={remoteData} errorMessage={"An error has occurred"} />
      <ButtonWithSpinner variant="outlined" disabled={disabled} showSpinner={isLoading} onClick={onClick}>
        {props.triggeredWorkflowAction.workflowAction.text}
      </ButtonWithSpinner>
    </>
  );
}
