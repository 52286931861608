import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { titleize, humanize } from "Lib/Utils";

export type Threshold = {
  // TODO: Should mnemonic be a Phantom Type?
  mnemonic: string;
  maxValue: number;
  minValue: number;
  thresholdClass: ScaleThresholdClass;
};

function mnemonicTitle(thresholdMnemonic: string): string {
  return titleize(humanize(thresholdMnemonic));
}

// Given a mnemomic, get a human readable title of the desired maximum length.
function mnemonicShortTitle(
  thresholdMnemonic: string,
  {
    desiredTitleLength = 16,
    maxWordLength,
  }: {
    maxWordLength?: number;
    desiredTitleLength?: number;
  } = {}
): string {
  let title = mnemonicTitle(thresholdMnemonic);

  if (maxWordLength) {
    title = title
      .split(" ")
      .map((w) => abbreviateTitle(w, maxWordLength))
      .map((w) => ellipseTitle(w, maxWordLength))
      .join(" ");
  }
  title = abbreviateTitle(title, desiredTitleLength);
  title = ellipseTitle(title, desiredTitleLength);

  return title;
}

// Applies abbreviation rules to titles that have characterCount
// greater than maximumLength. Titles with fewer
// characters do not need abbreviation and will remain the same.
// Abbreviation rules are applied until the title has reached
// maximumLength or less in length.
function abbreviateTitle(title: string, maximumLength: number) {
  // Ex:
  // Moderately Severe Depression
  // this is 33 characters and is too long.
  //
  // It becomes:
  // Mod. Sev. Depr.
  // A more reasonable 15.
  //
  // Note that shortening rules are applied top to bottom and stop
  // once we have a short enough name. This means that more
  // important words should be at the bottom of the rule set,
  // so that they are replaced last if necessary.
  const shorteningRules = [
    {
      find: ["Moderately", "Moderate"],
      replace: "Mod.",
    },
    {
      find: ["Severely", "Severe"],
      replace: "Sev.",
    },
    {
      find: ["Negative", "Negatively"],
      replace: "Neg.",
    },
    {
      find: ["Positive", "Positively"],
      replace: "Pos.",
    },
    {
      find: ["Behavioral"],
      replace: "Behav.",
    },
    {
      find: ["Referral"],
      replace: "Refer.",
    },
    {
      find: ["Clinical"],
      replace: "Clin.",
    },
    {
      find: ["Depression"],
      replace: "Depr.",
    },
  ];

  shorteningRules.map((rule) => {
    rule.find.map((find) => {
      if (title.length > maximumLength) {
        // This only replaces whole words.
        title = title.replace(new RegExp(`\\b${find}\\b`, "g"), rule.replace);
      }
    });
  });
  return title;
}

// Ellipses titles longer than maximumLength characters. The last
// three characters will be replaced with an ellipse, '...'.
function ellipseTitle(title: string, maximumLength: number): string {
  if (title.length > maximumLength) {
    title = title.substring(0, maximumLength - 3);
    title += "...";
  }
  return title;
}

function title(threshold: Pick<Threshold, "mnemonic">): string {
  return mnemonicTitle(threshold.mnemonic);
}

function shortTitle(
  threshold: Pick<Threshold, "mnemonic">,
  config: {
    maxWordLength?: number;
    desiredTitleLength?: number;
  } = {}
): string {
  return mnemonicShortTitle(threshold.mnemonic, config);
}

/**
 * For sorting thresholds and things with thresholds by severity
 */
function numericThresholdClass(threshold: ScaleThresholdClass | null) {
  if (threshold === null) {
    return 0;
  }

  switch (threshold) {
    case ScaleThresholdClass.UNANSWERED:
      return 0;
    case ScaleThresholdClass.UNKNOWN:
      return 0;
    case ScaleThresholdClass.NONE:
      return 1;
    case ScaleThresholdClass.CONTINUOUS:
      return 1;
    case ScaleThresholdClass.LOW:
      return 2;
    case ScaleThresholdClass.MILD:
      return 3;
    case ScaleThresholdClass.MODERATE:
      return 4;
    case ScaleThresholdClass.MOD_SEVERE:
      return 5;
    case ScaleThresholdClass.SEVERE:
      return 6;
  }
}

export default Threshold;
export {
  title,
  shortTitle,
  mnemonicTitle,
  mnemonicShortTitle,
  abbreviateTitle,
  ellipseTitle,
  numericThresholdClass,
};
