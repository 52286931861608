import React from "react";
import { Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import EntityMeasurementPlanTable from "Care/EntityMeasurementPlanTable";
import {
  AppointmentMeasurementPlansQuery,
  useAppointmentMeasurementPlansQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import Link from "MDS/Link";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import { Dig } from "type-utils";

function NoSession() {
  const { t } = useTranslation(["patients"]);
  return <Typography>{t("patients:appointments.planning.noSession")}</Typography>;
}

function ManualPlanning() {
  const { t } = useTranslation(["patients", "enums"]);

  return <Typography>{t("patients:appointments.planning.manuallyPlanned")}</Typography>;
}

function PlanningDetails(props: {
  patientId: PatientId;
  measurementPlans: Dig<
    AppointmentMeasurementPlansQuery,
    ["schedulingAppointment", "patientSession", "entityMeasurementPlans"]
  >;
}) {
  const { t } = useTranslation(["patients"]);
  return (
    <>
      <Typography>{t("patients:appointments.planning.plansSelected")}</Typography>
      <EntityMeasurementPlanTable entityMeasurementPlans={props.measurementPlans} />
      <Link to={`/provider/patients/${props.patientId}/plan`}>
        {t("patients:appointments.planning.goToPlanningDetails")}
      </Link>
    </>
  );
}

export function AppointmentMeasurementPlans(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentMeasurementPlansQuery({ variables: { appointmentId: props.appointmentId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      const session = data.schedulingAppointment?.patientSession;

      if (session) {
        if (session.autoplan) {
          return (
            <PlanningDetails measurementPlans={session.entityMeasurementPlans} patientId={props.patientId} />
          );
        } else {
          return <ManualPlanning />;
        }
      } else {
        return <NoSession />;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });
}
