import { UserInternalOverviewFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { ReactNode } from "react";

function userOverviewFields(user: UserInternalOverviewFragment): Array<{
  value: string | ReactNode | null | boolean;
  name: string;
}> {
  return [
    {
      name: "User Fields",
      value: "-----------",
    },
    { name: "User ID", value: user.id.toString() },
    { name: "Name", value: user.name },
    { name: "confirmedAt", value: user.confirmedAt?.toLocaleString() },
    { name: "currentSignInAt", value: user.currentSignInAt?.toLocaleString() },
    { name: "email", value: user.email },
    { name: "emailSource", value: user.emailSource },
    { name: "failedAttempts", value: user.failedAttempts },
    { name: "firstName", value: user.firstName },
    { name: "firstNameSource", value: user.firstNameSource },
    { name: "isInternal", value: user.isInternal },
    { name: "language", value: user.language },
    { name: "lastActivityAt", value: user.lastActivityAt?.toLocaleString() },
    { name: "lastName", value: user.lastName },
    { name: "lastNameSource", value: user.lastNameSource },
    { name: "lastSignInAt", value: user.lastSignInAt?.toLocaleString() },
    { name: "lockedAt", value: user.lockedAt?.toLocaleString() },
    { name: "notificationPreference", value: user.notificationPreference },
    { name: "phoneNumber", value: user.phoneNumber },
    { name: "phoneNumberSource", value: user.phoneNumberSource },
    { name: "role", value: user.role },
    { name: "signInCount", value: user.signInCount },
    { name: "ssoUsername", value: user.ssoUsername },
    { name: "suffix", value: user.suffix },
    { name: "timezone", value: user.timezone },
    { name: "title", value: user.title },
    { name: "username", value: user.username },
    { name: "createdAt", value: user.createdAt.toLocaleString() },
    { name: "updatedAt", value: user.updatedAt.toLocaleString() },
  ];
}

export { userOverviewFields };
