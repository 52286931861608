import { oncePerPeriodT } from "GeneratedGraphQL/EnumTranslations";
import {
  MeasurementPlanMeasureOnsetFrom,
  MeasurementPlanScale,
  OncePerPeriod,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Interval as GqlInterval } from "Lib/GqlScalars";
import { parseDuration } from "Lib/Utils";
import { formatDuration } from "date-fns";
import { TFunction } from "i18next";
import { compact } from "type-utils";

// This is mostly copied from https://github.com/mirahtech/ember-frontend/blob/master/app/models/care/measurement-plan-scale.js
// But now with better typing and moment.js replaced by date-fns

type SchedulePreset = {
  id: string;
  name: string;
  onsetSession: number | null;
  onsetTime: GqlInterval | null;
  repeatSessions: number | null;
  maxRepeatInterval: GqlInterval | null;
  discontinueSession: number | null;
  discontinueTime: GqlInterval | null;
  measureOnsetFrom: MeasurementPlanMeasureOnsetFrom | null;
  oncePerPeriod: OncePerPeriod | null;
  rolloutPeriod: GqlInterval | null;
  rolloutBefore: Date | null;
};

const PRESETS: ReadonlyArray<SchedulePreset> = [
  {
    id: "onceOnly",
    name: "Once only",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 0,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "everySession",
    name: "Every measurement",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "weekly",
    name: "Weekly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: "P1W",
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "everyOtherSession",
    name: "Every other measurement",
    onsetSession: null,
    onsetTime: null,
    repeatSessions: 2,
    maxRepeatInterval: null,
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "fortnightly",
    name: "Every 2 weeks",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: "P2W",
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "monthly",
    name: "Monthly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: "P1M",
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
  {
    id: "quarterly",
    name: "Quarterly",
    onsetSession: 1,
    onsetTime: null,
    repeatSessions: 1,
    maxRepeatInterval: "P3M",
    discontinueSession: null,
    discontinueTime: null,
    measureOnsetFrom: MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START,
    oncePerPeriod: null,
    rolloutBefore: null,
    rolloutPeriod: null,
  },
];

// moment.js did some rounding, but date-fns doesn't, so we can just straight use the formatDuration method.
const humanizeExact = function (t: TFunction<["care"]>, stringDuration: GqlInterval) {
  const duration = parseDuration(stringDuration).getOrElse(null);

  if (!duration) {
    return t("care:duration.unknown");
  }

  return formatDuration(duration);
};

const isSameInterval = function (a: GqlInterval | null | undefined, b: GqlInterval | null | undefined) {
  if (a && b) {
    return parseDuration(a).getOrElse(null) === parseDuration(b).getOrElse(null);
  } else {
    return !a && !b;
  }
};

export function scheduleSummaryOrPreset(t: TFunction<["care"]>, data: Omit<SchedulePreset, "id" | "name">) {
  return scheduleSummary(t, { ...data, schedulePreset: schedulePresetFromSettings(data) });
}

function measureOnsetFromText(
  t: TFunction<["care"]>,
  measureOnsetFrom: MeasurementPlanMeasureOnsetFrom | null
) {
  switch (measureOnsetFrom) {
    case MeasurementPlanMeasureOnsetFrom.NEXT_CALENDAR_QUARTER:
      return t("care:measureOnsetFrom.nextCalendarQuarter");
    case MeasurementPlanMeasureOnsetFrom.CARE_EPISODE_PERIOD_START:
    default:
      return t("care:measureOnsetFrom.careEpisodePeriodStart");
  }
}

export function scheduleSummary(
  t: TFunction<["care", "enums", "common"]>,
  data: Omit<SchedulePreset, "id" | "name"> & {
    schedulePreset: SchedulePreset | null;
  }
) {
  // Output needs to be, e.g.:
  // "starting at the later of session 2 or 2 weeks after first session, repeating the longer of every 1 session or every 2 weeks"
  // "starting at session 1, every 2 sessions"
  // "starting 2 weeks after the first session, once only"
  const {
    schedulePreset,
    onsetSession,
    onsetTime,
    repeatSessions,
    maxRepeatInterval,
    discontinueSession,
    discontinueTime,
    measureOnsetFrom,
    oncePerPeriod,
    rolloutBefore,
    rolloutPeriod,
  } = data;

  if (
    !onsetSession &&
    !onsetTime &&
    repeatSessions === 0 &&
    !maxRepeatInterval &&
    !discontinueTime &&
    !discontinueSession &&
    !oncePerPeriod &&
    !rolloutBefore &&
    !rolloutPeriod
  ) {
    return t("care:scheduleSummary.onceOnly");
  }

  if (schedulePreset && schedulePreset.id !== "custom") {
    return schedulePreset.name;
  }

  let initial = null;

  const measureOnsetText = measureOnsetFromText(t, measureOnsetFrom);

  let oncePer = null;
  let measureOnsetExtra: string | null = null;
  let separateOncePerSentence = true;
  let separateMeasureOnset = true;

  if (onsetSession && onsetTime) {
    initial = t("care:scheduleSummary.initialBoth", {
      onsetSession: onsetSession,
      onsetTime: humanizeExact(t, onsetTime),
      measureOnsetFrom,
    });
  } else if (onsetSession) {
    initial = t("care:scheduleSummary.initialSession", { onsetSession: onsetSession });
  } else if (onsetTime) {
    initial = t("care:scheduleSummary.initialTime", {
      onsetTime: humanizeExact(t, onsetTime),
      measureOnsetText,
    });
  }

  let repeat = null;

  if (repeatSessions === 0) {
    repeat = t("care:scheduleSummary.onceOnly");
  } else if (repeatSessions && maxRepeatInterval) {
    repeat = t("care:scheduleSummary.repeatBoth", {
      repeatSessions: repeatSessions,
      maxRepeatInterval: humanizeExact(t, maxRepeatInterval),
    });
    separateMeasureOnset = false;
  } else if (repeatSessions) {
    repeat = t("care:scheduleSummary.repeatSession", { repeatSessions: repeatSessions });
  } else if (maxRepeatInterval) {
    repeat = t("care:scheduleSummary.repeatTime", { maxRepeatInterval: humanizeExact(t, maxRepeatInterval) });
  } else if (oncePerPeriod) {
    repeat = t("care:scheduleSummary.oncePerPeriod", { period: oncePerPeriodT(oncePerPeriod, t) });
    separateOncePerSentence = false;
  } else {
    repeat = t("care:scheduleSummary.everySession");
  }

  let ending = null;

  if (discontinueTime && discontinueSession) {
    ending = t("care:scheduleSummary.endingBoth", {
      discontinueSession: discontinueSession,
      discontinueTime: humanizeExact(t, discontinueTime),
    });
  } else if (discontinueSession) {
    ending = t("care:scheduleSummary.endingSession", { discontinueSession: discontinueSession });
  } else if (discontinueTime) {
    ending = t("care:scheduleSummary.endingTime", { discontinueTime: humanizeExact(t, discontinueTime) });
  }

  if (separateOncePerSentence && oncePerPeriod) {
    oncePer = t("care:scheduleSummary.endingSession", { period: oncePerPeriodT(oncePerPeriod, t) });
  }

  if (separateMeasureOnset && measureOnsetFrom === MeasurementPlanMeasureOnsetFrom.NEXT_CALENDAR_QUARTER) {
    measureOnsetExtra = t("care:scheduleSummary.measuredFrom", { onset: measureOnsetText });
  }

  let rollout = null;

  if (rolloutBefore && rolloutPeriod) {
    rollout = t("care:scheduleSummary.rollout", {
      rolloutPeriod: humanizeExact(t, rolloutPeriod),
      rolloutBefore: t("common:date.medium", { date: rolloutBefore }),
    });
  }

  return compact([initial, repeat, ending, oncePer, measureOnsetExtra, rollout]).join(", ");
}

export function schedulePresetFromSettings(
  data: Pick<
    MeasurementPlanScale,
    | "onsetSession"
    | "onsetTime"
    | "repeatSessions"
    | "maxRepeatInterval"
    | "discontinueSession"
    | "discontinueTime"
    | "oncePerPeriod"
  >
): SchedulePreset | null {
  const {
    onsetSession,
    onsetTime,
    repeatSessions,
    maxRepeatInterval,
    discontinueSession,
    discontinueTime,
    oncePerPeriod,
  } = data;

  if (
    typeof onsetSession === "undefined" &&
    typeof onsetTime === "undefined" &&
    typeof repeatSessions === "undefined" &&
    typeof maxRepeatInterval === "undefined" &&
    typeof discontinueSession === "undefined" &&
    typeof discontinueTime === "undefined"
  ) {
    return null;
  }

  const preset = PRESETS.find((preset) => {
    return (
      onsetSession === preset.onsetSession &&
      isSameInterval(onsetTime, preset.onsetTime) &&
      repeatSessions === preset.repeatSessions &&
      isSameInterval(maxRepeatInterval, preset.maxRepeatInterval) &&
      discontinueSession === preset.discontinueSession &&
      oncePerPeriod === preset.oncePerPeriod &&
      isSameInterval(discontinueTime, preset.discontinueTime)
    );
  });

  if (preset) {
    return preset;
  } else {
    return null;
  }
}

/**
 * Parts of our backend system use the phrase 'session' or 'session number' and it's confusing to our users now we
 * want to talk about 'measurements'. So just do a sub on all of them.
 * @param text the string to replace
 * @returns the text with all session->measurement
 */
export function replaceSessionWithMeasurement(text: string | null): string | null {
  if (!text) {
    return null;
  }
  return text.replaceAll("session", "measurement").replaceAll("Session", "Measurement");
}
