import { Clear } from "@mui/icons-material";
import { FormControl, IconButton, InputLabel, Select, SelectProps } from "@mui/material";
import React, { ReactElement } from "react";

type ClearableSelectProps<T> = {
  onChange: (newValue: T | null) => void;
} & Omit<SelectProps, "onChange">;

/**
 * A standard select dropdown except it also has a button to clear the current value. Button is only shown when the
 * select has a value, and is either hovered or focused. (Same as Mui's built in behavior for Autocomplete.) In at
 * least some browsers and some situations (its not every time and I can't work out why) this receives either extra
 * mouseover events or mouseover/mouseout pairs in the wrong order, leaving it thinking its hovered when it's not, so
 * it shows the (x) when it shouldn't. This is annoying but I can live with it, so I'm leaving it.
 *
 * @param props Same as the props for a regular select component. Note that the onChange event takes the new value for
 * from the select (or null) not a change event. Values are simply cast into the type, no actual coercion is done.
 * @returns
 */
export function ClearableSelect<T>(props: ClearableSelectProps<T>): ReactElement {
  const {
    onMouseOver: propsOver,
    onMouseOut: propsOut,
    onFocus: propsFocus,
    onBlur: propsBlur,
    ...passthrough
  } = props;

  const [hovered, setHovered] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const showClear = props.value && props.value !== "" && (hovered || focused);

  // This whole block of code is just to make sure that if someone wants to have additional mouseover/focus/etc events
  // on this they can, so we check for them and call them ourselves.
  const onMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
    if (propsOver) {
      propsOver(event);
    }
    setHovered(true);
  };
  const onMouseOut = (event: React.MouseEvent<HTMLDivElement>) => {
    if (propsOut) {
      propsOut(event);
    }
    setHovered(false);
  };
  const onFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (propsFocus) {
      propsFocus(event);
    }
    setFocused(true);
  };
  const onBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (propsBlur) {
      propsBlur(event);
    }
    setFocused(false);
  };

  // The actual select element needs an event handler that takes an event, rather than a value. I tried a version of
  // this where I pass an event in the clear case as well, but I can't figure out how to create an event or event-like
  // object with `value: null` like react/typescript require of me.
  const eventOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value as T);
    // Setting hovered to here because the usual flow of clicking on an option in the list has you count as hovered at
    // the moment the click happens (since the options are a child of the select) but then your mouse is outside the
    // element so it will never fire a mouse out naturally.
    setHovered(false);
  };
  const onClear = () => props.onChange(null);

  return (
    <FormControl fullWidth={props.fullWidth} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <InputLabel>{props.label}</InputLabel>
      <Select onFocus={onFocus} onBlur={onBlur} {...passthrough} onChange={eventOnChange}>
        {props.children}
      </Select>
      <IconButton
        size="small"
        sx={{
          // Positioning here tucks it inside the select box next to the down arrow, similar to Autocomplete.
          position: "absolute",
          right: "1.75rem",
          top: "50%",
          marginTop: "-1rem",
          visibility: showClear ? "visible" : "hidden",
        }}
        onClick={onClear}
      >
        <Clear fontSize="small" />
      </IconButton>
    </FormControl>
  );
}
