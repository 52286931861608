import {
  Button,
  Typography,
  TablePagination,
  MenuItem,
  Menu,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  IconButton,
} from "@mui/material";
import React, { Fragment, ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSurveyManagementIndexQuery, IntakeStatus } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { ArrowDropDown } from "@mui/icons-material";
import { PatientId, CareEpisodeId } from "Lib/Ids";
import { SendImmediateSurveyButton } from "CollaborativeCare/PatientDetails/SendImmediateSurvey";
import { InviteSummary, transformInvitation, InvitationStatusElement } from "./InviteSummary";
import {
  CancelInvitationButton,
  DownloadPdfButton,
  ResendAllNotificationsButton,
  SendReminderButton,
  SurveyManagementTableFailed,
  SurveyManagementTableLoading,
} from "./SurveyManagementTableShared";
import { PatientDetails } from "CollaborativeCare/PatientDetails/PatientDetails";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import InvitationHistory from "CollaborativeCare/PatientDetails/InvitationHistory";
import InfoIcon from "@mui/icons-material/Info";
import Link from "MDS/Link";

export default function SurveyManagementTableMobile(props: {
  patient: PatientDetails;
  careEpisodeId: CareEpisodeId;
  intakeStatus: IntakeStatus;
  forPreviousEnrollment?: boolean;
}) {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(
    useSurveyManagementIndexQuery({
      variables: {
        careEpisodeId: props.careEpisodeId,
      },
    })
  );

  let cardAction = props.forPreviousEnrollment ? (
    <></>
  ) : (
    <Box marginRight="0.3em">
      <ResendAllNotificationsButton
        patientId={props.patient.id}
        careEpisodeId={props.careEpisodeId}
        disabled={true}
      />
      <Divider />
      <SendImmediateSurveyButton patient={props.patient} />
    </Box>
  );
  return remoteData.caseOf({
    NotAsked: () => <SurveyManagementTableLoading />,
    Loading: () => <SurveyManagementTableLoading />,
    Failure: () => <SurveyManagementTableFailed action={cardAction} />,
    Success: (response) => {
      const invitations =
        response.assessmentCareEpisodeScaleSummary?.invitations
          .map((rawInvitation) =>
            transformInvitation(rawInvitation, props.patient.id, props.careEpisodeId, t)
          )
          .filter((invitation) => {
            return invitation.status != "NOT_REQUIRED";
          }) || [];

      cardAction = props.forPreviousEnrollment ? (
        <></>
      ) : (
        <Box marginRight="0.3em" sx={{ display: "flex" }}>
          <ResendAllNotificationsButton
            patientId={props.patient.id}
            careEpisodeId={props.careEpisodeId}
            count={
              response.assessmentCareEpisodeScaleSummary?.invitations.filter((rawInvitation) => {
                return rawInvitation.canResendNotifications;
              }).length
            }
          />
          &nbsp;
          <SendImmediateSurveyButton patient={props.patient} />
        </Box>
      );

      return (
        <LoadedSurveyManagementTableMobile
          invitations={invitations}
          intakeStatus={props.intakeStatus}
          patient={props.patient}
          careEpisodeId={props.careEpisodeId}
          action={cardAction}
          forPreviousEnrollment={props.forPreviousEnrollment}
        />
      );
    },
  });
}

type LoadedSurveyManagementTableMobileProps = {
  invitations: Array<InviteSummary>;
  intakeStatus: IntakeStatus;
  patient: PatientDetails;
  careEpisodeId: CareEpisodeId;
  action: ReactNode;
  forPreviousEnrollment?: boolean;
};

function LoadedSurveyManagementTableMobile(props: LoadedSurveyManagementTableMobileProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [page, setPage] = React.useState<number>(0);
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const [pageSize, setPageSize] = React.useState<number>(5);
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [sortColumn, setSortColumn] = React.useState<keyof InviteSummary>("sentAt");
  const [sortColumnString, setSortColumnString] = React.useState<string>(
    t("collaborativeCare:surveyManagement.headers.lastSent")
  );

  const handleChangeSortColumn = (newColumn: keyof InviteSummary) => {
    //don't do anything if we're on the same column they just clicked
    if (newColumn == sortColumn) {
      return;
    }
    //if we're moving _to_ the `scalesSortKey` column, ascending is 0, descending is 1
    if (newColumn == "scalesSortKey" && sortDirection == 0) {
      setSortDirection(1);
    } else if (newColumn == "scalesSortKey" && sortDirection == 1) {
      setSortDirection(0);
    }

    //if we're moving _from_ the `scalesSortKey` column, swap ascending and descending
    if (sortColumn == "scalesSortKey" && sortDirection == 0) {
      setSortDirection(1);
    } else if (sortColumn == "scalesSortKey" && sortDirection == 1) {
      setSortDirection(0);
    }

    setPage(0);
    setSortColumn(newColumn);
  };

  //0 has the most recent at the top, 1 has the oldest at the top
  const [sortDirection, setSortDirection] = React.useState<number>(0);
  const handleSetSortDirection = (newDirection: number) => {
    if (sortColumn == "scalesSortKey" && newDirection == 0) {
      newDirection = 1;
    } else if (sortColumn == "scalesSortKey" && newDirection == 1) {
      newDirection = 0;
    }
    setSortDirection(newDirection);
  };

  const [sortDirectionString, setSortDirectionString] = React.useState<string>("Descending");

  props.invitations.sort(function (a, b) {
    const accessA = (str: keyof typeof a) => {
      return a[str] || 0;
    };
    const accessB = (str: keyof typeof b) => {
      return b[str] || 0;
    };
    if (sortDirection != 0) {
      // swap a and b so they sort the other way
      const c = b;
      b = a;
      a = c;
    }

    const valueA = accessA(sortColumn);
    const valueB = accessB(sortColumn);
    if (valueA > valueB) {
      return -1;
    } else if (valueB > valueA) {
      return 1;
    } else return 0;
  });

  let noInvitesElement = <></>;
  if (props.invitations.length == 0) {
    if (props.intakeStatus != IntakeStatus.COMPLETE) {
      noInvitesElement = <Typography>{t("collaborativeCare:surveyManagement.intakeIncomplete")}</Typography>;
    } else {
      noInvitesElement = <Typography>{t("collaborativeCare:surveyManagement.noInvites")}</Typography>;
    }
    return (
      <Card>
        <CardHeader
          title={t("collaborativeCare:surveyManagement.title")}
          action={
            <Box marginRight="0.3em" sx={{ display: "flex" }}>
              <SendImmediateSurveyButton patient={props.patient} />
            </Box>
          }
        />
        <CardContent>{noInvitesElement}</CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:surveyManagement.title")} action={props.action} />
      <CardContent>
        <SurveyManagementMobileTableHeader
          sortColumn={sortColumn}
          sortColumnName={sortColumnString}
          sortDirection={sortDirection}
          sortDirectionName={sortDirectionString}
          onSortColumnChange={handleChangeSortColumn}
          onSortColumnNameChange={setSortColumnString}
          onSortDirectionChange={handleSetSortDirection}
          onSortDirectionNameChange={setSortDirectionString}
        />
        {props.invitations.slice(page * pageSize, page * pageSize + pageSize).map((invite, index) => (
          <SurveyManagementMobileTableRow
            key={index}
            invitation={invite}
            patientId={props.patient.id}
            careEpisodeId={props.careEpisodeId}
            forPreviousEnrollment={props.forPreviousEnrollment}
          />
        ))}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.invitations.length}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ fontSize: "1rem", overflow: "hidden" }}
        />
      </CardContent>
    </Card>
  );
}

type SurveyManagementMobileHeaderProps = {
  sortDirection: number;
  sortColumn: keyof InviteSummary;
  sortColumnName: string;
  sortDirectionName: string;
  onSortColumnChange: (sortColumn: keyof InviteSummary) => void;
  onSortColumnNameChange: (columnName: string) => void;
  onSortDirectionChange: (sortDirection: number) => void;
  onSortDirectionNameChange: (directionName: string) => void;
};

function SurveyManagementMobileTableHeader(props: SurveyManagementMobileHeaderProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [sortAnchor, setSortAnchor] = React.useState<Element | null>(null);
  const sortOpen = sortAnchor != null;
  const handleSortOpen = (event: React.MouseEvent) => {
    setSortAnchor(event.currentTarget);
  };
  const handleSortClose = () => {
    setSortAnchor(null);
  };

  const [directionAnchor, setDirectionAnchor] = React.useState<Element | null>(null);
  const directionOpen = directionAnchor != null;
  const handleDirectionOpen = (event: React.MouseEvent) => {
    setDirectionAnchor(event.currentTarget);
  };
  const handleDirectionClose = () => {
    setDirectionAnchor(null);
  };

  return (
    <Typography sx={{ fontSize: "1rem", textAlign: "right" }}>
      {t("collaborativeCare:surveyManagement.sortBy")}
      <IconButton sx={{ fontSize: "1rem" }} onClick={handleSortOpen}>
        <ArrowDropDown sx={{ fontSize: "1rem" }} />
        {props.sortColumnName}
      </IconButton>
      <Menu open={sortOpen} onClose={handleSortClose} anchorEl={sortAnchor}>
        <MenuItem
          onClick={function () {
            props.onSortColumnChange("userName");
            props.onSortColumnNameChange(t("collaborativeCare:surveyManagement.headers.recipient"));
            handleSortClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.headers.recipient")}
        </MenuItem>
        <MenuItem
          onClick={function () {
            props.onSortColumnChange("scalesSortKey");
            props.onSortColumnNameChange(t("collaborativeCare:surveyManagement.headers.measure"));
            handleSortClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.headers.measure")}
        </MenuItem>
        <MenuItem
          onClick={function () {
            props.onSortColumnChange("status");
            props.onSortColumnNameChange(t("collaborativeCare:surveyManagement.headers.status"));
            handleSortClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.headers.status")}
        </MenuItem>
        <MenuItem
          onClick={function () {
            props.onSortColumnChange("sentAt");
            props.onSortColumnNameChange(t("collaborativeCare:surveyManagement.headers.lastSent"));
            handleSortClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.headers.lastSent")}
        </MenuItem>
        <MenuItem
          onClick={function () {
            props.onSortColumnChange("closesAt");
            props.onSortColumnNameChange(t("collaborativeCare:surveyManagement.headers.expiresOn"));
            handleSortClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.headers.expiresOn")}
        </MenuItem>
      </Menu>
      <IconButton sx={{ fontSize: "1rem" }} onClick={handleDirectionOpen}>
        <ArrowDropDown sx={{ fontSize: "1rem" }} />
        {props.sortDirectionName}
      </IconButton>
      <Menu open={directionOpen} onClose={handleDirectionClose} anchorEl={directionAnchor}>
        <MenuItem
          onClick={function () {
            props.onSortDirectionChange(0);
            props.onSortDirectionNameChange("Descending");
            handleDirectionClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.descending")}
        </MenuItem>
        <MenuItem
          onClick={function () {
            props.onSortDirectionChange(1);
            props.onSortDirectionNameChange("Ascending");
            handleDirectionClose();
          }}
        >
          {t("collaborativeCare:surveyManagement.ascending")}
        </MenuItem>
      </Menu>
    </Typography>
  );
}

type SurveyManagementMobileTableRowProps = {
  invitation: InviteSummary;
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  forPreviousEnrollment?: boolean;
};

function SurveyManagementMobileTableRow(props: SurveyManagementMobileTableRowProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [historyOpen, setHistoryOpen] = React.useState<boolean>(false);
  const invitationDetailsModal = (
    <InvitationHistory
      open={historyOpen}
      onClose={() => {
        setHistoryOpen(false);
      }}
      rawInvitation={props.invitation.rawInvitation}
      careEpisodeId={props.careEpisodeId}
      forPreviousEnrollment={props.forPreviousEnrollment}
    ></InvitationHistory>
  );

  const sentAt = props.invitation.sentAt
    ? "at " +
      t("common:date.tinyWithTime", {
        date: props.invitation.sentAt,
      })
    : "";
  const closesAt = props.invitation.closesAt
    ? t("common:date.tinyWithTime", {
        date: props.invitation.closesAt,
      })
    : "";
  const methods = props.invitation.methods.map(function (m, index) {
    if (m == "email") {
      return (
        <Fragment key={index}>
          <EmailIcon />{" "}
        </Fragment>
      );
    } else if (m == "text") {
      return (
        <Fragment key={index}>
          <TextsmsIcon />
        </Fragment>
      );
    }
    return <>{m}</>;
  });

  const style = { marginBottom: "10px" };
  const boxStyle = { marginBottom: "20px", marginTop: "20px" };
  return (
    <>
      <Grid sx={boxStyle} container spacing={0.5} rowGap={0.5}>
        <Grid item sm={8} xs={8}>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.recipient")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              {props.invitation.userName}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.measure")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              {props.invitation.scaleLinkElements}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.status")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              <InvitationStatusElement invitation={props.invitation}></InvitationStatusElement>
              <IconButton
                size="small"
                onClick={() => {
                  setHistoryOpen(true);
                }}
              >
                <InfoIcon fontSize="small" />
              </IconButton>
            </Typography>
          </div>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.lastSent")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              {sentAt}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.expiresOn")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              {closesAt}
            </Typography>
          </div>
          <div>
            <Typography variant="h3" display="inline">
              {t("collaborativeCare:surveyManagement.headers.sentBy")}:{" "}
            </Typography>
            <Typography sx={style} display="inline">
              {methods}
            </Typography>
          </div>
        </Grid>
        <Grid item sm={4} xs={4}>
          <ActionsMobile
            invite={props.invitation}
            careEpisodeId={props.careEpisodeId}
            patientId={props.patientId}
            forPreviousEnrollment={props.forPreviousEnrollment}
          />
        </Grid>
      </Grid>
      {invitationDetailsModal}
      <Divider />
    </>
  );
}

function ActionsMobile(props: {
  invite: InviteSummary;
  careEpisodeId: CareEpisodeId;
  patientId: PatientId;
  forPreviousEnrollment?: boolean;
}) {
  const { t } = useTranslation(["collaborativeCare"]);

  let reportLink = "";
  if (props.invite.scales[0]) {
    reportLink = `/app/care-units/${props.patientId}/care-episodes/${
      props.careEpisodeId
    }/feedback-report/scale-${props.invite.scales[0].scaleGroupId ? "group" : "administration"}-history/${
      props.invite.scales[0].scaleGroupId || props.invite.scales[0].id
    }`;
  }
  const style = { textAlign: "center", width: "100%" };
  const element = (
    <>
      <Button
        sx={style}
        variant="contained"
        color="secondary"
        disabled={props.forPreviousEnrollment || !props.invite.rawInvitation.manuallyAdministerable || false}
        href={`/provider/assessment/invitation/${props.invite.id}/edit`}
      >
        {t("collaborativeCare:surveyManagement.actions.administerNow")}
      </Button>
      &nbsp;
      <SendReminderButton
        disabled={props.forPreviousEnrollment || !props.invite.rawInvitation.canResendNotifications || false}
        invitationId={props.invite.id}
        careEpisodeId={props.careEpisodeId}
        mobile={true}
      />
      &nbsp;
      <CancelInvitationButton
        disabled={!props.invite.manuallyCancelable}
        invitationId={props.invite.id}
        careEpisodeId={props.careEpisodeId}
        mobile={true}
      ></CancelInvitationButton>
      &nbsp;
      <Link to={reportLink}>
        <Button
          sx={style}
          variant="contained"
          color="secondary"
          disabled={props.invite.status != t("collaborativeCare:surveyManagement.status.complete")}
        >
          {t("collaborativeCare:surveyManagement.actions.viewReport")}
        </Button>
      </Link>
      &nbsp;
      <DownloadPdfButton
        disabled={!props.invite.downloadAvailable}
        invitationId={props.invite.id}
        mobile={true}
      />
    </>
  );
  return element;
}
