import React, { ReactElement, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Breadcrumbs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useIsMobile } from "Shared/Responsive";
import { SupportsPanelsOverrideProvider } from "Contexts/SelectedPanelContext";
import {
  InstituteGroupMode,
  SupportsInstituteGroupsOverrideProvider,
} from "Contexts/ActiveInstituteGroupContext";
import { useInEmbeddedPatientOrEncounter } from "Contexts/LaunchContext";

const StyledRootDiv = styled("div")(({ theme }) => ({
  minHeight: "100%",
  // Embedded patient pages omit the top navigation, so we should also omit the space we leave for it here
  paddingTop: useInEmbeddedPatientOrEncounter() ? 0 : useIsMobile() ? theme.spacing(0.5) : theme.spacing(2.5),
  paddingBottom: theme.spacing(9),
  paddingLeft: useIsMobile() ? theme.spacing(0.2) : theme.spacing(2),
  marginRight: useIsMobile() ? theme.spacing(0) : theme.spacing(2),
}));

const BreadcrumbContainer = styled("div")(({ theme }) => ({
  marginTop: useIsMobile() ? theme.spacing(1) : 0,
  paddingBottom: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.common.white}`,
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  maxWidth: "100vw",
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  // The MUI breadcrumb component implicitly wraps all its children in <li> tags, and that's what we target here to
  // turn the first/root breadcrumb element bold.
  "& li:first-of-type": {
    fontWeight: "bold",
  },
}));

type BreadcrumbProps = {
  children: React.ReactNode;
};

function PageBreadcrumbs(props: BreadcrumbProps): ReactElement {
  return (
    <BreadcrumbContainer>
      <StyledBreadcrumbs>{props.children}</StyledBreadcrumbs>
    </BreadcrumbContainer>
  );
}

type Props = {
  browserTitle: string;
  children: ReactNode;
  breadcrumbs?: ReactNode;
  supportsPanels?: boolean;
  instituteGroupOverride?: InstituteGroupMode;
};

function Page(props: Props): ReactElement {
  const { browserTitle, children } = props;

  const breadcrumbs = props.breadcrumbs ? <PageBreadcrumbs>{props.breadcrumbs}</PageBreadcrumbs> : null;

  return (
    <SupportsPanelsOverrideProvider supportsPanels={!!props.supportsPanels}>
      <SupportsInstituteGroupsOverrideProvider overrideMode={props.instituteGroupOverride}>
        <StyledRootDiv>
          <Helmet>
            <title>{browserTitle}</title>
          </Helmet>
          {breadcrumbs}
          <ContentContainer>{children}</ContentContainer>
        </StyledRootDiv>
      </SupportsInstituteGroupsOverrideProvider>
    </SupportsPanelsOverrideProvider>
  );
}

export default Page;
