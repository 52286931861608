import React, { useEffect, useState } from "react";
import { ApolloError } from "@apollo/client";
import {
  EntityTreeNodeParams,
  MetricRollupType,
  MetricScaleScorerConfigurationPayload,
  ScaleScorersConfigurationQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ScaleScorerId } from "Lib/Ids";
import { RemoteData } from "seidr";
import ScaleScorerDetails from "./FormElements/ScaleScorerDetails";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { SectionHelpText } from "./CreateTimeBasedMetricForm";
import MultiScaleScorerDetails from "./FormElements/MultiScaleScorerDetails";
import Spinner from "Shared/Spinner";

type MetricRollupAndScalesForm = {
  defaults: MetricRollupType | null;
  scaleScorerConfigParams: MetricScaleScorerConfigurationPayload | null;
  scaleScorerConfigData: RemoteData<ApolloError, ScaleScorersConfigurationQuery>;
  onChange: (value: MetricScaleScorerConfigurationPayload | null) => void;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

function MetricRollupAndScalesForm(props: MetricRollupAndScalesForm) {
  const { t } = useTranslation(["outcomes"]);
  const [rollup, setRollup] = useState<MetricRollupType | null>(props.defaults);

  // If the default changes it means we've changed template so should force it to that state.
  useEffect(() => {
    setRollup(props.defaults);
  }, [props.defaults]);

  let mainSection = null;

  if (rollup === MetricRollupType.SINGLE_SCALE) {
    const scaleScorerId = props.scaleScorerConfigParams?.singleScale || null;
    const onSingleScaleChange = (id: ScaleScorerId | null) => {
      if (id) {
        props.onChange({ singleScale: id });
      } else {
        props.onChange(null);
      }
    };
    mainSection = (
      <ScaleScorerDetails {...props} scaleScorerId={scaleScorerId} onChange={onSingleScaleChange} />
    );
  } else if (rollup === MetricRollupType.MULTI_SCALE) {
    mainSection = <MultiScaleScorerDetails {...props} />;
  }

  const onChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === MetricRollupType.MULTI_SCALE || value === MetricRollupType.SINGLE_SCALE) {
      setRollup(value);
    } else {
      setRollup(null);
    }
    // Reset the form
    props.onChange(null);
  };

  return (
    <>
      <Card>
        <CardHeader title={t("outcomes:create.rollup.title")} />
        <CardContent>
          <FormControl>
            <SectionHelpText text={t("outcomes:create.rollup.helpText")} />
            <RadioGroup value={rollup} onChange={onChange}>
              <FormControlLabel
                value={MetricRollupType.SINGLE_SCALE}
                control={<Radio />}
                label={t("outcomes:create.rollup.single")}
              />
              <FormControlLabel
                value={MetricRollupType.MULTI_SCALE}
                control={<Radio />}
                label={t("outcomes:create.rollup.multi")}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
      {mainSection}
    </>
  );
}

type MetricRollupAndScalesFormWrapperProps = {
  defaults: RemoteData<Error, MetricRollupType | null>;
  scaleScorerConfigParams: MetricScaleScorerConfigurationPayload | null;
  scaleScorerConfigData: RemoteData<ApolloError, ScaleScorersConfigurationQuery>;
  onChange: (value: MetricScaleScorerConfigurationPayload | null) => void;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

export default function MetricRollupAndScalesFormWrapper(props: MetricRollupAndScalesFormWrapperProps) {
  return props.defaults.caseOf({
    Success: (defaults) => {
      return <MetricRollupAndScalesForm {...props} defaults={defaults} />;
    },
    NotAsked: () => {
      return <MetricRollupAndScalesForm {...props} defaults={null} />;
    },
    Failure: () => {
      return <MetricRollupAndScalesForm {...props} defaults={null} />;
    },
    Loading: () => {
      return <Spinner />;
    },
  });
}
