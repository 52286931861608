import React, { ReactElement } from "react";
import MuiMarkdown from "mui-markdown";
import { List, ListItem, ListItemText } from "@mui/material";

// This basically resets some of the properties that Mui adds to list items so that they have native html behavior
// again while still being something we can target with our theme. The key is to remove display: flex and replace it
// with display: list-item, which lets it have a bullet/number applied to it again. Removing the padding makes it not
// float so far away from the bullet.
function NativeHtmlListItem(props: React.PropsWithChildren): ReactElement {
  return (
    <ListItem sx={{ listStyle: "inherit", display: "list-item", paddingLeft: 0 }}>
      <ListItemText sx={{ margin: 0 }}>{props.children}</ListItemText>
    </ListItem>
  );
}

type RichTextProps = {
  children: string | undefined | null;
};

/**
 * Takes a markdown-formatted string and renders it using Mui components. This intentionally disables the markdown
 * feature of including raw html snippets so that we don't open an XSS vector on ourselves.
 *
 * If we ever use this to render code blocks (which seems unlikely) we can supply a Mirah-branded code coloring theme
 * to the renderer.
 */
export function RichText(props: RichTextProps): ReactElement {
  return (
    <MuiMarkdown
      options={{
        disableParsingRawHTML: true,
        // The MuiMarkdown project doesn't override these components by default for some reason. Note that there's also
        // an overrides prop on MuiMarkdown instead of going through the options hash (which is for props that get
        // get passed to the underline markdown-to-jsx project). However, that prop doesn't seem to work, so don't use
        // it.
        overrides: {
          // Because we set disc and decimal unconditionally here we won't get the native behavior of changing the
          // bullet style as the indentation increased. I do not anticipate a lot of our users writing bulleted lists in
          // markdown.
          ul: { component: List, props: { sx: { listStyle: "disc", paddingLeft: 1 } } },
          ol: { component: List, props: { sx: { listStyle: "decimal", paddingLeft: 1 } } },
          li: { component: NativeHtmlListItem, props: {} },
        },
      }}
    >
      {/* Jump through a hoop to convert null to undefined here because the graphql codegen defines a nullable
          field as `T | null` while the markdown component only wants `T | undefined`. */}
      {props.children || undefined}
    </MuiMarkdown>
  );
}
