import { Link, Paper } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

import React, { ReactElement } from "react";

import {
  InstitutePatientAppointmentListQuery,
  useInstitutePatientAppointmentListQuery,
  InstitutePatientAppointmentListQueryVariables,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { PatientId } from "Lib/Ids";

type ExtraVars = Pick<InstitutePatientAppointmentListQueryVariables, "patientId">;

function AppointmentTab({ patientId }: { patientId: PatientId }): ReactElement {
  const extra: ExtraVars = {
    patientId: patientId,
  };
  const columns: DataGridCols<InstitutePatientAppointmentListQuery, ["schedulingAppointments"]> =
    React.useMemo(() => {
      return [
        {
          field: "id",
          headerName: "ID",
          filterable: false,
          sortable: false,
          flex: 1,
          renderCell: (params: GridRenderCellParams) => (
            <Link component={RouterLink} to={`../../appointments/${params.row["id"]}`}>
              {params.value}
            </Link>
          ),
        },
        {
          field: "startDate",
          headerName: "Start Date",
          filterable: false,
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) => row.startDate?.toLocaleString(),
        },
        {
          field: "status",
          headerName: "Status",
          filterable: false,
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) => row.status,
        },

        {
          field: "session",
          headerName: "Session?",
          filterable: false,
          sortable: false,
          flex: 1,
          type: "boolean",
          valueGetter: (_value, row) => !!row.patientSession,
        },
      ];
    }, []);

  return (
    <>
      <Paper>
        <SortablePagableCollectionDataGrid
          queryHook={useInstitutePatientAppointmentListQuery}
          unwrapData={(d) => d?.schedulingAppointments || null}
          queryVariables={extra}
          defaultPageSize={10}
          colNameToSortParam={(_colName) => null}
          columns={columns}
        />
      </Paper>
    </>
  );
}

export default AppointmentTab;
