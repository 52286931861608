import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { CSSProperties, ReactElement, useState } from "react";
import { TaskDetails } from "./TaskCard";
import {
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import EditableTaskTitle from "./EditableTaskTitle";
import { useTranslation } from "react-i18next";
import { TaskCardBody } from "./TaskCardBody";
import { TaskActions } from "./TaskActions";
import PlaceholderOptions from "../PlaceholderOptions";
import { TaskStatusBadge } from "./TaskCompleteForm";
import { TaskId } from "Lib/Ids";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useTaskCardBackTimelineQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { EventDialogType, Timeline } from "CollaborativeCare/Timeline";
import { TIMELINE_FLAG, WithFrontendFlag, WithoutFrontendFlag } from "Contexts/FrontendFlagContext";

type TaskCardBackProps = {
  open: boolean;
  onClose: () => void;
  task: TaskDetails;
  openDetails: (val: boolean) => void;
};
// This component is meant to represent the modal dialog we show when viewing details about a task card.
// This detail view we're referring to as the "back" of the card, while the bits we show normally
// about a card is the "front".
export function TaskCardBack(props: TaskCardBackProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const theme = useTheme();

  const [showBodyAndActions, setShowBodyAndActions] = useState(true);

  const displayedTask = props.task;

  const patientHeader = displayedTask.patient ? (
    <PatientReferenceCardHeader patientId={displayedTask.patient.id} disableCardPadding={true} />
  ) : null;

  const dueAtSubhead = displayedTask.dueAt ? (
    <Typography variant="subtitle1">
      {t("collaborativeCare:tasks.dueAtSubhead", { date: displayedTask.dueAt })}
    </Typography>
  ) : null;

  let headerContent = (
    <>
      <EditableTaskTitle task={displayedTask} />
      {dueAtSubhead}
    </>
  );
  if (displayedTask.isPlaceholder && displayedTask.patient?.id) {
    headerContent = (
      <PlaceholderOptions
        taskId={displayedTask.id}
        patientId={displayedTask.patient.id}
        setShowSurroundingForms={setShowBodyAndActions}
      />
    );
  }

  let bodyContent = <TaskCardBody task={displayedTask} />;
  let actionsContent = (
    <DialogActions>
      <TaskActions
        task={displayedTask}
        expand={props.openDetails}
        showBackButton={true}
        onClickBackButton={props.onClose}
      />
    </DialogActions>
  );
  let statusContent = (
    <>
      <TaskStatusBadge
        disabled={false}
        task={props.task}
        patient={props.task.patient}
        currentStatus={props.task.status}
        size={"small"}
      />
    </>
  );
  if (!showBodyAndActions) {
    bodyContent = <></>;
    actionsContent = <></>;
    statusContent = <></>;
  }

  // We actually have two different versions of the card, depending on whether or not timelines are turned on.
  return (
    <>
      <WithFrontendFlag flagName={TIMELINE_FLAG}>
        <ResponsiveDialog
          dialogWidth="100%" // This is necessary to keep it from resizing based on tab content size.
          dialogMaxWidth="85%"
          dialogPadding="0"
          dialogBackgroundColor={theme.palette.background.default}
          open={props.open}
          onClose={props.onClose}
          hideTitle={true}
        >
          <DialogContent>
            <Grid container columns={14} spacing={1}>
              <Grid item lg={6} xs={14}>
                <Card sx={{ height: "100%" }}>
                  <CardContent>
                    {patientHeader}
                    {headerContent}
                    {statusContent}
                    {bodyContent}
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={8} xs={14}>
                <TaskCardBackTimeline taskId={props.task.id} />
              </Grid>
            </Grid>
          </DialogContent>
          <Paper sx={{ marginTop: "1em" }}>{actionsContent}</Paper>
        </ResponsiveDialog>
      </WithFrontendFlag>
      <WithoutFrontendFlag flagName={TIMELINE_FLAG}>
        <ResponsiveDialog
          dialogWidth="100%" // This is necessary to keep it from resizing based on tab content size.
          open={props.open}
          onClose={props.onClose}
          title={displayedTask.title}
          hideTitle={true}
        >
          <DialogContent>
            {patientHeader}
            {headerContent}
            {statusContent}
            {bodyContent}
          </DialogContent>
          {actionsContent}
        </ResponsiveDialog>
      </WithoutFrontendFlag>
    </>
  );
}

type TaskCardBackTimelineProps = {
  taskId: TaskId;
};

function TaskCardBackTimeline(props: TaskCardBackTimelineProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(
    useTaskCardBackTimelineQuery({
      variables: {
        taskId: props.taskId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => {
      return <TimelineCardSkeleton />;
    },
    Loading: () => {
      return <TimelineCardSkeleton />;
    },
    Failure: () => {
      return <TimelineCardListError />;
    },
    Success: (result) => {
      if (!result.collaborativeCareTimeEntryLogs) {
        return <TimelineCardListError />;
      }

      const cardContentStyle: CSSProperties = {
        overflow: "auto",
      };

      return (
        <Card sx={{ height: "100%" }}>
          <CardHeader title={t("collaborativeCare:patientDetails.cards.timeline")} />
          <CardContent style={cardContentStyle}>
            <Timeline
              events={result.collaborativeCareTimeEntryLogs.nodes}
              eventDialogType={EventDialogType.TimeEntryLog}
            />
          </CardContent>
        </Card>
      );
    },
  });
}

function TimelineCardSkeleton(): ReactElement {
  return <Skeleton variant="rectangular" height="100%" />;
}

function TimelineCardListError(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return <Typography>{t("collaborativeCare:patientDetails.timeline.failure")}</Typography>;
}
