import React, { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";
import { FeedbackReportRoute } from "../FeedbackReport/FeedbackReportRoute";
import ReportRedirectRoute from "FeedbackReport/ReportRedirectRoute";

function CareUnits(): ReactElement {
  return (
    <Routes>
      <Route
        element={<FeedbackReportRoute />}
        path=":patientId/care-episodes/:careEpisodeId/feedback-report/*"
      />
      <Route element={<ReportRedirectRoute />} path=":patientId/report" />
    </Routes>
  );
}

export { CareUnits };
