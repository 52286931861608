import { MonthParams } from "GeneratedGraphQL/SchemaAndOperations";

export function dateToMonth(date: Date): MonthParams {
  return {
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
}

export function monthToDate(month: MonthParams): Date {
  return new Date(month.year, month.month - 1, 1);
}

export function monthToday(): MonthParams {
  return dateToMonth(new Date());
}

export function endOfMonth(month: MonthParams) {
  // Don't add one to the month, and then use 0 as the last day of the previous
  return new Date(month.year, month.month, 0);
}
