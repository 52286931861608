import { Maybe, Nothing, Just, RemoteData, Result, Failure, Success, Err, Ok } from "seidr";
import * as NEL from "./NonEmptyList";
import {
  any,
  uniq,
  assoc,
  assocPath,
  sort,
  sortBy,
  filter,
  isNil,
  groupBy,
  values,
  mapObjIndexed,
  not,
  pipe,
  zip,
  splitEvery,
  fromPairs,
  toPairs,
  update,
} from "ramda";
import SumType from "sums-up";
import { Duration, parseISO } from "date-fns";

// -- Types -------------------------------------------------------------------

export type Opaque<A> = A & { readonly _: unique symbol };
export type Phantom<Type, Data> = { _type: Type } & Data;

// -- Function ----------------------------------------------------------------

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noOp(): void {}

// -- Number ------------------------------------------------------------------

function toInt(string: string): Maybe<number> {
  const value = parseInt(string);

  return isNaN(value) ? Nothing() : Just(value);
}

function isEven(n: number): boolean {
  return n % 2 === 0;
}

function isOdd(n: number): boolean {
  return !isEven(n);
}

// -- List --------------------------------------------------------------------

// A class to hold our array utility methods alongside the regular array methods
// so that we can do array operations through method chaining instead of
// regular function calls. The main advantage of chaining is that we can write
// our code "in order", like
//
//    return new ExtendedReadonlyArray(values)
//      .flatMap(transformVals)
//      .filter(goodValues)
//      .head()
//
// instead of "inside out", like
//
//    return head(
//      values
//        .flatMap(transformVals)
//        .filter(goodValues)
//    )
//
// A small example with just head isn't too bad, but the problem gets worse as
// you need to use more utility operations.
//
// This is a partial implementation - in principle this class should include
// all the built-in Array method as well as all our list utility methods in this
// file. Add to this as you need to.
class ExtendedReadonlyArray<T> {
  private inner: ReadonlyArray<T>;

  public constructor(inner: ReadonlyArray<T>) {
    this.inner = inner;
  }

  public flatMap<U>(xform: (t: T) => ReadonlyArray<U>): ExtendedReadonlyArray<U> {
    return new ExtendedReadonlyArray(this.inner.flatMap(xform));
  }

  public map<U>(xform: (t: T) => U): ExtendedReadonlyArray<U> {
    return new ExtendedReadonlyArray(this.inner.map(xform));
  }

  public filter(pred: (t: T) => boolean): ExtendedReadonlyArray<T> {
    return new ExtendedReadonlyArray(this.inner.filter(pred));
  }

  public reduce<U>(reducer: (accumulator: U, next: T) => U, initial: U): U {
    return this.inner.reduce(reducer, initial);
  }

  public head(): Maybe<T> {
    return Maybe.fromNullable(this.inner[0]);
  }

  // For when you need to bail out an extended array into another part of the
  // code that's expecting a regular array.
  public toArray(): ReadonlyArray<T> {
    return this.inner;
  }
}

function head<T>(list: ReadonlyArray<T>): Maybe<T> {
  return Maybe.fromNullable(list[0]);
}

function tail<T>(list: ReadonlyArray<T>): Array<T> {
  return list.slice(1, list.length);
}

function init<T>(list: ReadonlyArray<T>): Array<T> {
  return take(list.length - 1, list);
}

function uncons<T>(list: ReadonlyArray<T>): Maybe<[T, Array<T>]> {
  return head(list).map((t) => [t, tail(list)]);
}

function unsnoc<T>(list: ReadonlyArray<T>): Maybe<[Array<T>, T]> {
  return last(list).map((t) => [init(list), t]);
}

function last<T>(list: ReadonlyArray<T>): Maybe<T> {
  return Maybe.fromNullable(list[list.length - 1]);
}

function find<T>(pred: (t: T) => boolean, list: ReadonlyArray<T>): Maybe<T> {
  return Maybe.fromNullable(list.find(pred));
}

function take<A>(n: number, as: ReadonlyArray<A>): Array<A> {
  return as.slice(0, n);
}

function takeLast<A>(n: number, as: ReadonlyArray<A>): Array<A> {
  return as.slice(Math.max(as.length - n, 0));
}

function arrOf<A>(a: A): Array<A> {
  return [a];
}

function arrOfN(n: number): Array<number> {
  return [...Array(n).keys()];
}

function ensureArr<A>(as: Array<A> | A): Array<A> {
  return Array.isArray(as) ? as : [as];
}

function partition<A>(
  predicate: (a: A, i: number) => boolean,
  arr: ReadonlyArray<A>
): [ReadonlyArray<A>, ReadonlyArray<A>] {
  return arr.reduce<[Array<A>, Array<A>]>(
    (acc, a, i) => {
      if (predicate(a, i)) {
        acc[0].push(a);
      } else {
        acc[1].push(a);
      }

      return acc;
    },
    [[], []]
  );
}

function inPairs<A>(arr: ReadonlyArray<A>): ReadonlyArray<[A, A]> {
  const partitions = partition((_, i) => isEven(i), arr);
  return zip(...partitions);
}

// -- NonEmptyList ------------------------------------------------------------

function max(as: NEL.NonEmptyList<number>): number {
  return Math.max(...NEL.toArray(as));
}

function min(as: NEL.NonEmptyList<number>): number {
  return Math.min(...NEL.toArray(as));
}

function maxBy<A>(f: (a: A) => number, as: NEL.NonEmptyList<A>): A {
  const vals = as.map(f);
  const i = NEL.toArray(vals).indexOf(max(vals));
  return NEL.toArray(as)[i] || NEL.head(as);
}

function minBy<A>(f: (a: A) => number, as: NEL.NonEmptyList<A>): A {
  const vals = as.map(f);
  const i = NEL.toArray(vals).indexOf(min(vals));
  return NEL.toArray(as)[i] || NEL.head(as);
}

// - Normal Array -------------------------------------------------------------

function arrayMaxBy<A>(f: (a: A) => number, as: ReadonlyArray<A>): A | null {
  return NEL.fromArray(as).caseOf({
    Just: (nel) => maxBy(f, nel),
    Nothing: () => null,
  });
}

function arrayMinBy<A>(f: (a: A) => number, as: ReadonlyArray<A>): A | null {
  return NEL.fromArray(as).caseOf({
    Just: (nel) => minBy(f, nel),
    Nothing: () => null,
  });
}

// -- Object ------------------------------------------------------------------

/**
 * Create an object with the given key present only if the maybe given is a Just, else produces an empty object.
 * Useful for splatting objects:, e.g:
 *   { ...objofJust('myKey', Just('myValue') )}
 * @param key the key to be created if the value is a Just
 * @param maybe the wrapped value
 */
function objOfJust<T>(key: string, maybe: Maybe<T>): Record<string, T> {
  return maybe.caseOf({
    Just: (value) => ({ [key]: value }),
    Nothing: () => ({}),
  });
}

/**
 * Create an object with the given key present only if the value is not null.
 * @param key the key to be created if the value is non null
 * @param value the value
 */
function objOfOr<T>(key: string, value: T | null | undefined): Record<string, T> {
  if (value) {
    return { [key]: value };
  } else {
    return {};
  }
}

function prop<A, K extends keyof A>(k: K): (a: A) => A[K] {
  return (a: A): A[K] => {
    return a[k];
  };
}

export type WithName = {
  name: string;
  firstName: Maybe<string>;
  preferredFirstName: Maybe<string>;
  legalFirstName: Maybe<string>;
};

// Attempt to use a firstName field, falling back on the first word in the name
function firstName(a: WithName): string {
  const firstWordInName = capitalize(NEL.head(words(a.name)));
  return a.preferredFirstName
    .orElse(() => a.legalFirstName)
    .orElse(() => a.firstName)
    .map(capitalize)
    .getOrElse(firstWordInName);
}

/**
 * Create a new object using the fields from the base object but replacing the key given with the value given
 * @param object The object to use as a base
 * @param key The key to replace
 * @param value
 */
function assocKey<O, K extends keyof O, V extends O[K]>(key: K, value: V, object: O): O & Record<K, V> {
  return {
    ...object,
    [key]: value,
  };
}

// -- String ------------------------------------------------------------------

function nullifyEmptyString(str: string): string | null {
  if (str === "") {
    return null;
  } else {
    return str;
  }
}

function toUpper(s: string): string {
  return s.toUpperCase();
}

function toLower(s: string): string {
  return s.toLowerCase();
}

function initials(str: string): string {
  const words = str.split(" ").map(toUpper);

  return uniq(justs([head(words), last(words)]))
    .map((w) => w[0])
    .join("");
}

function words(str: string): NEL.NonEmptyList<string> {
  const ws = str.split(" ");
  return NEL.NonEmptyList(ws[0] || str, tail(ws));
}

function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function titleize(str: string): string {
  return str.split(" ").map(toLower).map(capitalize).join(" ");
}

function decamelize(str: string): string {
  return str
    .replace(/([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu, `$1 $2`)
    .replace(/(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu, `$1 $2`)
    .toLowerCase();
}

function desnakeize(str: string): string {
  return str.replace(/_/g, " ").toLowerCase();
}

function humanize(str: string | null): string {
  if (!str) {
    return "";
  }
  return capitalize(desnakeize(decamelize(str)));
}

function humanizeNullable(str: string | undefined): string | undefined {
  return str ? humanize(str) : undefined;
}

function truncate(maxLength: number, str: string): string {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 1) + "…";
  } else {
    return str;
  }
}

// -- Combinators -------------------------------------------------------------

function identity<A>(a: A): A {
  return a;
}

// Returns a function that always returns a constant value `a`. Occasionally useful for managing the degenerate case of
// something.
function always<A>(a: A): (...args: Array<unknown>) => A {
  return (..._args) => a;
}

// -- Enum --------------------------------------------------------------------

/**
 * Enums in typescript have no base implementation or common ancestor so we
 * have to approximate them.
 * https://stackoverflow.com/questions/30774874/enum-as-parameter-in-typescript
 * This is useful for generic functions on things like grapql filter enums
 */
export type GenericEnum<E, S extends keyof E> = Record<string, E[S]>;

// -- Maybe ------------------------------------------------------------------

// Sometimes called catMaybes
function justs<A>(as: Array<Maybe<A>>): Array<A> {
  return as.reduce<Array<A>>((acc, a) => {
    return a.caseOf({
      Nothing: () => acc,
      Just: (aa) => acc.concat(aa),
    });
  }, []);
}

type WithLength = {
  length: number;
};

function maybeFromEmpty<L extends WithLength>(withLength: L): Maybe<L> {
  if (withLength.length === 0) return Nothing();
  else return Just(withLength);
}

function maybeMap2<A, B, C>(f: (a: A, b: B) => C, ma: Maybe<A>, mb: Maybe<B>): Maybe<C> {
  return ma.flatMap((a) => mb.map((b) => f(a, b)));
}

function maybeToRemoteData<E, A>(error: E, maybe: Maybe<A>): RemoteData<E, A> {
  return maybe.caseOf({
    Just: (a) => Success(a),
    Nothing: () => Failure(error),
  });
}

function maybeToResult<E, A>(error: E, maybe: Maybe<A>): Result<E, A> {
  return maybe.caseOf({
    Just: (a) => Ok(a),
    Nothing: () => Err(error),
  });
}

// -- RemoteData --------------------------------------------------------------

function remoteDataToMaybe<E, A>(data: RemoteData<E, A>): Maybe<A> {
  return data.caseOf({
    Success: (a) => Just(a),
    _: () => Nothing(),
  });
}

/**
 * Take a nullable parameter and promote it to remote data, with a generic error message if
 * failed.
 * @param data the nullable data
 * @returns A RemoteData with either Success or Failure
 */
function nullableToRemoteData<A>(data: A | null): RemoteData<Error, A> {
  if (data) {
    return Success(data);
  } else {
    return Failure(new Error("Not Found"));
  }
}

// -- Result ------------------------------------------------------------------

function resultToRemoteData<E, A>(result: Result<E, A>): RemoteData<E, A> {
  return result.caseOf({
    Err: (e) => Failure<E, A>(e),
    Ok: (a) => Success<E, A>(a),
  });
}

function resultToMaybe<E, A>(result: Result<E, A>): Maybe<A> {
  return result.caseOf({
    Err: (_e) => Nothing(),
    Ok: (a) => Just(a),
  });
}

function oks<E, A>(as: Array<Result<E, A>>): Array<A> {
  return as.reduce<Array<A>>((acc, a) => {
    return a.caseOf({
      Err: (_) => acc,
      Ok: (aa) => acc.concat(aa),
    });
  }, []);
}

function resultMap2<A, B, C, E>(f: (a: A, b: B) => C, ra: Result<E, A>, rb: Result<E, B>): Result<E, C> {
  return ra.flatMap((a) => rb.map((b) => f(a, b)));
}

// -- Ordered list  ------------------------------------------------------------------

class OrderedOrLast extends SumType<{
  Ordered: [number];
  Last: [];
}> {
  public static Ordered = (number: number) => new OrderedOrLast("Ordered", number);
  public static Last = () => new OrderedOrLast("Last");

  public min(other: OrderedOrLast): OrderedOrLast {
    return sortOrderedOrLast(this, other) > 0 ? other : this;
  }
}

function sortOrderedOrLast(a: OrderedOrLast, b: OrderedOrLast): number {
  return a.caseOf({
    Ordered: (aNum) => {
      return b.caseOf({
        Ordered: (bNum) => {
          return aNum - bNum;
        },
        Last: () => {
          return -1;
        },
      });
    },
    Last: () => {
      return b.caseOf({
        Ordered: () => {
          return 1;
        },
        Last: () => {
          return 0;
        },
      });
    },
  });
}

// -- Durations ------------------------------------------------------------------
const ISO_PERIOD =
  /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;

/**
 * Parse a duration from an ISO8601 string. Note that it is typed as any because apollo
 * does not recognize the custom duration scalar type as a string.
 */
function parseDuration(input: string): Result<Error, Duration> {
  input = input.trim();
  const matches = ISO_PERIOD.exec(input);

  if (!matches) {
    return Err(new Error("Invalid duration"));
  }

  return Ok<Error, Duration>(
    ["years", "months", "weeks", "days", "rours", "minutes", "seconds"].reduce((result, part, index) => {
      const value = matches[index + 2]; // +2 for full match and sign parts
      if (value) {
        const number = parseInt(value);
        if (number) {
          return {
            ...result,
            ...objOfOr(part, number),
          };
        }
      }
      return result;
    }, {})
  );
}

/**
 * As we change graphql generators, we are going from untyped to typed dates. You cannot simply call parseISO on an
 * existing date, so instead we check to see if there is a date before trying to parse.
 * @param input the string or date
 */
function parseIsoStringOrDate(input: string | Date): Date {
  if (input instanceof Date) {
    return input;
  }

  return parseISO(input);
}

function maybeFromTextBoxChange(
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
): Maybe<string> {
  return Maybe.fromNullable(event.target.value.length > 0 ? event.target.value : null);
}

/**
 * Every now and then the type system is not able to fully understand the nullability of types. There are two
 * main parts right now:
 *  * Form validation, where the type is nullable but it's only called from a valid hook so it's known to not be null
 *  * Skips on queries where we don't have the appropriate variable.
 *
 * In these cases we just need a way of saying "Yes this is definitely not null"
 * @param value a value that is expected to be non-null but is typed as null
 * @returns the value without the null
 * @throws an exception if it's not null
 */
function assertNonNull<T>(value: T | null | undefined): T {
  return value as unknown as T;
}

export {
  identity,
  always,
  head,
  tail,
  init,
  uncons,
  unsnoc,
  last,
  objOfJust,
  nullifyEmptyString,
  objOfOr,
  prop,
  toInt,
  find,
  take,
  takeLast,
  toUpper,
  toLower,
  initials,
  justs,
  oks,
  resultMap2,
  titleize,
  capitalize,
  humanize,
  humanizeNullable,
  desnakeize,
  decamelize,
  resultToRemoteData,
  resultToMaybe,
  maybeFromEmpty,
  maybeToRemoteData,
  maybeToResult,
  remoteDataToMaybe,
  nullableToRemoteData,
  arrOf,
  arrOfN,
  ensureArr,
  max,
  min,
  maxBy,
  minBy,
  isEven,
  isOdd,
  zip,
  partition,
  inPairs,
  parseDuration,
  words,
  firstName,
  maybeMap2,
  truncate,
  assoc,
  assocPath,
  assocKey,
  sort,
  sortBy,
  filter,
  isNil,
  groupBy,
  values,
  mapObjIndexed,
  not,
  pipe,
  splitEvery,
  any,
  OrderedOrLast,
  sortOrderedOrLast,
  fromPairs,
  toPairs,
  update as updateArray,
  noOp,
  parseIsoStringOrDate,
  maybeFromTextBoxChange,
  ExtendedReadonlyArray,
  assertNonNull,
  arrayMaxBy,
  arrayMinBy,
};
