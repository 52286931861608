import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useAppointmentInvitationDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import { InvitationsTable } from "Patients/Invitations/InvitationsTable";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type AppointmentInvitationsCardProps = {
  appointmentId: AppointmentId;
  patientId: PatientId;
};

export function AppointmentInvitationsCard(props: AppointmentInvitationsCardProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentInvitationDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  const { t } = useTranslation(["patients"]);

  const inner = remoteData.caseOf({
    Success: (data) => {
      if (data.schedulingAppointment?.patientSession?.assessmentReport?.invitations) {
        return (
          <InvitationsTable
            invitations={data.schedulingAppointment.patientSession.assessmentReport.invitations}
            scalesPlanned={data.schedulingAppointment.patientSession.scalesPlanned || false}
          />
        );
      } else {
        return <Typography>{t("patients:appointments.invitations.notMeasured")}</Typography>;
      }
    },
    Failure: (error) => <ErrorMessage message={error.message} />,
    _: () => <Spinner />,
  });

  return (
    <Card>
      <CardHeader title={t("patients:appointments.cards.invitations")} />
      <CardContent>{inner}</CardContent>
    </Card>
  );
}
