import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { InboxTable } from "Providers/DecisionSupport/InboxTable";
import { ListDetailsCutout } from "Shared/ListDetailsCutout";
import { InboxFilters, useInboxFilters } from "./InboxFilters";
import { InboxSummary } from "./InboxSummary";
import { useParams, Routes, Route } from "react-router-dom";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { TriggeredWorkflowDetail } from "./TriggeredWorkflowDetail";

export function Inbox(): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);
  const filters = useInboxFilters();

  return (
    <Page browserTitle={t("decisionSupport:pageTitle")}>
      <ListDetailsCutout
        filters={<InboxFilters filters={filters} />}
        list={<InboxTable filters={filters} />}
        details={
          <Routes>
            <Route element={<InboxDetailRoute />} path="workflow/:triggeredWorkflowId" />
            <Route element={<InboxSummary />} path="/" />
          </Routes>
        }
      />
    </Page>
  );
}

function InboxDetailRoute(): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);
  const params = useParams<{ triggeredWorkflowId?: string }>();

  return Id.fromNullableString<"TriggeredWorkflow">(params.triggeredWorkflowId).caseOf({
    Ok: (id) => <TriggeredWorkflowDetail triggeredWorkflowId={id} />,
    Err: (_err) => <ErrorMessage message={t("decisionSupport:inbox.noSuchTriggeredWorkflow")} />,
  });
}
