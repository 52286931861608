import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Button,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  TaskTemplate,
  useCreateTaskTemplateMutation,
  useTaskTemplatesQuery,
  useUpdateTaskTemplateMutation,
  useDeleteTaskTemplateMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import ContainedIconButton from "MDS/ContainedIconButton";
import ErrorMessage from "Shared/ErrorMessage";
import { Form, FormOverlay, useForm, useTextField } from "Shared/Form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { RichText } from "MDS/RichText";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { refetchQueries } from "Lib/RefetchQueries";

type FormProps = {
  onSuccess?: () => void;
};

function NewTaskTemplateForm(props: FormProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const [createTaskTemplate, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTaskTemplate,
    useCreateTaskTemplateMutation({
      refetchQueries: refetchQueries("taskTemplates"),
    })
  );

  const fields = {
    title: useTextField({
      required: true,
    }),
    body: useTextField({
      required: false,
    }),
  };

  const form = useForm({
    submit: () => {
      createTaskTemplate({
        variables: {
          input: {
            title: fields.title.value || "",
            body: fields.body.value,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: props.onSuccess,
    fields: fields,
  });

  return (
    <Form onSubmit={form.onSubmit} sx={{ minWidth: "10rem" }}>
      <FormOverlay
        response={remoteData}
        errorMessage={t("collaborativeCare:taskTemplates.genericFormError")}
      />
      <Stack direction="column" spacing={1}>
        <TextField
          label={t("collaborativeCare:fields.title.label")}
          autoFocus
          value={fields.title.value}
          onChange={fields.title.onChange}
          error={fields.title.error}
          helperText={fields.title.helperText}
        />
        <TextField
          multiline
          minRows={5}
          label={t("collaborativeCare:fields.body.label")}
          value={fields.body.value}
          onChange={fields.body.onChange}
          error={fields.body.error}
          helperText={fields.body.helperText}
        />
        <Stack direction="row-reverse">
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </Stack>
      </Stack>
    </Form>
  );
}

function NewTaskTemplateFormTrigger(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showForm, setShowForm] = React.useState(false);

  return (
    <>
      <Button onClick={() => setShowForm(true)} variant="contained" color="secondary">
        {t("collaborativeCare:taskTemplates.createHeader")}
      </Button>
      <ResponsiveDialog
        title={t("collaborativeCare:taskTemplates.createHeader")}
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <DialogContent>
          {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
              form goes away */}
          <NewTaskTemplateForm onSuccess={() => setTimeout(() => setShowForm(false), 300)} />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

type TaskTemplateDetails = PickTypename<TaskTemplate, "title" | "body" | "id">;
type TaskTemplateRowProps = {
  template: TaskTemplateDetails;
  index: number;
};
type TaskTemplateEditProps = {
  template: TaskTemplateDetails;
};

// this is copy-pasta-ed from CreateTaskTemplateFormTrigger, and could be DRY-ed
function EditTaskTemplateFormTrigger(props: TaskTemplateEditProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showForm, setShowForm] = React.useState(false);

  return (
    <>
      <ContainedIconButton onClick={() => setShowForm(true)}>
        <Edit />
      </ContainedIconButton>
      <ResponsiveDialog
        title={t("collaborativeCare:taskTemplates.editHeader")}
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <DialogContent>
          {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
              form goes away */}
          <EditTaskTemplateForm
            onSuccess={() => setTimeout(() => setShowForm(false), 300)}
            template={props.template}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

type EditFormProps = FormProps & {
  template: TaskTemplateDetails;
};

function EditTaskTemplateForm(props: EditFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [EditTaskTemplate, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateTaskTemplate,
    useUpdateTaskTemplateMutation({
      refetchQueries: refetchQueries("taskTemplates"),
    })
  );

  const fields = {
    title: useTextField({
      required: true,
      default: props.template.title,
    }),
    body: useTextField({
      required: false,
      default: props.template.body as string,
    }),
  };

  const form = useForm({
    submit: () => {
      EditTaskTemplate({
        variables: {
          input: {
            taskTemplateId: props.template.id,
            title: fields.title.value || "",
            body: fields.body.value || "",
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: props.onSuccess,
    fields: fields,
  });

  return (
    <Form onSubmit={form.onSubmit} sx={{ minWidth: "10rem" }}>
      <FormOverlay
        response={remoteData}
        errorMessage={t("collaborativeCare:taskTemplates.genericFormError")}
      />
      <Stack direction="column" spacing={1}>
        <TextField
          label={t("collaborativeCare:fields.title.label")}
          autoFocus
          value={fields.title.value}
          onChange={fields.title.onChange}
          error={fields.title.error}
          helperText={fields.title.helperText}
        />
        <TextField
          multiline
          minRows={5}
          label={t("collaborativeCare:fields.body.label")}
          value={fields.body.value}
          onChange={fields.body.onChange}
          error={fields.body.error}
          helperText={fields.body.helperText}
        />
        <Stack direction="row-reverse">
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.edit")}
          </ButtonWithSpinner>
        </Stack>
      </Stack>
    </Form>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.report.itemCoding.severe.primary,
  padding: theme.spacing(1),
  color: theme.palette.common.white,
}));

// this is copy-pasta-ed from CreateTaskTemplateFormTrigger, and could be DRY-ed
function DeleteTaskTemplateFormTrigger(props: TaskTemplateEditProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [showForm, setShowForm] = React.useState(false);

  const [DeleteTaskTemplate, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTaskTemplate,
    useDeleteTaskTemplateMutation({
      refetchQueries: refetchQueries("taskTemplates"),
    })
  );
  const showSpinner = remoteData.kind === "Loading";

  return (
    <>
      <ContainedIconButton onClick={() => setShowForm(true)}>
        <Delete />
      </ContainedIconButton>
      <ResponsiveDialog
        title={t("collaborativeCare:taskTemplates.deleteHeader")}
        open={showForm}
        onClose={() => {
          setShowForm(false);
        }}
      >
        <DialogContent>
          <StyledBox>
            <Stack direction="column">
              <Typography>
                {t("collaborativeCare:taskTemplates.fields.title")}:{" "}
                <RichText>{props.template.title}</RichText>
              </Typography>
              <Typography>
                {t("collaborativeCare:taskTemplates.fields.description")}:{" "}
                <RichText>{props.template.body}</RichText>
              </Typography>
            </Stack>
          </StyledBox>
          <Typography>
            {" "}
            {t("common:actions.deletion.confirmationMessage")} {t("common:actions.permanentWarning")}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Stack direction="row-reverse" spacing={1}>
            <ButtonWithSpinner
              variant="contained"
              color="secondary"
              type="submit"
              showSpinner={showSpinner}
              disabled={showSpinner}
              onClick={() =>
                DeleteTaskTemplate({
                  variables: {
                    input: {
                      taskTemplateId: props.template.id,
                    },
                  },
                })
              }
            >
              {t("common:actions.delete")}
            </ButtonWithSpinner>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setTimeout(() => setShowForm(false), 200)}
            >
              {t("common:actions.cancel")}
            </Button>
          </Stack>
        </DialogActions>
      </ResponsiveDialog>
    </>
  );
}

function TaskTemplateListRow(props: TaskTemplateRowProps): ReactElement {
  const theme = useTheme();
  const backgroundColor = props.index % 2 == 0 ? theme.palette.background.paper : "transparent";

  return (
    <Card sx={{ backgroundColor: backgroundColor }}>
      <CardHeader
        title={props.template.title}
        action={
          <Stack direction="row" spacing={0.5}>
            <EditTaskTemplateFormTrigger template={props.template} />
            <DeleteTaskTemplateFormTrigger template={props.template} />
          </Stack>
        }
      />
      <CardContent>
        <RichText>{props.template.body}</RichText>
      </CardContent>
    </Card>
  );
}

function TaskTemplateList(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(useTaskTemplatesQuery());

  return remoteData.caseOf({
    NotAsked: () => <></>,
    Loading: () => <></>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (response) => {
      if (!response.collaborativeCareTaskTemplates) {
        return <ErrorMessage message={t("collaborativeCare:taskTemplates.genericQueryError")} />;
      }

      const taskTemplates = [...response.collaborativeCareTaskTemplates.nodes]
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
        .map((template, i) => <TaskTemplateListRow template={template} index={i} key={i} />);

      return <>{taskTemplates}</>;
    },
  });
}

export function TaskTemplates(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Page browserTitle={t("collaborativeCare:taskTemplates.title")}>
      <Stack direction="column" spacing={1}>
        <Stack direction="row">
          <Typography component="h1" variant="h1" sx={{ fontSize: "2em" }} flexGrow={1}>
            {t("collaborativeCare:taskTemplates.header")}
          </Typography>
          <NewTaskTemplateFormTrigger />
        </Stack>
        <TaskTemplateList />
      </Stack>
    </Page>
  );
}
