import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import LogoImg from "../Assets/images/mirah-star.png";

import React, { ReactElement } from "react";

type StyleProps = {
  color1: string;
  color2?: string;
};

const StyledBasePath = styled("path")<{ color: string }>(({ color }) => ({
  fill: color,
  fillOpacity: 1,
  fillRule: "nonzero",
  stroke: "none",
}));

type BaseProps = SvgIconProps & StyleProps;

function MirahIconBase(props: BaseProps): ReactElement {
  const { color1 } = props;
  let { color2 } = props;
  if (!color2) {
    color2 = color1;
  }

  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 147.3 147.3">
        <g transform="rotate(45 73.65 73.65) matrix(1.3333333,0,0,-1.3333333,0,666.66667)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <StyledBasePath
              id="path26"
              color={color1}
              d="m 1102.885,4639.8502 -189.65,-189.53 h -108.14 l 112.98,112.99 h -206.72 l 76.54,76.54 h 314.99"
            />
            <StyledBasePath
              id="path28"
              color={color2}
              d="m 1102.885,4257.2802 h -306.03 l -76.54,76.54 h 197.76 l -114.68,114.8 1.7,1.7 h 108.14 l -1.7,-1.7 191.35,-191.34"
            />
            <StyledBasePath
              id="path30"
              color={color1}
              d="m 742.725,4203.2702 v -306.15 l -191.34,191.34 v 108.15 l 114.8,-114.69 v 197.77 l 76.54,-76.42"
            />
            <StyledBasePath
              id="path32"
              color={color2}
              d="m 742.725,5000.0002 v -297.06 l -76.54,-76.54 v 188.92 l -114.8,-114.8 v 108.26 l 191.34,191.22"
            />
            <StyledBasePath
              id="path34"
              color={color2}
              d="m 551.385,4196.6102 v -108.15 l -191.225,-191.34 v 314.99 l 76.536,76.54 v -206.73 l 114.689,114.69"
            />
            <StyledBasePath
              id="path36"
              color={color1}
              d="m 551.385,4808.7802 v -108.26 l -114.689,114.8 v -197.88 l -76.536,76.54 v 306.02 l 191.225,-191.22"
            />
            <StyledBasePath
              id="path38"
              color={color2}
              d="M 382.563,4563.3102 H 184.68 l 113.109,-112.99 H 189.645 L 0,4639.8502 h 306.028 l 76.535,-76.54"
            />
            <StyledBasePath
              id="path40"
              color={color1}
              d="m 373.602,4333.8202 -76.418,-76.54 H 0 l 191.34,191.34 -1.695,1.7 h 108.144 l 1.696,-1.7 -114.805,-114.8 h 188.922"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

function MirahIcon(props: SvgIconProps) {
  return <MirahIconBase color1={"#F7F3F0"} {...props} />;
}

function MirahIconTwoTone(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <MirahIconBase color1={theme.palette.primary.dark} color2={theme.palette.primary.light} {...props} />
  );
}

type MirahLogoProps = {
  width?: string;
  height?: string;
};
export function MirahLogo(props: MirahLogoProps) {
  return <img src={LogoImg} {...props} />;
}

export { MirahIcon, MirahIconTwoTone };
export default MirahIcon;
