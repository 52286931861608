import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { PanelId, PanelTeamMemberId } from "Lib/Ids";
import { PanelCareTeamSelect } from "CollaborativeCare/PanelManagement/PanelCareTeamSelector";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { FieldMethods } from "Shared/Form";

type EnrollmentPanelConsentProps = {
  enrollmentDate: Date | undefined;
  setEnrollmentDate: (newValue: Date) => void;
  consentDate: Date | undefined;
  setConsentDate: (newValue: Date) => void;
  patient: PickTypename<Patient, "name">;
  panel: FieldMethods<PanelId, PanelId>;
  pcp: FieldMethods<PanelTeamMemberId, PanelTeamMemberId>;
  cm: FieldMethods<PanelTeamMemberId, PanelTeamMemberId>;
  onConfirm: () => void;
};

export function EnrollmentPanelConsent(props: EnrollmentPanelConsentProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const continuable = !!props.pcp.value && !!props.cm.value && !!props.panel.value && !!props.consentDate;
  return (
    <>
      <DialogContent>
        <Stack direction="column" spacing={1} sx={{ paddingTop: 1 }}>
          <Typography fontStyle="italic">
            {t("collaborativeCare:enrollment.consentPrompt", { name: props.patient.name })}
          </Typography>
          <FormControl>
            <PanelCareTeamSelect panel={props.panel} primaryCareProvider={props.pcp} careManager={props.cm} />
          </FormControl>
          <Box>
            <FormControl>
              <DateTimePicker
                label={t("collaborativeCare:enrollment.consentDate")}
                value={props.consentDate}
                onChange={props.setConsentDate}
              />
            </FormControl>
          </Box>
          <FormHelperText>{t("collaborativeCare:enrollment.consentDateHelp")}</FormHelperText>
          <Box>
            <FormControl>
              <DateTimePicker
                label={t("collaborativeCare:enrollment.enrollmentDate")}
                value={props.enrollmentDate}
                onChange={props.setEnrollmentDate}
              />
            </FormControl>
          </Box>
          <FormHelperText>{t("collaborativeCare:enrollment.enrollmentDateHelp")}</FormHelperText>
        </Stack>
      </DialogContent>
      <DialogActions>
        {" "}
        <Button variant="contained" color="secondary" disabled={!continuable} onClick={props.onConfirm}>
          {t("collaborativeCare:enrollment.actions.confirmConsent")}
        </Button>
      </DialogActions>
    </>
  );
}
