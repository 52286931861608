import EditIcon from "@mui/icons-material/Edit";
import StraightenIcon from "@mui/icons-material/Straighten";
import MenuIcon from "@mui/icons-material/Menu";
import { Menu as MuiMenu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CaseConsultId, ConsultMeetingId, PatientId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import * as React from "react";
import ContainedIconButton from "MDS/ContainedIconButton";
import { ArrowBack, SettingsBackupRestore } from "@mui/icons-material";
import { WithInternalUser } from "AppSession/AuthenticatedProviderUser";
import { CaseConsultStatus, useCaseConsultListQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Link from "MDS/Link";

const VerticalCenteredLink = styled(Link)(() => ({
  display: "flex",
  alignItems: "center",
}));

const SpacedIcon = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));

type FeedbackReportActionMenuProps = {
  patientId: PatientId;
  showDialog: (dialog: "progressNote" | "addTask") => void;
};

export default function FeedbackReportActionMenu(props: FeedbackReportActionMenuProps) {
  const { patientId, showDialog } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t } = useTranslation(["report"]);

  const measurementUrl = `/provider/patients/${patientId}/plan`;

  return (
    <div>
      <ContainedIconButton
        id="action-menu-button"
        aria-controls={open ? "action-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </ContainedIconButton>
      <MuiMenu
        id="action-menu"
        MenuListProps={{
          "aria-labelledby": "action-menu-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            showDialog("progressNote");
          }}
          disableRipple
        >
          <SpacedIcon>
            <EditIcon />
          </SpacedIcon>
          {t("report:actions.generateClinicalNote")}
        </MenuItem>
        <MenuItem onClick={handleClose} disableRipple>
          {/* MenuItems do this vertical centering by default, we only need to add it when we wrap menu content in
              something else like a link. */}
          <VerticalCenteredLink to={measurementUrl}>
            <SpacedIcon>
              <StraightenIcon />
            </SpacedIcon>
            {t("report:actions.changeMeasurementPlan")}
          </VerticalCenteredLink>
        </MenuItem>

        <ReturnToConsultMenuItem patientId={patientId} />

        <WithInternalUser>
          <MenuItem onClick={handleClose} disableRipple>
            <VerticalCenteredLink to={`/provider/patients/${patientId}/report-v2`}>
              <SpacedIcon>
                <SettingsBackupRestore />
              </SpacedIcon>
              {t("report:actions.showOldReport")}
            </VerticalCenteredLink>
          </MenuItem>
        </WithInternalUser>
      </MuiMenu>
    </div>
  );
}

function ReturnToConsultMenuItem(props: { patientId: PatientId }) {
  const { t } = useTranslation(["collaborativeCare"]);

  const activeConsult = useActiveConsult(props.patientId);

  if (activeConsult) {
    return (
      <MenuItem>
        <VerticalCenteredLink
          to={`/app/cocm/case-consult/meeting/${activeConsult.meeting}/case/${activeConsult.case}`}
        >
          <SpacedIcon>
            <ArrowBack />
          </SpacedIcon>
          {t("collaborativeCare:caseConsult.consultMeeting.return")}
        </VerticalCenteredLink>
      </MenuItem>
    );
  } else {
    return null;
  }
}

type ConsultLinkProps = {
  case: CaseConsultId;
  meeting: ConsultMeetingId;
};

function useActiveConsult(patientId: PatientId): ConsultLinkProps | null {
  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        testPatient: false,
        patient: { patientIds: [patientId] },
        statuses: [CaseConsultStatus.NEEDS_SUMMARY, CaseConsultStatus.AWAITING_CONSULT],
      },
    })
  );

  return remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareCaseConsults) {
        const maybeConsult = response.collaborativeCareCaseConsults.nodes[0];
        if (maybeConsult?.consultMeeting) {
          return {
            case: maybeConsult.id,
            meeting: maybeConsult.consultMeeting.id,
          };
        }
      }

      return null;
    },
    _: () => null,
  });
}
