import React, { ReactElement } from "react";
import { ParticipantSummary } from "Shared/Participant";
import * as ItemCoding from "Shared/Scale/ItemCoding";
import { QuestionResponseSummary, questionText } from "Shared/Scale/QuestionResponse";
import { Patterns } from "Shared/SvgPatterns";
import ParticipantAvatarAndName from "FeedbackReport/ParticipantAvatarAndName";
import { Tooltip, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Maybe } from "seidr";
import { useTranslation } from "react-i18next";
import { NormalizedScore as NormalizedScoreGraphql } from "GeneratedGraphQL/SchemaAndOperations";
import * as NormalizedScore from "Shared/Scale/NormalizedScore";

const StyledAnswerText = styled(Typography)(() => ({
  fontWeight: "bold",
}));
const StyledQuestionColumn = styled(Typography)(() => ({}));
const StyledAnswerColumn = styled("td")(() => ({
  position: "relative",
}));

const StyledAnswerDot = styled("div", {
  shouldForwardProp: (prop) => prop !== "itemCoding" && prop !== "normalizedScore",
})<{
  itemCoding?: Maybe<ItemCoding.ItemCoding>;
  normalizedScore?: Maybe<NormalizedScoreGraphql>;
}>(({ theme, normalizedScore }) => {
  const backgroundColorStyle = normalizedScore
    ? { backgroundColor: NormalizedScore.toColorWithDefault(normalizedScore) }
    : { backgroundColor: "transparent" };
  return {
    position: "absolute",
    top: 13,
    left: theme.spacing(-0.5),
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    background: theme.palette.text.primary,
    ...backgroundColorStyle,
  };
});

function AnswerWithCodingDot(props: {
  itemCoding?: Maybe<ItemCoding.ItemCoding>;
  normalizedScore?: Maybe<NormalizedScoreGraphql>;
}): ReactElement {
  const theme = useTheme();
  const { itemCoding, normalizedScore } = props;

  const containerStyle: React.CSSProperties = {
    position: "absolute",
    left: theme.spacing(-0.75),
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    borderRadius: theme.spacing(0.75),
  };

  // Due to, I believe, float rounding issues during rendering, we have to constrain the margin and sizing here to
  // whole-pixel values here so that it forms a correct circle instead of a lopsided thing. This exploits the fact that
  // I happen to know that theme.spacing(1) === 16px (so the 1.5 above is 24px). The original design used 5% or 10%
  // margins on each ring, which approximate 1px or 2px, so I'm clamping to those.

  const groundColorStyle = itemCoding ? { backgroundColor: ItemCoding.toColor(itemCoding, theme) } : {};
  const outerStyle: React.CSSProperties = {
    position: "absolute",
    marginTop: "1px",
    marginLeft: "1px",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    background: theme.palette.text.primary,
    ...groundColorStyle,
  };

  const midStyle: React.CSSProperties = {
    position: "absolute",
    marginTop: "2px",
    marginLeft: "2px",
    width: "18px",
    height: "18px",
    borderRadius: "50%",
    background: "white",
  };

  const innerColor = normalizedScore
    ? { backgroundColor: NormalizedScore.toColorWithDefault(normalizedScore) }
    : { backgroundColor: "transparent" };
  const innerStyle: React.CSSProperties = {
    position: "absolute",
    marginTop: "2px",
    marginLeft: "2px",
    width: "14px",
    height: "14px",
    borderRadius: "50%",
    background: theme.palette.text.primary,
    ...innerColor,
  };

  return (
    <div style={containerStyle}>
      <div style={outerStyle}>
        <div style={midStyle}>
          <div style={innerStyle}></div>
        </div>
      </div>
    </div>
  );
}

const StyledNotAskedAnswerDot = styled(StyledAnswerDot)(() => ({
  background: `url("${Patterns.DiagonalLines.dataUrl}")`,
}));
const StyledSkippedAnswerDot = styled(StyledAnswerDot)(() => ({
  background: `url("${Patterns.Crosshatch.dataUrl}")`,
}));

type QuestionResponseRowProps = {
  questionResponse: QuestionResponseSummary;
};

function QuestionResponseRow(props: QuestionResponseRowProps): ReactElement {
  const { questionResponse } = props;
  const { t } = useTranslation(["report"]);

  const partialQuestionText = (
    <span dangerouslySetInnerHTML={{ __html: questionText(questionResponse.question) }}></span>
  );
  const fullQuestionText = (
    <span dangerouslySetInnerHTML={{ __html: questionResponse.question.title }}></span>
  );

  return (
    <tr data-testid="question-response-row">
      <td>
        <Tooltip title={fullQuestionText}>
          <StyledQuestionColumn>{partialQuestionText}</StyledQuestionColumn>
        </Tooltip>
      </td>
      <StyledAnswerColumn>
        {questionResponse.response.caseOf({
          NotAsked: () => <StyledNotAskedAnswerDot />,
          Skipped: () => <StyledSkippedAnswerDot />,
          Answered: (a) => (
            <AnswerWithCodingDot normalizedScore={a.normalizedScore} itemCoding={a.itemCoding} />
          ),
        })}
        <StyledAnswerText>
          {questionResponse.response.caseOf({
            NotAsked: () => t("report:responses.notAsked"),
            Skipped: () => t("report:responses.skipped"),
            Answered: (a) => a.option.map((o) => o.reportText.getOrElse(o.title)).getOrElse(a.value),
          })}
        </StyledAnswerText>
      </StyledAnswerColumn>
    </tr>
  );
}

const StyledResponsesTable = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  tableLayout: "fixed",
  "& td": {
    padding: "0.6rem 1.2rem",
  },

  "& tr:nth-of-type(2n)": {
    backgroundColor: theme.palette.report.shading.background,
  },
}));

type Props = {
  responses: ReadonlyArray<QuestionResponseSummary>;
};

function ResponsesTable(props: Props): ReactElement {
  const { responses } = props;

  const questionResponses = responses.map((qr, i) => <QuestionResponseRow key={i} questionResponse={qr} />);
  return (
    <StyledResponsesTable>
      <tbody>{questionResponses}</tbody>
    </StyledResponsesTable>
  );
}

type ResponsesWithParticipantTableProps = Props & {
  participant: ParticipantSummary;
};

function ResponsesWithParticipantTable(props: ResponsesWithParticipantTableProps): ReactElement {
  const { responses } = props;

  const questionResponses = responses.map((qr, i) => <QuestionResponseRow key={i} questionResponse={qr} />);
  return (
    <StyledResponsesTable>
      <thead>
        <tr>
          <td></td>
          <td>
            <ParticipantAvatarAndName displayRelationship={false} participant={props.participant} />
          </td>
        </tr>
      </thead>
      <tbody>{questionResponses}</tbody>
    </StyledResponsesTable>
  );
}

export { ResponsesTable, ResponsesWithParticipantTable };
