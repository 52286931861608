import { apolloQueryHookWrapper } from "Api/GraphQL";
import { ChartData, useNewEnrollmentStatsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import { useTranslation } from "react-i18next";
import AnalyticsLineChart from "../Shared/Graphs/AnalyticsLineChart";
import { InstituteId, ProviderId } from "Lib/Ids";
import { Card, CardContent, CardHeader } from "@mui/material";

const dateAccessor = (d: ChartData) =>
  d.date.getUTCMonth() + 1 + "/" + d.date.getUTCDate() + "/" + d.date.getUTCFullYear();
const yAccessor = (d: ChartData) => d.value.toString();

export default function NewEnrollmentsChart(props: {
  startDate: Date | null;
  endDate: Date | null;
  careManagerIds: ReadonlyArray<ProviderId>;
  instituteId: InstituteId | null;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const data: Array<ChartData> = [];
  const { remoteData } = apolloQueryHookWrapper(
    useNewEnrollmentStatsQuery({
      variables: {
        careManagerIds: props.careManagerIds,
        startDate: props.startDate,
        endDate: props.endDate,
        instituteId: props.instituteId,
      },
    })
  );
  let totalEnrollments = 0;
  let content = <>Loading</>;

  remoteData.caseOf({
    Loading: () => null,
    NotAsked: () => null,
    Failure: () => {
      content = <>Failed to load chart</>;
    },
    Success: (result) => {
      let max = 0;
      result.collaborativeCareNewEnrollmentStats?.forEach(function (chartData) {
        data.push(chartData as ChartData);
        totalEnrollments += chartData.value;
        if (chartData.value > max) {
          max = chartData.value;
        }
      });
      content = (
        <AnalyticsLineChart
          data={data}
          dataKey={"New Enrollments"}
          xAccessor={dateAccessor}
          yAccessor={yAccessor}
          yDomainMax={Math.max(30, max)}
        ></AnalyticsLineChart>
      );
    },
  });
  return (
    <Card>
      <CardHeader
        title={t("collaborativeCare:performanceCharts.newEnrollments.title")}
        subheader={
          t("collaborativeCare:performanceCharts.newEnrollments.description") + " " + totalEnrollments
        }
      />
      <CardContent>{content}</CardContent>
    </Card>
  );
}
