import {
  scaleAdministrationHistoryRoute,
  scaleGroupHistoryRoute,
} from "FeedbackReport/FeedbackReportRouting";
import {
  InvitationFormat,
  InvitationNotification,
  InvitationNotificationStatus,
  SurveyManagementIndexQuery,
  User,
} from "GeneratedGraphQL/SchemaAndOperations";
import {
  CareEpisodeId,
  InvitationId,
  ParticipantId,
  PatientId,
  ReportId,
  ScaleGroupId,
  ScaleId,
} from "Lib/Ids";
import { TFunction } from "i18next";
import React, { ReactElement } from "react";
import { DigUnpacked, PickTypename, compact } from "type-utils";
import Link from "MDS/Link";
import { max as maxDate, min as minDate } from "date-fns";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type ScaleSummary = {
  id: ScaleId;
  name: string | null;
  reportId: ReportId;
  scaleGroupId: ScaleGroupId | undefined;
};

export type InviteSummary = {
  id: InvitationId;
  status: string;
  closesAt: Date | null;
  opensAt: Date | null;
  createdAt: Date;
  user: PickTypename<User, "name">;
  userName: string;
  willSendAt: Date | null | undefined;
  dateBegun: Date | null | undefined;
  dateCompleted: Date | null | undefined;
  sentAt: Date | null | undefined;
  methods: Array<string>;
  scales: Array<ScaleSummary>;
  scaleLinkElements: Array<ReactElement>;
  scalesSortKey: string;
  downloadAvailable: boolean;
  manuallyCancelable: boolean;
  canceledAt: Date | null;
  canceledByUser: PickTypename<User, "name"> | null;
  rawInvitation: QueryInvitation;
};

type QueryInvitation = DigUnpacked<
  SurveyManagementIndexQuery,
  ["assessmentCareEpisodeScaleSummary", "invitations"]
>;

export function transformRawStatus(
  status: string,
  defaultStatus: string,
  interview: boolean,
  t: TFunction<["common", "collaborativeCare"]>
): string {
  let ret = "";
  switch (status) {
    case "COMPLETE":
      ret = t("collaborativeCare:surveyManagement.status.complete");
      break;
    case "NEW":
      ret = t("collaborativeCare:surveyManagement.status.scheduled");
      break;
    case "FUTURE":
      ret = t("collaborativeCare:surveyManagement.status.scheduled");
      break;
    case "SENT":
      ret = t("collaborativeCare:surveyManagement.status.sent");
      break;
    case "OPENED":
      ret = t("collaborativeCare:surveyManagement.status.opened");
      break;
    case "BEGUN":
      ret = t("collaborativeCare:surveyManagement.status.begun");
      break;
    case "CANCELED":
      ret = t("collaborativeCare:surveyManagement.status.canceled");
      break;
    case "MANUALLY_CANCELED":
      ret = t("collaborativeCare:surveyManagement.status.canceled");
      break;
    case "EXPIRED":
      ret = t("collaborativeCare:surveyManagement.status.expired");
      break;
    case "PARTIAL":
      ret = t("collaborativeCare:surveyManagement.status.partial");
      break;
    default:
      ret = defaultStatus;
      break;
  }
  if (ret == t("collaborativeCare:surveyManagement.status.scheduled") && interview) {
    ret = t("collaborativeCare:surveyManagement.status.administrationRequired");
  }
  return ret;
}

export function transformInvitation(
  rawInvitation: QueryInvitation,
  patientId: PatientId,
  careEpisodeId: CareEpisodeId,
  t: TFunction<["common", "collaborativeCare"]>
): InviteSummary {
  const methods = [];
  // there may be several notifications in various orders, and some of them may not get sent because of duplications etc
  // so if we need to look at all of them to see if we've ever sent a text and / or email
  if (
    rawInvitation.invitationNotifications.find(function (notification: InvitationNotification) {
      return notification.dateEmailed;
    })
  ) {
    methods.push("email");
  }
  if (
    rawInvitation.invitationNotifications.find(function (notification: InvitationNotification) {
      return notification.dateTexted;
    })
  ) {
    methods.push("text");
  }

  // The date-fns max and min functions return an invalid Date object when given an empty list, rather than null or
  // undefined. We would rather have nullable values here, so we have to convert them ourselves. The official way to
  // check for invalid dates is to look for NaN coming out of valueOf:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_epoch_timestamps_and_invalid_date
  let dateSent: Date | null = maxDate(
    compact(
      // Collect all sending dates no matter the medium and find the global max.
      rawInvitation.invitationNotifications.flatMap((notification) => [
        notification.dateEmailed,
        notification.dateTexted,
      ])
    )
  );
  if (Number.isNaN(dateSent.valueOf())) {
    dateSent = null;
  }

  let scheduledDate: Date | null = minDate(
    compact(
      rawInvitation.invitationNotifications
        .filter((notification) => notification.status === InvitationNotificationStatus.SCHEDULED)
        .map((notification) => notification.sendAt)
    )
  );
  if (Number.isNaN(scheduledDate.valueOf())) {
    scheduledDate = null;
  }

  const status = transformRawStatus(
    rawInvitation.status,
    rawInvitation.status,
    rawInvitation.format == InvitationFormat.INTERVIEW,
    t
  );

  const scales = rawInvitation.reportScales.map(function (rs) {
    return {
      id: rs.scale.id,
      name: rs.scale.shortname,
      reportId: rs.report.id,
      scaleGroupId: rs.scale.scaleGroup?.id,
    };
  });

  const routingContext = { patientId: patientId, careEpisodeId: careEpisodeId };

  return {
    id: rawInvitation.id,
    status: status,
    closesAt: rawInvitation.closesAt || rawInvitation.statusUpdatedAt,
    opensAt: rawInvitation.opensAt,
    user: rawInvitation.user,
    userName: rawInvitation.user.name,
    createdAt: rawInvitation.createdAt,
    willSendAt: scheduledDate,
    dateBegun: rawInvitation.dateBegun,
    dateCompleted: rawInvitation.dateCompleted,
    sentAt: dateSent,
    methods: methods,
    scales: scales,
    scaleLinkElements: scales.map((scale, i) => {
      const historyUrl = scale.scaleGroupId
        ? scaleGroupHistoryRoute(routingContext, scale.scaleGroupId, undefined as unknown as ParticipantId)
        : scaleAdministrationHistoryRoute(routingContext, scale.id);

      return (
        <React.Fragment key={i}>
          <Link to={historyUrl}>{scale.name}</Link>{" "}
        </React.Fragment>
      );
    }),
    scalesSortKey: scales.map((scale) => scale.name).join(","),
    downloadAvailable: rawInvitation.downloadAvailable,
    manuallyCancelable: rawInvitation.manuallyCancelable,
    canceledAt: rawInvitation.canceledAt,
    canceledByUser: rawInvitation.canceledByUser,
    rawInvitation: rawInvitation,
  };
}

export function InvitationStatusElement(props: { invitation: InviteSummary }) {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const dateBegun = props.invitation.dateBegun
    ? t("common:date.tinyWithTime", {
        date: props.invitation.dateBegun as Date,
      })
    : "";
  const dateCompleted = props.invitation.dateCompleted
    ? t("common:date.tinyWithTime", {
        date: props.invitation.dateCompleted,
      })
    : "";
  const willSendAt = props.invitation.willSendAt
    ? t("common:date.tinyWithTime", {
        date: props.invitation.willSendAt,
      })
    : "";

  const sentAt = props.invitation.sentAt
    ? t("common:date.tinyWithTime", {
        date: props.invitation.sentAt,
      })
    : "";

  switch (props.invitation.status) {
    case t("collaborativeCare:surveyManagement.status.complete"):
      return (
        <>
          <Typography component="span" fontWeight="bold">
            {props.invitation.status}
          </Typography>{" "}
          {t("collaborativeCare:surveyManagement.statusDisplayStrings.complete", {
            date: dateCompleted,
          })}
        </>
      );
    case t("collaborativeCare:surveyManagement.status.scheduled"):
      return (
        <>
          <Typography component="span" fontWeight="bold">
            {props.invitation.status}
          </Typography>{" "}
          {willSendAt
            ? t("collaborativeCare:surveyManagement.statusDisplayStrings.scheduled", {
                date: willSendAt,
              })
            : ""}
        </>
      );
    case t("collaborativeCare:surveyManagement.status.sent"):
      return (
        <>
          <Typography component="span" fontWeight="bold">
            {props.invitation.status}
          </Typography>{" "}
          {t("collaborativeCare:surveyManagement.statusDisplayStrings.sent", {
            date: " " + sentAt,
          })}
        </>
      );
    case t("collaborativeCare:surveyManagement.status.begun"):
      return (
        <>
          <Typography component="span" fontWeight="bold">
            {props.invitation.status}
          </Typography>{" "}
          {t("collaborativeCare:surveyManagement.statusDisplayStrings.begun", {
            date: dateBegun,
          })}
        </>
      );
    case t("collaborativeCare:surveyManagement.status.canceled"):
      if (props.invitation.canceledAt && props.invitation.canceledByUser) {
        return (
          <>
            <Typography component="span" fontWeight="bold">
              {props.invitation.status}
            </Typography>{" "}
            {t("collaborativeCare:surveyManagement.statusDisplayStrings.canceled", {
              name: props.invitation.canceledByUser.name,
              date: t("common:date.tinyWithTime", {
                date: props.invitation.canceledAt,
              }),
            })}
          </>
        );
      } else {
        return (
          <>
            <Typography component="span" fontWeight="bold">
              {props.invitation.status}
            </Typography>
          </>
        );
      }
    default:
      return (
        <>
          <Typography component="span" fontWeight="bold">
            {props.invitation.status}
          </Typography>
        </>
      );
  }
}
