import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import {
  InstituteDetailsQueryQuery,
  useInstituteDetailsQueryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Box, Chip, Stack } from "@mui/material";
import { Dig } from "type-utils";
import { fromArray } from "Lib/NonEmptyList";
import EhrLinkLogList from "./EhrLinkLogList";
import { InstituteId } from "Lib/Ids";
import DetailsLayout from "./DetailsLayout";
import LaunchInstituteButton from "./LaunchInstituteButton";
import { Assignment, Face, Group, LocationCity } from "@mui/icons-material";
import UnderMeasurementChip from "./UnderMeasurementChip";
import { InternalBreadcrumbPath } from "./InstituteDetailsRoute";

type InstituteDetailsProps = {
  instituteId: InstituteId;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
};

function tabs(dat: NonNullable<Dig<InstituteDetailsQueryQuery, ["institute"]>>) {
  return [
    {
      tabLabel: "link Logs",
      path: "linklogs",
      component: fromArray(dat.ehrLinks.nodes).caseOf({
        Just: (links) => <EhrLinkLogList ehrLinkId={links.map((x) => x.id).toArray()} />,
        Nothing: () => <div>No EHR Links</div>,
      }),
    },
  ];
}

function InstituteDetails(props: InstituteDetailsProps): ReactElement {
  return (
    <DetailsLayout
      queryResult={useInstituteDetailsQueryQuery({
        variables: {
          instituteId: props.instituteId,
        },
      })}
      setSubCrumbs={props.setSubCrumbs}
      subCrumbs={(_x) => []}
      headerContents={(x) => (
        <Stack direction="column" spacing={1}>
          <Box sx={{ display: "none" }}>
            <UnderMeasurementChip
              measurementAllowed={x.measurementAllowed}
              measureStarting={x.measureStarting}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Chip icon={<Face />} label="Patients" component={Link} to={"patients"} clickable />
            <Chip icon={<Group />} label="Providers" component={Link} to={"providers"} clickable />
            <Chip
              icon={<LocationCity />}
              label="Organizations"
              component={Link}
              to={"organizations"}
              clickable
            />
            <Chip
              icon={<Assignment />}
              label="Measurement Bundles"
              component={Link}
              to="measurement-bundles"
              clickable
            />
          </Stack>
        </Stack>
      )}
      browserTitle={() => "Institute"}
      titleAction={(x) => <LaunchInstituteButton instituteId={x.id} />}
      resultFetcher={(x: InstituteDetailsQueryQuery) => x.institute}
      title={(x) => x.name}
      overviewFields={(x) => overviewFields(x)}
      tabs={(x) => tabs(x)}
    />
  );
}

function overviewFields(dat: NonNullable<Dig<InstituteDetailsQueryQuery, ["institute"]>>) {
  return [
    { name: "id", value: dat.id.toString() },
    { name: "name", value: dat.name },
    { name: "Measurement Allowed", value: dat.measurementAllowed },
  ];
}
export default InstituteDetails;
