import { SimpleLandingPageWithLink } from "Shared/SimpleLandingPage";
import { useTranslation } from "react-i18next";
import React from "react";

// We need the two level affair so that we bootstrap adequately to get the institute id before we can send the sso request.
export default function LoggedOut() {
  const { t } = useTranslation(["common"]);

  return (
    <SimpleLandingPageWithLink
      title={t("common:loggedOut.title")}
      subtitle={t("common:loggedOut.subtitle")}
      href="/app/landing-page"
      cta={t("common:loggedOut.cta")}
    />
  );
}
