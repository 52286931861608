import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { AllTreatmentTargetsQuery, useAllTreatmentTargetsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DigUnpacked } from "type-utils";

type treatmentTargetType = DigUnpacked<
  AllTreatmentTargetsQuery,
  ["collaborativeCareTreatmentTargets", "nodes"]
>;

type TreatmentPickerProps = {
  value: treatmentTargetType | undefined;
  onChange: (newValue: treatmentTargetType | null) => void;
  label: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | null;
};

export function TreatmentTargetPicker(props: TreatmentPickerProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useAllTreatmentTargetsQuery());

  const emptySelect = (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <Select />
    </FormControl>
  );

  return remoteData.caseOf({
    NotAsked: () => emptySelect,
    Loading: () => emptySelect,
    Failure: (_err) => emptySelect,
    Success: (response) => {
      const targets = response.collaborativeCareTreatmentTargets?.nodes;
      if (targets === undefined) {
        return emptySelect;
      }

      return (
        <Autocomplete
          value={props.value}
          onChange={(_event, newValue) => {
            props.onChange(newValue);
          }}
          options={targets}
          renderInput={(params) => (
            <TextField
              label={props.label}
              {...params}
              error={props.error}
              fullWidth={props.fullWidth}
              helperText={props.helperText}
            />
          )}
          getOptionLabel={(option) => option.name}
        />
      );
    },
  });
}

type TreatmentTargetPickerMultipleProps = {
  value: Array<treatmentTargetType>;
  onChange: (newValue: Array<treatmentTargetType>) => void;
  label: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string | null;
};

export function TreatmentTargetPickerMultiple(props: TreatmentTargetPickerMultipleProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(useAllTreatmentTargetsQuery());

  const emptySelect = (
    <FormControl fullWidth={props.fullWidth}>
      <InputLabel>{props.label}</InputLabel>
      <Select />
    </FormControl>
  );

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  const bundleAndScaleNameArray = props.value.map((selection) => {
    const scaleNames = selection.measurementPlan.measurementPlanScales.map((mps) => mps.scale.name);
    return {
      bundleName: selection.name,
      scaleNames: scaleNames,
    };
  });

  let bundleExplainationText = <Typography>{t("enrollment.bundlesDisplayHelpNoSelection")}</Typography>;
  if (bundleAndScaleNameArray.length > 0) {
    bundleExplainationText = <Typography>{t("enrollment.bundlesDisplayHelpWithSelection")}</Typography>;
  }

  return remoteData.caseOf({
    NotAsked: () => emptySelect,
    Loading: () => emptySelect,
    Failure: (_err) => emptySelect,
    Success: (response) => {
      const targets = response.collaborativeCareTreatmentTargets?.nodes;
      if (targets === undefined) {
        return emptySelect;
      }

      return (
        <>
          <Autocomplete
            multiple
            disableCloseOnSelect
            value={props.value}
            onChange={(_event, newValue) => {
              props.onChange(newValue);
            }}
            options={targets}
            renderInput={(params) => (
              <TextField
                label={props.label}
                {...params}
                error={props.error}
                fullWidth={props.fullWidth}
                helperText={props.helperText}
              />
            )}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
          />
          {bundleExplainationText}
          {bundleAndScaleNameArray.map(function (bundleAndScaleName) {
            const title = <Typography variant={"h3"}>{bundleAndScaleName.bundleName}</Typography>;
            return (
              <React.Fragment key={bundleAndScaleName.bundleName}>
                {title}
                <ul>
                  {bundleAndScaleName.scaleNames.map(function (name) {
                    return <li>{name}</li>;
                  })}
                </ul>
              </React.Fragment>
            );
          })}
        </>
      );
    },
  });
}
