import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { EntityTreeNodeParams } from "GeneratedGraphQL/SchemaAndOperations";
import { useStickyBooleanParameter } from "Shared/StickyParameter";
import React from "react";
import { EntityPathFromParams } from "./EntityPath";
import { useTranslation } from "react-i18next";

type QueryParamsProps = {
  exact: boolean;
  setExact: (value: boolean) => void;
  moreSpecific: boolean;
  setMoreSpecific: (value: boolean) => void;
  lessSpecific: boolean;
  setLessSpecific: (value: boolean) => void;
  reset: () => void;
  filterIsDefault: boolean;
};

const DEFAULTS = {
  exact: true,
  moreSpecific: false,
  lessSpecific: false,
};

export function useStickyEntityQueryParams(filterSet: string) {
  const [exact, setExact] = useStickyBooleanParameter("entityQueryExact", filterSet, DEFAULTS.exact, true);
  const [moreSpecific, setMoreSpecific] = useStickyBooleanParameter(
    "entityQueryMoreSpecific",
    filterSet,
    DEFAULTS.moreSpecific,
    true
  );
  const [lessSpecific, setLessSpecific] = useStickyBooleanParameter(
    "entityQueryLessSpecific",
    filterSet,
    DEFAULTS.lessSpecific,
    true
  );

  const reset = () => {
    setExact(DEFAULTS.exact);
    setMoreSpecific(DEFAULTS.moreSpecific);
    setLessSpecific(DEFAULTS.lessSpecific);
  };

  const filterIsDefault =
    exact === DEFAULTS.exact &&
    moreSpecific === DEFAULTS.moreSpecific &&
    lessSpecific === DEFAULTS.lessSpecific;

  return {
    exact,
    setExact,
    moreSpecific,
    setMoreSpecific,
    lessSpecific,
    setLessSpecific,
    reset,
    filterIsDefault,
  };
}

/**
 * Puts a message on the page notifying the user what they are filtering on and what they can do about it.
 */
export function CurrentEntityQueryFilterExplanation(props: {
  exact: boolean;
  moreSpecific: boolean;
  lessSpecific: boolean;
}) {
  const { exact, moreSpecific, lessSpecific } = props;
  const { t } = useTranslation(["common"]);

  if (!exact && !moreSpecific && !lessSpecific) {
    return (
      <Typography variant="caption">
        {t("common:entityQuery.allExplanation")} {t("common:entityQuery.filterAtTop")}
      </Typography>
    );
  }

  const explanations = [];

  if (exact) {
    explanations.push(t("common:entityQuery.exactExplanation"));
  }
  if (moreSpecific) {
    explanations.push(t("common:entityQuery.moreSpecificExplanation"));
  }
  if (lessSpecific) {
    explanations.push(t("common:entityQuery.lessSpecificExplanation"));
  }

  const explanationString = explanations.join("; ");

  return (
    <Typography variant="caption">
      {t("common:entityQuery.someExplanation")} {explanationString}. {t("common:entityQuery.filterAtTop")}
    </Typography>
  );
}

export function EntityQueryConfigCheckboxes(props: {
  params: QueryParamsProps;
  currentEntityTreeParams: EntityTreeNodeParams;
}) {
  const params = props.params;
  const { t } = useTranslation(["common"]);

  const currentPath = (
    <EntityPathFromParams node={props.currentEntityTreeParams} includeInstitute="when_solo" />
  );

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={params.exact} onChange={() => params.setExact(!params.exact)} />}
        label={
          <Stack direction={"row"} spacing={0.5}>
            <Typography>{t("common:entityQuery.exact")}</Typography>
            {currentPath}
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={params.moreSpecific}
            onChange={() => params.setMoreSpecific(!params.moreSpecific)}
          />
        }
        label={
          <Stack direction={"row"} spacing={0.5}>
            <Typography>{t("common:entityQuery.moreSpecific")}</Typography>
            {currentPath}
          </Stack>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={params.lessSpecific}
            onChange={() => params.setLessSpecific(!params.lessSpecific)}
          />
        }
        label={
          <Stack direction={"row"} spacing={0.5}>
            <Typography>{t("common:entityQuery.lessSpecificPrefix")}</Typography>
            {currentPath}
            <Typography>{t("common:entityQuery.lessSpecificSuffix")}</Typography>
          </Stack>
        }
      />
    </>
  );
}
