import React from "react";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";

// Taken from reactjs hook FAQ at https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious<T>(hookValue: T): T | undefined {
  const prevRef = React.useRef<T>(undefined);
  React.useEffect(() => {
    prevRef.current = hookValue;
  });
  return prevRef.current;
}

/** Runs a side effect each time your component renders or re-renders.
 *
 * @param effect The effect to run on every render
 */
export function useEffectEveryRender(effect: React.EffectCallback): void {
  React.useEffect(effect);
}

/**
 * Runs a side effect exactly once on the first render of your component.
 * For somewhat obscure bug-catching reasons React will run the effect a second time only during developement.
 *
 * @param effect The effect to run once
 */
export function useEffectOnce(effect: React.EffectCallback): void {
  React.useEffect(effect, []);
}

// These are the types that will use value equality when being compared with Object.is. All other types use reference
// equality, which is almost certainly not what you want when you're writing a useEffect hook.
type SimpleComparable = string | number | boolean | null | undefined | bigint | symbol;
type SimpleComparableList = ReadonlyArray<SimpleComparable>;

/**
 * Runs a side effect on the first render and then again when any of the values in the dependencies list change. The
 * values in the dependencies list are compared with Object.is, and this function only accepts dependency values that
 * compare using value equality with that function. It is _strongly_ preferred to use this hook when writing a
 * dependent effect hook, and only to fall back to useEffectDeepCompare if that isn't possible.
 *
 * @param effect The effect to run when the dependency list changes
 * @param deps The list of dependent values, changes in which should trigger running the effect
 */
export function useEffectSimpleCompare(effect: React.EffectCallback, deps: SimpleComparableList): void {
  React.useEffect(effect, deps);
}

// Re-exporting this mostly to have name consistency and so you can import things from one namespace.
/**
 * Run a side effect on the first render and then again when any of the values in the dependencies list change. The
 * values in the dependencies list are compared with deep equality from the `dequal` package. It is _strongly_ preferred
 * to use useEffectSimpleCompare when possible, and only use this hook if that isn't tractable.
 */
export const useEffectDeepCompare = useDeepCompareEffectNoCheck;

/**
 * Take a value that can change, and return a debounced version of that value that only updates after there have been
 * no changes to it for <delay> milliseconds. Useful to be able to do things like not spam search requests while typing
 * (both to avoid running extra queries, and to avoid showing the user an old query that happens to come back while
 * they're still typing).)
 *
 * This implementation based on https://usehooks.com/useDebounce/
 *
 * @param value The current high-frequency value
 * @param delay How long (in milliseconds) the value should be settled before the debounced value updates
 * @returns The most recent value that didn't change for at least delay milliseconds
 */
export function useDebounce<T extends SimpleComparable>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  useEffectSimpleCompare(() => {
    // Every time value changes, start a timeout to update the debounced value after a delay, unless...
    const delayedSet = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // ... this gets called again, in which case React will run this function before it runs the effect again,
    // canceling the original delayed set, and replacing it with the new one.
    return () => {
      clearTimeout(delayedSet);
    };
  }, [value, delay]);

  return debouncedValue;
}
