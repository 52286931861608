import { Trend } from "GeneratedGraphQL/SchemaAndOperations";

function name(ac: Trend): string {
  switch (ac) {
    case Trend.DECLINING:
      return "Worsening";
    case Trend.IMPROVING:
      return "Improving";
    case Trend.STABLE:
      return "Stable";
    case Trend.INVALID:
      return "Invalid";
    case Trend.NOT_SPECIFIED:
      return "Not Specified";
    case Trend.NOT_SUPPORTED:
      return "Not Supported";
  }
}

function isSupported(trend: Trend): boolean {
  switch (trend) {
    case Trend.DECLINING:
    case Trend.IMPROVING:
    case Trend.STABLE:
    case Trend.INVALID:
    case Trend.NOT_SPECIFIED:
      return true;
    case Trend.NOT_SUPPORTED:
      return false;
  }
}

type WithTrend = {
  trend: Trend;
};

function supportsTrend(a: WithTrend): boolean {
  return isSupported(a.trend);
}

export default Trend;
export { name, isSupported, supportsTrend, Trend };
