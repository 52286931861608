import { PRIVACY_MODE_STORAGE_KEY } from "Shared/Storage";
import React, { ReactElement, useState } from "react";

type DiscreetModeType = {
  discreetMode: boolean;
  setDiscreetMode: (newDiscreetMode: boolean) => void;
};
export const DiscreetModeContext: React.Context<DiscreetModeType> = React.createContext({
  discreetMode: currentDiscreetMode(),
  setDiscreetMode: (_newDiscreteMode: boolean) => {
    /* Go away linter */
  },
});

export function currentDiscreetMode(): boolean {
  const discreetMode = sessionStorage.getItem(PRIVACY_MODE_STORAGE_KEY);
  if (discreetMode == "true") {
    return true;
  }
  return false;
}

export function DiscreetModeContextProvider({ children }: { children: ReactElement }): ReactElement {
  const [discreetMode, setDiscreetMode] = useState<boolean>(currentDiscreetMode());
  return (
    <DiscreetModeContext.Provider
      value={{
        discreetMode: discreetMode,
        setDiscreetMode: (newDiscreetMode: boolean) => {
          if (!newDiscreetMode) {
            sessionStorage.removeItem(PRIVACY_MODE_STORAGE_KEY);
          } else {
            sessionStorage.setItem(PRIVACY_MODE_STORAGE_KEY, "true");
          }
          setDiscreetMode(newDiscreetMode);
        },
      }}
    >
      {children}
    </DiscreetModeContext.Provider>
  );
}
