import { ApolloError } from "@apollo/client";
import { Alert, Box, ButtonProps, DialogContent, Typography } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  EntityTreeNodeParams,
  MetricDataExportStatus,
  OutcomesMetricDataExportQuery,
  useCreateOutcomesMetricDataExportMutation,
  useOutcomesMetricDataExportDownloadUrlMutation,
  useOutcomesMetricDataExportQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { MetricDataExportId, MetricId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import Spinner from "Shared/Spinner";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Failure, NotAsked, RemoteData, Success } from "seidr";

type DownloadDialogProps = {
  metricDataExportId: MetricDataExportId;
  open: boolean;
  onClose: () => void;
};

function DownloadFileDialog(props: DownloadDialogProps) {
  const { t } = useTranslation(["outcomes"]);

  const [downloadError, setDownloadError] = useState<string | null>(null);

  // We don't want the loading screen to flash up while it polls, so we use a use state hook here to only update it when we get new data from the server.
  const [loadedData, setLoadedData] = useState<RemoteData<ApolloError, OutcomesMetricDataExportQuery>>(
    NotAsked()
  );

  useOutcomesMetricDataExportQuery({
    variables: {
      metricDataExportId: props.metricDataExportId,
    },
    pollInterval: 5000,
    onCompleted: (data) => {
      setLoadedData(Success(data));
    },
    onError: (e) => {
      setLoadedData(Failure(e));
    },
  });

  const processingContent = (
    <Box textAlign={"center"}>
      <Typography>{t("outcomes:download.processing")}</Typography>
      <Spinner />
    </Box>
  );

  const errorContent = (
    <Box textAlign={"center"}>
      <Typography>{t("outcomes:download.error")}</Typography>
    </Box>
  );

  const content = loadedData.caseOf({
    Success: (data) => {
      if (data.outcomesMetricDataExport) {
        switch (data.outcomesMetricDataExport.status) {
          case MetricDataExportStatus.ERROR:
            return errorContent;
          case MetricDataExportStatus.NEW:
          case MetricDataExportStatus.PROCESSING:
            return processingContent;
          case MetricDataExportStatus.READY:
            return (
              <Box textAlign={"center"}>
                <Typography>{t("outcomes:download.ready")}</Typography>
                <DownloadMetricExcelButton
                  metricDataExportId={props.metricDataExportId}
                  name={data.outcomesMetricDataExport.filename}
                  setError={setDownloadError}
                />
                {downloadError ? <Alert severity="error">{downloadError}</Alert> : null}
              </Box>
            );
        }
      } else {
        return errorContent;
      }
    },
    Failure: () => {
      return errorContent;
    },
    _: () => {
      return processingContent;
    },
  });

  if (props.open) {
    return (
      <ResponsiveDialog
        open={props.open}
        title={t("outcomes:download.title")}
        keepMounted={false}
        dialogWidth="50%"
        onClose={props.onClose}
      >
        <DialogContent>{content}</DialogContent>
      </ResponsiveDialog>
    );
  }
  return null;
}

function DownloadMetricExcelButton(props: {
  metricDataExportId: MetricDataExportId;
  name: string;
  setError: (error: string) => void;
}) {
  const [downloadFile, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesCreateMetricDataExportDownloadUrl,
    useOutcomesMetricDataExportDownloadUrlMutation({
      variables: {
        input: {
          metricDataExportId: props.metricDataExportId,
        },
      },
    })
  );
  const showSpinner = remoteData.kind === "Loading";

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: (result) => {
        if (result?.downloadUrl) {
          window.location.href = result.downloadUrl;
        }
      },
      Failure: (error) => {
        props.setError(error.toError().message);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const onClick = () => {
    downloadFile({ variables: { input: { metricDataExportId: props.metricDataExportId } } });
  };

  return (
    <ButtonWithSpinner
      variant="contained"
      color="primary"
      showSpinner={showSpinner}
      disabled={showSpinner}
      onClick={onClick}
    >
      {props.name}
    </ButtonWithSpinner>
  );
}

type DownloadMetricExcelProps = {
  metricId: MetricId;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  children: ReactNode;
  buttonProps?: Partial<ButtonProps>;
};

export default function DownloadMetricExcel(props: DownloadMetricExcelProps) {
  const [createDataExport, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesCreateMetricDataExport,
    useCreateOutcomesMetricDataExportMutation()
  );

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const dialog = remoteData.caseOf({
    Success: (data) => {
      if (data?.metricDataExport) {
        return (
          <DownloadFileDialog
            metricDataExportId={data.metricDataExport.id}
            open={showDialog}
            onClose={() => setShowDialog(false)}
          />
        );
      } else {
        return null;
      }
    },
    _: () => null,
  });

  const onSubmit = () => {
    createDataExport({
      variables: {
        input: {
          dateRange: {
            min: props.startDate,
            max: props.endDate,
          },
          entityTreeNode: props.entityTreeNode,
          metricId: props.metricId,
        },
      },
      onCompleted: () => {
        setShowDialog(true);
      },
    });
  };

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <>
      {dialog}
      <ButtonWithSpinner
        showSpinner={showSpinner}
        disabled={disabled}
        onClick={onSubmit}
        {...props.buttonProps}
      >
        {props.children}
      </ButtonWithSpinner>
    </>
  );
}
