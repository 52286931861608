import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  InstituteCondition,
  TreatmentService,
  useTreatmentServicesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { LinkButton, LinkIconButton } from "MDS/Link";
import React, { ReactElement } from "react";
import EditIcon from "@mui/icons-material/Edit";
import GridWithCenterableItems from "MDS/GridWithCenterableItems";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import PersonIcon from "@mui/icons-material/Person";
import { PickTypename } from "type-utils";

function TreatmentServices(): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  return (
    <Page browserTitle={t("treatmentServices:index.title")}>
      <Card>
        <CardHeader
          title={t("treatmentServices:index.title")}
          action={
            <LinkButton to="create" sx={{ width: "fit-content" }} variant="contained">
              {t("treatmentServices:index.createButton")}
            </LinkButton>
          }
        ></CardHeader>
        <CardContent>
          <GridWithCenterableItems container spacing={2} columns={12} paddingTop={"1em"}>
            <Grid item xs={3}>
              <Typography variant="h3">{t("treatmentServices:index.columns.name")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3">{t("treatmentServices:index.columns.parentName")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3">{t("treatmentServices:index.columns.serviceTypes")}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="h3">{t("treatmentServices:index.columns.conditions")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h3">{t("treatmentServices:index.columns.measurementAllowed")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h3">{t("treatmentServices:index.columns.associate")}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h3">{t("treatmentServices:index.columns.edit")}</Typography>
            </Grid>
            <TreatmentServicesList />
          </GridWithCenterableItems>
        </CardContent>
      </Card>
    </Page>
  );
}

function TreatmentServicesList(): ReactElement {
  const { t } = useTranslation(["treatmentServices"]);
  const { remoteData } = apolloQueryHookWrapper(useTreatmentServicesQuery());

  return remoteData.caseOf({
    Loading: () => {
      return <CircularProgress />;
    },
    NotAsked: () => {
      return <CircularProgress />;
    },
    Failure: (_error) => {
      return <>{t("treatmentServices:index.error")}</>;
    },
    Success: (result) => {
      if (result.treatmentServices?.nodes) {
        return <TreatmentServicesListElement treatmentServices={result.treatmentServices.nodes} />;
      }
      return <>{t("treatmentServices:index.error")}</>;
    },
  });
}

type TreatmentServiceListElementProps = {
  treatmentServices: ReadonlyArray<
    Pick<
      TreatmentService,
      "__typename" | "id" | "name" | "parentId" | "measurementAllowed" | "serviceTypes"
    > & {
      instituteConditions: ReadonlyArray<PickTypename<InstituteCondition, "id" | "name">>;
    }
  >;
};
function TreatmentServicesListElement(props: TreatmentServiceListElementProps): ReactElement {
  const treatmentServices = [...props.treatmentServices]
    .sort((a, b) => {
      if (a.name == b.name) {
        return 0;
      } else if (a.name > b.name) {
        return 1;
      }
      return -1;
    })
    .map((treatmentService, index) => {
      return (
        <TreatmentServiceElement
          treatmentService={treatmentService}
          treatmentServices={props.treatmentServices}
          row={index}
          key={index}
        />
      );
    });
  return <>{treatmentServices}</>;
}

type TreatmentServiceProps = {
  treatmentService: Pick<
    TreatmentService,
    "__typename" | "id" | "name" | "parentId" | "measurementAllowed" | "serviceTypes"
  > & {
    instituteConditions: ReadonlyArray<PickTypename<InstituteCondition, "id" | "name">>;
  };
  treatmentServices: ReadonlyArray<
    Pick<TreatmentService, "__typename" | "id" | "name" | "parentId" | "measurementAllowed" | "serviceTypes">
  >;
  row: number;
};
function TreatmentServiceElement(props: TreatmentServiceProps): ReactElement {
  // We're going to even/odd some light shadowing to make the rows more distinct.
  const theme = useTheme();
  const background = props.row % 2 == 0 ? theme.palette.common.white : "transparent";
  // The database just returns an id, but we want to know the actual name of our parent. We have to search
  // through the set of treatmentServices to find it.
  const parentName = props.treatmentServices.find((treatmentService) => {
    return treatmentService.id == props.treatmentService.parentId;
  })?.name;
  const conditions = props.treatmentService.instituteConditions.map((ic) => {
    return ic.name;
  });

  return (
    <>
      <Grid item xs={3} sx={{ background: background }}>
        {props.treatmentService.name}
      </Grid>
      <Grid item xs={2} sx={{ background: background }}>
        {parentName}
      </Grid>
      <Grid item xs={2} sx={{ background: background }}>
        {props.treatmentService.serviceTypes.join(", ")}
      </Grid>
      <Grid item xs={2} sx={{ background: background }}>
        {conditions.join(", ")}
      </Grid>
      <Grid item xs={1} sx={{ background: background }}>
        {props.treatmentService.measurementAllowed ? <CheckIcon /> : <DoNotDisturbIcon />}
      </Grid>
      <Grid item xs={1} sx={{ background: background }}>
        <LinkIconButton to={`${props.treatmentService.id}/associate`}>
          <PersonIcon />
        </LinkIconButton>
      </Grid>
      <Grid item xs={1} sx={{ background: background }}>
        <LinkIconButton to={`${props.treatmentService.id}/update`}>
          <EditIcon />
        </LinkIconButton>
      </Grid>
    </>
  );
}

export default TreatmentServices;
