import { Add } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  DialogContent,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper, remoteErrorMessage } from "Api/GraphQL";
import { careEpisodeTreatmentTrackStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisodeTreatmentTrack,
  CareEpisodeTreatmentTrackStatus,
  TreatmentTrack,
  TreatmentTrackStatus,
  useCreateCareEpisodeTreatmentTrackMutation,
  useTreatmentTracksByCareEpisodeIdQuery,
  useUpdateCareEpisodeTreatmentTrackMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, TreatmentTrackId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import TreatmentTrackSelect from "Shared/Filters/TreatmentTrackSelect";
import Spinner from "Shared/Spinner";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

type EditCareEpisodeTreatmentTrackDialogProps = {
  careEpisodeId: CareEpisodeId;
  open: boolean;
  onClose: () => void;
};

type CareEpisodeTreatmentTrackSummary = Pick<
  CareEpisodeTreatmentTrack,
  "id" | "status" | "startSessionNumber" | "enrollmentDate"
> & {
  treatmentTrack: Pick<TreatmentTrack, "id" | "name">;
};

function CompleteButton(props: { careEpisodeTreatmentTrack: CareEpisodeTreatmentTrackSummary }) {
  const { careEpisodeTreatmentTrack } = props;
  const { t } = useTranslation(["careEpisodes", "enums"]);

  const [confirm, setConfirm] = useState<boolean>(false);

  // Mutation: Update a care episode treatment track with the provided status.
  const [updateCareEpisodeTreatmentTrack, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.careUpdateCareEpisodeTreatmentTrack,
    useUpdateCareEpisodeTreatmentTrackMutation({
      variables: {
        input: {
          careEpisodeTreatmentTrackId: props.careEpisodeTreatmentTrack.id,
          status: CareEpisodeTreatmentTrackStatus.COMPLETE,
        },
      },
      refetchQueries: refetchQueries("treatmentTracks"),
    })
  );

  const showSpinner = remoteData.kind === "Loading";

  const dialog = confirm ? (
    <ResponsiveDialog
      dialogWidth="50%"
      open={confirm}
      onClose={() => setConfirm(false)}
      title={t("careEpisodes:treatmentTracks.dialogTitle")}
      stopBackdropClose={false}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Alert severity="info">
            <AlertTitle>{t("careEpisodes:treatmentTracks.pills.completeTitle")}</AlertTitle>
            {t("careEpisodes:treatmentTracks.pills.completeText", {
              name: careEpisodeTreatmentTrack.treatmentTrack.name,
            })}
          </Alert>
          <Stack direction="row" spacing={1}>
            <ButtonWithSpinner
              showSpinner={showSpinner}
              variant={"contained"}
              color="primary"
              disabled={showSpinner}
              onClick={() => updateCareEpisodeTreatmentTrack()}
            >
              {t("careEpisodes:treatmentTracks.completeDialog.confirm")}
            </ButtonWithSpinner>
            <Button
              onClick={() => setConfirm(false)}
              disabled={false}
              variant={"outlined"}
              color={"secondary"}
            >
              {t("careEpisodes:treatmentTracks.completeDialog.abort")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </ResponsiveDialog>
  ) : null;

  return (
    <>
      {dialog}
      <Button variant="contained" color={"primary"} onClick={() => setConfirm(true)}>
        {t("careEpisodes:treatmentTracks.completeDialog.button")}
      </Button>
    </>
  );
}

function EnteredInErrorButton(props: { careEpisodeTreatmentTrack: CareEpisodeTreatmentTrackSummary }) {
  const { careEpisodeTreatmentTrack } = props;
  const { t } = useTranslation(["careEpisodes", "enums"]);

  const [confirm, setConfirm] = useState<boolean>(false);

  // Mutation: Update a care episode treatment track with the provided status.
  const [updateCareEpisodeTreatmentTrack, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.careUpdateCareEpisodeTreatmentTrack,
    useUpdateCareEpisodeTreatmentTrackMutation({
      variables: {
        input: {
          careEpisodeTreatmentTrackId: props.careEpisodeTreatmentTrack.id,
          status: CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR,
        },
      },
      refetchQueries: refetchQueries("treatmentTracks"),
    })
  );

  const showSpinner = remoteData.kind === "Loading";

  const dialog = confirm ? (
    <ResponsiveDialog
      dialogWidth="50%"
      open={confirm}
      onClose={() => setConfirm(false)}
      title={t("careEpisodes:treatmentTracks.dialogTitle")}
      stopBackdropClose={false}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Alert severity="warning">
            <AlertTitle>{t("careEpisodes:treatmentTracks.pills.enteredInErrorTitle")}</AlertTitle>
            {t("careEpisodes:treatmentTracks.pills.enteredInErrorText", {
              name: careEpisodeTreatmentTrack.treatmentTrack.name,
            })}
          </Alert>
          <Stack direction="row" spacing={1}>
            <ButtonWithSpinner
              showSpinner={showSpinner}
              variant={"contained"}
              color="primary"
              disabled={showSpinner}
              onClick={() => updateCareEpisodeTreatmentTrack()}
            >
              {t("careEpisodes:treatmentTracks.enteredInErrorDialog.confirm")}
            </ButtonWithSpinner>
            <Button
              onClick={() => setConfirm(false)}
              disabled={false}
              variant={"outlined"}
              color={"secondary"}
            >
              {t("careEpisodes:treatmentTracks.enteredInErrorDialog.abort")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </ResponsiveDialog>
  ) : null;

  return (
    <>
      {dialog}
      <Button variant="contained" color={"warning"} onClick={() => setConfirm(true)}>
        {t("careEpisodes:treatmentTracks.enteredInErrorDialog.button")}
      </Button>
    </>
  );
}

const SelectContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(15),
}));

function TreatmentTrackRow(props: { careEpisodeTreatmentTrack: CareEpisodeTreatmentTrackSummary }) {
  const { t } = useTranslation(["careEpisodes", "enums"]);
  const { careEpisodeTreatmentTrack } = props;

  const actions =
    props.careEpisodeTreatmentTrack.status === CareEpisodeTreatmentTrackStatus.ACTIVE ? (
      <>
        <CompleteButton careEpisodeTreatmentTrack={careEpisodeTreatmentTrack} />
        <EnteredInErrorButton careEpisodeTreatmentTrack={careEpisodeTreatmentTrack} />
      </>
    ) : null;
  return (
    <TableRow>
      <TableCell>{props.careEpisodeTreatmentTrack.treatmentTrack.name}</TableCell>
      <TableCell>{careEpisodeTreatmentTrackStatusT(props.careEpisodeTreatmentTrack.status, t)}</TableCell>
      <TableCell>
        <Typography fontSize={"0.8em"}>
          {t("careEpisodes:treatmentTracks.startDateAndSession", {
            date: props.careEpisodeTreatmentTrack.enrollmentDate,
            startSessionNumber: props.careEpisodeTreatmentTrack.startSessionNumber,
          })}
        </Typography>
      </TableCell>
      <TableCell>{actions}</TableCell>
    </TableRow>
  );
}

export default function EditCareEpisodeTreatmentTracksDialog(
  props: EditCareEpisodeTreatmentTrackDialogProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes", "enums"]);

  const [selectedTrack, setSelectedTrack] = useState<TreatmentTrackId | null>(null);

  // Query: What tracks does this care episode currently have?
  const { remoteData: currentRemoteData } = apolloQueryHookWrapper(
    useTreatmentTracksByCareEpisodeIdQuery({
      variables: {
        careEpisodeId: props.careEpisodeId,
        status: [CareEpisodeTreatmentTrackStatus.ACTIVE, CareEpisodeTreatmentTrackStatus.COMPLETE],
      },
    })
  );

  // Mutation: I want to add this track.
  const [createCareEpisodeTreatmentTrack, { remoteData: addRemoteData }] = apolloMutationHookWrapper(
    (data) => data.careCreateCareEpisodeTreatmentTrack,
    useCreateCareEpisodeTreatmentTrackMutation({
      refetchQueries: refetchQueries("treatmentTracks"),
    })
  );

  const handleAddTracks = function () {
    if (selectedTrack) {
      createCareEpisodeTreatmentTrack({
        variables: {
          input: {
            careEpisodeId: props.careEpisodeId,
            treatmentTrackId: selectedTrack,
            status: CareEpisodeTreatmentTrackStatus.ACTIVE,
            enrollmentDate: new Date(),
          },
        },
      });
    }

    setSelectedTrack(null);
  };

  const addError = addRemoteData.caseOf({
    Failure: (error) => <Alert severity="error">{remoteErrorMessage(error)}</Alert>,
    _: () => null,
  });

  const rows = currentRemoteData.caseOf({
    Success: (data) => {
      if (
        data.careCareEpisodeTreatmentTracks?.nodes &&
        data.careCareEpisodeTreatmentTracks.nodes.length > 0
      ) {
        return data.careCareEpisodeTreatmentTracks.nodes.map((row) => {
          return <TreatmentTrackRow key={row.id.toString()} careEpisodeTreatmentTrack={row} />;
        });
      } else {
        return (
          <TableRow>
            <TableCell colSpan={3}>{t("careEpisodes:treatmentTracks.noTracks")}</TableCell>
          </TableRow>
        );
      }
    },
    Failure: (error) => {
      return (
        <TableRow>
          <TableCell colSpan={3}>{error.message}</TableCell>
        </TableRow>
      );
    },
    _: () => {
      return (
        <TableRow>
          <TableCell colSpan={3}>
            <Spinner />
          </TableCell>
        </TableRow>
      );
    },
  });

  return (
    <ResponsiveDialog
      dialogWidth="50%"
      open={props.open}
      onClose={props.onClose}
      title={t("careEpisodes:treatmentTracks.dialogTitle")}
      stopBackdropClose={false}
    >
      <DialogContent>
        <Stack spacing={1}>
          <Typography>{t("careEpisodes:treatmentTracks.explanation")}</Typography>
          {addError}
          <Table>
            <TableBody>{rows}</TableBody>
          </Table>
          <Divider />
          <Typography variant="h3">{t("careEpisodes:treatmentTracks.addTracks")}</Typography>
          <Stack direction="row" spacing={1}>
            <SelectContainer>
              <TreatmentTrackSelect
                setValue={setSelectedTrack}
                value={selectedTrack}
                status={TreatmentTrackStatus.ACTIVE}
              />
            </SelectContainer>
            <Button onClick={handleAddTracks} variant="contained" color="primary">
              <Add />
              {t("careEpisodes:actions.add")}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </ResponsiveDialog>
  );
}

type EditCareEpisodeTreatmentTracksProps = {
  careEpisodeId: CareEpisodeId;
  buttonMinWidth?: string;
};

export function EditCareEpisodeTreatmentTracksButton(
  props: EditCareEpisodeTreatmentTracksProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const [open, setOpen] = React.useState(false);

  const dialog = open ? (
    <EditCareEpisodeTreatmentTracksDialog
      open={open}
      onClose={() => setOpen(false)}
      careEpisodeId={props.careEpisodeId}
    />
  ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
        {t("careEpisodes:treatmentTracks.edit")}
      </Button>
      {dialog}
    </Box>
  );
}
