import React, { ReactNode, ReactElement } from "react";
import { Typography, Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLevelHeader = styled(Typography)(() => ({
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  alignItems: "center",
  lineHeight: "1em",
}));

type Props = {
  children: ReactNode;
};

function LevelHeader(props: Props): ReactElement {
  return <StyledLevelHeader variant="h1">{props.children}</StyledLevelHeader>;
}

function LevelHeaderLoadingIndicator(): ReactElement {
  return <Skeleton height={21} width={150} data-testid="loading-indicator" />;
}

export default LevelHeader;
export { LevelHeader, LevelHeaderLoadingIndicator };
