const treatmentServices = {
  index: {
    title: "Treatment Services",
    createButton: "Create a new Treatment Service",
    error: "There was an error loading Treatment Services",
    columns: {
      name: "Name",
      measurementAllowed: "Measurement Allowed",
      parentName: "Parent",
      edit: "Edit",
      serviceTypes: "Service Types",
      conditions: "Conditions",
      associate: "Associate",
    },
  },
  create: {
    title: "Create a Treatment Service",
    nameLabel: "Name",
    measurementAllowedLabel: "Measurement Allowed",
    serviceTypesLabel: "Service Types",
    serviceTypesHelpText: "Comma Delimited",
    createButton: "Create",
    cancelButton: "Cancel",
    error: "There was an error creating this Treatment Service",
  },
  update: {
    title: "Update a Treatment Service",
    nameLabel: "Name",
    measurementAllowedLabel: "Measurement Allowed",
    serviceTypesLabel: "Service Types",
    serviceTypesHelpText: "Comma Delimited",
    updateButton: "Update",
    cancelButton: "Cancel",
    errorLoading: "There was an error loading this Treatment Service",
    errorUpdating: "There was an error updating this Treatment Service",
  },
  associate: {
    title: "Associate a Treatment Service with Organizations and Providers",
    errorLoading: "There was an error loading this Treatment Service",
    associateButton: "Associate",
    cancelButton: "Cancel",
    organizationsLabel: "Organizations",
    providersLabel: "Providers",
    instituteConditionsLabel: "Conditions",
    pendingLabel: "Pending - ",
    treatmentServiceLabel: "Treatment Service - ",
    directions:
      "Add or remove associations to organizations and providers, then click Associate below.\
    Organizations and providers associated with this treatment service will be used to infer\
    whether this treatment service is appropriate for a given appointment when more specific\
    information is unavailable.",
  },
  select: {
    parentIdLabel: "Parent Treatment Service",
    noneMenuItem: "None",
  },
};

export default treatmentServices;
