import React, { ReactElement } from "react";
import Page from "../Layout/Page";
import { Typography, Container, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";

import { MirahIconTwoTone } from "./MirahIcon";
import { useTranslation } from "react-i18next";

const StyledPage = styled(Page)(({ theme }) => ({
  minHeight: "100%",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(3),
  paddingTop: 80,
  paddingBottom: 80,
}));

const StyledIcon = styled(MirahIconTwoTone)(({ theme }) => ({
  maxWidth: "50%",
  width: 150,
  maxHeight: 300,
  height: "auto",
  marginBottom: theme.spacing(7),
}));

type FatalErrorProps = {
  title?: string;
  subtitle?: string;
  reset: () => void;
};

function FatalError(props: FatalErrorProps): ReactElement {
  const { t } = useTranslation(["general"]);
  const mobileDevice = useMediaQuery(useTheme().breakpoints.down("md"));

  const title = props.title ? props.title : t("general:fatalError.title");
  const subtitle = props.subtitle ? props.subtitle : t("general:fatalError.subtitle");

  return (
    <StyledPage browserTitle="Not found">
      <Container maxWidth="lg">
        <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
          <StyledIcon />
        </Box>
        <Typography align="center" variant={mobileDevice ? "h4" : "h1"} color="textPrimary">
          {title}
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          {subtitle}
        </Typography>

        <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
          <Button color="secondary" onClick={props.reset} variant="outlined">
            {t("general:fatalError.goBack")}
          </Button>
        </Box>
      </Container>
    </StyledPage>
  );
}

export default FatalError;
