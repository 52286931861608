import { Add, Cancel, Check, Delete, Edit } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { scaleTargetingT } from "GeneratedGraphQL/EnumTranslations";
import {
  AdministrableScale,
  CareEpisodeTreatmentTarget,
  InstituteScale,
  InvitationFormat,
  MeasurementPlan,
  MeasurementPlanScale,
  Scale,
  ScaleTargeting,
  Subscale,
  TreatmentTarget,
  useCreateOrEditTreatmentTargetScaleMutation,
  useCreateTreatmentTargetMutation,
  useDeleteTreatmentTargetMutation,
  useDeleteTreatmentTargetScaleMutation,
  useEditTreatmentTargetMutation,
  useInstituteMeasurementBundlesQuery,
  useReplanAllActiveEnrollmentsMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { InstituteId, ScaleId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { MonitoredJobResponsePoll } from "Ops/MonitoredJob";
import EnumSelect from "Shared/EnumSelect";
import ErrorMessage from "Shared/ErrorMessage";
import { Form, FormOverlay, useBooleanField, useForm, useTextField, useWrappedField } from "Shared/Form";
import { ScaleOrSubscaleDescription, ScaleOrSubscalePickerSingle } from "Shared/ScalePicker";
import Spinner from "Shared/Spinner";
import { Duration } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type InstituteMeasurementBundlesProps = {
  instituteId: InstituteId;
};

export function InstituteMeasurementBundles(props: InstituteMeasurementBundlesProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useInstituteMeasurementBundlesQuery({
      variables: {
        instituteId: props.instituteId,
      },
    })
  );

  const [needsReplanning, setNeedsReplanning] = React.useState(false);

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (
        response.collaborativeCareTreatmentTargets &&
        response.assessmentInstituteScales &&
        response.collaborativeCareCareEpisodeTreatmentTargets
      ) {
        return (
          <InstituteMeasurementBundleList
            measurementBundles={response.collaborativeCareTreatmentTargets.nodes}
            instituteScales={response.assessmentInstituteScales.nodes}
            careEpisodeBundles={response.collaborativeCareCareEpisodeTreatmentTargets.nodes}
            onEditUsedBundle={() => {
              setNeedsReplanning(true);
            }}
          />
        );
      } else {
        return <ErrorMessage message="Failed to load measurement bundles" />;
      }
    },
  });

  return (
    <Page browserTitle="Institute Measurement Bundles">
      <Snackbar open={needsReplanning} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="warning" variant="filled" sx={{ width: "35rem", textAlign: "center" }}>
          One or more active bundles have been edited. When you're done editing, replan the institute using
          the button at the top of the page.
        </Alert>
      </Snackbar>
      <Stack direction="column" spacing={1}>
        <ReplanAllEnrollmentsButton
          instituteId={props.instituteId}
          onReplanSuccess={() => setNeedsReplanning(false)}
        />
        <PageHeader title="Institute Measurement Bundles" />
        <CreateBundleForm instituteId={props.instituteId} />
        {content}
      </Stack>
    </Page>
  );
}

type BundleableScaleSummary =
  | PickTypename<AdministrableScale, "id" | "shortname" | "nanoname" | "mnemonic" | "defaultParticipants">
  | PickTypename<Subscale, "id" | "shortname" | "mnemonic" | "nanoname">;

type MeasurementPlanScaleSummary = PickTypename<
  MeasurementPlanScale,
  "id" | "discontinueSession" | "maxRepeatInterval" | "targeting" | "onsetTime"
> & {
  scale: BundleableScaleSummary;
};

type TreatmentTargetSummary = PickTypename<TreatmentTarget, "id" | "name" | "internallyConsistent"> & {
  measurementPlan: PickTypename<MeasurementPlan, "id"> & {
    measurementPlanScales: ReadonlyArray<MeasurementPlanScaleSummary>;
  };
  nonadministrableScalesWithoutAdministrableParents: ReadonlyArray<Pick<Scale, "id" | "mnemonic">>;
};

type InstituteScaleSummary = PickTypename<InstituteScale, "id" | "format" | "disableAdministration"> & {
  scale: PickTypename<AdministrableScale, "id"> | PickTypename<Subscale, "id">;
};

type CareEpisodeTreatmentTargetSummary = PickTypename<CareEpisodeTreatmentTarget, "id"> & {
  treatmentTarget: PickTypename<TreatmentTarget, "id">;
};

type InstituteMeasurementBundleListProps = {
  measurementBundles: ReadonlyArray<TreatmentTargetSummary>;
  instituteScales: ReadonlyArray<InstituteScaleSummary>;
  careEpisodeBundles: ReadonlyArray<CareEpisodeTreatmentTargetSummary>;
  onEditUsedBundle: () => void;
};

function InstituteMeasurementBundleList(props: InstituteMeasurementBundleListProps): ReactElement {
  // This gets us numeric sorting so "ADHD 4-5" is sorted before "ADHD 12-17" even though the latter is
  // lexicographically earlier.
  const collator = new Intl.Collator(undefined, { numeric: true });
  const sortedBundles = [...props.measurementBundles];
  sortedBundles.sort((a, b) => collator.compare(a.name, b.name));

  const formatOverrideLookup = props.instituteScales.reduce<Record<string, InvitationFormat | null>>(
    (acc, instituteScale) => {
      acc[instituteScale.scale.id.toString()] = instituteScale.format;
      return acc;
    },
    {}
  );

  const bundleCountLookup = props.careEpisodeBundles.reduce<Record<string, number>>(
    (acc, careEpisodeBundle) => {
      acc[careEpisodeBundle.treatmentTarget.id.toString()] =
        (acc[careEpisodeBundle.treatmentTarget.id.toString()] || 0) + 1;
      return acc;
    },
    {}
  );

  return (
    <Stack direction="column" spacing={1}>
      {sortedBundles.map((bundle, i) => (
        <MeasurementBundleInfoCard
          measurementBundle={bundle}
          instituteScales={props.instituteScales}
          formatOverrideLookup={formatOverrideLookup}
          careEpisodeCount={bundleCountLookup[bundle.id.toString()] || 0}
          onEditUsedBundle={props.onEditUsedBundle}
          key={i}
        />
      ))}
    </Stack>
  );
}

type DisabledScalesWarningProps = {
  instituteScales: ReadonlyArray<InstituteScaleSummary>;
  bundleScales: ReadonlyArray<BundleableScaleSummary>;
};

function DisabledScalesWarning(props: DisabledScalesWarningProps): ReactElement | null {
  const disabledScales = props.bundleScales.filter((scale) => {
    const instituteScale = props.instituteScales.find((is) => is.scale.id === scale.id);

    return !instituteScale || instituteScale.disableAdministration;
  });

  if (disabledScales.length === 0) {
    return null;
  }

  const scaleNames = [...new Set(disabledScales.map((scale) => scale.nanoname)).values()].join(", ");

  return (
    <Alert severity="error">Some scales in this bundle are not enabled for the institute: {scaleNames}</Alert>
  );
}

type MeasurementBundleInfoProps = {
  measurementBundle: TreatmentTargetSummary;
  instituteScales: ReadonlyArray<InstituteScaleSummary>;
  formatOverrideLookup: Record<string, InvitationFormat | null>;
  careEpisodeCount: number;
  onEditUsedBundle: () => void;
};

function MeasurementBundleInfoCard(props: MeasurementBundleInfoProps) {
  const inUse = props.careEpisodeCount > 0;
  const onEdit = () => {
    if (inUse) {
      props.onEditUsedBundle();
    }
  };
  const editWarning = (
    <Alert severity="warning">
      This bundle has been used in {props.careEpisodeCount} care episode(s). If you edit the bundle, be sure
      to replan enrollments in this institute using the button at the top of the page.
    </Alert>
  );

  let subScaleWarning = null;
  if (!props.measurementBundle.internallyConsistent) {
    const nonAdministrableMnemonics =
      props.measurementBundle.nonadministrableScalesWithoutAdministrableParents
        .map((scale) => {
          return scale.mnemonic;
        })
        .join(", ");
    subScaleWarning = (
      <Alert severity="warning">
        This bundle is using at least one nonadministrable subscale where a parent administrable scale is not
        present. The affected subscales are {nonAdministrableMnemonics}. Please add an appropriate parent, or
        these subscales will never be administered.
      </Alert>
    );
  }

  return (
    <Card>
      <CardHeader
        // Editable bundle name doesn't need a callback because just editing the name doesn't require replanning.
        title={<EditableBundleName measurementBundle={props.measurementBundle} />}
        action={<DeleteBundleButton measurementBundle={props.measurementBundle} onEdit={onEdit} />}
      />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <DisabledScalesWarning
            instituteScales={props.instituteScales}
            bundleScales={props.measurementBundle.measurementPlan.measurementPlanScales.map(
              (mps) => mps.scale
            )}
          />
          {inUse ? editWarning : null}
          {subScaleWarning}
          <Table>
            <MeasurementPlanScaleTableHeader />
            <TableBody>
              {props.measurementBundle.measurementPlan.measurementPlanScales.map(
                (measurementPlanScale, i) => (
                  <EditableMeasurementPlanScaleRow
                    measurementBundle={props.measurementBundle}
                    measurementPlanScale={measurementPlanScale}
                    onEdit={onEdit}
                    formatOverrideLookup={props.formatOverrideLookup}
                    key={i}
                  />
                )
              )}
            </TableBody>
          </Table>
          <AddScaleForm measurementBundle={props.measurementBundle} onEdit={onEdit} />
        </Stack>
      </CardContent>
    </Card>
  );
}

function MeasurementPlanScaleTableHeader(): ReactElement {
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography fontWeight="bold" color={theme.palette.primary.contrastText}>
            Scale ID
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography fontWeight="bold" color={theme.palette.primary.contrastText}>
            Measure
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography fontWeight="bold" color={theme.palette.primary.contrastText}>
            Mnemonic
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography fontWeight="bold" color={theme.palette.primary.contrastText}>
            Respondents
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography fontWeight="bold" color={theme.palette.primary.contrastText}>
            Frequency
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}>
          <Typography component="div" fontWeight="bold" color={theme.palette.primary.contrastText}>
            Targeting
          </Typography>
          <Typography component="div" color={theme.palette.primary.contrastText}>
            (for status badge)
          </Typography>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.primary.dark }}></TableCell>
      </TableRow>
    </TableHead>
  );
}

type EditableMeasurementPlanScaleRowProps = {
  measurementBundle: PickTypename<TreatmentTarget, "id">;
  measurementPlanScale: MeasurementPlanScaleSummary;
  formatOverrideLookup: Record<string, InvitationFormat | null>;
  onEdit: () => void;
};

function EditableMeasurementPlanScaleRow(props: EditableMeasurementPlanScaleRowProps): ReactElement {
  const theme = useTheme();

  const [showForm, setShowForm] = React.useState(false);

  const [deleteScale, _] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTreatmentTargetScale,
    useDeleteTreatmentTargetScaleMutation({
      variables: {
        input: {
          treatmentTargetId: props.measurementBundle.id,
          scaleId: props.measurementPlanScale.scale.id,
        },
      },
      refetchQueries: refetchQueries("measurementBundles"),
    })
  );

  const participants =
    props.measurementPlanScale.scale.__typename === "AdministrableScale"
      ? props.measurementPlanScale.scale.defaultParticipants || []
      : [];

  const formatOverride = props.formatOverrideLookup[props.measurementPlanScale.scale.id.toString()];
  const formatOverrideBadge = formatOverride ? <Chip label={`Institute Format: ${formatOverride}`} /> : null;

  const handleDelete = () => {
    props.onEdit();
    deleteScale();
  };

  const handleEditSuccess = () => {
    props.onEdit();
    setShowForm(false);
  };

  if (showForm) {
    return (
      <TableRow>
        <TableCell colSpan={8}>
          <AddOrEditScaleForm
            measurementBundle={props.measurementBundle}
            scale={props.measurementPlanScale.scale}
            interval={getJankyIntervalString(props.measurementPlanScale)}
            targeting={props.measurementPlanScale.targeting || ScaleTargeting.NOT_TARGETED}
            skipIntake={props.measurementPlanScale.onsetTime !== null}
            onSuccess={handleEditSuccess}
            onCancel={() => setShowForm(false)}
          />
        </TableCell>
      </TableRow>
    );
  } else {
    return (
      <TableRow>
        {/* Force word breaks in ids and long mnemonics so the table doesn't overlow on laptops */}
        <TableCell sx={{ backgroundColor: theme.palette.background.paper, wordBreak: "break-word" }}>
          {props.measurementPlanScale.scale.id.toString()}
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.background.paper }}>
          <Stack direction="column" spacing={0.5}>
            <span>{props.measurementPlanScale.scale.nanoname}</span>
            {formatOverrideBadge}
          </Stack>
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.background.paper, wordBreak: "break-word" }}>
          {props.measurementPlanScale.scale.mnemonic}
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.background.paper }}>
          {participants.join(", ")}
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.background.paper }}>
          <MeasurementPlanScaleScheduleDescription measurementPlanScale={props.measurementPlanScale} />
        </TableCell>
        <TableCell sx={{ backgroundColor: theme.palette.background.paper }}>
          <Stack direction="column" spacing={0.5}>
            <BundleTargetingIndicator
              targeting={props.measurementPlanScale.targeting}
              mnemonic={props.measurementPlanScale.scale.mnemonic}
            />
            <span>{props.measurementPlanScale.targeting}</span>
          </Stack>
        </TableCell>
        <TableCell>
          <Stack direction="row" spacing={1}>
            <Button startIcon={<Edit />} onClick={() => setShowForm(true)}>
              Edit
            </Button>
            <Button startIcon={<Delete />} onClick={handleDelete}>
              Delete
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
    );
  }
}

type MeasurementPlanScaleScheduleDescriptionProps = {
  measurementPlanScale: MeasurementPlanScaleSummary;
};

function MeasurementPlanScaleScheduleDescription(props: MeasurementPlanScaleScheduleDescriptionProps) {
  const theme = useTheme();
  const { discontinueSession, onsetTime, maxRepeatInterval } = props.measurementPlanScale;

  // This set of cases doesn't cover the whole panoply of what measurement plan scales can do, only the things we
  // consider to be valid bundle setups.
  if (discontinueSession !== null && onsetTime === null) {
    return <Typography>Intake Only</Typography>;
  } else if (maxRepeatInterval !== null) {
    if (onsetTime === null) {
      return <Typography>Intake + {getJankyIntervalString(props.measurementPlanScale)}</Typography>;
    } else if (onsetTime === maxRepeatInterval) {
      // All CoCM measurement plan scales currently in production either have nil onset time or onset time == interval
      return <Typography>{getJankyIntervalString(props.measurementPlanScale)} (No Intake)</Typography>;
    }
  }

  return (
    <Stack direction="column" spacing={0.5} color={theme.palette.error.main}>
      <Typography>Error - Invalid measurement plan scale settings</Typography>
      <Typography>Interval: {getJankyIntervalString(props.measurementPlanScale)}</Typography>
      <Typography>Onset Time: {getJankyIntervalStringFromDuration(onsetTime)}</Typography>
      <Typography>Discontinue Session: {discontinueSession}</Typography>
    </Stack>
  );
}

function getJankyIntervalStringFromDuration(someBullshitDuration: string | null): string | null {
  if (someBullshitDuration === "P14D") {
    return "twice_monthly";
  } else if (someBullshitDuration === "P21D") {
    return "three_weeks";
  } else if (someBullshitDuration === "P1M") {
    return "monthly";
  } else if (someBullshitDuration === "P35D") {
    return "five_weeks";
  } else if (someBullshitDuration === "P42D") {
    return "six_weeks";
  } else if (someBullshitDuration === "P63D") {
    return "nine_weeks";
  } else if (someBullshitDuration === "P3M") {
    return "quarterly";
  } else {
    return null;
  }
}

function getJankyIntervalString(measurementPlanScale: MeasurementPlanScaleSummary): string {
  const nonBullshitDuration = getJankyIntervalStringFromDuration(measurementPlanScale.maxRepeatInterval);

  if (measurementPlanScale.discontinueSession === 2) {
    return "intake_only";
  } else if (nonBullshitDuration) {
    return nonBullshitDuration;
  } else {
    console.log(measurementPlanScale.maxRepeatInterval);
    return "";
  }
}

type BundleTargetingIndicatorProps = {
  targeting: ScaleTargeting | null;
  mnemonic: string;
};

const IndicatorBubble = styled("div", { shouldForwardProp: (_) => false })<{
  active: boolean;
}>(({ theme, active }) => ({
  borderRadius: "50%",
  border: `${theme.spacing(0.125)} solid ${theme.palette.primary.main}`,
  width: theme.spacing(2),
  height: theme.spacing(2),
  position: "relative",
  backgroundColor: theme.palette.primary.light,
  opacity: active ? "100%" : "30%",
}));

const IndicatorGroupSeparator = styled("div")(({ theme }) => ({
  width: theme.spacing(0.5),
  height: theme.spacing(0.125),
  backgroundColor: theme.palette.primary.main,
  opacity: "30%",
}));

function BundleTargetingIndicator(props: BundleTargetingIndicatorProps) {
  // There's no API we can query to get this list of scales, they're harcoded in CareEpisodeClinicalAlert.rb
  const alertMnemonics = [
    "sfssSuicideCombined",
    "sfssSuicide",
    "sfssSuicide_C",
    "taqs",
    "ptpbMedCombined",
    "sfssSubstanceUseCombined",
    "suicideRisk",
    "alliance",
    "medicationThoughts",
    "substanceUse",
    "phq9",
    "phq9Adolescent",
    "m3",
    "suicidescreener_PTPB",
    "suicidescreener_PTPB_Cg",
  ];

  const alert = alertMnemonics.includes(props.mnemonic);
  const target = props.targeting === ScaleTargeting.TARGETED;
  const general = props.targeting === ScaleTargeting.GENERAL_FUNCTIONING;

  return (
    <Stack direction="row" spacing={0} alignItems="center" divider={<IndicatorGroupSeparator />}>
      <IndicatorBubble active={alert} />
      <IndicatorBubble active={target} />
      <IndicatorBubble active={general} />
    </Stack>
  );
}

type EditableBundleNameProps = {
  measurementBundle: PickTypename<TreatmentTarget, "id" | "name">;
};

function EditableBundleName(props: EditableBundleNameProps): ReactElement {
  const [editing, setEditing] = React.useState(false);
  const [editBundle, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditTreatmentTarget,
    useEditTreatmentTargetMutation({})
  );

  const fields = {
    name: useTextField({ default: props.measurementBundle.name, required: true, errorPaths: ["name"] }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      editBundle({
        variables: {
          input: {
            treatmentTargetId: props.measurementBundle.id,
            name: fields.name.value || "",
          },
        },
      });
    },
    onSuccess: () => {
      setEditing(false);
      resetMutation();
    },
  });

  const cancel = () => {
    setEditing(false);
    fields.name.reset();
  };

  useEffectSimpleCompare(() => {
    // If the underlying bundle changes out from under us, take the bundle's value.
    if (props.measurementBundle.name !== fields.name.value) {
      fields.name.reset();
    }
  }, [props.measurementBundle.name]);

  if (!editing) {
    return (
      <Stack direction="row" spacing={3} alignItems="center">
        <span>{props.measurementBundle.name}</span>
        <Button startIcon={<Edit />} onClick={() => setEditing(true)}>
          Edit
        </Button>
        <span>Id: {props.measurementBundle.id.toString()}</span>
      </Stack>
    );
  } else {
    return (
      <Form>
        <FormOverlay
          response={remoteData}
          errorMessage={form.globalError || "Failed to edit measurement bundle"}
        />
        <Stack direction="row" spacing={3} alignItems="center">
          <TextField
            value={fields.name.value}
            onChange={fields.name.onChange}
            error={fields.name.error}
            helperText={fields.name.helperText}
          />
          <Button startIcon={<Check />} onClick={() => form.onFakeSubmit()}>
            Save
          </Button>
          <Button startIcon={<Cancel />} onClick={cancel}>
            Cancel
          </Button>
        </Stack>
      </Form>
    );
  }
}

type AddScaleFormProps = {
  measurementBundle: PickTypename<TreatmentTarget, "id">;
  onEdit: () => void;
};

function AddScaleForm(props: AddScaleFormProps): ReactElement | null {
  const [showForm, setShowForm] = React.useState(false);

  const handleSuccess = () => {
    setShowForm(false);
    props.onEdit();
  };

  if (showForm) {
    return (
      <AddOrEditScaleForm
        measurementBundle={props.measurementBundle}
        onSuccess={handleSuccess}
        onCancel={() => setShowForm(false)}
      />
    );
  } else {
    return (
      <Stack direction="row">
        <Button startIcon={<Add />} onClick={() => setShowForm(true)}>
          Add Measure
        </Button>
      </Stack>
    );
  }
}

type AddOrEditScaleFormProps = {
  measurementBundle: PickTypename<TreatmentTarget, "id">;
  scale?: BundleableScaleSummary;
  interval?: string;
  targeting?: ScaleTargeting;
  skipIntake?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
};

function AddOrEditScaleForm(props: AddOrEditScaleFormProps): ReactElement | null {
  const { t } = useTranslation(["common"]);

  const [addOrEditScale, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateOrEditTreatmentTargetScale,
    useCreateOrEditTreatmentTargetScaleMutation({})
  );

  const fields = {
    // This actually is required and we don't handle it being missing well in the submit code but this makes it easy to
    // support adding and editing in the same form and for internal purposes I can live with this.
    scale: useWrappedField<ScaleOrSubscaleDescription>({ default: undefined, required: false }),
    interval: useWrappedField<string>({ default: props.interval || "intake_only", required: false }),
    targeting: useWrappedField<ScaleTargeting>({ default: props.targeting, required: true }),
    skipIntake: useBooleanField({ default: props.skipIntake || false, required: true }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      addOrEditScale({
        variables: {
          input: {
            treatmentTargetId: props.measurementBundle.id,
            scaleId: props.scale?.id || (fields.scale.value?.id as unknown as ScaleId),
            interval: fields.interval.value === "intake_only" ? undefined : fields.interval.value,
            targeting: fields.targeting.value as unknown as ScaleTargeting,
            skipIntake: fields.skipIntake.value,
          },
        },
        refetchQueries: refetchQueries("measurementBundles"),
      });
    },
    onSuccess: () => {
      if (props.onSuccess) {
        props.onSuccess();
      }

      resetMutation();
    },
  });

  const cancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }

    form.reset();
  };

  const scalePicker = (
    <ScaleOrSubscalePickerSingle
      value={fields.scale.value}
      onChange={fields.scale.onChange}
      error={fields.scale.error}
      helperText={fields.scale.helperText}
      showMnemonics={true}
    />
  );
  const fixedScaleDisplay = <Typography>{props.scale?.shortname}</Typography>;

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay response={remoteData} errorMessage={form.globalError || "Failed to save measure"} />
      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={1}>
          <Box width="30rem">{props.scale ? fixedScaleDisplay : scalePicker}</Box>
          <Box width="15rem">
            <FormControl error={fields.interval.error} fullWidth>
              <InputLabel>Interval</InputLabel>
              <Select
                value={fields.interval.value}
                error={fields.interval.error}
                onChange={(event) => fields.interval.onChange(event.target.value)}
                label="Interval"
                fullWidth
              >
                <MenuItem value="intake_only">Intake Only</MenuItem>
                <MenuItem value="twice_monthly">Twice Monthly</MenuItem>
                <MenuItem value="three_weeks">Three Weeks</MenuItem>
                <MenuItem value="monthly">Monthly</MenuItem>
                <MenuItem value="six_weeks">Six Weeks</MenuItem>
                <MenuItem value="quarterly">Quarterly</MenuItem>
              </Select>
              <FormHelperText>{fields.interval.helperText}</FormHelperText>
            </FormControl>
          </Box>
          <FormControl>
            <FormControlLabel
              control={<Checkbox />}
              label="Skip Intake"
              checked={fields.skipIntake.value}
              onChange={fields.skipIntake.onChange}
            />
          </FormControl>
          <Box width="15rem">
            <EnumSelect
              optionsEnum={ScaleTargeting}
              title={t("common:scales.targeting")}
              enumTrans={scaleTargetingT}
              value={fields.targeting.value || null}
              onChange={fields.targeting.onChange}
              omitOptions={[ScaleTargeting.HEADLINE, ScaleTargeting.SUBSCALE_OF_TARGET]}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button startIcon={<Check />} type="submit">
            Save
          </Button>
          <Button startIcon={<Cancel />} onClick={cancel}>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}

function CreateBundleForm(props: { instituteId: InstituteId }): ReactElement {
  const theme = useTheme();

  const [showForm, setShowForm] = React.useState(false);

  const [createBundle, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTreatmentTarget,
    useCreateTreatmentTargetMutation({
      refetchQueries: refetchQueries("treatmentTargets"),
    })
  );

  const fields = {
    name: useTextField({ default: "", required: false, errorPaths: ["name"] }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      createBundle({
        variables: {
          input: {
            instituteId: props.instituteId,
            name: fields.name.value || "",
          },
        },
      });
    },
    onSuccess: () => {
      setShowForm(false);
      resetMutation();
    },
  });

  const cancel = () => {
    setShowForm(false);
    form.reset();
  };

  if (showForm) {
    return (
      <Form onSubmit={form.onSubmit}>
        <FormOverlay response={remoteData} errorMessage={form.globalError || "Failed to save bundle"} />
        <Stack direction="column" spacing={1}>
          <Box width="30rem">
            <TextField
              value={fields.name.value}
              onChange={fields.name.onChange}
              error={fields.name.error}
              helperText={fields.name.helperText}
              label="Name"
              fullWidth
              sx={{ backgroundColor: theme.palette.background.paper }}
            />
          </Box>
          <Stack direction="row" spacing={1}>
            <Button startIcon={<Check />} type="submit">
              Save
            </Button>
            <Button startIcon={<Cancel />} onClick={cancel}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Form>
    );
  } else {
    return (
      <Stack direction="row">
        <Button startIcon={<Add />} onClick={() => setShowForm(true)}>
          Create Bundle
        </Button>
      </Stack>
    );
  }
}

type DeleteBundleButtonProps = {
  measurementBundle: PickTypename<TreatmentTarget, "id">;
  onEdit: () => void;
};

function DeleteBundleButton(props: DeleteBundleButtonProps) {
  const [deleteBundle, _] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteTreatmentTarget,
    useDeleteTreatmentTargetMutation({
      variables: {
        input: {
          treatmentTargetId: props.measurementBundle.id,
        },
      },
      refetchQueries: refetchQueries("treatmentTargets"),
    })
  );

  const handleClick = () => {
    props.onEdit();
    deleteBundle();
  };

  return (
    <Button startIcon={<Delete />} onClick={handleClick}>
      Delete Bundle
    </Button>
  );
}

// date-fns apparently acccepted a pr to parse iso durations but didn't include it in a release???? whatever i copied
// it out of the pr, thanks pr person for your code, https://github.com/date-fns/date-fns/pull/3151/files
const ISO_DURATION_REGEX =
  /P(?:([\d]+\.?[\d]*|\.[\d]+)Y)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)W)?(?:([\d]+\.?[\d]*|\.[\d]+)D)?(?:T(?:([\d]+\.?[\d]*|\.[\d]+)H)?(?:([\d]+\.?[\d]*|\.[\d]+)M)?(?:([\d]+\.?[\d]*|\.[\d]+)S)?)?$/;
export function parseISODuration(argument: string): Duration {
  const parsedArgument = argument.replace(/,/g, "."); // Decimal fraction may be specified with either a comma or a full stop

  const match = parsedArgument.match(ISO_DURATION_REGEX);

  if (!match) {
    throw new RangeError("Invalid format");
  }

  const [, years = 0, months = 0, weeks = 0, days = 0, hours = 0, minutes = 0, seconds = 0] = match;

  const entries = Object.entries({
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }) as Array<[keyof Duration, string]>;

  return entries.reduce<Duration>((prev, [key, value]) => {
    prev[key] = +value;
    return prev;
  }, {});
}

type ReplanAllEnrollmentsButtonProps = {
  instituteId: InstituteId;
  onReplanSuccess: () => void;
};

function ReplanAllEnrollmentsButton(props: ReplanAllEnrollmentsButtonProps): ReactElement {
  const [replan, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareReplanAllActiveEnrollments,
    useReplanAllActiveEnrollmentsMutation({ variables: { input: { instituteId: props.instituteId } } })
  );

  const monitorDisplay = remoteData.caseOf({
    NotAsked: () => <></>,
    Loading: () => <Spinner />,
    Failure: () => (
      <ErrorMessage message="Failed to enqueue replanning job, contact support to ensure enrollments are correctly replanned." />
    ),
    Success: (response) => {
      if (response?.monitoredJob) {
        return (
          <MonitoredJobResponsePoll
            monitoredJobId={response.monitoredJob.id}
            onMonitorSuccess={props.onReplanSuccess}
          />
        );
      } else {
        return (
          <ErrorMessage message="Failed to enqueue replanning job, contact support to ensure enrollments are correctly replanned." />
        );
      }
    },
  });

  return (
    <Paper sx={{ padding: "1rem", maxWidth: "35rem" }}>
      <Stack direction="column" spacing={1}>
        {monitorDisplay}
        <Stack direction="row">
          <Button variant="contained" color="secondary" onClick={() => replan()}>
            Replan All Enrollments In Institute
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
