import { Panel, PanelTeamMember, Provider } from "GeneratedGraphQL/SchemaAndOperations";
import { ProviderId } from "Lib/Ids";
import { PickTypename } from "type-utils";

type PanelWithIdentifiableTeamMembers = PickTypename<Panel, "id"> & {
  teamMembers: ReadonlyArray<
    PickTypename<PanelTeamMember, "id"> & { provider: PickTypename<Provider, "id"> }
  >;
};

export function panelContainsProvider(
  panel: PanelWithIdentifiableTeamMembers,
  providerId: ProviderId | null
): boolean {
  if (providerId === null) {
    return false;
  }

  const providerIds = panel.teamMembers.map((teamMember) => teamMember.provider.id);
  return providerIds.includes(providerId);
}
