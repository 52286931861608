import React from "react";

import { useTranslation } from "react-i18next";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { DetailsWrapper } from "../DetailsWrapper";
import { CreateGoalForm } from "./CreateGoalForm";

type GoalCreateProps = {
  feedbackReportContext: FeedbackReportContext;
};

export const GoalCreateRoute = (props: GoalCreateProps) => {
  const { t } = useTranslation(["report"]);

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [t("report:breadcrumbs.addGoal")],
  ];

  return (
    <DetailsWrapper breadcrumbs={breadcrumbs}>
      <CreateGoalForm
        feedbackReportContext={props.feedbackReportContext}
        onSuccessRoute={Routing.overviewRoute(props.feedbackReportContext)}
      />
    </DetailsWrapper>
  );
};
