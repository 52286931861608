import React from "react";
import { Box, Typography, Card, CardContent, Stack, CardActions, styled } from "@mui/material";
import { PatientReferenceCardHeader } from "CollaborativeCare/PatientReference";
import { CareEpisodeProviderRelationship, PanelEnrollmentQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import { QuickTrackButton } from "CollaborativeCare/QuickTrackButton";
import { DigUnpacked } from "type-utils";
import { enrollmentStatusT } from "GeneratedGraphQL/EnumTranslations";

// We want these cards to take up (almost) all of the width of the viewport so that they're all the same size rather
// than getting wider and skinnier based on things like how long the patient's name is.
const MostOfThePageCard = styled(Card)(() => ({
  width: "90%",
}));

export function MobilePanelListCard(props: {
  row: DigUnpacked<PanelEnrollmentQuery, ["collaborativeCareEnrollments", "nodes"]>;
}) {
  const { row } = props;
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  const pcpProvider = row.careEpisode.careEpisodeProviders.find((provider) => {
    return provider.relationship == CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN;
  });
  const pcp = pcpProvider?.provider.orderedName ?? "";

  const cmProvider = row.careEpisode.careEpisodeProviders.find((provider) => {
    return provider.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
  });
  const cm = cmProvider?.provider.orderedName ?? "";

  const enrollment = enrollmentStatusT(row.status, t);
  return (
    <MostOfThePageCard>
      <PatientReferenceCardHeader patientId={row.patient.id} />
      <CardContent>
        <Stack direction="row" spacing={1}>
          {/* This is the left side grouping. */}
          <Stack direction="column" spacing={1}>
            <Typography variant="h3" color="text.secondary">
              {enrollment}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.mrn")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {row.patient.mrn}
              </Typography>
            </Stack>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          {/* This is the right side grouping. */}
          <Stack direction="column" spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.pcp")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {pcp}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h3" color="text.secondary">
                {t("collaborativeCare:patientList.fields.cm")}:
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {cm}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
      <CardActions>
        <QuickTrackButton patientId={row.patient.id} />
      </CardActions>
    </MostOfThePageCard>
  );
}
