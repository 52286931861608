import { Edit, List } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  InvitationLoginMethod,
  InvitationStatusEvent,
  UpdateInvitationInput,
  useUpdateAssessmentInvitationMutationMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { InvitationId, PatientId, PatientSessionId, UserId } from "Lib/Ids";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import { ContextualReportLink } from "Shared/ContextualReportLink";

type InvitationActionsProps = {
  invitationId: InvitationId;
  patientId: PatientId;
  sessionId: PatientSessionId;
  isReportAvailable: boolean;
  isReportComplete: boolean;
  referrer?: InvitationLoginMethod | null;
  userId: UserId;
};

function InvitationActions(props: InvitationActionsProps): ReactElement {
  const { invitationId, patientId, sessionId, isReportAvailable, isReportComplete, referrer, userId } = props;
  // app/pods/components/provider/assessment/dashboard/session-list-item/template.hbs
  // <button {{action 'takeProviderAssessmentAndReturn' invitation '/provider/assessment/dashboard?activeTab=myTasks'}}
  // class="btn btn-primary btn-raised take-assessment"><span class="fa fa-pencil-square-o"></span> Fill Out</button>
  /*  // app/mixins/provider-assessment-redirect.js
    _calculateReferrer(invitation, referrer) {
      if (invitation.get("referrer")) {
        return invitation.get("referrer");
      } else {
        return referrer;
      }
    },
     // take an assessment not meant for the current provider.  Will not validate/confirm with the user beforehand
    takeProviderAssessmentAndReturn(invitation, returnUrl) {
      invitation.setProperties({
        takingUser: this.get('invitation.user'),
        statusEvent: 'assessment_begun',
        referrer: this._calculateReferrer(invitation, 'provider_session_bulk')
      });
      invitation.save();
      return this.get("router").transitionTo('provider.assessment.invitation.edit', invitation.id, { queryParams: { returnUrl }});
    },
 */
  // /provider/assessment/invitation/fe1e85ae-05f4-4d12-88c2-1c5f7c9d9b2b/edit?returnUrl=%2Fprovider%2Fassessment%2Fdashboard%3FactiveTab%3DmyTasks
  const returnUrl = "/app/providers/dashboard/my-tasks";
  const takeAssessmentUrl = `/provider/assessment/invitation/${invitationId}/edit?returnUrl=${encodeURIComponent(
    returnUrl
  )}`;
  const sessionLink = `/provider/patients/${patientId}/sessions/${sessionId}`;
  // TODO: get translations for this
  const viewReportText = isReportComplete ? "View Report" : "View Partial Report";
  const navigate = useNavigate();
  const calculatedReferrer = referrer || InvitationLoginMethod.PROVIDER_SESSION_BULK;

  const input: UpdateInvitationInput = {
    // clientMutationId?:,
    invitationId: invitationId,
    referrer: calculatedReferrer,
    statusEvent: InvitationStatusEvent.ASSESSMENT_BEGUN,
    takingUserId: userId,
  };

  const [updateAssessmentInvitation] = apolloMutationHookWrapper(
    (data) => data.assessmentUpdateInvitation,
    useUpdateAssessmentInvitationMutationMutation({
      variables: {
        invitationInput: input,
      },
    })
  );
  const handleFillOutClick = () => {
    updateAssessmentInvitation();
    navigate(takeAssessmentUrl);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Button variant="contained" onClick={handleFillOutClick}>
        <Edit /> Fill Out
      </Button>
      {isReportAvailable ? (
        <ContextualReportLink patient={patientId}>
          <Button variant="contained">{viewReportText}</Button>
        </ContextualReportLink>
      ) : (
        <></>
      )}
      <Link to={sessionLink}>
        <Button variant="contained">
          <List /> Details
        </Button>
      </Link>
    </Stack>
  );
}
export default InvitationActions;
