import React, { ReactElement } from "react";
import ParticipantAvatar from "FeedbackReport/ParticipantAvatar";
import * as NEL from "Lib/NonEmptyList";
import { truncate } from "Lib/Utils";
import EmptyState from "Shared/EmptyState";
import ErrorMessage from "Shared/ErrorMessage";
import * as Goal from "Shared/Scale/Goal";
import { Section } from "Shared/Section";
import { RemoteData } from "seidr";
import { Link } from "MDS/Link";
import { Button, Box, Tooltip, Skeleton } from "@mui/material";
import * as FeedbackReportContext from "FeedbackReport/FeedbackReportContext";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { styled } from "@mui/material/styles";
import { TableRowFocusHighlight } from "Shared/Focusable";
import { useTranslation } from "react-i18next";
import { BlankIcon } from "MDS/Icons";
import SeverityHeatmapWithChip from "Shared/Severity/SeverityHeatmapWithChip";

const StyledGoalTextCell = styled("td")(({ theme }) => ({
  width: 200,
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  paddingRight: theme.spacing(1.5),
}));

function GoalItemRow(props: {
  goal: Goal.Goal;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
}): ReactElement {
  const { goal, feedbackReportContext } = props;

  let participantCell;

  if (feedbackReportContext.hasMultipleClientParticipants) {
    const participantAvatar = FeedbackReportContext.getParticipantByUserId(
      feedbackReportContext,
      goal.user.id
    ).map((p) => <ParticipantAvatar participant={p} />);

    participantCell = <td style={{ paddingLeft: "0.5rem" }}>{participantAvatar.getOrElse(null)}</td>;
  }

  const goalDetailRoute = Routing.goalRoute(feedbackReportContext, goal.id);

  return (
    <TableRowFocusHighlight route={goalDetailRoute}>
      {participantCell}
      <StyledGoalTextCell>
        <Tooltip title={goal.patientText}>
          <span>
            <Link to={goalDetailRoute.toString()}>{truncate(20, goal.patientText)}</Link>
          </span>
        </Tooltip>
      </StyledGoalTextCell>
      <td>
        <BlankIcon />
      </td>
      <td>
        <Link to={goalDetailRoute.toString()}>
          <SeverityHeatmapWithChip
            height={24}
            maxWidth={200}
            values={goal.answers}
            dates={feedbackReportContext.administrationDates}
            chipWidth={150}
          />
        </Link>
      </td>
    </TableRowFocusHighlight>
  );
}

const StyledGoalTable = styled("table")(() => ({
  borderSpacing: 0,
  tableLayout: "fixed",
  "& td": {
    padding: "6px 8px 6px 0",
  },
}));

function GoalsTable(props: {
  goals: NEL.NonEmptyList<Goal.Goal>;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
}): ReactElement {
  const { goals, feedbackReportContext } = props;

  return (
    <StyledGoalTable>
      <tbody>
        {NEL.toArray(
          goals.mapWithIndex((goal, i) => (
            <GoalItemRow key={i} goal={goal} feedbackReportContext={feedbackReportContext} />
          ))
        )}
      </tbody>
    </StyledGoalTable>
  );
}

function GoalsSummaryLoadingIndicator(): ReactElement {
  return <Skeleton width={400} height={20} />;
}

type GoalSummaryContentProps = {
  goals: ReadonlyArray<Goal.Goal>;
  goalCreateRoute: string;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
};

function GoalsSummaryContent(props: GoalSummaryContentProps): ReactElement {
  const { goals, goalCreateRoute, feedbackReportContext } = props;
  const { t } = useTranslation(["report"]);

  return NEL.fromArray(goals).caseOf({
    Just: (goals) => <GoalsTable goals={goals} feedbackReportContext={feedbackReportContext} />,
    Nothing: () => (
      <>
        <EmptyState text={t("report:goalsProblems.noGoals")} />
        <Box sx={{ mt: 0.857 }}>
          <Link to={goalCreateRoute}>
            <Button variant="contained" color="primary">
              {t("report:goalsProblems.addGoal")}
            </Button>
          </Link>
        </Box>
      </>
    ),
  });
}

type Props = {
  goals: RemoteData<Error, ReadonlyArray<Goal.Goal>>;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
};

function GoalsSlashProblems(props: Props): ReactElement {
  const { goals, feedbackReportContext } = props;
  const { t } = useTranslation(["report"]);

  // Only show the subtitle if there are goals (and thus not an empty state)
  const renderSubTitle = goals
    .map((g) =>
      g.length
        ? () => (
            <Link to={Routing.goalCreateRoute(feedbackReportContext)}>
              <Button variant="contained" color="secondary" size="small">
                {t("report:goalsProblems.addGoalShort")}
              </Button>
            </Link>
          )
        : undefined
    )
    .caseOf({ _: () => undefined, Success: (x) => x });

  return (
    <Section title={t("report:sections.goalsProblems")} renderSubTitle={renderSubTitle}>
      {goals.caseOf({
        Success: (goals) => {
          return (
            <GoalsSummaryContent
              goals={goals}
              goalCreateRoute={Routing.goalCreateRoute(feedbackReportContext)}
              feedbackReportContext={feedbackReportContext}
            />
          );
        },
        Loading: () => <GoalsSummaryLoadingIndicator />,
        NotAsked: () => <GoalsSummaryLoadingIndicator />,
        Failure: (err) => <ErrorMessage message={err.toString()} />,
      })}
    </Section>
  );
}

export default GoalsSlashProblems;
