import { Add, Remove } from "@mui/icons-material";
import {
  Button,
  ButtonProps,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  CareEpisodeProviderInput,
  CareEpisodeProviderRelationship,
} from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId, ProviderId } from "Lib/Ids";
import { humanize } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { enumToOptions } from "Shared/Enum";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import ProviderName from "Shared/Names/ProviderName";

type ProviderSelectorMode = "all" | "organization";
type ProviderSelectorButtonProps = ButtonProps & { mode: ProviderSelectorMode };

function ProviderSelectorButton(props: ProviderSelectorButtonProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const text =
    props.mode === "all"
      ? t("careEpisodes:actions.showOrgProviders")
      : t("careEpisodes:actions.showAllProviders");
  return <Button {...props}>{text}</Button>;
}

type ProviderSectionProps = {
  selectedOrg: OrganizationId | null;
  careTeam: ReadonlyArray<CareEpisodeProviderInput>;
  setCareTeam: (careEpisodeProviders: Array<CareEpisodeProviderInput>) => void;
};

export function CareEpisodeProviderSection(props: ProviderSectionProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  const { careTeam, setCareTeam, selectedOrg } = props;

  const [providerListMode, setProviderListMode] = React.useState<ProviderSelectorMode>("all");
  const [selectedProvider, setSelectedProvider] = React.useState<ProviderId | null>(null);
  const [selectedRelationship, setSelectedRelationship] = React.useState<
    CareEpisodeProviderRelationship | ""
  >("");

  function swapProviderListMode() {
    setProviderListMode(providerListMode === "all" ? "organization" : "all");
    setSelectedProvider(null);
  }

  function handleSelectedRelationshipChange(event: SelectChangeEvent<HTMLSelectElement>) {
    setSelectedRelationship(event.target.value as unknown as CareEpisodeProviderRelationship);
  }

  function removeFromCareTeam(id: ProviderId) {
    setCareTeam(careTeam.filter((member) => member.providerId !== id));
  }

  function addToCareTeam() {
    if (selectedProvider && selectedRelationship) {
      setCareTeam(careTeam.concat({ providerId: selectedProvider, relationship: selectedRelationship }));
      setSelectedProvider(null);
      setSelectedRelationship("");
    }
  }

  const activeOrg = providerListMode === "organization" ? selectedOrg : null;

  return (
    <Card>
      <CardHeader title={t("careEpisodes:sections.careTeam")} />
      <CardContent>
        <Table>
          <TableBody>
            {careTeam.map((member, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <ProviderName providerId={member.providerId} />
                  </TableCell>
                  <TableCell>{humanize(member.relationship)}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => removeFromCareTeam(member.providerId)}>
                      <Remove />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              {/* Using explicit column widths here so that the cells don't jump around as we selected differently-
                    sized names in the dropdowns. Keeping the inputs in the table so that they have a clear relationship
                    with the data is nice enough that I'm okay jumping through a little hoop for it. */}
              <TableCell width="40%">
                <Stack spacing={1} alignItems="flex-start">
                  <FormControl sx={{ width: "100%" }}>
                    <ProviderSelectSingle
                      setValue={setSelectedProvider}
                      value={selectedProvider}
                      organizationId={activeOrg}
                    />
                  </FormControl>
                  <ProviderSelectorButton
                    variant="outlined"
                    size="small"
                    onClick={swapProviderListMode}
                    mode={providerListMode}
                  />
                </Stack>
              </TableCell>
              <TableCell width="40%" sx={{ verticalAlign: "top" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="relationship-selector-label">
                    {t("careEpisodes:fields.relationship")}
                  </InputLabel>
                  <Select
                    labelId="relationship-selector-label"
                    id="relationship-selector"
                    value={selectedRelationship as unknown}
                    label={t("careEpisodes:fields.relationship")}
                    onChange={handleSelectedRelationshipChange}
                  >
                    {enumToOptions(CareEpisodeProviderRelationship).map((r) => (
                      <MenuItem value={r} key={r}>
                        {humanize(r)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell sx={{ verticalAlign: "top" }}>
                <IconButton onClick={addToCareTeam}>
                  <Add />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

export default CareEpisodeProviderSection;
