import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCurrentDefaultNode } from "Contexts/CurrentInstituteIdContext";
import {
  CocmBillingAlgorithmRuleCheckValue,
  EntityTreeNodeParams,
  ImplementationTargetType,
  MonthParams,
  useCocmBillingDashboardRulesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { allowedEntityTypes } from "Implementation";
import { ImplementationTargetWidget } from "Implementation/ImplementationTargetWidget";
import Page from "Layout/Page";
import DatePicker from "Shared/DatePickers";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyEntityTreeNodeParameter,
  useStickyEnumParameter,
  useStickyMonthParameterV2,
} from "Shared/StickyParameter";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BillingCumulativeEffort } from "./BillingCumulativeEffort";
import { dateToMonth, endOfMonth, monthToDate, monthToday } from "Shared/Month";
import Link, { LinkButton } from "MDS/Link";
import EnumSelect from "Shared/EnumSelect";
import { cocmBillingAlgorithmRuleCheckValueT } from "GeneratedGraphQL/EnumTranslations";
import { ALL_VALUE_TYPES, BillingPredictionTable } from "./BillingPrediction";
import { BillingPredictionFilters } from "./BillingPredictionFilters";
import { useLocation } from "react-router-dom";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import BillingWinnerDetailsModal from "./BillingWinnerDetailsModal";
import { BillingSummaryStats } from "./BillingSummaryStats";
import { BillingDashboardFilters, useBillingDashboardFilters } from "./BillingDashboardFilters";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import { BillingRuleNameDetails } from "./BillingRuleHelpers";
import { sortBy } from "ramda";
import EntityTreeNodeSelect from "Shared/Filters/EntityTreeNodeSelect";
import { useProviderUserHasAnyRole } from "Shared/WithPermission";
import { useConfiguration } from "Contexts/CurrentInstituteContext";
import { FilterButton } from "Lib/FilterPanel";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";

function valueTypeToImplementationTargetType(
  valueType: CocmBillingAlgorithmRuleCheckValue
): ImplementationTargetType {
  switch (valueType) {
    case CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING;
    case CocmBillingAlgorithmRuleCheckValue.RVUS:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS;
    case CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS;
    case CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES:
      return ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES;
  }
}

// This is the preferred default values, you should choose the first one that is available.
export const ORDERED_VALUE_TYPES: ReadonlyArray<CocmBillingAlgorithmRuleCheckValue> = [
  CocmBillingAlgorithmRuleCheckValue.ESTIMATED_RATE,
  CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS,
  CocmBillingAlgorithmRuleCheckValue.RVUS,
  CocmBillingAlgorithmRuleCheckValue.BILLABLE_MINUTES,
];

function BillingDashboardInner(props: {
  month: MonthParams;
  entityTreeNodeParams: EntityTreeNodeParams;
  valueType: CocmBillingAlgorithmRuleCheckValue;
  allowedValueTypes: ReadonlyArray<CocmBillingAlgorithmRuleCheckValue>;
  filters: BillingDashboardFilters;
}) {
  const { month, entityTreeNodeParams, valueType, filters } = props;
  const [activeWinnerId, setActiveWinnerId] =
    useQueryStringIdParameter<"EnrollmentMonthBillingRuleResultWinner">("winnerId", true);

  const { t } = useTranslation(["billing"]);
  const { search } = useLocation();

  const predictionFilters: BillingPredictionFilters = {
    month,
    // You can't set the month
    setMonth: (_value) => {},
    // We don't use search here but it can be plugged into the table.
    search: null,
    setSearch: (_value: string) => {},
    valueType,
    // Can't set value type from here
    setValueType: (_value) => {},
    entityTreeNodeParams,
    setEntityTreeNodeParams: (_value) => {},
    excludeDischarged: filters.excludeDischarged,
    setExcludeDischarged: filters.setExcludeDischarged,
    reset: () => {},
  };

  const details = activeWinnerId ? (
    <BillingWinnerDetailsModal winnerId={activeWinnerId} onClose={() => setActiveWinnerId(null)} />
  ) : null;

  return (
    <>
      <Grid item xs={1}>
        <ImplementationTargetWidget
          implementationTargetType={valueTypeToImplementationTargetType(valueType)}
          entityTreeNode={entityTreeNodeParams}
          date={endOfMonth(month)}
        />
      </Grid>
      <Grid item xs={1}>
        <BillingCumulativeEffort
          month={month}
          entityTreeNode={entityTreeNodeParams}
          valueType={valueType}
          setActiveWinnerId={setActiveWinnerId}
          monthOfEnrollment={filters.monthsOfEnrollment}
          ruleIds={filters.selectedRuleIds}
          excludeDischarged={filters.excludeDischarged}
        />
      </Grid>

      <Grid item xs={2}>
        <BillingPredictionTable
          allowedValueTypes={props.allowedValueTypes}
          viewContext="dashboard"
          filters={predictionFilters}
          onRowClick={setActiveWinnerId}
          monthsofTreatment={filters.monthsOfEnrollment}
          winningRuleIds={filters.selectedRuleIds}
        />
        <LinkButton to={`prediction?${search}`} variant="contained" color="secondary" fullWidth>
          Show Full Predictions
        </LinkButton>
      </Grid>
      <Grid item xs={2}>
        <BillingSummaryStats
          rules={filters.selectedRuleDetails}
          entityTreeNode={entityTreeNodeParams}
          month={month}
          valueType={valueType}
          monthOfEnrollment={filters.monthsOfEnrollment}
        />
      </Grid>
      <Grid item xs={2}>
        <Link to="configuration">{t("billing:dashboard.viewBillingAlgorithm")}</Link>
      </Grid>
      {details}
    </>
  );
}

function BillingDashboardForEntity(props: {
  entityTreeNodeParams: EntityTreeNodeParams;
  setEntityTreeNodeParams: (value: EntityTreeNodeParams) => void;
  defaultEntityTreeNodeParams: EntityTreeNodeParams;
  ruleData: RemoteData<ApolloError, ReadonlyArray<BillingRuleNameDetails> | null>;
}) {
  const { t } = useTranslation(["billing"]);

  const isSuperuser = useProviderUserHasAnyRole(["Collaborative Care Superuser", "superuser"]);

  const cmAllowedValueTypes = useConfiguration("cocmCareManagerVisibleBillingValueTypes");

  const allowedValueTypes = isSuperuser ? ALL_VALUE_TYPES : cmAllowedValueTypes;

  const defaultValueType =
    ORDERED_VALUE_TYPES.find((item) => cmAllowedValueTypes.includes(item)) ??
    CocmBillingAlgorithmRuleCheckValue.VALUE_UNITS;

  const omittedValueTypes = React.useMemo(() => {
    return ALL_VALUE_TYPES.filter((x) => !allowedValueTypes.includes(x));
  }, [isSuperuser]);

  const [month, setMonth] = useStickyMonthParameterV2(
    "month",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    monthToday(),
    true
  );

  const [valueType, setValueType] = useStickyEnumParameter(
    "valueType",
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    CocmBillingAlgorithmRuleCheckValue,
    defaultValueType,
    true
  );

  // This is a bit awkward because we need the filters to stay rendered as we change the entity, but there's a requirement that we
  // need to have the entity tree node selected to set up all other filters, which is rendered inline. So we have a strange way
  // of passing all the data in, but sometimes we don't have valid data so just set things up repeatedly here no matter what we get.

  const filters = useBillingDashboardFilters(
    props.ruleData.caseOf({
      Success: (rules) => rules ?? [],
      _: () => [],
    })
  );

  const [advancedOpen, setAdvancedOpen] = useState(false);

  useEffect(() => {
    // If you get into a state where you can't see your current value type,
    // reset to the default.
    if (!allowedValueTypes.includes(valueType)) {
      setValueType(defaultValueType);
    }
  }, [valueType]);

  const [inner, advancedFilters] = props.ruleData.caseOf({
    Success: (result) => {
      if (result) {
        // TODO: limitation for now: we only take the rules from the first active algorithm. If you have multiple active
        // Algorithms tthis will need to be extended to support that.
        return [
          <BillingDashboardInner
            allowedValueTypes={allowedValueTypes}
            entityTreeNodeParams={props.entityTreeNodeParams}
            month={month}
            valueType={valueType}
            filters={filters}
          />,
          <BillingDashboardFilters filters={filters} allRuleDetails={result} />,
        ];
      } else {
        return [
          <Card>
            <CardContent>{t("billing:dashboard.noAlgo")}</CardContent>
          </Card>,
          <Typography>{t("billing:dashboard.noAlgo")}</Typography>,
        ];
      }
    },
    Failure: (error) => [<ErrorMessage message={error.message} />, <ErrorMessage message={error.message} />],
    NotAsked: () => [<Spinner />, <Spinner />],
    Loading: () => [<Spinner />, <Spinner />],
  });

  const onExcludeDischarged = (event: ChangeEvent<HTMLInputElement>) =>
    filters.setExcludeDischarged(event.target.checked);

  return (
    <Page browserTitle={t("billing:dashboard.title")}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1} alignItems="center" rowGap={1} flexWrap={"wrap"} useFlexGap>
            <Box>
              <DatePicker
                views={["year", "month"]}
                label={t("billing:prediction.fields.yearAndMonth")}
                minDate={new Date("2019-01-01")} // This should be the oldest enrollment for the institute.
                maxDate={new Date()}
                openTo={"month"}
                value={monthToDate(month)}
                onChange={(newValue) => {
                  if (newValue) {
                    setMonth(dateToMonth(newValue));
                  }
                }}
              />
            </Box>

            <Box minWidth="25em">
              <EntityTreeNodeSelect
                setValue={props.setEntityTreeNodeParams}
                entityTypes={allowedEntityTypes}
                value={props.entityTreeNodeParams}
                defaultValue={props.defaultEntityTreeNodeParams}
                relatedOnly={!isSuperuser}
              />
            </Box>
            <Box minWidth="20em">
              <EnumSelect
                value={valueType}
                onChange={setValueType}
                optionsEnum={CocmBillingAlgorithmRuleCheckValue}
                omitOptions={omittedValueTypes}
                enumTrans={cocmBillingAlgorithmRuleCheckValueT}
                title={t("billing:prediction.showValueBy")}
                defaultValue={defaultValueType}
              />
            </Box>
            <Box>
              <Tooltip title={t("billing:prediction.excludeDischargedExplanation")}>
                <FormControl>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={t("billing:prediction.excludeDischarged")}
                    checked={filters.excludeDischarged}
                    onChange={onExcludeDischarged}
                  />
                </FormControl>
              </Tooltip>
            </Box>
            <FilterButton
              activeFilterCount={filters.changedFilterCount}
              open={advancedOpen}
              toggleOpen={setAdvancedOpen}
            />
            <ResetAndStickyFilterButtonGroup
              onReset={() => {
                setMonth(monthToday());
                props.setEntityTreeNodeParams(props.defaultEntityTreeNodeParams);
                setValueType(defaultValueType);
                filters.reset();
              }}
            />
          </Stack>
        </Grid>
        <Grid item lg={2} xs={2}>
          <Collapse in={advancedOpen}>
            <Stack direction="row" spacing={1}>
              {advancedFilters}
            </Stack>
          </Collapse>
        </Grid>
        {inner}
      </Grid>
    </Page>
  );
}

// We have to wrap this in a slightly awkward way because the set of allowed rules depends on the entity and needs to go all the way through without rendering problems.
export default function BillingDashboard() {
  const [currentDefaultNode] = useCurrentDefaultNode();
  const [entityTreeNodeParams, setEntityTreeNodeParams] = useStickyEntityTreeNodeParameter(
    STICKY_PARAMETER_NAMES.ENTITY_TREE_NODE,
    STICKY_PARAMETER_FILTER_SETS.BILLING,
    currentDefaultNode,
    true
  );

  const { remoteData: availableRulesData } = apolloQueryHookWrapper(
    useCocmBillingDashboardRulesQuery({
      variables: {
        entityTreeNode: entityTreeNodeParams,
      },
    })
  );

  const ruleData = availableRulesData.map((result) => {
    const firstAlgo = result.billingCocmBillingAlgorithms?.nodes[0];
    if (firstAlgo) {
      return sortBy((item) => -item.priority, firstAlgo.rules);
    } else {
      return null;
    }
  });

  return (
    <BillingDashboardForEntity
      defaultEntityTreeNodeParams={currentDefaultNode}
      entityTreeNodeParams={entityTreeNodeParams}
      setEntityTreeNodeParams={setEntityTreeNodeParams}
      ruleData={ruleData}
    />
  );
}
