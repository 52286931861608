import React, { ReactElement } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Id from "Lib/Id";
import { useTranslation } from "react-i18next";
import {
  CareEpisodeTreatmentTrackStatus,
  useUpdateCareEpisodeTreatmentTrackMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { refetchQueries } from "Lib/RefetchQueries";

type CareEpisodeDetailsTreatmentTracksPillDialogProps = {
  careEpisodeTreatmentTrackId: Id<"CareEpisodeTreatmentTrack">;
  name: string;
  status: CareEpisodeTreatmentTrackStatus;
  title: string;
  text: string;
  open: boolean;
  onClose: () => void;
};
export default function CareEpisodeDetailsTreatmentTracksPillDialogHookWrapper(
  props: CareEpisodeDetailsTreatmentTracksPillDialogProps
): ReactElement {
  // Mutation: Update a care episode treatment track with the provided status.
  const [updateCareEpisodeTreatmentTrack] = apolloMutationHookWrapper(
    (data) => data.careUpdateCareEpisodeTreatmentTrack,
    useUpdateCareEpisodeTreatmentTrackMutation({
      variables: {
        input: {
          careEpisodeTreatmentTrackId: props.careEpisodeTreatmentTrackId,
          status: props.status,
        },
      },
      refetchQueries: refetchQueries("treatmentTracks"),
    })
  );

  const handleOk = function () {
    updateCareEpisodeTreatmentTrack();
    props.onClose();
  };

  return <CareEpisodeDetailsTreatmentTracksPillDialogComponent okHandler={handleOk} {...props} />;
}

type CareEpisodeDetailsTreatmentTracksPillDialogComponentProps =
  CareEpisodeDetailsTreatmentTracksPillDialogProps & {
    okHandler: () => void;
  };
function CareEpisodeDetailsTreatmentTracksPillDialogComponent(
  props: CareEpisodeDetailsTreatmentTracksPillDialogComponentProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <Card>
        <CardHeader title={props.title}></CardHeader>
        <CardContent>
          <Stack spacing={1}>
            <Typography>{props.text}</Typography>
            <Stack direction="row" spacing={1}>
              <Button variant="contained" onClick={props.okHandler}>
                {t("careEpisodes:actions.ok")}
              </Button>
              <Button variant="outlined" onClick={props.onClose}>
                {t("careEpisodes:actions.cancel")}
              </Button>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Dialog>
  );
}

export {
  CareEpisodeDetailsTreatmentTracksPillDialogHookWrapper as CareEpisodeDetailsTreatmentTracksPillDialog,
  CareEpisodeDetailsTreatmentTracksPillDialogComponent as Component,
};
