import { Box, Collapse, Divider, Snackbar, Stack, Typography, useTheme } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper, cache as apolloCache } from "Api/GraphQL";
import { Section } from "CollaborativeCare/CaseConsult/Section";
import {
  SortDirection,
  TaskListItemSortParameter,
  TaskStatus,
  useDefaultTaskListQuery,
  useMoveDefaultTaskListItemMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useState } from "react";
import { CompactTaskCard, CompactTaskCardDragPreview, LoadingCompactTaskCard } from "./CompactTaskCard";
import { DnDSortableList } from "DragAndDrop/DnDSortableList";
import { useTranslation } from "react-i18next";
import { QuickAddTaskForm } from "./QuickAddTaskForm";
import { useEffectSimpleCompare } from "Lib/Hooks";
import EnumSelect from "Shared/EnumSelect";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import Paginator, { initPage, isFirstPage } from "Shared/Paginator";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { usePanelFilter } from "Contexts/SelectedPanelContext";

export function DashboardTaskList(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();

  const [moveItem, _] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareMoveTaskListItemToPositionInDefaultTaskList,
    useMoveDefaultTaskListItemMutation()
  );

  const [taskStatusFilter, setTaskStatusFilter] = useState<TaskStatus | null>(TaskStatus.ACTIVE);
  const [pagination, setPagination] = React.useState(initPage(10));
  const testPatientViewAbility = useTestPatientViewability();

  const panelFilter = usePanelFilter();
  const { remoteData } = apolloQueryHookWrapper(
    useDefaultTaskListQuery({
      variables: {
        ...pagination,
        sortBy: TaskListItemSortParameter.POSITION,
        sortDirection: SortDirection.ASC,
        status: taskStatusFilter,
        testPatient: testPatientViewAbility,
        forPanel: panelFilter,
      },
      fetchPolicy: "cache-and-network",
    })
  );

  const [listLoadedCount, setListLoadedCount] = React.useState(0);
  useEffectSimpleCompare(() => {
    if (remoteData.kind === "Success") {
      setListLoadedCount(listLoadedCount + 1);
    }
  }, [remoteData.kind]);
  // We don't want to animate cards in on the first load, which will have loadedCount === 0.
  const animateCard = listLoadedCount >= 1;

  const children = remoteData.caseOf({
    NotAsked: () => <DashboardTaskListLoading />,
    Loading: () => <DashboardTaskListLoading />,
    Failure: (error) => {
      const user = React.useContext(AuthenticatedProviderUserContext);
      let message = error.message;
      if (user && user.userType === "internal") {
        message = t("collaborativeCare:careManagerDashboard.taskList.internalUserError");
      }
      return <DashboardTaskListError message={message} />;
    },
    Success: (response) => {
      if (response.collaborativeCareMyDefaultTaskList) {
        // In order to assign to the position property of the drag payload (see below) we need to strip readonly-ness
        // off it, which it has when it comes out of the graphql request. This just maps all the items into copies of
        // themselves that allow assigning to their top level properties.
        const items = response.collaborativeCareMyDefaultTaskList.taskListItems.nodes.map((item) => ({
          ...item,
        }));

        const missingTasksCount =
          response.collaborativeCareMyDefaultTaskList.nonPanelItems.totalCount -
          response.collaborativeCareMyDefaultTaskList.taskListItems.totalCount;
        const emptyMessage =
          missingTasksCount === 0
            ? t("collaborativeCare:careManagerDashboard.taskList.noTasksMessage")
            : t("collaborativeCare:careManagerDashboard.taskList.hiddenPanelTasks", {
                count: missingTasksCount,
              });

        const missingTasksMessage = (
          <Stack direction="row" alignItems="center" justifyContent="center" marginTop="2rem">
            <Typography variant="caption" textAlign="center">
              {t("collaborativeCare:careManagerDashboard.taskList.hiddenPanelTasks", {
                count: missingTasksCount,
              })}
            </Typography>
          </Stack>
        );

        return (
          <>
            <DnDSortableList
              items={items}
              itemType="CompactTaskCard"
              getId={(item) => item.id.toString()}
              getPosition={(item) => item.position}
              renderItem={(item, dragging) => (
                <Collapse in appear={animateCard}>
                  <CompactTaskCard task={item.task} dragging={dragging} />
                </Collapse>
              )}
              renderDragPreview={(item) => <CompactTaskCardDragPreview task={item.task} />}
              moveWhileDragging={(item, position) => {
                const id = apolloCache.identify(item);

                // First we update the data in the apollo cache, which will cause all of these components to re-render...
                apolloCache.modify({
                  id: id,
                  fields: {
                    position: (_oldPosition) => position,
                  },
                });

                // ... and then we separately update the item in the drag state itself. It's a little weird to do this
                // by straight assignment instead of a calling setItem or something, but that's apparently how it works,
                // see https://codesandbox.io/p/sandbox/github/react-dnd/react-dnd/tree/gh-pages/examples_ts/04-sortable/simple?file=%2Fsrc%2FCard.tsx
                item.position = position;
              }}
              moveOnDrop={(item, position) => {
                moveItem({
                  variables: {
                    input: {
                      taskListItemId: item.id,
                      position: position,
                    },
                  },
                });
              }}
              emptyMessage={emptyMessage}
            />
            {/* Don't show the missing tasks message if there are no tasks, because it will replace the no tasks message instead. */}
            {missingTasksCount > 0 && items.length > 0 ? missingTasksMessage : null}
            <Paginator
              pagination={pagination}
              onChange={setPagination}
              pageInfo={response.collaborativeCareMyDefaultTaskList.taskListItems.pageInfo}
            />
          </>
        );
      } else {
        return <DashboardTaskListError message={t("collaborativeCare:careManagerDashboard.tasksError")} />;
      }
    },
  });

  const notOnFirstPage = remoteData.caseOf({
    Success: (response) => {
      if (response.collaborativeCareMyDefaultTaskList) {
        return !isFirstPage(response.collaborativeCareMyDefaultTaskList.taskListItems.pageInfo);
      } else {
        return false;
      }
    },
    _: () => false,
  });

  const [showNewTaskMessage, setShowNewTaskMessage] = React.useState(false);
  const handleNewTaskMessageClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setShowNewTaskMessage(false);
  };

  return (
    <Stack direction="column" spacing={1}>
      <Section
        title={t("collaborativeCare:careManagerDashboard.tasksHeader")}
        actions={
          <Box width="15rem">
            <EnumSelect
              sx={{ backgroundColor: theme.palette.background.paper }}
              optionsEnum={TaskStatus}
              title={t("collaborativeCare:filters.task.status")}
              enumTrans={taskStatusT}
              value={taskStatusFilter}
              onChange={(newStatus) => {
                setTaskStatusFilter(newStatus);
              }}
            />
          </Box>
        }
      >
        <QuickAddTaskForm
          onSuccess={() => {
            // What I would actually like to do here is compare the id that comes back from the mutation to the slice of
            // the collection we're currently looking at rather than assuming all tasks will appear on the first page,
            // but there's a timing issue there because this callback will run before the collection refetches, so it
            // will always look like the new task isn't present. This is close enough.
            if (notOnFirstPage || taskStatusFilter === TaskStatus.COMPLETE) {
              setShowNewTaskMessage(true);
            }
          }}
        />
        <Divider sx={{ borderColor: theme.palette.dividerLight, marginTop: 1 }} />
        {children}
      </Section>
      <Snackbar
        open={showNewTaskMessage}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleNewTaskMessageClose}
        message={t("collaborativeCare:careManagerDashboard.taskList.invisibleNewTask")}
      />
    </Stack>
  );
}

function DashboardTaskListLoading(): ReactElement {
  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      <LoadingCompactTaskCard />
      <LoadingCompactTaskCard />
      <LoadingCompactTaskCard />
    </Stack>
  );
}

function DashboardTaskListError(props: { message: string }): ReactElement {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" margin={3}>
      <Typography variant="h2">{props.message}</Typography>
    </Stack>
  );
}
