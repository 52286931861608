import React from "react";

import { Card, CardContent, Paper } from "@mui/material";
import {
  EntityTreeNodeParams,
  MetricComputedValueSortParameter,
  MetricParams,
  OutcomesTransientMetricComputedValueQuery,
  OutcomesTransientMetricComputedValueQueryVariables,
  SortDirection,
  useOutcomesTransientMetricComputedValueQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { SeverityNameAndValueChip } from "Shared/Scale/SeverityChip";
import { Maybe } from "seidr";
import { useTranslation } from "react-i18next";
import { ensureMetricParams } from "./OutcomesFormHelpers";
import { ContextualReportLink } from "Shared/ContextualReportLink";
import { scaleShortestName } from "Shared/Scale/Scale";

const PAGE_SIZE = 25;

type OutcomesTransientMetricComputedValueTableProps = {
  metricParams: MetricParams;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

function OutcomesTransientMetricComputedValueTable(props: OutcomesTransientMetricComputedValueTableProps) {
  const { t } = useTranslation(["common", "outcomes"]);
  const { metricParams, entityTreeNodeParams, startDate, endDate } = props;

  const cols: DataGridCols<
    OutcomesTransientMetricComputedValueQuery,
    ["outcomesTransientMetricComputedValues"]
  > = React.useMemo(() => {
    return [
      {
        field: "treatmentStartDate",
        headerName: t("outcomes:computedValueTable.treatmentStartDate"),
        sortable: true,
        flex: 2,
        valueGetter: (_value, row) => t("date.tiny", { date: row.treatmentStartDate }),
      },
      {
        field: "patientName",
        headerName: t("outcomes:computedValueTable.patientName"),
        sortable: true,
        flex: 3,
        renderCell: (params) => {
          return (
            <ContextualReportLink patient={params.row.patient.id}>
              {params.row.patient.name}
            </ContextualReportLink>
          );
        },
      },
      {
        field: "scaleName",
        headerName: t("outcomes:computedValueTable.scale"),
        sortable: false,
        flex: 2,
        renderCell: (params) => {
          return scaleShortestName(params.row.scale);
        },
      },
      {
        field: "baseline",
        headerName: t("outcomes:computedValueTable.baseline"),
        sortable: true,
        flex: 2,
        renderCell: (params) => {
          if (typeof params.row.firstValue !== "number") {
            return null;
          }

          if (params.row.firstThresholdClass) {
            const severityValue = {
              value: params.row.firstValue,
              thresholdClass: params.row.firstThresholdClass,
              thresholdMnemonic: Maybe.fromNullable(params.row.firstThresholdMnemonic),
            };

            return <SeverityNameAndValueChip isStale={false} value={severityValue} />;
          } else {
            return params.row.firstValue;
          }
        },
      },

      {
        field: "result",
        headerName: t("outcomes:computedValueTable.result"),
        sortable: true,
        flex: 2,
        renderCell: (params) => {
          if (typeof params.row.value !== "number") {
            return null;
          }

          if (params.row.thresholdClass) {
            const severityValue = {
              value: params.row.value,
              thresholdClass: params.row.thresholdClass,
              thresholdMnemonic: Maybe.fromNullable(params.row.thresholdMnemonic),
            };

            return <SeverityNameAndValueChip isStale={false} value={severityValue} />;
          } else {
            return params.row.value;
          }
        },
      },
      {
        field: "daysSinceTreatmentStart",
        headerName: t("outcomes:computedValueTable.daysSinceTreatmentStart"),
        sortable: true,
        flex: 1,
        valueGetter: (_value, row) => {
          return `${row.daysSinceTreatmentStart.toString()} days`;
        },
      },
      {
        field: "metInclusionCriteria",
        headerName: t("outcomes:computedValueTable.metInclusionCriteria"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          if (params.row.metInclusionCriteria) {
            return <CheckIcon />;
          } else {
            return <CloseIcon />;
          }
        },
      },
    ];
  }, []);

  if (props.metricParams.payload.timeBased) {
    cols.push({
      field: "metSuccessCriteria",
      headerName: t("outcomes:computedValueTable.metSuccessCriteria"),
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.metSuccessCriteria) {
          return <CheckIcon />;
        } else {
          return <CloseIcon />;
        }
      },
    });
  }

  if (props.metricParams.payload.triggerBased) {
    cols.push({
      field: "metTrigger",
      headerName: t("outcomes:computedValueTable.metTrigger"),
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.metTrigger) {
          return <CheckIcon />;
        } else {
          return <CloseIcon />;
        }
      },
    });
  }

  const queryVariables: Pick<
    OutcomesTransientMetricComputedValueQueryVariables,
    "metricParams" | "entityTreeNode" | "dateRange"
  > = {
    metricParams: ensureMetricParams(metricParams), // We need to ensure that all extra params are removed
    entityTreeNode: entityTreeNodeParams,
    dateRange: {
      min: startDate,
      max: endDate,
    },
  };

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        queryHook={useOutcomesTransientMetricComputedValueQuery}
        unwrapData={(d) => d?.outcomesTransientMetricComputedValues || null}
        defaultPageSize={PAGE_SIZE}
        queryVariables={queryVariables}
        colNameToSortParam={(colName) => {
          switch (colName) {
            case "baseline": {
              return MetricComputedValueSortParameter.FIRST_VALUE;
            }
            case "result": {
              return MetricComputedValueSortParameter.VALUE;
            }
            case "treatmentStartDate": {
              return MetricComputedValueSortParameter.TREATMENT_START_DATE;
            }
            case "patientName": {
              return MetricComputedValueSortParameter.PATIENT_NAME;
            }
            case "metTrigger": {
              return MetricComputedValueSortParameter.MET_TRIGGER;
            }
            case "metInclusionCriteria": {
              return MetricComputedValueSortParameter.MET_INCLUSION_CRITERIA;
            }
            case "metSuccessCriteria": {
              return MetricComputedValueSortParameter.MET_SUCCESS_CRITERIA;
            }
            default: {
              return null;
            }
          }
        }}
        defaultSortParams={{
          sortBy: MetricComputedValueSortParameter.TREATMENT_START_DATE,
          sortDirection: SortDirection.ASC,
        }}
        columns={cols}
        initialState={{
          columns: {
            columnVisibilityModel: {
              scaleName: !!props.metricParams.scaleScorerConfig.multiScale,
            },
          },
        }}
      />
    </Paper>
  );
}

type OutcomesTransientMetricComputedValueTableWrapperProps = {
  metricParams: MetricParams | null;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

export default function (props: OutcomesTransientMetricComputedValueTableWrapperProps) {
  const { t } = useTranslation(["outcomes"]);
  if (props.metricParams) {
    return <OutcomesTransientMetricComputedValueTable {...props} metricParams={props.metricParams} />;
  } else {
    return (
      <Card>
        <CardContent style={{ textAlign: "center" }}>
          {t("outcomes:create.computedValues.invalid")}
        </CardContent>
      </Card>
    );
  }
}
