import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonProps,
  DialogContent,
  Menu,
  MenuItem,
  MenuProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { MutationRemoteDataResult, apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import {
  Patient,
  PatientSelectQueryVariables,
  Provider,
  useBeginTimeEntryLogMutation,
  useCreateTaskMutation,
  usePatientSelectLazyQuery,
  useTaskTemplatesQuery,
  CreateTaskResult,
  useUpdateTaskMutation,
  Task,
  useCollaborativeCareTaskDetailsQuery,
  ForceStartStrategy,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId, TaskId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import ContainedIconButton from "MDS/ContainedIconButton";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import { Form, FormOverlay, useForm, useTextField, useWrappedField } from "Shared/Form";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { refetchQueries } from "Lib/RefetchQueries";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { assertNonNull } from "Lib/Utils";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

type PatientSummary = PickTypename<Patient, "id" | "name">;
type ProviderSummary = PickTypename<Provider, "id" | "name">;

type CreateTaskTriggerProps = {
  patient?: PatientSummary;
  component?: ReactElement<{ onClick?: (event: React.MouseEvent<HTMLElement>) => void }>;
  buttonMinWidth?: string;
};

type CreateTaskFormDefaults<TResponse> = Omit<
  CreateOrEditTaskFormProps<TResponse>,
  "onSuccess" | "mutation" | "remoteData" | "getTaskId"
>;

// This is the actual button that triggers showing the form.
export function CreateTaskTrigger(props: CreateTaskTriggerProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  const [showForm, setShowForm] = React.useState(false);
  const [formDefaults, setFormDefaults] = React.useState<CreateTaskFormDefaults<CreateTaskResult>>({
    patient: props.patient,
  });

  const onMenuSelect = (defaults: CreateTaskFormDefaults<CreateTaskResult>) => {
    setShowForm(true);
    setFormDefaults({ ...defaults, patient: props.patient });
  };

  let patientName = "";
  if (props.patient?.name) {
    patientName = " (" + props.patient.name + ")";
  }

  return (
    <Box minWidth={props.buttonMinWidth}>
      <TaskTemplateSelect onSelect={onMenuSelect} component={props.component} />
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:tasks.createHeader") + patientName}
      >
        <DialogContent>
          {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
            form goes away */}
          <CreateTaskForm
            {...formDefaults}
            onSuccess={() => {
              setTimeout(() => setShowForm(false), 300);
            }}
          />
        </DialogContent>
      </ResponsiveDialog>
    </Box>
  );
}

type EditTaskTriggerProps = EditTaskFormProps & Pick<ButtonProps, "variant" | "color">;

export function EditTaskTrigger(props: EditTaskTriggerProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const { variant, color, ...formProps } = props;

  const [showForm, setShowForm] = React.useState(false);
  let patientName = "";
  if (props.task.patient) {
    patientName = " (" + props.task.patient.name + ")";
  }
  return (
    <>
      <Button variant={variant} color={color} onClick={() => setShowForm(true)}>
        {t("common:actions.edit")}
      </Button>
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:tasks.editHeader") + patientName}
      >
        <DialogContent>
          <EditTaskForm
            {...formProps}
            onSuccess={() => {
              setTimeout(() => setShowForm(false), 300);
            }}
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

type TaskTemplateSelectProps = {
  onSelect: (template: CreateTaskFormDefaults<CreateTaskResult>) => void;
  label?: string;
  component?: ReactElement<{ onClick?: (event: React.MouseEvent<HTMLElement>) => void }>;
  renderInStack?: boolean;
  hideCustom?: boolean;
};

// This is the select section to pick a template.
export function TaskTemplateSelect(props: TaskTemplateSelectProps): ReactElement | null {
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return null;
  }

  let renderStack = props.renderInStack;
  if (props.renderInStack == null) {
    renderStack = true;
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (defaults: CreateTaskFormDefaults<CreateTaskResult>) => {
    setAnchorEl(null);
    props.onSelect(defaults);
  };

  const label = props.label ? (
    <Button onClick={handleClick}>
      <Typography variant="h3">{props.label}</Typography>
    </Button>
  ) : null;

  const button = props.component ? (
    React.cloneElement(props.component, { onClick: handleClick })
  ) : (
    <ContainedIconButton onClick={handleClick}>
      <Add />
    </ContainedIconButton>
  );

  let buttonAndLabelContent = (
    <>
      <Stack direction="row" spacing={0.5} alignItems="center">
        {button}
        {label}
      </Stack>
    </>
  );
  if (!renderStack) {
    buttonAndLabelContent = (
      <>
        {button}
        {label}
      </>
    );
  }

  return (
    <>
      {buttonAndLabelContent}
      <TaskTemplateMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onSelect={handleSelect}
        hideCustom={props.hideCustom}
        // AuthenticatedProviderUser isn't a real Provider object anymore so we have to trick it so that we can use
        // this value in a provider select.
        currentProvider={{ __typename: "Provider", id: currentProviderId, name: currentProvider.name }}
      />
    </>
  );
}

type CreateTaskFormProps = Omit<
  CreateOrEditTaskFormProps<CreateTaskResult>,
  "remoteData" | "mutation" | "getTaskId"
>;

export function CreateTaskForm(props: CreateTaskFormProps): ReactElement {
  const { onSuccess, ...formDefaults } = props;

  const [createTask, { remoteData, reset: resetCreateTask }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTask,
    useCreateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  return (
    <CreateOrEditTaskForm
      {...formDefaults}
      onSuccess={() => {
        if (onSuccess) {
          onSuccess();
        }

        setTimeout(() => resetCreateTask(), 300);
      }}
      mutation={(data) => createTask({ variables: { input: { addToDefaultList: true, ...data } } })}
      remoteData={remoteData}
      getTaskId={(response) => response?.task.id}
    />
  );
}

type EditTaskFormWithTaskIdProps = {
  taskId: TaskId;
  onSuccess?: () => void;
};
export function EditTaskFormWithTaskId(props: EditTaskFormWithTaskIdProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const { remoteData } = apolloQueryHookWrapper(
    // This query gets a lot more than we need but we already have it on hand from the original lazy task card
    useCollaborativeCareTaskDetailsQuery({
      variables: {
        id: props.taskId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <></>,
    Loading: () => <></>,
    Failure: () => <></>,
    Success: (response) => {
      if (response.collaborativeCareTask) {
        return (
          <EditTaskForm task={response.collaborativeCareTask} onSuccess={props.onSuccess}></EditTaskForm>
        );
      } else {
        return <ErrorMessage message={t("collaborativeCare:tasks.genericQueryError")} />;
      }
    },
  });
}

type EditTaskFormProps = {
  task: PickTypename<Task, "id" | "title" | "body" | "dueAt"> & {
    patient: PatientSummary | null;
    assignedTo: ProviderSummary;
  };
  onSuccess?: () => void;
};

export function EditTaskForm(props: EditTaskFormProps): ReactElement {
  const [editTask, { remoteData, reset: resetEditTask }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateTask,
    useUpdateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  return (
    <CreateOrEditTaskForm
      title={props.task.title}
      body={props.task.body}
      dueAt={props.task.dueAt || undefined}
      patient={props.task.patient || undefined}
      assignedTo={props.task.assignedTo.id}
      onSuccess={() => {
        if (props.onSuccess) {
          props.onSuccess();
        }

        setTimeout(() => resetEditTask(), 300);
      }}
      mutation={(data) => editTask({ variables: { input: { taskId: props.task.id, ...data } } })}
      remoteData={remoteData}
      getTaskId={(response) => response?.task.id}
    />
  );
}

type CreateOrEditMutationData = {
  title: string;
  body: string | null | undefined;
  dueAt: Date | null | undefined;
  patientId: PatientId | null | undefined;
  assignedToId: ProviderId;
};

type CreateOrEditTaskFormProps<TResponse> = {
  title?: string;
  body?: string;
  dueAt?: Date;
  patient?: PatientSummary;
  assignedTo?: ProviderId;
  onSuccess?: () => void;
  remoteData: MutationRemoteDataResult<TResponse>;
  mutation: (data: CreateOrEditMutationData) => void;
  getTaskId: (response: TResponse) => TaskId | undefined;
};

// This is the actual form, as shown in the modal.
function CreateOrEditTaskForm<TResponse>(props: CreateOrEditTaskFormProps<TResponse>): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const myActiveTimer = useCurrentTimeTracking();
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  const currentProviderId = currentProvider?.providerId.getOrElse(null);

  const [beginTimer] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  const [beginTimerOnSuccess, setBeginTimerOnSuccess] = React.useState(false);

  useEffectSimpleCompare(() => {
    props.remoteData.caseOf({
      Success: (response) => {
        const taskId = props.getTaskId(response);
        if (beginTimerOnSuccess && taskId && currentProviderId) {
          beginTimer({
            variables: {
              input: {
                taskStrategy: { taskId: taskId },
                providerId: currentProviderId,
                clientStartTime: new Date(),
                forceStart: ForceStartStrategy.NEVER,
              },
            },
          });
        }
      },
      _: () => {
        return;
      },
    });
  }, [props.remoteData.kind]);

  const fields = {
    title: useTextField({ required: true, default: props.title }),
    body: useTextField({ required: false, default: props.body }),
    patient: useWrappedField({ required: false, default: props.patient }),
    dueAt: useWrappedField({ required: false, default: props.dueAt }),
    assignedTo: useWrappedField({ required: true, default: props.assignedTo }),
  };

  const form = useForm({
    fields: fields,
    submit: () => {
      props.mutation({
        // Casts here are safe because this will only get called if all the fields are valid, including required
        // fields having values.
        title: fields.title.value as string,
        body: fields.body.value,
        dueAt: fields.dueAt.value as Date,
        patientId: fields.patient.value?.id,
        assignedToId: assertNonNull(fields.assignedTo.value),
      });
    },
    remoteData: props.remoteData,
    onSuccess: props.onSuccess,
  });

  const disableBegin = form.disableSubmit || myActiveTimer.status != "not tracking";

  const patientSelectVars: Omit<PatientSelectQueryVariables, "search"> = {
    first: 30,
  };

  const onSaveAndBegin = async () => {
    setBeginTimerOnSuccess(true);
    form.onFakeSubmit();
  };

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay response={props.remoteData} errorMessage={t("collaborativeCare:tasks.genericFormError")} />
      <Stack direction="column" spacing={1}>
        <TextField
          title={t("collaborativeCare:fields.title.label")}
          label={t("collaborativeCare:fields.title.label")}
          autoFocus
          value={fields.title.value}
          onChange={fields.title.onChange}
          error={fields.title.error}
          helperText={fields.title.helperText}
        />
        <TextField
          title={t("collaborativeCare:fields.body.label")}
          label={t("collaborativeCare:fields.body.label")}
          multiline
          minRows={5}
          value={fields.body.value}
          onChange={fields.body.onChange}
          error={fields.body.error}
          helperText={fields.body.helperText}
        />
        <QueryAutocompleteSingle
          label={t("collaborativeCare:fields.patient.label")}
          value={fields.patient.value}
          valueUpdated={fields.patient.onChange}
          error={fields.patient.error}
          helperText={fields.patient.helperText}
          query={usePatientSelectLazyQuery}
          queryVariables={patientSelectVars}
          unwrapResponse={(response) => response.patients?.nodes}
          valueEqual={(left, right) => left.id === right.id}
          autocompleteProps={{
            getOptionLabel: (option) => option.name,
          }}
        />
        <ProviderSelectSingle
          label={t("collaborativeCare:fields.assignedTo.label")}
          value={fields.assignedTo.value || null}
          setValue={fields.assignedTo.onChange}
          error={fields.assignedTo.error}
          helperText={fields.assignedTo.helperText}
        />
        <DateTimePicker
          label={t("collaborativeCare:fields.dueAt.label")}
          format={t("collaborativeCare:fields.dueAt.format")}
          value={fields.dueAt.value}
          onChange={fields.dueAt.onChange}
        />
        <Stack direction="row-reverse" spacing={1}>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
          <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
            <ButtonWithSpinner
              variant="outlined"
              color="secondary"
              type="button"
              showSpinner={form.showSpinner}
              disabled={disableBegin}
              onClick={onSaveAndBegin}
            >
              {t("collaborativeCare:tasks.actions.saveAndBegin")}
            </ButtonWithSpinner>
          </ReadOnlyOrImpersonatingDisabledButton>
        </Stack>
      </Stack>
    </Form>
  );
}

type TaskTemplateMenuProps = {
  onSelect: (defaults: CreateTaskFormDefaults<CreateTaskResult>) => void;
  currentProvider: ProviderSummary;
  hideCustom?: boolean;
} & Omit<MenuProps, "onSelect">;

function TaskTemplateMenu(props: TaskTemplateMenuProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(useTaskTemplatesQuery());
  const { onSelect, currentProvider, ...menuProps } = props;

  const items = remoteData.caseOf({
    NotAsked: () => [],
    Loading: () => [],
    Failure: (error) => [
      <MenuItem>
        <ErrorMessage message={error.message} />
      </MenuItem>,
    ],
    Success: (response) => {
      if (!response.collaborativeCareTaskTemplates) {
        return [
          <MenuItem>
            <ErrorMessage message={t("collaborativeCare:taskTemplates.genericQueryError")} />
          </MenuItem>,
        ];
      }

      const taskTemplates = [...response.collaborativeCareTaskTemplates.nodes]
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
        .map((template, i) => (
          <MenuItem
            key={i}
            onClick={() =>
              onSelect({
                title: template.title,
                body: template.body || undefined,
                assignedTo: currentProvider.id,
              })
            }
          >
            {template.title}
          </MenuItem>
        ));

      if (!props.hideCustom) {
        taskTemplates.unshift(
          <MenuItem onClick={() => onSelect({ assignedTo: currentProvider.id })} key="custom-task">
            {t("collaborativeCare:tasks.noPrefilledTask")}
          </MenuItem>
        );
      }
      return taskTemplates;
    },
  });

  return <Menu {...menuProps}>{items}</Menu>;
}
