import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { EntityTreeNodeDetails } from "Entities/EntityPath";
import { entityTypeT, implementationTargetStatPeriodT } from "GeneratedGraphQL/EnumTranslations";
import { Axis, Tooltip, XYChart, BarStack, BarSeries, DataProvider, DataContext } from "@visx/xychart";
import {
  EntityTreeCollection,
  ImplementationTargetBreakdownStat,
  ImplementationTargetReportGraphDataUnit,
  ImplementationTargetStatPeriod,
  ImplementationTargetType,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useContext } from "react";
import { LegendOrdinal } from "@visx/legend";
import { assertNonNull } from "Lib/Utils";
import { DEFAULT_CHART_THEME } from "Shared/GraphTheme";
import { formatValueFromUnitClass } from "./ImplementationTargetElements";

const HEIGHT_PER_ROW = 30;
const MARGIN_HEIGHT = 80;
const DEFAULT_MAX_VALUE = 50;

type DataPoint = Pick<ImplementationTargetBreakdownStat, "displayValue" | "series" | "value">;

type DataPointWithName = DataPoint & {
  node: EntityTreeNodeDetails;
};

type BreakdownNode = {
  node: EntityTreeNodeDetails;
  values: ReadonlyArray<DataPoint>;
};

type Breakdown = {
  collection: Pick<EntityTreeCollection, "collection">;
  nodes: ReadonlyArray<BreakdownNode>;
};

export type ImplementationTargetBreakdownDetails = ReadonlyArray<Breakdown>;

function BreakdownSection(props: {
  breakdown: Breakdown;
  maxValue: number | null;
  targetType: ImplementationTargetType;
  unitClass: ImplementationTargetReportGraphDataUnit;
}) {
  const { t } = useTranslation(["enums", "implementation"]);
  const { breakdown } = props;
  const collectionText = entityTypeT(breakdown.collection.collection, t);

  return (
    <Card>
      <CardHeader
        title={collectionText}
        subheader={t("implementation:details.breakdown", { collection: collectionText })}
      />
      <CardContent>
        <BreakdownGraph
          unitClass={props.unitClass}
          rows={breakdown.nodes}
          maxValue={props.maxValue}
          targetType={props.targetType}
        />
      </CardContent>
    </Card>
  );
}

function breakdownAxisTitle(targetType: ImplementationTargetType, t: TFunction<["implementation"]>) {
  switch (targetType) {
    case ImplementationTargetType.COCM_NEW_ENROLLMENTS:
      return t("implementation:targets.COCM_NEW_ENROLLMENTS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_BILLED_MINUTES.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLING.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_RVUS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_VALUE_UNITS.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.graphLegend");
    case ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY:
      return t("implementation:targets.COCM_MONTHLY_BILLING_EFFICIENCY.graphLegend");
  }
}

function BreakdownGraph(props: {
  rows: ReadonlyArray<BreakdownNode>;
  maxValue: number | null;
  targetType: ImplementationTargetType;
  unitClass: ImplementationTargetReportGraphDataUnit;
}) {
  const { t } = useTranslation(["implementation", "enums"]);

  // This is a horizontal graph: the x axis is the value
  const xAccessor = (datum: DataPointWithName) => datum.value;
  const yAccessor = (datum: DataPointWithName) => datum.node.entity.shortname;

  const seriesData: Partial<Record<ImplementationTargetStatPeriod, Array<DataPointWithName>>> = {};

  const graphHeight = props.rows.length * HEIGHT_PER_ROW + MARGIN_HEIGHT;

  if (props.rows.length === 0) {
    return <Typography>{t("implementation:details.noBreakdownData")}</Typography>;
  }

  props.rows.forEach((node) => {
    node.values.forEach((value) => {
      const currentValueSet = seriesData[value.series] || [];

      if (!seriesData[value.series]) {
        seriesData[value.series] = currentValueSet;
      }

      currentValueSet.push({ ...value, node: node.node });
    });
  });

  const bars = Object.entries(seriesData).map(([series, points]) => {
    return (
      <BarSeries
        dataKey={implementationTargetStatPeriodT(series as ImplementationTargetStatPeriod, t)}
        key={series}
        data={points}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
      />
    );
  });

  return (
    <DataProvider
      yScale={{ type: "band", paddingInner: 0.2, paddingOuter: 0.1 }}
      xScale={{ type: "linear", domain: [0, props.maxValue || DEFAULT_MAX_VALUE] }}
      theme={DEFAULT_CHART_THEME}
    >
      <XYChart
        height={graphHeight}
        // Top margin: enough for the ticks
        // Left margin: enough for the name of the brakdown
        // right & bottom: purely padding
        margin={{ top: 60, right: 10, bottom: 10, left: 200 }}
      >
        <Axis
          orientation="left"
          hideTicks
          tickFormat={(t: string) => {
            return t;
          }}
          // It'll hide names if you don't make a large number of max ticks here.
          numTicks={99}
        />
        <Axis
          orientation="top"
          label={breakdownAxisTitle(props.targetType, t)}
          tickFormat={(t) => {
            return formatValueFromUnitClass(t, props.unitClass);
          }}
        />
        <BarStack>{bars}</BarStack>
        <Tooltip
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!tooltipData || !tooltipData.nearestDatum || !colorScale) {
              return <></>;
            }

            const entries = Object.entries(tooltipData.datumByKey).map(([key, datum]) => {
              return (
                <div key={key} style={{ color: colorScale(key) }}>
                  {key}: {formatValueFromUnitClass((datum.datum as DataPointWithName).value, props.unitClass)}
                </div>
              );
            });

            return (
              <div>
                <Typography variant={"h2"}>
                  {(tooltipData.nearestDatum.datum as DataPointWithName).node.entity.shortname}
                </Typography>
                {entries}
              </div>
            );
          }}
        />
      </XYChart>
      <ChartLegend />

      <Typography>{t("implementation:details.onlyNodesWithDataShown")}</Typography>
    </DataProvider>
  );
}

// See this codepen on why moving this into a data context makes sense
// https://codesandbox.io/p/sandbox/xychart-legend-demo-mgqr7?file=%2FExample.tsx%3A96%2C26
function ChartLegend() {
  const { colorScale } = useContext(DataContext);

  return (
    <LegendOrdinal
      direction="row"
      itemMargin="8px 8px 8px 0"
      scale={assertNonNull(colorScale)} // I do not understand why this thinks it's not null, but the code works in all circumstances I have found.
      labelFormat={(label) => label.replace("-", " ")}
    />
  );
}

export function ImplementationTargetBreakdownSections(props: {
  breakdowns: ReadonlyArray<Breakdown>;
  breakdownMaxValue: number | null;
  targetType: ImplementationTargetType;
  unitClass: ImplementationTargetReportGraphDataUnit;
}) {
  const sections = props.breakdowns.map((breakdown) => {
    return (
      <BreakdownSection
        key={breakdown.collection.collection}
        breakdown={breakdown}
        maxValue={props.breakdownMaxValue}
        targetType={props.targetType}
        unitClass={props.unitClass}
      />
    );
  });

  return (
    <Stack direction="column" spacing={1}>
      {sections}
    </Stack>
  );
}
