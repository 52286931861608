import { Box, Button, DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import { consultRequestReasonT } from "GeneratedGraphQL/EnumTranslations";
import {
  ConsultRequestReason,
  Patient,
  useRequestCaseConsultMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId, PatientId, ProviderId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import EnumSelect from "Shared/EnumSelect";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import { Form, FormOverlay, useForm, useTextField, useWrappedField } from "Shared/Form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type RequestConsultButtonProps = {
  patient: PickTypename<Patient, "id" | "name">;
  buttonMinWidth: string;
};

export function RequestConsultButton(props: RequestConsultButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const enrollment = useEnrollmentState(props.patient.id);
  const [open, setOpen] = React.useState(false);

  if (enrollment.status === "enrolled") {
    return (
      <Box minWidth={props.buttonMinWidth}>
        <Button fullWidth variant="contained" color="secondary" onClick={() => setOpen(true)}>
          {t("collaborativeCare:caseConsult.requestConsult.title")}
        </Button>
        <ResponsiveDialog
          open={open}
          title={t("collaborativeCare:caseConsult.requestConsult.titleWithName", {
            patient: props.patient.name,
          })}
          onClose={() => setOpen(false)}
        >
          <RequestConsultForm
            enrollmentId={enrollment.enrollmentId}
            patientId={props.patient.id}
            onSuccess={() => {
              setTimeout(() => setOpen(false), 500);
            }}
          />
        </ResponsiveDialog>
      </Box>
    );
  } else {
    return (
      <Box minWidth={props.buttonMinWidth}>
        <Button fullWidth variant="contained" color="secondary" disabled>
          {t("collaborativeCare:caseConsult.requestConsult.title")}
        </Button>
      </Box>
    );
  }
}

type RequestConsultFormProps = {
  enrollmentId: EnrollmentId;
  patientId: PatientId;
  onSuccess?: () => void;
};

function RequestConsultForm(props: RequestConsultFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [requestConsult, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareRequestCaseConsult,
    useRequestCaseConsultMutation({
      refetchQueries: refetchQueries("consultRequests"),
    })
  );

  const fields = {
    providerId: useWrappedField<ProviderId | null>({
      default: null,
      required: false,
      errorPaths: ["provider"],
    }),
    reason: useWrappedField<ConsultRequestReason>({
      default: ConsultRequestReason.OTHER,
      required: true,
      errorPaths: ["request_reason"],
    }),
    text: useTextField({ required: false, errorPaths: ["request_text"] }),
  };

  const form = useForm({
    id: "create-consult-request-form",
    remoteData: remoteData,
    fields: fields,
    onSuccess: props.onSuccess,
    submit: () => {
      requestConsult({
        variables: {
          input: {
            enrollmentId: props.enrollmentId,
            // Cast is safe because the field is required.
            requestReason: fields.reason.value as ConsultRequestReason,
            requestText: fields.text.value,
            onBehalfOf: fields.providerId.value,
          },
        },
      });
    },
  });

  return (
    <>
      <DialogContent>
        <Form onSubmit={form.onSubmit} id={form.id}>
          <FormOverlay
            response={remoteData}
            errorMessage={form.globalError || t("collaborativeCare:caseConsult.requestConsult.error")}
          />
          <Stack direction="column" spacing={1}>
            <ProviderSelectSingle
              value={fields.providerId.value || null}
              setValue={fields.providerId.onChange}
              error={fields.providerId.error}
              helperText={fields.providerId.helperText}
              label={t("collaborativeCare:caseConsult.requestConsult.fields.requester")}
            />
            <EnumSelect
              optionsEnum={ConsultRequestReason}
              title={t("collaborativeCare:caseConsult.requestConsult.fields.reason")}
              enumTrans={consultRequestReasonT}
              value={fields.reason.value || null}
              onChange={fields.reason.onChange}
              error={fields.reason.error}
              helperText={fields.reason.helperText}
            />
            <TextField
              multiline
              label={t("collaborativeCare:caseConsult.requestConsult.fields.text")}
              rows={5}
              value={fields.text.value}
              onChange={fields.text.onChange}
              error={fields.text.error}
              helperText={fields.text.helperText}
            />
          </Stack>
        </Form>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          showSpinner={form.showSpinner}
          disabled={form.disableSubmit}
          variant="contained"
          color="secondary"
          type="submit"
          form={form.id}
        >
          {t("common:actions.save")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
