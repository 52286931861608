import i18n, { use as i18nUse } from "i18next";
import { initReactI18next } from "react-i18next";
import resources from "./Resources";
import { format as formatDate, formatDistanceToNowStrict, Locale as DateLocale } from "date-fns";
import { enUS as dateEn } from "date-fns/locale";
import { negotiateLanguages } from "@fluent/langneg";

export enum SupportedLanguage {
  English = "en",
}
const supportedLanguages: Array<string> = Object.values(SupportedLanguage);

export type SupportedLanguageMap<T> = {
  [Lang in SupportedLanguage]: T;
};

export function getSupportedLanguage(lang: string | undefined): SupportedLanguage {
  if (!lang) {
    return SupportedLanguage.English;
  }

  // It's possible for langneg to return a locale like "en-US", which we still
  // want to match here, so we can't just do raw string compares.
  if (lang.startsWith("en")) {
    return SupportedLanguage.English;
  }

  return SupportedLanguage.English;
}

// Languages the browser declares support for. The fallback to .language and
// .userLanguage is for older browser and IE.
// expect ts error: Whatever type definitions typescript has don't think that
// userLanguage is a valid property, but according to MDN it's the only
// language property that IE has: https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
const requestedLanguages = [
  ...navigator.languages,
  navigator.language,
  // @ts-expect-error userLanguage is the only property available for IE
  navigator.userLanguage,
];

export const negotiatedLanguage: SupportedLanguage = getSupportedLanguage(
  negotiateLanguages(requestedLanguages, supportedLanguages, {
    strategy: "lookup",
    defaultLocale: SupportedLanguage.English,
  })[0] || "en" // negotiateLanguages always returns an array, even in lookup mode where
); // it'll only have one element.

const dateLocales: SupportedLanguageMap<DateLocale> = {
  en: dateEn,
};

export const DefaultNS = "common" as const;

const nameSpaces = Object.keys(resources);

// pass the i18n instance to react-i18next.
i18nUse(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: negotiatedLanguage,
    debug: false,
    resources,
    defaultNS: DefaultNS,
    ns: nameSpaces,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      // i18next's format option parsing is pretty wonky and will split on this character anywhere it appears in a
      // formatting section, even if surrounded by quotes. The default is a comma and we want to use commas in our date
      // formats (e.g., date(MMM dd, yyyy), so if we don't change this that will parse as if we did date(MMM dd)
      // instead. If you end up here again because there's some other formatting option where we need to use semicolons
      // in the format string, we should consider instead just registering a bunch of specialized formatters that have
      // the format strings baked in, rather than trying to chase down increasingly uncommon characters.
      formatSeparator: ";",
    },
  });

i18n.services.formatter?.add("date", (value, language, options) => {
  return formatDate(value, options.format, {
    locale: dateLocales[getSupportedLanguage(language)],
  });
});

i18n.services.formatter?.add("distanceToNow", (value, language, _options) => {
  // Using the strict version of this instead of the default one to avoid the case where you can have dates in the same
  // table that fall just on each side of the "2 months ago" / "about 2 months ago" divide, which is very confusing.
  return formatDistanceToNowStrict(value, {
    locale: dateLocales[getSupportedLanguage(language)],
  });
});

export default i18n;
export { resources };
