import { Card, CardContent, CardHeader } from "@mui/material";
import {
  Patient,
  UserConsentListQuery,
  UserConsentSortParameter,
  useUserConsentListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { userConsentStatusT } from "GeneratedGraphQL/EnumTranslations";

type UserConsentsCardElementProps = {
  patient: PickTypename<Patient, "id">;
};

export function UserConsentsCard(props: UserConsentsCardElementProps): ReactElement {
  const { t } = useTranslation(["patients", "collaborativeCare", "enums", "common"]);

  const columns: DataGridCols<UserConsentListQuery, ["userConsents"]> = [
    {
      field: "createdAt",
      headerName: t("patients:userConsent.createdAt"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return t("common:date.tiny", { date: params.row.createdAt });
      },
    },
    {
      field: "user",
      headerName: t("patients:userConsent.user"),
      flex: 3,
      sortable: false,
      renderCell: (params) => {
        return params.row.user.name;
      },
    },
    {
      field: "consentType",
      headerName: t("patients:userConsent.consentType"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.consentType;
      },
    },

    {
      field: "status",
      headerName: t("patients:userConsent.status"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return userConsentStatusT(params.row.status, t);
      },
    },
  ];
  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.userConsents")} />
      <CardContent>
        <SortablePagableCollectionDataGrid
          queryHook={useUserConsentListQuery}
          queryVariables={{ patientId: props.patient.id }}
          unwrapData={(response) => response?.userConsents || null}
          colNameToSortParam={() => {
            return UserConsentSortParameter.CREATED_AT;
          }}
          columns={columns}
          defaultPageSize={5}
          getRowId={(row) => row.id.toString()}
          autoHeight
        />
      </CardContent>
    </Card>
  );
}
