import { AcuteChangeCategory } from "GeneratedGraphQL/SchemaAndOperations";
import { TFunction } from "i18next";

// -- Helpers -----------------------------------------------------------------

function name(t: TFunction<["report"]>, ac: AcuteChangeCategory): string {
  switch (ac) {
    case AcuteChangeCategory.DECLINING:
      return t("report:acuteChange.worsening");
    case AcuteChangeCategory.IMPROVING:
      return t("report:acuteChange.improvement");
    case AcuteChangeCategory.INVALID:
      return t("report:acuteChange.invalid");
    case AcuteChangeCategory.NOT_SPECIFIED:
      return t("report:acuteChange.notSpecified");
    case AcuteChangeCategory.NOT_SUPPORTED:
      return t("report:acuteChange.notSupported");
    case AcuteChangeCategory.STABLE:
      return t("report:acuteChange.stable");
  }
}
export { name };
