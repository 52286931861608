import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useDismissFindingMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { FindingId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type DismissActionProps = {
  findingId: FindingId;
};
export function DismissAction(props: DismissActionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const [dismiss, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDismissFinding,
    useDismissFindingMutation({
      variables: {
        input: {
          findingId: props.findingId,
        },
      },
      refetchQueries: refetchQueries("findings"),
    })
  );

  const onDismiss = () => {
    dismiss();
  };

  const showSpinner = remoteData.kind === "Loading";
  const disabled = showSpinner;

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="error"
      onClick={onDismiss}
      showSpinner={showSpinner}
      disabled={disabled}
    >
      {t("collaborativeCare:findings.actions.dismiss")}
    </ButtonWithSpinner>
  );
}
