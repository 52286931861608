import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import TaskList from "./TaskList/TaskList";

function TaskListPage(): ReactElement {
  const { t } = useTranslation(["general"]);

  return (
    <Page browserTitle={t("general:navigation.main.collaborativeCare.tasks")} supportsPanels>
      <TaskList />
    </Page>
  );
}

export default TaskListPage;
