const dashboard = {
  patient: {
    label: "Client",
    firstName: "First Name",
    lastName: "Last Name",
    preferredFirstName: "First Name (Preferred)",
    preferredLastName: "Last Name (Preferred)",
    preferredPronouns: "Preferred Pronouns",
    legalFirstName: "First Name (Legal)",
    legalName: "Full Name (Legal)",
    legalLastName: "Last Name (Legal)",
    preferredName: "Preferred Name",
    gender: "Sex",
    genderIdentity: "Gender Identity (free text)",
    genderIdentityFhir: "Gender Identity (select)",
    genderIdentityCombined: "Gender Identity",
    email: "Email",
    phoneNumber: "Phone Number",
    race: "Race",
    educationYears: "Education (years)",
    researchPrimaryDiagnosis: "Primary Diagnosis",
    researchOtherDiagnoses: "Other Diagnosis",
    researchOther: "Other Details",
    dob: "Date of Birth",
    dobShort: "Date of Birth",
    yob: "Year of Birth",
    lastSsn: "Last 4 SSN",
    isTest: "Test Client",
    relatedPeople: "Related people",
    language: "Default Language",
    flags: "Flags",
    primaryProvider: "Primary Clinician",
    currentStats: {
      updatedAt: "Updated",
      complexity: "Complexity",
      severity: "Severity",
      severityTrend: {
        label: "Trend",
      },
      complexityDescription: "Complexity indicates the total number of symptoms endorsed, capped at 10",
      severityDescription:
        "Severity indicates the magnitude of the client's most severe symptom on a 1-10 scale",
      severityTrendDescription: "Trend indicates the change in severity since the previous session",
    },
    errors: {
      "mrn - has already been taken":
        "A client with this MRN has already been created. Please check the client does not already exist, or enter a different MRN.",
    },
  },
  pagination: {
    next: "Next Week",
    previous: "Last Week",
    current: "Today",
    // In principle we should be able to reuse the date format from common here like
    // `title: '$t(common:date.dayMonth, {"date": {{ start }} })`, but I can't actually get that to work right so I'm
    // inlining the format string here.
    title: "{{ start; date(format:EEEE, MMMM do) }} - {{ end; date(format:EEEE, MMMM do) }}",
  },
  headers: {
    appointmentTime: "Time",
    appointmentStatus: "Status",
    report: "Report",
    providerName: "Provider",
    actions: "Actions",
  },
  discreet: {
    text: "Your dashboard is not shown because discreet mode is on.",
    button: "Disable Discreet Mode",
    filterLabel: "Search clients by name or MRN",
    searchButton: "Search",
  },
  titles: {
    appointment: "Appointment Schedule",
  },
  noAppointments: "No scheduled appointments that match your current filters",
  badFilters: {
    allAppointments: "To look at the upcoming schedule, please select either a unit or a provider (or both)",
  },
  keycodeLogin: {
    name: "Name",
    code: "Code",
    timing: "Codes are valid for 15 minutes",
  },
  actions: {
    measurementDetails: "Measurement Details",
    keycodeLogin: "Key Code Login",
  },
};

export default dashboard;
