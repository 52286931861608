import React, { ReactElement } from "react";
import { InboxFilters } from "./InboxFilters";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  DataGridRowClickHandler,
} from "Shared/SortablePagableCollectionDataGrid";
import {
  Patient,
  TriggeredWorkflowListQuery,
  TriggeredWorkflowListQueryVariables,
  useTriggeredWorkflowListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Link from "MDS/Link";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PickTypename } from "type-utils";

type InboxTableProps = {
  filters: InboxFilters;
};

export function InboxTable(props: InboxTableProps): ReactElement | null {
  const { t } = useTranslation(["decisionSupport"]);
  const theme = useTheme();
  const navigate = useNavigate();

  const user = React.useContext(AuthenticatedProviderUserContext);
  if (!user) {
    return null;
  }

  const columns: DataGridCols<TriggeredWorkflowListQuery, ["decisionSupportTriggeredWorkflows"]> = [
    {
      field: "patientName",
      headerName: t("decisionSupport:inbox.headers.patientName"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        return <PatientNameColumn patient={params.row.triggeredDecisionAlgorithm.careEpisode.patient} />;
      },
    },
    {
      field: "workflow",
      headerName: t("decisionSupport:inbox.headers.workflow"),
      flex: 3,
      sortable: false,
      valueGetter: (_value, row) => row.workflow.title,
    },
    {
      field: "status",
      headerName: t("decisionSupport:inbox.headers.status"),
      flex: 1,
      sortable: false,
      valueGetter: (_value, row) => t(`decisionSupport:workflowStatus.${row.status}.title`),
    },
  ];

  const queryVars: Pick<TriggeredWorkflowListQueryVariables, "provider" | "patient" | "status" | "workflow"> =
    {
      provider: props.filters.provider,
      patient: props.filters.patient,
      status: props.filters.workflowState,
      workflow: props.filters.workflow,
    };

  // TODO: How to package up this row-click-without-clobbering-links behavior into something more easily reusable
  const onClick: DataGridRowClickHandler<
    TriggeredWorkflowListQuery,
    ["decisionSupportTriggeredWorkflows"]
  > = (params, event) => {
    // Calling `navigate` in this handler will override the effect of clicking on any links in the table, so the patient
    // name column's link does nothing. Checking isDefaultPrevented tells us if we're clicking on something that's
    // generating another event in addition to this click (like a link, or possibly other inputs, I haven't tested).
    // As long as we don't navigate during clicks on the link the link works fine.
    if (!event.isDefaultPrevented()) {
      const id = params.row.id;
      navigate(`/app/providers/decision-support/inbox/workflow/${id}`);
    }
  };

  return (
    <SortablePagableCollectionDataGrid
      sx={{ "& .MuiDataGrid-row.Mui-selected": { backgroundColor: theme.palette.background.default } }}
      disableRowSelectionOnClick={false}
      queryHook={useTriggeredWorkflowListQuery}
      queryVariables={queryVars}
      unwrapData={(response) => response?.decisionSupportTriggeredWorkflows || null}
      colNameToSortParam={(_field) => null}
      columns={columns}
      defaultPageSize={10}
      onRowClick={onClick}
    />
  );
}

type PatientNameColumnProps = {
  patient: PickTypename<Patient, "id" | "name">;
};

function PatientNameColumn(props: PatientNameColumnProps) {
  return <Link to={`/provider/patients/${props.patient.id}`}>{props.patient.name}</Link>;
}
