import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { DataSourceRecordLogsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { ArrayElement, Dig } from "type-utils";

type iHx = ArrayElement<Dig<DataSourceRecordLogsQuery, ["integrationsDataSourceRecordLogs", "nodes"]>>;

function RecordLogDialog({ log, closeDialog }: { log: iHx; closeDialog: () => void }) {
  return (
    <Dialog
      disableEnforceFocus
      open={true}
      fullWidth={true}
      maxWidth={"sm"}
      aria-labelledby="add-care-unit-dialog-title"
      data-testid="add-care-unit-dialog"
      onClose={closeDialog}
    >
      <DialogTitle id="add-care-unit-dialog-title">RecordLogDialog {log.id.toString()}</DialogTitle>

      <DialogContent dividers>
        <PrettyPrintJson data={log} />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function PrettyPrintJson({ data }: { data: unknown }): ReactElement {
  return (
    <div>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  );
}

export default RecordLogDialog;
