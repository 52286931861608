import { ButtonProps } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { Task, useMoveDefaultTaskListTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type SendTaskToTopButtonProps = {
  task: PickTypename<Task, "id">;
} & Omit<ButtonProps, "onClick">;

/**
 * Sends a task to the top of the default task list (as long as the task is already present in the list). Note that this
 * takes a task not a task list item, and so uses the slightly worse mutation that does the same. This is so it can be
 * used in contexts where we don't have access to the task list item, like the all tasks page. If you do have a task
 * list item, consider making a new version of this component rather than calling this one.
 */
export function SendTaskToTopButton(props: SendTaskToTopButtonProps): ReactElement {
  const { task, disabled, ...buttonProps } = props;
  const { t } = useTranslation(["collaborativeCare"]);

  const [moveToTop, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareMoveTaskToPositionInDefaultTaskList,
    useMoveDefaultTaskListTaskMutation({
      variables: {
        input: {
          taskId: task.id,
          position: 1,
        },
      },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  // We may want to do something with error responses here, but for now the assumption in the production app is going
  // to be that all tasks for all users are in their default task list, so this should never return an error. Fingers
  // crossed I guess.

  const showSpinner = remoteData.kind === "Loading";

  return (
    <ButtonWithSpinner
      {...buttonProps}
      showSpinner={showSpinner}
      disabled={disabled || showSpinner}
      onClick={() => moveToTop()}
    >
      {t("collaborativeCare:careManagerDashboard.taskList.sendToTop")}
    </ButtonWithSpinner>
  );
}
