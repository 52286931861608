import { ButtonProps } from "@mui/material";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import { Patient, Task, TimeEntryLog } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { Edit } from "@mui/icons-material";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { omit } from "ramda";
import { TimeEntryLogEditDialog } from "./TimeEntryLogEditDialog";

type TimeEntryEditIconButtonProps = ButtonProps & { task: Pick<Task, "id" | "title" | "isPlaceholder"> } & {
  timeEntryLog: PickTypename<
    TimeEntryLog,
    | "id"
    | "clientStartTime"
    | "durationSeconds"
    | "durationReviewStatus"
    | "reviewedAt"
    | "unreviewedDurationSeconds"
  >;
  patient: PickTypename<Patient, "id"> | null;
};

export function TimeEntryEditIconButton(props: TimeEntryEditIconButtonProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return null;
  }

  const [showForm, setShowForm] = React.useState(false);

  const maybeTask = useCurrentTask();
  const displayedTask = maybeTask || props.task;
  // We need the patient smashed onto the task for our dialog, so let's just reform this.
  const dialogTask = { ...displayedTask, patient: props.patient };

  const buttonProps = omit(["timeEntryLog"], props);

  return (
    <>
      <ExpandingIconButton
        variant="contained"
        color="secondary"
        icon={<Edit />}
        onClick={() => setShowForm(true)}
        {...buttonProps}
      >
        {t("common:actions.edit")}
      </ExpandingIconButton>
      <TimeEntryLogEditDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        onSuccess={() => setTimeout(() => setShowForm(false), 300)}
        task={dialogTask}
        timeEntryLog={props.timeEntryLog}
        allowDiscardTime={false}
      />
    </>
  );
}
