import { CareEpisodeSpecialSectionType } from "GeneratedGraphQL/SchemaAndOperations";
import { sortBy } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { SpecialSection } from "Shared/Scale/Constructs";
import { ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { Section } from "Shared/Section";
import { FeedbackReportContext } from "../FeedbackReportContext";
import ScaleSectionsTable from "./ScaleSectionsTable";

const ORDERED_NORSE_SYMPTOM_MNEMONICS = [
  "emotionalDistance",
  "suicideRisk",
  "avoidance",
  "psychosis",
  "relationalDistress",
  "perfectionism",
  "eating",
  "somaticAnxiety",
  "hurtfulRumination",
  "negativeAffect",
  "demoralization",
  "hypervigilance",
  "substanceUse",
];

const ORDERED_NORSE_STRENGTH_MNEMONICS = [
  "attachment",
  "connectedness",
  "resilience",
  "socialRole",
  "alliance",
  "expressedTherapyNeeds",
];

function isSymptoms(section: ScaleSectionSummary): boolean {
  return section.caseOf({
    IndividualScaleSection: (s) => {
      return ORDERED_NORSE_SYMPTOM_MNEMONICS.includes(s.scale.mnemonic);
    },
    ScaleGroupSection: () => {
      return false;
    },
  });
}

function symptomOrder(section: ScaleSectionSummary): number {
  return section.caseOf({
    IndividualScaleSection: (s) => {
      return ORDERED_NORSE_SYMPTOM_MNEMONICS.indexOf(s.scale.mnemonic);
    },
    ScaleGroupSection: () => {
      return -1;
    },
  });
}

function isStrength(section: ScaleSectionSummary): boolean {
  return section.caseOf({
    IndividualScaleSection: (s) => {
      return ORDERED_NORSE_STRENGTH_MNEMONICS.includes(s.scale.mnemonic);
    },
    ScaleGroupSection: () => {
      return false;
    },
  });
}

function strengthOrder(section: ScaleSectionSummary): number {
  return section.caseOf({
    IndividualScaleSection: (s) => {
      return ORDERED_NORSE_STRENGTH_MNEMONICS.indexOf(s.scale.mnemonic);
    },
    ScaleGroupSection: () => {
      return -1;
    },
  });
}

function parseNorseSymptomsSections(
  sections: ReadonlyArray<ScaleSectionSummary>
): ReadonlyArray<ScaleSectionSummary> {
  return sortBy(symptomOrder, sections.filter(isSymptoms));
}

function parseNorseStrengthsSections(
  sections: ReadonlyArray<ScaleSectionSummary>
): ReadonlyArray<ScaleSectionSummary> {
  return sortBy(strengthOrder, sections.filter(isStrength));
}

function norseCompareGraphsData(
  section: SpecialSection & { sectionType: CareEpisodeSpecialSectionType.NORSE }
) {
  return [
    {
      id: "norseSymptoms",
      name: "CAMS Symptoms",
      scales: parseNorseSymptomsSections(section.scaleSections),
    },
    {
      id: "norseStrengths",
      name: "CAMS Strengths",
      scales: parseNorseStrengthsSections(section.scaleSections),
    },
  ];
}

function NorseSection(props: {
  section: SpecialSection;
  feedbackReportContext: FeedbackReportContext;
  hideTitle?: boolean;
}): ReactElement {
  const { t } = useTranslation(["report"]);
  const { section, feedbackReportContext } = props;

  const strengths = parseNorseStrengthsSections(section.scaleSections);
  const symptoms = parseNorseSymptomsSections(section.scaleSections);
  return (
    <>
      <Section title={t("report:norse.symptomsTitle")} hideTitle={props.hideTitle}>
        <ScaleSectionsTable scaleSections={symptoms} feedbackReportContext={feedbackReportContext} />
      </Section>
      <Section title={t("report:norse.strengthsTitle")} hideTitle={props.hideTitle}>
        <ScaleSectionsTable scaleSections={strengths} feedbackReportContext={feedbackReportContext} />
      </Section>
    </>
  );
}

export { norseCompareGraphsData };

export default NorseSection;
