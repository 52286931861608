import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import LevelHeader from "FeedbackReport/LevelHeader";
import { TrendIndicatorBase } from "Shared/Scale/TrendIndicator";
import Trend from "Shared/Scale/Trend";
import { AcuteChangeIndicatorBase } from "Shared/Scale/AcuteChangeIndicator";
import { SeverityChipBase } from "Shared/Scale/SeverityChip";
import { AcuteChangeCategory, ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";

export function Legend(): ReactElement {
  const { t } = useTranslation(["report"]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <LevelHeader>{t("report:acuteChange.acuteChange")}</LevelHeader>
      </Grid>
      <Grid item xs={6}>
        <LevelHeader>{t("report:trend.trend")}</LevelHeader>
      </Grid>

      <Grid item xs={3}>
        {t("report:acuteChange.improvement")}
      </Grid>
      <Grid item xs={3}>
        <AcuteChangeIndicatorBase size={24} acuteChange={AcuteChangeCategory.IMPROVING} />
      </Grid>
      <Grid item xs={3}>
        {t("report:trend.improving")}
      </Grid>
      <Grid item xs={3}>
        <TrendIndicatorBase size={24} trend={Trend.IMPROVING} />
      </Grid>

      <Grid item xs={3}>
        {t("report:acuteChange.stable")}
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={3}>
        {t("report:trend.stable")}
      </Grid>
      <Grid item xs={3}>
        <TrendIndicatorBase size={24} trend={Trend.STABLE} />
      </Grid>

      <Grid item xs={3}>
        {t("report:acuteChange.worsening")}
      </Grid>
      <Grid item xs={3}>
        <AcuteChangeIndicatorBase size={24} acuteChange={AcuteChangeCategory.DECLINING} />
      </Grid>
      <Grid item xs={3}>
        {t("report:trend.worsening")}
      </Grid>
      <Grid item xs={3}>
        <TrendIndicatorBase size={24} trend={Trend.DECLINING} />
      </Grid>

      <Grid item xs={12} />

      <Grid item xs={12}>
        <LevelHeader>{t("report:legend.severity.title")}</LevelHeader>
      </Grid>
      <Grid item xs={6}>
        {t("report:legend.severity.notMeasured")}
      </Grid>
      <Grid item xs={6}>
        <SeverityChipBase thresholdClass={ScaleThresholdClass.UNKNOWN} isStale={false} width={150}>
          &nbsp;
        </SeverityChipBase>
      </Grid>
      <Grid item xs={6}>
        {t("report:legend.severity.low")}
      </Grid>
      <Grid item xs={6}>
        <SeverityChipBase thresholdClass={ScaleThresholdClass.LOW} isStale={false} width={150}>
          &nbsp;
        </SeverityChipBase>
      </Grid>
      <Grid item xs={6}>
        {t("report:legend.severity.moderate")}
      </Grid>
      <Grid item xs={6}>
        <SeverityChipBase thresholdClass={ScaleThresholdClass.MODERATE} isStale={false} width={150}>
          &nbsp;
        </SeverityChipBase>
      </Grid>
      <Grid item xs={6}>
        {t("report:legend.severity.severe")}
      </Grid>
      <Grid item xs={6}>
        <SeverityChipBase thresholdClass={ScaleThresholdClass.SEVERE} isStale={false} width={150}>
          &nbsp;
        </SeverityChipBase>
      </Grid>
    </Grid>
  );
}
