import React, { ReactElement } from "react";
import { Badge, Paper } from "@mui/material";
import Link from "MDS/Link";
import { useTranslation } from "react-i18next";
import { shortGender } from "Lib/Gender";
import {
  CareEpisodeProviderRelationship,
  CollaborativeCarePatientSearchQuery,
  CollaborativeCarePatientSearchQueryVariables,
  PatientSortParameter,
  useCollaborativeCarePatientSearchQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { PatientBillableMinutesBadge } from "CollaborativeCare/PatientBillableMinutesBadge";
import { QuickTrackButton } from "CollaborativeCare/QuickTrackButton";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  useShowExportToolbarFromFlag,
} from "Shared/SortablePagableCollectionDataGrid";
import { SearchFilters } from "./PatientSearchFilters";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { MobilePatientListCard } from "./MobilePatientList";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import { COCM_PATIENT_LIST_TABLE_STORAGE_KEY } from "Shared/Storage";
import { useDebounce } from "Lib/Hooks";

type PatientListTableProps = {
  filters: SearchFilters;
};

export default function PatientListTable(props: PatientListTableProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums", "common", "patients"]);
  const testPatientViewability = useTestPatientViewability();
  const showExportToolbar = useShowExportToolbarFromFlag();

  const panelFilter = usePanelFilter();
  const debouncedSearch = useDebounce(props.filters.search, 500);

  const queryVars: Pick<
    CollaborativeCarePatientSearchQueryVariables,
    "search" | "cocmCareManager" | "cocmPrimaryCareProvider" | "enrollmentStatus" | "testPatient" | "forPanel"
  > = {
    search: debouncedSearch || null,
    cocmPrimaryCareProvider: props.filters.cocmPrimaryCareProvider || null,
    cocmCareManager: props.filters.cocmCareManager || null,
    enrollmentStatus: props.filters.enrollmentStatus || null,
    testPatient: testPatientViewability,
    forPanel: panelFilter,
  };

  const columns: DataGridCols<CollaborativeCarePatientSearchQuery, ["patients"]> = React.useMemo(() => {
    return [
      {
        field: "status",
        headerName: t("collaborativeCare:patientList.fields.status"),
        sortable: false,
        width: 150,
        renderCell: (params): ReactElement => {
          return <PatientHeaderStatus patientId={params.row.id} patientName={params.row.nameLastFirst} />;
        },
      },
      {
        field: "minutes",
        headerName: t("collaborativeCare:patientList.fields.minutes"),
        sortable: false,
        width: 90,
        renderCell: (params): ReactElement => {
          return <PatientBillableMinutesBadge patientId={params.row.id} />;
        },
      },
      {
        field: "quickTrack",
        headerName: "",
        sortable: false,
        width: 150,
        renderCell: (params): ReactElement => {
          return <QuickTrackButton patientId={params.row.id} />;
        },
      },
      {
        field: "enrolledAt",
        headerName: "Enrollment Date",
        sortable: true,
        width: 140,
        renderCell: (params) => {
          const enrolledAt =
            params.row.activeCollaborativeCareCareEpisode?.collaborativeCareEnrollment?.enrolledAt;
          return enrolledAt
            ? t("common:date.durationToNow", { date: enrolledAt }).toString() + " " + t("common:date.ago")
            : "";
        },
      },
      {
        field: "patientName",
        headerName: t("collaborativeCare:patientList.fields.patientName"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!params.row.isTest}
              color="success"
            >
              <Link to={`/app/cocm/patient/${params.row.id}`}>{params.row.nameLastFirst}</Link>
            </Badge>
          );
        },
      },
      {
        field: "gender",
        headerName: t("collaborativeCare:patientList.fields.gender"),
        align: "center",
        headerAlign: "center",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return params.row.gender ? shortGender(params.row.gender, t) : "";
        },
      },
      {
        field: "dob",
        headerName: t("collaborativeCare:patientList.fields.dob"),
        sortable: true,
        width: 120,
        valueGetter: (_value, row) => {
          return row.dob ? t("common:date.tiny", { date: row.dob }) : "";
        },
      },
      {
        field: "pcp",
        headerName: t("collaborativeCare:patientList.fields.pcp"),
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => {
          if (row.activeCollaborativeCareCareEpisode) {
            const cep = row.activeCollaborativeCareCareEpisode.careEpisodeProviders.find((provider) => {
              return provider.relationship == CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN;
            });
            return cep ? cep.provider.orderedName : "";
          } else {
            return "";
          }
        },
      },
      {
        field: "cm",
        headerName: t("collaborativeCare:patientList.fields.cm"),
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => {
          if (row.activeCollaborativeCareCareEpisode) {
            const cep = row.activeCollaborativeCareCareEpisode.careEpisodeProviders.find((provider) => {
              return provider.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
            });
            return cep ? cep.provider.orderedName : "";
          } else {
            return "";
          }
        },
      },
      { field: "mrn", headerName: t("collaborativeCare:patientList.fields.mrn"), sortable: true, flex: 1 },
    ];
  }, []);

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        queryHook={useCollaborativeCarePatientSearchQuery}
        storageKey={COCM_PATIENT_LIST_TABLE_STORAGE_KEY}
        queryVariables={queryVars}
        columns={columns}
        unwrapData={(response) => response?.patients || null}
        colNameToSortParam={sortFieldToParameter}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
        mobileCard={MobilePatientListCard}
        showExportToolbar={showExportToolbar}
        getFooterMessage={(response) => {
          const fullSearchResultCount = response?.nonPanelPatients?.totalCount;
          const searchWithPanelsCount = response?.patients?.totalCount;

          if (fullSearchResultCount === undefined || searchWithPanelsCount === undefined) {
            return undefined;
          }

          return t("collaborativeCare:patientList.search.footer", {
            // Note that even though count isn't interpolated into the translation we need it to select the right
            // variant.
            count: fullSearchResultCount - searchWithPanelsCount,
            fullSearchResultCount: fullSearchResultCount,
            searchWithPanelsCount: searchWithPanelsCount,
          });
        }}
      />
    </Paper>
  );
}

function sortFieldToParameter(parameter: string | null): PatientSortParameter | null {
  switch (parameter) {
    case "dob":
      return PatientSortParameter.DOB;
    case "patientName":
      return PatientSortParameter.NAME_LAST_FIRST;
    case "enrolledAt":
      return PatientSortParameter.ENROLLEDAT;
    case "gender":
      return PatientSortParameter.GENDER;
    case "mrn":
      return PatientSortParameter.MRN;
    default:
      return null;
  }
}
