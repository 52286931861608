import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeView } from "@mui/lab";
import { Card, CardContent, CardHeader, CircularProgress } from "@mui/material";
import { apolloQueryHookWrapper, ApolloQueryRemoteData } from "Api/GraphQL";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import {
  Organization,
  OrganizationTreeQuery,
  useOrganizationTreeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { ancestry, treeify } from "Shared/Ancestry";
import ErrorMessage from "Shared/ErrorMessage";
import { OnlyExpandOnIconClickTreeItem } from "Shared/OnlyExpandOnIconClickTreeItem";

type OrganizationSummary = Pick<Organization, "__typename" | "id" | "name" | "parentId">;

type OrganizationTree = OrganizationSummary & {
  children: Array<OrganizationTree>;
};

function OrganizationTreeItem(props: OrganizationTree): ReactElement {
  return (
    <OnlyExpandOnIconClickTreeItem nodeId={props.id.toString()} label={props.name}>
      {props.children.map((o, i) => (
        <OrganizationTreeItem {...o} key={i} />
      ))}
    </OnlyExpandOnIconClickTreeItem>
  );
}

type OrganizationSectionHookWrapperProps = {
  selectedOrg: OrganizationId | null;
  setSelectedOrg: (newOrg: OrganizationId) => void;
};

function OrganizationSectionHookWrapper(props: OrganizationSectionHookWrapperProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useOrganizationTreeQuery());
  return <OrganizationSection organizationData={remoteData} {...props} />;
}

type OrganizationSectionProps = {
  selectedOrg: OrganizationId | null;
  organizationData: ApolloQueryRemoteData<OrganizationTreeQuery>;
  setSelectedOrg: (newOrg: OrganizationId) => void;
};

function OrganizationSection(props: OrganizationSectionProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);
  const treatmentServiceIsEnabled = useWithFeatureEnabled("enableTreatmentServices");

  // We don't want the default expanded items to change, so we need to keep the initial selection
  const [initialOrg] = useState<OrganizationId | null>(props.selectedOrg);

  function handleNodeSelect(_event: React.SyntheticEvent, id: Array<string> | string) {
    props.setSelectedOrg(id as unknown as OrganizationId);
  }

  const tree = (orgQuery: OrganizationTreeQuery): ReactElement => {
    const orgs = orgQuery.organizations?.nodes || [];
    const tree = treeify(orgs);
    const ancestors = ancestry(orgs, initialOrg);

    return (
      <TreeView
        aria-label={t("careEpisodes:fields.organizationSelector")}
        selected={props.selectedOrg?.toString()}
        onNodeSelect={handleNodeSelect}
        multiSelect={false}
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        defaultExpanded={ancestors}
        disabledItemsFocusable
      >
        {tree.map((o, i) => (
          <OrganizationTreeItem {...o} key={i} />
        ))}
      </TreeView>
    );
  };

  return (
    <Card>
      <CardHeader
        title={t("careEpisodes:sections.organization")}
        subheader={
          treatmentServiceIsEnabled
            ? t("careEpisodes:sections.organizationSubtitleTreatmentService")
            : t("careEpisodes:sections.organizationSubtitle")
        }
      />
      <CardContent>
        {props.organizationData.caseOf({
          Success: tree,
          Loading: () => <CircularProgress />,
          NotAsked: () => <CircularProgress />,
          Failure: (e) => <ErrorMessage message={e.toString()} />,
        })}
      </CardContent>
    </Card>
  );
}

export default OrganizationSectionHookWrapper;
