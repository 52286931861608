import {
  CocmBillingAlgorithmListQuery,
  CocmBillingAlgorithmListQueryVariables,
  CocmBillingAlgorithmSortParameter,
  useCocmBillingAlgorithmListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import React from "react";
import { useTranslation } from "react-i18next";
import AlgorithmListFilters, {
  AlgorithmListFilters as FilterType,
  useAlgorithmListFilters,
} from "./AlgorithmListFilter";
import { COCM_BILLING_ALGORITHM_TABLE_STORAGE_KEY } from "Shared/Storage";
import { Paper } from "@mui/material";
import EntityPath from "Entities/EntityPath";
import Link from "MDS/Link";

function AlgorithmListTable(props: { filters: FilterType }) {
  const { t } = useTranslation(["billing", "common"]);

  const queryVars: Pick<CocmBillingAlgorithmListQueryVariables, "status" | "entityTreeNode"> = {
    status: props.filters.status,
    entityTreeNode: props.filters.entityTreeNodeParams,
  };

  const columns: DataGridCols<CocmBillingAlgorithmListQuery, ["billingCocmBillingAlgorithms"]> =
    React.useMemo(() => {
      return [
        {
          field: "name",
          headerName: t("billing:algorithmList.columns.name"),
          sortable: true,
          flex: 1,
          renderCell: (params) => {
            return <Link to={`${params.row.id}`}>{params.row.name}</Link>;
          },
        },
        {
          field: "entityId",
          headerName: t("billing:algorithmList.columns.entityId"),
          sortable: false,
          flex: 1,
          renderCell: (params) => {
            return <EntityPath entityTreeNode={params.row.entityTreeNode} includeInstitute={"when_solo"} />;
          },
        },
        {
          field: "startDate",
          headerName: t("billing:algorithmList.columns.startDate"),
          sortable: true,
          flex: 1,
          valueGetter: (_value, row) => t("common:date.tiny", { date: row.startDate }),
        },
        {
          field: "endDate",
          headerName: t("billing:algorithmList.columns.endDate"),
          sortable: true,
          flex: 1,
          valueGetter: (_value, row) => {
            if (row.endDate) {
              return t("common:date.tiny", { date: row.endDate });
            } else {
              return t("billing:algorithm.endDateActive");
            }
          },
        },
        {
          field: "codes",
          headerName: t("billing:algorithmList.columns.codes"),
          sortable: false,
          flex: 1,
          valueGetter: (_value, row) => {
            return row.codes.map((code) => code.code).join(", ");
          },
        },
      ];
    }, []);

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        queryHook={useCocmBillingAlgorithmListQuery}
        storageKey={COCM_BILLING_ALGORITHM_TABLE_STORAGE_KEY}
        queryVariables={queryVars}
        unwrapData={(response) => response?.billingCocmBillingAlgorithms || null}
        colNameToSortParam={(field) => {
          switch (field) {
            case "name":
              return CocmBillingAlgorithmSortParameter.NAME;
            case "startDate":
              return CocmBillingAlgorithmSortParameter.START_DATE;
            case "endDate":
              return CocmBillingAlgorithmSortParameter.END_DATE;
            case "status":
              return CocmBillingAlgorithmSortParameter.STATUS;
            default:
              return CocmBillingAlgorithmSortParameter.NAME;
          }
        }}
        columns={columns}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
      />
    </Paper>
  );
}

export default function AlgorithmList() {
  const { t } = useTranslation(["billing"]);
  const filters = useAlgorithmListFilters();
  return (
    <Page browserTitle={t("billing:algorithmList.pageTitle")}>
      <AlgorithmListFilters filters={filters} />
      <AlgorithmListTable filters={filters} />
    </Page>
  );
}
