import { Box, Button, Stack, TextField } from "@mui/material";
import { DiscreetModeContext as DiscreetModeContext } from "Contexts/DiscreetModeContext";
import Page from "Layout/Page";
import React, { ReactElement, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiscreetModeBanner } from "./DiscreetModeBanner";
import { ScheduleFilters, useScheduleFilters } from "./ScheduleFilters";
import SchedulingAppointments from "./SchedulingAppointments";

function Schedule(): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const discreetMode = useContext(DiscreetModeContext);
  let schedule = <RegularSchedule />;
  if (discreetMode.discreetMode) {
    schedule = <DiscreetSchedule setDiscreetMode={discreetMode.setDiscreetMode} />;
  }
  return <Page browserTitle={t("dashboard:titles.appointment")}>{schedule}</Page>;
}

type DiscreetScheduleProps = {
  setDiscreetMode: (newDiscreetMode: boolean) => void;
};
export function DiscreetSchedule(props: DiscreetScheduleProps): ReactElement {
  const { t } = useTranslation(["dashboard"]);
  const [search, setSearch] = useState("");
  const [canSearch, setCanSearch] = useState(false);
  let appointments = null;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanSearch(false);
    setSearch(event.target.value);
  };

  // We're setting up a second button here to actually a trigger search instead of
  // speed loading it as the user types because we don't want to accidentally reveal
  // a patient.
  const handleSearch = () => {
    setCanSearch(true);
  };
  if (search.length > 0 && canSearch) {
    appointments = <SchedulingAppointments search={search} />;
  }
  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={1}>
        <TextField
          onChange={handleChange}
          sx={{ width: "20em" }}
          label={t("dashboard:discreet.filterLabel")}
          variant="outlined"
        />
        <Box>
          <Button onClick={handleSearch} variant="contained" sx={{ width: "5em", marginY: "0.75em" }}>
            {t("dashboard:discreet.searchButton")}
          </Button>
        </Box>
      </Stack>

      <DiscreetModeBanner setDiscreetMode={props.setDiscreetMode} />
      {appointments}
    </Stack>
  );
}

// The default filters are only used in the case where we are coming from discreet mode.
export const DEFAULT_FILTERS: ScheduleFilters = {
  organization: { allOrganizations: true },
  provider: { me: true },
  flags: null,
  category: null,
  testClients: false,
};

function RegularSchedule(): ReactElement {
  const filterData = useScheduleFilters(DEFAULT_FILTERS);

  return (
    <Stack spacing={1}>
      <ScheduleFilters filters={filterData} />
      <SchedulingAppointments filters={filterData.filters} search={null} />
    </Stack>
  );
}

export default Schedule;
