import { Skeleton } from "@mui/material";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { useClinicalAlertHistoryQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, CareEpisodeScaleId } from "Lib/Ids";
import { arrOfN, find } from "Lib/Utils";
import React, { ReactElement } from "react";
import { Failure, RemoteData, Success } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { AlertStatusChip } from "Shared/Scale/AlertChip";
import {
  alertTitle,
  CareEpisodeClinicalAlertDetails,
  CareEpisodeClinicalAlertHistory,
  CareEpisodeClinicalAlertId,
  toCareEpisodeClinicalAlertHistoryDetails,
} from "Shared/Scale/CareEpisodeClinicalAlert";
import { responsesByParticipant } from "Shared/Scale/QuestionResponse";
import { FeedbackReportContext } from "../FeedbackReportContext";
import LevelDetail from "FeedbackReport/LevelDetail";
import { LevelHeaderLoadingIndicator } from "FeedbackReport/LevelHeader";
import DetailPaneLevelHeader from "FeedbackReport/DetailPaneLevelHeader";
import { ResponsesWithParticipantTable } from "../Shared/ResponsesTable";
import { useTranslation } from "react-i18next";

// -- TYPES ------------------------------------------------------------------

type AlertData = RemoteData<Error, CareEpisodeClinicalAlertHistory>;
export type AlertPaneProps = {
  alertHistoryId: CareEpisodeScaleId;
  careEpisodeId: CareEpisodeId;
  alertId: CareEpisodeClinicalAlertId;
  feedbackReportContext: FeedbackReportContext;
};

function AlertPaneHookWrapper(props: AlertPaneProps): ReactElement {
  const { alertHistoryId, alertId, feedbackReportContext } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useClinicalAlertHistoryQueryQuery({
      variables: {
        careEpisodeClinicalAlertHistoryId: alertHistoryId,
      },
    })
  );

  const transformed: AlertData = remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      if (result.assessmentCareEpisodeClinicalAlertHistory) {
        return Success(
          toCareEpisodeClinicalAlertHistoryDetails(
            result.assessmentCareEpisodeClinicalAlertHistory,
            feedbackReportContext.participantsByUserId
          )
        );
      } else {
        return Failure(new Error("Not Found"));
      }
    });

  return (
    <AlertPane remoteData={transformed} feedbackReportContext={feedbackReportContext} alertId={alertId} />
  );
}

function LoadingIndicator(): ReactElement {
  const rows = arrOfN(3).map((i) => {
    return (
      <tr key={i} data-testid="loading-row">
        <td>
          <Skeleton width={75} height={20} />
        </td>
        <td>
          <Skeleton width={50} height={20} />
        </td>
        <td>
          <Skeleton width={75} height={20} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <LevelDetail>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </LevelDetail>
    </div>
  );
}

function AlertValueDetails(props: { alert: CareEpisodeClinicalAlertDetails }): ReactElement {
  const { alert } = props;

  const responseTables = responsesByParticipant(alert.responses).map((data) => {
    return (
      <ResponsesWithParticipantTable participant={data.participant} responses={data.responses.toArray()} />
    );
  });

  return <>{responseTables}</>;
}

function AlertDetails(props: {
  alertHistory: CareEpisodeClinicalAlertHistory;
  alert: CareEpisodeClinicalAlertDetails;
}): ReactElement {
  const { t } = useTranslation(["report"]);
  const { alertHistory, alert } = props;

  return (
    <>
      <DetailPaneLevelHeader
        label={t("report:sections.alertDetails", {
          alertTitle: alertTitle(alertHistory.alertType),
          date: alert.date,
        })}
      >
        <AlertStatusChip alertType={alertHistory.alertType} alertStatus={alert.status} />
      </DetailPaneLevelHeader>
      <LevelDetail>
        <AlertValueDetails alert={alert} />
      </LevelDetail>
    </>
  );
}

type Props = {
  remoteData: AlertData;
  feedbackReportContext: FeedbackReportContext;
  alertId: CareEpisodeClinicalAlertId;
};

function AlertPane(props: Props): ReactElement {
  const { alertId, remoteData } = props;

  return remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (alertHistory) => {
      return find((a) => a.id === alertId, alertHistory.history).caseOf({
        Just: (alert) => <AlertDetails alertHistory={alertHistory} alert={alert} />,
        Nothing: () => <ErrorMessage message="Could not find Alert" />,
      });
    },
  });
}

export default AlertPaneHookWrapper;
export { AlertPaneHookWrapper as HookWrapper, AlertPane as Component };
