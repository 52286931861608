import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Routes, Link as RouterLink, useParams, Route } from "react-router-dom";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { InstituteSummaryQuery, useInstituteSummaryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement, useState } from "react";
import { Maybe } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { Dig } from "type-utils";
import InstituteAppointment from "./Appointments/InstituteAppointment";
import InstituteCareEpisode from "./CareEpisodes/InstituteCareEpisode";
import InstituteDetails from "./InstituteDetails";
import InstituteOrganization from "./Organizations/InstituteOrganization";
import InstituteOrganizationList from "./Organizations/InstituteOrganizationList";
import InstitutePatient from "./Patients/InstitutePatient";
import InstitutePatientList from "./Patients/InstitutePatientList";
import InstituteProvider from "./Providers/InstituteProvider";
import InstituteProviderList from "./Providers/InstituteProviderList";
import * as NEL from "Lib/NonEmptyList";
import { InstituteMeasurementBundles } from "./MeasurementBundles/InstituteMeasurementBundles";

function InstituteDetailsRoute(): ReactElement {
  const params = useParams<{ instituteId?: string }>();

  return fromNullableString<"Institute">(params.instituteId).caseOf({
    Ok: (instituteId) => {
      const { remoteData } = apolloQueryHookWrapper(
        useInstituteSummaryQuery({
          variables: {
            instituteId: instituteId,
          },
        })
      );

      return remoteData.caseOf({
        NotAsked: () => <h1>Loading</h1>,
        Loading: () => <h1>Loading</h1>,
        Failure: (error) => <h1>`Error! ${error.message}`</h1>,
        Success: (data) =>
          Maybe.fromNullable(data.institute).caseOf({
            Just: (institute) => (
              <InstituteRoutes
                institute={institute}
                instituteUri={`/app/ops/institutes/${institute.id.toString()}`}
              />
            ),
            Nothing: () => <ErrorMessage message="Invalid URL" />,
          }),
      });
    },
    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

export type InternalBreadcrumbPath = {
  text: string;
  path: string;
};

function InternalBreadcrumbs(props: {
  crumbs: Array<InternalBreadcrumbPath>;
  instituteUri: string;
  instituteName: string;
}): ReactElement {
  const crumbs = NEL.NonEmptyList({ text: props.instituteName, path: "" }, props.crumbs);
  const lastCrumb = NEL.last(crumbs);
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {crumbs
        .toArray()
        .slice(0, -1)
        .map((crumb, index) => (
          <Link
            key={`${index}-${crumb.text}-${crumb.path}`}
            underline="hover"
            component={RouterLink}
            to={props.instituteUri + crumb.path}
          >
            {crumb.text}
          </Link>
        ))}

      <Typography>{lastCrumb.text}</Typography>
    </Breadcrumbs>
  );
}

function InstituteRoutes({
  institute,
  instituteUri,
}: {
  institute: Dig<InstituteSummaryQuery, ["institute"]>;
  instituteUri: string;
}): ReactElement {
  const [subCrumbs, setSubCrumbs] = useState<Array<InternalBreadcrumbPath>>([]);

  return (
    <>
      <InternalBreadcrumbs crumbs={subCrumbs} instituteUri={instituteUri} instituteName={institute.name} />
      <Routes>
        <Route
          element={<InstitutePatientList setSubCrumbs={setSubCrumbs} instituteId={institute.id} />}
          path="/patients/"
        />
        <Route element={<InstitutePatient setSubCrumbs={setSubCrumbs} />} path="/patients/:patientId/*" />
        <Route
          element={<InstituteProviderList setSubCrumbs={setSubCrumbs} instituteId={institute.id} />}
          path="/providers/"
        />
        <Route element={<InstituteProvider setSubCrumbs={setSubCrumbs} />} path="/providers/:providerId/*" />
        <Route
          element={<InstituteOrganizationList setSubCrumbs={setSubCrumbs} instituteId={institute.id} />}
          path="/organizations/"
        />
        <Route
          element={<InstituteOrganization setSubCrumbs={setSubCrumbs} />}
          path="/organizations/:organizationId/*"
        />
        <Route
          element={<InstituteAppointment setSubCrumbs={setSubCrumbs} />}
          path="/appointments/:appointmentId/*"
        />
        <Route
          element={<InstituteCareEpisode setSubCrumbs={setSubCrumbs} />}
          path="/careEpisodes/:careEpisodeId/*"
        />
        <Route
          element={<InstituteMeasurementBundles instituteId={institute.id} />}
          path="/measurement-bundles"
        />
        <Route
          element={<InstituteDetails instituteId={institute.id} setSubCrumbs={setSubCrumbs} />}
          path="*"
        />
      </Routes>
    </>
  );
}

export default InstituteDetailsRoute;
