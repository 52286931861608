import { EditNote } from "@mui/icons-material";
import { Button, DialogContent, ListItemIcon, ListItemText, MenuItem, Stack, TextField } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { Patient, Task, useAddNoteToTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { TaskId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Form, FormOverlay, useForm, useTextField } from "Shared/Form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type AddNoteButtonProps = {
  onClick: () => void;
};

// Note that this is NOT the functionality on TaskCardBody, but instead the
// Add Note button that appears on the CollaborativeCareAppBar.
export function AddNoteButton(props: AddNoteButtonProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return (
    <Button variant="outlined" color="inherit" onClick={props.onClick} startIcon={<EditNote />}>
      {t("collaborativeCare:tasks.actions.addNote")}
    </Button>
  );
}

type AddNoteMenuItemProps = AddNoteButtonProps & {
  onClick: () => void;
};

export function AddNoteMenuItem(props: AddNoteMenuItemProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  return (
    <MenuItem onClick={props.onClick}>
      <ListItemIcon>
        <EditNote />
      </ListItemIcon>
      <ListItemText>{t("collaborativeCare:tasks.actions.addNote")}</ListItemText>
    </MenuItem>
  );
}

type AddNoteDialogProps = {
  task: PickTypename<Task, "id" | "title">;
  patient: PickTypename<Patient, "name">;
  open: boolean;
  onClose: () => void;
};

export function AddNoteDialog(props: AddNoteDialogProps) {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const title =
    t("collaborativeCare:tasks.actions.addNote") + ": " + props.task.title + " (" + props.patient.name + ")";
  return (
    <ResponsiveDialog open={props.open} onClose={() => props.onClose()} title={title}>
      <DialogContent>
        <AddNoteForm taskId={props.task.id} setShowForm={props.onClose} />
      </DialogContent>
    </ResponsiveDialog>
  );
}

type AddNoteFormProps = {
  taskId: TaskId;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddNoteForm(props: AddNoteFormProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const [addNote, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAddNoteToTask,
    useAddNoteToTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const fields = {
    text: useTextField({ required: true, errorPaths: ["text"] }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      addNote({
        variables: {
          input: {
            // Safe cast because validation has passed when we get here
            text: fields.text.value as string,
            taskId: props.taskId,
          },
        },
      });
    },
    onSuccess: () => {
      // Let the success overlay linger for a bit so they get the feedback it worked (the note will also show up
      // inline), then clear the form.
      setTimeout(() => {
        props.setShowForm(false);
      }, 650);
    },
  });

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay
        response={remoteData}
        errorMessage={form.globalError || t("collaborativeCare:tasks.genericNoteFormError")}
      />
      <Stack direction="column" spacing={1}>
        <TextField
          label={t("collaborativeCare:tasks.newNote")}
          multiline
          minRows={5}
          value={fields.text.value}
          onChange={fields.text.onChange}
          error={fields.text.error}
          helperText={fields.text.helperText}
          sx={{ flexGrow: 1 }}
        />
        <Stack direction="row-reverse">
          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="secondary"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("collaborativeCare:tasks.actions.addNote")}
          </ButtonWithSpinner>
        </Stack>
      </Stack>
    </Form>
  );
}
