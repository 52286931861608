import { Chip, ChipProps } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useProviderActiveDecisionsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";

export function useActiveProviderDecisionCount(): number {
  const { remoteData } = apolloQueryHookWrapper(useProviderActiveDecisionsQuery());

  return remoteData.caseOf({
    NotAsked: () => 0,
    Loading: () => 0,
    Failure: (_error) => {
      // I don't love silently discarding errors but there isn't really anything we can do about it here. Badge
      // isn't a spot for error text, and we don't have any sort of global error display toast or something.
      return 0;
    },
    Success: (response) => {
      return response.decisionSupportMyProviderNotifications.totalCount;
    },
  });
}

// We want callers of the chip to be able to use it like a builtin chip, but we have control over the content
type ChipPropsWithoutLabel = Omit<ChipProps, "label">;

export function ActiveDecisionsChip(props: ChipPropsWithoutLabel): ReactElement {
  const count = useActiveProviderDecisionCount();

  return <Chip color={props.color || "primary"} {...props} label={count} />;
}
