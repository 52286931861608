import React, { ReactElement } from "react";
import {
  InstituteSettingsQuery,
  useInstituteSettingsQuery,
  useInstituteUpdateMeasurementAllowedMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper, apolloMutationHookWrapper } from "Api/GraphQL";
import { InstituteId } from "Lib/Ids";
import { Maybe, RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import Page from "Layout/Page";
import ShadowSettingsLink from "Settings/ShadowSettingsLink";
import UnderMeasurementChip from "Ops/Institutes/InstituteDetails/UnderMeasurementChip";
import { useTranslation } from "react-i18next";
import ImmediateSettingsSwitch from "Shared/ImmediateSettingSwitch";
import { Dig } from "type-utils";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { refetchQueries } from "Lib/RefetchQueries";

export type InstituteSettingsProps = {
  instituteId: InstituteId;
};

function InstituteSettingsHookWrapper(props: InstituteSettingsProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useInstituteSettingsQuery({
      variables: {
        instituteId: props.instituteId,
      },
    })
  );

  return <InstituteSettings remoteData={remoteData} />;
}

type InstituteSettingsDataProps = {
  remoteData: RemoteData<ApolloError, InstituteSettingsQuery>;
};

function InstituteSettings(props: InstituteSettingsDataProps): ReactElement {
  return props.remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) =>
      Maybe.fromNullable(result.institute).caseOf({
        Nothing: () => <DefaultError error="Unknown institute" />,
        Just: (institute) => (
          <InstituteSettingsElement
            instituteId={institute.id}
            measurementAllowed={institute.measurementAllowed}
            measureStarting={institute.measureStarting}
            organizations={institute.organizations.nodes}
          />
        ),
      }),
  });
}

export type InstituteMeasurementAllowedSwitchProps = {
  instituteId: InstituteId;
  measurementAllowed: boolean;
};

function InstituteMeasurementAllowedSwitch(props: InstituteMeasurementAllowedSwitchProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const [updateMeasurementAllowed, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsInstituteUpdateMeasurementAllowed,
    useInstituteUpdateMeasurementAllowedMutation({
      refetchQueries: refetchQueries("instituteSettings"),
    })
  );
  const partialUpdateMeasurementAllowed = (allowed: boolean) => {
    updateMeasurementAllowed({
      variables: {
        input: {
          instituteId: props.instituteId,
          measurementAllowed: allowed,
        },
      },
    });
  };
  return (
    <ImmediateSettingsSwitch
      value={props.measurementAllowed}
      label={t("settings:measurementAllowedSwitch.label")}
      description={t("settings:measurementAllowedSwitch.instituteTooltip")}
      remoteData={remoteData}
      query={partialUpdateMeasurementAllowed}
    />
  );
}

type OrganizationList = Dig<InstituteSettingsQuery, ["institute", "organizations", "nodes"]>;

export type InstituteSettingsElementProps = {
  instituteId: InstituteId;
  measurementAllowed: boolean;
  measureStarting: Date | null;
  organizations: OrganizationList;
};

function InstituteSettingsElement(props: InstituteSettingsElementProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  const title = t("settings:institute.instituteSettingsLabel");

  const measureStartingText = props.measureStarting
    ? t("settings:institute.measureStarting", { date: props.measureStarting })
    : t("settings:institute.measureStartingImmediately");

  return (
    <Page browserTitle={title}>
      <Stack direction="column" spacing={1}>
        <Card>
          <CardHeader
            title={title}
            subheader={
              <ShadowSettingsLink shadowSettingUrl={window.location.href}>
                {props.instituteId.toString()}
              </ShadowSettingsLink>
            }
            action={
              <UnderMeasurementChip
                measurementAllowed={props.measurementAllowed}
                measureStarting={props.measureStarting}
              />
            }
          ></CardHeader>
          <CardContent>
            <Stack direction={"column"}>
              <InstituteMeasurementAllowedSwitch
                measurementAllowed={props.measurementAllowed}
                instituteId={props.instituteId}
              />
              <Typography>{measureStartingText}</Typography>
            </Stack>
          </CardContent>
        </Card>
        <OrganizationList organizations={props.organizations} />
      </Stack>
    </Page>
  );
}

type OrganizationListProps = {
  organizations: OrganizationList;
};

function OrganizationList(props: OrganizationListProps) {
  const { t } = useTranslation(["settings"]);
  const organizations = [...props.organizations]
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    })
    .map((organization) => {
      return (
        <Typography key={organization.id.toString()}>
          <Link component={RouterLink} to={"organization/" + organization.id}>
            {organization.name}
          </Link>
        </Typography>
      );
    });

  return (
    <Card>
      <CardHeader title={t("settings:institute.organizationSettingsLabel")}></CardHeader>
      <CardContent>
        <Stack direction="column" spacing={1}>
          {organizations}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default InstituteSettingsHookWrapper;
export { InstituteSettingsHookWrapper as HookWrapper, InstituteSettings as Component };
