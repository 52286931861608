import { Route, Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import NotFound from "./NotFound";

type RouterWithNotFoundProps = {
  children: React.ReactNode;
};

function RouterWithNotFound(props: RouterWithNotFoundProps): ReactElement {
  const { children, ...noChildren } = props;
  return (
    <Routes {...noChildren}>
      {children}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RouterWithNotFound;
