import { Alert, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EntityTreeNodeParams,
  ImplementationTarget,
  ImplementationTargetType,
  useExistingImplementationTargetCheckQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ImplementationTargetId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import Link from "MDS/Link";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";

export type ExistingTargetDetails = Pick<ImplementationTarget, "id" | "name">;

/**
 * We are only allowed one implementation target at a time.  This checks for an existing
 * one with those params to stop you and give you the option to go there instead.
 * @param id
 * @param targetType
 * @param startDate
 * @param entityTreeNodeParams
 * @returns
 */
export function useCheckExistingImplementationTarget(
  id: ImplementationTargetId | null,
  targetType: ImplementationTargetType | null,
  startDate: Date | null,
  entityTreeNodeParams: EntityTreeNodeParams | null
): RemoteData<Error, ExistingTargetDetails | null> {
  const { remoteData } = apolloQueryHookWrapper(
    useExistingImplementationTargetCheckQuery({
      variables: {
        targetType,
        date: assertNonNull(startDate), // will be skipped if not present
        entityQuery: {
          entityTreeNodeParams: assertNonNull(
            // entityTreeNodeParams ? entityTreeNodeToParams(entityTreeNodeParams) : null
            entityTreeNodeParams
          ),
          exact: true,
        },
      },
      skip: !startDate || !entityTreeNodeParams || !targetType,
    })
  );

  return remoteData.map((result) => {
    const existing = result.implementationTargets?.nodes[0] ?? null;

    if (id && existing?.id === id) {
      return null;
    } else {
      return existing;
    }
  });
}

type ExistingImplementationTargetWarningProps = {
  existing: RemoteData<Error, ExistingTargetDetails | null>;
};

export default function ExistingImplementationTargetWarning(
  props: ExistingImplementationTargetWarningProps
): ReactElement | null {
  const { t } = useTranslation(["implementation"]);
  return props.existing.caseOf({
    Success: (result) => {
      if (result?.id) {
        return (
          <Alert severity="warning">
            <Typography>{t("implementation:form.existingTarget")}</Typography>
            <Link to={`../${result.id}`}>{result.name}</Link>
          </Alert>
        );
      }

      return null;
    },

    _: () => {
      return null;
    },
  });
}
