import { Divider, Stack } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import UnfilledAsessmentInvitations from "./UnfilledAssessmentInvitations";

function MyTasks(): ReactElement {
  return (
    <Page browserTitle="My Tasks">
      My Tasks
      <Stack divider={<Divider />}>
        <UnfilledAsessmentInvitations></UnfilledAsessmentInvitations>
      </Stack>
    </Page>
  );
}

export default MyTasks;
