import { ComponentsProps, ComponentsOverrides } from "@mui/material";
import palette from "../palette";

const styleOverrides: ComponentsOverrides["MuiLink"] = {
  root: {
    cursor: "pointer",
    color: palette.primary.main,
    textDecorationColor: palette.primary.main,

    "&:hover": {
      color: palette.primary.light,
    },
  },
};

const defaultProps: ComponentsProps["MuiLink"] = {};

const override = {
  defaultProps,
  styleOverrides,
};

export default override;
