import { CareEpisodeId } from "Lib/Ids";
import * as React from "react";
import FeedbackReportProgressNoteText from "./FeedbackReportProgressNoteText";
import { useTranslation } from "react-i18next";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { DialogContent } from "@mui/material";

type FeedbackReportProgressNoteProps = {
  show: boolean;
  onClose: () => void;
  careEpisodeId: CareEpisodeId;
};

export default function FeedbackReportProgressNoteModal(props: FeedbackReportProgressNoteProps) {
  const { show, onClose, careEpisodeId } = props;
  const { t } = useTranslation(["report"]);

  return (
    <ResponsiveDialog
      open={show}
      onClose={onClose}
      aria-labelledby="modal-progress-note-title"
      title={t("report:progressNote.title")}
    >
      <DialogContent>
        <FeedbackReportProgressNoteText careEpisodeId={careEpisodeId} />
      </DialogContent>
    </ResponsiveDialog>
  );
}
