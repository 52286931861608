import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { TimeBasedAggregationType } from "GeneratedGraphQL/SchemaAndOperations";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { enumFromStringValue } from "Shared/Enum";
import { FieldMethods } from "Shared/Form";
import React from "react";
import { useTranslation } from "react-i18next";

type TimeBasedAggregationForm = {
  timeBasedAggregationType: FieldMethods<TimeBasedAggregationType, TimeBasedAggregationType>;
};

export default function TimeBasedAggregationForm(props: TimeBasedAggregationForm) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const enumValue = enumFromStringValue<TimeBasedAggregationType>(TimeBasedAggregationType, value);
    props.timeBasedAggregationType.onChange(enumValue || TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS);
  };

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.timeBased.aggregation.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.timeBased.aggregation.helperText")} />
      <RadioGroup value={props.timeBasedAggregationType.value} onChange={onChange}>
        <FormControlLabel
          value={TimeBasedAggregationType.PERCENTAGE_OF_PATIENTS}
          control={<Radio />}
          label={t("outcomes:create.timeBased.aggregation.percentageOfPatients")}
        />
        <FormControlLabel
          value={TimeBasedAggregationType.NUMBER_OF_PATIENTS}
          control={<Radio />}
          label={t("outcomes:create.timeBased.aggregation.numberOfPatients")}
        />
      </RadioGroup>
    </FormControl>
  );
}
