import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AppLayoutUserMenuItem } from "Layout/AppLayout/AppLayout";
import React, { ReactElement, useContext } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { TestPatientViewabilityContext } from "Contexts/TestPatientViewabilityContext";
import { useTranslation } from "react-i18next";

export default function TestPatientViewabilityToggle(_props: Record<string, never>): ReactElement {
  // We're using a context and session storage to track this, so hook that up here.
  const testPatientViewability = useContext(TestPatientViewabilityContext);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    testPatientViewability.setTestPatientViewability(checked);
  };

  return (
    <FormControlLabel
      control={<Switch onChange={onChange} checked={testPatientViewability.testPatientViewability} />}
      label=""
      sx={{ marginLeft: "0.75em", marginTop: "-0.3em", marginRight: "0em", maxHeight: "29px" }}
    />
  );
}

export function TestPatientViewabilityToggleUserItemDefinition(): AppLayoutUserMenuItem {
  const { t } = useTranslation(["collaborativeCare"]);
  return {
    icon: <PersonSearchIcon />,
    label: t("collaborativeCare:careManagerDashboard.testPatientViewability"),
    additionalElement: <TestPatientViewabilityToggle />,
  };
}
