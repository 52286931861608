import React from "react";
import { Route, Routes } from "react-router-dom";
import { DemographicsRoute } from "./Demographics/DemographicsRoute";
import { GoalCreateRoute } from "./Goals/GoalCreateRoute";
import { GoalRoute } from "./Goals/GoalRoute";
import { AlertHistoryRoute } from "./Alerts/AlertHistoryRoute";
import { AlertRoute } from "./Alerts/AlertRoute";
import { ScaleGroupHistoryRoute } from "./ScaleHistory/ScaleGroupHistoryRoute";
import { ScaleHistoryRoute } from "./ScaleHistory/ScaleHistoryRoute";
import { ScaleGroupScoreRoute } from "./ScaleScore/ScaleGroupScoreRoute";
import { ScaleScoreRoute } from "./ScaleScore/ScaleScoreRoute";
import { FeedbackReportContext } from "./FeedbackReportContext";
import { CareEpisodeDetail } from "Shared/CareEpisode";
import { ProviderSummary } from "Shared/Provider";
import { PatientConditionDetails } from "./Demographics/PatientConditionModal";

// Note that the routes in this file must be the same as the routes in
// src/CareUnits/CareEpisodes/FeedbackReport/FeedbackReportPage.tsx so that deep links to existing reports won't break.

type DetailRouteProps = {
  patient: React.ComponentProps<typeof DemographicsRoute>["patient"];
  careEpisode: CareEpisodeDetail;
  careTeam: ReadonlyArray<ProviderSummary>;
  patientConditions: ReadonlyArray<PatientConditionDetails>;
  feedbackReportContext: FeedbackReportContext;
};

export const FeedbackReportDetailRoutes = (props: DetailRouteProps) => {
  const { careEpisode, feedbackReportContext } = props;

  // <Route path="/" element={<Home />} />
  return (
    <Routes>
      <Route
        path="/"
        element={
          <DemographicsRoute
            patient={props.patient}
            careEpisode={careEpisode}
            careTeam={props.careTeam}
            feedbackReportContext={feedbackReportContext}
            patientConditions={props.patientConditions}
          />
        }
      />
      <Route
        element={<GoalCreateRoute feedbackReportContext={feedbackReportContext} />}
        path="/goals/create"
      />
      <Route element={<GoalRoute feedbackReportContext={feedbackReportContext} />} path="/goals/:goalId" />
      <Route
        element={<AlertHistoryRoute feedbackReportContext={feedbackReportContext} />}
        path="/alert-history/:alertHistoryId"
      />
      <Route
        element={<AlertRoute careEpisodeId={careEpisode.id} feedbackReportContext={feedbackReportContext} />}
        path="alert-history/:alertHistoryId/alert/:alertId"
      />
      <Route
        element={
          <ScaleGroupHistoryRoute
            careEpisodeId={careEpisode.id}
            feedbackReportContext={feedbackReportContext}
          />
        }
        path="scale-group-history/:scaleGroupId"
      />
      <Route
        element={
          <ScaleGroupScoreRoute
            careEpisodeId={careEpisode.id}
            feedbackReportContext={feedbackReportContext}
          />
        }
        path="scale-group-history/:scaleGroupId/scale-group-score/:scaleGroupScoreId"
      />
      <Route
        element={
          <ScaleHistoryRoute careEpisodeId={careEpisode.id} feedbackReportContext={feedbackReportContext} />
        }
        path="scale-administration-history/:scaleId"
      />
      <Route
        element={
          <ScaleScoreRoute careEpisodeId={careEpisode.id} feedbackReportContext={feedbackReportContext} />
        }
        path="scale-administration-history/:scaleId/scale-score/:scaleScoreId"
      />
    </Routes>
  );
};
