import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import * as NEL from "Lib/NonEmptyList";
import React, { ReactElement } from "react";
import {
  quantizedCompletionDate,
  QuantizedScaleCompletion,
  ScaleCompletion,
} from "Shared/Scale/ScaleCompletionHistory";
import { XYChart } from "@visx/xychart";
import ScaleThresholdChartBackground from "Shared/Viz/Shared/ScaleThresholdChartBackground";
import { CompletionHistorySeries } from "Shared/Viz/Shared/CompletionHistorySeries";
import { Nothing } from "seidr";
import { HoverCircleSize } from "Shared/Viz/Shared/HoverCircle";

type CompletionCompareGraphProps = {
  width: number;
  height: number;
  completionHistory: NEL.NonEmptyList<QuantizedScaleCompletion>;
  completionRoute: (completion: ScaleCompletion) => FeedbackReportRoute;
};

export function CompletionCompareGraph(props: CompletionCompareGraphProps): ReactElement {
  const { completionHistory, completionRoute, width, height } = props;

  return (
    <XYChart
      captureEvents={false}
      yScale={{ type: "linear", domain: [0, 0] }}
      xScale={{
        type: "time",
        domain: [
          quantizedCompletionDate(NEL.head(completionHistory)),
          quantizedCompletionDate(NEL.last(completionHistory)),
        ],
      }}
      width={width}
      height={height}
      margin={{
        top: HoverCircleSize.large.radius,
        bottom: HoverCircleSize.large.radius,
        right: HoverCircleSize.large.radius,
        left: HoverCircleSize.large.radius,
      }}
    >
      <ScaleThresholdChartBackground scale={Nothing()} />
      <CompletionHistorySeries completionHistory={completionHistory} completionRoute={completionRoute} />
    </XYChart>
  );
}
