import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { toViewableDomainSections } from "FeedbackReport/DomainSection";
import { norseCompareGraphsData } from "FeedbackReport/OverviewPane/NorseSection";
import { format } from "date-fns";
import { CareEpisodeSpecialSectionType } from "GeneratedGraphQL/SchemaAndOperations";
import Id from "Lib/Id";
import { CareEpisodeComputedValueId, ScaleGroupId } from "Lib/Ids";
import * as NEL from "Lib/NonEmptyList";
import React, { ReactElement } from "react";
import { Just, Maybe, Nothing, RemoteData, Success } from "seidr";
import EmptyState from "Shared/EmptyState";
import { ParticipantSummary } from "Shared/Participant";
import * as CareEpisodeComputedValueDetails from "Shared/Scale/CareEpisodeComputedValueDetails";
import { ConstructSection, SpecialSection } from "Shared/Scale/Constructs";
import * as Goal from "Shared/Scale/Goal";
import * as Scale from "Shared/Scale/Scale";
import { ScaleCompletion } from "Shared/Scale/ScaleCompletionHistory";
import * as ScaleGroup from "Shared/Scale/ScaleGroup";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { Trend } from "Shared/Scale/Trend";
import { SumType } from "sums-up";
import { FeedbackReportContext, getParticipantByUserId } from "../../FeedbackReportContext";
import * as Routing from "../../FeedbackReportRouting";
import CompareGraphRow from "./CompareGraph";
import ScaleSelectors from "./ScaleSelectors";

export type CompareGraphSummary = {
  id: string;
  name: string;
  participant: ParticipantSummary;
  trend: Maybe<Trend>;
  graphData: CompareGraphData;
  routeToScale: Routing.FeedbackReportRoute;
};

class CompareGraphData extends SumType<{
  Completion: [
    {
      scale: Scale.ScaleWithScorer;
      completions: ReadonlyArray<CareEpisodeComputedValueDetails.CareEpisodeUnscoredComputedValueDetails>;
      dates: NEL.NonEmptyList<Date>;
      routeToScore: (completion: ScaleCompletion) => Routing.FeedbackReportRoute;
    }
  ];
  Categorical: [
    {
      scale: Scale.ScaleWithScorer;
      values: ReadonlyArray<CareEpisodeComputedValueDetails.CareEpisodeCategoricalComputedValueDetails>;
      dates: NEL.NonEmptyList<Date>;
      routeToScore: (s: { id: Id<string> }) => Routing.FeedbackReportRoute;
    }
  ];
  Severity: [
    {
      // It could be a goal, which doesn't have a scale
      scale: Maybe<Scale.ScaleWithNumericalScorer>;
      values: ReadonlyArray<
        CareEpisodeComputedValueDetails.CareEpisodeComputedValueDetails | Goal.GoalAnswer
      >;
      dates: NEL.NonEmptyList<Date>;
      valueRange: [number, number];
      routeToScore: (s: { id: Id<string> }) => Routing.FeedbackReportRoute;
    }
  ];
}> {
  public static fromSeverityScale = (
    hx: ScaleScorerHistory,
    feedbackReportContext: FeedbackReportContext,
    groupId?: ScaleGroupId
  ) => {
    const getRoute = groupId
      ? (s: { id: CareEpisodeComputedValueId }) =>
          Routing.scaleGroupScoreRoute(feedbackReportContext, groupId, hx.participant.participantId, s.id)
      : (s: { id: CareEpisodeComputedValueId }) =>
          Routing.scaleScoreRoute(feedbackReportContext, hx.scale.id, s.id);

    if (Scale.isUnscored(hx.scale.scorer)) {
      return new CompareGraphData("Completion", {
        scale: hx.scale,
        completions:
          // Type system makes it difficult to express these joint constraints that the
          hx.scores as ReadonlyArray<CareEpisodeComputedValueDetails.CareEpisodeUnscoredComputedValueDetails>,
        dates: feedbackReportContext.administrationDates,
        routeToScore: getRoute,
      });
    }

    if (Scale.scaleIsNumerical(hx.scale)) {
      return new CompareGraphData("Severity", {
        scale: Just(hx.scale),
        values:
          hx.scores as ReadonlyArray<CareEpisodeComputedValueDetails.CareEpisodeNumericalComputedValueDetails>,
        dates: feedbackReportContext.administrationDates,
        valueRange: Scale.parseBoundRange(hx.scale.scorer),
        routeToScore: getRoute,
      });
    }

    if (Scale.isCategorical(hx.scale.scorer)) {
      return new CompareGraphData("Categorical", {
        scale: hx.scale,
        values:
          hx.scores as ReadonlyArray<CareEpisodeComputedValueDetails.CareEpisodeCategoricalComputedValueDetails>,
        dates: feedbackReportContext.administrationDates,
        routeToScore: getRoute,
      });
    }

    // Expressing the fact that categorical has no value means that this is not technically
    // exhaustive in the type system, but we shouldn't be able to get hre.
    throw new Error("This state should be unreachable");
  };

  public static fromGoal = (goal: Goal.Goal, feedbackReportContext: FeedbackReportContext) => {
    return new CompareGraphData("Severity", {
      scale: Nothing(),
      values: goal.answers,
      dates: feedbackReportContext.administrationDates,
      valueRange: Goal.goalBounds(goal),
      routeToScore: () => Routing.goalRoute(feedbackReportContext, goal.id),
    });
  };
}

function toCompareGraphSummary(
  history: ScaleScorerHistory,
  feedbackReportContext: FeedbackReportContext,
  groupId?: ScaleGroupId
): CompareGraphSummary {
  const id = history.scale.id.toString().concat(history.participant.participantId.toString());
  return {
    id,
    trend: Nothing(),
    name: Scale.scaleFriendlyName(history.scale),
    participant: history.participant,
    routeToScale: groupId
      ? Routing.scaleGroupHistoryRoute(feedbackReportContext, groupId, history.participant.participantId)
      : Routing.scaleAdministrationHistoryRoute(feedbackReportContext, history.scale.id),
    graphData: CompareGraphData.fromSeverityScale(history, feedbackReportContext, groupId),
  };
}

type Props = {
  constructs: RemoteData<Error, ReadonlyArray<ConstructSection>>;
  specialSectionsData: RemoteData<Error, ReadonlyArray<SpecialSection>>;
  goals: RemoteData<Error, ReadonlyArray<Goal.Goal>>;
  feedbackReportContext: FeedbackReportContext;
  targetScaleSections: RemoteData<Error, ReadonlyArray<ScaleSectionSummary>>;
  selectedScales: Array<string>;
  setSelectedScales: (newValue: Array<string>) => void;
};

function CompareGraphs(props: Props): ReactElement {
  const {
    constructs,
    goals,
    feedbackReportContext,
    targetScaleSections,
    specialSectionsData,
    selectedScales,
    setSelectedScales,
  } = props;

  const data = targetScaleSections.flatMap((targetScaleSections) => {
    return goals.flatMap((goals) => {
      return specialSectionsData.flatMap((specialSections) => {
        return constructs.flatMap((constructs) => {
          const targetSelectors = [
            {
              name: "Treatment Response",
              id: "Treatment Response",
              scales: getSections(targetScaleSections, feedbackReportContext),
            },
          ];
          const goalsSelectors = [
            {
              name: "Goals / Problems",
              id: "goals",
              scales: goals.map((goal) => {
                const scale: CompareGraphSummary = {
                  id: goal.id.toString(),
                  name: goal.patientText,
                  routeToScale: Routing.goalRoute(feedbackReportContext, goal.id),
                  participant: getParticipantByUserId(feedbackReportContext, goal.user.id).getOrElse(
                    NEL.head(feedbackReportContext.participants)
                  ),
                  trend: Nothing(),
                  graphData: CompareGraphData.fromGoal(goal, feedbackReportContext),
                };
                return {
                  scale,
                  subScales: [],
                };
              }),
            },
          ];
          const constructSelectors = toViewableDomainSections(constructs).map((domain) => {
            const name = domain.domain.caseOf({
              Just: (d) => d.name,
              Nothing: () => "Other",
            });
            const id = domain.domain.caseOf({
              Just: (d) => "constructs" + d.id,
              Nothing: () => "constructs+++Other",
            });
            const sections = getSections(
              domain.constructs.flatMap((dat) => dat.scaleSections),
              feedbackReportContext
            );
            return {
              id,
              name,
              scales: sections,
            };
          });
          const specialSectionSelectors = specialSections.flatMap((section) => {
            switch (section.sectionType) {
              case CareEpisodeSpecialSectionType.NORSE:
                return norseCompareGraphsData(section).map((norseSection) => {
                  return {
                    ...norseSection,
                    scales: getSections(norseSection.scales, feedbackReportContext),
                  };
                });
            }
          });

          const allSelectors = targetSelectors
            .concat(goalsSelectors)
            .concat(specialSectionSelectors)
            .concat(constructSelectors);

          const allScales = allSelectors
            .flatMap((section) => {
              return section.scales.flatMap((selectorGroup) =>
                selectorGroup.subScales.concat([selectorGroup.scale])
              );
            })
            .reduce<Record<string, CompareGraphSummary>>(
              (acc, item) => ((acc[item.id.toString()] = item), acc),
              {}
            );

          return Success(
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <ScaleSelectors
                  administrationDates={feedbackReportContext.administrationDates}
                  scaleSelectorGroups={allSelectors}
                  selectedScales={selectedScales}
                  toggleSelectedScale={(scaleId: string, selected: boolean) => {
                    if (selected) {
                      setSelectedScales(selectedScales.concat([scaleId]));
                    } else {
                      setSelectedScales(selectedScales.filter((id) => id !== scaleId));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <LoadedCompareGraphs
                  administrationDates={feedbackReportContext.administrationDates}
                  selectedScales={selectedScales.reduce((acc, id) => {
                    const scale = allScales[id];
                    if (scale) {
                      return [...acc, scale];
                    }
                    return acc;
                  }, [])}
                />
              </Grid>
            </Grid>
          );
        });
      });
    });
  });

  return data.caseOf({
    Success: (fields) => fields,
    NotAsked: () => <h1>Not Asked</h1>,
    Failure: () => <h1>Not Asked</h1>,
    Loading: () => <h1>Loading</h1>,
  });
}

function getSections(
  scaleSections: ReadonlyArray<ScaleSectionSummary>,
  feedbackReportContext: FeedbackReportContext
): ReadonlyArray<{
  scale: CompareGraphSummary;
  subScales: Array<CompareGraphSummary>;
}> {
  return scaleSections.flatMap((section) => {
    return section.caseOf({
      IndividualScaleSection: (indy) => [
        {
          scale: toCompareGraphSummary(indy, feedbackReportContext),
          subScales: [],
        },
      ],

      ScaleGroupSection: (group) =>
        ScaleGroup.groupByDisplayableScale(group).map((scaleWithSubScales) => ({
          scale: toCompareGraphSummary(scaleWithSubScales.parentScale, feedbackReportContext),
          subScales: scaleWithSubScales.subScales
            .map((subScale) => toCompareGraphSummary(subScale, feedbackReportContext))
            .sort((a, b) => a.name.localeCompare(b.name)),
        })),
    });
  });
}

const StyledEmptyStateWrapper = styled("div")(({ theme }) => ({
  textAlign: "center",
  marginTop: theme.spacing(3),
}));

type LoadedProps = {
  selectedScales: Array<CompareGraphSummary>;
  administrationDates: NEL.NonEmptyList<Date>;
};

function LoadedCompareGraphs({ administrationDates, selectedScales }: LoadedProps): ReactElement {
  if (selectedScales.length === 0) {
    return (
      <StyledEmptyStateWrapper>
        <EmptyState text="Select scales to compare from the list on the left" />
      </StyledEmptyStateWrapper>
    );
  }
  const compareContent = selectedScales.map((selectedScale) => (
    <CompareGraphRow selectedGraph={selectedScale} key={selectedScale.id} dates={administrationDates} />
  ));
  const header = (
    <tr>
      <td>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box>{format(NEL.head(administrationDates), "MMM dd")}</Box>

          {administrationDates.length > 1 ? (
            <Box>{format(NEL.last(administrationDates), "MMM dd")}</Box>
          ) : null}
        </Box>
      </td>
      <td></td>
    </tr>
  );
  return (
    <table>
      <thead>{header}</thead>

      <tbody>{compareContent}</tbody>
    </table>
  );
}

export default CompareGraphs;
