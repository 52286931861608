import { Checkbox, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement } from "react";
import { QuantizedScaleCompletionsHeatmap } from "Shared/Scale/ScaleCompletionsHeatmapWithChip";
import ParticipantAvatar from "../../ParticipantAvatar";
import SeverityHeatmap from "Shared/Severity/SeverityHeatmap";
import { CompareGraphSummary } from "./CompareGraphs";
import { truncate } from "Lib/Utils";
import * as NEL from "Lib/NonEmptyList";
import { toQuantizedScaleCompletions } from "Shared/Scale/ScaleCompletionHistory";

const StyledParticipantCell = styled("td")(({ theme }) => ({
  paddingRight: theme.spacing(0.25),
}));

type ScaleSelectorGroup = {
  id: string;
  name: string;
  scales: ReadonlyArray<ScaleSelectorScale>;
};

type ScaleSelectorScale = {
  scale: CompareGraphSummary;
  subScales: ReadonlyArray<CompareGraphSummary>;
};

type ScaleSelectorProps = {
  graphData: CompareGraphSummary;
  showParticipant: boolean;
  toggleSelectedScale: (scaleId: string, selected: boolean) => void;
  selected: boolean;
  isSubScale: boolean;
  administrationDates: NEL.NonEmptyList<Date>;
};

const GRAPH_DEFAULTS = { height: 24, maxWidth: 200, idealColumnWidth: 10 };
function ScaleSelector({
  graphData,
  toggleSelectedScale,
  selected,
  showParticipant,
  isSubScale,
  administrationDates,
}: ScaleSelectorProps): ReactElement {
  const heatmap = graphData.graphData.caseOf({
    Severity: (sev) => (
      <SeverityHeatmap {...GRAPH_DEFAULTS} dates={administrationDates} history={sev.values} />
    ),
    Categorical: (categorical) => (
      <SeverityHeatmap {...GRAPH_DEFAULTS} dates={administrationDates} history={categorical.values} />
    ),
    Completion: (comp) => (
      <QuantizedScaleCompletionsHeatmap
        {...GRAPH_DEFAULTS}
        completions={toQuantizedScaleCompletions(administrationDates, comp.completions)}
      />
    ),
  });
  const participant = showParticipant ? <ParticipantAvatar participant={graphData.participant} /> : null;

  const name =
    graphData.name.length > 20 ? (
      <Tooltip title={graphData.name}>
        <span>{truncate(20, graphData.name)}</span>
      </Tooltip>
    ) : (
      <span>{graphData.name}</span>
    );

  const StyledTD = styled("td")<{ isSubScale: boolean }>(({ theme, isSubScale }) =>
    isSubScale ? { paddingLeft: theme.spacing(1) } : {}
  );

  return (
    <tr>
      <StyledTD isSubScale={isSubScale}>
        <Checkbox checked={selected} onChange={(_e, checked) => toggleSelectedScale(graphData.id, checked)} />
      </StyledTD>
      <StyledParticipantCell>{participant}</StyledParticipantCell>
      <td>{name}</td>
      <td>{heatmap}</td>
    </tr>
  );
}
type ScaleSelectorGroupCProps = {
  group: ScaleSelectorGroup;
  selectedScales: Array<string>;
  toggleSelectedScale: (scaleId: string, selected: boolean) => void;
  administrationDates: NEL.NonEmptyList<Date>;
};

function ScaleSelectorGroupC({
  group,
  selectedScales,
  toggleSelectedScale,
  administrationDates,
}: ScaleSelectorGroupCProps): ReactElement {
  return (
    <>
      <tr>
        <td colSpan={4}>
          <Typography variant="h2">{group.name}</Typography>
        </td>
      </tr>
      {group.scales.map((scaleGroup) => {
        return (
          <React.Fragment key={scaleGroup.scale.id}>
            <ScaleSelector
              selected={selectedScales.includes(scaleGroup.scale.id)}
              toggleSelectedScale={toggleSelectedScale}
              graphData={scaleGroup.scale}
              showParticipant={true}
              isSubScale={false}
              administrationDates={administrationDates}
            />
            {scaleGroup.subScales.map((subscale) => {
              return (
                <ScaleSelector
                  key={subscale.id}
                  selected={selectedScales.includes(subscale.id)}
                  toggleSelectedScale={toggleSelectedScale}
                  graphData={subscale}
                  showParticipant={true}
                  isSubScale={true}
                  administrationDates={administrationDates}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}

type ScaleSelectorsProps = {
  scaleSelectorGroups: ReadonlyArray<ScaleSelectorGroup>;
  selectedScales: Array<string>;
  toggleSelectedScale: (scaleId: string, selected: boolean) => void;
  administrationDates: NEL.NonEmptyList<Date>;
};
function ScaleSelectors({
  toggleSelectedScale,
  scaleSelectorGroups,
  selectedScales,
  administrationDates,
}: ScaleSelectorsProps): ReactElement {
  const content = scaleSelectorGroups.map((group) => (
    <ScaleSelectorGroupC
      key={group.id}
      group={group}
      toggleSelectedScale={toggleSelectedScale}
      selectedScales={selectedScales}
      administrationDates={administrationDates}
    />
  ));

  return (
    <table>
      <tbody>{content}</tbody>
    </table>
  );
}

export default ScaleSelectors;
