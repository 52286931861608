import React, { ReactElement, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import Add from "@mui/icons-material/Add";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisodeTreatmentTrackStatus,
  TreatmentTrack,
  TreatmentTracksByStatusQuery,
  TreatmentTrackStatus,
  useCreateCareEpisodeTreatmentTrackMutation,
  useTreatmentTracksByStatusQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Divider, Typography } from "@mui/material";
import Id, { fromNullableString } from "Lib/Id";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import CareEpisodeDetailsTreatmentTracksList from "./CareEpisodeDetailsTreatmentTracksList";
import { TreatmentTrackId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";

type CareEpisodeDetailsTreatmentTracksBoxHookWrapperProps = {
  careEpisodeId: Id<"CareEpisode">;
};
export default function CareEpisodeDetailsTreatmentTracksBoxHookWrapper(
  props: CareEpisodeDetailsTreatmentTracksBoxHookWrapperProps
): ReactElement {
  const [selectedTrack, setSelectedTrack] = useState<TreatmentTrackId>();

  const handleAddTracks = function () {
    if (selectedTrack) {
      createCareEpisodeTreatmentTrack({
        variables: {
          input: {
            careEpisodeId: props.careEpisodeId,
            treatmentTrackId: selectedTrack,
            status: CareEpisodeTreatmentTrackStatus.ACTIVE,
            enrollmentDate: new Date(),
          },
        },
      });
    }

    setSelectedTrack(undefined);
  };

  // Query: What tracks are available at all?
  const { remoteData: availableRemoteData } = apolloQueryHookWrapper(
    useTreatmentTracksByStatusQuery({
      variables: {
        status: TreatmentTrackStatus.ACTIVE,
      },
    })
  );

  // Mutation: I want to add this track.
  const [createCareEpisodeTreatmentTrack] = apolloMutationHookWrapper(
    (data) => data.careCreateCareEpisodeTreatmentTrack,
    useCreateCareEpisodeTreatmentTrackMutation({
      refetchQueries: refetchQueries("treatmentTracks"),
    })
  );

  return (
    <CareEpisodeDetailsTreatmentTracksBoxComponent
      availableRemoteData={availableRemoteData}
      selectedTrack={selectedTrack}
      handleChange={setSelectedTrack}
      handleAddTracks={handleAddTracks}
      {...props}
    />
  );
}

type CareEpisodeDetailsTreatmentTracksPillDialogComponentProps =
  CareEpisodeDetailsTreatmentTracksBoxHookWrapperProps & {
    availableRemoteData: RemoteData<ApolloError, TreatmentTracksByStatusQuery>;
    selectedTrack?: TreatmentTrackId;
    handleChange: (newId: TreatmentTrackId | undefined) => void;
    handleAddTracks: () => void;
  };
type TreatmentTrackSummary = Pick<TreatmentTrack, "__typename" | "id" | "name">;

function CareEpisodeDetailsTreatmentTracksBoxComponent(
  props: CareEpisodeDetailsTreatmentTracksPillDialogComponentProps
) {
  const { t } = useTranslation(["careEpisodes"]);

  // We have to jump through some hoops with spreading nodes back into a list and explicitly declaring this type so
  // that availableTracks is a mutable array instead of a readonly one, because the only sort method that javascript
  // has is in-place.
  const availableTracks: Array<TreatmentTrackSummary> = props.availableRemoteData.caseOf({
    Success: (result) => {
      return [...(result.careTreatmentTracks?.nodes || [])];
    },
    _: () => [],
  });
  availableTracks.sort((trackA, trackB) => (trackA.name < trackB.name ? -1 : 1));

  const availableTrackMenuItems = availableTracks.map((track) => {
    return (
      <MenuItem key={track.id.toString()} value={track.id.toString()}>
        {track.name}
      </MenuItem>
    );
  });

  const allowAddingTracks = availableTrackMenuItems.length > 0;
  const availableTracksSelectionElement = allowAddingTracks ? (
    <FormControl>
      <InputLabel>{t("careEpisodes:treatmentTracks.availableTracks")}</InputLabel>
      <Select
        value={props.selectedTrack?.toString() || ""}
        label={t("careEpisodes:treatmentTracks.availableTracks")}
        onChange={(e) =>
          props.handleChange(
            fromNullableString<"TreatmentTrack">(e.target.value).caseOf({
              Ok: (id) => id,
              Err: () => undefined,
            })
          )
        }
        sx={{ width: "40rem" }}
      >
        {availableTrackMenuItems}
      </Select>
    </FormControl>
  ) : (
    <Typography>{t("careEpisodes:treatmentTracks.noAvailableTracks")}</Typography>
  );

  return (
    <Card>
      <CardHeader title={t("careEpisodes:sections.treatmentTracks")}></CardHeader>
      <CardContent>
        <Stack spacing={1}>
          <CareEpisodeDetailsTreatmentTracksList
            title={t("careEpisodes:treatmentTracks.activeTreatmentTracks")}
            status={CareEpisodeTreatmentTrackStatus.ACTIVE}
            careEpisodeId={props.careEpisodeId}
          />
          <Divider />
          <CareEpisodeDetailsTreatmentTracksList
            title={t("careEpisodes:treatmentTracks.completeTreatmentTracks")}
            status={CareEpisodeTreatmentTrackStatus.COMPLETE}
            careEpisodeId={props.careEpisodeId}
            showPillOptions={false}
          />
          <Divider />
          <Typography variant="h3">{t("careEpisodes:treatmentTracks.addTracks")}</Typography>
          {availableTracksSelectionElement}
          <Stack direction="row" spacing={1}>
            <Button
              disabled={!allowAddingTracks}
              onClick={props.handleAddTracks}
              variant="contained"
              color="primary"
            >
              <Add />
              {t("careEpisodes:actions.add")}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export {
  CareEpisodeDetailsTreatmentTracksBoxHookWrapper as CareEpisodeDetailsTreatmentTracksBox,
  CareEpisodeDetailsTreatmentTracksBoxComponent as Component,
};
