import { Box, Card, CardContent, CardHeader, Skeleton, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DataSourceRecordLog,
  DataSourceResourceType,
  useIntegrationDataSourceRecordLogDetailQuery,
  useIntegrationStatusLogQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { DataSourceRecordLogId, ImportHistoryId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DataSourceRecordAlgorithmLogDetails, IntegrationStatusLastLog } from "./IntegrationStatusLog";

export function IntegrationHistorySummary(props: {
  resourceType: DataSourceResourceType;
  importHistoryId: ImportHistoryId;
}): ReactElement {
  const { t } = useTranslation(["integrations", "common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationStatusLogQuery({
      variables: { id: props.importHistoryId, resourceType: props.resourceType },
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.integrationsImportHistory) {
        return (
          <Stack direction="column" spacing={1} alignItems="center" margin="3rem">
            <Typography variant="h1" component="h1">
              {t("integrations:dataSourceRecordLogHistory.noneSelected.title", {
                name: data.integrationsImportHistory.name,
              })}
            </Typography>
            <Typography variant="body1">
              {t("integrations:dataSourceRecordLogHistory.noneSelected.body")}
            </Typography>
          </Stack>
        );
      } else {
        return <Typography>{t("common:notFound")}</Typography>;
      }
    },
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Failure: (error) => {
      return <ErrorMessage message={error.message} />;
    },
  });
}

function SkeletonCard() {
  return (
    <Card>
      <CardHeader title={<Skeleton />} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Stack>
      </CardContent>
    </Card>
  );
}

function ErrorCard(props: { message: string }) {
  return (
    <Card>
      <CardHeader />
      <CardContent>
        <ErrorMessage message={props.message} />
      </CardContent>
    </Card>
  );
}

function DataSourceRecordLogCard(props: {
  log: Pick<DataSourceRecordLog, "createdAt" | "status" | "transformErrors" | "raw" | "matchStatus"> & {
    dataSourceRecordAlgorithmLogs: ReadonlyArray<DataSourceRecordAlgorithmLogDetails>;
  };
  resourceType: DataSourceResourceType;
}) {
  const { t } = useTranslation(["integrations", "common"]);
  return (
    <Card>
      <CardHeader title={t("common:date.tinyWithTime", { date: props.log.createdAt })} />
      <CardContent>
        <Stack spacing={2} direction={"column"}>
          <IntegrationStatusLastLog log={props.log} resourceType={props.resourceType} />
          <Box>
            <Typography variant="h2">{t("integrations:dataSourceRecordLogHistory.card.rawData")}</Typography>
            <div>
              <pre>{JSON.stringify(props.log.raw, null, 2)}</pre>
            </div>
            <Typography variant="caption">
              {t("integrations:dataSourceRecordLogHistory.card.rawDataExplanation")}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function DataSourceRecordLogDetail(props: {
  logId: DataSourceRecordLogId;
  resourceType: DataSourceResourceType;
}): ReactElement {
  const { t } = useTranslation(["common"]);

  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationDataSourceRecordLogDetailQuery({
      variables: {
        dataSourceRecordLogId: props.logId,
      },
    })
  );

  return remoteData.caseOf({
    NotAsked: () => <SkeletonCard />,
    Loading: () => <SkeletonCard />,
    Failure: (err) => <ErrorCard message={err.message} />,
    Success: (response) => {
      if (response.integrationsDataSourceRecordLog) {
        return (
          <DataSourceRecordLogCard
            log={response.integrationsDataSourceRecordLog}
            resourceType={props.resourceType}
          />
        );
      } else {
        return <ErrorCard message={t("common:notFound")} />;
      }
    },
  });
}
