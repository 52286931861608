import React from "react";
import { TaskCard } from "CollaborativeCare/Tasks/TaskCard/TaskCard";
import { TaskSearchQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { DigUnpacked } from "type-utils";

export function MobileTaskListCard(props: {
  row: DigUnpacked<TaskSearchQuery, ["collaborativeCareTasks", "nodes"]>;
}) {
  return <TaskCard task={props.row} key={props.row.id.toString()} inPatientContext={false} />;
}

export function MobileTaskListCardForPatient(props: {
  row: DigUnpacked<TaskSearchQuery, ["collaborativeCareTasks", "nodes"]>;
}) {
  return <TaskCard task={props.row} key={props.row.id.toString()} inPatientContext={true} />;
}
