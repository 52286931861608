import { Route, Routes, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import NotFound from "Shared/NotFound";
import OutcomesMetricsDashboard from "./OutcomesMetricsDashboard";
import { fromNullableString } from "Lib/Id";
import OutcomesMetricsDetails from "./OutcomesMetricDetails";
import { EntityType } from "GeneratedGraphQL/SchemaAndOperations";
import CreateOutcomesMetric from "./CreateOutcomesMetric";
import EditOutcomesMetric from "./EditOutcomesMetric";
import { WithPermission, WithoutPermission } from "Shared/WithPermission";

/**
 * Only allow certain entity types in the outcomes system. No patients/care episodes etc
 */
const allowedEntityTypes = [
  EntityType.INSTITUTE_GROUP,
  EntityType.INSTITUTE,
  EntityType.ORGANIZATION,
  EntityType.PROVIDER,
  EntityType.TREATMENT_SERVICE,
  EntityType.PANEL,
  EntityType.INSTITUTE_CONDITION,
  EntityType.TREATMENT_TRACK,
];

function MetricDetailsRoute(): ReactElement | null {
  const params = useParams<{ metricId: string }>();

  return fromNullableString<"Metric">(params.metricId).caseOf({
    Ok: (id) => <OutcomesMetricsDetails id={id} />,
    Err: () => <NotFound />,
  });
}

function EditMetricRoute(): ReactElement | null {
  const params = useParams<{ metricId: string }>();

  return fromNullableString<"Metric">(params.metricId).caseOf({
    Ok: (id) => <EditOutcomesMetric id={id} />,
    Err: () => <NotFound />,
  });
}

/**
 * Providers full routing for outcomes metrics
 * @returns routes
 */
export function OutcomesRoutes(): ReactElement {
  // Although the permissions are view outcomes metrics, we only link on the top if you're a superuser or CoCM superuser.
  return (
    <>
      <WithPermission permission="viewOutcomesMetrics">
        <Routes>
          <Route element={<MetricDetailsRoute />} path="/:metricId" />
          <Route element={<EditMetricRoute />} path="/:metricId/edit" />
          <Route element={<CreateOutcomesMetric />} path="/create" />
          <Route element={<OutcomesMetricsDashboard />} path="/" />
        </Routes>
      </WithPermission>
      <WithoutPermission permission="viewOutcomesMetrics">
        <Routes>
          <Route element={<NotFound />} path="*" />
        </Routes>
      </WithoutPermission>
    </>
  );
}

export { allowedEntityTypes };
