import React, { ReactElement } from "react";
import { Badge, Paper, Tooltip, Typography } from "@mui/material";
import Link from "MDS/Link";
import { useTranslation } from "react-i18next";
import { shortGender } from "Lib/Gender";
import {
  CareEpisodeProviderRelationship,
  EnrollmentSortParameter,
  EnrollmentStatus,
  PanelEnrollmentQuery,
  PanelEnrollmentQueryVariables,
  usePanelEnrollmentQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PreloadedThreePartStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { PatientBillableMinutesWithTargetBadgeElement } from "CollaborativeCare/PatientBillableMinutesBadge";
import { QuickTrackButton } from "CollaborativeCare/QuickTrackButton";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import { COCM_PANEL_STORAGE_KEY } from "Shared/Storage";
import { useDebounce } from "Lib/Hooks";
import { PanelSearchFilters } from "./PanelFilters";
import { dischargeReasonT } from "GeneratedGraphQL/EnumTranslations";
import { useGridApiRef } from "@mui/x-data-grid";
import { MobilePanelListCard } from "./MobilePanelList";

type PanelListTableProps = {
  filters: PanelSearchFilters;
};

const COLUMN_ENROLLMENT_STATUS_MAP: Record<string, EnrollmentStatus> = {
  dischargedAt: EnrollmentStatus.DISCHARGED,
  dischargeReason: EnrollmentStatus.DISCHARGED,
  minutes: EnrollmentStatus.ENROLLED,
  status: EnrollmentStatus.ENROLLED,
};

export default function PanelListTable(props: PanelListTableProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums", "common", "patients"]);
  const testPatientViewability = useTestPatientViewability();

  const panelFilter = usePanelFilter();
  const debouncedSearch = useDebounce(props.filters.search, 500);

  const apiRef = useGridApiRef();

  // This is used only in case of first time view and reset. The table keeps its setting appropriately otherwise.
  const columnVisibilityModel = React.useMemo(() => {
    return Object.entries(COLUMN_ENROLLMENT_STATUS_MAP).reduce(
      (obj: Record<string, boolean>, [column, valence]) => {
        if (props.filters.status) {
          obj[column] = valence === props.filters.status;
        }

        return obj;
      },
      {
        // You can make columns disappear by default here.
        durationDays: false,
        durationCalendarMonths: false,
        consentDate: false,
      }
    );
  }, [props.filters.status]);

  const queryVars: Pick<
    PanelEnrollmentQueryVariables,
    | "search"
    | "careManager"
    | "primaryCareProvider"
    | "status"
    | "testPatient"
    | "forPanel"
    | "severity"
    | "currentMonthMinutesTargetStatus"
    | "dischargeReason"
    | "dischargedAt"
    | "firstSuicidalityAlert"
    | "lastSuicidalityAlert"
    | "anySuicidalityAlert"
    | "enrolledAt"
  > = {
    search: debouncedSearch || null,
    primaryCareProvider: props.filters.primaryCareProvider || null,
    careManager: props.filters.careManager || null,
    status: props.filters.status || null,
    testPatient: testPatientViewability,
    forPanel: panelFilter,
    severity: props.filters.severity,
    currentMonthMinutesTargetStatus: props.filters.currentMonthMinutesTargetStatus,
    dischargedAt: props.filters.dischargedAt,
    dischargeReason: props.filters.dischargeReason,
    firstSuicidalityAlert: props.filters.firstSuicidalityAlert,
    lastSuicidalityAlert: props.filters.lastSuicidalityAlert,
    anySuicidalityAlert: props.filters.anySuicidalityAlert,
    enrolledAt: props.filters.enrolledAt,
  };

  // We want to have a separate set of columns for every status as they have different columns.
  const storageKey = `${COCM_PANEL_STORAGE_KEY}-${props.filters.status}`;

  const columns: DataGridCols<PanelEnrollmentQuery, ["collaborativeCareEnrollments"]> = React.useMemo(() => {
    return [
      {
        field: "status",
        headerName: t("collaborativeCare:panel.fields.status"),
        sortable: false,
        width: 150,
        renderCell: (params): ReactElement => {
          return (
            <PreloadedThreePartStatus
              patientId={params.row.patient.id}
              enrollmentId={params.row.id}
              careEpisodeId={params.row.careEpisode.id}
              patientName={params.row.patient.nameLastFirst}
              alertValue={params.row.currentSuicidalityAlert}
              generalFunctioningThresholdClass={params.row.currentGeneralFunctioningThresholdClass}
              targetedThresholdClass={params.row.currentTargetedThresholdClass}
            />
          );
        },
      },
      {
        field: "minutes",
        headerName: t("collaborativeCare:panel.fields.minutes"),
        sortable: true,
        width: 90,
        renderCell: (params): ReactElement | null => {
          if (params.row.currentEnrollmentMonth) {
            const current = params.row.currentEnrollmentMonth;
            return (
              <PatientBillableMinutesWithTargetBadgeElement
                billableMinutes={current.billableMinutes}
                minutesTargetCeiling={current.minutesTargetCeiling}
                minutesTargetFloor={current.minutesTargetFloor}
                minutesTargetStatus={current.minutesTargetStatus}
              />
            );
          } else {
            return null;
          }
        },
      },
      {
        field: "quickTrack",
        headerName: "",
        sortable: false,
        width: 150,
        renderCell: (params): ReactElement => {
          return <QuickTrackButton patientId={params.row.patient.id} />;
        },
      },
      {
        field: "consentDate",
        headerName: t("collaborativeCare:panel.fields.consentDate"),
        sortable: true,
        width: 140,
        renderCell: (params) => {
          return t("common:date.tinyWithTime", { date: params.row.consentDate });
        },
      },
      {
        field: "enrolledAt",
        headerName: t("collaborativeCare:panel.fields.enrolledAt"),
        sortable: true,
        width: 140,
        renderCell: (params) => {
          return (
            <Tooltip title={t("common:date.tinyWithTime", { date: params.row.enrolledAt })}>
              <Typography>
                {t("common:date.durationToNow", { date: params.row.enrolledAt }).toString() +
                  " " +
                  t("common:date.ago")}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        field: "dischargedAt",
        headerName: t("collaborativeCare:panel.fields.dischargedAt"),
        sortable: true,
        width: 140,
        renderCell: (params) => {
          if (params.row.dischargedAt) {
            return (
              <Tooltip title={t("common:date.tinyWithTime", { date: params.row.dischargedAt })}>
                <Typography>
                  {t("common:date.durationToNow", { date: params.row.dischargedAt }).toString() +
                    " " +
                    t("common:date.ago")}
                </Typography>
              </Tooltip>
            );
          }
          return null;
        },
      },
      {
        field: "durationDays",
        headerName: t("collaborativeCare:panel.fields.durationDays"),
        sortable: true,
        valueGetter: (_value, row) => row.durationDays,
      },
      {
        field: "durationCalendarMonths",
        headerName: t("collaborativeCare:panel.fields.durationMonths"),
        sortable: true,
        valueGetter: (_value, row) => row.durationCalendarMonths,
      },
      {
        field: "dischargeReason",
        headerName: t("collaborativeCare:panel.fields.dischargeReason"),
        sortable: true,
        width: 140,
        valueGetter: (_value, row) => (row.dischargeReason ? dischargeReasonT(row.dischargeReason, t) : null),
      },
      {
        field: "patientName",
        headerName: t("collaborativeCare:panel.fields.patientName"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          const link =
            params.row.status === EnrollmentStatus.ENROLLED
              ? `/app/cocm/patient/${params.row.patient.id}`
              : `/app/cocm/patient/${params.row.patient.id}/enrollment/${params.row.id}`;
          return (
            <Badge
              badgeContent={t("patients:referenceHeader.testPatient")}
              invisible={!params.row.patient.isTest}
              color="success"
            >
              <Link to={link}>{params.row.patient.nameLastFirst}</Link>
            </Badge>
          );
        },
      },
      {
        field: "gender",
        headerName: t("collaborativeCare:panel.fields.gender"),
        align: "center",
        headerAlign: "center",
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return params.row.patient.gender ? shortGender(params.row.patient.gender, t) : "";
        },
      },
      {
        field: "dob",
        headerName: t("collaborativeCare:panel.fields.dob"),
        sortable: true,
        width: 120,
        valueGetter: (_value, row) => {
          return row.patient.dob ? t("common:date.tiny", { date: row.patient.dob }) : "";
        },
      },
      {
        field: "pcp",
        headerName: t("collaborativeCare:panel.fields.pcp"),
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => {
          const cep = row.careEpisode.careEpisodeProviders.find((provider) => {
            return provider.relationship == CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN;
          });
          return cep ? cep.provider.orderedName : "";
        },
      },
      {
        field: "cm",
        headerName: t("collaborativeCare:panel.fields.cm"),
        sortable: false,
        flex: 1,
        valueGetter: (_value, row) => {
          const cep = row.careEpisode.careEpisodeProviders.find((provider) => {
            return provider.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
          });
          return cep ? cep.provider.orderedName : "";
        },
      },
      { field: "mrn", headerName: t("collaborativeCare:panel.fields.mrn"), sortable: true, flex: 1 },
    ];
  }, [props.filters.status]);

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        // We want to reload an entirely different set of columns if you change the status
        // The easiest way to do this is just to force a new data grid so that it doesn't
        // get confused trying to work out what to do about column visibility etc.
        key={props.filters.status}
        queryHook={usePanelEnrollmentQuery}
        storageKey={storageKey}
        queryVariables={queryVars}
        columns={columns}
        unwrapData={(response) => response?.collaborativeCareEnrollments || null}
        colNameToSortParam={sortFieldToParameter}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
        apiRef={apiRef}
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
        mobileCard={MobilePanelListCard}
        showExportToolbar={true}
        getFooterMessage={(response) => {
          const fullSearchResultCount = response?.nonPanelPatients?.totalCount;
          const searchWithPanelsCount = response?.collaborativeCareEnrollments?.totalCount;

          if (fullSearchResultCount === undefined || searchWithPanelsCount === undefined) {
            return undefined;
          }

          return t("collaborativeCare:panel.search.footer", {
            // Note that even though count isn't interpolated into the translation we need it to select the right
            // variant.
            count: fullSearchResultCount - searchWithPanelsCount,
            fullSearchResultCount: fullSearchResultCount,
            searchWithPanelsCount: searchWithPanelsCount,
          });
        }}
      />
    </Paper>
  );
}

function sortFieldToParameter(parameter: string | null): EnrollmentSortParameter | null {
  switch (parameter) {
    case "dob":
      return EnrollmentSortParameter.DOB;
    case "patientName":
      return EnrollmentSortParameter.NAME_LAST_FIRST;
    case "enrolledAt":
      return EnrollmentSortParameter.ENROLLED_AT;
    case "gender":
      return EnrollmentSortParameter.GENDER;
    case "mrn":
      return EnrollmentSortParameter.MRN;
    case "minutes":
      return EnrollmentSortParameter.CURRENT_MONTH_MINUTES;
    case "dischargedAt":
      return EnrollmentSortParameter.DISCHARGED_AT;
    case "dischargeReason":
      return EnrollmentSortParameter.DISCHARGE_REASON;
    case "consentDate":
      return EnrollmentSortParameter.CONSENT_DATE;
    default:
      return null;
  }
}
