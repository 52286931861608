import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { ReactElement, ReactNode } from "react";

// A collection of small helpers for rendering/styling different things based on the viewport size. For our
// responsiveness is pretty limited, this is mostly helpful to keep the various things that are responsive coordinated
// around a single breakpoint.

// We're using the default set of Mui breakpoints, so "md" here is 900px. It would probably be better for us to define
// a semantic set of breakpoints "tablet", "phone", etc, but there are some pieces of the pipbhc code that use the
// default breakpoints in ways I don't want to chase down right now.
export const MOBILE_BREAKPOINT = "md";

export function useIsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));
}

export function useIsDesktop() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(MOBILE_BREAKPOINT));
}

export function OnMobile(props: { children: ReactNode }): ReactElement | null {
  return useIsMobile() ? <>{props.children}</> : null;
}

export function OnDesktop(props: { children: ReactNode }): ReactElement | null {
  return useIsDesktop() ? <>{props.children}</> : null;
}
