import { Route, Routes, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import { CareEpisodeDetailsPageRoutes } from "./CareEpisodes";
import { QualityIssuesRoutes } from "./QualityIssues/QualityIssueRoutes";
import { CareEpisodeCreatePage } from "./CareEpisodes/CareEpisodeCreate";
import { fromNullableString } from "Lib/Id";
import NotFound from "Shared/NotFound";
import PatientList from "./PatientList";
import { PatientDetails } from "./PatientDetails/PatientDetails";
import { PatientAppointmentRoutes } from "./Appointments";
import { PickTypename } from "type-utils";
import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientCareEpisodeRoutes } from "./CareEpisodes2";

function PatientDetailsRoute(): ReactElement | null {
  const params = useParams<{ patientId: string }>();

  return fromNullableString<"Patient">(params.patientId).caseOf({
    Ok: (id) => {
      return (
        <Routes>
          <Route element={<PatientDetails patientId={id} />} path="/" />
          <Route element={<PatientAppointmentRoutes patientId={id} />} path="appointments/*" />
          <Route element={<PatientCareEpisodeRoutes patientId={id} />} path="care-episodes-new/*" />
        </Routes>
      );
    },
    Err: () => <NotFound />,
  });
}

export function patientUrl(patient: PickTypename<Patient, "id">) {
  return `/app/patients/${patient.id}`;
}

export function PatientRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<PatientList />} path="/" />
      <Route element={<PatientDetailsRoute />} path="/:patientId/*" />
      <Route element={<QualityIssuesRoutes />} path="quality/*" />
      <Route element={<CareEpisodeDetailsPageRoutes />} path=":patientId/care-episodes/:careEpisodeId/*" />
      <Route element={<CareEpisodeCreatePage />} path=":patientId/care-episodes/create" />
    </Routes>
  );
}
