import { apolloMutationHookWrapper } from "Api/GraphQL";
import { InboxMessagesFindingDatagram } from "CollaborativeCare/CareManagerDashboard/InboxMessagesFindings";
import { useActOnInboxMessageMutation, useCreateTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type CreateTaskLaterActionProps = {
  finding: InboxMessagesFindingDatagram;
  title: string;
  body: string;
  dueAt: Date;
  patientId: PatientId;
  assignedToId: ProviderId;
};
export function CreateTaskLaterAction(props: CreateTaskLaterActionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  // We have two mutation calls. The first one creates the task, and the second accepts
  // the finding.

  // This first call creates the task.
  const [createTask, { remoteData: createTaskRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTask,
    useCreateTaskMutation({
      variables: {
        input: {
          title: props.title,
          body: props.body,
          dueAt: props.dueAt,
          patientId: props.patientId,
          assignedToId: props.assignedToId,
          addToDefaultList: true,
        },
      },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  // This second one accepts the finding.
  const [accept, { remoteData: acceptRemoteData }] = apolloMutationHookWrapper(
    (response) => response.actOnInboxMessage,
    useActOnInboxMessageMutation({
      variables: {
        input: {
          action: {
            accept: {
              inboxMessageId: props.finding.inboxMessage.id,
            },
          },
        },
      },
      refetchQueries: refetchQueries("findings"),
    })
  );

  const onCreateTaskLater = () => {
    // We need to create our task and accept our finding.
    createTask();
    accept();
  };

  const showSpinner = createTaskRemoteData.kind === "Loading" || acceptRemoteData.kind === "Loading";
  const disabled = showSpinner;

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="secondary"
      onClick={onCreateTaskLater}
      showSpinner={showSpinner}
      disabled={disabled}
    >
      {t("collaborativeCare:findings.actions.createTaskLater")}
    </ButtonWithSpinner>
  );
}
