import { KeyboardArrowLeft } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { MirahRemoteError } from "Api/GraphQL";
import { TreatmentTargetPickerMultiple } from "CollaborativeCare/TreatmentTargetPicker";
import {
  AllTreatmentTargetsQuery,
  Patient,
  ShortTaskDescription,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { FormOverlay } from "Shared/Form";
import { differenceInYears } from "date-fns";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";
import { DigUnpacked, PickTypename } from "type-utils";

type EnrollmentTargetsAndTasksProps = {
  patient: PickTypename<Patient, "name" | "dob">;
  targets: Array<DigUnpacked<AllTreatmentTargetsQuery, ["collaborativeCareTreatmentTargets", "nodes"]>>;
  tasks: Array<ShortTaskDescription>;
  remoteData: RemoteData<MirahRemoteError<unknown>, unknown>;
  errorMessage: string | undefined;
  setTargets: (
    newTargets: Array<DigUnpacked<AllTreatmentTargetsQuery, ["collaborativeCareTreatmentTargets", "nodes"]>>
  ) => void;
  setTasks: (newTasks: Array<ShortTaskDescription>) => void;
  onBack: () => void;
  onComplete: () => void;
};

export function EnrollmentTargetsAndTasks(props: EnrollmentTargetsAndTasksProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const valid = props.targets.length > 0;
  const showSpinner = props.remoteData.kind === "Loading";
  const disabled = !valid || props.remoteData.kind === "Loading";

  let bundlesPrompt = t("collaborativeCare:enrollment.addBundlesPrompt", { patient: props.patient.name });
  if (props.patient.dob) {
    bundlesPrompt = t("collaborativeCare:enrollment.addBundlesPromptWithAge", {
      patient: props.patient.name,
      age: differenceInYears(new Date(), props.patient.dob),
    });
  }

  return (
    <>
      <DialogContent>
        <FormOverlay
          response={props.remoteData}
          errorMessage={props.errorMessage || t("collaborativeCare:enrollment.genericErrorMessage")}
        />
        <Stack direction="column" spacing={1} sx={{ paddingTop: 1 }}>
          <Typography>{bundlesPrompt}</Typography>
          <TreatmentTargetPickerMultiple
            value={props.targets}
            onChange={props.setTargets}
            label={t("collaborativeCare:patientDetails.surveyHistory.bundles")}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onBack}>
          <KeyboardArrowLeft /> {t("common:actions.back")}
        </Button>
        <Box flexGrow={1} />
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          disabled={disabled}
          onClick={props.onComplete}
          showSpinner={showSpinner}
        >
          {t("collaborativeCare:enrollment.actions.completeEnrollment")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
