import { clearCache } from "Api/GraphQL";
import { fromString } from "Lib/Id";
import { UserId } from "Lib/Ids";
import { resultToMaybe } from "Lib/Utils";
import { IMPERSONATION_STORAGE_KEY } from "Shared/Storage";
import { Nothing } from "seidr";

// IMPERSONATING USER FUNCTIONALITY
// This allows internal users to send a special header to get a read-only view
// of data from another user's perspective

function getImpersonatingUserId() {
  const value = localStorage.getItem(IMPERSONATION_STORAGE_KEY);

  if (value) {
    return resultToMaybe(fromString<"User">(value));
  } else {
    return Nothing();
  }
}

function setImpersonatingUserId(id: UserId | null) {
  // Note that we do clearCache here because we want to refetch all the queries.
  clearCache();
  if (id) {
    return localStorage.setItem(IMPERSONATION_STORAGE_KEY, id.toString());
  } else {
    localStorage.removeItem(IMPERSONATION_STORAGE_KEY);
    return null;
  }
}

function getImpersonatingUserHeader() {
  const userId = getImpersonatingUserId();

  return userId.caseOf({
    Just: (value) => {
      return {
        "Impersonating-User-Id": value,
      };
    },
    Nothing: () => {
      return {};
    },
  });
}

export { getImpersonatingUserId, setImpersonatingUserId, getImpersonatingUserHeader };
