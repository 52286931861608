import {
  useWorkflowSelectLazyQuery,
  WorkflowFilter,
  WorkflowSelectQueryVariables,
} from "GeneratedGraphQL/SchemaAndOperations";
import { WorkflowId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { QueryAutocompleteSingle } from "Shared/QueryAutocomplete";

type WorkflowSelectProps = {
  value: WorkflowFilter | null;
  setValue: (newValue: WorkflowFilter | null) => void;
};

type SelectOption = { id: WorkflowId | "all"; title: string };

function inputToSelect(value: SelectOption | null): WorkflowFilter {
  if (!value) {
    return { allWorkflows: true };
  } else if (value.id === "all") {
    return { allWorkflows: true };
  } else {
    return { workflowIds: [value.id] };
  }
}

function selectToInput(value: WorkflowFilter | null): SelectOption | null {
  if (value === null) {
    return null;
  } else if (value.allWorkflows !== undefined) {
    return { id: "all", title: "Ignored" };
  } else if (value.workflowIds[0]) {
    return { id: value.workflowIds[0], title: "Ignored" };
  } else {
    return null;
  }
}

export default function WorkflowSelect(props: WorkflowSelectProps): ReactElement {
  const { t } = useTranslation(["decisionSupport"]);
  const queryVars: Omit<WorkflowSelectQueryVariables, "search"> = { first: 10 };

  const fixedOptions: ReadonlyArray<SelectOption> = [
    { title: t("decisionSupport:workflowSelect.allWorkflows"), id: "all" },
  ];

  return (
    <QueryAutocompleteSingle
      query={useWorkflowSelectLazyQuery}
      queryVariables={queryVars}
      unwrapResponse={(response) =>
        response.decisionSupportInstituteWorkflows?.nodes.map((node) => node.workflow)
      }
      valueUpdated={(value) => props.setValue(inputToSelect(value))}
      valueEqual={(left, right) => left.id === right.id}
      value={selectToInput(props.value)}
      fixedOptions={fixedOptions}
      label={t("decisionSupport:workflowSelect.title")}
      autocompleteProps={{
        noOptionsText: t("decisionSupport:workflowSelect.noWorkflows"),
        getOptionLabel: (option) => option.title,
      }}
    />
  );
}
