import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import React, { ReactElement } from "react";
import { WithFeature, WithOutFeature } from "Contexts/CurrentInstituteContext";
import { useExternalPatientSearchQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import SimpleLandingPage from "Shared/SimpleLandingPage";
import { useTranslation } from "react-i18next";
import RemoteCreatePatient from "./RemoteCreatePatient";

function CocmExternalPatientDeeplink() {
  const params = useParams<{ externalPatientId?: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation(["external"]);

  if (!params.externalPatientId) {
    return <SimpleLandingPage title={t("external:noId.title")} subtitle={t("external:noId.subtitle")} />;
  }

  const { remoteData } = apolloQueryHookWrapper(
    useExternalPatientSearchQuery({ variables: { externalId: params.externalPatientId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      const result = data.patients?.nodes[0];
      if (result) {
        navigate(`/app/cocm/patient/${result.id}`);
        return (
          <SimpleLandingPage title={t("external:success.title")} subtitle={t("external:success.subtitle")} />
        );
      } else {
        return (
          <SimpleLandingPage
            title={t("external:patientNotFound.title")}
            subtitle={t("external:patientNotFound.subtitle")}
          />
        );
      }
    },
    Failure: (_) => {
      return <SimpleLandingPage title={t("external:error.title")} subtitle={t("external:error.subtitle")} />;
    },
    _: () => {
      return (
        <SimpleLandingPage title={t("external:loading.title")} subtitle={t("external:loading.subtitle")} />
      );
    },
  });
}

function MbcExternalPatientDeeplink() {
  const params = useParams<{ externalPatientId?: string }>();
  const { t } = useTranslation(["external"]);

  if (!params.externalPatientId) {
    return <SimpleLandingPage title={t("external:noId.title")} subtitle={t("external:noId.subtitle")} />;
  }

  const { remoteData } = apolloQueryHookWrapper(
    useExternalPatientSearchQuery({ variables: { externalId: params.externalPatientId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      const result = data.patients?.nodes[0];
      if (result) {
        const url = result.hasCompletedAssessment
          ? `/provider/patients/${result.id}/longitudinal`
          : `/provider/patients/${result.id}`;

        window.location.href = url;

        return (
          <SimpleLandingPage title={t("external:success.title")} subtitle={t("external:success.subtitle")} />
        );
      } else {
        return (
          <SimpleLandingPage
            title={t("external:patientNotFound.title")}
            subtitle={t("external:patientNotFound.subtitle")}
          />
        );
      }
    },
    Failure: (_) => {
      return <SimpleLandingPage title={t("external:error.title")} subtitle={t("external:error.subtitle")} />;
    },
    _: () => {
      return (
        <SimpleLandingPage title={t("external:loading.title")} subtitle={t("external:loading.subtitle")} />
      );
    },
  });
}

export function ExternalRoutes(): ReactElement {
  return (
    <>
      <WithFeature feature="enableCollaborativeCare">
        <Routes>
          <Route element={<CocmExternalPatientDeeplink />} path="/patient/:externalPatientId" />
          <Route element={<RemoteCreatePatient />} path="/remote-create-patient" />
        </Routes>
      </WithFeature>
      <WithOutFeature feature="enableCollaborativeCare">
        <Routes>
          <Route element={<MbcExternalPatientDeeplink />} path="/patient/:externalPatientId" />
          <Route element={<RemoteCreatePatient />} path="/remote-create-patient" />
        </Routes>
      </WithOutFeature>
    </>
  );
}
