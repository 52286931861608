import { Typography } from "@mui/material";
import { Axis, Grid, AnimatedLineSeries, Tooltip, XYChart } from "@visx/xychart";
import { ChartData } from "GeneratedGraphQL/SchemaAndOperations";
import React from "react";
import { ScaleConfig } from "@visx/scale";
import { AxisScaleOutput } from "@visx/axis";

export type ChartDataItem = Pick<ChartData, "date" | "label" | "value">;

export default function AnalyticsLineChart(props: {
  title?: string;
  subtitle?: string;
  data: Array<ChartDataItem>;
  dataKey: string;
  xAccessor: (d: ChartDataItem) => string;
  yAccessor: (d: ChartDataItem) => string;
  yDomainMax?: number;
  labelAccessor?: (d: ChartDataItem) => string;
  numYTicks?: number;
}) {
  const titleElement = props.title ? <Typography variant={"h6"}>{props.title}</Typography> : null;
  const subtitleElement = props.subtitle ? <Typography>{props.subtitle}</Typography> : null;

  // TODO qqHRB this does not work properly because it needs to use the yAccessor but that needs a string right now
  //const yDomainMax = props.yDomainMax ? props.yDomainMax : Math.max(...props.data.map((p) => p.value));
  let yScale = { type: "linear" } as ScaleConfig<AxisScaleOutput>;
  if (props.yDomainMax) {
    yScale = { type: "linear", domain: [0, props.yDomainMax] } as ScaleConfig<AxisScaleOutput>;
  }
  return (
    <>
      {titleElement}
      {subtitleElement}
      <XYChart
        height={300}
        xScale={{ type: "band", paddingInner: 0.5 }}
        yScale={yScale}
        margin={{ top: 20, right: 20, bottom: 40, left: 40 }}
      >
        <Axis orientation="left" numTicks={6} hideAxisLine hideTicks />
        <Axis
          numTicks={props.numYTicks}
          orientation="bottom"
          hideAxisLine
          hideTicks
          rangePadding={50}
          labelOffset={200}
        />
        <Grid columns={false} numTicks={6} strokeDasharray="3 3" />
        <AnimatedLineSeries
          dataKey={props.dataKey}
          data={props.data}
          xAccessor={props.xAccessor}
          yAccessor={props.yAccessor}
        />
        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => {
            if (!tooltipData || !tooltipData.nearestDatum || !colorScale) {
              return <></>;
            }
            return (
              <div>
                <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                  {tooltipData.nearestDatum.key}
                </div>
                {props.xAccessor(tooltipData.nearestDatum.datum as ChartData)}
                {": "}
                {props.labelAccessor
                  ? props.labelAccessor(tooltipData.nearestDatum.datum as ChartData)
                  : props.yAccessor(tooltipData.nearestDatum.datum as ChartData)}
              </div>
            );
          }}
        />
      </XYChart>
    </>
  );
}
