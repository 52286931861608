import { NormalizedScore } from "GeneratedGraphQL/SchemaAndOperations";
import { Maybe } from "seidr";

function toColor(normalizedScore: NormalizedScore): string {
  switch (normalizedScore) {
    case NormalizedScore.VALUE_1:
      return "#eeeeee";
    case NormalizedScore.VALUE_2:
      return "#dddddd";
    case NormalizedScore.VALUE_3:
      return "#cccccc";
    case NormalizedScore.VALUE_4:
      return "#aaaaaa";
    case NormalizedScore.VALUE_5:
      return "#888888";
    case NormalizedScore.VALUE_6:
      return "#444444";
    case NormalizedScore.VALUE_7:
      return "#222222";
  }
}

function toColorWithDefault(normalizedScore: Maybe<NormalizedScore>): string {
  return normalizedScore.map(toColor).getOrElse("transparent");
}

export { NormalizedScore, toColor, toColorWithDefault };
export default NormalizedScore;
