import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import CurrentInstituteContext from "Contexts/CurrentInstituteContext";
import RedirectToEmber from "RedirectToEmber";
import NotFound from "Shared/NotFound";
import React, { ReactElement } from "react";
import { Navigate } from "react-router-dom";

/**
 * Pick where to drop an authenticated user based on what institute features and permissions they have.
 */
export function GuessRightLandingPage(): ReactElement {
  const institute = React.useContext(CurrentInstituteContext);
  return institute.caseOf({
    Just: (institute) => {
      const user = React.useContext(AuthenticatedProviderUserContext);

      if (!user) {
        return <NotFound />;
      } else if (
        institute.featureSet["enableCollaborativeCare"] &&
        user.permissions["viewCollaborativeCare"]
      ) {
        return <Navigate to="/app/cocm/dashboard" />;
      } else if (institute.featureSet["enableDashboardSchedule"]) {
        return <Navigate to="/app/providers/dashboard/schedule" />;
      } else {
        window.location.assign("/provider/assessment/dashboard");
        // I'm not sure redirect to ember is actually doing anything here but we gotta return a component to make the
        // types work out, so might as well return one that doesn't do anything and describes what we want to happen.
        return <RedirectToEmber />;
      }
    },
    Nothing: () => {
      return <NotFound />;
    },
  });
}
