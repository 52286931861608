import { Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  useConfigurationEnabled,
  useConfigurationNumber,
  useWithFeatureEnabled,
} from "Contexts/CurrentInstituteContext";
import {
  DataSourceRecordAlgorithmLog,
  SourceEnum,
  useAppointmentSessionCreationAlgorithmDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentId, PatientId } from "Lib/Ids";
import Link from "MDS/Link";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import DecisionTable, { AlgorithmDecisionResult, DecisionTableSection } from "Shared/DecisionTable";
import Spinner from "Shared/Spinner";
import { resources } from "i18n";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

const sourceList: Record<
  SourceEnum,
  `patients:appointments.patientSessionCreation.${keyof (typeof resources)["en"]["patients"]["appointments"]["patientSessionCreation"]}`
> = {
  [SourceEnum.ALGORITHM]: "patients:appointments.patientSessionCreation.algorithmicSelection",
  [SourceEnum.BULK_IMPORT]: "patients:appointments.patientSessionCreation.algorithmicSelection",
  [SourceEnum.MANUAL]: "patients:appointments.patientSessionCreation.manualSelection",
  [SourceEnum.UNKNOWN]: "patients:appointments.patientSessionCreation.unknownSelection",
};

function MoreInfoWithLink(props: { patientId: PatientId; appointmentId: AppointmentId }) {
  const { t } = useTranslation(["patients"]);

  return (
    <Typography>
      {t("patients:appointments.patientSessionCreation.moreInfo")}{" "}
      <Link
        to={`/app/patients/${props.patientId}/appointments/${props.appointmentId}#patientSessionCreation`}
      >
        {t("patients:appointments.patientSessionCreation.appointmentDetailsPage")}
      </Link>
    </Typography>
  );
}

export function PatientSessionCreationAlgorithmTable(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  hideUnknown?: boolean;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useAppointmentSessionCreationAlgorithmDetailsQuery({ variables: { appointmentId: props.appointmentId } })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.schedulingAppointment) {
        return (
          <PatientSessionCreationReason
            {...props}
            appointmentSource={data.schedulingAppointment.source || SourceEnum.UNKNOWN}
            patientSessionExists={!!data.schedulingAppointment.patientSession?.id}
            lastPatientSessionCreationAlgorithm={
              data.schedulingAppointment.lastPatientSessionCreationAlgorithm
            }
            lastActivePatientSessionCreationAlgorithm={
              data.schedulingAppointment.lastActivePatientSessionCreationAlgorithm
            }
          />
        );
      } else {
        return null;
      }
    },
    _: () => <Spinner />,
  });
}

// CREATING THE DECISION LOG
//
// The failure reasons are ORDERED coming out of the back end - you will always fail missing provider first.
// Therefore we give check marks to everything before the algorithm status
// We only want to show the relevant reasons

type Reason = [
  string,
  `patients:appointments.patientSessionCreation.${keyof (typeof resources)["en"]["patients"]["appointments"]["patientSessionCreation"]}`,
  // This function is used to determine which elements are allowed to be seen. We do not want users getting confused about algorithms which do not apply.
  (
    | ((config: {
        enableTreatmentServices: boolean;
        enableMeasurementPlans: boolean;
        integrationOnlyMeasurePrimaryProvider: boolean;
        integrationAppointmentDeduplicationWindowMinutes: number;
      }) => boolean)
    | null
  )
];

const failureReasonList: ReadonlyArray<Reason> = [
  ["missing_care_episode", "patients:appointments.patientSessionCreation.missing_care_episode", null],
  ["no_automatic_creation", "patients:appointments.patientSessionCreation.no_automatic_creation", null],
  ["appointment_in_past", "patients:appointments.patientSessionCreation.appointment_in_past", null],
  ["missing_provider", "patients:appointments.patientSessionCreation.missing_provider", null],
  ["disabled_provider", "patients:appointments.patientSessionCreation.disabled_provider", null],
  [
    "provider_measurement_disabled",
    "patients:appointments.patientSessionCreation.provider_measurement_disabled",
    null,
  ],
  ["appointment_canceled", "patients:appointments.patientSessionCreation.appointment_canceled", null],
  ["wrong_service_type", "patients:appointments.patientSessionCreation.wrong_service_type", null],
  [
    "wrong_provider",
    "patients:appointments.patientSessionCreation.wrong_provider",
    ({ integrationOnlyMeasurePrimaryProvider }) => {
      return integrationOnlyMeasurePrimaryProvider;
    },
  ],
  [
    "appointment_too_close",
    "patients:appointments.patientSessionCreation.appointment_too_close",
    ({ integrationAppointmentDeduplicationWindowMinutes }) => {
      return integrationAppointmentDeduplicationWindowMinutes > 0;
    },
  ],
  [
    "no_autoplanning",
    "patients:appointments.patientSessionCreation.no_autoplanning",
    ({ enableMeasurementPlans }) => {
      // We only want to show this if it's turned off, otherwise people won't care.
      return !enableMeasurementPlans;
    },
  ],
  // ["measurement_updated", "patients:appointments.patientSessionCreation.measurement_updated"],
  // ["measurement_created", "patients:appointments.patientSessionCreation.measurement_created"],
];

function PatientSessionCreationReason(props: {
  lastPatientSessionCreationAlgorithm: Pick<DataSourceRecordAlgorithmLog, "algorithm" | "statusName"> | null;
  lastActivePatientSessionCreationAlgorithm: Pick<
    DataSourceRecordAlgorithmLog,
    "algorithm" | "statusName"
  > | null;
  patientId: PatientId;
  appointmentId: AppointmentId;
  hideUnknown?: boolean;
  patientSessionExists: boolean;
  appointmentSource: SourceEnum;
}) {
  const { t } = useTranslation(["patients"]);
  const {
    hideUnknown,
    patientId,
    appointmentId,
    lastPatientSessionCreationAlgorithm,
    lastActivePatientSessionCreationAlgorithm,
  } = props;
  const enableTreatmentServices = useWithFeatureEnabled("enableTreatmentServices");
  const enableMeasurementPlans = useWithFeatureEnabled("enableMeasurementPlans");
  const integrationAppointmentDeduplicationWindowMinutes = useConfigurationNumber(
    "integrationAppointmentDeduplicationWindowMinutes"
  );
  const integrationOnlyMeasurePrimaryProvider = useConfigurationEnabled(
    "integrationOnlyMeasurePrimaryProvider"
  );

  const bottomElement = props.hideUnknown ? (
    <MoreInfoWithLink appointmentId={appointmentId} patientId={patientId} />
  ) : null;

  // For anything except algorithmic details, we just return saying what happened.
  if (props.appointmentSource === SourceEnum.MANUAL) {
    return (
      <DecisionTable
        hideUnknown={props.hideUnknown}
        sections={[
          {
            topElement: t(sourceList[props.appointmentSource]),
            rows: [],
            bottomElement,
          },
        ]}
      />
    );
  }

  const algo = lastActivePatientSessionCreationAlgorithm || lastPatientSessionCreationAlgorithm;

  if (!algo) {
    return t("patients:appointments.patientSessionCreation.algorithmNotRun");
  }

  const successIndex = props.patientSessionExists
    ? failureReasonList.length + 1
    : failureReasonList.findIndex((row) => {
        return row[0] === algo.statusName;
      });

  if (successIndex === -1) {
    return t("patients:appointments.patientSessionCreation.algorithmNotRun");
  }

  const rows = useMemo(() => {
    const rowsToUse = failureReasonList.filter((reason) => {
      if (reason[2]) {
        return reason[2]({
          enableMeasurementPlans,
          enableTreatmentServices,
          integrationAppointmentDeduplicationWindowMinutes,
          integrationOnlyMeasurePrimaryProvider,
        });
      } else {
        return true;
      }
    });

    return rowsToUse.map((reason, index) => {
      return {
        description: t(reason[1]),
        result:
          index < successIndex
            ? AlgorithmDecisionResult.SUCCESS
            : index === successIndex
            ? AlgorithmDecisionResult.FAILURE
            : AlgorithmDecisionResult.UNKNOWN,
      };
    });
  }, [algo]);

  const sections: ReadonlyArray<DecisionTableSection> = [
    {
      topElement: props.patientSessionExists
        ? t("patients:appointments.patientSessionCreation.algorithmicSelection")
        : t("patients:appointments.patientSessionCreation.missingText"),
      rows,
      bottomElement,
    },
  ];

  return <DecisionTable hideUnknown={hideUnknown} sections={sections} />;
}

export function AppointmentPatientSessionCreationAlgorithmTooltip(props: {
  appointmentId: AppointmentId;
  patientId: PatientId;
  children: ReactElement;
  hideUnknown?: boolean;
}) {
  return (
    <TooltipBase
      content={
        <PatientSessionCreationAlgorithmTable
          appointmentId={props.appointmentId}
          patientId={props.patientId}
          hideUnknown={props.hideUnknown}
        />
      }
    >
      {props.children}
    </TooltipBase>
  );
}
