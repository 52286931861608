import { Box, Button } from "@mui/material";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import { IntakeStatus, Patient } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { IntakeDialog } from "./IntakeDialog";

type IntakeButtonProps = {
  patient: PickTypename<Patient, "id" | "name">;
  buttonMinWidth: string;
};

export function IntakeButton(props: IntakeButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showDialog, setShowDialog] = React.useState(false);

  const enrollment = useEnrollmentState(props.patient.id);
  const disabled = enrollment.status !== "enrolled" || enrollment.intakeStatus === IntakeStatus.COMPLETE;
  const dialog =
    enrollment.status === "enrolled" ? (
      <IntakeDialog
        open={showDialog}
        patient={props.patient}
        enrollmentId={enrollment.enrollmentId}
        careEpisodeId={enrollment.careEpisodeId}
        intakeStatus={enrollment.intakeStatus}
        onClose={() => setShowDialog(false)}
      />
    ) : null;

  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => setShowDialog(true)}
        disabled={disabled}
      >
        {t("collaborativeCare:enrollment.actions.intake")}
      </Button>
      {dialog}
    </Box>
  );
}
