const qualityIssues = {
  headers: {
    createdAt: "Since",
    patientName: "Patient",
    status: "Status",
    link: "Link",
    issue: "Issue",
  },
  saveNote: "Save Note",
  updateNote: "Update Note",
  issueCountWithTotalAndPending_one: "{{count}} issue ({{active}} active, {{pending}} pending)",
  issueCountWithTotalAndPending_other: "{{count}} issues ({{active}} active, {{pending}} pending)",
  issueCountWithTotal_one: "{{count}} issue ({{active}} active)",
  issueCountWithTotal_other: "{{count}} issues ({{active}} active)",
  issueCountWithPending_one: "{{count}} issue ({{pending}} pending)",
  issueCountWithPending_other: "{{count}} issues ({{pending}} pending)",
  issueCount_one: "{{count}} issue",
  issueCount_other: "{{count}} issues",
  pageTitle: "Mirah",
  title: "Patient Quality Issues",
  statuses: {
    RESOLVED: "Resolved",
    FIXED_IN_EHR: "Fixed in EHR",
    NOT_AN_ISSUE: "Not an issue",
    OUT_OF_TREATMENT: "Out of treatment",
    SNOOZE: "Snoozed for 7 days",
    UNRESOLVED: "Unresolved",
    WONT_FIX: "Won't fix",
  },
  actions: {
    RESOLVED: "Resolved in EMR",
    WONT_FIX: "Ignore",
    SNOOZE: "Snooze for 7 days",
    UNRESOLVED: "Re-open",
    unsnooze: "Unsnooze",
  },
  superStatuses: {
    active: {
      subTitle: "",
    },
    pending: {
      subTitle: "Snoozed by {{ provider }} until {{ statusUntil; date(format:MMM dd) }}",
    },
    resolved: {
      subTitle: "Resolved by {{ provider }} on {{ resolvedAt; date(format:MMM dd) }}",
    },
  },
  status: "Status",
  issue: "Issue",
  activeIssues: "Active",
  pendingIssues: "Pending",
  resolvedIssues: "Resolved",
  issueType: "Type of Issue",
  showAll: "Show All",
  allIssues: "All Issues",
  hideFiltered: "Hide Filtered",
  issues: {
    PATIENT_UNDER_18_NO_CAREGIVER: {
      title: "Missing Caregiver",
      longTitle: "Child Has No Active Caregiver",
      explanation:
        "Children under the age of 18 can benefit from having caregivers provide additional data. Younger children may not be able to complete assessments alone and may require a caregiver to supply information",
    },
    PATIENT_OVER_11_NO_CONTACT_INFO: {
      title: "No Contact Details",
      longTitle: "Patient has no contact details",
      explanation:
        "Without a valid phone number or email address, the patient will not receive measures ahead of sessions; if intended to complete measures, staff will have to manually provide access to the kiosk.",
    },
    PATIENT_OVER_11_CONTACT_FORBIDDEN: {
      title: "Contact Forbidden",
      longTitle: "Patient has opted out of contact",
      explanation:
        "The patient has asked Mirah not to send any notifications. Without these notifications, the patient may have trouble remembering to complete assessments.",
    },
    CAREGIVER_NO_CONTACT_INFO: {
      title: "Caregiver No Contact Details",
      longTitle: "Caregiver has no contact details",
      explanation:
        "Without a valid phone number or email address, the caregiver will not receive measures ahead of sessions; if intended to complete measures, staff will have to manually provide access to the kiosk.",
    },
    CAREGIVER_CONTACT_FORBIDDEN: {
      title: "Caregiver Contact Forbidden",
      longTitle: "Caregiver has opted out of contact",
      explanation:
        "The caregiver has asked Mirah not to send any notifications. Without these notifications, the patient may have trouble remembering to complete assessments.",
    },
    AGE_INCORRECT: {
      title: "Age Doubtful",
      longTitle: "The Age of this patient needs to be confirmed",
      explanation:
        "This age seems out of range. Check that the patient's date of birth is correct in the electronic medical record.",
    },
    PATIENT_NEVER_OPENED: {
      title: "Never Opened",
      longTitle: "Patient has not opened a notification",
      explanation:
        "The patient has never opened a measure link sent to their email and/or phone.  The intake staff or clinician should talk to the patient to help problem solve any technical barriers, concerns, or lack of understanding.",
    },
    CAREGIVER_NEVER_OPENED: {
      title: "Caregiver Never Opened",
      longTitle: "Caregiver has not opened a notification",
      explanation:
        "If this caregiver should be completing measures, they have not yet opened any measure links sent to their cell phone and/or email. The intake staff or clinician should talk to the caregiver to help problem solve any technical barriers, concerns, or lack of understanding.",
    },
    CAREGIVER_SHARES_CONTACT_INFO: {
      title: "Shared Contact Info",
      longTitle: "Caregiver shares contact information with the patient",
      explanation:
        // TODO: add link to the guide when available.
        // "The links for measures will all be sent to the same contact information. This can sometimes lead to confusion about who fills out what measures. We recommend that you discuss measure completion with this family to help everyone get clear. This guide (linked) has more information that can be helpful in talking with families about completing assessments."
        "The links for measures will all be sent to the same contact information. This can sometimes lead to confusion about who fills out what measures. We recommend that you discuss measure completion with this family to help everyone get clear.",
    },
    NOT_MEASURED_FOR_EXTENDED_PERIOD: {
      title: "Large Measurement Gap",
      longTitle: "No Data has been collected for this patient for over 6 weeks",
      explanation:
        "No one has taken any assessments for this patient in the last 6 weeks, despite at least 2 attempts to measure them.",
    },
  },
};

export default qualityIssues;
