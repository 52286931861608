import { Route, Routes } from "react-router-dom";
import React, { ReactElement } from "react";
import InstituteGroupList from "./InstituteGroupList";

function InstituteGroups(): ReactElement {
  return (
    <Routes>
      <Route element={<InstituteGroupList />} path="/*" />
    </Routes>
  );
}

export { InstituteGroups };
