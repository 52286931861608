import React from "react";
import NewEnrollmentsChart from "./NewEnrollmentsChart";
import EnrollmentAcuityChart from "./EnrollmentAcuityChart";
import AssessedPatientsChart from "./AssessedPatientsChart";
import CaseloadEfficiencyChart from "./CaseloadEfficiencyChart";
import { Grid } from "@mui/material";
import { InstituteId, ProviderId } from "Lib/Ids";

export default function PerformancePage(props: {
  startDate: Date;
  endDate: Date;
  careManagerIds: ReadonlyArray<ProviderId>;
  instituteId: InstituteId | null;
}) {
  return (
    <Grid container columns={2} spacing={1}>
      <Grid item lg={1} xs={2}>
        <NewEnrollmentsChart
          startDate={props.startDate}
          endDate={props.endDate}
          careManagerIds={props.careManagerIds}
          instituteId={props.instituteId}
        />
      </Grid>
      <Grid item lg={1} xs={2}>
        <AssessedPatientsChart
          startDate={props.startDate}
          endDate={props.endDate}
          careManagerIds={props.careManagerIds}
          instituteId={props.instituteId}
        />
      </Grid>
      <Grid item lg={1} xs={2}>
        <EnrollmentAcuityChart
          startDate={props.startDate}
          endDate={props.endDate}
          showNone={true}
          showLow={true}
          showMild={true}
          showModerate={true}
          showSevere={false}
          showModSevere={false}
          careManagerIds={props.careManagerIds}
          instituteId={props.instituteId}
        />
      </Grid>
      <Grid item lg={1} xs={2}>
        <CaseloadEfficiencyChart
          startDate={props.startDate}
          endDate={props.endDate}
          careManagerIds={props.careManagerIds}
          instituteId={props.instituteId}
        />
      </Grid>
    </Grid>
  );
}
