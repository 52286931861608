import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { scaleThresholdClassT } from "GeneratedGraphQL/EnumTranslations";
import { ScaleThresholdClass, SeverityCategory } from "GeneratedGraphQL/SchemaAndOperations";
import { enumFromStringValue } from "Shared/Enum";
import { SeverityChipBase } from "Shared/Scale/SeverityChip";
import React from "react";
import { useTranslation } from "react-i18next";

export function ScaleThresholdClassChip(props: { thresholdClass: ScaleThresholdClass }) {
  const { t } = useTranslation(["enums"]);
  return (
    <SeverityChipBase thresholdClass={props.thresholdClass} isStale={false}>
      {scaleThresholdClassT(props.thresholdClass, t)}
    </SeverityChipBase>
  );
}

function severityToPriority(severity: SeverityCategory) {
  switch (severity) {
    case SeverityCategory.NONE:
      return 0;
    case SeverityCategory.MILD:
      return 1;
    case SeverityCategory.MODERATE:
      return 2;
    case SeverityCategory.SEVERE:
      return 3;
    case SeverityCategory.INVALID:
      return 4;
    case SeverityCategory.NOT_SPECIFIED:
      return 5;
    case SeverityCategory.NOT_SUPPORTED:
      return 6;
  }
}

export function sortSeverities(severities: ReadonlyArray<SeverityCategory>): ReadonlyArray<SeverityCategory> {
  return [...severities].sort((a, b) => severityToPriority(a) - severityToPriority(b));
}

export function ScaleThresholdClassSelectMultiple(props: {
  thresholdClasses: ReadonlyArray<ScaleThresholdClass> | null;
  supportedThresholdClasses: ReadonlyArray<ScaleThresholdClass>;
  onChange: (severities: ReadonlyArray<ScaleThresholdClass> | null) => void;
  disabled: boolean;
  formHelperText?: string;
  label?: string;
  selectProps?: Omit<SelectProps, "multiple" | "onChange" | "value">;
}) {
  const { thresholdClasses, onChange, disabled } = props;
  const { t } = useTranslation(["common"]);

  const handleOnChange = (event: SelectChangeEvent<ReadonlyArray<ScaleThresholdClass>>) => {
    const finalArray: Array<ScaleThresholdClass> = [];
    Array.isArray(event.target.value)
      ? event.target.value.forEach((value) => {
          const parsed = enumFromStringValue<ScaleThresholdClass>(ScaleThresholdClass, value);

          if (parsed) {
            finalArray.push(parsed);
          }
        })
      : null;

    onChange(finalArray);
  };

  const items = props.supportedThresholdClasses.map((thresholdClass) => {
    return (
      <MenuItem key={thresholdClass} value={thresholdClass}>
        <ScaleThresholdClassChip thresholdClass={thresholdClass} />
      </MenuItem>
    );
  });

  const label = props.label ? <InputLabel>{props.label}</InputLabel> : null;
  const formHelperText = props.formHelperText ? (
    <FormHelperText>{props.formHelperText}</FormHelperText>
  ) : null;

  // Note that we don't want to support all the trends, only the valid ones, so this isn't a full enum
  return (
    <FormControl>
      {label}
      <Select
        {...props.selectProps}
        multiple
        displayEmpty
        sx={{ height: "2em" }}
        value={thresholdClasses ?? []}
        onChange={handleOnChange}
        disabled={disabled}
        renderValue={(selected: ReadonlyArray<ScaleThresholdClass>) => {
          if (selected.length === 0) {
            return <em>{t("common:all")}</em>;
          }

          return (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((item) => (
                <ScaleThresholdClassChip key={item} thresholdClass={item} />
              ))}
            </Box>
          );
        }}
      >
        {items}
      </Select>
      {formHelperText}
    </FormControl>
  );
}
