import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

import RouterWithNotFound from "../Shared/RouterWithNotFound";
import ProviderRoutes from "./Provider/ProviderRoutes";

function SettingsRoutes(): ReactElement {
  // Right now, we're modeling routes 1:1 with ember so one can simply slap app/settings
  // into a url and get from an ember object to the backing react settings. We will likely
  // want to change this in the future.
  return (
    <RouterWithNotFound>
      <Route element={<ProviderRoutes />} path="/provider/*" />
    </RouterWithNotFound>
  );
}

export default SettingsRoutes;
