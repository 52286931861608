import { Box, Skeleton } from "@mui/material";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { useScaleGroupHistoryQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId, ScaleGroupId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { Failure, RemoteData, Success } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { CareEpisodeScaleGroupHistory } from "Shared/Scale/ScaleGroup";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { LevelHeaderLoadingIndicator } from "FeedbackReport/LevelHeader";
import { parseScaleGroupHistoryQuery } from "FeedbackReport/DetailPane/DataStore/OverviewTreatmentResponseTransforms";
import ScaleGroupSfssHistory from "./ScaleGroupSfssHistoryPane";

// -- MODEL -------------------------------------------------------------------

type DetailedScaleGroupHistoryData = RemoteData<Error, CareEpisodeScaleGroupHistory>;

export type ScaleGroupHistoryPaneProps = {
  careEpisodeId: CareEpisodeId;
  scaleGroupId: ScaleGroupId;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleGroupHistoryPaneHookWrapper(props: ScaleGroupHistoryPaneProps): ReactElement {
  const { careEpisodeId, scaleGroupId, feedbackReportContext } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useScaleGroupHistoryQueryQuery({
      variables: {
        careEpisodeId,
        scaleGroupId,
      },
    })
  );

  const transformed: DetailedScaleGroupHistoryData = remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      if (result.assessmentCareEpisodeScaleGroupDetail) {
        return Success(
          parseScaleGroupHistoryQuery(
            result.assessmentCareEpisodeScaleGroupDetail.careEpisodeScaleGroupResult,
            feedbackReportContext.participantsByUserId
          )
        );
      } else {
        return Failure(new Error("Not Found"));
      }
    });

  return ScaleGroupHistory({
    remoteData: transformed,
    feedbackReportContext,
  });
}

// -- VIEW --------------------------------------------------------------------

function ScaleGroupHistoryLoadingIndicator(): ReactElement {
  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <Box sx={{ display: "flex", flexDirection: "row", pl: 10, mt: 8 }}>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <Skeleton variant="rectangular" height={180} width={34} />
        </Box>
      </Box>
    </div>
  );
}

type Props = {
  remoteData: DetailedScaleGroupHistoryData;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleGroupHistory(props: Props): ReactElement {
  const { remoteData, feedbackReportContext } = props;

  const content = remoteData.caseOf({
    NotAsked: () => <ScaleGroupHistoryLoadingIndicator />,
    Loading: () => <ScaleGroupHistoryLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (data) => {
      return <ScaleGroupSfssHistory data={data} feedbackReportContext={feedbackReportContext} />;
    },
  });

  return <div data-testid="scale-group-history">{content}</div>;
}

export default ScaleGroupHistoryPaneHookWrapper;
export { ScaleGroupHistoryPaneHookWrapper as HookWrapper, ScaleGroupHistory as Component };
