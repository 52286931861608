import React, { ReactElement, useState } from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import { useIsMobile } from "Shared/Responsive";
import { Assignment, CallMerge, Create } from "@mui/icons-material";
import MergeTaskDialog from "./MergeTaskDialog";
import { PatientId, TaskId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import EditPlaceholderTitleAndBodyDialog from "./EditPlaceholderTitleAndBodyDialog";
import { TaskTemplateSelect } from "CollaborativeCare/CreateOrEditTaskForm";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useUpdateTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";

type PlaceholderOptionsProps = {
  onSuccess?: () => void;
  setShowSurroundingForms?: (showForms: boolean) => void;
  patientId: PatientId;
  taskId: TaskId;
};

function PlaceholderOptions(props: PlaceholderOptionsProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [showingMergeDialog, setShowingMergeDialog] = useState<boolean>(false);
  const [showingEditDialog, setShowingEditDialog] = useState<boolean>(false);

  const [updateTask] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateTask,
    useUpdateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const columns = useIsMobile() ? 6 : 12;
  const column = columns / 3;
  const theme = useTheme();

  const borderStyle = "solid";
  const borderWidth = "1px";
  const outlineStyle = {
    borderRadius: theme.shape.borderRadius,
    borderWidth: borderWidth,
    borderStyle: borderStyle,
    borderColor: theme.palette.textInput.borderColor,
    alignContent: "center",
    paddingTop: "5px",
    paddingBottom: "5px",
  };

  const taskTemplatesContent = (
    <Grid item xs={column} textAlign="center" style={outlineStyle}>
      <Assignment fontSize="large"></Assignment>
      <div></div>
      <Typography variant="caption">
        {t("collaborativeCare:patientDetails.tasks.placeholders.descriptionTemplate")}
      </Typography>
    </Grid>
  );

  const optionsSelectionContent = (
    <>
      <Grid container columns={columns} spacing={0}>
        <Grid item xs={columns}>
          <Typography>{t("collaborativeCare:patientDetails.tasks.placeholders.descriptionStart")}</Typography>
        </Grid>
      </Grid>
      <Grid container columns={columns} columnGap={0} spacing={0} alignItems="stretch">
        <Grid
          item
          xs={column}
          textAlign="center"
          style={outlineStyle}
          onClick={() => setShowingMergeDialog(true)}
        >
          <CallMerge fontSize="large"></CallMerge>
          <div></div>
          <Typography variant="caption">
            {t("collaborativeCare:patientDetails.tasks.placeholders.descriptionMerge")}
          </Typography>
        </Grid>
        <TaskTemplateSelect
          hideCustom={true}
          onSelect={(t) => {
            if (t.title && t.body) {
              updateTask({
                variables: {
                  input: {
                    taskId: props.taskId,
                    title: t.title,
                    body: t.body,
                  },
                },
              });
            }
          }}
          renderInStack={false}
          component={taskTemplatesContent}
        ></TaskTemplateSelect>
        <Grid
          item
          xs={column}
          textAlign="center"
          style={outlineStyle}
          onClick={() => setShowingEditDialog(true)}
        >
          <Create fontSize="large"></Create>
          <div></div>
          <Typography variant="caption">
            {t("collaborativeCare:patientDetails.tasks.placeholders.descriptionDetails")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container columns={columns} spacing={0}>
        <Grid item xs={columns}>
          <Typography>{t("collaborativeCare:patientDetails.tasks.placeholders.descriptionEnd")}</Typography>
        </Grid>
      </Grid>
    </>
  );

  let content = optionsSelectionContent;

  if (showingMergeDialog) {
    content = (
      <MergeTaskDialog
        onBack={() => setShowingMergeDialog(false)}
        onSuccess={() => {
          setShowingMergeDialog(false);
          if (props.onSuccess) {
            props.onSuccess();
          }
        }}
        patientId={props.patientId}
        taskId={props.taskId}
        setShowSurroundingForms={props.setShowSurroundingForms}
      ></MergeTaskDialog>
    );
  } else if (showingEditDialog) {
    content = (
      <EditPlaceholderTitleAndBodyDialog
        onBack={() => setShowingEditDialog(false)}
        onSuccess={() => {
          setShowingEditDialog(false);
        }}
        patientId={props.patientId}
        taskId={props.taskId}
        setShowSurroundingForms={props.setShowSurroundingForms}
      ></EditPlaceholderTitleAndBodyDialog>
    );
  }
  React.useEffect(() => {
    // Now the parent state is updated after rendering, avoiding the error
    if (showingMergeDialog || showingEditDialog) {
      //if we're showing any of the dialogs, don't show the surrounding content
      if (props.setShowSurroundingForms) {
        props.setShowSurroundingForms(false);
      }
    } else {
      if (props.setShowSurroundingForms) {
        props.setShowSurroundingForms(true);
      }
    }
  }, [showingMergeDialog, showingEditDialog]);
  return <>{content}</>;
}

export default PlaceholderOptions;
