import { Box, Typography, useTheme } from "@mui/material";
import { format, formatDistanceToNowStrict } from "date-fns";
import { AcuteChangeCategory, Trend, Scale as GraphqlScale } from "GeneratedGraphQL/SchemaAndOperations";
import * as NEL from "Lib/NonEmptyList";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import React, { ReactElement } from "react";
import { Maybe } from "seidr";
import { ParticipantSummary } from "Shared/Participant";
import { AcuteChangeIndicatorWithNameWithoutTooltip } from "Shared/Scale/AcuteChangeIndicator";
import {
  CareEpisodeCategoricalComputedValueDetails,
  CareEpisodeComputedValueDetails,
  CareEpisodeNumericalComputedValueDetails,
  isCategorical,
  isInvalid,
  isNumerical,
} from "Shared/Scale/CareEpisodeComputedValueDetails";
import * as Scale from "Shared/Scale/Scale";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import ScaleScoresHeatmapWithChip from "Shared/Scale/ScaleScoresHeatmapWithChip";
import * as Threshold from "Shared/Scale/Threshold";
import { TrendIndicatorWithNameWithoutTooltip } from "Shared/Scale/TrendIndicator";
import { formatValue } from "Shared/Severity/SeverityHistory";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";

type ScaleScoreWithHistoryTooltipProps = {
  children: ReactElement;
  history: ScaleScorerHistory;
  administrationDates: NEL.NonEmptyList<Date>;
};

function ScaleScoreWithHistoryTooltip(props: ScaleScoreWithHistoryTooltipProps): ReactElement {
  const { children, history, administrationDates } = props;

  const heatmap =
    history.scores.length === 0 ? (
      <Typography>Administered for a future session</Typography>
    ) : (
      <ScaleScoresHeatmapWithChip
        height={24}
        maxWidth={200}
        scores={history.scores}
        administrationDates={administrationDates}
      />
    );

  const scoreText = history.latest.caseOf({
    Nothing: () => null,
    Just: (administration) => (
      <ScoreText isFresh={true} participant={history.participant} administration={administration} />
    ),
  });
  const content = (
    <>
      <Box sx={{ mt: 0.5 }}>{heatmap}</Box>
      {scoreText}
    </>
  );

  return (
    <TooltipBase
      content={content}
      title={Scale.scaleFriendlyName(history.scale)}
      subtitle={history.scale.name}
    >
      {children}
    </TooltipBase>
  );
}

type ScaleScoreTooltipProps = {
  administration: CareEpisodeComputedValueDetails;
  participant: ParticipantSummary;
  scale: Pick<GraphqlScale, "name" | "shortname" | "nanoname" | "friendlyName">;
  isFresh?: boolean;
  children: ReactElement;
};

function ScaleScoreTooltip(props: ScaleScoreTooltipProps): ReactElement {
  const { administration, scale, children, participant } = props;

  const isFresh = props.isFresh || false;

  const content = <ScoreText isFresh={isFresh} participant={participant} administration={administration} />;

  return (
    <TooltipBase content={content} title={Scale.scaleFriendlyName(scale)} subtitle={scale.name}>
      {children}
    </TooltipBase>
  );
}

function generateAcuteChange(administration: CareEpisodeComputedValueDetails, isFresh: boolean) {
  switch (administration.acuteChangeCategory) {
    case AcuteChangeCategory.IMPROVING:
    case AcuteChangeCategory.DECLINING:
      return (
        <p>
          This represent{isFresh ? "s" : "ed"} an{" "}
          <AcuteChangeIndicatorWithNameWithoutTooltip acuteChange={administration.acuteChangeCategory} />
        </p>
      );
      break;
    default:
      return null;
  }
}

function createTrendNarrative(administration: CareEpisodeComputedValueDetails, isFresh: boolean) {
  switch (administration.trend) {
    case Trend.DECLINING:
    case Trend.IMPROVING:
    case Trend.STABLE:
      return isFresh ? (
        <span>
          Since treatment start, the trend is{" "}
          <TrendIndicatorWithNameWithoutTooltip trend={administration.trend} />.
        </span>
      ) : (
        <span>
          At this point in treatment, the trend was{" "}
          <TrendIndicatorWithNameWithoutTooltip trend={administration.trend} />.
        </span>
      );
      break;
    case Trend.NOT_SPECIFIED:
    case Trend.NOT_SUPPORTED:
    case Trend.INVALID:
      return null;
  }
}

type ScoreTextProps = {
  administration: CareEpisodeComputedValueDetails;
  participant: ParticipantSummary;
  isFresh: boolean;
};

function generateInvalidScoreText(): ReactElement {
  return <>This score was invalid</>;
}

function generateNumericalScoreText(
  administration: CareEpisodeNumericalComputedValueDetails,
  scoreColor: { color: string }
): ReactElement {
  return (
    <>
      They scored a <strong style={scoreColor}>{formatValue(administration)}</strong>{" "}
      {Maybe.fromNullable(administration.thresholdMnemonic).caseOf({
        Just: (mnemonic) => (
          <>
            which is classified as <strong style={scoreColor}>{Threshold.mnemonicTitle(mnemonic)}</strong>
          </>
        ),
        Nothing: () => null,
      })}
    </>
  );
}

function generateCategoricalScoreText(
  administration: CareEpisodeCategoricalComputedValueDetails,
  scoreColor: { color: string }
): ReactElement {
  if (!administration.thresholdMnemonic) {
    // We should never get here, because by definition a categorical scale needs thresholds, but until we can enforce it in graphql, just do something sensible.
    return (
      <>
        Their score classified as <strong style={scoreColor}>unknown</strong>
      </>
    );
  }

  return (
    <>
      Their score classified as{" "}
      <strong style={scoreColor}>{Threshold.mnemonicTitle(administration.thresholdMnemonic)}</strong>
    </>
  );
}

function ScoreText({ administration, participant, isFresh }: ScoreTextProps): ReactElement | null {
  const theme = useTheme();
  const dateText = isFresh ? null : (
    <Typography variant="h3">{format(administration.targetDate, "MMM dd")}</Typography>
  );

  const trendNarrative = createTrendNarrative(administration, isFresh);

  const acuteChangeNarrative = generateAcuteChange(administration, isFresh);

  const scoreColor = {
    color: SeverityThresholdClass.color(administration.thresholdClass, theme).text,
  };

  const whenText = isFresh ? (
    <>
      <strong>{participant.name}</strong> last took this{" "}
      {formatDistanceToNowStrict(administration.targetDate)} ago on{" "}
      <strong>{format(administration.targetDate, "MMM dd")}</strong>.
    </>
  ) : (
    <>
      This score is from when <strong>{participant.name}</strong> took this{" "}
      {formatDistanceToNowStrict(administration.targetDate)} ago on{" "}
      <strong>{format(administration.targetDate, "MMM dd")}</strong>.
    </>
  );

  let valueText;

  // We have four different options:
  //  - Values that are simply invalid
  //  - Numerical scales with a value
  //  - Categorical scales without a value but with a threshold
  //  - Unscored completions without anything.
  if (isInvalid(administration)) {
    valueText = generateInvalidScoreText();
  } else if (isNumerical(administration)) {
    valueText = generateNumericalScoreText(administration, scoreColor);
  } else if (isCategorical(administration)) {
    valueText = generateCategoricalScoreText(administration, scoreColor);
  }

  return (
    <>
      {dateText}
      <p>
        {whenText} {valueText}.
      </p>
      {acuteChangeNarrative}
      {trendNarrative}
    </>
  );
}

export { ScaleScoreWithHistoryTooltip, ScaleScoreTooltip };
