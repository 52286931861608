import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiFormHelperText"] = {
  root: {
    // The default MUI theme has this at ~0.8 rem, which is way small
    fontSize: "1rem",
  },
};

const override = {
  styleOverrides,
};

export default override;
