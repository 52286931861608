import { SvgIcon, SvgIconProps, styled } from "@mui/material";
import React from "react";

const RotatingPath = styled("path", { shouldForwardProp: (name) => name != "duration" })<{
  duration: number;
}>(({ duration }) => ({
  animationName: "rotate",
  animationDuration: `${duration}s`,
  animationIterationCount: "infinite",
  animationTimingFunction: "linear",
  // This is the middle of the 24x24 viewBox. If I'm doing my math right it's not the center-endpoint of the arm rects,
  // though at the size we're drawing this it doesn't seem to cause noticeable visual artifacts.
  transformOrigin: "12px 12px",
}));

export function TimeIndicatorIcon(props: SvgIconProps) {
  const keyframes = {
    "@keyframes rotate": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(360deg)",
      },
    },
  };
  const sx = { ...props.sx, ...keyframes };

  // SVG path here adapted from https://mui.com/material-ui/material-icons/?selected=AccessTime.
  return (
    <SvgIcon {...props} sx={sx}>
      <svg focusable="false" viewBox="0 0 24 24">
        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8"></path>
        <RotatingPath duration={60} d="M12.5 9H11v4H12.5z"></RotatingPath>
        <RotatingPath duration={10} d="M12.5 7H11v6H12.5z"></RotatingPath>
      </svg>
    </SvgIcon>
  );
}
