import React, { ReactElement, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Patient, Task, TaskStatus, useSetTaskStatusMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { TaskId } from "Lib/Ids";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import {
  Button,
  ButtonProps,
  DialogContent,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Form, FormOverlay, useBooleanField, useForm, useTextField } from "Shared/Form";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { refetchQueries } from "Lib/RefetchQueries";
import { taskStatusT } from "GeneratedGraphQL/EnumTranslations";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type TaskCompleteFormProps = {
  taskId: TaskId;
  patient: Pick<Patient, "id"> | null;
  selectedStatus: TaskStatus;
  onSuccess?: () => void;
};

export function TaskCompleteForm(props: TaskCompleteFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);
  // TODO: UX issue- adding the refetch query for MyTasksDocument makes it so notes appear in the notes section if
  // a user unchecks mark complete
  // but it also means if they check mark complete, the task immediately disappears...
  // we expect the update to SetTaskStatusMutation pulling notes nodes to also update the
  // notes list view, but it currently does not. investigate further after this refactor goes out
  const [markComplete, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSetTaskStatus,
    useSetTaskStatusMutation({
      refetchQueries: refetchQueries("timeEntries"),
      variables: {
        input: {
          taskId: props.taskId,
          status: props.selectedStatus,
        },
      },
    })
  );

  const fields = {
    completeTask: useBooleanField({ required: false, default: true }),
    noteText: useTextField({ required: false }),
  };

  const form = useForm({
    fields: fields,
    submit: () => {
      markComplete({
        variables: {
          input: {
            noteText: fields.noteText.value as string,
            status: props.selectedStatus,
            taskId: props.taskId,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: props.onSuccess,
  });

  return (
    <Form onSubmit={form.onSubmit}>
      <FormOverlay response={remoteData} errorMessage={t("collaborativeCare:tasks.genericFormError")} />
      <Stack direction="column" spacing={1}>
        <TextField
          multiline
          minRows={5}
          title={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          label={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
          autoFocus
          value={fields.noteText.value}
          onChange={fields.noteText.onChange}
          error={fields.noteText.error}
        />
        <FormControl>
          <FormHelperText>
            {t("collaborativeCare:tasks.timeEntryLog.TaskWillBeCompletedHelp", {
              status: taskStatusT(props.selectedStatus, t),
            })}
          </FormHelperText>
        </FormControl>
        <Stack direction="row-reverse" spacing={1}>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            type="submit"
            showSpinner={form.showSpinner}
            disabled={form.disableSubmit}
          >
            {t("common:actions.save")}
          </ButtonWithSpinner>
        </Stack>
      </Stack>
    </Form>
  );
}

type TaskCompleteButtonProps = ButtonProps & {
  task: Pick<Task, "id" | "title">;
  patient: Pick<Patient, "id"> | null;
  alreadyComplete: boolean;
  onSuccess?: () => void;
};

// Task Complete Button pops up a modal to add a note and confirm you want to complete
// Similar to the Complete button from Timer Stop Button
export function TaskCompleteButton(props: TaskCompleteButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showForm, setShowForm] = React.useState(false);
  const variant = props.variant || "outlined";
  const color = props.color || "secondary";
  const button =
    props.disabled || props.alreadyComplete ? (
      <Tooltip
        title={t("collaborativeCare:tasks.actions.tooltips.disabledCompleteBlockedByTracking")}
        arrow
        placement="right-end"
      >
        <div>
          <Button onClick={() => setShowForm(true)} disabled={true} variant={variant} color={color}>
            {t("collaborativeCare:tasks.actions.complete")}
          </Button>
        </div>
      </Tooltip>
    ) : (
      <Button onClick={() => setShowForm(true)} disabled={false} variant={variant} color={color}>
        {t("collaborativeCare:tasks.actions.complete")}
      </Button>
    );
  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:tasks.actions.completeForm") + ": " + props.task.title}
      >
        <DialogContent>
          {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
                form goes away */}
          <TaskCompleteForm
            taskId={props.task.id}
            patient={props.patient}
            selectedStatus={TaskStatus.COMPLETE}
            onSuccess={() =>
              setTimeout(() => {
                setShowForm(false);
                if (props.onSuccess) {
                  props.onSuccess();
                }
              }, 300)
            }
          />
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

type TaskStatusBadgeProps = ButtonProps & {
  task: Pick<Task, "id" | "title">;
  patient: Pick<Patient, "id"> | null;
  currentStatus: TaskStatus;
  onSuccess?: () => void;
  size?: string;
};

// the Task Mark As button allows the user to select the state they want to transition the task to
export function TaskStatusBadge(props: TaskStatusBadgeProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);
  const [selectedStatus, setSelectedStatus] = React.useState<TaskStatus>(TaskStatus.COMPLETE);
  const [showForm, setShowForm] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const handleClick = () => {
    setMenuOpen(true);
  };
  const handleClose = () => {
    setMenuOpen(false);
  };
  const variant = props.variant || "outlined";
  const color = props.color || "secondary";
  let button = <></>;
  const selectableStatuses: Array<ReactElement> = [];
  for (const status in TaskStatus) {
    if (props.currentStatus != (status as TaskStatus)) {
      const item = (
        <MenuItem
          key={status}
          onClick={() => {
            setSelectedStatus(status as TaskStatus);
            setShowForm(true);
            handleClose();
          }}
          disabled={false}
          color={color}
        >
          {taskStatusT(status as TaskStatus, t)}
        </MenuItem>
      );
      selectableStatuses.push(item);
    }
  }
  const statusButton = useRef(null);
  button = (
    <>
      <Button
        size={props.size}
        onClick={handleClick}
        variant={variant}
        color={color}
        endIcon={<KeyboardArrowDownIcon />}
        ref={statusButton}
      >
        {taskStatusT(props.currentStatus, t)}
      </Button>
      <Menu
        open={menuOpen}
        onClose={handleClose}
        anchorEl={statusButton.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {selectableStatuses}
      </Menu>
    </>
  );

  const dialog = (
    <ResponsiveDialog
      open={showForm}
      onClose={() => setShowForm(false)}
      title={
        t("collaborativeCare:tasks.actions.completeForm", { status: taskStatusT(selectedStatus, t) }) +
        ": " +
        props.task.title
      }
    >
      <DialogContent>
        {/* Pausing for a couple hundred millis here so that users have a chance to see the checkmark before the
        form goes away */}
        <TaskCompleteForm
          taskId={props.task.id}
          patient={props.patient}
          selectedStatus={selectedStatus}
          onSuccess={() =>
            setTimeout(() => {
              setShowForm(false);
              if (props.onSuccess) {
                props.onSuccess();
              }
            }, 300)
          }
        />
      </DialogContent>
    </ResponsiveDialog>
  );
  return (
    <>
      {button}
      {dialog}
    </>
  );
}
