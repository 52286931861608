import { Failure, Loading, NotAsked, RemoteData, Success } from "seidr";

export function remotePair<Err, ValLeft, ValRight>(
  left: RemoteData<Err, ValLeft>,
  right: RemoteData<Err, ValRight>
): RemoteData<Err, [ValLeft, ValRight]> {
  return left.caseOf({
    NotAsked: () => NotAsked(),
    Loading: () => Loading(),
    Failure: (err) => Failure(err),
    Success: (leftVal) =>
      right.caseOf({
        NotAsked: () => NotAsked(),
        Loading: () => Loading(),
        Failure: (err) => Failure(err),
        Success: (rightVal) => Success([leftVal, rightVal]),
      }),
  });
}

export function remoteDataGetOrElse<E, A>(data: RemoteData<E, A>, defaultValue: A) {
  return data.caseOf({
    Success: (s) => s,
    _: () => defaultValue,
  });
}
