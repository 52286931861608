import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Box } from "@mui/material";
import { WithPermission } from "Shared/WithPermission";
import IntegrationStatusBadge from "Integrations/IntegrationStatusBadge";
import { ImportHistoryId, PatientId } from "Lib/Ids";
import { DataSourceResourceType } from "GeneratedGraphQL/SchemaAndOperations";
import { AppointmentDetails } from "./AppointmentDetails";
import { WithFeature } from "Contexts/CurrentInstituteContext";
import InfoSidebarCard, { LabelAndInfo, LabelAndRichInfo } from "Shared/InfoSidebarCard";
import { appointmentStatusT, recurrenceFrequencyT } from "GeneratedGraphQL/EnumTranslations";
import { EditAppointmentButton } from "./EditAppointmentButton";
import { patientCareEpisodeUrl } from "Patients/CareEpisodes2";
import Link from "MDS/Link";

type AppointmentInfoCardProps = {
  appointment: AppointmentDetails;
  patientId: PatientId;
};
export default function AppointmentInfoCard(props: AppointmentInfoCardProps): ReactElement {
  const { t } = useTranslation(["report", "enums", "common", "patients"]);

  const cardAction = (
    <WithPermission permission="scheduleAppointment">
      <Box marginRight="0.3em">
        <EditAppointmentButton appointmentId={props.appointment.id} />
      </Box>
    </WithPermission>
  );

  let recurrenceBox = null;

  if (props.appointment.patientSession?.schedulingRecurrence) {
    const recurrence = props.appointment.patientSession.schedulingRecurrence;
    recurrenceBox = (
      <Alert severity="info">
        {t("patients:appointments.recurrence", {
          date: recurrence.endDate,
          recurrence: recurrenceFrequencyT(recurrence.repeatFrequency, t),
        })}
      </Alert>
    );
  }

  const header = (
    <>
      <IntegrationStatusBadge
        importHistoryId={props.appointment.id as unknown as ImportHistoryId}
        resourceType={DataSourceResourceType.APPOINTMENT}
      />
      {recurrenceBox}
    </>
  );

  return (
    <InfoSidebarCard
      action={cardAction}
      header={header}
      title={t("patients:appointments.cards.appointmentDetails")}
    >
      <LabelAndInfo
        label={t("patients:appointments.fields.startDate")}
        data={
          props.appointment.startDate
            ? t("common:date.tinyWithTime", { date: props.appointment.startDate })
            : t("common:unknown")
        }
      />
      <LabelAndInfo
        label={t("patients:appointments.fields.minutesDuration")}
        data={
          props.appointment.minutesDuration
            ? props.appointment.minutesDuration.toString()
            : t("common:unknown")
        }
      />
      <LabelAndInfo
        label={t("patients:appointments.fields.status")}
        data={appointmentStatusT(props.appointment.status, t)}
      />
      <LabelAndInfo
        label={t("patients:appointments.fields.serviceType")}
        data={props.appointment.serviceType}
      />
      <LabelAndInfo
        label={t("patients:appointments.fields.provider")}
        data={props.appointment.provider?.name || null}
      />
      <LabelAndRichInfo
        label={t("patients:appointments.fields.careEpisode")}
        data={
          props.appointment.careEpisode ? (
            <Link to={patientCareEpisodeUrl(props.appointment.careEpisode.id, props.patientId)}>
              {props.appointment.careEpisode.name}
            </Link>
          ) : null
        }
      />
      <WithFeature feature="enableTreatmentServices">
        <LabelAndInfo
          label={t("patients:appointments.fields.treatmentService")}
          data={props.appointment.treatmentService?.name || null}
        />
      </WithFeature>
      <LabelAndInfo
        label={t("patients:appointments.fields.organization")}
        data={props.appointment.organization?.name || null}
      />
    </InfoSidebarCard>
  );
}
