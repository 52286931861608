import {
  Alert,
  Box,
  Button,
  DialogContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  InvitationFormat,
  ManagedBy,
  SurveyManagementIndexQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement, ReactNode } from "react";
import { DigUnpacked } from "type-utils";
import EmailIcon from "@mui/icons-material/Email";
import TextsmsIcon from "@mui/icons-material/Textsms";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { transformRawStatus } from "./DetailTabs/SurveyManagementTab/InviteSummary";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import {
  CancelInvitationButton,
  DownloadPdfButton,
  SendReminderButton,
} from "./DetailTabs/SurveyManagementTab/SurveyManagementTableShared";
import { CareEpisodeId } from "Lib/Ids";
import LinkIcon from "@mui/icons-material/Link";

type QueryInvitation = DigUnpacked<
  SurveyManagementIndexQuery,
  ["assessmentCareEpisodeScaleSummary", "invitations"]
>;

type NotificationType = DigUnpacked<
  SurveyManagementIndexQuery,
  ["assessmentCareEpisodeScaleSummary", "invitations", "invitationNotifications"]
>;

type TimelineEvent = {
  type: string;
  date?: Date;
};

type ElementAndCompleteStatus = {
  element: ReactNode;
  complete: boolean;
};

function InvitationHistoryTimeline(props: { rawInvitation: QueryInvitation }) {
  const { t } = useTranslation(["common", "collaborativeCare", "common"]);

  const events: Array<TimelineEvent> = [];

  events.push({ type: "created", date: props.rawInvitation.createdAt });

  if (props.rawInvitation.status == "EXPIRED" && props.rawInvitation.closesAt) {
    events.push({ type: "expired", date: props.rawInvitation.closesAt });
  }

  if (props.rawInvitation.canceledAt) {
    events.push({ type: "canceled", date: props.rawInvitation.canceledAt });
  }

  if (props.rawInvitation.dateBegun) {
    events.push({ type: "begun", date: props.rawInvitation.dateBegun });
  }
  if (
    props.rawInvitation.status != "EXPIRED" &&
    props.rawInvitation.status != "CANCELED" &&
    props.rawInvitation.status != "MANUALLY_CANCELED" &&
    !props.rawInvitation.dateBegun
  ) {
    events.push({ type: "not_begun" });
  }

  if (props.rawInvitation.dateCompleted) {
    events.push({ type: "completed", date: props.rawInvitation.dateCompleted });
  }
  if (
    props.rawInvitation.status != "EXPIRED" &&
    props.rawInvitation.status != "CANCELED" &&
    props.rawInvitation.status != "MANUALLY_CANCELED" &&
    !props.rawInvitation.dateCompleted
  ) {
    events.push({ type: "not_complete" });
  }
  if (
    props.rawInvitation.status != "EXPIRED" &&
    props.rawInvitation.status != "CANCELED" &&
    props.rawInvitation.status != "MANUALLY_CANCELED" &&
    !props.rawInvitation.dateCompleted &&
    props.rawInvitation.closesAt
  ) {
    events.push({ type: "expires_future", date: props.rawInvitation.closesAt });
  }

  props.rawInvitation.invitationNotifications
    .slice()
    .sort(function (a: NotificationType, b: NotificationType) {
      const aMax = Math.max(a.dateEmailed?.getTime() || 0, a.dateTexted?.getTime() || 0);
      const bMax = Math.max(b.dateEmailed?.getTime() || 0, b.dateTexted?.getTime() || 0);
      if (aMax < bMax) return -1;
      if (bMax < aMax) return 1;
      return 0;
    })
    .forEach((invitationNotification: NotificationType) => {
      if (invitationNotification.status == "DUPLICATE" || invitationNotification.status == "NOT_NEEDED") {
        return;
      }
      if (invitationNotification.status == "MISSING_DETAILS" && invitationNotification.sendAt) {
        events.push({ type: "missing_details", date: invitationNotification.sendAt });
      } else if (invitationNotification.status == "FORBIDDEN" && invitationNotification.sendAt) {
        events.push({ type: "forbidden", date: invitationNotification.sendAt });
      } else if (invitationNotification.dateTexted) {
        events.push({ type: "texted", date: invitationNotification.dateTexted });
      } else if (invitationNotification.dateEmailed) {
        events.push({ type: "emailed", date: invitationNotification.dateEmailed });
      } else if (invitationNotification.sendAt) {
        events.push({ type: "scheduled", date: invitationNotification.sendAt });
      }
    });
  let innerList = <></>;
  const eventElements: Array<ElementAndCompleteStatus> = [];
  const sortedEvents = events.sort((a: TimelineEvent, b: TimelineEvent) => {
    //expires_future events go all the way to the bottom
    if (a.type == "expires_future") return 1;
    if (b.type == "expires_future") return -1;

    //put created at the top no matter what
    if (a.type == "created") return -1;
    if (b.type == "created") return 1;

    //entries without a date should to the bottom, in the order they came in
    if (!a.date && !b.date) return 0;
    if (!a.date) return 1;
    if (!b.date) return -1;
    if (a.date.getTime() < b.date.getTime()) return -1;
    if (b.date.getTime() < a.date.getTime()) return 1;
    return 0;
  });
  sortedEvents.forEach((event: TimelineEvent) => {
    const date = t("common:date.tinyWithTime", {
      date: event.date,
    });
    if (event.type == "created") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <AddBoxIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.createdAt")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "texted") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <TextsmsIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.sentText")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "emailed") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <EmailIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.sentEmail")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "missing_details") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <ContactSupportIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.missingDetails")}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "forbidden") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <PhoneDisabledIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.forbidden")}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "scheduled") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <CalendarMonthIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.scheduled")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: false,
      });
    }
    if (event.type == "completed") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.completed")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "not_complete") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.notCompleted")}
            </ListItem>
          </>
        ),
        complete: false,
      });
    }
    if (event.type == "begun") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <PlayCircleFilledWhiteIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.begun")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "not_begun") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <PlayCircleFilledWhiteIcon />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.notBegun")}
            </ListItem>
          </>
        ),
        complete: false,
      });
    }
    if (event.type == "canceled") {
      if (props.rawInvitation.canceledByUser) {
        eventElements.push({
          element: (
            <>
              <ListItem>
                <ListItemIcon>
                  <CancelIcon color="primary" />
                </ListItemIcon>
                {props.rawInvitation.canceledByUser.name}{" "}
                {t("collaborativeCare:surveyManagement.invitationHistory.canceled")} {date.toString()}
              </ListItem>
            </>
          ),
          complete: true,
        });
      } else {
        eventElements.push({
          element: (
            <>
              <ListItem>
                <ListItemIcon>
                  <CancelIcon color="primary" />
                </ListItemIcon>
                {t("collaborativeCare:surveyManagement.invitationHistory.canceledNoName")} {date.toString()}
              </ListItem>
            </>
          ),
          complete: true,
        });
      }
    }
    if (event.type == "expired") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <TimerOffIcon color="primary" />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.expired")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: true,
      });
    }
    if (event.type == "expires_future") {
      eventElements.push({
        element: (
          <>
            <ListItem>
              <ListItemIcon>
                <TimerOffIcon />
              </ListItemIcon>
              {t("collaborativeCare:surveyManagement.invitationHistory.expiresFuture")} {date.toString()}
            </ListItem>
          </>
        ),
        complete: false,
      });
    }
  });

  //add the vertical ... below all the timeline events except for the last
  eventElements.slice(0, -1).forEach((element) => {
    const variant = element.complete ? "primary" : undefined;
    innerList = (
      <>
        {innerList}
        {element.element}
        <>
          <ListItem>
            <ListItemIcon>
              <MoreVertIcon color={variant} />
            </ListItemIcon>
          </ListItem>
        </>
      </>
    );
  });
  //and add the last one to the content
  innerList = (
    <>
      <Typography variant="h2">
        {t("collaborativeCare:surveyManagement.invitationHistory.timeline")}
      </Typography>
      {innerList}
      {eventElements[eventElements.length - 1]?.element}
    </>
  );

  //now wrap the whole thing in a list
  return <List dense={true}>{innerList}</List>;
}

/**
 * The external timeline is substantially simpler. It includes a warning say this was externally administered and then events
 * for when it was imported and when it was created.
 */
function InvitationHistoryTimelineExternal(props: { rawInvitation: QueryInvitation }) {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const completedItem = props.rawInvitation.dateCompleted ? (
    <ListItem>
      <ListItemIcon>
        <CheckCircleIcon color="primary" />
      </ListItemIcon>
      {t("collaborativeCare:surveyManagement.invitationHistory.completed")}{" "}
      {t("common:date.tinyWithTime", { date: props.rawInvitation.dateCompleted })}
    </ListItem>
  ) : null;
  return (
    <>
      <Alert icon={<LinkIcon />} severity="info">
        {t("collaborativeCare:surveyManagement.invitationHistory.managedExternally")}
      </Alert>

      <List dense={true}>{completedItem}</List>
    </>
  );
}

export default function InvitationHistory(props: {
  open: boolean;
  rawInvitation: QueryInvitation;
  careEpisodeId: CareEpisodeId;
  forPreviousEnrollment?: boolean;
  onClose?: () => void;
}): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare", "common"]);

  const indentStyle = { marginLeft: "20px" };
  const content = (
    <>
      <Typography variant="h2">
        {t("collaborativeCare:surveyManagement.invitationHistory.recipient")}{" "}
      </Typography>
      <Typography sx={indentStyle}>{props.rawInvitation.user.name}</Typography>
      <Typography variant="h2">
        {t("collaborativeCare:surveyManagement.invitationHistory.measures")}{" "}
      </Typography>
      <Typography sx={indentStyle}>
        {props.rawInvitation.reportScales
          .map((rs) => {
            return rs.scale;
          })
          .map((s) => {
            return s.shortname;
          })
          .join(", ")}
      </Typography>
      <Typography variant="h2">{t("collaborativeCare:surveyManagement.invitationHistory.status")}</Typography>
      <Typography sx={indentStyle}>
        {transformRawStatus(
          props.rawInvitation.status,
          props.rawInvitation.status,
          props.rawInvitation.format == InvitationFormat.INTERVIEW,
          t
        )}
      </Typography>
    </>
  );

  const list =
    props.rawInvitation.managedBy === ManagedBy.EXTERNAL ? (
      <InvitationHistoryTimelineExternal rawInvitation={props.rawInvitation} />
    ) : (
      <InvitationHistoryTimeline rawInvitation={props.rawInvitation} />
    );

  let reportLink = "";
  if (props.rawInvitation.reportScales[0]) {
    reportLink = `/app/care-units/${props.rawInvitation.patient.id}/care-episodes/${
      props.careEpisodeId
    }/feedback-report/scale-${
      props.rawInvitation.reportScales[0].scale.scaleGroup?.id ? "group" : "administration"
    }-history/${
      props.rawInvitation.reportScales[0].scale.scaleGroup?.id || props.rawInvitation.reportScales[0].scale.id
    }`;
  }
  return (
    <Box>
      <ResponsiveDialog
        open={props.open}
        onClose={props.onClose}
        title={t("collaborativeCare:surveyManagement.invitationHistory.title")}
      >
        <DialogContent>
          <Grid container columns={12} spacing={1}>
            <Grid item lg={5} xs={12}>
              {content}
            </Grid>
            <Grid item lg={3} xs={12}>
              <Button
                disabled={props.forPreviousEnrollment || !props.rawInvitation.manuallyAdministerable}
                variant="contained"
                color="secondary"
                sx={{ width: "100%" }}
                href={`/provider/assessment/invitation/${props.rawInvitation.id}/edit`}
              >
                {t("collaborativeCare:surveyManagement.actions.administerNow")}
              </Button>
              <div style={{ marginBottom: "20px" }}></div>
              <SendReminderButton
                disabled={props.forPreviousEnrollment || !props.rawInvitation.canResendNotifications || false}
                invitationId={props.rawInvitation.id}
                careEpisodeId={props.careEpisodeId}
                mobile={true}
              />
              <div style={{ marginBottom: "20px" }}></div>
              <CancelInvitationButton
                disabled={!props.rawInvitation.manuallyCancelable}
                invitationId={props.rawInvitation.id}
                careEpisodeId={props.careEpisodeId}
                mobile={true}
              ></CancelInvitationButton>
            </Grid>
            <Grid item lg={3} xs={12}>
              <Button
                disabled={props.rawInvitation.status != "COMPLETE"}
                variant="contained"
                color="secondary"
                sx={{ width: "100%" }}
                href={reportLink}
              >
                {t("collaborativeCare:surveyManagement.actions.viewReport")}
              </Button>
              <div style={{ marginBottom: "20px" }}></div>

              <DownloadPdfButton
                disabled={!props.rawInvitation.downloadAvailable}
                invitationId={props.rawInvitation.id}
                mobile={true}
              />
            </Grid>
          </Grid>
          {list}
        </DialogContent>
      </ResponsiveDialog>
    </Box>
  );
}
