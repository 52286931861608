import React, { ReactElement } from "react";
import { PatientDetails } from "./PatientDetails";
import { Card, CardContent, CardHeader, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { dischargeReasonT } from "GeneratedGraphQL/EnumTranslations";
import { Enrollment, PatientDetailsQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { DigUnpacked, PickTypename } from "type-utils";
import { PropertyTable } from "MDS/PropertyTable";
import { Link } from "react-router-dom";

type PreviousEnrollmentsCardProps = {
  patient: PatientDetails;
};

export function PreviousEnrollmentsCard(props: PreviousEnrollmentsCardProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "patients", "enums"]);

  let previousEnrollmentContent = <></>;
  if (
    props.patient.collaborativeCareEnrollments.nodes.filter((e: PickTypename<Enrollment, "dischargedAt">) => {
      return e.dischargedAt;
    }).length == 0
  ) {
    previousEnrollmentContent = (
      <Typography>{t("collaborativeCare:patientDetails.enrollmentHistory.noPreviousEnrollments")}</Typography>
    );
  }
  props.patient.collaborativeCareEnrollments.nodes
    .slice()
    .sort((a: PickTypename<Enrollment, "dischargedAt">, b: PickTypename<Enrollment, "dischargedAt">) => {
      if (!a.dischargedAt || !b.dischargedAt) return 0;
      if (a.dischargedAt > b.dischargedAt) return -1;
      return 1;
    })
    .forEach(
      (
        enrollment: DigUnpacked<PatientDetailsQuery, ["patient", "collaborativeCareEnrollments", "nodes"]>
      ) => {
        let panelInformationContent = <></>;
        // skip enrollments that aren't discharged, i.e. the current active one
        // also typescript needs to be sure that these aren't null
        if (!enrollment.dischargedAt || !enrollment.dischargeReason) {
          return;
        }

        // panels aren't required, so only display that information if it is present
        if (
          enrollment.primaryCareProviderTeamMember?.provider.user.name &&
          enrollment.careManagerTeamMember?.provider.user.name &&
          enrollment.panel
        ) {
          panelInformationContent = (
            <>
              <TableRow>
                <TableCell size="small">
                  {t("collaborativeCare:patientDetails.enrollmentHistory.panelName")}:
                </TableCell>
                <TableCell size="small">{enrollment.panel.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small">
                  {t("collaborativeCare:patientDetails.enrollmentHistory.primaryCareProvider")}:
                </TableCell>
                <TableCell size="small">
                  {enrollment.primaryCareProviderTeamMember.provider.user.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size="small">
                  {t("collaborativeCare:patientDetails.enrollmentHistory.careManager")}:
                </TableCell>
                <TableCell size="small">{enrollment.careManagerTeamMember.provider.user.name}</TableCell>
              </TableRow>
            </>
          );
        }
        const content = (
          <>
            <TableRow>
              <TableCell>
                {t("common:date.tiny", { date: enrollment.enrolledAt })} -{" "}
                {t("common:date.tiny", { date: enrollment.dischargedAt })}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small">
                {t("collaborativeCare:patientDetails.enrollmentHistory.consentDate")}:
              </TableCell>
              <TableCell size="small">{t("common:date.tiny", { date: enrollment.consentDate })}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small">
                {t("collaborativeCare:patientDetails.enrollmentHistory.measurementPlans")}:{" "}
              </TableCell>
              <TableCell size="small">
                {enrollment.careEpisode.collaborativeCareCareEpisodeTreatmentTargets
                  .map(function (
                    tt: DigUnpacked<
                      PatientDetailsQuery,
                      [
                        "patient",
                        "collaborativeCareEnrollments",
                        "nodes",
                        "careEpisode",
                        "collaborativeCareCareEpisodeTreatmentTargets"
                      ]
                    >
                  ) {
                    return tt.treatmentTarget.name;
                  })
                  .join(", ")}
              </TableCell>
            </TableRow>
            {panelInformationContent}
            <TableRow>
              <TableCell size="small">
                {t("collaborativeCare:patientDetails.enrollmentHistory.dischargeDate")}:
              </TableCell>
              <TableCell size="small">{t("common:date.tiny", { date: enrollment.dischargedAt })}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small">
                {t("collaborativeCare:patientDetails.enrollmentHistory.dischargeReason")}:
              </TableCell>
              <TableCell size="small">{dischargeReasonT(enrollment.dischargeReason, t)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell size="small"></TableCell>
              <TableCell size="small">
                <Link to={`/app/cocm/patient/${props.patient.id}/enrollment/${enrollment.id}`}>
                  {t("collaborativeCare:patientDetails.enrollmentHistory.details")}
                </Link>
              </TableCell>
            </TableRow>
          </>
        );
        previousEnrollmentContent = (
          <>
            {previousEnrollmentContent}
            <PropertyTable sx={{ marginBottom: "2em" }}>
              <TableBody>{content}</TableBody>
            </PropertyTable>
          </>
        );
      }
    );
  return (
    <Card>
      <CardHeader
        title={t("collaborativeCare:patientDetails.enrollmentHistory.enrollmentHistory")}
      ></CardHeader>
      <CardContent>{previousEnrollmentContent}</CardContent>
    </Card>
  );
}
