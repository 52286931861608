import { CaseConsult, CaseConsultStatus } from "GeneratedGraphQL/SchemaAndOperations";
import { PickTypename } from "type-utils";

export function isPreMeeting(consult: PickTypename<CaseConsult, "status">): boolean {
  return (
    consult.status === CaseConsultStatus.AWAITING_CONSULT ||
    consult.status === CaseConsultStatus.NEEDS_SUMMARY ||
    consult.status === CaseConsultStatus.STALE
  );
}

export function isPostMeeting(consult: PickTypename<CaseConsult, "status">): boolean {
  return !isPreMeeting(consult);
}

export function isTerminal(consult: PickTypename<CaseConsult, "status">): boolean {
  return consult.status === CaseConsultStatus.CANCELED || consult.status === CaseConsultStatus.COMPLETE;
}

// Hoisting this out into a constant because when you pass the status list in refetch queries not only the statuses
// _but also their order_ has to match in order for apollo to correctly trigger the refetch.
export const REQUESTED_CONSULTS_STATUSES = [
  CaseConsultStatus.AWAITING_CONSULT,
  CaseConsultStatus.AWAITING_DOCUMENTATION,
  CaseConsultStatus.NEEDS_SUMMARY,
  CaseConsultStatus.STALE,
];
