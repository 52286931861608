import { Routes, Route } from "react-router-dom";
import React, { ReactElement } from "react";
import TreatmentServices from "./TreatmentServices";
import CreateTreatmentService from "./CreateTreatmentService";
import UpdateTreatmentService from "./UpdateTreatmentService";
import AssociateTreatmentService from "./AssociateTreatmentService";

export function TreatmentServicesRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<TreatmentServices />} path="/" />
      <Route element={<CreateTreatmentService />} path="/create" />
      <Route element={<UpdateTreatmentService />} path="/:id/update" />
      <Route element={<AssociateTreatmentService />} path="/:id/associate" />
    </Routes>
  );
}
