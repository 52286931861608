import { Grid, GridProps } from "@mui/material";
import React, { ReactElement } from "react";

type GridItemWithLeftMarginProps = GridProps & { xs: number; columns: number; margin: string };

// This is a dark trick to let us put a margin on a grid column, but I don't have a better solution. This is plausibly
// reusable but also slightly cursed, so I'm not trying to promote it to MDS-hood. An explanation of the mechanics at
// play:
// * The way Mui grid works is that the container element sets up a flex display context with flex-wrap on, so
//   that as the size of the child elements gets wider than the parent, they roll over to the next line.
// * Then it uses the xs properties to set flex-basis on each child: flex-basis = (child cols / parent cols)%
// * Thus, each child takes up the right number of columns, and when you fill a row of columns they start
//   wrapping to the next one. Very neat.
// * However, this means that just throwing a margin on one element kicks everything out of alignment, and you
//   get the last column of a row wrapping to the next row because it ran out of space.
// * We have to fix this by stealing the size back from the column that has the margin.
// * So instead we avoid setting xs at all and instead use calc on flex-basis to set it to (xs / columns)%
//   minus the amount we're using for the margin. This will break if Mui ever updates its grid implementation, but I
//   think that's unlikely since they seem to be building some sort of Grid2 thing instead.
//
// Things that would be a better solution than this but don't work:
// * Instead of adding margin here, set paddingLeft=0 on the header grid items. The rule from the overall grid theme
//   sets them to have 1rem of padding ends up with higher specificity than the rule that gets generated when you do
//   that, so it has no effect. This is very annoying.
// * Use flex-shrink on the grid element so it automatically takes up less space. Flex shrink only takes effect when the
//   flex elements would overflow their container. Because the container grid uses flex-wrap, they flow to the next line
//   instead of overflowing, so flex-shrink never comes into play.
// * Keep the grid item at the full size, add a layer of layout around the children (using either a div with margin or
//   a <Box> with width=margin) to move the content to the right place. I tried several versions of this, but none of
//   them could get the background color to be the right size.
export function GridItemWithLeftMargin(props: GridItemWithLeftMarginProps): ReactElement {
  const { xs, columns, margin, sx, ...gridProps } = props;

  return (
    <Grid
      {...gridProps}
      item
      marginLeft={margin}
      flexBasis={`calc(${(xs / columns) * 100}% - ${margin})`}
      sx={sx}
    >
      {props.children}
    </Grid>
  );
}
