import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiPaper"] = {
  rounded: {
    borderRadius: "0.5rem",
  },
  elevation1: {
    boxShadow: "none",
  },
};

const override = {
  styleOverrides,
};

export default override;
