import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiCardHeader"] = {
  root: {
    // This value specifically should match the padding in MuiCardContent so that the header and the content are aligned
    // with each other.
    paddingLeft: "2rem",
    // Balance the between-header-and-content padding between header and card so you can content-less cards.
    paddingBottom: "1rem",

    "& .MuiCardHeader-title": {
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    "& .MuiCardHeader-subheader": {
      fontStyle: "italic",
      fontSize: "1rem",
    },
  },
};

const override = {
  styleOverrides,
};

export default override;
