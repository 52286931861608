import { Box, useTheme } from "@mui/material";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { Link } from "MDS/Link";
import { CareEpisodeComputedValueId } from "Lib/Ids";
import * as NEL from "Lib/NonEmptyList";
import * as Staleness from "Lib/Staleness";
import Heatmap from "Lib/Viz/Heatmap";
import React, { ReactElement } from "react";
import { Nothing } from "seidr";
import NotTakenChip from "Shared/Scale/NotTakenChip";
import { CareEpisodeUnscoredComputedValueDetails } from "./CareEpisodeComputedValueDetails";
import { ScaleCompletionChip } from "./ScaleCompletionChip";
import {
  lastCompletion,
  lastQuantizedCompletionDate,
  QuantizedScaleCompletion,
  toQuantizedScaleCompletions,
} from "./ScaleCompletionHistory";

type QuantizedScaleCompletionsHeatmapProps = {
  height: number;
  maxWidth: number;
  idealColumnWidth: number;
  completions: NEL.NonEmptyList<QuantizedScaleCompletion>;
  onColumnClickRoute?: (completion: QuantizedScaleCompletion) => FeedbackReportRoute | undefined;
};

function QuantizedScaleCompletionsHeatmap(props: QuantizedScaleCompletionsHeatmapProps): ReactElement {
  const { maxWidth, height, completions, onColumnClickRoute, idealColumnWidth } = props;

  const theme = useTheme();

  return (
    <Heatmap
      maxWidth={maxWidth}
      height={height}
      idealColumnWidth={idealColumnWidth}
      datumToBackgroundImage={() => Nothing()}
      datumToColor={(quantized) => {
        return quantized.caseOf({
          Value: () => theme.palette.report.completion.primary,
          Blank: () => theme.palette.report.blank.primary,
        });
      }}
      data={completions}
      onColumnClickRoute={onColumnClickRoute}
    />
  );
}

type Props = {
  height: number;
  maxWidth: number;
  completions: Array<CareEpisodeUnscoredComputedValueDetails>;
  administrationDates: NEL.NonEmptyList<Date>;
  chipWidth?: number;
  onHeatmapClickRoute?: FeedbackReportRoute;
  onHeatmapClick?: () => void;
  onChipClickRoute?: (id: CareEpisodeComputedValueId) => FeedbackReportRoute;
  heatmapTooltip?: (child: ReactElement) => ReactElement;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function ScaleCompletionsHeatmapWithChip(props: Props): ReactElement {
  const {
    height,
    maxWidth,
    completions,
    administrationDates,
    chipWidth,
    onHeatmapClickRoute,
    onChipClickRoute,
    heatmapTooltip,
    chipTooltip,
  } = props;

  const quantized = toQuantizedScaleCompletions(administrationDates, completions);
  const chip = lastCompletion(quantized).map((completion) => {
    let onClickRoute;

    if (onChipClickRoute) {
      onClickRoute = onChipClickRoute(completion.id);
    }

    return (
      <ScaleCompletionChip
        completion={completion}
        isStale={Staleness.isStale(lastQuantizedCompletionDate(quantized), completion)}
        width={chipWidth}
        chipTooltip={chipTooltip}
        onClickRoute={onClickRoute}
      />
    );
  });
  const heatmapbase = (
    <QuantizedScaleCompletionsHeatmap
      idealColumnWidth={10}
      height={height}
      maxWidth={maxWidth}
      completions={quantized}
    />
  );

  const heatmap = heatmapTooltip ? heatmapTooltip(heatmapbase) : heatmapbase;

  return (
    <Box data-testid="scale-completions-heatmap-with-chip" sx={{ display: "flex", flexDirection: "row" }}>
      {onHeatmapClickRoute ? <Link to={onHeatmapClickRoute}>{heatmap}</Link> : heatmap}
      <Box sx={{ ml: 0.5, display: "inline-flex" }}>{chip.getOrElse(<NotTakenChip width={chipWidth} />)}</Box>
    </Box>
  );
}

export default ScaleCompletionsHeatmapWithChip;
export { QuantizedScaleCompletionsHeatmap };
