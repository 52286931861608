import { ComponentsOverrides } from "@mui/material";
import { FontSize } from "../typography";
import palette from "../palette";

const styleOverrides: ComponentsOverrides["MuiButton"] = {
  root: {
    textTransform: "none",
    height: "2rem",
    fontSize: FontSize.Regular,
    lineHeight: "1em",
    borderRadius: "0.188rem",
    // Set sizing and spacing around icons in buttons
    "& svg": {
      fontSize: "1rem",
      marginRight: "0.5rem",
    },
  },
  contained: {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },
  },
  containedPrimary: {
    color: palette.primary.offset,
  },
  containedSecondary: {
    color: palette.secondary.offset,
  },
  outlined: {
    border: "2px solid",
    fontWeight: "bold",
    "&:hover": {
      border: "2px solid",
    },
    "&:disabled": {
      border: "2px solid",
    },
  },
  sizeSmall: {
    height: "1.5rem",
    fontSize: FontSize.Small,
  },
};

const override = {
  styleOverrides,
};

export default override;
