import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  FormControlLabel,
  Stack,
  Tooltip,
} from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useUpdateTrialPeriodMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { OrganizationId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import SettingSwitch from "MDS/SettingSwitch";
import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";

type ConfirmEditAllCareEpisodesProps = {
  open: boolean;
  cancel: () => void;
  confirm: () => void;
};

function ConfirmEditAllCareEpisodes(props: ConfirmEditAllCareEpisodesProps) {
  const { t } = useTranslation(["settings"]);

  return (
    <Dialog open={props.open} onClose={props.cancel}>
      <Card>
        <CardHeader title={t("settings:trialPeriodSwitch.confirmTitle")}></CardHeader>
        <CardContent>
          <p>{t("settings:trialPeriodSwitch.confirmText")}</p>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" color="primary" onClick={props.confirm}>
              {t("settings:trialPeriodSwitch.confirm")}
            </Button>
            <Button variant="outlined" color="primary" onClick={props.cancel}>
              {t("settings:trialPeriodSwitch.cancel")}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Dialog>
  );
}

type OrganizationSettingsTrialPeriodProps = {
  id: OrganizationId;
  inTrialPeriod: boolean;
};

function OrganizationTrialPeriodSwitch(props: OrganizationSettingsTrialPeriodProps): ReactElement {
  const { t } = useTranslation(["settings"]);

  // Hoisting a lot of the logic from ImmediateSettingSwitch up here. We can't use it directly in this component
  // because the switch is no longer really immediate, but like "semi-immediate", depending on the state of the
  // switch and the checkbox.
  const [inTrialPeriod, setInTrialPeriod] = React.useState(props.inTrialPeriod);
  const [desiredTrialPeriod, setDesiredTrialPeriod] = React.useState<boolean | undefined>(undefined);
  const [enableAllCareEpisodes, setEnableAllCareEpisodes] = React.useState(false);

  const [updateTrialPeriod, { reset, remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsOrganizationUpdateTrialPeriod,

    useUpdateTrialPeriodMutation({
      refetchQueries: refetchQueries("organizationSettings"),
    })
  );
  function runQuery(trialPeriod: boolean) {
    updateTrialPeriod({
      variables: {
        input: {
          organizationId: props.id,
          inTrialPeriod: trialPeriod,
          enableAllCareEpisodes: enableAllCareEpisodes,
        },
      },
    });
  }

  remoteData.caseOf({
    Failure: () => {
      setDesiredTrialPeriod(undefined);
      reset();
    },
    Success: () => {
      if (desiredTrialPeriod !== undefined) {
        setInTrialPeriod(desiredTrialPeriod);
      }
      setDesiredTrialPeriod(undefined);
      reset();
    },
    _: () => {
      return;
    },
  });

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    console.log(`checkbox clicked, enableAllCareEpisodes = ${event.target.checked}`);
    setEnableAllCareEpisodes(event.target.checked);
  }

  const [confirmOpen, setConfirmOpen] = React.useState(false);

  function confirmDialog() {
    runQuery(desiredTrialPeriod === undefined ? inTrialPeriod : desiredTrialPeriod);
    setConfirmOpen(false);
  }

  function cancelDialog() {
    setConfirmOpen(false);
    setDesiredTrialPeriod(undefined);
  }

  const handleSwitchToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const desired = event.target.checked;
    setDesiredTrialPeriod(desired);

    if (!desired && enableAllCareEpisodes) {
      setConfirmOpen(true);
    } else {
      runQuery(desired);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <SettingSwitch
          label={t("settings:trialPeriodSwitch.label")}
          description={t("settings:trialPeriodSwitch.tooltip")}
          checked={desiredTrialPeriod === undefined ? inTrialPeriod : desiredTrialPeriod}
          onChange={handleSwitchToggle}
          queryStatus={remoteData}
        />
        <Tooltip
          placement="bottom-start"
          title={t("settings:trialPeriodSwitch.careEpisodesOptionTooltip") as string}
        >
          <FormControlLabel
            label={t("settings:trialPeriodSwitch.careEpisodesOption") as string}
            control={
              <Checkbox
                checked={enableAllCareEpisodes}
                onChange={onCheckboxChange}
                disabled={!props.inTrialPeriod}
              />
            }
          />
        </Tooltip>
      </Stack>
      <ConfirmEditAllCareEpisodes open={confirmOpen} cancel={cancelDialog} confirm={confirmDialog} />
    </>
  );
}

export default OrganizationTrialPeriodSwitch;
