import { ComponentsOverrides } from "@mui/material";
import { iconColor } from "../palette";

const styleOverrides: ComponentsOverrides["MuiIconButton"] = {
  root: {
    color: iconColor,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
  },
};

const override = {
  styleOverrides,
};

export default override;
