import React, { BaseSyntheticEvent, ReactElement, useState } from "react";
import { TaskDetails } from "./TaskCard";
import { Grid, Stack, TextField, Typography, useTheme } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useUpdateTaskMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { useIsMobile } from "Shared/Responsive";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { useEffectSimpleCompare } from "Lib/Hooks";

type EditableTaskTitleProps = {
  task: TaskDetails;
  small?: boolean;
  allowInteraction?: boolean;
};

function EditableTaskTitle(props: EditableTaskTitleProps): ReactElement {
  const [editingTitle, setEditingTitle] = useState<boolean>(false);
  const [editButtonVisible, setEditButtonVisible] = useState<boolean>(false);

  const { t } = useTranslation(["common", "collaborativeCare"]);

  const displayedTask = props.task;
  const originalTitle = displayedTask.title;
  const [newTitle, setNewTitle] = useState<string>(originalTitle);

  useEffectSimpleCompare(() => {
    // If the underlying task changes out from under us we should take that change rather than sticking with the
    // old data.
    if (originalTitle !== newTitle) {
      setNewTitle(originalTitle);
    }
  }, [props.task.title]);

  const headerSize = props.small ? "h2" : "h1";

  //there are some cases, like an unexpanded task list card, where we don't want to show the edit button
  let allowInteraction = true;
  if (props.allowInteraction === false) {
    allowInteraction = false;
  }

  const [updateTask, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateTask,
    useUpdateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  remoteData.caseOf({
    NotAsked: () => [],
    Loading: () => [],
    Failure: () => {
      setNewTitle(originalTitle);
      return [];
    },
    Success: (response) => {
      if (response?.task.title && !editingTitle && newTitle != response.task.title) {
        setNewTitle(response.task.title);
      }
      return [];
    },
  });

  const titleChangeHandler = function (e: BaseSyntheticEvent) {
    setNewTitle(e.target.value);
  };

  const enterEditingTitleClickHandler = function () {
    setEditingTitle(true);
  };

  const exitEditingTitleHandler = function () {
    if (newTitle != originalTitle) {
      updateTask({
        variables: {
          input: {
            taskId: displayedTask.id,
            title: newTitle,
          },
        },
      });
    }
    setEditingTitle(false);
  };
  const exitEditingTitleWithoutSavingHandler = function () {
    setEditingTitle(false);
    setNewTitle(originalTitle);
  };
  const columns = useIsMobile() ? 6 : 12;
  const isMobile = useIsMobile();
  const theme = useTheme();

  const borderStyle = "solid";
  const borderWidth = "1px";
  const padding = "10px";
  let outlineStyle = {};
  if (allowInteraction && editButtonVisible) {
    outlineStyle = {
      borderRadius: theme.shape.borderRadius,
      borderWidth: borderWidth,
      borderStyle: borderStyle,
      borderColor: theme.palette.textInput.borderColor,
      paddingTop: padding,
      paddingBottom: padding,
    };
  } else {
    outlineStyle = {
      borderRadius: theme.shape.borderRadius,
      borderWidth: borderWidth,
      borderStyle: borderStyle,
      borderColor: "transparent",
      paddingTop: padding,
      paddingBottom: padding,
    };
  }

  let titleBlock = (
    <>
      <Grid
        onMouseEnter={() => {
          setEditButtonVisible(true);
        }}
        onMouseLeave={() => {
          setEditButtonVisible(false);
        }}
        onClick={() => {
          //if the user is on mobile, they can simply click on the title to start editing
          if (isMobile && allowInteraction) {
            enterEditingTitleClickHandler();
          }
        }}
        container
        columns={columns}
        spacing={1}
      >
        <Grid item xs={9}>
          <Typography style={outlineStyle} variant={headerSize}>
            {displayedTask.title}
          </Typography>
        </Grid>
        <Grid item xs={3} hidden={allowInteraction ? (useIsMobile() ? true : !editButtonVisible) : true}>
          <ExpandingIconButton
            color="primary"
            variant="outlined"
            icon={<CreateIcon />}
            onClick={enterEditingTitleClickHandler}
          >
            {t("common:actions.edit")}
          </ExpandingIconButton>
        </Grid>
      </Grid>
    </>
  );

  if (editingTitle) {
    titleBlock = (
      <>
        <Grid container columns={columns} spacing={1}>
          <Grid item xs={9}>
            <TextField
              label={t("collaborativeCare:taskTemplates.fields.title")}
              size="small"
              fullWidth={true}
              defaultValue={displayedTask.title}
              autoFocus={true}
              onChange={titleChangeHandler}
            ></TextField>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row" spacing={1}>
              <ExpandingIconButton
                color="primary"
                variant="outlined"
                icon={<CheckIcon />}
                onClick={exitEditingTitleHandler}
              >
                {t("common:actions.save")}
              </ExpandingIconButton>
              <ExpandingIconButton
                color="primary"
                variant="outlined"
                icon={<CancelIcon />}
                onClick={exitEditingTitleWithoutSavingHandler}
              >
                {t("common:actions.cancel")}
              </ExpandingIconButton>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
  }
  return titleBlock;
}
export default EditableTaskTitle;
