import SumType from "sums-up";
import {
  CareEpisodeScaleGroupSummary,
  CareEpisodeScaleGroupHistory,
  displayableHistories,
} from "./ScaleGroup";
import { ScaleScorerHistory } from "./ScaleScorerHistory";

type IndividualHistory = ScaleScorerHistory;
type GroupHistory = CareEpisodeScaleGroupSummary | CareEpisodeScaleGroupHistory;

// A ScaleSection can represent either a single scale history, or a scale group
// This is a summary variant which does not contain the same level of detailed answer information as ScaleSection
class ScaleSection<I extends IndividualHistory, G extends GroupHistory> extends SumType<{
  IndividualScaleSection: [I];
  ScaleGroupSection: [G];
}> {
  public static IndividualScaleSection = <I extends IndividualHistory>(h: I) =>
    new ScaleSection("IndividualScaleSection", h);

  public static ScaleGroupSection = <G extends GroupHistory>(h: G) =>
    new ScaleSection("ScaleGroupSection", h);
}

function graphableScaleSectionHistories<I extends IndividualHistory, G extends GroupHistory>(
  scaleSections: Array<ScaleSection<I, G>>
): Array<ScaleScorerHistory> {
  return graphableScaleSections(scaleSections).flatMap((scaleSection) => {
    return scaleSection.caseOf({
      IndividualScaleSection: (h) => [h],
      ScaleGroupSection: (h) => {
        return displayableHistories(h);
      },
    });
  });
}

export type ScaleSectionSummary = ScaleSection<ScaleScorerHistory, CareEpisodeScaleGroupSummary>;

function graphableScaleSections<I extends IndividualHistory, G extends GroupHistory>(
  sections: ReadonlyArray<ScaleSection<I, G>>
): ReadonlyArray<ScaleSection<I, G>> {
  return sections.reduce<ReadonlyArray<ScaleSection<I, G>>>((acc, section) => {
    return section.caseOf({
      IndividualScaleSection: (_history) => acc.concat(section),
      ScaleGroupSection: (groupHistory) => {
        const histories = groupHistory.histories;

        if (!histories.length) return acc;

        return acc.concat(
          ScaleSection.ScaleGroupSection({
            ...groupHistory,
            scaleScorerHistories: histories,
          })
        );
      },
    });
  }, []);
}

export { ScaleSection, graphableScaleSectionHistories, graphableScaleSections };
