import React, { ReactElement, useEffect, useState } from "react";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Patient, useCollaborativeCareUpdatePatientNoteMutation } from "GeneratedGraphQL/SchemaAndOperations";

import { PickTypename } from "type-utils";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import { usePiiLevelIsAtLeast } from "Contexts/PiiLevelContext";

type PatientDetails = PickTypename<Patient, "id" | "note">;

function PatientNoteCard({ patient }: { patient: PatientDetails }): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "patients"]);
  const [noteUpdates, setNoteUpdates] = useState<string | null>(null);
  const disableNotes = !usePiiLevelIsAtLeast("limited_pii");
  const [updateNote, { remoteData, reset }] = apolloMutationHookWrapper(
    (x) => x.collaborativeCareUpdatePatientNote,
    useCollaborativeCareUpdatePatientNoteMutation()
  );
  useEffect(() => setNoteUpdates(null), [patient.note]);

  const state = remoteData.caseOf<{
    loading: boolean;
    error: ReactElement | null;
  }>({
    Success: () => ({
      loading: false,
      error: null,
    }),
    Failure: () => ({
      loading: false,
      error: <Typography color="error">{t("common:failedToSave")}</Typography>,
    }),
    NotAsked: () => ({
      loading: false,
      error: null,
    }),
    Loading: () => ({
      loading: true,
      error: null,
    }),
  });

  // Note that it's not legit to have a text field value of null, but we allow
  // a null note, so we need that extra check to sub in a blank space.
  const noteValue = noteUpdates === null ? patient.note || "" : noteUpdates;

  return (
    <Stack direction="column" spacing={0.5}>
      <TextField
        id="outlined-multiline-static"
        fullWidth
        multiline
        disabled={state.loading || disableNotes}
        rows={8}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setNoteUpdates(event.target.value);
        }}
        value={noteValue}
      />
      {state.error}

      <Stack direction={"row"} spacing={0.25} justifyContent={"flex-end"}>
        <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
          <Button
            disabled={state.loading || noteUpdates === null}
            color="error"
            onClick={() => {
              reset();
              setNoteUpdates(null);
            }}
            sx={{ marginRight: "1em" }}
          >
            {t("common:actions.discardChanges")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            color="secondary"
            showSpinner={state.loading}
            disabled={state.loading || noteUpdates === null}
            onClick={() => {
              if (noteUpdates !== null) {
                updateNote({
                  variables: {
                    input: {
                      note: noteUpdates,
                      patientId: patient.id,
                    },
                  },
                });
              }
            }}
          >
            {noteUpdates === null ? t("common:noUnsavedCanges") : t("common:actions.save")}
          </ButtonWithSpinner>
        </ReadOnlyOrImpersonatingDisabledButton>
      </Stack>
    </Stack>
  );
}

export default PatientNoteCard;
