const outcomes = {
  noMetrics: "You haven't set up any outcomes metrics yet",
  explainMetrics: "Add a metric to start monitoring the outcomes of your practice",
  trends: {
    improving: "improving",
    stable: "stable",
    declining: "worsening",
  },
  dashboard: {
    title: "Outcomes Metrics",
    startMonth: "Start Month",
    endMonth: "End Month",
    createMetric: "Explore & Create New Metrics",
    queryType: "Show outcomes metrics at the following levels:",
  },
  metricDetails: {
    title: "Outcomes Metric",
    startMonth: "Start Month",
    endMonth: "End Month",
    noData:
      "There is no data available for this metric. Try broadening the date range or changing the entity",
    target: "Target",
    scale: "Scale(s)",
    measurementPoint: "Time Window",
    successTriggers: "Trigger Criteria",
    meetingInclusionCriteria: "Meeting ALL of the following Inclusion Criteria",
    meetingSuccessCriteria: "Meeting ANY of the following Success Criteria",
    edit: "Edit this metric",
    abandon: "Stop tracking this metric",
  },
  create: {
    template: {
      title: "Choose a template or start from scratch",
      helpText:
        "You can either select a template from the list, or start a metric from scratch. Once you select a template, you will be able to customize it",
      scratch: "Start with a blank metric",
      useATemplate: "Use the following template",
    },
    validation: {
      atLeastOneSuccess: "At least one Success Criterion required",
      invalidSuccess: "One of the Success Criteria is invalid",
      invalidInclusionSeverity: "You must select at least one severity for your Inclusion Criterion",
      atLeastOneTrigger: "At least one Trigger Criterion required",
    },
    title: "Create Outcomes Metric",
    dates: "Select Example Dates",
    startMonth: "Start Month",
    endMonth: "End Month",
    configureMetric: "Configure Your Metric",
    saveDialog: {
      invalid:
        "You need to make a valid metric before you can save it. Close this dialog and select more options.",
      title: "Save and track this metric",
      namePlaceholder: "Give this metric a name",
      explanation:
        "Saving this metric will make it appear in the list of metrics on the Outcomes page, letting you track it regularly over time.",
    },
    actions: {
      createAndTrack: "Save this metric and continue to track it",
    },
    scale: {
      title: "Choose a scale",
      selectForDetails:
        "Choose a scale to see what kind of outcomes metrics the scale supports. Only scales with data will be shown. Administration count includes baseline measures.",
    },
    multiScale: {
      title: "Choose one or more scales",
      noScalesSelected: "Select at least one scale to begin",
      remove: "Remove",
      selectForDetails:
        "Choose at least one scale to start analysis. Only scales with data are available to choose. Analysis options are limited by the set of measures you choose. Administration count includes baseline measures.",
    },
    timeBased: {
      aggregation: {
        helperText:
          "This determines by what method this metric will be aggregated. Only a single aggregation method may be chosen.",
        title: "Aggregation",
        percentageOfPatients: "Percentage (%) of patients",
        numberOfPatients: "Number (#) of patients",
      },
    },
    triggerBased: {
      aggregation: {
        helperText:
          "This determines by what method this metric will be aggregated. Only a single aggregation method may be chosen",
        title: "Aggregation",
        averageDays: "Average days since treatment episode start",
      },
      triggers: {
        title: "Trigger Criteria",
        helperText:
          "In this section you select the events that you would like to trigger as the moment for analysis. This could be an outcome, like an improvement in severity of symptoms.",
      },
    },
    metricType: {
      title: "Choose a metric type",
      helpText:
        "Mirah supports two types of metrics. You may either select a fixed time point to measure, and evaluate how many patients are successful, or fix the Success Criteria and measure how it takes to achieve that goal",
      time: "Effectiveness: evaluate how many patients are successful at a time point in a treatment episode",
      trigger: "Efficiency: evaluate how long patients take to achieve an outcome",
    },
    rollup: {
      title: "Choose a single or multi-scale metric",
      helpText:
        "You may create a metric that tracks one scale very closely and allows you to set precise parameters on that scale, or create a multi-scale metric which has limited options but tracks progress against multiple scales at once.",
      single: "Single-scale metric",
      multi: "Multi-scale rollup metric",
    },
    computedValues: {
      invalid: "Create a valid metric to see the individual episodes of care supporting that metric.",
    },
    chooseScorerHelp:
      "To begin creating a metric, select a scale. The options available for the metric will depend on what the scale supports.",
    configureMetricTitle: "Configure metric",
    stats: {
      noValidMetric: "Make a valid metric to see statistics and outcomes",
      header: "Statistics",
      subheader: "How many rows met the Inclusion and Success Criteria, by treatment episode start date.",
      totalRows: "Treated",
      rowsInTimePeriod: "In Time Window",
      eligibleRows: "Included",
      successfulRows: "Successful",
      triggerRows: "Triggered",
    },
    successCriteria: {
      title: "Success Criteria",
      helpText:
        "This determines if a given metric datapoint is considered successful or not. At least one Success Criteria needs to be met for a given metric datapoint to be considered successful.",
      delta: {
        title: "A change since treatment episode start with a ",
        increase: "increase",
        decrease: "decrease",
      },
      finalValue: {
        title: "A final value",
      },
      finalValuePercent: {
        title: "A final value",
        units: "percent of baseline",
      },
      trend: {
        title: "A trend since treatment episode start of",
      },
      severity: {
        title: "A final severity of",
      },
      treatmentRemission: {
        title: "Where severity has moved into remission",
      },
      treatmentResponse: {
        title: "Where patient has seen treatment response",
      },
      improvedSeverity: {
        title: "Where patient has seen an improvement in severity",
      },
    },
    inclusionCriteria: {
      title: "Inclusion Criteria",
      helpText:
        "This determines if a given metric datapoint is included in the data set or not. All Inclusion Criteria must be met for a datapoint to be included. As many or as few Inclusion Criteria may be chosen, including none at all.",
      baselineSeverity: {
        title: "An initial severity of",
      },
      daysBetweenTreatmentStartAndBaseline: {
        title: "days between treatment episode start and baseline",
      },
      daysSinceTreatmentStart: {
        title: "days between treatment episode start and selected measurement",
      },
      cocmTargetScale: {
        title: "The scale is marked as a treatment target",
      },
      gender: {
        title: "The patient's gender",
      },
      race: {
        title: "The patient's race",
      },
      ageAtTreatmentStart: {
        titleStart: "Patient is aged between",
        titleMiddle: "and",
        titleEnd: "at the start of treatment episode",
      },
    },
    triggers: {
      firstSessionOfImprovedSeverity: {
        title: "The first measurement where the patient improves by a clinical category",
      },
      firstSessionOfSpecifiedSeverity: {
        title: "The first measurement where the severity becomes",
      },
      firstSessionOfTrend: {
        title: "The first measurement where the trend of treatment becomes",
      },
      firstSessionOfRemission: {
        title: "The first measurement where the symptom went into remission",
      },
      firstSessionOfResponse: {
        title: "The first measurement where the patient saw a response to treatment",
      },
    },
    timePeriod: {
      title: "Time Window",
      helpText:
        "Select the window of time at which you would like to measure the effectiveness of care. Windows of time are measured from the start of the treatment episode, rather than the date of baseline measurement. Only the latest measurement in the window given will be used. No outcomes outside of this window will be evaluated. The first day of treatment is the first day of Month 1 and Week 1.",
      lastMeasurement: {
        title: "Last measurement recorded",
      },
      monthOfTreatment: {
        title: "Last measurement in month",
      },
      weekOfTreatment: {
        title: "Last measurement in week",
      },
      unit: "of treatment episode",
    },
  },
  stats: {
    totalRows: "All episodes of care",
    rowsInTimePeriod: "Rows in time window",
    eligibleRows: "Rows which met eligibility criteria",
    successfulRows: "Rows which met Success Criteria",
    triggerRows: "Rows which met Trigger Criteria",
  },
  card: {
    errorLoadingChart: "There was an error loading the data.",
    noData:
      "There is no data available for this metric. Try changing your criteria or broadening the date range",
    successfulRows: "{{value}} Successful",
    eligibleRows: "{{value}} Included",
    rowsInTimePeriod: "{{value}} Measured",
    totalRows: "{{value}} Treated",
    triggerRows: "{{value}} Triggered",
  },
  units: {
    triggerBased: {
      avgDays: "avg days",
      averageNumberOfDays: "Average # of days",
      days: "days",
    },
    timeBased: {
      patients: "patients",
      successful: "successful",
    },
  },
  multiScaleBreakdown: {
    header: "Scale Breakdown",
    selectAScale: "Select at least one scale to view a breakdown",
    invalidParams: "Select a valid set of parameters to view a breakdown",
    tableHeaders: {
      scale: "Scale",
      result: "Result",
      eligibleRows: "Eligible Treatment Episodes",
    },
  },
  computedValueTable: {
    treatmentStartDate: "Episode Start Date",
    patientName: "Patient",
    baseline: "Baseline",
    result: "Result",
    scale: "Scale",
    daysSinceTreatmentStart: "LoS",
    metInclusionCriteria: "Included?",
    metSuccessCriteria: "Successful?",
    metTrigger: "Triggered?",
  },
  operator: {
    equal: "equal",
    lessThan: "less than",
    greaterThan: "greater than",
  },
  excludeResults: {
    include: "is one of",
    exclude: "is NOT one of",
  },
  scaleScorerDetails: {
    scoredBetween: "The scale is scored between ",
    and: " and ",
    supportedSeverities: "The scale supports the following clinical categories:",
    noSupportedSeverities:
      "The scale has no clinical categories. You will not be able to report on outcomes based on severity",
    supportedTrends: "The scale supports trends improving, worsening, stable.",
    noTrends: "The scale does not support trend",
    categoricalScale:
      "This scale does not have a numerical value. Outcomes can only be done on categorical values",
    unscoredScale: "This scale is unscored and will not provide enough data for good metrics",
  },
  multiScaleScorerDetails: {
    allSeverities:
      "The following severities are used in at least one scale and may be used for Inclusion Criteria",
    commonSeverities:
      "The following severities are used in ALL chosen scales and may be used for Success Criteria",
    noSeverities: "There are no severities supported for this combination for scales",
    noNumerical:
      "Numerical analysis is not possible in multi-scale mode as scales have different scoring mechanisms.",
    commonTrends: "All scales support trends for improving, worsening, stable",
    noTrends: "At least one scale does not support trends, so they cannot be used for Success Criteria",
    selectAScale: "Choose at least one scale to see what modes are supported",
  },
  summaries: {
    lastMeasurement: "at their last measurement",
    weekOfTreatment: "after {{value} weeks of treatment",
    monthOfTreatment: "after {{value}} months of treatment",
    stillInTreatment: "still in treatment",
    meetingSuccessCriteria: "meeting any of {{value}} Success Criteria",
  },
  downloadTransientAsExcel: "Download this data in Excel format",
  downloadDisabled: "Download Not Supported",
  downloadDisabledTrigger: "We do not currently supported downloading data for Efficency metrics",
  download: {
    title: "Download Outcomes Data",
    error:
      "There was an error creating your excel download. Please try again and contact support if the issue persists.",
    processing: "Your export file is currently being processed. It will be ready shortly.",
    ready: "Your export file is ready to download",
  },
  downloadTransient: {
    title: "Download Data",
    explanation:
      "Enter a name to download this metric as an Excel file. You will be able to change the criteria in the file to modify the analysis.",
    namePlaceholder: "Give the file a name",
    exportData: "Begin export",
  },
  templateSelect: {
    title: "Select a template",
    noneMatching: "No Matching templates",
  },
  edit: {
    submitButton: "Update Metric",
    name: {
      title: "Edit Name",
      placeholder: "Give this metric a name",
      label: "Metric Name",
    },
  },
  abandonMetric: {
    form: {
      title: "Retire{{ metric }}?",
      confirm: "Retire this metric",
      abort: "Keep tracking this metric",
      description: 'Retiring "{{ metric }}" will mean that it will not be shown on the dashboard.',
    },
    buttonTitle: "Retire metric",
  },
  successCriteria: {
    deltaDecreaseMoreThan: "with a decrease since baseline of more than {{value}}",
    deltaDecreaseLessThan: "with a decrease since baseline of less than {{value}}",
    deltaIncreaseMoreThan: "with an increase since baseline of more than {{value}}",
    deltaIncreaseLessThan: "with an increase since baseline of less than {{value}}",
    deltaOperator: "with a delta since baseline {{operator}}",
    finalValue: "with a final value {{operator}}",
    severity: "with a final severity of {{severity}}",
    finalValuePercent: "with a final value {{operator}}% of baseline",
    remission: "where symptom went into remission",
    response: "where patient saw a response to treatment",
    trend: "with an overall trend of {{trend}}",
    improvedSeverity: "with an improvement in severity",
  },
  inclusionCriteria: {
    meetingAll: "Meeting all the following criteria:",
    meetingXCriteria: "meeting {{count}} Inclusion Criteria",
    daysBetweenStartAndBaseline:
      "with {{operator}} days between treatment episode start and baseline measurement",
    daysSinceTreatmentStart:
      "with {{operator}} days between treatment episode start and the included measurement",
    baselineSeverity: "with a baseline severity of {{severities}}",
    baselineScore: "with a baseline score {{operator}}",
    cocmTargetScale: "marked as a target scale for treatment",
    ageAtTreatmentStart: "aged between {{min}} and {{max}} at start of treatment episode",
    gender: "with a gender {{matching}} {{genders}}",
    race: "with a race {{matching}} {{races}}",
    matching: "matching",
    notMatching: "notMatching",
  },
  operatorText: {
    unknown: "Unknown conditions",
    greaterThan: "greater than {{value}}",
    lessThan: "less than {{value}}",
    equal: "equal to {{value}}",
  },
  triggers: {
    meetingAll: "Meeting all the following criteria:",
    stillInTreatment: "Still in treatment",
    unknown: "Meeting unknown criteria",
    meetingX: "meeting {{count}} Trigger Criteria",
    firstSessionOfTrend: "before treatment progress became {{trend}}",
    firstSessionOfImprovedSeverity: "before clinical category improved for the first time",
    firstSessionOfSpecifiedSeverity: "before clinical severity became {{severity}} for first time",
    firstSessionOfTreatmentResponse: "before patient showed response in treatment",
    firstSessionOfTreatmentRemission: "before symptom went into remission",
  },
  breakdown: {
    header: "Show a breakdown",
    selectTitle: "Breakdown by provider, organization...",
    firstItems: "First {{number}} items",
    invalidParams: "Create a valid metric and select an entity to show a breakdown",
    tableColumns: {
      entity: "Entity",
      result: "Result",
      eligibleRows: "Eligible Treatment Episodes",
      actions: "Actions",
    },
  },
  result: "Result",
  addScaleAndSubtree: "Add with Subscales",
};

export default outcomes;
