import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  CaseConsult,
  CaseConsultStatus,
  Patient,
  useCancelCaseConsultMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useEffectSimpleCompare } from "Lib/Hooks";

type CancelCaseConsultButtonProps = {
  consult: PickTypename<CaseConsult, "id" | "status"> & {
    patient: PickTypename<Patient, "id" | "name">;
  };
};

// Task Complete Button pops up a modal to add a note and confirm you want to complete
// Similar to the Complete button from Timer Stop Button
export function CancelConsultButton(props: CancelCaseConsultButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const [showForm, setShowForm] = React.useState(false);

  const [cancelConsult, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCancelCaseConsult,
    useCancelCaseConsultMutation({
      variables: {
        input: {
          caseConsultId: props.consult.id,
        },
      },
      refetchQueries: refetchQueries("consultRequests"),
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setTimeout(() => setShowForm(false), 300);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const disabled =
    props.consult.status === CaseConsultStatus.AWAITING_DOCUMENTATION ||
    props.consult.status === CaseConsultStatus.COMPLETE ||
    props.consult.status === CaseConsultStatus.CANCELED ||
    remoteData.kind === "Loading";

  const button = (
    <Button onClick={() => setShowForm(true)} disabled={disabled} variant={"outlined"} color={"secondary"}>
      {t("collaborativeCare:caseConsult.consultList.actions.cancel")}
    </Button>
  );

  const showSpinner = remoteData.kind === "Loading";

  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:caseConsult.consultList.actions.cancelForm.title", {
          patient: props.consult.patient.name,
        })}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>
              {t("collaborativeCare:caseConsult.consultList.actions.cancelForm.title", {
                patient: props.consult.patient.name,
              })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <ButtonWithSpinner
                showSpinner={showSpinner}
                variant={"contained"}
                color="error"
                disabled={disabled}
                onClick={() => cancelConsult()}
              >
                {t("collaborativeCare:caseConsult.consultList.actions.cancelForm.confirm")}
              </ButtonWithSpinner>
              <Button
                onClick={() => setShowForm(false)}
                disabled={false}
                variant={"outlined"}
                color={"secondary"}
              >
                {t("collaborativeCare:caseConsult.consultList.actions.cancelForm.abort")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
