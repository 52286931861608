import MuiButton from "./MuiButton";
import MuiTooltip from "./MuiTooltip";
import MuiIconButton from "./MuiIconButton";
import MuiPaper from "./MuiPaper";
import MuiCardContent from "./MuiCardContent";
import MuiTableCell from "./MuiTableCell";
import MuiTypography from "./MuiTypography";
import MuiLink from "./MuiLink";
import MuiCssBaseline from "./MuiCssBaseline";
import MuiCardHeader from "./MuiCardHeader";
import MuiMenu from "./MuiMenu";
import MuiTable from "./MuiTable";
import MuiDataGrid from "./MuiDataGrid";
import MuiTreeItem from "./MuiTreeItem";
import MuiFormHelperText from "./MuiFormHelperText";
import MuiCardActions from "./MuiCardActions";
import MuiTab from "./MuiTab";

import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";
import { Components } from "@mui/material/styles/components";

const overrides: Components = {
  MuiButton,
  MuiIconButton,
  MuiLink,
  MuiPaper,
  MuiCardContent,
  MuiTableCell,
  MuiTypography,
  MuiCssBaseline,
  MuiTooltip,
  MuiCardHeader,
  MuiMenu,
  MuiTable,
  MuiDataGrid,
  MuiTreeItem,
  MuiFormHelperText,
  MuiCardActions,
  MuiTab,
};

export default overrides;
