import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  ImplementationTarget,
  ImplementationTargetStatus,
  useUpdateImplementationTargetStatusMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useEffectSimpleCompare } from "Lib/Hooks";

type RetireImplementationTargetButton = {
  target: Pick<ImplementationTarget, "id" | "name">;
  onSuccess?: () => void;
};

export function RetireImplementationTargetButton(props: RetireImplementationTargetButton): ReactElement {
  const { t } = useTranslation(["implementation"]);
  const [showForm, setShowForm] = React.useState(false);

  const [retireImplementationTarget, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.implementationUpdateImplementationTargetStatus,
    useUpdateImplementationTargetStatusMutation({
      variables: {
        input: {
          implementationTargetId: props.target.id,
          status: ImplementationTargetStatus.RETIRED,
        },
      },
      refetchQueries: ["ImplementationTargetDashboard"],
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setTimeout(() => {
          setShowForm(false);
          if (props.onSuccess) {
            props.onSuccess();
          }
        }, 300);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const disabled = remoteData.kind === "Loading";

  const button = (
    <Button onClick={() => setShowForm(true)} disabled={disabled} variant={"outlined"} color={"warning"}>
      {t("implementation:retireImplementationTarget.buttonTitle")}
    </Button>
  );

  const showSpinner = remoteData.kind === "Loading";

  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("implementation:retireImplementationTarget.form.title", {
          target: props.target.name,
        })}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>
              {t("implementation:retireImplementationTarget.form.description", {
                target: props.target.name,
              })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <ButtonWithSpinner
                showSpinner={showSpinner}
                variant={"contained"}
                color="error"
                disabled={disabled}
                onClick={() => retireImplementationTarget()}
              >
                {t("implementation:retireImplementationTarget.form.confirm")}
              </ButtonWithSpinner>
              <Button
                onClick={() => setShowForm(false)}
                disabled={false}
                variant={"outlined"}
                color={"secondary"}
              >
                {t("implementation:retireImplementationTarget.form.abort")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
