import { XYChart } from "@visx/xychart";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { CareEpisodeComputedValueId } from "Lib/Ids";
import * as NEL from "Lib/NonEmptyList";
import React, { ReactElement } from "react";
import { Just } from "seidr";
import { CareEpisodeComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { parseBoundRange, ScaleWithNumericalScorer } from "Shared/Scale/Scale";
import { SeveritySupportedValues } from "Shared/Severity/Severity";
import { SeverityHistorySeries } from "Shared/Viz/Shared/SeverityHistorySeries";
import DateAxis from "./DateAxis";
import ScaleThresholdAxis from "./ScaleThresholdAxis";
import ScaleThresholdChartBackground from "Shared/Viz/Shared/ScaleThresholdChartBackground";

type Props = {
  scale: ScaleWithNumericalScorer;
  scores: Array<CareEpisodeComputedValueDetails>;
  administrationDates: NEL.NonEmptyList<Date>;
  onScoreClickRoute: (id: CareEpisodeComputedValueId) => FeedbackReportRoute;
};

function ScaleAdministrationHistoryLineGraph(props: Props): ReactElement {
  const { scores, administrationDates, scale, onScoreClickRoute } = props;

  const valueRoute = (v: SeveritySupportedValues) => {
    // The Series component we're using is a little more general than we need here - it can also handle Goals, but here
    // we only have computed values. Since the type of `scores` here only contains computed values this cast is safe.
    return onScoreClickRoute((v as CareEpisodeComputedValueDetails).id);
  };

  const valueRange = parseBoundRange(scale.scorer);
  const dateRange = [NEL.head(administrationDates), NEL.last(administrationDates)];

  const height = 300;
  return (
    <div data-testid="detailed-scale-scorer-history-line-graph">
      <XYChart
        // Despite this prop being named "polyfill" ResizeObserver has been available in browser release builds for
        // four+ years, so we don't need a polyfill. This lets the chart fill its container properly.
        resizeObserverPolyfill={ResizeObserver}
        captureEvents={false}
        margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
        height={height}
        xScale={{ type: "time", domain: dateRange }}
        yScale={{ type: "linear", domain: valueRange, zero: false }}
      >
        <ScaleThresholdChartBackground scale={Just(scale)} />

        <ScaleThresholdAxis scale={scale} />

        <DateAxis desiredTicks={administrationDates.length} />
        <SeverityHistorySeries history={scores} valueRoute={valueRoute} invalidYValue={valueRange[1]} />
      </XYChart>
    </div>
  );
}

export default ScaleAdministrationHistoryLineGraph;
