import React, { ReactElement } from "react";
import * as NEL from "Lib/NonEmptyList";
import { QuantizedScaleCompletion, ScaleCompletion } from "Shared/Scale/ScaleCompletionHistory";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { LineSeries, GlyphSeries } from "@visx/xychart";
import { Link } from "MDS/Link";
import { HoverCircle } from "./HoverCircle";
import { useTheme } from "@mui/material/styles";

type CompletionHistorySeriesProps = {
  completionHistory: NEL.NonEmptyList<QuantizedScaleCompletion>;
  completionRoute: (completion: ScaleCompletion) => FeedbackReportRoute;
};

export function CompletionHistorySeries(props: CompletionHistorySeriesProps): ReactElement {
  const { completionHistory, completionRoute } = props;
  const theme = useTheme();

  const completions = completionHistory.toArray().reduce<Array<ScaleCompletion>>((acc, d) => {
    return d.caseOf({
      Value: (v) => acc.concat(v),
      Blank: () => acc,
    });
  }, []);

  return (
    <>
      <LineSeries dataKey="line" data={completions} xAccessor={(d) => d.date} yAccessor={(_) => 0} />
      <GlyphSeries
        dataKey="glyph"
        data={completions}
        xAccessor={(d) => d.date}
        yAccessor={(_) => 0}
        renderGlyph={(props) => (
          <Link to={completionRoute(props.datum)}>
            <HoverCircle cx={props.x} cy={props.y} swatch={theme.palette.report.completion} />
          </Link>
        )}
      />
    </>
  );
}
