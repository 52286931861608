const instituteGroups = {
  selectInstituteGroup: "Select Active Sites",
  titleWithCount: "{{count}} Sites",
  selectedInstitutesExplanation: "Data is allowed from the selected sites",
  groupHasNoMembers: "This group has no members",
  instituteScopedExplanation: "The current page has only data from the site the data is stored",
  leaderOnlyExplanation: "The current page only allows data for your top level Practice Group",
  table: {
    pageTitle: "Institute Groups",
    search: "Search",
    columns: {
      name: "Name",
      leader: "Leader",
      members: "Members",
      memberCount: "{{memberCount}} members",
    },
  },
  form: {
    name: "Name",
    leader: "Leader",
    leaderHelp: "Must be Internal or Group Leader",
  },
  actions: {
    create: "Create Institute Group",
    createError: "There was an error creating the group",
  },
  noneMatching: "No matches",
  select: {
    title: "Institute",
  },
  members: {
    remove: "Remove",
    add: "Add",
    warning:
      "Adding a member will mean that users in the Group Leader can see data for this institute. This may incur a HIPAA breach if the Leader does not have legal access to the data.",
  },
};

export default instituteGroups;
