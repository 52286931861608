import { Box, Typography, Stack } from "@mui/material";
import { sort } from "Lib/Utils";
import React, { ReactElement } from "react";
import {
  isCategorical,
  isUnscored,
  scaleFriendlyName,
  scaleIsNumerical,
  ScaleRole,
  sortByRoleAndName,
} from "Shared/Scale/Scale";
import ScaleShortDescription from "Shared/Scale/ScaleDescription";
import { CareEpisodeScaleGroupHistory } from "Shared/Scale/ScaleGroup";
import { CareEpisodeComputedValueId } from "Lib/Ids";
import { FeedbackReportContext } from "FeedbackReport/FeedbackReportContext";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import LevelDetail from "FeedbackReport/LevelDetail";
import DetailPaneLevelHeader from "FeedbackReport/DetailPane/DetailPaneLevelHeader";
import ScaleScorerHistoryNarrative from "FeedbackReport/DetailPane/ScaleScorerHistoryNarrative";
import ScaleAdministrationHistoryLineGraph from "Shared/Viz/ScaleAdministrationHistoryLineGraph";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { useTranslation } from "react-i18next";
import { formatDate } from "Shared/formatters";
import { Link } from "MDS/Link";

type Props = {
  data: CareEpisodeScaleGroupHistory;
  feedbackReportContext: FeedbackReportContext;
};

function SfssHistoryElement(props: {
  feedbackReportContext: FeedbackReportContext;
  history: ScaleScorerHistory;
  onScoreClickRoute: (careEpisodeComputedValueId: CareEpisodeComputedValueId) => Routing.FeedbackReportRoute;
}): ReactElement | null {
  const { feedbackReportContext, history, onScoreClickRoute } = props;
  const { t } = useTranslation(["report"]);
  if (scaleIsNumerical(history.scale)) {
    return (
      <ScaleAdministrationHistoryLineGraph
        scores={history.scores}
        administrationDates={feedbackReportContext.administrationDates}
        scale={history.scale}
        onScoreClickRoute={(id) => onScoreClickRoute(id)}
      />
    );
  }

  if (isCategorical(history.scale.scorer) || isUnscored(history.scale.scorer)) {
    return (
      <>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mr: 4 }}>
            {history.scores.map((completion) => (
              <Box sx={{ mb: 1 }}>
                <Link to={Routing.scaleScoreRoute(feedbackReportContext, history.scale.id, completion.id)}>
                  {formatDate(completion.date)}
                </Link>
              </Box>
            ))}
          </Box>
          <Box sx={{ width: "20rem" }}>{t("report:noScoringInformation")}</Box>
        </Box>
      </>
    );
  }

  return null;
}

function ScaleGroupSfssHistory(props: Props): ReactElement {
  const { data, feedbackReportContext } = props;

  const graphableHistories = data.histories.filter((history) => {
    const role = history.scale.role || ScaleRole.PER_RESPONDENT_TOTAL;
    return role !== ScaleRole.ADMINISTRATIVE && role !== ScaleRole.ALERT && role !== ScaleRole.INTERMEDIATE;
  });

  const orderedHistories = sort(
    (a, b) => sortByRoleAndName(a.scale, b.scale),

    graphableHistories
  );

  return (
    <div data-testid="scale-group-history">
      <DetailPaneLevelHeader label={data.scaleGroup.name} />
      <LevelDetail>
        <Stack spacing={2}>
          {orderedHistories.map((history, i) => {
            const onScoreClickRoute = (careEpisodeComputedValueId: CareEpisodeComputedValueId) =>
              Routing.scaleGroupScoreRoute(
                feedbackReportContext,
                data.scaleGroup.id,
                history.participant.participantId,
                careEpisodeComputedValueId
              );

            return (
              <Box key={i}>
                <Box sx={{ mb: 1 }}>
                  <Typography variant="h3">{scaleFriendlyName(history.scale)}</Typography>
                  <ScaleShortDescription scale={history.scale} />
                </Box>
                <SfssHistoryElement
                  feedbackReportContext={feedbackReportContext}
                  history={history}
                  onScoreClickRoute={onScoreClickRoute}
                />
                <ScaleScorerHistoryNarrative
                  history={history}
                  administrationDates={feedbackReportContext.administrationDates}
                  onScoreClickRoute={onScoreClickRoute}
                />
              </Box>
            );
          })}
        </Stack>
      </LevelDetail>
    </div>
  );
}

export default ScaleGroupSfssHistory;
export { ScaleGroupSfssHistory as Component };
