import { ApolloError } from "@apollo/client";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisodeTreatmentTrack,
  CareEpisodeTreatmentTrackStatus,
  TreatmentTrack,
  TreatmentTracksByCareEpisodeIdQuery,
  useTreatmentTracksByCareEpisodeIdQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Id from "Lib/Id";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";
import CareEpisodeDetailsTreatmentTracksPill from "./CareEpisodeDetailsTreatmentTracksPill";

type CareEpisodeDetailsTreatmentTracksListHookWrapperProps = {
  careEpisodeId: Id<"CareEpisode">;
  status: CareEpisodeTreatmentTrackStatus;
  title: string;
  showPillOptions?: boolean;
};
export default function CareEpisodeDetailsTreatmentTracksListHookWrapper(
  props: CareEpisodeDetailsTreatmentTracksListHookWrapperProps
): ReactElement {
  // Set defaults.
  const showPillOptions = props.showPillOptions === undefined ? true : props.showPillOptions;

  // Query: What tracks does this care episode currently have?
  const { remoteData: currentRemoteData } = apolloQueryHookWrapper(
    useTreatmentTracksByCareEpisodeIdQuery({
      variables: {
        careEpisodeId: props.careEpisodeId,
        status: props.status,
      },
    })
  );

  return (
    <CareEpisodeDetailsTreatmentTracksListLoader
      title={props.title}
      currentRemoteData={currentRemoteData}
      showPillOptions={showPillOptions}
    />
  );
}

type CareEpisodeDetailsTreatmentTracksListLoaderProps = {
  title: string;
  currentRemoteData: RemoteData<ApolloError, TreatmentTracksByCareEpisodeIdQuery>;
  showPillOptions: boolean;
};
function CareEpisodeDetailsTreatmentTracksListLoader(
  props: CareEpisodeDetailsTreatmentTracksListLoaderProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  return props.currentRemoteData.caseOf({
    Success: (result) => {
      if (result.careCareEpisodeTreatmentTracks) {
        return (
          <CareEpisodeDetailsTreatmentTracksListComponent
            title={props.title}
            showPillOptions={props.showPillOptions}
            tracks={result.careCareEpisodeTreatmentTracks.nodes}
          />
        );
      }
      return (
        <CareEpisodeDetailsTreatmentTracksListInfo
          title={props.title}
          text={t("careEpisodes:treatmentTracks.errorTracks")}
        />
      );
    },
    Loading: () => (
      <CareEpisodeDetailsTreatmentTracksListInfo
        title={props.title}
        text={t("careEpisodes:treatmentTracks.loadingTracks")}
      />
    ),
    Failure: () => (
      <CareEpisodeDetailsTreatmentTracksListInfo
        title={props.title}
        text={t("careEpisodes:treatmentTracks.errorTracks")}
      />
    ),
    _: () => (
      <CareEpisodeDetailsTreatmentTracksListInfo
        title={props.title}
        text={t("careEpisodes:treatmentTracks.errorTracks")}
      />
    ),
  });
}

type CareEpisodeDetailsTreatmentTracksListComponentProps = {
  title: string;
  tracks: ReadonlyArray<
    Pick<CareEpisodeTreatmentTrack, "status" | "id" | "startSessionNumber" | "enrollmentDate"> & {
      readonly treatmentTrack: {
        readonly __typename: "TreatmentTrack";
      } & Pick<TreatmentTrack, "name">;
    }
  >;
  showPillOptions: boolean;
};
function CareEpisodeDetailsTreatmentTracksListComponent(
  props: CareEpisodeDetailsTreatmentTracksListComponentProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  const treatmentTracks = props.tracks.map((track) => {
    return (
      <CareEpisodeDetailsTreatmentTracksPill
        key={track.id.toString()}
        careEpisodeTreatmentTrackId={track.id}
        name={track.treatmentTrack.name}
        enrollmentDate={track.enrollmentDate}
        startSessionNumber={track.startSessionNumber}
        showPillOptions={props.showPillOptions}
      />
    );
  });
  const currentTreatmentTracks =
    treatmentTracks.length > 0 ? (
      <Stack direction="row" spacing={1}>
        {treatmentTracks}
      </Stack>
    ) : (
      <Typography>{t("careEpisodes:treatmentTracks.noTracks")}</Typography>
    );

  return (
    <Stack spacing={1} direction="row">
      <Typography variant="h3" paddingTop={"0.22rem"}>
        {props.title}
      </Typography>
      {currentTreatmentTracks}
    </Stack>
  );
}

type CareEpisodeDetailsTreatmentTracksListInfoProps = {
  title: string;
  text: string;
};

// This is pretty much a generic informational display component that
// we can use for either loading or error messages.
function CareEpisodeDetailsTreatmentTracksListInfo(
  props: CareEpisodeDetailsTreatmentTracksListInfoProps
): ReactElement {
  return (
    <Stack spacing={1} direction="row">
      <Typography variant="h3" paddingTop={"0.22rem"}>
        {props.title}
      </Typography>
      <Typography>{props.text}</Typography>
    </Stack>
  );
}

export {
  CareEpisodeDetailsTreatmentTracksListHookWrapper as CareEpisodeDetailsTreatmentTracksList,
  CareEpisodeDetailsTreatmentTracksListComponent as Component,
};
