import { Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { EnrollmentFlow } from "./EnrollmentFlow";
import { FallibleIntakeFlow } from "./IntakeScalesFlow";

type EnrollmentDialogProps = {
  open: boolean;
  onClose: () => void;
  patient: PickTypename<Patient, "id" | "name" | "dob">;
};

type Flow = "enrollment" | "intake";

export function EnrollmentDialog(props: EnrollmentDialogProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [flow, setFlow] = React.useState<Flow>("enrollment");

  const activeFlow =
    flow === "enrollment" ? (
      <EnrollmentFlow
        patient={props.patient}
        onClose={props.onClose}
        onStartIntake={() => setFlow("intake")}
      />
    ) : (
      <FallibleIntakeFlow patient={props.patient} onClose={props.onClose} />
    );

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("collaborativeCare:enrollment.title", { patient: props.patient.name })}
      keepMounted={false}
      dialogWidth="50%"
      stopBackdropClose={true}
    >
      {activeFlow}
    </ResponsiveDialog>
  );
}
