import React, { ReactNode } from "react";
import { useParams } from "react-router-dom";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { useFeedbackReportCareEpisodeQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { resultToRemoteData } from "Lib/Utils";
import { parseFeedbackReportCareEpisode, FeedbackReportCareEpisode } from "./FeedbackReportCareEpisode";
import * as Id from "Lib/Id";
import { CareEpisodeId } from "Lib/Ids";
import { RemoteData } from "seidr";
import Page from "Layout/Page";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import { FeedbackReport } from "./FeedbackReport";
import { useTranslation } from "react-i18next";
import Link from "MDS/Link";
import { Typography } from "@mui/material";

export const FeedbackReportRoute = () => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{
    careEpisodeId?: string;
    patientId?: string;
  }>();

  const careEpisodeId = Id.fromNullableString<"CareEpisode">(params.careEpisodeId).getOrElse(null);
  const patientId = Id.fromNullableString<"Patient">(params.patientId).getOrElse(null);

  let content;
  let breadcrumbs: Array<ReactNode> = [];
  if (!careEpisodeId || !patientId) {
    content = <ErrorMessage message={t("report:errors.invalidUrl")} />;
  } else {
    const careEpisode = loadFeedbackReportCareEpisode(careEpisodeId);

    content = careEpisode.caseOf({
      NotAsked: () => <Spinner />,
      Loading: () => <Spinner />,
      Failure: (error) => <ErrorMessage message={error.message} />,
      Success: (feedbackReportCareEpisode) => <FeedbackReport careEpisode={feedbackReportCareEpisode} />,
    });

    breadcrumbs = careEpisode.caseOf({
      Success: (feedbackReportCareEpisode) => {
        return feedbackReportCareEpisode.caseOf({
          WithAdministrations: (patientInfo) => {
            return [
              <Link to={`/app/cocm/patient/${patientInfo.id}`} key="patient-breadcrumb">
                {patientInfo.name}
              </Link>,
              <Typography key="report-breadcrumb">{t("report:title")}</Typography>,
            ];
          },
          NoAdministrations: (patientInfo) => {
            return [
              <Link to={`/app/cocm/patient/${patientInfo.id}`} key="patient-breadcrumb">
                {patientInfo.name}
              </Link>,
              <Typography key="report-breadcrumb">{t("report:title")}</Typography>,
            ];
          },
        });
      },
      _: () => [],
    });
  }

  return (
    <Page breadcrumbs={breadcrumbs} browserTitle={t("report:title")}>
      {content}
    </Page>
  );
};

export function loadFeedbackReportCareEpisode(
  careEpisodeId: CareEpisodeId
): RemoteData<Error, FeedbackReportCareEpisode> {
  const { remoteData } = apolloQueryHookWrapper(
    useFeedbackReportCareEpisodeQueryQuery({
      variables: {
        careEpisodeId: careEpisodeId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) => resultToRemoteData(parseFeedbackReportCareEpisode(result)));
}
