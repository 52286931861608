import React, { ChangeEvent, FormEvent, ReactElement, useState } from "react";
import Page from "Layout/Page";
import TextField from "@mui/material/TextField";
import { Autocomplete, Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { Form, useRedirectOnSuccess } from "Shared/Form";
import {
  ShowTreatmentTrackQuery,
  TreatmentTrack,
  TreatmentTrackStatus,
  useUpdateTreatmentTrackMutation,
  useShowTreatmentTrackQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { enumToOptions, optionToEnum } from "Shared/Enum";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import * as Id from "Lib/Id";
import { ApolloError } from "@apollo/client";
import { RemoteData } from "seidr";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { TreatmentTrackId } from "Lib/Ids";

type UpdateTreatmentTrackHookWrapperProps = {
  id: TreatmentTrackId;
};

function UpdateTreatmentTrackRoute(): ReactElement {
  const { treatmentTrackId } = useParams<{ treatmentTrackId?: string }>();

  return Id.fromNullableString<"TreatmentTrack">(treatmentTrackId).caseOf({
    Err: () => <DefaultError error={"The parameters to this page are invalid"} />,
    Ok: (id) => <UpdateTreatmentTrackHookWrapper id={id} />,
  });
}

function UpdateTreatmentTrackHookWrapper(props: UpdateTreatmentTrackHookWrapperProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useShowTreatmentTrackQuery({
      variables: {
        id: props.id,
      },
    })
  );

  return <UpdateTreatmentTrackData remoteData={remoteData} />;
}

type UpdateTreatmentTrackDataProps = {
  remoteData: RemoteData<ApolloError, ShowTreatmentTrackQuery>;
};

function UpdateTreatmentTrackData(props: UpdateTreatmentTrackDataProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  return props.remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) => {
      if (result.careTreatmentTrack) {
        return <UpdateTreatmentTrack treatmentTrack={result.careTreatmentTrack} />;
      }
      return <DefaultError error={t("settings:treatmentTrack.updateTreatmentTrack.notFound")} />;
    },
  });
}

type UpdateTreatmentTrackProps = {
  treatmentTrack: Pick<TreatmentTrack, "id" | "name" | "status">;
};

function UpdateTreatmentTrack(props: UpdateTreatmentTrackProps): ReactElement {
  // We're setting up some patterns here that we might extrapolate out later.
  // Here, we have the actual property, but also whether or not it's ever been changed.
  // This lets us present a textfield that does not default to an error state.
  // We additionally have a validator for it, which is a simple function.
  const [name, setName] = useState(props.treatmentTrack.name);
  const [nameDirty, setNameDirty] = useState(false);
  const isNameValid = () => {
    if (name.trim().length === 0) {
      return false;
    }
    return true;
  };

  // Here we have a handler for when changes to the name come in. Note that
  // any change will irreversibly set dirty to be on so we'll start showing errors.
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setNameDirty(true);
  };

  // Status is much simpler and has no validity, as it's purely a select box.
  // We could always write it in but it's fine for common use cases.
  const [status, setStatus] = useState(props.treatmentTrack.status);
  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.textContent) {
      setStatus(optionToEnum(TreatmentTrackStatus, event.target.textContent));
    }
  };

  // Lastly, we have some form-wide elements.

  // Here we check if the entire form is valid or not. If we had more validators
  // above, this would check all of them.
  // We actually disable the create button if the entire form is not valid.
  const isFormValid = () => {
    return isNameValid();
  };

  // We also need our actual submit call back to the server. It uses the properties
  // created above as the params.
  const [updateTreatmentTrack, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.careUpdateTreatmentTrack,
    useUpdateTreatmentTrackMutation({
      variables: {
        input: {
          treatmentTrackId: props.treatmentTrack.id,
          name: name,
          status: status,
        },
      },
    })
  );

  // We also need somethign to handle clicking the actual
  // create button. We check form validity again here, though with
  // the button disabled it's probably not necessary but a good standard pattern
  // to use.
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isFormValid()) {
      updateTreatmentTrack();
    }
  };

  // Lastly, we should redirect back to the track list upon success.
  // The . actually takes us back to the index page for this resource.
  useRedirectOnSuccess(
    remoteData,
    "/app/settings/provider/admin/configuration/treatmenttrack/",
    useNavigate()
  );

  const { t } = useTranslation(["settings"]);

  return (
    <Page browserTitle={t("settings:treatmentTrack.updateTreatmentTrack.title")}>
      <Card>
        <CardHeader title={t("settings:treatmentTrack.updateTreatmentTrack.title")} />
        <CardContent>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={1} alignItems="flex-start">
              <TextField
                variant="outlined"
                label={t("settings:treatmentTrack.updateTreatmentTrack.name")}
                value={name}
                onChange={handleNameChange}
                error={!isNameValid() && nameDirty} // This is where we slip in dirty to prevent showing an error right away.
              />
              <Autocomplete
                options={enumToOptions(TreatmentTrackStatus)}
                value={status}
                sx={{ minWidth: 189 }} // Experimentally determined.
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("settings:treatmentTrack.updateTreatmentTrack.status")}
                    variant="outlined"
                  />
                )}
                onChange={handleStatusChange}
              />
              <Stack direction="row" spacing="20px">
                <Button variant="contained" type="submit" disabled={!isFormValid()}>
                  {t("settings:treatmentTrack.updateTreatmentTrack.update")}
                </Button>
                <Button variant="outlined" type="input" href=".">
                  {t("settings:treatmentTrack.updateTreatmentTrack.cancel")}
                </Button>
              </Stack>
            </Stack>
          </Form>
        </CardContent>
      </Card>
    </Page>
  );
}

export default UpdateTreatmentTrackRoute;
