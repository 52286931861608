import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  ForceStartStrategy,
  useAcceptFindingMutation,
  useBeginTimeEntryLogMutation,
  useCreateTaskMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { FindingId, PatientId, ProviderId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type StartTaskNowActionProps = {
  findingId: FindingId;
  title: string;
  body: string;
  dueAt: Date;
  patientId: PatientId;
  assignedToId: ProviderId;
  buttonText?: string;
  redirectOnSuccess?: string;
};
export function StartTaskNowAction(props: StartTaskNowActionProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const navigate = useNavigate();

  // We have three mutation calls, one to create the task, one to start the timer,
  // and one to accept the finding.

  // This first one creates the task.
  const [createTask, { remoteData: createTaskRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTask,
    useCreateTaskMutation({
      variables: {
        input: {
          title: props.title,
          body: props.body,
          dueAt: props.dueAt,
          patientId: props.patientId,
          assignedToId: props.assignedToId,
          addToDefaultList: true,
        },
      },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  // This second one creates the timer.
  const [beginTimeEntryLog, { remoteData: beginTimeEntryRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartTimeEntryLogNow,
    useBeginTimeEntryLogMutation()
  );

  // This third one accepts the finding.
  const [accept, { remoteData: acceptRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAcceptFinding,
    useAcceptFindingMutation({
      variables: {
        input: {
          findingId: props.findingId,
        },
      },
      refetchQueries: refetchQueries("findings"),
    })
  );

  const onStartTaskNow = () => {
    // We have to do a two part where we first create our task, and then immediately start running
    // that.
    createTask().then((response) => {
      const taskId = response.data?.collaborativeCareCreateTask?.result?.task.id;
      if (taskId) {
        beginTimeEntryLog({
          refetchQueries: refetchQueries("timeEntries"),
          variables: {
            input: {
              taskStrategy: { taskId: taskId },
              providerId: props.assignedToId,
              clientStartTime: new Date(),
              forceStart: ForceStartStrategy.ALWAYS,
            },
          },
        }).then(() => {
          if (props.redirectOnSuccess) {
            navigate(props.redirectOnSuccess);
          }
        });
      }
    });
    // Thirdly, we have to accept the finding.
    accept();
  };

  const buttonText = props.buttonText
    ? props.buttonText
    : t("collaborativeCare:findings.actions.startTaskNow");

  const showSpinner =
    createTaskRemoteData.kind === "Loading" ||
    beginTimeEntryRemoteData.kind === "Loading" ||
    acceptRemoteData.kind === "Loading";
  const disabled = showSpinner;

  return (
    <ButtonWithSpinner
      variant="outlined"
      color="success"
      onClick={onStartTaskNow}
      showSpinner={showSpinner}
      disabled={disabled}
    >
      {buttonText}
    </ButtonWithSpinner>
  );
}
