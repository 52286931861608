import React, { ReactElement } from "react";
import { Stack, Breadcrumbs } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FeedbackReportRoute } from "./FeedbackReportRouting";
import { Link } from "MDS/Link";

const PaddedStack = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(2),
}));

const PrimarySpan = styled("span")(({ theme }) => ({
  color: theme.palette.text.primary,
}));

type Props = {
  breadcrumbs: ReadonlyArray<[string, FeedbackReportRoute?]>;
};

export function DetailsWrapper(props: React.PropsWithChildren<Props>): ReactElement {
  let breadcrumbs;
  if (props.breadcrumbs.length > 0) {
    breadcrumbs = (
      <Breadcrumbs>
        {props.breadcrumbs.map(([label, route], index) => {
          if (route) {
            return (
              <Link underline="always" to={route} key={index}>
                {label}
              </Link>
            );
          } else {
            return <PrimarySpan key={index}>{label}</PrimarySpan>;
          }
        })}
      </Breadcrumbs>
    );
  } else {
    breadcrumbs = null;
  }

  return (
    <PaddedStack spacing={2}>
      {breadcrumbs}
      {props.children}
    </PaddedStack>
  );
}
