import {
  CategoricalScaleScorerSummaryFragment,
  NumericalScaleScorer,
  NumericalScaleScorerSummaryFragment,
  QuestionType,
  Scale,
  ScaleMeasureType,
  ScaleRole,
  ScaleScorerDomain,
  ScaleScorerSummaryFragment,
  ScaleSummaryFragment,
  UnscoredScaleScorerSummaryFragment,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Maybe } from "seidr";

// Lower and upper bounds of the Scale based on a ScaleScorer
export type ScaleBounds = [number, number];

export type ScaleWithScorer = ScaleSummaryFragment & { scorer: ScaleScorerSummaryFragment };

export type ScaleWithNumericalScorer = ScaleSummaryFragment & { scorer: NumericalScaleScorerSummaryFragment };

export type ScaleWithName = Pick<Scale, "id" | "name" | "shortname">;

export type BoundInterpretation = {
  value: number;
  interpretation: Maybe<string>;
  interpretationShort: Maybe<string>;
};

function scaleIsNumerical(scale: ScaleWithScorer): scale is ScaleWithNumericalScorer {
  return isNumerical(scale.scorer);
}

function isNumerical(value: ScaleScorerSummaryFragment): value is NumericalScaleScorerSummaryFragment {
  return value.__typename === "NumericalScaleScorer";
}
function isCategorical(value: ScaleScorerSummaryFragment): value is CategoricalScaleScorerSummaryFragment {
  return value.__typename === "CategoricalScaleScorer";
}

function isUnscored(value: ScaleScorerSummaryFragment): value is UnscoredScaleScorerSummaryFragment {
  return value.__typename === "UnscoredScaleScorer";
}

export type ScaleBoundWithInterpretation = [BoundInterpretation, BoundInterpretation];

// -- Helpers -----------------------------------------------------------------

function scaleShortestName(scale: Pick<ScaleSummaryFragment, "name" | "shortname" | "nanoname">): string {
  if (scale.nanoname) {
    return scale.nanoname;
  }

  if (scale.shortname) {
    return scale.shortname;
  }

  return scale.name;
}

function scaleMediumName(scale: Omit<ScaleWithName, "id">): string {
  if (scale.shortname) {
    return scale.shortname;
  }

  return scale.name;
}

function scaleFriendlyName(scale: Pick<Scale, "name" | "shortname" | "nanoname" | "friendlyName">): string {
  if (scale.friendlyName) {
    return scale.friendlyName;
  }

  return scaleShortestName(scale);
}

function scaleFriendlyNameWithActual(
  scale: Pick<Scale, "name" | "shortname" | "nanoname" | "friendlyName">
): string {
  if (scale.friendlyName) {
    `${scale.friendlyName} - ${scaleShortestName(scale)}`;
  }
  return scaleShortestName(scale);
}

// TODO: WHEN IS THIS USED???
function isDisplayable(scorer: ScaleScorerSummaryFragment): boolean {
  return scorer.domain === ScaleScorerDomain.RAW || scorer.domain === ScaleScorerDomain.INTERPRETED;
}

/**
 * Provides a number suitable for sorting a scale for display purposes.
 * As a general rule, the combined and total scales should always be displayed
 * before the subscales.
 * @param role the scale role
 */
function scaleRolePriority(role: ScaleRole): number {
  switch (role) {
    case ScaleRole.COMBINED_TOTAL:
      return 0;
    case ScaleRole.COMBINED_SUBSCALE:
      return 1;
    case ScaleRole.PER_RESPONDENT_TOTAL:
      return 2;
    case ScaleRole.PER_RESPONDENT_SUBSCALE:
      return 3;
    default:
      return 4;
  }
}

function scaleRolePriorityWithDefault(
  role: ScaleRole | null,
  defaultRole: ScaleRole = ScaleRole.PER_RESPONDENT_TOTAL
): number {
  return scaleRolePriority(role || defaultRole);
}

/**
 * Sort function for sorting firstly by role, and then by scale name
 */
function sortByRoleAndName(a: ScaleSummaryFragment, b: ScaleSummaryFragment): number {
  const aRole = scaleRolePriorityWithDefault(a.role);
  const bRole = scaleRolePriorityWithDefault(b.role);

  if (aRole === bRole) {
    return a.name.localeCompare(b.name);
  } else {
    return aRole - bRole;
  }
}

// -- Transforms -----------------------------------------------------------------

function parseBounds(
  raw: Pick<
    NumericalScaleScorer,
    | "minValue"
    | "maxValue"
    | "minInterpretation"
    | "minInterpretationShort"
    | "maxInterpretation"
    | "maxInterpretationShort"
  >
): ScaleBoundWithInterpretation {
  return [
    {
      value: raw.minValue,
      interpretation: Maybe.fromNullable(raw.minInterpretation),
      interpretationShort: Maybe.fromNullable(raw.minInterpretationShort),
    },
    {
      value: raw.maxValue,
      interpretation: Maybe.fromNullable(raw.maxInterpretation),
      interpretationShort: Maybe.fromNullable(raw.maxInterpretationShort),
    },
  ];
}
function parseBoundRange(raw: Pick<NumericalScaleScorer, "minValue" | "maxValue">): [number, number] {
  return [raw.minValue, raw.maxValue];
}

export {
  QuestionType,
  ScaleScorerDomain,
  ScaleMeasureType,
  ScaleRole,
  scaleIsNumerical,
  scaleShortestName,
  scaleMediumName,
  scaleFriendlyName,
  scaleFriendlyNameWithActual,
  isDisplayable,
  scaleRolePriority,
  sortByRoleAndName,
  parseBounds,
  parseBoundRange,
  isCategorical,
  isNumerical,
  isUnscored,
};
