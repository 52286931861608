import * as Sentry from "@sentry/react";
import { objOfOr } from "./Lib/Utils";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
// In development mode nothing gets expanded.
const sentryEnvironment = window.environment === "%ENVIRONMENT%" ? "development" : window.environment;
const release = window.appVersion === "%CURRENT_GIT_SHA%" ? null : window.appVersion;

Sentry.init({
  beforeSend(event, _) {
    if (sentryEnvironment == "development") {
      // Returning null here effectively deletes the event. This is what keeps us
      // from polluting sentry with local development errors.
      return null;
    }
    return event;
  },
  dsn: "https://4195b602a2f1494e97258b09b2a3c6d7@o376047.ingest.sentry.io/5257288",
  ...objOfOr("environment", sentryEnvironment),
  ...objOfOr("release", release),

  // Explicitly set this to an empty array so no one is tempted to use it by accident (see comment on integrations)
  ignoreErrors: [],
  // For reasons I cannot properly suss out, the InboundFilters integration is dropping many of our errors that
  // originate in react components. The default behavior _which we are not changing_ is to drop ScriptError instances,
  // which are often spurious. However, we've observed it also dropping other errors, despite not being able to find
  // a mechanism that would make it do that. Hence, disable the entire integration. Note that this also disables the
  // functionality of the ignoreErrors option in init, since InboundFilters is what implements the error ignoring
  // feature. If we end up getting spammed with too many bad errors from turning this off we may need to implement
  // our own filtering or something.
  integrations: (allIntegrations) => {
    const integrations = allIntegrations.filter((i) => i.name !== "InboundFilters");

    integrations.push(Sentry.browserTracingIntegration());

    integrations.push(
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      })
    );

    return integrations;
  },

  // We currently want distributed tracing set up to cross reference errors on the backend and frontend, but do want to
  // cross reference when we get
  tracesSampleRate: 0,

  // Enable debug logging. Only turn on when trying to debug an issue with Sentry, shouldn't normally be enabled in
  // production environments.
  debug: false,
});
