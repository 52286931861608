import { Tooltip as MUITooltip, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { ReactElement } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  lineHeight: "1.3em",
  padding: theme.spacing(0.5),
}));

type TooltipBaseProps = {
  children: ReactElement;
  title?: string;
  subtitle?: string;
  content?: ReactElement;
};

// General consensus is that this is load-bearing but we probably should
// not be building new components directly on top of it.
export function TooltipBase(props: TooltipBaseProps): ReactElement {
  const { title, subtitle, children, content } = props;

  // For somewhat arcane React reasons, we need to wrap the children here in a concrete element that can take a ref, so
  // that the tooltip can work with it. See https://mui.com/guides/composition/#caveat-with-refs. It's possible that
  // always forcing our tooltip children into a span can mess up their layout - I haven't been able to find any examples
  // of this in practice but if we run into it we can probably find a way around it then.
  const ChildrenWithRef = React.forwardRef<HTMLSpanElement>((props, ref) => (
    <span {...props} ref={ref}>
      {children}
    </span>
  ));

  const toolTipContent = (
    <StyledBox>
      <TitleContent title={props.title} subtitle={props.subtitle} />
      {content}
    </StyledBox>
  );

  return (
    <MUITooltip title={toolTipContent} aria-label={title || subtitle || "tooltip"}>
      <ChildrenWithRef />
    </MUITooltip>
  );
}

//Note: this is a weird issue with the typing of styled components.
const StyledSubtitle = styled(Typography)(({ theme }) => ({
  marginBottom: 12,
  fontSize: theme.typography.subtitle2.fontSize,
  lineHeight: "1.2em",
})) as typeof Typography;

function TitleContent(props: { title?: string; subtitle?: string }): ReactElement {
  const title = props.title ? (
    <Typography variant="h6" component="h1">
      {props.title}
    </Typography>
  ) : null;
  const subtitle = props.title ? (
    <StyledSubtitle component={"h2"} color={"textSecondary"} variant={"subtitle1"}>
      {props.subtitle}
    </StyledSubtitle>
  ) : null;
  return (
    <Box>
      {title}
      {subtitle}
    </Box>
  );
}

export default TooltipBase;
