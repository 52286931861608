import React, { ReactElement } from "react";
import { Maybe, RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import Page from "Layout/Page";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Stack from "@mui/material/Stack";
import {
  TreatmentTrack,
  TreatmentTracksQuery,
  useTreatmentTracksQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SettingsIcon from "@mui/icons-material/Settings";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useTranslation } from "react-i18next";
import EqualizerIcon from "@mui/icons-material/Equalizer";

function TreatmentTracksListHookWrapper(): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useTreatmentTracksQuery());

  return <TreatmentTracksList remoteData={remoteData} />;
}

type TreatmentTracksListDataProps = {
  remoteData: RemoteData<ApolloError, TreatmentTracksQuery>;
};

function TreatmentTracksList(props: TreatmentTracksListDataProps): ReactElement {
  const { t } = useTranslation(["settings"]);
  return props.remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) => {
      return Maybe.fromNullable(result).caseOf({
        Nothing: () => <DefaultError error={t("settings:treatmentTrack.listTreatmentTrack.error")} />,
        Just: (treatmentTracks: TreatmentTracksQuery) => {
          if (treatmentTracks.careTreatmentTracks) {
            return <TreatmentTracksListElement treatmentTracks={treatmentTracks.careTreatmentTracks.nodes} />;
          }
          return <DefaultError error={t("settings:treatmentTrack.listTreatmentTrack.error")} />;
        },
      });
    },
  });
}

type TreatmentTracksListElementProps = {
  treatmentTracks: ReadonlyArray<Pick<TreatmentTrack, "__typename" | "name" | "status" | "id">>;
};

function TreatmentTracksListElement(props: TreatmentTracksListElementProps) {
  const { t } = useTranslation(["settings"]);

  const treatmentTracks = [...props.treatmentTracks]
    .sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    })
    .map((treatmentTrack) => {
      return (
        <TableRow key={treatmentTrack.id.toString()}>
          <TableCell>{treatmentTrack.name}</TableCell>
          <TableCell>
            <Link
              component={RouterLink}
              to={"/provider/plan/entities?path=treatment-track%2F" + treatmentTrack.id.toString()}
            >
              <EqualizerIcon />
            </Link>
          </TableCell>
          <TableCell>{treatmentTrack.status}</TableCell>
          <TableCell>
            <Link component={RouterLink} to={treatmentTrack.id.toString()}>
              <SettingsIcon />
            </Link>
          </TableCell>
        </TableRow>
      );
    });
  return (
    <Page browserTitle={t("settings:treatmentTrack.listTreatmentTrack.title")}>
      <Card>
        <CardHeader title={t("settings:treatmentTrack.listTreatmentTrack.title")} />
        <CardContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("settings:treatmentTrack.listTreatmentTrack.name")}</TableCell>
                  <TableCell>{t("settings:treatmentTrack.listTreatmentTrack.measurementPlan")}</TableCell>
                  <TableCell>{t("settings:treatmentTrack.listTreatmentTrack.status")}</TableCell>
                  <TableCell>{t("settings:treatmentTrack.listTreatmentTrack.edit")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{treatmentTracks}</TableBody>
            </Table>
          </TableContainer>
          <Stack direction="row" spacing={1}>
            <Button variant="contained" href="create">
              <AddBoxIcon />
              {t("settings:treatmentTrack.listTreatmentTrack.create")}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Page>
  );
}

export default TreatmentTracksListHookWrapper;
export { TreatmentTracksListHookWrapper as HookWrapper, TreatmentTracksList as Component };
