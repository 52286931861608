import {
  DialogActions,
  DialogContent,
  Link,
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  AdministrableScale,
  IntakeScaleAdministrationChoice,
  IntakeSessionAdministrationChoice,
  Patient,
  RelatedPersonRelationship,
  useEnrollmentIntakeScalesQuery,
  useSetIntakeScaleAdministrationChoiceMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { CareEpisodeId, EnrollmentId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ShadedTable } from "MDS/ShadedTable";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { optionToEnum } from "Shared/Enum";
import { FormOverlay, useForm } from "Shared/Form";
import { PickTypename } from "type-utils";
import { useParticipantLookup } from "Shared/Participant";
import { refetchQueries } from "Lib/RefetchQueries";
import { RelatedPersonForm } from "CollaborativeCare/PatientDetails/DetailTabs/RelatedPeopleTab/RelatedPersonForm";
import { relatedPersonRelationshipT } from "GeneratedGraphQL/EnumTranslations";

type IntakeAdministrationChoicesProps = {
  enrollmentId: EnrollmentId;
  careEpisodeId: CareEpisodeId;
  patient: PickTypename<Patient, "id" | "name">;
  onSuccess: () => void;
};

type IntakeScaleAdministrationChoiceWithName = IntakeScaleAdministrationChoice & {
  scale: Pick<AdministrableScale, "shortname" | "allowedParticipants">;
};

export function IntakeAdministrationChoices(props: IntakeAdministrationChoicesProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const [choices, setChoices] = React.useState<Array<IntakeScaleAdministrationChoiceWithName>>([]);

  const { remoteData: intakeScaleRemoteData } = apolloQueryHookWrapper(
    useEnrollmentIntakeScalesQuery({ variables: { enrollmentId: props.enrollmentId } })
  );

  useEffectSimpleCompare(() => {
    intakeScaleRemoteData.caseOf({
      Success: (response) => {
        if (response.collaborativeCareEnrollment) {
          setChoices(
            response.collaborativeCareEnrollment.intakeSessionScales.map((iss) => ({
              administrationChoice: iss.administrationChoice,
              intakeSessionScaleId: iss.id,
              scale: iss.scale,
            }))
          );
        }
      },
      _: () => {
        return;
      },
    });
  }, [intakeScaleRemoteData.kind]);

  const [saveChoices, { remoteData: saveRemoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareSetIntakeScaleAdministrationChoice,
    useSetIntakeScaleAdministrationChoiceMutation({
      variables: {
        input: {
          enrollmentId: props.enrollmentId,
          // Somehow there are enough layers of indirection between the `Exact<...>` on the input type and this field
          // that typescript won't complain if we assign choices straight into it even though it has too many fields,
          // but the graphql gem sure does. Pluck off just the fields we need to make the API happy.
          choices: choices.map((choice) => ({
            administrationChoice: choice.administrationChoice,
            intakeSessionScaleId: choice.intakeSessionScaleId,
          })),
        },
      },
      refetchQueries: refetchQueries("sessions"),
    })
  );

  const form = useForm({
    fields: {},
    remoteData: saveRemoteData,
    submit: () => {
      saveChoices();
    },
    onSuccess: () => {
      setTimeout(() => props.onSuccess(), 300);
    },
  });

  const participantLookup = useParticipantLookup(props.patient);

  const onChoiceChange = (newValue: IntakeScaleAdministrationChoiceWithName, index: number) => {
    const newChoices = [...choices];
    newChoices[index] = newValue;
    setChoices(newChoices);
  };

  const rows = choices.map((intakeScale, i) => (
    <SelectAdministrationStrategyRow
      key={i}
      value={intakeScale}
      onChange={(newValue) => onChoiceChange(newValue, i)}
      relatedPeople={intakeScale.scale.allowedParticipants?.flatMap((p) => participantLookup[p])}
      patient={props.patient}
    />
  ));

  const valid = choices.reduce((acc, choice) => {
    const validParticipants = choice.scale.allowedParticipants?.flatMap((p) => participantLookup[p]);
    const noValidParticipants = !validParticipants || validParticipants.length === 0;
    return (
      acc &&
      (noValidParticipants || choice.administrationChoice !== IntakeSessionAdministrationChoice.NOT_SELECTED)
    );
  }, true);
  const disabled = !valid || saveRemoteData.kind === "Loading";
  const showSpinner = saveRemoteData.kind === "Loading";

  return (
    <>
      <DialogContent>
        <FormOverlay
          response={saveRemoteData}
          errorMessage={form.globalError || t("collaborativeCare:enrollment.intakeChoicesFailed")}
        />
        <ShadedTable>
          <TableHead>
            <TableRow>
              <TableCell />
              <CenteredCell>{t("collaborativeCare:enrollment.administerManually")}</CenteredCell>
              <CenteredCell>{t("collaborativeCare:enrollment.sendNotification")}</CenteredCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </ShadedTable>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          disabled={disabled}
          showSpinner={showSpinner}
          onClick={form.onFakeSubmit}
        >
          {t("common:actions.save")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}

const CenteredCell = styled(TableCell)(() => ({
  textAlign: "center",
}));

type SelectAdministrationStrategyRowProps = {
  value: IntakeScaleAdministrationChoiceWithName;
  onChange: (newValue: IntakeScaleAdministrationChoiceWithName) => void;
  relatedPeople: Array<string> | undefined;
  patient: PickTypename<Patient, "id" | "name">;
};

function SelectAdministrationStrategyRow(props: SelectAdministrationStrategyRowProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  const [showRelatedPeopleForm, setShowRelatedPeopleForm] = React.useState<boolean>(false);

  // to make this cast safe we need to make sure we don't try to cast a value for
  // ParticipantRelationship that doesn't exist in RelatedPersonRelationship
  // i.e. remove child, patient, and provider records as they can't be cast to RelatedPersonRelationship
  const missingParticipantRelationship = props.value.scale.allowedParticipants
    ? (props.value.scale.allowedParticipants.filter((r) => {
        return r != "CHILD" && r != "PATIENT" && r != "PROVIDER";
      })[0] as unknown as RelatedPersonRelationship)
    : undefined;

  let missingString = t("enrollment.noRecipient");
  if (missingParticipantRelationship) {
    missingString = t("enrollment.noRecipientWithRelationship", {
      relationship: relatedPersonRelationshipT(missingParticipantRelationship, t),
    });
  }

  const people =
    props.relatedPeople && props.relatedPeople.length > 0 ? (
      <Typography variant="caption" component="span">
        {props.relatedPeople.join(", ")}
      </Typography>
    ) : (
      <Link>
        <Typography
          variant="subtitle2"
          component="span"
          onClick={() => {
            setShowRelatedPeopleForm(true);
          }}
          sx={{ cursor: "pointer" }}
        >
          {missingString}
        </Typography>
      </Link>
    );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChoice = optionToEnum(IntakeSessionAdministrationChoice, event.target.value);
    props.onChange({ ...props.value, administrationChoice: newChoice });
  };

  //it shouldn't be possible for us to not have a missingParticpantRelationship,
  //but the schema defines it as optional
  const content =
    showRelatedPeopleForm && missingParticipantRelationship ? (
      <RelatedPersonForm
        patient={props.patient}
        closeForm={() => {
          setShowRelatedPeopleForm(false);
        }}
        additionalTitle={
          "(" +
            relatedPersonRelationshipT(missingParticipantRelationship, t) +
            " " +
            t("patientDetails.enrollmentHistory.for") +
            " " +
            props.patient.name +
            ")" || ""
        }
        subtitle={props.value.scale.shortname || undefined}
        preSelectedRelationship={missingParticipantRelationship}
      />
    ) : (
      <TableRow>
        <TableCell>
          <Stack direction="column" spacing={0.5}>
            {props.value.scale.shortname}
            {people}
          </Stack>
        </TableCell>
        <CenteredCell>
          <Radio
            checked={props.value.administrationChoice === IntakeSessionAdministrationChoice.ADMINISTER}
            onChange={onChange}
            value={IntakeSessionAdministrationChoice.ADMINISTER}
            inputProps={{ "aria-label": t("collaborativeCare:enrollment.administerManually") }}
            disabled={(props.relatedPeople?.length ?? 0) === 0}
          />
        </CenteredCell>
        <CenteredCell>
          <Radio
            checked={props.value.administrationChoice === IntakeSessionAdministrationChoice.NOTIFY}
            onChange={onChange}
            value={IntakeSessionAdministrationChoice.NOTIFY}
            inputProps={{ "aria-label": t("collaborativeCare:enrollment.sendNotification") }}
            disabled={(props.relatedPeople?.length ?? 0) === 0}
          />
        </CenteredCell>
      </TableRow>
    );

  return content;
}
