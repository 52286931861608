import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  MetricTriggerType,
  SeverityCategory,
  Trend,
  MetricTriggerParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { enumFromStringValue } from "Shared/Enum";
import { FieldMethods } from "Shared/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import { SeveritySelect } from "./SeverityHelpers";

type TriggersFormProps = {
  triggersField: FieldMethods<ReadonlyArray<MetricTriggerParams>, ReadonlyArray<MetricTriggerParams>>;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
  supportedTrends: ReadonlyArray<Trend>;
};

type ItemFields = {
  disabled: boolean;
  row: MetricTriggerParams;
  onUpdate: (data: MetricTriggerParams) => void;
};

/**
 * Make a single select box that contains the operator elements
 */
function TrendSelect(props: { trend: Trend; onChange: (trend: Trend) => void; disabled: boolean }) {
  const { t } = useTranslation(["outcomes"]);
  const { trend, onChange, disabled } = props;

  const handleOnChange = (event: SelectChangeEvent<Trend>) => {
    const parsed = event.target.value ? enumFromStringValue<Trend>(Trend, event.target.value) : undefined;

    // We don't do an update if we can't parse it, but given the constraints it should never occur
    if (parsed) {
      onChange(parsed);
    }
  };

  // Note that we don't want to support all the trends, only the valid ones, so this isn't a full enum
  return (
    <FormControl>
      <Select sx={{ height: "2em" }} value={trend} onChange={handleOnChange} disabled={disabled}>
        <MenuItem value={Trend.IMPROVING}>{t("outcomes:trends.improving")}</MenuItem>
        <MenuItem value={Trend.STABLE}>{t("outcomes:trends.stable")}</MenuItem>
        <MenuItem value={Trend.DECLINING}>{t("outcomes:trends.declining")}</MenuItem>
      </Select>
    </FormControl>
  );
}

function TrendRow(props: ItemFields) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const value = row.trendValue || Trend.IMPROVING;

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.triggers.firstSessionOfTrend.title")}</span>
        <FormControl>
          <TrendSelect
            disabled={disabled}
            trend={value}
            onChange={(trend) => onUpdate({ ...row, trendValue: trend })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

function SeverityRow(props: ItemFields & { supportedSeverities: ReadonlyArray<SeverityCategory> }) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const value = row.severityValue || SeverityCategory.MILD;

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.triggers.firstSessionOfSpecifiedSeverity.title")}</span>
        <FormControl>
          <SeveritySelect
            disabled={disabled}
            supportedSeverities={props.supportedSeverities}
            severity={value}
            onChange={(severity) => onUpdate({ ...row, severityValue: severity })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

const defaultValues: Record<MetricTriggerType, MetricTriggerParams> = {
  [MetricTriggerType.FIRST_SESSION_OF_TREND]: {
    triggerType: MetricTriggerType.FIRST_SESSION_OF_TREND,
    trendValue: Trend.IMPROVING,
  },
  [MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY]: {
    triggerType: MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY,
    severityValue: SeverityCategory.MILD,
  },
  [MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY]: {
    triggerType: MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY,
  },
  [MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION]: {
    triggerType: MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION,
  },
  [MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE]: {
    triggerType: MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE,
  },
};

function Item(props: {
  active: boolean;
  row: MetricTriggerParams;
  onCheckboxChange: (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onUpdate: (data: MetricTriggerParams) => void;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
}) {
  const { active, row, onCheckboxChange, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  const subprops = { disabled: !active, onUpdate, row };

  // Assign a default for now for compiler
  const component = (() => {
    switch (row.triggerType) {
      case MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY:
        return <Typography>{t("outcomes:create.triggers.firstSessionOfImprovedSeverity.title")}</Typography>;
      case MetricTriggerType.FIRST_SESSION_OF_TREND:
        return <TrendRow {...subprops} />;
      case MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY:
        return <SeverityRow {...subprops} supportedSeverities={props.supportedSeverities} />;
      case MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION:
        return <Typography>{t("outcomes:create.triggers.firstSessionOfRemission.title")}</Typography>;
      case MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE:
        return <Typography>{t("outcomes:create.triggers.firstSessionOfResponse.title")}</Typography>;
    }
  })();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox checked={active} onChange={onCheckboxChange} />}
        label={component}
      />
    </FormGroup>
  );
}

export default function TriggersForm(props: TriggersFormProps) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);

  // Technically in the back end this is just a collection, but to make the UI simpler we're going to offer a set of checkboxes
  // one per type of success criteria.
  // This component will react and spit up a full set of objects, but the checkbox component itself is purely internal
  const [currentData, setCurrentData] =
    React.useState<Record<MetricTriggerType, MetricTriggerParams>>(defaultValues);

  const activeNodes: Partial<Record<MetricTriggerType, boolean>> = {};

  props.triggersField.value?.forEach((value) => {
    currentData[value.triggerType] = value;
    activeNodes[value.triggerType] = true;
  });

  const handleCheckboxChange = (triggerType: MetricTriggerType) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const set = props.triggersField.value || [];
      const without = set.filter((s) => s.triggerType !== triggerType);
      const item = currentData[triggerType];

      if (checked) {
        props.triggersField.onChange([...without, item]);
      } else {
        props.triggersField.onChange(without);
      }
    };
  };

  const handleDetailsChange = (triggerType: MetricTriggerType) => {
    return (newValue: MetricTriggerParams) => {
      const set = props.triggersField.value || [];
      const without = set.filter((s) => s.triggerType !== triggerType);
      setCurrentData({ ...currentData, [triggerType]: newValue });
      props.triggersField.onChange([...without, newValue]);
    };
  };

  let trendControls = null;

  if (props.supportedTrends.length > 0) {
    trendControls = (
      <>
        <Item
          active={activeNodes.FIRST_SESSION_OF_TREND || false}
          onCheckboxChange={handleCheckboxChange(MetricTriggerType.FIRST_SESSION_OF_TREND)}
          onUpdate={handleDetailsChange(MetricTriggerType.FIRST_SESSION_OF_TREND)}
          row={currentData.FIRST_SESSION_OF_TREND}
          supportedSeverities={props.supportedSeverities}
        />
      </>
    );
  }

  const remissionControls = props.supportedSeverities.includes(SeverityCategory.NONE) ? (
    <Item
      active={activeNodes.FIRST_SESSION_OF_TREATMENT_REMISSION || false}
      onCheckboxChange={handleCheckboxChange(MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION)}
      onUpdate={handleDetailsChange(MetricTriggerType.FIRST_SESSION_OF_TREATMENT_REMISSION)}
      row={currentData.FIRST_SESSION_OF_TREATMENT_REMISSION}
      supportedSeverities={props.supportedSeverities}
    />
  ) : null;

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.triggerBased.triggers.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.triggerBased.triggers.helperText")} />
      <Item
        active={activeNodes.FIRST_SESSION_OF_TREATMENT_RESPONSE || false}
        onCheckboxChange={handleCheckboxChange(MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE)}
        onUpdate={handleDetailsChange(MetricTriggerType.FIRST_SESSION_OF_TREATMENT_RESPONSE)}
        row={currentData.FIRST_SESSION_OF_TREATMENT_RESPONSE}
        supportedSeverities={props.supportedSeverities}
      />
      {remissionControls}
      {trendControls}
      <Item
        active={activeNodes.FIRST_SESSION_OF_IMPROVED_SEVERITY || false}
        onCheckboxChange={handleCheckboxChange(MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY)}
        onUpdate={handleDetailsChange(MetricTriggerType.FIRST_SESSION_OF_IMPROVED_SEVERITY)}
        row={currentData.FIRST_SESSION_OF_IMPROVED_SEVERITY}
        supportedSeverities={props.supportedSeverities}
      />
      <Item
        active={activeNodes.FIRST_SESSION_OF_SPECIFIED_SEVERITY || false}
        onCheckboxChange={handleCheckboxChange(MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY)}
        onUpdate={handleDetailsChange(MetricTriggerType.FIRST_SESSION_OF_SPECIFIED_SEVERITY)}
        row={currentData.FIRST_SESSION_OF_SPECIFIED_SEVERITY}
        supportedSeverities={props.supportedSeverities}
      />
    </FormControl>
  );
}
