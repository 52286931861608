import React, { ReactElement } from "react";
import { OrganizationId } from "Lib/Ids";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useOrganizationUpdateMeasurementAllowedMutation } from "GeneratedGraphQL/SchemaAndOperations";
import ImmediateSettingsSwitch from "Shared/ImmediateSettingSwitch";
import { refetchQueries } from "Lib/RefetchQueries";

type OrganizationSettingsMeasurementAllowedProps = {
  id: OrganizationId;
  measurementAllowed: boolean;
};

function OrganizationMeasurementAllowedSwitch(
  props: OrganizationSettingsMeasurementAllowedProps
): ReactElement {
  const { t } = useTranslation(["settings"]);
  const [updateMeasurementAllowed, { remoteData }] = apolloMutationHookWrapper(
    (data) => data.settingsOrganizationUpdateMeasurementAllowed,
    useOrganizationUpdateMeasurementAllowedMutation({
      refetchQueries: refetchQueries("organizationSettings"),
    })
  );
  const partialUpdateMeasurementAllowed = (allowed: boolean) => {
    updateMeasurementAllowed({
      variables: {
        input: {
          organizationId: props.id,
          measurementAllowed: allowed,
        },
      },
    });
  };
  return (
    <ImmediateSettingsSwitch
      value={props.measurementAllowed}
      label={t("settings:measurementAllowedSwitch.label")}
      description={t("settings:measurementAllowedSwitch.organizationTooltip")}
      remoteData={remoteData}
      query={partialUpdateMeasurementAllowed}
    />
  );
}

export default OrganizationMeasurementAllowedSwitch;
