import React, { ReactElement } from "react";
import { Card } from "@mui/material";
import { useIsMobile } from "Shared/Responsive";
import PatientReferenceCardHeader from "./PatientNameHeader";
import { PatientId } from "Lib/Ids";

type PatientDetailsCardProps = {
  patientId: PatientId;
  disableLink?: boolean;
};

function PatientDetailsCard(props: PatientDetailsCardProps): ReactElement {
  // Note that the PRCH is meant to float as the page is scrolled down to always
  // keep it in view on this specific page. The zindex is chosen to be above
  // all other elements, but not above the tooltips that the PRCH might itself
  // generate.
  // We have different sizes here because the actual top gutter is different sizes.
  const stickyTop = useIsMobile() ? "4em" : "4.7em";
  return (
    <>
      <Card variant="outlined" sx={{ position: "sticky", top: stickyTop, zIndex: "stickyCard" }}>
        <PatientReferenceCardHeader patientId={props.patientId} disableLink={props.disableLink} />
      </Card>
    </>
  );
}

export default PatientDetailsCard;
