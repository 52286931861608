import { Button, DialogContent, Link, Stack, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { Enrollment, useUpdateEnrollmentMutation } from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { refetchQueries } from "Lib/RefetchQueries";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Form, FormOverlay, useForm, useWrappedField } from "Shared/Form";
import { useProviderUserHasPermission } from "Shared/WithPermission";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export function UpdateConsentDateLink(props: {
  enrollment: Pick<Enrollment, "id" | "consentDate">;
  text: string;
}) {
  const [showForm, setShowForm] = useState<boolean>(false);
  const hasPermission = useProviderUserHasPermission("editCollaborativeCare");

  const link = <Link onClick={() => setShowForm(true)}>{props.text}</Link>;

  if (!hasPermission) {
    return null;
  }

  const dialog = showForm ? (
    <UpdateConsentDateButtonInternal {...props} setShowForm={setShowForm} showForm={showForm} />
  ) : null;

  return (
    <>
      {link}
      {dialog}
    </>
  );
}

function UpdateConsentDateButtonInternal(props: {
  enrollment: Pick<Enrollment, "id" | "consentDate">;
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}) {
  const { t } = useTranslation(["collaborativeCare"]);
  const { enrollment, showForm, setShowForm } = props;

  const fields = {
    consentDate: useWrappedField({ default: enrollment.consentDate, required: true }),
  };

  const [updateConsentDate, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareUpdateEnrollment,
    useUpdateEnrollmentMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  const form = useForm({
    id: "edit-consent-date-form",
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      updateConsentDate({
        variables: {
          input: {
            consentDate: assertNonNull(fields.consentDate.value),
            enrollmentId: props.enrollment.id,
          },
        },
      });
    },
  });

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setTimeout(() => setShowForm(false), 300);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const errorMessage = remoteData.caseOf({
    Failure: (error) => error.toError().message,
    _: () => t("collaborativeCare:enrollment.updateConsentDateDialog.errorSaving"),
  });

  return (
    <>
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("collaborativeCare:enrollment.updateConsentDateDialog.title")}
      >
        <DialogContent>
          <Form onSubmit={form.onSubmit} id={form.id}>
            <FormOverlay response={remoteData} errorMessage={errorMessage} />
            <Stack direction="column" spacing={1}>
              <Typography>{t("collaborativeCare:enrollment.updateConsentDateDialog.details")}</Typography>
              <DateTimePicker
                label={t("collaborativeCare:enrollment.updateConsentDateDialog.fieldLabel")}
                format={t("collaborativeCare:enrollment.updateConsentDateDialog.dateFormat")}
                value={fields.consentDate.value}
                onChange={fields.consentDate.onChange}
              />
              <Typography>
                {t("collaborativeCare:enrollment.updateConsentDateDialog.onlyTimeTracked")}
              </Typography>
              <Typography>
                {t("collaborativeCare:enrollment.updateConsentDateDialog.bulletHeader")}
              </Typography>
              <ul>
                <li>{t("collaborativeCare:enrollment.updateConsentDateDialog.bullet1")}</li>
                <li>{t("collaborativeCare:enrollment.updateConsentDateDialog.bullet2")}</li>
              </ul>
              <Stack direction="row" spacing={1}>
                <ButtonWithSpinner
                  variant={"contained"}
                  color="primary"
                  type="submit"
                  form={form.id}
                  showSpinner={form.showSpinner}
                  disabled={form.disableSubmit}
                >
                  {t("collaborativeCare:enrollment.updateConsentDateDialog.confirm")}
                </ButtonWithSpinner>
                <Button
                  onClick={() => setShowForm(false)}
                  disabled={false}
                  variant={"outlined"}
                  color={"secondary"}
                >
                  {t("collaborativeCare:enrollment.updateConsentDateDialog.abort")}
                </Button>
              </Stack>
            </Stack>
          </Form>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
