import { Card, CardContent, CardHeader, Grid, Link, Paper, TextField } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";

import React, { ReactElement, useState } from "react";

import {
  InstitutePatientListQuery,
  useInstitutePatientListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { InstituteId } from "Lib/Ids";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import { useEffectOnce } from "Lib/Hooks";

type ExtraVars = {
  search: string | null;
  instituteId: InstituteId | null;
};

function InstitutePatientList(props: {
  instituteId: InstituteId;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  useEffectOnce(() => {
    props.setSubCrumbs([{ text: "Patients", path: "/patients" }]);
  });
  const [search, updateSearch] = useState("");
  const extra: ExtraVars = {
    search: search,
    instituteId: props.instituteId,
  };
  const cols: DataGridCols<InstitutePatientListQuery, ["patients"]> = React.useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Link component={RouterLink} to={params.row.id.toString()}>
            {params.row.id.toString()}
          </Link>
        ),
      },
      {
        field: "firstName",
        headerName: "First Name",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.firstName,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.lastName,
      },
      {
        field: "gender",
        headerName: "Gender",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.gender,
      },
      {
        field: "dob",
        headerName: "DoB",

        sortable: false,
        filterable: false,
        valueGetter: (_value, row) =>
          row.dob
            ? new Date(row.dob).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : null,
      },
      {
        field: "mrn",
        headerName: "MRN",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.mrn,
      },
    ];
  }, []);
  return (
    <Page browserTitle="Institute Patients">
      <PageHeader title="Institute Patients" />

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Paper>
            <SortablePagableCollectionDataGrid
              queryHook={useInstitutePatientListQuery}
              unwrapData={(d) => d?.patients || null}
              queryVariables={extra}
              defaultPageSize={10}
              colNameToSortParam={(_colName) => null}
              columns={cols}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader title="Search" />
            <CardContent>
              <TextField
                label="Search name"
                variant="outlined"
                fullWidth
                defaultValue={search}
                onChange={(ev: React.ChangeEvent<{ value: string }>) => updateSearch(ev.target.value)}
                inputProps={{ "data-testid": "search" }}
                size="small"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default InstitutePatientList;
