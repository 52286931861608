const settings = {
  beta: "BETA",
  errors: {
    unknown: "Unknown error changing setting",
  },
  measurementAllowedSwitch: {
    label: "Measurement Based Care Allowed",
    instituteTooltip: "Whether or not this institute can have assessments created automatically.",
    organizationTooltip: "Whether or not this organization can have assessments created automatically.",
    providerTooltip: "Whether or not this provider can have assessments created automatically.",
    careEpisodeTooltip: "Whether or not this care episode can have assessments created automatically.",
  },
  measurementAllowedSwitchRedesign: {
    label: "Automatic Measurement On",
    instituteTooltip: "Whether or not this institute can have assessments created automatically.",
    organizationTooltip: "Whether or not this organization can have assessments created automatically.",
    providerTooltip: "Whether or not this provider can have assessments created automatically.",
    careEpisodeTooltip: "Whether or not this care episode can have assessments created automatically.",
  },
  measurementAllowedBadge: {
    allowed: "Participating in Measurement Based Care",
    allowedInFuture: "Participating from $t(common:date.medium)",
    notAllowed: "Not Participating in Measurement Based Care",
    defaultTooltipPrefix: "Participating in Measurement Based Care is set to",
    defaultTooltipAllowed: "TRUE",
    defaultTooltipFuture: "STARTS $t(common:date.medium)",
    defaultTooltipNotAllowed: "FALSE",
    careEpisodeTitle:
      "For this Care Episode to be participating in measurement based care, all of the conditions below must be met:",
    careEpisodeProviderDescription:
      "In order for sessions to be measured, at least one provider must be enabled. Currently, the statuses of the providers assigned to this care episode are:",
  },
  measurementAllowedBadgeRedesign: {
    allowed: "Measured Automatically",
    notAllowed: "Not Measurement Automatically",
    allowedInFuture: "Measured Automatically from $t(common:date.medium)",
    defaultTooltipPrefix: "Automatic Measurement is set to",
    defaultTooltipAllowed: "TRUE",
    defaultTooltipNotAllowed: "FALSE",
    defaultTooltipFuture: "STARTS $t(common:date.medium)",
    careEpisodeTitle:
      "For this Care Episode to be automatically measured, all of the conditions below must be met:",
    careEpisodeProviderDescription:
      "In order for sessions to be measured, at least one provider must be enabled. Currently, the statuses of the providers assigned to this care episode are:",
  },
  trialPeriodSwitch: {
    label: "Trial Period",
    tooltip: "Whether or not this organization is currently in a trial period.",
    careEpisodesOption: "Enable all care episodes",
    careEpisodesOptionTooltip:
      "When turning an organization's trial period off, selecting this option will enable measurement based care in all care episodes in that organization.",
    confirmTitle: "Enable all care episodes?",
    confirmText:
      "This will enable measurement base care for all care episodes in this organization. Patients in those care episodes will start recieving notifications. Are you sure?",
    confirm: "Ok",
    cancel: "Cancel",
  },
  treatmentTrack: {
    listTreatmentTrack: {
      title: "Treatment Tracks",
      error: "Could not find treatment tracks!",
      name: "Name",
      measurementPlan: "Measurement Plan",
      status: "Status",
      edit: "Edit",
      create: "Create",
    },
    createTreatmentTrack: {
      title: "Create Treatment Track",
      name: "Name",
      status: "Status",
      create: "Create",
      cancel: "Cancel",
      error: "Something went wrong",
    },
    updateTreatmentTrack: {
      title: "Update Treatment Track",
      notFound: "Could not find treatment track!",
      name: "Name",
      status: "Status",
      update: "Update",
      cancel: "Cancel",
    },
  },
  institute: {
    instituteSettingsLabel: "Institute Settings",
    organizationSettingsLabel: "Organization Settings",
    measureStartingImmediately: "Measures start immediately",
    measureStarting: "Measures start from $t(common:date.medium)",
  },
  organization: {
    title: "Organization Settings",
    error: "Error",
    timezone: {
      timezone: "Timezone",
      inherit: "Inherit from Institute",
    },
    switchesLabel: "Switches",
    switchesSublabel: "Immediately apply",
    settingsLabel: "Settings",
    settingsSublabel: "Applied on submit",
    settingsSubmit: "Submit",
  },
  exports: {
    title: "Data Exports",
    error: "An unknown error has occurred",
    download: "Download",
    downloadBegins: "Your download will begin shortly",
    name: "Name",
    browserTitle: "Mirah | Data Exports",
    password: "Zip Password",
    createNew: "Create New Export",
    status: "Status",
    actions: "Actions",
    createdAt: "Date Created",
    createExport: "Create Export",
    create: {
      beginExport: "Begin Data Export",
      name: {
        label: "Name",
      },
      startDate: {
        label: "Start Date (leave blank for all time)",
        format: "MM/dd/yyyy",
      },
      endDate: {
        label: "End Date (leave blank for all time)",
        format: "MM/dd/yyyy",
      },
      resourceExplanation: "By default this will create an export with the Research Set file.",
      showAvailableResources: "Show Available Files",
    },
  },
  internalUser: {
    disabledButton: "Disabled for Internal",
    disabledButtonShort: "Disabled",
  },
  impersonatingUser: {
    menuItemBlank: "Impersonate User",
    menuItemActive: "Impersonating {{name}}",
    title: "Impersonate User",
    currentlyAssuming: "You are currently assuming the role of: {{name}}",
    stopAssuming: "Stop assuming role",
    explanation:
      "This feature means that when you query data, instead of being listed as having an internal role with all access to the data, you will instead see the data through the lens of the targeted user. You will be in readonly mode and unable to create new data or do anything until you revert to being yourself. All data access and actions you take on the platform will still be logged as your internal user. This feature may cause some pages to break.",
    disabledButton: "Disabled While Impersonating",
  },
  instituteGroupUser: {
    disabledButton: "Read Only Mode",
  },
  piiLevel: {
    title: "Viewing Identifiable Information",
    navItem: "Change PII Visibility",
    anonUser: "Anonymous User",
    anonInstitute: "Anonymous Institute",
    explanation:
      "Choosing an option here changes the level of information which is displayed in the platform to have more or less identifiable information. Note that changing to a new option will cause the current page to fully reload.",
    full_access: {
      title: "Full Access",
      description:
        "Shows all Personally Identifiable Information (PII) in the system. This is the default setting. Suitable for use by healthcare professionals who have appropriate authorization to view data under HIPAA and organization policy.",
    },
    limited_pii: {
      title: "Limited PII",
      description:
        "Hides most Personally Identifiable Information (PII) for patients in the system. Free text fields that may contain information that might in certain circumstances be identifiable will still be available. Patient names will be replaced by anonymous identifiers. Information on the provider, such as names and facility information, is still available. This mode is suitable for use by consultants who are not authorized to view PHI. Dates are rounded to the nearest month.",
    },
    pii_free: {
      title: "Hide PII",
      description:
        "Hides all Personally Identifiable Information (PII) for patients in the system. Free text fields that may contain information that might in certain circumstances be identifiable will be redacted. Patient names will be replaced by anonymous identifiers. Information on the provider, such as names and facility information, is still available. Dates are rounded to the nearest month.",
    },
    full_anon: {
      title: "Fully Anonymous",
      description:
        "Hides all Personally Identifiable Information (PII) for both patients, providers and facilities. This mode can be used when wanting to show anonymous data without attribution to any specific healthcare provider. Dates are rounded to the nearest month.",
    },
    disabledButton: "Disabled At This PII Level",
  },
};

export default settings;
