import { ComponentsOverrides } from "@mui/material";

const styleOverrides: ComponentsOverrides["MuiTypography"] = {
  gutterBottom: {
    marginBottom: 8,
  },
};

const override = {
  styleOverrides,
};

export default override;
