import { initials } from "../../../Lib/Utils";
import { Avatar, Box, ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavUserMenu } from "Layout/AppLayout/NavBar/NavUserMenu";
import { UserMenuItem } from "../NavBar/NavBar";
import React from "react";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  height: 32,
  width: 32,
  marginRight: theme.spacing(1),
}));

type Props = {
  user: {
    name: string;
    lastSignIn: Date | null;
  };
  userBadge?: { count: number };
  userMenuItems: Array<UserMenuItem>;
  isMobile: boolean;
};

function Account({ user, userMenuItems, userBadge, isMobile }: Props) {
  const account = {
    user: {
      name: user.name,
    },
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }} component={ButtonBase}>
        <StyledAvatar alt="User">{initials(account.user.name)}</StyledAvatar>
        <NavUserMenu
          user={user}
          userBadge={userBadge}
          fontVariant="body2"
          arrowSize={20}
          userMenuItems={userMenuItems}
          isMobile={isMobile}
        />
      </Box>
    </>
  );
}

export default Account;
