import { Check, DoNotDisturb } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Grid, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import EntityPath, { EntityTreeNodeDetails } from "Entities/EntityPath";
import { cocmBillingAlgorithmStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  CocmBillingAlgorithm,
  CocmBillingAlgorithmCode,
  CocmBillingAlgorithmRule,
  CocmBillingAlgorithmRuleCode,
  useCocmBillingAlgorithmDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { CocmBillingAlgorithmId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import { DisplayMoney, MoneyDetails } from "Shared/Money";
import NotFound from "Shared/NotFound";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import { sortBy } from "ramda";

type CodeDetails = Pick<
  CocmBillingAlgorithmCode,
  "code" | "valueUnits" | "rvusPerUnit" | "initialEncounter"
> & {
  avgRatePerUnit: MoneyDetails;
};

type RuleCodeDetails = Pick<CocmBillingAlgorithmRuleCode, "units"> & {
  code: Pick<CocmBillingAlgorithmCode, "code">;
};

type RuleDetails = Pick<
  CocmBillingAlgorithmRule,
  | "anyAssessmentCompleted"
  | "billingDate"
  | "minimumMinutes"
  | "billableMinutes"
  | "mustBeInitialEncounter"
  | "mustBeSubsequentEncounter"
  | "priority"
> & {
  ruleCodes: ReadonlyArray<RuleCodeDetails>;
};

type AlgorithmDetails = Pick<CocmBillingAlgorithm, "status" | "name" | "startDate" | "endDate"> & {
  entityTreeNode: EntityTreeNodeDetails;
  rules: ReadonlyArray<RuleDetails>;
  codes: ReadonlyArray<CodeDetails>;
};

function RuleCodeItem(props: { code: RuleCodeDetails }) {
  return (
    <Box>
      <Typography fontWeight={"bold"}>{props.code.code.code}</Typography> x {props.code.units}
    </Box>
  );
}

function RuleItem(props: { rule: RuleDetails }) {
  const { t } = useTranslation(["enums", "billing"]);
  const { rule } = props;

  const codes = rule.ruleCodes.map((code, index) => {
    return <RuleCodeItem code={code} key={index} />;
  });

  return (
    <Box border={"thin black solid"} padding={"1em"}>
      <Typography variant="h2">{t("billing:rules.rule", { number: rule.priority })}</Typography>
      <Grid container spacing={1} columns={2}>
        <Grid item lg={1} xs={1}>
          <Grid container spacing={0.5} alignItems="center">
            <Grid item xs={4}>
              <Typography>{t("billing:rules.billableMinutes")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {rule.billableMinutes}
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("billing:rules.minimumMinutes.title")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {rule.minimumMinutes}
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("billing:rules.anyAssessmentCompleted.title")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {rule.anyAssessmentCompleted ? <Check fontSize="small" /> : <DoNotDisturb fontSize="small" />}
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("billing:rules.mustBeInitialEncounter.title")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {rule.mustBeInitialEncounter ? <Check fontSize="small" /> : <DoNotDisturb fontSize="small" />}
            </Grid>
            <Grid item xs={4}>
              <Typography>{t("billing:rules.mustBeSubsequentEncounter.title")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {rule.mustBeSubsequentEncounter ? (
                <Check fontSize="small" />
              ) : (
                <DoNotDisturb fontSize="small" />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1} xs={1}>
          {codes}
        </Grid>
      </Grid>
    </Box>
  );
}

function CodeItem(props: { code: CodeDetails }) {
  const { t } = useTranslation(["billing"]);
  const { code } = props;
  return (
    <Box border={"thin black solid"} padding={"1em"}>
      <Typography variant="h2">{code.code}</Typography>
      <Grid container spacing={0.5} alignItems="center">
        <Grid item xs={4}>
          <Typography>{t("billing:codes.avgRatePerUnit.title")}</Typography>
        </Grid>
        <Grid item xs={8}>
          <DisplayMoney money={code.avgRatePerUnit} />
        </Grid>
        <Grid item xs={4}>
          <Typography>{t("billing:codes.valueUnits.title")}</Typography>
        </Grid>
        <Grid item xs={8}>
          {code.valueUnits}
        </Grid>
        <Grid item xs={4}>
          <Typography>{t("billing:codes.rvusPerUnit.title")}</Typography>
        </Grid>
        <Grid item xs={8}>
          {code.rvusPerUnit}
        </Grid>
        <Grid item xs={4}>
          <Typography>{t("billing:codes.initialEncounter.title")}</Typography>
        </Grid>
        <Grid item xs={8}>
          {code.initialEncounter ? <Check fontSize="small" /> : <DoNotDisturb fontSize="small" />}
        </Grid>
      </Grid>
    </Box>
  );
}

function CodesSection(props: { codes: ReadonlyArray<CodeDetails> }) {
  const { t } = useTranslation(["billing"]);

  const codes = props.codes.map((code, index) => {
    return <CodeItem code={code} key={index} />;
  });

  return (
    <Card>
      <CardHeader title={t("billing:algorithmDetails.codes.title")} />
      <CardContent>
        <Stack spacing={1}>{codes}</Stack>
      </CardContent>
    </Card>
  );
}

function RulesSection(props: { rules: ReadonlyArray<RuleDetails> }) {
  const { t } = useTranslation(["billing"]);

  const sortedRules = sortBy((item: RuleDetails) => item.priority, props.rules);

  const rules = sortedRules.map((rule, index) => {
    return <RuleItem rule={rule} key={index} />;
  });

  return (
    <Card>
      <CardHeader title={t("billing:algorithmDetails.rules.title")} />
      <CardContent>
        <Stack spacing={1}>{rules}</Stack>
      </CardContent>
    </Card>
  );
}

function AlgorithmDetailsCard(props: { algorithm: AlgorithmDetails }) {
  const { t } = useTranslation(["enums", "billing", "common"]);
  const { algorithm } = props;
  return (
    <Stack spacing={1}>
      <Card>
        <CardHeader title={algorithm.name} />
        <CardContent>
          <Grid container spacing={0.5} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="h3">{t("billing:algorithmDetails.status")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {cocmBillingAlgorithmStatusT(algorithm.status, t)}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h3">{t("billing:algorithmDetails.entity")}</Typography>
            </Grid>
            <Grid item xs={8}>
              <EntityPath includeInstitute={"always"} entityTreeNode={algorithm.entityTreeNode} />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h3">{t("billing:algorithmDetails.startDate")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {t("common:date.tiny", { date: algorithm.startDate })}
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h3">{t("billing:algorithmDetails.endDate")}</Typography>
            </Grid>
            <Grid item xs={8}>
              {algorithm.endDate
                ? t("common:date.tiny", { date: algorithm.endDate })
                : t("billing:algorithm.endDateActive")}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <CodesSection codes={algorithm.codes} />
      <RulesSection rules={algorithm.rules} />
      <RulesExplanation />
    </Stack>
  );
}

function RulesExplanation() {
  const { t } = useTranslation(["billing"]);
  return (
    <Card>
      <CardHeader title={t("billing:algorithmDetails.explanation.title")} />
      <CardContent>
        <Typography variant="h2">{t("billing:algorithmDetails.explanation.algorithmTitle")}</Typography>
        <Typography paddingBottom="1em">
          {t("billing:algorithmDetails.explanation.algorithmDescription")}
        </Typography>
        <Typography variant="h2">{t("billing:algorithmDetails.explanation.careUnits")}</Typography>
        <Typography paddingBottom={"1em"}>
          {t("billing:algorithmDetails.explanation.careUnitsDetails")}
        </Typography>
        <Typography variant="h2">{t("billing:algorithmDetails.explanation.rvus")}</Typography>
        <Typography>{t("billing:algorithmDetails.explanation.rvuDetails")}</Typography>
      </CardContent>
    </Card>
  );
}

export default function BillingAlgorithmDetails(params: { id: CocmBillingAlgorithmId }) {
  const { t } = useTranslation(["billing"]);

  const { remoteData } = apolloQueryHookWrapper(
    useCocmBillingAlgorithmDetailsQuery({
      variables: {
        id: params.id,
      },
    })
  );

  const details = remoteData.caseOf({
    Success: (result) => {
      if (result.billingCocmBillingAlgorithm) {
        return <AlgorithmDetailsCard algorithm={result.billingCocmBillingAlgorithm} />;
      } else {
        return <NotFound />;
      }
    },
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Failure: (e) => <ErrorMessage message={e.message} />,
  });

  return <Page browserTitle={t("billing:algorithmDetails.pageTitle")}>{details}</Page>;
}
