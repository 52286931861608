// Sentry is very insistent on being the first thing loaded
import "./instrument";
import React from "react";
import { createRoot } from "react-dom/client";
import "./i18n";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import Style from "./Style";
import { ApolloProvider } from "@apollo/client";
import { client } from "Api/GraphQL";
import { BrowserRouter } from "react-router-dom";
import captureException from "Lib/ErrorHandling";
import { LaunchContextProvider } from "Contexts/LaunchContext";
import * as Sentry from "@sentry/react";
import FatalError from "Shared/FatalError";

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    environment: string | undefined;
    appVersion: string | undefined;
  }
}

// New React 18 mounting incantation:
// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container, {
    // Callback called when an error is thrown and not caught by an ErrorBoundary.
    onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
      console.warn("Uncaught error", error, errorInfo.componentStack);
    }),
    // Callback called when React catches an error in an ErrorBoundary.
    onCaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
      console.warn("Uncaught error", error, errorInfo.componentStack);
    }),
    // Callback called when React automatically recovers from errors.
    onRecoverableError: Sentry.reactErrorHandler((error, errorInfo) => {
      console.warn("Uncaught error", error, errorInfo.componentStack);
    }),
  });
  // If you are developing single sign on flows, the double render caused by StrictMode can invalidate the token
  // you generate. Comment it out when developing.
  root.render(
    <React.StrictMode>
      <Style>
        <LaunchContextProvider>
          <BrowserRouter>
            <ApolloProvider client={client}>
              <Sentry.ErrorBoundary fallback={({ resetError }) => <FatalError reset={resetError} />}>
                <App />
              </Sentry.ErrorBoundary>
            </ApolloProvider>
          </BrowserRouter>
        </LaunchContextProvider>
      </Style>
    </React.StrictMode>
  );
} else {
  // Something has gone catastrophically wrong and we can't mount the app. What do?
  captureException(new Error("Unable to mount React app, no #root element."), "index");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
