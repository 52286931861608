import { useDownloadEnrollmentMonthSummaryPdfLazyQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentMonthId } from "Lib/Ids";
import React from "react";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useTranslation } from "react-i18next";
import { downloadPdf } from "Shared/PdfDownload";
import { PictureAsPdf } from "@mui/icons-material";

export function DownloadEnrollmentMonthSummaryPdfButton(props: {
  disabled: boolean;
  enrollmentMonthId: EnrollmentMonthId;
}) {
  const { t } = useTranslation(["collaborativeCare"]);

  const [fetchPdfData, fetchPdfResult] = useDownloadEnrollmentMonthSummaryPdfLazyQuery({
    variables: { enrollmentMonthId: props.enrollmentMonthId },
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data.collaborativeCareEnrollmentMonth?.monthlySummaryPdfContentBase64) {
        downloadPdf(
          data.collaborativeCareEnrollmentMonth.monthlySummaryPdfContentBase64,
          t("collaborativeCare:patientMonthlyBilling.enrollmentMonthSummaryPdfTitle")
        );
      }
    },
  });

  const disabled = props.disabled || fetchPdfResult.loading;

  const style = { textAlign: "center", width: "100%" };
  return (
    <ButtonWithSpinner
      sx={style}
      disabled={disabled}
      showSpinner={fetchPdfResult.loading}
      onClick={function () {
        fetchPdfData();
      }}
    >
      <PictureAsPdf />
    </ButtonWithSpinner>
  );
}
