import React, { ReactElement, ReactNode } from "react";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledToolTip = styled(Tooltip)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
  },
}));

type Props = {
  title: string | ReactNode;
  children: ReactElement;
};

function SimpleTooltip(props: Props): ReactElement {
  return <StyledToolTip title={props.title}>{props.children}</StyledToolTip>;
}

export default SimpleTooltip;
