import {
  DataFetchRequestStatus,
  Encounter,
  Enrollment,
  EnrollmentMonth,
  useActiveEncounterDetailsQuery,
  useActiveEncounterFetchQuery,
  useActiveEnrollmentMonthEncounterQuery,
  useAssignDocumentationEncounterMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";

import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { EncounterId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { monthToday } from "Shared/Month";
import { useQueryStringIdParameter } from "Shared/QueryStringParameter";
import { remotePair } from "Lib/RemoteData";
import ErrorMessage from "Shared/ErrorMessage";
import { Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";

const POLL_INTERVAL = 1000; // Check every second for updates

function ActiveEncounterCard({
  enrollment,
}: {
  enrollment: Pick<Enrollment, "id"> | null;
}): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);
  // We need to do it this way because otherwise it'll poll indefinitely
  const [fetchError, setFetchError] = React.useState(false);
  const [encounterId, setEncounterId] = useQueryStringIdParameter<"Encounter">("launchEncounterId");
  const [pendingEncounterFetchId] = useQueryStringIdParameter<"DataFetchRequest">("pendingEncounterFetchId");

  useActiveEncounterFetchQuery({
    // We can assert because of the skip below.
    variables: { dataFetchId: assertNonNull(pendingEncounterFetchId) },
    pollInterval: POLL_INTERVAL,
    skip: !!encounterId || !pendingEncounterFetchId || !enrollment,

    onCompleted: (result) => {
      if (result.integrationsDataFetchRequest?.dataSourceRecord?.target?.id) {
        setEncounterId(
          result.integrationsDataFetchRequest.dataSourceRecord.target.id as unknown as EncounterId
        );
      } else if (
        result.integrationsDataFetchRequest?.status !== DataFetchRequestStatus.IN_PROGRESS &&
        result.integrationsDataFetchRequest?.status !== DataFetchRequestStatus.PENDING
      ) {
        setFetchError(true);
      }
    },
  });

  if (!enrollment && !pendingEncounterFetchId && !encounterId) {
    return null;
  }

  let body = null;

  if (encounterId && enrollment) {
    body = <ActiveEncounterDetailsCard encounterId={encounterId} enrollment={enrollment} />;
  } else if (fetchError) {
    body = (
      <CardContent>
        <Typography>{t("collaborativeCare:patientDetails.activeEncounter.fetchError")}</Typography>
      </CardContent>
    );
  } else {
    body = (
      <CardContent>
        <Typography>{t("collaborativeCare:patientDetails.activeEncounter.fetching")}</Typography>
      </CardContent>
    );
  }

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:patientDetails.activeEncounter.title")} />
      {body}
    </Card>
  );
}

function ActiveEncounterDetailsCard(props: { encounterId: EncounterId; enrollment: Pick<Enrollment, "id"> }) {
  const [currentMonth] = React.useState(monthToday());
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData: encounterData } = apolloQueryHookWrapper(
    useActiveEncounterDetailsQuery({ variables: { encounterId: props.encounterId } })
  );
  const { remoteData: enrollmentMonthData } = apolloQueryHookWrapper(
    useActiveEnrollmentMonthEncounterQuery({
      variables: { enrollmentId: props.enrollment.id, monthAndYear: currentMonth },
    })
  );

  return remotePair(encounterData, enrollmentMonthData).caseOf({
    Success: (data) => {
      if (data[0].encounter && data[1].collaborativeCareEnrollmentMonths?.nodes[0]) {
        return (
          <ActiveEncounterUpdate
            encounter={data[0].encounter}
            enrollmentMonth={data[1].collaborativeCareEnrollmentMonths.nodes[0]}
          />
        );
      } else {
        return (
          <CardContent>
            <Typography>{t("collaborativeCare:patientDetails.activeEncounter.fetchError")}</Typography>
          </CardContent>
        );
      }
    },
    Loading: () => (
      <CardContent>
        <Typography>{t("collaborativeCare:patientDetails.activeEncounter.fetching")}</Typography>
      </CardContent>
    ),
    Failure: (e) => (
      <CardContent>
        <ErrorMessage message={e.message} />
      </CardContent>
    ),
    NotAsked: () => null,
  });
}

type EncounterDetails = Pick<Encounter, "id">;
type EnrollmentMonthDetails = Pick<EnrollmentMonth, "id" | "beginningOfMonth"> & {
  encounter: Pick<Encounter, "id"> | null;
};

function ActiveEncounterUpdate(props: {
  encounter: EncounterDetails;
  enrollmentMonth: EnrollmentMonthDetails;
}) {
  const { t } = useTranslation(["collaborativeCare"]);
  const [assignDocumentationEncounter, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareAssignDocumentationEncounter,
    useAssignDocumentationEncounterMutation({
      variables: {
        input: {
          encounterId: props.encounter.id,
          enrollmentMonthId: props.enrollmentMonth.id,
          overwrite: true,
        },
      },
    })
  );

  const noActionRequired = props.encounter.id === props.enrollmentMonth.encounter?.id;
  const existingEncounter = !!props.enrollmentMonth.encounter;

  let bodyText = t("collaborativeCare:patientDetails.activeEncounter.setEncounter");

  if (noActionRequired) {
    if (remoteData.kind === "Success") {
      bodyText = t("collaborativeCare:patientDetails.activeEncounter.justSet");
    } else {
      bodyText = t("collaborativeCare:patientDetails.activeEncounter.alreadySet");
    }
  } else if (existingEncounter) {
    bodyText = t("collaborativeCare:patientDetails.activeEncounter.overwriteEncounter");
  } else {
    bodyText = t("collaborativeCare:patientDetails.activeEncounter.setEncounter");
  }

  const buttonText = noActionRequired
    ? t("collaborativeCare:patientDetails.activeEncounter.setEncounterButtonNoAction")
    : t("collaborativeCare:patientDetails.activeEncounter.setEncounterButton");

  return (
    <>
      <CardContent>
        <Typography>{bodyText}</Typography>
        <Typography variant="caption">
          {t("collaborativeCare:patientDetails.activeEncounter.explanation")}
        </Typography>
      </CardContent>
      <CardActions>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          type="button"
          showSpinner={remoteData.kind === "Loading"}
          disabled={remoteData.kind === "Loading" || noActionRequired}
          onClick={() => assignDocumentationEncounter()}
        >
          {buttonText}
        </ButtonWithSpinner>
      </CardActions>
    </>
  );
}

export default ActiveEncounterCard;
