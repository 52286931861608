import { useParams } from "react-router-dom";
import { InstitutePatientQuery, useInstitutePatientQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { fromNullableString } from "Lib/Id";
import { default as React, ReactElement, ReactNode } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import DetailsLayout from "../DetailsLayout";
import { format } from "date-fns";
import { Maybe } from "seidr";
import { Dig } from "type-utils";
import AppointmentTab from "./AppointmentTab";
import { userOverviewFields } from "../UserOverviewFields";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import InspectorTab from "../../../Inspector/InspectorTab";

function InstitutePatient(props: {
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const params = useParams<{ patientId?: string }>();
  return fromNullableString<"Patient">(params.patientId).caseOf({
    Ok: (patientId) => (
      <DetailsLayout
        setSubCrumbs={props.setSubCrumbs}
        subCrumbs={(x) => [
          { text: "Patients", path: "/patients" },
          { text: x.name, path: `/patients/${x.id}` },
        ]}
        queryResult={useInstitutePatientQuery({
          variables: {
            patientId: patientId,
          },
        })}
        headerContents={(p) => (
          <div>
            {Maybe.fromNullable(p.dob)
              .map((d) => format(d, "MM/dd/yyy"))
              .getOrElse("NOT SET")}
          </div>
        )}
        browserTitle={() => "Patient"}
        titleAction={(p) => <div>({p.mrn})</div>}
        resultFetcher={(x: InstitutePatientQuery) => x.patient}
        title={(p) => p.name}
        overviewFields={(p) => overviewFields(p)}
        integrationsDataSourceRecords={(p) => p.integrationsDataSourceRecords}
        tabs={(x) => [
          {
            component: <AppointmentTab patientId={x.id} />,
            tabLabel: "Appointments",
            path: "appointments",
          },
          {
            component: <InspectorTab className={"Patient"} id={patientId.toString()} />,
            tabLabel: "Inspector",
            path: "inspector",
          },
        ]}
      />
    ),

    Err: () => <ErrorMessage message="Invalid URL" />,
  });
}

function overviewFields(dat: NonNullable<Dig<InstitutePatientQuery, ["patient"]>>) {
  const extraFields: Array<{
    value: string | ReactNode | null | boolean;
    name: string;
  }> = [
    {
      value: Maybe.fromNullable(dat.dob)
        .map((d) => format(d, "MM/dd/yyy"))
        .getOrElse(null),
      name: "Date of Birth",
    },
    { name: "id", value: dat.id.toString() },
    { name: "name", value: dat.name },
    { name: "phoneNumber", value: dat.phoneNumber },
    { name: "email", value: dat.email },
    { name: "primaryLanguage", value: dat.primaryLanguage },
    { name: "gender", value: dat.gender?.toString() },
    { name: "mrn", value: dat.mrn },
    { name: "firstName", value: dat.firstName },
    { name: "lastName", value: dat.lastName },
    { name: "isTest", value: dat.isTest },
    { name: "source", value: dat.source },
    { name: "autoCreateSessions", value: dat.autoCreateSessions },
    { name: "isProvider", value: dat.isProvider },
    { name: "genderSource", value: dat.genderSource },
    { name: "enableNotifications", value: dat.enableNotifications },
    { name: "groupName", value: dat.groupName },
    { name: "lastSsn", value: dat.lastSsn },
    { name: "maritalStatus", value: dat.maritalStatus },
    { name: "race", value: dat.race },
    { name: "researchOther", value: dat.researchOther },
    { name: "researchOtherDiagnoses", value: dat.researchOtherDiagnoses },
    { name: "researchPrimaryDiagnosis", value: dat.researchPrimaryDiagnosis },
    { name: "state", value: dat.state },
    { name: "veteranStatus", value: dat.veteranStatus },
    { name: "zipCode", value: dat.zipCode },
    { name: "ethnicity", value: dat.ethnicity },
  ];

  return extraFields.concat(userOverviewFields(dat.user));
}

export default InstitutePatient;
