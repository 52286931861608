const failedLogins = {
  headers: {
    eventDate: "Time",
    lastName: "Input Last Name",
    birthdate: "Input Birthdate",
    lastSsn: "Input SSN",
    session: "Session",
  },
  pageTitle: "Mirah",
  title: "Failed Patient Logins",
  eventDate: "{{ date; distanceToNow }} ago",
  noSession: "unknown",
  noLastName: "not asked",
  expectedBirthdate: "expected $t(common:date.medium)",
  expectedSsn: "expected {{ ssn }}",
};

export default failedLogins;
