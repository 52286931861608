import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Input,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  MetricInclusionCriterionType,
  Operator,
  SeverityCategory,
  MetricInclusionCriterionParams,
  Gender as GenderEnum,
} from "GeneratedGraphQL/SchemaAndOperations";
import { SectionHelpText } from "Outcomes/CreateTimeBasedMetricForm";
import { FieldMethods } from "Shared/Form";
import React from "react";
import { SeveritySelectMultiple } from "./SeverityHelpers";
import { ExclusionSelect, OperatorSelect } from "./CommonOutcomeFormElements";
import { useTranslation } from "react-i18next";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";
import { EnumSelectMultiple } from "Shared/EnumSelect";
import { genderEnumFromString, genderStringFromEnum } from "Lib/Gender";
import { genderT } from "GeneratedGraphQL/EnumTranslations";
import { RaceSelectMultiple, raceStringToArray } from "Shared/RaceSelect";

type InclusionCriteriaFormProps = {
  inclusionCriteriaField: FieldMethods<
    ReadonlyArray<MetricInclusionCriterionParams>,
    ReadonlyArray<MetricInclusionCriterionParams>
  >;
  supportedSeverities: ReadonlyArray<SeverityCategory>;
};

type LineItemProps = {
  disabled: boolean;
  row: MetricInclusionCriterionParams;
  onUpdate: (data: MetricInclusionCriterionParams) => void;
};

/**
 * The delta controller is slightly complicated because although under the hood it deals with
 * things like '< -5' people find it difficult to understand so we have to translate into a simpler to understand
 */
function DaysSinceTreatmentStart(props: LineItemProps) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const operator = row.operator || Operator.LESS_THAN;
  const value = row.numberValue || 0;

  const onOperatorChange = (event: SelectChangeEvent<Operator>) => {
    onUpdate({ ...row, operator: event.target.value as Operator });
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    onUpdate({ ...row, numberValue: newValue });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <OperatorSelect operator={operator} disabled={disabled} onChange={onOperatorChange} />
        <FormControl>
          <Input
            sx={{ width: "2.5em" }}
            onChange={onValueChange}
            type="number"
            inputProps={{ min: 0 }}
            value={value}
            disabled={disabled}
          />
        </FormControl>
        <span>{t("outcomes:create.inclusionCriteria.daysSinceTreatmentStart.title")}</span>
      </Stack>
    </FormControl>
  );
}

function DaysBetweenTreatmentStartAndBaseline(props: LineItemProps) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const operator = row.operator || Operator.LESS_THAN;
  const value = row.numberValue || 0;

  const onOperatorChange = (event: SelectChangeEvent<Operator>) => {
    onUpdate({ ...row, operator: event.target.value as Operator });
  };

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    onUpdate({ ...row, numberValue: newValue });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <OperatorSelect operator={operator} disabled={disabled} onChange={onOperatorChange} />
        <FormControl>
          <Input
            sx={{ width: "2.5em" }}
            onChange={onValueChange}
            type="number"
            inputProps={{ min: 0 }}
            value={value}
            disabled={disabled}
          />
        </FormControl>
        <span>{t("outcomes:create.inclusionCriteria.daysBetweenTreatmentStartAndBaseline.title")}</span>
      </Stack>
    </FormControl>
  );
}

function BaselineSeverity(props: LineItemProps & { supportedSeverities: ReadonlyArray<SeverityCategory> }) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const value = row.severityValues || [SeverityCategory.MILD];

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.inclusionCriteria.baselineSeverity.title")}</span>
        <FormControl>
          <SeveritySelectMultiple
            supportedSeverities={props.supportedSeverities}
            disabled={disabled}
            severity={value}
            onChange={(severity) => onUpdate({ ...row, severityValues: severity })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

function Gender(props: LineItemProps) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // The enum select uses the graphql enum, but we want to translate to the string M, F, etc.
  const values = (row.stringArrayValue || []).map(genderEnumFromString);

  const onExclusionChange = (event: SelectChangeEvent<boolean>) => {
    const newValue = event.target.value === "true";
    onUpdate({ ...row, excludeResults: newValue });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.inclusionCriteria.gender.title")}</span>

        <FormControl>
          <ExclusionSelect
            excludeResults={row.excludeResults || false}
            disabled={disabled}
            onChange={onExclusionChange}
          />
        </FormControl>
        <FormControl>
          <EnumSelectMultiple
            disabled={disabled}
            value={values}
            enumTrans={genderT}
            optionsEnum={GenderEnum}
            onChange={(genders) =>
              onUpdate({ ...row, stringArrayValue: genders?.map(genderStringFromEnum) || [] })
            }
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

function Race(props: LineItemProps) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  const values = raceStringToArray(row.stringArrayValue || []);

  const onExclusionChange = (event: SelectChangeEvent<boolean>) => {
    const newValue = event.target.value === "true";
    onUpdate({ ...row, excludeResults: newValue });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.inclusionCriteria.race.title")}</span>

        <FormControl>
          <ExclusionSelect
            excludeResults={row.excludeResults || false}
            disabled={disabled}
            onChange={onExclusionChange}
          />
        </FormControl>
        <FormControl>
          <RaceSelectMultiple
            disabled={disabled}
            value={values}
            onChange={(updatedValues) => onUpdate({ ...row, stringArrayValue: updatedValues || [] })}
          />
        </FormControl>
      </Stack>
    </FormControl>
  );
}

function AgeAtTreatmentStart(props: LineItemProps) {
  const { row, disabled, onUpdate } = props;
  const { t } = useTranslation(["outcomes"]);

  // In theory these should always be supplied, but just to make the type system happy and ward of edge cases...
  const min = (row.integerArrayValue || [])[0] || 0;
  const max = (row.integerArrayValue || [])[1] || 100;

  const onMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    onUpdate({ ...row, integerArrayValue: [newValue, max] });
  };

  const onMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number.parseInt(event.target.value);
    onUpdate({ ...row, integerArrayValue: [min, newValue] });
  };

  return (
    <FormControl>
      <Stack alignItems={"center"} direction="row" spacing={0.5}>
        <span>{t("outcomes:create.inclusionCriteria.ageAtTreatmentStart.titleStart")}</span>
        <FormControl>
          <Input
            sx={{ width: "2.5em" }}
            onChange={onMinChange}
            type="number"
            inputProps={{ min: 0, max: 100 }}
            value={min}
            disabled={disabled}
          />
        </FormControl>
        <span>{t("outcomes:create.inclusionCriteria.ageAtTreatmentStart.titleMiddle")}</span>
        <FormControl>
          <Input
            sx={{ width: "2.5em" }}
            onChange={onMaxChange}
            type="number"
            inputProps={{ min: 0, max: 100 }}
            value={max}
            disabled={disabled}
          />
        </FormControl>
        <span>{t("outcomes:create.inclusionCriteria.ageAtTreatmentStart.titleEnd")}</span>
      </Stack>
    </FormControl>
  );
}

const defaultValues: Record<MetricInclusionCriterionType, MetricInclusionCriterionParams> = {
  [MetricInclusionCriterionType.BASELINE_SCORE]: {
    criterionType: MetricInclusionCriterionType.BASELINE_SCORE,
    operator: Operator.LESS_THAN,
    numberValue: 5,
  },
  [MetricInclusionCriterionType.BASELINE_SEVERITY]: {
    criterionType: MetricInclusionCriterionType.BASELINE_SEVERITY,
    severityValues: [SeverityCategory.SEVERE],
  },
  [MetricInclusionCriterionType.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE]: {
    criterionType: MetricInclusionCriterionType.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE,
    operator: Operator.LESS_THAN,
    numberValue: 30,
  },
  [MetricInclusionCriterionType.DAYS_SINCE_TREATMENT_START]: {
    criterionType: MetricInclusionCriterionType.DAYS_SINCE_TREATMENT_START,
    operator: Operator.GREATER_THAN,
    numberValue: 30,
  },
  [MetricInclusionCriterionType.AGE_AT_TREATMENT_START]: {
    criterionType: MetricInclusionCriterionType.AGE_AT_TREATMENT_START,
    integerArrayValue: [0, 100],
  },
  [MetricInclusionCriterionType.GENDER]: {
    criterionType: MetricInclusionCriterionType.GENDER,
    excludeResults: false,
    stringArrayValue: ["M", "F"],
  },
  [MetricInclusionCriterionType.RACE]: {
    criterionType: MetricInclusionCriterionType.RACE,
    excludeResults: false,
    stringArrayValue: ["2106-3"],
  },
  [MetricInclusionCriterionType.COCM_TARGET_SCALE]: {
    criterionType: MetricInclusionCriterionType.COCM_TARGET_SCALE,
  },
};

export default function InclusionCriteriaForm(props: InclusionCriteriaFormProps) {
  const theme = useTheme();
  const { t } = useTranslation(["outcomes"]);
  const isCocm = useWithFeatureEnabled("enableCollaborativeCare");
  // Technically in the back end this is just a collection, but to make the UI simpler we're going to offer a set of checkboxes
  // one per type of success criteria.
  // This component will react and spit up a full set of objects, but the checkbox component itself is purely internal
  const [currentData, setCurrentData] =
    React.useState<Record<MetricInclusionCriterionType, MetricInclusionCriterionParams>>(defaultValues);

  const activeNodes: Partial<Record<MetricInclusionCriterionType, boolean>> = {};

  props.inclusionCriteriaField.value?.forEach((value) => {
    currentData[value.criterionType] = value;
    activeNodes[value.criterionType] = true;
  });

  const handleCheckboxChange = (criterionType: MetricInclusionCriterionType) => {
    return (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const set = props.inclusionCriteriaField.value || [];
      const without = set.filter((s) => s.criterionType !== criterionType);
      const item = currentData[criterionType];

      if (checked) {
        props.inclusionCriteriaField.onChange([...without, item]);
      } else {
        props.inclusionCriteriaField.onChange(without);
      }
    };
  };

  const handleDetailsChange = (criterionType: MetricInclusionCriterionType) => {
    return (newValue: MetricInclusionCriterionParams) => {
      const set = props.inclusionCriteriaField.value || [];
      const without = set.filter((s) => s.criterionType !== criterionType);
      setCurrentData({ ...currentData, [criterionType]: newValue });
      props.inclusionCriteriaField.onChange([...without, newValue]);
    };
  };

  let severity = null;

  if (props.supportedSeverities.length > 0) {
    severity = (
      <FormControlLabel
        control={
          <Checkbox
            checked={activeNodes.BASELINE_SEVERITY || false}
            onChange={handleCheckboxChange(MetricInclusionCriterionType.BASELINE_SEVERITY)}
          />
        }
        label={
          <BaselineSeverity
            disabled={!activeNodes.BASELINE_SEVERITY}
            row={currentData.BASELINE_SEVERITY}
            supportedSeverities={props.supportedSeverities}
            onUpdate={handleDetailsChange(MetricInclusionCriterionType.BASELINE_SEVERITY)}
          />
        }
      />
    );
  }

  let cocmTarget = null;

  if (isCocm) {
    cocmTarget = (
      <FormControlLabel
        control={
          <Checkbox
            checked={activeNodes.COCM_TARGET_SCALE || false}
            onChange={handleCheckboxChange(MetricInclusionCriterionType.COCM_TARGET_SCALE)}
          />
        }
        label={<Typography>{t("outcomes:create.inclusionCriteria.cocmTargetScale.title")}</Typography>}
      />
    );
  }

  return (
    <FormControl>
      <FormLabel>
        <Typography variant="h2" sx={{ paddingBottom: "0.5em", color: theme.palette.common.black }}>
          {t("outcomes:create.inclusionCriteria.title")}
        </Typography>
      </FormLabel>
      <SectionHelpText text={t("outcomes:create.inclusionCriteria.helpText")} />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={activeNodes.DAYS_SINCE_TREATMENT_START || false}
              onChange={handleCheckboxChange(MetricInclusionCriterionType.DAYS_SINCE_TREATMENT_START)}
            />
          }
          label={
            <DaysSinceTreatmentStart
              disabled={!activeNodes.DAYS_SINCE_TREATMENT_START}
              row={currentData.DAYS_SINCE_TREATMENT_START}
              onUpdate={handleDetailsChange(MetricInclusionCriterionType.DAYS_SINCE_TREATMENT_START)}
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeNodes.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE || false}
              onChange={handleCheckboxChange(
                MetricInclusionCriterionType.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE
              )}
            />
          }
          label={
            <DaysBetweenTreatmentStartAndBaseline
              disabled={!activeNodes.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE}
              row={currentData.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE}
              onUpdate={handleDetailsChange(
                MetricInclusionCriterionType.DAYS_BETWEEN_TREATMENT_START_AND_BASELINE
              )}
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeNodes.GENDER || false}
              onChange={handleCheckboxChange(MetricInclusionCriterionType.GENDER)}
            />
          }
          label={
            <Gender
              disabled={!activeNodes.GENDER}
              row={currentData.GENDER}
              onUpdate={handleDetailsChange(MetricInclusionCriterionType.GENDER)}
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={activeNodes.AGE_AT_TREATMENT_START || false}
              onChange={handleCheckboxChange(MetricInclusionCriterionType.AGE_AT_TREATMENT_START)}
            />
          }
          label={
            <AgeAtTreatmentStart
              disabled={!activeNodes.AGE_AT_TREATMENT_START}
              row={currentData.AGE_AT_TREATMENT_START}
              onUpdate={handleDetailsChange(MetricInclusionCriterionType.AGE_AT_TREATMENT_START)}
            />
          }
        />
        {severity}
        {cocmTarget}
        <FormControlLabel
          control={
            <Checkbox
              checked={activeNodes.RACE || false}
              onChange={handleCheckboxChange(MetricInclusionCriterionType.RACE)}
            />
          }
          label={
            <Race
              disabled={!activeNodes.RACE}
              row={currentData.RACE}
              onUpdate={handleDetailsChange(MetricInclusionCriterionType.RACE)}
            />
          }
        />
      </FormGroup>
    </FormControl>
  );
}
