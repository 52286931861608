import React from "react";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { DetailsWrapper } from "../DetailsWrapper";
import ScaleGroupHistoryPane from "./ScaleGroupHistoryPane";
import { CareEpisodeId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import { useTranslation } from "react-i18next";

type ScaleGroupHistoryProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

export const ScaleGroupHistoryRoute = (props: ScaleGroupHistoryProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{
    scaleGroupId?: string;
  }>();

  const scaleGroupId = Id.fromNullableString<"ScaleGroup">(params.scaleGroupId);

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [t("report:breadcrumbs.measureHistory")],
  ];

  return scaleGroupId.caseOf({
    Err: (error) => <ErrorMessage message={error.message} />,
    Ok: (scaleGroupId) => (
      <DetailsWrapper breadcrumbs={breadcrumbs}>
        <ScaleGroupHistoryPane
          careEpisodeId={props.careEpisodeId}
          scaleGroupId={scaleGroupId}
          feedbackReportContext={props.feedbackReportContext}
        />
      </DetailsWrapper>
    ),
  });
};
