import { useTheme } from "@mui/material";
import { DatePickerProps as MuiDatePickerProps, DatePicker as MuiDatePicker } from "@mui/x-date-pickers";

// The 'defaultValue' used here is about a non-controlled item, while our defaultValue refers to
// the value to be compared to, in order to highlight if the filter has changed.
type DatePickerProps<T extends Date> = Omit<MuiDatePickerProps<T>, "defaultValue"> & {
  defaultValue?: T;
};
export default function DatePicker<T extends Date>(props: DatePickerProps<T>) {
  const { defaultValue, sx, ...passthroughProps } = props;
  const highlightColor = useTheme().palette.filterHighlight;
  const highlight =
    typeof props.defaultValue !== "undefined" && defaultValue?.toString() !== props.value?.toString();

  const mergedSx = highlight
    ? [
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: "4px",
            borderColor: highlightColor,
          },
        },
      ]
    : sx;

  return <MuiDatePicker sx={mergedSx} {...passthroughProps} />;
}
