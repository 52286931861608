import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ContainedIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
  },

  color: theme.palette.common.white,
  "& svg": {
    fill: theme.palette.common.white,
  },
}));

export default ContainedIconButton;
