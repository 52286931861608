import { format } from "date-fns";
// Various default formatters to help ensure a consistent
// language —  TODO: Take locale into consideration

// -- DATE --------------------------------------------------------------------

function formatDate(date: Date): string {
  return format(date, "MMM dd, yyyy");
}

function formatDateIso(date: Date): string {
  return format(date, "yyyy-MM-dd");
}

function formatShortDate(date: Date): string {
  return format(date, "MMM dd");
}

function formatNumber(value: number, decimalPlaces = 1) {
  if (value === Math.round(value)) {
    return value.toString();
  }
  return value.toFixed(decimalPlaces);
}

function formatPercent(value: number, decimalPlaces = 1) {
  return `${formatNumber(value * 100, decimalPlaces)}%`;
}

export { formatDate, formatShortDate, formatNumber, formatDateIso, formatPercent };
