import {
  PatientFilter,
  ProviderFilter,
  TriggeredWorkflowStatus,
  WorkflowFilter,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import PatientSelect from "Shared/Filters/PatientSelect";
import EnumSelect from "Shared/EnumSelect";
import WorkflowSelect from "./WorkflowSelect";
import { useTranslation } from "react-i18next";
import { triggeredWorkflowStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  useQueryStringEnumParameter,
  useQueryStringPatientFilterParameter,
  useQueryStringProviderFilterParameter,
} from "Shared/QueryStringParameter";
import ProviderSelect from "Shared/Filters/ProviderSelect";

export type InboxFilters = {
  patient: PatientFilter;
  setPatient: (patient: PatientFilter) => void;
  provider: ProviderFilter;
  setProvider: (provider: ProviderFilter) => void;
  workflowState: TriggeredWorkflowStatus;
  setWorkflowState: (state: TriggeredWorkflowStatus) => void;
  workflow: WorkflowFilter;
  setWorkflow: (workflow: WorkflowFilter) => void;
};

const DEFAULT_FILTERS = {
  patient: { allPatients: true },
  provider: { me: true },
  workflowState: TriggeredWorkflowStatus.ACTIVE,
  workflow: { allWorkflows: true },
};

export function useInboxFilters(): InboxFilters {
  const [patient, setPatient] = useQueryStringPatientFilterParameter("patient", DEFAULT_FILTERS.patient);
  const [provider, setProvider] = useQueryStringProviderFilterParameter("provider", DEFAULT_FILTERS.provider);
  const [workflowState, setWorkflowState] = useQueryStringEnumParameter<TriggeredWorkflowStatus>(
    "workflowStatus",
    TriggeredWorkflowStatus,
    DEFAULT_FILTERS.workflowState
  );
  const [workflow, setWorkflow] = React.useState<WorkflowFilter>(DEFAULT_FILTERS.workflow);

  return {
    patient: patient || DEFAULT_FILTERS.patient,
    setPatient,
    provider: provider || DEFAULT_FILTERS.provider,
    setProvider,
    workflowState,
    setWorkflowState,
    workflow,
    setWorkflow,
  };
}

const SelectContainer = styled(Box)(({ theme }) => ({
  width: theme.spacing(15),
}));

type InboxFiltersProps = {
  filters: InboxFilters;
};

export function InboxFilters(props: InboxFiltersProps) {
  const { t } = useTranslation(["decisionSupport"]);
  return (
    <Stack direction="row" spacing={1}>
      <SelectContainer>
        <PatientSelect value={props.filters.patient} setValue={props.filters.setPatient} />
      </SelectContainer>
      <ProviderSelect value={props.filters.provider} setValue={props.filters.setProvider} />
      <SelectContainer>
        <EnumSelect
          optionsEnum={TriggeredWorkflowStatus}
          value={props.filters.workflowState}
          onChange={props.filters.setWorkflowState}
          title={t("decisionSupport:inbox.filters.workflowStatus")}
          enumTrans={triggeredWorkflowStatusT}
        />
      </SelectContainer>
      <SelectContainer>
        <WorkflowSelect value={props.filters.workflow} setValue={props.filters.setWorkflow} />
      </SelectContainer>
    </Stack>
  );
}
