import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { Operator, Trend } from "GeneratedGraphQL/SchemaAndOperations";
import { enumFromStringValue } from "Shared/Enum";
import React from "react";
import { useTranslation } from "react-i18next";

/*
 * Make a single select box that contains the operator elements
 */
function OperatorSelect(props: {
  operator: Operator;
  onChange: (event: SelectChangeEvent<Operator>) => void;
  disabled: boolean;
}) {
  const { operator, onChange, disabled } = props;
  const { t } = useTranslation(["outcomes"]);
  return (
    <FormControl>
      <Select sx={{ height: "2em" }} value={operator} onChange={onChange} disabled={disabled}>
        <MenuItem value={Operator.EQUAL}>{t("outcomes:operator.equal")}</MenuItem>
        <MenuItem value={Operator.LESS_THAN}>{t("outcomes:operator.lessThan")}</MenuItem>
        <MenuItem value={Operator.GREATER_THAN}>{t("outcomes:operator.greaterThan")}</MenuItem>
      </Select>
    </FormControl>
  );
}

/*
 * Make a single select box that contains the operator elements
 */
function ExclusionSelect(props: {
  excludeResults: boolean;
  onChange: (event: SelectChangeEvent<boolean>) => void;
  disabled: boolean;
}) {
  const { excludeResults, onChange, disabled } = props;
  const { t } = useTranslation(["outcomes"]);
  return (
    <FormControl>
      <Select sx={{ height: "2em" }} value={excludeResults} onChange={onChange} disabled={disabled}>
        <MenuItem value={"true"}>{t("outcomes:excludeResults.exclude")}</MenuItem>
        <MenuItem value={"false"}>{t("outcomes:excludeResults.include")}</MenuItem>
      </Select>
    </FormControl>
  );
}

/**
 * Make a single select box that contains the operator elements
 */
function TrendSelect(props: { trend: Trend; onChange: (trend: Trend) => void; disabled: boolean }) {
  const { t } = useTranslation(["outcomes"]);
  const { trend, onChange, disabled } = props;

  const handleOnChange = (event: SelectChangeEvent<Trend>) => {
    const parsed = event.target.value ? enumFromStringValue<Trend>(Trend, event.target.value) : undefined;

    // We don't do an update if we can't parse it, but given the constraints it should never occur
    if (parsed) {
      onChange(parsed);
    }
  };

  // Note that we don't want to support all the trends, only the valid ones, so this isn't a full enum
  return (
    <FormControl>
      <Select sx={{ height: "2em" }} value={trend} onChange={handleOnChange} disabled={disabled}>
        <MenuItem value={Trend.IMPROVING}>{t("outcomes:trends.improving")}</MenuItem>
        <MenuItem value={Trend.STABLE}>{t("outcomes:trends.stable")}</MenuItem>
        <MenuItem value={Trend.DECLINING}>{t("outcomes:trends.declining")}</MenuItem>
      </Select>
    </FormControl>
  );
}

export { OperatorSelect, TrendSelect, ExclusionSelect };
