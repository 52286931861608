import React, { ReactElement } from "react";
import { TaskDetails } from "./TaskCard";
import { useTranslation } from "react-i18next";
import {
  TimerBeginButton,
  activatedTaskTimerStates,
  useCheckForActiveTaskTimer,
} from "CollaborativeCare/TimeEntry/TimerBeginButton";
import { TimeEntryLogButton } from "CollaborativeCare/TimeEntry/TimeEntryLogForm";
import { Box, Button, Stack } from "@mui/material";
import { TimerDiscardButton } from "CollaborativeCare/TimeEntry/TimerDiscardButton";
import { TimerStopButton } from "CollaborativeCare/TimeEntry/TimerStopButton";
import { TaskStatusBadge } from "./TaskCompleteForm";
import { useIsMobile } from "Shared/Responsive";
import { EditTaskTrigger } from "CollaborativeCare/CreateOrEditTaskForm";
import { KeyboardArrowLeft } from "@mui/icons-material";
import { SendTaskToTopButton } from "../SendToTopButton";

export function TaskActions(props: {
  task: TaskDetails;
  expand: React.Dispatch<React.SetStateAction<boolean>>;
  showBackButton?: boolean;
  onClickBackButton?: () => void;
}): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const timerStatus = useCheckForActiveTaskTimer(props.task.id);
  const spacing = useIsMobile() ? 0.2 : 1;
  const backButton = props.showBackButton ? (
    <Button sx={{ marginLeft: "1em" }} onClick={props.onClickBackButton}>
      <KeyboardArrowLeft /> {t("common:actions.back")}
    </Button>
  ) : null;

  if (
    timerStatus === activatedTaskTimerStates["NO_TIMER_ACTIVE"] ||
    timerStatus === activatedTaskTimerStates["ANOTHER_TASK_TIMER_ACTIVE"]
  ) {
    const isDisabled = timerStatus === activatedTaskTimerStates["ANOTHER_TASK_TIMER_ACTIVE"];
    // TODO: we want to slow down and have the complete button (no timer going)

    return (
      <Stack direction="row" spacing={spacing} flex={1}>
        {backButton}
        <Box sx={{ flexGrow: 1 }} />
        <SendTaskToTopButton task={props.task} variant="outlined" color="secondary" />
        <EditTaskTrigger variant="outlined" color="secondary" task={props.task} />
        <TimeEntryLogButton
          task={props.task}
          patientId={props.task.patient?.id}
          buttonLabel={t("collaborativeCare:tasks.actions.logTime")}
          isDisabled={isDisabled}
        />
        <TaskStatusBadge
          disabled={isDisabled}
          task={props.task}
          patient={props.task.patient}
          currentStatus={props.task.status}
          onSuccess={() => props.expand(false)}
        />
        <TimerBeginButton
          task={props.task}
          patientId={props.task.patient?.id}
          buttonLabel={t("collaborativeCare:tasks.actions.begin")}
          buttonCompleteLabel={t("collaborativeCare:tasks.actions.alreadyComplete")}
          variant="contained"
          color="secondary"
        />
      </Stack>
    );
  } else if (timerStatus === activatedTaskTimerStates["CURRENT_TASK_TIMER_ACTIVE"]) {
    return (
      <Stack direction="row" spacing={spacing} flex={1}>
        {backButton}
        <Box sx={{ flexGrow: 1 }} />
        <SendTaskToTopButton task={props.task} variant="outlined" color="secondary" />
        <EditTaskTrigger variant="outlined" color="secondary" task={props.task} />
        <TimerDiscardButton
          buttonLabel={t("collaborativeCare:tasks.actions.discard")}
          variant="contained"
          color="error"
        />
        <TimerStopButton
          buttonLabel={t("collaborativeCare:tasks.actions.save")}
          completeTask={false}
          variant="outlined"
          color="secondary"
        />
        <TimerStopButton
          buttonLabel={t("collaborativeCare:tasks.actions.complete")}
          completeTask={true}
          variant="outlined"
          color="secondary"
        />
        <TimerBeginButton
          task={props.task}
          patientId={props.task.patient?.id}
          buttonLabel={t("collaborativeCare:tasks.actions.begin")}
          buttonCompleteLabel={t("collaborativeCare:tasks.actions.alreadyComplete")}
          variant="contained"
          color="secondary"
        />
      </Stack>
    );
  }
  // This is an error case, we can't handle any sort of time tracking so we'll just let folks log.
  return (
    <>
      <TimeEntryLogButton task={props.task} buttonLabel={t("collaborativeCare:tasks.actions.logTime")} />
    </>
  );
}
