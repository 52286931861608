import SumType from "sums-up";
import * as AppContext from "./AppContext";
import {
  AuthenticatedProviderUser,
  AuthenticatedProviderUserContext,
  RawProviderAuthenticationData,
  parse as parseUser,
} from "./AuthenticatedProviderUser";
import { RemoteData, Maybe, Nothing, Just, NotAsked, Success, Failure, Loading } from "seidr";
import * as Id from "Lib/Id";
import { identity, resultToMaybe } from "../Lib/Utils";
import { InstituteId, ProviderId, UserId } from "Lib/Ids";
import { useContext, useEffect, useRef, useState } from "react";
import { PublicFeatureFlags } from "./AppContext";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { EMBER_AUTH_LOCAL_STORAGE_KEY, storageLogout } from "Shared/Storage";

export type AppSessionId = Id.Id<"AppSession">;

class AppSession extends SumType<{
  Uninitialized: [AppSessionId];
  Unauthenticated: [AppContext.AppContext, AppSessionId];
  Authenticated: [AppContext.AppContext, AuthenticatedProviderUser, AppSessionId];
}> {}

// Opaque data constructors
const Uninitialized = (id: AppSessionId) => new AppSession("Uninitialized", id);
const Unauthenticated = (appContext: AppContext.AppContext, id: AppSessionId) =>
  new AppSession("Unauthenticated", appContext, id);
const Authenticated = (
  appContext: AppContext.AppContext,
  user: AuthenticatedProviderUser,
  id: AppSessionId
) => new AppSession("Authenticated", appContext, user, id);

// -- AppSession Management ------------------------------------------------------

type LogoutHandler = (s: AppSession) => void;

let currentAppSession: AppSession = Uninitialized(Id.generate<"AppSession">());
const logOutHandlers: Array<LogoutHandler> = [];

function getAppSession(): AppSession {
  return currentAppSession;
}

function getCurrentPublicFeatureFlags(): Maybe<PublicFeatureFlags> {
  return currentAppSession.caseOf({
    Authenticated: (appCtx) =>
      appCtx.institute.caseOf({
        ProviderInstituteContext: (ictx) => Just(ictx.featureFlags),
        MirahInternalInstituteContext: (mctx) => Just(mctx.featureFlags),
        GroupLeaderInstituteContext: (ictx) => Just(ictx.featureFlags),
      }),
    Unauthenticated: (appCtx) =>
      appCtx.institute.caseOf({
        ProviderInstituteContext: (ictx) => Just(ictx.featureFlags),
        MirahInternalInstituteContext: (mctx) => Just(mctx.featureFlags),
        GroupLeaderInstituteContext: (ictx) => Just(ictx.featureFlags),
      }),
    Uninitialized: () => Nothing(),
  });
}

function getCurrentUser(): Maybe<AuthenticatedProviderUser> {
  return currentAppSession.caseOf({
    Authenticated: (_ctx, user, _id) => Just(user),
    _: () => Nothing(),
  });
}

function getCurrentUserId(): Maybe<UserId> {
  return currentAppSession.caseOf({
    Authenticated: (_ctx, user, _id) => resultToMaybe(Id.fromString(user.id)),
    _: () => Nothing(),
  });
}

export function useCurrentProviderId(): ProviderId | null {
  const currentProvider = useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return null;
  }

  const providerId = currentProvider.providerId.getOrElse(null);

  return providerId;
}

// Describes just the bits of information we need, unauthenticated, when
// dealing with institutes around login situtations.
type CurrentInstituteLoginDetails = {
  name?: string;
  id?: InstituteId;
  forceSsoRedirect?: boolean;
};

// This hook is meant to go out and gather some disparate data we need to make
// good decisions about what to do while logging in a user.
// We need information about the current institute based on our bootstrap data,
// as well as data that we get from another arbitrary endpoint.
export function useCurrentInstituteLoginDetails(): CurrentInstituteLoginDetails {
  // This will hold the actual data that we've got. We don't bother to set
  // it correctly until we're done doing the second fetch of data.

  // This first section is all about getting the data we need from our bootstrap data.
  let instituteName: string | undefined;
  let instituteId: InstituteId | undefined;
  currentAppSession.caseOf({
    Unauthenticated: (session) => {
      return session.institute.caseOf({
        ProviderInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
        MirahInternalInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
        GroupLeaderInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
      });
    },
    // You may already have the details of the app if you're currently logged in, but we want
    // SSO links to work even if you're already logged in.
    Authenticated: (context) => {
      return context.institute.caseOf({
        ProviderInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
        MirahInternalInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
        GroupLeaderInstituteContext: (institute) => {
          instituteName = institute.name;
          instituteId = institute.id;
        },
      });
    },
    _: () => {
      /* noop */
    },
  });

  const { remoteData, fetchFn } = useFetch("/api/current-institutes");
  let currentInstituteLoginDetails: CurrentInstituteLoginDetails = {};
  remoteData.caseOf({
    NotAsked: async () => {
      // This can be triggered anywhere.
      fetchFn();
    },
    _: async () => {
      /* noop */
    },
    Success: async (result: {
      json: { data: [{ attributes: { "sso-settings": { forceSsoRedirect: boolean } } }] };
    }) => {
      currentInstituteLoginDetails = {
        name: instituteName,
        id: instituteId,
        forceSsoRedirect: result.json.data[0].attributes["sso-settings"].forceSsoRedirect,
      };
    },
  });

  return currentInstituteLoginDetails;
}

type UseFetchType = {
  remoteData: RemoteData<unknown, unknown>;
  fetchFn: () => Promise<void>;
};

// This is our useFetch hook. It's still a WIP but works well for our current cases.
// Basically, call this at the top level of a component. Invoke the fetchFn at some point
// which will populate the result with whatever the current state of RemoteData is. Options
// for the fetch, like specifying POST, etc, go in the options argument. The dependencies
// argument specifies under what circumstances the generated fetch should update: set the value
// to the array of arguments to any of the options data or url and you should be good. These
// need to be primitives or else the dependencies will endlessly update.
export type UseFetchResultType = {
  result: Response;
  json: unknown; // We can probably stop doing this via generics on useFetch itself.
};
export function useFetch(
  url: string,
  options?: RequestInit,
  dependencies: Array<string | undefined> = []
): UseFetchType {
  const [result, setResult] = useState({
    remoteData: NotAsked(),
    fetchFn: async () => {
      /* noop */
    },
  });

  useEffectSimpleCompare(() => {
    const wrapperFn = async () => {
      try {
        setResult({ remoteData: Loading<Error, UseFetchResultType>(), fetchFn: result.fetchFn });
        const fetchResult = await fetch(url, options);
        if (fetchResult.status == 200 || fetchResult.status == 201) {
          const resultJson: JSON = await fetchResult.json();
          const fetchRemoteData = Success<Error, UseFetchResultType>({
            result: fetchResult,
            json: resultJson,
          });
          setResult({ remoteData: fetchRemoteData, fetchFn: result.fetchFn });
        } else {
          setResult({
            remoteData: Failure<Error, UseFetchResultType>(new Error("Non-200 response received")),
            fetchFn: result.fetchFn,
          });
        }
      } catch {
        setResult({
          remoteData: Failure<Error, UseFetchResultType>(new Error("Unknown error")),
          fetchFn: result.fetchFn,
        });
      }
    };
    setResult({ remoteData: result.remoteData, fetchFn: wrapperFn });
  }, dependencies);

  return result;
}

// https://stackoverflow.com/questions/73732474/handling-oauth-with-react-18-useeffect-hook-running-twice
export function useFetchOnce(
  url: string,
  options?: RequestInit,
  dependencies: Array<string | undefined> = []
): RemoteData<unknown, unknown> {
  const [result, setResult] = useState(NotAsked());
  const allowFetch = useRef(true);

  useEffect(() => {
    // Do nothing if already fetched once
    const fetchFn = async () => {
      if (!allowFetch.current) {
        return;
      }

      try {
        allowFetch.current = false;
        setResult(Loading<Error, UseFetchResultType>());
        const fetchResult = await fetch(url, options);
        if (fetchResult.status == 200 || fetchResult.status == 201) {
          const resultJson: JSON = await fetchResult.json();
          const fetchRemoteData = Success<Error, UseFetchResultType>({
            result: fetchResult,
            json: resultJson,
          });
          setResult(fetchRemoteData);
        } else {
          setResult(Failure<Error, UseFetchResultType>(new Error("Non-200 response received")));
        }
      } catch {
        setResult(Failure<Error, UseFetchResultType>(new Error("Unknown error")));
      }
    };

    fetchFn();

    return () => {
      allowFetch.current = false;
    };
  }, dependencies);

  return result;
}

function _setAppSession(newAppSession: AppSession): AppSession {
  // Update global session state
  currentAppSession = newAppSession;
  return newAppSession;
}

function onLogout(handler: LogoutHandler): void {
  logOutHandlers.push(handler);
}

// -- LocalStorage ------------------------------------------------------------

function persistAppSessionData(sessionData: RawProviderAuthenticationData) {
  localStorage.setItem(EMBER_AUTH_LOCAL_STORAGE_KEY, JSON.stringify({ authenticated: sessionData }));
}

// TODO: Think about using e.g. https://github.com/woutervh-/typescript-is to verify unknown contents
function readAppSessionData(): Maybe<RawProviderAuthenticationData> {
  const localStorageString = localStorage.getItem(EMBER_AUTH_LOCAL_STORAGE_KEY);

  try {
    return Maybe.fromNullable(localStorageString).flatMap((jsonString) => {
      const raw = JSON.parse(jsonString);

      if (Object.keys(raw.authenticated).length == 0) {
        // This is just catching a bug from the ember side, where we just set
        // auth as being {} on logout and then tricking ourselves later as if we are authed.
        return Nothing();
      }

      return Maybe.fromNullable(raw.authenticated as RawProviderAuthenticationData);
    });
  } catch {
    return Nothing();
  }
}

// -- Transitions -------------------------------------------------------------

function init(): AppSession {
  return _setAppSession(Uninitialized(Id.generate()));
}

function authenticate(session: AppSession, user: AuthenticatedProviderUser): AppSession {
  return session.caseOf({
    Unauthenticated: (appContext, id) => {
      // persistAppSessionData(user);
      const session = Authenticated(appContext, user, id);

      return _setAppSession(session);
    },
    _: () => session,
  });
}

type LogoutBehavior = "retry" | "endSession";

function logout(behavior: LogoutBehavior = "retry"): AppSession {
  const s = getAppSession().caseOf({
    Uninitialized: Uninitialized,
    Unauthenticated: (appContext, sessionId) => Unauthenticated(appContext, sessionId),
    Authenticated: (appContext, _u, sessionId) => Unauthenticated(appContext, sessionId),
  });

  logOutHandlers.forEach((handler) => handler(s));
  storageLogout();
  const newSession = _setAppSession(s);

  // There are generally two scenarios when you get logged out:
  // - The session is complete - you pressed logout, or you were timed out There is no
  //   immediate need to start another session
  // - You were logged out in the middle of something that you'd like to continue.

  if (behavior === "endSession") {
    // Set the window directly to make sure all data is wiped
    window.location.href = "/app/logged-out";
  } else {
    // Do nothing and it'll get retried
  }

  return newSession;
}

function bootstrap(onUpdate: (r: RemoteData<Error, AppSession>) => void) {
  AppContext.bootstrap((res) => {
    const user = readAppSessionData().map(parseUser);

    const id = getAppSessionId(getAppSession());

    const sessionRes = res.map((appContext) => {
      return user.caseOf({
        Nothing: () => Unauthenticated(appContext, id),
        Just: (u) => Authenticated(appContext, u, id),
      });
    });

    sessionRes.map((s) => _setAppSession(s));

    onUpdate(sessionRes);
  });
}

function getAuthorizationHeader(): string | undefined {
  return readAppSessionData().caseOf({
    Just: (headers) => {
      return `authentication_token="${headers.authentication_token}", user_type="${headers.user_type}", id="${headers.id}"`;
    },
    Nothing: () => {
      return undefined;
    },
  });
}

function sendKeepAlive(): void {
  // We assume that if we fail any keepalive check, we want to end the session.
  const authData = getAuthorizationHeader();
  if (!authData) {
    // logout ? this is an error case. once the provider ui app loads, you should be authenticated
    logout("endSession");
  }

  // use fetch instead of jquery
  fetch("/api/keepalive", {
    headers: { Authorization: `Token ${authData}` },
  }).then((response) => {
    if (response.status === 498 || response.status === 499) {
      logout("endSession");
    }
  });
}

function sendLogout(): void {
  const authData = getAuthorizationHeader();
  if (!authData) {
    return;
  }

  fetch("/api/sign_out", { method: "DELETE", headers: { Authorization: `Token ${authData}` } });
}

// -- Helpers -----------------------------------------------------------------

function getAppSessionId(s: AppSession): AppSessionId {
  return s.caseOf({
    Uninitialized: identity,
    Unauthenticated: (_a, id) => id,
    Authenticated: (_a, _u, id) => id,
  });
}

const Test = {
  readAppSessionData,
  persistAppSessionData,
  sendKeepAlive,
  Uninitialized,
  Unauthenticated,
  Authenticated,
  _setAppSession,
  useCurrentInstituteLoginDetails,
};

export default AppSession;
export {
  AppSession,
  onLogout,
  init,
  authenticate,
  bootstrap,
  getAppSession,
  getAppSessionId,
  getCurrentUser,
  getCurrentUserId,
  getCurrentPublicFeatureFlags,
  logout,
  sendKeepAlive,
  sendLogout,
  Test,
};
