import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  CaseConsult,
  CaseConsultStatus,
  useEndConsultReviewMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { refetchQueries } from "Lib/RefetchQueries";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useNavigate } from "react-router-dom";
import { PickTypename } from "type-utils";
import { ConsultMeetingId } from "Lib/Ids";

type CompleteAndDocumentedButtonProps = {
  consult: PickTypename<CaseConsult, "id" | "status">;
  meetingId: ConsultMeetingId;
};

export default function CompleteAndDocumentedButton(props: CompleteAndDocumentedButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const navigate = useNavigate();

  const [completeReview, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEndCaseConsultReview,
    useEndConsultReviewMutation({
      variables: {
        input: {
          caseConsultId: props.consult.id,
          status: CaseConsultStatus.COMPLETE,
        },
      },
      refetchQueries: refetchQueries("consultRequests"),
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        navigate("../..", { relative: "path" });
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";
  const disabled = props.consult.status === CaseConsultStatus.COMPLETE || remoteData.kind === "Loading";

  return (
    <ButtonWithSpinner
      variant="contained"
      color="secondary"
      showSpinner={showSpinner}
      disabled={disabled}
      onClick={() => completeReview()}
    >
      {t("collaborativeCare:caseConsult.caseConsultReview.actions.completeAndDocumented")}
    </ButtonWithSpinner>
  );
}
