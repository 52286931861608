import * as Id from "Lib/Id";
import { UserSummary } from "./User";
import {
  ParticipantRelationship,
  ParticipantSummaryFragmentFragment as ParticipantSummaryFragment,
  Patient,
  RelatedPersonRelationship,
  usePatientRelatedPeopleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { filter, any, humanize } from "Lib/Utils";
import { Maybe } from "seidr";
import { PickTypename } from "type-utils";
import { apolloQueryHookWrapper } from "Api/GraphQL";

export type ParticipantId = Id.Id<"Participant">;

type ParticipantBase = {
  participantId: ParticipantId;
  relationship: ParticipantRelationship;
};

export type ParticipantSummary = ParticipantBase & {
  name: string;
  firstName: string | null;
  user: UserSummary;
};

// DISPLAY

function displayNameWithRelationship(participant: ParticipantSummary): string {
  return `${participant.name} (${humanize(participant.relationship)})`;
}

// HELPERS

function nonPatientParticipants<P extends ParticipantBase>(participants: ReadonlyArray<P>): ReadonlyArray<P> {
  return filter((p) => {
    return (
      p.relationship !== ParticipantRelationship.PATIENT &&
      p.relationship !== ParticipantRelationship.PROVIDER
    );
  }, participants);
}

function hasNonPatientParticipants(participants: ReadonlyArray<ParticipantBase>): boolean {
  return any((p) => {
    return (
      p.relationship !== ParticipantRelationship.PATIENT &&
      p.relationship !== ParticipantRelationship.PROVIDER
    );
  }, participants);
}

function isPatient<P extends Pick<ParticipantSummary, "relationship">>(participant: P): boolean {
  return participant.relationship === ParticipantRelationship.PATIENT;
}

function clientParticipants<T extends ParticipantBase>(participants: ReadonlyArray<T>): ReadonlyArray<T> {
  return participants.filter((p) => p.relationship !== ParticipantRelationship.PROVIDER);
}

// -- Transforms --------------------------------------------------------------

function toParticipantSummary(raw: ParticipantSummaryFragment): ParticipantSummary {
  return {
    participantId: raw.participantId,
    relationship: raw.relationship,
    name: raw.user.name,
    firstName: raw.user.firstName,
    user: raw.user,
  };
}

// -- Helpers -----------------------------------------------------------------

function sortItemsByPatientParticipant<T>(
  items: Array<T>,
  getParticipant: (item: T) => Maybe<ParticipantSummary>,
  getSecondarySortString: (item: T) => string
): Array<T> {
  const sortFunction = (a: T, b: T): number => {
    const aP = getParticipant(a);
    const bP = getParticipant(b);
    const fallBackVal = (a: T, b: T) => {
      return getSecondarySortString(a).localeCompare(getSecondarySortString(b));
    };

    return aP.caseOf({
      Just: (aP) => {
        return bP.caseOf({
          Just: (bP) => {
            if (aP.user.id.toString() === bP.user.id.toString()) {
              fallBackVal(a, b);
            }
            if (aP.relationship === ParticipantRelationship.PATIENT) {
              return -1;
            }
            if (bP.relationship === ParticipantRelationship.PATIENT) {
              return 1;
            }
            return aP.user.id.toString().localeCompare(bP.user.id.toString());
          },
          Nothing: () => fallBackVal(a, b),
        });
      },
      Nothing: () => fallBackVal(a, b),
    });
  };
  return items.sort(sortFunction);
}

// -- Hooks -------------------------------------------------------------------

type ParticipantLookup = Record<ParticipantRelationship, Array<string>>;

const EMPTY_PARTICIPANT_LOOKUP: ParticipantLookup = {
  [ParticipantRelationship.CHILD]: [],
  [ParticipantRelationship.FATHER]: [],
  [ParticipantRelationship.GUARDIAN]: [],
  [ParticipantRelationship.MOTHER]: [],
  [ParticipantRelationship.OTHER]: [],
  [ParticipantRelationship.TEACHER]: [],
  [ParticipantRelationship.PATIENT]: [],
  [ParticipantRelationship.PROVIDER]: [],
};

function useParticipantLookup(patient: PickTypename<Patient, "id" | "name">): ParticipantLookup {
  const { remoteData } = apolloQueryHookWrapper(
    usePatientRelatedPeopleQuery({ variables: { patientId: patient.id } })
  );

  return remoteData.caseOf({
    Success: (response) => {
      if (!response.patient) {
        return EMPTY_PARTICIPANT_LOOKUP;
      }

      // Note that this has to be a distinct object from EMPTY_PARTICIPANT_LOOKUP since we're going to modify it in place.
      const lookup: ParticipantLookup = {
        [ParticipantRelationship.CHILD]: [],
        [ParticipantRelationship.FATHER]: [],
        [ParticipantRelationship.GUARDIAN]: [],
        [ParticipantRelationship.MOTHER]: [],
        [ParticipantRelationship.OTHER]: [],
        [ParticipantRelationship.TEACHER]: [],
        [ParticipantRelationship.PATIENT]: [patient.name],
        [ParticipantRelationship.PROVIDER]: [],
      };

      return response.patient.relatedPeople.reduce((lookup, person) => {
        lookup[person.relatedPersonRelationship].push(person.name);

        // Related people with MOTHER or FATHER will also get sent scales tagged GUARDIAN, so just duplicate them in
        // the lookup.
        if (
          person.relatedPersonRelationship === RelatedPersonRelationship.MOTHER ||
          person.relatedPersonRelationship === RelatedPersonRelationship.FATHER
        ) {
          lookup[ParticipantRelationship.GUARDIAN].push(person.name);
        }

        return lookup;
      }, lookup);
    },
    _: () => EMPTY_PARTICIPANT_LOOKUP,
  });
}

export {
  toParticipantSummary,
  hasNonPatientParticipants,
  nonPatientParticipants,
  clientParticipants,
  displayNameWithRelationship,
  ParticipantRelationship,
  isPatient,
  sortItemsByPatientParticipant,
  useParticipantLookup,
};
