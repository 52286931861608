import { Box, Divider, Grid, Paper, TextField, styled } from "@mui/material";
import { ProviderId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CareManagerPatientSearchFilter } from "./CareManagerPatientSearchFilter";
import { PrimaryCareProviderPatientSearchFilter } from "./PrimaryCareProviderPatientSearchFilter";
import { EnrollmentStatus, PatientSortParameter, SortDirection } from "GeneratedGraphQL/SchemaAndOperations";
import { enrollmentStatusT } from "GeneratedGraphQL/EnumTranslations";
import EnumSelect from "Shared/EnumSelect";
import { CreatePatientButton } from "./CreatePatientButton";
import { useIsMobile } from "Shared/Responsive";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyOptionalEnumParameter,
  useStickyOptionalIdParameter,
} from "Shared/StickyParameter";
import { useQueryStringOptionalEncryptedParameter } from "Shared/QueryStringParameter";
import { SortFilters } from "Shared/Sorting";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/PanelManagement/GlobalPanelIndicator";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";

const DEFAULTS = {
  search: null,
  pcpId: null,
  cmId: null,
  enrollmentStatus: null,
};

export type SearchFilters = {
  search: string | null;
  setSearch: (value: string | null) => void;
  cocmPrimaryCareProvider: ProviderId | null;
  setCocmPrimaryCareProvider: (value: ProviderId | null) => void;
  cocmCareManager: ProviderId | null;
  setCocmCareManager: (value: ProviderId | null) => void;
  enrollmentStatus: EnrollmentStatus | null;
  setEnrollmentStatus: (value: EnrollmentStatus | null) => void;
} & SortFilters<PatientSortParameter>;

export function usePatientSearchParameters(): SearchFilters {
  // Note that the search param is NOT included in the sticky filtering.
  const [search, setSearch] = useQueryStringOptionalEncryptedParameter("search", null, true);
  const [cocmPrimaryCareProvider, setCocmPrimaryCareProvider] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.PCP,
    STICKY_PARAMETER_FILTER_SETS.COCM_PATIENT_SEARCH,
    true
  );
  const [cocmCareManager, setCocmCareManager] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.CARE_MANAGER,
    STICKY_PARAMETER_FILTER_SETS.COCM_PATIENT_SEARCH,
    true
  );
  const [enrollmentStatus, setEnrollmentStatus] = useStickyOptionalEnumParameter<EnrollmentStatus>(
    STICKY_PARAMETER_NAMES.ENROLLMENT_STATUS,
    STICKY_PARAMETER_FILTER_SETS.COCM_PATIENT_SEARCH,
    EnrollmentStatus,
    DEFAULTS.enrollmentStatus,
    true
  );

  const [sortBy, setSortBy] = useStickyOptionalEnumParameter<PatientSortParameter>(
    STICKY_PARAMETER_NAMES.SORT_BY,
    STICKY_PARAMETER_FILTER_SETS.COCM_PATIENT_SEARCH,
    PatientSortParameter,
    null,
    true
  );
  const [sortDirection, setSortDirection] = useStickyOptionalEnumParameter<SortDirection>(
    STICKY_PARAMETER_NAMES.SORT_DIRECTION,
    STICKY_PARAMETER_FILTER_SETS.COCM_PATIENT_SEARCH,
    SortDirection,
    null,
    true
  );

  return {
    search,
    setSearch,
    cocmPrimaryCareProvider,
    setCocmPrimaryCareProvider,
    cocmCareManager,
    setCocmCareManager,
    enrollmentStatus,
    setEnrollmentStatus,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
  };
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

type PatientSearchFiltersProps = {
  filters: SearchFilters;
};
export default function PatientSearchFilters(props: PatientSearchFiltersProps): ReactElement {
  const { filters } = props;
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const rowSpacing = useIsMobile() ? 1 : 0;

  return (
    <StyledPaper>
      <Grid container rowSpacing={rowSpacing} spacing={1}>
        <StyledBox>
          <Grid container rowSpacing={rowSpacing} spacing={1}>
            <Grid item xs="auto">
              <TextField
                id="outlined-required"
                label={t("collaborativeCare:patientList.search.searchClientsLabel")}
                value={props.filters.search || ""}
                onChange={(event) => props.filters.setSearch(event.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryCareProviderPatientSearchFilter
                value={props.filters.cocmPrimaryCareProvider}
                setValue={props.filters.setCocmPrimaryCareProvider}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs="auto">
              <CareManagerPatientSearchFilter
                value={filters.cocmCareManager}
                setValue={filters.setCocmCareManager}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs="auto">
              <Box width="17em">
                <EnumSelect
                  optionsEnum={EnrollmentStatus}
                  title={t("collaborativeCare:patientList.search.enrollmentStatus")}
                  enumTrans={enrollmentStatusT}
                  onChange={props.filters.setEnrollmentStatus}
                  sortFn={(a, b) => {
                    //enrolled goes to the top
                    if (a == EnrollmentStatus.ENROLLED) {
                      return -1;
                    }
                    if (b == EnrollmentStatus.ENROLLED) {
                      return 1;
                    }
                    //discharged goes to the bottom
                    if (a == EnrollmentStatus.DISCHARGED) {
                      return 1;
                    }
                    if (b == EnrollmentStatus.DISCHARGED) {
                      return -1;
                    }
                    //the middle two are already in alphabetical order
                    return 0;
                  }}
                  value={props.filters.enrollmentStatus}
                  defaultValue={DEFAULTS.enrollmentStatus}
                />
              </Box>
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <GlobalPanelIndicatorBadge />
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <Divider orientation="vertical" flexItem />
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  filters.setCocmCareManager(DEFAULTS.cmId);
                  filters.setEnrollmentStatus(DEFAULTS.enrollmentStatus);
                  filters.setSearch(DEFAULTS.search);
                  filters.setCocmPrimaryCareProvider(DEFAULTS.pcpId);
                }}
              />
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item display="flex" flexGrow={1}>
          <Box sx={{ flexGrow: 1 }} />
          <CreatePatientButton />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
