import { ButtonBase, Chip, Stack, useTheme } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useQuickTextListQuery } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";

type QuickTextInputListProps = {
  inputRef: React.RefObject<HTMLInputElement | null>;
  fullText: string;
  setText: (fullText: string) => void;
};

export function QuickTextInputList(props: QuickTextInputListProps): ReactElement {
  const theme = useTheme();
  const { remoteData } = apolloQueryHookWrapper(useQuickTextListQuery());
  const quickTexts = remoteData.caseOf({
    Success: (response) => response.collaborativeCareQuickTexts?.nodes || [],
    _: () => [],
  });

  const onClick = (quickText: string) => {
    // This is the position we want to insert at - the current cursor if its available, or the end of the text if we
    // can't get a cursor reference for some reason. We have to check for null and undefined directly instead of just
    // `cursor || length` so that we support inserting at the 0 index.
    const cursor = props.inputRef.current?.selectionStart;
    const insertAt = cursor === null || cursor === undefined ? props.fullText.length : cursor;
    const firstSpace = insertAt === 0 ? "" : " ";
    const prefix = props.fullText.substring(0, insertAt);
    const suffix = props.fullText.substring(insertAt);
    // This will sometimes lead to extra spaces in the text, such as at the start of the string or if the cursor is
    // already next to a whitespace character, but fixing that is a lot of complexity for minimal gain.
    props.setText(`${prefix}${firstSpace}${quickText} ${suffix}`);
  };

  return (
    <Stack direction="row" spacing={0.5} flexWrap="wrap" useFlexGap={true}>
      {quickTexts.map((quickText, i) => (
        <ButtonBase
          onClick={() => onClick(quickText.text || quickText.title)}
          key={i}
          // This makes it so the chips get highlighted when you tab through them, which should make it easier to use
          // this keyboard-only. It might not be a bad idea to add something like this to all our buttons in the theme,
          sx={{ "&:focus > div": { outline: `2px solid ${theme.palette.info.main}` } }}
        >
          <Chip color="secondary" label={quickText.title} />
        </ButtonBase>
      ))}
    </Stack>
  );
}
