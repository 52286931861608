import React, { ReactElement } from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@mui/icons-material";
import { PageInfo } from "GeneratedGraphQL/SchemaAndOperations";

export type EmptyQueryPage = {
  first: number | null;
  after: string | null;
  last: number | null;
  before: string | null;
};

export type QueryPage =
  | {
      first: number;
      after: string | null;
      last: null;
      before: null;
    }
  | {
      last: number;
      before: string | null;
      first: null;
      after: null;
    };

export function initPage(pageSize: number): QueryPage {
  return {
    first: pageSize,
    after: null,
    last: null,
    before: null,
  };
}

export function isFirstPage(pageInfo: PageInfo): boolean {
  return !pageInfo.hasPreviousPage;
}

export type PageSelection = "next" | "prev" | "first" | "last";

type Props = {
  pagination: QueryPage;
  pageInfo: PageInfo | null;
  onChange: (p: QueryPage, selection?: PageSelection) => void;
};

export function Paginator(props: Props): ReactElement {
  const { pageInfo } = props;

  const pageSize = props.pagination.first === null ? props.pagination.last : props.pagination.first;
  const previous =
    pageInfo?.startCursor && pageInfo.hasPreviousPage
      ? () => {
          props.onChange(
            {
              before: pageInfo.startCursor,
              last: pageSize,
              first: null,
              after: null,
            },
            "prev"
          );
        }
      : undefined;

  const next =
    pageInfo?.hasNextPage && pageInfo.endCursor
      ? () => {
          props.onChange(
            {
              after: pageInfo.endCursor,
              last: null,
              first: pageSize,
              before: null,
            },
            "next"
          );
        }
      : undefined;

  const first = pageInfo?.hasPreviousPage
    ? () => {
        props.onChange({ before: null, last: null, first: pageSize, after: null }, "first");
      }
    : undefined;

  const last = pageInfo?.hasNextPage
    ? () => {
        props.onChange({ before: null, last: pageSize, first: null, after: null }, "last");
      }
    : undefined;

  return (
    <Box sx={{ p: 1, textAlign: "center", mt: 1 }} role="pagination">
      <IconButton color="secondary" onClick={first} disabled={first === undefined} size="large">
        <FirstPage />
      </IconButton>

      <IconButton
        color="secondary"
        onClick={previous}
        disabled={previous === undefined}
        data-testid="prev"
        size="large"
      >
        <ChevronLeft />
      </IconButton>

      <IconButton
        color="secondary"
        onClick={next}
        disabled={next === undefined}
        data-testid="next"
        size="large"
      >
        <ChevronRight />
      </IconButton>

      <IconButton color="secondary" onClick={last} disabled={last === undefined} size="large">
        <LastPage />
      </IconButton>
    </Box>
  );
}

// for consideration for some future style

// import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from "@mui/icons-material";

// function TablePaginationActions() {
//   const theme = useTheme();

//   return (
//     <Box sx={{ flexShrink: 0, ml: 2.5 }}>
//       <IconButton aria-label="first page">
//         {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
//       </IconButton>
//       <IconButton aria-label="previous page">
//         {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
//       </IconButton>
//       <IconButton aria-label="next page">
//         {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
//       </IconButton>
//       <IconButton aria-label="last page">
//         {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
//       </IconButton>
//     </Box>
//   );
// }

export default Paginator;
