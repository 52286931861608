import { Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { Link } from "MDS/Link";
import * as NEL from "Lib/NonEmptyList";
import * as Scale from "Shared/Scale/Scale";
import * as ScaleGroup from "Shared/Scale/ScaleGroup";
import { ScaleSectionSummary, graphableScaleSections } from "Shared/Scale/ScaleSection";
import { TrendIndicatorWithSimpleTooltip } from "Shared/Scale/TrendIndicator";
import ScaleScoresHeatmapWithChip from "Shared/Scale/ScaleScoresHeatmapWithChip";
import { ParticipantId } from "Shared/Participant";
import ParticipantAvatar from "FeedbackReport/ParticipantAvatar";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { CareEpisodeComputedValueId, ScaleGroupId, ScaleId } from "Lib/Ids";
import { styled } from "@mui/material/styles";
import { TableRowFocusHighlight } from "Shared/Focusable";
import { useTranslation } from "react-i18next";
import { BlankIcon } from "MDS/Icons";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { useIsMobile } from "Shared/Responsive";

const StyledScaleNameCell = styled("td")(() => ({
  width: 200,
}));

type IndividualScaleRowProps = {
  history: ScaleScorerHistory;
  isIntake: boolean;
  administrationDates: NEL.NonEmptyList<Date>;
  showParticipant: boolean;
  onScaleClickRoute: (scaleId: ScaleId) => Routing.FeedbackReportRoute;
  onScoreClickRoute: (
    scaleId: ScaleId,
    careEpisodeComputedValueId: CareEpisodeComputedValueId
  ) => Routing.FeedbackReportRoute;
};

export function IndividualScaleRow(props: IndividualScaleRowProps): ReactElement {
  const { history, showParticipant, administrationDates, onScaleClickRoute, onScoreClickRoute } = props;
  const { t } = useTranslation(["report"]);

  const participant = history.participant;

  const focusRoute = onScaleClickRoute(history.scale.id);

  const heatmap =
    history.scores.length === 0 ? (
      <Typography>{t("report:heatmapNoHistory")}</Typography>
    ) : (
      <ScaleScoresHeatmapWithChip
        height={24}
        maxWidth={200}
        scores={history.scores}
        administrationDates={administrationDates}
        chipWidth={150}
        onHeatmapClickRoute={onScaleClickRoute(history.scale.id)}
        onChipClickRoute={(careEpisodeComputedValueId: CareEpisodeComputedValueId) =>
          onScoreClickRoute(history.scale.id, careEpisodeComputedValueId)
        }
      />
    );

  const trendIndicator = history.latest
    .map((s) => s.trend)
    .map((trend) => <TrendIndicatorWithSimpleTooltip includePlaceholder trend={trend} />)
    .getOrElse(<BlankIcon />);

  let participantCell;

  if (showParticipant) {
    participantCell = (
      <td style={{ paddingLeft: "0.5rem" }}>
        <ParticipantAvatar participant={participant} />
      </td>
    );
  }

  const scale = history.scale;

  return (
    <>
      <TableRowFocusHighlight route={focusRoute}>
        {participantCell}
        <StyledScaleNameCell>
          <Link to={props.onScaleClickRoute(scale.id).toString()}>
            <div>{Scale.scaleFriendlyName(scale)}</div>
          </Link>
        </StyledScaleNameCell>
        <td style={{ whiteSpace: "nowrap" }}>{trendIndicator}</td>
        <td style={{ whiteSpace: "nowrap" }}>{heatmap}</td>
      </TableRowFocusHighlight>
    </>
  );
}

type ScaleGroupRowProps = {
  history: ScaleGroup.CareEpisodeScaleGroupSummary;
  // Really this is a ordered set
  administrationDates: NEL.NonEmptyList<Date>;
  isIntake: boolean;
  showParticipant: boolean;
  onScaleGroupClickRoute: (
    scaleGroupId: ScaleGroupId,
    participantId: ParticipantId
  ) => Routing.FeedbackReportRoute;
  onScaleGroupScoreClickRoute: (
    scaleGroupId: ScaleGroupId,
    participantId: ParticipantId,
    careEpisodeComputedValueId: CareEpisodeComputedValueId
  ) => Routing.FeedbackReportRoute;
};

function ScaleGroupRow(props: ScaleGroupRowProps): ReactElement {
  const { history, administrationDates, onScaleGroupClickRoute, onScaleGroupScoreClickRoute } = props;

  return (
    <>
      {ScaleGroup.displayableHistories(history).map((scaleAdministrationHistory, i) => {
        // TODO: Perhaps its better to filter out MultiParticipant scales
        // entirely? Do they even make sense for ScaleGroups?
        const participant = scaleAdministrationHistory.participant;

        return (
          <IndividualScaleRow
            key={i}
            history={scaleAdministrationHistory}
            administrationDates={administrationDates}
            isIntake={props.isIntake}
            showParticipant={props.showParticipant}
            onScaleClickRoute={(_scaleId: ScaleId) =>
              onScaleGroupClickRoute(history.scaleGroup.id, participant.participantId)
            }
            onScoreClickRoute={(_scaleId, CareEpisodeComputedValueId) =>
              onScaleGroupScoreClickRoute(
                history.scaleGroup.id,
                participant.participantId,
                CareEpisodeComputedValueId
              )
            }
          />
        );
      })}
    </>
  );
}

type ScaleSectionRowProps = {
  section: ScaleSectionSummary;
  isIntake: boolean;
  administrationDates: NEL.NonEmptyList<Date>;
  showParticipant: boolean;
  navigationContext: Routing.BaseContext;
};

function ScaleSectionRow(props: ScaleSectionRowProps): ReactElement {
  const { section, isIntake, showParticipant, administrationDates, navigationContext } = props;

  return section.caseOf({
    IndividualScaleSection: (h) => {
      return (
        <IndividualScaleRow
          administrationDates={props.administrationDates}
          history={h}
          isIntake={props.isIntake}
          showParticipant={props.showParticipant}
          onScaleClickRoute={(scaleId) => Routing.scaleAdministrationHistoryRoute(navigationContext, scaleId)}
          onScoreClickRoute={(scaleId, CareEpisodeComputedValueId) =>
            Routing.scaleScoreRoute(navigationContext, scaleId, CareEpisodeComputedValueId)
          }
        />
      );
    },
    ScaleGroupSection: (h) => (
      <ScaleGroupRow
        administrationDates={administrationDates}
        history={h}
        isIntake={isIntake}
        showParticipant={showParticipant}
        onScaleGroupClickRoute={(scaleGroupId, participantId) =>
          Routing.scaleGroupHistoryRoute(navigationContext, scaleGroupId, participantId)
        }
        onScaleGroupScoreClickRoute={(scaleGroupId, participantId, CareEpisodeComputedValueId) =>
          Routing.scaleGroupScoreRoute(
            navigationContext,
            scaleGroupId,
            participantId,
            CareEpisodeComputedValueId
          )
        }
      />
    ),
  });
}

const StyledChartTable = styled("table")(() => ({
  borderSpacing: 0,
  tableLayout: "fixed",
  "& td": {
    padding: "6px 8px 6px 0",
  },
}));

type Props = {
  scaleSections: ReadonlyArray<ScaleSectionSummary>;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleSectionsTable(props: Props): ReactElement {
  const { scaleSections, feedbackReportContext } = props;

  // There's a whole late-render thing going on that makes manipulating
  // the size of this table extremely difficult. The only way I've found to influence
  // the behavior is to physically set a maxWidth here that makes sense for the desktop size.
  // This is a total hack.
  const maxWidth = useIsMobile() ? "30em" : "100em";

  return (
    <StyledChartTable data-testid="scale-section-table" sx={{ maxWidth: maxWidth }}>
      <tbody>
        {graphableScaleSections(scaleSections).map((section, i) => (
          <ScaleSectionRow
            key={i}
            section={section}
            administrationDates={feedbackReportContext.administrationDates}
            isIntake={feedbackReportContext.isIntake}
            showParticipant={feedbackReportContext.hasMultipleClientParticipants}
            navigationContext={feedbackReportContext}
          />
        ))}
      </tbody>
    </StyledChartTable>
  );
}

export default ScaleSectionsTable;
