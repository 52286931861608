import { useTheme } from "@mui/material/styles";
import { GlyphSeries, LineSeries } from "@visx/xychart";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { CareEpisodeCategoricalComputedValueDetails } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { getDate, isInvalid, SeveritySupportedValues } from "Shared/Severity/Severity";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";
import { HoverCircle } from "./HoverCircle";

type SeverityHistorySeriesProps = {
  history: ReadonlyArray<SeveritySupportedValues>;
  valueRoute: (value: SeveritySupportedValues) => FeedbackReportRoute;
};

export function CategoricalHistorySeries(props: SeverityHistorySeriesProps): ReactElement {
  const { history, valueRoute } = props;
  const theme = useTheme();

  const getX = (value: CareEpisodeCategoricalComputedValueDetails) => getDate(value);

  const validScores = history.filter((score) => !isInvalid(score));
  const invalidScores = history.filter(isInvalid);

  return (
    <>
      <LineSeries dataKey="line" data={validScores} xAccessor={getX} yAccessor={(_) => 0} />
      <GlyphSeries
        dataKey="glyph-valid"
        data={validScores}
        xAccessor={getX}
        yAccessor={() => 0}
        renderGlyph={(props) => (
          <Link to={valueRoute(props.datum)}>
            <HoverCircle
              cx={props.x}
              cy={props.y}
              swatch={SeverityThresholdClass.color(props.datum.thresholdClass, theme)}
            />
          </Link>
        )}
      />
      <GlyphSeries
        dataKey="glyph-invalid"
        data={invalidScores}
        xAccessor={getX}
        yAccessor={(_) => 0}
        renderGlyph={(props) => (
          <Link to={valueRoute(props.datum)}>
            <HoverCircle
              cx={props.x}
              cy={props.y}
              swatch={SeverityThresholdClass.color(ScaleThresholdClass.UNANSWERED, theme)}
            />
          </Link>
        )}
      />
    </>
  );
}
