import { ProviderSummaryFragmentFragment as ProviderSummaryFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { ProviderId } from "Lib/Ids";
import { Ok, Result } from "seidr";
import { UserSummary } from "./User";

export type ProviderBase = {
  id: ProviderId;
};

export type ProviderSummary = ProviderBase & {
  name: string;
  firstName: string | null;
  user: UserSummary;
  title: string | null;
};

// -- Parsers -----------------------------------------------------------------

function parseProviderSummary(raw: ProviderSummaryFragment): Result<Error, ProviderSummary> {
  return Ok({
    id: raw.id,
    name: raw.user.name,
    firstName: raw.user.firstName,
    user: raw.user,
    title: raw.title,
  });
}

export { parseProviderSummary };
