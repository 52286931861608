import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { ProviderId } from "Lib/Ids";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ClinicalAlertValue,
  DischargeReason,
  EnrollmentMonthMinutesTargetStatus,
  EnrollmentSortParameter,
  EnrollmentStatus,
  ScaleTargeting,
  ScaleThresholdClass,
  SeverityQueryParams,
  SortDirection,
} from "GeneratedGraphQL/SchemaAndOperations";
import { dischargeReasonT, enrollmentMonthMinutesTargetStatusT } from "GeneratedGraphQL/EnumTranslations";
import EnumSelect, { EnumSelectMultiple } from "Shared/EnumSelect";
import { useIsMobile } from "Shared/Responsive";
import {
  ResetAndStickyFilterButtonGroup,
  useStickyOptionalDateRangeParameter,
  useStickyOptionalEnumArrayParameter,
  useStickyOptionalEnumParameter,
  useStickyOptionalIdParameter,
  useStickySeverityQueryParameter,
} from "Shared/StickyParameter";
import { useQueryStringOptionalEncryptedParameter } from "Shared/QueryStringParameter";
import { SortFilters } from "Shared/Sorting";
import { GlobalPanelIndicatorBadge } from "CollaborativeCare/PanelManagement/GlobalPanelIndicator";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";
import { PrimaryCareProviderPatientSearchFilter } from "CollaborativeCare/PatientList/PrimaryCareProviderPatientSearchFilter";
import { CareManagerPatientSearchFilter } from "CollaborativeCare/PatientList/CareManagerPatientSearchFilter";
import { FilterButton } from "Lib/FilterPanel";
import { CreatePatientButton } from "CollaborativeCare/PatientList/CreatePatientButton";
import { ScaleThresholdClassSelectMultiple } from "Outcomes/FormElements/ThresholdClassHelpers";
import { ExpandMore } from "@mui/icons-material";
import { DateRangeParams, OptionalDateRangeSelect } from "Shared/DateRangeSelect";
import { exhaustiveGuard } from "type-utils";
import { TFunction } from "i18next";
import ClinicalAlertValueSelect from "Shared/Scale/CareEpisodeClinicalAlertSelect";

const DEFAULTS = {
  search: null,
  pcpId: null,
  cmId: null,
  enrollmentStatus: EnrollmentStatus.ENROLLED,
};

const OMIT_STATUSES: ReadonlyArray<EnrollmentStatus> = [
  EnrollmentStatus.UNENROLLED,
  EnrollmentStatus.UNENROLLED_NEVER_ENROLLED,
];

export type PanelSearchFilters = {
  search: string | null;
  setSearch: (value: string | null) => void;
  primaryCareProvider: ProviderId | null;
  setPrimaryCareProvider: (value: ProviderId | null) => void;
  careManager: ProviderId | null;
  setCareManager: (value: ProviderId | null) => void;
  status: EnrollmentStatus | null;
  setStatus: (value: EnrollmentStatus | null) => void;
  severity: ReadonlyArray<SeverityQueryParams> | null;
  setSeverity: (value: ReadonlyArray<SeverityQueryParams> | null) => void;
  currentMonthMinutesTargetStatus: EnrollmentMonthMinutesTargetStatus | null;
  setCurrentMonthMinutesTargetStatus: (value: EnrollmentMonthMinutesTargetStatus | null) => void;
  dischargeReason: ReadonlyArray<DischargeReason> | null;
  setDischargeReason: (value: ReadonlyArray<DischargeReason> | null) => void;
  dischargedAt: DateRangeParams | null;
  setDischargedAt: (value: DateRangeParams | null) => void;
  enrolledAt: DateRangeParams | null;
  setEnrolledAt: (value: DateRangeParams | null) => void;
  activeAdvancedFilterCount: number;
  firstSuicidalityAlert: ClinicalAlertValue | null;
  setFirstSuicidalityAlert: (value: ClinicalAlertValue | null) => void;
  lastSuicidalityAlert: ClinicalAlertValue | null;
  setLastSuicidalityAlert: (value: ClinicalAlertValue | null) => void;
  anySuicidalityAlert: ClinicalAlertValue | null;
  setAnySuicidalityAlert: (value: ClinicalAlertValue | null) => void;
} & SortFilters<EnrollmentSortParameter>;

export function usePanelFilters(): PanelSearchFilters {
  // Note that the search param is NOT included in the sticky filtering.
  const [search, setSearch] = useQueryStringOptionalEncryptedParameter("search", null, true);
  const [primaryCareProvider, setPrimaryCareProvider] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.PCP,
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    true
  );
  const [careManager, setCareManager] = useStickyOptionalIdParameter<"Provider">(
    STICKY_PARAMETER_NAMES.CARE_MANAGER,
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    true
  );
  const [status, setStatus] = useStickyOptionalEnumParameter<EnrollmentStatus>(
    STICKY_PARAMETER_NAMES.ENROLLMENT_STATUS,
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    EnrollmentStatus,
    DEFAULTS.enrollmentStatus,
    true
  );

  const [sortBy, setSortBy] = useStickyOptionalEnumParameter<EnrollmentSortParameter>(
    STICKY_PARAMETER_NAMES.SORT_BY,
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    EnrollmentSortParameter,
    null,
    true
  );
  const [sortDirection, setSortDirection] = useStickyOptionalEnumParameter<SortDirection>(
    STICKY_PARAMETER_NAMES.SORT_DIRECTION,
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    SortDirection,
    null,
    true
  );

  const [severity, setSeverity] = useStickySeverityQueryParameter(
    "severity",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    null,
    true
  );

  const [currentMonthMinutesTargetStatus, setCurrentMonthMinutesTargetStatus] =
    useStickyOptionalEnumParameter<EnrollmentMonthMinutesTargetStatus>(
      "minuteStatus",
      STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
      EnrollmentMonthMinutesTargetStatus,
      null,
      true
    );

  const [dischargeReason, setDischargeReason] = useStickyOptionalEnumArrayParameter<DischargeReason>(
    "dischargeReason",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    DischargeReason,
    null,
    true
  );

  const [dischargedAt, setDischargedAt] = useStickyOptionalDateRangeParameter(
    "dischargedAt",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    null,
    true
  );

  const [enrolledAt, setEnrolledAt] = useStickyOptionalDateRangeParameter(
    "enrolledAt",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    null,
    true
  );

  const [firstSuicidalityAlert, setFirstSuicidalityAlert] =
    useStickyOptionalEnumParameter<ClinicalAlertValue>(
      "firstSuicidalityAlert",
      STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
      ClinicalAlertValue,
      null,
      true
    );

  const [lastSuicidalityAlert, setLastSuicidalityAlert] = useStickyOptionalEnumParameter<ClinicalAlertValue>(
    "lastSuicidalityAlert",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    ClinicalAlertValue,
    null,
    true
  );

  const [anySuicidalityAlert, setAnySuicidalityAlert] = useStickyOptionalEnumParameter<ClinicalAlertValue>(
    "anySuicidalityAlert",
    STICKY_PARAMETER_FILTER_SETS.COCM_PANEL,
    ClinicalAlertValue,
    null,
    true
  );

  const activeAdvancedFilterCount =
    (severity?.length ?? 0) +
    (currentMonthMinutesTargetStatus ? 1 : 0) +
    (dischargeReason ? 1 : 0) +
    (dischargedAt ? 1 : 0) +
    (enrolledAt ? 1 : 0);

  return {
    search,
    setSearch,
    primaryCareProvider,
    setPrimaryCareProvider,
    careManager,
    setCareManager,
    status,
    setStatus,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    severity,
    setSeverity,
    currentMonthMinutesTargetStatus,
    setCurrentMonthMinutesTargetStatus,
    dischargeReason,
    setDischargeReason,
    dischargedAt,
    setDischargedAt,
    enrolledAt,
    setEnrolledAt,
    activeAdvancedFilterCount,
    firstSuicidalityAlert,
    setFirstSuicidalityAlert,
    lastSuicidalityAlert,
    setLastSuicidalityAlert,
    anySuicidalityAlert,
    setAnySuicidalityAlert,
  };
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

const SUPPORTED_THRESHOLD_CLASSES: ReadonlyArray<ScaleThresholdClass> = [
  ScaleThresholdClass.NONE,
  ScaleThresholdClass.LOW,
  ScaleThresholdClass.MILD,
  ScaleThresholdClass.MODERATE,
  ScaleThresholdClass.MOD_SEVERE,
  ScaleThresholdClass.SEVERE,
];

function SeverityQueryFilters(props: {
  severity: ReadonlyArray<SeverityQueryParams> | null;
  setSeverity: (value: ReadonlyArray<SeverityQueryParams> | null) => void;
}) {
  const { severity, setSeverity } = props;
  const { t } = useTranslation(["collaborativeCare"]);

  // Rather than making a full on 'add whatever you like', for now just add specific combos to filter on
  const intakeGeneralIndex =
    severity?.findIndex(
      (item) => item.isFirstMeasurement && item.targeting === ScaleTargeting.GENERAL_FUNCTIONING
    ) ?? -1;

  const intakeTargetedIndex =
    severity?.findIndex((item) => item.isFirstMeasurement && item.targeting === ScaleTargeting.TARGETED) ??
    -1;

  const latestGeneralIndex =
    severity?.findIndex(
      (item) => item.isLastMeasurement && item.targeting === ScaleTargeting.GENERAL_FUNCTIONING
    ) ?? -1;

  const latestTargetedIndex =
    severity?.findIndex((item) => item.isLastMeasurement && item.targeting === ScaleTargeting.TARGETED) ?? -1;

  // This is a bit of a mouthful but it finds or creates a severity query item
  // on the basis of the given set of circumstances.
  const updateSeverityFunc = (isFirst: boolean, isLast: boolean, targeting: ScaleTargeting) => {
    return (thresholdClasses: ReadonlyArray<ScaleThresholdClass> | null) => {
      const index =
        severity?.findIndex(
          (item) =>
            (!isFirst || item.isFirstMeasurement) &&
            (!isLast || item.isLastMeasurement) &&
            item.targeting === targeting
        ) ?? -1;
      if (index >= 0 && severity) {
        if (thresholdClasses && thresholdClasses.length > 0) {
          const updatedItem = { ...severity[index], thresholdClasses };
          setSeverity([...severity.slice(0, index), updatedItem, ...severity.slice(index + 1)]);
        } else {
          setSeverity([...severity.slice(0, index), ...severity.slice(index + 1)]);
        }
      } else if (thresholdClasses && thresholdClasses.length > 0) {
        setSeverity([
          ...(severity ?? []),
          {
            isFirstMeasurement: isFirst ? true : undefined,
            isLastMeasurement: isLast ? true : undefined,
            targeting,
            thresholdClasses,
          },
        ]);
      }
    };
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2">{t("collaborativeCare:panel.filters.symptoms")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"} useFlexGap>
          <ScaleThresholdClassSelectMultiple
            formHelperText={t("collaborativeCare:panel.filters.intakeGeneral")}
            disabled={false}
            supportedThresholdClasses={SUPPORTED_THRESHOLD_CLASSES}
            onChange={updateSeverityFunc(true, false, ScaleTargeting.GENERAL_FUNCTIONING)}
            thresholdClasses={
              intakeGeneralIndex >= 0 ? severity?.[intakeGeneralIndex]?.thresholdClasses ?? null : null
            }
          />
          <ScaleThresholdClassSelectMultiple
            formHelperText={t("collaborativeCare:panel.filters.intakeTargeted")}
            disabled={false}
            supportedThresholdClasses={SUPPORTED_THRESHOLD_CLASSES}
            onChange={updateSeverityFunc(true, false, ScaleTargeting.TARGETED)}
            thresholdClasses={
              intakeTargetedIndex >= 0 ? severity?.[intakeTargetedIndex]?.thresholdClasses ?? null : null
            }
          />
          <ScaleThresholdClassSelectMultiple
            formHelperText={t("collaborativeCare:panel.filters.currentGeneral")}
            disabled={false}
            supportedThresholdClasses={SUPPORTED_THRESHOLD_CLASSES}
            onChange={updateSeverityFunc(false, true, ScaleTargeting.GENERAL_FUNCTIONING)}
            thresholdClasses={
              latestGeneralIndex >= 0 ? severity?.[latestGeneralIndex]?.thresholdClasses ?? null : null
            }
          />
          <ScaleThresholdClassSelectMultiple
            formHelperText={t("collaborativeCare:panel.filters.currentTargeted")}
            disabled={false}
            supportedThresholdClasses={SUPPORTED_THRESHOLD_CLASSES}
            onChange={updateSeverityFunc(false, true, ScaleTargeting.TARGETED)}
            thresholdClasses={
              latestTargetedIndex >= 0 ? severity?.[latestTargetedIndex]?.thresholdClasses ?? null : null
            }
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

function MinutesFilters(props: {
  currentMonthMinutesTargetStatus: EnrollmentMonthMinutesTargetStatus | null;
  setCurrentMonthMinutesTargetStatus: (value: EnrollmentMonthMinutesTargetStatus | null) => void;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2">{t("collaborativeCare:panel.filters.minutes")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <EnumSelect
          enumTrans={enrollmentMonthMinutesTargetStatusT}
          onChange={props.setCurrentMonthMinutesTargetStatus}
          value={props.currentMonthMinutesTargetStatus}
          optionsEnum={EnrollmentMonthMinutesTargetStatus}
          title={t("collaborativeCare:panel.filters.minuteStatus")}
        />
      </AccordionDetails>
    </Accordion>
  );
}

function RiskFilters(props: {
  firstSuicidalityAlert: ClinicalAlertValue | null;
  setFirstSuicidalityAlert: (value: ClinicalAlertValue | null) => void;
  lastSuicidalityAlert: ClinicalAlertValue | null;
  setLastSuicidalityAlert: (value: ClinicalAlertValue | null) => void;
  anySuicidalityAlert: ClinicalAlertValue | null;
  setAnySuicidalityAlert: (value: ClinicalAlertValue | null) => void;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2">{t("collaborativeCare:panel.filters.risk")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"} useFlexGap>
          <ClinicalAlertValueSelect
            onChange={props.setFirstSuicidalityAlert}
            value={props.firstSuicidalityAlert}
            title={t("collaborativeCare:panel.filters.firstSuicidalityAlert")}
          />
          <ClinicalAlertValueSelect
            onChange={props.setLastSuicidalityAlert}
            value={props.lastSuicidalityAlert}
            title={t("collaborativeCare:panel.filters.lastSuicidalityAlert")}
          />
          <ClinicalAlertValueSelect
            onChange={props.setAnySuicidalityAlert}
            value={props.anySuicidalityAlert}
            title={t("collaborativeCare:panel.filters.anySuicidalityAlert")}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

function DischargeFilters(props: {
  dischargeReason: ReadonlyArray<DischargeReason> | null;
  setDischargeReason: (value: ReadonlyArray<DischargeReason> | null) => void;
  dischargedAt: DateRangeParams | null;
  setDischargedAt: (value: DateRangeParams | null) => void;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2">{t("collaborativeCare:panel.filters.discharge")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"} useFlexGap>
          <OptionalDateRangeSelect
            dateRange={props.dischargedAt}
            setDateRange={props.setDischargedAt}
            startLabel={t("collaborativeCare:panel.filters.dischargedAtStart")}
            endLabel={t("collaborativeCare:panel.filters.dischargedAtEnd")}
          />
          <Box>
            <InputLabel>{t("collaborativeCare:panel.filters.dischargeReason")}</InputLabel>
            <EnumSelectMultiple
              enumTrans={dischargeReasonT}
              onChange={props.setDischargeReason}
              value={props.dischargeReason}
              optionsEnum={DischargeReason}
              title={t("collaborativeCare:panel.filters.dischargeReason")}
            />
          </Box>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

function EnrollmentFilters(props: {
  enrolledAt: DateRangeParams | null;
  setEnrolledAt: (value: DateRangeParams | null) => void;
}) {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h2">{t("collaborativeCare:panel.filters.enrollment")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={1} alignItems="center" flexWrap={"wrap"} useFlexGap>
          <OptionalDateRangeSelect
            dateRange={props.enrolledAt}
            setDateRange={props.setEnrolledAt}
            startLabel={t("collaborativeCare:panel.filters.enrolledAtStart")}
            endLabel={t("collaborativeCare:panel.filters.enrolledAtEnd")}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}

type PanelFiltersProps = {
  filters: PanelSearchFilters;
};

// We previously had a sense of 'enrollment' which included a patient status - e.g. 'never enrolled' rather than
// the status of an individual enrollment.
// Once we transition over we will change the standard translation and remove this, but until then
// we want the right translations
function enrollmentStatusNewT(value: EnrollmentStatus, t: TFunction<["enums", "collaborativeCare"]>): string {
  switch (value) {
    case EnrollmentStatus.DISCHARGED:
      return t("collaborativeCare:panel.enrollmentStatus.discharged");
    case EnrollmentStatus.ENROLLED:
      return t("collaborativeCare:panel.enrollmentStatus.enrolled");
    case EnrollmentStatus.UNENROLLED:
    case EnrollmentStatus.UNENROLLED_NEVER_ENROLLED:
      // We should never need to display this because we don't show it in the dropdown
      return t("collaborativeCare:panel.enrollmentStatus.unenrolled");
    default:
      return exhaustiveGuard(value);
  }
}

export default function PanelFilters(props: PanelFiltersProps): ReactElement {
  const { filters } = props;
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [advancedOpen, setAdvancedOpen] = useState(false);

  const isMobile = useIsMobile();

  const rowSpacing = isMobile ? 1 : 0;

  const advancedFilters = isMobile ? (
    <>
      <SeverityQueryFilters severity={props.filters.severity} setSeverity={props.filters.setSeverity} />
      <RiskFilters
        anySuicidalityAlert={props.filters.anySuicidalityAlert}
        setAnySuicidalityAlert={props.filters.setAnySuicidalityAlert}
        firstSuicidalityAlert={props.filters.firstSuicidalityAlert}
        setFirstSuicidalityAlert={props.filters.setFirstSuicidalityAlert}
        lastSuicidalityAlert={props.filters.lastSuicidalityAlert}
        setLastSuicidalityAlert={props.filters.setLastSuicidalityAlert}
      />
      <MinutesFilters
        currentMonthMinutesTargetStatus={props.filters.currentMonthMinutesTargetStatus}
        setCurrentMonthMinutesTargetStatus={props.filters.setCurrentMonthMinutesTargetStatus}
      />
      <EnrollmentFilters enrolledAt={props.filters.enrolledAt} setEnrolledAt={props.filters.setEnrolledAt} />
      <DischargeFilters
        dischargedAt={props.filters.dischargedAt}
        setDischargedAt={props.filters.setDischargedAt}
        dischargeReason={props.filters.dischargeReason}
        setDischargeReason={props.filters.setDischargeReason}
      />
    </>
  ) : (
    <Grid container columns={2}>
      <Grid item xs={1}>
        <SeverityQueryFilters severity={props.filters.severity} setSeverity={props.filters.setSeverity} />
        <MinutesFilters
          currentMonthMinutesTargetStatus={props.filters.currentMonthMinutesTargetStatus}
          setCurrentMonthMinutesTargetStatus={props.filters.setCurrentMonthMinutesTargetStatus}
        />
      </Grid>
      <Grid item xs={1}>
        <RiskFilters
          anySuicidalityAlert={props.filters.anySuicidalityAlert}
          setAnySuicidalityAlert={props.filters.setAnySuicidalityAlert}
          firstSuicidalityAlert={props.filters.firstSuicidalityAlert}
          setFirstSuicidalityAlert={props.filters.setFirstSuicidalityAlert}
          lastSuicidalityAlert={props.filters.lastSuicidalityAlert}
          setLastSuicidalityAlert={props.filters.setLastSuicidalityAlert}
        />
        <EnrollmentFilters
          enrolledAt={props.filters.enrolledAt}
          setEnrolledAt={props.filters.setEnrolledAt}
        />
        <DischargeFilters
          dischargedAt={props.filters.dischargedAt}
          setDischargedAt={props.filters.setDischargedAt}
          dischargeReason={props.filters.dischargeReason}
          setDischargeReason={props.filters.setDischargeReason}
        />
      </Grid>
    </Grid>
  );

  return (
    <StyledPaper>
      <Grid container rowSpacing={rowSpacing} spacing={1}>
        <StyledBox>
          <Grid container rowSpacing={rowSpacing} spacing={1}>
            <Grid item xs="auto">
              <TextField
                id="outlined-required"
                label={t("collaborativeCare:patientList.search.searchClientsLabel")}
                value={props.filters.search || ""}
                onChange={(event) => props.filters.setSearch(event.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs="auto">
              <PrimaryCareProviderPatientSearchFilter
                value={props.filters.primaryCareProvider}
                setValue={props.filters.setPrimaryCareProvider}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs="auto">
              <CareManagerPatientSearchFilter
                value={filters.careManager}
                setValue={filters.setCareManager}
                defaultValue={null}
              />
            </Grid>
            <Grid item xs="auto">
              <Box width="17em">
                <EnumSelect
                  optionsEnum={EnrollmentStatus}
                  title={t("collaborativeCare:patientList.search.enrollmentStatus")}
                  enumTrans={enrollmentStatusNewT}
                  omitOptions={OMIT_STATUSES}
                  onChange={props.filters.setStatus}
                  sortFn={(a, b) => {
                    //enrolled goes to the top
                    if (a == EnrollmentStatus.ENROLLED) {
                      return -1;
                    }
                    if (b == EnrollmentStatus.ENROLLED) {
                      return 1;
                    }
                    //discharged goes to the bottom
                    if (a == EnrollmentStatus.DISCHARGED) {
                      return 1;
                    }
                    if (b == EnrollmentStatus.DISCHARGED) {
                      return -1;
                    }
                    //the middle two are already in alphabetical order
                    return 0;
                  }}
                  value={props.filters.status}
                  defaultValue={DEFAULTS.enrollmentStatus}
                />
              </Box>
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <GlobalPanelIndicatorBadge />
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <FilterButton
                activeFilterCount={props.filters.activeAdvancedFilterCount}
                open={advancedOpen}
                toggleOpen={setAdvancedOpen}
              />
            </Grid>
            <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
              <Divider orientation="vertical" flexItem />
              <ResetAndStickyFilterButtonGroup
                onReset={() => {
                  filters.setCareManager(DEFAULTS.cmId);
                  filters.setStatus(DEFAULTS.enrollmentStatus);
                  filters.setSearch(DEFAULTS.search);
                  filters.setPrimaryCareProvider(DEFAULTS.pcpId);
                  filters.setSeverity(null);
                  filters.setDischargedAt(null);
                  filters.setDischargeReason(null);
                  filters.setCurrentMonthMinutesTargetStatus(null);
                  filters.setEnrolledAt(null);
                  filters.setAnySuicidalityAlert(null);
                  filters.setFirstSuicidalityAlert(null);
                  filters.setLastSuicidalityAlert(null);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Collapse in={advancedOpen}>{advancedFilters}</Collapse>
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item display="flex" flexGrow={1}>
          <Box sx={{ flexGrow: 1 }} />
          <CreatePatientButton />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}
