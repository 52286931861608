import React from "react";
import { useParams } from "react-router-dom";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { DetailsWrapper } from "../DetailsWrapper";
import AlertPane from "./AlertPane";
import { CareEpisodeId } from "Lib/Ids";
import { FeedbackReportContext } from "../FeedbackReportContext";
import * as Routing from "FeedbackReport/FeedbackReportRouting";
import { useTranslation } from "react-i18next";

type AlertProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

export const AlertRoute = (props: AlertProps) => {
  const { t } = useTranslation(["report"]);
  const params = useParams<{
    alertHistoryId?: string;
    alertId?: string;
  }>();

  const alertHistoryId = Id.fromNullableString<"CareEpisodeScale">(params.alertHistoryId).getOrElse(null);
  const alertId = Id.fromNullableString<"CareEpisodeClinicalAlert">(params.alertId).getOrElse(null);

  if (alertHistoryId === null || alertId === null) {
    return <ErrorMessage message={t("report:errors.invalidUrl")} />;
  }

  const breadcrumbs: ReadonlyArray<[string, Routing.FeedbackReportRoute?]> = [
    [t("report:breadcrumbs.patientInfo"), Routing.overviewRoute(props.feedbackReportContext)],
    [
      t("report:breadcrumbs.riskHistory"),
      Routing.alertHistoryRoute(props.feedbackReportContext, alertHistoryId),
    ],
    [t("report:breadcrumbs.responses")],
  ];

  return (
    <DetailsWrapper breadcrumbs={breadcrumbs}>
      <AlertPane
        careEpisodeId={props.careEpisodeId}
        alertHistoryId={alertHistoryId}
        alertId={alertId}
        feedbackReportContext={props.feedbackReportContext}
      />
    </DetailsWrapper>
  );
};
