import React, { ReactElement, useState } from "react";
import { Button, MenuItem, Select, Stack, Typography } from "@mui/material";
import {
  SortDirection,
  TaskSortParameter,
  TaskStatus,
  useMergePlaceholderTaskWithExistingTaskMutation,
  useTasksCardQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { PatientId, TaskId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { useTranslation } from "react-i18next";
import { useChangeCurrentTask } from "Contexts/CurrentTaskContext";

function MergeTaskDialog(props: {
  onBack: () => void;
  onSuccess?: () => void;
  patientId: PatientId;
  taskId: TaskId;
  setShowSurroundingForms?: (showForms: boolean) => void;
}): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  let mergeDisabled = false;

  const { remoteData } = apolloQueryHookWrapper(
    useTasksCardQuery({
      variables: {
        patientId: props.patientId,
        sortBy: TaskSortParameter.DUE_AT,
        sortDirection: SortDirection.DESC,
        status: TaskStatus.ACTIVE,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, // JS vs Ruby
        enrollmentId: null,
      },
    })
  );

  let mergeClick = function () {};

  const [mergeTarget, setMergeTarget] = useState<TaskId>();

  const [mergeTask, { remoteData: mutationData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareMergePlaceholderTaskWithExistingTask,
    useMergePlaceholderTaskWithExistingTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const changeTask = useChangeCurrentTask();

  useEffectSimpleCompare(() => {
    mutationData.caseOf({
      Success: (response) => {
        if (response?.task) {
          changeTask(response.task);
          if (props.setShowSurroundingForms) {
            props.setShowSurroundingForms(true);
          }
        }
        if (props.onSuccess) {
          props.onSuccess();
        }
      },
      _: () => {
        return;
      },
    });
  }, [mutationData.kind]);

  const dropdownContent = remoteData.caseOf({
    NotAsked: () => {
      return <></>;
    },
    Loading: () => {
      return <></>;
    },
    Failure: () => {
      return (
        <>
          <Typography>
            {t("collaborativeCare:patientDetails.tasks.placeholders.merge.loadTasksError")}
          </Typography>
        </>
      );
    },
    Success: (result) => {
      const noTasksContent = (
        <>
          <Typography>{t("collaborativeCare:patientDetails.tasks.placeholders.merge.noTargets")}</Typography>
        </>
      );
      if (!result.collaborativeCareTasks) {
        return noTasksContent;
      }
      const filteredTasks = result.collaborativeCareTasks.nodes
        .filter((task) => task.id != props.taskId)
        .filter((task) => !task.isPlaceholder);

      if (filteredTasks.length == 0) {
        mergeDisabled = true;
        return noTasksContent;
      }
      mergeClick = function () {
        if (mergeTarget) {
          mergeTask({
            variables: {
              input: {
                taskId: props.taskId,
                taskIdToMergeInto: mergeTarget,
              },
            },
          });
        } else if (filteredTasks[0]) {
          mergeTask({
            variables: {
              input: {
                taskId: props.taskId,
                taskIdToMergeInto: filteredTasks[0].id,
              },
            },
          });
        }
      };

      return (
        <Select defaultValue={filteredTasks[0]?.id.toString()}>
          {filteredTasks.map(function (task) {
            return (
              <MenuItem
                value={task.id.toString()}
                onClick={() => {
                  setMergeTarget(task.id);
                }}
                key={task.id.toString()}
              >
                {task.title}
              </MenuItem>
            );
          })}
        </Select>
      );
    },
  });

  return (
    <>
      <Stack direction="column" spacing={1}>
        <Typography>{t("collaborativeCare:patientDetails.tasks.placeholders.merge.description")}</Typography>
        {dropdownContent}
        <Stack direction="row-reverse" spacing={1}>
          <Button onClick={mergeClick} disabled={mergeDisabled} variant="contained" color="secondary">
            {t("collaborativeCare:patientDetails.tasks.placeholders.merge.merge")}
          </Button>
          <Button onClick={props.onBack} variant="outlined" color="secondary">
            {t("collaborativeCare:patientDetails.tasks.placeholders.merge.back")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default MergeTaskDialog;
