import { ChevronRight } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import {
  OrganizationFilter,
  Patient,
  PatientFilter,
  PatientQualityIssuesQuery,
  PatientQualityIssuesQueryVariables,
  PatientQualityIssueType,
  PatientSortParameter,
  usePatientQualityIssuesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  DataGridRowClickHandler,
} from "Shared/SortablePagableCollectionDataGrid";
import IssueCountColumn from "./IssueCountColumn";
import { PatientQualityIssueSuperStatus, statusMap, superStatusToStatusMap } from "./PatientQualityIssue";

type QualityIssueTableProps = {
  status: PatientQualityIssueSuperStatus | null;
  issue: PatientQualityIssueType | null;
  organizationFilter: OrganizationFilter | null;
  patientFilter: PatientFilter | null;
};

export function QualityIssuesTable(props: QualityIssueTableProps): ReactElement {
  const { status, issue } = props;
  const { t } = useTranslation(["qualityIssues"]);
  const navigate = useNavigate();
  const theme = useTheme();

  const columns: DataGridCols<PatientQualityIssuesQuery, ["patients"]> = [
    {
      field: "patientName",
      headerName: t("qualityIssues:headers.patientName"),
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return <PatientNameColumn patient={params.row} />;
      },
    },
    {
      field: "issue",
      headerName: t("qualityIssues:headers.issue"),
      flex: 1,
      renderCell: (params) => {
        return (
          <IssueCountColumn
            patientId={params.row.id}
            activeIssue={issue}
            activeStatuses={superStatusToStatusMap(status)}
          />
        );
      },
    },
    {
      field: "icon",
      headerName: "",
      renderCell: (_params) => {
        return <ChevronRight />;
      },
    },
  ];

  const filters: Pick<
    PatientQualityIssuesQueryVariables,
    "qualityIssues" | "organizationFilter" | "patientFilter"
  > = {
    organizationFilter: props.organizationFilter,
    patientFilter: props.patientFilter,
    qualityIssues: {
      statuses: status ? statusMap[status] : undefined,
      issues: issue ? [issue] : undefined,
    },
  };

  const onClick: DataGridRowClickHandler<PatientQualityIssuesQuery, ["patients"]> = (params, event) => {
    // Calling `navigate` in this handler will override the effect of clicking on any links in the table, so the patient
    // name column's link does nothing. Checking isDefaultPrevented tells us if we're clicking on something that's
    // generating another event in addition to this click (like a link, or possibly other inputs, I haven't tested).
    // As long as we don't navigate during clicks on the link the link works fine.
    if (!event.isDefaultPrevented()) {
      const id = params.row.id;
      navigate(`/app/patients/quality/patient/${id}` + window.location.search);
    }
  };

  return (
    <SortablePagableCollectionDataGrid
      sx={{ "& .MuiDataGrid-row.Mui-selected": { backgroundColor: theme.palette.background.default } }}
      disableRowSelectionOnClick={false}
      queryHook={usePatientQualityIssuesQuery}
      queryVariables={filters}
      unwrapData={(response) => response?.patients || null}
      colNameToSortParam={(field) => (field === "FIRSTNAME" ? PatientSortParameter.FIRSTNAME : null)}
      columns={columns}
      defaultPageSize={10}
      onRowClick={onClick}
      getRowId={(row) => row.id.toString()}
    />
  );
}

type PatientNameColumnProps = {
  patient: Pick<Patient, "__typename" | "id" | "name"> | undefined | null;
};

function PatientNameColumn(props: PatientNameColumnProps): ReactElement {
  return <Link to={`/provider/patients/${props.patient?.id}`}>{props.patient?.name}</Link>;
}
