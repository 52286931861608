import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

import RouterWithNotFound from "../../../../Shared/RouterWithNotFound";
import ProviderSettings from "./ProviderSettings";

function UserRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<ProviderSettings />} path=":providerId/*" />
    </RouterWithNotFound>
  );
}

export default UserRoutes;
