import { IntakeStatus, Patient } from "GeneratedGraphQL/SchemaAndOperations";
import { EnrollmentId, CareEpisodeId } from "Lib/Ids";
import ErrorMessage from "Shared/ErrorMessage";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { IntakeAdministrationChoices } from "./IntakeAdministrationChoices";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import { IntakeManualAdministrationLinks } from "./IntakeManualAdministrationLinks";
import { PostIntakeTaskList } from "CollaborativeCare/Enrollment/PostIntakeTaskList";

type IntakeScreen = "scale-choices" | "manual-admin-links" | "post-intake-task-list";

type FallibleIntakeFlowProps = {
  patient: PickTypename<Patient, "id" | "name">;
  onClose: () => void;
};

/**
 * For use when you want an intake flow, but aren't sure if you have an enrollment yet.
 */
export function FallibleIntakeFlow(props: FallibleIntakeFlowProps): ReactElement {
  const enrollmentState = useEnrollmentState(props.patient.id);
  if (enrollmentState.status !== "enrolled") {
    return <ErrorMessage message="Patient is not enrolled" />;
  }

  return (
    <IntakeFlow
      patient={props.patient}
      enrollmentId={enrollmentState.enrollmentId}
      onClose={props.onClose}
      intakeStatus={enrollmentState.intakeStatus}
      careEpisodeId={enrollmentState.careEpisodeId}
    />
  );
}

type IntakeFlowProps = {
  patient: PickTypename<Patient, "id" | "name">;
  enrollmentId: EnrollmentId;
  careEpisodeId: CareEpisodeId;
  intakeStatus: IntakeStatus;
  onClose: () => void;
};

export function IntakeFlow(props: IntakeFlowProps): ReactElement {
  const startScreen =
    props.intakeStatus === IntakeStatus.NOT_STARTED ? "scale-choices" : "manual-admin-links";
  const [screen, setScreen] = React.useState<IntakeScreen>(startScreen);

  switch (screen) {
    case "scale-choices":
      return (
        <IntakeAdministrationChoices
          enrollmentId={props.enrollmentId}
          careEpisodeId={props.careEpisodeId}
          patient={props.patient}
          onSuccess={() => setScreen("manual-admin-links")}
        />
      );
    case "manual-admin-links":
      return (
        <IntakeManualAdministrationLinks
          enrollmentId={props.enrollmentId}
          onClose={() => setScreen("post-intake-task-list")}
        />
      );
    case "post-intake-task-list":
      return <PostIntakeTaskList patient={props.patient} onFinish={props.onClose}></PostIntakeTaskList>;
  }
}
