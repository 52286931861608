import { Tooltip } from "@mui/material";
import React, { ReactElement } from "react";
import { SeverityChipBase } from "Shared/Scale/SeverityChip";
import { ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import { BadTextTypography, GoodTextTypography } from "MDS/MDSTypography";
import { useTranslation } from "react-i18next";
import { MBC_REDESIGN_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";

function defaultMeasurementAllowedToolTip(
  measurementAllowed: boolean,
  measureStarting: Date | null
): (child: ReactElement) => ReactElement {
  const { t } = useTranslation(["settings"]);
  const measureStartingInFuture = measureStarting && measureStarting > new Date();
  const value = measurementAllowed ? (
    measureStartingInFuture ? (
      <GoodTextTypography component="span">
        {t("settings:measurementAllowedBadge.defaultTooltipFuture", { date: measureStarting })}
      </GoodTextTypography>
    ) : (
      <GoodTextTypography component="span">
        {t("settings:measurementAllowedBadge.defaultTooltipAllowed")}
      </GoodTextTypography>
    )
  ) : (
    <BadTextTypography component="span">
      {t("settings:measurementAllowedBadge.defaultTooltipNotAllowed")}
    </BadTextTypography>
  );
  return (child: ReactElement) => (
    <Tooltip
      title={
        <>
          {t("settings:measurementAllowedBadge.defaultTooltipPrefix")} {value}
        </>
      }
      placement="bottom"
    >
      {child}
    </Tooltip>
  );
}

// We want to change the text for the redesign. Rather than do clever string construction to do this which
// makes the parser go mad, just make a copy for now, and delete the other function when we switch.
function defaultMeasurementAllowedToolTipRedesign(
  measurementAllowed: boolean,
  measureStarting: Date | null
): (child: ReactElement) => ReactElement {
  const { t } = useTranslation(["settings"]);
  const measureStartingInFuture = measureStarting && measureStarting > new Date();
  const value = measurementAllowed ? (
    measureStartingInFuture ? (
      <GoodTextTypography component="span">
        {t("settings:measurementAllowedBadgeRedesign.defaultTooltipFuture", { date: measureStarting })}
      </GoodTextTypography>
    ) : (
      <GoodTextTypography component="span">
        {t("settings:measurementAllowedBadgeRedesign.defaultTooltipAllowed")}
      </GoodTextTypography>
    )
  ) : (
    <BadTextTypography component="span">
      {t("settings:measurementAllowedBadgeRedesign.defaultTooltipNotAllowed")}
    </BadTextTypography>
  );
  return (child: ReactElement) => (
    <Tooltip
      title={
        <>
          {t("settings:measurementAllowedBadgeRedesign.defaultTooltipPrefix")} {value}
        </>
      }
      placement="bottom"
    >
      {child}
    </Tooltip>
  );
}

function UnderMeasurementChip({
  measurementAllowed,
  measureStarting,
  chipTooltip,
}: {
  measurementAllowed: boolean;
  measureStarting: Date | null;
  chipTooltip?: (child: ReactElement) => ReactElement;
}): ReactElement {
  const { t } = useTranslation(["settings"]);
  const redesignEnabled = useIsFrontendFlagEnabled(MBC_REDESIGN_FLAG);

  const measureStartingInFuture = measureStarting && measureStarting > new Date();

  let chipText = measurementAllowed
    ? measureStartingInFuture
      ? t("settings:measurementAllowedBadge.allowedInFuture", { date: measureStarting })
      : t("settings:measurementAllowedBadge.allowed")
    : t("settings:measurementAllowedBadge.notAllowed");

  if (redesignEnabled) {
    chipText = measurementAllowed
      ? measureStartingInFuture
        ? t("settings:measurementAllowedBadgeRedesign.allowedInFuture", { date: measureStarting })
        : t("settings:measurementAllowedBadgeRedesign.allowed")
      : t("settings:measurementAllowedBadgeRedesign.notAllowed");
  }

  const defaultTooltip = redesignEnabled
    ? defaultMeasurementAllowedToolTipRedesign(measurementAllowed, measureStarting)
    : defaultMeasurementAllowedToolTip(measurementAllowed, measureStarting);

  return (
    <SeverityChipBase
      thresholdClass={
        measurementAllowed
          ? measureStartingInFuture
            ? ScaleThresholdClass.MODERATE
            : ScaleThresholdClass.NONE
          : ScaleThresholdClass.SEVERE
      }
      isStale={false}
      chipTooltip={chipTooltip || defaultTooltip}
    >
      {chipText}
    </SeverityChipBase>
  );
}

export default UnderMeasurementChip;
