import React, { ReactElement } from "react";
import { Route } from "react-router-dom";

import RouterWithNotFound from "Shared/RouterWithNotFound";
import { WithPermission } from "Shared/WithPermission";
import ExportList from "./ExportList";

function ExportRoutes(): ReactElement {
  return (
    <WithPermission permission="bulkExport">
      <RouterWithNotFound>
        <Route element={<ExportList />} path="/" />
      </RouterWithNotFound>
    </WithPermission>
  );
}

export default ExportRoutes;
