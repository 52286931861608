import { Button, DialogContent, Link, Stack, Typography } from "@mui/material";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  Appointment,
  AppointmentStatus,
  PatientSession,
  Recurrence,
  useCancelAppointmentMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Key things to know when canceling an appointment:
//  - canceling only sets the status to canceled. The appointment will still be visible on the patient details page
//  - any invitations that have not been taken will be canceled
//  - [future] provider will not be assessed on whether they viewed the feedback report

export function CancelAppointmentLink(props: {
  appointment: Pick<Appointment, "id" | "status"> & {
    patientSession:
      | (Pick<PatientSession, "isReportAvailable"> & { schedulingRecurrence: Pick<Recurrence, "id"> | null })
      | null;
  };
  text: string;
}) {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { appointment } = props;

  const link = <Link onClick={() => setShowForm(true)}>{props.text}</Link>;

  if (appointment.status === AppointmentStatus.CANCELED) {
    return null;
  }

  const dialog = showForm ? (
    <CancelAppointmentButtonInternal {...props} setShowForm={setShowForm} showForm={showForm} />
  ) : null;

  return (
    <>
      {link}
      {dialog}
    </>
  );
}

export function CancelAppointmentButton(props: {
  appointment: Pick<Appointment, "id" | "status" | "source"> & {
    patientSession:
      | (Pick<PatientSession, "isReportAvailable"> & { schedulingRecurrence: Pick<Recurrence, "id"> | null })
      | null;
  };
}) {
  const { t } = useTranslation(["patients"]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const { appointment } = props;

  const button = (
    <Button onClick={() => setShowForm(true)} variant={"outlined"} color={"secondary"}>
      {t("patients:appointments.actions.cancel")}
    </Button>
  );

  if (appointment.status === AppointmentStatus.CANCELED) {
    return (
      <Button variant="contained" color="secondary" disabled>
        {t("patients:appointments.actions.alreadyCanceled")}
      </Button>
    );
  }

  const dialog = showForm ? (
    <CancelAppointmentButtonInternal {...props} setShowForm={setShowForm} showForm={showForm} />
  ) : null;

  return (
    <>
      {button}
      {dialog}
    </>
  );
}

function CancelAppointmentButtonInternal(props: {
  appointment: Pick<Appointment, "id" | "status"> & {
    patientSession:
      | (Pick<PatientSession, "isReportAvailable"> & { schedulingRecurrence: Pick<Recurrence, "id"> | null })
      | null;
  };
  showForm: boolean;
  setShowForm: (show: boolean) => void;
}) {
  const { t } = useTranslation(["patients"]);
  const { appointment, showForm, setShowForm } = props;

  const [cancelAppointment, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.schedulingCancelAppointment,
    useCancelAppointmentMutation({
      variables: {
        input: {
          appointmentId: props.appointment.id,
        },
      },
      refetchQueries: ["AppointmentDetails", "AppointmentInvitationDetails"],
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setTimeout(() => setShowForm(false), 300);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";

  const bullets = [t("patients:appointments.cancelDialog.noObligation")];

  if (appointment.patientSession) {
    if (appointment.patientSession.isReportAvailable) {
      bullets.push(t("patients:appointments.cancelDialog.reportAvailable"));
    } else {
      bullets.push(t("patients:appointments.cancelDialog.noReport"));
    }
  } else {
    bullets.push(t("patients:appointments.cancelDialog.noSession"));
  }

  if (appointment.patientSession?.schedulingRecurrence?.id) {
    bullets.push(t("patients:appointments.cancelDialog.cancelOneOnly"));
  }

  return (
    <>
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("patients:appointments.cancelDialog.title")}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>{t("patients:appointments.cancelDialog.details")}</Typography>
            <ul>
              {bullets.map((bullet, index) => {
                return <li key={index}>{bullet}</li>;
              })}
            </ul>
            <Stack direction="row" spacing={1}>
              <ButtonWithSpinner
                showSpinner={showSpinner}
                variant={"contained"}
                color="error"
                disabled={showSpinner}
                onClick={() => cancelAppointment()}
              >
                {t("patients:appointments.cancelDialog.confirm")}
              </ButtonWithSpinner>
              <Button
                onClick={() => setShowForm(false)}
                disabled={false}
                variant={"outlined"}
                color={"secondary"}
              >
                {t("patients:appointments.cancelDialog.abort")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
