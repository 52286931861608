import { Alert, Card, CardContent, CardHeader, Grid, Stack, Typography, styled } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  EnrollmentMonthMinutesTargetStatus,
  PatientMinutesTarget,
  useMinutesTargetsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import ErrorMessage from "Shared/ErrorMessage";
import Spinner from "Shared/Spinner";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import { PatientBillableMinutesWithTargetBadgeElement } from "./PatientBillableMinutesBadge";
import { Section } from "./CaseConsult/Section";
import { useTranslation } from "react-i18next";

export function MinutesTargetsPage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(useMinutesTargetsQuery());

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (
        response.collaborativeCarePatientMinutesTargets &&
        response.collaborativeCareDefaultPatientMinutesTargets
      ) {
        return (
          <Stack direction="column" spacing={1} maxWidth="35rem">
            <Alert severity="info">{t("collaborativeCare:minutesTargets.explanation")}</Alert>
            <Section title={t("collaborativeCare:minutesTargets.custom.title")}>
              <Stack direction="column" spacing={1} mt={1}>
                {response.collaborativeCarePatientMinutesTargets.nodes.map((target) => (
                  <MinutesTarget target={target} key={target.id.toString()} />
                ))}
                {response.collaborativeCarePatientMinutesTargets.nodes.length === 0
                  ? t("collaborativeCare:minutesTargets.custom.empty")
                  : ""}
              </Stack>
            </Section>
            <Section title={t("collaborativeCare:minutesTargets.default.title")}>
              <Stack direction="column" spacing={1} mt={1}>
                {response.collaborativeCareDefaultPatientMinutesTargets.map((target) => (
                  <MinutesTarget target={target} key={target.id.toString()} />
                ))}
              </Stack>
            </Section>
          </Stack>
        );
      } else {
        return <ErrorMessage message={t("collaborativeCare:minutesTargets.genericLoadError")} />;
      }
    },
  });

  return <Page browserTitle={t("collaborativeCare:minutesTargets.pageTitle")}>{content}</Page>;
}

type MinutesTargetProps = {
  target: PickTypename<
    PatientMinutesTarget,
    "floor" | "ceiling" | "enrollmentMonthStart" | "enrollmentMonthEnd"
  >;
};

const CenteredGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(0.5),
}));

function MinutesTarget(props: MinutesTargetProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const title =
    props.target.enrollmentMonthStart === props.target.enrollmentMonthEnd
      ? t("collaborativeCare:minutesTargets.target.oneMonthTitle", {
          month: props.target.enrollmentMonthStart,
        })
      : props.target.enrollmentMonthEnd
      ? t("collaborativeCare:minutesTargets.target.twoMonthTitle", {
          start: props.target.enrollmentMonthStart,
          end: props.target.enrollmentMonthEnd,
        })
      : t("collaborativeCare:minutesTargets.target.openMonthTitle", {
          start: props.target.enrollmentMonthStart,
        });

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        <Grid container columns={6} spacing={1}>
          <Grid item xs={3}>
            <Typography variant="h3">
              {t("collaborativeCare:minutesTargets.target.floor", { floor: props.target.floor })}
            </Typography>
          </Grid>
          <Grid item xs={3} textAlign="right">
            <Typography variant="h3">
              {t("collaborativeCare:minutesTargets.target.ceiling", { ceiling: props.target.ceiling })}
            </Typography>
          </Grid>
          <CenteredGrid item xs={2}>
            {t("collaborativeCare:minutesTargets.target.under")}
          </CenteredGrid>
          <CenteredGrid item xs={2}>
            {t("collaborativeCare:minutesTargets.target.on")}
          </CenteredGrid>
          <CenteredGrid item xs={2} textAlign="center">
            {t("collaborativeCare:minutesTargets.target.over")}
          </CenteredGrid>
          <CenteredGrid item xs={2}>
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={0}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.UNDER_TARGET}
            />
            -
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={props.target.floor - 1}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.UNDER_TARGET}
            />
          </CenteredGrid>
          <CenteredGrid item xs={2}>
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={props.target.floor}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.ON_TARGET}
            />
            -
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={props.target.ceiling}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.ON_TARGET}
            />
          </CenteredGrid>
          <CenteredGrid item xs={2}>
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={props.target.ceiling + 1}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.OVER_TARGET}
            />
            -
            <PatientBillableMinutesWithTargetBadgeElement
              billableMinutes={props.target.ceiling + props.target.floor}
              minutesTargetFloor={props.target.floor}
              minutesTargetCeiling={props.target.ceiling}
              minutesTargetStatus={EnrollmentMonthMinutesTargetStatus.OVER_TARGET}
              label={`${props.target.ceiling + props.target.floor}+`}
            />
          </CenteredGrid>
        </Grid>
      </CardContent>
    </Card>
  );
}
