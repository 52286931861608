import { Clear } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  User,
  SupportTaskHistory,
  SupportTaskHistorySortParameter,
  TaskHistoriesQuery,
  useTaskHistoriesQuery,
  useTaskListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { UserId } from "Lib/Ids";
import { humanize } from "Lib/Utils";
import { Link } from "MDS/Link";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { PropertyTable } from "MDS/PropertyTable";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";

type TaskHistoryFilters = {
  className: string | null;
  userId: UserId | null;
};

export function TaskHistory(): ReactElement {
  const { t } = useTranslation();

  const [taskFilter, setTaskFilter] = React.useState<string>("");

  const filters: TaskHistoryFilters = {
    className: taskFilter,
    userId: null,
  };

  const columns: DataGridCols<TaskHistoriesQuery, ["supportTaskHistories"]> = React.useMemo(() => {
    return [
      {
        field: "className",
        headerName: "Task",
        flex: 1,
        sortable: false,
        valueGetter: (_value, row) => row.className,
      },
      {
        field: "user.name",
        headerName: "Run By",
        flex: 1,
        sortable: false,
        valueGetter: (_value, row) => row.user?.name,
      },
      {
        field: "startedAt",
        headerName: "Run At",
        flex: 1,
        sortable: true,
        valueGetter: (_value, row) => t("date.long", { date: row.startedAt }),
      },
      {
        field: "details",
        headerName: "",
        flex: 1,
        sortable: false,
        renderCell: (params) => <ShowTaskDetails task={params.row} />,
      },
    ];
  }, []);

  return (
    <Page
      browserTitle="Task History"
      breadcrumbs={[
        <Link to="../.." key={1}>
          Ops Tools
        </Link>,
        <Link to=".." key={2}>
          Task List
        </Link>,
      ]}
    >
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TaskSelector value={taskFilter} onChange={setTaskFilter} />
            </Grid>
            <Grid item xs={3}>
              {/* This is where a user filter would go, but there doesn't seem to be a reasonable way to select internal
                  users out of the graphql API at the moment. I can select all providers, but I can't filter them by
                  internal, and I can't select users directly at all. Rather than try to hack it, I'm just omitting
                  this until we actually need it. */}
            </Grid>
            <Grid item xs={12}>
              <SortablePagableCollectionDataGrid
                queryHook={useTaskHistoriesQuery}
                unwrapData={(response) => response?.supportTaskHistories || null}
                queryVariables={filters}
                colNameToSortParam={(column) =>
                  column == "startedAt" ? SupportTaskHistorySortParameter.STARTED_AT : null
                }
                defaultPageSize={10}
                columns={columns}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Page>
  );
}

type UserSummary = Pick<User, "__typename" | "id" | "name">;

type TaskHistoryRecord = Pick<
  SupportTaskHistory,
  "__typename" | "className" | "completedAt" | "startedAt" | "status" | "arguments"
> & { user: UserSummary | null };

function ShowTaskDetails(props: { task: TaskHistoryRecord }): ReactElement {
  const { t } = useTranslation();

  const [showModal, setShowModal] = React.useState<boolean>(false);

  return (
    <>
      <Button variant="outlined" onClick={() => setShowModal(true)}>
        Details
      </Button>
      <ResponsiveDialog
        onClose={() => setShowModal(false)}
        open={showModal}
        title={`${props.task.className} - ${t("date.long", { date: props.task.startedAt })}`}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <PropertyTable>
              <TableBody>
                <TableRow>
                  <TableCell>Task</TableCell>
                  <TableCell>{props.task.className}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Run By</TableCell>
                  <TableCell>{props.task.user?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Started At</TableCell>
                  <TableCell>{t("date.long", { date: props.task.startedAt })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Completed At</TableCell>
                  <TableCell>{t("date.long", { date: props.task.completedAt })}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>{humanize(props.task.status)}</TableCell>
                </TableRow>
              </TableBody>
            </PropertyTable>
            <Divider orientation="horizontal" />
            <Typography variant="body1">Arguments</Typography>
            <PropertyTable>
              <TableBody>
                {props.task.arguments?.map((argument, i) => {
                  // Some string inputs are single line (like a name) and some are multiline (like a SAML doc) but the
                  // task history system doesn't tell us the input type. Here I'm just guessing how we should format the
                  // output based on the value's content.
                  const multiline = argument.value?.includes("\n");
                  const whitespace = multiline ? "pre" : "normal";

                  return (
                    <TableRow key={i}>
                      <TableCell>{argument.name}</TableCell>
                      <TableCell style={{ whiteSpace: whitespace }}>{argument.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </PropertyTable>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}

function DropdownSkeleton(): ReactElement {
  return <Skeleton height={40} width="100%" />;
}

function TaskSelector(props: { value: string; onChange: (task: string) => void }): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useTaskListQuery());
  const onChange = (event: SelectChangeEvent<HTMLSelectElement>) =>
    props.onChange(event.target.value.toString());

  return remoteData.caseOf({
    NotAsked: () => <DropdownSkeleton />,
    Loading: () => <DropdownSkeleton />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (response.supportTasks) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Task</InputLabel>
              <Select fullWidth label="Task" value={props.value as unknown} onChange={onChange}>
                {response.supportTasks.map((task, i) => {
                  // I don't feel like changing the server to expose className on TaskHistoryType right now, so I'm just
                  // using the fact that I know `shortcode` is computed based on the underlying class name to reverse
                  // that computation

                  // The first letter of each word in the shortcode. First letters are preceded by either the start of
                  // the string, ^, or an underscore.
                  const firstLetterOfWord = /(^|_)(\w)/g;

                  // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_function_as_the_replacement
                  const initialUpcase = (_match: string, _prefix: string, initial: string) =>
                    initial.toUpperCase();

                  const className = task.shortcode.replaceAll(firstLetterOfWord, initialUpcase);
                  return (
                    <MenuItem value={className} key={i}>
                      {className}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <IconButton
              onClick={() => props.onChange("")}
              sx={{ visibility: props.value === "" ? "hidden" : "visible" }}
            >
              <Clear />
            </IconButton>
          </Stack>
        );
      } else {
        return <ErrorMessage message="No support tasks" />;
      }
    },
  });
}
