import { ApolloError } from "@apollo/client";
import { ContentCopy } from "@mui/icons-material";
import { Button, Skeleton } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  FeedbackReportProgressNoteTextQuery,
  useFeedbackReportProgressNoteTextQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { styled } from "@mui/material/styles";

export type FeedbackReportProgressNoteTextProps = {
  careEpisodeId: CareEpisodeId;
};

function FeedbackReportProgressNoteTextHookWrapper(props: FeedbackReportProgressNoteTextProps): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useFeedbackReportProgressNoteTextQuery({
      variables: {
        careEpisodeId: props.careEpisodeId,
      },
    })
  );

  return FeedbackReportProgressNoteText({ remoteData });
}

type FeedbackReportProgressNoteTextDataProps = {
  remoteData: RemoteData<ApolloError, FeedbackReportProgressNoteTextQuery>;
};

function LoadingIndicator(): ReactElement {
  return <Skeleton height={21} width={150} data-testid="loading-indicator" />;
}

// Not an actual <textarea>, but an area for text.
const FixedSizeTextArea = styled("div")(({ theme }) => ({
  maxWidth: theme.spacing(45),
  maxHeight: theme.spacing(35),
  overflowY: "scroll",
  whiteSpace: "pre-line",
}));

function CopyableProgressNote(props: { text: string }) {
  const { text } = props;

  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
  };

  const { t } = useTranslation(["report"]);
  const buttonText = copied ? t("report:progressNote.copied") : t("report:progressNote.copy");

  return (
    <div>
      <div style={{ marginBottom: "12px", marginLeft: "-4px" }}>
        <Button id="copy-note-button" onClick={copyToClipboard} startIcon={<ContentCopy />} size="small">
          {buttonText}
        </Button>
      </div>
      <FixedSizeTextArea>{text}</FixedSizeTextArea>
    </div>
  );
}

function FeedbackReportProgressNoteText(props: FeedbackReportProgressNoteTextDataProps): ReactElement {
  return props.remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      if (result.assessmentLatestReportText) {
        return <CopyableProgressNote text={result.assessmentLatestReportText.plainText} />;
      } else {
        return <>t("report:noProgressNote")</>;
      }
    },
  });
}

export default FeedbackReportProgressNoteTextHookWrapper;
export {
  FeedbackReportProgressNoteTextHookWrapper as HookWrapper,
  FeedbackReportProgressNoteText as Component,
};
