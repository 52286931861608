/*===================================================================================
 * Graphql endpoints. Used for almost everything except unauthenticated bootstrapping
 *
 * == Conventions
 * The new codegen style automatically adds e.g. `Fragment` to fragments. Some older
 * fragments therefore maybe be doubled to `ScaleFragmentFragment` for now while we
 * transition, but new fragments should be named without the word `Fragment` in them.
 * Ditto queries, mutations, etc.
 *
 * == Typing
 * The graphql codegen generates a single root type for each query, with `Picks` of the
 * appropriate base types. When you wish to break up parsing or logic into smaller functions
 * you have three choices:
 *  * Use a fragment, and then use the fragment directly as an input into your function
 *  * Parse the object out into a special case. We already have common things like scales mapped
 *    this way.
 *  * Use type digging to get the nested selections you requested (see below)
 *
 * == Type digging
 *
 * Digging into types works like digging into hashes:
 *
 *     type X = Dig<{ a: string }, ['a']> // => string
 *
 * There are two complications.
 *
 * === Digging in Unions
 *
 * If you have a graphql union, e.g. `Person = Patient | Provider`, and you wish
 * to reference something in one of the types, you will need to use `DiscriminateUnion` to get
 * the type out, e.g.:
 *
 *     type Person = { __typename: "Patient", mrn: string } | { __typename: "Provider" }
 *     type Query = {
 *       result: Array<{
 *         __typename: "PatientSession",
 *         participant: Person
 *       }>
 *
 * In order to get the type of 'Patients with the mrn string', you cannot just do:
 *
 *     //Doesn't work
 *     Dig<Query, ["result", "participant", "mrn"]>
 *
 * Instead you need to break it into stages
 *
 *     type Participant = Dig<Query, ["result", "participant"]>
 *     type Patient = DiscriminateUnion<Participant, "Patient">
 *     type Mrn = Dig<Patient, ["mrn"]>
 *
 * === Digging for arrays or unpacked types
 *
 * In the example above, if you dig for "result", you will get:
 *
 *   type ResultArray = Dig<Query, ["result"]> = Array<{...}>
 *
 * If instead, you want the actual result type, unboxed from the array, you can either do:
 *
 *   type Item = Unpacked<Dig<Query, ["result"]>> = {__typename: "PatientSession"...}
 *   type Item = DigUnpacked<Query, ["result"]> // Same thing, single convenience method
 */

import {
  InMemoryCache,
  ApolloClient,
  OperationVariables,
  QueryResult,
  ApolloError,
  MutationResult,
  HttpLink,
} from "@apollo/client";
import * as AppSession from "AppSession/AppSession";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import IntrospectionResult from "GeneratedGraphQL/IntrospectionResult.json";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import { parseISO } from "date-fns";
import { FunctionsMap, withScalars } from "apollo-link-scalars";
import { UserError } from "GeneratedGraphQL/SchemaAndOperations";
import { Failure, Loading, RemoteData, Success, NotAsked } from "seidr/";
import SumType from "sums-up";
import { getImpersonatingUserHeader } from "AppSession/ImpersonatingUser";
import { getPiiLevelHeader } from "Contexts/PiiLevelContext";
import { getActiveInstituteGroupHeader } from "Contexts/ActiveInstituteGroupContext";

function formatAuthenticationToken(): string {
  return AppSession.getAppSession().caseOf({
    Authenticated: (_appContext, user) => {
      return `Token authentication_token="${user.authenticationToken}", user_type="${user.userType}", id="${user.id}"`;
    },
    _: () => "",
  });
}

// We want to be able to see the op name in the developer mode, so we append it to the request.
const httpLink = new HttpLink({
  fetch: (_uri, options) => {
    const { operationName } = options?.body ? JSON.parse(options.body.toString()) : "unknown";
    return fetch(`/graphql?op=${operationName}`, options);
  },
});

const authLink = setContext((_, context) => {
  const headers = context.headers;
  return {
    headers: {
      ...headers,
      ...getImpersonatingUserHeader(),
      ...getPiiLevelHeader(),
      ...getActiveInstituteGroupHeader(context.instituteLeader as boolean | undefined),
      Authorization: formatAuthenticationToken(),
    },
  };
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && "response" in networkError) {
    if (networkError.response.status === 498 || networkError.response.status === 499) {
      // If we get a logged out error during requests, assume we want to start the re-login semantics
      AppSession.logout("retry");
    }
  }
});

const typesMap: FunctionsMap = {};

// Add in specific types:
typesMap.ISO8601Date = {
  serialize: (parsed: Date) => {
    const month = parsed.toLocaleDateString("en-US", { month: "2-digit" });
    const day = parsed.toLocaleDateString("en-US", { day: "2-digit" });
    const year = parsed.getFullYear();
    return `${year}-${month}-${day}`;
  },
  parseValue: (raw: string | null): Date | null => {
    return raw ? parseISO(raw) : null;
  },
};

typesMap.ISO8601DateTime = {
  serialize: (parsed: Date) => parsed.toISOString(),
  parseValue: (raw: string | null): Date | null => {
    return raw ? parseISO(raw) : null;
  },
};

const scalarsLink = withScalars({
  schema: buildClientSchema(
    // Casting to IntrospectionQuery is apparently a sanctioned way of dealing
    // with this. See graphql-code-generator section of
    // https://github.com/eturino/apollo-link-scalars#example-of-loading-a-schema
    IntrospectionResult as unknown as IntrospectionQuery
  ),
  typesMap,
});

// TODO: Giving it an empty IntrospectionFragmentMatcher seems to work for
// unions and fragments, but really we want to full solution from:
// https://www.apollographql.com/docs/react/data/fragments/#fragments-on-unions-and-interfaces
const cache = new InMemoryCache({
  // In certain circumstances, the graphql cache gets confused about what fragments it has
  // for union types, and will overwrite/merge cache data incorrectly, resulting in runtime
  // errors where fields missing from the response, having been delivered by the server
  possibleTypes: {
    Scale: ["AdministrableScale", "Subscale"],
    Participant: ["Provider", "Patient", "RelatedPerson"],
    ScaleScorer: ["CategoricalScaleScorer", "NumericalScaleScorer", "UnscoredScaleScorer"],
  },
  typePolicies: {
    MyActiveTimeEntryLog: {
      // Singleton types that have no identifying field can use an empty
      // array for their keyFields.
      keyFields: [],
    },
  },
});
const client = new ApolloClient({
  cache,
  link: authLink.concat(logoutLink).concat(scalarsLink).concat(httpLink),
});

/**
 * You should call this function when you want to refetch all active queries. If you simply wish to clear
 * the cache and logout, call clearCacheForLogout instead.
 */
function clearCache() {
  client.resetStore();
}

/**
 * You should call this function whenever a user logs out to ensure that all
 * data is removed.
 * This will not refetch queries. Use clearCache if you want to refresh all the queries.
 */
function clearCacheForLogout() {
  client.clearStore();
}

type MirahMutationResultData<TResult> = {
  success: boolean;
  errors: ReadonlyArray<UserError>;
  result: TResult;
};

export type MutationRemoteDataResult<T> = RemoteData<MirahRemoteError<T>, T>;

class MirahRemoteError<TData> extends SumType<{
  apolloError: [ApolloError];
  userError: [ReadonlyArray<UserError>, TData | null];
}> {
  public static ApolloError = (error: ApolloError) => new MirahRemoteError("apolloError", error);
  public static UserError = <T>(errors: ReadonlyArray<UserError>, result: T | null) =>
    new MirahRemoteError<T>("userError", errors, result);

  public toError(): Error {
    return this.caseOf({
      apolloError: (error) => {
        return error;
      },
      userError: (errors) => {
        const message = errors.map((error) => error.message).join(",");
        return new Error(message);
      },
    });
  }
}

function apolloMutationHookWrapper<TResult, TMutateFunction, TData>(
  mutationResultFetcher: (data: TData) => MirahMutationResultData<TResult> | null,
  mutationTuple: [TMutateFunction, MutationResult<TData>]
): [
  TMutateFunction,
  {
    remoteData: MutationRemoteDataResult<TResult>;
  } & MutationResult
] {
  const [mutateFunction, mutationResult] = mutationTuple;

  return [
    mutateFunction,
    {
      ...mutationResult,
      remoteData: apolloMutationToRemoteData(mutationResult, mutationResultFetcher),
    },
  ];
}

export type ApolloMutationRemoteData<T> = RemoteData<MirahRemoteError<T>, T>;

// using apolloMutationToRemoteData will convert an apollo response object
// into a RemoteData object which has strictly defined sumtype of statuses
function apolloMutationToRemoteData<TResult, TData>(
  mutationResult: MutationResult<TData>,
  mutationResultFetcher: (data: TData) => MirahMutationResultData<TResult> | null
): ApolloMutationRemoteData<TResult> {
  if (!mutationResult.called) {
    return NotAsked();
  }
  if (mutationResult.loading) {
    return Loading();
  }
  if (mutationResult.error) {
    return Failure(MirahRemoteError.ApolloError(mutationResult.error));
  }

  if (mutationResult.data === undefined) {
    return Failure(
      MirahRemoteError.ApolloError(
        new ApolloError({
          errorMessage: "There was no data returned, this may be the result of a bad query.",
        })
      )
    );
  }
  if (mutationResult.data) {
    const resultData = mutationResultFetcher(mutationResult.data);

    if (resultData) {
      if (resultData.success) {
        return Success(resultData.result);
      } else {
        return Failure(MirahRemoteError.UserError<TResult>(resultData.errors, resultData.result));
      }
    }
    return Failure(
      MirahRemoteError.ApolloError(
        new ApolloError({
          errorMessage: "There was no data returned, this may be the result of a bad query.",
        })
      )
    );
  }

  return Failure(
    MirahRemoteError.ApolloError(new ApolloError({ errorMessage: "This state should not be reachable" }))
  );
}

export function remoteErrorMessage<T>(error: MirahRemoteError<T>): string {
  return error.caseOf({
    apolloError: (apolloError) => {
      return apolloError.message;
    },
    userError: (userError) => {
      return userError.map((item) => item.message).join(", ");
    },
  });
}

function apolloErrorToError(error: ApolloError): Error {
  return error;
}

function apolloQueryHookWrapper<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>
): {
  remoteData: RemoteData<ApolloError, TData>;
} & QueryResult<TData, TVariables> {
  return { ...queryResult, remoteData: apolloQueryToRemoteData(queryResult) };
}

export type ApolloQueryRemoteData<T> = RemoteData<ApolloError, T>;

// Important note about this function - the three fields we look at here - data, loading and error, are not wholly
// disjoint. Depending on the fetch policy you use (https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies)
// a query might have both loading and data set, for example if you're using `cache-and-network` the result will
// populate data immediately from the cache, but also immediately set loading to true as it sends a request, then update
// data again and set loading to false when the request comes back. The current implementation prioritizes acting like
// the query has returned as long as we have any data available, whether or not it might get subsequently updated.
function apolloQueryToRemoteData<TData, TVariables extends OperationVariables>(
  queryResult: QueryResult<TData, TVariables>
): ApolloQueryRemoteData<TData> {
  if (queryResult.data) {
    return Success(queryResult.data);
  }
  if (queryResult.loading) {
    return Loading();
  }
  if (queryResult.error) {
    return Failure(queryResult.error);
  }

  return NotAsked();
}

export {
  cache,
  clearCache,
  clearCacheForLogout,
  client,
  apolloQueryHookWrapper,
  apolloMutationHookWrapper,
  apolloErrorToError,
  MirahRemoteError,
  apolloQueryToRemoteData,
};
