import React from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  dataSourceRecordAlgorithmT,
  dataSourceRecordLogErrorDescriptionT,
  dataSourceRecordLogErrorT,
  dataSourceRecordLogStatusDescriptionT,
  dataSourceRecordLogStatusT,
} from "GeneratedGraphQL/EnumTranslations";
import {
  DataSourceRecordAlgorithm,
  DataSourceRecordAlgorithmLog,
  DataSourceRecordLog,
  DataSourceRecordLogMatchStatus,
  DataSourceRecordLogSortParameter,
  DataSourceRecordLogStatus,
  DataSourceRecordLogTransformError,
  DataSourceResourceType,
  ImportHistory,
  IntegrationDataSourceRecordLogHistoryQuery,
  IntegrationStatusLogQuery,
  useIntegrationDataSourceRecordLogHistoryQuery,
  useIntegrationStatusLogQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ImportHistoryId } from "Lib/Ids";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import ErrorMessage from "Shared/ErrorMessage";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import Spinner from "Shared/Spinner";
import { useTranslation } from "react-i18next";
import Link from "MDS/Link";

export type DataSourceRecordAlgorithmLogDetails = Pick<
  DataSourceRecordAlgorithmLog,
  "id" | "algorithm" | "algorithmCode" | "statusDescription" | "statusName"
>;

// TODO: this is currently unused until we decide if we want to show this data.
export function IntegrationDataSourceRecordLogHistory(props: { importHistoryId: ImportHistoryId }) {
  const { t } = useTranslation(["integrations", "common", "enums"]);
  const columns: DataGridCols<
    IntegrationDataSourceRecordLogHistoryQuery,
    ["integrationsDataSourceRecordLogs"]
  > = React.useMemo(() => {
    return [
      {
        field: "date",
        headerName: t("integrations:integrationLog.logs.date"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return t("common:date.tinyWithTime", { date: params.row.createdAt });
        },
      },
      {
        field: "status",
        headerName: t("integrations:integrationLog.logs.status"),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          const status = dataSourceRecordLogStatusT(params.row.status, t);
          const description = dataSourceRecordLogStatusDescriptionT(params.row.status, t);
          return (
            <Tooltip title={description}>
              <Typography>{status}</Typography>
            </Tooltip>
          );
        },
      },
    ];
  }, []);
  return (
    <>
      <SortablePagableCollectionDataGrid
        queryHook={useIntegrationDataSourceRecordLogHistoryQuery}
        queryVariables={{ importHistoryId: props.importHistoryId }}
        unwrapData={(response) => response?.integrationsDataSourceRecordLogs || null}
        colNameToSortParam={(_field) => DataSourceRecordLogSortParameter.CREATED_AT}
        columns={columns}
        defaultPageSize={10}
        autoHeight
      />
      <Typography>{t("integrations:integrationLog.history.warning")}</Typography>
    </>
  );
}

const alertMap: Record<DataSourceRecordLogStatus, AlertColor> = {
  AWAITING_PROCESSING: "info",
  CREATED: "success",
  ID_ERROR: "error",
  INVALID: "error",
  LOAD_ERROR: "error",
  PROCESSING: "info",
  SKIPPED: "warning",
  STALE: "warning",
  SYSTEM_ERROR: "error",
  UNCHANGED: "success",
  UNKNOWN_ERROR: "error",
  UPDATED: "success",
};

function IntegrationLastLogStatus(props: { status: DataSourceRecordLogStatus; createdAt: Date }) {
  const { t } = useTranslation(["integrations", "common", "enums"]);

  const status = dataSourceRecordLogStatusT(props.status, t);
  const description = dataSourceRecordLogStatusDescriptionT(props.status, t);

  // TODO: the alert title doesn't seem to displayed right.
  return (
    <Alert severity={alertMap[props.status]}>
      <AlertTitle>
        {status} - {t("common:date.tinyWithTime", { date: props.createdAt })}
      </AlertTitle>
      {description}
    </Alert>
  );
}

function DataSourceRecordLogTransformErrors(props: {
  errors: ReadonlyArray<Pick<DataSourceRecordLogTransformError, "errors" | "field">>;
}) {
  const { t } = useTranslation(["integrations", "enums"]);

  if (props.errors.length > 0) {
    const items = props.errors.map((errorField) => {
      const fieldErrorItems = errorField.errors.map((error) => {
        const title = dataSourceRecordLogErrorT(error, t);
        const description = dataSourceRecordLogErrorDescriptionT(error, t);

        return (
          <li key={errorField.field}>
            <b>{errorField.field}</b>:{" "}
            <Tooltip title={description}>
              <Typography>{title}</Typography>
            </Tooltip>
          </li>
        );
      });
      return fieldErrorItems;
    });
    return (
      <Alert severity="warning">
        {t("integrations:integrationLog.lastLog.errorsPresent")}
        <ul>{items}</ul>
      </Alert>
    );
  } else {
    return <Alert severity="success">{t("integrations:integrationLog.lastLog.noErrors")}</Alert>;
  }
}

function matchResourceKey(resourceType: DataSourceResourceType) {
  switch (resourceType) {
    case DataSourceResourceType.PATIENT:
      return "PATIENT";
    default:
      return "generic";
  }
}

function DataSourceRecordLogMatchStatusDetails(props: {
  matchStatus: DataSourceRecordLogMatchStatus | null;
  resourceType: DataSourceResourceType;
}) {
  const { t } = useTranslation(["integrations", "enums"]);

  if (!props.matchStatus) {
    // Don't show anything if the matcher didn't run.
    return null;
  }

  return (
    <Alert severity="info">
      {t(`integrations:matches.${matchResourceKey(props.resourceType)}.${props.matchStatus}`)}
    </Alert>
  );
}

// Always ignore these algorithms, never show them to the user.
const ignoredAlgorithms = [
  DataSourceRecordAlgorithm.APPOINTMENT_CREATE_ATHENA_ENCOUNTER,
  DataSourceRecordAlgorithm.APPOINTMENT_DEFAULT_PROVIDER,
];

// Ignore these algorithms when they have certain statuses. For example, no point in displaying 'got the insurance information from patient' if
// they're not using that feature.
const ignoredAlgorithmStatusMap: Partial<Record<DataSourceRecordAlgorithm, ReadonlyArray<string>>> = {
  [DataSourceRecordAlgorithm.PATIENT_COVERAGE]: ["feature_disabled", "no_info"],
};

function DataSourceRecordLogAlgorithms(props: {
  algorithms: ReadonlyArray<DataSourceRecordAlgorithmLogDetails>;
}) {
  const { t } = useTranslation(["integrations", "enums"]);

  const filteredAlgorithms = props.algorithms.filter(
    (algorithm) =>
      !ignoredAlgorithms.includes(algorithm.algorithm) &&
      !ignoredAlgorithmStatusMap[algorithm.algorithm]?.includes(algorithm.statusName)
  );

  if (filteredAlgorithms.length > 0) {
    const items = filteredAlgorithms.map((algorithm) => {
      return (
        <li key={algorithm.id.toString()}>
          <b>{dataSourceRecordAlgorithmT(algorithm.algorithm, t)}</b>: {algorithm.statusDescription}
        </li>
      );
    });
    return (
      <Alert severity="info">
        {t("integrations:integrationLog.algorithm.algorithmsRun")}
        <ul>{items}</ul>
      </Alert>
    );
  } else {
    return <Alert severity="info">{t("integrations:integrationLog.algorithm.noAlgorithms")}</Alert>;
  }
}

export function IntegrationStatusLastLog(props: {
  resourceType: DataSourceResourceType;
  log:
    | (Pick<DataSourceRecordLog, "createdAt" | "status" | "transformErrors" | "raw" | "matchStatus"> & {
        dataSourceRecordAlgorithmLogs: ReadonlyArray<DataSourceRecordAlgorithmLogDetails>;
      })
    | null
    | undefined;
}) {
  const { t } = useTranslation(["integrations", "common"]);

  if (!props.log) {
    return t("integrations:integrationLog.lastLog.noLog");
  }

  return (
    <>
      <IntegrationLastLogStatus status={props.log.status} createdAt={props.log.createdAt} />
      <DataSourceRecordLogTransformErrors errors={props.log.transformErrors || []} />
      <DataSourceRecordLogMatchStatusDetails
        matchStatus={props.log.matchStatus}
        resourceType={props.resourceType}
      />
      <DataSourceRecordLogAlgorithms algorithms={props.log.dataSourceRecordAlgorithmLogs} />
    </>
  );
}

function IntegrationStatusLogInternal(props: {
  importHistory: NonNullable<IntegrationStatusLogQuery["integrationsImportHistory"]>;
}) {
  const { t } = useTranslation(["integrations", "common"]);
  const { importHistory } = props;
  const alertText = importHistory.lastImportDate
    ? t("integrations:integrationLog.ehrCreated", { date: importHistory.lastImportDate })
    : t("integrations:integrationLog.ehrCreatedNoDate");

  const records = importHistory.integrationsDataSourceRecords.map((record) => {
    return (
      <TableRow>
        <TableCell>{record.remoteId}</TableCell>
        <TableCell>{record.dataSourceResource.dataSource.name}</TableCell>
        <TableCell>
          {record.effectiveSince
            ? t("common:date.tinyWithTime", { date: record.effectiveSince })
            : t("integrations:integrationLog.neverImported")}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <Stack spacing={1}>
      <Alert severity="info" icon={<CloudSyncIcon />}>
        {alertText}
      </Alert>
      <Box>
        <Typography variant="h2">{t("integrations:integrationLog.records.title")}</Typography>
        <Typography>{t("integrations:integrationLog.records.explanation")}</Typography>
        <Table>
          <TableHead>
            <TableCell>{t("integrations:integrationLog.records.remoteId")}</TableCell>
            <TableCell>{t("integrations:integrationLog.records.sourceName")}</TableCell>
            <TableCell>{t("integrations:integrationLog.records.lastImportDate")}</TableCell>
          </TableHead>
          <TableBody>{records}</TableBody>
        </Table>
      </Box>
      <Box>
        <Typography variant="h2">{t("integrations:integrationLog.lastLog.title")}</Typography>
        <IntegrationStatusLastLog
          log={props.importHistory.integrationsDataSourceRecordLogs.nodes[0]}
          resourceType={props.importHistory.resourceType}
        />
      </Box>
      <Box>
        <Link
          to={`/app/integrations/import-history/${props.importHistory.resourceType}/${props.importHistory.id}`}
        >
          {t("integrations:integrationLog.seeHistory")}
        </Link>
      </Box>
    </Stack>
  );
}

export function IntegrationStatusLogContent(props: {
  importHistoryId: ImportHistoryId;
  resourceType: DataSourceResourceType;
}) {
  const { t } = useTranslation(["integrations", "common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationStatusLogQuery({
      variables: { id: props.importHistoryId, resourceType: props.resourceType },
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.integrationsImportHistory) {
        return <IntegrationStatusLogInternal importHistory={data.integrationsImportHistory} />;
      } else {
        return <Typography>{t("common:notFound")}</Typography>;
      }
    },
    Loading: () => <Spinner />,
    NotAsked: () => <Spinner />,
    Failure: (error) => {
      return <ErrorMessage message={error.message} />;
    },
  });
}

export default function IntegrationStatusLogDialog(props: {
  importHistory: Pick<ImportHistory, "id" | "name">;
  resourceType: DataSourceResourceType;
  show: boolean;
  setShow: (show: boolean) => void;
}) {
  const { t } = useTranslation(["integrations"]);

  return (
    <ResponsiveDialog
      dialogWidth="50%"
      open={props.show}
      onClose={() => props.setShow(false)}
      title={t("integrations:integrationLog.title", {
        name: props.importHistory.name,
      })}
    >
      <DialogContent>
        <IntegrationStatusLogContent
          importHistoryId={props.importHistory.id}
          resourceType={props.resourceType}
        />
      </DialogContent>
    </ResponsiveDialog>
  );
}
