import { Skeleton, Stack, TableBody, TableCell, TableRow, Link as MUILink, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { SupportTask, useTaskListQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import ErrorMessage from "Shared/ErrorMessage";
import { groupBy } from "ramda";
import { Section } from "CollaborativeCare/CaseConsult/Section";
import { ShadedTable } from "MDS/ShadedTable";

export function TaskList(): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(useTaskListQuery());

  const content = remoteData.caseOf({
    NotAsked: () => <TaskListSkeleton />,
    Loading: () => <TaskListSkeleton />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => <TaskListContent tasks={response.supportTasks || []} />,
  });

  return (
    <Page
      browserTitle="Mirah Task Runner"
      breadcrumbs={[
        <Link to="../.." key={0}>
          Ops Tools
        </Link>,
      ]}
    >
      {content}
    </Page>
  );
}

function TaskListSkeleton(): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <Skeleton width={40} />
      <Skeleton width={40} />
      <Skeleton width={40} />
    </Stack>
  );
}

type TaskSummary = Pick<
  SupportTask,
  "__typename" | "shortcode" | "name" | "description" | "category" | "subCategory"
>;
type TaskListContentProps = {
  tasks: ReadonlyArray<TaskSummary>;
};

const DEFAULT_CATEGORY = "Uncategorized";

function TaskListContent(props: TaskListContentProps): ReactElement {
  const grouped = groupBy((task) => task.category || DEFAULT_CATEGORY, props.tasks);
  const categories = Object.keys(grouped);
  categories.sort();

  return (
    <>
      <Stack direction="column" spacing={1}>
        <ul>
          {categories.map((category, i) => (
            <TaskCategoryLink title={category} tasks={grouped[category] || []} key={i} />
          ))}
        </ul>
      </Stack>
      <Stack direction="column" spacing={1}>
        {categories.map((category, i) => (
          <TaskCategory title={category} tasks={grouped[category] || []} key={i} />
        ))}
      </Stack>
    </>
  );
}

function TaskCategoryLink(props: TaskCategoryProps): ReactElement {
  const grouped = groupBy((task) => task.subCategory || DEFAULT_CATEGORY, props.tasks);
  const categories = Object.keys(grouped);
  categories.sort((a, b) => (a < b ? -1 : 1));

  //if the only sub category is "Uncategorized", don't bother rendering sub sections
  const renderSection = ![DEFAULT_CATEGORY].every((element, index) => element === categories[index]);

  return (
    <>
      <li>
        <MUILink href={"#" + props.title}>{props.title}</MUILink>
        <ul>
          {categories.map((subCategory) => {
            if (renderSection) {
              return (
                <li>
                  <MUILink href={"#" + subCategory}>{subCategory}</MUILink>
                </li>
              );
            } else {
              return <></>;
            }
          })}
        </ul>
      </li>
    </>
  );
}

type TaskCategoryProps = {
  title: string;
  tasks: ReadonlyArray<TaskSummary>;
};

function TaskCategory(props: TaskCategoryProps): ReactElement {
  const grouped = groupBy((task) => task.subCategory || DEFAULT_CATEGORY, props.tasks);
  const categories = Object.keys(grouped);
  categories.sort((a, b) => (a < b ? -1 : 1));

  //if the only sub category is "Uncategorized", don't bother rendering sub sections
  const renderSection = ![DEFAULT_CATEGORY].every((element, index) => element === categories[index]);

  return (
    <Section title={props.title}>
      {categories.map((category, i) => (
        <TaskSubCategory
          title={category}
          tasks={grouped[category] || []}
          key={i}
          renderSection={renderSection}
        />
      ))}
    </Section>
  );
}

type TaskSubCategoryProps = {
  title: string;
  tasks: ReadonlyArray<TaskSummary>;
  renderSection?: boolean;
};

function TaskSubCategory(props: TaskSubCategoryProps): ReactElement {
  const sortedTasks = [...props.tasks];
  sortedTasks.sort((a, b) => (a.name < b.name ? -1 : 1));
  const content = (
    <ShadedTable sx={{ tableLayout: "auto" }}>
      <TableBody>
        {sortedTasks.map((task, i) => (
          <TableRow key={i}>
            <TableCell sx={{ minWidth: "25rem" }}>
              <Link to={`${task.shortcode}/run`}>{task.name}</Link>
            </TableCell>
            <TableCell width="100%">
              <Typography maxWidth="50rem">{task.description}</Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </ShadedTable>
  );
  if (props.renderSection) {
    return (
      <Section title={props.title} titleFontSize="1.5em">
        {content}
      </Section>
    );
  } else {
    return content;
  }
}
