import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { careEpisodeTreatmentTrackStatusT } from "GeneratedGraphQL/EnumTranslations";
import {
  CareEpisodeTreatmentTrack,
  CareEpisodeTreatmentTrackStatus,
  TreatmentTrack,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";
import InfoSidebarCard from "Shared/InfoSidebarCard";
import { WithPermission } from "Shared/WithPermission";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { EditCareEpisodeTreatmentTracksButton } from "./EditCareEpisodeTreatmentTracksButton";

type CareEpisodeTreatmentTracksCardProps = {
  careEpisodeId: CareEpisodeId;
  careEpisodeTreatmentTracks: ReadonlyArray<
    Pick<CareEpisodeTreatmentTrack, "id" | "status"> & { treatmentTrack: Pick<TreatmentTrack, "name"> }
  >;
};

export default function CareEpisodeTreatmentTracksCard(
  props: CareEpisodeTreatmentTracksCardProps
): ReactElement {
  const { t } = useTranslation(["careEpisodes", "enums"]);

  const cardAction = (
    <WithPermission permission="editCareEpisodeTreatmentTracks">
      <Box marginRight="0.3em">
        {<EditCareEpisodeTreatmentTracksButton careEpisodeId={props.careEpisodeId} />}
      </Box>
    </WithPermission>
  );

  const tracks = props.careEpisodeTreatmentTracks.filter(
    (cett) => cett.status !== CareEpisodeTreatmentTrackStatus.ENTERED_IN_ERROR
  );

  const rows =
    tracks.length > 0 ? (
      tracks.map((cett) => {
        return (
          <TableRow key={cett.id.toString()}>
            <TableCell>{cett.treatmentTrack.name}</TableCell>
            <TableCell>{careEpisodeTreatmentTrackStatusT(cett.status, t)}</TableCell>
          </TableRow>
        );
      })
    ) : (
      <TableRow key="empty">
        <TableCell colSpan={2}>{t("careEpisodes:treatmentTracks.noTracks")}</TableCell>
      </TableRow>
    );

  return (
    <InfoSidebarCard action={cardAction} title={t("careEpisodes:cards.treatmentTracks")}>
      <Table>
        <TableBody>{rows}</TableBody>
      </Table>
    </InfoSidebarCard>
  );
}
