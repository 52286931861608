import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Trend } from "GeneratedGraphQL/SchemaAndOperations";
import { fromArray, head, last } from "Lib/NonEmptyList";
import * as Staleness from "Lib/Staleness";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { SeverityChip } from "Shared/Scale/SeverityChip";
import { TrendIndicatorWithNameWithoutTooltip } from "Shared/Scale/TrendIndicator";

const TrendInfoTable = styled("table")(() => ({
  textAlign: "center",
  display: "inline-block",
}));

type TrendInfoProps = {
  history: ScaleScorerHistory;
  scoreDate: Date;
};

export function ScaleScoreTrendInfo(props: TrendInfoProps): ReactElement | null {
  const { history, scoreDate } = props;
  const { t } = useTranslation(["common", "report"]);

  return fromArray(history.scores).caseOf({
    Nothing: () => null,
    Just: (scores) => {
      const administration = scores.toArray().find((score) => score.date === scoreDate);
      if (!administration) {
        return null;
      }
      const firstValue = head(scores);
      const lastDate = last(scores).date;

      // Because administration isn't null, we know there's at least one entry in
      // history.scores, so neither of these will be null either.

      // We won't display unsupported trends.
      const unsupportedTrend = administration.trend === Trend.NOT_SUPPORTED;
      const trendHeader = unsupportedTrend ? null : t("report:trend.trend");
      const trendBody = unsupportedTrend ? null : (
        <TrendIndicatorWithNameWithoutTooltip trend={administration.trend} />
      );

      return (
        <TrendInfoTable>
          <thead>
            <tr>
              <th>{t("report:trend.firstScore")}</th>
              <th>{trendHeader}</th>
              <th>{t("report:trend.thisScore")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <SeverityChip value={firstValue} isStale={Staleness.isStale(lastDate, firstValue)} />
              </td>
              <td style={{ padding: "0 2rem" }}>{trendBody}</td>
              <td>
                <SeverityChip value={administration} isStale={Staleness.isStale(lastDate, administration)} />
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>{t("date.medium", { date: firstValue.date })}</td>
              <td></td>
              <td>{t("date.medium", { date: administration.date })}</td>
            </tr>
          </tfoot>
        </TrendInfoTable>
      );
    },
  });
}

type TrendInfoStackProps = {
  histories: ReadonlyArray<ScaleScorerHistory>;
  scoreDate: Date;
};

export function ScaleScoreTrendInfoStack(props: TrendInfoStackProps): ReactElement {
  return (
    <Stack direction="column" spacing={3}>
      {props.histories.map((history, i) => {
        return <ScaleScoreTrendInfo history={history} scoreDate={props.scoreDate} key={i} />;
      })}
    </Stack>
  );
}
