import { DialogActions, DialogContent, Skeleton, Stack, TextField } from "@mui/material";
import { MutationRemoteDataResult } from "Api/GraphQL";
import { InstituteType } from "GeneratedGraphQL/SchemaAndOperations";
import { InstituteId } from "Lib/Ids";
import { assertNonNull } from "Lib/Utils";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import InstituteSelect from "Shared/Filters/InstituteSelect";
import { FormOverlay, Form as SharedForm, useForm, useTextField, useWrappedField } from "Shared/Form";
import { range } from "d3";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function DemographicsInputLoading(): ReactElement {
  return (
    <Stack spacing={1}>
      {range(2).map((i) => (
        <Skeleton key={i} />
      ))}
    </Stack>
  );
}

type InstituteGroupDetails = {
  name: string;
  leaderId: InstituteId;
};

type InstituteGroupDetailsDefaults = Partial<InstituteGroupDetails>;

type FormProps<ResponseType> = {
  defaults: InstituteGroupDetailsDefaults;
  onSuccess?: (response: ResponseType) => void;
  remoteData: MutationRemoteDataResult<ResponseType>;
  mutation: (fields: InstituteGroupDetails) => void;
  errorMessage: string;
};

export function InstituteGroupForm<ResponseType>(props: FormProps<ResponseType>): ReactElement {
  const { t } = useTranslation(["instituteGroups", "common"]);

  const fields = {
    name: useTextField({
      default: props.defaults.name || undefined,
      required: true,
    }),
    leaderId: useWrappedField<InstituteId>({
      default: props.defaults.leaderId,
      required: true,
    }),
  };

  const form = useForm({
    id: "edit-institute-group",
    fields: fields,
    onSuccess: props.onSuccess,
    remoteData: props.remoteData,
    submit: () => {
      props.mutation({
        name: assertNonNull(fields.name.value),
        leaderId: assertNonNull(fields.leaderId.value),
      });
    },
  });

  return (
    <>
      <DialogContent>
        <SharedForm onSubmit={form.onSubmit} id={form.id}>
          <FormOverlay response={props.remoteData} errorMessage={form.globalError || props.errorMessage} />
          <Stack direction="column" spacing={1} sx={{ paddingTop: "1rem" }}>
            <TextField
              label={t("instituteGroups:form.name")}
              value={fields.name.value}
              error={fields.name.error}
              helperText={fields.name.helperText}
              onChange={fields.name.onChange}
            />
            <InstituteSelect
              setValue={fields.leaderId.onChange}
              value={fields.leaderId.value ?? null}
              types={[InstituteType.GROUP_LEADER, InstituteType.INTERNAL]}
            />
          </Stack>
        </SharedForm>
      </DialogContent>
      <DialogActions>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          type="submit"
          form={form.id}
          showSpinner={form.showSpinner}
          disabled={form.disableSubmit}
        >
          {t("common:actions.save")}
        </ButtonWithSpinner>
      </DialogActions>
    </>
  );
}
