import React from "react";
import { Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CareEpisodeId } from "Lib/Ids";
import { resultToRemoteData } from "Lib/Utils";
import {
  useOverviewConstructsQueryQuery,
  useOverviewGoalsQueryQuery,
  useOverviewTreatmentResponseQueryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { RemoteData } from "seidr";
import { ConstructSection, SpecialSection } from "Shared/Scale/Constructs";
import * as Goal from "Shared/Scale/Goal";
import { ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { LevelHeader } from "FeedbackReport/LevelHeader";
import LevelDetail from "FeedbackReport/LevelDetail";
import CompareGraphs from "./Compare/CompareGraphs";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { parseOverviewTreatmentResponseQuery } from "FeedbackReport/DetailPane/DataStore/OverviewTreatmentResponseTransforms";
import { parseOverviewGoals } from "FeedbackReport/DetailPane/DataStore/OverviewGoalsTransforms";
import {
  parseOverviewConstructs,
  parseOverviewSpecialSections,
} from "FeedbackReport/DetailPane/DataStore/OverviewConstructsTransforms";
import { OverviewContent } from "./OverviewContent";

type OverviewPaneProps = {
  careEpisodeId: CareEpisodeId;
  feedbackReportContext: FeedbackReportContext;
};

const OverviewPaneHookWrapper = (props: OverviewPaneProps) => {
  const { careEpisodeId, feedbackReportContext } = props;
  const treatmentResponse = loadTreatmentResponse(careEpisodeId, feedbackReportContext);
  const goals = loadGoals(careEpisodeId, feedbackReportContext);
  const [specialSections, constructs] = loadConstructs(careEpisodeId, feedbackReportContext);

  return (
    <OverviewPane
      treatmentResponse={treatmentResponse}
      goals={goals}
      constructs={constructs}
      specialSections={specialSections}
      feedbackReportContext={feedbackReportContext}
    />
  );
};

export function loadTreatmentResponse(
  careEpisodeId: CareEpisodeId,
  feedbackReportContext: FeedbackReportContext
): RemoteData<Error, ReadonlyArray<ScaleSectionSummary>> {
  const { remoteData } = apolloQueryHookWrapper(
    useOverviewTreatmentResponseQueryQuery({
      variables: {
        careEpisodeId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) =>
      resultToRemoteData(
        parseOverviewTreatmentResponseQuery(result, feedbackReportContext.participantsByUserId)
      )
    );
}

function loadGoals(
  careEpisodeId: CareEpisodeId,
  feedbackReportContext: FeedbackReportContext
): RemoteData<Error, ReadonlyArray<Goal.Goal>> {
  const { remoteData } = apolloQueryHookWrapper(
    useOverviewGoalsQueryQuery({
      variables: {
        careEpisodeId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) =>
      resultToRemoteData(parseOverviewGoals(result, feedbackReportContext.participantsByUserId))
    );
}

export function loadConstructs(
  careEpisodeId: CareEpisodeId,
  feedbackReportContext: FeedbackReportContext
): [RemoteData<Error, ReadonlyArray<SpecialSection>>, RemoteData<Error, ReadonlyArray<ConstructSection>>] {
  const { remoteData } = apolloQueryHookWrapper(
    useOverviewConstructsQueryQuery({
      variables: {
        careEpisodeId,
      },
    })
  );

  const constructs = remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) =>
      resultToRemoteData(parseOverviewConstructs(result, feedbackReportContext.participantsByUserId))
    );

  const specialSections = remoteData
    .mapFailure(apolloErrorToError)
    .flatMap((result) =>
      resultToRemoteData(parseOverviewSpecialSections(result, feedbackReportContext.participantsByUserId))
    );

  return [specialSections, constructs];
}

type ComponentProps = {
  treatmentResponse: RemoteData<Error, ReadonlyArray<ScaleSectionSummary>>;
  goals: RemoteData<Error, ReadonlyArray<Goal.Goal>>;
  constructs: RemoteData<Error, ReadonlyArray<ConstructSection>>;
  specialSections: RemoteData<Error, ReadonlyArray<SpecialSection>>;
  feedbackReportContext: FeedbackReportContext;
};

const StyledModeChangeButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const OverviewPane = (props: ComponentProps) => {
  const { treatmentResponse, goals, constructs, specialSections, feedbackReportContext } = props;

  const [selectedScales, setSelectedScales] = React.useState<Array<string> | undefined>(undefined);

  const headerLabel = (
    <span>
      {selectedScales ? "Compare" : "Overview"}
      <StyledModeChangeButton
        onClick={() => setSelectedScales(selectedScales ? undefined : [])}
        variant="contained"
        color="primary"
        size="small"
      >
        {selectedScales ? "Overview" : "Compare"}
      </StyledModeChangeButton>
    </span>
  );
  const detail = selectedScales ? (
    <CompareGraphs
      selectedScales={selectedScales}
      setSelectedScales={setSelectedScales}
      targetScaleSections={treatmentResponse}
      specialSectionsData={specialSections}
      feedbackReportContext={feedbackReportContext}
      constructs={constructs}
      goals={goals}
    />
  ) : (
    <OverviewContent
      treatmentResponseData={treatmentResponse}
      feedbackReportContext={feedbackReportContext}
      specialSections={specialSections}
      constructData={constructs}
      goalsData={goals}
    />
  );

  return (
    <div>
      <LevelHeader>
        <Box sx={{ ml: 1 }}>{headerLabel}</Box>
      </LevelHeader>
      <LevelDetail>{detail}</LevelDetail>
    </div>
  );
};

export default OverviewPaneHookWrapper;
export { OverviewPaneHookWrapper as HookWrapper, OverviewPane as Component };
