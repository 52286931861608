import { Routes, Route, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import { AppointmentDetails } from "./AppointmentDetails";
import { PatientId } from "Lib/Ids";
import { fromNullableString } from "Lib/Id";
import NotFound from "Shared/NotFound";

export function AppointmentDetailsRoute(props: { patientId: PatientId }) {
  const params = useParams<{
    appointmentId?: string;
  }>();

  return fromNullableString<"Appointment">(params.appointmentId).caseOf({
    Ok: (id) => {
      return <AppointmentDetails patientId={props.patientId} appointmentId={id} />;
    },
    Err: () => <NotFound />,
  });
}

export function PatientAppointmentRoutes(props: { patientId: PatientId }): ReactElement {
  return (
    <Routes>
      <Route element={<AppointmentDetailsRoute patientId={props.patientId} />} path=":appointmentId" />
    </Routes>
  );
}
