import { CareEpisode, Organization, TreatmentService } from "GeneratedGraphQL/SchemaAndOperations";
import { TFunction } from "i18next";
import { humanize } from "Lib/Utils";

type OrganizationName = Pick<Organization, "__typename" | "name">;
type TreatmentServiceName = Pick<TreatmentService, "__typename" | "name">;
export type CareEpisodeNameable = Pick<CareEpisode, "__typename" | "periodStart" | "periodEnd" | "status"> & {
  organization: OrganizationName;
  treatmentService: TreatmentServiceName | null;
};

// Sticking this here instead of src/Shared/CareEpisode.rb because that file defines a whole new CareEpisode type,
// and I want this to be defined over what we get straight out of graphql.
export function careEpisodeName(
  careEpisode: CareEpisodeNameable,
  t: TFunction<["common", "careEpisodes"]>
): string {
  // The graphql type exports periodStart as nullable, but activerecord enforces that it can't be blank, so we don't
  // have to worry about the null case here.
  const start = careEpisode.periodStart ? t("date.short", { date: careEpisode.periodStart }) : undefined;
  const end = careEpisode.periodEnd
    ? t("date.short", { date: careEpisode.periodEnd })
    : t("careEpisodes:notEnded");

  if (careEpisode.treatmentService) {
    return `${careEpisode.treatmentService.name}, ${start} - ${end} (${humanize(careEpisode.status)})`;
  }

  return `${careEpisode.organization.name}, ${start} - ${end} (${humanize(careEpisode.status)})`;
}
