import { Card, CardContent, CardHeader, Grid, Link, Paper, TextField } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";

import React, { ReactElement, useState } from "react";

import {
  InstituteProviderListQuery,
  ProviderSortParameter,
  useInstituteProviderListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { InstituteId } from "Lib/Ids";
import { InternalBreadcrumbPath } from "../InstituteDetailsRoute";
import { useEffectOnce } from "Lib/Hooks";

type ExtraVars = {
  search: string | null;
  instituteId: InstituteId | null;
};

function InstitutePatientList(props: {
  instituteId: InstituteId;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  const [search, updateSearch] = useState("");
  const extra: ExtraVars = {
    search: search,
    instituteId: props.instituteId,
  };

  useEffectOnce(() => {
    props.setSubCrumbs([{ text: "Providers", path: "/providers" }]);
  });

  const columns: DataGridCols<InstituteProviderListQuery, ["providers"]> = React.useMemo(() => {
    return [
      {
        field: "id",
        headerName: "ID",
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => (
          <Link component={RouterLink} to={params.row.id.toString()}>
            {params.row.id.toString()}
          </Link>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        sortable: true,
        filterable: false,
        valueGetter: (_value, row) => row.name,
      },
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.status,
      },
      {
        field: "measurementAllowed",
        headerName: "measurementAllowed",
        type: "boolean",
        sortable: true,
        filterable: false,
        valueGetter: (_value, row) => row.measurementAllowed,
      },
    ];
  }, []);

  return (
    <Page browserTitle="Institute Providers">
      <PageHeader title="Institute Providers" />

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Paper>
            <SortablePagableCollectionDataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    // Hide column measurementAllowed, the other columns will remain visible
                    measurementAllowed: false,
                  },
                },
              }}
              queryHook={useInstituteProviderListQuery}
              unwrapData={(d) => d?.providers || null}
              queryVariables={extra}
              defaultPageSize={10}
              colNameToSortParam={(colName) => {
                switch (colName) {
                  case "name": {
                    return ProviderSortParameter.NAME;
                    break;
                  }
                  case "measurementAllowed": {
                    return ProviderSortParameter.MEASUREMENT_ALLOWED;
                    break;
                  }
                  default: {
                    return null;
                    break;
                  }
                }
              }}
              columns={columns}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader title="Search" />
            <CardContent>
              <TextField
                label="Search name"
                variant="outlined"
                fullWidth
                defaultValue={search}
                onChange={(ev: React.ChangeEvent<{ value: string }>) => updateSearch(ev.target.value)}
                inputProps={{ "data-testid": "search" }}
                size="small"
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
}

export default InstitutePatientList;
