import { useSearchParams } from "react-router-dom";
import {
  parsers,
  ParameterSerializer,
  getSearchParamByName,
  searchParamIsPresent,
} from "./QueryStringParameter";
import { SimpleEnum } from "./Enum";
import { useObfuscationSecrets } from "./parameterObfuscation";
import {
  EntityTreeNodeParams,
  MonthParams,
  OrganizationFilter,
  PatientFilter,
  ProviderFilter,
  SeverityQueryParams,
} from "GeneratedGraphQL/SchemaAndOperations";
import { patientFilterSerializer } from "./Filters/PatientSelect";
import { organizationFilterSerializer } from "./Filters/OrganizationSelect";
import { providerFilterSerializer } from "./Filters/ProviderSelect";
import { Clear } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import Id from "Lib/Id";
import { stickyParameterKey } from "./Storage";
import { DateRangeParams } from "./DateRangeSelect";

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum object, needed for realtime lookup
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyEnumParameter<T extends string>(
  parameterName: string,
  filterSet: string,
  enumObject: SimpleEnum,
  defaultValue: T,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseEnum(enumObject, defaultValue), replace);
}

/**
 * Read a value from the query string as a string union, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param values an array containing the runtime values you can check the string against.
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyUnionParameter<T extends string>(
  parameterName: string,
  filterSet: string,
  values: ReadonlyArray<T>,
  defaultValue: T,
  replace?: boolean
) {
  return useStickyParameter(
    parameterName,
    filterSet,
    parsers.parseStringUnion(values, defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as a string union, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param values an array containing the runtime values you can check the string against.
 * @param defaultValue if nothing read from query string, this will be used. Can be null
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalUnionParameter<T extends string>(
  parameterName: string,
  filterSet: string,
  values: ReadonlyArray<T>,
  defaultValue: T | null,
  replace?: boolean
) {
  return useStickyParameter(
    parameterName,
    filterSet,
    parsers.parseOptionalStringUnion(values, defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as an enum that can be optional, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum, required for runtime serialization.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalEnumParameter<T extends string>(
  parameterName: string,
  filterSet: string,
  enumObject: SimpleEnum,
  defaultValue: T | null,
  replace?: boolean
) {
  return useStickyParameter<T | null>(
    parameterName,
    filterSet,
    parsers.parseOptionalEnum<T>(enumObject, defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as an enum that can be optional, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum, required for runtime serialization.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalEnumArrayParameter<T extends string>(
  parameterName: string,
  filterSet: string,
  enumObject: SimpleEnum,
  defaultValue: ReadonlyArray<T> | null,
  replace?: boolean
) {
  return useStickyParameter<ReadonlyArray<T> | null>(
    parameterName,
    filterSet,
    parsers.parseOptionalEnumArray<T>(enumObject, defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param enumObject the enum object, needed for realtime lookup
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalEncryptedParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: string | null,
  replace?: boolean
) {
  const obfuscationSecrets = useObfuscationSecrets();
  return useStickyParameter(
    parameterName,
    filterSet,
    parsers.parseOptionalEncrypted(defaultValue, obfuscationSecrets),
    replace
  );
}

/**
 * Read a value from the query string as a typed ID, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyIdParameter<IdType extends string>(
  parameterName: string,
  filterSet: string,
  defaultValue: Id<IdType>,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseId(defaultValue), replace);
}

function useStickyEntityTreeNodeParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: EntityTreeNodeParams,
  replace?: boolean
) {
  return useStickyParameter(
    parameterName,
    filterSet,
    parsers.parseEntityTreeNodeParams(defaultValue),
    replace
  );
}

/**
 * Read a value from the query string as a typed ID, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalIdParameter<IdType extends string>(
  parameterName: string,
  filterSet: string,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseOptionalId<IdType>(), replace);
}

/**
 * Read a patient filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyPatientFilterParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: PatientFilter | null,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, patientFilterSerializer(defaultValue), replace);
}

/**
 * Read an organization filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOrganizationFilterParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: OrganizationFilter | null,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, organizationFilterSerializer(defaultValue), replace);
}

/**
 * Read an organization filter object with a custom serializer, and provide an update hook
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyProviderFilterParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: ProviderFilter | null,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, providerFilterSerializer(defaultValue), replace);
}

/**
 * Read a value from the query string as an enum, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyBooleanParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: boolean,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseBoolean(defaultValue), replace);
}

/**
 * Read a value from the query string as an number, and provide an update hook.
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalNumberParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: number,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseNumber(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyDateParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: Date,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseDate(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalDateRangeParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: DateRangeParams | null,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseOptionalDateRange(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyMonthParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: Date,
  mode: "start" | "end",
  replace?: boolean
) {
  const parser = mode === "start" ? parsers.parseMonthStart : parsers.parseMonthEnd;
  return useStickyParameter(parameterName, filterSet, parser(defaultValue), replace);
}

/**
 * Read a value from the query string as a date. This uses the new graphql date parsing
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyMonthParameterV2(
  parameterName: string,
  filterSet: string,
  defaultValue: MonthParams,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseMonthStructured(defaultValue), replace);
}

/**
 * Read a value from the query string as a date
 * @param parameterName the query string parameter, global
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickyOptionalDateParameter(parameterName: string, filterSet: string, replace?: boolean) {
  return useStickyParameter(parameterName, filterSet, parsers.parseOptionalDate(), replace);
}

/**
 * Read a value from the query string as a set of severity queries
 * @param parameterName the query string parameter, global
 * @param defaultValue if nothing read from query string, this will be used.
 * @param replace whether to push or replace state.
 * @returns [currentValue, updateHook]
 */
function useStickySeverityQueryParameter(
  parameterName: string,
  filterSet: string,
  defaultValue: ReadonlyArray<SeverityQueryParams> | null,
  replace?: boolean
) {
  return useStickyParameter(parameterName, filterSet, parsers.parseSeverityQuery(defaultValue), replace);
}

/**
 * Base functionality for reading a parameter with a given name off the query string.
 * @param parameterName the key to be used on the query string. Can be reused across pages
 * @param filterSet the key to use when storing. Must be unique across pages
 * @param serializer which serializer to use to translate from a string to the final object
 * @param replace whether to push or replace state on the query string when it changes.
 * @return [currentValue, updateValue, persistCurrentValue, clearValue]
 */
function useStickyParameter<T>(
  parameterName: string,
  filterSet: string,
  serializer: ParameterSerializer<T>,
  replace?: boolean,
  skipQueryString?: boolean
): [T, (newValue: T) => void] {
  const [currentSearchParams, setSearchParams] = useSearchParams();

  const currentValue = searchParamIsPresent(currentSearchParams, parameterName)
    ? getSearchParamByName(currentSearchParams, parameterName, serializer)
    : getStickyParamFromStorage(parameterName, filterSet, serializer);

  const onUpdate = (newValue: T) => {
    // We need to take the active current url params in case we get multiple updates in the same cycle.
    const newSearchParams = new URLSearchParams(window.location.search);

    const newSerialized = serializer.serialize(newValue);
    if (newSerialized === null) {
      newSearchParams.delete(parameterName);
      localStorage.removeItem(stickyParameterKey(parameterName, filterSet));
    } else {
      newSearchParams.set(parameterName, newSerialized);
      localStorage.setItem(stickyParameterKey(parameterName, filterSet), newSerialized);
    }
    if (!skipQueryString) {
      setSearchParams(newSearchParams, { replace });
    }
    return newSearchParams;
  };

  return [currentValue, onUpdate];
}

function getStickyParamFromStorage<T>(
  parameterName: string,
  filterSet: string,
  serializer: ParameterSerializer<T>
): T {
  const foundValue = localStorage.getItem(stickyParameterKey(parameterName, filterSet));

  return serializer.deserialize(foundValue);
}

function ResetAndStickyFilterButtonGroup(props: { onReset: () => void }) {
  const { onReset } = props;
  const { t } = useTranslation(["common"]);
  return (
    <Button endIcon={<Clear />} color="error" variant={"text"} onClick={onReset}>
      {t("common:filters.clearFilter")}
    </Button>
  );
}

export {
  useStickyParameter,
  useStickyBooleanParameter,
  useStickyEnumParameter,
  useStickyMonthParameter,
  useStickyOptionalDateParameter,
  useStickyOptionalEncryptedParameter,
  useStickyOptionalIdParameter,
  useStickyIdParameter,
  useStickyOptionalEnumParameter,
  useStickyOptionalNumberParameter,
  useStickyOptionalUnionParameter,
  useStickyOrganizationFilterParameter,
  useStickyDateParameter,
  useStickyPatientFilterParameter,
  useStickyProviderFilterParameter,
  useStickyUnionParameter,
  useStickyMonthParameterV2,
  useStickyEntityTreeNodeParameter,
  useStickyOptionalEnumArrayParameter,
  useStickySeverityQueryParameter,
  useStickyOptionalDateRangeParameter,
  ResetAndStickyFilterButtonGroup,
};
