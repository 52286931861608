import { Result, Maybe } from "seidr";
import { OrganizationSummary } from "./Organization";
import { titleize, humanize, parseIsoStringOrDate } from "../Lib/Utils";
import { ParticipantSummary, toParticipantSummary } from "./Participant";
import { PatientSessionSummary, parsePatientSessionSummary } from "./PatientSession";
import {
  CareEpisodeDetailFragmentFragment,
  CareEpisodeStatus,
  CareEpisodeSummaryFragment,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeId } from "Lib/Ids";

// A care episode represents treatment in a given organization for a given patient
export type CareEpisodeBase = {
  id: CareEpisodeId;
};

// The summary gives a minimal amount of detail suitable for displaying a name
export type CareEpisodeSummary = CareEpisodeBase & {
  status: CareEpisodeStatus;
  periodStart: Date;
  periodEnd: Maybe<Date>;
  organizationSummary: OrganizationSummary;
};

export type CareEpisodeDetail = CareEpisodeSummary & {
  participants: ReadonlyArray<ParticipantSummary>;
  latestPatientSessionWithFeedback: Maybe<PatientSessionSummary>;
};

// -- Transformers ------------------------------------------------------------

function toCareEpisodeDetail(raw: CareEpisodeDetailFragmentFragment): CareEpisodeDetail {
  return {
    ...toCareEpisodeSummary(raw),
    participants: raw.participants.map(toParticipantSummary),
    latestPatientSessionWithFeedback: Result.fromNullable(
      new Error("No Patient Sessions"),
      // The patient sessions are filtered by status to include sessions with
      // feedback
      raw.patientSessions.nodes[0]
    )
      .flatMap(parsePatientSessionSummary)
      .toMaybe(),
  };
}

function toCareEpisodeSummary(raw: CareEpisodeSummaryFragment): CareEpisodeSummary {
  return {
    id: raw.id,
    organizationSummary: raw.organization,
    status: raw.status,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    periodStart: parseIsoStringOrDate(raw.periodStart!), // TODO: deal with optional start date
    periodEnd: Maybe.fromNullable(raw.periodEnd).map(parseIsoStringOrDate),
  };
}

function humanizeStatus(status: CareEpisodeStatus) {
  return titleize(humanize(status.toString()));
}

export { toCareEpisodeSummary, humanizeStatus, toCareEpisodeDetail };
