import * as Id from "Lib/Id";
import { CareEpisodeDetail } from "Shared/CareEpisode";
import { hasNonPatientParticipants, ParticipantSummary } from "Shared/Participant";
import * as NEL from "Lib/NonEmptyList";
import { UserId } from "Shared/User";
import { Maybe } from "seidr";
import { InvitationWithUserSummary } from "Shared/Scale/Invitation";
import { CareEpisodeId, PatientId } from "Lib/Ids";

/**
 * Due to limitations in the way we construct the back end, it is not trivial
 * to go from the 'user', who can be in multiple roles, to the 'participant',
 * who is that particular person in a given role - e.g. a user can technically
 * be both a patient and a related person, and we need to know the context they
 * are answering questions in to give their relation to the patient.  In time
 * we hope to fix this in the back end, but right now we just supply this map
 * so that when you fetch user information, you can match it to the given
 * participant.  Note that the strong key here is the *User Id* not the
 * participant id.
 */
export type ParticipantByUserIdMap = Record<string, ParticipantSummary>;

export type FeedbackReportContextBase = {
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
  participants: NEL.NonEmptyList<ParticipantSummary>;
  participantsByUserId: ParticipantByUserIdMap;
  hasMultipleClientParticipants: boolean;
};

export type FeedbackReportContext = FeedbackReportContextBase & {
  administrationDates: NEL.NonEmptyList<Date>;
  /**
   * The date beyond which data should be considered fresh. For now this is
   * likely to be the date of last administration, but during multiple
   * stakeholder models this is likely to be 'date since last appointment'
   */
  earliestFreshDate: Date;
  isIntake: boolean;
  activeInvitations: ReadonlyArray<InvitationWithUserSummary>;
};

function parseFeedbackReportContext(
  patientId: PatientId,
  careEpisodeDetail: CareEpisodeDetail,
  administrationDates: NEL.NonEmptyList<Date>,
  activeInvitations: ReadonlyArray<InvitationWithUserSummary>
): FeedbackReportContext {
  const result = NEL.fromArray(careEpisodeDetail.participants).caseOf({
    Just: (participants) => {
      const participantsByUserId = Id.groupById((p) => p.user.id, participants.toArray());

      return {
        patientId,
        careEpisodeId: careEpisodeDetail.id,
        administrationDates,
        earliestFreshDate: NEL.last(administrationDates),
        participants,
        participantsByUserId: participantsByUserId,
        hasMultipleClientParticipants: hasNonPatientParticipants(participants.toArray()),
        isIntake: administrationDates.length === 1,
        activeInvitations,
      };
    },
    Nothing: () => null,
  });
  if (!result) {
    throw new Error("Somehow the participants are empty");
  }
  return result;
}

function getParticipantByUserId(context: FeedbackReportContext, userId: UserId): Maybe<ParticipantSummary> {
  return Maybe.fromNullable(context.participantsByUserId[userId.toString()]);
}

export { parseFeedbackReportContext, getParticipantByUserId };
