import React, { ReactElement } from "react";
import RouterWithNotFound from "Shared/RouterWithNotFound";
import TreatmentTracks from "./TreatmentTracks";
import CreateTreatmentTrack from "./CreateTreatmentTrack";
import UpdateTreatmentTrack from "./UpdateTreatmentTrack";
import { Route } from "react-router-dom";

function TreatmentTrackRoutes(): ReactElement {
  return (
    <RouterWithNotFound>
      <Route element={<TreatmentTracks />} path="/" />
      <Route element={<CreateTreatmentTrack />} path="/create" />
      <Route element={<UpdateTreatmentTrack />} path="/:treatmentTrackId/*" />
    </RouterWithNotFound>
  );
}

export default TreatmentTrackRoutes;
