import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CaseConsult,
  CaseConsultStatus,
  ConsultMeeting,
  ConsultMeetingAttendee,
  ConsultMeetingAttendeeRole,
  ConsultMeetingStatus,
  ConsultRequest,
  Patient,
  Provider,
  useCaseConsultListQuery,
  useConsultMeetingSummaryQuery,
  useCreateConsultMeetingAttendeeMutation,
  useRemoveConsultMeetingAttendeeMutation,
  useMarkConsultMeetingCompleteMutation,
  useStartConsultMeetingMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { useEffectSimpleCompare } from "Lib/Hooks";
import { unsafeFromUuid } from "Lib/Id";
import { ConsultMeetingId, ProviderId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import NotFound from "Shared/NotFound";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "Shared/Spinner";
import ErrorMessage from "Shared/ErrorMessage";
import {
  Box,
  Button,
  Chip,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Slideshow } from "@mui/icons-material";
import { ShadedTable } from "MDS/ShadedTable";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { consultRequestReasonT } from "GeneratedGraphQL/EnumTranslations";
import { RichText } from "MDS/RichText";
import { Section } from "./Section";
import { PickTypename } from "type-utils";
import { CompleteConsultButton } from "./CompleteCaseConsultButton";
import Link from "MDS/Link";
import StartConsultReviewButton from "./CaseConsultReview/StartConsultReviewButton";
import ProviderSelectSingle from "Shared/Filters/ProviderSelectSingle";
import { CancelConsultButton } from "./CancelCaseConsultButton";
import Paginator, { initPage } from "Shared/Paginator";
import { CaseConsultListFilters, useCaseConsultListFilters } from "./CaseConsultListFilters";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";

export function ConsultMeetingButton(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const navigate = useNavigate();

  const [startMeeting, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareStartConsultMeeting,
    useStartConsultMeetingMutation({
      variables: {
        input: {},
      },
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: (response) => {
        if (response?.consultMeeting) {
          navigate(`meeting/${response.consultMeeting.id}`);
        }
      },
      _: () => {},
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";

  return (
    <ButtonWithSpinner
      variant="contained"
      color="secondary"
      showSpinner={showSpinner}
      onClick={() => startMeeting()}
    >
      {t("collaborativeCare:caseConsult.consultList.actions.startConsult")}
    </ButtonWithSpinner>
  );
}

export function ConsultMeetingPage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const params = useParams<{ meetingId: string }>();
  if (!params.meetingId) {
    return <NotFound />;
  }
  const meetingId = unsafeFromUuid<"ConsultMeeting">(params.meetingId);

  return (
    <Page
      breadcrumbs={[
        <Link to="/app/cocm/case-consult" key="consults-breadcrumb">
          {t("collaborativeCare:caseConsult.consultList.title")}
        </Link>,
        <Typography key="meeting-breadcrumb">
          {t("collaborativeCare:caseConsult.consultMeeting.pageTitle")}
        </Typography>,
      ]}
      browserTitle={t("collaborativeCare:caseConsult.consultMeeting.pageTitle")}
    >
      <ConsultMeetingSections meetingId={meetingId} />
    </Page>
  );
}

function ConsultMeetingSections(props: { meetingId: ConsultMeetingId }): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const { remoteData } = apolloQueryHookWrapper(
    useConsultMeetingSummaryQuery({ variables: { id: props.meetingId } })
  );

  return remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareConsultMeeting) {
        return (
          <ErrorMessage
            message={t("collaborativeCare:caseConsult.consultMeeting.consultSummary.failedToLoad")}
          />
        );
      }

      return (
        <Stack direction="column" spacing={3}>
          <ConsultSummarySection meeting={response.collaborativeCareConsultMeeting} />
          <RequestedCasesSection meeting={response.collaborativeCareConsultMeeting} />
          <DiscussedCasesSection meeting={response.collaborativeCareConsultMeeting} />
        </Stack>
      );
    },
  });
}

type AttendeeSummary = PickTypename<ConsultMeetingAttendee, "id" | "role"> & {
  provider: PickTypename<Provider, "id" | "name">;
};
type ConsultMeetingSummary = PickTypename<ConsultMeeting, "id" | "startTime" | "endTime" | "status"> & {
  attendees: {
    nodes: ReadonlyArray<AttendeeSummary>;
  } | null;
};

type ConsultMeetingSectionProps = {
  meeting: ConsultMeetingSummary;
};

function ConsultSummarySection(props: ConsultMeetingSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const addAttendee =
    props.meeting.status === ConsultMeetingStatus.ONGOING ? <AddAttendees meeting={props.meeting} /> : null;
  const finishMeeting =
    props.meeting.status === ConsultMeetingStatus.ONGOING ? (
      <FinishMeetingButton meeting={props.meeting} />
    ) : null;

  return (
    <Section
      title={t("collaborativeCare:caseConsult.consultMeeting.consultSummary.title", {
        date: props.meeting.startTime,
      })}
    >
      <Stack direction="column" spacing={1} marginTop={1}>
        <Stack direction="row" spacing={1} flexWrap="wrap" alignItems="center">
          <Typography variant="h2" component="h3">
            {t("collaborativeCare:caseConsult.consultMeeting.consultSummary.attendees")}
          </Typography>
          {props.meeting.attendees?.nodes.map((attendee, i) => (
            <AttendeeChip attendee={attendee} key={i} />
          ))}
          {addAttendee}
        </Stack>
        {finishMeeting}
      </Stack>
    </Section>
  );
}

function AddAttendees(props: ConsultMeetingSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();
  const [providerId, setProviderId] = React.useState<ProviderId | null>(null);

  const [addAttendee, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateConsultMeetingAttendee,
    useCreateConsultMeetingAttendeeMutation({
      variables: {
        input: {
          consultMeetingId: props.meeting.id,
          // This cast should be safe since we disable clicking the button until providerId is non-null
          providerId: providerId as ProviderId,
        },
      },
    })
  );

  // Reset the provider id when the remote data state changes into success, i.e., when we finish adding a new attendee
  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setProviderId(null);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";
  const disabled = providerId === null || remoteData.kind === "Loading";

  return (
    <>
      <Box minWidth="15rem">
        <ProviderSelectSingle
          value={providerId}
          setValue={setProviderId}
          autocompleteProps={{ sx: { backgroundColor: theme.palette.background.paper } }}
        />
      </Box>
      <ButtonWithSpinner
        showSpinner={showSpinner}
        disabled={disabled}
        variant="outlined"
        color="secondary"
        onClick={() => addAttendee()}
      >
        {t("collaborativeCare:caseConsult.consultMeeting.consultSummary.addAttendee")}
      </ButtonWithSpinner>
    </>
  );
}

function FinishMeetingButton(props: ConsultMeetingSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const navigate = useNavigate();

  const [finishMeeting, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareMarkConsultMeetingComplete,
    useMarkConsultMeetingCompleteMutation({
      variables: {
        input: {
          consultMeetingId: props.meeting.id,
        },
      },
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        navigate("../..", { relative: "path" });
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <ButtonWithSpinner
      variant="contained"
      color="secondary"
      sx={{ maxWidth: "fit-content" }}
      showSpinner={showSpinner}
      disabled={disabled}
      onClick={() => finishMeeting()}
    >
      {t("collaborativeCare:caseConsult.consultMeeting.consultSummary.finish")}
    </ButtonWithSpinner>
  );
}

type AttendeeChipProps = {
  attendee: AttendeeSummary;
};

function AttendeeChip(props: AttendeeChipProps): ReactElement {
  const color = props.attendee.role === ConsultMeetingAttendeeRole.PRESENTER ? "primary" : "default";
  const icon = props.attendee.role === ConsultMeetingAttendeeRole.PRESENTER ? <Slideshow /> : undefined;

  const [removeAttendee] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareRemoveConsultMeetingAttendee,
    useRemoveConsultMeetingAttendeeMutation({
      variables: {
        input: {
          consultMeetingAttendeeId: props.attendee.id,
        },
      },
    })
  );

  const onDelete =
    props.attendee.role === ConsultMeetingAttendeeRole.PRESENTER ? undefined : () => removeAttendee();

  return <Chip label={props.attendee.provider.name} icon={icon} color={color} onDelete={onDelete} />;
}

function RequestedCasesSection(props: ConsultMeetingSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const filters = useCaseConsultListFilters();

  const content =
    props.meeting.status === ConsultMeetingStatus.ONGOING ? (
      <LoadedRequestedCases meeting={props.meeting} filters={filters} />
    ) : (
      <MeetingEndedMessage />
    );

  return (
    <Section
      title={t("collaborativeCare:caseConsult.consultMeeting.requestedCases.title")}
      actions={<CaseConsultListFilters filters={filters} />}
    >
      {content}
    </Section>
  );
}

type LoadedRequestedCasesProps = ConsultMeetingSectionProps & {
  filters: CaseConsultListFilters;
};

function LoadedRequestedCases(props: LoadedRequestedCasesProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const testPatientViewability = useTestPatientViewability();

  const [pagination, setPagination] = React.useState(initPage(10));

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        statuses: [
          CaseConsultStatus.AWAITING_CONSULT,
          CaseConsultStatus.NEEDS_SUMMARY,
          CaseConsultStatus.STALE,
        ],
        primaryCareProviderId: props.filters.pcp,
        careManagerId: props.filters.careManager,
        testPatient: testPatientViewability,
        ...pagination,
      },
      fetchPolicy: "network-only",
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.collaborativeCareCaseConsults?.pageInfo || null,
    _: () => null,
  });

  return remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareCaseConsults) {
        return (
          <ErrorMessage
            message={t("collaborativeCare:caseConsult.consultMeeting.requestedCases.failedToLoad")}
          />
        );
      }

      if (response.collaborativeCareCaseConsults.nodes.length === 0) {
        return <NoRequestedCasesMessage />;
      }

      return (
        <Stack direction="column" spacing={1}>
          <RequestedCasesTable
            consults={response.collaborativeCareCaseConsults.nodes}
            consultMeetingId={props.meeting.id}
          />
          <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
        </Stack>
      );
    },
  });
}

function MeetingEndedMessage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="row" justifyContent="center" margin={2}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.consultMeeting.requestedCases.meetingEnded")}
      </Typography>
    </Stack>
  );
}

function NoRequestedCasesMessage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="row" justifyContent="center" margin={2}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.consultMeeting.requestedCases.noCases")}
      </Typography>
    </Stack>
  );
}

type PatientWithName = PickTypename<Patient, "id" | "name"> & {
  cocmPrimaryCareProvider: ProviderWithName | null;
};
type ProviderWithName = PickTypename<Provider, "id" | "name">;
type ConsultRequestSummary = PickTypename<
  ConsultRequest,
  "id" | "requestReason" | "requestText" | "requestDate"
> & {
  provider: ProviderWithName;
};
type CaseConsultSummary = PickTypename<
  CaseConsult,
  "id" | "status" | "caseSummary" | "consultationNotes" | "consultationDocumentation" | "presentationOrder"
> & {
  patient: PatientWithName;
  consultRequests: {
    nodes: ReadonlyArray<ConsultRequestSummary>;
  };
};

type CaseConsultListProps = {
  consults: ReadonlyArray<CaseConsultSummary>;
  consultMeetingId: ConsultMeetingId;
};

type CaseConsultItemProps = {
  consult: CaseConsultSummary;
  consultMeetingId: ConsultMeetingId;
};

// Note to future programmers/me: Consider unifying this with the similar table in CaseConsults.tsx. I haven't done it
// yet because I'm not sure how much these pages are going to drift apart from each other, but if its closer to
// releasing this feature and the only differences are in which buttons are available, these could pretty easily be a
// single component.

function RequestedCasesTable(props: CaseConsultListProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <ShadedTable>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.patient")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">{t("collaborativeCare:patientList.fields.pcp")}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestTime")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.consults.map((consult, i) => (
          <RequestedCaseTableRow consult={consult} key={i} consultMeetingId={props.consultMeetingId} />
        ))}
      </TableBody>
    </ShadedTable>
  );
}

function RequestedCaseTableRow(props: CaseConsultItemProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  return (
    <TableRow>
      <TableCell>
        <PatientHeaderStatus patientId={props.consult.patient.id} patientName={props.consult.patient.name} />
      </TableCell>
      <TableCell>
        <Link to={`/app/cocm/patient/${props.consult.patient.id}`}>{props.consult.patient.name}</Link>
      </TableCell>
      <TableCell>{props.consult.patient.cocmPrimaryCareProvider?.name}</TableCell>
      <TableCell>
        {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueShort", {
          date: sortedRequests[0]?.requestDate,
        })}
      </TableCell>
      <TableCell>
        {sortedRequests
          .map((request) => `${request.provider.name} (${consultRequestReasonT(request.requestReason, t)})`)
          .join(", ")}
      </TableCell>
      <TableCell>
        <StartConsultReviewButton
          caseConsultId={props.consult.id}
          consultMeetingId={props.consultMeetingId}
        />
      </TableCell>
      <TableCell>
        <CancelConsultButton consult={props.consult} />
      </TableCell>
    </TableRow>
  );
}

function DiscussedCasesSection(props: ConsultMeetingSectionProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);
  const testPatientViewability = useTestPatientViewability();

  const [pagination, setPagination] = React.useState(initPage(10));
  const filters = useCaseConsultListFilters();

  const { remoteData } = apolloQueryHookWrapper(
    useCaseConsultListQuery({
      variables: {
        consultMeetingId: props.meeting.id,
        primaryCareProviderId: filters.pcp,
        careManagerId: filters.careManager,
        testPatient: testPatientViewability,
        ...pagination,
      },
      fetchPolicy: "network-only",
    })
  );

  const pageInfo = remoteData.caseOf({
    Success: (response) => response.collaborativeCareCaseConsults?.pageInfo || null,
    _: () => null,
  });

  const content = remoteData.caseOf({
    NotAsked: () => <Spinner />,
    Loading: () => <Spinner />,
    Failure: (err) => <ErrorMessage message={err.message} />,
    Success: (response) => {
      if (!response.collaborativeCareCaseConsults) {
        return (
          <ErrorMessage
            message={t("collaborativeCare:caseConsult.consultMeeting.discussedCases.failedToLoad")}
          />
        );
      }

      if (response.collaborativeCareCaseConsults.nodes.length === 0) {
        return <NoDiscussedCasesMessage />;
      }

      return (
        <Stack direction="column" spacing={1}>
          <DiscussedCasesTable
            consults={response.collaborativeCareCaseConsults.nodes}
            consultMeetingId={props.meeting.id}
          />
          <Paginator pagination={pagination} pageInfo={pageInfo} onChange={setPagination} />
        </Stack>
      );
    },
  });

  return (
    <Section
      title={t("collaborativeCare:caseConsult.consultMeeting.discussedCases.title")}
      actions={<CaseConsultListFilters filters={filters} />}
    >
      {content}
    </Section>
  );
}

function NoDiscussedCasesMessage(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <Stack direction="row" justifyContent="center" margin={2}>
      <Typography variant="h1">
        {t("collaborativeCare:caseConsult.consultMeeting.discussedCases.noCases")}
      </Typography>
    </Stack>
  );
}

function DiscussedCasesTable(props: CaseConsultListProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  return (
    <ShadedTable>
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.patient")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">{t("collaborativeCare:patientList.fields.pcp")}</Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestTime")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultList.fields.requestedBy")}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontWeight="bold">
              {t("collaborativeCare:caseConsult.consultMeeting.discussedCases.caseNotes")}
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.consults.map((consult, i) => (
          <DiscussedCaseTableRow consult={consult} key={i} consultMeetingId={props.consultMeetingId} />
        ))}
      </TableBody>
    </ShadedTable>
  );
}

function DiscussedCaseTableRow(props: CaseConsultItemProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "enums"]);

  const sortedRequests = [...props.consult.consultRequests.nodes];
  sortedRequests.sort((a, b) => (a.requestDate < b.requestDate ? -1 : 1));

  return (
    <TableRow>
      <TableCell>
        <PatientHeaderStatus patientId={props.consult.patient.id} patientName={props.consult.patient.name} />
      </TableCell>
      <TableCell>
        <Link to={`/app/cocm/patient/${props.consult.patient.id}`}>{props.consult.patient.name}</Link>
      </TableCell>
      <TableCell>{props.consult.patient.cocmPrimaryCareProvider?.name}</TableCell>
      <TableCell>
        {t("collaborativeCare:caseConsult.consultList.fields.requestTimeValueShort", {
          date: sortedRequests[0]?.requestDate,
        })}
      </TableCell>
      <TableCell>
        {sortedRequests
          .map((request) => `${request.provider.name} (${consultRequestReasonT(request.requestReason, t)})`)
          .join(", ")}
      </TableCell>
      <TableCell>
        <RichText>{props.consult.consultationNotes}</RichText>
      </TableCell>
      <TableCell>
        <Link to={`case/${props.consult.id}`}>
          <Button variant="contained" color="secondary">
            {t("collaborativeCare:caseConsult.consultMeeting.discussedCases.viewCase")}
          </Button>
        </Link>
      </TableCell>
      <TableCell>
        <CompleteConsultButton consult={props.consult} />
      </TableCell>
    </TableRow>
  );
}
