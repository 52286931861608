import { Button, Card, CardContent, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { PickTypename } from "type-utils";
import {
  Patient,
  useUpdatePatientNotificationPreferenceMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { PatientId } from "Lib/Ids";
import { ContextualReportButton } from "Shared/ContextualReportLink";
import { Assessment } from "@mui/icons-material";
import { CreateCareEpisodeButton } from "Patients/CareEpisodes2/CreateCareEpisodeButton";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";

type PatientActionProps = {
  patient: PickTypename<Patient, "id" | "name" | "dob" | "enableNotifications" | "hasCompletedAssessment">;
};
export function PatientActionsCard(props: PatientActionProps): ReactElement {
  return (
    <Card>
      <CardContent>
        <Grid container columns={6} spacing={1}>
          <Grid item lg={6} xs={6}>
            <Stack direction="column" spacing={0.5}>
              <MeasurementList patient={props.patient} />
              <GeneralList patient={props.patient} />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

type ActionListSubheaderProps = {
  title: string;
};
function ActionListSubheader(props: ActionListSubheaderProps): ReactElement {
  return (
    <Typography variant="h2" marginBottom="0.65em">
      {props.title}
    </Typography>
  );
}

function DisableNotificationsButton(props: { patientId: PatientId; enableNotifications: boolean }) {
  const { patientId, enableNotifications } = props;
  const { t } = useTranslation(["patients"]);
  const [toggleNotifications, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.updatePatientNotificationPreference,
    useUpdatePatientNotificationPreferenceMutation()
  );

  const onClick = () => {
    toggleNotifications({
      variables: {
        input: {
          patientId,
          enableNotifications: !enableNotifications,
        },
      },
    });
  };

  return (
    <ReadOnlyOrImpersonatingDisabledButton>
      <ButtonWithSpinner
        variant="contained"
        color="secondary"
        type="submit"
        showSpinner={remoteData.type === "Loading"}
        disabled={remoteData.type === "Loading"}
        onClick={onClick}
      >
        {enableNotifications
          ? t("patients:patientDetails.actions.disableNotifications")
          : t("patients:patientDetails.actions.enableNotifications")}
      </ButtonWithSpinner>
    </ReadOnlyOrImpersonatingDisabledButton>
  );
}

function MeasurementList(props: PatientActionProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  const reportLink = props.patient.hasCompletedAssessment ? (
    <ContextualReportButton patient={props.patient.id} variant="contained" color="secondary">
      <Assessment />
      {t("patients:patientDetails.actions.feedbackReport")}
    </ContextualReportButton>
  ) : (
    <Button variant="contained" disabled={true}>
      {t("patients:patientDetails.actions.noReport")}
    </Button>
  );

  return (
    <List subheader={<ActionListSubheader title={t("patients:patientDetails.actions.measurement")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          {reportLink}
          <CreateCareEpisodeButton patient={props.patient} />
        </Stack>
      </ListItem>
    </List>
  );
}

function GeneralList(props: PatientActionProps): ReactElement {
  const { t } = useTranslation(["patients"]);

  return (
    <List subheader={<ActionListSubheader title={t("patients:patientDetails.actions.general")} />}>
      <ListItem disableGutters>
        <Stack direction="row" spacing={0.6}>
          <DisableNotificationsButton
            patientId={props.patient.id}
            enableNotifications={props.patient.enableNotifications}
          />
        </Stack>
      </ListItem>
    </List>
  );
}
