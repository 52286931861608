type GraphqlMaybe<T> = T | null;
/**
 * Given a tuple type `[A, B, C, ...]`, returns the first type: `A`
 */
export type Head<L extends Array<unknown>> = [] extends L
  ? undefined
  : ((...args: L) => never) extends (h: infer H, ...t: Array<never>) => unknown
  ? H
  : undefined;
/**
 * Given a tuple type `[A, B, C, ...]` returns a new tuple type with the first element removed: `[B, C, ...]`
 */
export type Tail<L extends Array<unknown>> = ((...args: L) => never) extends (
  h: never,
  ...t: infer T
) => unknown
  ? T
  : [];

/**
 * The recursive 'worker' type for `DigOptional`, which masks its recursion by wrapping its result in an object type.
 * Prettier doesn't appreciate me trying to intersperse comments in this type, but at a high level:
 *  - If the list of keys is empty, we're done and can just return T
 *  - Otherwise, look at the first key in the list, H
 *    - If it's a valid key of T, then we recurse and dig into T[H], returning that
 *    - If T could be undefined, we exclude that possibility and then check whether the key is present
 *      in it and recurse as above, adding `| undefined` to the result.
 *    - Otherwise, it's just not a valid key and we return `undefined`.
 */
type DigOptional1<T, Keys extends Array<string | number | symbol>> = [] extends Keys
  ? { dug: T }
  : Head<Keys> extends infer H
  ? H extends keyof T
    ? { dug: DigOptional1<T[H], Tail<Keys>>["dug"] }
    : H extends keyof NonNullable<Unpacked<T>>
    ? {
        dug: DigOptional1<NonNullable<Unpacked<T>>[H], Tail<Keys>>["dug"] | undefined;
      }
    : { dug: undefined }
  : { dug: undefined };
/**
 * Given a type and a tuple of keys, returns the type that would result from following that chain
 * of keys, accounting for possible `null` or `undefined` values.
 *
 *     type X = DigOptional<{ a: string }, ['a']> // => string
 *     type X = DigOptional<{ a: string }, []> // => { a: string }
 *     type X = DigOptional<{ a: string }, ['z']> // => undefined
 *     type X = DigOptional<{ a: string }, ['a', 'length']> // => number
 *     type X = DigOptional<{ a?: { b: string } }, ['a', 'b']> // => string | undefined
 *     type X = DigOptional<{ a: { b: string | undefined } }, ['a', 'b']> // => string | undefined
 *
 * Intuitively, `DigOptional<typeof obj, ['a', 'b', 'c']>` represents the type of the
 * expression `obj?.a?.b?.c`.
 */
export type DigOptional<T, Keys extends Array<string | number | symbol>> = DigOptional1<T, Keys>["dug"];
/**
 * The recursive 'worker' type for `Dig`, which masks its recursion by wrapping its result in an object type.
 * This type functions nearly the same as `DigOptional1`, except that it doesn't introduce an `| undefined`
 * when it discovers a nullable intermediary value.
 */
type Dig1<T, Keys extends Array<string | number | symbol>> = [] extends Keys
  ? { dug: T }
  : Head<Keys> extends infer H
  ? H extends keyof NonNullable<Unpacked<T>>
    ? { dug: NonNullable<Dig1<NonNullable<Unpacked<T>>[H], Tail<Keys>>["dug"]> }
    : { dug: undefined }
  : { dug: undefined };
/**
 * Given a type and a tuple of keys, returns the type that would result from following that chain
 * of keys, ignoring possible `null` or `undefined` intermediary values.
 *
 *     type X = Dig<{ a: string }, ['a']> // => string
 *     type X = Dig<{ a: string }, []> // => { a: string }
 *     type X = Dig<{ a: string }, ['z']> // => undefined
 *     type X = Dig<{ a: string }, ['a', 'length']> // => number
 *     type X = Dig<{ a?: { b: string } }, ['a', 'b']> // => string
 *     type X = Dig<{ a: { b: string | undefined } }, ['a', 'b']> // => string | undefined
 *
 * Intuitively, `Dig<typeof obj, ['a', 'b', 'c']>` represents the type of the expression `obj!.a!.b!.c`.
 */
export type Dig<T, Keys extends Array<string | number | symbol>> = Dig1<T, Keys>["dug"];

/**
 * Do a dig, and then unpack the result. Useful when you are referencing an array property but want the type of the items in the array, not
 * the array type itself.
 */
export type DigUnpacked<T, Keys extends Array<string | number | symbol>> = Unpacked<Dig1<T, Keys>["dug"]>;

/**
 * Unpack a boxed type, e.g. `Array<string> => string`. Supports arrays, promises, maybes.
 * Note that order is important here: if you do not put readonly before read-write, on the arrays,
 * it will not correct unbox readonly arrays.`
 */
// eslint-disable-next-line @typescript-eslint/array-type
export type Unpacked<T> = T extends readonly (infer U)[]
  ? U
  : T extends Array<infer U>
  ? U
  : T extends (...args: Array<unknown>) => infer U
  ? U
  : T extends Promise<infer U>
  ? U
  : T extends GraphqlMaybe<infer U>
  ? U
  : T extends ReadonlyArray<infer U>
  ? U
  : T;

/**
 * Take a graphql type with a discriminated union and a type name and reduce to the type you want only.
 */
export type DiscriminateUnion<U, T extends string> = NonNullable<U & { __typename: T }>;

/**
 * Get the type of the elements inside an array type
 */
export type ArrayElement<ArrayType extends ReadonlyArray<unknown>> = ArrayType extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

// This isn't strictly correct because each typename is technically a distinct const string type. The type that comes
// out of PickTypename doesn't get infected by `string` here though, it has the correct const __typename field. I'm not
// 100% sure why that happens without a type argument here, but I'm not looking a gift horse in the mouth.
type HasTypename = { __typename: string };
/**
 * Pick from a model type, automatically including __typename
 */
export type PickTypename<TData extends HasTypename, TFields extends keyof TData> = Pick<
  TData,
  "__typename" | TFields
>;

/**
 * Make the given fields optional for the given type
 */
export type WithOptionalFields<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Make the given fields required for the given type
 */
export type WithRequiredFields<T, K extends keyof T> = T & Required<Pick<T, K>>;

/**
 * Make the given fields required for the given type
 */
export type WithNonNullFields<T, K extends keyof T> = T & {
  [P in K]-?: NonNullable<T[P]>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NoInfer<T> = [T][T extends any ? 0 : never];
export type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export function exhaustiveGuard(_value: never): never {
  throw new Error(`ERROR! Reached forbidden guard function with unexpected value: ${JSON.stringify(_value)}`);
}

/**
 * This not only filters out missing values, but does the type assertion to tell typescript that we've filtered them
 * out. The default implementation of `Array.prototype.filter` is `Array<T> -> Array<T>`, which is generally reasonable,
 * but here we're specifically stripping off some of the possibilities in that union, and we'd like to not have to
 * keep handling them down the line.
 *
 * @param values Array of nullable/undefinable values
 * @returns Array of values with no nulls/undefined
 */
export function compact<T>(values: Array<T | undefined | null>): Array<T> {
  return values.filter((x) => x !== null && x !== undefined) as Array<T>;
}

export type OnlyKeysOfType<T, K> = { [P in keyof T]: T[P] extends K ? P : never }[keyof T];

export type BooleanKeys<T> = OnlyKeysOfType<T, boolean>;
export type NumberKeys<T> = OnlyKeysOfType<T, number>;

/*
 * Sometimes, especially for 3rd party libraries, the compiler thinks that
 * a value will be present when it's actually not.
 * In those cases, you need a function to just say 'is this is secretly null'.
 * Tis is that function.
 */
export function valueIsSecretlyNull<T>(value: T): boolean {
  return !(value as T | null);
}
