import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  CaseConsult,
  CaseConsultStatus,
  useMarkCaseConsultCompleteMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { refetchQueries } from "Lib/RefetchQueries";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type CompleteCaseConsultButtonProps = {
  consult: PickTypename<CaseConsult, "id" | "status">;
};

export function CompleteConsultButton(props: CompleteCaseConsultButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "enums"]);

  const [completeConsult, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareMarkCaseConsultComplete,
    useMarkCaseConsultCompleteMutation({
      variables: {
        input: {
          caseConsultId: props.consult.id,
        },
      },
      refetchQueries: refetchQueries("consultRequests"),
    })
  );

  const showSpinner = remoteData.kind === "Loading";
  const disabled =
    props.consult.status !== CaseConsultStatus.AWAITING_DOCUMENTATION || remoteData.kind === "Loading";
  const variant =
    props.consult.status === CaseConsultStatus.AWAITING_DOCUMENTATION ? "contained" : "outlined";

  return (
    <ButtonWithSpinner
      showSpinner={showSpinner}
      variant={variant}
      color="secondary"
      disabled={disabled}
      onClick={() => completeConsult()}
    >
      {t("collaborativeCare:caseConsult.consultList.actions.complete")}
    </ButtonWithSpinner>
  );
}
