import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  Metric,
  MetricStatus,
  useUpdateOutcomesMetricStatusMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Button, DialogContent, Stack, Typography } from "@mui/material";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useEffectSimpleCompare } from "Lib/Hooks";

type AbandonMetricButton = {
  metric: Pick<Metric, "id" | "name">;
  onSuccess?: () => void;
};

export function AbandonMetricButton(props: AbandonMetricButton): ReactElement {
  const { t } = useTranslation(["outcomes"]);
  const [showForm, setShowForm] = React.useState(false);

  const [abandonMetric, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.outcomesUpdateMetricStatus,
    useUpdateOutcomesMetricStatusMutation({
      variables: {
        input: {
          metricId: props.metric.id,
          status: MetricStatus.RETIRED,
        },
      },
      refetchQueries: ["OutcomesMetrics"],
    })
  );

  useEffectSimpleCompare(() => {
    remoteData.caseOf({
      Success: () => {
        setTimeout(() => {
          setShowForm(false);
          if (props.onSuccess) {
            props.onSuccess();
          }
        }, 300);
      },
      _: () => {
        return;
      },
    });
  }, [remoteData.kind]);

  const disabled = remoteData.kind === "Loading";

  const button = (
    <Button onClick={() => setShowForm(true)} disabled={disabled} variant={"outlined"} color={"warning"}>
      {t("outcomes:abandonMetric.buttonTitle")}
    </Button>
  );

  const showSpinner = remoteData.kind === "Loading";

  return (
    <>
      {button}
      <ResponsiveDialog
        open={showForm}
        onClose={() => setShowForm(false)}
        title={t("outcomes:abandonMetric.form.title", {
          metric: props.metric.name,
        })}
      >
        <DialogContent>
          <Stack direction="column" spacing={1}>
            <Typography>
              {t("outcomes:abandonMetric.form.description", {
                metric: props.metric.name,
              })}
            </Typography>
            <Stack direction="row" spacing={1}>
              <ButtonWithSpinner
                showSpinner={showSpinner}
                variant={"contained"}
                color="error"
                disabled={disabled}
                onClick={() => abandonMetric()}
              >
                {t("outcomes:abandonMetric.form.confirm")}
              </ButtonWithSpinner>
              <Button
                onClick={() => setShowForm(false)}
                disabled={false}
                variant={"outlined"}
                color={"secondary"}
              >
                {t("outcomes:abandonMetric.form.abort")}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>
    </>
  );
}
