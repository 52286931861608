import { Button, Card, CardContent, CardHeader, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DataSourceResourceType,
  Patient,
  RelatedPerson,
  usePatientRelatedPeopleQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { RelatedPersonForm } from "../../CollaborativeCare/PatientDetails/DetailTabs/RelatedPeopleTab/RelatedPersonForm";
import { notificationPreferenceT, relatedPersonRelationshipT } from "GeneratedGraphQL/EnumTranslations";
import { useIsMobile } from "Shared/Responsive";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import TrueFalseIcon from "MDS/Icons/TrueFalseIcon";
import { IntegrationStatusBadgeInline } from "Integrations/IntegrationStatusBadge";
import { ImportHistoryId } from "Lib/Ids";
import { PatientDetailsDataGridContainer } from "CollaborativeCare/PatientDetails/PatientDetailsDataGridContainer";

type RelatedPeopleCardProps = {
  patient: PickTypename<Patient, "id">;
};

export default function RelatedPeopleCard(props: RelatedPeopleCardProps): ReactElement {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    usePatientRelatedPeopleQuery({ variables: { patientId: props.patient.id } })
  );
  return remoteData.caseOf({
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Failure: () => <ErrorMessage />,
    Success: (response) => {
      if (response.patient === null) {
        return <ErrorMessage />;
      }
      return <RelatedPeopleCardElement patient={props.patient} people={response.patient.relatedPeople} />;
    },
  });
}

function ErrorMessage(): ReactElement {
  const { t } = useTranslation(["common"]);
  return <Typography>{t("common:remoteData.failure")}</Typography>;
}

type PersonDetail = PickTypename<
  RelatedPerson,
  | "id"
  | "email"
  | "active"
  | "firstName"
  | "lastName"
  | "phoneNumber"
  | "relatedPersonRelationship"
  | "notificationPreference"
  | "notificationPreferenceWithDefault"
>;

type RelatedPeopleCardElementProps = {
  patient: PickTypename<Patient, "id">;
  people: ReadonlyArray<PersonDetail>;
};
function RelatedPeopleCardElement(props: RelatedPeopleCardElementProps): ReactElement {
  const { t } = useTranslation(["patients", "collaborativeCare", "enums"]);

  type RowDataType = {
    active: boolean;
    name: string;
    relationship: string;
    phoneNumber: string;
    email: string;
    person: PickTypename<
      RelatedPerson,
      | "id"
      | "email"
      | "active"
      | "firstName"
      | "lastName"
      | "phoneNumber"
      | "relatedPersonRelationship"
      | "notificationPreference"
      | "notificationPreferenceWithDefault"
    >;
  };

  const flex = useIsMobile() ? 0 : 1;

  const [showForm, setShowForm] = useState<{ show: boolean; personToEdit?: PersonDetail }>({ show: false });

  const columns: Array<GridColDef<RowDataType>> = [
    {
      field: "active",
      headerName: t("patients:user.active"),
      sortable: true,
      maxWidth: 70,
      renderCell: (params) => {
        return <TrueFalseIcon value={params.row.person.active} />;
      },
    },
    {
      field: "name",
      headerName: t("patients:user.name"),
      flex: flex,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params.row.name}
            <IntegrationStatusBadgeInline
              importHistoryId={params.row.person.id as unknown as ImportHistoryId}
              resourceType={DataSourceResourceType.RELATED_PERSON}
            />
          </>
        );
      },
    },
    {
      field: "relationship",
      headerName: t("patients:relatedPerson.relationship"),
      flex: flex,
      sortable: true,
    },
    {
      field: "phoneNumber",
      headerName: t("patients:user.phoneNumber"),
      flex: flex,
      sortable: true,
    },
    {
      field: "email",
      headerName: t("patients:user.email"),
      flex: flex,
      sortable: true,
    },
    {
      field: "notificationPreference",
      headerName: t("patients:user.notificationPreference"),
      flex: flex,
      sortable: true,
    },
    {
      field: "edit",
      headerName: "", // We don't need an column name, as it's a column of named buttons.
      maxWidth: 80, // Stuff like this has a pretty fixed size and it lets us shave off pixels.
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowForm({ show: true, personToEdit: params.row["person"] })}
          >
            {t("patients:relatedPerson.edit")}
          </Button>
        );
      },
    },
  ];

  const rows: ReadonlyArray<RowDataType> = props.people.map((person) => {
    return {
      id: person.id, // Datagrid seems to require an id.
      active: person.active,
      name: `${person.lastName}, ${person.firstName}`,
      relationship: relatedPersonRelationshipT(person.relatedPersonRelationship, t),
      phoneNumber: person.phoneNumber || "",
      email: person.email || "",
      notificationPreference: notificationPreferenceT(person.notificationPreferenceWithDefault, t),
      person: person,
    };
  });

  // This is the create button we'll show in the upper right corner.
  // Note that it also has a related person form attached, but it
  // only shows when show is true and there is no one we are trying
  // to show.
  const cardActionButton = (
    <Button
      variant="contained"
      color="secondary"
      onClick={() => setShowForm({ show: true })}
      sx={{ marginRight: "0.3em" }}
    >
      {t("patients:relatedPerson.add")}
    </Button>
  );

  // There's a long discussion about side effects being glossed over, but
  // the short answer is that by constantly nuking the actual form component,
  // we'll maintain a stable starting state with no weirdness.
  // We can then simply apply the current state to a fresh form component
  // and everything just wires up correctly with no fuss.
  const form = showForm.show ? (
    <RelatedPersonForm
      personToEdit={showForm.personToEdit}
      closeForm={() => {
        setShowForm({ show: false });
      }}
      patient={props.patient}
    />
  ) : null;

  return (
    <Card>
      <CardHeader
        title={t("collaborativeCare:patientDetails.cards.relatedPeople")}
        action={cardActionButton}
      />
      <CardContent>
        <PatientDetailsDataGridContainer>
          <DataGrid
            rows={rows}
            columns={columns}
            autoHeight
            disableRowSelectionOnClick={true}
            hideFooter={true}
            sx={{
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
              overflowX: "scroll",
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "lastName", sort: "asc" }],
              },
            }}
          />
        </PatientDetailsDataGridContainer>
        {form}
      </CardContent>
    </Card>
  );
}
