import React, { ReactNode, ReactElement } from "react";
import { useTheme, Theme } from "@mui/material";
import * as NEL from "../NonEmptyList";
import * as d3 from "d3";
import { Link } from "MDS/Link";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { styled } from "@mui/material/styles";
import { Maybe } from "seidr";

const StyledBorder = styled("div")(() => ({
  position: "absolute",
  right: 0,
  top: 0,
  bottom: 0,
  width: 1,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
}));

type ColumnProps = {
  height: number;
  width: number;
  color: string;
  backgroundImage: Maybe<string>;
  onHover: () => void;
  children: ReactNode;
  onColumnClickRoute?: string;
};

const StyledLinkColumn = styled(Link)(() => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
}));
const StyledNonLinkColumn = styled("div")(() => ({
  position: "relative",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "column",
}));
function Column(props: ColumnProps): ReactElement {
  const { height, width, color, onColumnClickRoute, onHover, children, backgroundImage } = props;
  const backgroundColor = color;

  const content = (
    <>
      <div
        style={{ height, width, backgroundColor, backgroundImage: backgroundImage.getOrElse("") }}
        data-testid={"heatmap-column-fill"}
      />
      {children}
      <StyledBorder />
    </>
  );

  const sharedParentParams = {
    onMouseOver: onHover,
    "data-testid": "heatmap-column",
  };

  return onColumnClickRoute ? (
    <StyledLinkColumn {...sharedParentParams} to={onColumnClickRoute}>
      {content}
    </StyledLinkColumn>
  ) : (
    <StyledNonLinkColumn {...sharedParentParams}>{content}</StyledNonLinkColumn>
  );
}

const StyledColumnContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
}));

type HeatmapBaseProps<D> = {
  maxWidth: number;
  height: number;
  idealColumnWidth: number;
  data: NEL.NonEmptyList<D>;
  datumToBackgroundImage: (d: D, theme: Theme) => Maybe<string>;
  datumToColor: (d: D, theme: Theme) => string;
  onColumnClickRoute?: (d: D) => string | undefined;
  onColumnHover?: (d: D) => void;
  renderColumn?: (d: D, i: number) => ReactNode;
  datumToHeight?: (d: D, maxHeight: number) => number;
};

function HeatmapBase<D>(props: HeatmapBaseProps<D>): ReactElement {
  const {
    maxWidth,
    idealColumnWidth,
    data,
    onColumnHover,
    datumToColor,
    renderColumn,
    datumToHeight,
    datumToBackgroundImage,
  } = props;

  const theme = useTheme();

  const flexWidth = maxWidth / data.length;

  // If the width of the chart exceeds the max width , the column width is
  // adjusted to fit within the alotted space.
  const columnWidth = flexWidth > idealColumnWidth ? idealColumnWidth : flexWidth;

  const columns = NEL.toArray(data).map((datum, i) => {
    const innerColumn = renderColumn ? renderColumn(datum, i) : null;

    let height = props.height;

    // if we have been given a datum to height fuction, we want to have a minumum height for bars
    // for now we will use 5% of max height or 2 px, which ever is larger
    if (datumToHeight) {
      const heightOffset = Math.max(props.height * 0.05, 2);
      // we also want to prevent bad height functions from going out of bounds
      height = Math.min(datumToHeight(datum, props.height - heightOffset) + heightOffset, props.height);
    }

    const onColumnClickRoute = props.onColumnClickRoute ? props.onColumnClickRoute(datum) : undefined;

    return (
      <Column
        key={i}
        width={columnWidth}
        height={height}
        color={datumToColor(datum, theme)}
        backgroundImage={datumToBackgroundImage(datum, theme)}
        onColumnClickRoute={onColumnClickRoute}
        onHover={() => {
          if (onColumnHover) onColumnHover(datum);
        }}
      >
        {innerColumn}
      </Column>
    );
  });

  return (
    <StyledColumnContainer data-testid="heatmap" style={{ height: props.height, maxWidth }}>
      {columns}
    </StyledColumnContainer>
  );
}

type HeatmapProps<D> = {
  maxWidth: number;
  height: number;
  idealColumnWidth: number;
  data: NEL.NonEmptyList<D>;
  datumToColor: (d: D, theme: Theme) => string;
  onColumnClickRoute?: (d: D) => string | undefined;
  onColumnHover?: (d: D) => void;
  columnHeightPercentage?: (d: D) => number;
  datumToHeight?: (d: D, maxHeight: number) => number;
  datumToBackgroundImage: (d: D, theme: Theme) => Maybe<string>;
};

function Heatmap<D>(props: HeatmapProps<D>): ReactElement {
  return <HeatmapBase {...props} />;
}

// -- DottedHeatmap -----------------------------------------------------------
//
const Dot = styled("div")<{
  y: number;
  color: string;
  radius: number;
}>(({ y, color, radius }) => {
  const halfRadius = radius / 2;
  return {
    top: y - halfRadius,
    marginLeft: -halfRadius,
    background: color,
    width: radius,
    height: radius,
    borderRadius: halfRadius,
    position: "absolute",
    left: "50%",
  };
});

type WithValue = {
  value: number;
};

type DottedHeatmapProps<D extends WithValue> = {
  maxWidth: number;
  height: number;
  idealColumnWidth: number;
  data: NEL.NonEmptyList<D>;
  datumToColor: (d: D, theme: Theme) => string;
  datumToDotColor: (d: D, theme: Theme) => string;
  dotRadius: number;
  yDomain: [number, number];
  onColumnClickRoute?: (d: D) => FeedbackReportRoute | undefined;
  datumToBackgroundImage: (d: D, theme: Theme) => Maybe<string>;
  onColumnHover?: (d: D) => void;
};

function DottedHeatmap<D extends WithValue>(props: DottedHeatmapProps<D>): ReactElement {
  const {
    maxWidth,
    height,
    idealColumnWidth,
    yDomain,
    data,
    datumToColor,
    datumToDotColor,
    dotRadius,
    onColumnClickRoute,
    onColumnHover,
    datumToBackgroundImage,
  } = props;
  const y = d3.scaleLinear().domain(yDomain).range([height, 0]);
  const theme = useTheme();

  return (
    <HeatmapBase
      maxWidth={maxWidth}
      height={height}
      idealColumnWidth={idealColumnWidth}
      data={data}
      datumToColor={datumToColor}
      datumToBackgroundImage={datumToBackgroundImage}
      onColumnClickRoute={onColumnClickRoute}
      onColumnHover={onColumnHover}
      renderColumn={(d: D) => {
        return (
          <Dot
            y={y(d.value)}
            color={datumToDotColor(d, theme)}
            radius={dotRadius}
            data-testid="heatmap-dot"
          />
        );
      }}
    />
  );
}

export default Heatmap;
export { HeatmapBase, Heatmap, DottedHeatmap };
