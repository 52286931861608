import React, { CSSProperties, ReactElement, useState } from "react";
import { format, parse } from "date-fns";
import { Box, Button, Tooltip, Typography, useTheme } from "@mui/material";
import { TaskCardBack } from "./Tasks/TaskCard/TaskCardBack";
import { TaskDetails } from "./Tasks/TaskCard/TaskCard";
import { useTranslation } from "react-i18next";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { TimeEntryLog } from "GeneratedGraphQL/SchemaAndOperations";
import { TimeEntryLogEditDialog } from "./TimeEntry/TimeEntryLogEditDialog";
import { PickTypename } from "type-utils";

// This format is used when we want to use dates as a key-like object, IE, for days.
const DATE_KEY_FORMAT = "yyyy-MM-dd";

// This enum determines if the dialog we'll open when clicking an event is the task
// dialog or the TEL dialog.
export const enum EventDialogType {
  Task = 1,
  TimeEntryLog = 2,
}

// In theory, this could be anything we might want to draw as an event, but at present, we only
// care about time entry logs. There's a lot of TEL-specific stuff right now, but we could back
// a lot of that out if necessary and make this concept more generic.
export type TimelineEvent = PickTypename<
  TimeEntryLog,
  | "id"
  | "startTime"
  | "clientStartTime"
  | "durationSeconds"
  | "endTime"
  | "blockedMinutes"
  | "durationReviewStatus"
  | "reviewedAt"
  | "unreviewedDurationSeconds"
> & {
  workFor: TaskDetails;
};

type TimelineProps = {
  events: ReadonlyArray<TimelineEvent>;
  onLoadMoreEventsClicked?: () => void;
  eventDialogType?: EventDialogType; // What dialog do we open when a user clicks on an event?
};

// Draws out a timeline given a set of events. Events can only be drawn
// if they have a definite start time and end time. Events that do not have
// an end time will simply not be drawn.
export function Timeline(props: TimelineProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const eventDialogType = props.eventDialogType || EventDialogType.Task;

  // This is the button that we'll use so the user can indicate that they want
  // to see older events.
  let showOlderEventsButton = null;
  if (props.onLoadMoreEventsClicked) {
    showOlderEventsButton = (
      <Box textAlign="center">
        <Button
          color="secondary"
          variant="contained"
          onClick={props.onLoadMoreEventsClicked}
          sx={{ marginBottom: "2em" }}
        >
          {t("collaborativeCare:timeline.showOlderEventsButton")}
        </Button>
      </Box>
    );
  }

  // We're not going to attempt to draw active events, so just filter those out.
  const validEvents = props.events.filter((event) => {
    return !!event.endTime;
  });

  if (validEvents.length == 0) {
    return (
      <Box>
        {showOlderEventsButton}
        <Typography>{t("collaborativeCare:timeline.noEvents")}</Typography>
      </Box>
    );
  }

  // We're going to make a horrible construct to hold everything by day. This
  // will make it easier for us to insert the right amount of breaks we need
  // to make this whole monstrosity flow. In essence, this contains a list of days
  // with all of the corresponding events.
  // yyyy-MM-dd => [TimelineEvent]
  const eventMap = new Map<string, Array<TimelineEvent>>();
  validEvents.map((event) => {
    const date = `${format(event.startTime, DATE_KEY_FORMAT)}`;

    // We might need to actually create a default empty array for this date.
    if (!eventMap.has(date)) {
      eventMap.set(date, []);
    }

    // Now we can actually stick our event into the key.
    const dateArray = eventMap.get(date);
    if (dateArray) {
      dateArray.push(event);
      // We want to make sure that we always have the dates sorted themselves, for our sanity later.
      // If we don't do this here we'll need to rerun through the entire object again.
      dateArray.sort((a, b) => (a.startTime < b.startTime ? -1 : 1));
      // This whole thing above is by-reference and in-place, so this is actually unnecessary,
      // but written for clarity.
      eventMap.set(date, dateArray);
    }
  });

  // This bullshit just makes sure that we actually know what the ordered set of dates
  // actually are. We need to unwrap the keys this way because we only have access
  // to an iterator. We have to completely seperately track this ordered array to grab
  // our dates in the correct order.
  const dates: Array<string> = [];
  for (const date of eventMap.keys()) {
    dates.push(date);
  }
  dates.sort(); // in-place

  // Now we need to create the actual elements we want.
  const timelineElements = dates.map((date) => {
    const events = eventMap.get(date) || [];
    return <TimelineDay date={date} events={events} key={date} eventDialogType={eventDialogType} />;
  });

  return (
    <Box>
      {showOlderEventsButton}
      {timelineElements}
    </Box>
  );
}

// Which column are we reserving for displaying the actual time?
const HOUR_COLUMN = 1;

// How many hours outside events do we want to show?
// This is presently set to zero, and does nothing, but the math has already been mathed, so this is
// left hooked up if we want to use it via configuration later.
const HOUR_BUFFER = 0;

// How tall are our rows?
const ROW_HEIGHT_EM = 6;

type TimelineDayProps = {
  date: string;
  events: ReadonlyArray<TimelineEvent>;
  eventDialogType: EventDialogType;
};

// This represents a single day on the timeline.
function TimelineDay(props: TimelineDayProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation(["common"]);

  // We need to figure out how many hours we're actually going to use.
  // We'll use this when drawing the actual hours column, as well as when
  // we're applying events to our hours.
  const hours = filteredHours(props.events);

  const eventElements = props.events.map((event, eventIndex) => {
    return (
      <TimelineEvent
        event={event}
        hours={hours}
        key={`${props.date}_${eventIndex}`}
        eventDialogType={props.eventDialogType}
      />
    );
  });

  // We basically have to use raw CSS grid, as MUI isn't interested in providing row-spanning
  // functionality in their implementation and straight up recommends using raw CSS.
  const gridStyle: CSSProperties = {
    display: "grid",
    gridTemplateColumns: "8em", // This just makes it so our very first column is 8em, others will use the auto.
    gridAutoColumns: "12em",
    gridAutoRows: `${ROW_HEIGHT_EM}em`,
    // This helps protect against a too-short event bleeding into the next day near the end of an hour.
    // The more complicated way to handle that would be to do some back calculation to move the event up instead,
    // but I am lazy and this will suffice for now.
    marginBottom: "2em",
  };

  // We are getting a string date which will be interpreted incorrectly by internationalization, but
  // everything seems fine if we just manually parse the date out with datefns, so here we are.
  const date = parse(props.date, DATE_KEY_FORMAT, new Date());

  return (
    <>
      <Typography variant="h1">{t("common:date.tiny", { date: date })}</Typography>
      <div style={gridStyle}>
        <Hours hours={hours} />
        {eventElements}
      </div>
      <Box borderTop="1px solid" marginBottom="1em" borderColor={theme.palette.timeline.dayBorder}></Box>
    </>
  );
}

// Determines the set of hours we care to show for a given set of events.
// Events are assumed to be within a single day.
function filteredHours(events: ReadonlyArray<TimelineEvent>): ReadonlyArray<number> {
  // What's a range?
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  // We're going to cut out irrelevant hours so we can fit more physical events into the view.
  // For now, we just want to find the first and last event in a given day.
  const minStartTimeEvent = events.reduce((p, c) => {
    return p.startTime < c.startTime ? p : c;
  });
  // We don't use the maxStartTimeEvent unless we somehow are trying to draw events that
  // have no end time. Those technically should not get here, but I don't feel like having
  // multiple types, so we'll just do this and we can rewrite it later if it's annoying.
  const maxStartTimeEvent = events.reduce((p, c) => {
    return p.startTime > c.startTime ? p : c;
  });
  const maxEndTimeEvent = events.reduce((p, c) => {
    if (p.endTime && c.endTime) {
      return p.endTime > c.endTime ? p : c;
    }
    return p.endTime ? p : c;
  });
  const startHour = minStartTimeEvent.startTime.getHours() - HOUR_BUFFER;
  const endHourTime = maxEndTimeEvent.endTime
    ? maxEndTimeEvent.endTime.getHours()
    : maxStartTimeEvent.startTime.getHours();
  const endHour = endHourTime + HOUR_BUFFER;

  // Now we can filter our hours down to the set between our first and last events.
  const filteredHours = hours.filter((hour) => {
    return hour >= startHour && hour <= endHour;
  });
  return filteredHours;
}

type HoursProps = {
  hours: ReadonlyArray<number>;
};

// Given a set of events, generates the hours elements. This basically
// fills out the first column.
function Hours(props: HoursProps): ReactElement {
  // This will be the actual representation of hours running down the left side of the timeline.
  // They will take up the first column on each row.
  const hoursElements = props.hours.map((hour, index) => {
    // This is hacky, but we just want a nicely formatted hour block.
    // What we're going to do is make a new date for today with the hour set, and then use that
    // date to rip out a nicely formatted version of the hour.
    const now = new Date();
    const specificHourDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour);
    const hourText = format(specificHourDate, "h b");

    // Grid rows are 1 based, and our hours are 0 based.
    const ZERO_TO_ONE_ADJUSTMENT = 1;

    const hourStyle: CSSProperties = {
      gridColumnStart: HOUR_COLUMN,
      gridColumnEnd: HOUR_COLUMN,
      gridRowStart: index + ZERO_TO_ONE_ADJUSTMENT,
      gridRowEnd: index + ZERO_TO_ONE_ADJUSTMENT,
      borderTop: "1px solid",
      marginRight: "1em", // This gets added to the margin on the events to give a nice gutter.
    };
    return (
      <div style={hourStyle} key={hour}>
        <Typography variant="body1">{hourText}</Typography>
      </div>
    );
  });
  return <>{hoursElements}</>;
}

type TimelineEventProps = {
  // The actual event we want to draw.
  event: TimelineEvent;
  // These are the valid hours for the day. This just helps with offset calculations
  // so we can put our event in the correct rows.
  hours: ReadonlyArray<number>;
  eventDialogType: EventDialogType;
};

// This represents a single timeline event, within some TimelineDay.
// We make no attempt to deal with events that occur across a day, as our server code
// will always split these into two separate events if they are TEL's, which is what
// this is designed to support. If we need that functionality in the future, it will
// need to be added.
function TimelineEvent(props: TimelineEventProps): ReactElement {
  const theme = useTheme();
  const { t } = useTranslation(["common", "collaborativeCare"]);

  // This is rigging for opening and closing the task card back modal.
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const onClick = () => {
    setOpen(true);
  };

  // We can determine an hour offset based on the hours we're getting passed in.
  // We basically need to subtract this out from our event time to end up in the correct
  // row.
  const startHourOffset = props.event.startTime.getHours() - (props.hours[0] || 0) + HOUR_BUFFER;

  // We also need to calculate what rows are actual event lives in. It's basically just the
  // startHourOffset, which is start time based, plus the difference in raw hours between start and end time.
  let endHourOffset = startHourOffset;
  if (props.event.endTime) {
    endHourOffset = startHourOffset + (props.event.endTime.getHours() - props.event.startTime.getHours());
  }

  // We need to calculate our start and end times against our row heights to determine
  // how much margin to apply to make the box our event is going to sit in appear accurate.
  const marginTopValue = ROW_HEIGHT_EM * (props.event.startTime.getMinutes() / 60.0);
  let marginBottomValue = 0;
  if (props.event.endTime) {
    marginBottomValue = ROW_HEIGHT_EM - ROW_HEIGHT_EM * (props.event.endTime.getMinutes() / 60.0);
  }

  // Grid rows are 1 based, and our hours are 0 based.
  const ZERO_TO_ONE_ADJUSTMENT = 1;

  // The values we give are actually not cells but instead lines of the grid. We need to add
  // an extra 1 for anything indicating an end because it's the second line in the cell.
  // Our math is really cell based.
  const END_LINE_ADJUSTMENT = 1;

  // Whether or not this event is missing billable minutes. We'll use this to style those events differently.
  const eventMissingBillableMinutes = props.event.blockedMinutes && props.event.blockedMinutes > 0;

  // Note that by not bothering to specify any columns, we are guaranteed to get our
  // events drawn into a new column.
  const eventStyle: CSSProperties = {
    gridRowStart: startHourOffset + ZERO_TO_ONE_ADJUSTMENT,
    gridRowEnd: endHourOffset + ZERO_TO_ONE_ADJUSTMENT + END_LINE_ADJUSTMENT,
    marginLeft: "1em",
    marginTop: `${marginTopValue}em`,
    marginBottom: `${marginBottomValue}em`,
    minHeight: "3.2em", // This won't respect the end of an hour, but will make the boxes look nice.
    padding: "0.2em",
    color: theme.palette.timeline.eventText,
    backgroundColor: theme.palette.timeline.event,
    borderRadius: "4px",
    cursor: "pointer",
  };
  const startTime = format(props.event.startTime, "hh:mm b");
  const endTime = props.event.endTime ? format(props.event.endTime, "hh:mm b") : "";

  // These don't seem to take effect when applied to the outer box, so we apply them directly
  // to the typography element.
  const eventTypographyStyle: CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const badgeTooltip = (
    <Box>
      <Typography variant="body2">{t("collaborativeCare:timeline.badge.top")}</Typography>
      <ul>
        <li>{t("collaborativeCare:timeline.badge.firstNote")}</li>
        <li>{t("collaborativeCare:timeline.badge.secondNote")}</li>
      </ul>
      <Typography variant="body2">{t("collaborativeCare:timeline.badge.footer")}</Typography>
    </Box>
  );

  const badgeStyle: CSSProperties = {
    float: "right",
    backgroundColor: theme.palette.timeline.eventNotBillable,
    borderRadius: "4px",
    border: "1px solid white",
    borderColor: theme.palette.timeline.eventText,
    maxHeight: "1.7em",
  };
  const badgeIconStyle: CSSProperties = {
    color: theme.palette.timeline.eventText,
  };
  const badge = eventMissingBillableMinutes ? (
    <Tooltip title={badgeTooltip}>
      <Box sx={badgeStyle}>
        <PriorityHighIcon style={badgeIconStyle} />
      </Box>
    </Tooltip>
  ) : null;

  let eventDialog = (
    <TaskCardBack task={props.event.workFor} onClose={onClose} open={open} openDetails={setOpen} />
  );
  if (props.eventDialogType == EventDialogType.TimeEntryLog) {
    eventDialog = (
      <TimeEntryLogEditDialog
        open={open}
        onClose={onClose}
        onSuccess={() => setTimeout(() => setOpen(false), 300)}
        task={props.event.workFor}
        timeEntryLog={props.event}
        allowDiscardTime={false}
      />
    );
  }

  // The TaskCardBack needs to be outside of the Box containing the onClick handler or there will be
  // issues closing the modal due to event propagation.
  return (
    <>
      <Box style={eventStyle} onClick={onClick}>
        {badge}
        <Typography style={eventTypographyStyle} variant="body2">
          {props.event.workFor.title}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: theme.palette.timeline.eventText }}>
          {startTime} - {endTime}
        </Typography>
      </Box>
      {eventDialog}
    </>
  );
}
