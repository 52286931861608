import { Stack, Typography, Box, TextField } from "@mui/material";
import { RichText } from "MDS/RichText";
import React, { BaseSyntheticEvent, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoteDetails } from "./TaskCard";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateIcon from "@mui/icons-material/Create";
import { refetchQueries } from "Lib/RefetchQueries";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { useDeleteTaskNoteMutation, useEditTaskNoteMutation } from "GeneratedGraphQL/SchemaAndOperations";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useIsDesktop, useIsMobile } from "Shared/Responsive";

export function EditableTaskCardNote(props: { note: NoteDetails }): ReactElement {
  const [editingNote, setEditingNote] = useState<boolean>(false);
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const isMobile = useIsMobile();

  const originalText = props.note.text;
  const [newText, setNewText] = useState<string>(originalText);

  const [updateNote, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditNote,
    useEditTaskNoteMutation({
      variables: { input: { noteId: props.note.id, text: newText } },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const [deleteNote] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDeleteNote,
    useDeleteTaskNoteMutation({
      variables: { input: { noteId: props.note.id } },
      refetchQueries: refetchQueries("tasks"),
    })
  );

  remoteData.caseOf({
    NotAsked: () => [],
    Loading: () => [],
    Failure: () => {
      return [];
    },
    Success: (response) => {
      if (response?.note.text && !editingNote && newText != response.note.text) {
        setNewText(response.note.text);
      }
      return [];
    },
  });

  const toDelete = newText.length == 0;
  const updateInProgress = remoteData.kind === "Loading";

  const exitEditingAndSaveHandler = function () {
    if (toDelete) {
      deleteNote();
    } else if (newText != originalText) {
      updateNote();
    }
    setEditingNote(false);
  };

  const exitEditingWithoutSavingHandler = function () {
    setEditingNote(false);
    setNewText(originalText);
  };

  const textChangeHandler = function (e: BaseSyntheticEvent) {
    setNewText(e.target.value);
  };

  let noteBlock = (
    <Box border={`1px solid`} borderRadius={0.5} padding={0.5} overflow="auto">
      <RichText>{newText}</RichText>
    </Box>
  );
  const editButton = (
    <ExpandingIconButton
      color="secondary"
      variant="outlined"
      icon={<CreateIcon />}
      onClick={() => {
        setEditingNote(true);
      }}
    >
      {t("common:actions.edit")}
    </ExpandingIconButton>
  );
  let actionBlock = (
    <Stack direction="column" spacing={1} alignItems="start">
      {editButton}
    </Stack>
  );

  if (isMobile) {
    actionBlock = <Stack direction="row-reverse">{editButton}</Stack>;
  }

  if (editingNote) {
    noteBlock = (
      <TextField
        fullWidth={true}
        autoFocus={true}
        multiline={true}
        onChange={textChangeHandler}
        disabled={updateInProgress}
        value={newText}
        helperText={toDelete ? t("collaborativeCare:tasks.noteWillBeDeleted") : null}
      >
        {props.note.text}
      </TextField>
    );

    const saveButton = (
      <ExpandingIconButton
        color="secondary"
        variant="outlined"
        icon={<CheckIcon />}
        onClick={exitEditingAndSaveHandler}
      >
        {t("common:actions.save")}
      </ExpandingIconButton>
    );
    const cancelButton = (
      <ExpandingIconButton
        color="secondary"
        variant="outlined"
        icon={<CancelIcon />}
        onClick={exitEditingWithoutSavingHandler}
      >
        {t("common:actions.cancel")}
      </ExpandingIconButton>
    );

    actionBlock = (
      <Stack direction="column" spacing={1} alignItems="start">
        {saveButton}
        {cancelButton}
      </Stack>
    );

    if (isMobile) {
      actionBlock = (
        <Stack direction="row-reverse" spacing={1}>
          {cancelButton}
          {saveButton}
        </Stack>
      );
    }
  }
  const forTimeEntry = props.note.timeEntry ? <>{t("collaborativeCare:tasks.details.forTime")} </> : <></>;
  const icon = props.note.timeEntry ? <AddCommentIcon /> : <></>;

  if (useIsDesktop()) {
    return (
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={0.75} paddingLeft={1}>
          <Box width="1.375rem">{icon}</Box>
          <Typography>
            {props.note.author.name}, {forTimeEntry}{" "}
            {t("common:date.long", { date: props.note.timeEntry?.startTime || props.note.createdAt })}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={1} paddingLeft={3}>
          <Box flexGrow={1}>{noteBlock}</Box>
          <Box flexBasis="10rem">{actionBlock}</Box>
        </Stack>
      </Stack>
    );
  } else {
    return (
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" spacing={0.75} paddingLeft={1}>
          <Box width="1.375rem">{icon}</Box>
          <Typography>
            {props.note.author.name}, {forTimeEntry}{" "}
            {t("common:date.long", { date: props.note.timeEntry?.startTime || props.note.createdAt })}
          </Typography>
        </Stack>
        {noteBlock}
        {actionBlock}
      </Stack>
    );
  }
}
