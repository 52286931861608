import { Maybe, Just, Nothing } from "seidr";
import * as Id from "Lib/Id";
import * as NEL from "Lib/NonEmptyList";
import { compareAsc } from "date-fns";
import { last, justs, groupBy } from "Lib/Utils";
import { Theme } from "@mui/material";
import { QuestionResponseDetail, toQuestionResponseDetail } from "./QuestionResponse";
import { AlertColors } from "Style/Theme/palette";
import { ParticipantByUserIdMap } from "FeedbackReport/FeedbackReportContext";
import * as DateQuantizedData from "Lib/DateQuantizedData";
import {
  ClinicalAlertDetailsFragmentFragment,
  ClinicalAlertHistoryFragmentFragment,
  ClinicalAlertType,
  ClinicalAlertValue,
  Invitation,
  User,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeScaleId, ScaleGroupId } from "Lib/Ids";
import { PickTypename, compact } from "type-utils";

export type CareEpisodeClinicalAlertId = Id.Id<"CareEpisodeClinicalAlert">;

export type CareEpisodeClinicalAlertBase = {
  id: CareEpisodeClinicalAlertId;
};

export type InvitationDateAndUser = PickTypename<Invitation, "id" | "dateCompleted" | "dateBegun"> & {
  user: PickTypename<User, "id">;
};

export type CareEpisodeClinicalAlertDetails = CareEpisodeClinicalAlertBase & {
  status: ClinicalAlertValue;
  date: Date;
  responses: ReadonlyArray<QuestionResponseDetail>;
  invitations: ReadonlyArray<InvitationDateAndUser>;
};

export type CareEpisodeClinicalAlertHistoryId = CareEpisodeScaleId;

export type CareEpisodeClinicalAlertHistory = {
  id: CareEpisodeClinicalAlertHistoryId;
  alertType: ClinicalAlertType;
  scaleGroupId: Maybe<ScaleGroupId>;
  scaleMnemonic: Maybe<string>; // Necessary for now to allow us to select the correct alert from within a group
  latest: Maybe<CareEpisodeClinicalAlertDetails>;
  history: ReadonlyArray<CareEpisodeClinicalAlertDetails>;
};

export type QuantizedAlert = DateQuantizedData.DateQuantizedDatum<CareEpisodeClinicalAlertDetails>;

// -- Helpers -----------------------------------------------------------------

function alertTitle(alertType: ClinicalAlertType): string {
  switch (alertType) {
    case ClinicalAlertType.SUICIDALITY:
      return "Suicidality";
    case ClinicalAlertType.ALLIANCE:
      return "Alliance";
    case ClinicalAlertType.MEDICATIONS:
      return "Medications";
    case ClinicalAlertType.SUBSTANCE_USE:
      return "Substance Use";
  }
}

function alertColor(value: ClinicalAlertValue, theme: Theme): AlertColors {
  const alertTheme = theme.palette.report.alerts;

  switch (value) {
    case ClinicalAlertValue.ALERT:
      return alertTheme.alert;
    case ClinicalAlertValue.NO_ALERT:
      return alertTheme.noAlert;
    case ClinicalAlertValue.NOT_SUPPORTED:
      return alertTheme.notSupported;
  }
}

function alertValue(alertType: ClinicalAlertType, value: ClinicalAlertValue): string {
  const endorsed = value === ClinicalAlertValue.ALERT;

  switch (alertType) {
    case ClinicalAlertType.SUICIDALITY:
      return endorsed ? "At Risk" : "Minimal";
    case ClinicalAlertType.ALLIANCE:
      return endorsed ? "At Risk" : "Strong";
    case ClinicalAlertType.MEDICATIONS:
      return endorsed ? "Concerns" : "No Concerns";
    case ClinicalAlertType.SUBSTANCE_USE:
      return endorsed ? "At Risk" : "Minimal";
  }
}

function lastAlert(completions: NEL.NonEmptyList<QuantizedAlert>): Maybe<CareEpisodeClinicalAlertDetails> {
  return last(
    justs(
      NEL.toArray(
        completions.map((datum) =>
          datum.caseOf({
            Value: Just,
            Blank: Nothing,
          })
        )
      )
    )
  );
}

// -- Transformers ------------------------------------------------------------

function toCareEpisodeClinicalAlertDetails(
  raw: ClinicalAlertDetailsFragmentFragment,
  participantsByUserId: ParticipantByUserIdMap
): CareEpisodeClinicalAlertDetails {
  const invitationDates = compact(raw.invitations.map((i) => i.dateCompleted ?? i.dateBegun)).sort(
    (a, b) => a.valueOf() - b.valueOf()
  );
  // It should be impossible to end up here without at least one non-null invitation date (otherwise how did we get any
  // answers to show?) but fall back to session in case.
  const alertDate = invitationDates[invitationDates.length - 1] ?? raw.targetDate;

  return {
    id: Id.unsafeFromUuid(raw.id),
    status: raw.status,
    date: alertDate,
    responses: raw.answers.map((r) => toQuestionResponseDetail(r, participantsByUserId)),
    invitations: raw.invitations,
  };
}

function toCareEpisodeClinicalAlertHistoryDetails(
  raw: ClinicalAlertHistoryFragmentFragment,
  participantsByUserId: ParticipantByUserIdMap
): CareEpisodeClinicalAlertHistory {
  const latest = Maybe.fromNullable(raw.latest).map((r) =>
    toCareEpisodeClinicalAlertDetails(r, participantsByUserId)
  );

  const history = raw.history.map((r) => toCareEpisodeClinicalAlertDetails(r, participantsByUserId));

  return {
    id: raw.id,
    alertType: raw.alertType,
    latest,
    scaleGroupId: Maybe.fromNullable(raw.scale?.scaleGroup?.id),
    scaleMnemonic: Maybe.fromNullable(raw.scale?.mnemonic),
    history: latest
      .map((l) => history.concat(l))
      .getOrElse(history)
      .sort((a, b) => compareAsc(a.date, b.date)),
  };
}

function toQuantizedAlerts(
  dates: NEL.NonEmptyList<Date>,
  alerts: ReadonlyArray<CareEpisodeClinicalAlertDetails>
): NEL.NonEmptyList<QuantizedAlert> {
  return DateQuantizedData.quantize(dates, [...alerts]);
}

/**
 * We can have multiple alerts from the same group - for example, the SFSS has both a child, parent, and combined alert status.
 * We want to only show one from each group. In future, this might vary as e.g. when filtering by a given respondent we may want
 * to only show the alert for that respondent.
 */
function deduplicateByGroup(
  alerts: ReadonlyArray<CareEpisodeClinicalAlertHistory>
): ReadonlyArray<CareEpisodeClinicalAlertHistory> {
  return Object.values(groupBy((alert) => alert.scaleGroupId.toString(), alerts)).flatMap((ag) => {
    const alertGroup = ag || [];
    return NEL.fromArray(alertGroup)
      .map((ah) => NEL.head(ah).scaleGroupId)
      .caseOf({
        Nothing: () => alertGroup, // If they're not in a group, show all of them.
        Just: () => {
          // If they are in a group, we should only show one. For now, hard code it so that we pick the SFSS combined and drop the others.
          const sfss = alertGroup.find(
            (alert) => alert.scaleMnemonic.getOrElse(null) === "sfssSuicideCombined"
          );

          const substanceUse = alertGroup.find(
            (alert) => alert.scaleMnemonic.getOrElse(null) === "sfssSubstanceUseCombined"
          );

          if (sfss || substanceUse) {
            const result = [];

            if (sfss) {
              result.push(sfss);
            }

            if (substanceUse) {
              result.push(substanceUse);
            }

            return result;
          } else {
            return alertGroup;
          }
        },
      });
  });
}

export {
  toCareEpisodeClinicalAlertDetails,
  toCareEpisodeClinicalAlertHistoryDetails,
  toQuantizedAlerts,
  deduplicateByGroup,
  alertTitle,
  alertValue,
  alertColor,
  lastAlert,
  ClinicalAlertType,
  ClinicalAlertValue,
};
