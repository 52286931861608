import { Table } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * A table that alernates row colors. Can be used on either a paper background (e.g., <Dialog>, <Card>) or just straight
 * on the background of a page.
 */
export const ShadedTable = styled(Table)(({ theme }) => ({
  // The very long selector here is so that we don't also pick up tds in nested tables.
  "& > tbody > tr:nth-of-type(even) > td": {
    backgroundColor: theme.palette.background.default,
  },
  "& > tbody > tr:nth-of-type(odd) > td": {
    backgroundColor: theme.palette.background.paper,
  },
}));
