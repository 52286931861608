import { Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import React, { ReactElement } from "react";
import { FindingDatagram } from "../Findings";
import { CreateTaskLaterAction } from "./Actions/CreateTaskLaterAction";
import { StartTaskNowAction } from "./Actions/StartTaskNowAction";
import { useTranslation } from "react-i18next";
import { useCurrentProviderId } from "AppSession/AppSession";
import { DismissAction } from "./Actions/DismissAction";
import Link from "MDS/Link";
import { PatientHeaderStatus } from "CollaborativeCare/PatientReference/PatientHeaderStatus";
import { SendNotificationAndSnoozeAction } from "./Actions/SendNotificationAndSnoozeAction";

type MonthlyAssessmentNotCompletedFindingProps = {
  finding: FindingDatagram;
};
export function MonthlyAssessmentNotCompletedFinding(
  props: MonthlyAssessmentNotCompletedFindingProps
): ReactElement | null {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const currentProviderId = useCurrentProviderId();

  if (!props.finding.patient || !currentProviderId) {
    return null;
  }

  // This is just part of a common payload for our actions, so we might
  // as well define it here.
  const reminderTask = {
    title: t("collaborativeCare:findings.findings.monthlyAssessmentIncomplete.reminderTask.title"),
    body: `[${props.finding.patient.name}](/app/cocm/patient/${props.finding.patient.id}) ${t(
      "collaborativeCare:findings.findings.monthlyAssessmentIncomplete.reminderTask.body"
    )}`,
    dueAt: new Date(),
    patientId: props.finding.patient.id,
    assignedToId: currentProviderId,
  };

  const administerTask = {
    title: t("collaborativeCare:findings.administerNowTask.title"),
    body: `${t("collaborativeCare:findings.administerNowTask.body")}[${
      props.finding.patient.name
    }](/app/cocm/patient/${props.finding.patient.id})`,
    dueAt: new Date(),
    patientId: props.finding.patient.id,
    assignedToId: currentProviderId,
  };

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:findings.findings.monthlyAssessmentIncomplete.title")} />
      <CardContent>
        <Stack direction="row" spacing={0.5} alignItems="center" useFlexGap>
          <PatientHeaderStatus
            patientId={props.finding.patient.id}
            patientName={props.finding.patient.name}
          />
          <span>
            <Typography variant="h2" component="span">
              <Link to={`/app/cocm/patient/${props.finding.patient.id}`}>{props.finding.patient.name}</Link>{" "}
            </Typography>
            {t("collaborativeCare:findings.findings.monthlyAssessmentIncomplete.body")}
          </span>
        </Stack>
      </CardContent>
      <CardActions>
        <StartTaskNowAction
          findingId={props.finding.id}
          {...administerTask}
          buttonText={t("collaborativeCare:findings.findings.monthlyAssessmentIncomplete.viewMeasures")}
          redirectOnSuccess={`/app/cocm/patient/${props.finding.patient.id}`}
        />
        <SendNotificationAndSnoozeAction findingId={props.finding.id} patientId={props.finding.patient.id} />
        <CreateTaskLaterAction findingId={props.finding.id} {...reminderTask} />
        <DismissAction findingId={props.finding.id} />
      </CardActions>
    </Card>
  );
}
