import { Autocomplete, Button, FormHelperText, Stack, TextField, useTheme } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import { useCurrentProviderId } from "AppSession/AppSession";
import {
  Patient,
  TaskTemplate,
  useCreateTaskMutation,
  useTaskTemplatesQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useEffectOnce, useEffectSimpleCompare } from "Lib/Hooks";
import { TaskId } from "Lib/Ids";
import { refetchQueries } from "Lib/RefetchQueries";
import { ReadOnlyOrImpersonatingDisabledButton } from "Shared/ContextDisabledButtons";
import { StrictPatientSelect } from "Shared/Filters/PatientSelect";
import { useForm, useWrappedField } from "Shared/Form";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";

type QuickAddTaskFormProps = {
  defaultPatient?: PickTypename<Patient, "id" | "name">;
  onSuccess?: (newTaskId: TaskId) => void;
};

export function QuickAddTaskForm(props: QuickAddTaskFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [open, setOpen] = React.useState(false);

  const trigger = (
    <ReadOnlyOrImpersonatingDisabledButton minPiiLevel="limited_pii">
      <Button fullWidth onClick={() => setOpen(true)}>
        {t("collaborativeCare:careManagerDashboard.taskList.createTask")}
      </Button>
    </ReadOnlyOrImpersonatingDisabledButton>
  );
  const form = (
    <OpenQuickAddTaskForm
      onCancel={() => setOpen(false)}
      defaultPatient={props.defaultPatient}
      onSuccess={props.onSuccess}
    />
  );

  return (
    <Stack direction="column" spacing={1} paddingTop={1}>
      {open ? form : trigger}
    </Stack>
  );
}

type OpenQuickAddTaskFormProps = {
  onCancel?: () => void;
  defaultPatient?: PickTypename<Patient, "id" | "name">;
  hidePatientField?: boolean;
  onSuccess?: (newTaskId: TaskId) => void;
};

export function OpenQuickAddTaskForm(props: OpenQuickAddTaskFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common"]);
  const theme = useTheme();
  const currentProviderId = useCurrentProviderId();

  const [createTask, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareCreateTask,
    useCreateTaskMutation({
      refetchQueries: refetchQueries("tasks"),
    })
  );

  const fields = {
    patient: useWrappedField<PickTypename<Patient, "id" | "name">>({
      required: true,
      default: props.defaultPatient,
    }),
    templateOrTitle: useWrappedField<QuickTitleInputValue>({ required: true }),
  };

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      // This should never be true, but we want to narrow the values.
      if (!fields.patient.value || !fields.templateOrTitle.value || !currentProviderId) {
        return;
      }

      const templateOrTitle = fields.templateOrTitle.value;
      const title = typeof templateOrTitle === "string" ? templateOrTitle : templateOrTitle.title;
      const body = typeof templateOrTitle === "string" ? "" : templateOrTitle.body || "";

      createTask({
        variables: {
          input: {
            title: title,
            body: body,
            patientId: fields.patient.value.id,
            assignedToId: currentProviderId,
            addToDefaultList: true,
          },
        },
      });

      form.reset();
      patientInputRef.current?.focus();
    },
    onSuccess: (response) => {
      if (response && props.onSuccess) {
        props.onSuccess(response.task.id);
      }
    },
  });

  // We use these to automatically move focus around so that users can enter data as fast as possible. The flow is
  // * Form open -> patient focused
  // * Patient selected -> title focused
  // * Form submitted -> clear inputs, patient focused
  const patientInputRef = React.useRef<HTMLInputElement>(undefined);
  const titleInputRef = React.useRef<HTMLInputElement>(undefined);

  useEffectOnce(() => patientInputRef.current?.focus());

  const errorMessage = remoteData.caseOf({
    Failure: () => <FormHelperText error>{t("collaborativeCare:tasks.genericFormError")}</FormHelperText>,
    _: () => null,
  });

  return (
    <form onSubmit={form.onSubmit}>
      <Stack direction="column" spacing={1}>
        <StrictPatientSelect
          value={fields.patient.value || null}
          onChange={(newValue) => {
            fields.patient.onChange(newValue);
            titleInputRef.current?.focus();
          }}
          label={t("collaborativeCare:fields.patient.label")}
          inputProps={{
            sx: { backgroundColor: theme.palette.background.paper },
            inputRef: patientInputRef,
          }}
          hidden={props.hidePatientField}
        />
        <TitleOrTemplateInput
          value={fields.templateOrTitle.value || null}
          onChange={fields.templateOrTitle.onChange}
          inputRef={titleInputRef}
        />
        {errorMessage}
        <Stack direction="row-reverse" spacing={1}>
          <Button variant="contained" color="secondary" onClick={props.onCancel}>
            {t("common:actions.cancel")}
          </Button>
          <Button variant="contained" color="secondary" type="submit">
            {t("common:actions.save")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

type TaskTemplateSummary = PickTypename<TaskTemplate, "id" | "title" | "body">;
type QuickTitleInputValue = TaskTemplateSummary | string;

type TitleOrTemplateInputProps = {
  value: QuickTitleInputValue | null;
  onChange: (newValue: QuickTitleInputValue | null) => void;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
};

function TitleOrTemplateInput(props: TitleOrTemplateInputProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();

  const { remoteData } = apolloQueryHookWrapper(useTaskTemplatesQuery());
  const templates = remoteData.caseOf({
    Success: (response) => response.collaborativeCareTaskTemplates?.nodes || [],
    _: () => [],
  });
  const sortedTemplates = [...templates].sort((a, b) => {
    return a.title.localeCompare(b.title);
  });

  // See the mui Autocomplete docs for the distinction between value and inputValue. We need to control them both here
  // so that we can feed inputValue changes back to the parent form as value changes, otherwhise typing something, not
  // selecting a template option and then clicking save (rather than hitting enter) will do nothing because the form
  // won't have ever got a change event from this component. Similarly, clear the inputValue when we get a null value.
  const [inputValue, setInputValue] = React.useState("");
  useEffectSimpleCompare(() => {
    if (props.value === null) {
      setInputValue("");
    }
  }, [props.value === null]);

  return (
    <Autocomplete
      // We need freeSolo so that users can set a custom title as well as picking a template.
      freeSolo
      // Clear on blur clears the autocomplete value when the value prop changes back to null, normally this is the
      // default but turning freeSolo on turns it off, so we have to turn it back on
      clearOnBlur
      options={sortedTemplates}
      value={props.value}
      onChange={(_event, value) => props.onChange(value)}
      inputValue={inputValue}
      onInputChange={(_event, inputValue) => {
        setInputValue(inputValue);
        props.onChange(inputValue);
      }}
      renderInput={(params) => (
        <TextField
          label={t("collaborativeCare:fields.title.label")}
          inputRef={props.inputRef}
          sx={{ backgroundColor: theme.palette.background.paper }}
          {...params}
        />
      )}
      getOptionLabel={(option) =>
        // This will actually never be a string because the options we pass in are all templates, but the freeSolo thing
        // forces us to handle the string case.
        typeof option === "string" ? option : option.title
      }
    />
  );
}
