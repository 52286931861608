import React, { ReactElement, ReactNode, Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider, CssBaseline } from "@mui/material";
import theme from "./Theme";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Spinner from "Shared/Spinner";

export type Props = {
  children: ReactNode;
};

const MaterialDecorator = (props: Props): ReactElement => (
  <HelmetProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Suspense fallback={<Spinner />}>{props.children}</Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </HelmetProvider>
);

export default MaterialDecorator;
