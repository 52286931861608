import Page from "Layout/Page";
import React from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { AuthenticatedProviderUserContext } from "AppSession/AuthenticatedProviderUser";
import PerformancePage from "./PerformancePage";
import { endOfMonth, startOfMonth, sub } from "date-fns";
import { useTranslation } from "react-i18next";
import { ResetAndStickyFilterButtonGroup, useStickyMonthParameter } from "Shared/StickyParameter";
import DatePicker from "Shared/DatePickers";
import { STICKY_PARAMETER_FILTER_SETS, STICKY_PARAMETER_NAMES } from "Shared/Storage";

export default function MyPerformancePage() {
  const { t } = useTranslation(["collaborativeCare", "common"]);

  const [now, _] = React.useState(new Date());
  const threeMonthsAgo = startOfMonth(sub(now, { months: 3 }));
  const endOfThisMonth = endOfMonth(now);

  const [startDate, setStartDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.START_DATE,
    STICKY_PARAMETER_FILTER_SETS.COCM_MY_PERFORMANCE,
    threeMonthsAgo,
    "start",
    true
  );
  const [endDate, setEndDate] = useStickyMonthParameter(
    STICKY_PARAMETER_NAMES.END_DATE,
    STICKY_PARAMETER_FILTER_SETS.COCM_MY_PERFORMANCE,
    endOfThisMonth,
    "end",
    true
  );

  const currentProvider = React.useContext(AuthenticatedProviderUserContext);
  if (!currentProvider) {
    return <></>;
  }
  const currentProviderId = currentProvider.providerId.getOrElse(null);
  if (!currentProviderId) {
    return <></>;
  }

  const careManagerIds = [currentProviderId];

  return (
    <Page browserTitle={t("collaborativeCare:performanceCharts.myPerformance")}>
      <Grid container columns={2} spacing={1}>
        <Grid item lg={2} xs={2}>
          <Stack direction="row" spacing={1}>
            <Box minWidth="15em">
              <DatePicker
                label={"Start Month"}
                views={["month", "year"]}
                openTo={"month"}
                value={startDate}
                defaultValue={threeMonthsAgo}
                onChange={(newValue: Date) => setStartDate(newValue)}
              />
            </Box>
            <Box>
              <DatePicker
                label={"End Month"}
                views={["month", "year"]}
                openTo={"month"}
                value={endDate}
                defaultValue={endOfThisMonth}
                onChange={(newValue: Date) => setEndDate(newValue)}
              />
            </Box>
            <Box>
              <Grid item xs="auto" display="flex" alignItems="center" justifyContent="center">
                <Divider orientation="vertical" flexItem />
                <ResetAndStickyFilterButtonGroup
                  onReset={() => {
                    setStartDate(threeMonthsAgo);
                    setEndDate(endOfThisMonth);
                  }}
                />
              </Grid>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <PerformancePage
        startDate={startDate}
        endDate={endDate}
        careManagerIds={careManagerIds}
        instituteId={null}
      />
    </Page>
  );
}
