import { Alert, Skeleton, Stack } from "@mui/material";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { parseScaleAdministrationHistoryQuery } from "FeedbackReport/DetailPane/DataStore/OverviewTreatmentResponseTransforms";
import LevelDetail from "FeedbackReport/LevelDetail";
import { LevelHeader, LevelHeaderLoadingIndicator } from "FeedbackReport/LevelHeader";
import {
  CalculationMechanism,
  CareEpisodeComputedValueWithScaleAndAnswersFragment,
  ResponsesAvailable,
  useScaleScoreQueryQuery,
  useScaleScorerHistoryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { CareEpisodeComputedValueId, CareEpisodeId, ScaleId } from "Lib/Ids";
import { arrOfN, nullableToRemoteData, resultToRemoteData } from "Lib/Utils";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { RemoteData } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { toQuestionResponseSummary } from "Shared/Scale/QuestionResponse";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { FeedbackReportContext } from "../FeedbackReportContext";
import { ResponsesTable } from "../Shared/ResponsesTable";
import EditResponses from "./EditResponses";
import { ScaleScoreTrendInfoStack } from "./ScaleScoreTrendInfo";
import { scaleFriendlyName } from "Shared/Scale/Scale";
import LinkIcon from "@mui/icons-material/Link";

type ScaleAdministrationHistoryData = RemoteData<Error, ReadonlyArray<ScaleScorerHistory>>;

type DetailedScaleScoreData = RemoteData<Error, CareEpisodeComputedValueWithScaleAndAnswersFragment>;

export type ScaleScorePaneProps = {
  careEpisodeId: CareEpisodeId;
  scaleId: ScaleId;
  careEpisodeComputedValueId: CareEpisodeComputedValueId;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleScorePaneHookWrapper(props: ScaleScorePaneProps): ReactElement {
  const { careEpisodeId, scaleId, careEpisodeComputedValueId, feedbackReportContext } = props;

  const scaleHistoryData = loadScaleHistory(careEpisodeId, scaleId, feedbackReportContext);
  const scaleScoreData = loadDetailedScaleScore(careEpisodeComputedValueId);

  return (
    <ScaleScorePane
      scaleHistory={scaleHistoryData}
      careEpisodeComputedValueDetails={scaleScoreData}
      feedbackReportContext={feedbackReportContext}
    />
  );
}

function loadDetailedScaleScore(
  careEpisodeComputedValueId: CareEpisodeComputedValueId
): DetailedScaleScoreData {
  const { remoteData } = apolloQueryHookWrapper(
    useScaleScoreQueryQuery({
      variables: {
        careEpisodeComputedValueId: careEpisodeComputedValueId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => nullableToRemoteData(result.assessmentCareEpisodeComputedValue));
}

function loadScaleHistory(
  careEpisodeId: CareEpisodeId,
  scaleId: ScaleId,
  feedbackReportContext: FeedbackReportContext
): ScaleAdministrationHistoryData {
  const { remoteData } = apolloQueryHookWrapper(
    useScaleScorerHistoryQuery({
      variables: {
        careEpisodeId,
        scaleId,
      },
    })
  );

  return remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      return resultToRemoteData(
        parseScaleAdministrationHistoryQuery(result, feedbackReportContext.participantsByUserId)
      );
    });
}

function ScaleScorerLoadingIndicator(): ReactElement {
  const rows = arrOfN(10).map((i) => {
    return (
      <tr key={i} data-testid="loading-row">
        <td>
          <Skeleton width={250} height={20} />
        </td>
        <td>
          <Skeleton width={50} height={20} />
        </td>
        <td>
          <Skeleton width={75} height={20} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <LevelDetail>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </LevelDetail>
    </div>
  );
}

type Props = {
  scaleHistory: ScaleAdministrationHistoryData;
  careEpisodeComputedValueDetails: DetailedScaleScoreData;
  feedbackReportContext: FeedbackReportContext;
};

function ScaleScorePane(props: Props): ReactElement {
  const { t } = useTranslation(["report"]);
  const { scaleHistory, careEpisodeComputedValueDetails } = props;

  return scaleHistory.caseOf({
    NotAsked: () => <ScaleScorerLoadingIndicator />,
    Loading: () => <ScaleScorerLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (scaleHistory) => {
      return careEpisodeComputedValueDetails.caseOf({
        NotAsked: () => <ScaleScorerLoadingIndicator />,
        Loading: () => <ScaleScorerLoadingIndicator />,
        Failure: (error) => <ErrorMessage message={error.message} />,
        Success: (scaleScore) => {
          // For some reason, if you do .map directly it doesn't pick up typing errors from different fragments being used.
          const responses = scaleScore.answers.map((x) => toQuestionResponseSummary(x));

          const trendInfo = <ScaleScoreTrendInfoStack histories={scaleHistory} scoreDate={scaleScore.date} />;

          const externalScoreWarning =
            scaleScore.scoringMechanism === CalculationMechanism.EXTERNAL ? (
              <Alert icon={<LinkIcon />} severity="info">
                {t("report:sections.scoredExternally")}
              </Alert>
            ) : null;

          const responsesSection =
            scaleScore.responsesAvailable === ResponsesAvailable.SCORE_ONLY ? (
              <Alert icon={<LinkIcon />} severity="info">
                {t("report:sections.noResponses")}
              </Alert>
            ) : (
              <ResponsesTable responses={responses} />
            );

          return (
            <Stack spacing={0.5} data-testid="scale-score">
              <LevelHeader>{scaleScore.user?.name}</LevelHeader>
              <LevelHeader>
                {t("report:sections.responses", {
                  date: scaleScore.date,
                  scale: scaleFriendlyName(scaleScore.scale),
                })}
                <EditResponses scaleScore={scaleScore} />
              </LevelHeader>
              {trendInfo}
              <LevelDetail>
                {externalScoreWarning}
                {responsesSection}
              </LevelDetail>
            </Stack>
          );
        },
      });
    },
  });
}

export default ScaleScorePaneHookWrapper;
export { ScaleScorePaneHookWrapper as HookWrapper, ScaleScorePane as Component };
