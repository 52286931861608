import {
  InstituteGroupListQuery,
  InstituteGroupListQueryVariables,
  InstituteGroupSortParameter,
  useInstituteGroupListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Paper, TextField, styled } from "@mui/material";
import Link from "MDS/Link";
import { useQueryStringOptionalEncryptedParameter } from "Shared/QueryStringParameter";
import SimpleTooltip from "MDS/Tooltip/SimpleTooltip";
import { CreateInstituteGroupButton } from "./CreateInstituteGroupButton";
import { Route, Routes } from "react-router-dom";
import { InstituteGroupDetails } from "./InstituteGroupDetails";

type FilterType = {
  search: string | null;
  setSearch: (newSearch: string | null) => void;
};

function InstituteGroupListTable(props: { filters: FilterType }) {
  const { t } = useTranslation(["instituteGroups", "common"]);

  const queryVars: Pick<InstituteGroupListQueryVariables, "search"> = {
    search: props.filters.search,
  };

  const columns: DataGridCols<InstituteGroupListQuery, ["instituteGroups"]> = React.useMemo(() => {
    return [
      {
        field: "name",
        headerName: t("instituteGroups:table.columns.name"),
        sortable: true,
        flex: 2,
        renderCell: (params) => {
          return <Link to={`${params.row.id}`}>{params.row.name}</Link>;
        },
      },
      {
        field: "leader",
        headerName: t("instituteGroups:table.columns.leader"),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          return <Link to={`../../institutes/${params.row.leader.id}`}>{params.row.leader.name}</Link>;
        },
      },
      {
        field: "members",
        headerName: t("instituteGroups:table.columns.members"),
        sortable: false,
        flex: 1,
        renderCell: (params) => {
          const memberCount = params.row.members.length;
          const tooltip = (
            <ul>
              {params.row.members.map((member) => {
                return (
                  <li key={member.id.toString()}>
                    <Link to={`../../institutes/${member.id}`}>{member.name}</Link>
                  </li>
                );
              })}
            </ul>
          );

          return (
            <SimpleTooltip title={tooltip}>
              <span>{t("instituteGroups:table.columns.memberCount", { memberCount })}</span>
            </SimpleTooltip>
          );
        },
      },
    ];
  }, []);

  return (
    <Paper>
      <SortablePagableCollectionDataGrid
        queryHook={useInstituteGroupListQuery}
        queryVariables={queryVars}
        unwrapData={(response) => response?.instituteGroups || null}
        colNameToSortParam={(field) => {
          switch (field) {
            case "name":
            default:
              return InstituteGroupSortParameter.NAME;
          }
        }}
        columns={columns}
        defaultPageSize={10}
        getRowId={(row) => row.id.toString()}
      />
    </Paper>
  );
}

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderColor: theme.palette.divider,
  borderStyle: "solid",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.25),
}));

function InstituteGroupListFilters(props: { filters: FilterType }) {
  const { t } = useTranslation(["instituteGroups"]);

  return (
    <StyledPaper>
      <Grid container spacing={1}>
        <StyledBox>
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <TextField
                label={t("instituteGroups:table.search")}
                value={props.filters.search || ""}
                onChange={(event) =>
                  props.filters.setSearch(event.target.value === "" ? null : event.target.value)
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        </StyledBox>
        <Grid item display="flex" flexGrow={1}>
          <Box sx={{ flexGrow: 1 }} />
          <CreateInstituteGroupButton />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

function useInstituteGroupListFilters(): FilterType {
  const [search, setSearch] = useQueryStringOptionalEncryptedParameter("search", null, true);

  return { search, setSearch };
}

export default function InstituteGroupList() {
  const { t } = useTranslation(["instituteGroups"]);
  const filters = useInstituteGroupListFilters();
  return (
    <Page browserTitle={t("instituteGroups:table.pageTitle")}>
      <InstituteGroupListFilters filters={filters} />
      <InstituteGroupListTable filters={filters} />
      <Routes>
        <Route element={<InstituteGroupDetails />} path=":instituteGroupId" />
      </Routes>
    </Page>
  );
}
