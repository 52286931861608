import { Routes, Route } from "react-router-dom";
import React, { ReactElement } from "react";
import Schedule from "./Dashboard/Schedule";
import MyTasks from "./Dashboard/MyTasks";

export function DashboardRoutes(): ReactElement {
  return (
    <Routes>
      <Route element={<Schedule />} path="/schedule" />
      <Route element={<MyTasks />} path="/my-tasks" />
    </Routes>
  );
}
