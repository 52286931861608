import { Grid, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  CareEpisode,
  CareEpisodeMeasurementAllowedDetailsFragment,
  CareEpisodeProvider,
  CareEpisodeTreatmentTrack,
  Organization,
  Patient,
  Provider,
  TreatmentService,
  TreatmentTrack,
  useCareEpisodeDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { CareEpisodeId, PatientId } from "Lib/Ids";
import { ActiveInvitationsCard } from "Patients/PatientDetails/ActiveInvitationsCard";
import { RecentAppointmentsCard, UpcomingAppointmentsCard } from "Patients/PatientDetails/AppointmentsCard";
import PatientDetailsCard from "Patients/PatientDetails/PatientDetailsCard";
import NotFound from "Shared/NotFound";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import { CareEpisodeActionsCard } from "./CareEpisodeActionsCard";
import CareEpisodeCareTeamCard from "./CareEpisodeCareTeamCard";
import CareEpisodeInfoCard from "./CareEpisodeInfoCard";
import CareEpisodeTreatmentTracksCard from "./CareEpisodeTreatmentTracksCard";

export type CareEpisodeDetails = PickTypename<
  CareEpisode,
  "id" | "periodStart" | "periodEnd" | "status" | "dischargeDueToInactivityDate"
> & {
  organization: Pick<Organization, "id" | "name"> | null;
  treatmentService: Pick<TreatmentService, "id" | "name"> | null;
  careEpisodeProviders: ReadonlyArray<
    Pick<CareEpisodeProvider, "id" | "relationship"> & { provider: Pick<Provider, "id" | "name"> }
  >;
  careEpisodeTreatmentTracks: ReadonlyArray<
    Pick<CareEpisodeTreatmentTrack, "id" | "status"> & { treatmentTrack: Pick<TreatmentTrack, "name"> }
  >;
  patient: Pick<Patient, "id" | "hasCompletedAssessment">;
} & CareEpisodeMeasurementAllowedDetailsFragment;

export function CareEpisodeDetails(props: {
  patientId: PatientId;
  careEpisodeId: CareEpisodeId;
}): ReactElement | null {
  const { t } = useTranslation(["common"]);
  const { remoteData } = apolloQueryHookWrapper(
    useCareEpisodeDetailsQuery({ variables: { careEpisodeId: props.careEpisodeId } })
  );

  return remoteData.caseOf({
    Failure: () => <Typography>{t("common:remoteData.failure")}</Typography>,
    NotAsked: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    Loading: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Success: (data) => {
      if (data.careEpisode) {
        return <CareEpisodeDetailsElement careEpisode={data.careEpisode} patientId={props.patientId} />;
      } else {
        return <NotFound />;
      }
    },
  });
}

type CareEpisodeDetailsElementProps = {
  careEpisode: CareEpisodeDetails;
  patientId: PatientId;
};
function CareEpisodeDetailsElement(props: CareEpisodeDetailsElementProps): ReactElement {
  const { t } = useTranslation(["careEpisodes"]);

  return (
    <Page browserTitle={t("careEpisodes:title")}>
      <Grid container columns={12} spacing={1}>
        <Grid item lg={4} xs={12}>
          <Stack direction="column" minHeight={"100%"} spacing={1} flexGrow={1} flexDirection={"column"}>
            <LeftPanel patientId={props.patientId} careEpisode={props.careEpisode} />
          </Stack>
        </Grid>
        <Grid item lg={8} xs={12}>
          <Stack direction="column" spacing={1}>
            <RightPanel careEpisode={props.careEpisode} patientId={props.patientId} />
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
}

type PanelProps = {
  careEpisode: CareEpisodeDetails;
  patientId: PatientId;
};

function LeftPanel(props: PanelProps): ReactElement {
  return (
    <>
      <PatientDetailsCard patientId={props.patientId} />
      <CareEpisodeActionsCard patient={props.careEpisode.patient} careEpisode={props.careEpisode} />
      <CareEpisodeInfoCard careEpisode={props.careEpisode} />
      <CareEpisodeCareTeamCard
        careEpisodeId={props.careEpisode.id}
        careEpisodeProviders={props.careEpisode.careEpisodeProviders}
      />
      <CareEpisodeTreatmentTracksCard
        careEpisodeTreatmentTracks={props.careEpisode.careEpisodeTreatmentTracks}
        careEpisodeId={props.careEpisode.id}
      />
    </>
  );
}

function RightPanel(props: PanelProps): ReactElement {
  return (
    <>
      <ActiveInvitationsCard patientId={props.patientId} careEpisodeId={props.careEpisode.id} />
      <UpcomingAppointmentsCard patientId={props.patientId} careEpisodeId={props.careEpisode.id} />
      <RecentAppointmentsCard patientId={props.patientId} careEpisodeId={props.careEpisode.id} />
    </>
  );
}
