import { Maybe, Functor } from "seidr";
import SumType from "sums-up";
import { uncons } from "./Utils";

const nelSymbol = Symbol();

class NEL<A> extends SumType<{ [nelSymbol]: [A, ReadonlyArray<A>] }> implements Functor<A> {
  public map<B>(f: (a: A) => B): NonEmptyList<B> {
    return this.caseOf({
      [nelSymbol]: (a, as) => NonEmptyList(f(a), as.map(f)),
    });
  }

  public mapWithIndex<B>(f: (a: A, i: number) => B): NonEmptyList<B> {
    return this.caseOf({
      [nelSymbol]: (a, as) =>
        NonEmptyList(
          f(a, 0),
          as.map((item, i) => f(item, i + 1))
        ),
    });
  }

  public append(aa: A): NonEmptyList<A> {
    return this.caseOf({
      [nelSymbol]: (a, as) => NonEmptyList(a, as.concat(aa)),
    });
  }

  public concat(that: NonEmptyList<A>): NonEmptyList<A> {
    return this.caseOf({
      [nelSymbol]: (a, as) => NonEmptyList(a, as.concat(toArray(that))),
    });
  }

  public get length(): number {
    return toArray(this).length;
  }

  public [Symbol.iterator]() {
    return toArray(this)[Symbol.iterator]();
  }

  public toArray(): Array<A> {
    return toArray(this);
  }

  public sort(f: (a: A, b: A) => number): NonEmptyList<A> {
    return fromArray(toArray(this).sort(f)).caseOf({
      Just: (x) => x,
      Nothing: () => this,
    });
  }
}

export type NonEmptyList<A> = NEL<A>;

function NonEmptyList<A>(head: A, tail: ReadonlyArray<A>): NonEmptyList<A> {
  return new NEL(nelSymbol, head, tail);
}

function fromArray<A>(as: ReadonlyArray<A>): Maybe<NonEmptyList<A>> {
  return uncons(as).map(([x, xs]) => NonEmptyList(x, xs));
}

function toArray<A>(nel: NonEmptyList<A>): Array<A> {
  return nel.caseOf({
    [nelSymbol]: (a, as) => [a, ...as],
  });
}

function length(nel: NonEmptyList<unknown>): number {
  return nel.length;
}

function head<A>(nel: NonEmptyList<A>): A {
  return nel.caseOf({
    [nelSymbol]: (a, _as) => a,
  });
}

function last<A>(nel: NonEmptyList<A>): A {
  return nel.caseOf({
    [nelSymbol]: (a, as) => (as.length ? as[as.length - 1] || a : a),
  });
}

export { NonEmptyList, fromArray, toArray, length, head, last };
