const decisionSupport = {
  pageTitle: "Mirah",
  inbox: {
    noSuchTriggeredWorkflow: "No inbox item with that id. Try selecting one from the list.",
    filters: {
      workflowStatus: "Status",
    },
    headers: {
      patientName: "Patient",
      workflow: "Workflow",
      status: "Status",
    },
    noSelectedWorkflow: {
      title: "Decision Support Inbox",
      body: "On this page, you can see items that have been triggered for your patients. Select one of them from the list to the left for more details.",
    },
    triggeredWorkflowDetails: {
      patientMetCriteria: "{{ patientName }} met the following criteria on $t(common:date.medium):",
      viewReport: "View Report",
      subtitle: {
        ACTIVE: "",
        SNOOZED: "Snoozed by {{ provider }} until $t(common:date.medium)",
        CANCELED: "Canceled automatically on $t(common:date.medium)",
        COMPLETE: "Completed by {{ provider }} on $t(common:date.medium)",
      },
    },
  },
  workflowSelect: {
    title: "Workflows",
    noWorkflows: "No matching workflows",
    allWorkflows: "All Workflows",
  },
  workflowStatus: {
    ACTIVE: {
      title: "Active",
    },
    SNOOZED: {
      title: "Snoozed",
    },
    COMPLETE: {
      title: "Complete",
    },
    CANCELED: {
      title: "Canceled",
    },
  },
  algorithms: {
    headers: {
      algorithm: "Algorithm",
      target: "Target",
    },
    noSuchAlgorithm: "The algorithm you are requesting cannot be found",
    noSelectedAlgorithm: {
      title: "Algorithms",
      body: "Select an algorithm in the left list to view details",
    },
    filters: {
      status: "Status",
    },
    details: {
      noNotes: "There are no notes associated with this algorithm.",
      appliesTo: "Applies to:",
      startDate: "Start Date",
      sendNotifications: "Send Notifications",
    },
  },
};

export default decisionSupport;
