import React, { ReactElement, ReactNode } from "react";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";

import TabNav from "./TabNav";
import { QueryResult, OperationVariables } from "@apollo/client";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { Maybe } from "seidr";
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import ImportHistory from "./ImportHistory/ImportHistory";
import { InternalIntegrationsDataSourceRecordsFragment } from "GeneratedGraphQL/SchemaAndOperations";
import { InternalBreadcrumbPath } from "./InstituteDetailsRoute";
import { useEffectOnce } from "Lib/Hooks";

type DetailsLayoutParams<TData, TVariables extends OperationVariables, TDetailType> = {
  title: (item: TDetailType) => string;
  headerContents: (item: TDetailType) => ReactNode;
  queryResult: QueryResult<TData, TVariables>;
  resultFetcher: (data: TData) => TDetailType | null;
  browserTitle: (item: TDetailType) => string;
  titleAction: (item: TDetailType) => ReactNode;
  overviewFields: (item: TDetailType) => Array<{
    value: string | ReactNode | null;
    name: string;
  }>;
  integrationsDataSourceRecords?: (
    item: TDetailType
  ) => ReadonlyArray<InternalIntegrationsDataSourceRecordsFragment>;
  tabs: (item: TDetailType) => Array<{
    tabLabel: string;
    path: string;
    component: ReactElement;
  }>;
  subCrumbs: (item: TDetailType) => Array<InternalBreadcrumbPath>;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
};

function DetailsLayout<TData, TVariables extends OperationVariables, TDetailType>({
  queryResult,
  resultFetcher,
  ...props
}: DetailsLayoutParams<TData, TVariables, TDetailType>): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(queryResult);

  return remoteData.caseOf({
    NotAsked: () => <h1>Loading</h1>,
    Loading: () => <h1>Loading</h1>,
    Failure: (error) => <h1>`Error! ${error.message}`</h1>,
    Success: (mdata) =>
      Maybe.fromNullable(resultFetcher(mdata)).caseOf({
        Nothing: () => <div>No object found</div>,
        Just: (data) => <LoadedDetailsLayout data={data} {...props} />,
      }),
  });
}

function LoadedDetailsLayout<TDetailType>({
  title,
  browserTitle,
  headerContents,
  tabs,
  integrationsDataSourceRecords,
  overviewFields,
  titleAction,
  data,
  subCrumbs,
  setSubCrumbs,
}: {
  data: TDetailType;
  title: (item: TDetailType) => string;
  headerContents: (item: TDetailType) => ReactNode;
  browserTitle: (item: TDetailType) => string;
  titleAction: (item: TDetailType) => ReactNode;
  overviewFields: (item: TDetailType) => Array<{
    value: string | ReactNode | null;
    name: string;
  }>;
  integrationsDataSourceRecords?: (
    item: TDetailType
  ) => ReadonlyArray<InternalIntegrationsDataSourceRecordsFragment>;
  tabs: (item: TDetailType) => Array<{
    tabLabel: string;
    path: string;
    component: ReactElement;
  }>;
  subCrumbs: (item: TDetailType) => Array<InternalBreadcrumbPath>;
  setSubCrumbs: (subCrumbs: Array<InternalBreadcrumbPath>) => void;
}): ReactElement {
  useEffectOnce(() => {
    setSubCrumbs(subCrumbs(data));
  });
  return (
    <Page browserTitle={browserTitle(data)}>
      <PageHeader title={title(data)} titleAction={titleAction(data)}>
        {headerContents(data)}
      </PageHeader>
      <TabNav
        baseRoute={{
          tabLabel: "Overview",
          component: (
            <OverviewTable
              overviewFields={overviewFields(data)}
              integrationsDataSourceRecords={
                integrationsDataSourceRecords ? integrationsDataSourceRecords(data) : []
              }
            />
          ),
        }}
        routes={
          integrationsDataSourceRecords
            ? tabs(data).concat({
                path: "history",
                tabLabel: "Import History",
                component: <ImportHistory dataSourceRecordIds={integrationsDataSourceRecords(data)} />,
              })
            : tabs(data)
        }
      />
    </Page>
  );
}

function OverviewTable({
  overviewFields,
  integrationsDataSourceRecords,
}: {
  integrationsDataSourceRecords: ReadonlyArray<InternalIntegrationsDataSourceRecordsFragment>;
  overviewFields: Array<{
    value: string | ReactNode | null | boolean;
    name: string;
  }>;
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Field Name</TableCell>
            <TableCell align="right">Field Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {overviewFields.map((f, index) => (
            <TableRow key={index}>
              <TableCell>{f.name}</TableCell>
              <TableCell align="right">
                {Maybe.fromNullable(f.value).caseOf({
                  Nothing: () => "NOT SET",
                  Just: (x) => {
                    if (x === false) {
                      return "FALSE";
                    }
                    if (x === true) {
                      return "TRUE";
                    }
                    if (x instanceof Date) {
                      return x.toLocaleString();
                    }
                    return x;
                  },
                })}
              </TableCell>
            </TableRow>
          ))}
          {integrationsDataSourceRecords.map((idsr, index) => (
            <TableRow key={index + 333333}>
              <TableCell>"IDS"</TableCell>
              <TableCell align="right">{idsr.remoteId}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DetailsLayout;
