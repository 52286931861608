import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { Button, TextField, DialogContent, DialogActions, Typography, Stack, Box } from "@mui/material";
import { dischargeReasonT } from "GeneratedGraphQL/EnumTranslations";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import {
  Patient,
  useDischargeEnrollmentMutation,
  DischargeReason,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, useState } from "react";
import { PickTypename } from "type-utils";
import { useTranslation } from "react-i18next";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import { EnrollmentId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import Overlay from "Shared/Overlay";
import { StyledSuccessIcon } from "Shared/Form";
import EnumSelect from "Shared/EnumSelect";
import { refetchQueries } from "Lib/RefetchQueries";

type DischargeDialogProps = {
  open: boolean;
  patient: PickTypename<Patient, "id" | "name">;
  onClose: () => void;
};

export function DischargeDialog(props: DischargeDialogProps): ReactElement {
  const [noteUpdates, setNoteUpdates] = useState<string | null>("");
  const [reasonUpdates, setReasonUpdates] = useState<DischargeReason | null>(DischargeReason.COMPLETED);
  const { t } = useTranslation(["collaborativeCare"]);

  if (props.open) {
    return (
      <ResponsiveDialog
        open={props.open}
        title={t("collaborativeCare:enrollment.actions.dischargeTitle", { patient: props.patient.name })}
        keepMounted={false}
        dialogWidth="50%"
        onClose={props.onClose}
      >
        <DialogContent style={{ marginTop: "0.5em", overflow: "visible" }}>
          <Stack direction="column" spacing={1}>
            <Typography>{t("collaborativeCare:enrollment.actions.dischargeWarning")}</Typography>
            <EnumSelect
              title={t("collaborativeCare:enrollment.actions.dischargeReasonTitle")}
              value={reasonUpdates}
              enumTrans={dischargeReasonT}
              optionsEnum={DischargeReason}
              onChange={setReasonUpdates}
            ></EnumSelect>
            <TextField
              id="outlined-multiline-static"
              fullWidth
              margin="dense"
              multiline
              disabled={false}
              rows={6}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNoteUpdates(event.target.value);
              }}
              value={noteUpdates}
              placeholder={t("collaborativeCare:enrollment.actions.dischargeNotes")}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <DischargeButton
            patient={props.patient}
            reason={reasonUpdates}
            notes={noteUpdates}
            onSuccess={() => setTimeout(props.onClose, 300)}
          />
        </DialogActions>
      </ResponsiveDialog>
    );
  } else {
    return <></>;
  }
}

type DischargeDialogButtonProps = {
  patient: PickTypename<Patient, "id" | "name">;
  buttonMinWidth: string;
};

export function DischargeDialogButton(props: DischargeDialogButtonProps): ReactElement {
  const [showDialog, setShowDialog] = React.useState(false);
  const { t } = useTranslation(["collaborativeCare"]);

  const dialog = (
    <DischargeDialog open={showDialog} patient={props.patient} onClose={() => setShowDialog(false)} />
  );

  const enrollment = useEnrollmentState(props.patient.id);
  return (
    <Box minWidth={props.buttonMinWidth}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => setShowDialog(true)}
        disabled={!(enrollment.status === "enrolled")}
        fullWidth
      >
        {t("collaborativeCare:enrollment.actions.discharge")}
      </Button>
      {dialog}
    </Box>
  );
}

type DischargeButtonProps = {
  patient: PickTypename<Patient, "id">;
  notes: string | null;
  reason: DischargeReason | null;
  onSuccess: () => void;
};

export function DischargeButton(props: DischargeButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const enrollment = useEnrollmentState(props.patient.id);
  if (enrollment.status === "enrolled") {
    return (
      <EnabledDischargeButton
        patient={props.patient}
        enrollmentId={enrollment.enrollmentId}
        notes={props.notes}
        reason={props.reason}
        onSuccess={props.onSuccess}
      />
    );
  } else {
    // if the patient is not enrolled but the discharge dialog is still open, they've probably just successfully discharged a patient
    // until it gets hooked up as a form, just show the success overlay during that time until the window auto closes
    return (
      <>
        <Overlay>
          <StyledSuccessIcon data-testid="success-icon" />
        </Overlay>
        <Button variant="contained" color="secondary" disabled>
          {t("collaborativeCare:enrollment.actions.discharge")}
        </Button>
      </>
    );
  }
}

type EnabledDischargeButtonProps = {
  patient: PickTypename<Patient, "id">;
  enrollmentId: EnrollmentId;
  notes: string | null;
  reason: DischargeReason | null;
  onSuccess: () => void;
};

function EnabledDischargeButton(props: EnabledDischargeButtonProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [discharge, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareDischargeEnrollment,
    useDischargeEnrollmentMutation({
      variables: {
        input: {
          enrollmentId: props.enrollmentId,
          dischargeText: props.notes,
          dischargeReason: props.reason,
        },
      },
      refetchQueries: refetchQueries("discharge"),
    })
  );

  const dischargeAndClose = function () {
    discharge();
    props.onSuccess();
  };

  const showSpinner = remoteData.kind === "Loading";
  const disabled = remoteData.kind === "Loading";

  return (
    <>
      <ButtonWithSpinner
        variant="contained"
        color="secondary"
        showSpinner={showSpinner}
        disabled={disabled}
        onClick={() => dischargeAndClose()}
      >
        {t("collaborativeCare:enrollment.actions.discharge")}
      </ButtonWithSpinner>
    </>
  );
}
