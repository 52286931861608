import React, { ReactNode, ReactElement } from "react";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Maybe } from "seidr";

const StyledSeparator = styled("span")(() => ({
  marginLeft: 10,
  marginRight: 10,
}));

// Excludes hasChildren prop from forwarding to silence warning about invalid HTML attributes.
const StyledHeader = styled("header", { shouldForwardProp: (prop) => prop !== "hasChildren" })<{
  hasChildren: boolean;
}>(({ theme, hasChildren }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  whiteSpace: "nowrap",
  marginBottom: hasChildren ? theme.spacing(1) : 0,
}));

type SectionBaseProps = {
  title: string;
  hideTitle?: boolean;
  renderSubTitle?: () => ReactElement;
  children?: ReactNode;
};

function SectionBase(props: SectionBaseProps): ReactElement {
  const { title, renderSubTitle, children } = props;

  const hasChildren = children ? true : false;

  const headerContent = Maybe.fromNullable(renderSubTitle).caseOf({
    Just: (render) => (
      <>
        <Typography variant="h2">
          {title}
          <StyledSeparator>—</StyledSeparator>
        </Typography>
        {render()}
      </>
    ),
    Nothing: () => <Typography variant="h2">{title}</Typography>,
  });

  const header = props.hideTitle ? null : (
    <StyledHeader hasChildren={hasChildren}>{headerContent}</StyledHeader>
  );

  return (
    <section>
      {header}
      <article>{children}</article>
    </section>
  );
}

type Props = {
  title: string;
  hideTitle?: boolean;
  renderSubTitle?: () => ReactElement;
  children?: ReactNode;
};

function Section(props: Props): ReactElement {
  const { title, renderSubTitle, children } = props;

  return (
    <SectionBase title={title} hideTitle={props.hideTitle} renderSubTitle={renderSubTitle}>
      {children}
    </SectionBase>
  );
}

export default Section;
export { SectionBase, Section };
