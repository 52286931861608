import { TEST_PATIENT_STORAGE_KEY } from "Shared/Storage";
import React, { ReactElement, useContext, useState } from "react";

// This is just the arbitrary string we're using for true in our value.
const VALUE_STRING = "true";

type TestPatientViewability = {
  testPatientViewability: boolean;
  setTestPatientViewability: (newTestPatientViewability: boolean) => void;
};

// Use the actual context if you need to set the viewability.
export const TestPatientViewabilityContext: React.Context<TestPatientViewability> = React.createContext({
  testPatientViewability: currentTestPatientViewability(),
  setTestPatientViewability: (_newTestPatientViewability: boolean) => {
    /* Go away linter */
  },
});

// Otherwise, use the hook to save yourself some trouble unpacking the context.
export function useTestPatientViewability(): boolean {
  const testPatientViewAbility = useContext(TestPatientViewabilityContext);
  return testPatientViewAbility.testPatientViewability;
}

function currentTestPatientViewability(): boolean {
  const testPatientViewability = sessionStorage.getItem(TEST_PATIENT_STORAGE_KEY);
  if (testPatientViewability == VALUE_STRING) {
    return true;
  }
  return false;
}

export function TestPatientViewabilityContextProvider({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  const [testPatientViewability, setTestPatientViewability] = useState<boolean>(
    currentTestPatientViewability()
  );
  return (
    <TestPatientViewabilityContext.Provider
      value={{
        testPatientViewability: testPatientViewability,
        setTestPatientViewability: (newTestPatientViewability: boolean) => {
          if (!newTestPatientViewability) {
            sessionStorage.removeItem(TEST_PATIENT_STORAGE_KEY);
          } else {
            sessionStorage.setItem(TEST_PATIENT_STORAGE_KEY, VALUE_STRING);
          }
          setTestPatientViewability(newTestPatientViewability);
        },
      }}
    >
      {children}
    </TestPatientViewabilityContext.Provider>
  );
}
