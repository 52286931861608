import React, { ReactElement } from "react";
import { Grid } from "@mui/material";
import * as NEL from "Lib/NonEmptyList";
import * as FeedbackReportContext from "FeedbackReport/FeedbackReportContext";
import { Goal } from "Shared/Scale/Goal";
import ParticipantAvatar from "FeedbackReport/ParticipantAvatar";
import TooltipBase from "MDS/Tooltip/TooltipBase";
import { styled } from "@mui/material/styles";
import SeverityHeatmapWithChip from "Shared/Severity/SeverityHeatmapWithChip";

const StyledGoalText = styled("div")(({ theme }) => ({
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "inherit",
  whiteSpace: "nowrap",
  paddingRight: theme.spacing(1.5),
  paddingBottom: theme.spacing(0.5),
}));
const StyledGrid = styled(Grid)(() => ({ width: "inherit" }));
const StyledAvatar = styled(Grid)(({ theme }) => ({
  paddingRight: theme.spacing(0.5),
}));

function GoalItemRow(props: {
  goal: Goal;
  administrationDates: NEL.NonEmptyList<Date>;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
}): ReactElement {
  const { goal, administrationDates, feedbackReportContext } = props;

  let participantCell;

  if (feedbackReportContext.hasMultipleClientParticipants) {
    const participantAvatar = FeedbackReportContext.getParticipantByUserId(
      feedbackReportContext,
      goal.user.id
    ).map((p) => <ParticipantAvatar participant={p} />);

    participantCell = <StyledAvatar>{participantAvatar.getOrElse(null)}</StyledAvatar>;
  }

  return (
    <>
      <StyledGrid>
        <StyledGoalText>{goal.patientText}</StyledGoalText>
        <Grid container>
          {participantCell}
          <Grid item>
            <SeverityHeatmapWithChip
              height={24}
              maxWidth={200}
              values={goal.answers}
              dates={administrationDates}
              chipWidth={150}
            />
          </Grid>
        </Grid>
      </StyledGrid>
    </>
  );
}

function GoalsTable(props: {
  goals: NEL.NonEmptyList<Goal>;
  administrationDates: NEL.NonEmptyList<Date>;
  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
}): ReactElement {
  const { goals, administrationDates, feedbackReportContext } = props;

  return (
    <Grid container spacing={1} direction="column">
      {NEL.toArray(
        goals.mapWithIndex((goal, i) => (
          <GoalItemRow
            key={i}
            goal={goal}
            administrationDates={administrationDates}
            feedbackReportContext={feedbackReportContext}
          />
        ))
      )}
    </Grid>
  );
}

type GoalsTooltipProps = {
  children: ReactElement;
  goals: NEL.NonEmptyList<Goal>;

  feedbackReportContext: FeedbackReportContext.FeedbackReportContext;
  administrationDates: NEL.NonEmptyList<Date>;
};

function GoalsTooltip(props: GoalsTooltipProps): ReactElement {
  const { children, administrationDates, goals, feedbackReportContext } = props;

  const content = (
    <GoalsTable
      goals={goals}
      administrationDates={administrationDates}
      feedbackReportContext={feedbackReportContext}
    />
  );

  return (
    <TooltipBase content={content} title={"Goals / Problems"}>
      {children}
    </TooltipBase>
  );
}

export default GoalsTooltip;
