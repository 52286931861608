import React, { ReactElement } from "react";
import { Link } from "MDS/Link";
import { ListItem } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledListItem = styled(ListItem)(() => ({
  textAlign: "center",
  display: "block",
  textTransform: "capitalize",
}));

type Props = {
  title: string;
  href: string;
};

function NavItem(props: Props): ReactElement {
  const { title, href } = props;
  return (
    <StyledListItem key={title} disableGutters>
      <Link to={href}>{title}</Link>
    </StyledListItem>
  );
}

export default NavItem;
