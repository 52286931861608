import { clearCache } from "Api/GraphQL";
import { PII_LEVEL_STORAGE_KEY } from "Shared/Storage";
import React, { ReactElement, useContext, useState } from "react";

// PII LEVEL
//
// We support setting a switch which stops the back end from sending sensitive data.
// This is useful in a whole host of circumstances:
//  * Showing users who do not have access to PHI
//  * Consultations in the CoCM Model where consultant cannot see PHI
//  * Demos of real data to external parties where it has been anonymized.

export type PiiLevel = "full_access" | "limited_pii" | "pii_free" | "full_anon";

const PII_LEVEL_MAP = {
  full_access: 4,
  limited_pii: 3,
  pii_free: 2,
  full_anon: 1,
};

type PiiLevelContextType = {
  piiLevel: PiiLevel;
  setPiiLevel: (newPiiLevel: PiiLevel | null) => void;
};

/**
 * Whether the current pii level is at least the level given. This can be used to say "can I at least see this amount of detail"
 * @param minPiiLevel the required level of PII visibility. Higher levels = more visibility
 * @returns whether this level of visibility is present.
 */
export function usePiiLevelIsAtLeast(minPiiLevel: PiiLevel): boolean {
  const piiLevel = usePiiLevel();

  return PII_LEVEL_MAP[piiLevel] >= PII_LEVEL_MAP[minPiiLevel];
}

/**
 * Whether the current pii level is lower than required to see the given data
 * @param minPiiLevel the required level of PII visibility. Higher levels = more visibility
 * @returns whether the current level is lower than required.
 */
export function usePiiLevelIsInsufficient(minPiiLevel: PiiLevel): boolean {
  const piiLevel = usePiiLevel();

  return PII_LEVEL_MAP[piiLevel] < PII_LEVEL_MAP[minPiiLevel];
}

// Use the actual context if you need to set the PiiLevel.
export const PiiLevelContext: React.Context<PiiLevelContextType> = React.createContext({
  piiLevel: getPiiLevelWithDefault(),
  setPiiLevel: (_newPiiLevel: PiiLevel | null) => {
    /* Go away linter */
  },
});

// Otherwise, use the hook to save yourself some trouble unpacking the context.
export function usePiiLevel(): PiiLevel {
  const piiLevelContext = useContext(PiiLevelContext);
  return piiLevelContext.piiLevel;
}

export function PiiLevelContextContextProvider({ children }: { children: ReactElement }): ReactElement {
  const [piiLevel, setStatePiiLevel] = useState<PiiLevel>(getPiiLevelWithDefault());
  return (
    <PiiLevelContext.Provider
      value={{
        piiLevel: piiLevel,
        setPiiLevel: (newPiiLevel: PiiLevel | null) => {
          setStoragePiiLevel(newPiiLevel);
          if (newPiiLevel === null) {
            newPiiLevel = "full_access";
          }
          setStatePiiLevel(newPiiLevel);
        },
      }}
    >
      {children}
    </PiiLevelContext.Provider>
  );
}

// The functions below are all the raw data access functions for PiiLevel that interact
// with session storage and the like. Generally speaking, folks should be using the context
// functions above in lieu of these.

function getPiiLevel(): PiiLevel | null {
  const value = localStorage.getItem(PII_LEVEL_STORAGE_KEY);

  if (value === "full_access" || value === "limited_pii" || value === "pii_free" || value === "full_anon") {
    return value;
  } else {
    return null;
  }
}

function getPiiLevelWithDefault(): PiiLevel {
  return getPiiLevel() || "full_access";
}

function setStoragePiiLevel(piiLevel: PiiLevel | null) {
  // We want to refetch the queries so use the normal clearCache.
  clearCache();
  if (piiLevel) {
    localStorage.setItem(PII_LEVEL_STORAGE_KEY, piiLevel);
  } else {
    localStorage.removeItem(PII_LEVEL_STORAGE_KEY);
  }

  // We're going to reload the entire page after setting our PII level. Our refetch queries
  // can't be data locked atomically with our header updates, and components can't deal with
  // that complexity easily. A reload is much easier to manage.
  window.location.reload();
}

export function getPiiLevelHeader() {
  const piiLevel = getPiiLevel();

  if (piiLevel) {
    return {
      "Pii-Level": piiLevel,
    };
  }

  return {};
}
