import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  Patient,
  PatientConditionListQuery,
  PatientConditionSortParameter,
  usePatientConditionListQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { PickTypename } from "type-utils";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { patientConditionStatusT } from "GeneratedGraphQL/EnumTranslations";

type PatientConditionsCardElementProps = {
  patient: PickTypename<Patient, "id">;
};

export function PatientConditionsCard(props: PatientConditionsCardElementProps): ReactElement {
  const { t } = useTranslation(["patients", "collaborativeCare", "enums"]);

  const columns: DataGridCols<PatientConditionListQuery, ["patientConditions"]> = [
    {
      field: "instituteConditionName",
      headerName: t("patients:patientConditions.name"),
      flex: 3,
      sortable: false,
      renderCell: (params) => {
        return params.row.instituteCondition.name;
      },
    },
    {
      field: "instituteConditionCode",
      headerName: t("patients:patientConditions.code"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.instituteCondition.code;
      },
    },
    {
      field: "dates",
      headerName: t("patients:patientConditions.dates"),
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const { onsetDate, abatementDate } = params.row;

        if (abatementDate && onsetDate) {
          return t("patients:patientConditions.dateBoth", { abatementDate, onsetDate });
        } else if (abatementDate) {
          return t("patients:patientConditions.dateAbatement", { abatementDate });
        } else if (onsetDate) {
          return t("patients:patientConditions.dateOnset", { date: onsetDate });
        } else {
          return t("patients:patientConditions.dateNone");
        }
      },
    },
    {
      field: "status",
      headerName: t("patients:patientConditions.status"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return patientConditionStatusT(params.row.status, t);
      },
    },
  ];
  return (
    <Card>
      <CardHeader title={t("patients:patientDetails.cards.patientConditions")} />
      <CardContent>
        <SortablePagableCollectionDataGrid
          queryHook={usePatientConditionListQuery}
          queryVariables={{ patientId: props.patient.id }}
          unwrapData={(response) => response?.patientConditions || null}
          colNameToSortParam={() => {
            return PatientConditionSortParameter.ID;
          }}
          autoHeight
          columns={columns}
          defaultPageSize={5}
          getRowId={(row) => row.id.toString()}
        />
        <Typography>{t("patients:patientConditions.ehrOnly")}</Typography>
      </CardContent>
    </Card>
  );
}
