import { Card, CardHeader, CardContent, TextField } from "@mui/material";
import React, { ReactElement } from "react";
import { maybeFromEmpty } from "Lib/Utils";
import { Maybe } from "seidr";

type Props = {
  searchText: Maybe<string>;
  onChange: (search: Maybe<string>) => void;
};

function SearchAndFilters(props: Props): ReactElement {
  const { searchText, onChange } = props;

  const onSearchChange = (ev: React.ChangeEvent<{ value: string }>) => {
    // TODO: Debounce
    onChange(maybeFromEmpty(ev.target.value));
  };

  return (
    <Card>
      <CardHeader title="Search" />
      <CardContent>
        <TextField
          label="Search"
          fullWidth
          defaultValue={searchText.getOrElse("")}
          onChange={onSearchChange}
          inputProps={{ "data-testid": "search" }}
        />
      </CardContent>
    </Card>
  );
}

export default SearchAndFilters;
