import React, { ReactElement } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { QualityIssueSummary } from "./QualityIssueSummary";
import * as Id from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import QualityIssueDetail from "./QualityIssueDetail";
import { useTranslation } from "react-i18next";
import QualityIssuesFilters, { useQualityFilters } from "./QualityIssueFilters";
import Page from "Layout/Page";
import { QualityIssuesTable } from "./QualityIssueTable";
import { PatientQualityIssueSuperStatus } from "./PatientQualityIssue";
import { ListDetailsCutout } from "Shared/ListDetailsCutout";

export function QualityIssuesRoutes(): ReactElement {
  const { t } = useTranslation(["qualityIssues"]);
  const filterProps = useQualityFilters();

  return (
    <Page browserTitle={t("qualityIssues:pageTitle")}>
      <ListDetailsCutout
        filters={<QualityIssuesFilters {...filterProps} />}
        list={
          <QualityIssuesTable
            organizationFilter={filterProps.organizationFilter}
            patientFilter={filterProps.patientFilter}
            issue={filterProps.issue}
            status={filterProps.status}
          />
        }
        details={
          <Routes>
            <Route
              element={<QualityIssueDetailRoute activeSuperStatus={filterProps.status} />}
              path="/patient/:patientId"
            />
            <Route element={<QualityIssueSummary />} path="/" />
          </Routes>
        }
      />
    </Page>
  );
}

export function QualityIssueDetailRoute(props: {
  activeSuperStatus: PatientQualityIssueSuperStatus | null;
}): ReactElement {
  const params = useParams<{ patientId?: string }>();

  return Id.fromNullableString<"Patient">(params.patientId).caseOf({
    Ok: (id) => <QualityIssueDetail patientId={id} activeSuperStatus={props.activeSuperStatus} />,
    Err: (_err) => {
      return <ErrorMessage message="Invalid URL" />;
    },
  });
}

export default QualityIssuesRoutes;
