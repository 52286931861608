const appointments = {
  measurementStatus: {
    title: "Status",
    ABORTED: {
      title: "Measurement Canceled",
      description:
        "Either the appointment was canceled, or the client did not show for the session and did not fill out the assessment",
    },
    COMPLETE: {
      title: "Complete",
      description: "All data has been collected for this appointment and the feedback has been viewed",
    },
    FAILED: {
      title: "Not Taken",
      description: "No data was collected for this appointment and the collection window has now closed",
    },
    FEEDBACK_AVAILABLE: {
      title: "Feedback Available",
      description:
        "At least one respondent has submitted feedback that the primary provider has not looked at yet",
    },
    FEEDBACK_COMPLETE: {
      title: "Feedback Complete",
      description:
        "All respondents have completed their assessments but the feedback has not yet been reviewed",
    },
    FUTURE: {
      title: "Not Open Yet",
      description:
        "This appointment is in the future and the window to enter assessment data is not open yet.",
    },
    NOTIFICATIONS_BLOCKED: {
      title: "Notifications Blocked",
      description:
        "We are unable to send notifications because the patient has opted out or is missing contact information",
    },
    AWAITING_INTERVIEW: {
      title: "Awaiting Interview",
      description: "The measurement is waiting on a provider interview.",
    },
    NOT_MEASURED: {
      title: "Not Measured",
      description: "There were no measurements to complete as part of this appointment.",
    },
    NOT_PARTICIPATING: {
      title: "Not Participating",
      description: "This appointment has not been enabled for MBC and no measurements will be taken",
    },
    NOT_STARTED: {
      title: "Not Started",
      description:
        "No respondents have started their assessments yet. Notifications have been sent where appropriate.",
    },
    REPORT_VIEWED: {
      title: "Feedback Viewed",
      description:
        "At least one respondent has submitted data and all current feedback has been viewed by the primary provider.",
    },
    STARTED: {
      title: "Started",
      description: "At least one respondent has started an assessment, but no data is available yet",
    },
    UNKNOWN: {
      title: "Unknown",
      description: "We have no further information about the state of this appointment",
    },
  },
  measurementCategory: {
    title: "Status", // On the front end, we refer to categories as statuses.
    COMPLETE: {
      title: "Complete",
      description: "All data has been collected for this appointment",
    },
    IN_PROGRESS: {
      title: "In Progress",
      description: "At least one respondent has started the assessment",
    },
    NOT_PARTICIPATING: {
      title: "Not Participating",
      description: "This appointment has not been enabled for MBC and no measurements will be taken",
    },
    NOT_MEASURED: {
      title: "Not Measured",
      description: "This appointment has no measurement associated",
    },
    NOT_STARTED: {
      title: "Not Started",
      description:
        "The window to complete assessments is open but no respondents have started their assessment yet",
    },
    FAILED: {
      title: "Failed",
      description: "No feedback was collected and measurement is now closed",
    },
    MEASUREMENT_CANCELED: {
      title: "Measurement Canceled",
      description:
        "Either the appointment was canceled, or the client did not show for the session and did not fill out the assessment",
    },
    FUTURE: {
      title: "Future",
      description:
        "This appointment is in the future and the window to enter assessment data is not open yet",
    },
    NOTIFICATIONS_BLOCKED: {
      title: "Notifications Blocked",
      description:
        "We are unable to send notifications because the patient has opted out or is missing contact information",
    },
    ERROR: {
      title: "Error",
      description: "We have encountered an error",
    },
    AWAITING_INTERVIEW: {
      title: "Awaiting Interview",
      description: "The measurement is waiting on a provider interview",
    },
  },
};

export default appointments;
