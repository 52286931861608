import { Routes, Route, useParams } from "react-router-dom";
import React, { ReactElement } from "react";
import { CareEpisodeId, PatientId } from "Lib/Ids";
import { fromNullableString } from "Lib/Id";
import NotFound from "Shared/NotFound";
import { AppointmentDetailsRoute } from "Patients/Appointments";
import { CareEpisodeDetails } from "./CareEpisodeDetails";

function CareEpisodeDetailsRoute(props: { patientId: PatientId }) {
  const params = useParams<{
    careEpisodeId?: string;
  }>();

  return fromNullableString<"CareEpisode">(params.careEpisodeId).caseOf({
    Ok: (id) => {
      return (
        <Routes>
          <Route element={<CareEpisodeDetails patientId={props.patientId} careEpisodeId={id} />} path="/" />
          <Route
            element={<AppointmentDetailsRoute patientId={props.patientId} />}
            path="/appointments/:appointmentId"
          />
        </Routes>
      );
    },
    Err: () => <NotFound />,
  });
}

export function patientCareEpisodeUrl(careEpisodeId: CareEpisodeId, patientId: PatientId) {
  return `/app/patients/${patientId}/care-episodes-new/${careEpisodeId}`;
}

export function PatientCareEpisodeRoutes(props: { patientId: PatientId }): ReactElement {
  return (
    <Routes>
      <Route element={<CareEpisodeDetailsRoute patientId={props.patientId} />} path=":careEpisodeId/*" />
      <Route element={<NotFound />} path="*" />
    </Routes>
  );
}
