import React from "react";

import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  MetricSummaryData,
  Scale,
  ScaleScorer,
  useOutcomesMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { MetricId } from "Lib/Ids";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useTranslation } from "react-i18next";
import { HasAggregationType, metricLabelGenerator } from "./OutcomesMetricHelpers";
import { EligibleCountColumn, MetricValueColumn } from "./OutcomesMetricBreakdown";
import { RemoteData } from "seidr";
import { ApolloError } from "@apollo/client";
import { scaleMediumName } from "Shared/Scale/Scale";

type ScaleScorerConfig = Pick<ScaleScorer, "id"> & { scale: Pick<Scale, "name" | "shortname" | "nanoname"> };

type ScaleBreakdownTableProps = {
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  metricId: MetricId;
  scaleScorerConfigData: ReadonlyArray<ScaleScorerConfig>;
  metricConfiguration: HasAggregationType;
};

function ScaleBreakdownTable(props: ScaleBreakdownTableProps) {
  const { t } = useTranslation(["outcomes"]);

  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: { node: props.entityTreeNodeParams },
    period: {
      dateRange: {
        min: props.startDate,
        max: props.endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };

  // Because of limitations on react hooks, you need to be very careful with how this is mounted, as it is going to issue an arbitrary numbers
  // of queries.
  // In theory, each instantiation should only issue the same number, because the component will be unmounted if the number of scales changes.
  const rowsWithData = props.scaleScorerConfigData.map((scorer) => {
    const { remoteData } = apolloQueryHookWrapper(
      useOutcomesMetricSummaryDataQuery({
        variables: {
          drilldownMetric: drilldownMetric,
          metricId: props.metricId,
          scaleScorerId: scorer.id,
        },
      })
    );
    return { scorer: scorer, data: remoteData.map((result) => result.outcomesMetricSummaryData) };
  });

  const labelGenerator = metricLabelGenerator(props.metricConfiguration, t);

  const columns: Array<
    GridColDef<{
      scorer: ScaleScorerConfig;
      data: RemoteData<ApolloError, Pick<MetricSummaryData, "value" | "eligibleRows">>;
    }>
  > = [
    {
      field: "scale",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.scale"),
      sortable: true,
      flex: 2,
      valueGetter: (_value, row) => scaleMediumName(row.scorer.scale),
    },
    {
      field: "value",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.result"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => row.data.map((data) => data.value),
      renderCell: (params) => {
        return params.row.data.caseOf({
          Success: (data) => {
            return (
              <MetricValueColumn
                timeBasedAggregationType={
                  props.metricConfiguration.__typename === "TimeBasedMetric"
                    ? props.metricConfiguration.timeBasedAggregationType
                    : null
                }
                labelGenerator={labelGenerator}
                value={data.value}
              />
            );
          },
          _: () => {
            return <Skeleton width={"150px"} />;
          },
        });
      },
    },
    {
      field: "eligibleRows",
      headerName: t("outcomes:multiScaleBreakdown.tableHeaders.eligibleRows"),
      sortable: true,
      flex: 1,
      valueGetter: (_value, row) => row.data.map((r) => r.eligibleRows),
      renderCell: (params) => {
        return params.row.data.caseOf({
          Success: (data) => {
            return <EligibleCountColumn value={data.eligibleRows} />;
          },
          _: () => {
            return <Skeleton width={"50px"} />;
          },
        });
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rowsWithData}
      getRowId={(row) => {
        return row.scorer.id.toString();
      }}
      hideFooterPagination={true}
      sortingMode={"client"}
    />
  );
}

type OutcomesMetricMultiScaleBreakdownProps = {
  metricId: MetricId;
  entityTreeNodeParams: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
  scaleScorerConfigData: ReadonlyArray<ScaleScorerConfig>;
  metricConfiguration: HasAggregationType;
};

function OutcomesMetricMultiScaleBreakdown(props: OutcomesMetricMultiScaleBreakdownProps) {
  const { t } = useTranslation(["outcomes"]);
  const { metricId, entityTreeNodeParams } = props;

  return (
    <Card>
      <CardHeader title={t("outcomes:multiScaleBreakdown.header")} />
      <CardContent>
        <Stack direction="column" spacing={1}>
          <ScaleBreakdownTable
            {...props}
            entityTreeNodeParams={entityTreeNodeParams}
            metricId={metricId}
            scaleScorerConfigData={props.scaleScorerConfigData}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OutcomesMetricMultiScaleBreakdown;
