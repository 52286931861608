import { compareAsc } from "date-fns";
import { OverviewConstructsQueryQuery, ScaleRole } from "GeneratedGraphQL/SchemaAndOperations";
import { oks } from "Lib/Utils";
import { Err, Just, Maybe, Ok, Result } from "seidr";
import { sortItemsByPatientParticipant } from "Shared/Participant";
import { parseDetailedScaleScore } from "Shared/Scale/CareEpisodeComputedValueDetails";
import { ConstructSection, SpecialSection, toConstructSummary } from "Shared/Scale/Constructs";
import { isDisplayable } from "Shared/Scale/Scale";
import { ScaleScorerHistory } from "Shared/Scale/ScaleScorerHistory";
import { ScaleSection, ScaleSectionSummary } from "Shared/Scale/ScaleSection";
import { DigUnpacked, DiscriminateUnion } from "type-utils";
import { ParticipantByUserIdMap } from "../../FeedbackReportContext";

// Graphql Types
type RawConstructSection = DigUnpacked<
  OverviewConstructsQueryQuery,
  ["assessmentCareEpisodeScaleSummary", "constructs"]
>;
type RawCareEpisodeScaleSection = DigUnpacked<
  OverviewConstructsQueryQuery,
  ["assessmentCareEpisodeScaleSummary", "constructs", "scaleSections"]
>;
type RawCareEpisodeScaleGroup = DiscriminateUnion<
  DigUnpacked<
    OverviewConstructsQueryQuery,
    ["assessmentCareEpisodeScaleSummary", "constructs", "scaleSections"]
  >,
  "CareEpisodeScaleGroupResult"
>;
type RawCareEpisodeScaleSummary = DiscriminateUnion<
  DigUnpacked<
    OverviewConstructsQueryQuery,
    ["assessmentCareEpisodeScaleSummary", "constructs", "scaleSections"]
  >,
  "CareEpisodeScaleResult"
>;

// -- Helpers -----------------------------------------------------------------

function toScaleScorerHistoryByUser(
  rawScale: DigUnpacked<RawCareEpisodeScaleSummary, ["scale"]>,
  rawScaleByUser: DigUnpacked<RawCareEpisodeScaleSummary, ["byUser"]>,
  rawByScorer: DigUnpacked<RawCareEpisodeScaleSummary, ["byUser", "byScorer"]>,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ScaleScorerHistory> {
  const scores = rawByScorer.history
    .concat(rawByScorer.latest)
    .filter((score) => score.scorer.__typename !== "NumericalScaleScorer" || score.value !== null)
    .map((score) => ({
      ...score,
      invitationDate:
        score.mostRelevantInvitation?.dateCompleted || score.mostRelevantInvitation?.dateBegun || null,
    }))
    .sort((a, b) => compareAsc(a.date, b.date));

  const participant = rawScaleByUser.user && participantsByUserId[rawScaleByUser.user.id.toString()];
  if (!participant) {
    return Err(new Error("Missing Participant"));
  }

  // TODO: The 'latest calculation needs more work as we need to exclude
  // things like scales that were never administered etc.
  return Ok({
    latest: parseDetailedScaleScore(rawScale, rawByScorer.latest).toMaybe(),
    scale: { ...rawScale, scorer: rawByScorer.scorer },
    participant,
    scores: scores,
  });
}

// eslint-disable-next-line
function parseScaleAdministrationHistories(
  careEpisodeScales: ReadonlyArray<RawCareEpisodeScaleSummary>,
  participantsByUserId: ParticipantByUserIdMap
): Array<ScaleScorerHistory> {
  const histories = oks(
    careEpisodeScales.flatMap((ces) =>
      ces.byUser.flatMap((sbu) =>
        sbu.byScorer.flatMap((byScorer) => {
          // Not all scales have scale roles set. For now, assume any scale without a scale role
          // is Per Respondent Total.
          switch (ces.scale.role || ScaleRole.PER_RESPONDENT_TOTAL) {
            case ScaleRole.INTERMEDIATE:
            case ScaleRole.CUSTOM:
            case ScaleRole.ALERT:
            case ScaleRole.AGGREGATE:
              return [];
            case ScaleRole.ADMINISTRATIVE:
            case ScaleRole.COMBINED_SUBSCALE:
            case ScaleRole.COMBINED_TOTAL:
            case ScaleRole.PER_RESPONDENT_SUBSCALE:
            case ScaleRole.PER_RESPONDENT_TOTAL:
              return [toScaleScorerHistoryByUser(ces.scale, sbu, byScorer, participantsByUserId)];
          }
        })
      )
    )
  ).filter((h) => {
    if ("scorer" in h.scale) {
      return isDisplayable(h.scale.scorer);
    } else {
      return true;
    }
  });

  return sortItemsByPatientParticipant(
    histories,
    (history) => Just(history.participant),
    (_x) => "same"
  );
}

// -- Main parsers ------------------------------------------------------------

function parseScaleGroupSummary(
  raw: RawCareEpisodeScaleGroup,
  participantsByUserId: ParticipantByUserIdMap
): ScaleSectionSummary {
  return ScaleSection.ScaleGroupSection({
    scaleGroup: raw.scaleGroup,
    histories: parseScaleAdministrationHistories(raw.byScale, participantsByUserId),
  });
}

function parseScaleSectionSummaries(
  rawSections: ReadonlyArray<RawCareEpisodeScaleSection>,
  participantsByUserId: ParticipantByUserIdMap
): Array<ScaleSectionSummary> {
  return rawSections.flatMap((raw) => {
    if (raw.__typename === "CareEpisodeScaleResult") {
      return parseScaleAdministrationHistories([raw], participantsByUserId).map(
        ScaleSection.IndividualScaleSection
      );
    } else {
      return parseScaleGroupSummary(raw, participantsByUserId);
    }
  });
}

function parseConstructSection(
  rawConstruct: RawConstructSection,
  participantsByUserId: ParticipantByUserIdMap
): ConstructSection {
  return {
    construct: Maybe.fromNullable(rawConstruct.construct).map(toConstructSummary),
    scaleSections: parseScaleSectionSummaries(rawConstruct.scaleSections, participantsByUserId),
  };
}

function parseSpecialSection(
  rawSection: RawSpecialSection,
  participantsByUserId: ParticipantByUserIdMap
): SpecialSection {
  return {
    sectionType: rawSection.sectionType,
    scaleSections: parseScaleSectionSummaries(rawSection.scaleSections, participantsByUserId),
  };
}

type RawSpecialSection = DigUnpacked<
  OverviewConstructsQueryQuery,
  ["assessmentCareEpisodeScaleSummary", "specialSections"]
>;

function parseSpecialSections(
  rawSpecialSections: ReadonlyArray<RawSpecialSection>,
  participantsByUserId: ParticipantByUserIdMap
): ReadonlyArray<SpecialSection> {
  return rawSpecialSections.map((d) => parseSpecialSection(d, participantsByUserId));
}

function parseConstructSections(
  rawConstructs: ReadonlyArray<RawConstructSection>,
  participantsByUserId: ParticipantByUserIdMap
): ReadonlyArray<ConstructSection> {
  return rawConstructs.map((d) => parseConstructSection(d, participantsByUserId));
}

function parseOverviewConstructs(
  raw: OverviewConstructsQueryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ReadonlyArray<ConstructSection>> {
  return Result.fromNullable(
    new Error("no assessmentCareEpisodeScaleSummary found"),
    raw.assessmentCareEpisodeScaleSummary
  ).map((summary) => {
    return parseConstructSections(summary.constructs, participantsByUserId);
  });
}

function parseOverviewSpecialSections(
  raw: OverviewConstructsQueryQuery,
  participantsByUserId: ParticipantByUserIdMap
): Result<Error, ReadonlyArray<SpecialSection>> {
  return Result.fromNullable(
    new Error("no assessmentCareEpisodeScaleSummary found"),
    raw.assessmentCareEpisodeScaleSummary
  ).map((summary) => {
    return parseSpecialSections(summary.specialSections, participantsByUserId);
  });
}

export { parseOverviewConstructs, parseOverviewSpecialSections, parseScaleAdministrationHistories };
