import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import { useClinicalAlertHistoryQueryQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { Link } from "MDS/Link";
import { CareEpisodeScaleId } from "Lib/Ids";
import { arrOfN } from "Lib/Utils";
import { default as React, ReactElement } from "react";
import { Failure, RemoteData, Success } from "seidr";
import ErrorMessage from "Shared/ErrorMessage";
import { formatDate } from "Shared/formatters";
import { AlertStatusChip, LatestAlertChip } from "Shared/Scale/AlertChip";
import {
  alertTitle,
  CareEpisodeClinicalAlertHistory,
  toCareEpisodeClinicalAlertHistoryDetails,
} from "Shared/Scale/CareEpisodeClinicalAlert";
import { FeedbackReportContext } from "../FeedbackReportContext";
import * as Routing from "../FeedbackReportRouting";
import LevelDetail from "../LevelDetail";
import { LevelHeaderLoadingIndicator } from "../LevelHeader";
import DetailPaneLevelHeader from "../DetailPaneLevelHeader";

type AlertData = RemoteData<Error, CareEpisodeClinicalAlertHistory>;

export type AlertPaneProps = {
  alertHistoryId: CareEpisodeScaleId;
  feedbackReportContext: FeedbackReportContext;
};

function AlertHistoryDetailPaneHookWrapper(props: AlertPaneProps): ReactElement {
  const { alertHistoryId, feedbackReportContext } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useClinicalAlertHistoryQueryQuery({
      variables: {
        careEpisodeClinicalAlertHistoryId: alertHistoryId,
      },
    })
  );

  const transformed: AlertData = remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      if (result.assessmentCareEpisodeClinicalAlertHistory) {
        return Success(
          toCareEpisodeClinicalAlertHistoryDetails(
            result.assessmentCareEpisodeClinicalAlertHistory,
            feedbackReportContext.participantsByUserId
          )
        );
      } else {
        return Failure(new Error("Not Found"));
      }
    });

  return AlertHistoryDetailPane({
    remoteData: transformed,
    feedbackReportContext,
  });
}

function LoadingIndicator(): ReactElement {
  const rows = arrOfN(3).map((i) => {
    return (
      <tr key={i} data-testid="loading-row">
        <td>
          <Skeleton width={75} height={20} />
        </td>
        <td>
          <Skeleton width={50} height={20} />
        </td>
        <td>
          <Skeleton width={75} height={20} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <LevelDetail>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </LevelDetail>
    </div>
  );
}

const StyledTable = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  "& th": {
    fontWeight: "normal",
    color: theme.palette.text.secondary,
    padding: "0.6rem 1.2rem",
    fontSize: theme.typography.body1.fontSize,
  },
  "& td": {
    padding: "0.6rem 1.2rem",
  },
}));

function AlertHistoryDetails(props: {
  alertHistory: CareEpisodeClinicalAlertHistory;
  feedbackReportContext: FeedbackReportContext;
}): ReactElement {
  const { alertHistory, feedbackReportContext } = props;

  const content = alertHistory.latest.caseOf({
    Nothing: () => <div>This alert has not been measured yet</div>,
    Just: (_) => {
      const rows = alertHistory.history.map((alert, i) => {
        const alertRoute = Routing.alertRoute(feedbackReportContext, alertHistory.id, alert.id);

        return (
          <tr key={i}>
            <td>
              <Link to={alertRoute}>{formatDate(alert.date)}</Link>
            </td>
            <td>
              <AlertStatusChip alertStatus={alert.status} alertType={alertHistory.alertType} />
            </td>
          </tr>
        );
      });

      return (
        <StyledTable>
          <tbody>{rows}</tbody>
        </StyledTable>
      );
    },
  });

  return (
    <>
      <DetailPaneLevelHeader label={alertTitle(alertHistory.alertType)}>
        <LatestAlertChip alertHistory={alertHistory} />
      </DetailPaneLevelHeader>
      <LevelDetail>{content}</LevelDetail>
    </>
  );
}

type Props = {
  remoteData: AlertData;
  feedbackReportContext: FeedbackReportContext;
};

function AlertHistoryDetailPane(props: Props): ReactElement {
  const { remoteData, feedbackReportContext } = props;

  return remoteData.caseOf({
    Loading: () => <LoadingIndicator />,
    NotAsked: () => <LoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (alertHistory) => (
      <AlertHistoryDetails alertHistory={alertHistory} feedbackReportContext={feedbackReportContext} />
    ),
  });
}

export default AlertHistoryDetailPaneHookWrapper;
export { AlertHistoryDetailPaneHookWrapper as HookWrapper, AlertHistoryDetailPane as Component };
