import React, { ReactElement } from "react";
import { MirahIcon, MirahIconTwoTone } from "Shared/MirahIcon";
import { Box, SvgIconProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "./Link";

function Logo(props: SvgIconProps) {
  return <MirahIcon {...props} />;
}

function LogoTwoTone(props: SvgIconProps) {
  return <MirahIconTwoTone {...props} />;
}

const StyledLogo = styled(MirahIcon)(() => ({
  fontSize: 30,
}));
const StyledLogoContainer = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  borderRadius: 25,
  background: theme.palette.primary.main,
  display: "inline-flex",
  justifyItems: "center",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  transition: "all 0.2s",

  "&:hover": {
    background: theme.palette.primary.light,
  },
}));

function LogoLink(props: { to: string }): ReactElement {
  return (
    <Link to={props.to}>
      <StyledLogoContainer>
        <StyledLogo />
      </StyledLogoContainer>
    </Link>
  );
}

export { Logo, LogoTwoTone, LogoLink };
export default Logo;
