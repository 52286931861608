import {
  useInstituteAppointmentAthenaPushbackQuery,
  AthenaScreeningQuestionnaire,
  AthenaAppointmentEncounter,
  Scale,
} from "GeneratedGraphQL/SchemaAndOperations";
import { default as React, ReactElement } from "react";
import { AppointmentId } from "Lib/Ids";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Maybe } from "seidr";

export default function AthenaPushbackDetails({
  appointmentId,
}: {
  appointmentId: AppointmentId;
}): ReactElement {
  const { remoteData } = apolloQueryHookWrapper(
    useInstituteAppointmentAthenaPushbackQuery({
      variables: {
        appointmentId: appointmentId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => <div>Loading</div>,
    NotAsked: () => <div>Not Asked</div>,
    Success: (data) => <LoadedComponent data={data.schedulingAppointment} />,
    Failure: () => <div>Fail</div>,
  });
}

function LoadedComponent({
  data,
}: {
  data: null | {
    athenaAppointmentEncounter: null | EncounterDetails;
    athenaScreeningQuestionnaires: ReadonlyArray<QuestionaireDetails>;
  };
}): ReactElement {
  if (data == null) {
    return <div>no appointment found</div>;
  }
  return (
    <>
      <Typography variant="h2">Encounter</Typography>
      <EncounterDetails encounter={data.athenaAppointmentEncounter} />
      <Typography variant="h2">Questionaires</Typography>
      <QuestionaireTable questionaires={data.athenaScreeningQuestionnaires} />
    </>
  );
}

type QuestionaireDetails = Pick<
  AthenaScreeningQuestionnaire,
  | "status"
  | "appointmentEncounterId"
  | "createdAt"
  | "updatedAt"
  | "pdfS3Location"
  | "athenaDocumentId"
  | "athenaQuestionaireId"
  | "computedValueId"
  | "id"
> & { scale: Pick<Scale, "mnemonic"> };
type EncounterDetails = Pick<
  AthenaAppointmentEncounter,
  "id" | "athenaEncounterId" | "athenaPatientId" | "departmentId" | "createdAt" | "updatedAt"
>;

function EncounterDetails(props: { encounter: null | EncounterDetails }): ReactElement {
  return Maybe.fromNullable(props.encounter).caseOf({
    Nothing: () => <Typography>No Encounter Yet</Typography>,
    Just: (encounter) => (
      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell align="right">Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              id
            </TableCell>
            <TableCell align="right">{encounter.id.toString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              createdAt
            </TableCell>
            <TableCell align="right">{encounter.createdAt.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              updatedAt
            </TableCell>
            <TableCell align="right">{encounter.updatedAt.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              athenaEncounterId
            </TableCell>
            <TableCell align="right">{encounter.athenaEncounterId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              athenaPatientId
            </TableCell>
            <TableCell align="right">{encounter.athenaPatientId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              departmentId
            </TableCell>
            <TableCell align="right">{encounter.departmentId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    ),
  });
}

function QuestionaireTable({
  questionaires,
}: {
  questionaires: ReadonlyArray<QuestionaireDetails>;
}): ReactElement {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Mnemonic</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionaires.map((questionaire) => (
            <QuestionaireRow key={questionaire.id.toString()} questionaire={questionaire} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function QuestionaireRow({ questionaire }: { questionaire: QuestionaireDetails }): ReactElement {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {questionaire.scale.mnemonic}
        </TableCell>
        <TableCell align="right">{questionaire.status}</TableCell>
        <TableCell align="right">{questionaire.updatedAt.toLocaleString()}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <QuestionaireDetails questionaire={questionaire} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function QuestionaireDetails({ questionaire }: { questionaire: QuestionaireDetails }): ReactElement {
  return (
    <Box sx={{ margin: 1 }}>
      <Typography variant="h6" gutterBottom component="div">
        Details
      </Typography>
      <Table size="small" aria-label="details">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell align="right">Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              id
            </TableCell>
            <TableCell align="right">{questionaire.id.toString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              appointmentEncounterId
            </TableCell>
            <TableCell align="right">{questionaire.appointmentEncounterId?.toString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              computedValueId
            </TableCell>
            <TableCell align="right">{questionaire.computedValueId.toString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              createdAt
            </TableCell>
            <TableCell align="right">{questionaire.createdAt.toLocaleString()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              pdfS3Location
            </TableCell>
            <TableCell align="right">{questionaire.pdfS3Location}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              athenaDocumentId
            </TableCell>
            <TableCell align="right">{questionaire.athenaDocumentId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              athenaQuestionaireId
            </TableCell>
            <TableCell align="right">{questionaire.athenaQuestionaireId}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
