import React, { ReactElement } from "react";
import { PatientDetails } from "./PatientDetails";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { LabelAndInfo, LabelAndRichInfo } from "Shared/InfoSidebarCard";
import { DigUnpacked } from "type-utils";
import { CareEpisodeProviderRelationship, EnrollmentQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { dischargeReasonT } from "GeneratedGraphQL/EnumTranslations";

type PreviousEnrollmentPatientInfoCardProps = {
  patient: PatientDetails;
  enrollment: DigUnpacked<EnrollmentQuery, ["collaborativeCareEnrollment"]>;
};
export default function PreviousEnrollmentPatientInfoCard(
  props: PreviousEnrollmentPatientInfoCardProps
): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "report", "enums", "common", "patients"]);

  let enrolledDate = t("collaborativeCare:enrollment.unenrolled");

  enrolledDate = t("common:date.tiny", {
    date: props.enrollment.enrolledAt,
  });

  let consentDate = <Typography>{t("collaborativeCare:enrollment.unenrolled")}</Typography>;

  consentDate = (
    <Typography>
      {t("common:date.tinyWithTime", {
        date: props.enrollment.consentDate,
      })}{" "}
    </Typography>
  );

  const dischargedAt = (
    <Typography>
      {t("common:date.tinyWithTime", {
        date: props.enrollment.dischargedAt,
      })}{" "}
    </Typography>
  );

  const panelNameContent = props.enrollment.panel?.name ? (
    <LabelAndInfo
      label={t("collaborativeCare:patientDetails.enrollmentHistory.panelName")}
      data={props.enrollment.panel.name}
    />
  ) : (
    <></>
  );

  return (
    <Card>
      <CardHeader title={t("collaborativeCare:patientDetails.cards.patientInfo")} />
      <CardContent>
        <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
          <LabelAndInfo label={t("patients:user.mrn")} data={props.patient.mrn} />
          <LabelAndInfo label={t("patients:user.email")} data={props.patient.email} />
          <LabelAndInfo label={t("patients:user.phoneNumber")} data={props.patient.phoneNumber} />
          {panelNameContent}
          <LabelAndInfo
            label={t("patients:details.primaryCareProvider")}
            data={
              props.enrollment.primaryCareProviderTeamMember?.provider.user.name ||
              props.enrollment.careEpisode.careEpisodeProviders.find((cep) => {
                return cep.relationship == CareEpisodeProviderRelationship.PRIMARY_CARE_PHYSICIAN;
              })?.provider.user.name ||
              t("patients:unknownValue")
            }
          />
          <LabelAndInfo
            label={t("patients:details.careManager")}
            data={
              props.enrollment.careManagerTeamMember?.provider.user.name ||
              props.enrollment.careEpisode.careEpisodeProviders.find((cep) => {
                return cep.relationship == CareEpisodeProviderRelationship.CARE_MANAGER;
              })?.provider.user.name ||
              t("patients:unknownValue")
            }
          />
          <LabelAndRichInfo label={t("collaborativeCare:enrollment.consentDate")} data={consentDate} />
          <LabelAndInfo label={t("collaborativeCare:enrollment.enrollmentDate")} data={enrolledDate} />
          <LabelAndRichInfo
            label={t("collaborativeCare:patientDetails.enrollmentHistory.dischargeDate")}
            data={dischargedAt}
          />
          <LabelAndInfo
            label={t("collaborativeCare:patientDetails.enrollmentHistory.dischargeReason")}
            data={
              props.enrollment.dischargeReason ? dischargeReasonT(props.enrollment.dischargeReason, t) : ""
            }
          />
        </Grid>
      </CardContent>
    </Card>
  );
}
