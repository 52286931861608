import React, { ReactElement } from "react";
import { useInstituteSsoSignonUrlQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { unsafeFromUuid } from "Lib/Id";
import { client } from "Api/GraphQL";
import { useLocation } from "react-router-dom";

function Redirect(): ReactElement {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const institute = search.get("institute");
  const destination = search.get("destination");

  if (institute) {
    const { data } = useInstituteSsoSignonUrlQuery({
      client: client,
      fetchPolicy: "no-cache",
      variables: {
        instituteId: unsafeFromUuid<"Institute">(institute),
      },
    });

    if (data) {
      const redirectUrl = `${data.institute?.ssoSignonUrl}&destination=${destination}&internal_redirect=1`;
      window.location.assign(redirectUrl);
    }
  }

  return (
    <h1>
      Redirecting! institute: {institute} destination: {destination}
    </h1>
  );
}

export { Redirect };
