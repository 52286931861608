import React, { ReactElement } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fromNullableString } from "Lib/Id";
import ErrorMessage from "Shared/ErrorMessage";
import { useReportRedirectQuery } from "GeneratedGraphQL/SchemaAndOperations";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import DefaultLoading from "Settings/DefaultLoading";
import DefaultError from "Settings/DefaultError";
import { Maybe } from "seidr";
import { PatientId } from "Lib/Ids";

// This page is designed to take a careUnitId and look up which feedback report to go to. It will
// redirect to best guess of the most recently  active care episode.

function ReportRedirect(props: { patientId: PatientId }): ReactElement {
  const navigate = useNavigate();
  const { remoteData } = apolloQueryHookWrapper(
    useReportRedirectQuery({
      variables: {
        patientId: props.patientId,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => <DefaultLoading />,
    NotAsked: () => <DefaultLoading />,
    Failure: (error) => <DefaultError error={error.message} />,
    Success: (result) =>
      Maybe.fromNullable(result.patient?.latestCareEpisode?.id).caseOf({
        Nothing: () => <DefaultError error="This patient has no data" />,
        Just: (careEpisodeId) => {
          navigate(`/app/care-units/${props.patientId}/care-episodes/${careEpisodeId}/feedback-report`, {
            replace: true,
          });

          return <DefaultLoading />;
        },
      }),
  });
}

function ReportRedirectRoute(): ReactElement {
  const { patientId } = useParams<{
    patientId?: string;
  }>();

  return fromNullableString<"Patient">(patientId).caseOf({
    Ok: (id) => {
      return <ReportRedirect patientId={id} />;
    },
    Err: (_err) => {
      return <ErrorMessage message="Invalid URL" />;
    },
  });
}

export default ReportRedirectRoute;
