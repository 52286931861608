import { Theme, useTheme } from "@mui/material";
import { FeedbackReportRoute } from "FeedbackReport/FeedbackReportRouting";
import { AcuteChangeCategory, ScaleThresholdClass } from "GeneratedGraphQL/SchemaAndOperations";
import React, { ReactElement, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Maybe } from "seidr";
import { formatDate } from "Shared/formatters";
import * as SeverityHistory from "Shared/Severity/SeverityHistory";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";
import { CareEpisodeScaleScorerSummaryDetails } from "Shared/Severity/SummaryHeatmapWithChip";
import { AcuteChangeIndicatorBadge } from "./AcuteChangeIndicator";
import {
  CareEpisodeCategoricalComputedValueDetails,
  CareEpisodeComputedValueDetails,
  CareEpisodeNumericalComputedValueDetails,
  CareEpisodeUnscoredComputedValueDetails,
  isCategorical,
  isNumerical,
  isUnscored,
  isInvalid,
  isNotAdministered,
} from "./CareEpisodeComputedValueDetails";
import { GoalAnswer } from "./Goal";
import { ScaleChipBase } from "./ScaleChipBase";
import * as Threshold from "./Threshold";

// -- Style helpers -----------------------------------------------------------

type ChipStyles = {
  backgroundColor: string;
  color: string;
};

function staleStyles(thresholdClass: ScaleThresholdClass, theme: Theme): ChipStyles {
  const colors = SeverityThresholdClass.color(thresholdClass, theme);
  return {
    backgroundColor: colors.secondary,
    color: colors.secondaryOffset,
  };
}

export function freshStyles(thresholdClass: ScaleThresholdClass, theme: Theme): ChipStyles {
  const colors = SeverityThresholdClass.color(thresholdClass, theme);

  return {
    backgroundColor: colors.primary,
    color: colors.offset,
  };
}

export type SeverityChipSupportedValues =
  | CareEpisodeNumericalComputedValueDetails
  | CareEpisodeCategoricalComputedValueDetails
  | CareEpisodeUnscoredComputedValueDetails
  | CareEpisodeComputedValueDetails
  | CareEpisodeScaleScorerSummaryDetails
  | GoalAnswer;

// -- SeverityChip

export type SeverityChipProps = {
  value: SeverityChipSupportedValues;
  isStale: boolean;
  onClickRoute?: FeedbackReportRoute;
  width?: number;
  border?: string;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

/**
 * Render a chip on the page containing the latest score
 */
function SeverityChip({ value, ...chipProps }: SeverityChipProps): ReactElement {
  if (value.__typename === "GoalAnswer") {
    return <GoalAnswerChip value={value} {...chipProps} />;
  }

  if (value.__typename === "CareEpisodeScaleScorerSummary") {
    return <CareEpisodeScaleScorerSummaryChip value={value} {...chipProps} />;
  }

  if (isInvalid(value)) {
    return <InvalidSeverityChip {...chipProps} />;
  }
  if (isNotAdministered(value)) {
    return <NotAdministeredSeverityChip {...chipProps} />;
  }

  if (isNumerical(value)) {
    return <CareEpisodeNumericalComputedValueChip value={value} {...chipProps} />;
  }
  if (isCategorical(value)) {
    return <CareEpisodeCategoricalComputedValueChip value={value} {...chipProps} />;
  }
  if (isUnscored(value)) {
    return <CareEpisodeUnscoredComputedValueChip value={value} {...chipProps} />;
  }

  throw new Error("Runtime Exception: this state should be unreachable.");
}

function GoalAnswerChip(
  props: SeverityChipProps & {
    value: GoalAnswer;
  }
): ReactElement {
  const { value, onClickRoute, isStale, width, border } = props;

  return (
    <SeverityChipBase
      thresholdClass={value.thresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
    >
      {value.thresholdMnemonic.map(Threshold.mnemonicShortTitle).getOrElse(formatDate(value.date))}
    </SeverityChipBase>
  );
}

function CareEpisodeScaleScorerSummaryChip(
  props: SeverityChipProps & {
    value: CareEpisodeScaleScorerSummaryDetails;
  }
): ReactElement {
  const { value, onClickRoute, isStale, width, border } = props;

  if (!value.lastDate || !value.lastThresholdClass) {
    return <NotAdministeredSeverityChip {...props} />;
  }

  return (
    <SeverityChipBase
      thresholdClass={value.lastThresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
    >
      {Maybe.fromNullable(value.lastThresholdMnemonic)
        .map(Threshold.mnemonicShortTitle)
        .getOrElse(formatDate(value.lastDate))}
    </SeverityChipBase>
  );
}

function CareEpisodeCategoricalComputedValueChip(
  props: SeverityChipProps & {
    value: CareEpisodeCategoricalComputedValueDetails;
  }
): ReactElement {
  const { value, onClickRoute, isStale, width, border } = props;

  return (
    <SeverityChipWithAcuteChange
      thresholdClass={value.thresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
      acuteChange={value.acuteChangeCategory}
    >
      {Maybe.fromNullable(value.thresholdMnemonic)
        .map(Threshold.mnemonicShortTitle)
        .getOrElse(formatDate(value.targetDate))}
    </SeverityChipWithAcuteChange>
  );
}

function CareEpisodeNumericalComputedValueChip(
  props: SeverityChipProps & {
    value: CareEpisodeNumericalComputedValueDetails;
  }
): ReactElement {
  const { value, onClickRoute, isStale, width, border } = props;
  const formattedValue = SeverityHistory.formatValue(value);

  return (
    <SeverityChipWithAcuteChange
      thresholdClass={value.thresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
      acuteChange={value.acuteChangeCategory}
    >
      {Maybe.fromNullable(value.thresholdMnemonic)
        .map(Threshold.mnemonicShortTitle)
        .map((title) => `${title} · ${formattedValue}`)
        .getOrElse(formattedValue)}
    </SeverityChipWithAcuteChange>
  );
}

function CareEpisodeUnscoredComputedValueChip(
  props: SeverityChipProps & {
    value: CareEpisodeUnscoredComputedValueDetails;
  }
): ReactElement {
  const { value, onClickRoute, isStale, width, border } = props;

  return (
    <SeverityChipWithAcuteChange
      thresholdClass={value.thresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
      acuteChange={value.acuteChangeCategory}
    >
      {formatDate(value.date)}
    </SeverityChipWithAcuteChange>
  );
}

// -- SeverityChip --------------------------------------------------------
// displays with severity color of the datum and any children and the
// AcuteChange badge

type SeverityChipBaseProps = {
  thresholdClass: ScaleThresholdClass;
  isStale: boolean;
  children: ReactNode;
  width?: number;
  border?: string;
  onClickRoute?: FeedbackReportRoute;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function SeverityChipBase(props: SeverityChipBaseProps): ReactElement {
  const { thresholdClass, onClickRoute, isStale, children, width, border } = props;

  const theme = useTheme();
  const styles = isStale ? staleStyles(thresholdClass, theme) : freshStyles(thresholdClass, theme);

  return (
    <ScaleChipBase
      onClickRoute={onClickRoute}
      backgroundColor={styles.backgroundColor}
      color={styles.color}
      width={width}
      border={border}
      chipTooltip={props.chipTooltip}
    >
      {children}
    </ScaleChipBase>
  );
}

// -- SeverityChipWithAcuteChange --------------------------------------------------------
// displays with severity color of the datum and any children and the
// AcuteChange badge

type SeverityChipWithAcuteChangeProps = {
  acuteChange: AcuteChangeCategory | null;
  thresholdClass: ScaleThresholdClass | null;
  isStale: boolean;
  children: ReactNode;
  width?: number;
  border?: string;
  onClickRoute?: FeedbackReportRoute;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function SeverityChipWithAcuteChange(props: SeverityChipWithAcuteChangeProps): ReactElement {
  const { children, acuteChange, thresholdClass, ...chipProps } = props;

  let acuteChangeText;
  if (acuteChange) {
    acuteChangeText = <AcuteChangeIndicatorBadge acuteChange={acuteChange} />;
  }

  return (
    <>
      <SeverityChipBase thresholdClass={thresholdClass || ScaleThresholdClass.UNKNOWN} {...chipProps}>
        {children}
      </SeverityChipBase>
      {acuteChangeText}
    </>
  );
}

type InvalidSeverityChipProps = {
  isStale: boolean;
  width?: number;
  onClickRoute?: FeedbackReportRoute;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function InvalidSeverityChip(props: InvalidSeverityChipProps): ReactElement {
  const { t } = useTranslation(["report"]);
  return (
    <SeverityChipBase thresholdClass={ScaleThresholdClass.UNKNOWN} {...props}>
      {t("report:validity.invalid")}
    </SeverityChipBase>
  );
}

type NotAdministeredSeverityChipProps = InvalidSeverityChipProps;

function NotAdministeredSeverityChip(props: NotAdministeredSeverityChipProps): ReactElement {
  const { t } = useTranslation(["report"]);
  return (
    <SeverityChipBase thresholdClass={ScaleThresholdClass.UNKNOWN} {...props}>
      {t("report:validity.notAdministered")}
    </SeverityChipBase>
  );
}

// -- SeverityNameAndValueChip ------------------------------------------------
// Displays both Severity Name/Treshold Mnemonic and the datum value

export type SeverityNameAndValueChipProps = {
  value: Pick<
    SeverityHistory.SeverityValueWithNumber,
    "acuteChange" | "thresholdClass" | "thresholdMnemonic" | "value"
  >;
  isStale: boolean;
  onClickRoute?: FeedbackReportRoute;
  width?: number;
  chipTooltip?: (child: ReactElement) => ReactElement;
};

function SeverityNameAndValueChip(props: SeverityNameAndValueChipProps): ReactElement {
  const { value, onClickRoute, isStale, width } = props;
  const formattedValue = SeverityHistory.formatValue(value);

  return (
    <SeverityChipWithAcuteChange
      acuteChange={value.acuteChange || AcuteChangeCategory.NOT_SUPPORTED}
      thresholdClass={value.thresholdClass}
      isStale={isStale}
      onClickRoute={onClickRoute}
      width={width}
      chipTooltip={props.chipTooltip}
    >
      {value.thresholdMnemonic
        .map(Threshold.mnemonicShortTitle)
        .map((title) => `${title} · ${formattedValue}`)
        .getOrElse(formattedValue)}
    </SeverityChipWithAcuteChange>
  );
}

export {
  SeverityNameAndValueChip,
  SeverityChip,
  SeverityChipBase,
  CareEpisodeCategoricalComputedValueChip,
  CareEpisodeNumericalComputedValueChip,
  CareEpisodeUnscoredComputedValueChip,
};
