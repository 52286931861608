import { Alert, Link, Skeleton, Tooltip } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DataSourceResourceType,
  ImportHistory,
  SourceEnum,
  useIntegrationStatusBadgeInlineQuery,
  useIntegrationStatusBadgeQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import {
  AppointmentId,
  CareEpisodeId,
  ImportHistoryId,
  InstituteConditionId,
  PatientConditionId,
  PatientId,
  ProviderId,
} from "Lib/Ids";
import React, { useState } from "react";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import IntegrationStatusLog from "./IntegrationStatusLog";
import { ExpandingIconButton } from "MDS/ExpandingIconButton";
import { User } from "@sentry/react";
import { Code } from "@mui/icons-material";
import { WithConfiguration } from "Contexts/CurrentInstituteContext";

function IntegrationStatusBadgeInner(props: {
  history: Pick<ImportHistory, "id" | "lastImportDate" | "source" | "updatedAt" | "name"> & {
    createdBy: Pick<User, "name"> | null;
  };
  resourceType: DataSourceResourceType;
}) {
  const { history, resourceType } = props;

  const { t } = useTranslation(["integrations"]);
  const [showLog, setShowLog] = useState<boolean>(false);

  if (history.source === SourceEnum.BULK_IMPORT) {
    const text = history.lastImportDate
      ? t("integrations:statusBadge.ehrCreated", { date: history.lastImportDate })
      : t("integrations:statusBadge.ehrCreatedNoDate");
    return (
      <>
        <Alert severity="info" icon={<CloudSyncIcon />}>
          <Link onClick={() => setShowLog(true)}>{text}</Link>
        </Alert>
        <IntegrationStatusLog
          importHistory={history}
          resourceType={resourceType}
          show={showLog}
          setShow={setShowLog}
        />
      </>
    );
  }

  if (history.source === SourceEnum.ALGORITHM) {
    return (
      <Alert severity="info" icon={<Code />}>
        {t("integrations:statusBadge.algorithmCreated")}
      </Alert>
    );
  }

  return (
    <Alert severity="info" icon={<PersonIcon />}>
      {history.createdBy
        ? t("integrations:statusBadge.manuallyCreated", {
            date: history.updatedAt,
            user: history.createdBy.name,
          })
        : t("integrations:statusBadge.manuallyCreatedNoUser", {
            date: history.updatedAt,
          })}
    </Alert>
  );
}

// This is a variant of the expanding button. Delete it if we don't like it
export function IntegrationStatusBadgeExpanding(props: {
  importHistoryId: ImportHistoryId;
  resourceType: DataSourceResourceType;
}) {
  const { t } = useTranslation(["integrations"]);
  const [showLog, setShowLog] = useState<boolean>(false);
  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationStatusBadgeQuery({
      variables: { id: props.importHistoryId, resourceType: props.resourceType },
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.integrationsImportHistory) {
        const history = data.integrationsImportHistory;

        if (history.source === SourceEnum.BULK_IMPORT) {
          return (
            <>
              <ExpandingIconButton icon={<CloudSyncIcon />} onClick={() => setShowLog(true)}>
                {t("integrations:statusBadge.integrationDetails")}
              </ExpandingIconButton>
              <IntegrationStatusLog
                importHistory={history}
                resourceType={props.resourceType}
                show={showLog}
                setShow={setShowLog}
              />
            </>
          );
        }
      }

      return null;
    },
    Loading: () => {
      return null;
    },
    _: () => {
      // Disappear the badge on error - no point in having it show an error message
      return null;
    },
  });
}

// This variant is a link. Delete if we don't like it.
export function IntegrationStatusBadgeInline(props: {
  importHistoryId: ImportHistoryId;
  resourceType: DataSourceResourceType;
}) {
  const { t } = useTranslation(["integrations"]);
  const [showLog, setShowLog] = useState<boolean>(false);
  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationStatusBadgeInlineQuery({
      variables: { id: props.importHistoryId, resourceType: props.resourceType },
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.integrationsImportHistory) {
        const history = data.integrationsImportHistory;

        if (history.source === SourceEnum.BULK_IMPORT) {
          const text = history.lastImportDate
            ? t("integrations:statusBadge.ehrCreated", { date: history.lastImportDate })
            : t("integrations:statusBadge.ehrCreatedNoDate");

          return (
            <>
              <Tooltip title={text}>
                <Link onClick={() => setShowLog(true)}>
                  <CloudSyncIcon />
                </Link>
              </Tooltip>
              <IntegrationStatusLog
                importHistory={history}
                resourceType={props.resourceType}
                show={showLog}
                setShow={setShowLog}
              />
            </>
          );
        }
      }

      return null;
    },
    Loading: () => {
      return null;
    },
    _: () => {
      // Disappear the badge on error - no point in having it show an error message
      return null;
    },
  });
}

/**
 * Converts the supported ids to an import history id. This is all just type theater and doesn't do anything real.
 */
export function toImportHistoryId(
  id: PatientId | CareEpisodeId | ProviderId | AppointmentId | InstituteConditionId | PatientConditionId
) {
  return id as unknown as ImportHistoryId;
}

export function IntegrationStatusBadgeIfIntegrationEnabled(props: {
  importHistoryId: ImportHistoryId;
  resourceType: DataSourceResourceType;
}) {
  return (
    <WithConfiguration property="integrationEnabled">
      <IntegrationStatusBadge {...props} />
    </WithConfiguration>
  );
}

export default function IntegrationStatusBadge(props: {
  importHistoryId: ImportHistoryId;
  resourceType: DataSourceResourceType;
}) {
  const { remoteData } = apolloQueryHookWrapper(
    useIntegrationStatusBadgeQuery({
      variables: { id: props.importHistoryId, resourceType: props.resourceType },
    })
  );

  return remoteData.caseOf({
    Success: (data) => {
      if (data.integrationsImportHistory) {
        return (
          <IntegrationStatusBadgeInner
            history={data.integrationsImportHistory}
            resourceType={props.resourceType}
          />
        );
      }

      return null;
    },
    Loading: () => {
      return <Skeleton />;
    },
    _: () => {
      // Disappear the badge on error - no point in having it show an error message
      return null;
    },
  });
}
