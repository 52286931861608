import { Alert, Grid, Stack, Typography } from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import { useEnrollmentQuery } from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { unsafeFromUuid } from "Lib/Id";
import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import SurveyManagement from "./DetailTabs/SurveyManagementTab/SurveyManagement";
import SurveyHistoryCard from "./SurveyHistoryCard";
import TasksCard from "./TasksCard";
import { CaseConsultCard } from "./CaseConsultCard";
import PatientDetailsCard from "./PatientDetailsCard";
import PreviousEnrollmentPatientInfoCard from "./PreviousEnrollmentPatientInfoCard";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "Shared/Responsive";
import EnrollmentMonthsCard from "./EnrollmentMonthsCard";
import Link from "MDS/Link";

export function EnrollmentDetails(): ReactElement {
  const { t } = useTranslation(["collaborativeCare", "common", "patients", "enums"]);
  const stickyTop = useIsMobile() ? "4em" : "4.7em";

  const params = useParams<{ patientId: string; enrollmentId: string }>();
  if (!params.patientId) {
    return <></>;
  }
  const patientId = unsafeFromUuid<"Patient">(params.patientId);

  if (!params.enrollmentId) {
    return (
      <Page browserTitle={t("collaborativeCare:patientDetails.enrollmentHistory.enrollmentDetails")}>
        {t("collaborativeCare:patientDetails.enrollmentHistory.noEnrollment")}
      </Page>
    );
  }
  const enrollmentId = unsafeFromUuid<"Enrollment">(params.enrollmentId);

  let content = <></>;
  // we need to get the care episode id
  const { remoteData: enrollmentData } = apolloQueryHookWrapper(
    useEnrollmentQuery({
      variables: { patientId: patientId, enrollmentId: enrollmentId },
    })
  );
  enrollmentData.caseOf({
    Loading: () => {
      content = <></>;
    },
    NotAsked: () => {
      content = <></>;
    },
    Failure: () => {
      content = <></>;
    },
    Success: (result) => {
      if (!result.patient || !result.collaborativeCareEnrollment) {
        content = <></>;
        return;
      }
      content = (
        <>
          <Grid container columns={12} spacing={1}>
            <Grid sx={{ position: "sticky", top: stickyTop, zIndex: "stickyCard" }} item lg={12} xs={12}>
              <Alert variant="filled" severity="info">
                {t("collaborativeCare:patientDetails.enrollmentHistory.viewingText")} (
                <strong>
                  {t("common:date.tiny", { date: result.collaborativeCareEnrollment.enrolledAt })}
                </strong>{" "}
                -{" "}
                <strong>
                  {t("common:date.tiny", { date: result.collaborativeCareEnrollment.dischargedAt })})
                </strong>{" "}
                {t("collaborativeCare:patientDetails.enrollmentHistory.for")}{" "}
                <Link to={`/app/cocm/patient/${patientId}`}>
                  <strong>{result.patient.name}</strong>
                </Link>
              </Alert>
            </Grid>
            <Grid item lg={4} xs={12}>
              <Stack direction="column" minHeight={"100%"} spacing={1} flexGrow={1} flexDirection={"column"}>
                <PatientDetailsCard
                  patient={result.patient}
                  forPreviousEnrollment={true}
                  canReactivate={result.collaborativeCareEnrollment.canReactivate}
                  disableLink
                />
                <PreviousEnrollmentPatientInfoCard
                  patient={result.patient}
                  enrollment={result.collaborativeCareEnrollment}
                />
              </Stack>
            </Grid>
            <Grid item lg={8} xs={12}>
              <Stack direction="column" spacing={1}>
                <SurveyManagement
                  patient={result.patient}
                  mobile={false}
                  forPreviousEnrollment={true}
                  careEpisodeId={result.collaborativeCareEnrollment.careEpisode.id}
                />
                <SurveyHistoryCard
                  patient={result.patient}
                  forPreviousEnrollment={true}
                  careEpisodeId={result.collaborativeCareEnrollment.careEpisode.id}
                />
                <TasksCard
                  patient={result.patient}
                  forPreviousEnrollment={true}
                  enrollmentId={enrollmentId}
                />
                <EnrollmentMonthsCard enrollment={{ id: enrollmentId }} />
                <CaseConsultCard
                  patient={result.patient}
                  forPreviousEnrollment={true}
                  enrollmentId={enrollmentId}
                />
              </Stack>
            </Grid>
          </Grid>
        </>
      );
    },
  });

  const breadcrumbs = enrollmentData.caseOf({
    Success: (response) => {
      if (response.patient) {
        return [
          <Link to={`/app/cocm/patient/${response.patient.id}`} key="patient-breadcrumb">
            {response.patient.name}
          </Link>,
          <Typography key="enrollment-breadcrumb">
            {t("collaborativeCare:patientDetails.enrollmentHistory.enrollmentName", {
              enrolledAt: response.collaborativeCareEnrollment?.enrolledAt,
              dischargedAt: response.collaborativeCareEnrollment?.dischargedAt,
            })}
          </Typography>,
        ];
      } else {
        return [];
      }
    },
    _: () => [],
  });

  return (
    <Page
      breadcrumbs={breadcrumbs}
      browserTitle={t("collaborativeCare:patientDetails.enrollmentHistory.enrollmentDetails")}
    >
      {content}
    </Page>
  );
}
