import { Button, ButtonProps, Collapse } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

type ExpandingIconButtonProps = Omit<ButtonProps, "endIcon" | "startIcon"> & {
  icon: ReactNode;
  defaultOpen?: boolean;
};

/**
 * A button with an icon and a label (defined by the children). The label starts collapsed, but expands on hover, to
 * allow us to use small buttons with icons where we want to conserve space but also have labels so that users are more
 * likely to figure out what they mean. The defaultOpen prop is useful if you're swapping button components around on
 * state changes that might happen while the button is hovered.
 */
export function ExpandingIconButton(props: ExpandingIconButtonProps): ReactElement {
  // These are the props we don't want to copy over to the button - icon because it doesn't exist on Button and the
  // mouse enter/leave events because we need to extend them.
  const { icon, onMouseEnter, onMouseLeave, defaultOpen, ...buttonProps } = props;

  const [showChildren, setShowChildren] = React.useState(!!defaultOpen);

  return (
    <Button
      endIcon={icon}
      onMouseEnter={(event) => {
        setShowChildren(true);
        if (onMouseEnter) {
          onMouseEnter(event);
        }
      }}
      onMouseLeave={(event) => {
        setShowChildren(false);
        if (onMouseLeave) {
          onMouseLeave(event);
        }
      }}
      {...buttonProps}
    >
      {/* whiteSpace: nowrap here so that text labels don't break into multiple lines during the animation and instead
          just overflow and get hidden until the block is wide enough. */}
      <Collapse in={showChildren} orientation="horizontal" sx={{ whiteSpace: "nowrap" }}>
        {props.children}
      </Collapse>
    </Button>
  );
}
