import { Box, Skeleton, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apolloErrorToError, apolloQueryHookWrapper } from "Api/GraphQL";
import {
  GoalDetailQueryQuery,
  GoalStatus,
  ScaleThresholdClass,
  useGoalDetailQueryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { arrOfN, humanize } from "Lib/Utils";
import React, { ReactElement } from "react";
import { Failure, Maybe, RemoteData, Success } from "seidr";
import EmptyState from "Shared/EmptyState";
import ErrorMessage from "Shared/ErrorMessage";
import { formatDate } from "Shared/formatters";
import { ParticipantSummary } from "Shared/Participant";
import { Goal, GoalAnswer, GoalId, GOAL_TYPE_CONFIGURATION, toGoal } from "Shared/Scale/Goal";
import * as Threshold from "Shared/Scale/Threshold";
import * as SeverityThresholdClass from "Shared/Severity/SeverityThresholdClass";
import { FeedbackReportContext, getParticipantByUserId } from "../FeedbackReportContext";
import LevelDetail from "../LevelDetail";
import { LevelHeaderLoadingIndicator } from "../LevelHeader";
import ParticipantAvatar from "../ParticipantAvatar";
import DetailPaneLevelHeader from "./DetailPaneLevelHeader";
import ToggleGoalStatusButton from "./ToggleGoalStatusButton";

type GoalDetailData = RemoteData<Error, Goal>;

export type GoalDetailPaneProps = {
  goalId: GoalId;
  feedbackReportContext: FeedbackReportContext;
};

function GoalDetailPaneHookWrapper(props: GoalDetailPaneProps): ReactElement {
  const { goalId, feedbackReportContext } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useGoalDetailQueryQuery({
      variables: {
        goalId,
      },
    })
  );

  const transformed: GoalDetailData = remoteData
    .mapFailure(apolloErrorToError) // We have to drop the extra apollo error detail to deal with the parsing.
    .flatMap((result) => {
      return parseGoalDetail(result);
    });

  return GoalDetailPane({
    remoteData: transformed,
    feedbackReportContext,
  });
}

function parseGoalDetail(data: GoalDetailQueryQuery): GoalDetailData {
  if (data.assessmentGoal) {
    return Success(toGoal(data.assessmentGoal));
  } else {
    return Failure(new Error("No goal data found"));
  }
}

// -- VIEW --------------------------------------------------------------------

const StyledDateCell = styled("td")(() => ({
  textAlign: "right",
}));

const StyledAnswerCell = styled("td")(() => ({
  position: "relative",
  borderLeft: "1px solid #eee",
  fontWeight: 600,
}));

const StyledAnswerDot = styled("div")<{
  thresholdClass: ScaleThresholdClass;
}>(({ theme, thresholdClass }) => ({
  position: "absolute",
  top: 13,
  left: theme.spacing(-0.5),
  width: theme.spacing(1),
  height: theme.spacing(1),
  borderRadius: theme.spacing(0.5),
  background: SeverityThresholdClass.color(thresholdClass, theme).primary,
}));

type AnswerRowProps = {
  answer: GoalAnswer;
};

function AnswerRow(props: AnswerRowProps): ReactElement {
  const { answer } = props;

  return (
    <tr data-testid="response-row">
      <StyledDateCell>{formatDate(answer.date)}</StyledDateCell>
      <StyledAnswerCell>
        <StyledAnswerDot thresholdClass={answer.thresholdClass} />
        {answer.thresholdMnemonic.map(Threshold.mnemonicTitle).getOrElse(answer.value)}
      </StyledAnswerCell>
    </tr>
  );
}

const StyledTable = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  "& th": {
    fontWeight: "normal",
    color: theme.palette.text.secondary,
    padding: "0.6rem 1.2rem",
    fontSize: theme.typography.body1.fontSize,
  },
  "& td": {
    padding: "0.6rem 1.2rem",
  },
}));

type AnswerTableProps = {
  participant: Maybe<ParticipantSummary>;
  answers: ReadonlyArray<GoalAnswer>;
};

function AnswerTable(props: AnswerTableProps): ReactElement {
  const { answers, participant } = props;

  const responses = answers.map((answer, i) => <AnswerRow key={i} answer={answer} />);

  const thead = participant.map((p) => (
    <thead>
      <th></th>
      <th>
        <ParticipantAvatar participant={p} /> {p.name}
      </th>
    </thead>
  ));

  return (
    <StyledTable>
      {thead.getOrElse(null)}
      <tbody>{responses}</tbody>
    </StyledTable>
  );
}

function GoalDetailLoadingIndicator(): ReactElement {
  const rows = arrOfN(10).map((i) => {
    return (
      <tr key={i} data-testid="loading-row">
        <td>
          <Skeleton width={50} height={20} />
        </td>
        <td>
          <Skeleton width={75} height={20} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <LevelHeaderLoadingIndicator />
      <LevelDetail>
        <table>
          <tbody>{rows}</tbody>
        </table>
      </LevelDetail>
    </div>
  );
}

const StyledGoalStatus = styled("span")(({ theme }) => ({
  fontWeight: "normal",
  color: theme.palette.text.secondary,
}));

type Props = {
  feedbackReportContext: FeedbackReportContext;
  remoteData: GoalDetailData;
};

function GoalDetailPane(props: Props): ReactElement {
  const { remoteData, feedbackReportContext } = props;

  const content = remoteData.caseOf({
    NotAsked: () => <GoalDetailLoadingIndicator />,
    Loading: () => <GoalDetailLoadingIndicator />,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (goal) => {
      const configuration = GOAL_TYPE_CONFIGURATION[goal.goalType];

      const showTable = goal.answers.length > 0;

      const participant = getParticipantByUserId(feedbackReportContext, goal.user.id);

      const table = (
        <Box sx={{ mt: 2 }}>
          <AnswerTable participant={participant} answers={goal.answers} />
        </Box>
      );

      const disabledGoalParts = <>— This item has been deleted</>;

      const activeGoalParts = (
        <ToggleGoalStatusButton careEpisodeId={feedbackReportContext.careEpisodeId} goal={goal} />
      );

      return (
        <>
          <DetailPaneLevelHeader label={configuration.title}>
            {goal.status === GoalStatus.ENTERED_IN_ERROR ? disabledGoalParts : activeGoalParts}
          </DetailPaneLevelHeader>
          <LevelDetail>
            <Typography variant="h3">
              {humanize(goal.patientText)} <StyledGoalStatus>— {humanize(goal.status)}</StyledGoalStatus>
            </Typography>
            {showTable ? (
              table
            ) : (
              <Box sx={{ mt: 1 }}>
                <EmptyState text="There is no data for this goal." />
              </Box>
            )}
          </LevelDetail>
        </>
      );
    },
  });

  return <div data-testid="goal-detail">{content}</div>;
}

export default GoalDetailPaneHookWrapper;
export { GoalDetailPaneHookWrapper as HookWrapper, GoalDetailPane as Component };
