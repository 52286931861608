import { Box, Grid, Paper } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { styled, useTheme } from "@mui/material/styles";
import SortablePagableCollectionDataGrid from "./SortablePagableCollectionDataGrid";

/*
    ╭───────────────────╮
    │ FILTERS           │
    ├────────╭──────────╯
    │ LIST   │  DETAILS
    │        │
    ╰────────╯

    FiltersPaper and ListPaper here square off the corners of those boxes so
    that they meet cleanly in the middle. TopLeftInnerCorner forms the inner
    junction between filters and list by making a paper-colored box and covering
    it with a background-colored box with one corner rounded off. It's
    absolutely positioned so it doesn't affect the layout of any other content.
*/

const FiltersPaper = styled(Paper)(() => ({
  borderBottomLeftRadius: 0,
  paddingBottom: 10,
}));

const ListPaper = styled(Paper)(() => ({
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
}));

function TopLeftInnerCorner(): ReactElement {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: theme.spacing(0.5),
        height: theme.spacing(0.5),
        backgroundColor: theme.palette.background.paper,
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <Box
        sx={{
          width: theme.spacing(0.5),
          height: theme.spacing(0.5),
          backgroundColor: theme.palette.background.default,
          borderTopLeftRadius: theme.spacing(0.5),
        }}
      ></Box>
    </Box>
  );
}

const RelativeGrid = styled(Grid)(() => ({
  position: "relative",
}));

type ListDetailsCutoutProps = {
  filters?: ReactNode;
  list?: ReactNode;
  details?: ReactNode;
};

/** Basic structure for a list and details view. This doesn't try to perform any of the logic to query for the list
 *  and details data, just gives you slots to drop those elements in. See the quality issues page for an example of
 *  using it.
 */
export function ListDetailsCutout(props: ListDetailsCutoutProps): ReactElement {
  return (
    <Grid container spacing={1} alignItems="flex-start">
      <Grid item xs={12} component={FiltersPaper}>
        {props.filters}
      </Grid>
      <Grid item xs={6} component={ListPaper}>
        {props.list}
      </Grid>
      <RelativeGrid item xs={6}>
        <TopLeftInnerCorner />
        {props.details}
      </RelativeGrid>
    </Grid>
  );
}

export const BackgroundShadedCollectionDataGrid = styled(SortablePagableCollectionDataGrid)(({ theme }) => ({
  "& .MuiDataGrid-row.Mui-selected": { backgroundColor: theme.palette.background.default },
}));
