import React, { ReactElement } from "react";
import { AppBar, Box, Menu, MenuList, Toolbar, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCurrentTimeTracking } from "Contexts/TimeTracking/CurrentTimeTrackingContext";
import { TimerDiscardButton, TimerDiscardMenuItem } from "./TimeEntry/TimerDiscardButton";
import { TimerStopButton, TimerStopMenuItem } from "./TimeEntry/TimerStopButton";
import { PatientId } from "Lib/Ids";
import { patientUrl } from "./Utils/patient";
import Link from "MDS/Link";
import { PatientBillableMinutesBadge } from "./PatientBillableMinutesBadge";
import { AddNoteButton, AddNoteDialog, AddNoteMenuItem } from "./Tasks/AddNoteButton";
import { PickTypename } from "type-utils";
import { Patient, Task } from "GeneratedGraphQL/SchemaAndOperations";
import { useIsMobile } from "Shared/Responsive";
import ContainedIconButton from "MDS/ContainedIconButton";
import { TimeIndicatorIcon } from "./LoggingTimeIcon";

// This is also called the time tracking bar. It's the bar that appears at the
// bottom of the screen when a user has started tracking a task.
export function CollaborativeCareAppBar(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const theme = useTheme();
  const timeTrackingContext = useCurrentTimeTracking();

  // We allow tasks not to have a patient at all, so we'll have to support that.
  let patientName = t("collaborativeCare:appBar.noPatient");
  let patientId: PatientId | null = null;
  if (timeTrackingContext.status === "tracking" && timeTrackingContext.patient) {
    patientName = timeTrackingContext.patient.name;
    patientId = timeTrackingContext.patient.id;
  }

  let patientNameMarkup = <Typography variant="h2">{patientName}</Typography>;
  if (timeTrackingContext.status === "tracking" && timeTrackingContext.patient) {
    patientName = timeTrackingContext.patient.name;
    const patient = timeTrackingContext.patient;
    patientNameMarkup = (
      <Typography variant="h2" marginLeft="0.8em">
        <Link color="inherit" to={patientUrl(patient)}>
          {patientName}
        </Link>
      </Typography>
    );
  }

  const mobile = useIsMobile();
  const [showAddNoteForm, setShowAddNoteForm] = React.useState(false);

  // If we aren't actively tracking, don't bother drawing the bar.
  // We'll ditch early in this case, but after hooks.
  if (timeTrackingContext.status !== "tracking") {
    return <></>;
  }

  let patientBillableMinutesBadge = null;
  if (patientId) {
    patientBillableMinutesBadge = <PatientBillableMinutesBadge patientId={patientId} />;
  }

  const actionButtons = (
    <>
      <AddNoteButton onClick={() => setShowAddNoteForm(true)} />
      <TimerDiscardButton
        variant="outlined"
        color="inherit"
        buttonLabel={t("collaborativeCare:tasks.actions.discard")}
        sx={{ marginLeft: "1em" }}
      />
      <TimerStopButton
        buttonLabel={t("collaborativeCare:appBar.saveProgress")}
        completeTask={false}
        variant="outlined"
        color="inherit"
        sx={{ marginLeft: "1em" }}
      />
      <TimerStopButton
        buttonLabel={t("collaborativeCare:appBar.completeTask")}
        completeTask={true}
        variant="contained"
        color="secondary"
        sx={{ marginLeft: "1em" }}
      />
      <TimeIndicatorIcon
        fontSize="large"
        sx={{ fill: theme.palette.primary.contrastText, marginLeft: "1em" }}
      />
    </>
  );

  const actionMenu = (
    <ActionMenu
      task={timeTrackingContext.task}
      patient={{ __typename: "Patient", name: patientName }}
      onAddNote={() => setShowAddNoteForm(true)}
    />
  );

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: "auto", bottom: 0, overflowX: "scroll", display: "flex" }}
    >
      <Toolbar>
        {patientBillableMinutesBadge}
        {patientNameMarkup}
        <Typography marginLeft="1em">|</Typography>
        <Typography marginLeft="1em">{timeTrackingContext.task.title}</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ marginLeft: "1em" }} />
        {mobile ? actionMenu : actionButtons}
      </Toolbar>
      <AddNoteDialog
        task={timeTrackingContext.task}
        patient={{ __typename: "Patient", name: patientName }}
        open={showAddNoteForm}
        onClose={() => setShowAddNoteForm(false)}
      />
    </AppBar>
  );
}

type ActionMenuProps = {
  task: PickTypename<Task, "id" | "title">;
  patient: PickTypename<Patient, "name">;
  onAddNote: () => void;
};

function ActionMenu(props: ActionMenuProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ContainedIconButton onClick={handleClick} sx={{ padding: "0.25rem" }}>
        <TimeIndicatorIcon fontSize="large" />
      </ContainedIconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MenuList>
          <AddNoteMenuItem
            onClick={() => {
              handleClose();
              props.onAddNote();
            }}
          />
          <TimerDiscardMenuItem label={t("collaborativeCare:tasks.actions.discard")} />
          <TimerStopMenuItem label={t("collaborativeCare:appBar.saveProgress")} completeTask={false} />
          <TimerStopMenuItem label={t("collaborativeCare:appBar.completeTask")} completeTask={true} />
        </MenuList>
      </Menu>
    </>
  );
}
