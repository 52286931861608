import Page from "Layout/Page";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import PatientListTable from "./PatientListTable";
import PatientSearchFilters, { usePatientSearchParameters } from "./PatientSearchFilters";

export function PatientList(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);
  const searchFilters = usePatientSearchParameters();

  return (
    <Page browserTitle={t("collaborativeCare:patientList.title")} supportsPanels>
      <PatientSearchFilters filters={searchFilters} />
      <PatientListTable filters={searchFilters} />
    </Page>
  );
}
