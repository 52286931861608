import React, { ReactNode, ReactElement } from "react";
import { Box } from "@mui/material";

type Props = {
  children: ReactNode;
};

function LevelDetail(props: Props): ReactElement {
  return <Box sx={{ pt: 2 }}>{props.children}</Box>;
}

export default LevelDetail;
