import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  DrilldownMetricParams,
  DrilldownMetricShortcode,
  EntityTreeNodeParams,
  Metric,
  MetricInclusionCriterion,
  MetricRollupType,
  MetricSuccessCriterion,
  MetricTrigger,
  TimeBasedMetric,
  TriggerBasedMetric,
  useOutcomesMetricSummaryDataQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { MetricId } from "Lib/Ids";
import React from "react";
import AnalyticsLineChart, { ChartDataItem } from "Shared/Graphs/AnalyticsLineChart";
import {
  HasAggregationType,
  TimeBasedMetricShortTitle,
  TriggerBasedMetricShortTitle,
  dateAccessor,
  metricDownloadDisabled,
  metricUnits,
  metricYMax,
  timeBasedMetricLabelGenerator,
  timeBasedMetricValueFormatter,
  timeBasedMetricYAccessor,
  triggerBasedMetricLabelGenerator,
  triggerBasedMetricValueFormatter,
  triggerBasedMetricYAccessor,
} from "./OutcomesMetricHelpers";
import EntityPath, { EntityTreeNodeDetails } from "Entities/EntityPath";
import { ArrowDropDown, Download } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import Link from "MDS/Link";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import DownloadMetricExcel from "./DownloadMetricExcel";
import { WithPermission } from "Shared/WithPermission";
import { MetricScaleScorerConfigurationWithName } from "./OutcomesFormHelpers";

type MetricSuccessCriterionDetails = Pick<
  MetricSuccessCriterion,
  | "id"
  | "criterionType"
  | "integerValue"
  | "operator"
  | "severityValues"
  | "stringValue"
  | "trendValue"
  | "numberValue"
>;

type MetricInclusionCriteriaDetails = Pick<
  MetricInclusionCriterion,
  | "id"
  | "criterionType"
  | "integerValue"
  | "operator"
  | "severityValues"
  | "stringArrayValue"
  | "stringValue"
  | "trendValue"
  | "numberValue"
  | "excludeResults"
  | "integerArrayValue"
>;

type MetricTriggerDetails = Pick<
  MetricTrigger,
  "id" | "triggerType" | "integerValue" | "severityValue" | "stringArrayValue" | "stringValue" | "trendValue"
>;

export type BaseMetricDetails = Pick<Metric, "id" | "name"> & {
  entityTreeNode: EntityTreeNodeDetails;
};

type BaseMetricConfiguration = {
  rollup: MetricRollupType;
  scorerConfig: MetricScaleScorerConfigurationWithName;
  metricInclusionCriteria: ReadonlyArray<MetricInclusionCriteriaDetails>;
};

export type TriggerBasedMetricConfiguration = BaseMetricConfiguration &
  Pick<TriggerBasedMetric, "__typename" | "triggerBasedAggregationType"> & {
    metricTriggers: ReadonlyArray<MetricTriggerDetails>;
  };

export type TriggerBasedMetricDetails = BaseMetricDetails & {
  configuration: TriggerBasedMetricConfiguration;
};

export type TimeBasedMetricConfiguration = BaseMetricConfiguration &
  Pick<TimeBasedMetric, "__typename" | "timeBasedAggregationType" | "timePeriodType" | "timePeriodValue"> & {
    metricSuccessCriteria: ReadonlyArray<MetricSuccessCriterionDetails>;
  };

export type TimeBasedMetricDetails = BaseMetricDetails & {
  configuration: TimeBasedMetricConfiguration;
};

type CardProps = {
  metric: BaseMetricDetails;
  configuration: TimeBasedMetricConfiguration | TriggerBasedMetricConfiguration;
  entityTreeNode: EntityTreeNodeParams;
  startDate: Date;
  endDate: Date;
};

type SummaryProps = {
  metricId: MetricId;
  metricType: "time" | "trigger";
  configuration: HasAggregationType;
  startDate: Date;
  endDate: Date;
  name: string;
  entityTreeNode: EntityTreeNodeParams;
  yAccessor: (d: ChartDataItem) => string;
  labelAccessor: (d: ChartDataItem) => string;
  valueFormatter: (d: number) => string;
  units: string | null;
};

function OutcomesMetricSummaryData(props: SummaryProps) {
  const { t } = useTranslation(["common", "outcomes"]);
  const { metricId, entityTreeNode, name, yAccessor, labelAccessor } = props;
  const drilldownMetric: DrilldownMetricParams = {
    includeGraph: true,
    entityTreeNode: { node: entityTreeNode },
    period: {
      dateRange: {
        min: props.startDate,
        max: props.endDate,
      },
    },
    shortcode: DrilldownMetricShortcode.OUTCOMES_METRIC_DRILLDOWN,
  };
  const { remoteData } = apolloQueryHookWrapper(
    useOutcomesMetricSummaryDataQuery({
      variables: {
        metricId: metricId,
        drilldownMetric,
        scaleScorerId: null,
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => null,
    NotAsked: () => null,
    Failure: () => {
      return <>{t("outcomes:card.errorLoadingChart")}</>;
    },
    Success: (result) => {
      if (result.outcomesMetricSummaryData.reportEntityTreeNodes[0]) {
        const node = result.outcomesMetricSummaryData.reportEntityTreeNodes[0];
        const graphData = node.summaryGraphData?.find((x) => x.name === "value");

        const { value, successfulRows, eligibleRows, totalRows, triggerRows, rowsInTimePeriod } =
          result.outcomesMetricSummaryData;

        let graph = <Typography textAlign={"center"}>{t("outcomes:card.noData")}</Typography>;

        if (graphData) {
          graph = (
            <AnalyticsLineChart
              // We need the array to be mutable for the underlying chart data.
              data={graphData.points.concat()}
              dataKey={name}
              xAccessor={dateAccessor}
              yAccessor={yAccessor}
              labelAccessor={labelAccessor}
              yDomainMax={metricYMax(props.configuration, graphData.points)}
              numYTicks={5}
            />
          );
        }

        let headline = null;
        if (props.metricType === "time") {
          headline = (
            <TimeBasedMetricHeadline
              valueFormatter={props.valueFormatter}
              value={value}
              eligibleRows={eligibleRows}
              successfulRows={successfulRows}
              rowsInTimePeriod={rowsInTimePeriod}
              totalRows={totalRows}
              units={props.units}
            />
          );
        } else {
          headline = (
            <TriggerBasedMetricHeadline
              valueFormatter={props.valueFormatter}
              value={value}
              eligibleRows={eligibleRows}
              triggerRows={triggerRows}
              totalRows={totalRows}
              units={props.units}
            />
          );
        }

        return (
          <Grid container spacing={1}>
            <Grid item xs={9}>
              {graph}
            </Grid>
            <Grid item xs={3}>
              {headline}
            </Grid>
          </Grid>
        );
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
  });
}

function TimeBasedMetricHeadline(props: {
  valueFormatter: (d: number) => string;
  value: number | null;
  totalRows: number;
  eligibleRows: number;
  successfulRows: number;
  rowsInTimePeriod: number;
  units: string | null;
}) {
  const { t } = useTranslation(["outcomes"]);
  const formattedValue = props.value !== null ? props.valueFormatter(props.value) : "-";

  const units = props.units ? (
    <Typography variant="h2" textAlign={"center"}>
      {props.units}
    </Typography>
  ) : null;
  return (
    <>
      <Typography variant="h1" fontSize={"3.5em"} textAlign={"center"}>
        {formattedValue}
      </Typography>
      {units}
      <Typography textAlign={"center"}>
        <Stack direction="column">
          <Typography>{t("outcomes:card.successfulRows", { value: props.successfulRows })}</Typography>
          <Typography>{t("outcomes:card.eligibleRows", { value: props.eligibleRows })}</Typography>
          <Typography>{t("outcomes:card.rowsInTimePeriod", { value: props.rowsInTimePeriod })}</Typography>
          <Typography>{t("outcomes:card.totalRows", { value: props.totalRows })}</Typography>
        </Stack>
      </Typography>
    </>
  );
}

function TriggerBasedMetricHeadline(props: {
  valueFormatter: (d: number) => string;
  value: number | null;
  totalRows: number;
  eligibleRows: number;
  triggerRows: number;
  units: string | null;
}) {
  const { t } = useTranslation(["outcomes"]);
  const formattedValue = props.value !== null ? props.valueFormatter(props.value) : "-";
  return (
    <>
      <Typography variant="h1" fontSize={"3.5em"} textAlign={"center"}>
        {formattedValue}
      </Typography>
      <Typography variant="h2" textAlign={"center"}>
        {props.units}
      </Typography>
      <Typography textAlign={"center"}>
        <Stack>
          <Typography>{t("outcomes:card.triggerRows", { value: props.triggerRows })}</Typography>
          <Typography>{t("outcomes:card.eligibleRows", { value: props.eligibleRows })}</Typography>
          <Typography>{t("outcomes:card.totalRows", { value: props.totalRows })}</Typography>
        </Stack>
      </Typography>
    </>
  );
}

function TimeBasedMetricCard(props: {
  startDate: Date;
  endDate: Date;
  entityTreeNode: EntityTreeNodeParams;
  metric: BaseMetricDetails;
  configuration: TimeBasedMetricConfiguration;
}) {
  const { search } = useLocation();
  const { t } = useTranslation(["outcomes"]);
  const yAccessor = timeBasedMetricYAccessor(props.configuration.timeBasedAggregationType);
  const labelAccessor = timeBasedMetricLabelGenerator(props.configuration.timeBasedAggregationType);
  const valueFormatter = timeBasedMetricValueFormatter(props.configuration.timeBasedAggregationType);
  const units = metricUnits(props.configuration, t);
  const subheader = <TimeBasedMetricShortTitle config={props.configuration} />;

  const title = <Link to={`${props.metric.id.toString()}${search}`}>{props.metric.name}</Link>;
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <EditButtonWithMenu
            id={props.metric.id}
            endDate={props.endDate}
            startDate={props.startDate}
            entityTreeNode={props.entityTreeNode}
            downloadDisabled={metricDownloadDisabled(props.configuration)}
          />
        }
      />
      <CardContent>
        <EntityPath includeInstitute={"always"} entityTreeNode={props.metric.entityTreeNode} />
        <OutcomesMetricSummaryData
          metricType="time"
          configuration={props.configuration}
          startDate={props.startDate}
          endDate={props.endDate}
          yAccessor={yAccessor}
          valueFormatter={valueFormatter}
          labelAccessor={labelAccessor}
          metricId={props.metric.id}
          entityTreeNode={props.entityTreeNode}
          name={props.metric.name}
          units={units}
        />
      </CardContent>
    </Card>
  );
}

function TriggerBasedMetricCard(props: {
  startDate: Date;
  endDate: Date;
  entityTreeNode: EntityTreeNodeParams;
  metric: BaseMetricDetails;
  configuration: TriggerBasedMetricConfiguration;
}) {
  const { t } = useTranslation(["outcomes"]);
  const { search } = useLocation();
  const yAccessor = triggerBasedMetricYAccessor(props.configuration.triggerBasedAggregationType);
  const labelAccessor = triggerBasedMetricLabelGenerator(props.configuration.triggerBasedAggregationType, t);
  const valueFormatter = triggerBasedMetricValueFormatter(props.configuration.triggerBasedAggregationType);
  const units = metricUnits(props.configuration, t);
  const subheader = <TriggerBasedMetricShortTitle configuration={props.configuration} />;
  const title = <Link to={`${props.metric.id.toString()}${search}`}>{props.metric.name}</Link>;
  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        action={
          <EditButtonWithMenu
            id={props.metric.id}
            endDate={props.endDate}
            startDate={props.startDate}
            entityTreeNode={props.entityTreeNode}
            downloadDisabled={metricDownloadDisabled(props.configuration)}
          />
        }
      />
      <CardContent>
        <EntityPath includeInstitute={"always"} entityTreeNode={props.metric.entityTreeNode} />
        <OutcomesMetricSummaryData
          metricType="trigger"
          configuration={props.configuration}
          startDate={props.startDate}
          endDate={props.endDate}
          yAccessor={yAccessor}
          valueFormatter={valueFormatter}
          labelAccessor={labelAccessor}
          metricId={props.metric.id}
          entityTreeNode={props.entityTreeNode}
          name={props.metric.name}
          units={units}
        />
      </CardContent>
    </Card>
  );
}

type EditButtonWithMenuProps = {
  startDate: Date;
  endDate: Date;
  entityTreeNode: EntityTreeNodeParams;
  id: MetricId;
  downloadDisabled: boolean;
};

function EditButtonWithMenu(props: EditButtonWithMenuProps) {
  const { t } = useTranslation(["common", "outcomes"]);
  const [anchor, setAnchor] = React.useState<Element | null>(null);
  const open = anchor != null;
  const handleOpen = (event: React.MouseEvent) => {
    setAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setAnchor(null);
  };

  const downloadButton = props.downloadDisabled ? null : (
    <DownloadMetricExcel
      endDate={props.endDate}
      startDate={props.startDate}
      entityTreeNode={props.entityTreeNode}
      metricId={props.id}
    >
      <Download />
    </DownloadMetricExcel>
  );

  return (
    <>
      {downloadButton}
      <WithPermission permission="editOutcomesMetrics">
        <Button onClick={handleOpen}>
          <SettingsIcon />
          <ArrowDropDown fontSize="small" />
        </Button>
        <Menu open={open} onClose={handleClose} anchorEl={anchor}>
          <Link to={`${props.id.toString()}/edit`}>
            <MenuItem>{t("common:edit")}</MenuItem>
          </Link>
        </Menu>
      </WithPermission>
    </>
  );
}

export function OutcomesMetricCard(props: CardProps) {
  switch (props.configuration.__typename) {
    case "TimeBasedMetric":
      return (
        <TimeBasedMetricCard
          metric={props.metric}
          entityTreeNode={props.entityTreeNode}
          startDate={props.startDate}
          endDate={props.endDate}
          configuration={props.configuration}
        />
      );
    case "TriggerBasedMetric":
      return (
        <TriggerBasedMetricCard
          metric={props.metric}
          entityTreeNode={props.entityTreeNode}
          startDate={props.startDate}
          endDate={props.endDate}
          configuration={props.configuration}
        />
      );
  }
}
