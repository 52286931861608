import React, { ReactElement, ReactNode } from "react";
import Link from "./Link";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ShadedTable } from "./ShadedTable";
import { ArrowDownward, ArrowUpward, Download } from "@mui/icons-material";
import { PropertyTable } from "./PropertyTable";
import { OnDesktop, OnMobile, useIsMobile } from "Shared/Responsive";
import { useTranslation } from "react-i18next";
import { SortDirection } from "GeneratedGraphQL/SchemaAndOperations";

/**
 * This module defines some helper functions and components for managing data that we want to export to a CSV file.
 * Some of the code in here may be useful in the future if we want to tackle the unified table project in =CCC-421.
 * As of this writing though, the table component in here is not a serious attempt at that project, just enough to
 * be usable in the one place we currently want to export a CSV. The basic structure here of having a column definition
 * that we then use with a set of rows to handle the different sorts of rendering we want to do is likely a fruitful
 * pattern when we do get to that project though.
 */

/**
 * This type covers javascript values that have a single obvious way to render them both as an HTML node and a value in
 * a CSV file. Note that this is more or less equivalent to ReactNode minus the React bits.
 */
type SimpleRenderableValue = string | number | boolean | null | undefined;

/**
 * We require all items in a CSV data set to have an id, mostly so that we can put something in the key prop of
 * components that we render in a loop.
 */
type CsvExportableRow = { id: NonNullable<unknown> };

type CsvExportableRowNumberColDef = {
  rowNumber: true;
  headerName: string;
};

/**
 * This is a simplified version of Mui's column definition, just enough to serve our needs today.
 */
type CsvExportableColDef<TRow extends CsvExportableRow> =
  | {
      /**
       * Which field in the row data this column corresponds to. Note that unlike Mui I'm restricting this to actual keys
       * of the row type rather than any string. This forces us to project the raw data into flattened row structs before
       * passing it to the table. I'm not sure if that's good overall or just convenient for now.
       */
      field: keyof TRow;
      headerName: string;
      /** Minimum column width in pixels */
      minWidth?: number;
      /**
       * Whether or not the table can be sorted by this column. Defaults to false. Will sort by the value that gets
       * rendered into the CSV.
       */
      sortable?: boolean;
      /**
       * If true, the table will start out sorted by this value. Setting this to true on multiple columns will sort by
       * an arbitrary one.
       */
      defaultSort?: boolean;
      /**
       * If the raw value out of the row struct isn't suitable for rendering into HTML, supply a renderCell function to
       * render a custom value instead.
       */
      renderCell?: (row: TRow) => ReactNode;
      /**
       * If the raw value out of the row struct isn't suitable for rendering into a CSV, supply a csvValue function to
       * return a custom value instead.
       */
      csvValue?: (row: TRow) => SimpleRenderableValue;
      /**
       * If true, does not appear in the export
       */
      disableExport?: boolean;

      rowNumber?: false;
    }
  | CsvExportableRowNumberColDef;

export type CsvExportableColDefs<TRow extends CsvExportableRow> = ReadonlyArray<CsvExportableColDef<TRow>>;

// Always using Windows line endings partly on the assumption that most of our users are on windows and partly because
// thats what Mui did and I'd rather just do the exact same thing than try to change anything.
const LINE_ENDING = "\r\n";
const SEPARATOR = ",";
const VALUE_BOUNDARY = '"';

function escapeCsvValue(value: string): string {
  // If a CSV value includes a comma, we have to surround it in quotes so that it counts as one value rather than two.
  // But then of course if the value contains quotes now we need to escape the quotes by doubling them.
  const escapedQuotes = value.replaceAll(VALUE_BOUNDARY, `${VALUE_BOUNDARY}${VALUE_BOUNDARY}`);

  if (escapedQuotes.includes(SEPARATOR) || escapedQuotes.includes(VALUE_BOUNDARY)) {
    return `${VALUE_BOUNDARY}${escapedQuotes}${VALUE_BOUNDARY}`;
  } else {
    return escapedQuotes;
  }
}

export function csvValue<TRow extends CsvExportableRow>(
  column: CsvExportableColDef<TRow>,
  row: TRow
): string {
  if (column.rowNumber) {
    return "";
  }
  const value = column.csvValue ? column.csvValue(row) : row[column.field];
  const stringValue = value === null || value === undefined ? "" : value.toString();

  return escapeCsvValue(stringValue);
}

export function csvData<TRow extends CsvExportableRow>(
  columns: CsvExportableColDefs<TRow>,
  rows: ReadonlyArray<TRow>
): string {
  const exportableColumns = columns.filter((c) => !c.rowNumber && !c.disableExport);
  const headerLine = exportableColumns.map((column) => escapeCsvValue(column.headerName)).join(SEPARATOR);
  const dataLines = rows
    .map((row) => exportableColumns.map((column) => csvValue(column, row)).join(SEPARATOR))
    .join(LINE_ENDING);
  return `${headerLine}${LINE_ENDING}${dataLines}`;
}

export function csvDataUrl<TRow extends CsvExportableRow>(
  columns: CsvExportableColDefs<TRow>,
  rows: ReadonlyArray<TRow>
): string {
  return `data:text/plain;charset=utf-8,${encodeURIComponent(csvData(columns, rows))}`;
}

function cellContent<TRow extends CsvExportableRow>(column: CsvExportableColDef<TRow>, row: TRow): ReactNode {
  if (!column.rowNumber && column.renderCell) {
    return column.renderCell(row);
  }

  const value = !column.rowNumber ? row[column.field] : null;
  // ESlint and TSC disagree about whether null is a memeber of value's type here - it definitely is so we have to make
  // ESlint stop complaining about it.
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return value === null || value === undefined ? "" : value.toString();
}

type CsvDownloadLinkProps<TRow extends CsvExportableRow> = {
  filename: string;
  columns: CsvExportableColDefs<TRow>;
  rows: ReadonlyArray<TRow>;
};

export function CsvLink<TRow extends CsvExportableRow>(
  props: React.PropsWithChildren<CsvDownloadLinkProps<TRow>>
): ReactElement {
  // If we wanted to, we could make this defer actually constructing the large CSV string until the user clicked on it,
  // by running an onClick function that constructs a new link element and clicks on it, e.g., https://stackoverflow.com/a/18197341
  // We might want to do that for performance reasons, or we'll be forced into it if we're constructing the CSV data
  // incrementally or only loading it after the click or something.
  return (
    <Link to={csvDataUrl(props.columns, props.rows)} download={props.filename}>
      {props.children}
    </Link>
  );
}

function csvFieldCompare<TRow extends CsvExportableRow>(
  rowA: TRow,
  rowB: TRow,
  column: CsvExportableColDef<TRow>
): number {
  if (column.rowNumber) {
    return 0;
  }
  const valueA = column.csvValue ? column.csvValue(rowA) : rowA[column.field];
  const valueB = column.csvValue ? column.csvValue(rowB) : rowB[column.field];

  if (valueA === valueB) {
    return 0;
  } else if (valueA === null || valueA === undefined) {
    return -1;
  } else if (valueB === null || valueB === undefined) {
    return 1;
  } else if (valueA === true && valueB === false) {
    return -1;
  } else if (valueA === false && valueB === true) {
    return 1;
  } else if (typeof valueA === "string" && typeof valueB === "string") {
    return valueA.localeCompare(valueB);
  } else if (typeof valueA === "number" && typeof valueB === "number") {
    return valueA - valueB;
  } else {
    // Realistically I don't think we should get here. The first condition captures all equal values. The next two
    // capture all null and undefined values. The next four capture all cases where we have two unequal non-nullish
    // values of the same type. So the only way to get here is if we have two rows of data with different non-nullish
    // types in the same field, which shouldn't happen. To ensure a non-crashing default behavior we'll convert
    // everything to a string and compare the strings.
    return valueA.toString().localeCompare(valueB.toString());
  }
}

type CsvExportableTableProps<TRow extends CsvExportableRow> = {
  rows: ReadonlyArray<TRow>;
  columns: CsvExportableColDefs<TRow>;
  filename: string;
  emptyMessage?: string;
};

export function CsvExportableTable<TRow extends CsvExportableRow>(
  props: CsvExportableTableProps<TRow>
): ReactElement {
  const { t } = useTranslation(["general"]);

  const someDefaultSortColumn = props.columns.findIndex((column) => !column.rowNumber && column.defaultSort);
  const [sortColumnIndex, setSortColumnIndex] = React.useState(
    someDefaultSortColumn === -1 ? undefined : someDefaultSortColumn
  );

  // Mobile doesn't have a clean way to set sort column and direction at the same time, so rather than defaulting
  // to no direction, default to ASC so that a sort happens as soon as they select a column. This means there's no good
  // way to get back to no sort on mobile but that's a price I'm willing to pay. Similarly, if we have a default sort
  // column, we have also have a default sort direction.
  const startTableSorted = someDefaultSortColumn >= 0 || useIsMobile();
  const defaultSortDirection = startTableSorted ? SortDirection.ASC : undefined;
  const [sortDirection, setSortDirection] = React.useState<SortDirection | undefined>(defaultSortDirection);
  const sortedRows = React.useMemo(() => {
    if (sortColumnIndex === undefined || sortDirection === undefined) {
      return props.rows;
    }

    const sortColumn = props.columns[sortColumnIndex];

    if (sortColumn === undefined) {
      return props.rows;
    }

    const sortedRows = [...props.rows];
    sortedRows.sort((rowA, rowB) => {
      const order = csvFieldCompare(rowA, rowB, sortColumn);
      return sortDirection === SortDirection.ASC ? order : -order;
    });
    return sortedRows;
  }, [props.rows, sortColumnIndex, sortDirection]);

  return (
    <Stack direction="column" spacing={1}>
      <CsvLink filename={props.filename} columns={props.columns} rows={sortedRows}>
        <Button variant="contained" color="secondary" startIcon={<Download />}>
          {t("general:csv.export")}
        </Button>
      </CsvLink>
      <OnDesktop>
        <CsvExportableDesktopTable
          rows={sortedRows}
          columns={props.columns}
          sortColumnIndex={sortColumnIndex}
          sortDirection={sortDirection}
          setSortColumnIndex={setSortColumnIndex}
          setSortDirection={setSortDirection}
        />
      </OnDesktop>
      <OnMobile>
        <CsvExportableMobileTable
          rows={sortedRows}
          columns={props.columns}
          sortColumnIndex={sortColumnIndex}
          sortDirection={sortDirection}
          setSortColumnIndex={setSortColumnIndex}
          setSortDirection={setSortDirection}
        />
      </OnMobile>
      {props.rows.length === 0 ? <NoRowsMessage message={props.emptyMessage} /> : null}
    </Stack>
  );
}

function NoRowsMessage(props: { message?: string }): ReactElement {
  const { t } = useTranslation(["general"]);
  const content = props.message || t("general:csv.noData");

  return (
    <Box sx={{ padding: "5rem", textAlign: "center" }}>
      <Typography variant="h2">{content}</Typography>
    </Box>
  );
}

type CsvExportableRowDisplayProps<TRow extends CsvExportableRow> = {
  rows: ReadonlyArray<TRow>;
  columns: CsvExportableColDefs<TRow>;
  sortColumnIndex: number | undefined;
  sortDirection: SortDirection | undefined;
  setSortColumnIndex: (newIndex: number) => void;
  setSortDirection: (newDirection: SortDirection | undefined) => void;
};

function CsvExportableDesktopTable<TRow extends CsvExportableRow>(
  props: CsvExportableRowDisplayProps<TRow>
): ReactElement {
  return (
    <ShadedTable>
      <CsvExportableTableHeader
        columns={props.columns}
        sortColumnIndex={props.sortColumnIndex}
        sortDirection={props.sortDirection}
        setSortColumnIndex={props.setSortColumnIndex}
        setSortDirection={props.setSortDirection}
      />
      <TableBody>
        {props.rows.map((row, index) => (
          <CsvExportableTableRow
            key={row.id.toString()}
            row={row}
            columns={props.columns}
            rowNumber={index + 1}
          />
        ))}
      </TableBody>
    </ShadedTable>
  );
}

type CsvExportableTableHeaderProps<TRow extends CsvExportableRow> = {
  columns: CsvExportableColDefs<TRow>;
  sortColumnIndex: number | undefined;
  sortDirection: SortDirection | undefined;
  setSortColumnIndex: (newIndex: number) => void;
  setSortDirection: (newDirection: SortDirection | undefined) => void;
};

function CsvExportableTableHeader<TRow extends CsvExportableRow>(
  props: CsvExportableTableHeaderProps<TRow>
): ReactElement {
  return (
    <TableHead>
      <TableRow>
        {props.columns.map((column, index) => (
          <SortableTableHeader
            key={!column.rowNumber ? column.field.toString() : "rowNumber"}
            column={column}
            index={index}
            sortColumnIndex={props.sortColumnIndex}
            sortDirection={props.sortDirection}
            setSortColumnIndex={props.setSortColumnIndex}
            setSortDirection={props.setSortDirection}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

type SortableTableHeaderProps<TRow extends CsvExportableRow> = {
  column: CsvExportableColDef<TRow>;
  index: number;
  sortColumnIndex: number | undefined;
  sortDirection: SortDirection | undefined;
  setSortColumnIndex: (newIndex: number) => void;
  setSortDirection: (newDir: SortDirection | undefined) => void;
};

function SortableTableHeader<TRow extends CsvExportableRow>(props: SortableTableHeaderProps<TRow>) {
  const style = {
    minWidth: !props.column.rowNumber && props.column.minWidth ? `${props.column.minWidth}px` : undefined,
    cursor: !props.column.rowNumber && props.column.sortable ? "pointer" : "auto",
    "& .csv-table-sort-indicator": {
      visibility: "hidden",
    },
    "&:hover .csv-table-sort-indicator": {
      visibility: "visible",
    },
  };

  const sortingThisColumn = props.sortColumnIndex === props.index && props.sortDirection !== undefined;

  const sortArrow = props.sortDirection === SortDirection.ASC ? <ArrowUpward /> : <ArrowDownward />;
  const currentSortIndicator = sortingThisColumn ? sortArrow : null;

  // Using the rare explicit class name here so we can show/hide this in css rather than having to keep the cursor
  // hover position in state via mousein/mouseout events.
  const fadedSortArrow = <ArrowUpward color="disabled" className="csv-table-sort-indicator" />;
  const hoverSortIndicator =
    !props.column.rowNumber && props.column.sortable && !sortingThisColumn ? fadedSortArrow : null;

  const onClick = () => {
    if (!props.column.rowNumber && props.column.sortable) {
      props.setSortColumnIndex(props.index);
      if (props.sortColumnIndex !== props.index) {
        props.setSortDirection(SortDirection.ASC);
      } else if (props.sortDirection === SortDirection.ASC) {
        props.setSortDirection(SortDirection.DESC);
      } else if (props.sortDirection === SortDirection.DESC) {
        props.setSortDirection(undefined);
      } else {
        // props.sortDirection === undefined
        props.setSortDirection(SortDirection.ASC);
      }
    }
  };

  return (
    <TableCell sx={style} onClick={onClick}>
      <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{props.column.headerName}</Typography>
        {currentSortIndicator}
        {hoverSortIndicator}
      </Stack>
    </TableCell>
  );
}

type CsvExportableTableRowProps<TRow extends CsvExportableRow> = {
  row: TRow;
  columns: ReadonlyArray<CsvExportableColDef<TRow>>;
  rowNumber: number;
};

function CsvExportableTableRow<TRow extends CsvExportableRow>(
  props: CsvExportableTableRowProps<TRow>
): ReactElement {
  return (
    <TableRow>
      {props.columns.map((column) => {
        const style = !column.rowNumber && column.minWidth ? { minWidth: `${column.minWidth}px` } : {};
        if (column.rowNumber) {
          return (
            <TableCell key={`rowNumber-${props.row.id.toString()}`} sx={style}>
              {props.rowNumber}
            </TableCell>
          );
        }
        return (
          <TableCell key={`${column.field.toString()}-${props.row.id.toString()}`} sx={style}>
            {cellContent(column, props.row)}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

function CsvExportableMobileTable<TRow extends CsvExportableRow>(
  props: CsvExportableRowDisplayProps<TRow>
): ReactElement {
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1}>
        <SortColumnSelect
          columns={props.columns}
          sortColumnIndex={props.sortColumnIndex}
          setSortColumnIndex={props.setSortColumnIndex}
        />
        <SortDirectionSwitcher
          sortDirection={props.sortDirection}
          setSortDirection={props.setSortDirection}
        />
      </Stack>
      {props.rows.map((row, index) => (
        <CsvExportableRowCard
          row={row}
          rowNumber={index + 1}
          columns={props.columns}
          key={row.id.toString()}
        />
      ))}
    </Stack>
  );
}

function CsvExportableRowCard<TRow extends CsvExportableRow>(
  props: CsvExportableTableRowProps<TRow>
): ReactElement {
  return (
    <Card>
      <CardContent>
        <PropertyTable>
          <TableBody>
            {props.columns.map((column) => {
              if (column.rowNumber) {
                return (
                  <TableRow key={`rowNumber-${props.row.id.toString()}`}>
                    <TableCell>{column.headerName}</TableCell>
                    <TableCell>{props.rowNumber}</TableCell>
                  </TableRow>
                );
              }
              return (
                <TableRow key={`${column.field.toString()}-${props.row.id.toString()}`}>
                  <TableCell>{column.headerName}</TableCell>
                  <TableCell>{cellContent(column, props.row)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </PropertyTable>
      </CardContent>
    </Card>
  );
}

type SortColumnSelectProps<TRow extends CsvExportableRow> = {
  columns: CsvExportableColDefs<TRow>;
  sortColumnIndex: number | undefined;
  setSortColumnIndex: (newIndex: number) => void;
};

function SortColumnSelect<TRow extends CsvExportableRow>(props: SortColumnSelectProps<TRow>): ReactElement {
  const { t } = useTranslation(["common"]);
  const theme = useTheme();

  const onChange = (event: SelectChangeEvent) => {
    const index = Number.parseInt(event.target.value);
    props.setSortColumnIndex(index);
  };

  return (
    <FormControl sx={{ flexGrow: 1 }}>
      <InputLabel>{t("common:actions.sort")}</InputLabel>
      <Select
        value={(props.sortColumnIndex === undefined ? "" : props.sortColumnIndex).toString()}
        onChange={onChange}
        sx={{ backgroundColor: theme.palette.background.paper }}
        label={t("common:actions.sort")}
      >
        {props.columns.map((column, index) => {
          if (!column.rowNumber && column.sortable) {
            return (
              <MenuItem key={column.field.toString()} value={index.toString()}>
                {column.headerName}
              </MenuItem>
            );
          } else {
            return null;
          }
        })}
      </Select>
    </FormControl>
  );
}

type SortDirectionSwitcherProps = {
  sortDirection: SortDirection | undefined;
  setSortDirection: (newDirection: SortDirection) => void;
};

function SortDirectionSwitcher(props: SortDirectionSwitcherProps) {
  const icon = props.sortDirection === SortDirection.ASC ? <ArrowUpward /> : <ArrowDownward />;
  const nextSortDirection =
    props.sortDirection === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC;

  return <IconButton onClick={() => props.setSortDirection(nextSortDirection)}>{icon}</IconButton>;
}
