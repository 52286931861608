import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { apolloMutationHookWrapper } from "Api/GraphQL";
import {
  TimeEntryLog,
  TimeEntryLogDurationReviewStatus,
  useEditTimeEntryLogMutation,
} from "GeneratedGraphQL/SchemaAndOperations";
import { PatientId, ProviderId, TaskId, TimeEntryLogId } from "Lib/Ids";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Form, FormOverlay, useForm, useNumberField, useWrappedField, useTextField } from "Shared/Form";
import { addMinutes } from "date-fns";
import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteTimeEntryButton } from "./DeleteTimeEntryButton";
import { refetchQueries } from "Lib/RefetchQueries";
import { useEnrollmentState } from "CollaborativeCare/PatientDetails/EnrollmentState";
import PlaceholderOptions from "CollaborativeCare/Tasks/PlaceholderOptions";
import { useCurrentTask } from "Contexts/CurrentTaskContext";
import { useCurrentProviderId } from "AppSession/AppSession";

// This hook just breaks out the actual form, so we can use the same form logic with a completely different
// presentation and all will be well.
export function useTimeEntryLogEditForm(
  clientStartTime: Date,
  defaultDurationMinutes: number | undefined,
  providerId: ProviderId,
  timeEntryLogId: TimeEntryLogId,
  onSuccess: (() => void) | undefined
) {
  const [editTimeEntry, { remoteData, reset: resetMutation }] = apolloMutationHookWrapper(
    (response) => response.collaborativeCareEditTimeEntryLog,
    useEditTimeEntryLogMutation({
      refetchQueries: refetchQueries("timeEntries"),
    })
  );

  const fields = {
    // Error matchers on these two fields are for fields on TimeEntryLog
    clientStartTime: useWrappedField({
      required: true,
      default: clientStartTime,
      errorPaths: ["start_time", "client_start_time"],
    }),
    durationMinutes: useNumberField({
      required: false,
      default: defaultDurationMinutes,
      errorPaths: ["duration"],
    }),
    // Error matcher on this field is for Note. Fortunately the attributes on these two models are disjoint.
    noteText: useTextField({ required: false, errorPaths: ["text"] }),
    // There's no end time field because we calculate it from start+duration. End time errors will end up in the
    // global error display, which I think is correct since you can't edit that field directly.
  };

  const clientEndTime = addMinutes(
    fields.clientStartTime.value as Date,
    fields.durationMinutes.value as number
  );

  const form = useForm({
    fields: fields,
    submit: () => {
      editTimeEntry({
        variables: {
          input: {
            providerId: providerId,
            timeEntryLogId: timeEntryLogId,
            clientStartTime: fields.clientStartTime.value as Date,
            clientEndTime: clientEndTime,
            durationSeconds: durationMinutesToSeconds(fields.durationMinutes.value),
            noteText: fields.noteText.value as string,
          },
        },
      });
    },
    remoteData: remoteData,
    onSuccess: onSuccess,
  });

  return { form, fields, remoteData, resetMutation };
}

export type ModifyTimerFormProps = {
  taskId: TaskId;
  isPlaceholder?: boolean;
  patientId?: PatientId;
  timeEntryLog: Pick<
    TimeEntryLog,
    | "id"
    | "clientStartTime"
    | "durationSeconds"
    | "durationReviewStatus"
    | "reviewedAt"
    | "unreviewedDurationSeconds"
  >;
  onSuccess?: () => void;
};

// This is the actual legacy form presentation. This form should go away once timelines is released.
export function ModifyTimerForm(props: ModifyTimerFormProps): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const [showTimeEntryForm, setShowTimeEntryForm] = useState<boolean>(true);

  const defaultDurationMinutes =
    props.timeEntryLog.durationSeconds === null
      ? undefined
      : Math.round(props.timeEntryLog.durationSeconds / 60);

  const currentProviderId = useCurrentProviderId();
  if (!currentProviderId) {
    // This breaks the hooks model in that we won't use the later ones, but providers also
    // can't both be and not be when this component renders, so it's a moot problem for now.
    // If we do end up needing this we'll need to rebox these into new components.
    return <></>;
  }

  const { form, fields, remoteData } = useTimeEntryLogEditForm(
    props.timeEntryLog.clientStartTime,
    defaultDurationMinutes,
    currentProviderId,
    props.timeEntryLog.id,
    props.onSuccess
  );

  const maybeTask = useCurrentTask();
  const displayedTaskId = maybeTask?.id || props.taskId;
  let placeholder = props.isPlaceholder;
  if (maybeTask) {
    placeholder = maybeTask.isPlaceholder;
  }

  let placeholderOptionsContent = <></>;
  if (placeholder && props.patientId) {
    placeholderOptionsContent = (
      <PlaceholderOptions
        patientId={props.patientId}
        taskId={displayedTaskId}
        setShowSurroundingForms={setShowTimeEntryForm}
      />
    );
  }

  // We're going to use the duration to infer the end time rather than let someone choose it,
  // so we'll have to calculate when that actually is.
  const startDate = fields.clientStartTime.value as Date;
  const seconds = durationMinutesToSeconds(fields.durationMinutes.value);
  const minutes = seconds ? seconds / 60 : 0; // We need a possible zero, and duration can be undefined.
  const endTime = addMinutes(startDate, minutes);

  let formContent = (
    <>
      <DateTimePicker
        label={t("collaborativeCare:fields.manualStartTime.label")}
        format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
        value={fields.clientStartTime.value}
        onChange={fields.clientStartTime.onChange}
        slotProps={{
          textField: {
            error: fields.clientStartTime.error,
            helperText: fields.clientStartTime.helperText,
          },
        }}
      />
      <TextField
        title={t("collaborativeCare:fields.duration.label")}
        label={t("collaborativeCare:fields.duration.label")}
        autoFocus
        value={minutes.toFixed(0)}
        onChange={fields.durationMinutes.onChange}
        error={fields.durationMinutes.error}
        helperText={fields.durationMinutes.helperText}
        type="number"
        InputProps={{ inputProps: { min: 1 } }}
      />
      <DateTimePicker
        label="End Time"
        format={t("collaborativeCare:tasks.timeEntryLog.dateFormat")}
        value={endTime}
        disabled
      />
      <TextField
        multiline
        minRows={5}
        title={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
        label={t("collaborativeCare:tasks.timeEntryLog.noteText.label")}
        autoFocus
        value={fields.noteText.value}
        onChange={fields.noteText.onChange}
        error={fields.noteText.error}
        helperText={fields.noteText.helperText}
      />
      <Stack direction="row-reverse" spacing={1}>
        <ButtonWithSpinner
          variant="contained"
          color="secondary"
          type="submit"
          showSpinner={form.showSpinner}
          disabled={form.disableSubmit}
        >
          {t("collaborativeCare:tasks.timeEntryLog.modifyTimeSave")}
        </ButtonWithSpinner>
        <DeleteTimeEntryButton
          variant="outlined"
          color="secondary"
          onSuccess={props.onSuccess}
          timeEntryLogId={props.timeEntryLog.id}
          buttonLabel={t("collaborativeCare:tasks.timeEntryLog.deleteTimeEntryButtonLabel")}
        />
      </Stack>
    </>
  );
  if (!showTimeEntryForm) {
    formContent = <></>;
  }

  return (
    <>
      <TimeEntryReviewAlert timeEntryLog={props.timeEntryLog} />
      {placeholderOptionsContent}
      <Form onSubmit={form.onSubmit}>
        <FormOverlay
          response={remoteData}
          errorMessage={form.globalError || t("collaborativeCare:tasks.genericFormError")}
        />
        <Stack direction="column" spacing={1}>
          <Box marginBottom={"0.5em"} fontSize={"small"}>
            <OutsideEnrollmentNotification
              patientId={props.patientId}
              clientStartTime={props.timeEntryLog.clientStartTime}
            />
          </Box>
          {formContent}
        </Stack>
      </Form>
    </>
  );
}

type OutsideEnrollmentNotificationProps = {
  patientId?: PatientId;
  clientStartTime: Date;
};

export function OutsideEnrollmentNotification(props: OutsideEnrollmentNotificationProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  if (!props.patientId) {
    return <></>;
  }

  const enrollment = useEnrollmentState(props.patientId);
  if (enrollment.status != "enrolled" || !(props.clientStartTime < enrollment.consentDate)) {
    return <></>;
  }

  return (
    <Typography color={"red"}>
      {t("collaborativeCare:tasks.beforeConsentWarning", { date: enrollment.consentDate })}
    </Typography>
  );
}

// We do this conversion in a number of places, so this is just sticking it in one terrible place.
export function durationMinutesToSeconds(durationMinutes: number | string | undefined): number | undefined {
  if (durationMinutes === undefined) {
    return undefined;
  }

  if (typeof durationMinutes === "string") {
    return Number.parseInt(durationMinutes) * 60;
  }

  return durationMinutes * 60;
}

type TimeEntryReviewAlertProps = {
  timeEntryLog: Pick<
    TimeEntryLog,
    "durationReviewStatus" | "reviewedAt" | "unreviewedDurationSeconds" | "durationSeconds"
  >;
};

export function TimeEntryReviewAlert(props: TimeEntryReviewAlertProps): ReactElement | null {
  const { t } = useTranslation(["collaborativeCare"]);

  const unreviewedMinutes = props.timeEntryLog.unreviewedDurationSeconds
    ? Math.floor(props.timeEntryLog.unreviewedDurationSeconds / 60)
    : null;
  const durationMinutes = props.timeEntryLog.durationSeconds
    ? Math.floor(props.timeEntryLog.durationSeconds / 60)
    : null;

  if (props.timeEntryLog.durationReviewStatus === TimeEntryLogDurationReviewStatus.NEEDS_REVIEW) {
    return (
      <Alert severity="warning">
        {t("collaborativeCare:tasks.timeEntryLog.needsReview", {
          unreviewedMinutes: unreviewedMinutes,
          durationMinutes: durationMinutes,
        })}
      </Alert>
    );
  } else if (props.timeEntryLog.durationReviewStatus === TimeEntryLogDurationReviewStatus.REVIEWED) {
    return (
      <Alert severity="info">
        {t("collaborativeCare:tasks.timeEntryLog.reviewed", { date: props.timeEntryLog.reviewedAt })}
      </Alert>
    );
  } else {
    return null;
  }
}
