import type {} from "@mui/lab/themeAugmentation";
import { ComponentsProps, ComponentsOverrides } from "@mui/material";
import palette from "../palette";

const styleOverrides: ComponentsOverrides["MuiTreeItem"] = {
  content: {
    paddingRight: "1rem",
    // This isn't supported in IE, but I can't find another way to make the content not grow to
    // 100% of its container without doing a lot more surgery. I think we just live with IE getting long
    // item backgrounds.
    width: "max-content",
    "&.Mui-selected": {
      backgroundColor: palette.primary.light,
      color: palette.primary.offset,

      "&.Mui-focused": {
        backgroundColor: palette.primary.light,
      },

      "&:hover": {
        backgroundColor: palette.primary.light,
      },
    },
    "&.Mui-focused": {
      backgroundColor: "transparent",
    },
  },
};

const defaultProps: ComponentsProps["MuiTreeItem"] = {};

const override = {
  defaultProps,
  styleOverrides,
};

export default override;
