import { ComponentsOverrides } from "@mui/material";
import palette from "../palette";
import { FontSize } from "../typography";
const styleOverrides: ComponentsOverrides["MuiTableCell"] = {
  root: {
    fontSize: FontSize.Regular,
    borderBottom: `1px solid ${palette.divider}`,
  },
};

const override = {
  styleOverrides,
};

export default override;
