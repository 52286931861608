import { Button, DialogContent } from "@mui/material";
import React, { ReactNode, useState } from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import { useTranslation } from "react-i18next";

export function FilterButton(props: {
  activeFilterCount: number;
  title?: string;
  toggleOpen: (open: boolean) => void;
  open: boolean;
}) {
  const { t } = useTranslation(["common"]);
  return (
    <Button
      variant="outlined"
      sx={{ whiteSpace: "nowrap" }}
      startIcon={<TuneIcon />}
      onClick={() => props.toggleOpen(!props.open)}
    >
      {props.open
        ? t("common:filterPanel.hideActiveFilters", { count: props.activeFilterCount })
        : t("common:filterPanel.showActiveFilters", { count: props.activeFilterCount })}
    </Button>
  );
}

/**
 * Constructs a set of extra filters that you can expand to get a full list, but when contracted
 * just show the active filters.
 * @param props
 */
export default function FilterPanel(props: {
  children: ReactNode;
  activeFilterCount: number;
  title?: string;
}) {
  const [showPanel, setShowPanel] = useState(false);
  const { t } = useTranslation(["common"]);

  const dialog = showPanel ? (
    <ResponsiveDialog
      open={showPanel}
      onClose={() => setShowPanel(false)}
      title={props.title ?? t("common:filterPanel.dialogTitle")}
      stopBackdropClose={false}
    >
      <DialogContent>{props.children}</DialogContent>
    </ResponsiveDialog>
  ) : null;

  return (
    <>
      <Button
        variant="outlined"
        sx={{ whiteSpace: "nowrap" }}
        startIcon={<TuneIcon />}
        onClick={() => setShowPanel(true)}
      >
        {props.activeFilterCount > 0
          ? t("common:filterPanel.activeFilters", { count: props.activeFilterCount })
          : t("common:filterPanel.filters")}
      </Button>
      {dialog}
    </>
  );
}
