import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { PiiLevelContext } from "Contexts/PiiLevelContext";
import { AppLayoutUserMenuItem } from "Layout/AppLayout/AppLayout";
import { ResponsiveDialog } from "MDS/ResponsiveDialog";
import React, { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

function PiiLevelDialog(props: { open: boolean; onClose: () => void }): ReactElement {
  const { t } = useTranslation(["settings", "common"]);

  const piiLevelContext = useContext(PiiLevelContext);

  const onChange = (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    if (value === "full_access" || value === "limited_pii" || value === "pii_free" || value == "full_anon") {
      piiLevelContext.setPiiLevel(value);
    } else {
      piiLevelContext.setPiiLevel(null);
    }
  };

  return (
    <ResponsiveDialog
      open={props.open}
      onClose={props.onClose}
      title={t("settings:piiLevel.title")}
      stopBackdropClose={false}
    >
      <DialogContent>
        <Typography>{t("settings:piiLevel.explanation")}</Typography>
        <RadioGroup value={piiLevelContext.piiLevel} onChange={onChange}>
          <FormControlLabel
            value="full_access"
            control={<Radio />}
            label={t("settings:piiLevel.full_access.title")}
          />
          <FormHelperText>{t("settings:piiLevel.full_access.description")}</FormHelperText>
          <FormControlLabel
            value="limited_pii"
            control={<Radio />}
            label={t("settings:piiLevel.limited_pii.title")}
          />
          <FormHelperText>{t("settings:piiLevel.limited_pii.description")}</FormHelperText>
          <FormControlLabel
            value="pii_free"
            control={<Radio />}
            label={t("settings:piiLevel.pii_free.title")}
          />
          <FormHelperText>{t("settings:piiLevel.pii_free.description")}</FormHelperText>
          <FormControlLabel
            value="full_anon"
            control={<Radio />}
            label={t("settings:piiLevel.full_anon.title")}
          />
          <FormHelperText>{t("settings:piiLevel.full_anon.description")}</FormHelperText>
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <>
          <Button variant="outlined" color="primary" onClick={props.onClose}>
            {t("common:actions.close")}
          </Button>
        </>
      </DialogActions>
    </ResponsiveDialog>
  );
}

function PiiLevelMenuItems(setActive: () => void): ReadonlyArray<AppLayoutUserMenuItem> {
  const { t } = useTranslation(["settings"]);

  return [
    {
      label: t("settings:piiLevel.navItem"),
      icon: <VisibilityOffIcon />,
      onClick: () => setActive(),
    },
  ];
}

export { PiiLevelMenuItems, PiiLevelDialog };
