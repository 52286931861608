import React, { ReactElement } from "react";

import CheckIcon from "@mui/icons-material/Check";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";

type TrueFalseIconProps = {
  value: boolean;
};
export default function TrueFalseIcon(props: TrueFalseIconProps): ReactElement {
  return props.value ? <CheckIcon fontSize="small" /> : <DoNotDisturbIcon fontSize="small" />;
}
