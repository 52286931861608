import { Grid, Link, Paper } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import Page from "Layout/Page";
import PageHeader from "Layout/PageHeader";
import React, { ReactElement, useState } from "react";
import {
  InstituteListQueryQuery,
  useInstituteListQueryQuery,
  InstituteSortParameter,
} from "GeneratedGraphQL/SchemaAndOperations";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";
import { GridRenderCellParams } from "@mui/x-data-grid";
import LaunchInstituteButton from "../InstituteDetails/LaunchInstituteButton";
import { Maybe, Nothing } from "seidr";
import SearchAndFilters from "./SearchAndFilters";

type ExtraVars = {
  search: string | null;
};

function InstituteList(): ReactElement {
  const [search, updateSearch] = useState<Maybe<string>>(Nothing());
  const extra: ExtraVars = {
    search: search.getOrElse(""),
  };

  const columns: DataGridCols<InstituteListQueryQuery, ["institutes"]> = React.useMemo(() => {
    return [
      {
        field: "Institute Launcher",
        headerName: "",
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams) => {
          params.row["id"];
          return <LaunchInstituteButton instituteId={params.row.id} />;
        },
      },
      {
        field: "shortcode",
        headerName: "shortcode",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.shortcode,
      },
      {
        field: "enabled",
        headerName: "enabled",
        type: "boolean",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.enabled,
      },
      {
        field: "isTest",
        headerName: "isTest",
        type: "boolean",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.isTest,
      },
      {
        field: "updatedAt",
        headerName: "updatedAt",

        sortable: true,
        filterable: false,
        valueGetter: (_value, row) =>
          new Date(row.updatedAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
      },
      {
        field: "createdAt",
        headerName: "createdAt",
        sortable: true,
        filterable: false,
        valueGetter: (_value, row) =>
          new Date(row.createdAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }),
      },
      {
        field: "id",
        headerName: "ID",
        sortable: false,
        filterable: false,
        valueGetter: (_value, row) => row.id.toString(),
      },
      {
        field: "name",
        headerName: "Name",
        filterable: false,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Link component={RouterLink} to={params.row["id"]}>
            {params.value}
          </Link>
        ),
      },
      {
        field: "measurementAllowed",
        headerName: "measurementAllowed",
        type: "boolean",
        sortable: true,
        filterable: false,
        valueGetter: (_value, row) => row.measurementAllowed,
      },
    ];
  }, []);

  return (
    <Page browserTitle="Institutes">
      <PageHeader title="Institutes" />

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Paper>
            <SortablePagableCollectionDataGrid
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    measurementAllowed: false,
                    id: false,
                    updatedAt: false,
                    isTest: false,
                    enabled: false,
                    shortcode: false,
                    createdAt: false,
                  },
                },
              }}
              queryHook={useInstituteListQueryQuery}
              unwrapData={(props) => props?.institutes || null}
              queryVariables={extra}
              defaultPageSize={10}
              colNameToSortParam={(colName) => {
                switch (colName) {
                  case "name": {
                    return InstituteSortParameter.NAME;
                    break;
                  }
                  case "updatedAt": {
                    return InstituteSortParameter.UPDATED_AT;
                    break;
                  }
                  case "createdAt": {
                    return InstituteSortParameter.CREATED_AT;
                    break;
                  }
                  case "measurementAllowed": {
                    return InstituteSortParameter.MEASUREMENT_ALLOWED;
                    break;
                  }
                  default: {
                    return null;
                    break;
                  }
                }
              }}
              columns={columns}
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <SearchAndFilters
            onChange={(newSearch) => {
              updateSearch(newSearch);
            }}
            searchText={search}
          />
        </Grid>
      </Grid>
    </Page>
  );
}

export default InstituteList;
