import { TypographyOptions } from "@mui/material/styles/createTypography";
import palette from "./palette";

enum FontSize {
  Micro = "0.625rem", // 10px
  Tiny = "0.75rem", // 12px
  Small = "0.875rem", // 14px
  Regular = "1em", // 16px
  Large = "1.125rem", // 18px
  Huge = "1.25rem", // 20px
}

// TODO: Consider changing the set of typography variants we can define
//       so that we can have explicit variants for things like chips and
//       charts instead of repurposing the mui default variant list.
//       https://mui.com/customization/typography/#adding-amp-disabling-variants

const typography: TypographyOptions = {
  fontFamily: ["gibson", "iAWriterQuattroS", "sans-serif"].join(","),
  body1: {
    fontSize: FontSize.Regular,
  },
  body2: {
    // TODO: body2 is applied to the body element as the
    // default, should that be body1 such that we can use body
    // to as a proper alternative main style?
    fontSize: FontSize.Regular,
  },
  h1: {
    fontSize: FontSize.Huge,
    fontWeight: "bold",
  },
  h2: {
    fontSize: FontSize.Large,
    fontWeight: "bold",
  },
  h3: {
    fontSize: FontSize.Regular,
    fontWeight: "bold",
  },
  subtitle1: {
    fontSize: FontSize.Small,
    color: palette.text.primary,
  },
  subtitle2: {
    fontSize: FontSize.Small,
    color: palette.text.secondary,
  },
  caption: {
    fontSize: FontSize.Small,
    fontStyle: "italic",
  },
};

export default typography;
export { typography, FontSize };
