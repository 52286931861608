import { Box, Button, Card, CardContent, Grid, Stack, TextField, Typography } from "@mui/material";
import { useCurrentInstituteLoginDetails, useFetch } from "AppSession/AppSession";
import { Link } from "MDS/Link";
import React, { ChangeEvent, ReactElement, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { MirahLogo } from "Shared/MirahIcon";

function ForgotPassword(): ReactElement {
  const { t } = useTranslation(["login"]);

  // We need some pre-baked data to decide what to display.
  const instituteLoginDetails = useCurrentInstituteLoginDetails();

  // Whether or not we've started sending out an email. This just disables the button to send one.
  const [activelySending, setActivelySending] = useState(false);

  // Whether or not we've sent out an email. This just swaps the text to tell folks what happened.
  const [emailSent, setEmailSent] = useState(false);

  // This just tracks the username the user is typing.
  const [email, setEmail] = useState("");
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // Our data for posting to the forgot password endpoint.
  const postData = {
    user: {
      email: email,
      institute_id: instituteLoginDetails.id,
    },
  };

  // We're going to send the reset request, but we'll also hard wait for it finish
  // before showing any additional information.
  const { remoteData, fetchFn } = useFetch(
    "/users/password",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    },
    [email]
  );
  remoteData.caseOf({
    _: () => {
      /*noop*/
    },
    Success: () => {
      if (!emailSent) {
        setEmailSent(true);
      }
    },
  });

  const handleSubmit = async (event: React.SyntheticEvent) => {
    // Since this is a form submit, this just keeps the actual event from immediately
    // refreshing the page.
    event.preventDefault();

    setActivelySending(true);
    fetchFn();
  };

  const isFormValid = () => {
    return email.length > 0;
  };

  return (
    <Card>
      <CardContent>
        <Helmet>
          <title>{t("login:titles.forgotPassword")}</title>
        </Helmet>
        <Grid container columns={12} spacing={0}>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <Typography sx={{ float: "right", marginTop: "0.1em" }}>{instituteLoginDetails.name}</Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit} hidden={emailSent}>
          <Stack spacing={2} alignItems="center">
            <MirahLogo width="100px" height="100px" />
            <Box width="20em">{t("login:text.forgotPasswordDirections")}</Box>
            <Box width="20em">
              <TextField
                label={t("login:fields.username")}
                onChange={handleEmailChange}
                value={email}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box width="14em">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={!isFormValid() || activelySending}
                sx={{ marginTop: "1em" }}
                type="submit"
              >
                {t("login:buttons.passwordReset")}
              </Button>
            </Box>
          </Stack>
        </form>
        <Box sx={{ display: emailSent ? "inline" : "none" }}>
          <Stack spacing={2} alignItems="center">
            <MirahLogo width="100px" height="100px" />
            <Box width="20">{t("login:text.postForgotPassword")}</Box>
            <Link to="/app/login">{t("login:links.login")}</Link>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}

export default ForgotPassword;
